import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrderFeedSVG from "../../assets/svg/OrderFeed";
import OrderHistorySVG from "../../assets/svg/OrderHistory";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";

const OrderHistory = () => {
  const navigate = useNavigate();

  const onNavigate = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    []
  );

  const { t } = useTranslation();

  return (
    <div className="h-screen overflow-auto flex flex-col bg-[white]">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={onNavigate("/partner")}
          title={t("feedOrderAndDelivery.cards.myOrder.title")}
        />
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={t("PartnerOrder.UpcomingOrder")}
            subTitle={t("PartnerOrder.UpcomingOrderDetail")}
            icon={<OrderFeedSVG />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={onNavigate("/order/new")}
          />
        </div>
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={t("PartnerOrder.OrderHistory")}
            subTitle={t("PartnerOrder.OrderHistoryDetail")}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={onNavigate("/order/history")}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderHistory;
