export function UserSvg() {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 2.17535C9.61079 1.48334 10.5044 1.04688 11.5 1.04688C13.341 1.04688 14.8333 2.53926 14.8333 4.38021C14.8333 6.22116 13.341 7.71354 11.5 7.71354C10.5044 7.71354 9.61079 7.27708 9 6.58506M11.5 16.0469H1.5V15.2135C1.5 12.4521 3.73858 10.2135 6.5 10.2135C9.26142 10.2135 11.5 12.4521 11.5 15.2135V16.0469ZM11.5 16.0469H16.5V15.2135C16.5 12.4521 14.2614 10.2135 11.5 10.2135C10.5893 10.2135 9.73543 10.457 9 10.8825M9.83333 4.38021C9.83333 6.22116 8.34095 7.71354 6.5 7.71354C4.65905 7.71354 3.16667 6.22116 3.16667 4.38021C3.16667 2.53926 4.65905 1.04688 6.5 1.04688C8.34095 1.04688 9.83333 2.53926 9.83333 4.38021Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
