import CircleIcon from "@mui/icons-material/Circle";
import { UnitEnum } from "../../../../types/FeedBrand.type";
import { ListOrderDetail } from "../../../../types/MyOrderFeed.type";
import { thousandComma } from "../../../../utils/common/string";
import { useTranslation } from "react-i18next";

type OrderFeedFarmerItemListProp = {
  isTotalItemPrice?: boolean;
  listOrder: ListOrderDetail;
  index?: number;
};

const OrderFeedFarmerItemList = ({
  isTotalItemPrice,
  listOrder,
  index,
}: OrderFeedFarmerItemListProp) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="pt-4 items-center">
        <CircleIcon
          sx={{
            fontSize: "8px",
            color: "#014F79",
            marginLeft: "12px",
          }}
        />
        <span className="ml-[10px] text-tertiary font-medium text-sm">
          {`${t("feedOrderAndDelivery.myOrder.card.item")}`} {index ?? 1}
        </span>
        <div className="px-4">
          <div className="flex justify-between text-sm mt-2">
            <p className="text-tertiary">{`${t(
              "feedOrderAndDelivery.myOrder.card.brandName"
            )}`}</p>
            <p className="text-primary">{listOrder.feedBrand.nameTh}</p>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <p className="text-tertiary">{`${t(
              "feedOrderAndDelivery.myOrder.card.brandSkuName"
            )}`}</p>
            <p className="text-primary">{listOrder.feedBrandItem.skuTh}</p>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <p className="text-tertiary">{`${t(
              "feedOrderAndDelivery.myOrder.card.quantity"
            )}`}</p>
            <p className="text-primary">
              {listOrder.feedAmount}{" "}
              {i18n.language === "th"
                ? UnitEnum[listOrder.feedUnit as keyof typeof UnitEnum]
                : listOrder.feedUnit}
            </p>
          </div>
          {isTotalItemPrice ? (
            <div className="flex justify-between text-sm mt-2">
              <p className="text-tertiary">{`${t(
                "feedOrderAndDelivery.myOrder.card.price"
              )}`}</p>
              <p className="text-primary">
                {thousandComma(listOrder.totalItemPrice)}{" "}
                {`${t("feedOrderAndDelivery.myOrder.card.baht")}`}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default OrderFeedFarmerItemList;
