import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ButtonFillCustom from "../../../admin/components/Button/ButtonFillCustom";
import AppBarFS from "../../../components/app-bar/AppBar";
import { getListStaff } from "./staff.service";
import { IStaffDetail } from "./staff.type";

type Props = {};

function NextPageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <mask
        id="mask0_18059_877667"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="12"
        height="12"
      >
        <rect width="12" height="12" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_18059_877667)">
        <path
          d="M4.0125 11L3.125 10.1125L7.2375 6L3.125 1.8875L4.0125 1L9.0125 6L4.0125 11Z"
          fill="#34407B"
        />
      </g>
    </svg>
  );
}

const Staff = (props: Props) => {
  const navigate = useNavigate();
  const [staffList, setStaffList] = useState<IStaffDetail[]>();
  const { t } = useTranslation();

  useEffect(() => {
    const fetch = async () => {
      const data = await getListStaff();
      setStaffList(data.staffs);
    };
    fetch();
  }, []);

  return (
    <div className="!h-screen !min-h-screen flex flex-col">
      <div className="mt-4 flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          onIconPress={() => navigate("/farms/account-center")}
          title={t("RoleAndPermission.StaffRole")}
        />
      </div>
      <div className="m-6">
        <p className="text-[20px] font-semibold text-[#3777BC]">
          {t("RoleAndPermission.StaffMember")}
        </p>
      </div>
      {(staffList || []).map((staff, index) => (
        <div
          key={index}
          className="flex justify-between items-center bg-[#EEF2FF]  py-3 px-5 mb-4 mx-4 rounded-2xl text-[18px] font-semibold !text-[#34407B] shadow-lg"
          onClick={() =>
            navigate("/partner/account-center/staff/" + staff.id, {
              state: staff.id,
            })
          }
        >
          <p>{`${staff.userName}(${staff.name})`}</p>
          <div>
            <NextPageIcon />
          </div>
        </div>
      ))}
      <div className="flex justify-center pt-6">
        <ButtonFillCustom
          title={t("RoleAndPermission.AddRole")}
          startIcon={<AddCircleOutlinedIcon />}
          className="!w-[189px] !h-[38px] !rounded-[50px] !py-[16px] !font-bold !text-[16px]"
          onClick={() => navigate("/partner/account-center/add-staff")}
        />
      </div>
    </div>
  );
};

export default Staff;
