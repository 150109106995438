import { useMemo } from "react";
import { useAdminAuth } from "../admin/components/AdminAuth/AdminAuthProvider";
import { UserFunctionRole, UserRole } from "../utils/enum/user-role.enum";

export const useShowButtonByRoles = (roles: UserFunctionRole[]) => {
  const { functionRole } = useAdminAuth();
  const showButton = useMemo(
    () => roles.includes(functionRole),
    [functionRole, roles]
  );
  return { showButton };
};
