import dayjs from "dayjs";
import { useEffect, useState } from "react";
import PersonSvg from "../../../../../assets/svg/PersonSvg";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../../const/app.constant";
import { thousandComma } from "../../../../../utils/common/string";
import { getPersonalInfoPartner } from "../../services/partnerDetail.service";
import { IGetPersonalInfoPartner } from "../../types/personalInformation.type";
import { useTranslation } from "react-i18next";

type Props = {
  partnerId?: string;
};

const PersonalInformation = ({ partnerId }: Props) => {
  const { t } = useTranslation();
  const [partnerInfo, setPartnerInfo] =
    useState<IGetPersonalInfoPartner | null>();

  const handleGetPartnerInfo = async () => {
    const data = await getPersonalInfoPartner(partnerId);
    setPartnerInfo(data);
  };
  useEffect(() => {
    handleGetPartnerInfo();
  }, []);
  return (
    <>
      <div className="flex gap-2 text-center items-center mt-6">
        <PersonSvg />
        <p className="text-lg font-bold text-[#3777BC] font-sans">
          {t("partner.personalInformationTab.title")}
        </p>
      </div>

      <div className="flex gap-3 mt-4">
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">
            {t("partner.personalInformationTab.partnerId")}
          </p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {partnerInfo?.result.partnerCode}
          </p>
        </div>
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">
            {t("partner.personalInformationTab.partnerName")}
          </p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {partnerInfo?.result.name + " " + partnerInfo?.result.surname}
          </p>
        </div>
      </div>
      <div className="flex gap-3 mt-4">
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">
            {t("partner.personalInformationTab.phoneNumber")}
          </p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {partnerInfo?.result.mobileNumber || "-"}
          </p>
        </div>
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">
            {t("partner.personalInformationTab.type")}
          </p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {partnerInfo?.result.type || "-"}
          </p>
        </div>
      </div>
      <div className="flex gap-3 mt-4">
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">
            {t("partner.personalInformationTab.saleName")}
          </p>
          <div className="w-full pb-3">
            {partnerInfo?.result.groupArea.sellerName}
          </div>
        </div>
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">
            {t("partner.personalInformationTab.groupArea")}
          </p>
          <div className="w-full pb-3 font-sans">
            {partnerInfo?.result.groupArea.name}
          </div>
        </div>
      </div>
      <div className="flex gap-3 mt-4">
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">
            {t("partner.personalInformationTab.province")}
          </p>
          <div className="w-[340px] pb-3 font-sans">
            {" "}
            {partnerInfo?.result.groupArea.groupProvinces
              .map((group) => group.nameTh)
              .join(" , ")}
          </div>
        </div>
        <div className="w-[50%]">
          <p className="text-[14px] mb-3 font-sans font-bold">
            {t("partner.personalInformationTab.contractSigningDate")}
          </p>
          <div className="w-[340px] pb-3 font-sans">
            {partnerInfo?.result.contractSignDate
              ? dayjs(partnerInfo.result.contractSignDate).format(
                  DATE_FORMAT_DATE_PICKER2
                )
              : "-"}
          </div>
        </div>
      </div>
      <div className="flex gap-3 mt-4">
        <div className="w-full">
          <p className="text-[14px] mb-3 font-sans font-bold">
            {t("partner.personalInformationTab.totalRevenue")}
          </p>
          <p className="text-[14px] w-[340px] pb-3 font-sans">
            {thousandComma(partnerInfo?.result.totalRevenue || 0, 2) + " บาท"}
          </p>
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
