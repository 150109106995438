import axios from "axios";
import { get } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Receipt from "../../../../assets/svg/Receipt";
import DownloadLinkWithDelete from "../../../../components/common/File/DownloadLinkWithDelete";
import { getDownloadUrl } from "../../../../services/Financial.service";
import { IContracts } from "../../../../types/Financial.type";
import { handleDownloadBlob } from "../../../../utils/downloadFile";
import { getCommaNumber } from "../../../../utils/number";
import CountChart from "./CountChart";
import DetailAndIcon from "./DetailAndIcon";

interface D {
  data: IContracts;
}

interface FileBeforeUpload {
  fileName: string;
  fileSize: number;
  id: string;
}

const InfoContract = (props: D) => {
  const { data } = props;

  const { t } = useTranslation();

  const [customerPickingSlip, setCustomerPickingSlip] = useState<
    FileBeforeUpload[]
  >([]);

  const handleDownloadByUrl = useCallback(
    (id: string, fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrl(id, fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        if (typeof window !== "undefined") {
          const objectURL = window.URL.createObjectURL(response.data);
          handleDownloadBlob(objectURL, name);
        }
      }
    },
    [customerPickingSlip]
  );

  return (
    <div className="flex flex-col gap-3 w-full h-auto">
      <div className="w-full h-full flex flex-col items-center py-4">
        <CountChart
          dataset={[
            {
              name: "remaining-pigs",
              value: get(data, ["creditUsed"]),
              fill: "#F1EFFB",
            },
            {
              name: "exceedAmount",
              value: get(data, ["exceedAmount"]),
              fill: "#F1EFFB",
            },
            {
              name: "sold-pigs",
              value: get(data, ["credit"]),
            },
          ]}
        />
      </div>
      <DetailAndIcon data={data} />
      <div className="flex flex-col px-3 pb-3">
        <div className="bg-[#F6FBFF] rounded-[10px] gap-1">
          <div className="flex flex-row justify-between p-3 border-[#D9EBFE] border-b-[1px]">
            <div className="text-[#4B4B4B] text-[16px] font-normal">
              {t("FinancialSupport.FarmName")}
            </div>
            <div className="text-[#3777BC] text-right text-[16px] font-normal">
              {get(data, ["house", "farm", "name"])}
            </div>
          </div>
          <div className="flex flex-row justify-between p-3 border-[#D9EBFE] border-b-[1px]">
            <div className="text-[#4B4B4B] text-[16px] font-normal">
              {t("FinancialSupport.HouseName")}
            </div>
            <div className="text-[#3777BC] text-right text-[16px] font-normal">
              {get(data, ["house", "name"])}
            </div>
          </div>
          <div className="flex flex-row justify-between p-3 border-[#D9EBFE] border-b-[1px]">
            <div className="text-[#4B4B4B] text-[16px] font-normal">
              {t("FinancialSupport.PigType")}
            </div>
            <div className="text-[#3777BC] text-right text-[16px] font-normal">
              {t("FinancialSupport.FattenedPig")}
            </div>
          </div>
          <div className="flex flex-row justify-between p-3 ">
            <div className="text-[#4B4B4B] text-[16px] font-normal">
              {t("FinancialSupport.Quantity")}
            </div>
            <div className="text-[#3777BC] text-right text-[16px] font-normal">
              {getCommaNumber(+get(data, ["pigAmount"]))} {t("common.pronoun")}
            </div>
          </div>
        </div>
      </div>
      {data && data.creditInformation.contractDocument.length > 0 && (
        <div className="flex flex-col px-3 pb-4 gap-2">
          <div className="flex flex-row px-3 gap-2">
            <Receipt />
            <div className="text-[#3777BC] text-[16px] font-normal">
              {t("FinancialSupport.ContractDocuments")}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div className="text-[#979797] text-[16px] font-normal pl-[48px]">
              {t("FinancialSupport.ContractDocuments")}
            </div>
            <DownloadLinkWithDelete
              onDownload={handleDownloadByUrl(
                get(data, ["id"]),
                get(data, ["creditInformation", "contractDocument", 0, "id"]),
                get(data, [
                  "creditInformation",
                  "contractDocument",
                  0,
                  "fileName",
                ])
              )}
              onDelete={async () => {
                setCustomerPickingSlip([]);
              }}
              sx={{
                fontSize: "16px",
                color: "#979797",
                width: "auto",
                padding: "0 !important",
                margin: "0 !important",
                ":hover": {
                  background: "#FFFFFF",
                },
              }}
              hideClip
              hideDeleteBtn
              name={t("FinancialSupport.ViewDocument")}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InfoContract;
