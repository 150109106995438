import {
  Checkbox,
  Link,
  TableCell,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import dayjs from "dayjs";
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import CheckBoxUnCheck from "../../../../../assets/svg/CheckBoxUnCheck";
import { TFarmerGetListBillItem } from "../../../../../types/Invoice.type";
import { getCommaNumber } from "../../../../../utils/number";
import { tw } from "../../../../../utils/tw";
import TableCustomMobile from "./TableCustomMobile";
import ReceiptModal from "./views/views/ReceiptModal";

const classes = {
  container: tw(`mt-6 min-h-[350px] px-[16px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

interface ITable {
  swapCellColor?: boolean;
  summary: string;
  setSummary: any;
  data: TFarmerGetListBillItem[];
  orderByType: "desc" | "asc";
  setOrderByType: Dispatch<SetStateAction<"desc" | "asc">>;
  itemSelected: string[];
  setItemSelected: Dispatch<SetStateAction<string[]>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  count: number;
}

const Table = (props: ITable) => {
  const {
    swapCellColor,
    summary,
    setSummary,
    data,
    orderByType,
    setOrderByType,
    itemSelected,
    setItemSelected,
    currentPage,
    setCurrentPage,
    count,
  } = props;
  const { t } = useTranslation();
  const headerItems = [
    {
      id: "paymentDueDate",
      numeric: false,
      disablePadding: false,
      label: t("FinancialSupport.DueDate"),
      width: "auto",
      isSorting: true,
      className: tw(`whitespace-pre`),
    },
    {
      id: "total",
      numeric: false,
      disablePadding: false,
      label: t("FinancialSupport.DueAmount"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "totalPending",
      numeric: false,
      disablePadding: false,
      label: t("FinancialSupport.PendingAmount"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "money",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
  ];
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");
  const [searchParams] = useSearchParams();

  const refId = searchParams.get("id");

  const [isShowReceipt, setIsShowReceipt] = useState(false);

  const onCloseReceipt = useCallback(() => {
    setIsShowReceipt(false);
    setSelectedInvoiceId("");
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = data.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const getCalTotal = useCallback(
    (idArray: string[], data: TFarmerGetListBillItem[]) => {
      if (data) {
        return data
          .filter((item) => idArray.includes(item.id))
          .reduce(
            (total, item) => total + (item.totalPrice - item.totalPaid),
            0
          );
      } else return null;
    },
    [data]
  );

  const totalValue = useMemo(() => {
    const ids = itemSelected.filter((i) => itemSelected);

    return getCalTotal(ids, data!);
  }, [data, itemSelected]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#4B4B4B",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    },
    "&:nth-of-type(even)": {
      backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    },
  }));

  useEffect(() => {
    setSummary(totalValue);
  }, [totalValue]);

  useEffect(() => {
    if (refId) {
      setItemSelected((prev) => [refId]);
    }
  }, [refId]);

  const rowChildren = useMemo(() => {
    const onShowReceipt = (id: string) => {
      setIsShowReceipt(true);
      setSelectedInvoiceId(id);
    };

    return data.map((row, index) => {
      const isItemSelected = isSelected(row.id);

      return (
        <StyledTableRow key={row.id + index}>
          <StyledTableCell
            padding="checkbox"
            sx={{
              border: "0px !important",
              paddingX: "4px",
            }}
          >
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
              sx={{
                borderWidth: "1px",
                color: "#C9C9C9",
                "&.Mui-checked": {
                  color: "#68C184",
                },
              }}
              icon={<CheckBoxUnCheck />}
            />
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
            }}
          >
            {dayjs(row.dueDate).format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
            }}
          >
            {getCommaNumber(row.totalPrice)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
            }}
          >
            {getCommaNumber(row.totalPending)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {row.debtStatus && (
              <Link
                component="button"
                variant="body2"
                onClick={() => onShowReceipt(row.id)}
                sx={{
                  color: "#68C184",
                  textDecorationColor: "#68C184",
                  whiteSpace: "pre",
                  ":hover": {
                    fontSize: "16px",
                  },
                }}
              >
                {t("FinancialSupport.ViewDebt")}
              </Link>
            )}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }, [data, itemSelected, currentPage]);

  return (
    <div className={classes.container}>
      <TableCustomMobile
        orderBy={"paymentDueDate"}
        order={orderByType}
        onChangeOrder={setOrderByType}
        onChangeOrderBy={() => {}}
        totalItemSize={count}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={10}
        rows={data}
        EmptyComponent={
          data.length === 0 ? (
            <div className={classes.emptyTable}>{t("manageStock.noData")}</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={itemSelected}
        onSelectAllClick={handleSelectAllClick}
        onSelectItem={() => {}}
      />
      <ReceiptModal
        isOpen={isShowReceipt}
        onClose={onCloseReceipt}
        ids={selectedInvoiceId}
        isMoney={true}
        data={data.filter((i) => i.id === selectedInvoiceId)}
      />
    </div>
  );
};

export default memo(Table);
