import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Y from "yup";
import ButtonFillCustom from "../../../../admin/components/Button/ButtonFillCustom";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../admin/pages/group-area/views/Dropdown";
import { Input } from "../../../../admin/pages/group-area/views/Input";
import AppBarFS from "../../../../components/app-bar/AppBar";
import useFormValidator from "../../../../hooks/useFormValidator.hook";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { tw } from "../../../../utils/tw";
import { editStaff, getHouseStaff, getListStaffById } from "../staff.service";
import { Farm } from "../staff.type";

type Props = {};

interface TForm {
  name: string;
  role: TDropdownValue[];
  farmName: TDropdownValue[];
  houseName: TDropdownValue[];
}

const defaultForm: TForm = {
  name: "",
  role: [],
  farmName: [],
  houseName: [],
};

const EditStaff = (props: Props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [form, setForm] = useState(defaultForm);
  const [houses, setHouses] = useState<TDropdownValue[]>([]);
  const [farms, setFarms] = useState<TDropdownValue[]>([]);
  const [resFarmData, setResFarmData] = useState<Farm[] | null>([]);
  const selectRole = [
    {
      label: "Manager",
      value: "manager",
    },
    {
      label: "Staff",
      value: "staff",
    },
  ];

  const errorSchema = Y.object({
    name: Y.string().required(t("RoleAndPermission.requiredInput")),
    role: Y.array()
      .min(1, t("RoleAndPermission.requiredSelect"))
      .required(t("RoleAndPermission.requiredSelect")),
    farmName: Y.array()
      .min(1, t("RoleAndPermission.requiredSelect"))
      .required(t("RoleAndPermission.requiredSelect")),
    houseName: Y.array()
      .min(1, t("RoleAndPermission.requiredSelect"))
      .required(t("RoleAndPermission.requiredSelect")),
  });

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));

      if (key === "farmName" && resFarmData) {
        const selectedFarmIds = value.map((farm: TDropdownValue) => farm.value);

        const filteredHouses = resFarmData
          .filter((farm) => selectedFarmIds.includes(farm.id))
          .flatMap((farm) =>
            farm.houses.map((house) => ({
              label: house.name,
              value: house.id,
            }))
          );
        setHouses(filteredHouses);
      }
    },
    [resFarmData]
  );

  const fetchDataHouseAndFarm = async () => {
    const data = await getHouseStaff();
    if (data.success) {
      // Map farms
      const mappedFarms = data.farms.map((farm) => ({
        label: farm.name,
        value: farm.id,
      }));

      // Map houses
      const mappedHouses = data.farms.flatMap((farm) =>
        farm.houses.map((house) => ({
          label: house.name,
          value: house.id,
        }))
      );
      setResFarmData(data.farms);
      setFarms(mappedFarms);
      setHouses(mappedHouses);
    }
  };
  const fetchDataEditStaff = async (id: string) => {
    const data = await getListStaffById(id);
    if (data) {
      const mappedFarms = data.staffs[0].farms.map((farm) => ({
        label: farm.name,
        value: farm.id,
      }));
      const mappedHouses = data.staffs[0].farms.flatMap((farm) =>
        farm.houses.map((house) => ({
          label: house.name,
          value: house.id,
        }))
      );
      const mappedType = data.staffs[0].type;

      setForm({
        name: data.staffs[0].name,
        role: [{ label: mappedType, value: mappedType }],
        farmName: mappedFarms,
        houseName: mappedHouses,
      });
    }
  };

  useEffect(() => {
    fetchDataHouseAndFarm();
  }, []);

  useEffect(() => {
    fetchDataEditStaff(state);
  }, [state]);

  const { onSubmit, errors } = useFormValidator({
    form,
    errorSchema,
    onValidateSuccess: async () => {
      const result = form.houseName.map((house) => {
        const foundFarm = resFarmData?.find((farm) =>
          farm.houses.some((h) => h.id === house.value)
        );

        return {
          houseId: house.value,
          id: foundFarm ? foundFarm.id : null,
        };
      });
      const groupedResult = _(result)
        .groupBy("id")
        .map((houses, farmId) => ({
          id: farmId,
          houseIds: houses.map((house) => house.houseId),
        }))
        .value();

      const body = {
        name: form.name,
        role: form.role[0]?.value,
        farms: groupedResult,
      };
      try {
        dispatch(loadingActions.show({ message: t("reportOrder.recording") }));
        await editStaff(state, body);
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: t("BasicAccounting.Success"),
            type: "success",
          })
        );
        navigate("/partner/account-center/staff");
      } catch (error) {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }
    },
  });

  const navigate = useNavigate();
  return (
    <div className="!h-screen !min-h-screen flex flex-col">
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          onIconPress={() => navigate("/partner/account-center/staff")}
          title={t("RoleAndPermission.EditStaff")}
        />
      </div>
      <div className="flex flex-col px-6 gap-6">
        <Input
          label2={t("RoleAndPermission.StaffName")}
          focused
          placeholder={t("RoleAndPermission.StaffName")}
          value={form.name}
          onChangeText={onChangeForm("name")}
          customInputStyle={tw(
            "!h-[56px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
          )}
          borderRadius={"28px"}
          errorMessage={errors.sureName}
          containerDetailClassName={"p-0"}
        />
        <Dropdown
          options={selectRole}
          onChange={onChangeForm("role")}
          renderValueArray={form.role}
          shrink
          label2={t("RoleAndPermission.Role")}
          switchStyle
          placeholder={t("RoleAndPermission.Role")}
          containerSelect="pb-0"
          errorMessage={errors.role}
        />
        <Dropdown
          multiple
          options={farms}
          onChange={onChangeForm("farmName")}
          renderValueArray={form.farmName}
          shrink
          label2={t("RoleAndPermission.FarmName")}
          switchStyle
          placeholder={t("RoleAndPermission.FarmName")}
          containerSelect="pb-0"
          errorMessage={errors.farmName}
        />
        <Dropdown
          multiple
          options={houses}
          onChange={onChangeForm("houseName")}
          renderValueArray={form.houseName}
          shrink
          label2={t("RoleAndPermission.HouseName")}
          switchStyle
          placeholder={t("RoleAndPermission.HouseName")}
          containerSelect="pb-0"
          errorMessage={errors.houseName}
        />
      </div>
      <div className="mt-auto mb-6 px-6">
        <ButtonFillCustom
          onClick={onSubmit}
          title={t("FinancialSupport.Confirm")}
          btnTextColor="#ffffff"
          btnBgColor="#68C184"
          className={"!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold"}
        />
      </div>
    </div>
  );
};

export default EditStaff;
