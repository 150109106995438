import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { tw } from "../../../../utils/tw";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import SelectAdminCustom from "../../../components/Select/Select";
import { TUseSaleTargetSaleLead } from "../hooks/useSaleTargetSaleLead.hook";
import { getProvinceSaleTarget } from "../services/saleTarget.service";
import { IProvinceSaleTargetResult } from "../types/SaleTargetTable.type";

const classes = {
  flexRow: tw(`flex flex-row`),
  flexCol: tw(`flex flex-col`),
  flex: tw(`flex`),
  fullScreen: tw(`w-full h-full`),
  label: tw(`text-sm font-medium font-sans`),
};

interface IFarmDetailFilter {
  searchSaleTarget: TUseSaleTargetSaleLead;
  onSearch: () => void;
  hideSelect?: boolean;
}

export function SaleTargetSaleLeadSearch({
  searchSaleTarget,
  onSearch,
  hideSelect = false,
}: IFarmDetailFilter) {
  const [toggleClear, setToggleClear] = useState(false);
  const [selectProvince, setSelectProvince] = useState<
    IProvinceSaleTargetResult[]
  >([]);

  const fetchProvince = async () => {
    const data = await getProvinceSaleTarget();
    if (data) {
      setSelectProvince(data.result);
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchSaleTarget.setPage(1);
    onSearch();
  };

  useEffect(() => {
    onSearch();
  }, [toggleClear]);

  const handleChangeProvince = (e: SelectChangeEvent<unknown>) => {
    searchSaleTarget.setProvince(e.target.value as string);
  };

  useEffect(() => {
    fetchProvince();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.fullScreen}>
          {!hideSelect && (
            <div className="flex justify-between">
              <div className="flex gap-3 w-[80%]">
                <div className="w-[50%]">
                  <span className={classes.label}>จังหวัด</span>
                  <div className="pt-2">
                    <SelectAdminCustom
                      value={searchSaleTarget.province}
                      placeholder="เลือก"
                      endAdornment={
                        searchSaleTarget.province !== "" ? (
                          <CloseRoundedIcon
                            sx={{
                              cursor: "pointer",
                              fontSize: "16px !important",
                              marginRight: "20px",
                            }}
                            onClick={(_) => {
                              searchSaleTarget.setProvince("");
                              setToggleClear((prev) => !prev);
                            }}
                          />
                        ) : null
                      }
                      onChange={(e) => handleChangeProvince(e)}
                      menuItems={selectProvince?.map((item, index) => (
                        <MenuItem
                          key={`reject-item-${index}`}
                          value={item.code}
                        >
                          {item.province}
                        </MenuItem>
                      ))}
                      renderValueArray={selectProvince?.map((row) => ({
                        value: row.code.toString(),
                        label: row.province,
                      }))}
                    />
                  </div>
                </div>
              </div>
              <div className="h-full self-end">
                <ButtonFillCustom
                  type="submit"
                  title="ค้นหา"
                  onClick={() => onSearch()}
                />
              </div>
            </div>
          )}
          <div className={tw(classes.flexRow, "w-full justify-between pt-6")}>
            <div className="flex w-full">
              <div className="w-[50%] mr-4">
                <TextInputSearchCustom
                  value={searchSaleTarget.searchText}
                  placeholder="ค้นหาโดยรหัสพนักงาน, ชื่อพนักงาน"
                  onChange={(event) =>
                    searchSaleTarget.setSearchText(event.target.value)
                  }
                  InputProps={{
                    endAdornment:
                      searchSaleTarget.searchText.length > 0 ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(_) => {
                            searchSaleTarget.setSearchText("");
                            setToggleClear((prev) => !prev);
                          }}
                        />
                      ) : null,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
