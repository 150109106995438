import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { tw } from "../../../../../../utils/tw";
import { ICreditFeedData } from "../../../../../../types/CreditFeed.type";
import TableCustom, {
  ExpandableTableRow,
} from "../../../../../components/Table/TableCustom";
import dayjs from "dayjs";
import ChipOrderStatus from "../../../../../components/Chip/ChipOrderStatus";
import FillDataExpand from "./FillDataExpand";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import ImportIcon from "../../../../../assets/svg/ImportIcon.svg";
import { useTranslation } from "react-i18next";

type TStatusCreditMap =
  | "pending_lending_approve_offer"
  | "pending_sale_lead_approve_offer"
  | "pending_sale_forward_consideration"
  | "pending_lending_confirm_consideration"
  | "pending_farmer_approve_offer";

const classes = {
  container: tw(`min-h-[240px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

interface ITable {
  data: ICreditFeedData[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  itemSelected: string[];
  setItemSelected: (ids: string[]) => void;
  roleSaleLead?: boolean;
  onOpenReject?: () => void;
  onOpenApproved?: () => void;
  idPending: string;
  setIdPending: Dispatch<SetStateAction<string>>;
  onOpenImport?: () => void;
  onSummit?: (id: string) => void;
  countData: number;
  currentOrderByKey: "farmer_code" | "credit_rating" | "updated_at";
  setCurrentOrderByKey: Dispatch<
    SetStateAction<"farmer_code" | "credit_rating" | "updated_at">
  >;
  orderByType: "desc" | "asc";
  setOrderByType: Dispatch<SetStateAction<"desc" | "asc">>;
  isEdit?: boolean;
}

const TableTabPending = (props: ITable) => {
  const { t } = useTranslation();
  const {
    data,
    currentPage,
    setCurrentPage,
    itemSelected,
    setItemSelected,
    roleSaleLead = false,
    onOpenReject,
    onOpenApproved,
    idPending,
    setIdPending,
    onOpenImport,
    onSummit,
    countData,
    currentOrderByKey,
    setCurrentOrderByKey,
    orderByType,
    setOrderByType,
    isEdit,
  } = props;

  const headerItems = [
    {
      id: "farmer_code",
      numeric: false,
      disablePadding: false,
      label: t("creditFeed.waitingToApprovalTab.customerId"),
      width: "auto",
      isSorting: true,
      className: "!whitespace-pre",
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t("creditFeed.waitingToApprovalTab.customerName"),
      width: "auto",
      isSorting: false,
      className: "!whitespace-pre",
    },
    {
      id: "credit_rating",
      numeric: false,
      disablePadding: false,
      label: t("creditFeed.waitingToApprovalTab.grade"),
      width: "auto",
      isSorting: true,
      className: "!whitespace-pre",
    },
    {
      id: "saleName",
      numeric: false,
      disablePadding: false,
      label: t("creditFeed.waitingToApprovalTab.saleName"),
      width: "auto",
      isSorting: false,
      className: "!whitespace-pre",
    },
    ...(roleSaleLead
      ? []
      : [
          {
            id: "updated_at",
            numeric: false,
            disablePadding: false,
            label: t("creditFeed.waitingToApprovalTab.lastUpdatedDate"),
            width: "auto",
            isSorting: true,
            className: "!whitespace-pre",
          },
          {
            id: "statusCredit",
            numeric: false,
            disablePadding: false,
            label: t("creditFeed.waitingToApprovalTab.status"),
            width: "auto",
            isSorting: false,
            className: "!whitespace-pre",
          },
        ]),
    {
      id: "statusCredit",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "auto",
      isSorting: false,
      className: "!whitespace-pre",
    },
  ];

  const statusCreditMap: Record<
    TStatusCreditMap,
    { color: string; bgColor: string; label: string }
  > = {
    pending_sale_lead_approve_offer: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "รออนุมัติจากหัวหน้าเซลล์",
    },
    pending_lending_approve_offer: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "รออนุมัติจาก Lending",
    },
    pending_sale_forward_consideration: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "รอตรวจสอบเอกสาร",
    },
    pending_lending_confirm_consideration: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "รออนุมัติเครดิตจาก Lending",
    },
    pending_farmer_approve_offer: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "รอการพิจารณาจากลูกค้า",
    },
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = data.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const onOpenRejectFn = useCallback((id: string) => {
    setIdPending(id);
    onOpenReject && onOpenReject();
  }, []);

  const onOpenApprovedFn = useCallback((id: string) => {
    setIdPending(id);
    onOpenApproved && onOpenApproved();
  }, []);

  const onOpenImportFn = useCallback((id: string) => {
    setIdPending(id);
    onOpenImport && onOpenImport();
  }, []);

  const onSummitFn = useCallback((id: string) => {
    setIdPending(id);
    onSummit && onSummit(id);
  }, []);

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      const isItemSelected = isSelected(row.id);

      return (
        <ExpandableTableRow
          key={row.id + index}
          selected={isItemSelected}
          aria-checked={isItemSelected}
          expandComponent={
            <FillDataExpand
              data={row}
              isShowButton={
                isEdit &&
                (roleSaleLead ||
                  row.contractStatus === "pending_sale_forward_consideration")
              }
              button={
                <div className="flex gap-4">
                  <ButtonFillCustom
                    title={t("creditFeed.waitingToApprovalTab.notApprove")}
                    onClick={() => onOpenRejectFn(row.id)}
                    btnBgColor="#D32F2F"
                  />
                  <ButtonFillCustom
                    title={
                      row.contractStatus ===
                      "pending_sale_forward_consideration"
                        ? t("creditFeed.waitingToApprovalTab.sendToLending")
                        : t("creditFeed.waitingToApprovalTab.approve")
                    }
                    onClick={
                      row.contractStatus ===
                      "pending_sale_forward_consideration"
                        ? () => onSummitFn(row.id)
                        : () => onOpenApprovedFn(row.id)
                    }
                  />
                </div>
              }
              isShowButtonImport={
                row.contractStatus === "pending_sale_forward_consideration"
              }
              buttonImport={
                <div className="flex gap-4">
                  <ButtonOutlinedCustom
                    btnTextColor="#68C184"
                    btnBorderColor="#68C184"
                    title="นำเข้าเอกสาร"
                    startIcon={<ImportIcon />}
                    onClick={() => onOpenImportFn(row.id)}
                  />
                </div>
              }
            />
          }
        >
          <TableCell
            padding="checkbox"
            sx={{
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
              sx={{
                borderWidth: "1px",
                color: "#C9C9C9",
                "&.Mui-checked": {
                  color: "#68C184",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            <Link
              component="button"
              variant="body2"
              sx={{
                color: "#68C184",
                textDecorationColor: "#68C184",
                ":hover": {
                  fontSize: "16px",
                },
              }}
            >
              {row.registrationId}
            </Link>
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            {row.name + " " + row.surname}
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            {row.customerGrade.toLocaleUpperCase()}
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            {row.sellerName +
              " " +
              (row.sellerSurname === null ? "" : row.sellerSurname)}
          </TableCell>
          {!roleSaleLead && (
            <>
              <TableCell
                sx={{
                  whiteSpace: "pre",
                }}
              >
                {dayjs(row.updatedAt).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell
                sx={{
                  whiteSpace: "pre",
                }}
              >
                <ChipOrderStatus
                  bgColor={(statusCreditMap as any)[row.contractStatus].bgColor}
                  fontColor={(statusCreditMap as any)[row.contractStatus].color}
                  label={(statusCreditMap as any)[row.contractStatus].label}
                />
              </TableCell>
            </>
          )}
        </ExpandableTableRow>
      );
    });
  }, [data, currentPage, itemSelected]);

  return (
    <div className={classes.container}>
      <TableCustom
        order={orderByType}
        onChangeOrder={setOrderByType}
        orderBy={currentOrderByKey}
        onChangeOrderBy={setCurrentOrderByKey as any}
        totalItemSize={countData}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={10}
        rows={data}
        EmptyComponent={
          countData === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={itemSelected}
        onSelectAllClick={handleSelectAllClick}
        onSelectItem={(val) => setItemSelected(val as string[])}
      />
    </div>
  );
};

export default memo(TableTabPending);
