import FilterListIcon from "@mui/icons-material/FilterList";
import { Dialog, DialogContent, Divider, IconButton } from "@mui/material";
import { cloneDeep, set } from "lodash";
import { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../../../../admin/assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../../../admin/components/Button/ButtonFillCustom";
import { Input } from "../../../../../admin/pages/group-area/views/Input";
import RadioButton from "../../../../../components/common/RadioButton";
import { getCommaNumber } from "../../../../../utils/number";
import { tw } from "../../../../../utils/tw";

interface IAddMoneyToPay {
  isOpen: boolean;
  onCloseFn: () => void;
  summary: string;
  customPrice: string;
  setCustomPrice: any;
}

interface IState {
  option: number;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiDialog-paperScrollPaper]:rounded-[40px] [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[375px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  space: tw(`h-[12px]`),
};

const defaultForm = {
  SpecifyTheAmount: "",
};

const AddMoneyToPay = (props: IAddMoneyToPay) => {
  const { isOpen, onCloseFn, summary, customPrice, setCustomPrice } = props;
  const [form, setForm] = useState(defaultForm);

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const [state, setState] = useState<IState>({ option: 0 });

  const onChangeState = useCallback(
    (key: keyof IState) => (value: any) => {
      setState((prev) => {
        const prevState = cloneDeep(prev);
        return set(prevState, key, value);
      });
    },
    []
  );

  const onSubmit = useCallback(() => {
    if (state.option === 1) {
      setCustomPrice(form.SpecifyTheAmount);
    } else {
      setCustomPrice("");
    }
    onClose();
  }, [state, form]);

  const onClose = useCallback(() => {
    onCloseFn();
    setForm(defaultForm);
    setState({ option: 0 });
  }, []);

  useEffect(() => {
    if (isOpen && !!customPrice) {
      setState({ option: 1 });
      setForm((prev) => ({
        ...prev,
        SpecifyTheAmount: customPrice,
      }));
    }
  }, [isOpen, customPrice]);

  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent className="w-[375px] flex flex-col gap-6">
        <div className={classes.titleContainer}>
          <div className="text-secondary flex-shrink-0 flex flex-row">
            <div className="pt-[2px]">
              <FilterListIcon />
            </div>
            <div className="text-[24px] pl-2 font-medium leading-[28.8px]">
              {t("FinancialSupport.AmountToBePaid")}
            </div>
          </div>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div>
          <div className="flex flex-col gap-3 pb-2">
            <div
              className="flex flex-row items-center"
              onClick={() => onChangeState("option")(0)}
            >
              <RadioButton checked={state.option === 0} />
              <div className="font-normal text-[16px] text-[#646464]">
                {t("FinancialSupport.FullAmount")}
              </div>
            </div>
            {state.option === 0 && (
              <div className="flex flex-col gap-3">
                <div className="font-normal text-[14px] text-[#3777BC]">
                  {t("FinancialSupport.FullAmount")}
                </div>
                <div className="font-normal text-[14px] text-[#34407B]">
                  {getCommaNumber(+summary, true)}
                </div>
              </div>
            )}
          </div>
          <Divider></Divider>
          <div
            className="flex flex-row items-center pt-2 pb-3"
            onClick={() => onChangeState("option")(1)}
          >
            <RadioButton checked={state.option === 1} />
            <div className="font-normal text-[16px] text-[#646464]">
              {t("FinancialSupport.SpecifyAmount")}
            </div>
          </div>
          {state.option === 1 && (
            <Input
              label={""}
              placeholder="0.00"
              value={form.SpecifyTheAmount}
              onChangeText={onChangeForm("SpecifyTheAmount")}
              inputType="number"
              customInputStyle={tw(
                "!h-[63px] !bg-[#F6FBFF] !rounded-[28px] border-[1px]"
              )}
              borderRadius={"28px"}
              borderColor={"#3777BC !important"}
              customSuffix={
                <div className="pr-[5px] font-medium text-[16px] leading-[24px] text-[#D9EBFE]">
                  |{" "}
                  <span className="text-[#3777BC]">
                    {t("reportOrder.currency")}
                  </span>
                </div>
              }
            />
          )}
        </div>
        <ButtonFillCustom
          onClick={onSubmit}
          title={t("FinancialSupport.Confirm")}
          btnTextColor="#ffffff"
          btnBgColor="#68C184"
          className={classes.button}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddMoneyToPay);
