import { IGetGroupArea, IGetYear } from "../../types/Utils.type";
import {
  IGetAllUsersGraph,
  IGetCreditCompare,
  IGetListAllUsers,
  IGetListUsersVsCustomer,
  IGetUserVsCustomerGraph,
} from "../../types/dashboard/AllUsers.type";
import adminAPI from "../AdminApiService";

export const getAllUsersYear = async () => {
  const { data } = await adminAPI.get<IGetYear>(
    "/dashboard/all-users/dropdown/years"
  );
  return data.rows;
};

export const getAllUsersGroupArea = async () => {
  const { data } = await adminAPI.get<IGetGroupArea>(
    "/dashboard/all-users/dropdown/group-area"
  );
  return data.rows;
};

export const getAllUserGraph = async (searchParams?: URLSearchParams) => {
  try {
    let url = `/dashboard/all-users/graph/?${searchParams}`;
    const { data } = await adminAPI.get<IGetAllUsersGraph>(url);
    return data.result;
  } catch (error) {
    return { BarGraphData: [], accumulatedGraphData: [] };
  }
};

export const getCreditComparisonGraph = async () => {
  const { data } = await adminAPI.get<IGetCreditCompare>(
    "/dashboard/all-users/customer-credit-comparison/pie"
  );
  return data.result;
};

export const getUserVsCustomerGraph = async (params: {
  year?: string;
  groupAreaId?: string;
  segment?: string;
}) => {
  try {
    let url = "/dashboard/all-users/users-vs-customers/graph";
    if (params.year) {
      url += `?year=${params.year}`;
    }

    if (params.groupAreaId) {
      url += `&groupAreaId=${params.groupAreaId}`;
    }

    if (params.segment) {
      url += `&segment=${params.segment}`;
    }

    const { data } = await adminAPI.get<IGetUserVsCustomerGraph>(url);
    return data.result;

    // const { data } = await adminAPI.get<IGetUserVsCustomerGraph>(
    //   "/dashboard/all-users/users-vs-customers/graph",
    //   {
    //     params: {
    //       year: params.year,
    //       groupAreaId: params?.groupAreaId,
    //       segment: params?.segment,
    //     },
    //   }
    // );
  } catch (error) {
    console.log("getUserVsCustomerGraph", error);
    return { barGraphData: [], accumulatedGraphData: [] };
  }
};

export const getListAllUsers = async (params: {
  year: string;
  activeDateStart?: Date;
  activeDateEnd?: Date;
  groupAreaId?: string;
  segment?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListAllUsers>(
      "/dashboard/all-users",
      {
        params: {
          year: params.year,
          activeDateStart: params?.activeDateStart,
          activeDateEnd: params?.activeDateEnd,
          groupAreaIds: params?.groupAreaId,
          segments: params?.segment,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListAllUsers", error);
    return null;
  }
};

export const getExportListAllUsers = async (params: {
  year: string;
  activeDateStart?: Date;
  activeDateEnd?: Date;
  groupAreaId?: string;
  segment?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListAllUsers>(
      "/dashboard/all-users/export",
      {
        params: {
          year: params.year,
          activeDateStart: params?.activeDateStart,
          groupAreaId: params?.groupAreaId,
          segment: params?.segment,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getExportListAllUsers", error);
    return null;
  }
};

export const getListUsersVsCustomer = async (params: {
  year: string;
  activeDateStart?: Date;
  activeDateEnd?: Date;
  farmerType?: string;
  groupAreaIds?: string;
  segments?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListUsersVsCustomer>(
      "/dashboard/all-users/users-vs-customers",
      {
        params: {
          year: params.year,
          activeDateStart: params?.activeDateStart,
          activeDateEnd: params?.activeDateEnd,
          farmerType: params?.farmerType,
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListUsersVsCustomer", error);
    return null;
  }
};

export const getExportListUsersVsCustomer = async (params: {
  year: string;
  activeDateStart?: Date;
  activeDateEnd?: Date;
  farmerType?: string;
  groupAreaIds?: string;
  segment?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListUsersVsCustomer>(
      "/dashboard/all-users/users-vs-customers/export",
      {
        params: {
          year: params.year,
          activeDateStart: params?.activeDateStart,
          activeDateEnd: params?.activeDateEnd,
          farmerType: params?.farmerType,
          groupAreaId: params?.groupAreaIds,
          segment: params?.segment,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getExportListUsersVsCustomer", error);
    return null;
  }
};
