import { useState } from "react";
import { getSaleLeadTarget } from "../services/saleTarget.service";
import { ISaleTargetSaleLeadRow } from "../types/SaleTargetTable.type";
import { TUseSaleTargetSaleLead } from "./useSaleTargetSaleLead.hook";

export const useSaleTargetSaleLeadTableHook = (
  saleTargetHook: TUseSaleTargetSaleLead
) => {
  const [list, setList] = useState<ISaleTargetSaleLeadRow[]>([]);

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("page", saleTargetHook.page.toString());
    searchParams.append("limit", saleTargetHook.limit.toString());
    searchParams.append("direction", saleTargetHook.direction);
    searchParams.append("orderBy", saleTargetHook.order);
    searchParams.append("searchText", saleTargetHook.searchText);
    searchParams.append("province", saleTargetHook.province);
    searchParams.append("groupId", saleTargetHook.groupId);
    setList([]);
    const data = await getSaleLeadTarget(searchParams);
    if (data) {
      setList(data?.rows);
    }
  };

  return {
    list,
    handleFetch,
  };
};
