import React, { useState } from "react";
import MenuBar from "../../../components/MenuBar/MenuBar";
import { Divider, Tab, Tabs } from "@mui/material";
import { tw } from "../../../../utils/tw";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import PersonalInformation from "./FarmInformationTabs/PersonalInformation";
import FarmInformationTab from "./FarmInformationTabs/FarmInformationTab";
import PerformanceInformation from "./FarmInformationTabs/PerformanceInformation";
import CreditInformationTab from "./FarmInformationTabs/CreditInformationTab";
import { IFarmDetailTable } from "../types/farmDetailTable.type";
import { useTranslation } from "react-i18next";
type Props = {};

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  header: tw(`text-[#000] font-bold text-3xl font-sans`),
  paddingTop: tw(`p-10`),
  flexItemCenter: tw(`flex items-center`),
};

const FarmInformation = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const farmDetail: IFarmDetailTable = location.state;

  const [tabIndex, setTabIndex] = useState<number>(0);
  const onChangeTabIndex = (
    _event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };
  return (
    <MenuBar>
      <div className={classes.container}>
        <div className={classes.paddingTop}>
          <div className={classes.flexItemCenter}>
            <div onClick={() => navigate(-1)}>
              <ChevronLeftIcon fontSize="large" />
            </div>
            <span className={classes.header}>
              {farmDetail.customerId + " " + farmDetail.customerName}
            </span>
          </div>
          <Divider sx={{ paddingTop: "12px" }} />
          <div className="mt-4">
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              aria-label="farm-information-tab-control"
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "#68C184",
                    height: "3px",
                  },
                },
              }}
              sx={{
                height: "36px",
                minHeight: "unset",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#000000",
                      borderBottom: "3px solid #DDF3E4",
                      "& .Mui-selected": {
                        color: "#000000",
                      },
                    },
                  },
                },
              }}
            >
              <Tab label={t("farmDetail.personalInformation.title")} />
              <Tab label={t("farmDetail.farmInformation.title")} />
              <Tab label={t("farmDetail.performance.title")} />
              <Tab label={t("farmDetail.creditInformation.title")} />
            </Tabs>
          </div>
          <TabPanelCustom
            value={tabIndex}
            index={0}
            prefix="personal-information"
          >
            <PersonalInformation personalId={id} />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={1} prefix="farm-information">
            <FarmInformationTab farmId={id} />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={2} prefix="performance">
            <PerformanceInformation farmId={id} />
          </TabPanelCustom>
          <TabPanelCustom
            value={tabIndex}
            index={3}
            prefix="credit-information"
          >
            <CreditInformationTab farmId={id} />
          </TabPanelCustom>
        </div>
      </div>
    </MenuBar>
  );
};

export default FarmInformation;
