import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  SxProps,
  Theme,
} from "@mui/material";
import { useEffect, useState } from "react";
import ButtonFillCustom from "../Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../Button/ButtonOutlinedCustom";
import TextAreaCustom from "../Input/TextAreaCustom";
import { useTranslation } from "react-i18next";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "548px",
  bgcolor: "background.paper",
  boxShadow: 12,
  padding: "24px",
  borderRadius: "12px",
};

interface IConfirmCreditModalProps {
  open: boolean;
  isLending: boolean;
  onClose: () => void;
  onSubmit: (reason: string) => Promise<void>;
}
function ConfirmCreditModal({
  isLending,
  open,
  onClose,
  onSubmit,
}: IConfirmCreditModalProps) {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");
  useEffect(() => {
    if (open) {
      setReason("");
    }
  }, [open]);
  return (
    <Modal
      aria-labelledby="confirm-credit-modal-title"
      aria-describedby="confirm-credit-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="w-full h-full">
            <div className="flex flex-row justify-between items-center">
              <span className="font-sans font-bold text-[1rem] text-[#191919]">
                {isLending
                  ? t(
                      "saleOrder.creditHoldOrdersTab.modal.approveCreditHoldOrder"
                    )
                  : "ออเดอร์ส่งขออนุมัติเครดิต"}
              </span>
              <IconButton
                aria-label="close-modal"
                size="small"
                onClick={onClose}
              >
                <CloseRoundedIcon
                  sx={{
                    fontSize: "24px",
                    color: "#191919",
                  }}
                />
              </IconButton>
            </div>
            <div className="pt-6 flex flex-col">
              <span className="font-sans font-medium text-[14px] leading-5 text-[#191919] pb-2">
                {t("saleOrder.creditHoldOrdersTab.modal.reason")}
              </span>
              <TextAreaCustom
                placeholder="กรอก"
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
              <div className="w-full flex justify-end pt-[2px]">
                <span className="font-normal text-[12px] text-[#979797]">
                  (maximum 500 characters)
                </span>
              </div>
            </div>
            <div className="flex w-full justify-end gap-2 pt-6">
              <ButtonOutlinedCustom
                title={t("common.button.cancel")}
                btnBorderColor="#68C184"
                btnTextColor="#68C184"
                onClick={onClose}
              />
              <ButtonFillCustom
                title={t("common.button.confirm")}
                disabled={!reason}
                onClick={() => onSubmit(reason)}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default ConfirmCreditModal;
