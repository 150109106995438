import React from "react";

import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";

import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useInvoiceSearchFilter } from "../../../../../hooks/useInvoiceSearchFilter.hook";

import { useTranslation } from "react-i18next";
import {
  getListExportSuccessManager,
  getSuccessInvoiceManager,
} from "../../../../../services/admin/invoice/invoiceManager.service";
import { IRowSuccessInvoice } from "../../../../../types/invoice/invoiceManager.type";
import { thousandComma } from "../../../../../utils/common/string";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import DocumentModal from "../../../../components/Modal/DocumentModal/DocumentModal";
import { FilterInvoice } from "../../components/FilterInvoice";
import { IInvoiceTable, TInvoiceStatus } from "../../type";

type Props = { handleFetchCount: () => void };

const SuccessInvoicePanel = ({ handleFetchCount }: Props) => {
  const { t } = useTranslation();
  const [itemSelected, setItemSelected] = React.useState<string[]>([]);
  //TODO : change type IUpdateDocumentInvoiceSaleTable
  const [orderInvoice, setOrderInvoice] = React.useState<IInvoiceTable[]>([]);
  const [count, setCount] = React.useState(0);
  const [invoiceLog, setInvoiceLog] = React.useState<IRowSuccessInvoice[]>([]);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<IInvoiceTable>();

  function createData(
    id: string,
    orderId: string,
    customerId: string,
    customerName: string,
    invoiceNumber: string,
    workSheetNumber: string,
    ReceiptNumber: string,
    amountToBePaid: number,
    paidAmount: number,
    receiveSuccessDate: string,
    paymentDueDate: string,
    updateDate: string,
    status: TInvoiceStatus
  ): IInvoiceTable {
    return {
      id,
      orderId: orderId ? orderId : "-",
      customerId: customerId ? customerId : "-",
      customerName: customerName ? customerName : "-",
      invoiceNumber: invoiceNumber ? invoiceNumber : "-",
      workSheetNumber: workSheetNumber ? workSheetNumber : "-",
      ReceiptNumber: ReceiptNumber ? ReceiptNumber : "-",
      amountToBePaid: amountToBePaid
        ? `${thousandComma(amountToBePaid, 2)} บาท`
        : "-",
      paidAmount: paidAmount ? `${thousandComma(paidAmount, 2)} บาท` : "-",
      receiveSuccessDate: receiveSuccessDate
        ? dayjs(receiveSuccessDate).format("DD/MM/YYYY")
        : "-",
      paymentDueDate: paymentDueDate
        ? dayjs(paymentDueDate).format("DD/MM/YYYY")
        : "-",
      updateDate: updateDate ? dayjs(updateDate).format("DD/MM/YYYY") : "-",
      status: status as TInvoiceStatus,
    };
  }

  const headCells: ITableCustomHeadCell<keyof IInvoiceTable>[] = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.orderNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.customerId"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.customerName"),
      width: "150px",
      isSorting: false,
    },

    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.invoiceNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "workSheetNumber",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.sheetNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "ReceiptNumber",
      numeric: false,
      disablePadding: false,
      label: "เลขที่ใบเสร็จ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "amountToBePaid",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.totalDueAmount"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "paidAmount",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.paidAmount"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "receiveSuccessDate",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.successfulInvoiceTab.listOfInvoice.productReceivedDate"
      ),
      width: "150px",
      isSorting: true,
    },
    {
      id: "paymentDueDate",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.paymentDueDate"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.lastUpdatedDate"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.status"),
      width: "100px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
  ];

  const OrderStatusMap: Record<
    TInvoiceStatus,
    { color: string; bgColor: string; label: string }
  > = {
    wait_for_update_document: {
      color: "",
      bgColor: "",
      label: "",
    },
    verifying_document: {
      color: "",
      bgColor: "",
      label: "",
    },
    overdue: {
      color: "",
      bgColor: "",
      label: "",
    },
    complete: {
      color: "#409261",
      bgColor: "#E9FFEF",
      label: "สำเร็จ",
    },
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = orderInvoice.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const MapOrderByWithFilter: {
    [K in keyof IInvoiceTable]?: string;
  } = {
    receiveSuccessDate: "farmer_received_date",
    updateDate: "updated_at",
  };

  const searchFilterInvoice = useInvoiceSearchFilter();

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchFilterInvoice.searchText);
    searchParams.append("page", searchFilterInvoice.page.toString());
    searchParams.append("limit", searchFilterInvoice.limit.toString());
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFilterInvoice.orderBy] ?? ""
    );
    searchParams.append("direction", searchFilterInvoice.direction);
    setOrderInvoice([]);

    const data = await getSuccessInvoiceManager(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row: any) => {
        return createData(
          row.id,
          row.orderNumber,
          row.farmerRegistrationId,
          row.farmerName,
          row.invoiceNumber,
          row.assignmentNumber,
          row.receiptNumber,
          row.totalPrice,
          row.totalPaid,
          "",
          row.dueDate,
          row.updateAt,
          row.status as TInvoiceStatus
        );
      });
      setCount(data.count);
      setOrderInvoice(newList);
      setInvoiceLog(data.rows.map((row) => row));
    }
    handleFetchCount();
  };

  React.useEffect(() => {
    handleFetch();
  }, [
    searchFilterInvoice.orderBy,
    searchFilterInvoice.direction,
    searchFilterInvoice.page,
  ]);

  const getExportExcelData = async () => {
    type TColumnName =
      | "หมายเลขสั่งซื้อ"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "เลข Invoice"
      | "เลขที่ใบงาน"
      | "เลขที่ใบเสร็จ"
      | "ยอดเงินที่ต้องชำระ"
      | "ยอดเงินที่ชำระเเล้ว"
      | "วันรับสินค้าสำเร็จ"
      | "วันครบกำหนดชำระ"
      | "วันที่อัปเดตล่าสุด"
      | "สถานะ";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getListExportSuccessManager(itemSelected);
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            หมายเลขสั่งซื้อ: row.orderNumber,
            รหัสลูกค้า: row.farmerRegistrationId,
            ชื่อลูกค้า: row.farmerName,
            "เลข Invoice": row.invoiceNumber,
            เลขที่ใบงาน: row.assignmentNumber,
            เลขที่ใบเสร็จ: row.receiptNumber,
            ยอดเงินที่ต้องชำระ: `${thousandComma(row.totalPrice, 2)} บาท`,
            ยอดเงินที่ชำระเเล้ว: `${thousandComma(row.totalPaid, 2)} บาท`,
            วันรับสินค้าสำเร็จ: dayjs(row.receiveDate).format("DD/MM/YYYY"),
            วันครบกำหนดชำระ: dayjs(row.dueDate).format("DD/MM/YYYY"),
            วันที่อัปเดตล่าสุด: dayjs(row.updatedAt).format("DD/MM/YYYY"),
            สถานะ: row.status as TInvoiceStatus,
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    console.log(data);
    const fileName = `processed_invoice_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const handleOpenModalDocument = (row: IInvoiceTable) => {
    setModalData(row);
    setIsOpen(true);
  };

  const handleCloseModalDocument = (isSuccess: boolean) => {
    setIsOpen(false);
    if (isSuccess) {
      handleFetch();
    }
  };

  return (
    <div className="w-full h-full pt-8">
      <FilterInvoice
        searchFilterInvoice={searchFilterInvoice}
        isDisplaySelect={false}
        onSearch={handleFetch}
        placeholder={t("invoice.successfulInvoiceTab.searchForInvoiceNumber")}
        itemSelected={itemSelected}
        onExport={handleExportExcel}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchFilterInvoice.direction}
          orderBy={searchFilterInvoice.orderBy}
          rowsPerPage={searchFilterInvoice.limit}
          rows={orderInvoice}
          itemsSelected={itemSelected}
          headerItems={headCells}
          page={1}
          onChangeOrderBy={(val) =>
            searchFilterInvoice.setOrderBy(val as keyof IInvoiceTable)
          }
          onChangeOrder={(val) => searchFilterInvoice.setDirection(val)}
          onChangePage={(val) => searchFilterInvoice.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={orderInvoice.map((row) => row.id)}
          rowChildren={
            <>
              {orderInvoice.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          console.info("I'm a button.");
                        }}
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.workSheetNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.ReceiptNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.amountToBePaid}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paidAmount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.receiveSuccessDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paymentDueDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.updateDate}
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        <ChipOrderStatus
                          bgColor={
                            OrderStatusMap[row.status as TInvoiceStatus].bgColor
                          }
                          fontColor={
                            OrderStatusMap[row.status as TInvoiceStatus].color
                          }
                          label={
                            OrderStatusMap[row.status as TInvoiceStatus].label
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        onClick={() => handleOpenModalDocument(row)}
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {t(
                          "invoice.successfulInvoiceTab.listOfInvoice.viewDocuments"
                        )}
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <DocumentModal
        handleModalClose={handleCloseModalDocument}
        isOpen={isOpen}
        data={modalData}
      />
    </div>
  );
};

export default SuccessInvoicePanel;
