import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import RHFSelectCustom from "../../../../../components/Select/RHFSelectCustom";
import { useMedicineFilterHook } from "../../../hooks/medicine/useMedicineFilter.hook";
import { useMedicineTableHook } from "../../../hooks/medicine/useMedicineTable.hook";
import { createMedicine } from "../../../services/medicine.service";
import {
  TCreateMedicineForm,
  selectUintMedicine,
} from "../../../types/medicine.type";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleFetch: () => void;
};

const CreateVaccineModal = ({ isOpen, onClose, handleFetch }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const schema = Yup.object().shape({
    //Todo : add validation
    tradeName: Yup.string().required("กรุณากรอก"),
    tradeNameEn: Yup.string().required("กรุณากรอก"),
    medicineCategoryId: Yup.string().required("กรุณาเลือก"),
    unitSelect: Yup.string().required("กรุณาเลือก"),
  });

  const methods = useForm<TCreateMedicineForm>({
    defaultValues: {
      tradeName: "",
      tradeNameEn: "",
      medicineCategoryId: "",
      categoryName: "",
      categoryNameEn: "",
      unit: "",
      unitSelect: "",
    },
    resolver: yupResolver<TCreateMedicineForm>(schema as any),
  });

  const handleSubmit = (value: TCreateMedicineForm) => {
    const payload = {
      medicineCategoryId:
        value.medicineCategoryId === "90" ? "" : value.medicineCategoryId,
      tradeName: value.tradeName,
      tradeNameEn: value.tradeNameEn,
      categoryName: value.categoryName,
      categoryNameEn: value.categoryNameEn,
      unit: value.unitSelect
        ? value.unitSelect === "อื่นๆ"
          ? value.unit
          : value.unitSelect
        : "",
    };

    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await createMedicine(payload);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleFetch();
            onClose();
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const searchMedicine = useMedicineFilterHook();
  const { categoryMedicine, handleFetchCategory } =
    useMedicineTableHook(searchMedicine);

  const selectMedicine = [
    ...(categoryMedicine || []),
    { id: "90", nameTh: "อื่นๆ", nameEn: "other", isActive: "false" },
  ];

  const handleOnChangeSelect = (id: string) => {
    const selectedMedicine = selectMedicine.find(
      (medicine) => medicine.id === id
    );

    if (
      selectedMedicine?.nameTh === "อื่นๆ" &&
      selectedMedicine?.nameEn === "other"
    ) {
      methods.setValue("categoryName", "");
      methods.setValue("categoryNameEn", "");
      methods.setValue("medicineCategoryId", id);
    } else {
      methods.setValue("categoryName", selectedMedicine?.nameTh || "");
      methods.setValue("categoryNameEn", selectedMedicine?.nameEn || "");
      methods.setValue("medicineCategoryId", selectedMedicine?.id || "");
    }
  };

  useEffect(() => {
    methods.reset();
    handleFetchCategory();
  }, [isOpen]);

  return (
    <>
      <AdminModal
        label={t("masterData.medicine.addMedicineModal.title")}
        isOpen={isOpen}
        size={"sm"}
        handleClose={onClose}
        children={
          <>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <div className="my-4">
                  <p className="text-[14px] mb-3 font-sans">
                    {t(
                      "masterData.medicine.addMedicineModal.categoryOfMedicine"
                    )}{" "}
                    <span className="text-error-300">*</span>
                  </p>
                  <RHFSelectCustom
                    value={methods.watch("medicineCategoryId")}
                    controlName="medicineCategoryId"
                    placeholder="เลือก"
                    menuItems={(selectMedicine || []).map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.nameTh}
                      </MenuItem>
                    ))}
                    renderValueArray={(selectMedicine || []).map((row) => ({
                      value: row.id,
                      label: row.nameTh,
                    }))}
                    error={Boolean(methods.formState.errors.medicineCategoryId)}
                    helperText={
                      methods.formState.errors.medicineCategoryId?.message || ""
                    }
                    onChange={(e) =>
                      handleOnChangeSelect(e.target.value as string)
                    }
                  />
                </div>
                <div className="flex gap-4">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-3 font-sans">
                      {t(
                        "masterData.medicine.addMedicineModal.categoryOfMedicine"
                      )}{" "}
                      (TH) <span className="text-error-300">*</span>
                    </p>
                    <div className="w-full pb-3 font-sans">
                      <RHFTextInputCustom
                        disabled={methods.watch("medicineCategoryId") !== "90"}
                        controlName="categoryName"
                        placeholder="กรอก"
                        error={Boolean(methods.formState.errors.categoryName)}
                        helperText={
                          methods.formState.errors.categoryName?.message || ""
                        }
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-3 font-sans">
                      {t(
                        "masterData.medicine.addMedicineModal.categoryOfMedicine"
                      )}{" "}
                      (EN) <span className="text-error-300">*</span>
                    </p>
                    <div className="w-full pb-3 font-sans">
                      <RHFTextInputCustom
                        disabled={methods.watch("medicineCategoryId") !== "90"}
                        controlName="categoryNameEn"
                        placeholder="กรอก"
                        error={Boolean(methods.formState.errors.categoryNameEn)}
                        helperText={
                          methods.formState.errors.categoryNameEn?.message || ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-3 font-sans">
                      {t("masterData.medicine.addMedicineModal.medicineName")}{" "}
                      (TH) <span className="text-error-300">*</span>
                    </p>
                    <div className="w-full pb-3 font-sans">
                      <RHFTextInputCustom
                        controlName="tradeName"
                        placeholder="กรอก"
                        error={Boolean(methods.formState.errors.tradeName)}
                        helperText={
                          methods.formState.errors.tradeName?.message || ""
                        }
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-3 font-sans">
                      {t("masterData.medicine.addMedicineModal.medicineName")}{" "}
                      (EN) <span className="text-error-300">*</span>
                    </p>
                    <div className="w-full pb-3 font-sans">
                      <RHFTextInputCustom
                        controlName="tradeNameEn"
                        placeholder="กรอก"
                        error={Boolean(methods.formState.errors.tradeNameEn)}
                        helperText={
                          methods.formState.errors.tradeNameEn?.message || ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-3 font-sans">
                      {t("masterData.medicine.addMedicineModal.units")}{" "}
                      <span className="text-error-300">*</span>
                    </p>
                    <div className="w-full pb-3 font-sans">
                      <RHFSelectCustom
                        value={methods.watch("unitSelect")}
                        controlName="unitSelect"
                        placeholder="เลือก"
                        menuItems={(selectUintMedicine || []).map((row) => (
                          <MenuItem key={row} value={row}>
                            {row}
                          </MenuItem>
                        ))}
                        renderValueArray={(selectUintMedicine || []).map(
                          (row) => ({
                            value: row,
                            label: row,
                          })
                        )}
                        error={Boolean(
                          methods.formState.errors.medicineCategoryId
                        )}
                        helperText={
                          methods.formState.errors.medicineCategoryId
                            ?.message || ""
                        }
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-3 font-sans">
                      ชื่อหน่วย <span className="text-error-300">*</span>
                    </p>
                    <div className="w-full pb-3 font-sans">
                      <RHFTextInputCustom
                        disabled={methods.watch("unitSelect") !== "อื่นๆ"}
                        controlName="unit"
                        placeholder="กรอก"
                        error={Boolean(methods.formState.errors.unit)}
                        helperText={
                          methods.formState.errors.unit?.message || ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <ButtonFillCustom
                    title={t("common.button.confirm")}
                    type="submit"
                  />
                </div>
              </form>
            </FormProvider>
          </>
        }
      ></AdminModal>
    </>
  );
};

export default CreateVaccineModal;
