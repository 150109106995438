import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import OrderFeedSVG from "../../assets/svg/OrderFeed";
import OrderHistorySVG from "../../assets/svg/OrderHistory";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";

export default function Stock() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="h-screen overflow-auto flex flex-col bg-[#ffff]">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate("/partner")}
          title={t("OrderStock.Stock")}
        />
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={t("OrderStock.LandingPage")}
            subTitle={t("OrderStock.LandingPageDetail")}
            icon={<OrderFeedSVG />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={() => navigate("/stock/upcoming-stock")}
          />
        </div>
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={t("OrderStock.StockHistory")}
            subTitle={t("OrderStock.StockHistoryDetail")}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={() => navigate("/stock/stock-history")}
          />
        </div>
      </div>
    </div>
  );
}
