export default function () {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.664062" width="40" height="40" rx="20" fill="#3777BC" />
      <path
        d="M13.6563 8.11719C13.5438 8.225 13.5391 8.25312 13.5391 8.86719V9.5H20.6641H27.7891V8.87656C27.7891 8.31406 27.7797 8.24375 27.6953 8.15C27.5078 7.95312 27.3953 7.9625 26.8188 8.25312L26.2891 8.51562L25.7734 8.25781C25.4922 8.11719 25.2156 8 25.1641 8C25.1125 8 24.8359 8.11719 24.5547 8.25781L24.0391 8.51562L23.5234 8.25781C23.2422 8.11719 22.9656 8 22.9141 8C22.8625 8 22.5859 8.11719 22.3047 8.25781L21.7891 8.51562L21.2734 8.25781C20.9922 8.11719 20.7156 8 20.6641 8C20.6125 8 20.3359 8.11719 20.0547 8.25781L19.5391 8.51562L19.0234 8.25781C18.7422 8.11719 18.4656 8 18.4141 8C18.3625 8 18.0859 8.11719 17.8047 8.25781L17.2891 8.51562L16.7734 8.25781C16.4922 8.11719 16.2156 8 16.1641 8C16.1125 8 15.8359 8.11719 15.5547 8.25781L15.0391 8.51562L14.5234 8.25781C14.2422 8.11719 13.9563 8 13.8906 8C13.825 8 13.7172 8.05156 13.6563 8.11719Z"
        fill="white"
      />
      <path
        d="M26.9257 11.0569C25.8945 12.2944 25.5664 12.7584 25.2382 13.4147C24.7789 14.3334 24.8164 13.785 24.8023 19.6959C24.7882 23.1928 24.8023 25.0022 24.8351 25.2225C24.9804 26.1787 25.407 26.9381 26.7148 28.5881C27.1179 29.0897 27.5117 29.6006 27.6007 29.7178C27.6851 29.8397 27.7695 29.9381 27.7836 29.9334C27.7976 29.9334 27.9007 29.8116 28.0039 29.6662C28.1117 29.5209 28.5101 29.0381 28.8945 28.6022C29.2742 28.1616 29.7007 27.6506 29.8414 27.4584C30.1742 27.0084 30.5211 26.3522 30.657 25.9069L30.7648 25.5553L30.7789 20.2022C30.7929 14.2772 30.8023 14.535 30.4882 13.8037C30.2961 13.3584 30.0101 12.8709 29.6023 12.3037C29.2742 11.8491 27.8257 10.0631 27.7836 10.0631C27.7695 10.0631 27.3804 10.5131 26.9257 11.0569Z"
        fill="white"
      />
      <path
        d="M12.8547 10.9672C11.8516 12.2234 11.3547 13.0578 11.0687 13.9766L10.9375 14.3984V19.8828V25.3672L11.0687 25.7891C11.2141 26.2672 11.5328 26.9562 11.8141 27.4062C11.9172 27.575 12.3297 28.1703 12.7328 28.7328L13.4641 29.75H20.0641C25.3375 29.75 26.6641 29.7359 26.6359 29.6891C26.6125 29.6609 26.3125 29.2719 25.9609 28.8359C25.0047 27.6406 24.5594 26.9141 24.2922 26.1266C24.0391 25.3812 24.0391 25.4187 24.0391 19.8641C24.0437 14.2531 24.0344 14.3891 24.3203 13.6203C24.6203 12.7859 25.2344 11.8812 26.4531 10.4469L26.6266 10.25H20.0219L13.4219 10.2547L12.8547 10.9672ZM13.2156 14.9562C13.6422 15.1062 13.9937 15.35 14.3828 15.7672L14.7109 16.1187L15.0953 15.9547C16.5109 15.3547 18.0906 15.3547 19.4734 15.95L19.8625 16.1187L20.2984 15.6781C20.65 15.3266 20.8094 15.2 21.1094 15.05C21.4703 14.8719 21.4984 14.8672 21.9625 14.8672C22.3984 14.8672 22.4453 14.8766 22.5578 14.9797C22.7828 15.1906 22.8859 15.5562 22.8859 16.1328C22.8906 16.8266 22.7453 17.2344 22.2016 18.0922C22.0422 18.3359 21.9766 18.4812 22 18.5375C22.0187 18.5797 22.0562 18.7578 22.0937 18.9312L22.1547 19.25H21.5359C20.8937 19.2547 20.8234 19.2734 20.7156 19.475C20.6406 19.6156 20.6828 19.7797 20.8141 19.9062C20.9078 19.9906 20.9781 20 21.5594 20H22.2016L22.4172 20.2391C22.7172 20.5719 22.8672 20.9 22.9 21.3031C22.9937 22.4 22.2812 23.3984 21.2031 23.6797C20.9641 23.7406 20.4109 23.75 17.2891 23.7453C14.0125 23.7453 13.6281 23.7359 13.3469 23.6656C12.0672 23.3281 11.3125 21.8516 11.8469 20.7266C11.9219 20.5719 12.0766 20.3469 12.1984 20.2203L12.4141 20H13.0469C13.6609 20 13.6891 19.9953 13.7969 19.8828C13.9516 19.7328 13.9516 19.5172 13.7969 19.3672C13.6891 19.2547 13.6609 19.25 13.0703 19.25C12.3719 19.25 12.4141 19.2875 12.5359 18.7578L12.6156 18.4297L12.3578 18.0312C11.8422 17.2344 11.6875 16.8031 11.6922 16.1328C11.6922 15.5141 11.8609 15.0734 12.1562 14.9C12.3203 14.8062 12.8734 14.8344 13.2156 14.9562Z"
        fill="white"
      />
      <path
        d="M12.4906 15.6641C12.3266 16.1 12.5047 16.9109 12.8938 17.4875L13.0109 17.6562L13.1563 17.4547C13.3391 17.2109 13.5969 16.9344 13.8641 16.7094L14.0656 16.5359L13.8922 16.3203C13.7938 16.2031 13.6109 16.0344 13.4844 15.9406C13.0906 15.65 12.5563 15.5 12.4906 15.6641Z"
        fill="white"
      />
      <path
        d="M21.5875 15.6641C21.4891 15.7016 21.3438 15.7766 21.2594 15.8328C21.0484 15.9781 20.5703 16.4469 20.5703 16.5078C20.5703 16.5359 20.7016 16.6766 20.8656 16.8219C21.025 16.9625 21.25 17.2109 21.3672 17.3703L21.5781 17.6562L21.6578 17.5531C21.8031 17.3656 22.0563 16.7984 22.1172 16.5359C22.1734 16.2922 22.1594 15.7484 22.0984 15.6453C22.0516 15.575 21.8078 15.5844 21.5875 15.6641Z"
        fill="white"
      />
      <path
        d="M16.1001 18.5469C15.1814 18.7766 14.5298 19.7562 14.6985 20.6562C14.8157 21.3031 15.1954 21.8 15.7767 22.0766L16.0907 22.2266H17.2626C18.5798 22.2266 18.7204 22.1984 19.147 21.875C20.1595 21.1156 20.1595 19.6391 19.1517 18.875C18.7251 18.5516 18.6079 18.5281 17.3798 18.5187C16.7751 18.5094 16.1985 18.5234 16.1001 18.5469ZM16.7939 20.1172C17.122 20.4406 16.6485 20.9703 16.311 20.6562C16.1798 20.5297 16.1376 20.3656 16.2126 20.225C16.3439 19.9766 16.6064 19.925 16.7939 20.1172ZM18.2939 20.1172C18.622 20.4406 18.1485 20.9703 17.811 20.6562C17.6798 20.5297 17.6376 20.3656 17.7126 20.225C17.8439 19.9766 18.1064 19.925 18.2939 20.1172Z"
        fill="white"
      />
      <path
        d="M13.5391 31.1234C13.5391 31.6859 13.5484 31.7562 13.6328 31.85C13.8203 32.0469 13.9328 32.0375 14.5094 31.7469L15.0391 31.4844L15.5547 31.7422C15.8359 31.8828 16.1125 32 16.1641 32C16.2156 32 16.4922 31.8828 16.7734 31.7422L17.2891 31.4844L17.8047 31.7422C18.0859 31.8828 18.3625 32 18.4141 32C18.4656 32 18.7422 31.8828 19.0234 31.7422L19.5391 31.4844L20.0547 31.7422C20.3359 31.8828 20.6125 32 20.6641 32C20.7156 32 20.9922 31.8828 21.2734 31.7422L21.7891 31.4844L22.3047 31.7422C22.5859 31.8828 22.8625 32 22.9141 32C22.9656 32 23.2422 31.8828 23.5234 31.7422L24.0391 31.4844L24.5547 31.7422C24.8359 31.8828 25.1125 32 25.1641 32C25.2156 32 25.4922 31.8828 25.7734 31.7422L26.2891 31.4844L26.8188 31.7469C27.3953 32.0375 27.5078 32.0469 27.6953 31.85C27.7797 31.7562 27.7891 31.6859 27.7891 31.1234V30.5H20.6641H13.5391V31.1234Z"
        fill="white"
      />
    </svg>
  );
}
