import { Checkbox, Divider, Link, TableCell, TableRow } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MenuBar from "../../../components/MenuBar/MenuBar";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../components/Table/TableCustom";
import { FarmDetailFilter } from "../components/FarmDetailFilter";
import { useFarmDetailFilterHook } from "../hooks/useFarmDetailFilter.hook";
import { useFarmDetailTableHook } from "../hooks/useFarmDetailTable.hook";
import { IFarmDetailTable } from "../types/farmDetailTable.type";

const FarmDetail = () => {
  const { t } = useTranslation();
  const searchFarmDetail = useFarmDetailFilterHook();
  const {
    count,
    list,
    itemSelected,
    handleClick,
    handleSelectAllClick,
    setItemSelected,
    isSelected,
    handleFetch,
    handleExportExcel,
  } = useFarmDetailTableHook(searchFarmDetail);

  const navigate = useNavigate();

  const headCells: ITableCustomHeadCell<keyof IFarmDetailTable>[] = [
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: t("farmDetail.customerId"),
      width: "100px",
      isSorting: true,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t("farmDetail.customerName"),
      width: "100px",
      isSorting: false,
    },
    {
      id: "segment",
      numeric: false,
      disablePadding: false,
      label: t("farmDetail.segment"),
      width: "100px",
      isSorting: false,
    },
    {
      id: "farmerType",
      numeric: false,
      disablePadding: false,
      label: t("farmDetail.type"),
      width: "100px",
      isSorting: false,
    },
    {
      id: "farmSize",
      numeric: false,
      disablePadding: false,
      label: t("farmDetail.farmSize"),
      width: "100px",
      isSorting: false,
    },
    {
      id: "farmAmount",
      numeric: false,
      disablePadding: false,
      label: t("farmDetail.numberOfFarms"),
      width: "100px",
      isSorting: false,
    },
    {
      id: "houseAmount",
      numeric: false,
      disablePadding: false,
      label: t("farmDetail.numberOfBarns"),
      width: "100px",
      isSorting: false,
    },
    {
      id: "houseAmount",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "60px",
      isSorting: false,
    },
    {
      id: "houseAmount",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "60px",
      isSorting: false,
    },
  ];

  useEffect(() => {
    handleFetch();
  }, [searchFarmDetail.direction, searchFarmDetail.page]);

  return (
    <MenuBar>
      <div className="pt-16 w-full h-full relative">
        <div className="p-10">
          <span className="text-[#000] font-bold text-3xl">
            {t("farmDetail.title")}
          </span>
          <Divider sx={{ paddingTop: "12px" }} />
          <div className="mt-6">
            <FarmDetailFilter
              searchFarmDetail={searchFarmDetail}
              itemSelected={itemSelected}
              onExport={() => {}}
              onSearch={handleFetch}
            />
          </div>
          <div className="flex w-full pt-6">
            <TableCustom
              order={searchFarmDetail.direction}
              orderBy={"customerId"}
              rowsPerPage={10}
              rows={list}
              itemsSelected={itemSelected}
              headerItems={headCells}
              page={searchFarmDetail.page}
              onChangeOrderBy={() => {}}
              onChangeOrder={(val) => {
                searchFarmDetail.setDirection(val);
              }}
              onChangePage={(val) => searchFarmDetail.setPage(val)}
              onSelectAllClick={(val) => handleSelectAllClick(val)}
              onSelectItem={(val) => setItemSelected(val as string[])}
              totalItemSize={count}
              currentPageItems={list.map((row) => row.id)}
              rowChildren={
                <>
                  {list.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            onClick={(event) => handleClick(event, row.id)}
                            sx={{
                              borderWidth: "1px",
                              color: "#C9C9C9",
                              "&.Mui-checked": {
                                color: "#68C184",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <Link
                            component="button"
                            variant="body2"
                            sx={{
                              color: "#68C184",
                              textDecorationColor: "#68C184",
                            }}
                          >
                            {row.customerId}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.customerName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.segment}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.farmerType}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.farmSize}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.farmAmount}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.houseAmount}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <Link
                            component="button"
                            variant="body2"
                            sx={{
                              color: "#68C184",
                              textDecorationColor: "#68C184",
                            }}
                            onClick={() => handleExportExcel(row.id)}
                          >
                            {t("farmDetail.dailyLog")}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() =>
                              navigate("/admin/farm/detail/" + row.id, {
                                state: list[index],
                              })
                            }
                            sx={{
                              color: "#68C184",
                              textDecorationColor: "#68C184",
                            }}
                          >
                            {t("farmDetail.seeMore")}
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              }
            ></TableCustom>
          </div>
        </div>
      </div>
    </MenuBar>
  );
};

export default FarmDetail;
