import { Modal } from "@mui/material";
import { get } from "lodash";
import { Dispatch, SetStateAction, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ButtonFillCustom from "../../../../admin/components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../admin/components/Button/ButtonOutlinedCustom";
import { TDropdownValue } from "../../../../admin/pages/group-area/views/Dropdown";
import BellSvg from "../../../../assets/svg/BellSvg";
import CloudUploadSvg from "../../../../assets/svg/CloudUploadSvg";
import FenceSVG from "../../../../assets/svg/Fence";
import AppBarFS from "../../../../components/app-bar/AppBar";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import { completeAssignment } from "../../../../services/Invoice.service";
import {
  IGetDetailsAcceptedAssignment,
  RowAccepted,
} from "../../../../types/Invoice.type";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { getCommaNumber } from "../../../../utils/number";
import { tw } from "../../../../utils/tw";
import Dialog from "../../../order-history/views/Dialog";
import Table from "./Table";
import InvoiceTrackingFailModal from "./views/InvoiceTrackingFailModal";
import UpdateListInvoiceModal from "./views/UpdateListInvoiceModal";
import VerifyNotifyModal from "./views/VerifyNotifyModal";

interface IDetailsAcceptedAssignmentModal {
  isOpen: boolean;
  onClose: () => void;
  onShowButton?: boolean;
  InvoiceOption?: TDropdownValue[];
  invoice?: TDropdownValue[];
  setInvoice?: Dispatch<SetStateAction<TDropdownValue[]>>;
  selectedId?: string;
  RefetchUpdateAcceptedAssignment?: () => void;
  RefetchDetailsAcceptedAssignment?: () => void;
  refetchAcceptedAssignment?: () => void;
  DetailsAcceptedAssignment?: IGetDetailsAcceptedAssignment;
  HistoryAssignment?: RowAccepted;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`w-full h-full bg-[white] relative`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(
    `flex flex-col w-full h-auto pt-[16px] gap-[16px] px-[16px]`
  ),
  buttonContainer: tw(``),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  text1: tw(`font-semibold text-[20px] text-[#3777BC]`),
  text2: tw(`font-semibold text-[16px] text-[#34407B]`),
  text3: tw(`font-medium text-[14px] text-[#4B4B4B]`),
};

const DetailsAcceptedAssignmentModal = (
  props: IDetailsAcceptedAssignmentModal
) => {
  const {
    isOpen,
    onClose,
    onShowButton,
    InvoiceOption,
    invoice,
    setInvoice,
    selectedId,
    RefetchUpdateAcceptedAssignment,
    RefetchDetailsAcceptedAssignment,
    refetchAcceptedAssignment,
    DetailsAcceptedAssignment,
    HistoryAssignment,
  } = props;

  const dispatch = useDispatch<AppDispatch>();

  const [results, setResults] = useState<boolean[]>(
    (DetailsAcceptedAssignment &&
      DetailsAcceptedAssignment.result.invoices.map(
        (t) => t.totalPrice - t.totalPaid === 0
      )) ||
      []
  );

  const allTrue = results.every((result) => result);

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const onShowConfirm = useCallback(() => {
    setIsShowConfirmModal(true);
  }, []);

  const onCloseConfirm = useCallback(() => {
    setIsShowConfirmModal(false);
  }, []);

  const [isShowUpdateList, setIsShowUpdateList] = useState(false);

  const onShowUpdateList = useCallback(() => {
    setIsShowUpdateList(true);
  }, []);

  const onCloseUpdateList = useCallback(() => {
    setIsShowUpdateList(false);
  }, []);

  const [isShowVerifyNotify, setIsShowVerifyNotify] = useState(false);

  const onShowVerifyNotify = useCallback(() => {
    setIsShowVerifyNotify(true);
  }, []);

  const onCloseVerifyNotify = useCallback(() => {
    setIsShowVerifyNotify(false);
  }, []);

  const [isShowTrackingFail, setIsShowTrackingFail] = useState(false);

  const onShowTrackingFail = useCallback(() => {
    setIsShowTrackingFail(true);
  }, []);

  const onCloseTrackingFail = useCallback(() => {
    setIsShowTrackingFail(false);
  }, []);

  const { t } = useTranslation();

  const onTrackingSussesAssignment = useCallback(async () => {
    dispatch(loadingActions.show({ message: t("reportOrder.recording") }));
    try {
      Promise.all([
        completeAssignment({
          assignmentId: selectedId || "",
          isComplete: true,
          reason: "",
        }),
      ]);

      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: t("MoneyDebtAndCollection.Successful"),
            type: "success",
          })
        );
        refetchAcceptedAssignment && refetchAcceptedAssignment();
      }, 1500);
    } catch (e) {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(e),
            type: "error",
          })
        );
      }, 1500);
    }
  }, [selectedId]);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS
            title={`${
              get(HistoryAssignment, ["farmerName"], "") ||
              get(DetailsAcceptedAssignment, ["result", "farmerName"], "")
            }`}
            onIconPress={onClose}
          />
        </div>
        <div className={classes.detailContainer}>
          <div className="flex flex-row items-center justify-between">
            <div className={classes.text1}>
              {t("MoneyDebtAndCollection.AllDebt")}
            </div>
            {onShowButton && (
              <ButtonOutlinedCustom
                title="อัปเดตรายการ"
                className={tw(classes.button, "!h-[37px] w-auto")}
                onClick={onShowUpdateList}
                btnBgColor="#FFFFFF"
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                startIcon={<CloudUploadSvg />}
              />
            )}
          </div>
          <div className="flex flex-row justify-between">
            <div className={tw(classes.text1, "text-[#34407B]")}>
              {t("MoneyDebtAndCollection.TotalDebt")}
            </div>
            <div
              className={tw(
                classes.text1,
                "text-[18px] text-[#34407B] font-bold"
              )}
            >
              {getCommaNumber(
                get(HistoryAssignment, ["totalPrice"], 0) ||
                  get(DetailsAcceptedAssignment, ["result", "totalPrice"], 0),
                true
              )}{" "}
              {t("reportOrder.currency")}
            </div>
          </div>
          <div className="bg-[#F6FBFF] py-3 border-[2px] border-[#3777BC] rounded-[20px] flex flex-col gap-4 items-center">
            <div className="font-bold text-[16px] text-[#3777BC]">
              {t("FinancialSupport.PaidAmount")}
            </div>
            <div className="font-bold text-[22px] text-[#34407B]">
              {getCommaNumber(
                get(HistoryAssignment, ["totalPaid"], 0) ||
                  get(DetailsAcceptedAssignment, ["result", "totalPaid"], 0),
                true
              )}{" "}
              {t("reportOrder.currency")}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row gap-2">
              <FenceSVG />
              <div className={classes.text2}>
                {get(HistoryAssignment, ["assignmentNumber"], "") ||
                  get(
                    DetailsAcceptedAssignment,
                    ["result", "assignmentNumber"],
                    ""
                  )}
              </div>
            </div>
            {onShowButton && (
              <ButtonOutlinedCustom
                title={t("MoneyDebtAndCollection.NotifyToLending")}
                className={tw(classes.button, "!h-[37px] w-auto")}
                onClick={onShowVerifyNotify}
                btnBgColor="#FFFFFF"
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                startIcon={<BellSvg />}
              />
            )}
          </div>
          <Table
            data={
              (HistoryAssignment && HistoryAssignment.invoices) ||
              (DetailsAcceptedAssignment &&
                DetailsAcceptedAssignment.result.invoices) ||
              []
            }
          />
        </div>
        {onShowButton && (
          <div className="absolute top-[684px] px-4 w-full flex flex-row gap-2">
            <ButtonOutlinedCustom
              title={t("MoneyDebtAndCollection.Unsuccessful")}
              className={classes.button}
              onClick={onShowTrackingFail}
              btnBgColor="#FFFFFF"
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              disabled={!allTrue}
            />
            <ButtonFillCustom
              title={t("MoneyDebtAndCollection.Successful")}
              className={classes.button}
              onClick={onShowConfirm}
              disabled={allTrue}
            />
          </div>
        )}
        <Dialog
          isOpen={isShowConfirmModal}
          withoutCloseIcon
          title={t("MoneyDebtAndCollection.receiveSuccess")}
          text={`${t("MoneyDebtAndCollection.ConfirmReceive")}?\n${t(
            "FinancialSupport.ConfirmEvidenceTitleTwo"
          )}`}
          confirmText={t("MoneyDebtAndCollection.Confirm")}
          cancelText={t("MoneyDebtAndCollection.Cancel")}
          onClose={onCloseConfirm}
          onConfirm={onTrackingSussesAssignment}
          onCancel={onCloseConfirm}
        />
        <UpdateListInvoiceModal
          isOpen={isShowUpdateList}
          onCloseFn={onCloseUpdateList}
          InvoiceOption={InvoiceOption || []}
          invoice={invoice || []}
          selectedId={selectedId || ""}
          setInvoice={setInvoice || (() => [])}
          RefetchDetailsAcceptedAssignment={
            RefetchDetailsAcceptedAssignment || (() => {})
          }
        />
        <VerifyNotifyModal
          isOpen={isShowVerifyNotify}
          onCloseFn={onCloseVerifyNotify}
          InvoiceOption={InvoiceOption || []}
          invoice={invoice || []}
          selectedId={selectedId || ""}
          setInvoice={setInvoice || (() => [])}
          RefetchDetailsAcceptedAssignment={
            RefetchDetailsAcceptedAssignment || (() => {})
          }
        />
        <InvoiceTrackingFailModal
          isOpen={isShowTrackingFail}
          onCloseFn={onCloseTrackingFail}
          selectedId={selectedId || ""}
          onCloseDetails={onClose}
        />
      </div>
    </Modal>
  );
};

export default memo(DetailsAcceptedAssignmentModal);
