import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { tw } from "../../../../../../utils/tw";
import DateRangePickerPopover from "../../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import TextInputCustom from "../../../../../components/Input/TextInputCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import {
  acceptDept,
  closeDebtNotAcceptDebt,
} from "../../services/notAcceptDebt.service";
import { FILTER_DATE_FORMAT } from "../ExpandTableMoneyDebt/ExpandListLog";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  SvgIcon,
  SxProps,
  Theme,
} from "@mui/material";

import { default as CloseRoundedIcon } from "@mui/icons-material/CloseRounded";
import { CalendarSvg } from "../../../../../assets/svg/Calendar.svg";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import DatePickerPopover from "../../../../../components/Input/DatePickerPopover/DatePickerPopover";
import ExclamationCircle from "../../../../../../assets/svg/ExclamationCircle";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import { useTranslation } from "react-i18next";

export type TAcceptDebtForm = {
  paymentDate: string;
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  invoiceId: string;
  fetchData: () => void;
};

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "370px",
  bgcolor: "background.paper",
  boxShadow: 12,
  padding: "24px",
  borderRadius: "12px",
};

const AcceptDebtModal = ({ isOpen, onClose, invoiceId, fetchData }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [date, setDate] = useState("");
  const [dateFormat, setDateFormat] = useState("");

  const refCalendarDate =
    React.useRef<React.ElementRef<typeof DatePickerPopover>>(null);

  const handleSelectDate = (value?: Date) => {
    const formattedDate = dayjs(value).toISOString();
    const formattedDateShow = dayjs(value).format(FILTER_DATE_FORMAT);
    setDate(formattedDateShow);
    setDateFormat(formattedDate);
  };

  const handleSubmit = () => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await acceptDept(invoiceId, { paymentDate: dateFormat });
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "รับสภาพหนี้สำเร็จ",
              })
            );
            fetchData();
            onClose();
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  return (
    <div>
      <Modal
        aria-labelledby="confirm-order-delivery-modal-title"
        aria-describedby="confirm-order-delivery-modal-description"
        open={isOpen}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <div className="w-full h-full">
              <div className="flex items-center gap-1 mb-4">
                <SvgIcon sx={{ color: "#68C184" }}>
                  <ExclamationCircle />
                </SvgIcon>
                <span className="font-sans font-bold text-[18px] text-[#191919]">
                  ยืนยันการรับสภาพหนี้
                </span>
              </div>
              <span className="text-sm font-medium font-sans ">วันที่ชำระ</span>
              <div className="pt-2">
                <TextInputCustom
                  placeholder="เลือก"
                  value={date}
                  InputProps={{
                    endAdornment:
                      date !== "" ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(e) => {
                            setDate("");
                            e.stopPropagation();
                          }}
                        />
                      ) : (
                        <SvgIcon
                          sx={{
                            fontSize: "24px !important",
                            color: "#646464",
                          }}
                          fontSize="small"
                        >
                          <CalendarSvg />
                        </SvgIcon>
                      ),
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    refCalendarDate.current?.onOpen(e);
                  }}
                />
              </div>
            </div>
            <div className="flex w-full justify-end gap-2 mt-6">
              <ButtonOutlinedCustom
                title={t("common.button.cancel")}
                btnBorderColor="#68C184"
                btnTextColor="#68C184"
                onClick={onClose}
              />
              <ButtonFillCustom
                title={t("common.button.confirm")}
                onClick={handleSubmit}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
      <DatePickerPopover
        ref={refCalendarDate}
        id={"date-picker-date"}
        date={date}
        handleCalenderSave={handleSelectDate}
      />
    </div>
  );
};

export default AcceptDebtModal;
