import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Modal } from "@mui/material";
import axios from "axios";
import { isEmpty } from "lodash";
import { Dispatch, SetStateAction, memo, useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ButtonFillCustom from "../../../../../../admin/components/Button/ButtonFillCustom";
import AppBarFS from "../../../../../../components/app-bar/AppBar";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../../redux/store";
import {
  createdInvoice,
  getUpLoadReceiptUrl,
} from "../../../../../../services/Invoice.service";
import { TFarmerGetListBillItem } from "../../../../../../types/Invoice.type";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { tw } from "../../../../../../utils/tw";
import Dialog from "../../../../../order-history/views/Dialog";
import SuccessModal from "./SuccessModal";

interface IUploadProofOfPayment {
  isOpen: boolean;
  onClose: () => void;
  summary: string;
  customPrice: string;
  data?: TFarmerGetListBillItem[];
  itemSelected?: string[];
  setItemSelected?: Dispatch<SetStateAction<string[]>>;
}

interface IUploaderProps {
  maxFiles: number;
  onDrop: (images: File[]) => void;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`w-full h-full bg-[white]`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(`flex flex-col w-full h-full gap-[16px] px-[16px]`),
  text1: tw(`font-bold text-[16px] text-[#3777BC]`),
  title: tw(
    `text-[18px] leading-[18px] text-tertiary font-semibold mb-[24px] px-[16px]`
  ),
  uploadContainer: tw(`px-[16px] flex flex-col w-full`),
  helperText: tw(`text-[14px] leading-[18px] text-tertiary mt-[8px]`),
  buttonContainer: tw(`pt-1`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  buttonTextContainer: tw(
    `flex flex-col gap-6 pt-8 px-[17.5px] pb-6 fixed bottom-0 bg-[#FFFFFF] w-full z-10`
  ),
  textContainer: tw(`flex flex-row justify-between`),
  upload: {
    container: tw(
      `w-[97px] h-[97px] rounded-[17px] flex flex-col justify-center items-center border-[0.76px] border-[#97BFEA] cursor-pointer bg-[#F6FBFF] hover:brightness-105 duration-150 transition-all`
    ),
    listContainer: tw(`flex flex-row flex-wrap gap-3`),
    // image container
    image: tw(
      `w-[98px] h-[98px] rounded-[17px] hover:brightness-110 duration-150 transition-all relative`
    ),
    remove: tw(
      `absolute top-[5.3px] right-[5.3px] w-[19px] h-[19px] rounded-full z-10 bg-[rgba(255,255,255,0.9)] flex justify-center items-center cursor-pointer`
    ),
    img: tw(`w-full h-full object-cover rounded-[17px]`),
  },
};

const Uploader = memo((props: IUploaderProps) => {
  const { maxFiles, onDrop } = props;

  return (
    <Dropzone
      accept={{ "image/*": [] }}
      maxFiles={maxFiles}
      maxSize={100 * 1024 * 1024} // 100mb
      onDrop={onDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={classes.upload.container}>
            <InsertPhotoIcon className="!text-[#97BFEA] !text-[26px] !mb-[7.6px]" />
            <div className="flex flex-row gap-x-[2px] items-center -ml-[2px] -mb-[2px]">
              <AddIcon className="!text-[#97BFEA] !text-[14px] -mt-[2px]" />
              <div className="text-[14px] leading-[18px] text-[#97BFEA]">
                Photo
              </div>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
});

const UploadProofOfPayment = (props: IUploadProofOfPayment) => {
  const {
    isOpen,
    onClose,
    summary,
    customPrice,
    data,
    itemSelected,
    setItemSelected,
  } = props;
  const dispatch = useDispatch<AppDispatch>();

  const [images1, setImages1] = useState<
    (File | { id: string; url: string })[]
  >([]);

  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [isShowSummary, setIsShowSummary] = useState(false);

  const onShowConfirmUpload = useCallback(() => {
    setIsShowConfirmModal(true);
  }, []);

  const onCloseConfirmUpload = useCallback(() => {
    setIsShowConfirmModal(false);
  }, []);

  const onShowSummary = useCallback(() => {
    onCloseConfirmUpload();
    setTimeout(() => {
      setIsShowSummary(true);
    }, 400);
  }, []);

  const onAddImage = useCallback(
    (type: "image1") => (images: File[]) => {
      const maxFile = 1;
      setImages1((prev) => [...images, ...prev].slice(0, maxFile));
    },
    []
  );

  const onRemoveImage = useCallback(
    (type: "image1", index: number) => () => {
      setImages1((prev) => prev.filter((_, i) => i !== index));
    },
    []
  );

  const onCloseFn = useCallback(() => {
    onClose();
  }, []);

  const onConfirmUpload = useCallback(async () => {
    const imgs = [...images1];
    const fileArray: {
      id: string;
      url: string;
      fileName: string;
      fileSize: number;
    }[] = [];
    dispatch(
      loadingActions.show({ message: "กำลังบันทึกหลักฐานการโอนเงิน..." })
    );
    onShowSummary();
    try {
      for (let i = 0; i < imgs.length; i++) {
        const img = imgs[i];
        if (img instanceof File) {
          const presign = await getUpLoadReceiptUrl();
          await axios.put(presign.blob.blobUrl, img, {
            responseType: "blob",
            headers: {
              "Content-Type": img.type,
              "x-ms-blob-type": "BlockBlob",
            },
          });
          fileArray.push({
            id: presign.blob.fileId,
            url: presign.blob.url || "",
            fileName: img.name,
            fileSize: img.size,
          });
        }

        Promise.all([
          createdInvoice({
            paidAmount: +customPrice || +summary,
            invoiceIds: itemSelected ? itemSelected : [],
            paymentType: "upload_transfer_evidence",
            transferEvidence: {
              fileId: fileArray[0].id,
              fileName: fileArray[0].fileName,
            },
          }),
        ]);
      }
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "บันทึกหลักฐานการโอนเงินสำเร็จ",
            type: "success",
          })
        );
      }, 1500);
    } catch (e) {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(e),
            type: "error",
          })
        );
      }, 1500);
    }
  }, [images1]);

  const { t } = useTranslation();

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS
            title={t("FinancialSupport.UploadTransfer")}
            onIconPress={onCloseFn}
          />
        </div>
        <div className={classes.title}>{t("FinancialSupport.Transfer")}</div>
        <div className={tw(classes.uploadContainer, "mb-[24px]")}>
          <div className={classes.upload.listContainer}>
            {images1.map((image, index) => {
              const url =
                image instanceof File ? URL.createObjectURL(image) : image.url;
              return (
                <div key={`${index}`} className={classes.upload.image}>
                  <div
                    onClick={onRemoveImage("image1", index)}
                    className={classes.upload.remove}
                  >
                    <Close className="!text-[#979797] !text-[15px] -mr-[0.6px]" />
                  </div>
                  <img src={url} className={classes.upload.img} />
                </div>
              );
            })}
            <Uploader onDrop={onAddImage("image1")} maxFiles={1} />
          </div>
          <div className={classes.helperText}>
            {t("FinancialSupport.picturesOfReceipt")}
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <div
            className={classes.buttonTextContainer}
            style={{
              boxShadow: "2px 0px 4px 0px #00000020",
            }}
          >
            <ButtonFillCustom
              title={t("FinancialSupport.Next")}
              className={classes.button}
              disabled={isEmpty(images1)}
              onClick={onShowConfirmUpload}
            />
          </div>
        </div>
        <Dialog
          isOpen={isShowConfirmModal}
          withoutCloseIcon
          title={`${t("FinancialSupport.ConfirmEvidence")}\n${t(
            "FinancialSupport.Transfer"
          )}`}
          text={`${t("FinancialSupport.ConfirmEvidenceTitleOne")}\n${t(
            "FinancialSupport.Transfer"
          )}\n${t("FinancialSupport.ConfirmEvidenceTitleTwo")}`}
          confirmText={t("FinancialSupport.Confirm")}
          cancelText={t("FinancialSupport.Cancel")}
          onClose={onCloseConfirmUpload}
          onConfirm={onConfirmUpload}
          onCancel={onCloseConfirmUpload}
        />
        <SuccessModal isOpen={isShowSummary} />
      </div>
    </Modal>
  );
};

export default memo(UploadProofOfPayment);
