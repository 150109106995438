import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import { getCommaNumber } from "../../../../../../utils/number";
import { mockData } from "./mock";
import { get, isEmpty, isNull } from "lodash";
import ImagePreview from "../../../../../../components/common/ImagePreviewWithModal/ImagePreview";
import StockImage from "../../../../../../assets/images/stock-img.png";
import { IDataClaimOrder } from "../../../../../../types/ClaimOrder.type";
import { unitMap } from "../../../../manage-stock/[id]";
import { useCallback, useEffect, useState } from "react";
import {
  closeClaimOrder,
  getDownloadUrlClaimOrder,
} from "../../../../../../services/ClaimOrder.service";
import axios from "axios";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import CreateReportModal from "../../../views/CreateReportModalNew";
import CreateReportModalDetail from "../../../views/CreateReportModal";
import UpdateClaimCodeModal from "../../../views/UpdateClaimCodeModal";
import RejectModal from "./views/RejectModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../redux/store";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import CreateOrderModal from "./views/CreateOrderModal";
import { useAdminAuth } from "../../../../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

interface IReportDetail {
  data: IDataClaimOrder;
  refetchListClaimOrderUpcoming?: () => void;
  refetchListClaimOrderProgress?: () => void;
  refetchListClaimOrderHistory?: () => void;
  isFirst?: boolean;
}

export const reason = {
  feed_damaged: "สินค้าชำรุด",
  feed_low_quality: "สินค้าไม่ได้คุณภาพ",
  feed_not_match_with_order: "สินค้าไม่ตรงกับออเดอร์",
  "": "",
};

export const reasonOption = {
  deliver_product: "ส่งของเพิ่ม",
  refund_credit: "คืนเครดิต",
  add_debt: "แจ้งหนี้เพิ่ม",
  "": "",
};

export const textCode = {
  deliver_product: "DO Number",
  refund_credit: "DN Number",
  add_debt: "CN Number",
  "": "",
};

const ReportDetail = (props: IReportDetail) => {
  const { t } = useTranslation();
  const {
    data,
    refetchListClaimOrderUpcoming,
    refetchListClaimOrderProgress,
    refetchListClaimOrderHistory,
    isFirst,
  } = props;
  const [images1, setImages1] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const [isShowCreateReportCase4, setIsShowCreateReportCase4] = useState(false);

  const onShowCreateReportCase4 = useCallback(() => {
    setIsShowCreateReportCase4(true);
  }, []);

  const onHideCreateReportCase4 = useCallback(() => {
    setIsShowCreateReportCase4(false);
  }, []);

  const [isShowCreateReport, setIsShowCreateReport] = useState(false);

  const onShowCreateReport = useCallback(() => {
    setIsShowCreateReport(true);
  }, []);

  const onHideCreateReport = useCallback(() => {
    setIsShowCreateReport(false);
  }, []);

  const [isShowCreateReportDetail, setIsShowCreateReportDetail] =
    useState(false);

  const onShowCreateReportDetail = useCallback(() => {
    setIsShowCreateReportDetail(true);
  }, []);

  const onHideCreateReportDetail = useCallback(() => {
    setIsShowCreateReportDetail(false);
  }, []);

  const [isShowUpdateClaimCode, setIsShowUpdateClaimCode] = useState(false);

  const onShowUpdateClaimCode = useCallback(() => {
    setIsShowUpdateClaimCode(true);
  }, []);

  const onHideUpdateClaimCode = useCallback(() => {
    setIsShowUpdateClaimCode(false);
  }, []);

  const [isShowRejectClaimOrder, setIsShowRejectClaimOrder] = useState(false);

  const onShowRejectClaimOrder = useCallback(() => {
    setIsShowRejectClaimOrder(true);
  }, []);

  const onHideRejectClaimOrder = useCallback(() => {
    setIsShowRejectClaimOrder(false);
  }, []);

  const [isShowCreateOrder, setIsShowCreateOrder] = useState(false);

  const onShowCreateOrder = useCallback(() => {
    setIsShowCreateOrder(true);
  }, []);

  const onHideCreateOrder = useCallback(() => {
    setIsShowCreateOrder(false);
  }, []);

  const initImage = useCallback(async () => {
    const imgs = get(data, ["orderClaimItems", 0, "orderClaimItemFiles"], []);
    const images = [];
    if (imgs.length > 0) {
      for (let i = 0; i < imgs.length; i++) {
        try {
          const preSignURL = await getDownloadUrlClaimOrder(
            data.orderClaimItems[0].id,
            data.orderClaimItems[0].orderClaimItemFiles[i].id
          );
          if (preSignURL) {
            const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
              responseType: "blob",
            });
            const objectURL = window.URL.createObjectURL(response.data);

            images.push(objectURL);
          }
        } catch (e) {
          console.log("error to fetch image <Maybe timeout> e:", e);
        }
      }
    }
    setImages1(images);
  }, [data]);

  const closeMutation = useMutation({
    mutationFn: closeClaimOrder,
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries({
          queryKey: ["list_claim_order_progress"],
        }),
        queryClient.refetchQueries({
          queryKey: ["list_claim_order_history"],
        }),
      ]);
      {
        refetchListClaimOrderProgress && refetchListClaimOrderProgress();
      }
      {
        refetchListClaimOrderHistory && refetchListClaimOrderHistory();
      }
      setTimeout(() => {
        dispatch(loadingActions.hide());
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "ปิดเคสสำเร็จ",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onSubmit = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการปิดเคส",
        text: "คุณต้องการอนุมัติรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(loadingActions.show({ message: "กำลังปิดเคส..." }));
          closeMutation.mutate({
            orderClaimId: get(data, ["id"], "-"),
          });
        },
      })
    );
  }, [data]);

  const [ids, setIds] = useState("");

  const [idsClaimCode, setIdsClaimCode] = useState("");

  const id = useCallback((id: string) => {
    setIds(id);
    onShowCreateOrder();
  }, []);

  const updateClaimCode = useCallback((id: string) => {
    setIdsClaimCode(id);
    onShowUpdateClaimCode();
  }, []);

  useEffect(() => {
    initImage();
  }, [data]);

  const { action } = useAdminAuth();
  const roleActionEdit =
    action?.orderClaimAll === "edit" ||
    action?.orderClaimApproved === "edit" ||
    action?.orderClaimHistory === "edit";

  return (
    <div className="pt-6">
      <div className="flex flex-row justify-between">
        {data.orderClaimItems.length > 0 && (
          <>
            <div className="font-bold text-[20px] text-[#68C184]">
              รายละเอียดรายงานปัญหา
            </div>
            {roleActionEdit && data.orderClaimStatus === "created" && (
              <div className="flex flex-row gap-3">
                <ButtonFillCustom
                  onClick={onShowRejectClaimOrder}
                  title="ไม่อนุมัติ"
                  btnTextColor="#ffffff"
                  btnBgColor="#D32F2F"
                />
                <ButtonFillCustom
                  onClick={onShowCreateReportDetail}
                  title="อนุมัติ"
                  btnTextColor="#ffffff"
                  btnBgColor="#68C184"
                />
              </div>
            )}
            {roleActionEdit && data.orderClaimStatus === "processing" && (
              <div className="flex flex-row gap-3">
                <ButtonOutlinedCustom
                  onClick={onSubmit}
                  title={t("claimOrder.closeCase")}
                  btnTextColor="#68C184"
                  btnBgColor="#ffffff"
                  btnBorderColor="#68C184"
                  disabled={data.orderClaimItems.some(
                    (orderClaimItem) => !orderClaimItem.claimCode
                  )}
                />
                {roleActionEdit && data.orderFeedFiles.length > 0 ? (
                  <ButtonFillCustom
                    onClick={onShowCreateReport}
                    title={t("claimOrder.editClaimApproval")}
                    btnTextColor="#ffffff"
                    btnBgColor="#68C184"
                  />
                ) : (
                  <ButtonFillCustom
                    onClick={onShowCreateReportCase4}
                    title={t("claimOrder.editClaimApproval")}
                    btnTextColor="#ffffff"
                    btnBgColor="#68C184"
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
      {data.orderClaimItems.length > 0 ? (
        <>
          <div className="pt-6 w-[264px] min-w-[264px] flex justify-between">
            <span className="font-bold text-[14px] text-[#1B2128]">
              ราคารวมทั้งหมด
            </span>
            <span className="font-sans font-normal text-[14px] text-[#1B2128]">
              {getCommaNumber(get(data, ["totalFeedPrice"]), true)} บาท
            </span>
          </div>
          <div className="py-6 flex flex-col gap-3">
            {data.orderClaimItems.map((row, index) => {
              return (
                <div
                  key={`report-detail-${index}`}
                  className="w-full h-full border-[1px] border-[#E2E2E2] rounded-[10px] flex flex-row p-6 gap-[60px]"
                >
                  <div className="font-bold text-[14px] text-[#1B2128] flex items-center h-[30px]">
                    {index + 1}.
                  </div>
                  <div className="flex flex-col gap-6 w-full">
                    {row.orderFeedItem && (
                      <div className="w-[830px] flex flex-col gap-6">
                        <div className="grid grid-cols-4 gap-6">
                          <div className="h-[48px] flex flex-col gap-3">
                            <div className="font-bold text-[14px] text-[#1B2128]">
                              แบรนด์อาหารสัตว์
                            </div>
                            <div className="font-normal text-[14px] text-[#1B2128]">
                              {row.orderFeedItem.feedBrand.nameTh}
                            </div>
                          </div>
                          <div className="h-[48px] flex flex-col gap-3">
                            <div className="font-bold text-[14px] text-[#1B2128]">
                              เบอร์อาหารสัตว์
                            </div>
                            <div className="font-normal text-[14px] text-[#1B2128]">
                              {row.orderFeedItem.feedBrandItem.skuTh}
                            </div>
                          </div>
                          <div className="h-[48px] flex flex-col gap-3">
                            <div className="font-bold text-[14px] text-[#1B2128]">
                              จำนวน
                            </div>
                            <div className="font-normal text-[14px] text-[#1B2128]">
                              {get(row, [
                                "orderFeedItem",
                                "feedBrandItem",
                                "unit",
                              ]) === "bag"
                                ? getCommaNumber(
                                    +get(
                                      row,
                                      ["orderFeedItem", "feedAmount"],
                                      0
                                    )
                                  )
                                : getCommaNumber(
                                    +get(
                                      row,
                                      ["orderFeedItem", "feedAmount"],
                                      0
                                    ),
                                    true
                                  )}{" "}
                              {
                                unitMap[
                                  row.orderFeedItem.feedBrandItem.unit as
                                    | "kg"
                                    | "bag"
                                ]
                              }
                            </div>
                          </div>
                          <div className="h-[48px] flex flex-col gap-3">
                            <div className="font-bold text-[14px] text-[#1B2128]">
                              จำนวนที่รายงานปัญหา
                            </div>
                            <div className="font-normal text-[14px] text-[#1B2128]">
                              {get(row, [
                                "orderFeedItem",
                                "feedBrandItem",
                                "unit",
                              ]) === "bag"
                                ? getCommaNumber(+get(row, ["reportAmount"], 0))
                                : getCommaNumber(
                                    +get(row, ["reportAmount"], 0),
                                    true
                                  )}{" "}
                              {
                                unitMap[
                                  row.orderFeedItem.feedBrandItem.unit as
                                    | "kg"
                                    | "bag"
                                ]
                              }
                            </div>
                          </div>
                          <div className="h-[48px] flex flex-col gap-3">
                            <div className="font-bold text-[14px] text-[#1B2128]">
                              {t("claimOrder.reason")}
                            </div>
                            <div className="font-normal text-[14px] text-[#1B2128]">
                              {reason[row.reportReason]}
                            </div>
                          </div>
                          <div className="h-[48px] flex flex-col gap-3 col-span-3">
                            <div className="font-bold text-[14px] text-[#1B2128]">
                              {t("claimOrder.detailOfReason")}
                            </div>
                            <div className="font-normal text-[14px] text-[#1B2128]">
                              {row.reportDetail || "-"}
                            </div>
                          </div>
                        </div>
                        {row.orderClaimItemFiles.length > 0 && (
                          <div className="flex flex-row gap-3">
                            {images1.map((img, index) => (
                              <img
                                src={img}
                                key={`report-img-${index}`}
                                alt="report-img"
                                className="w-[80px] h-[80px] rounded-[10px] object-cover"
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    {(data.orderClaimStatus === "processing" ||
                      data.orderClaimStatus === "approved" ||
                      data.orderClaimStatus === "rejected") && (
                      <div className="w-full flex flex-col gap-6">
                        <div className="flex flex-row justify-between w-full">
                          <div className="font-bold text-[16px] text-[#3777BC] flex items-center h-[30px]">
                            ตัวเลือกการแก้ไข
                          </div>
                          <div className="flex flex-row gap-3">
                            {data.orderClaimStatus === "processing" && (
                              <>
                                {roleActionEdit &&
                                  get(row, ["claimOption"], "") ===
                                    "deliver_product" && (
                                    <ButtonOutlinedCustom
                                      onClick={() => id(get(row, ["id"], ""))}
                                      title="สร้างออเดอร์"
                                      btnTextColor="#68C184"
                                      btnBgColor="#FFFFFF"
                                      btnBorderColor="#68C184"
                                      disabled={get(row, [
                                        "isDeliverOrderFeed",
                                      ])}
                                    />
                                  )}
                                {roleActionEdit && (
                                  <ButtonFillCustom
                                    onClick={() =>
                                      updateClaimCode(get(row, ["id"]))
                                    }
                                    title="อัปเดตเลขที่ปิดเคส"
                                    btnTextColor="#ffffff"
                                    btnBgColor="#68C184"
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col gap-6">
                          <div className="flex flex-row gap-6">
                            <div className="flex flex-row gap-6 min-w-[419.5px]">
                              <div className="w-[189px] font-bold text-[14px] text-[#1B2128]">
                                {textCode[row.claimOption]}
                              </div>
                              <div className="font-normal text-[14px] text-[#1B2128]">
                                {get(row, ["claimCode"], "-")}
                              </div>
                            </div>
                            {row.isDeliverOrderFeed && (
                              <div className="flex flex-row gap-6">
                                <div className="w-[189px] font-bold text-[14px] text-[#1B2128]">
                                  หมายเลขสั่งซื้อ
                                </div>
                                <div className="font-normal text-[14px] text-[#1B2128]">
                                  {get(row, ["newOrderFeed", "orderNumber"]) ||
                                    "-"}
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-row gap-6">
                            <div className="flex flex-col gap-3">
                              <div className="w-[189px] font-bold text-[14px] text-[#1B2128]">
                                ตัวเลือก
                              </div>
                              <div className="font-normal text-[14px] text-[#1B2128]">
                                {reasonOption[get(row, ["claimOption"])]}
                              </div>
                            </div>
                            {!(
                              get(row, ["claimOption"], "") ===
                              "deliver_product"
                            ) && (
                              <>
                                <div className="flex flex-col gap-3">
                                  <div className="w-[189px] font-bold text-[14px] text-[#1B2128]">
                                    เครดิตที่ใช้
                                  </div>
                                  <div className="font-normal text-[14px] text-[#1B2128]">
                                    {getCommaNumber(
                                      get(data, ["totalFeedPrice"], 0),
                                      true
                                    )}{" "}
                                    บาท
                                  </div>
                                </div>
                                <div className="flex flex-col gap-3">
                                  <div className="w-[189px] font-bold text-[14px] text-[#1B2128]">
                                    {get(row, ["claimOption"], "") ===
                                    "add_debt"
                                      ? "เครดิตที่เพิ่ม"
                                      : "เครดิตที่คืน"}
                                  </div>
                                  <div className="font-normal text-[14px] text-[#1B2128]">
                                    {getCommaNumber(
                                      !isNull(get(row, ["claimRefundAmount"]))
                                        ? get(row, ["claimRefundAmount"], 0)
                                        : 0,
                                      true
                                    )}{" "}
                                    บาท
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                          <div className="flex flex-col gap-3">
                            <div className="w-[189px] font-bold text-[14px] text-[#1B2128]">
                              Remark
                            </div>
                            <div className="font-normal text-[14px] text-[#1B2128]">
                              {get(row, ["remark"], "-")}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="font-bold text-[14px] text-[#1B2128]">
          ยังไม่มี Order ที่มีปัญหา
        </div>
      )}
      <CreateReportModal
        isOpen={isShowCreateReportCase4}
        onCloseFn={onHideCreateReportCase4}
        refetchListClaimOrderProgress={refetchListClaimOrderProgress}
        onEdit
        dataEdit={data}
      />
      <CreateReportModalDetail
        isOpen={isShowCreateReportDetail}
        onCloseFn={onHideCreateReportDetail}
        dataEdit={data}
        refetchListClaimOrderUpcoming={refetchListClaimOrderUpcoming}
        refetchListClaimOrderProgress={refetchListClaimOrderProgress}
        isFirst={isFirst}
      />
      <CreateReportModalDetail
        isOpen={isShowCreateReport}
        onCloseFn={onHideCreateReport}
        dataEdit={data}
        refetchListClaimOrderUpcoming={refetchListClaimOrderUpcoming}
        refetchListClaimOrderProgress={refetchListClaimOrderProgress}
        onEdit
      />
      <UpdateClaimCodeModal
        isOpen={isShowUpdateClaimCode}
        onCloseFn={onHideUpdateClaimCode}
        refetchListClaimOrderProgress={refetchListClaimOrderProgress}
        dataEdit={data}
        ids={idsClaimCode}
      />
      <RejectModal
        isOpen={isShowRejectClaimOrder}
        onCloseFn={onHideRejectClaimOrder}
        refetchListClaimOrderUpcoming={refetchListClaimOrderUpcoming}
        refetchListClaimOrderHistory={refetchListClaimOrderHistory}
        dataEdit={data}
      />
      <CreateOrderModal
        isOpen={isShowCreateOrder}
        onCloseFn={onHideCreateOrder}
        ids={ids}
        refetchListClaimOrderProgress={refetchListClaimOrderProgress}
      />
    </div>
  );
};

export default ReportDetail;
