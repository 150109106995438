import { TDropdownValue } from "../admin/pages/group-area/views/Dropdown";

export const Month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const monthThreedigit = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const monthOption: TDropdownValue[] = [
  { label: "มกราคม", value: "0" },
  { label: "กุมภาพันธ์", value: "1" },
  { label: "มีนาคม", value: "2" },
  { label: "เมษายน", value: "3" },
  { label: "พฤษภาคม", value: "4" },
  { label: "มิถุนายน", value: "5" },
  { label: "กรกฎาคม", value: "6" },
  { label: "สิงหาคม", value: "7" },
  { label: "กันยายน", value: "8" },
  { label: "ตุลาคม", value: "9" },
  { label: "พฤศจิกายน", value: "10" },
  { label: "ธันวาคม", value: "11" },
];

export const echartGrid = {
  top: "3%",
  left: "3%",
  right: "3%",
  bottom: "15%",
  containLabel: true,
};
