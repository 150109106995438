import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../../const/app.constant";
import { CalendarSvg } from "../../../../assets/svg/Calendar.svg";
import CashSvg from "../../../../assets/svg/Cash.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import DateRangePickerPopover from "../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import TextInputCustom from "../../../../components/Input/TextInputCustom";
import { ChipCount } from "../../../sale-order/CreditHoldOrderPanel/CreditHoldOrderPanel";
import CustomerTab from "../../components/CustomerTab";
import { useCustomerFilterHook } from "../../hooks/useCustomerFilter.hook";
import { getCountCustomerInformation } from "../../services/customer.service";
import { IGetCountTabCustomer } from "../../types/customerTable.type";

type Props = { partnerId: string };

const CustomerInformation = ({ partnerId }: Props) => {
  const { t } = useTranslation();
  const [countTab, setCountTab] = useState<IGetCountTabCustomer | null>();
  const refCalendarRangeOrderDate =
    useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);

  const [isOpenCalenderOrderDate, setIsOpenCalenderOrderDate] =
    React.useState<boolean>(false);
  const [toggleState, setToggleState] = useState(1);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const searchCustomerFilter = useCustomerFilterHook();

  const toggleTab = (index: number) => {
    setToggleState(index);
  };

  const handleCloseCalenderOrderDate = () => {
    setIsOpenCalenderOrderDate(false);
  };

  const handleCalenderSaveOrderDate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).toISOString();
    const formattedEndDate = dayjs(value[0].endDate).toISOString();
    searchCustomerFilter.setStartDate(formattedStartDate);
    searchCustomerFilter.setEndDate(formattedEndDate);
    setStartDate(dayjs(value[0].startDate).format(DATE_FORMAT_DATE_PICKER2));
    setEndDate(dayjs(value[0].endDate).format(DATE_FORMAT_DATE_PICKER2));
  };

  const handleFetchCountCustomerTabs = async () => {
    const data = await getCountCustomerInformation(partnerId);
    setCountTab(data);
  };

  useEffect(() => {
    handleFetchCountCustomerTabs();
  }, []);

  const handleSelectOrderDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(dateRange[0]).toISOString();
    const formattedEndDate = dayjs(dateRange[1]).toISOString();
    searchCustomerFilter.setStartDate(formattedStartDate);
    searchCustomerFilter.setEndDate(formattedEndDate);
    setStartDate(dayjs(dateRange[0]).format(DATE_FORMAT_DATE_PICKER2));
    setEndDate(dayjs(dateRange[1]).format(DATE_FORMAT_DATE_PICKER2));
  };

  return (
    <>
      <div className="flex gap-2 text-center items-center mt-6">
        <CashSvg />
        <p className="text-lg font-bold text-[#3777BC] font-sans">
          {t("partner.customerInformationTab.title")}
        </p>
      </div>
      <div className="flex justify-between mt-4 items-end">
        <div>
          <span className="text-sm font-medium font-sans">
            {t("partner.customerInformationTab.startDate")}
          </span>
          <div className="pt-2 w-[340px]">
            <TextInputCustom
              placeholder="เริ่มต้น - สิ้นสุด"
              value={startDate ? startDate + " - " + endDate : ""}
              InputProps={{
                endAdornment:
                  searchCustomerFilter.startDate !== "" ? (
                    <CloseRoundedIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "18px !important",
                      }}
                      onClick={(e) => {
                        searchCustomerFilter.setStartDate("");
                        searchCustomerFilter.setEndDate("");
                        searchCustomerFilter.setToggleFetch((prev) => !prev);
                        setEndDate("");
                        setStartDate("");
                        e.stopPropagation();
                      }}
                    />
                  ) : (
                    <SvgIcon
                      sx={{
                        fontSize: "24px !important",
                        color: "#646464",
                      }}
                      fontSize="small"
                    >
                      <CalendarSvg />
                    </SvgIcon>
                  ),
              }}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                refCalendarRangeOrderDate.current?.onOpen(e);
              }}
            />
          </div>
        </div>
        <div>
          <ButtonFillCustom
            title={t("common.button.search")}
            onClick={() => searchCustomerFilter.setToggleFetch((prev) => !prev)}
          />
        </div>
      </div>

      <div className="w-full h-full pt-8">
        <div className="fs-tab-custom-container">
          <div className="fs-tab-custom-bloc-tabs">
            <button
              className={`fs-tab-custom-button ${
                toggleState === 1
                  ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                  : "fs-tab-custom-tabs"
              }`}
              onClick={() => toggleTab(1)}
            >
              <div className="flex w-full justify-center gap-3 items-center">
                ลูกค้า on board ทั้งหมด{" "}
                <ChipCount value={countTab?.result.onboardCustomer || 0} />
              </div>
            </button>
            <button
              className={`fs-tab-custom-button ${
                toggleState === 2
                  ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                  : "fs-tab-custom-tabs"
              }`}
              onClick={() => toggleTab(2)}
            >
              <div className="flex w-full justify-center gap-3 items-center">
                ลูกค้าที่เปิดใช้งาน
                <ChipCount value={countTab?.result.activeCustomer || 0} />
              </div>
            </button>
          </div>
        </div>
        <div className="fs-tab-custom-content-tabs">
          <div
            className={
              toggleState === 1
                ? "fs-tab-custom-content  fs-tab-custom-active-content"
                : "fs-tab-custom-content"
            }
          >
            <CustomerTab
              id={partnerId}
              type="all"
              searchCustomerFilter={searchCustomerFilter}
            />
          </div>

          <div
            className={
              toggleState === 2
                ? "fs-tab-custom-content  fs-tab-custom-active-content"
                : "fs-tab-custom-content"
            }
          >
            <CustomerTab
              id={partnerId}
              type="active"
              searchCustomerFilter={searchCustomerFilter}
            />
          </div>
        </div>
      </div>
      <DateRangePickerPopover
        ref={refCalendarRangeOrderDate}
        id={"calendar-range-popover-order"}
        handleSelect={handleSelectOrderDate}
        openCalender={isOpenCalenderOrderDate}
        onClose={handleCloseCalenderOrderDate}
        handleCalenderSave={handleCalenderSaveOrderDate}
        startDate={searchCustomerFilter.startDate}
        endDate={searchCustomerFilter.endDate}
      />
    </>
  );
};

export default CustomerInformation;
