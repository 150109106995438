import { memo } from "react";
import { tw } from "../../../../../../utils/tw";
import { SvgIcon } from "@mui/material";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import dayjs from "dayjs";
import { DownloadSvg } from "../../../../../assets/svg/Download.svg";
import { makeXlsx } from "../../../../../../utils/make-xlsx";
import { getExportFarmer } from "../../../../../../services/CreditFeed.service";
import { useTranslation } from "react-i18next";

const classes = {
  container: tw(`flex flex-row justify-between`),
  containerSearchText: tw(`w-[470px]`),
};

interface IProps {
  searchText: string;
  onSearchText: (e: any) => void;
  onClearSearchText: () => void;
  itemSelected: string[];
}

interface DataExport {
  customerId: string;
  customerName: string;
  saleName: string;
}

function createData(
  customerId: string,
  customerName: string,
  saleName: string
): DataExport {
  return {
    customerId,
    customerName,
    saleName,
  };
}

const FilterTabFarmer = (props: IProps) => {
  const { t } = useTranslation();
  const { searchText, onSearchText, onClearSearchText, itemSelected } = props;

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `credit_feed_farmer_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData = async () => {
    type TColumnName = "รหัสลูกค้า" | "ชื่อลูกค้า" | "พนักงานขาย";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getExportFarmer(itemSelected);
    if (allSelectItemData) {
      const data: TExcelData[] = allSelectItemData?.map<TExcelData>((row) => {
        const mappedData = createData(
          row.registrationId,
          row.name + " " + row.surname,
          row.userGroup.seller.name +
            " " +
            `${
              row.userGroup.seller.surname === null
                ? ""
                : row.userGroup.seller.surname
            }`
        );
        return {
          รหัสลูกค้า: mappedData.customerId,
          ชื่อลูกค้า: mappedData.customerName,
          พนักงานขาย: mappedData.saleName,
        };
      });
      return data;
    }
    return [];
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerSearchText}>
        <TextInputSearchCustom
          value={searchText}
          placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
          onChange={onSearchText}
          InputProps={{
            endAdornment:
              searchText.length > 0 ? (
                <CloseRoundedIcon
                  sx={{
                    cursor: "pointer",
                    fontSize: "18px !important",
                  }}
                  onClick={onClearSearchText}
                />
              ) : null,
          }}
        />
      </div>
      <ButtonOutlinedCustom
        title={t("common.download")}
        btnTextColor="#68C184"
        btnBorderColor="#68C184"
        onClick={handleExportExcel}
        disabled={itemSelected.length === 0}
        startIcon={
          <SvgIcon
            sx={{
              fontSize: "14px !important",
              color: "currentColor",
              marginBottom: "3px",
            }}
            fontSize="small"
          >
            <DownloadSvg />
          </SvgIcon>
        }
      />
    </div>
  );
};

export default memo(FilterTabFarmer);
