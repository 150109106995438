import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import LineChart from "echarts-for-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { DownloadSvg } from "../../admin/assets/svg/Download.svg";
import ButtonOutlinedCustom from "../../admin/components/Button/ButtonOutlinedCustom";
import AppBarFS from "../../components/app-bar/AppBar";
import FilterChartModal from "./FilterChartModal";
import { getGraphPerMonth, getGraphPerYears } from "./farmCommunity.service";
import { IGetGraphPerMonthsResult, IGetGraphPerYearsResult } from "./type";

type Props = {};

export type FilterMarketListType = {
  region: string;
  displayPrice: string;
  year?: string;
};

export enum ERegion {
  north = "ภาคเหนือ",
  south = "ภาคใต้",
  north_east = "ภาคอีสาน",
  east = "ภาคตะวันออก",
  west = "ภาคตะวันตก",
  central = "ภาคกลาง",
}

const DashBoardPigPrice = (props: Props) => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const [isOpenFilterYearModal, setIsOpenFilterYearModal] = useState(false);
  const [isOpenFilterMonthModal, setIsOpenFilterMonthModal] = useState(false);
  const [marketPriceList, setMarketPriceList] =
    useState<IGetGraphPerYearsResult>();
  const [filterMarketList, setFilterMarketList] =
    useState<FilterMarketListType>({
      region: "north",
      displayPrice: "all",
    });

  const [sellPriceList, setSellPriceList] =
    useState<IGetGraphPerMonthsResult>();
  const [filterSellList, setFilterSellList] = useState<FilterMarketListType>({
    region: "north",
    displayPrice: "all",
    year: dayjs().format("YYYY"),
  });
  const navigate = useNavigate();

  const dataYear = marketPriceList?.marketPriceList.map((data) => data.year);

  useEffect(() => {
    const fetch = async () => {
      const searchParams = new URLSearchParams();
      searchParams.append("region", filterMarketList.region);
      searchParams.append("displayPrice", filterMarketList.displayPrice);
      const data = await getGraphPerYears(state, searchParams);
      if (data) {
        setMarketPriceList(data?.result);
      }
    };
    fetch();
  }, [filterMarketList]);

  useEffect(() => {
    const fetch = async () => {
      const searchParams = new URLSearchParams();
      searchParams.append("region", filterSellList.region);
      searchParams.append("displayPrice", filterSellList.displayPrice);
      searchParams.append(
        "year",
        filterSellList.year || dayjs().format("YYYY")
      );
      const data = await getGraphPerMonth(state, searchParams);
      if (data) {
        setSellPriceList(data?.result);
      }
    };
    fetch();
  }, [filterSellList]);

  return (
    <div className="!h-screen !min-h-screen flex flex-col overflow-scroll">
      <div className="my-4 flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title={t("FarmCommunity.MarketPriceAndSellingPrice")}
        />
      </div>
      <div className="flex flex-row justify-between items-center mx-4">
        <div className="text-[#34407B] text-[20px] font-semibold">รายปี</div>
        <div className="h-full flex gap-2 items-center">
          <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
            {t("manageStock.filterBy")}
          </span>
          <IconButton
            aria-label="filter"
            sx={{
              color: "#68C184",
              border: "1px solid #68C184",
              ":hover": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
              ":active": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
            }}
            onClick={() => setIsOpenFilterYearModal(true)}
          >
            <FilterListIcon />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mx-4 mt-4">
        <div className="text-secondary text-[18px] font-semibold">
          {ERegion[filterMarketList.region as keyof typeof ERegion]}
        </div>
        <div className="h-full flex gap-2 items-center">
          <ButtonOutlinedCustom
            className="!rounded-2xl text-[18px]"
            btnBorderColor="#68C184"
            btnTextColor="#68C184"
            title={t("BasicAccounting.export")}
            disabled
            startIcon={
              <SvgIcon
                sx={{
                  fontSize: "14px !important",
                  color: "currentColor",
                  marginBottom: "3px",
                }}
                fontSize="small"
              >
                <DownloadSvg />
              </SvgIcon>
            }
          />
        </div>
      </div>
      <div className="mt-4">
        <LineChart
          option={{
            legend: {
              data: ["ราคาตลาด", "ราคาขาย"],
              textStyle: {
                fontFamily: "IBM Plex Sans Thai",
              },
              selectedMode: false,
              icon: "circle",
            },
            xAxis: {
              type: "category",
              data: marketPriceList?.marketPriceList.map((data) => data.year),
            },
            yAxis: {
              type: "value",
            },
            series: [
              {
                name: t("FarmCommunity.MarketPrice"),
                data: marketPriceList?.marketPriceList.map(
                  (data) => data.averageOfYear
                ),
                type: "line",
                lineStyle: {
                  normal: {
                    color: "#68C184",
                    width: 5,
                  },
                },
                symbol: "circle",
                symbolSize: 10,
                itemStyle: {
                  color: "#68C184",
                },
              },
              {
                name: t("FarmCommunity.SellPrice"),
                data: marketPriceList?.sellPriceList.map(
                  (data) => data.averageOfYear
                ),
                type: "line",
                lineStyle: {
                  normal: {
                    color: "#3777BC",
                    width: 5,
                  },
                },
                symbol: "circle",
                symbolSize: 10,
                itemStyle: {
                  color: "#3777BC",
                },
              },
            ],
            tooltip: {
              show: true,
              formatter: function (params: any) {
                return `${params.value} ${t("FarmCommunity.BahtPerKilo")}`;
              },
              textStyle: {
                fontFamily: "IBM Plex Sans Thai",
              },
            },
          }}
        />
      </div>
      <div className="flex flex-row justify-between items-center mx-4">
        <div className="text-[#34407B] text-[20px] font-semibold">รายเดือน</div>
        <div className="h-full flex gap-2 items-center">
          <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
            {t("manageStock.filterBy")}
          </span>
          <IconButton
            aria-label="filter"
            sx={{
              color: "#68C184",
              border: "1px solid #68C184",
              ":hover": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
              ":active": {
                color: "#FFFFFF",
                border: "1px solid #68C184",
                bgcolor: "#68C184",
              },
            }}
            onClick={() => setIsOpenFilterMonthModal(true)}
          >
            <FilterListIcon />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mx-4 mt-4">
        <div className="text-secondary text-[18px] font-semibold">
          {ERegion[filterSellList.region as keyof typeof ERegion]}
          {" ปี "}
          {filterSellList.year}
        </div>
        <div className="h-full flex gap-2 items-center">
          <ButtonOutlinedCustom
            className="!rounded-2xl text-[18px]"
            btnBorderColor="#68C184"
            btnTextColor="#68C184"
            title={t("BasicAccounting.export")}
            disabled
            startIcon={
              <SvgIcon
                sx={{
                  fontSize: "14px !important",
                  color: "currentColor",
                  marginBottom: "3px",
                }}
                fontSize="small"
              >
                <DownloadSvg />
              </SvgIcon>
            }
          />
        </div>
      </div>
      <div className="mt-4">
        <LineChart
          option={{
            legend: {
              data: ["ราคาตลาด", "ราคาขาย"],
              textStyle: {
                fontFamily: "IBM Plex Sans Thai",
              },
              selectedMode: false,
              icon: "circle",
            },
            grid: {
              left: "10%",
              right: "10%",
              bottom: "15%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data:
                filterSellList.displayPrice === "sell_price"
                  ? sellPriceList?.sellPriceList.map((data) => data.month)
                  : sellPriceList?.marketPriceList.map((data) => data.month),
              axisLabel: {
                interval: 0,
                rotate: 30,
              },
            },
            yAxis: {
              type: "value",
            },
            dataZoom: [
              {
                type: "slider",
                start: 0,
                end: 50,
              },
              {
                type: "inside",
              },
            ],
            series: [
              {
                name: t("FarmCommunity.MarketPrice"),
                data: sellPriceList?.marketPriceList.map(
                  (data) => data.averageOfMonth
                ),
                type: "line",
                lineStyle: {
                  normal: {
                    color: "#68C184",
                    width: 5,
                  },
                },
                symbol: "circle",
                symbolSize: 10,
                itemStyle: {
                  color: "#68C184",
                },
              },
              {
                name: t("FarmCommunity.SellPrice"),
                data: sellPriceList?.sellPriceList.map(
                  (data) => data.averageOfMonth
                ),
                type: "line",
                lineStyle: {
                  normal: {
                    color: "#3777BC",
                    width: 5,
                  },
                },
                symbol: "circle",
                symbolSize: 10,
                itemStyle: {
                  color: "#3777BC",
                },
              },
            ],
            tooltip: {
              show: true,
              formatter: function (params: any) {
                return `${params.value} ${t("FarmCommunity.BahtPerKilo")}`;
              },
            },
          }}
        />
      </div>
      <FilterChartModal
        isOpen={isOpenFilterYearModal}
        handleClose={() => setIsOpenFilterYearModal(false)}
        setFilterList={setFilterMarketList}
        filterList={filterMarketList}
        hideSelectYear
      />
      <FilterChartModal
        isOpen={isOpenFilterMonthModal}
        handleClose={() => setIsOpenFilterMonthModal(false)}
        setFilterList={setFilterSellList}
        filterList={filterSellList}
        dataYear={dataYear}
      />
    </div>
  );
};

export default DashBoardPigPrice;
