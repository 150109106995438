import { datadogRum } from "@datadog/browser-rum";
import { createContext, useContext, useEffect, useState } from "react";
import { ADMIN_AUTH_TOKEN_KEY } from "../../../const/app.constant";
import { Actions, IGetRoleResult } from "../../../services/AdminAuth.type";
import { getRole } from "../../../services/AdminAuthService";
import { getItem } from "../../../services/StorageService";
import { UserFunctionRole } from "../../../utils/enum/user-role.enum";

type IUserNull = IGetRoleResult | null;

const AdminAuthContext = createContext<{
  action: Actions | undefined;
  user: IUserNull;
  // userRole: UserRole;
  functionRole: UserFunctionRole;
  setUser: (user: IUserNull) => void;
}>({
  action: undefined,
  setUser: () => {},
  user: null,
  functionRole: UserFunctionRole.none,
  // userRole: UserRole.Guest,
});

export const AdminAuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<IUserNull>(null);
  // const [userRole, setUserRole] = useState<UserRole>(UserRole.Guest);
  const [isFetching, setIsFetching] = useState(false);
  const adminAuthToken = getItem(ADMIN_AUTH_TOKEN_KEY);
  const [functionRole, setFunctionRole] = useState<UserFunctionRole>(
    UserFunctionRole.none
  );
  const [action, setAction] = useState<Actions>();

  const handleSetRole = async () => {
    setIsFetching(true);
    const response = await getRole();
    if (response) {
      setUser(response.result);
      // setUserRole(response.result.userRole);
      setFunctionRole(response.result.functionName);
      setAction(response.result.actions);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    handleSetRole();
  }, [adminAuthToken]);

  useEffect(() => {
    if (user) {
      console.log("current username: ", user.value);
      console.log("current user role: ", user.userRole);
      console.log("action", action);
      datadogRum.setUser({ id: user.value });
      datadogRum.setUserProperty("type", "admin");
    }
  }, [user]);

  return (
    <AdminAuthContext.Provider value={{ user, setUser, functionRole, action }}>
      {!isFetching && { ...children }}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = () => {
  return useContext(AdminAuthContext);
};
