import { Dialog, DialogContent, IconButton, Tab, Tabs } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cloneDeep, get, set } from "lodash";
import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import { approvedClaimOrder } from "../../../../services/ClaimOrder.service";
import {
  EClaimOrderOption,
  IDataClaimOrder,
} from "../../../../types/ClaimOrder.type";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { tw } from "../../../../utils/tw";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import AddDetail from "./Tabs/AddDetail";
import SummaryDetail from "./Tabs/SummaryDetail";
import { useTranslation } from "react-i18next";

interface ICreateModal {
  isOpen: boolean;
  onCloseFn: () => void;
  dataEdit?: IDataClaimOrder;
  onEdit?: boolean;
  refetchListClaimOrderUpcoming?: () => void;
  refetchListClaimOrderProgress?: () => void;
  isFirst?: boolean;
}

interface IState {
  option: number;
  creditReturnUse: string;
  creditReturnReturn: string;
  creditAddUse: string;
  creditAddAdd: string;
  remark: string;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[1025px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
};

const CreateReportModalDetail = (props: ICreateModal) => {
  const { t } = useTranslation();
  const {
    isOpen,
    onCloseFn,
    dataEdit,
    onEdit,
    refetchListClaimOrderUpcoming,
    refetchListClaimOrderProgress,
    isFirst,
  } = props;
  const maxIndex = 1;
  const minIndex = 0;
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const [state, setState] = useState<IState[]>([]);

  const onChangeState = useCallback(
    (index: number, key: keyof IState) => (value: any) => {
      setState((prev) => {
        const prevState = cloneDeep(prev);
        return set(prevState, [index, key], value);
      });
    },
    []
  );

  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const onClickNext = useCallback(() => {
    if (tabIndex < maxIndex) {
      setTabIndex(tabIndex + 1);
    }
  }, [tabIndex]);

  const onClickPrev = useCallback(() => {
    if (tabIndex > minIndex) {
      setTabIndex(tabIndex - 1);
    }
  }, [tabIndex]);

  const getOptionValue = useCallback((type: number) => {
    if (type === 0) {
      return EClaimOrderOption.DeliverProduct;
    } else if (type === 1) {
      return EClaimOrderOption.RefundCredit;
    } else if (type === 2) {
      return EClaimOrderOption.AddDebt;
    } else {
      return EClaimOrderOption.AddDebt;
    }
  }, []);

  const upDateMutation = useMutation({
    mutationFn: approvedClaimOrder,
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries({
          queryKey: ["list_claim_order_upcoming"],
        }),
        queryClient.refetchQueries({
          queryKey: ["list_claim_order_progress"],
        }),
      ]);
      {
        refetchListClaimOrderUpcoming && refetchListClaimOrderUpcoming();
      }
      {
        refetchListClaimOrderProgress && refetchListClaimOrderProgress();
      }
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: onEdit ? "บันทึกสำเร็จ" : "สร้างรายการสำเร็จ",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onSubmitEdit = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: onEdit ? "ยืนการบันทึก" : "ยืนยันการสร้างรายการ",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(loadingActions.show({ message: "กำลังบันทึกรายการ..." }));
          upDateMutation.mutate({
            orderClaimId: get(dataEdit, ["id"], "-"),
            orderClaimItems: get(dataEdit, ["orderClaimItems"], []).map(
              (row, index) => ({
                orderClaimItemId: get(row, ["id"]),
                claimOption: getOptionValue(state[index].option),
                refundAmount:
                  getOptionValue(state[index].option) === "refund_credit"
                    ? +get(state, [index, "creditReturnReturn"], 0)
                    : getOptionValue(state[index].option) === "add_debt"
                    ? +get(state, [index, "creditAddAdd"], 0)
                    : 0,
                remark: state[index].remark,
              })
            ),
          });
        },
      })
    );
  }, [state]);

  const getOptionValueNumber = useCallback((type: EClaimOrderOption) => {
    if (type === EClaimOrderOption.DeliverProduct) {
      return 0;
    } else if (type === EClaimOrderOption.RefundCredit) {
      return 1;
    } else if (type === EClaimOrderOption.AddDebt) {
      return 2;
    } else {
      return 0;
    }
  }, []);

  const stateInit = (dataEdit?.orderClaimItems || []).map(
    (d) =>
      ({
        option: getOptionValueNumber(d.claimOption),
      } as IState)
  );

  const onClose = useCallback(() => {
    onCloseFn();
    setState([]);
    setState(stateInit);
    setTabIndex(0);
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent className="w-[1025px]">
        <div className={classes.titleContainer}>
          <p className={classes.title}>
            {onEdit
              ? t("claimOrder.editClaimApproval")
              : t("claimOrder.createClaimApproval.title")}
          </p>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        {onEdit ? (
          <AddDetail
            state={state}
            setState={setState}
            onChangeState={onChangeState}
            dataEdit={dataEdit}
            onEdit={onEdit}
          />
        ) : (
          <>
            <Tabs
              value={tabIndex}
              onChange={onChangeTab}
              aria-label={`order-tab-control-${tabIndex}`}
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "#68C184",
                    height: "3px",
                  },
                },
              }}
              sx={{
                height: "36px",
                minHeight: "unset",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#000000",
                      borderBottom: "3px solid #DDF3E4",
                      "& .Mui-selected": {
                        color: "#000000",
                      },
                    },
                  },
                },
              }}
            >
              <Tab label={t("claimOrder.createClaimApproval.step1.title")} />
              <Tab label={t("claimOrder.createClaimApproval.step2.title")} />
            </Tabs>
            <TabPanelCustom
              value={tabIndex}
              index={0}
              prefix={`order-item-${tabIndex}`}
            >
              <AddDetail
                state={state}
                setState={setState}
                onChangeState={onChangeState}
                dataEdit={dataEdit}
                isFirst={isFirst}
              />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={1}
              prefix={`order-item-${tabIndex}`}
            >
              <SummaryDetail
                state={state}
                tabIndex={tabIndex}
                dataEdit={dataEdit}
              />
            </TabPanelCustom>
          </>
        )}
        <div className="w-full flex justify-end gap-[10px]">
          {onEdit ? (
            <ButtonFillCustom
              title={t(
                "claimOrder.createClaimApproval.step2.confirmCreateApproval"
              )}
              onClick={onSubmitEdit}
            />
          ) : (
            <>
              {tabIndex !== 0 && (
                <ButtonOutlinedCustom
                  title={"ย้อนกลับ"}
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  onClick={onClickPrev}
                />
              )}
              {tabIndex === maxIndex ? (
                <ButtonFillCustom
                  title={t(
                    "claimOrder.createClaimApproval.step2.confirmCreateApproval"
                  )}
                  onClick={onSubmitEdit}
                />
              ) : (
                <ButtonFillCustom
                  title={t("claimOrder.createClaimApproval.step1.next")}
                  onClick={onClickNext}
                />
              )}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(CreateReportModalDetail);
