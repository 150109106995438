import {
  Slider,
  TableCell,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { get } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import scrollImg from "../../../../assets/images/feed/bnt-scroll.png";
import PigFeedSvg from "../../../../assets/svg/PigFeedSvg";
import AppBarFS from "../../../../components/app-bar/AppBar";
import {
  getMyStockPartnerHistoryId,
  getMyStockPartnerId,
} from "../../../../services/partner/historyStock.service";
import { IMyStock, IStockDetail } from "../../../../types/partner/stock.type";
import { getCommaNumber } from "../../../../utils/number";
import { tw } from "../../../../utils/tw";
import TableCustomMobile from "../../../financial-support/financial-billPayment/unpaid-bill/views/TableCustomMobile";
import ModalSeeDetails from "./views/ModalSeeDetails";

const classes = {
  container: tw(`min-h-[240px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const unitMap = {
  bag: "ถุง",
  kg: "กิโลกรัม",
  "": "",
};

interface ITable {
  swapCellColor?: boolean;
  data: IStockDetail[];
  unit: string;
}

const Table = (props: ITable) => {
  const { swapCellColor, data, unit } = props;
  const [scrollValue, setScrollValue] = useState(0);
  const { t } = useTranslation();

  const headerItems = [
    {
      id: "activityDate",
      numeric: false,
      disablePadding: false,
      label: t("OrderStock.DateOfReceipt"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "stockAmount",
      numeric: false,
      disablePadding: false,
      label: t("OrderStock.QuantityOfFeeds"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "remainingAmount",
      numeric: false,
      disablePadding: false,
      label: t("OrderStock.RemainingStockQuantity"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "stockAge",
      numeric: false,
      disablePadding: false,
      label: t("OrderStock.FeedShelfInventory"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
  ];

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#4B4B4B",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    },
    "&:nth-of-type(even)": {
      backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    },
  }));

  const scrollGridRef = useRef(null);
  const handleScroll = (e: any) => {
    const target: any = scrollGridRef.current;
    target.scrollLeft =
      (target.scrollWidth / 100) * e.target.value - (target.clientWidth ?? 200);
    setScrollValue(e.target.value);
  };

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      return (
        <StyledTableRow key={index}>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {dayjs(get(row, ["activityDate"], "")).format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {getCommaNumber(get(row, ["stockAmount"], 0))} {unit}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {get(row, ["remainingAmount"], 0) > 0
              ? getCommaNumber(get(row, ["remainingAmount"], 0))
              : 0}{" "}
            {unit}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {get(row, ["stockAge"], 0)} วัน
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }, [data]);

  return (
    <div className={classes.container}>
      <TableCustomMobile
        orderBy={""}
        order={"desc"}
        onChangeOrder={() => {}}
        onChangeOrderBy={() => {}}
        totalItemSize={data.length}
        headerItems={headerItems}
        page={0}
        onChangePage={() => {}}
        rowsPerPage={data.length}
        rows={data}
        EmptyComponent={
          data?.length === 0 ? (
            <div className={classes.emptyTable}>{t("manageStock.noData")}</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={[]}
        itemsSelected={[]}
        onSelectAllClick={() => {}}
        onSelectItem={() => {}}
        hideSelect
        hidePaginator
        _ref={scrollGridRef}
        isScrollBar
      />
      <div className="flex flex-row justify-center w-[100%] p-2">
        <Slider
          sx={{
            width: "90%",
            "& .MuiSlider-thumb": {
              borderRadius: "10px",
              backgroundImage: `url(${scrollImg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              color: "unset !important",
              backgroundColor: "transparent",
              width: 40,
            },
            "& .MuiSlider-rail": {
              color: "#E2E2E2 !important",
            },
            "& .MuiSlider-track": {
              backgroundColor: "grey !important",
              border: "1px solid grey !important",
            },
            "& .MuiSlider-valueLabelLabel": {
              fontSize: "20px",
              fontWeight: "bold",
              color: "unset",
              background: "unset",
            },
            cursor: "pointer",
          }}
          defaultValue={0}
          value={scrollValue}
          onChange={handleScroll}
          aria-label="Disabled slider"
        />
      </div>
    </div>
  );
};

const Details = () => {
  const { id } = useParams();
  const location = useLocation();

  const [data, setData] = useState<IMyStock[]>([]);

  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner/stock-details");
  }, []);

  const { data: GetMyStockId } = useQuery({
    queryKey: ["my-stock-partner-id"],
    queryFn: async () => {
      const result = await getMyStockPartnerId(id || "");
      return result;
    },
  });

  const { data: GetMyStockHistoryId } = useQuery({
    queryKey: ["my-stock-partner-history-id"],
    queryFn: async () => {
      const result = await getMyStockPartnerHistoryId(id || "");
      return result;
    },
  });

  useEffect(() => {
    setData(location.state);
  }, [id]);

  const dataId = data.map((i) => i.items.filter((i) => i.id === id));

  const [isOpenModalSeeDetails, setIsOpenModalSeeDetails] = useState(false);

  const onOpenModalSeeDetails = useCallback(
    () => setIsOpenModalSeeDetails(true),
    []
  );
  const onHideModalSeeDetails = useCallback(
    () => setIsOpenModalSeeDetails(false),
    []
  );

  const { t } = useTranslation();

  return (
    <div className="w-full h-screen bg-[#FFFFFF] flex flex-col justify-between">
      <div className="flex flex-col">
        <div className="h-[120px] flex items-center bg-[#FFFFFF]">
          <AppBarFS
            title={t("OrderStock.Warehouse")}
            onIconPress={handleBack}
          />
        </div>
        <div className="px-4 flex flex-col justify-between">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <div className="flex flex-row justify-between font-sans">
                <div className="flex flex-row gap-[6px]">
                  <PigFeedSvg />
                  <div className="text-[#34407B] text-[20px] font-semibold">
                    {get(data, [0, "feedBrand", "nameTh"], "")}
                  </div>
                </div>
                <div className="text-[#34407B] text-[18px] font-bold">
                  {get(dataId, [0, 0, "feedBrandItem", "skuTh"], "")}
                </div>
              </div>
              <div className="bg-[#F6FBFF] border-[#3777BC] border-[2px] py-3 gap-4 flex flex-col rounded-[20px] items-center justify-center">
                <div className="text-[#3777BC] text-[16px] font-bold">
                  title={t("OrderStock.RemainingStock")}
                </div>
                <div className="text-[#34407B] text-[22px] font-bold">
                  {get(dataId, [0, 0, "amount"], 0) > 0
                    ? getCommaNumber(get(dataId, [0, 0, "amount"], 0))
                    : 0}{" "}
                  {
                    unitMap[
                      get(dataId, [0, 0, "feedBrandItem", "unit"], "") as
                        | "bag"
                        | "kg"
                    ]
                  }
                </div>
              </div>
            </div>
            <Table
              data={GetMyStockId?.stockDetail || []}
              unit={
                unitMap[
                  get(dataId, [0, 0, "feedBrandItem", "unit"], "") as
                    | "bag"
                    | "kg"
                ]
              }
            />
          </div>
        </div>
      </div>
      <div className="px-4 py-6">
        <button
          className="w-full h-[59px] bg-[#68C184] rounded-[50px] py-4 px-1 flex items-center justify-center font-sans font-bold text-[16px] text-[#FFFFFF]"
          onClick={onOpenModalSeeDetails}
        >
          {t("OrderStock.MoreDetails")}
        </button>
      </div>
      <ModalSeeDetails
        isOpen={isOpenModalSeeDetails}
        onClose={onHideModalSeeDetails}
        brandName={get(data, [0, "feedBrand", "nameTh"], "")}
        skuName={get(dataId, [0, 0, "feedBrandItem", "skuTh"], "")}
        data={GetMyStockHistoryId?.stockDetailHistory || []}
      />
    </div>
  );
};

export default Details;
