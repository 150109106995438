import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SuccessCard from "../../../../components/common/SuccessCard";

type Props = {};

const AddStaffSuccess = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <SuccessCard
      title={t("RoleAndPermission.FinishedAddStaff")}
      buttonLabel={t("RoleAndPermission.SeeStaffDetail")}
      onButtonPress={() => {
        navigate("/partner/account-center/staff");
      }}
      subtitle1={t("RoleAndPermission.Congratulation")!}
      subtitle2={t("RoleAndPermission.YourFinishedAddStaff")}
      subtitle3={t("RoleAndPermission.LetSeeYourStaff")}
    />
  );
};

export default AddStaffSuccess;
