import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider, Link, SvgIcon, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_ENG } from "../../../../const/app.constant";
import {
  getAllUsersGroupArea,
  getAllUsersYear,
  getExportListAllUsers,
  getListAllUsers,
} from "../../../../services/dashboard/AllUsers.service";
import { TAllUsers } from "../../../../types/dashboard/AllUsers.type";
import { makeXlsx } from "../../../../utils/make-xlsx";
import { tw } from "../../../../utils/tw";
import { CalendarSvg } from "../../../assets/svg/Calendar.svg";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import DateRangePickerPopover from "../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import MenuBar from "../../../components/MenuBar/MenuBar";
import TableCustom from "../../../components/Table/TableCustom";
import { IGroupAreaRow } from "../../farm-detail/types/dropdownGroupArea.type";
import { Dropdown, TDropdownValue } from "../../group-area/views/Dropdown";
import { FILTER_DATE_FORMAT } from "../../sale-order/components/SaleOrderSearchFilter";

type Props = {};

const AllUsersTable = (props: Props) => {
  const navigate = useNavigate();

  const [activeStartDate, setActiveStartDate] = useState("");
  const [activeEndDate, setActiveEndDate] = useState("");
  const [isCalenderOpen, setIsCalenderOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");

  const [year, setYear] = useState<string>(dayjs().year().toString());
  const [yearOption, setYearOption] = useState<string[]>([]);

  const [groupArea, setGroupArea] = useState<TDropdownValue[]>([]);
  const [groupAreaOption, setGroupAreaOption] = useState<IGroupAreaRow[]>([]);

  const [segment, setSegment] = useState<TDropdownValue[]>([]);
  const segmentOption = ["Potential", "Grower", "Independent"];

  // table
  const [data, setData] = useState<TAllUsers[] | []>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderByType, setOrderByType] = useState<"desc" | "asc">("desc");
  const [currentOrderByKey, setCurrentOrderByKey] = useState<
    "customer_no" | "month"
  >("customer_no");

  const refCalendarRangeActiveDate = useRef<
    React.ElementRef<typeof DateRangePickerPopover>
  >({
    clearData: () => {},
    onOpen: () => {},
  });

  const handleSelectActiveDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);

    setActiveStartDate(formattedStartDate);
    setActiveEndDate(formattedEndDate);
  };

  const handleCloseCalender = () => {
    setIsCalenderOpen(false);
  };

  const handleCalenderSave = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);

    setActiveStartDate(formattedStartDate);
    setActiveEndDate(formattedEndDate);
  };

  const classes = {
    emptyTable: tw(
      `font-sans text-center w-full text-[#979797] text-[14px] py-3`
    ),
  };

  const headerItems = [
    {
      id: "active_date",
      numeric: false,
      disablePadding: false,
      label: "วันที่ active",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "customer_no",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "auto",
      isSorting: true,
      className: tw(`whitespace-pre`),
    },
    {
      id: "customer_name",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "month",
      numeric: false,
      disablePadding: false,
      label: "เดือน",
      width: "auto",
      isSorting: true,
      className: tw(`whitespace-pre`),
    },
    {
      id: "province",
      numeric: false,
      disablePadding: false,
      label: "จังหวัด",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "group_area",
      numeric: false,
      disablePadding: false,
      label: "Group Area",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "segment",
      numeric: false,
      disablePadding: false,
      label: "Segment",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "farm_size",
      numeric: false,
      disablePadding: false,
      label: "ขนาดฟาร์ม",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "farm_amount",
      numeric: false,
      disablePadding: false,
      label: "จำนวนฟาร์ม",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "barn_amount",
      numeric: false,
      disablePadding: false,
      label: "จำนวนโรงเรือน",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
  ];

  const rowChildren = useMemo(() => {
    return data.map((row, _index) => {
      return (
        <TableRow key={row.id}>
          <TableCell>{dayjs(row.createdAt).format("DD/MM/YYYY")}</TableCell>
          <TableCell>
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                console.info("I'm a button.");
              }}
              sx={{
                color: "#68C184",
                textDecorationColor: "#68C184",
                ":hover": {
                  fontSize: "16px",
                },
              }}
            >
              {row.registrationId}
            </Link>
          </TableCell>
          <TableCell>{row.farmerFullname}</TableCell>
          <TableCell>{row.month}</TableCell>
          <TableCell>{row.province}</TableCell>
          <TableCell>{row.groupArea}</TableCell>
          <TableCell>{row.segment}</TableCell>
          <TableCell>{row.farmSize}</TableCell>
          <TableCell>{row.farmAmount}</TableCell>
          <TableCell>{row.houseAmount}</TableCell>
        </TableRow>
      );
    });
  }, [data, currentPage]);

  const fetchYearOption = async () => {
    const data = await getAllUsersYear();
    setYearOption(data);
    setYear(data[0] ?? dayjs().year().toString());
  };

  const fetchGroupAreaOption = async () => {
    const data = await getAllUsersGroupArea();
    setGroupAreaOption(data);
  };

  const fetchAllUsersData = async () => {
    const tempData = await getListAllUsers({
      year: year,
      activeDateStart: activeStartDate
        ? dayjs(activeStartDate, "DD-MM-YYYY").startOf("day").toDate()
        : undefined,
      activeDateEnd: activeEndDate
        ? dayjs(activeEndDate, "DD-MM-YYYY").startOf("day").toDate()
        : undefined,
      groupAreaId: groupArea[0]?.value,
      segment: segment.map((item) => item.value.toLowerCase()).join(",") || "",
      searchText: searchText,
      orderBy: currentOrderByKey,
      direction: orderByType,
      page: currentPage,
    });
    if (tempData) {
      setData(tempData);
    }
  };

  useEffect(() => {
    fetchAllUsersData();
  }, [orderByType]);

  useEffect(() => {
    fetchYearOption();
    fetchGroupAreaOption();
    fetchAllUsersData();
  }, []);

  const handleExportData = async () => {
    const data = await getExportListAllUsers({
      year: year,
      activeDateStart: activeStartDate
        ? dayjs(activeStartDate, "DD-MM-YYYY").startOf("day").toDate()
        : undefined,
      activeDateEnd: activeEndDate
        ? dayjs(activeEndDate, "DD-MM-YYYY").startOf("day").toDate()
        : undefined,
      groupAreaId: groupArea[0]?.value,
      segment: segment.map((item) => item.value.toLowerCase()).join(",") || "",
      searchText: searchText,
      orderBy: currentOrderByKey,
      direction: orderByType,
      page: currentPage,
    });

    const dataExcel = data?.map((item) => {
      return {
        "วันที่ active": dayjs(item.createdAt).format(DATE_FORMAT_ENG),
        รหัสลูกค้า: item.registrationId,
        ชื่อลูกค้า: item.farmerFullname,
        เดือน: item.month,
        จังหวัด: item.province,
        "Group Area": item.groupArea,
        Segment: item.segment || "-",
        ขนาดฟาร์ม: item.farmSize || "-",
        จำนวนฟาร์ม: item.farmAmount || "-",
        จำนวนโรงเรือน: item.houseAmount || "-",
      };
    });
    const fileName = `All_Users_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(dataExcel || [], fileName, sheetName);
  };

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <div className="flex justify-start items-center gap-3">
              <div onClick={() => navigate("/admin/all-users")}>
                <ChevronLeftIcon fontSize="large" />
              </div>
              <span className="text-[#000] font-bold text-3xl">All Users</span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="flex flex-col py-6 gap-6">
              <div className="flex flex-row gap-3 items-end">
                <Dropdown
                  label="ปี"
                  options={yearOption.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => {
                    setYear(value[0].value);
                  }}
                  onReset={() => {
                    setYear("");
                  }}
                  renderValueArray={
                    [
                      {
                        value: year,
                        label: year,
                      },
                    ] as TDropdownValue[]
                  }
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                />
                <div className="flex flex-col gap-[6px] mb-[11px]">
                  <span className="text-sm font-medium font-sans">
                    วันที่ active
                  </span>
                  <TextInputCustom
                    placeholder="เริ่มต้น - สิ้นสุด"
                    value={
                      activeStartDate
                        ? activeStartDate + " - " + activeEndDate
                        : ""
                    }
                    InputProps={{
                      endAdornment:
                        activeStartDate !== "" ? (
                          <CloseRoundedIcon
                            sx={{
                              cursor: "pointer",
                              fontSize: "18px !important",
                            }}
                            onClick={(e) => {
                              setActiveStartDate("");
                              setActiveEndDate("");
                              e.stopPropagation();
                            }}
                          />
                        ) : (
                          <SvgIcon
                            sx={{
                              fontSize: "24px !important",
                              color: "#646464",
                            }}
                            fontSize="small"
                          >
                            <CalendarSvg />
                          </SvgIcon>
                        ),
                    }}
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      refCalendarRangeActiveDate.current?.onOpen(e);
                    }}
                  />
                </div>
                <Dropdown
                  label="Group Area"
                  options={groupAreaOption.map((row) => ({
                    value: row.id,
                    label: row.name,
                  }))}
                  onChange={(value) => {
                    setGroupArea(value);
                  }}
                  onReset={() => {
                    setGroupArea([]);
                  }}
                  renderValueArray={groupArea}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                />
                <Dropdown
                  label="Segment"
                  options={segmentOption.map((row) => ({
                    value: row,
                    label: row,
                  }))}
                  onChange={(value) => setSegment(value)}
                  onReset={() => {
                    setSegment([]);
                  }}
                  renderValueArray={segment}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                  multiple
                />
                <ButtonFillCustom
                  title="ค้นหา"
                  onClick={() => {
                    fetchAllUsersData();
                  }}
                  className="!mb-3"
                />
              </div>
              <div className="w-full flex justify-between">
                <TextInputSearchCustom
                  value={searchText}
                  className="max-w-[470px]"
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                  placeholder="ค้นหาโดยหมายเลขสั่งซื้อ, รหัสลูกค้า, ชื่อลูกค้า"
                  InputProps={{
                    endAdornment: searchText ? (
                      <CloseRoundedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchText("");
                        }}
                      />
                    ) : null,
                  }}
                />
                <ButtonOutlinedCustom
                  title="นำออก"
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  onClick={() => {
                    handleExportData();
                  }}
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                />
              </div>
            </div>

            <DateRangePickerPopover
              ref={refCalendarRangeActiveDate}
              id={"calendar-range-popover-active"}
              handleSelect={handleSelectActiveDate}
              openCalender={isCalenderOpen}
              onClose={handleCloseCalender}
              handleCalenderSave={handleCalenderSave}
              startDate={activeStartDate}
              endDate={activeEndDate}
            />

            <TableCustom
              order={orderByType}
              onChangeOrder={setOrderByType}
              orderBy={currentOrderByKey}
              onChangeOrderBy={setCurrentOrderByKey as any}
              totalItemSize={0}
              headerItems={headerItems}
              page={currentPage}
              onChangePage={setCurrentPage}
              rowsPerPage={10}
              rows={[]}
              EmptyComponent={
                data.length === 0 ? (
                  <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
                ) : null
              }
              rowChildren={rowChildren}
              currentPageItems={data!.map(({ id }) => id)}
              hideSelect
              itemsSelected={[]}
              onSelectItem={(_) => {}}
              onSelectAllClick={(_) => {}}
            />
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default AllUsersTable;
