import { Divider, SvgIcon, Tab, TableCell, Tabs } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import {
  getUploadPreSignURL,
  savePreSignURL,
} from "../../../../services/admin/sale-order/approvedOrder.service";
import { ITableRowDetail } from "../../../../types/EditOrderFeed";
import {
  BlobURL,
  IImportDocument,
  ImportDocumentReq,
  SaveImportDocumentURLReq,
} from "../../../../types/ImportDocument.type";
import { thousandComma } from "../../../../utils/common/string";
import delay from "../../../../utils/delay";
import { CreditHoldRejectOption } from "../../../../utils/enum/creditHoldRejectOption.enum";
import { DocumentSvg } from "../../../assets/svg/Document.svg";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import ImportDocumentModal from "../../../components/Modal/ImportDocumentModal";
import SuccessSnackBar from "../../../components/SnackBar/SuccessSnackBar";
import {
  TabPanelCustom,
  a11yProps,
} from "../../../components/TabPanelCustom/TabPanelCustom";
import TableBasicInfo from "../../../components/Table/TableBasicInfo";
import DocumentDetail from "./DocumentDetail";
import HistoryChangePanel from "./HistoryChangePanel";
import OrderDetailPanel from "./OrderDetailPanel";
import { useTranslation } from "react-i18next";

export interface ICellOrderDetailProps {
  id: number | string;
  index: number;
  colSpan: number;
  orderStatus: string;
  order: ITableRowDetail;
  showApproveBtn?: boolean;
  showRejectBtn?: boolean;
  showDeliveryBtn?: boolean;
  showManageStockBtn?: boolean;
  showDocumentDetail?: boolean;
  showReadyToDeliveryChip?: boolean;
  ShowEditOrderBtn?: boolean;
  handleFetch: () => void;
  tabName: string;
}

function CellOrderDetail({
  id,
  orderStatus,
  index,
  colSpan,
  order,
  showApproveBtn,
  showRejectBtn,
  showDeliveryBtn,
  showManageStockBtn,
  showDocumentDetail,
  ShowEditOrderBtn,
  handleFetch,
  showReadyToDeliveryChip,
  tabName,
}: ICellOrderDetailProps) {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [openImportDocumentModal, setOpenImportDocumentModal] =
    React.useState(false);
  const [openSuccessSnackBar, setOpenSuccessSnackBar] = useState(false);
  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };
  const handleUploadDocument = async (files: IImportDocument) => {
    const body: ImportDocumentReq = {
      customerPickingSlip: files.customerPickingSlip.length,
      customerInvoice: files.customerInvoice.length,
      customerReceipt: files.customerReceipt.length,
      partnerPickingSlipForSale: files.partnerPickingSlip.length,
    };
    const urls = await getUploadPreSignURL(order?.id || "", body);

    const saveURLs: SaveImportDocumentURLReq = {
      customerPickingSlip: [],
      customerInvoice: [],
      customerReceipt: [],
      partnerPickingSlipForSale: [],
    };

    if (urls?.result) {
      try {
        await Promise.all(
          [
            urls.result.customerPickingSlip.map(async (row, idx) => {
              await handleUploadEach(files.customerPickingSlip[idx], row);
              const id = row.id;
              saveURLs.customerPickingSlip.push({
                fileName: files.customerPickingSlip[idx].name,
                fileSize: files.customerPickingSlip[idx].size,
                url: extractPathName(row.blobUrl),
                id,
              });
            }),
            urls.result.customerInvoice.map(async (row, idx) => {
              const id = row.id;
              await handleUploadEach(files.customerInvoice[idx], row);
              saveURLs.customerInvoice.push({
                fileName: files.customerInvoice[idx].name,
                fileSize: files.customerInvoice[idx].size,
                url: extractPathName(row.blobUrl),
                id,
              });
            }),
            urls.result.customerReceipt.map(async (row, idx) => {
              const id = row.id;
              await handleUploadEach(files.customerReceipt[idx], row);
              saveURLs.customerReceipt.push({
                fileName: files.customerReceipt[idx].name,
                fileSize: files.customerReceipt[idx].size,
                url: extractPathName(row.blobUrl),
                id,
              });
            }),
            urls.result.partnerPickingSlipForSale.map(async (row, idx) => {
              const id = row.id;
              await handleUploadEach(files.partnerPickingSlip[idx], row);
              saveURLs.partnerPickingSlipForSale.push({
                fileName: files.partnerPickingSlip[idx].name,
                fileSize: files.partnerPickingSlip[idx].size,
                url: extractPathName(row.blobUrl),
                id,
              });
            }),
          ].flat()
        );
        await savePreSignURL(order?.id || "", saveURLs);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleUploadEach = async (file: File, url: BlobURL) => {
    await axios.put(url.blobUrl, file, {
      responseType: "blob",
      headers: {
        "Content-Type": file.type,
        "x-ms-blob-type": "BlockBlob",
      },
    });
  };

  const extractPathName = (url: string) => {
    const urlInstance = new URL(url);
    return urlInstance.pathname.split("/").slice(2).join("/");
  };

  const handleSubmitDocument = async (files: IImportDocument) => {
    try {
      await handleUploadDocument(files);
      setOpenImportDocumentModal(false);
      setOpenSuccessSnackBar(true);
    } catch (error) {
      throw error;
    } finally {
      await delay(1500);
      handleFetch();
    }
  };

  return (
    <>
      <TableCell colSpan={colSpan} sx={{ bgcolor: "#F6FBFF" }}>
        <div className="w-3/4 h-full px-6 py-2">
          {orderStatus === "not_approved" && (
            <div className="pt-3 pr-10 pb-6">
              <TableBasicInfo
                tableData={[
                  {
                    detailName: "เหตุผลที่ไม่อนุมัติ",
                    detailValue:
                      CreditHoldRejectOption[
                        order.rejectReason as keyof typeof CreditHoldRejectOption
                      ] || order.rejectReason,
                    color: "#D32F2F",
                    headerColor: "#D32F2F",
                    fontSize: "14px",
                  },
                  {
                    detailName: "ไม่อนุมัติโดย",
                    detailValue:
                      order.rejectedStatus === "waiting_for_sale_approval"
                        ? order.orderUpdatedBy?.name ||
                          order.orderUpdatedBy?.email
                        : order.creditUpdatedBy?.name ||
                          order.creditUpdatedBy?.email,
                    color: "#D32F2F",
                    headerColor: "#D32F2F",
                    fontSize: "14px",
                  },
                ]}
                titleWidth="166px"
              />
            </div>
          )}
          {order?.status === "rejected" && (
            <div className="pt-3 pr-10 pb-4">
              <TableBasicInfo
                tableData={[
                  {
                    detailName: "เหตุผลที่ไม่อนุมัติ",
                    detailValue:
                      CreditHoldRejectOption[
                        order.rejectReason as keyof typeof CreditHoldRejectOption
                      ] || order.rejectReason,
                    color: "#D32F2F",
                    fontSize: "14px",
                  },
                  {
                    detailName: "ไม่อนุมัติโดย",
                    detailValue:
                      order.rejectedStatus === "waiting_for_sale_approval"
                        ? order.orderUpdatedBy?.name ||
                          order.orderUpdatedBy?.email
                        : order.creditUpdatedBy?.name ||
                          order.creditUpdatedBy?.email,
                    color: "#D32F2F",
                    fontSize: "14px",
                  },
                ]}
                titleWidth="166px"
              />
            </div>
          )}
          <div className="flex justify-between items-center">
            <div>
              <SvgIcon sx={{ color: "#68C184", fontSize: "24px" }}>
                <DocumentSvg />
              </SvgIcon>
              <span className="font-sans font-bold text-[18px] leading-5 text-[#3777BC]">
                {t("saleOrder.approvedOrdersTab.contractNo")}{" "}
                {order?.contract?.contractNumber}
              </span>
            </div>
            {showDocumentDetail && ShowEditOrderBtn && (
              <ButtonOutlinedCustom
                onClick={() => setOpenImportDocumentModal(true)}
                title={t("saleOrder.approvedOrdersTab.uploadDocuments")}
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
              />
            )}
          </div>
          <div className="flex flex-row pt-3 gap-6">
            <div className="flex flex-col min-w-[488px] max-w-[488px]">
              <div className="w-full">
                <span className="font-sans font-bold text-[1rem] leading-6 text-[#68C184]">
                  {t("saleOrder.approvedOrdersTab.customerInfo")}
                </span>
                <div className="pt-3 pr-10">
                  <TableBasicInfo
                    tableData={[
                      {
                        detailName: "รหัสลูกค้า",
                        detailValue:
                          order?.contract?.house.farm.farmer.registrationId,
                        color: "#1B2128",
                        fontSize: "14px",
                      },
                      {
                        detailName: "ชื่อลูกค้า",
                        detailValue:
                          order?.contract?.house.farm.farmer.name +
                          " " +
                          order?.contract?.house.farm.farmer.surname,
                        color: "#1B2128",
                        fontSize: "14px",
                      },
                      {
                        detailName: "ที่อยู่",
                        detailValue:
                          order?.contract?.house.farm.address +
                          " " +
                          order?.contract?.house.farm.subDistrict.nameTh +
                          " " +
                          order?.contract?.house.farm.district.nameTh +
                          " " +
                          order?.contract?.house.farm.province.nameTh +
                          " " +
                          order?.contract?.house.farm.postalCode,
                        color: "#1B2128",
                        fontSize: "14px",
                      },
                    ]}
                    titleWidth="166px"
                  />
                </div>
              </div>
            </div>
            <Divider
              orientation="vertical"
              variant="fullWidth"
              flexItem
              sx={{
                borderColor: "#C9C9C9",
              }}
            />
            <div className="">
              <span className="font-sans font-bold text-[1rem] leading-6 text-[#68C184]">
                {t("saleOrder.approvedOrdersTab.contractInfo")}
              </span>
              <div className="pt-3">
                <TableBasicInfo
                  tableData={[
                    {
                      detailName: t("saleOrder.approvedOrdersTab.creditLimit"),
                      detailValue:
                        thousandComma(Number(order?.contract?.credit), 2) +
                        " บาท",
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: t(
                        "saleOrder.approvedOrdersTab.amoutExceedingTheLimit"
                      ),
                      detailValue:
                        thousandComma(order?.contract?.exceedAmount, 2) +
                        " บาท",
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: t("saleOrder.approvedOrdersTab.amountSpent"),
                      detailValue:
                        thousandComma(
                          Math.min(
                            Number(order?.contract?.creditUsed),
                            Number(order?.contract?.credit)
                          ),
                          2
                        ) + " บาท",
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: "วงเงินเครดิตคงเหลือ",
                      detailValue:
                        thousandComma(
                          (Math.max(
                            (order?.contract?.credit || 0) -
                              (order?.contract?.creditUsed || 0),
                            0
                          ) /
                            (order?.contract?.credit || 1)) *
                            100,
                          2
                        ) + " %",
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: t("saleOrder.approvedOrdersTab.creditTerm"),
                      detailValue: (order?.contract?.creditTerm || 0) + " วัน",
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: t("saleOrder.approvedOrdersTab.overdurDays"),
                      detailValue:
                        (order?.contract?.dayOfDebtOutStanding || 0) + " วัน",
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: t("saleOrder.approvedOrdersTab.grading"),
                      detailValue: order?.contract?.creditRating?.toUpperCase(),
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                  ]}
                  titleWidth="155px"
                />
              </div>
            </div>
          </div>
          {showDocumentDetail && <DocumentDetail order={order} />}
          <div className="pt-6 h-full w-full">
            <div className="p-6 bg-white rounded-[20px]">
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label={`order-tab-control-${id}`}
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
                sx={{
                  height: "36px",
                  minHeight: "unset",
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-flexContainer": {
                      alignItems: "end",
                      height: "100%",
                      display: "flex",
                      gap: "12px",
                      "& .MuiTab-root": {
                        padding: "6px 28px",
                        textTransform: "unset",
                        fontFamily: "IBM Plex Sans Thai",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "unset",
                        color: "#000000",
                        borderBottom: "3px solid #DDF3E4",
                        "& .Mui-selected": {
                          color: "#000000",
                        },
                      },
                    },
                  },
                }}
              >
                <Tab
                  label={`รายละเอียดคำสั่งซื้อ`}
                  {...a11yProps(`order-item-${id}`, 0)}
                />
                <Tab
                  label={t("saleOrder.approvedOrdersTab.revisionLog")}
                  {...a11yProps(`order-item-${id}`, 1)}
                />
              </Tabs>
              <TabPanelCustom
                value={tabIndex}
                index={0}
                prefix={`order-item-${id}`}
              >
                <OrderDetailPanel
                  order={order}
                  id={id}
                  showApproveBtn={showApproveBtn}
                  showRejectBtn={showApproveBtn}
                  showDeliveryBtn={showDeliveryBtn}
                  showManageStockBtn={showManageStockBtn}
                  showReadyToDeliveryChip={showReadyToDeliveryChip}
                  ShowEditOrderBtn={ShowEditOrderBtn}
                  disableDeliveryBtn={order.farmSize === "s"}
                  handleFetch={async () => await handleFetch()}
                  tabName={tabName}
                />
              </TabPanelCustom>
              <TabPanelCustom
                value={tabIndex}
                index={1}
                prefix={`order-item-${id}`}
              >
                <HistoryChangePanel orderFeedLogs={order.orderFeedLogs} />
              </TabPanelCustom>
            </div>
          </div>
        </div>
      </TableCell>
      <ImportDocumentModal
        initialValues={order?.orderFeedFiles}
        id={order?.id || ""}
        open={openImportDocumentModal}
        onClose={() => {
          setOpenImportDocumentModal(false);
        }}
        onSubmit={handleSubmitDocument}
      />
      <SuccessSnackBar
        openSnackbar={openSuccessSnackBar}
        handleCloseSnackbar={() => {
          setOpenSuccessSnackBar(false);
        }}
        snackbarText={"บันทึกสำเร็จ"}
      />
    </>
  );
}

export default CellOrderDetail;
