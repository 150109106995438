import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { TableCell, TableRow } from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Switch from "../../../../../../components/Switch";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../../components/Table/TableCustom";
import { useMedicineFilterHook } from "../../../hooks/medicine/useMedicineFilter.hook";
import { useMedicineTableHook } from "../../../hooks/medicine/useMedicineTable.hook";
import { toggleMedicine } from "../../../services/medicine.service";
import { IVaccineTable } from "../../../types/vaccine.type";

interface IActiveMedicineForm {
  medicines: ToggleMedicine[];
}

interface ToggleMedicine {
  id: string;
  tradeName: string;
  tradeNameEn: string;
  isActive: boolean;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ToggleMedicine = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const searchMedicine = useMedicineFilterHook();
  const { handleFetchEditData, medicine } =
    useMedicineTableHook(searchMedicine);

  const headerItems: ITableCustomHeadCell<keyof IVaccineTable>[] = [
    {
      id: "vaccineNameTH",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.medicine.openCloseModal.medicineName")} (TH)`,
      width: "200px",
      isSorting: false,
    },
    {
      id: "vaccineNameEN",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.medicine.openCloseModal.medicineName")} (EN)`,
      width: "200px",
      isSorting: false,
    },
    {
      id: "vaccineNameEN",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
      isSorting: false,
    },
  ];

  const medicines = { medicines: medicine || [] };

  const methods = useForm<IActiveMedicineForm>({
    defaultValues: {
      medicines: [
        {
          id: "",
          tradeName: "",
          tradeNameEn: "",
          isActive: false,
        },
      ],
    },
  });

  const MedicineWatched = useWatch({
    name: "medicines",
    control: methods.control,
  });

  const handleToggleChangeStatus = (index: number) => {
    const currentStatus = methods.getValues(`medicines.${index}.isActive`);
    methods.setValue(`medicines.${index}.isActive`, !currentStatus);
  };

  const handleSubmit = (value: IActiveMedicineForm) => {
    const payload = value.medicines.map((medicine) => {
      return {
        id: medicine.id,
        isActive: medicine.isActive,
      };
    });
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await toggleMedicine({ medicines: payload });
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            onClose();
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const handleSearch = async () => {
    if (searchMedicine.searchText.trim()) {
      const filteredMedicines = (medicine || []).filter(
        (med) =>
          med.tradeName
            ?.toLowerCase()
            .includes(searchMedicine.searchText.toLowerCase()) ||
          med.tradeNameEn
            ?.toLowerCase()
            .includes(searchMedicine.searchText.toLowerCase())
      );
      methods.reset({ medicines: filteredMedicines });
    } else {
      methods.reset(medicines);
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchMedicine.searchText, searchMedicine.toggleFetch]);

  useEffect(() => {
    handleFetchEditData();
    methods.reset(medicines);
  }, [isOpen, medicine]);

  return (
    <>
      <AdminModal
        label={t("masterData.medicine.openCloseModal.title")}
        isOpen={isOpen}
        size={"md"}
        handleClose={onClose}
        children={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <TextInputSearchCustom
                value={searchMedicine.searchText}
                placeholder="ค้นหาโดยชื่อวัคซีน"
                onChange={(event) => {
                  searchMedicine.setSearchText(event.target.value);
                }}
                InputProps={{
                  endAdornment:
                    searchMedicine.searchText.length > 0 ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {
                          searchMedicine.setSearchText("");
                          searchMedicine.setToggleFetch((prev) => !prev);
                        }}
                      />
                    ) : null,
                }}
              />
              <div className="mt-4">
                <TableCustom
                  order={"desc"}
                  orderBy={""}
                  itemsSelected={[]}
                  rowsPerPage={medicine?.length || 0}
                  page={medicine?.length || 0}
                  headerItems={headerItems}
                  rows={medicine ? medicine : []}
                  onChangeOrderBy={() => {}}
                  onChangeOrder={() => {}}
                  onChangePage={() => {}}
                  onSelectItem={() => {}}
                  onSelectAllClick={() => {}}
                  totalItemSize={medicine?.length || 0}
                  currentPageItems={[]}
                  hideSelect
                  hidePaginator
                  maxHeightForScroll="550px"
                  rowChildren={MedicineWatched.map((row, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.tradeName}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.tradeNameEn}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <Switch
                            checked={row.isActive}
                            onChange={() => handleToggleChangeStatus(index)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                />
              </div>
              <div className="flex justify-end">
                <ButtonFillCustom
                  title={t("common.button.confirm")}
                  type="submit"
                />
              </div>
            </form>
          </FormProvider>
        }
      />
    </>
  );
};

export default ToggleMedicine;
