import { Stack, Tab, Tabs } from "@mui/material";
import React, { useMemo, useState } from "react";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { TabPanelCustom } from "../../../../../components/TabPanelCustom/TabPanelCustom";
import SelectArticleTab, { ArrangedArticles } from "../tabs/SelectArticleTab";
import AddSelectSortArticle from "./AddSelectSortArticle";

type Props = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
  handleFetch: () => void;
};

const SelectSortArticleModal = ({
  isOpen,
  handleClose,
  handleFetch,
}: Props) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isOpenSelectArticle, setIsOpenSelectArticle] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [typeTab, setTypeTab] = useState("article");

  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
    if (value === 0) {
      setTypeTab("article");
    } else {
      setTypeTab("video");
    }
  };

  const titleBtn = useMemo(() => ["เพิ่มบทความ", "เพิ่มวิดิโอ"], []);

  return (
    <>
      <AdminModal
        label={"เรียงลำดับ"}
        isOpen={isOpen}
        size={"sm"}
        handleClose={() => handleClose(false)}
        children={
          <>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label="sale-order-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
                sx={{
                  height: "36px",
                  minHeight: "unset",
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-flexContainer": {
                      alignItems: "end",
                      height: "100%",
                      display: "flex",
                      gap: "12px",
                      "& .MuiTab-root": {
                        padding: "6px 28px",
                        textTransform: "unset",
                        fontFamily: "IBM Plex Sans Thai",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "unset",
                        color: "#000000",
                        backgroundColor: "#FFFFFF",
                        borderBottom: "3px solid #DDF3E4",
                        "&.Mui-selected": {
                          backgroundColor: "#FFFFFF !important",
                          color: "#000000 !important",
                        },
                      },
                    },
                  },
                }}
              >
                <Tab label={"บทความ"} />
                <Tab label={"วิดิโอ"} />
              </Tabs>
              <div>
                <ButtonOutlinedCustom
                  title={titleBtn[tabIndex]}
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  onClick={() => setIsOpenSelectArticle(true)}
                />
              </div>
            </Stack>
            <TabPanelCustom value={tabIndex} index={0} prefix="select-article">
              <SelectArticleTab
                handleFetch={handleFetch}
                handleClose={handleClose}
                toggleFetch={toggleFetch}
                type={typeTab}
              />
            </TabPanelCustom>
            <TabPanelCustom value={tabIndex} index={1} prefix="select-video">
              <SelectArticleTab
                handleFetch={handleFetch}
                handleClose={handleClose}
                toggleFetch={toggleFetch}
                type={typeTab}
              />
            </TabPanelCustom>
          </>
        }
      />
      <AddSelectSortArticle
        type={typeTab}
        isOpen={isOpenSelectArticle}
        handleClose={(isSuccess) => {
          if (isSuccess) {
            setToggleFetch((prev) => !prev);
          }
          setIsOpenSelectArticle(false);
        }}
      />
    </>
  );
};

export default SelectSortArticleModal;
