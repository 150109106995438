export default function () {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.335938" width="40" height="40" rx="20" fill="#5572EB" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7344 12.8008C11.6298 12.8008 10.7344 13.6962 10.7344 14.8008V20.4008C10.7344 21.5054 11.6298 22.4008 12.7344 22.4008H13.1344V15.2008H25.1344V14.8008C25.1344 13.6962 24.239 12.8008 23.1344 12.8008H12.7344ZM15.5344 19.6008C15.5344 18.4962 16.4298 17.6008 17.5344 17.6008H27.9344C29.039 17.6008 29.9344 18.4962 29.9344 19.6008V25.2008C29.9344 26.3054 29.039 27.2008 27.9344 27.2008H17.5344C16.4298 27.2008 15.5344 26.3054 15.5344 25.2008V19.6008ZM22.7344 24.8008C24.0599 24.8008 25.1344 23.7263 25.1344 22.4008C25.1344 21.0753 24.0599 20.0008 22.7344 20.0008C21.4089 20.0008 20.3344 21.0753 20.3344 22.4008C20.3344 23.7263 21.4089 24.8008 22.7344 24.8008Z"
        fill="white"
      />
    </svg>
  );
}
