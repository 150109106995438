import { get } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useShowButtonByRoles } from "../../../hooks/useActionButtonByRoles";
import { alertAction } from "../../../redux/slices/alertSlice";
import { loadingActions } from "../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../redux/store";
import { deleteRole, getRoleListByType } from "../../../services/Role.service";
import { IRoleListResponse } from "../../../types/Role.type";
import { UserFunctionRole, UserRole } from "../../../utils/enum/user-role.enum";
import getErrorMessage from "../../../utils/getErrorMessage";

export const useRoleController = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTab, setCurrentTab] = useState<{
    value: string;
    label: string;
  }>({ label: "พาร์เนอร์", value: "partner" });
  const [currentTabRoleAndPermission, setCurrentTabRoleAndPermission] =
    useState(0);
  const [isShowAddModal, setIsShowAddModal] = useState(false);

  const openAdd = useCallback(() => setIsShowAddModal(true), []);
  const closeAdd = useCallback(() => setIsShowAddModal(false), []);

  const [count, setCount] = useState({
    partner: "-",
    employee: "-",
  });
  const [initRoleData, setRoleData] = useState<IRoleListResponse["roles"]>([]);
  const [permissionData, setPermissionData] = useState([]);

  const onChangeTabRoleAndPermission = useCallback((e: any, index: number) => {
    setCurrentTabRoleAndPermission(index);
  }, []);

  const onAddRole = useCallback(() => {
    openAdd();
  }, []);

  const onRemove = useCallback(
    (id: string) => () => {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการลบ",
          text: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          cancelText: "ยกเลิก",
          confirmText: "ยืนยัน",
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: () => {
            dispatch(loadingActions.show({ message: "กำลังลบรายการ..." }));
            deleteRole(id)
              .then(() => {
                refetch();
                dispatch(alertAction.hide());
                setTimeout(() => {
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: "ลบสำเร็จ",
                      type: "success",
                    })
                  );
                }, 1000);
              })
              .catch((e) => {
                console.log("fail to delete role error:", e);
                dispatch(alertAction.hide());
                setTimeout(() => {
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: getErrorMessage(e),
                      type: "error",
                    })
                  );
                }, 1000);
              });
          },
        })
      );
    },
    []
  );

  const initData = useCallback(() => {
    Promise.all([getRoleListByType("partner"), getRoleListByType("farmTech")])
      .then(([partner, employee]) => {
        setCount({
          partner: `${(get(partner, "roles", []) || []).length}`,
          employee: `${(get(employee, "roles", []) || []).length}`,
        });
      })
      .catch((e) => {
        console.log("fail to init role data error:", e);
      });
  }, []);

  const roleData = useMemo(() => {
    if (initRoleData.length === 0) return [];
    const pageSize = 10;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = currentPage * pageSize;
    return initRoleData.slice(startIndex, endIndex);
  }, [initRoleData]);

  useEffect(() => {
    const type = currentTab.value === "employee" ? "farmTech" : "partner";
    getRoleListByType(type)
      .then((result) => {
        setRoleData(get(result, "roles", []) || []);
      })
      .catch((e) => {
        console.log("fail to getRoleListByType error:", e);
      });
  }, [currentTab]);

  useEffect(() => {
    initData();
  }, []);

  const refetch = useCallback(() => {
    initData();
    const type = currentTab.value === "employee" ? "farmTech" : "partner";
    getRoleListByType(type)
      .then((result) => {
        setRoleData(get(result, "roles", []) || []);
      })
      .catch((e) => {
        console.log("fail to getRoleListByType error:", e);
      });
  }, [currentTab]);

  const { showButton } = useShowButtonByRoles([
    UserFunctionRole.admin,
    UserFunctionRole.management,
  ]);

  return {
    currentTab,
    setCurrentTab,
    currentTabRoleAndPermission,
    onChangeTabRoleAndPermission,
    onAddRole,
    onRemove,
    count,
    roleData,
    permissionData,
    currentPage,
    setCurrentPage,
    isShowAddModal,
    openAdd,
    closeAdd,
    initData,
    refetch,
    showButton,
  };
};
