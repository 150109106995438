import { Checkbox, Link, TableCell } from "@mui/material";
import { useEffect, useState } from "react";
import { useMoneyDebtFilter } from "../../hooks/useMoneyDebtFilter.hook";
import { useTrackingDebtTableHook } from "../../hooks/useTrackingDebtTable.hook";
import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../../components/Table/TableCustom";
import { TTrackingDebtTable } from "../../types/trackingDebt.type";
import { MoneyDebtSearch } from "../../components/MoneyDeptSearch";
import ExpandTableMoneyDebt from "../../components/ExpandTableMoneyDebt/ExpandTableMoneyDebt";
import { EInvoiceDebtPaymentOptionDisplay } from "../../../../../../types/invoice/log.type";
import ViewDocumentModal from "../../components/Modals/ViewDocumentModal";
import ExpandListLog from "../../components/ExpandTableMoneyDebt/ExpandListLog";

type Props = {};

const FinishDebt = ({}: Props) => {
  const searchTrackingDebtFilter = useMoneyDebtFilter();
  const {
    list,
    itemSelected,
    setItemSelected,
    isSelected,
    handleClick,
    handleExportExcel,
    handleFetch,
    handleSelectAllClick,
    count,
    dataFollowDebt,
  } = useTrackingDebtTableHook(searchTrackingDebtFilter, "finished");

  const [installmentId, setInstallmentId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [isOpenViewDoc, setIsOpenViewDoc] = useState(false);

  const headCells: ITableCustomHeadCell<keyof TTrackingDebtTable>[] = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: false,
      label: "หมายเลขสั่งซื้อ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "150px",
      isSorting: false,
    },
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: "เลข Invoice",
      width: "150px",
      isSorting: false,
    },
    {
      id: "payment",
      numeric: false,
      disablePadding: false,
      label: "รูปเเบบการจ่าย",
      width: "150px",
      isSorting: false,
    },
    {
      id: "paidAmount",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่ต้องชำระ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "monthsOfInstallment",
      numeric: false,
      disablePadding: false,
      label: "จำนวนเดือนที่ผ่อน",
      width: "150px",
      isSorting: false,
    },
    {
      id: "installmentNumber",
      numeric: false,
      disablePadding: false,
      label: "ครั้งที่ผ่อนชำระ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "paymentPerMonth",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่ต้องชำระ/เดือน",
      width: "150px",
      isSorting: false,
    },
    {
      id: "totalPaid",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่ชำระเเล้ว",
      width: "150px",
      isSorting: false,
    },
    {
      id: "dueDate",
      numeric: false,
      disablePadding: false,
      label: "วันครบกำหนดชำระ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "วันที่อัปเดตล่าสุด",
      width: "150px",
      isSorting: true,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
      isSorting: false,
    },
  ];

  useEffect(() => {
    handleFetch();
  }, [searchTrackingDebtFilter.direction, searchTrackingDebtFilter.page]);

  return (
    <>
      <MoneyDebtSearch
        showSelect={false}
        searchMoneyDeptFilter={searchTrackingDebtFilter}
        itemSelected={itemSelected}
        onExport={handleExportExcel}
        onSearch={handleFetch}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchTrackingDebtFilter.direction}
          orderBy={"updateDate"}
          rowsPerPage={10}
          rows={list}
          itemsSelected={itemSelected}
          headerItems={headCells}
          page={searchTrackingDebtFilter.page}
          onChangeOrderBy={() => {}}
          onChangeOrder={(val) => {
            searchTrackingDebtFilter.setDirection(val);
          }}
          onChangePage={(val) => searchTrackingDebtFilter.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={list.map((row) => row.id)}
          rowChildren={
            <>
              {list.map((row, index) => {
                const isItemSelected = isSelected(row.debtInstallmentId!);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <ExpandableTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    expandComponent={
                      <ExpandListLog
                        dataExpandLog={
                          dataFollowDebt ? dataFollowDebt[index] : undefined
                        }
                        isExpandLogLending={true}
                      />
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) =>
                          handleClick(event, row.debtInstallmentId!)
                        }
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {
                        EInvoiceDebtPaymentOptionDisplay[
                          row.payment as keyof typeof EInvoiceDebtPaymentOptionDisplay
                        ]
                      }
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paidAmount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.monthsOfInstallment}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.installmentNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paymentPerMonth}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.totalPaid}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.dueDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.updateDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                        }}
                        onClick={() => {
                          setIsOpenViewDoc(true);
                          setInstallmentId(row.debtInstallmentId!);
                          setInvoiceId(row.id);
                        }}
                      >
                        ดูเอกสาร
                      </Link>
                    </TableCell>
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
        <ViewDocumentModal
          isOpen={isOpenViewDoc}
          handleClose={() => {
            setIsOpenViewDoc(false);
            setInstallmentId("");
          }}
          installmentId={installmentId}
          invoiceId={invoiceId}
          roleName="lending"
          tabLendingName="finished"
        />
      </div>
    </>
  );
};

export default FinishDebt;
