import { Chip } from "@mui/material";
import { StatusEnum, TColor } from "./type";
import { useTranslation } from "react-i18next";

type TStatusOrderFeedProps = {
  status: StatusEnum;
  isReportOrder?: boolean;
};

type NewType = {
  label: string;
  color: TColor;
};

const StatusFarmerOrderFeed = ({
  status,
  isReportOrder,
}: TStatusOrderFeedProps) => {
  const { t } = useTranslation();

  const labelWithColor: {
    [k in StatusEnum]?: NewType;
  } = {
    waiting_for_sale_approval: {
      label: t("feedOrderAndDelivery.myOrder.card.status.placeOrder"),
      color: "warning",
    },
    waiting_for_sale_send_to_lender: {
      label: t("feedOrderAndDelivery.myOrder.card.status.placeOrder"),
      color: "warning",
    },
    waiting_for_lender_approval: {
      label: t("feedOrderAndDelivery.myOrder.card.status.placeOrder"),
      color: "warning",
    },
    waiting_for_delivery: {
      label: t("feedOrderAndDelivery.myOrder.card.status.waitForDelivery"),
      color: "warning",
    },
    delivery: {
      label: t("feedOrderAndDelivery.myOrder.card.status.delivery"),
      color: "warning",
    },
    waiting_for_acceptance: {
      label: t("feedOrderAndDelivery.myOrder.card.status.waitToAccept"),
      color: "warning",
    },
    accepted: {
      label: t("feedOrderAndDelivery.myOrder.card.status.accepted"),
      color: "success",
    },
    canceled: {
      label: t("feedOrderAndDelivery.myOrder.card.status.cancelled"),
      color: "error",
    },
    rejected: {
      label: t("feedOrderAndDelivery.myOrder.card.status.cancelled"),
      color: "error",
    },
  };

  return (
    <>
      {isReportOrder ? (
        <Chip
          label={t("feedOrderAndDelivery.myOrder.card.status.report")}
          color="error"
          variant="outlined"
          size="small"
        />
      ) : (
        <Chip
          label={labelWithColor[status]?.label}
          color={labelWithColor[status]?.color}
          variant="outlined"
          size="small"
        />
      )}
    </>
  );
};

export default StatusFarmerOrderFeed;
