import React from "react";

import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";

import { Checkbox, Link, TableCell } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useInvoiceSearchFilter } from "../../../../../hooks/useInvoiceSearchFilter.hook";
import {
  getListExportProcessManager,
  getProcessInvoiceManager,
} from "../../../../../services/admin/invoice/invoiceManager.service";
import { IRowProcessInvoiceManager } from "../../../../../types/invoice/invoiceManager.type";
import { thousandComma } from "../../../../../utils/common/string";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import ExpandInvoiceTable from "../../components/ExpandInvoiceTable";
import { FilterInvoice } from "../../components/FilterInvoice";
import { IInvoiceTable, TInvoiceAssignmentApproveStatus } from "../../type";

type Props = { handleFetchCount: () => void };

const ProcessedInvoicePanel = ({ handleFetchCount }: Props) => {
  const { t } = useTranslation();
  const [itemSelected, setItemSelected] = React.useState<string[]>([]);
  const [orderInvoice, setOrderInvoice] = React.useState<IInvoiceTable[]>([]);
  const [count, setCount] = React.useState(0);
  const [invoiceExpand, setInvoiceExpand] = React.useState<
    IRowProcessInvoiceManager[]
  >([]);

  function createData(
    id: string,
    workSheetNumber: string,
    customerId: string,
    customerName: string,
    partnerFollowInvoice: string,
    saleName: string,
    totalAmountDue: number,
    receiveDate: string,
    requestResponseDate: string,
    status: TInvoiceAssignmentApproveStatus
  ): IInvoiceTable {
    return {
      id,
      workSheetNumber: workSheetNumber ? workSheetNumber : "-",
      customerId: customerId ? customerId : "-",
      customerName: customerName ? customerName : "-",
      partnerFollowInvoice: partnerFollowInvoice ? partnerFollowInvoice : "-",
      saleName: saleName ? saleName : "-",
      totalAmountDue: totalAmountDue
        ? `${thousandComma(+totalAmountDue, 2)} ${t("common.bath")}`
        : "-",
      receiveDate: receiveDate ? dayjs(receiveDate).format("DD/MM/YYYY") : "-",
      requestResponseDate: requestResponseDate
        ? dayjs(requestResponseDate).format("DD/MM/YYYY")
        : "-",
      status: status as TInvoiceAssignmentApproveStatus,
    };
  }

  const headCells: ITableCustomHeadCell<keyof IInvoiceTable>[] = [
    {
      id: "workSheetNumber",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.acctionedInvoiceTab.management.assignedCaseDetails.assignedNo"
      ),
      width: "150px",
      isSorting: true,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.acctionedInvoiceTab.management.assignedCaseDetails.customerId"
      ),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.acctionedInvoiceTab.management.assignedCaseDetails.customerName"
      ),
      width: "150px",
      isSorting: false,
    },
    {
      id: "partnerFollowInvoice",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.acctionedInvoiceTab.management.assignedCaseDetails.partnerName"
      ),
      width: "150px",
      isSorting: false,
    },
    {
      id: "saleName",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.acctionedInvoiceTab.management.assignedCaseDetails.saleName"
      ),
      width: "150px",
      isSorting: false,
    },
    {
      id: "totalAmountDue",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.acctionedInvoiceTab.management.assignedCaseDetails.totalPaymentAmount"
      ),
      width: "200px",
      isSorting: false,
    },
    {
      id: "receiveDate",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.acctionedInvoiceTab.management.assignedCaseDetails.receivingDate"
      ),
      width: "150px",
      isSorting: false,
    },
    {
      id: "requestResponseDate",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.acctionedInvoiceTab.management.assignedCaseDetails.approvalDate"
      ),
      width: "150px",
      isSorting: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.acctionedInvoiceTab.management.assignedCaseDetails.status"
      ),
      width: "120px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
      isSorting: false,
    },
  ];

  const OrderStatusMap: Record<
    TInvoiceAssignmentApproveStatus,
    { color: string; bgColor: string; label: string }
  > = {
    waiting_for_manager_approve: {
      color: "",
      bgColor: "",
      label: "",
    },
    approved_assignment: {
      color: "#409261",
      bgColor: "#E9FFEF",
      label: "อนุมัติ",
    },
    declined_assignment: {
      color: "#D32F2F",
      bgColor: "#EFB2B266",
      label: "ไม่อนุมัติ",
    },
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = orderInvoice.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const MapOrderByWithFilter: {
    [K in keyof IInvoiceTable]?: string;
  } = {
    workSheetNumber: "assignment_number",
    updateDate: "updated_at",
  };

  const searchFilterInvoice = useInvoiceSearchFilter();

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchFilterInvoice.searchText);
    searchParams.append("page", searchFilterInvoice.page.toString());
    searchParams.append("limit", searchFilterInvoice.limit.toString());
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFilterInvoice.orderBy] ?? ""
    );
    searchParams.append("direction", searchFilterInvoice.direction);
    setOrderInvoice([]);
    //TODO : handle get api
    const data = await getProcessInvoiceManager(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row: any) => {
        return createData(
          row.id,
          row.assignmentNumber,
          row.farmerRegistrationId,
          row.farmerName,
          row.partnerName,
          row.saleName,
          row.totalAmount,
          row.assignmentReceiveDate,
          row.assignmentUpdateDate,
          row.approveStatus
        );
      });
      setCount(data.count);
      setOrderInvoice(newList);
      setInvoiceExpand(data.rows.map((invoice) => invoice));
    }
    handleFetchCount();
  };

  React.useEffect(() => {
    handleFetch();
  }, [
    searchFilterInvoice.orderBy,
    searchFilterInvoice.direction,
    searchFilterInvoice.page,
  ]);

  const getExportExcelData = async () => {
    type TColumnName =
      | "เลขที่ใบงาน"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "พาร์ทเนอร์ที่ติดตาม"
      | "พนักงานขาย"
      | "ยอดเงินที่ต้องชำระทั้งหมด"
      | "วันที่ได้รับคำขอ"
      | "วันที่ตอบกลับคำขอ"
      | "สถานะ";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getListExportProcessManager(itemSelected);
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            เลขที่ใบงาน: row.assignmentNumber,
            รหัสลูกค้า: row.farmerRegistrationId,
            ชื่อลูกค้า: row.farmerName,
            พาร์ทเนอร์ที่ติดตาม: row.partnerName,
            พนักงานขาย: row.saleName,
            ยอดเงินที่ต้องชำระทั้งหมด: `${thousandComma(
              +row.totalAmount,
              2
            )} บาท`,
            วันที่ได้รับคำขอ: dayjs(row.assignmentReceiveDate).format(
              "DD/MM/YYYY"
            ),
            วันที่ตอบกลับคำขอ: dayjs(row.assignmentUpdateDate).format(
              "DD/MM/YYYY"
            ),
            สถานะ: row.status as TInvoiceAssignmentApproveStatus,
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `processed_invoice_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  return (
    <div className="w-full h-full pt-8">
      <FilterInvoice
        searchFilterInvoice={searchFilterInvoice}
        onSearch={handleFetch}
        isDisplaySelect={false}
        placeholder={t("invoice.acctionedInvoiceTab.searchForWorkSheetNumber")}
        itemSelected={itemSelected}
        onExport={handleExportExcel}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchFilterInvoice.direction}
          orderBy={searchFilterInvoice.orderBy}
          rowsPerPage={searchFilterInvoice.limit}
          rows={orderInvoice}
          itemsSelected={itemSelected}
          headerItems={headCells}
          page={1}
          onChangeOrderBy={(val) =>
            searchFilterInvoice.setOrderBy(val as keyof IInvoiceTable)
          }
          onChangeOrder={(val) => searchFilterInvoice.setDirection(val)}
          onChangePage={(val) => searchFilterInvoice.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={orderInvoice.map((row) => row.id)}
          rowChildren={
            <>
              {orderInvoice.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <ExpandableTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    //TODO : expand table
                    expandComponent={
                      <ExpandInvoiceTable
                        invoiceExpand={invoiceExpand[index]}
                      />
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.workSheetNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.partnerFollowInvoice}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.saleName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.totalAmountDue}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.receiveDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.requestResponseDate}
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        <ChipOrderStatus
                          bgColor={
                            OrderStatusMap[
                              row.status as TInvoiceAssignmentApproveStatus
                            ].bgColor
                          }
                          fontColor={
                            OrderStatusMap[
                              row.status as TInvoiceAssignmentApproveStatus
                            ].color
                          }
                          label={
                            OrderStatusMap[
                              row.status as TInvoiceAssignmentApproveStatus
                            ].label
                          }
                        />
                      </div>
                    </TableCell>
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
    </div>
  );
};

export default ProcessedInvoicePanel;
