import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SuccessCard from "../../../../components/common/SuccessCard";

type Props = {};

const RemoveStaffSuccess = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <SuccessCard
      title={t("RoleAndPermission.FinishedRemoveStaff")}
      buttonLabel="See Staff Member"
      onButtonPress={() => {
        navigate("/partner/account-center/staff");
      }}
      subtitle1={t("RoleAndPermission.Congratulation")}
      subtitle2={t("RoleAndPermission.YourFinishedRemoveStaff")}
      subtitle3={t("RoleAndPermission.LetSeeYourStaff")}
    />
  );
};

export default RemoveStaffSuccess;
