import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";

import { useTranslation } from "react-i18next";
import { TBaseGetListPartnerStock } from "../../../types/partner/stock.type";
import FilterOrderAndOrderTime from "../../feed-order-and-delivery/my-order/my-order-component/filter/FilterOrderAndOrderTime";
import CardPartnerOrder from "../stock-component/CardPartnerOrder";

export default function StockHistory() {
  const navigate = useNavigate();
  const { stockHistory } = useLoaderData() as {
    stockHistory: TBaseGetListPartnerStock;
  };
  const { t } = useTranslation();
  return (
    <div className="h-screen flex flex-col bg-[#ffff] overflow-scroll">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate("/stock")}
          title={t("OrderStock.StockHistory")}
        />
      </div>
      <FilterOrderAndOrderTime
        handleOnResetFilter={() => {}}
        handleOnSubmitFilter={() => {}}
        title={t("PartnerHomePage.AllStock")}
      />
      {stockHistory.stockRefills.map((stock, key) => {
        return (
          <CardPartnerOrder
            key={key}
            onMenuClick={() => navigate("/stock/stock-history/" + stock.id)}
            stock={stock}
          />
        );
      })}
    </div>
  );
}
