import { Divider, Link, TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { tw } from "../../../utils/tw";
import TrashIconSVG from "../../assets/svg/TrashIcon.svg";
import ButtonFillCustom from "../../components/Button/ButtonFillCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import TableCustom from "../../components/Table/TableCustom";
import CardList from "../group-area/views/CardList";
import { useRoleController } from "./controller";
import AddModal from "./views/AddModal";
import EditRoleModal from "./views/EditRoleModal";
import { useTranslation } from "react-i18next";

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  title: tw(`text-black font-bold text-3xl`),
  mainContainer: tw(`pt-6 flex flex-row w-full justify-between`),
  rightContainer: tw(`flex flex-col flex-1`),
  rightTopContainer: tw(
    `border-[1px] rounded-[10px] border-[#E2E2E2] p-[12px] flex flex-row self-start justify-between items-start w-full`
  ),
  subTitle: tw(`text-[#191919] font-bold text-[14px] mb-[6px]`),
  muteText: tw(`text-[#7E7E7E] text-[14px] font-normal`),
  tableContainer: tw(`mt-6 w-full flex flex-col`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const Role = () => {
  const {
    currentTab,
    setCurrentTab,
    currentTabRoleAndPermission,
    onChangeTabRoleAndPermission,
    onAddRole,
    count,
    roleData,
    // permissionData,
    currentPage,
    setCurrentPage,
    onRemove,
    isShowAddModal,
    closeAdd,
    refetch,
    showButton,
  } = useRoleController();
  const { t } = useTranslation();

  const [roleName, setRoleName] = useState("");
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className={classes.title}>Role</div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className={classes.mainContainer}>
              <div>
                <CardList
                  title="Role Member"
                  items={[
                    {
                      label: `${t("roleSetting.partner")} (${count.partner})`,
                      value: "partner",
                    },
                    { label: `พนักงาน (${count.employee})`, value: "employee" },
                  ]}
                  selectedItems={currentTab ? [currentTab] : []}
                  onSelect={setCurrentTab}
                  lastIndex={2}
                />
              </div>
              <div className="min-w-6" />
              <div className={classes.rightContainer}>
                <div className={classes.rightTopContainer}>
                  <div className="flex flex-col">
                    <div className={classes.subTitle}>Team members</div>
                    <div className={classes.muteText}>
                      Invite your organisation’s members
                    </div>
                  </div>
                  {showButton && (
                    <ButtonFillCustom title="Add Role" onClick={onAddRole} />
                  )}
                </div>
                <div className="mt-6">
                  <div className={classes.tableContainer}>
                    <TableCustom
                      order="desc"
                      orderBy={""}
                      totalItemSize={roleData.length}
                      rowsPerPage={10}
                      page={currentPage}
                      headerItems={[
                        {
                          id: "roleName",
                          numeric: false,
                          disablePadding: false,
                          label: "Role Name",
                          width: "420px",
                          isSorting: false,
                        },
                        {
                          id: "userCount",
                          numeric: false,
                          disablePadding: false,
                          label: "จำนวนผู้ใช้",
                          width: "420px",
                          isSorting: false,
                        },
                        {
                          id: "editAction",
                          numeric: false,
                          disablePadding: false,
                          label: "",
                          width: "20px",
                          isSorting: false,
                        },
                        {
                          id: "removeAction",
                          numeric: false,
                          disablePadding: false,
                          label: "",
                          width: "20px",
                          isSorting: false,
                        },
                      ]}
                      rows={roleData}
                      onChangePage={setCurrentPage}
                      currentPageItems={roleData.map(({ id }) => id)}
                      itemsSelected={[]}
                      onSelectItem={() => {}}
                      onSelectAllClick={() => {}}
                      hideSelect
                      onChangeOrder={() => {}}
                      onChangeOrderBy={() => {}}
                      EmptyComponent={
                        roleData.length === 0 ? (
                          <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
                        ) : null
                      }
                      rowChildren={roleData.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            className="border-x-[1px] border-x-[#ddd]"
                            key={index}
                          >
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">
                              {row.countMembers}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="self-center cursor-pointer"
                            >
                              <Link
                                onClick={() => {
                                  setRoleName(row.name);
                                  setIsOpenEditModal(true);
                                  setSelectedId(row.id);
                                }}
                                component="button"
                                variant="body2"
                                sx={{
                                  color: "#68C184",
                                  textDecoration: "underline",
                                  textDecorationColor: "#68C184",
                                }}
                              >
                                แก้ไข
                              </Link>
                            </TableCell>
                            <TableCell
                              align="left"
                              className="self-center cursor-pointer"
                            >
                              {showButton ? (
                                <div
                                  onClick={onRemove(row.id)}
                                  className="flex justify-center items-center"
                                >
                                  <TrashIconSVG />
                                </div>
                              ) : (
                                <div className="flex justify-center items-center"></div>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MenuBar>
      <AddModal
        isOpen={isShowAddModal}
        onClose={closeAdd}
        refetch={refetch}
        currentTab={currentTab}
      />
      <EditRoleModal
        isOpen={isOpenEditModal}
        handleClose={() => setIsOpenEditModal(false)}
        roleName={roleName}
        id={selectedId}
      />
    </>
  );
};

export default Role;
