import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DeleteOutlinedIcon } from "../../../../../../assets/svg/DeleteOutlined";
import Switch from "../../../../../../components/Switch";
import { DATE_FORMAT_ENG } from "../../../../../../const/app.constant";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { useAdminAuth } from "../../../../../components/AdminAuth/AdminAuthProvider";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../../components/Table/TableCustom";
import { useFarmCommunityFilterHook } from "../../../hooks/farm-community/useFarmCommuFilter.hook";
import {
  deleteManualArticle,
  getListSequenceUserManual,
  getListTopicUserManual,
  toggleActiveManual,
} from "../../../services/farmCommunity.service";
import {
  IGetDetailUserManualRow,
  SequenceManualRow,
} from "../../../types/farmCommunity";
import CreateUserManual from "../Modal/CreateUserManual";
import ViewUserManual from "../Modal/ViewUserManualModal";
import { SearchUserManual } from "../SearchUserManual";
import { useTranslation } from "react-i18next";

type Props = {
  tabIndex: number;
  id: string;
  tabName: string;
};

type TableUserManual = {
  id: string;
  topicName: string;
  updatedAt: string;
};

const UserManualTab = ({ tabIndex, id, tabName }: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.masterDataFarmCommunity === "edit";

  const searchUserManual = useFarmCommunityFilterHook();
  const dispatch = useDispatch();

  //* const set data and other
  const [userManual, setUserManual] = useState<IGetDetailUserManualRow[]>([]);
  const [sequenceUserManual, setSequenceUserManual] = useState<
    SequenceManualRow[]
  >([]);
  const [selectIndex, setSelectIndex] = useState<number>(0);
  const [isOpenViewModal, setIsOpenViewModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const headCells: ITableCustomHeadCell<keyof TableUserManual>[] = [
    {
      id: "topicName",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
    {
      id: "topicName",
      numeric: false,
      disablePadding: false,
      label: "ชื่อหัวข้อ",
      width: "400px",
      isSorting: false,
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: "วันที่อัปเดตล่าสุด",
      width: "200px",
      isSorting: true,
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "0px",
      isSorting: false,
    },
  ];

  const fetchData = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("updatedAtStart", searchUserManual.startDate);
    searchParams.append("updatedAtEnd", searchUserManual.endDate);
    searchParams.append("type", tabName);
    searchParams.append("searchText", searchUserManual.searchText);
    const data = await getListTopicUserManual(id, searchParams);
    if (data) {
      setUserManual(data.rows);
    }
  };

  const handleToggleChangeStatus = async (
    manualId: string,
    manualArticleId: string
  ) => {
    try {
      await toggleActiveManual(manualId, manualArticleId);
      dispatch(
        snakeActions.showMessage({
          message: "เปลี่ยนสถานะสำเร็จ",
          type: "success",
        })
      );
      fetchData();
    } catch (error) {
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
    }
  };

  const handleFetchSequenceManual = async () => {
    const data = await getListSequenceUserManual(id, tabName);
    if (data) {
      setSequenceUserManual(data.rows);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchUserManual.toggleFetch, tabName, id]);

  useEffect(() => {
    handleFetchSequenceManual();
  }, [id, tabName]);

  const handleDelete = async (manualId: string, manualArticleId: string) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการลบ",
        text: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังยืนยัน..." }));
            await deleteManualArticle(manualId, manualArticleId);
            fetchData();
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "ลบสำเร็จ",
              })
            );
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const filterHeader = roleActionEdit
    ? headCells
    : headCells.toSpliced(0, 1).toSpliced(4, 2);

  return (
    <>
      <SearchUserManual
        tabName={tabName}
        tabIndex={tabIndex}
        searchUserManual={searchUserManual}
        onSearch={fetchData}
        sequenceData={sequenceUserManual}
      />
      <div className="pt-6">
        <TableCustom
          order={"desc"}
          orderBy={""}
          itemsSelected={[]}
          rowsPerPage={999}
          page={1}
          headerItems={filterHeader}
          rows={userManual}
          onChangePage={(value) => {}}
          onChangeOrder={(val) => {}}
          onChangeOrderBy={(val) => {}}
          onSelectItem={() => {}}
          onSelectAllClick={() => {}}
          totalItemSize={999}
          currentPageItems={[]}
          hideSelect
          hidePaginator
          rowChildren={userManual.map((row, index) => (
            <TableRow hover key={index}>
              {roleActionEdit && (
                <TableCell padding="normal">
                  <Switch
                    checked={row.isActive}
                    onChange={async () => {
                      handleToggleChangeStatus(row.manual.id, row.id);
                    }}
                  />
                </TableCell>
              )}
              <TableCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                {row.topicName}
              </TableCell>
              <TableCell align="left">
                {dayjs(row.updatedAt).format(DATE_FORMAT_ENG)}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                  padding: "16px 0",
                }}
              >
                <ButtonOutlinedCustom
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  title={"ดูรายละเอียด"}
                  onClick={() => {
                    setSelectIndex(index);
                    setIsOpenViewModal(true);
                  }}
                />
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                  padding: "16px 0",
                }}
              >
                {roleActionEdit && (
                  <ButtonFillCustom
                    btnBgColor="#68C184"
                    btnTextColor="#FFFFFF"
                    title={"แก้ไข"}
                    onClick={() => {
                      setIsOpenEditModal(true);
                      setSelectIndex(index);
                    }}
                  />
                )}
              </TableCell>

              <TableCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                  padding: "16px 0",
                }}
              >
                {roleActionEdit && (
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleDelete(row.manual.id, row.id);
                    }}
                  >
                    <DeleteOutlinedIcon />
                  </div>
                )}
              </TableCell>
            </TableRow>
          ))}
        />
      </div>
      <ViewUserManual
        isOpen={isOpenViewModal}
        data={userManual[selectIndex]}
        handleClose={() => setIsOpenViewModal(false)}
      />
      <CreateUserManual
        data={userManual[selectIndex]}
        isOpen={isOpenEditModal}
        handleClose={(isSuccess) => {
          if (isSuccess) {
            setSelectIndex(0);
            fetchData();
          }
          setIsOpenEditModal(false);
        }}
      />
    </>
  );
};

export default UserManualTab;
