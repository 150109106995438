export default function () {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#0288D1" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0016 11.6016C12.6761 11.6016 11.6016 12.6761 11.6016 14.0016V26.0016C11.6016 27.327 12.6761 28.4016 14.0016 28.4016H26.0016C27.327 28.4016 28.4016 27.327 28.4016 26.0016V14.0016C28.4016 12.6761 27.327 11.6016 26.0016 11.6016H14.0016ZM24.8016 16.4016C24.8016 15.7388 24.2643 15.2016 23.6016 15.2016C22.9388 15.2016 22.4016 15.7388 22.4016 16.4016V23.6016C22.4016 24.2643 22.9388 24.8016 23.6016 24.8016C24.2643 24.8016 24.8016 24.2643 24.8016 23.6016V16.4016ZM21.2016 18.8016C21.2016 18.1388 20.6643 17.6016 20.0016 17.6016C19.3388 17.6016 18.8016 18.1388 18.8016 18.8016V23.6016C18.8016 24.2643 19.3388 24.8016 20.0016 24.8016C20.6643 24.8016 21.2016 24.2643 21.2016 23.6016V18.8016ZM17.6016 22.4016C17.6016 21.7388 17.0643 21.2016 16.4016 21.2016C15.7388 21.2016 15.2016 21.7388 15.2016 22.4016V23.6016C15.2016 24.2643 15.7388 24.8016 16.4016 24.8016C17.0643 24.8016 17.6016 24.2643 17.6016 23.6016V22.4016Z"
        fill="white"
      />
    </svg>
  );
}
