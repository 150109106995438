import { apiPaths } from "../../const/apiPaths";
import { IGetGroupArea, IGetYear } from "../../types/Utils.type";
import {
  IGetActiveUsersGraph,
  IGetDailyLogUsersGraph,
  IGetListActiveUser,
  IGetListDailyLogUsers,
} from "../../types/dashboard/ApplicationUsage.type";
import adminAPI from "../AdminApiService";

export const getApplicationUsageYear = async () => {
  const { data } = await adminAPI.get<IGetYear>(
    apiPaths.adminApplicationUsage.getYear
  );
  return data.rows;
};

export const getApplicationUsageGroupArea = async () => {
  const { data } = await adminAPI.get<IGetGroupArea>(
    apiPaths.adminApplicationUsage.getGroupArea
  );
  return data.rows;
};

export const getDailyLogUsersGraph = async (params: {
  year: string;
  groupAreaIds?: string;
  segments?: string;
}) => {
  try {
    const { data } = await adminAPI.get<IGetDailyLogUsersGraph>(
      apiPaths.adminApplicationUsage.getDailyLogUsersGraph,
      {
        params: {
          year: params.year,
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
        },
      }
    );
    return data.result;
  } catch (error) {
    console.log("getDailyLogUsersGraph", error);
    return null;
  }
};

export const getListDailyLogUsers = async (params: {
  year: string;
  month?: string;
  status?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListDailyLogUsers>(
      apiPaths.adminApplicationUsage.getListDailyLogUsers,
      {
        params: {
          year: params.year,
          months: params?.month,
          status: params?.status,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListDailyLogUsers", error);
    return null;
  }
};
export const getExportListDailyLogUsers = async (params: {
  year: string;
  month?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListDailyLogUsers>(
      apiPaths.adminApplicationUsage.exportListDailyLogUsers,
      {
        params: {
          year: params.year,
          months: params?.month,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getExportListDailyLogUsers", error);
    return null;
  }
};

export const getActiveUsersGraph = async (params: {
  year: string;
  groupAreaIds: string;
  segments: string;
}) => {
  try {
    const { data } = await adminAPI.get<IGetActiveUsersGraph>(
      apiPaths.adminApplicationUsage.getActiveUsersGraph,
      {
        params: {
          year: params.year,
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
        },
      }
    );
    return data.result;
  } catch (error) {
    console.log("getActiveUsersGraph", error);
    return null;
  }
};

export const getListActiveUsers = async (params: {
  year: string;
  month?: string;
  activeDays?: string;
  groupAreaIds?: string;
  segments?: string;
  searchText?: string;
  direction: string;
  orderBy?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IGetListActiveUser>(
      apiPaths.adminApplicationUsage.getListActiveUsers,
      {
        params: {
          year: params.year,
          month: params?.month,
          activeDays: params?.activeDays,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListActiveUsers", error);
    return null;
  }
};
