import { yupResolver } from "@hookform/resolvers/yup";
import { MenuItem } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import ListOfUploadedFile2 from "../../../../../../components/common/File/ListOfUploadedFile2";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import { commonDownloadFile } from "../../../../../../services/CommonApiService";
import {
  handleDownloadObject,
  handleUploadFileToAzure,
} from "../../../../../../utils/downloadFile";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import DragAndDropUploadImage from "../../../../../components/Input/DragAndDropUploadImage";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import RHFSelectCustom from "../../../../../components/Select/RHFSelectCustom";
import MySunEditor from "../../../../../components/Texteditor";
import {
  createArticle,
  editArticle,
  getArticleUploadUrl,
} from "../../../services/farmCommunity.service";
import {
  ArticleFile,
  ICreateArticle,
  ListNewsAndArticles,
} from "../../../types/farmCommunity";
import { farmCommunityTypeOptions } from "../SearchFarmCommunity";
import { useTranslation } from "react-i18next";

type Props = {
  data?: ListNewsAndArticles;
  isOpen: boolean;
  handleClose: (success: boolean) => void;
};

export type TFormArticle = {
  type: string;
  topicName: string;
  detail: string;
  url: string;
  file: File[];
  uploadedFile: ArticleFile[];
};

type document = {
  fileId: string;
  fileName: string;
};

export interface IArticleDoc {
  file: document[];
}

const CreateArticleModal = ({ data, isOpen, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileArticle: IArticleDoc["file"] = [];
  const [editorContent, setEditorContent] = useState("");

  const validateSchema = yup.object().shape({
    type: yup.string().required("กรุณากรอก"),
    topicName: yup.string().required("กรุณากรอก"),
  });

  const methods = useForm<TFormArticle>({
    defaultValues: {
      type: "",
      topicName: "",
      detail: "",
      url: "",
      file: [],
    },
    resolver: yupResolver(validateSchema as any),
  });

  const handleDeleteFile = async () => {
    methods.setValue("file", []);
  };
  const handleDeleteUploadFile = async () => {
    methods.setValue("uploadedFile", []);
  };

  const handleUploadFiles = (files: File[]) => {
    methods.setValue("file", files);
  };

  const handleEditorContentChange = useCallback(
    (content: string) => {
      setEditorContent(content);
    },
    [editorContent]
  );

  const submitAPI = async (value: TFormArticle) => {
    if (value.file.length) {
      const response = await getArticleUploadUrl();
      if (response?.blob?.blobUrl) {
        await handleUploadFileToAzure(response?.blob?.blobUrl, value.file[0]);
        fileArticle.push({
          fileId: response.blob.fileId || "",
          fileName: value.file[0].name,
        });
      }
    }
    if (data) {
      if (value.uploadedFile.length) {
        fileArticle.push({
          fileId: value.uploadedFile[0].id || "",
          fileName: value.uploadedFile[0].fileOriginalName || "",
        });
      }
    }

    const body: ICreateArticle = {
      type: value.type,
      topicName: value.topicName,
      description: editorContent,
      url: value.url,
      file: {
        fileId: fileArticle[0].fileId
          ? fileArticle[0].fileId
          : value.uploadedFile[0].id || "",
        fileName: fileArticle[0].fileName
          ? fileArticle[0].fileName
          : value.uploadedFile[0].fileOriginalName || "",
      },
    };

    if (data) {
      await editArticle(data?.id, body);
    } else {
      await createArticle(body);
    }
  };

  const handleSubmit = (value: TFormArticle) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            console.error(error);
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const onDownload = async (row: { id: string; fileOriginalName: string }) => {
    await commonDownloadFile(
      `/farm-community/article/download/${row.id}`,
      row.fileOriginalName
    );
  };

  useEffect(() => {
    if (isOpen && data) {
      setEditorContent(data.description);
      methods.reset({
        topicName: data?.topicName,
        type: data?.type,
        url: data?.url,
        uploadedFile: data.articleFiles,
        file: [],
      });
    } else {
      methods.reset();
      setEditorContent("");
    }
  }, [isOpen]);

  return (
    <>
      <AdminModal
        label={data ? "แก้ไขข่าวสารเเละบทความ" : "เพิ่มข่าวสารเเละบทความ"}
        isOpen={isOpen}
        size={"md"}
        handleClose={() => handleClose(false)}
        children={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <div className="my-4">
                <p className="text-[14px] mb-3 font-sans">
                  {t("farmCommunity.newsAndArticlePage.add.types")}{" "}
                  <span className="text-error-300">*</span>
                </p>
                <RHFSelectCustom
                  value={methods.watch("type")}
                  controlName="type"
                  placeholder="เลือก"
                  disabled={!!data}
                  menuItems={(farmCommunityTypeOptions || []).map((row) => (
                    <MenuItem key={row.value} value={row.value}>
                      {row.label}
                    </MenuItem>
                  ))}
                  renderValueArray={(farmCommunityTypeOptions || []).map(
                    (row) => ({
                      value: row.value,
                      label: row.label,
                    })
                  )}
                  error={Boolean(methods.formState.errors.type)}
                  helperText={methods.formState.errors.type?.message || ""}
                  onChange={(e) =>
                    methods.setValue("type", e.target.value as string)
                  }
                />
              </div>
              <div className="my-4">
                <p className="text-[14px] mb-3 font-sans">
                  {t("farmCommunity.newsAndArticlePage.add.topic")}{" "}
                  <span className="text-error-300">*</span>
                </p>
                <RHFTextInputCustom
                  controlName="topicName"
                  placeholder="กรอก"
                  error={Boolean(methods.formState.errors.topicName)}
                  helperText={methods.formState.errors.topicName?.message || ""}
                />
              </div>
              <div className="my-4">
                <p className="text-[14px] mb-3 font-sans">
                  {t("farmCommunity.newsAndArticlePage.add.url")}
                </p>
                <RHFTextInputCustom controlName="url" placeholder="กรอก" />
              </div>
              <div className="flex flex-col gap-2">
                <label className="font-semibold text-[14px]" htmlFor="files">
                  {methods.watch("type") === "article"
                    ? `${t(
                        "farmCommunity.newsAndArticlePage.add.uploadCoverImage"
                      )} (แนะนำขนาดรูป [16:9])`
                    : "อัปโหลดวิดิโอ"}
                </label>
                <ListOfFile
                  files={methods.watch("file")}
                  onDownload={handleDownloadObject}
                  onDelete={handleDeleteFile}
                />
                {methods.watch("uploadedFile") && (
                  <ListOfUploadedFile2
                    files={methods.watch("uploadedFile")}
                    fileKey="fileOriginalName"
                    onDownload={(row) => onDownload(row)}
                    hideDeleteBtn={false}
                    onDelete={handleDeleteUploadFile}
                  />
                )}
                {methods.watch("type") === "article" ? (
                  <DragAndDropUploadImage onUpload={handleUploadFiles} />
                ) : (
                  <DragAndDropUploadImage
                    onUpload={handleUploadFiles}
                    type={{
                      "video/mp4": [".mp4"],
                      "video/x-msvideo": [".avi"],
                      "video/x-flv": [".flv"],
                      "video/x-matroska": [".mkv"],
                      "video/ogg": [".ogv"],
                      "video/quicktime": [".mov"],
                      "video/x-ms-wmv": [".wmv"],
                    }}
                  />
                )}
              </div>
              {methods.watch("type") === "article" && (
                <div className="my-4">
                  <p className="text-[14px] font-sans mb-3">
                    {t("farmCommunity.newsAndArticlePage.add.description")}
                  </p>
                  <MySunEditor
                    initialContent={editorContent}
                    onContentChange={handleEditorContentChange}
                  />
                </div>
              )}
              <div className="flex justify-end mt-4">
                <ButtonFillCustom
                  title={t("common.button.confirm")}
                  type="submit"
                />
              </div>
            </form>
          </FormProvider>
        }
      />
    </>
  );
};

export default CreateArticleModal;
