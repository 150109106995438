import { useTranslation } from "react-i18next";
import { thousandComma } from "../../../../../utils/common/string";
import CashSvg from "../../../../assets/svg/Cash.svg";
import PersonSvg from "../../../../assets/svg/Person.svg";
import WalletSvg from "../../../../assets/svg/Wallet.svg";
import RevenueTab from "../../components/RevenueTab";

type Props = {};

const RevenueInfoBasicPartner = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex gap-2 text-center items-center mt-6">
        <CashSvg />
        <p className="text-lg font-bold text-[#3777BC] font-sans">
          {t("partner.revenueInformationTab.title")}
        </p>
      </div>
      <div className="flex space-x-4 my-6">
        <div className="bg-[#EEF2FF] py-5 px-6 rounded-2xl w-[25%]">
          <WalletSvg />
          <div className="mt-3">
            <p className="text-[24px] font-extrabold font-sans">
              {thousandComma(1400)}
              <span className="text-[#151D48] text-[18px] font-semibold font-[DM Sans]">
                {` ${t("common.bath")}`}
              </span>
            </p>
            <p className="font-sans text-[16px] font-medium text-[#425166]">
              รายได้ทั้งหมด
            </p>
          </div>
        </div>
        <div className="bg-[#F0FDF4] py-5 px-6 rounded-2xl w-[25%]">
          <PersonSvg />
          <div className="mt-3">
            <p className="text-[24px] font-extrabold font-sans">
              {thousandComma(100)}
              <span className="text-[#151D48] text-[18px] font-semibold font-[DM Sans]">
                {` ${t("common.bath")}`}
              </span>
            </p>
            <p className="font-sans text-[16px] font-medium text-[#425166]">
              On board
            </p>
          </div>
        </div>
      </div>
      <RevenueTab type={""} id={""} />
    </>
  );
};

export default RevenueInfoBasicPartner;
