import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { IGetListPerMonthResult } from "../../../../../types/BasicAccounting.type";
import { getShortDate } from "../../../../../utils/date";
import { getCommaNumber } from "../../../../../utils/number";
import { tw } from "../../../../../utils/tw";

interface IMonthlyExpense {
  data: IGetListPerMonthResult[];
}

const MonthlyExpense = (props: IMonthlyExpense) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <div className="bg-[#FFFF] flex flex-col gap-4 p-4 rounded-[20px]">
      {data.map((row, index) => (
        <Accordion
          sx={{
            margin: "0px !important",
            boxShadow: "none",
            width: "100%",
            ":before": {
              backgroundColor: "transparent",
            },
          }}
          key={dayjs(row.month).format("MMMM") + index}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="!text-[#68C184]" />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="!text-[#34407B] !text-[18px] !font-bold !font-sans"
            sx={{
              minHeight: "24px !important",
              height: "24px !important",
              margin: "0px !important",
              padding: "0px !important",
            }}
          >
            {dayjs(row.month).format("MMMM")}
          </AccordionSummary>
          <AccordionDetails className="flex flex-col gap-[6px] !p-0 !pt-[12px]">
            {row.list.map((list, index) => (
              <div
                className="bg-[#FBFBFB] p-3 flex flex-col gap-[6px]"
                key={list.id + index}
              >
                <div className="flex flex-row justify-between items-center">
                  <div className="text-[#34407B] font-bold text-[14px]">
                    {list.flockName}
                  </div>
                  <div
                    className={tw(
                      "font-normal text-[14px]",
                      list.income - list.expense >= 0
                        ? "text-[#68C184]"
                        : "text-[#D32F2F]"
                    )}
                  >
                    {list.income - list.expense >= 0 ? "+" : ""}
                    {getCommaNumber(list.income - list.expense, true)}{" "}
                    {t("reportOrder.currency")}
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center">
                  <div className="text-[#3777BC] font-normal text-[14px]">
                    {t("BasicAccounting.CloseFlockDate")}
                  </div>
                  <div className="text-[#34407B] font-normal text-[14px]">
                    {list.isActive === false
                      ? getShortDate(list.generationCloseDate)
                      : "-"}
                  </div>
                </div>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default MonthlyExpense;
