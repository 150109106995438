import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  Fade,
  IconButton,
  Stack,
  SvgIcon,
  styled,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import ListOfFile from "../../../components/common/File/ListOfFile";
import ListOfUploadedFile2 from "../../../components/common/File/ListOfUploadedFile2";
import { alertAction } from "../../../redux/slices/alertSlice";
import { loadingActions } from "../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../redux/store";
import {
  getEditInvoiceDocumentAPI,
  getInvoiceDownloadUrlAPI,
  updateInvoiceAPI,
  uploadInvoiceAPI,
} from "../../../services/admin/invoice/invoiceSale.service";
import { IUpdateInvoicePayload } from "../../../services/admin/invoice/type";
import {
  covertToBlobUrl,
  handleDownloadBlob,
} from "../../../utils/downloadFile";
import getErrorMessage from "../../../utils/getErrorMessage";
import { CalendarSvg } from "../../assets/svg/Calendar.svg";
import { IInvoiceTable } from "../../pages/invoice/type";
import ButtonFillCustom from "../Button/ButtonFillCustom";
import DatePickerPopover from "../Input/DatePickerPopover/DatePickerPopover";
import DragAndDropUploadImage from "../Input/DragAndDropUploadImage";
import TextInputCustom from "../Input/TextInputCustom";
import ConfirmCustomModal from "./ConfirmCustomModal";
import { FontTypo, InnerContainer, ModalContainer } from "./Modal.styled";
import { useTranslation } from "react-i18next";

export interface UpdateInvoiceModalProps {
  handleModalClose: (isSuccess: boolean) => void;
  isOpen: boolean;
  data?: IInvoiceTable;
}
const UpdateInvoiceModal = ({
  handleModalClose,
  isOpen = false,
  data,
}: UpdateInvoiceModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [dueDate, setDueDate] = useState<string>("");
  const [invoiceSlip, setInvoiceSlip] = useState<File[]>([]);
  const [prevInvoiceSlip, setPrevInvoiceSlip] = useState<
    {
      id: string;
      fileOriginalName: string;
      documentType: string;
    }[]
  >([]);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<string>("");

  const handleClose = (isSuccess: boolean) => {
    handleModalClose(isSuccess);
    setInvoiceSlip([]);
  };

  useEffect(() => {
    const fetcher = async () => {
      if (isOpen && data) {
        if (data.paymentDueDate) {
          setDueDate(data.paymentDueDate);
        }
        setInvoice("");
        setIsCheck(data.isUseRemainingCredit || false);
        const response = await getEditInvoiceDocumentAPI(data.id);
        const invoiceFile = (
          response?.data?.invoice?.invoiceFiles || []
        ).filter((row) => row.documentType === "invoice");
        setPrevInvoiceSlip(invoiceFile);
      }
    };
    fetcher();
  }, [data, isOpen]);

  const handleSubmit = async () => {
    try {
      dispatch(loadingActions.show({ message: "กำลังบันทึกข้อมูล..." }));
      const payload: IUpdateInvoicePayload = {
        isUseRemainingCredit: isCheck,
        invoiceNumber: invoice
          ? invoice
          : data?.invoiceNumber === "-"
          ? ""
          : data?.invoiceNumber || "",
        invoiceFiles: prevInvoiceSlip[0] ? [prevInvoiceSlip[0]] : [],
      };
      if (invoiceSlip[0]) {
        const preSign = await uploadInvoiceAPI(data?.id ?? "");
        if (preSign) {
          await axios.put(preSign.blob.blobUrl, invoiceSlip[0], {
            responseType: "blob",
            headers: {
              "Content-Type": invoiceSlip[0].type,
              "x-ms-blob-type": "BlockBlob",
            },
          });
          payload.invoiceFiles = [
            {
              id: preSign.blob.fileId,
              fileOriginalName: invoiceSlip[0].name,
            },
          ];
        }
      }
      await updateInvoiceAPI(data?.id ?? "", payload);
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "บันทึกสำเร็จ",
            type: "success",
          })
        );
        handleClose(true);
      }, 500);
    } catch (error) {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 500);
    }
  };

  const onDownload = async (row: {
    id: string;
    fileOriginalName: string;
    documentType: string;
  }) => {
    const response = await getInvoiceDownloadUrlAPI(data?.id || "", row.id);
    if (response) {
      const objectURL = await covertToBlobUrl(response.blob.blobUrl);
      handleDownloadBlob(objectURL, row.fileOriginalName);
    }
  };

  return (
    <ModalContainer
      aria-label="update-invoice-modal"
      data-testid="updateInvoiceModal"
      open={isOpen}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <InnerContainer width={"820px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <FontTypo fontSize={"18px"} fontWeight={"bold"}>
              {t(
                "invoice.waitToUpdateInvoiceTab.updateDocumentsModal.updateDocumentsModal",
                {
                  number: data?.orderId,
                }
              )}
            </FontTypo>
            <IconButton onClick={() => handleClose(false)} sx={{ padding: 0 }}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <CustomCheckbox
              checked={isCheck}
              onChange={(event) => {
                setIsCheck(event.target.checked);
              }}
            />
            <FontTypo fontSize={"1rem"} fontWeight={"normal"} ml={1} pt={0.5}>
              ใช้วงเงินคงเหลือ
            </FontTypo>
          </Stack>
          <Stack direction={"row"} alignItems={"center"}>
            <Stack direction={"column"} alignItems={"flex-start"} mr={"12px"}>
              <FontTypo fontSize={"14px"} fontWeight={"normal"}>
                {t(
                  "invoice.waitToUpdateInvoiceTab.updateDocumentsModal.invoiceNumber"
                )}
                <span className="text-error-300">*</span>
              </FontTypo>
              <TextInputCustom
                value={invoice}
                onChange={(e) => setInvoice(e.target.value)}
                style={{ width: "380px" }}
                placeholder="กรอก"
              />
            </Stack>
            <Stack direction={"column"} alignItems={"flex-start"}>
              <FontTypo fontSize={"14px"} fontWeight={"normal"}>
                {t(
                  "invoice.waitToUpdateInvoiceTab.updateDocumentsModal.paymentDueDate"
                )}
              </FontTypo>
              <TextInputCustom
                value={dueDate}
                style={{ width: "380px" }}
                placeholder="กรอก"
                disabled
                InputProps={{
                  endAdornment: (
                    <SvgIcon
                      sx={{
                        fontSize: "24px !important",
                        color: "#646464",
                      }}
                      fontSize="small"
                    >
                      <CalendarSvg />
                    </SvgIcon>
                  ),
                }}
              />
            </Stack>
          </Stack>
          <Stack>
            <FontTypo fontSize={"14px"} fontWeight={"bold"}>
              {t(
                "invoice.waitToUpdateInvoiceTab.updateDocumentsModal.attachInvoice"
              )}
            </FontTypo>
            <ListOfFile
              files={invoiceSlip}
              onDelete={async (idx) => {
                setInvoiceSlip([]);
              }}
            />
            {invoiceSlip.length === 0 && (
              <ListOfUploadedFile2
                files={prevInvoiceSlip}
                fileKey="fileOriginalName"
                onDownload={onDownload}
              />
            )}
            <DragAndDropUploadImage
              onUpload={(files) => {
                setInvoiceSlip(files);
              }}
              type={{ "application/pdf": [".pdf"] }}
            />
          </Stack>

          <Stack direction={"row"} justifyContent={"flex-end"}>
            <ButtonFillCustom
              style={{ width: "63px" }}
              title={t(
                "invoice.waitToUpdateInvoiceTab.updateDocumentsModal.confirmUpdateDocumentsButton"
              )}
              onClick={() => setOpenConfirmModal(true)}
            />
          </Stack>

          <ConfirmCustomModal
            title="ยืนยันการบันทึก"
            subtitleFirstRow="คุณต้องการบันทึกรายการนี้ใช่หรือไม่?"
            buttonPrimaryText={t(
              "invoice.waitToUpdateInvoiceTab.confirmUpdateDocumentsModal.confirm"
            )}
            buttonSecondaryText={t(
              "invoice.waitToUpdateInvoiceTab.confirmUpdateDocumentsModal.back"
            )}
            open={openConfirmModal}
            onClose={() => setOpenConfirmModal(false)}
            onSubmit={async () => {
              setOpenConfirmModal(false);
              handleSubmit();
            }}
          />
        </InnerContainer>
      </Fade>
    </ModalContainer>
  );
};
export default UpdateInvoiceModal;

const CustomCheckbox = styled(Checkbox)(() => ({
  borderWidth: "1px",
  color: "#C9C9C9",
  "&.Mui-checked": {
    color: "#68C184",
  },
  "&.MuiCheckbox-indeterminate": {
    color: "#68C184",
  },
  padding: 0,
}));
