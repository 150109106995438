import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { Button, Link } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../../assets/images/OrderFeedImage/bg.png";
import LllustationSVG from "../../../assets/svg/Lllustation";
import AppBarFS from "../../../components/app-bar/AppBar";

const SupportPagePartner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className="!h-auto !min-h-screen flex flex-col"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        height: "auto !important",
        backgroundSize: "cover",
      }}
    >
      <div className="p-4 h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title={t("AccountCenter.ListOfSupport")}
        />
      </div>
      <div className="flex flex-col gap-4 items-center h-[80%] justify-center pt-6">
        <p className="text-[24px] font-medium text-[#ffff]">
          {t("AccountCenter.ContactOurs")}
        </p>
        <LllustationSVG />
        <p className="text-[#ffff]">{t("AccountCenter.NeedToContactOurs")}</p>
        <div className="text-[#ffff]">
          <CallIcon />
          <Link
            href={`tel:02-833-8000`}
            sx={{
              color: "#ffff",
              fontWeight: "600",
              marginLeft: "8px",
              textDecorationColor: "white",
            }}
          >
            02-833-8000
          </Link>
        </div>
        <div className="text-[#ffff] pb-[70px]">
          <EmailIcon />
          <Link
            href={`mailto:farmsook@betagro.com`}
            sx={{
              color: "#ffff",
              fontWeight: "600",
              marginLeft: "8px",
              textDecorationColor: "white",
            }}
          >
            farmsook@betagro.com
          </Link>
        </div>
      </div>
      <div className="flex flex-1 px-4 justify-end items-end pb-[24px]">
        <Link
          href={`tel:02-833-8000`}
          sx={{
            width: "100%",
          }}
        >
          <Button
            size="large"
            variant="contained"
            startIcon={<CallIcon />}
            sx={{
              width: "100%",
            }}
          >
            {t("AccountCenter.CallCenter")}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default SupportPagePartner;
