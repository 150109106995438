import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import ButtonOutlinedCustom from "../../admin/components/Button/ButtonOutlinedCustom";
import AppBarFS from "../../components/app-bar/AppBar";
import { covertToBlobUrl } from "../../utils/downloadFile";
import { getManualDownloadUrlAPI } from "./farmCommunity.service";
import { Article } from "./type";

type PaginationUserManualModalProps = {
  tabUserManualType: string;
  role: string;
  isOpen: boolean;
  article: Article | null;
  onClose: () => void;
};

const PaginationUserManualModal: React.FC<PaginationUserManualModalProps> = ({
  role,
  isOpen,
  article,
  onClose,
  tabUserManualType,
}) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [fileUrls, setFileUrl] = useState<string[]>([]);
  const { t } = useTranslation();
  const handleNext = () => {
    if (article && currentPageIndex < article.articlePages.length - 1) {
      setCurrentPageIndex((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex((prev) => prev - 1);
    }
  };

  const playerRef = useRef<ReactPlayer | null>(null);

  const handlePlay = () => {
    if (playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      if (player && typeof player.requestFullscreen === "function") {
        player.requestFullscreen();
      } else if (player && typeof player.mozRequestFullScreen === "function") {
        player.mozRequestFullScreen();
      } else if (
        player &&
        typeof player.webkitRequestFullscreen === "function"
      ) {
        player.webkitRequestFullscreen();
      } else if (player && typeof player.msRequestFullscreen === "function") {
        player.msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    const fetchFileUrls = async () => {
      const urls = await Promise.all(
        (article?.articlePages || []).map(async (newsItem) => {
          const fileId = newsItem.pageFile[0].id;
          const response = await getManualDownloadUrlAPI(fileId, role);
          if (response) {
            try {
              return await covertToBlobUrl(response.blob.blobUrl);
            } catch (error) {
              console.error("Error converting blob URL:", error);
              return "";
            }
          }
          return "";
        })
      );
      setFileUrl(urls);
    };

    if ((article?.articlePages || []).length > 0) {
      fetchFileUrls();
    }
  }, [article]);

  return (
    <Dialog
      open={isOpen}
      fullScreen
      onClose={onClose}
      style={{
        margin: 0,
        padding: 0,
      }}
    >
      <DialogTitle
        style={{
          padding: 0,
          margin: 0,
        }}
      ></DialogTitle>
      <DialogContent
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        {article ? (
          <>
            {article.articlePages.length > 0 ? (
              <div className="mt-4 flex flex-col h-full">
                <AppBarFS title={article.topicName} onIconPress={onClose} />
                <div className="flex-grow mx-4">
                  <div className="bg-gray-100">
                    <div className="flex justify-center my-3">
                      {tabUserManualType === "article" ? (
                        fileUrls[currentPageIndex] ? (
                          <img
                            alt={`article-page-${currentPageIndex + 1}`}
                            src={fileUrls[currentPageIndex]}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "400px",
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <p>{t("FarmCommunity.loading")}</p>
                        )
                      ) : fileUrls[currentPageIndex] ? (
                        <ReactPlayer
                          ref={playerRef}
                          playing={false}
                          controls={true}
                          width="100%"
                          height="100%"
                          onPlay={handlePlay}
                          url={fileUrls[currentPageIndex]}
                        />
                      ) : (
                        <p>{t("FarmCommunity.loading")}</p>
                      )}
                    </div>
                  </div>
                  <h4 className="text-lg">
                    {article.articlePages[currentPageIndex].description}
                  </h4>
                </div>
                {/* Footer Section */}
                <div className="mt-auto bg-white p-4 mb-2">
                  <div className="flex justify-center text-[#979797] mb-2">
                    <p>
                      {currentPageIndex + 1} / {article.articlePages.length}{" "}
                      {t("FarmCommunity.page")}
                    </p>
                  </div>
                  <div className="flex justify-between gap-8">
                    <ButtonOutlinedCustom
                      className="!rounded-[50px] w-full !h-[48px]"
                      title={t("manageStock.back")}
                      disabled={currentPageIndex === 0}
                      onClick={handlePrevious}
                      btnBorderColor={
                        currentPageIndex === 0 ? "#fff" : "#68C184"
                      }
                      btnTextColor="#68C184"
                    />
                    <ButtonOutlinedCustom
                      className="!rounded-[50px] w-full !h-[48px]"
                      btnBorderColor={
                        currentPageIndex === article.articlePages.length - 1
                          ? "#fff"
                          : "#68C184"
                      }
                      btnTextColor="#68C184"
                      title={t("reportOrder.next")}
                      disabled={
                        currentPageIndex === article.articlePages.length - 1
                      }
                      onClick={handleNext}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <p>ไม่มีหน้าสำหรับบทความนี้</p>
            )}
          </>
        ) : (
          <p>{t("FarmCommunity.UserManual")}</p>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PaginationUserManualModal;
