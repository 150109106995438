import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider, SvgIcon, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { monthOption } from "../../../../const/utils";
import {
  getListSaleRevenue,
  getSalePerformanceGroupArea,
  getSalePerformanceYear,
  getSaleRevenueExport,
} from "../../../../services/dashboard/SalePerformance.service";
import { ISalePerformanceTableRow } from "../../../../types/dashboard/SalePerformance.type";
import { thousandComma } from "../../../../utils/common/string";
import { makeXlsx } from "../../../../utils/make-xlsx";
import { tw } from "../../../../utils/tw";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import MenuBar from "../../../components/MenuBar/MenuBar";
import TableCustom from "../../../components/Table/TableCustom";
import { IGroupAreaRow } from "../../farm-detail/types/dropdownGroupArea.type";
import { Dropdown, TDropdownValue } from "../../group-area/views/Dropdown";

type Props = {};

const RevenueTable = (props: Props) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  // table
  const [data, setData] = useState<ISalePerformanceTableRow[] | null>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderByType, setOrderByType] = useState<"desc" | "asc">("desc");
  const [currentOrderByKey, setCurrentOrderByKey] = useState<
    "customer_no" | "month"
  >("customer_no");

  const [yearOption, setYearOption] = useState<string[]>([]);
  const [gradeYear, setGradeYear] = useState<string>(dayjs().year().toString());

  const [gradeMonth, setGradeMonth] = useState<TDropdownValue[]>([]);

  const [groupAreaOption, setGroupAreaOption] = useState<IGroupAreaRow[]>([]);
  const [gradeGroupArea, setGradeGroupArea] = useState<TDropdownValue[]>([]);

  const segmentOption = ["Potential", "Grower", "Independent"];
  const [gradeSegment, setGradeSegment] = useState<TDropdownValue[]>([]);

  const classes = {
    emptyTable: tw(
      `font-sans text-center w-full text-[#979797] text-[14px] py-3`
    ),
  };

  const headerItems = [
    {
      id: "customer_no",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "auto",
      isSorting: true,
      className: tw(`whitespace-pre`),
    },
    {
      id: "customer_name",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "month",
      numeric: false,
      disablePadding: false,
      label: "เดือน",
      width: "auto",
      isSorting: true,
      className: tw(`whitespace-pre`),
    },
    {
      id: "province",
      numeric: false,
      disablePadding: false,
      label: "จังหวัด",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "group_area",
      numeric: false,
      disablePadding: false,
      label: "Group Area",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "segment",
      numeric: false,
      disablePadding: false,
      label: "Segment",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "farm_size",
      numeric: false,
      disablePadding: false,
      label: "ขนาดฟาร์ม",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "farm_amount",
      numeric: false,
      disablePadding: false,
      label: "จำนวนฟาร์ม",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "barn_amount",
      numeric: false,
      disablePadding: false,
      label: "จำนวนโรงเรือน",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "actual_revenue",
      numeric: false,
      disablePadding: false,
      label: "Actual Revenue",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "target_revenue",
      numeric: false,
      disablePadding: false,
      label: "Target Revenue",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
  ];

  const handleExportData = async () => {
    const data = await getSaleRevenueExport({
      year: gradeYear ?? dayjs().year().toString(),
      month: gradeMonth.map((month) => month.value).join(",") || "",
      segments: gradeSegment.map((item) => item.value).join(",") || "",
      groupAreaIds: gradeGroupArea[0]?.value,
      searchText: searchText,
      orderBy: currentOrderByKey,
      direction: orderByType,
      page: currentPage,
      limit: 10,
    });

    const dataExcel = data?.map((item) => {
      return {
        รหัสลูกค้า: item.registrationId,
        ชื่อลูกค้า: item.name,
        เดือน: item.month,
        จังหวัด: item.provinceNameTh,
        "Group Area": item.groupAreaName,
        Segment: item.segment || "-",
        ขนาดฟาร์ม: item.farmSize || "-",
        จำนวนฟาร์ม: item.farmAmount || "-",
        จำนวนโรงเรือน: item.houseAmount || "-",
        "Actual Revenue": thousandComma(item.actualSellingFeedRevenue) + " บาท",
        "Target Revenue": thousandComma(item.targetSellingFeedRevenue) + " บาท",
      };
    });

    const fileName = `Sale_Revenue_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(dataExcel || [], fileName, sheetName);
  };
  const fetchYearOption = async () => {
    const data = await getSalePerformanceYear();
    setYearOption(data);
    setGradeYear(data[0] ?? dayjs().year().toString());
  };

  const fetchGroupAreaOption = async () => {
    const data = await getSalePerformanceGroupArea();
    setGroupAreaOption(data);
  };

  const fetchSalePerformanceData = async () => {
    const data = await getListSaleRevenue({
      year: gradeYear ?? dayjs().year().toString(),
      month: gradeMonth.map((month) => month.value).join(",") || "",
      segments: gradeSegment.map((item) => item.value).join(",") || "",
      groupAreaIds: gradeGroupArea[0]?.value,
      searchText: searchText,
      orderBy: currentOrderByKey,
      direction: orderByType,
      page: currentPage,
      limit: 10,
    });
    if (data) {
      setData(data);
    }
  };

  useEffect(() => {
    fetchYearOption();
    fetchGroupAreaOption();
    fetchSalePerformanceData();
  }, []);

  const rowChildren = useMemo(() => {
    return data?.map((row, index) => {
      return (
        <>
          <TableRow>
            <TableCell>{row.registrationId}</TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.month}</TableCell>
            <TableCell>{row.provinceNameTh}</TableCell>
            <TableCell>{row.groupAreaName}</TableCell>
            <TableCell>{row.segment}</TableCell>
            <TableCell>{row.farmSize}</TableCell>
            <TableCell>{row.farmAmount}</TableCell>
            <TableCell>{row.houseAmount}</TableCell>
            <TableCell>
              {thousandComma(row.actualSellingFeedRevenue) + " บาท"}
            </TableCell>
            <TableCell>
              {thousandComma(row.targetSellingFeedRevenue) + " บาท"}
            </TableCell>
          </TableRow>
        </>
      );
    });
  }, [data, currentPage]);

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <div className="flex justify-start items-center gap-3">
              <div onClick={() => navigate("/admin/performance/sale")}>
                <ChevronLeftIcon fontSize="large" />
              </div>
              <span className="text-[#000] font-bold text-3xl">
                Revenue (THB)
              </span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="flex flex-col py-6 gap-6">
              <div className="flex flex-row gap-3 items-end">
                <Dropdown
                  label="ปี"
                  options={yearOption.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => {
                    setGradeYear(value[0].value);
                  }}
                  onReset={() => {
                    setGradeYear("");
                  }}
                  renderValueArray={
                    [
                      {
                        value: gradeYear,
                        label: gradeYear,
                      },
                    ] as TDropdownValue[]
                  }
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                />
                <Dropdown
                  label="เดือน"
                  options={monthOption}
                  onChange={(value) => {
                    setGradeMonth(value);
                  }}
                  onReset={() => {
                    setGradeMonth([]);
                  }}
                  renderValueArray={gradeMonth}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                  multiple
                />
                <Dropdown
                  label="Group Area"
                  options={groupAreaOption.map((row) => ({
                    value: row.id,
                    label: row.name,
                  }))}
                  onChange={(value) => {
                    setGradeGroupArea(value);
                  }}
                  onReset={() => {
                    setGradeGroupArea([]);
                  }}
                  renderValueArray={gradeGroupArea}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                />
                <Dropdown
                  label="Segment"
                  options={segmentOption.map((row) => ({
                    value: row,
                    label: row,
                  }))}
                  onChange={(value) => setGradeSegment(value)}
                  onReset={() => {
                    setGradeSegment([]);
                  }}
                  renderValueArray={gradeSegment}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                  multiple
                />
                <ButtonFillCustom
                  title="ค้นหา"
                  onClick={() => fetchSalePerformanceData()}
                  className="!mb-3"
                />
              </div>
              <div className="w-full flex justify-between">
                <TextInputSearchCustom
                  value={searchText}
                  className="max-w-[470px]"
                  onChange={(event) => setSearchText(event.target.value)}
                  placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
                  InputProps={{
                    endAdornment: searchText ? (
                      <CloseRoundedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchText("");
                        }}
                      />
                    ) : null,
                  }}
                />
                <ButtonOutlinedCustom
                  title="นำออก"
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  onClick={() => handleExportData()}
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                />
              </div>
            </div>

            <TableCustom
              order={orderByType}
              onChangeOrder={setOrderByType}
              orderBy={currentOrderByKey}
              onChangeOrderBy={setCurrentOrderByKey as any}
              totalItemSize={0}
              headerItems={headerItems}
              page={currentPage}
              onChangePage={setCurrentPage}
              rowsPerPage={10}
              rows={[]}
              EmptyComponent={
                data?.length === 0 ? (
                  <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
                ) : null
              }
              rowChildren={rowChildren}
              currentPageItems={data!.map(({ id }) => id)}
              hideSelect
              itemsSelected={[]}
              onSelectItem={(_) => {}}
              onSelectAllClick={(_) => {}}
            />
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default RevenueTable;
