import ClearIcon from "@mui/icons-material/Clear";
import { Box, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import ButtonFillCustom from "../../../admin/components/Button/ButtonFillCustom";
import PopupImage from "../../../assets/images/FarmRegistration/popup_bg.png";
import NoticeLogo from "../../../assets/images/Farms/Notice-logo.svg";
import { deleteStaff } from "./staff.service";

type PopupType = {
  isOpen: boolean;
  handleClose: () => void;
  id: string;
};

const RemoveStaffNoticePopup = ({ isOpen, handleClose, id }: PopupType) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        className="flex bg-bottom bg-no-repeat bg-white rounded-3xl p-2"
        style={{
          backgroundImage: `url(${PopupImage})`,
          width: "90%",
          maxWidth: "500px",
          margin: "auto",
          marginTop: "20%",
        }}
      >
        <div className="p-3 h-full flex flex-col justify-between w-full">
          <div className="flex flex-col items-end">
            <ClearIcon
              className="w-[30px] h-[30px] mr-2 mt-2 cursor-pointer"
              onClick={handleClose}
            />
          </div>
          <div className="flex flex-col">
            <div className="flex flex-col grow mt-10 items-center">
              <img
                src={NoticeLogo}
                className="mb-[32px] w-[100%] max-w-[130px]"
                alt="Notice-Logo"
              />
              <div className="flex flex-col items-center">
                <h3 className="font-medium text-[24px] text-justify text-[#F57C00]">
                  {t("RoleAndPermission.ConfirmDelete")}!
                </h3>
                <h4 className="py-2 text-center text-[#646464] font-normal">
                  {t("RoleAndPermission.ConfirmDeleteTwo")} <br />
                  {t("FinancialSupport.ConfirmEvidenceTitleTwo")}
                </h4>
              </div>
            </div>
            <div>
              <div className="mt-10 mb-5 flex items-center justify-center">
                <ButtonFillCustom
                  onClick={async () => {
                    try {
                      await deleteStaff(id);
                      navigate("/partner/account-center/remove-staff-success");
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                  title={t("FinancialSupport.Confirm")}
                  btnTextColor="#ffffff"
                  btnBgColor="#68C184"
                  className={
                    "!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RemoveStaffNoticePopup;
