import { Dialog, DialogContent, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { getFarmerDetail } from "../../../../services/admin/user-management/customer.service";
import { IGetDetailFarmer } from "../../../../types/admin/user-management/customer";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import EditCustomerModal from "./EditCustomerModal";
import { useAdminAuth } from "../../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

type DetailPartnerModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  selectedRow: string;
  showButton: boolean;
};

const DetailCustomerModal = ({
  isOpen,
  handleClose,
  selectedRow,
  showButton,
}: DetailPartnerModalProps) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.settingUserManagementFarmer === "edit";
  const [isLoading, setIsLoading] = useState(false);
  const [farmerDetail, setFarmerDetail] = useState<IGetDetailFarmer | null>();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const handleFetch = async () => {
    if (selectedRow) {
      setIsLoading(true);
      try {
        const farmer = await getFarmerDetail(selectedRow);
        setFarmerDetail(farmer);
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      handleFetch();
    }
  }, [isOpen]);

  return (
    <>
      <Dialog
        open={isLoading || isOpen}
        onClose={() => handleClose(true)}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
      >
        <DialogContent sx={{ width: "750px" }}>
          <div className="flex justify-between items-center">
            <p className="text-lg font-bold pb-3">
              {t("userManagement.farmer.button.seeMore")}
            </p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className="flex justify-between items-center my-2">
            <p className="text-lg font-bold pb-3 mb-1 text-[#3777BC]">
              ข้อมูลลูกค้า
            </p>
            {roleActionEdit && showButton && (
              <ButtonOutlinedCustom
                title={t("userManagement.farmer.button.editUser")}
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                onClick={() => {
                  setIsOpenEditModal(true);
                  handleClose(true);
                }}
              />
            )}
          </div>
          <div className="flex">
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">รหัสลูกค้า</p>
              <p className="text-sm pb-3 mb-1">
                {farmerDetail?.farmer.registrationId}
              </p>
            </div>
            <div className="items-start w-[50%]">
              <p className=" text-sm font-bold pb-3 mb-1">ชื่อลูกค้า</p>
              <p className=" text-sm pb-3 mb-1">
                {`${farmerDetail?.farmer.name} ${farmerDetail?.farmer.surname}`}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">วันเกิด</p>
              <p className="text-sm pb-3 mb-1">
                {farmerDetail?.farmer.dob
                  ? dayjs(farmerDetail?.farmer.dob).format("DD/MM/YYYY")
                  : "-"}
              </p>
            </div>
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">เลขบัตรประชาชน</p>
              <p className="text-sm pb-3 mb-1">
                {farmerDetail?.farmer.idCardNumber ?? "-"}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">อีเมล</p>
              <p className="text-sm pb-3 mb-1">
                {farmerDetail?.farmer.email ?? "-"}
              </p>
            </div>
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">เบอร์โทรศัพท์</p>
              <p className="text-sm pb-3 mb-1">
                {farmerDetail?.farmer.mobileNumber ?? "-"}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">วันที่ลงทะเบียน</p>
              <p className="text-sm pb-3 mb-1">
                {dayjs(farmerDetail?.farmer.registerDate).format("DD/MM/YYYY")}
              </p>
            </div>
            <div className="items-start w-[50%]">
              <p className="text-sm font-bold pb-3 mb-1">ใช้งานล่าสุด</p>
              <p className="text-sm pb-3 mb-1">
                {dayjs(farmerDetail?.farmer.lastActiveAt).format(
                  "DD/MM/YYYY HH:mm"
                )}
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <EditCustomerModal
        isOpen={isOpenEditModal}
        handleClose={() => setIsOpenEditModal(false)}
        selectedRow={selectedRow}
      />
    </>
  );
};

export default DetailCustomerModal;
