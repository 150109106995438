import React, { useEffect, useRef } from "react";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { FormProvider, useForm } from "react-hook-form";
import { ArticleFile } from "../../../../../../pages/farm-community/type";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import dayjs from "dayjs";
import {
  DATE_FORMAT_DATE_PICKER2,
  DATE_FORMAT_ENG,
} from "../../../../../../const/app.constant";
import { SvgIcon } from "@mui/material";
import { CalendarSvg } from "../../../../../assets/svg/Calendar.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import TextInputCustom from "../../../../../components/Input/TextInputCustom";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import ListOfUploadedFile2 from "../../../../../../components/common/File/ListOfUploadedFile2";
import {
  handleDownloadObject,
  handleUploadFileToAzure,
} from "../../../../../../utils/downloadFile";
import DragAndDropUploadImage from "../../../../../components/Input/DragAndDropUploadImage";
import { commonDownloadFile } from "../../../../../../services/CommonApiService";
import RHFTextAreaCustom from "../../../../../components/Input/RHFTextAreaCustom";
import DatePickerPopover from "../../../../../components/Input/DatePickerPopover/DatePickerPopover";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import {
  createPigPrice,
  editPigPrice,
  getPigPriceUploadUrl,
} from "../../../services/farmCommunity.service";
import { IArticleDoc } from "./CreateArticleModal";
import { IGetPigPriceRow } from "../../../types/farmCommunity";
import { useTranslation } from "react-i18next";

type Props = {
  data?: IGetPigPriceRow;
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
};

export type ICreatePigPrice = {
  topicName: string;
  announceDate: string;
  description: string;
  northLowestPrice: number;
  northHighestPrice: number;
  southLowestPrice: number;
  southHighestPrice: number;
  eastLowestPrice: number;
  eastHighestPrice: number;
  westLowestPrice: number;
  westHighestPrice: number;
  centralLowestPrice: number;
  centralHighestPrice: number;
  northEastLowestPrice: number;
  northEastHighestPrice: number;
  file: File[];
  uploadedFile: ArticleFile[];
};

const CreatePigPrice = ({ isOpen, handleClose, data }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const fileArticle: IArticleDoc["file"] = [];
  const validateSchema = yup.object().shape({
    topicName: yup.string().required("กรุณากรอก"),
    northLowestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
    northHighestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
    southLowestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
    southHighestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
    eastLowestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
    eastHighestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
    westLowestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
    westHighestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
    northEastLowestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
    northEastHighestPrice: yup
      .number()
      .moreThan(0, "กรุณากรอก")
      .typeError("กรุณากรอก"),
  });

  const methods = useForm<ICreatePigPrice>({
    defaultValues: {
      topicName: "",
      announceDate: "",
      description: "",
      northLowestPrice: 0,
      northHighestPrice: 0,
      southLowestPrice: 0,
      southHighestPrice: 0,
      eastLowestPrice: 0,
      eastHighestPrice: 0,
      westLowestPrice: 0,
      westHighestPrice: 0,
      northEastLowestPrice: 0,
      northEastHighestPrice: 0,
      centralLowestPrice: 0,
      centralHighestPrice: 0,
      file: [],
      uploadedFile: [],
    },
    resolver: yupResolver(validateSchema as any),
  });

  const refCalendarDate =
    useRef<React.ElementRef<typeof DatePickerPopover>>(null);

  const handleCalenderSave = (value: Date | undefined) => {
    if (value) {
      methods.setValue(
        "announceDate",
        dayjs(value).format(DATE_FORMAT_DATE_PICKER2)
      );
    }
  };

  const handleCalendarClear = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    methods.setValue("announceDate", "");
  };

  const handleDeleteFile = async () => {
    methods.setValue("file", []);
  };
  const handleDeleteUploadFile = async () => {
    methods.setValue("uploadedFile", []);
  };

  const handleUploadFiles = (files: File[]) => {
    methods.setValue("file", files);
  };

  const onDownload = async (row: { id: string; fileOriginalName: string }) => {
    await commonDownloadFile(
      `/farm-community/pig-price/download/${row.id}`,
      row.fileOriginalName
    );
  };

  const submitAPI = async (value: ICreatePigPrice) => {
    if (value.file.length) {
      const response = await getPigPriceUploadUrl();
      if (response?.blob?.blobUrl) {
        await handleUploadFileToAzure(response?.blob?.blobUrl, value.file[0]);
        fileArticle.push({
          fileId: response.blob.fileId || "",
          fileName: value.file[0].name,
        });
      }
    }

    if (data) {
      if (value.uploadedFile.length) {
        fileArticle.push({
          fileId: value.uploadedFile[0].id || "",
          fileName: value.uploadedFile[0].fileOriginalName || "",
        });
      }
    }

    const body: any = {
      topicName: value.topicName,
      announceDate: dayjs(
        value.announceDate,
        DATE_FORMAT_DATE_PICKER2
      ).toDate(),
      description: value.description,
      northLowestPrice: value.northLowestPrice,
      northHighestPrice: value.northHighestPrice,
      southLowestPrice: value.southLowestPrice,
      southHighestPrice: value.southHighestPrice,
      eastLowestPrice: value.eastLowestPrice,
      eastHighestPrice: value.eastHighestPrice,
      westLowestPrice: value.westLowestPrice,
      westHighestPrice: value.westHighestPrice,
      northEastLowestPrice: value.northEastLowestPrice,
      northEastHighestPrice: value.northEastHighestPrice,
      centralHighestPrice: value.centralHighestPrice,
      centralLowestPrice: value.centralLowestPrice,
      file: {
        fileId: fileArticle[0].fileId
          ? fileArticle[0].fileId
          : value.uploadedFile[0].id || "",
        fileName: fileArticle[0].fileName
          ? fileArticle[0].fileName
          : value.uploadedFile[0].fileOriginalName || "",
      },
    };

    if (data) {
      await editPigPrice(data?.id, body);
    } else {
      await createPigPrice(body);
    }
  };

  const handleSubmit = (value: ICreatePigPrice) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            console.error(error);
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  useEffect(() => {
    if (isOpen && data) {
      methods.reset({
        topicName: data.topicName,
        announceDate: dayjs(data.announceDate).format(DATE_FORMAT_ENG),
        description: data.description,
        northLowestPrice: data.northLowestPrice,
        northHighestPrice: data.northHighestPrice,
        southLowestPrice: data.southLowestPrice,
        southHighestPrice: data.southHighestPrice,
        eastLowestPrice: data.eastLowestPrice,
        eastHighestPrice: data.eastHighestPrice,
        westLowestPrice: data.westLowestPrice,
        westHighestPrice: data.westHighestPrice,
        centralLowestPrice: data.centralLowestPrice,
        centralHighestPrice: data.centralHighestPrice,
        northEastLowestPrice: data.northEastLowestPrice,
        northEastHighestPrice: data.northEastHighestPrice,
        uploadedFile: data.articleFiles,
        file: [],
      });
    } else {
      methods.reset();
    }
  }, [isOpen]);

  return (
    <>
      <AdminModal
        label={
          data
            ? t("farmCommunity.pigPricePage.edit.title")
            : t("farmCommunity.pigPricePage.add.title")
        }
        isOpen={isOpen}
        size={"md"}
        handleClose={() => handleClose(false)}
        children={
          <>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <div className="my-4">
                  <p className="text-[14px] mb-3 font-sans">
                    {t("farmCommunity.pigPricePage.add.topic")}{" "}
                    <span className="text-error-300">*</span>
                  </p>
                  <RHFTextInputCustom
                    controlName="topicName"
                    placeholder="กรอก"
                    error={Boolean(methods.formState.errors.topicName)}
                    helperText={
                      methods.formState.errors.topicName?.message || ""
                    }
                  />
                </div>
                <div className="my-4">
                  <p className="text-[14px] mb-3 font-sans">
                    {t("farmCommunity.pigPricePage.add.date")}
                  </p>
                  <RHFTextInputCustom
                    controlName="announceDate"
                    placeholder="เลือก"
                    InputProps={{
                      endAdornment:
                        methods.watch("announceDate") !== "" ? (
                          <CloseRoundedIcon
                            sx={{
                              cursor: "pointer",
                              fontSize: "18px !important",
                            }}
                            onClick={handleCalendarClear}
                          />
                        ) : (
                          <SvgIcon
                            sx={{
                              fontSize: "24px !important",
                              color: "#646464",
                            }}
                            fontSize="small"
                          >
                            <CalendarSvg />
                          </SvgIcon>
                        ),
                    }}
                    onClick={(
                      e: React.MouseEvent<HTMLDivElement, MouseEvent>
                    ) => {
                      e.stopPropagation();
                      refCalendarDate.current?.onOpen(e);
                    }}
                  />
                </div>
                <div className="my-4">
                  <div className="flex gap-3">
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        {t("farmCommunity.pigPricePage.add.region")}
                      </p>
                      <TextInputCustom disabled value="ภาคเหนือ" />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาต่ำสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="northLowestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.northLowestPrice
                        )}
                        helperText={
                          methods.formState.errors.northLowestPrice?.message ||
                          ""
                        }
                      />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาสูงสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="northHighestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.northHighestPrice
                        )}
                        helperText={
                          methods.formState.errors.northHighestPrice?.message ||
                          ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4">
                  <div className="flex gap-3">
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        {t("farmCommunity.pigPricePage.add.region")}
                      </p>
                      <TextInputCustom disabled value="ภาคตะวันออก" />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาต่ำสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="eastLowestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.eastLowestPrice
                        )}
                        helperText={
                          methods.formState.errors.eastLowestPrice?.message ||
                          ""
                        }
                      />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาสูงสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="eastHighestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.eastHighestPrice
                        )}
                        helperText={
                          methods.formState.errors.eastHighestPrice?.message ||
                          ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4">
                  <div className="flex gap-3">
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        {t("farmCommunity.pigPricePage.add.region")}
                      </p>
                      <TextInputCustom disabled value="ภาคตะวันตก" />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาต่ำสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="westLowestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.westLowestPrice
                        )}
                        helperText={
                          methods.formState.errors.westLowestPrice?.message ||
                          ""
                        }
                      />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาสูงสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="westHighestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.westHighestPrice
                        )}
                        helperText={
                          methods.formState.errors.westHighestPrice?.message ||
                          ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4">
                  <div className="flex gap-3">
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        {t("farmCommunity.pigPricePage.add.region")}
                      </p>
                      <TextInputCustom disabled value="ภาคอีสาน" />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาต่ำสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="northEastLowestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.northEastLowestPrice
                        )}
                        helperText={
                          methods.formState.errors.northEastLowestPrice
                            ?.message || ""
                        }
                      />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาสูงสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="northEastHighestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.northEastHighestPrice
                        )}
                        helperText={
                          methods.formState.errors.northEastHighestPrice
                            ?.message || ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4">
                  <div className="flex gap-3">
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        {t("farmCommunity.pigPricePage.add.region")}
                      </p>
                      <TextInputCustom disabled value="ภาคกลาง" />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาต่ำสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="centralLowestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.centralLowestPrice
                        )}
                        helperText={
                          methods.formState.errors.centralLowestPrice
                            ?.message || ""
                        }
                      />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาสูงสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="centralHighestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.centralHighestPrice
                        )}
                        helperText={
                          methods.formState.errors.centralHighestPrice
                            ?.message || ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="my-4">
                  <div className="flex gap-3">
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        {t("farmCommunity.pigPricePage.add.region")}
                      </p>
                      <TextInputCustom disabled value="ภาคใต้" />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาต่ำสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="southLowestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.southLowestPrice
                        )}
                        helperText={
                          methods.formState.errors.southLowestPrice?.message ||
                          ""
                        }
                      />
                    </div>
                    <div className="w-[33%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ราคาสูงสุด <span className="text-error-300">*</span>
                      </p>
                      <RHFTextInputCustom
                        type="number"
                        controlName="southHighestPrice"
                        placeholder="กรอก"
                        error={Boolean(
                          methods.formState.errors.southHighestPrice
                        )}
                        helperText={
                          methods.formState.errors.southHighestPrice?.message ||
                          ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    className="font-semibold text-[14px] mt-4"
                    htmlFor="files"
                  >
                    {t("farmCommunity.pigPricePage.add.uploadCoverImage")}
                  </label>
                  <ListOfFile
                    files={methods.watch("file")}
                    onDownload={handleDownloadObject}
                    onDelete={handleDeleteFile}
                  />
                  {methods.watch("uploadedFile") && (
                    <ListOfUploadedFile2
                      files={methods.watch("uploadedFile")}
                      fileKey="fileOriginalName"
                      onDownload={(row) => onDownload(row)}
                      hideDeleteBtn={false}
                      onDelete={handleDeleteUploadFile}
                    />
                  )}
                  <DragAndDropUploadImage onUpload={handleUploadFiles} />
                </div>
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="details"
                    className="mt-4 text-[14px] font-sans"
                  >
                    {t("farmCommunity.pigPricePage.add.description")}
                  </label>
                  <RHFTextAreaCustom
                    placeholder="กรอก"
                    controlName="description"
                  />
                  <p className="font-normal text-[12px] text-[#979797] text-end">
                    (maximum 500 characters)
                  </p>
                </div>
                <div className="flex justify-end mt-4">
                  <ButtonFillCustom title={"บันทึก"} type="submit" />
                </div>
              </form>
            </FormProvider>
          </>
        }
      />
      <DatePickerPopover
        ref={refCalendarDate}
        id={"date-picker"}
        date={methods.watch("announceDate") || ""}
        handleCalenderSave={handleCalenderSave}
      />
    </>
  );
};

export default CreatePigPrice;
