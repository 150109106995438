import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MenuItem, SvgIcon } from "@mui/material";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import SelectAdminCustom from "../../../components/Select/Select";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ICreditHoldOrderSearchFilter {
  daysOfDebt: string;
  onChangeDaysOfDebt: (value: string) => void;
  searchText: string;
  onChangeSearchText: (value: string) => void;
  onSearch: () => void;
  itemSelected: (string | number)[];
  onExport: () => Promise<void>;
}

function CreditHoldOrderSearchFilter({
  daysOfDebt,
  onChangeDaysOfDebt,
  searchText,
  onChangeSearchText,
  onSearch,
  itemSelected,
  onExport,
}: ICreditHoldOrderSearchFilter) {
  const { t } = useTranslation();
  const [toggleClear, setToggleClear] = useState(false);

  useEffect(() => {
    onSearch();
  }, [toggleClear]);

  const overdueDaysOptions = [
    {
      label: t("saleOrder.creditHoldOrdersTab.options.overdueOptions", {
        days: "3-7",
      }),
      value: "3,7",
    },
    {
      label: t("saleOrder.creditHoldOrdersTab.options.overdueOptions", {
        days: "7-30",
      }),
      value: "7,30",
    },
    {
      label: t("saleOrder.creditHoldOrdersTab.options.overdueOptions", {
        days: "31-60",
      }),
      value: "31,60",
    },
    {
      label: t("saleOrder.creditHoldOrdersTab.options.overdueOptions", {
        days: "61",
      }),
      value: "61",
    },
  ];

  return (
    <>
      <div className="flex flex-row justify-between">
        <div className="w-full">
          <span className="text-sm font-medium font-sans">
            {t("saleOrder.creditHoldOrdersTab.overDueDaysDropdown")}
          </span>
          <div className="pt-2 max-w-[470px]">
            <SelectAdminCustom
              value={daysOfDebt}
              placeholder="เลือก"
              renderValueArray={overdueDaysOptions.map((row) => ({
                value: row.value,
                label: row.label,
              }))}
              onChange={(event) =>
                onChangeDaysOfDebt(event.target.value as string)
              }
              menuItems={overdueDaysOptions.map((row) => (
                <MenuItem key={row.value} value={row.value}>
                  {row.label}
                </MenuItem>
              ))}
              endAdornment={
                daysOfDebt !== "" ? (
                  <CloseRoundedIcon
                    sx={{
                      cursor: "pointer",
                      fontSize: "16px !important",
                      marginRight: "20px",
                    }}
                    onClick={(_) => {
                      onChangeDaysOfDebt("");
                      setToggleClear((prev) => !prev);
                    }}
                  />
                ) : null
              }
            />
          </div>
        </div>
        <div className="h-full self-end">
          <ButtonFillCustom
            onClick={onSearch}
            title={t("saleOrder.creditHoldOrdersTab.search")}
          />
        </div>
      </div>
      <div className="flex flex-row w-full justify-between pt-6">
        <div className="flex w-full max-w-[656px]">
          <div className="w-full max-w-[470px] mr-4">
            <TextInputSearchCustom
              value={searchText}
              placeholder={t("saleOrder.creditHoldOrdersTab.searchBox")}
              onChange={(event) => onChangeSearchText(event.target.value)}
              InputProps={{
                endAdornment:
                  searchText.length > 0 ? (
                    <CloseRoundedIcon
                      sx={{ cursor: "pointer" }}
                      onClick={(_) => {
                        onChangeSearchText("");
                        setToggleClear((prev) => !prev);
                      }}
                    />
                  ) : null,
              }}
            />
          </div>
          {itemSelected.length > 0 && (
            <div className="w-full max-w-[170px] flex items-center font-sans text-[14px] text-[#646464]">
              {t("saleOrder.creditHoldOrdersTab.checkedListBox", {
                checked: itemSelected.length,
              })}
            </div>
          )}
        </div>
        <ButtonOutlinedCustom
          disabled={itemSelected.length === 0}
          title={t("saleOrder.creditHoldOrdersTab.download")}
          btnTextColor="#68C184"
          btnBorderColor="#68C184"
          onClick={onExport}
          startIcon={
            <SvgIcon
              sx={{
                fontSize: "14px !important",
                color: "currentColor",
                marginBottom: "3px",
              }}
              fontSize="small"
            >
              <DownloadSvg />
            </SvgIcon>
          }
        />
      </div>
    </>
  );
}

export default CreditHoldOrderSearchFilter;
