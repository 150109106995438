import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Divider, TableCell } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuBar from "../../../components/MenuBar/MenuBar";
import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../components/Table/TableCustom";
import ExpandSaleTargetSaleLead from "../components/ExpandSaleTargetSaleLead";
import { useSaleTargetManagementTableHook } from "../hooks/useSaleTargetManagementTable.hook";
import { useSaleTargetSaleLead } from "../hooks/useSaleTargetSaleLead.hook";
import { ISaleTargetSaleLeadTable } from "../types/SaleTargetTable.type";

type Props = {};

const headCells: ITableCustomHeadCell<keyof ISaleTargetSaleLeadTable>[] = [
  {
    id: "groupName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อกลุ่ม",
    width: "200px",
    isSorting: false,
  },
  {
    id: "province",
    numeric: false,
    disablePadding: false,
    label: "จังหวัด",
    width: "200px",
    isSorting: false,
  },
  {
    id: "countSale",
    numeric: false,
    disablePadding: false,
    label: "ชื่อพนักงาน",
    width: "200px",
    isSorting: false,
  },
  {
    id: "province",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "20px",
    isSorting: false,
  },
];

const SaleTargetManagementDetail = (props: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const searchSaleTarget = useSaleTargetSaleLead();
  const { handleFetchGetById, listById } = useSaleTargetManagementTableHook(
    searchSaleTarget,
    state.id
  );

  useEffect(() => {
    handleFetchGetById();
  }, [
    searchSaleTarget.page,
    searchSaleTarget.order,
    searchSaleTarget.limit,
    searchSaleTarget.direction,
  ]);

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <div className="flex items-center">
              <div onClick={() => navigate(-1)}>
                <ChevronLeftIcon fontSize="large" />
              </div>
              <span className="font-sans text-[#000] font-bold text-3xl">
                {state.fullName}
              </span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />

            <div className="flex w-full pt-6">
              <TableCustom
                order={searchSaleTarget.direction}
                orderBy={searchSaleTarget.order}
                rowsPerPage={searchSaleTarget.page}
                itemsSelected={[]}
                page={searchSaleTarget.page}
                headerItems={headCells}
                onSelectAllClick={() => {}}
                rows={listById}
                currentPageItems={listById.map((row) => row.id)}
                onChangePage={(val) => searchSaleTarget.setPage(val)}
                onSelectItem={() => {}}
                totalItemSize={searchSaleTarget.count}
                onChangeOrder={(val) => searchSaleTarget.setOrder(val)}
                onChangeOrderBy={(val) =>
                  searchSaleTarget.setOrder(
                    val as keyof ISaleTargetSaleLeadTable
                  )
                }
                hideSelect
                rowChildren={
                  <>
                    {listById.map((row, index) => {
                      return (
                        <ExpandableTableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          expandComponent={
                            <ExpandSaleTargetSaleLead
                              userTarget={row.userTarget}
                              customerTarget={row.customerTarget}
                              feedVolumeTarget={row.feedVolumeTarget}
                              feedRevenueTarget={row.feedRevenueTarget}
                              basicPartnerTarget={row.basicPartnerTarget}
                              corePartnerTarget={row.corePartnerTarget}
                            />
                          }
                        >
                          <TableCell
                            align="left"
                            sx={{
                              fontFamily: "IBM Plex Sans Thai",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "19px",
                            }}
                          >
                            {row.groupName}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontFamily: "IBM Plex Sans Thai",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "19px",
                            }}
                          >
                            {row.provinceName}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontFamily: "IBM Plex Sans Thai",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "19px",
                            }}
                          >
                            {row.saleName}
                          </TableCell>
                        </ExpandableTableRow>
                      );
                    })}
                  </>
                }
              ></TableCustom>
            </div>
          </div>
        </div>
      </MenuBar>
      ;
    </>
  );
};

export default SaleTargetManagementDetail;
