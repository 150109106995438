import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MenuItem, SvgIcon } from "@mui/material";
import { FormEvent, useRef, useState } from "react";
import { CalendarSvg } from "../../../../assets/svg/Calendar.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import TextInputCustom from "../../../../components/Input/TextInputCustom";
import SelectAdminCustom from "../../../../components/Select/Select";
import { TUseFarmCommunityFilter } from "../../hooks/farm-community/useFarmCommuFilter.hook";
import DateRangePickerPopover from "../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import dayjs from "dayjs";
import { FILTER_DATE_FORMAT } from "../../../sale-order/components/SaleOrderSearchFilter";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import SelectSortArticleModal from "./Modal/SelectSortArticleModal";
import SortPigPiceModal from "./Modal/SortPigPiceModal";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

type Props = {
  searchFarmCommunity: TUseFarmCommunityFilter;
  onSearch: () => void;
  hideSelect?: boolean;
  tabIndex: number;
};

export const farmCommunityTypeOptions = [
  { value: "video", label: "วิดีโอ" },
  { value: "article", label: "บทความ" },
];

export const SearchFarmCommunity = ({
  searchFarmCommunity,
  onSearch,
  hideSelect,
  tabIndex,
}: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const actionRoleFunction = action?.masterDataFarmCommunity === "edit";
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isOpenSortArticle, setIsOpenSortArticle] = useState(false);
  const [isOpenSortPigPice, setIsOpenSortPigPrice] = useState(false);
  const refCalendarRangeDate =
    useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);

  const handleSort = [setIsOpenSortArticle, setIsOpenSortPigPrice];
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchFarmCommunity.setPage(1);
    onSearch();
  };

  const handleSelectDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);

    const formattedStartDateIso = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).toISOString();

    const formattedEndDateIso = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).toISOString();

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    searchFarmCommunity.setStartDate(formattedStartDateIso);
    searchFarmCommunity.setEndDate(formattedEndDateIso);
  };

  const handleCalenderSaveDate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();

    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    const formattedStartDateIso = dayjs(value[0].startDate).toISOString();
    const formattedEndDateIso = dayjs(value[0].endDate).toISOString();

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    searchFarmCommunity.setStartDate(formattedStartDateIso);
    searchFarmCommunity.setEndDate(formattedEndDateIso);
  };

  return (
    <div className="mt-6">
      <form onSubmit={handleSubmit}>
        <div className="flex justify-between">
          <div className="flex gap-6">
            <div className="w-[340px]">
              <span className="text-sm font-medium font-sans">
                วันที่อัปเดตล่าสุด
              </span>
              <div className="pt-2">
                <TextInputCustom
                  placeholder="เริ่มต้น - สิ้นสุด"
                  value={startDate ? startDate + " - " + endDate : ""}
                  InputProps={{
                    endAdornment:
                      startDate !== "" ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(e) => {
                            searchFarmCommunity.setStartDate("");
                            searchFarmCommunity.setEndDate("");
                            setEndDate("");
                            setStartDate("");
                            e.stopPropagation();
                            searchFarmCommunity.setToggleFetch((prev) => !prev);
                          }}
                        />
                      ) : (
                        <SvgIcon
                          sx={{
                            fontSize: "24px !important",
                            color: "#646464",
                          }}
                          fontSize="small"
                        >
                          <CalendarSvg />
                        </SvgIcon>
                      ),
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    refCalendarRangeDate.current?.onOpen(e);
                  }}
                />
              </div>
            </div>
            {!hideSelect && (
              <div className="w-[340px]">
                <span className="text-sm font-medium font-sans">ประเภท</span>
                <div className="pt-2">
                  <SelectAdminCustom
                    placeholder="เลือก"
                    value={searchFarmCommunity.type}
                    renderValueArray={farmCommunityTypeOptions!.map((row) => ({
                      value: row.value,
                      label: row.label,
                    }))}
                    onChange={(event) =>
                      searchFarmCommunity.setType(event.target.value as string)
                    }
                    menuItems={farmCommunityTypeOptions!.map((row) => (
                      <MenuItem key={row.value} value={row.value}>
                        {row.label}
                      </MenuItem>
                    ))}
                    endAdornment={
                      searchFarmCommunity.type !== "" ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "16px !important",
                            marginRight: "20px",
                          }}
                          onClick={(_) => {
                            searchFarmCommunity.setType("");
                            searchFarmCommunity.setToggleFetch((prev) => !prev);
                          }}
                        />
                      ) : null
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="pt-8">
            <ButtonFillCustom
              type="submit"
              title={t("common.button.search")}
              onClick={() => handleSubmit}
            />
          </div>
        </div>
      </form>
      {actionRoleFunction && (
        <div className="flex justify-end mt-6">
          <ButtonOutlinedCustom
            title={"เรียงลำดับ"}
            onClick={() => handleSort[tabIndex](true)}
          />
        </div>
      )}
      <DateRangePickerPopover
        ref={refCalendarRangeDate}
        id={"calendar-range-popover-farm-community"}
        handleSelect={handleSelectDate}
        openCalender={isOpenDatePicker}
        onClose={() => setIsOpenDatePicker(false)}
        handleCalenderSave={handleCalenderSaveDate}
        startDate={startDate}
        endDate={endDate}
      />
      <SelectSortArticleModal
        isOpen={isOpenSortArticle}
        handleClose={() => setIsOpenSortArticle(false)}
        handleFetch={onSearch}
      />
      <SortPigPiceModal
        isOpen={isOpenSortPigPice}
        handleClose={(isSuccess) => {
          if (isSuccess) {
            onSearch();
          }
          setIsOpenSortPigPrice(false);
        }}
      />
    </div>
  );
};
