import { Modal, SvgIcon } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DownloadSvg } from "../../../../../../admin/assets/svg/Download.svg";
import ButtonFillCustom from "../../../../../../admin/components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../../../admin/components/Button/ButtonOutlinedCustom";
import MockQrCode from "../../../../../../assets/svg/MockQrCode";
import AppBarFS from "../../../../../../components/app-bar/AppBar";
import { getCommaNumber } from "../../../../../../utils/number";
import { tw } from "../../../../../../utils/tw";
import SuccessModal from "./SuccessModal";

interface IThaiQRPayment {
  isOpen: boolean;
  onClose: () => void;
  summary: string;
  customPrice: string;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`min-h-screen h-full bg-[white]`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(`flex flex-col w-full h-full gap-[16px] px-[16px]`),
  text1: tw(`font-bold text-[16px] text-[#3777BC]`),
  buttonContainer: tw(`h-full w-full`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  buttonTextContainer: tw(
    `flex flex-col gap-6 pt-8 px-[17.5px] pb-6 bottom-0 bg-[#FFFFFF] w-full z-10 fixed`
  ),
};

const ThaiQRPayment = (props: IThaiQRPayment) => {
  const { isOpen, onClose, summary, customPrice } = props;
  const [isShowSummary, setIsShowSummary] = useState(false);

  const onShowSummary = useCallback(() => {
    setTimeout(() => {
      setIsShowSummary(true);
    }, 400);
  }, []);

  const onCloseFn = useCallback(() => {
    onClose();
  }, []);

  const { t } = useTranslation();

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS
            title={t("FinancialSupport.Payment")}
            onIconPress={onCloseFn}
          />
        </div>
        <div className={classes.detailContainer}>
          <div className="bg-[#F6FBFF] rounded-[20px] p-4 gap-6 flex flex-col items-center">
            {/* <img
              src={`${randImg()}`}
              alt="Random image"
              width={266}
              height={269}
            /> */}
            <div className="w-[269px] h-[269px]">
              <MockQrCode />
            </div>
            <div className="flex flex-row justify-between gap-4">
              <div className={classes.text1}>
                {t("FinancialSupport.TotalDueAmount")}
              </div>
              <div className={tw(classes.text1, "text-[#34407B]")}>
                {getCommaNumber(+(customPrice || summary), true)}{" "}
                {t("reportOrder.currency")}
              </div>
            </div>
            <ButtonOutlinedCustom
              title={t("FinancialSupport.SaveImage")}
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              startIcon={
                <SvgIcon
                  sx={{
                    fontSize: "14px !important",
                    color: "currentColor",
                    marginBottom: "3px",
                  }}
                  fontSize="small"
                >
                  <DownloadSvg />
                </SvgIcon>
              }
              className="!h-[37px] !rounded-[50px]"
            />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <div className={classes.buttonTextContainer}>
            <ButtonFillCustom
              title={t("FinancialSupport.Next")}
              className={classes.button}
              onClick={onShowSummary}
            />
          </div>
        </div>
        <SuccessModal isOpen={isShowSummary} />
      </div>
    </Modal>
  );
};

export default memo(ThaiQRPayment);
