import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { tw } from "../../../../../../utils/tw";
import { IFarmersCreditFeed } from "../../../../../../types/CreditFeed.type";
import TableCustom from "../../../../../components/Table/TableCustom";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import { useTranslation } from "react-i18next";

const classes = {
  container: tw(`min-h-[240px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

interface ITable {
  data: IFarmersCreditFeed[];
  currentPage: number;
  setCurrentPage: (page: number) => void;
  itemSelected: string[];
  setItemSelected: (ids: string[]) => void;
  onOpenApplyCredit: () => void;
  idFramers: string;
  setIdFramers: Dispatch<SetStateAction<string>>;
  onGetCreditSetting?: (id: string) => void;
  countData: number;
  currentOrderByKey: "farmer_code" | "credit_rating" | "updated_at";
  setCurrentOrderByKey: Dispatch<
    SetStateAction<"farmer_code" | "credit_rating" | "updated_at">
  >;
  orderByType: "desc" | "asc";
  setOrderByType: Dispatch<SetStateAction<"desc" | "asc">>;
  isEdit?: boolean;
}

const TableTabFarmer = (props: ITable) => {
  const { t } = useTranslation();
  const {
    data,
    currentPage,
    setCurrentPage,
    itemSelected,
    setItemSelected,
    onOpenApplyCredit,
    idFramers,
    setIdFramers,
    onGetCreditSetting,
    countData,
    currentOrderByKey,
    setCurrentOrderByKey,
    orderByType,
    setOrderByType,
    isEdit,
  } = props;

  const headerItems = [
    {
      id: "farmer_code",
      numeric: false,
      disablePadding: false,
      label: t("creditFeed.qualifiedCustomerTab.customerId"),
      width: "129px",
      isSorting: true,
      className: "!whitespace-pre",
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t("creditFeed.qualifiedCustomerTab.customerName"),
      width: "372px",
      isSorting: false,
      className: "!whitespace-pre",
    },
    {
      id: "saleName",
      numeric: false,
      disablePadding: false,
      label: t("creditFeed.qualifiedCustomerTab.saleName"),
      width: "372px",
      isSorting: false,
      className: "!whitespace-pre",
    },
    {
      id: "applyCredit",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "149px",
      isSorting: false,
      className: "!whitespace-pre",
    },
  ];

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = data.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const onOpenFn = useCallback((id: string) => {
    onOpenApplyCredit();
    onGetCreditSetting && onGetCreditSetting(id);
  }, []);

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      const isItemSelected = isSelected(row.id);

      return (
        <TableRow
          key={row.id + index}
          selected={isItemSelected}
          aria-checked={isItemSelected}
        >
          <TableCell
            padding="checkbox"
            sx={{
              borderLeft: "1px solid rgba(224, 224, 224, 1)",
            }}
          >
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onClick={(event) => handleClick(event, row.id)}
              sx={{
                borderWidth: "1px",
                color: "#C9C9C9",
                "&.Mui-checked": {
                  color: "#68C184",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            <Link
              component="button"
              variant="body2"
              sx={{
                color: "#68C184",
                textDecorationColor: "#68C184",
                ":hover": {
                  fontSize: "16px",
                },
              }}
            >
              {row.registrationId}
            </Link>
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            {row.name + " " + row.surname}
          </TableCell>
          <TableCell
            sx={{
              whiteSpace: "pre",
            }}
          >
            {row.userGroup.seller.name +
              " " +
              (row.userGroup.seller.surname === null
                ? ""
                : row.userGroup.seller.surname)}
          </TableCell>
          <TableCell
            sx={{
              borderRight: "1px solid rgba(224, 224, 224, 1)",
              whiteSpace: "pre",
            }}
            className="!flex !justify-center"
          >
            {isEdit && (
              <ButtonFillCustom
                title={"Apply Credit"}
                onClick={() => {
                  setIdFramers(row.id);
                  onOpenFn(row.id);
                }}
              />
            )}
          </TableCell>
        </TableRow>
      );
    });
  }, [data, currentPage, itemSelected, currentOrderByKey]);

  return (
    <div className={classes.container}>
      <TableCustom
        order={orderByType}
        onChangeOrder={setOrderByType}
        orderBy={currentOrderByKey}
        onChangeOrderBy={setCurrentOrderByKey as any}
        totalItemSize={countData}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={10}
        rows={data}
        EmptyComponent={
          countData === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={itemSelected}
        onSelectAllClick={handleSelectAllClick}
        onSelectItem={(val) => setItemSelected(val as string[])}
      />
    </div>
  );
};

export default memo(TableTabFarmer);
