import CircleIcon from "@mui/icons-material/Circle";
import { useTranslation } from "react-i18next";
import { TMappedStockRefillItems } from "../../../types/partner/stock.type";

type OrderFeedPartnerItemListProp = {
  stockList: TMappedStockRefillItems;
  index?: number;
};

const OrderFeedPartnerItemList = ({
  stockList,
  index,
}: OrderFeedPartnerItemListProp) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="pt-4 items-center">
        <CircleIcon
          sx={{
            fontSize: "8px",
            color: "#014F79",
            marginLeft: "12px",
          }}
        />
        <span className="ml-[10px] text-tertiary font-medium text-sm">
          {t("feedOrderAndDelivery.myOrder.card.item")} {index ?? 1}
        </span>
        <div className="px-4">
          <div className="flex justify-between text-sm mt-2">
            <p className="text-tertiary">
              {t("feedOrderAndDelivery.myOrder.card.brandName")}
            </p>
            <p className="text-primary">{stockList?.brandName.th}</p>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <p className="text-tertiary">
              {t("feedOrderAndDelivery.myOrder.card.brandSkuName")}
            </p>
            <p className="text-primary">{stockList?.brandItemName.th}</p>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <p className="text-tertiary">
              {t("feedOrderAndDelivery.myOrder.card.quantity")}
            </p>
            <p className="text-primary">{stockList?.feedAmount} ถุง</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderFeedPartnerItemList;
