export enum EUserLoginType {
  Partner = "partner",
  Employee = "employee",
  Customer = "customer",
}

export enum EUserLoginRole {
  Farmer = "farmer",
  FarmerStaff = "farmer_staff",
  Sale = "sale",
  SaleLead = "sale_lead",
  Lending = "lending",
  management = "management",
  Admin = "admin",
}
