import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { memo, useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { array, number, object, string } from "yup";
import ListOfFile from "../../../../../components/common/File/ListOfFile";
import ListOfUploadedFile2 from "../../../../../components/common/File/ListOfUploadedFile2";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import {
  approveInvoicePayment,
  getInvoiceApproveDetail,
} from "../../../../../services/admin/invoice/invoiceLending.service";
import {
  getReceiptDownloadUrlAPI,
  uploadReceiptPresignAPI,
} from "../../../../../services/admin/invoice/invoiceSale.service";
import { IApproveInvoicePayment } from "../../../../../types/invoice/invoiceLending.type";
import { thousandComma } from "../../../../../utils/common/string";
import {
  handleDownloadFileFromAzure,
  handleDownloadObject,
  handleUploadFileToAzure,
} from "../../../../../utils/downloadFile";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import DragAndDropUploadImage from "../../../../components/Input/DragAndDropUploadImage";
import RHFTextInputCustom from "../../../../components/Input/RHFTextInputCustom";
import AdminModal from "../../../../components/Modal/AdminModal";
import { useTranslation } from "react-i18next";

type TFullPaidModalProps = {
  invoiceId: string;
  isOpen: boolean;
  isFullPaid: boolean;
  handleClose: (isSuccess: boolean) => void;
};

type TInvoiceConfirmForm = {
  invoiceNumber: string;
  paidAmountRequire: number;
  invoiceReceipts: {
    id: string;
    verifyAmount: string;
    invoiceNumber: string;
    receiptFile: {
      fileId: string;
      fileName: string;
    }[];
    file: File[];
  }[];
};

const validationSchema = object({
  invoiceReceipts: array(
    object({
      verifyAmount: number().typeError("กรุณากรอกยอดเงิน"),
      invoiceNumber: string().required("กรุณากรอกเลขที่ใบเสร็จ"),
    })
  ),
});

const PaymentModal = ({
  invoiceId,
  isOpen,
  handleClose,
  isFullPaid,
}: TFullPaidModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<TInvoiceConfirmForm>({
    defaultValues: {
      invoiceReceipts: [],
    },
    resolver: yupResolver(validationSchema) as any,
  });
  const paidAmountRequire = thousandComma(
    methods.watch("paidAmountRequire") || 0,
    2
  );

  const handleInitData = useCallback(async () => {
    if (invoiceId) {
      try {
        dispatch(loadingActions.show({ message: "กำลังโหลดข้อมูล" }));
        const resp = await getInvoiceApproveDetail(invoiceId);
        if (resp.data.invoice) {
          const {
            data: { invoice },
          } = resp;
          methods.reset({
            invoiceNumber: invoice.invoiceNumber,
            paidAmountRequire: invoice.totalPrice || 0 - invoice.totalPaid || 0,
            invoiceReceipts: invoice.invoiceReceipts.map((invoiceReceipt) => ({
              id: invoiceReceipt.id,
              invoiceNumber: invoiceReceipt.invoiceNumber || "",
              receiptFile: invoiceReceipt.fileReceipt
                ? [
                    {
                      fileId: invoiceReceipt.fileReceipt.id || "",
                      fileName:
                        invoiceReceipt.fileReceipt.fileOriginalName || "",
                    },
                  ]
                : [],
              verifyAmount: isFullPaid
                ? (invoice.totalPrice || 0 - invoice.totalPaid || 0).toString()
                : "",
            })),
          });
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          dispatch(
            snakeActions.showMessage({
              type: "error",
              message:
                error.response?.data?.message || "Cannot get invoice details",
            })
          );
        }
      } finally {
        dispatch(loadingActions.hide());
      }
    }
  }, [invoiceId, isFullPaid]);

  const handleDownloadFile = async (row: {
    fileId: string;
    fileName: string;
  }) => {
    const { fileId, fileName } = row;
    const response = await getReceiptDownloadUrlAPI("receipt", fileId);
    if (response?.blob?.blobUrl) {
      handleDownloadFileFromAzure(response.blob.blobUrl, fileName);
    }
  };

  const handleDropImage = (files: File[]) => {
    methods.setValue("invoiceReceipts.0.file", files);
  };

  const handleDeleteImage = async () => {
    methods.setValue("invoiceReceipts.0.file", []);
  };

  const handleSubmit = async (value: TInvoiceConfirmForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการตรวจสอบยอดเงินชำระ",
        text: "คุณต้องการยืนยันรายการนี้ใช่หรือไม่",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังยืนยัน..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "ยืนยันการขำระเงินสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const submitAPI = async (value: TInvoiceConfirmForm) => {
    const fileReceipt = await handleUploadFile(
      value.invoiceReceipts[0].file?.[0]
    );
    const body: IApproveInvoicePayment = {
      invoiceReceipts: [
        {
          id: value.invoiceReceipts[0].id,
          verifyAmount: value.invoiceReceipts[0].verifyAmount,
          invoiceNumber: value.invoiceReceipts[0].invoiceNumber,
          receiptFile: fileReceipt || value.invoiceReceipts[0].receiptFile[0],
        },
      ],
    };
    await approveInvoicePayment(invoiceId, body);
  };

  const handleUploadFile = async (file?: File) => {
    if (file) {
      const preSign = await uploadReceiptPresignAPI("receipt");
      if (preSign?.blob?.blobUrl) {
        await handleUploadFileToAzure(preSign?.blob?.blobUrl, file);
        return { fileId: preSign?.blob.fileId, fileName: file.name };
      }
    }
  };

  useEffect(() => {
    handleInitData();
  }, [handleInitData]);

  return (
    <AdminModal
      label={
        `ยืนยันการชำระเงิน${isFullPaid ? "สำเร็จ" : ""} Invoice เลขที่ ` +
        methods.watch("invoiceNumber")
      }
      isOpen={
        isOpen && JSON.stringify(methods.watch("invoiceReceipts")) !== "[]"
      }
      handleClose={handleClose}
      size="sm"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2 text-sm">
              <p className="font-bold">
                {t(
                  "invoiceLending.undueInvoices.matchedPaymentModal.totalDueAmount"
                )}
              </p>
              <p>
                <span>{paidAmountRequire}</span>
                <span>&nbsp;&nbsp;</span>
                <span>{t("common.bath")}</span>
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <div className="w-1/2">
                  <label
                    htmlFor="invoiceReceipts[0].verifyAmount"
                    className="text-sm"
                  >
                    {t(
                      "invoiceLending.undueInvoices.matchedPaymentModal.paidAmount"
                    )}
                  </label>
                  <RHFTextInputCustom
                    placeholder="กรอก"
                    controlName="invoiceReceipts[0].verifyAmount"
                    type="number"
                    error={
                      !!methods.formState.errors.invoiceReceipts?.[0]
                        ?.verifyAmount
                    }
                    helperText={
                      methods.formState.errors.invoiceReceipts?.[0]
                        ?.verifyAmount?.message
                    }
                  />
                </div>
                <div className="w-1/2">
                  <label
                    htmlFor="invoiceReceipts[0].invoiceNumber"
                    className="text-sm"
                  >
                    {t(
                      "invoiceLending.undueInvoices.matchedPaymentModal.receiptNo"
                    )}
                  </label>
                  <RHFTextInputCustom
                    placeholder="กรอก"
                    controlName="invoiceReceipts[0].invoiceNumber"
                    type="text"
                    error={
                      !!methods.formState.errors.invoiceReceipts?.[0]
                        ?.invoiceNumber
                    }
                    helperText={
                      methods.formState.errors.invoiceReceipts?.[0]
                        ?.invoiceNumber?.message
                    }
                  />
                </div>
              </div>
              <div>
                <label className="font-bold text-sm">
                  {t(
                    "invoiceLending.undueInvoices.matchedPaymentModal.attachReceipt"
                  )}
                </label>

                {(methods.watch("invoiceReceipts")[0]?.file || []).length ===
                  0 && (
                  <ListOfUploadedFile2
                    files={methods.watch("invoiceReceipts")[0]?.receiptFile}
                    fileKey={"fileName"}
                    onDownload={handleDownloadFile}
                  />
                )}
                <ListOfFile
                  files={methods.watch("invoiceReceipts")[0]?.file || []}
                  onDelete={handleDeleteImage}
                  onDownload={handleDownloadObject}
                />
                <DragAndDropUploadImage onUpload={handleDropImage} />
              </div>
            </div>
            <div className="flex justify-end">
              <ButtonFillCustom title={"บันทึก"} type="submit" />
            </div>
          </div>
        </form>
      </FormProvider>
    </AdminModal>
  );
};

export default memo(PaymentModal);
