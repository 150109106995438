import {
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import RadioButton from "../../../../components/common/RadioButton";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import AdminModal from "../../../components/Modal/AdminModal";
import RHFSelectCustom from "../../../components/Select/RHFSelectCustom";
import { editRolePerMission, getDefaultFormRole } from "../role.service";
import {
  Admin,
  AdminEnum,
  Lending,
  LendingEnum,
  Management,
  ManagementEnum,
  Sale,
  SaleEnum,
  SaleLead,
} from "../role.type";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  roleName: string;
  id: string;
};
interface IEditRoleForm {
  role: string;
  function: string;
  sale_lead: SaleLead;
  sale: Sale;
  lending: Lending;
  management: Management;
  admin: Admin;
}

const EditRoleModal = ({ isOpen, handleClose, roleName, id }: Props) => {
  const dispatch = useDispatch();
  const methods = useForm<IEditRoleForm>({
    defaultValues: {
      role: "",
      function: "",
    },
  });

  const fetch = async () => {
    const data = await getDefaultFormRole();
    methods.reset({
      sale: data.sale,
      sale_lead: data.sale_lead,
      admin: data.admin,
      lending: data.lending,
      management: data.management,
    });
  };

  const submitAPI = (value: IEditRoleForm) => {
    const selectedFunction = methods.watch("function") as keyof IEditRoleForm;
    const payload: Record<string, any> = {
      role: selectedFunction,
    };

    if (selectedFunction && value[selectedFunction]) {
      payload[selectedFunction] = value[selectedFunction];
    }
    editRolePerMission(id, payload);
  };

  const handleSubmit = (value: IEditRoleForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose();
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const selectRoleOption = [
    "sale_lead",
    "sale",
    "lending",
    "management",
    "admin",
  ];

  useEffect(() => {
    if (isOpen) {
      fetch();
    }
    methods.reset();
  }, [isOpen]);

  //* sale
  const formSale = methods.getValues("sale") || [];
  const formSaleArr = Object.entries(formSale);
  //* lending
  const formLending = methods.getValues("lending") || [];
  const formLendingArr = Object.entries(formLending);
  //* sale_lead
  const formSaleLead = methods.getValues("sale_lead") || [];
  const formSaleLeadArr = Object.entries(formSaleLead);
  //* management
  const formManagement = methods.getValues("management") || [];
  const formManagementArr = Object.entries(formManagement);
  //* admin
  const formAdmin = methods.getValues("admin") || [];
  const formAdminArr = Object.entries(formAdmin);

  return (
    <div>
      <AdminModal
        label={"แก้ไข"}
        isOpen={isOpen}
        size={"md"}
        handleClose={handleClose}
        children={
          <>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col gap-2 text-base">
                    <p className="font-bold text-base">Role name</p>
                    <p className="text-base">{roleName}</p>
                  </div>
                </div>
                <div className="w-[70%] mt-4">
                  <p className="mb-3 font-sans font-bold text-base">Function</p>
                  <div className="w-full pb-3">
                    <RHFSelectCustom
                      value={methods.watch("function")}
                      controlName="function"
                      menuItems={selectRoleOption.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                      renderValueArray={selectRoleOption.map((row) => ({
                        value: row,
                        label: row,
                      }))}
                    />
                  </div>
                </div>
                <div className="w-full mt-4">
                  <TableCell
                    sx={{ bgcolor: "#FFFFFF", padding: 0, width: "100%" }}
                  >
                    <TableContainer
                      sx={{
                        scrollBehavior: "smooth",
                        overflow: "auto",
                      }}
                    >
                      <Table
                        sx={{
                          width: "100%",
                          border: "1px solid #E1E1E1",
                          overflow: "hidden",
                        }}
                        aria-labelledby="table-show-data"
                        size={"medium"}
                      >
                        <TableHead
                          sx={{
                            bgcolor: "#FBFBFB",
                            borderBottom: "1px solid #E1E1E1",
                          }}
                        >
                          <TableRow>
                            <TableCell
                              sx={{
                                height: "50px",
                                fontFamily: "IBM Plex Sans Thai",
                                fontWeight: 700,
                                fontSize: "14px",
                                padding: "0px 16px",
                                width: "150px",
                              }}
                              align="center"
                            >
                              Read only
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "50px",
                                fontFamily: "IBM Plex Sans Thai",
                                fontWeight: 700,
                                fontSize: "14px",
                                border: "0px",
                                padding: "0px 16px",
                                width: "150px",
                              }}
                              align="center"
                            >
                              Edit
                            </TableCell>
                            <TableCell
                              sx={{
                                height: "50px",
                                fontFamily: "IBM Plex Sans Thai",
                                fontWeight: 700,
                                fontSize: "14px",
                                border: "0px",
                                padding: "0px 16px",
                                width: "600px",
                              }}
                            >
                              รายละเอียด
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {methods.watch("function") === "sale" &&
                            formSaleArr.map(([key, value], index) => (
                              <TableRow key={index}>
                                <TableCell
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "10px 20px",
                                  }}
                                >
                                  <RadioButton
                                    checked={
                                      methods.watch("sale")?.[key] ===
                                      "readonly"
                                    }
                                    onClick={() => {
                                      methods.setValue(
                                        `sale.${key}`,
                                        "readonly"
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  <div className="!flex !justify-center">
                                    <RadioButton
                                      checked={
                                        methods.watch("sale")?.[key] === "edit"
                                      }
                                      onClick={() => {
                                        methods.setValue(`sale.${key}`, "edit");
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  {SaleEnum[key as keyof typeof SaleEnum]}
                                </TableCell>
                              </TableRow>
                            ))}
                          {methods.watch("function") === "sale_lead" &&
                            formSaleLeadArr.map(([key, value], index) => (
                              <TableRow key={index}>
                                <TableCell
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "10px 20px",
                                  }}
                                >
                                  <RadioButton
                                    checked={
                                      methods.watch("sale_lead")?.[key] ===
                                      "readonly"
                                    }
                                    onClick={() => {
                                      methods.setValue(
                                        `sale_lead.${key}`,
                                        "readonly"
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  <div className="!flex !justify-center">
                                    <RadioButton
                                      checked={
                                        methods.watch("sale_lead")?.[key] ===
                                        "edit"
                                      }
                                      onClick={() => {
                                        methods.setValue(
                                          `sale_lead.${key}`,
                                          "edit"
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  {SaleEnum[key as keyof typeof SaleEnum]}
                                </TableCell>
                              </TableRow>
                            ))}
                          {methods.watch("function") === "lending" &&
                            formLendingArr.map(([key, value], index) => (
                              <TableRow key={index}>
                                <TableCell
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "10px 20px",
                                  }}
                                >
                                  <RadioButton
                                    checked={
                                      methods.watch("lending")?.[key] ===
                                      "readonly"
                                    }
                                    onClick={() => {
                                      methods.setValue(
                                        `lending.${key}`,
                                        "readonly"
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  <div className="!flex !justify-center">
                                    <RadioButton
                                      checked={
                                        methods.watch("lending")?.[key] ===
                                        "edit"
                                      }
                                      onClick={() => {
                                        methods.setValue(
                                          `lending.${key}`,
                                          "edit"
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  {LendingEnum[key as keyof typeof LendingEnum]}
                                </TableCell>
                              </TableRow>
                            ))}
                          {methods.watch("function") === "management" &&
                            formManagementArr.map(([key, value], index) => (
                              <TableRow key={index}>
                                <TableCell
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "10px 20px",
                                  }}
                                >
                                  <RadioButton
                                    checked={
                                      methods.watch("management")?.[key] ===
                                      "readonly"
                                    }
                                    onClick={() => {
                                      methods.setValue(
                                        `management.${key}`,
                                        "readonly"
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  <div className="!flex !justify-center">
                                    <RadioButton
                                      checked={
                                        methods.watch("management")?.[key] ===
                                        "edit"
                                      }
                                      onClick={() => {
                                        methods.setValue(
                                          `management.${key}`,
                                          "edit"
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  {
                                    ManagementEnum[
                                      key as keyof typeof ManagementEnum
                                    ]
                                  }
                                </TableCell>
                              </TableRow>
                            ))}
                          {methods.watch("function") === "admin" &&
                            formAdminArr.map(([key, value], index) => (
                              <TableRow key={index}>
                                <TableCell
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "10px 20px",
                                  }}
                                >
                                  <RadioButton
                                    checked={
                                      methods.watch("admin")?.[key] ===
                                      "readonly"
                                    }
                                    onClick={() => {
                                      methods.setValue(
                                        `admin.${key}`,
                                        "readonly"
                                      );
                                    }}
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  <div className="!flex !justify-center">
                                    <RadioButton
                                      checked={
                                        methods.watch("admin")?.[key] === "edit"
                                      }
                                      onClick={() => {
                                        methods.setValue(
                                          `admin.${key}`,
                                          "edit"
                                        );
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "10px 20px",
                                  }}
                                >
                                  {AdminEnum[key as keyof typeof AdminEnum]}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </TableCell>
                </div>
                <div className="mt-4 flex justify-end">
                  <ButtonFillCustom title="บันทึก" type="submit" />
                </div>
              </form>
            </FormProvider>
          </>
        }
      />
    </div>
  );
};

export default EditRoleModal;
