import { Checkbox, Link, TableCell } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useInvoiceSearchFilter } from "../../../../../hooks/useInvoiceSearchFilter.hook";
import {
  getInvoiceSale,
  getListExportInvoiceSaleUpcoming,
} from "../../../../../services/admin/invoice/invoiceSale.service";
import { Row } from "../../../../../types/invoice/invoiceSale.type";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import DocumentModal from "../../../../components/Modal/DocumentModal/DocumentModal";
import UpdateInvoiceModal from "../../../../components/Modal/UpdateInvoiceModal";
import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";
import ExpandUpdateDocumentInvoice from "../../components/ExpandUpdateDocumentInvoice";
import { FilterInvoice } from "../../components/FilterInvoice";
import { IInvoiceTable, TInvoiceStatus } from "../../type";
import { thousandComma } from "../../../../../utils/common/string";
import { tw } from "../../../../../utils/tw";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

type Props = { handleFetchCount: () => void };

const UpdateDocumentInvoicePanel = ({ handleFetchCount }: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.invoiceSale === "edit";
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [orderInvoice, setOrderInvoice] = useState<IInvoiceTable[]>([]);
  const [invoiceLog, setInvoiceLog] = useState<Row[]>([]);
  const [count, setCount] = useState(0);
  const [isOpenDocumentModal, setIsOpenDocumentModal] =
    useState<boolean>(false);
  const [isOpenUpdateDocModal, setIsOpenUpdateDocModal] =
    useState<boolean>(false);
  const [modalData, setModalData] = useState<IInvoiceTable>();

  function createData(
    id: string,
    orderId: string,
    customerId: string,
    customerName: string,
    invoiceNumber: string,
    amountToBePaid: string,
    totalPending: string,
    paidAmount: string,
    receiveSuccessDate: string,
    paymentDueDate: string,
    updateDate: string,
    isUseRemainingCredit?: boolean,
    status?: TInvoiceStatus
  ): IInvoiceTable {
    return {
      id,
      orderId,
      customerId,
      customerName,
      invoiceNumber: invoiceNumber ? invoiceNumber : "-",
      amountToBePaid,
      totalPending,
      paidAmount,
      receiveSuccessDate: receiveSuccessDate
        ? dayjs(receiveSuccessDate).format("DD/MM/YYYY")
        : "-",
      paymentDueDate: paymentDueDate
        ? dayjs(paymentDueDate).format("DD/MM/YYYY")
        : "-",
      updateDate: updateDate ? dayjs(updateDate).format("DD/MM/YYYY") : "-",
      status: status as TInvoiceStatus,
      isUseRemainingCredit,
    };
  }

  const headCells: ITableCustomHeadCell<keyof IInvoiceTable>[] = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: false,
      label: t("invoice.waitToUpdateInvoiceTab.listOfInvoice.orderNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: t("invoice.waitToUpdateInvoiceTab.listOfInvoice.customerId"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t("invoice.waitToUpdateInvoiceTab.listOfInvoice.customerName"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: t("invoice.waitToUpdateInvoiceTab.listOfInvoice.invoiceNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "amountToBePaid",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่ต้องชำระ",
      width: "150px",
      isSorting: false,
    },
    {
      id: "totalPending",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่รอตรวจสอบ",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "paidAmount",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่ชำระเเล้ว",
      width: "150px",
      isSorting: false,
    },
    {
      id: "receiveSuccessDate",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.waitToUpdateInvoiceTab.listOfInvoice.productReceivedAcceptOrderDate"
      ),
      width: "150px",
      isSorting: true,
    },
    {
      id: "paymentDueDate",
      numeric: false,
      disablePadding: false,
      label: t("invoice.waitToUpdateInvoiceTab.listOfInvoice.paymentDueDate"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.waitToUpdateInvoiceTab.listOfInvoice.dateOfLastDocumentUpdate"
      ),
      width: "150px",
      isSorting: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: t("invoice.waitToUpdateInvoiceTab.listOfInvoice.status"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "120px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
      isSorting: false,
    },
  ];

  const filteredHeadCells = roleActionEdit
    ? headCells
    : headCells.toSpliced(11, 1);

  const OrderStatusMap: Record<
    TInvoiceStatus,
    { color: string; bgColor: string; label: string }
  > = {
    wait_for_update_document: {
      color: "#3F3748",
      bgColor: "#E4E4E4",
      label: "รออัปเดทเอกสาร",
    },
    verifying_document: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "อยู่ระหว่างตรวจสอบ",
    },
    overdue: {
      color: "",
      bgColor: "",
      label: "",
    },
    complete: {
      color: "",
      bgColor: "",
      label: "",
    },
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = orderInvoice.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const MapOrderByWithFilter: {
    [K in keyof IInvoiceTable]?: string;
  } = {
    receiveSuccessDate: "update_date",
    updateDate: "updated_at",
  };

  const statusUpdateDocumentInvoice = [
    {
      statusEN: "wait_for_update_document",
      statusTH: "รออัปเดตเอกสาร",
    },
    {
      statusEN: "verifying_document",
      statusTH: "อยู่ระหว่างตรวจสอบ",
    },
  ];

  const searchFilterInvoice = useInvoiceSearchFilter();

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchFilterInvoice.searchText);
    searchParams.append("status", searchFilterInvoice.searchSaleStatus);
    searchParams.append("page", searchFilterInvoice.page.toString());
    searchParams.append("limit", searchFilterInvoice.limit.toString());
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFilterInvoice.orderBy] ?? ""
    );
    searchParams.append("direction", searchFilterInvoice.direction);
    setOrderInvoice([]);
    const data = await getInvoiceSale(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row: any) => {
        return createData(
          row.id,
          row.orderNumber,
          row.farmerRegistrationId,
          row.farmerName + " " + row.farmerSurname,
          row.invoiceNumber,
          row.totalPrice.toString(),
          row.totalPending.toString(),
          row.totalPaid.toString(),
          row.receiveDate,
          row.dueDate,
          row.updatedAt,
          row.isUseRemainingCredit,
          row.status
        );
      });
      setCount(data.count);
      setOrderInvoice(newList);
      setInvoiceLog(data.rows.map((row) => row));
    }
    handleFetchCount();
  };

  useEffect(() => {
    handleFetch();
  }, [
    searchFilterInvoice.orderBy,
    searchFilterInvoice.direction,
    searchFilterInvoice.page,
  ]);

  const getExportExcelData = async () => {
    type TColumnName =
      | "หมายเลขสั่งซื้อ"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "เลข Invoice"
      | "วันรับสินค้าสำเร็จ"
      | "วันครบกำหนดชำระ"
      | "วันที่อัปเดตล่าสุด"
      | "สถานะ";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getListExportInvoiceSaleUpcoming(
      itemSelected
    );
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            หมายเลขสั่งซื้อ: row.orderNumber,
            รหัสลูกค้า: row.farmerRegistrationId,
            ชื่อลูกค้า: row.farmerName + " " + row.farmerSurname,
            "เลข Invoice": row.invoiceNumber,
            วันรับสินค้าสำเร็จ: dayjs(row.receiveDate).format("DD/MM/YYYY"),
            วันครบกำหนดชำระ: dayjs(row.dueDate).format("DD/MM/YYYY"),
            วันที่อัปเดตล่าสุด: dayjs(row.updatedAt).format("DD/MM/YYYY"),
            สถานะ: row.status as TInvoiceStatus,
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `update_document_invoice_sale_${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const handleModalDocument = (rowData: IInvoiceTable) => {
    setIsOpenDocumentModal(true);
    setModalData(rowData);
  };

  const handleDocumentModalClose = (isSuccess: boolean) => {
    setIsOpenDocumentModal(false);
    if (isSuccess) {
      handleFetch();
    }
  };

  const handleUpdateInvoice = (rowData: IInvoiceTable) => {
    setIsOpenUpdateDocModal(true);
    setModalData(rowData);
  };

  const handleUpdateDocModalClose = (isSuccess: boolean) => {
    setIsOpenUpdateDocModal(false);
    if (isSuccess) {
      handleFetch();
    }
  };

  return (
    <div className="w-full h-full pt-8">
      <FilterInvoice
        searchFilterInvoice={searchFilterInvoice}
        onSearch={handleFetch}
        isDisplaySelect={true}
        placeholder={t("invoice.waitToUpdateInvoiceTab.searchForInvoiceNumber")}
        onExport={handleExportExcel}
        itemSelected={itemSelected}
        select={statusUpdateDocumentInvoice}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchFilterInvoice.direction}
          orderBy={searchFilterInvoice.orderBy}
          rowsPerPage={searchFilterInvoice.limit}
          rows={orderInvoice}
          itemsSelected={itemSelected}
          headerItems={filteredHeadCells}
          page={searchFilterInvoice.page}
          onChangeOrderBy={(val) =>
            searchFilterInvoice.setOrderBy(val as keyof IInvoiceTable)
          }
          onChangeOrder={(val) => searchFilterInvoice.setDirection(val)}
          onChangePage={(val) => searchFilterInvoice.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={orderInvoice.map((row) => row.id)}
          rowChildren={
            <>
              {orderInvoice.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <ExpandableTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    expandComponent={
                      <ExpandUpdateDocumentInvoice
                        key={row.id}
                        id={row.id}
                        colSpan={11}
                        invoiceLog={invoiceLog[index].invoiceLogs}
                      />
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          console.info("I'm a button.");
                        }}
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.amountToBePaid &&
                        thousandComma(+row.amountToBePaid, 2) +
                          ` ${t("common.bath")}`}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.totalPending &&
                        thousandComma(+row.totalPending, 2) +
                          ` ${t("common.bath")}`}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paidAmount &&
                        thousandComma(+row.paidAmount, 2) +
                          ` ${t("common.bath")}`}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.receiveSuccessDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paymentDueDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.updateDate}
                    </TableCell>
                    <TableCell align="left">
                      {row.status ? (
                        <div className="flex flex-row w-full">
                          <ChipOrderStatus
                            bgColor={
                              OrderStatusMap[row.status as TInvoiceStatus]
                                .bgColor
                            }
                            fontColor={
                              OrderStatusMap[row.status as TInvoiceStatus].color
                            }
                            label={
                              OrderStatusMap[row.status as TInvoiceStatus].label
                            }
                          />
                        </div>
                      ) : null}
                    </TableCell>
                    {row.status !== "verifying_document" ? (
                      roleActionEdit && (
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <ButtonFillCustom
                            title={t(
                              "invoice.waitToUpdateInvoiceTab.updateDocumentsButton"
                            )}
                            onClick={() => handleUpdateInvoice(row)}
                          />
                        </TableCell>
                      )
                    ) : (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <Link
                          component="button"
                          onClick={() => handleModalDocument(row)}
                          variant="body2"
                          sx={{
                            color: "#68C184",
                            textDecorationColor: "#68C184",
                            ":hover": {
                              fontSize: "16px",
                            },
                          }}
                        >
                          ดูเอกสาร
                        </Link>
                      </TableCell>
                    )}
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <UpdateInvoiceModal
        handleModalClose={handleUpdateDocModalClose}
        isOpen={isOpenUpdateDocModal}
        data={modalData}
      />
      <DocumentModal
        handleModalClose={handleDocumentModalClose}
        isOpen={isOpenDocumentModal}
        data={modalData}
      />
    </div>
  );
};

export default UpdateDocumentInvoicePanel;
