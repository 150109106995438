import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Checkbox, MenuItem, SelectChangeEvent, SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import React, { FormEvent, useEffect, useState } from "react";
import { TUseSaleOrderSearchFilter } from "../../../../hooks/useSaleOrderSearchFilter.hook";
import { FeedBrands } from "../../../../types/FeedBrand.type";
import { CalendarSvg } from "../../../assets/svg/Calendar.svg";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import DateRangePickerPopover from "../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import SelectAdminCustom from "../../../components/Select/Select";
import { useTranslation } from "react-i18next";

interface ISaleOrderSearchFilter {
  feedBrands: FeedBrands[];
  searchFilter: TUseSaleOrderSearchFilter;
  itemSelected: (string | number)[];
  onExport: () => void;
  onSearch: () => void;
}
export const FILTER_DATE_FORMAT = "DD/MM/YYYY";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function SaleOrderSearchFilter({
  feedBrands,
  searchFilter,
  itemSelected,
  onExport,
  onSearch,
}: ISaleOrderSearchFilter) {
  const { t } = useTranslation();
  const [searchFeedBrandId, setSearchFeedBrandId] = useState("");
  const [toggleClear, setToggleClear] = useState(false);
  const refCalendarRangeOrderDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectOrderDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    searchFilter.setOrderStartDate(formattedStartDate);
    searchFilter.setOrderEndDate(formattedEndDate);
  };

  const [isOpenCalenderOrderDate, setIsOpenCalenderOrderDate] =
    React.useState<boolean>(false);
  const handleCloseCalenderOrderDate = () => {
    setIsOpenCalenderOrderDate(false);
  };

  const handleCalenderSaveOrderDate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    searchFilter.setOrderStartDate(formattedStartDate);
    searchFilter.setOrderEndDate(formattedEndDate);
  };

  const refCalendarRangeDeliveryDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectDeliveryDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    searchFilter.setDeliveryStartDate(formattedStartDate);
    searchFilter.setDeliveryEndDate(formattedEndDate);
  };

  const [isOpenCalenderDeliveryDate, setIsOpenCalenderDeliveryDate] =
    React.useState<boolean>(false);
  const handleCloseCalenderDeliveryDate = () => {
    setIsOpenCalenderDeliveryDate(false);
  };
  const handleCalenderSaveDeliveryDate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    searchFilter.setDeliveryStartDate(formattedStartDate);
    searchFilter.setDeliveryEndDate(formattedEndDate);
  };

  const refCalendarRangeRequestDeliveryDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectRequestDeliveryDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    searchFilter.setRequestDeliveryStartDate(formattedStartDate);
    searchFilter.setRequestDeliveryEndDate(formattedEndDate);
  };

  const [
    isOpenCalenderRequestDeliveryDate,
    setIsOpenCalenderRequestDeliveryDate,
  ] = React.useState<boolean>(false);
  const handleCloseCalenderRequestDeliveryDate = () => {
    setIsOpenCalenderRequestDeliveryDate(false);
  };
  const handleCalenderSaveRequestDeliveryDate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    searchFilter.setRequestDeliveryStartDate(formattedStartDate);
    searchFilter.setRequestDeliveryEndDate(formattedEndDate);
  };

  const getFeedSKUs = () => {
    const feedSKU = feedBrands.find((row) => row.id === searchFilter.feedId);
    return feedSKU?.feed_brand_items ?? [];
  };

  const handleChangeFeedId = (event: SelectChangeEvent<unknown>) => {
    searchFilter.setFeedId(event.target.value as string);
    searchFilter.setFeedBrandId([]);
  };

  const handleChangeFeedBrandId = (event: SelectChangeEvent<unknown>) => {
    // searchFilter.setFeedBrandId(event.target.value as string[]);

    searchFilter.setFeedBrandId(
      typeof event.target.value === "string"
        ? event.target.value.split(",")
        : (event.target.value as string[])
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch();
  };

  const getFilteredFeedSKUs = () => {
    const filteredSKUs = getFeedSKUs().filter((row) =>
      row.skuTh.toLowerCase().includes(searchFeedBrandId.toLocaleLowerCase())
    );
    return filteredSKUs;
  };

  useEffect(() => {
    onSearch();
  }, [toggleClear]);

  const handleSearch = () => {
    searchFilter.setPage(1);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="h-full w-full">
          <div className="flex flex-row">
            <div className="w-[25%]">
              <span className="text-sm font-medium font-sans">
                {t("saleOrder.upComingOrdersTab.orderDate")}
              </span>
              <div className="pt-2">
                <TextInputCustom
                  placeholder="เริ่มต้น - สิ้นสุด"
                  value={
                    searchFilter.orderStartDate
                      ? searchFilter.orderStartDate +
                        " - " +
                        searchFilter.orderEndDate
                      : ""
                  }
                  InputProps={{
                    endAdornment:
                      searchFilter.orderStartDate !== "" ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(e) => {
                            searchFilter.setOrderStartDate("");
                            searchFilter.setOrderEndDate("");
                            e.stopPropagation();
                            setToggleClear((prev) => !prev);
                          }}
                        />
                      ) : (
                        <SvgIcon
                          sx={{
                            fontSize: "24px !important",
                            color: "#646464",
                          }}
                          fontSize="small"
                        >
                          <CalendarSvg />
                        </SvgIcon>
                      ),
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    refCalendarRangeOrderDate.current?.onOpen(e);
                  }}
                />
              </div>
            </div>
            <div className="w-[25%] px-2">
              <span className="text-sm font-medium font-sans">
                วันที่ต้องการรับสินค้า
              </span>
              <div className="pt-2">
                <TextInputCustom
                  value={
                    searchFilter.requestDeliveryStartDate
                      ? searchFilter.requestDeliveryStartDate +
                        " - " +
                        searchFilter.requestDeliveryEndDate
                      : ""
                  }
                  placeholder="เริ่มต้น - สิ้นสุด"
                  InputProps={{
                    endAdornment:
                      searchFilter.requestDeliveryStartDate !== "" ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(e) => {
                            searchFilter.setRequestDeliveryStartDate("");
                            searchFilter.setRequestDeliveryEndDate("");
                            e.stopPropagation();
                            setToggleClear((prev) => !prev);
                          }}
                        />
                      ) : (
                        <SvgIcon
                          sx={{
                            fontSize: "24px !important",
                            color: "#646464",
                          }}
                          fontSize="small"
                        >
                          <CalendarSvg />
                        </SvgIcon>
                      ),
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    refCalendarRangeRequestDeliveryDate.current?.onOpen(e);
                  }}
                />
              </div>
            </div>
            <div className="w-[25%] px-2">
              <span className="text-sm font-medium font-sans">
                {t("saleOrder.upComingOrdersTab.brand")}
              </span>
              <div className="pt-2">
                <SelectAdminCustom
                  placeholder="เลือก"
                  MenuProps={MenuProps}
                  value={searchFilter.feedId}
                  renderValueArray={feedBrands.map((row) => ({
                    value: row.id,
                    label: row.nameTh,
                  }))}
                  onChange={(event) => handleChangeFeedId(event)}
                  menuItems={feedBrands.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.nameTh}
                    </MenuItem>
                  ))}
                  endAdornment={
                    searchFilter.feedId !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px !important",
                          marginRight: "20px",
                        }}
                        onClick={(_) => {
                          searchFilter.setFeedId("");
                          searchFilter.setFeedBrandId([]);
                          setSearchFeedBrandId("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null
                  }
                />
              </div>
            </div>
            <div className="w-[25%] pr-2">
              <span className="text-sm font-medium font-sans">
                {t("saleOrder.upComingOrdersTab.sku")}
              </span>
              <div className="pt-2">
                <SelectAdminCustom
                  placeholder="เลือก"
                  MenuProps={MenuProps}
                  multiple
                  value={searchFilter.feedBrandId}
                  renderValueArray={getFeedSKUs().map((row) => ({
                    value: row.id,
                    label: row.skuTh,
                  }))}
                  onChange={(e) => handleChangeFeedBrandId(e)}
                  menuItems={[
                    <div key={"idx"} className="px-3 pb-2">
                      <TextInputSearchCustom
                        value={searchFeedBrandId}
                        placeholder="ค้นหาโดยเบอร์อาหารสัตว์"
                        onChange={(event) => {
                          setSearchFeedBrandId(event.target.value);
                        }}
                        onClickCapture={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        onKeyDown={(e) => e.stopPropagation()}
                        InputProps={{
                          endAdornment:
                            searchFilter.searchText.length > 0 ? (
                              <CloseRoundedIcon
                                sx={{
                                  cursor: "pointer",
                                  fontSize: "18px !important",
                                }}
                                onClick={(_) => {
                                  setSearchFeedBrandId("");
                                  setToggleClear((prev) => !prev);
                                }}
                              />
                            ) : null,
                        }}
                      />
                    </div>,
                    ...getFilteredFeedSKUs().map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        <Checkbox
                          checked={searchFilter.feedBrandId.includes(row.id)}
                          size="small"
                          sx={{
                            "&.MuiCheckbox-colorPrimary": {
                              color: "#68C184",
                            },
                          }}
                        />
                        {row.skuTh}
                      </MenuItem>
                    )),
                  ]}
                  endAdornment={
                    searchFilter.feedBrandId.length > 0 ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "16px !important",
                          marginRight: "20px",
                        }}
                        onClick={(_) => {
                          searchFilter.setFeedBrandId([]);
                          setSearchFeedBrandId("");
                          setToggleClear((prev) => !prev);
                        }}
                      />
                    ) : null
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex w-full justify-between pt-4">
            <div className="w-[25%]">
              <span className="text-sm font-medium font-sans">
                วันที่จัดส่งสินค้า
              </span>
              <div className="pt-2">
                <TextInputCustom
                  value={
                    searchFilter.deliveryStartDate
                      ? searchFilter.deliveryStartDate +
                        " - " +
                        searchFilter.deliveryEndDate
                      : ""
                  }
                  placeholder="เริ่มต้น - สิ้นสุด"
                  InputProps={{
                    endAdornment:
                      searchFilter.deliveryStartDate !== "" ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(e) => {
                            searchFilter.setDeliveryStartDate("");
                            searchFilter.setDeliveryEndDate("");
                            e.stopPropagation();
                            setToggleClear((prev) => !prev);
                          }}
                        />
                      ) : (
                        <SvgIcon
                          sx={{
                            fontSize: "24px !important",
                            color: "#646464",
                          }}
                          fontSize="small"
                        >
                          <CalendarSvg />
                        </SvgIcon>
                      ),
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    refCalendarRangeDeliveryDate.current?.onOpen(e);
                  }}
                />
              </div>
            </div>
            <div className="h-full self-end">
              <ButtonFillCustom
                type="submit"
                title={t("saleOrder.upComingOrdersTab.search")}
                onClick={handleSearch}
              />
            </div>
          </div>
          <div className="flex flex-row w-full justify-between pt-6">
            <div className="flex w-full max-w-[656px]">
              <div className="w-full max-w-[470px] mr-4">
                <TextInputSearchCustom
                  value={searchFilter.searchText}
                  placeholder="ค้นหาโดยหมายเลขสั่งซื้อ, รหัสลูกค้า, ชื่อลูกค้า"
                  onChange={(event) =>
                    searchFilter.setSearchText(event.target.value)
                  }
                  InputProps={{
                    endAdornment:
                      searchFilter.searchText.length > 0 ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(_) => {
                            searchFilter.setSearchText("");
                            setToggleClear((prev) => !prev);
                          }}
                        />
                      ) : null,
                  }}
                />
              </div>
              {itemSelected.length > 0 && (
                <div className="w-full max-w-[170px] flex items-center font-sans text-[14px] text-[#646464]">{`เลือกไว้ ${itemSelected.length} รายการ`}</div>
              )}
            </div>
            <ButtonOutlinedCustom
              title={t("saleOrder.upComingOrdersTab.download")}
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              disabled={itemSelected.length === 0}
              onClick={onExport}
              startIcon={
                <SvgIcon
                  sx={{
                    fontSize: "14px !important",
                    color: "currentColor",
                    marginBottom: "3px",
                  }}
                  fontSize="small"
                >
                  <DownloadSvg />
                </SvgIcon>
              }
            />
          </div>
          <DateRangePickerPopover
            ref={refCalendarRangeOrderDate}
            id={"calendar-range-popover-order"}
            handleSelect={handleSelectOrderDate}
            openCalender={isOpenCalenderOrderDate}
            onClose={handleCloseCalenderOrderDate}
            handleCalenderSave={handleCalenderSaveOrderDate}
            startDate={searchFilter.orderStartDate}
            endDate={searchFilter.orderEndDate}
          />
          <DateRangePickerPopover
            ref={refCalendarRangeDeliveryDate}
            id={"calendar-range-popover-delivery"}
            handleSelect={handleSelectDeliveryDate}
            openCalender={isOpenCalenderDeliveryDate}
            onClose={handleCloseCalenderDeliveryDate}
            handleCalenderSave={handleCalenderSaveDeliveryDate}
            startDate={searchFilter.deliveryStartDate}
            endDate={searchFilter.deliveryEndDate}
          />
          <DateRangePickerPopover
            ref={refCalendarRangeRequestDeliveryDate}
            id={"calendar-range-popover-request-delivery"}
            handleSelect={handleSelectRequestDeliveryDate}
            openCalender={isOpenCalenderRequestDeliveryDate}
            onClose={handleCloseCalenderRequestDeliveryDate}
            handleCalenderSave={handleCalenderSaveRequestDeliveryDate}
            startDate={searchFilter.requestDeliveryStartDate}
            endDate={searchFilter.requestDeliveryEndDate}
          />
        </div>
      </form>
    </>
  );
}
