import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { thousandComma } from "../../../../utils/common/string";
import AdminModal from "../../../components/Modal/AdminModal";
import { ITarget } from "../types/SaleTargetTable.type";
import { unitSaleOrderEnum } from "./UserTabs";

type Props = {
  data?: ITarget;
  isOpen: boolean;
  handleClose: () => void;
};

export const unitTypeSaleOrderEnum: Record<string, string> = {
  user: "ผู้ใช้",
  customer: "ลูกค้า",
  feed_volume: "Selling Feed Volume",
  feed_revenue: "Selling Feed Revenue",
  basic_partner: "Basic Partner",
  core_partner: "Core Partner",
};

const DetailSaleTarget = ({ data, isOpen, handleClose }: Props) => {
  return (
    <>
      <AdminModal
        label={"รายละเอียด"}
        isOpen={isOpen}
        size={"md"}
        handleClose={handleClose}
        children={
          <>
            <div className="mt-6">
              <div className="flex gap-[15rem] items-start">
                <div className="items-start">
                  <p className="text-sm font-bold pb-3 mb-1">Target</p>
                  <p className="text-sm pb-3 mb-1">
                    {data ? unitTypeSaleOrderEnum[data?.targetType] : ""}
                  </p>
                </div>
                <div className="items-start">
                  <p className="text-sm font-bold pb-3 mb-1">ปี</p>
                  <p className="text-sm pb-3 mb-1">{data?.year}</p>
                </div>
                <div className="items-start">
                  <p className="text-sm font-bold pb-3 mb-1">จำนวนรวม</p>
                  <p className="text-sm pb-3 mb-1">
                    {thousandComma(data?.totalAmount || 0)}{" "}
                    {data ? unitSaleOrderEnum[data?.unit] : ""}
                  </p>
                </div>
              </div>
              <div className="table-container mt-4">
                <TableContainer
                  sx={{
                    scrollBehavior: "smooth",
                    overflow: "auto",
                    border: "1px solid #E1E1E1",
                    borderRadius: "8px",
                  }}
                >
                  <Table
                    sx={{
                      width: "100%",
                      borderCollapse: "collapse",
                    }}
                    aria-labelledby="table-show-data"
                    size="medium"
                  >
                    <TableHead sx={{ bgcolor: "#FBFBFB" }}>
                      <TableRow>
                        <TableCell sx={headerCellStyle}>เดือน</TableCell>
                        <TableCell sx={headerCellStyle} align="left">
                          จำนวน
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                        {
                          month: "มกราคม",
                          amount: data?.january,
                        },
                        {
                          month: "กุมภาพันธ์",
                          amount: data?.february,
                        },
                        {
                          month: "มีนาคม",
                          amount: data?.march,
                        },
                        {
                          month: "เมษายน",
                          amount: data?.april,
                        },
                        {
                          month: "พฤษภาคม",
                          amount: data?.may,
                        },
                        {
                          month: "มิถุนายน",
                          amount: data?.june,
                        },
                        {
                          month: "กรกฎาคม",
                          amount: data?.july,
                        },
                        {
                          month: "สิงหาคม",
                          amount: data?.august,
                        },
                        {
                          month: "กันยายน",
                          amount: data?.september,
                        },
                        {
                          month: "ตุลาคม",
                          amount: data?.october,
                        },
                        {
                          month: "พฤศจิกายน",
                          amount: data?.november,
                        },
                        {
                          month: "ธันวาคม",
                          amount: data?.december,
                        },
                      ].map((item, index) => (
                        <TableRow key={`${data?.id}-${index}`} sx={rowStyle}>
                          <TableCell component="th" scope="row">
                            {item.month}
                          </TableCell>
                          <TableCell align="left">
                            {thousandComma(item.amount || 0)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

// Additional styles for the table headers and rows
const headerCellStyle = {
  height: "50px",
  justifyContent: "start",
  fontFamily: "IBM Plex Sans Thai",
  fontWeight: 700,
  fontSize: "14px",
  width: "270px",
};

const rowStyle = {
  "&:last-child td, &:last-child th": { border: 0 },
};

export default DetailSaleTarget;
