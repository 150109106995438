import { Divider, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getCountInvoiceSale } from "../../../../services/admin/invoice/invoiceSale.service";
import MenuBar from "../../../components/MenuBar/MenuBar";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import { TTabSale } from "../type";
import PastDueInvoicePanel from "./PastDueInvoicePanel";
import SuccessInvoicePanel from "./SuccessInvoicePanel";
import UpdateDocumentInvoicePanel from "./UpdateDocumentInvoicePanel";
import { useTranslation } from "react-i18next";

function SaleInvoice() {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isHandleFetch, setIsHandleFetch] = useState<boolean>(false);
  const [countInvoice, setCountInvoice] = useState<TTabSale>({
    tabUpcoming: 0,
    tabOverDue: 0,
    tabComplete: 0,
  });
  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };

  const handleFetchCountInvoice = async () => {
    const countInvoice1 = await getCountInvoiceSale("upcoming");
    const countInvoice2 = await getCountInvoiceSale("overdue");
    const countInvoice3 = await getCountInvoiceSale("complete");
    setCountInvoice({
      tabUpcoming: countInvoice1?.count ?? 0,
      tabOverDue: countInvoice2?.count ?? 0,
      tabComplete: countInvoice3?.count ?? 0,
    });
  };

  useEffect(() => {
    handleFetchCountInvoice();
  }, [isHandleFetch]);

  return (
    <MenuBar>
      <div className="pt-16 w-full h-full relative">
        <div className="p-10">
          <span className="text-[#000] font-bold text-3xl">
            {t("invoice.invoice")}
          </span>
          <Divider sx={{ paddingTop: "12px" }} />
          <div className="pt-6">
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              aria-label="sale-order-tab-control"
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "#68C184",
                    height: "3px",
                  },
                },
              }}
              sx={{
                height: "36px",
                minHeight: "unset",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#000000",
                      borderBottom: "3px solid #DDF3E4",
                      "& .Mui-selected": {
                        color: "#000000",
                      },
                    },
                  },
                },
              }}
            >
              <Tab
                label={`${t(
                  "invoice.waitToUpdateInvoiceTab.waitToUpdateInvoiceLandingPage"
                )} (${countInvoice.tabUpcoming})`}
              />
              <Tab
                label={`${t(
                  "invoice.overdueInvoiceTab.overdueInvoiceLandingPage.overdueInvoiceLandingPage"
                )} (${countInvoice.tabOverDue})`}
              />
              <Tab
                label={`${t(
                  "invoice.successfulInvoiceTab.successfulInvoiceLandingPage"
                )} (${countInvoice.tabComplete})`}
              />
            </Tabs>
          </div>
          <TabPanelCustom
            value={tabIndex}
            index={0}
            prefix="update-document-invoice"
          >
            <UpdateDocumentInvoicePanel
              handleFetchCount={() => {
                setIsHandleFetch((prev) => !prev);
              }}
            />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={1} prefix="past-due-invoice">
            <PastDueInvoicePanel
              handleFetchCount={() => {
                setIsHandleFetch((prev) => !prev);
              }}
            />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={2} prefix="success-invoice">
            <SuccessInvoicePanel
              handleFetchCount={() => {
                setIsHandleFetch((prev) => !prev);
              }}
            />
          </TabPanelCustom>
        </div>
      </div>
    </MenuBar>
  );
}

export default SaleInvoice;
