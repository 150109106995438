import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
} from "@mui/material";
import get from "lodash/get";
import { Controller, useFormContext } from "react-hook-form";
import { tw } from "../../../utils/tw";

interface IcustomStylesProps {
  borderRadius?: string;
  textAlign?: string;
  borderColor?: string;
  helperText?: string;
  label?: string;
  menuItems: JSX.Element[];
  shrink?: boolean;
  isHiddenBorder?: boolean;
  renderValueArray: { value: string; label: string }[];
  isShowCountAtMultiple?: boolean;
  switchStyle?: boolean;
  choseStyle2?: boolean;
  controlName: string;
}

export default function RHFSelectCustom(
  props: SelectProps & IcustomStylesProps
) {
  const {
    borderRadius,
    textAlign,
    borderColor,
    menuItems,
    label,
    renderValueArray,
    error,
    helperText,
    isShowCountAtMultiple = false,
    shrink = false,
    switchStyle,
    isHiddenBorder = false,
    choseStyle2,
    ...restProps
  } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={props.controlName || ""}
      control={control}
      render={({ field }) => (
        <FormControl
          fullWidth
          error={error}
          style={{
            borderColor: borderColor || "#3777BC",
          }}
        >
          {label && (
            <InputLabel
              id={`label-${restProps?.name}`}
              shrink={shrink}
              sx={{
                bgcolor: "#FFFFFF !important",
                color: shrink ? "#3777BC" : "transparent",
                "&.Mui-focused": {
                  color: "#3777BC",
                },
              }}
            >
              {label}
            </InputLabel>
          )}
          <Select
            {...field}
            displayEmpty
            {...restProps}
            variant="outlined"
            labelId={`label-${restProps?.name}`}
            fullWidth
            SelectDisplayProps={{
              ...restProps?.SelectDisplayProps,
            }}
            className={
              choseStyle2
                ? tw(
                    "[&>.MuiInputBase-input]:px-[10px]",
                    "[&>.MuiInputBase-input>span]:text-[#68C184]"
                  )
                : ""
            }
            renderValue={(value: any) => {
              if (!restProps.value || (restProps.value as any[]).length == 0) {
                return (
                  <span className="text-primary-grey-100">
                    {restProps.placeholder}
                  </span>
                );
              }
              if (
                isShowCountAtMultiple &&
                Array.isArray(renderValueArray) &&
                ((renderValueArray || []) as any[]).length > 1
              ) {
                return <>เลือกไว้ {renderValueArray.length} รายการ</>;
              }
              if (
                isShowCountAtMultiple &&
                Array.isArray(renderValueArray) &&
                ((renderValueArray || []) as any[]).length > 1
              ) {
                return <>เลือกไว้ {renderValueArray.length} รายการ</>;
              } else if (restProps.multiple) {
                const labels = (value as any[])
                  .map(
                    (row) =>
                      renderValueArray.find((each) => each.value == row)?.label
                  )
                  .filter((row) => row);
                return <>{labels.join(", ")}</>;
              }

              return (
                <>
                  {get(
                    renderValueArray.find((row) => row.value == value),
                    "label",
                    value
                  )}
                </>
              );
            }}
            sx={
              switchStyle
                ? {
                    borderRadius: borderRadius || "28px",
                    fontWeight: 500,
                    color: "#191919",
                    background: isHiddenBorder ? "transparent" : "#F7FBFF",
                    borderColor: borderColor || "#3777BC",
                    textAlign: textAlign || "",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#3777BC",
                      ...(isHiddenBorder ? { border: "0px" } : {}),
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px !important",
                      color: "#191919",
                      borderColor: "#3777BC",
                    },
                    "& .Mui-disabled": {
                      color: "#979797 !important",
                      WebkitTextFillColor: "#979797 !important",
                      bgcolor: "#FFFFFF !important",
                    },
                    "& input::placeholder": {
                      opacity: 1,
                      color: "#979797",
                    },
                  }
                : choseStyle2
                ? {
                    height: "2rem",
                    padding: "0px",
                    borderRadius: "10px !important",
                    minWidth: "132px",
                    border: "1px solid #68C184",
                    color: "#68C184",
                    bgcolor: "#F0FDF4",
                    justifyContent: "space-between",
                    width: "fit-content",
                    textAlign: textAlign || "",
                    fontWeight: "normal",
                    fontSize: "1rem",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px !important",
                      color: "#191919",
                      borderColor: "#E2E2E2",
                    },
                    "&.MuiInputBase-input": {
                      padding: 0,
                      backgroundColor: "red",
                    },
                    "& .Mui-disabled": {
                      color: "#979797 !important",
                      WebkitTextFillColor: "#979797 !important",
                      // bgcolor: "#FFFFFF !important",
                    },
                    "& input::placeholder": {
                      opacity: 1,
                      color: "#68C184",
                    },
                    "& .MuiSelect-icon": {
                      color: "#68C184",
                    },
                    "&.Mui-disabled": {
                      background: "#f5f5f5",
                    },
                  }
                : {
                    borderRadius: borderRadius || "6px !important",
                    fontWeight: 400,
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    color: "#191919",
                    background: "#FFFFFF",
                    borderColor: borderColor || "#E2E2E2",
                    textAlign: textAlign || "",
                    height: "32px",
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#E2E2E2",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px !important",
                      color: "#191919",
                      borderColor: "#E2E2E2",
                    },
                    "& .Mui-disabled": {
                      color: "#979797 !important",
                      WebkitTextFillColor: "#979797 !important",
                      // bgcolor: "#FFFFFF !important",
                    },
                    "& input::placeholder": {
                      opacity: 1,
                      color: "#979797",
                    },
                    "& .MuiSelect-icon": {
                      color: "#646464",
                    },
                    "&.Mui-disabled": {
                      background: "#f5f5f5",
                    },
                  }
            }
            IconComponent={KeyboardArrowDownIcon}
          >
            {menuItems}
          </Select>
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    ></Controller>
  );
}
