import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Slider, SvgIcon, Tab, Tabs } from "@mui/material";
import dayjs from "dayjs";
import * as echarts from "echarts";
import { EChartsOption } from "echarts";
import ReactECharts from "echarts-for-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DownloadSvg } from "../../../../admin/assets/svg/Download.svg";
import ButtonOutlinedCustom from "../../../../admin/components/Button/ButtonOutlinedCustom";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../admin/pages/group-area/views/Dropdown";
import scrollImg from "../../../../assets/images/feed/bnt-scroll.png";
import AppBarFS from "../../../../components/app-bar/AppBar";
import {
  getExportGraphYearAll,
  getExportGraphYearToMonth,
  getExportList,
  getExportPerFlockToMonth,
  getExportTwoGraphMonth,
  getExportTwoGraphYear,
} from "../../../../services/BasicAccounting.service";
import { IGraphYearResult } from "../../../../types/BasicAccounting.type";
import { makeXlsx } from "../../../../utils/make-xlsx";
import { getCommaNumber } from "../../../../utils/number";
import { useSeeDetailsBasicAccountingController } from "./controller";
import {
  defaultFormFilterModal,
  defaultFormSelectBillType,
} from "./defaultFormSeeDatails";
import {
  createData,
  createDataGraphYearAll,
  createDataGraphYearToMonth,
  createDataPerFlockToMonth,
  createDataTwoGraphMonth,
  createDataTwoGraphYear,
} from "./typeToExport";
import FilterModalOrImport from "./view/FilterModalOrImport";
import ListPerFlock from "./view/ListPerFlock";
import MonthlyExpense from "./view/MonthlyExpense";

const SeeDetailsBasicAccounting = () => {
  const { farmId } = useParams();
  const { t } = useTranslation();
  const optionsBillType = [
    { label: t("BasicAccounting.BreederCost"), value: "geneticBill" },
    { label: t("BasicAccounting.FeedCost"), value: "feedBill" },
    {
      label: t("BasicAccounting.VaccineCost"),
      value: "vaccineAndMedicineBill",
    },
    { label: t("BasicAccounting.StaffCost"), value: "staffBill" },
    { label: t("BasicAccounting.WaterBill"), value: "waterBill" },
    { label: t("BasicAccounting.ElectricityBill"), value: "electricityBill" },
    { label: t("BasicAccounting.Transportation"), value: "transportationBill" },
    { label: t("BasicAccounting.ProgramCost"), value: "programBill" },
    { label: t("BasicAccounting.Others"), value: "otherBill" },
  ];

  const {
    DataGraphYearBasicAccounting,
    DataGraphMonthBasicAccounting,
    DataGraphMonthBasicAccountingWithFilter,
    DataListPerFlock,
    DataListPerMonth,
    formSeeDetailsBasicAccounting,
    setFormSeeDetailsBasicAccounting,
    onChangeFormSeeDetailsBasicAccounting,
    formFilterModal,
    setFormFilterModal,
    onChangeFormFilterModal,
    isOpenFilterModal,
    onOpenFilterModal,
    onHideFilterModal,
    formImportModal,
    setFormImportModal,
    onChangeFormImportModal,
    isOpenImportModal,
    onOpenImportModal,
    onHideImportModal,
    isOpenFilterLockYearModal,
    onOpenFilterLockYearModal,
    onHideFilterLockYearModal,
    formSelectBillType,
    setFormSelectBillType,
    onChangeFormSelectBillType,
    isOpenSelectBillTypeModal,
    onOpenSelectBillTypeModal,
    onHideSelectBillTypeModal,
  } = useSeeDetailsBasicAccountingController(farmId || "");

  const navigate = useNavigate();

  const [scrollValue, setScrollValue] = useState(0);

  const scrollGridRef = useRef<any>(null);
  const handleScroll = (e: any) => {
    const maxScrollLeft =
      scrollGridRef.current.scrollWidth - scrollGridRef.current.clientWidth;
    const scrollLeft = (e.target.value / 100) * maxScrollLeft;
    scrollGridRef.current.scrollLeft = scrollLeft;
    setScrollValue(e.target.value);
  };

  const [tabIndexFarmSummary, setTabIndexFarmSummary] = useState(0);

  const onChangeTabFarmSummary = useCallback((e: any, index: number) => {
    setTabIndexFarmSummary(index);
  }, []);

  const [tabIndexAnnually, setTabIndexAnnually] = useState(0);

  const onChangeTabAnnually = useCallback((e: any, index: number) => {
    setTabIndexAnnually(index);
  }, []);

  const [tabIndexPerFlock, setTabIndexPerFlock] = useState(0);

  const onChangeTabPerFlock = useCallback((e: any, index: number) => {
    setTabIndexPerFlock(index);
  }, []);

  const getThaiYears = (
    currentYear: number,
    range: number
  ): { label: string; value: string }[] => {
    const thaiYears: { label: string; value: string }[] = [];

    for (let i = 0; i <= range; i++) {
      const yearAD = currentYear - i;
      const yearThai = yearAD + 543;
      thaiYears.push({
        label: yearThai.toString(),
        value: yearAD.toString(),
      });
    }

    return thaiYears;
  };

  const [selectYear, setSelectYear] = useState<TDropdownValue[]>([
    {
      label: (dayjs().year() + 543).toString(),
      value: dayjs().year().toString(),
    },
  ]);

  const calWidthShowBar = (count: number) => {
    return count * 70;
  };

  const optionBarTwoBar = (props: {
    dataArrayBar: IGraphYearResult[];
    maximumY?: number;
    isMonth?: boolean;
  }): EChartsOption => {
    const { dataArrayBar, maximumY, isMonth } = props;

    return {
      grid: {
        left: "3%",
        right: "3%",
        bottom: "3%",
        containLabel: true,
      },
      legend: {
        data: [
          {
            name: t("BasicAccounting.TotalRevenue"),
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: "#3777BC" },
                { offset: 0.3886, color: "#68C184" },
                { offset: 0.6698, color: "#77F2A1" },
                { offset: 0.9817, color: "rgba(119, 242, 161, 0)" },
              ]),
              borderRadius: 40,
            },
          },
          {
            name: t("BasicAccounting.TotalCost"),
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                { offset: 0, color: "#D32F2F" },
                { offset: 0.3958, color: "#DC5B5B" },
                { offset: 0.6823, color: "#EFB2B2" },
                { offset: 1, color: "rgba(239, 178, 178, 0)" },
              ]),
              borderRadius: 40,
            },
          },
        ],
        orient: "horizontal",
        show: true,
        top: "0%",
        textStyle: {
          color: "#000000",
          fontSize: "14px",
          fontFamily: "IBM Plex Sans Thai",
          fontWeight: "bold",
        },
      },
      xAxis: {
        type: "category",
        data: isMonth
          ? [
              t("BasicAccounting.Jan"),
              t("BasicAccounting.Feb"),
              t("BasicAccounting.Mar"),
              t("BasicAccounting.Apr"),
              t("BasicAccounting.May_short"),
              t("BasicAccounting.Jun"),
              t("BasicAccounting.Jul"),
              t("BasicAccounting.Aug"),
              t("BasicAccounting.Sep"),
              t("BasicAccounting.Oct"),
              t("BasicAccounting.Nov"),
              t("BasicAccounting.Dec"),
            ]
          : dataArrayBar?.map((row) => row.indexValue),
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: "12px",
          fontFamily: "IBM Plex Sans Thai",
          color: "#979797",
          fontWeight: "normal",
          interval: 0,
          // rotate: 45,
          hideOverlap: false,
        },
      },
      yAxis: {
        type: "value",
        max: maximumY == 0 ? 25000 : maximumY,
        maxInterval: (maximumY || 25000) / 5,
        minInterval: (maximumY || 25000) / 5,
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value) => {
            if (value >= 1000000) {
              return `{c| ${(value / 1000000).toFixed(2)}m}`;
            }
            if (value >= 1000) {
              return `{c| ${(value / 1000).toFixed(2)}k}`;
            }
            if (value <= -1000000) {
              return `{c| ${(value / 1000000).toFixed(2)}m}`;
            }
            if (value <= -1000) {
              return `{c| ${(value / 1000).toFixed(2)}k}`;
            }
            return `{c| ${value}}`;
          },
          rich: {
            c: {
              fontFamily: "IBM Plex Sans Thai",
              fontSize: "12px",
              color: "#979797",
              fontWeight: "normal",
            },
          },
        },
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: "#E0E0E0",
        padding: [10, 15],
        textStyle: {
          color: "#000",
          fontSize: 14,
        },
      },
      series: [
        {
          name: t("BasicAccounting.TotalRevenue"),
          data: dataArrayBar?.map((row) => row.income),
          type: "bar",
          showBackground: true,
          stack: "a",
          backgroundStyle: {
            color: "#EDF2F7",
            borderRadius: [50, 50, 50, 50],
          },
          itemStyle: {
            color: () => {
              return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#3777BC" },
                { offset: 0.3886, color: "#68C184" },
                { offset: 0.6698, color: "#77F2A1" },
                { offset: 0.9817, color: "rgba(119, 242, 161, 0)" },
              ]);
            },
            borderRadius: [50, 50, 50, 50],
          },
        },
        {
          name: t("BasicAccounting.TotalCost"),
          data: dataArrayBar?.map((row) => row.expense),
          type: "bar",
          showBackground: true,
          stack: "b",
          backgroundStyle: {
            color: "#EDF2F7",
            borderRadius: [50, 50, 50, 50],
          },
          itemStyle: {
            color: () => {
              return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 0, color: "#D32F2F" },
                { offset: 0.3958, color: "#DC5B5B" },
                { offset: 0.6823, color: "#EFB2B2" },
                { offset: 1, color: "rgba(239, 178, 178, 0)" },
              ]);
            },
            borderRadius: [50, 50, 50, 50],
          },
        },
      ],
    };
  };

  const optionBar = (props: {
    dataArrayBar?: { year: number; value: number }[];
    maximumY?: number;
    isMonth?: boolean;
  }): EChartsOption => {
    const { dataArrayBar, maximumY, isMonth } = props;

    return {
      grid: {
        left: "3%",
        right: "3%",
        bottom: "3%",
        top: "6%",
        containLabel: true,
      },
      xAxis: {
        type: "category",
        data: isMonth
          ? [
              t("BasicAccounting.Jan"),
              t("BasicAccounting.Feb"),
              t("BasicAccounting.Mar"),
              t("BasicAccounting.Apr"),
              t("BasicAccounting.May_short"),
              t("BasicAccounting.Jun"),
              t("BasicAccounting.Jul"),
              t("BasicAccounting.Aug"),
              t("BasicAccounting.Sep"),
              t("BasicAccounting.Oct"),
              t("BasicAccounting.Nov"),
              t("BasicAccounting.Dec"),
            ]
          : dataArrayBar?.map((row) => row.year),
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: "12px",
          fontFamily: "IBM Plex Sans Thai",
          color: "#979797",
          fontWeight: "normal",
          interval: 0,
          // rotate: 45,
          hideOverlap: false,
        },
      },
      yAxis: {
        type: "value",
        max: maximumY == 0 ? 25000 : maximumY,
        min: maximumY === 0 ? -25000 : -(maximumY || 0),
        maxInterval: (maximumY || 25000) / 5,
        minInterval: (maximumY || 25000) / 5,
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value) => {
            if (value >= 1000000) {
              return `{c| ${(value / 1000000).toFixed(2)}m}`;
            }
            if (value >= 1000) {
              return `{c| ${(value / 1000).toFixed(2)}k}`;
            }
            if (value <= -1000000) {
              return `{c| ${(value / 1000000).toFixed(2)}m}`;
            }
            if (value <= -1000) {
              return `{c| ${(value / 1000).toFixed(2)}k}`;
            }
            return `{c| ${value}}`;
          },
          rich: {
            c: {
              fontFamily: "IBM Plex Sans Thai",
              fontSize: "12px",
              color: "#979797",
              fontWeight: "normal",
            },
          },
        },
      },
      tooltip: {
        trigger: "axis",
        backgroundColor: "white",
        borderWidth: 1,
        borderColor: "#E0E0E0",
        padding: [10, 15],
        textStyle: {
          color: "#000",
          fontSize: 14,
        },
      },
      series: [
        {
          data: dataArrayBar?.map((row) => row.value),
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "#EDF2F7",
            borderRadius: [50, 50, 50, 50],
          },
          itemStyle: {
            color: (params: any) => {
              if (params.value >= 0) {
                return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#3777BC" },
                  { offset: 0.3886, color: "#68C184" },
                  { offset: 0.6698, color: "#77F2A1" },
                  { offset: 0.9817, color: "rgba(119, 242, 161, 0)" },
                ]);
              } else {
                return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  { offset: 0, color: "#D32F2F" },
                  { offset: 0.3958, color: "#DC5B5B" },
                  { offset: 0.6823, color: "#EFB2B2" },
                  { offset: 1, color: "rgba(239, 178, 178, 0)" },
                ]);
              }
            },
            borderRadius: [50, 50, 50, 50],
          },
          barCategoryGap: "10%",
          barWidth: "40%",
        },
      ],
    };
  };

  const dataGraphYear = (DataGraphYearBasicAccounting || []).map((row) => {
    return {
      year: row.indexValue,
      value: row.income - row.expense,
    };
  });

  const maximumYYear = Math.max(
    ...dataGraphYear.map((row) => Math.abs(row.value))
  );

  const maximumYYearData = Math.max(
    ...(DataGraphYearBasicAccounting || []).map((row) =>
      Math.max(Math.abs(row.income), Math.abs(row.expense))
    )
  );

  const dataGraphMonth = (DataGraphMonthBasicAccounting || []).map((row) => {
    return {
      year: row.indexValue,
      value: row.income - row.expense,
    };
  });

  const maximumYMonth = Math.max(
    ...dataGraphMonth.map((row) => Math.abs(row.value))
  );

  const maximumYMonthData = Math.max(
    ...(DataGraphMonthBasicAccountingWithFilter || []).map((row) =>
      Math.max(Math.abs(row.income), Math.abs(row.expense))
    )
  );

  const handleExportExcel = async (startDate: string, endDate: string) => {
    const data = await getExportExcelData(startDate, endDate);
    const fileName =
      tabIndexPerFlock === 1
        ? `export-per-month-${dayjs().format("YYYYMMDDHHmmss")}`
        : `export-per-flock-${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData = async (startDate: string, endDate: string) => {
    type TColumnName1 =
      | "ชื่อรุ่น"
      | "วันที่ปิดรุ่น"
      | "รายได้การขายสุกร"
      | "ค่าพันธุ์สัตว์"
      | "ค่าอาหาร"
      | "ค่ายาเเละวัคซีน"
      | "ค่าพนักงาน"
      | "ค่าน้ำ"
      | "ค่าไฟ"
      | "ค่าขนส่ง"
      | "ค่าโปรแกรม"
      | "ค่าใช้จ่ายอื่นๆ";

    type TColumnName2 =
      | "ชื่อรุ่น"
      | "ปี"
      | "เดือน"
      | "วันที่ปิดรุ่น"
      | "รายได้การขายสุกร"
      | "ค่าพันธุ์สัตว์"
      | "ค่าอาหาร"
      | "ค่ายาเเละวัคซีน"
      | "ค่าพนักงาน"
      | "ค่าน้ำ"
      | "ค่าไฟ"
      | "ค่าขนส่ง"
      | "ค่าโปรแกรม"
      | "ค่าใช้จ่ายอื่นๆ";

    type TExcelData1 = {
      [k in TColumnName1]: string | number;
    };

    type TExcelData2 = {
      [k in TColumnName2]: string | number;
    };

    if (tabIndexPerFlock === 1) {
      const allSelectItemData = await getExportPerFlockToMonth(
        farmId || "",
        dayjs(startDate).isValid()
          ? dayjs(startDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
        dayjs(endDate).isValid()
          ? dayjs(endDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined
      );
      if (allSelectItemData) {
        const data: TExcelData1[] = allSelectItemData?.map<TExcelData1>(
          (row) => {
            const mappedData = createDataPerFlockToMonth(
              row.flockName,
              dayjs(row.generationCloseDate).isValid()
                ? dayjs(row.generationCloseDate).format("DD/MM/YYYY")
                : "-",
              row.incomeFromSellingPig,
              row.geneticBill,
              row.feedBill,
              row.vaccineAndMedicineBill,
              row.staffBill,
              row.waterBill,
              row.electricityBill,
              row.transportationBill,
              row.programBill,
              row.otherBill
            );
            return {
              ชื่อรุ่น: mappedData.flockName,
              วันที่ปิดรุ่น: mappedData.generationCloseDate,
              รายได้การขายสุกร: getCommaNumber(
                mappedData.incomeFromSellingPig,
                true
              ),
              ค่าพันธุ์สัตว์: getCommaNumber(mappedData.geneticBill, true),
              ค่าอาหาร: getCommaNumber(mappedData.feedBill, true),
              ค่ายาเเละวัคซีน: getCommaNumber(
                mappedData.vaccineAndMedicineBill,
                true
              ),
              ค่าพนักงาน: getCommaNumber(mappedData.staffBill, true),
              ค่าน้ำ: getCommaNumber(mappedData.waterBill, true),
              ค่าไฟ: getCommaNumber(mappedData.electricityBill, true),
              ค่าขนส่ง: getCommaNumber(mappedData.transportationBill, true),
              ค่าโปรแกรม: getCommaNumber(mappedData.programBill, true),
              ค่าใช้จ่ายอื่นๆ: getCommaNumber(mappedData.otherBill, true),
            };
          }
        );
        return data;
      }
    } else {
      const allSelectItemData = await getExportList(
        farmId || "",
        dayjs(startDate).isValid()
          ? dayjs(startDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined,
        dayjs(endDate).isValid()
          ? dayjs(endDate, "YYYY-MM-DD").startOf("day").toDate()
          : undefined
      );
      if (allSelectItemData) {
        const data: TExcelData2[] = allSelectItemData?.map<TExcelData2>(
          (row) => {
            const mappedData = createData(
              row.flockName,
              row.year,
              dayjs().month(row.month).format("MMMM"),
              dayjs(row.generationCloseDate).isValid()
                ? dayjs(row.generationCloseDate).format("DD/MM/YYYY")
                : "-",
              row.incomeFromSellingPig,
              row.geneticBill,
              row.feedBill,
              row.vaccineAndMedicineBill,
              row.staffBill,
              row.waterBill,
              row.electricityBill,
              row.transportationBill,
              row.programBill,
              row.otherBill
            );
            return {
              ชื่อรุ่น: mappedData.flockName,
              ปี: mappedData.year,
              เดือน: mappedData.month,
              วันที่ปิดรุ่น: mappedData.generationCloseDate,
              รายได้การขายสุกร: getCommaNumber(
                mappedData.incomeFromSellingPig,
                true
              ),
              ค่าพันธุ์สัตว์: getCommaNumber(mappedData.geneticBill, true),
              ค่าอาหาร: getCommaNumber(mappedData.feedBill, true),
              ค่ายาเเละวัคซีน: getCommaNumber(
                mappedData.vaccineAndMedicineBill,
                true
              ),
              ค่าพนักงาน: getCommaNumber(mappedData.staffBill, true),
              ค่าน้ำ: getCommaNumber(mappedData.waterBill, true),
              ค่าไฟ: getCommaNumber(mappedData.electricityBill, true),
              ค่าขนส่ง: getCommaNumber(mappedData.transportationBill, true),
              ค่าโปรแกรม: getCommaNumber(mappedData.programBill, true),
              ค่าใช้จ่ายอื่นๆ: getCommaNumber(mappedData.otherBill, true),
            };
          }
        );
        return data;
      }
    }

    return [];
  };

  const handleExportExcelGraphYearAll = async () => {
    const data = await getExportExcelDataGraphYearAll(selectYear);
    const fileName =
      tabIndexAnnually === 1
        ? `export-graph-per-month-${dayjs().format("YYYYMMDDHHmmss")}`
        : `export-graph-per-year-${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelDataGraphYearAll = async (
    selectYear: TDropdownValue[]
  ) => {
    type TColumnName1 = "ปี" | "เดือน" | "กำไรขาดทุน";

    type TColumnName2 = "ปี" | "กำไรขาดทุน";

    type TExcelData1 = {
      [k in TColumnName1]: string | number;
    };

    type TExcelData2 = {
      [k in TColumnName2]: string | number;
    };

    if (tabIndexAnnually === 1) {
      const allSelectItemData = await getExportGraphYearToMonth(
        farmId || "",
        +selectYear[0].value
      );
      if (allSelectItemData) {
        const data: TExcelData1[] = allSelectItemData?.map<TExcelData1>(
          (row) => {
            const mappedData = createDataGraphYearToMonth(
              row.year,
              dayjs().month(row.month).format("MMMM"),
              getCommaNumber(row.profit, true)
            );
            return {
              ปี: mappedData.year,
              เดือน: mappedData.month,
              กำไรขาดทุน: mappedData.profit,
            };
          }
        );
        return data;
      }
    } else {
      const allSelectItemData = await getExportGraphYearAll(farmId || "");
      if (allSelectItemData) {
        const data: TExcelData2[] = allSelectItemData?.map<TExcelData2>(
          (row) => {
            const mappedData = createDataGraphYearAll(
              row.year,
              getCommaNumber(row.profit, true)
            );
            return {
              ปี: mappedData.year,
              กำไรขาดทุน: mappedData.profit,
            };
          }
        );
        return data;
      }
    }

    return [];
  };

  const handleExportExcelTwoGraph = async () => {
    const data = await getExportExcelDataTwoGraph(selectYear);
    const fileName =
      tabIndexAnnually === 1
        ? `export-graph-income-and-expense-month-${dayjs().format(
            "YYYYMMDDHHmmss"
          )}`
        : `export-graph-income-and-expense-year-${dayjs().format(
            "YYYYMMDDHHmmss"
          )}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelDataTwoGraph = async (selectYear: TDropdownValue[]) => {
    type TColumnName1 = "ปี" | "เดือน" | "รายรับ" | "รายจ่าย";

    type TColumnName2 = "ปี" | "รายรับ" | "รายจ่าย";

    type TExcelData1 = {
      [k in TColumnName1]: string | number;
    };

    type TExcelData2 = {
      [k in TColumnName2]: string | number;
    };

    if (tabIndexAnnually === 1) {
      const allSelectItemData = await getExportTwoGraphMonth(
        farmId || "",
        +selectYear[0].value
      );
      if (allSelectItemData) {
        const data: TExcelData1[] = allSelectItemData?.map<TExcelData1>(
          (row) => {
            const mappedData = createDataTwoGraphMonth(
              row.year,
              dayjs().month(row.month).format("MMMM"),
              getCommaNumber(row.income, true),
              getCommaNumber(row.expense, true)
            );
            return {
              ปี: mappedData.year,
              เดือน: mappedData.month,
              รายรับ: mappedData.income,
              รายจ่าย: mappedData.expense,
            };
          }
        );
        return data;
      }
    } else {
      const allSelectItemData = await getExportTwoGraphYear(farmId || "");
      if (allSelectItemData) {
        const data: TExcelData2[] = allSelectItemData?.map<TExcelData2>(
          (row) => {
            const mappedData = createDataTwoGraphYear(
              row.year,
              getCommaNumber(row.income, true),
              getCommaNumber(row.expense, true)
            );
            return {
              ปี: mappedData.year,
              รายรับ: mappedData.income,
              รายจ่าย: mappedData.expense,
            };
          }
        );
        return data;
      }
    }

    return [];
  };

  useEffect(() => {
    if (selectYear) {
      onChangeFormSeeDetailsBasicAccounting("christianYear")(selectYear);
    }
  }, [selectYear]);

  useEffect(() => {
    setTabIndexAnnually(0);
    setTabIndexPerFlock(0);
    setSelectYear([
      {
        label: (dayjs().year() + 543).toString(),
        value: dayjs().year().toString(),
      },
    ]);
  }, [tabIndexFarmSummary]);

  useEffect(() => {
    setFormFilterModal(defaultFormFilterModal);
    setFormImportModal(defaultFormFilterModal);
  }, [tabIndexPerFlock, tabIndexFarmSummary]);

  useEffect(() => {
    setFormSelectBillType(defaultFormSelectBillType);
  }, [tabIndexAnnually, tabIndexFarmSummary]);

  return (
    <div
      style={{
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#F0FDF4",
      }}
      className="!h-auto !min-h-screen font-sans"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          title={t("BasicAccounting.SeeAll")}
          onIconPress={() => navigate(-1)}
        />
      </div>
      <div className="flex flex-col p-4 gap-6">
        <Tabs
          value={tabIndexFarmSummary}
          onChange={onChangeTabFarmSummary}
          variant="scrollable"
          scrollButtons={false}
          TabIndicatorProps={{
            sx: {
              "&.MuiTabs-indicator": {
                transform: "unset",
                width: "unset",
                bgcolor: "#68C184",
                height: "3px",
              },
            },
          }}
          sx={{
            height: "36px",
            minHeight: "unset",
            "& .MuiTabs-scroller": {
              "& .MuiTabs-flexContainer": {
                alignItems: "end",
                height: "100%",
                display: "flex",
                gap: "12px",
                "& .MuiTab-root": {
                  padding: "6px 28px",
                  textTransform: "unset",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "20px",
                  height: "32px",
                  minHeight: "unset",
                  color: "#000000 !important",
                  bgcolor: "transparent !important",
                  borderBottom: "3px solid #DDF3E4",
                  "& .Mui-selected": {
                    color: "#000000",
                  },
                },
              },
            },
          }}
        >
          <Tab label={t("BasicAccounting.FarmSummary")} />
          <Tab label={t("BasicAccounting.IncomeExpenses")} />
        </Tabs>
        <div className="mt-[-16px]">
          <Tabs
            value={tabIndexAnnually}
            onChange={onChangeTabAnnually}
            variant="scrollable"
            scrollButtons={false}
            TabIndicatorProps={{
              sx: {
                "&.MuiTabs-indicator": {
                  transform: "unset",
                  width: "unset",
                  bgcolor: "transparent",
                  height: "3px",
                },
              },
            }}
            sx={{
              "& .MuiTabs-scroller": {
                "& .MuiTabs-flexContainer": {
                  alignItems: "end",
                  height: "100%",
                  display: "flex",
                  gap: "10px",
                  "& .MuiTab-root": {
                    padding: "8px 14px !important",
                    textTransform: "unset",
                    fontFamily: "IBM Plex Sans Thai",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "16px",
                    height: "32px",
                    minHeight: "unset",
                    color: "#68C184",
                    // bgcolor: "#FFFFFF",
                    bgcolor: "transparent",
                    borderRadius: "20px",
                    transition: "background-color 0.3s ease, color 0.3s ease",
                    "&.Mui-selected": {
                      bgcolor: "#68C184 !important",
                      color: "#FFFFFF !important",
                    },
                    "&.Mui-selected:hover": {
                      bgcolor: "#68C184 !important",
                      color: "#FFFFFF !important",
                    },
                  },
                },
              },
            }}
          >
            <Tab label={t("BasicAccounting.Annual")} />
            <Tab label={t("BasicAccounting.Monthly")} />
          </Tabs>
        </div>
        {tabIndexAnnually === 0 && tabIndexFarmSummary === 0 && (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center ">
              <div className="text-[18px] font-semibold text-[#3777BC]">
                {t("BasicAccounting.YearlyReport")}
              </div>
              <ButtonOutlinedCustom
                title={t("BasicAccounting.export")}
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                className="!rounded-[50px]"
                startIcon={
                  <SvgIcon
                    sx={{
                      fontSize: "14px !important",
                      color: "currentColor",
                      marginBottom: "3px",
                    }}
                    fontSize="small"
                  >
                    <DownloadSvg />
                  </SvgIcon>
                }
                onClick={handleExportExcelGraphYearAll}
              />
            </div>
            {dataGraphYear.length > 0 && (
              <>
                <div
                  ref={scrollGridRef}
                  className="overflow-x-scroll no-scrollbar"
                >
                  <div
                    style={{
                      width: calWidthShowBar(dataGraphYear.length),
                    }}
                  >
                    <ReactECharts
                      option={optionBar({
                        dataArrayBar: dataGraphYear,
                        maximumY: maximumYYear,
                      })}
                      className="!h-[400px] !w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-center w-[100%] px-8">
                  <Slider
                    sx={{
                      width: "90%",
                      "& .MuiSlider-thumb": {
                        borderRadius: "10px",
                        backgroundImage: `url(${scrollImg})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        color: "unset !important",
                        backgroundColor: "transparent",
                        width: 40,
                      },
                      "& .MuiSlider-rail": {
                        color: "#E2E2E2 !important",
                      },
                      "& .MuiSlider-track": {
                        backgroundColor: "grey !important",
                        border: "1px solid grey !important",
                      },
                      "& .MuiSlider-valueLabelLabel": {
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "unset",
                        background: "unset",
                      },
                      cursor: "pointer",
                    }}
                    defaultValue={0}
                    value={scrollValue}
                    onChange={handleScroll}
                    aria-label="Disabled slider"
                  />
                </div>
              </>
            )}
          </div>
        )}
        {tabIndexAnnually === 1 && tabIndexFarmSummary === 0 && (
          <div className="flex flex-col">
            <div className="flex flex-col gap-6">
              <div className="text-[18px] font-semibold text-[#3777BC]">
                {t("BasicAccounting.MonthlyReport")}
              </div>
              <div className="flex flex-row justify-between items-center">
                <Dropdown
                  options={getThaiYears(dayjs().year(), 19)}
                  placeholder={t("BasicAccounting.year")}
                  onChange={(value) => setSelectYear(value)}
                  renderValueArray={selectYear}
                  choseStyle2
                  containerDropdown="pb-0 text-center"
                />
                <ButtonOutlinedCustom
                  title={t("BasicAccounting.export")}
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  className="!rounded-[50px]"
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                  onClick={handleExportExcelGraphYearAll}
                />
              </div>
            </div>

            {dataGraphMonth.length > 0 && (
              <>
                <div
                  ref={scrollGridRef}
                  className="overflow-x-scroll no-scrollbar"
                >
                  <div
                    style={{
                      width: calWidthShowBar(dataGraphMonth.length),
                    }}
                  >
                    <ReactECharts
                      option={optionBar({
                        dataArrayBar: dataGraphMonth,
                        maximumY: maximumYMonth,
                        isMonth: true,
                      })}
                      className="!h-[400px] !w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-center w-[100%] px-8">
                  <Slider
                    sx={{
                      width: "90%",
                      "& .MuiSlider-thumb": {
                        borderRadius: "10px",
                        backgroundImage: `url(${scrollImg})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        color: "unset !important",
                        backgroundColor: "transparent",
                        width: 40,
                      },
                      "& .MuiSlider-rail": {
                        color: "#E2E2E2 !important",
                      },
                      "& .MuiSlider-track": {
                        backgroundColor: "grey !important",
                        border: "1px solid grey !important",
                      },
                      "& .MuiSlider-valueLabelLabel": {
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "unset",
                        background: "unset",
                      },
                      cursor: "pointer",
                    }}
                    defaultValue={0}
                    value={scrollValue}
                    onChange={handleScroll}
                    aria-label="Disabled slider"
                  />
                </div>
              </>
            )}
          </div>
        )}
        {tabIndexFarmSummary === 0 && (
          <div className="mt-[-16px]">
            <Tabs
              value={tabIndexPerFlock}
              onChange={onChangeTabPerFlock}
              variant="scrollable"
              scrollButtons={false}
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "transparent",
                    height: "3px",
                  },
                },
              }}
              sx={{
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "10px",
                    "& .MuiTab-root": {
                      padding: "8px 14px !important",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 600,
                      fontSize: "14px",
                      lineHeight: "16px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#68C184",
                      // bgcolor: "#FFFFFF",
                      bgcolor: "transparent",
                      borderRadius: "20px",
                      transition: "background-color 0.3s ease, color 0.3s ease",
                      "&.Mui-selected": {
                        bgcolor: "#68C184 !important",
                        color: "#FFFFFF !important",
                      },
                      "&.Mui-selected:hover": {
                        bgcolor: "#68C184 !important",
                        color: "#FFFFFF !important",
                      },
                    },
                  },
                },
              }}
            >
              <Tab label={t("BasicAccounting.FlockMonthlyReport")} />
              <Tab label={t("BasicAccounting.ExpandMonthPerFlock")} />
            </Tabs>
          </div>
        )}
        {tabIndexPerFlock === 0 && tabIndexFarmSummary === 0 && (
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between items-center">
              <div className="text-[18px] font-semibold text-[#3777BC]">
                {t("BasicAccounting.DetailsPage")}
              </div>
              <div className="h-full flex gap-2 items-center">
                <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
                  {t("FinancialSupport.FilterBy")}
                </span>
                <IconButton
                  aria-label="filter"
                  sx={{
                    color: "#68C184",
                    border: "1px solid #68C184",
                    ":hover": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                    ":active": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                  }}
                  onClick={onOpenFilterModal}
                >
                  <FilterListIcon />
                </IconButton>
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <ButtonOutlinedCustom
                title={t("BasicAccounting.export")}
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                className="!rounded-[50px] !h-[30px]"
                startIcon={
                  <SvgIcon
                    sx={{
                      fontSize: "16px !important",
                      color: "currentColor",
                      marginBottom: "3px",
                    }}
                    fontSize="small"
                  >
                    <DownloadSvg />
                  </SvgIcon>
                }
                onClick={onOpenImportModal}
              />
            </div>
            <ListPerFlock data={DataListPerFlock || []} />
          </div>
        )}
        {tabIndexPerFlock === 1 && tabIndexFarmSummary === 0 && (
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between items-center">
              <div className="text-[18px] font-semibold text-[#3777BC]">
                {t("BasicAccounting.MonthlyReport")}
              </div>
              <div className="h-full flex gap-2 items-center">
                <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
                  {t("FinancialSupport.FilterBy")}
                </span>
                <IconButton
                  aria-label="filter"
                  sx={{
                    color: "#68C184",
                    border: "1px solid #68C184",
                    ":hover": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                    ":active": {
                      color: "#FFFFFF",
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                    },
                  }}
                  onClick={onOpenFilterLockYearModal}
                >
                  <FilterListIcon />
                </IconButton>
              </div>
            </div>
            <div className="flex flex-row justify-end">
              <ButtonOutlinedCustom
                title={t("BasicAccounting.export")}
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                className="!rounded-[50px] !h-[30px]"
                startIcon={
                  <SvgIcon
                    sx={{
                      fontSize: "16px !important",
                      color: "currentColor",
                      marginBottom: "3px",
                    }}
                    fontSize="small"
                  >
                    <DownloadSvg />
                  </SvgIcon>
                }
                onClick={onOpenImportModal}
              />
            </div>
            <MonthlyExpense data={DataListPerMonth || []} />
          </div>
        )}
        {tabIndexAnnually === 0 && tabIndexFarmSummary === 1 && (
          <div className="flex flex-col">
            <div className="flex flex-row justify-between items-center pb-[24px]">
              <div className="text-[18px] font-semibold text-[#3777BC]">
                {t("BasicAccounting.YearlyExpense/Income")}
              </div>
              <ButtonOutlinedCustom
                title={t("BasicAccounting.export")}
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                className="!rounded-[50px]"
                startIcon={
                  <SvgIcon
                    sx={{
                      fontSize: "14px !important",
                      color: "currentColor",
                      marginBottom: "3px",
                    }}
                    fontSize="small"
                  >
                    <DownloadSvg />
                  </SvgIcon>
                }
                onClick={handleExportExcelTwoGraph}
              />
            </div>
            {(DataGraphYearBasicAccounting || []).length > 0 && (
              <>
                <div
                  ref={scrollGridRef}
                  className="overflow-x-scroll no-scrollbar"
                >
                  <div
                    style={{
                      width: (DataGraphYearBasicAccounting || []).length * 80,
                    }}
                  >
                    <ReactECharts
                      option={optionBarTwoBar({
                        dataArrayBar: DataGraphYearBasicAccounting || [],
                        maximumY: maximumYYearData,
                      })}
                      className="!h-[400px] !w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-center w-[100%] px-8">
                  <Slider
                    sx={{
                      width: "90%",
                      "& .MuiSlider-thumb": {
                        borderRadius: "10px",
                        backgroundImage: `url(${scrollImg})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        color: "unset !important",
                        backgroundColor: "transparent",
                        width: 40,
                      },
                      "& .MuiSlider-rail": {
                        color: "#E2E2E2 !important",
                      },
                      "& .MuiSlider-track": {
                        backgroundColor: "grey !important",
                        border: "1px solid grey !important",
                      },
                      "& .MuiSlider-valueLabelLabel": {
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "unset",
                        background: "unset",
                      },
                      cursor: "pointer",
                    }}
                    defaultValue={0}
                    value={scrollValue}
                    onChange={handleScroll}
                    aria-label="Disabled slider"
                  />
                </div>
              </>
            )}
          </div>
        )}
        {tabIndexAnnually === 1 && tabIndexFarmSummary === 1 && (
          <div className="flex flex-col">
            <div className="flex flex-col gap-6 pb-[24px]">
              <div className="flex flex-row justify-between items-center">
                <div className="text-[18px] font-semibold text-[#3777BC]">
                  {t("BasicAccounting.MonthlyExpense/Income")}
                </div>
                <div className="h-full flex gap-2 items-center">
                  <span className="text-secondary font-normal ml-1 text-[1rem] flex flex-col items-center">
                    {t("FinancialSupport.FilterBy")}
                  </span>
                  <IconButton
                    aria-label="filter"
                    sx={{
                      color: "#68C184",
                      border: "1px solid #68C184",
                      ":hover": {
                        color: "#FFFFFF",
                        border: "1px solid #68C184",
                        bgcolor: "#68C184",
                      },
                      ":active": {
                        color: "#FFFFFF",
                        border: "1px solid #68C184",
                        bgcolor: "#68C184",
                      },
                    }}
                    onClick={onOpenSelectBillTypeModal}
                  >
                    <FilterListIcon />
                  </IconButton>
                </div>
              </div>
              <div className="flex flex-row justify-between items-center">
                <Dropdown
                  options={getThaiYears(dayjs().year(), 19)}
                  placeholder={t("BasicAccounting.year")}
                  onChange={(value) => setSelectYear(value)}
                  renderValueArray={selectYear}
                  choseStyle2
                  containerDropdown="pb-0 text-center"
                />
                <ButtonOutlinedCustom
                  title={t("BasicAccounting.export")}
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  className="!rounded-[50px]"
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                  onClick={handleExportExcelTwoGraph}
                />
              </div>
            </div>
            {(DataGraphMonthBasicAccountingWithFilter || []).length > 0 && (
              <>
                <div
                  ref={scrollGridRef}
                  className="overflow-x-scroll no-scrollbar"
                >
                  <div
                    style={{
                      width:
                        (DataGraphMonthBasicAccountingWithFilter || []).length *
                        80,
                    }}
                  >
                    <ReactECharts
                      option={optionBarTwoBar({
                        dataArrayBar:
                          DataGraphMonthBasicAccountingWithFilter || [],
                        maximumY: maximumYMonthData,
                        isMonth: true,
                      })}
                      className="!h-[400px] !w-full"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-center w-[100%] px-8">
                  <Slider
                    sx={{
                      width: "90%",
                      "& .MuiSlider-thumb": {
                        borderRadius: "10px",
                        backgroundImage: `url(${scrollImg})`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        color: "unset !important",
                        backgroundColor: "transparent",
                        width: 40,
                      },
                      "& .MuiSlider-rail": {
                        color: "#E2E2E2 !important",
                      },
                      "& .MuiSlider-track": {
                        backgroundColor: "grey !important",
                        border: "1px solid grey !important",
                      },
                      "& .MuiSlider-valueLabelLabel": {
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "unset",
                        background: "unset",
                      },
                      cursor: "pointer",
                    }}
                    defaultValue={0}
                    value={scrollValue}
                    onChange={handleScroll}
                    aria-label="Disabled slider"
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <FilterModalOrImport
        isOpen={isOpenFilterModal}
        onClose={onHideFilterModal}
        title={t("FinancialSupport.FilterBy")}
        subtitle={t("farmPastPerformance.filter.closeDateRange")}
        form={formFilterModal}
        setForm={setFormFilterModal}
        onChangeForm={onChangeFormFilterModal}
      />
      <FilterModalOrImport
        isOpen={isOpenImportModal}
        onClose={onHideImportModal}
        title={t("FinancialSupport.FilterBy")}
        subtitle={t("farmPastPerformance.filter.closeDateRange")}
        isImport
        onImport={handleExportExcel}
        form={formImportModal}
        setForm={setFormImportModal}
        onChangeForm={onChangeFormImportModal}
      />
      <FilterModalOrImport
        isOpen={isOpenFilterLockYearModal}
        onClose={onHideFilterLockYearModal}
        title={t("FinancialSupport.FilterBy")}
        subtitle={t("farmPastPerformance.filter.closeDateRange")}
        form={formFilterModal}
        setForm={setFormFilterModal}
        onChangeForm={onChangeFormFilterModal}
        minDate={new Date(new Date().getFullYear(), 0, 1)}
        maxDate={new Date(new Date().getFullYear(), 11, 31)}
      />
      <FilterModalOrImport
        isOpen={isOpenSelectBillTypeModal}
        onClose={onHideSelectBillTypeModal}
        title={t("FinancialSupport.FilterBy")}
        subtitle={t("BasicAccounting.ExpenseType")}
        placeholder={t("BasicAccounting.all")}
        isDropdownMultiple
        option={optionsBillType}
        formDropdown={formSelectBillType}
        setFormDropdown={setFormSelectBillType}
        onChangeFormDropdown={onChangeFormSelectBillType}
      />
    </div>
  );
};

export default SeeDetailsBasicAccounting;
