import React, { useEffect } from "react";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { ListNewsAndArticles } from "../../../types/farmCommunity";
import ListOfUploadedFile2 from "../../../../../../components/common/File/ListOfUploadedFile2";
import { commonDownloadFile } from "../../../../../../services/CommonApiService";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { useTranslation } from "react-i18next";

type Props = {
  data?: ListNewsAndArticles;
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
};

export enum TFarmCommunityArticle {
  article = "ข่าวสาร",
  video = "วิดิโอ",
}
const ViewArticleModal = ({ data, isOpen, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onDownload = async (row: { id: string; fileOriginalName: string }) => {
    dispatch(loadingActions.show({ message: "กำลังดาวน์โหลดภาพ..." }));
    await commonDownloadFile(
      `/farm-community/article/download/${row.id}`,
      row.fileOriginalName
    );
    dispatch(loadingActions.hide());
  };

  return (
    <>
      <AdminModal
        label={t("farmCommunity.newsAndArticlePage.details")}
        isOpen={isOpen}
        size={"sm"}
        handleClose={() => handleClose(false)}
        children={
          <>
            <div className="flex flex-col gap-6">
              <div className="items-start">
                <p className="text-sm font-bold pb-3 mb-1">
                  {t("farmCommunity.newsAndArticlePage.types")}
                </p>
                <p className="text-sm pb-3 mb-1">
                  {
                    TFarmCommunityArticle[
                      data?.type as keyof typeof TFarmCommunityArticle
                    ]
                  }
                </p>
              </div>
              <div className="items-start">
                <p className="text-sm font-bold pb-3 mb-1">
                  {" "}
                  {t("farmCommunity.newsAndArticlePage.topic")}
                </p>
                <p className="text-sm pb-3 mb-1">{data?.topicName}</p>
              </div>
              <div className="items-start">
                <p className="text-sm font-bold pb-3 mb-1">
                  {t("farmCommunity.newsAndArticlePage.coverImage")}
                </p>
                <p className="text-sm">
                  <ListOfUploadedFile2
                    files={data?.articleFiles || []}
                    fileKey="fileOriginalName"
                    onDownload={(row) => onDownload(row)}
                    hideDeleteBtn
                  />
                </p>
              </div>
              <div className="items-start">
                <p className="text-sm font-bold pb-3 mb-1">
                  {t("farmCommunity.newsAndArticlePage.description")}
                </p>
                {data?.description && (
                  <div
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  ></div>
                )}
              </div>
              <div className="items-start">
                <p className="text-sm font-bold pb-3 mb-1">
                  {t("farmCommunity.newsAndArticlePage.url")}
                </p>
                <p className="text-sm pb-3 mb-1">{data?.url || "-"}</p>
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

export default ViewArticleModal;
