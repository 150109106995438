import { Divider } from "@mui/material";
import ReactECharts from "echarts-for-react";
import { tw } from "../../../utils/tw";
import { useAdminAuth } from "../../components/AdminAuth/AdminAuthProvider";
import MenuBar from "../../components/MenuBar/MenuBar";
import {
  BasicPartnerIcon,
  CashIcon,
  ChartSquareIcon,
  CorePartnerIcon,
  PersonIcon,
  PigFeedIcon,
} from "../../assets";
import { useTranslation } from "react-i18next";

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  title: tw(`text-black font-bold text-3xl`),
  body: tw(`text-black font-medium text-xl mt-3`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  filterRowContainer: tw(`flex flex-row flex-wrap items-end gap-3`),
  columnContainer: tw(`w-full flex flex-row overflow-x-auto mt-6 gap-x-3`),
};

function Home() {
  const { t } = useTranslation();
  const { user } = useAdminAuth();

  const UserData = [
    {
      value: 4000,
      name: "Users",
      itemStyle: {
        color: "#68C184",
        shadowColor: "#68C184B3",
      },
    },
    {
      value: 3000,
      name: "Customers",
      itemStyle: {
        color: "#3777BC",
        shadowColor: "#3777BCB3",
      },
    },
  ];

  const SaleData = [
    {
      value: 50000,
      name: "Actual Selling",
      itemStyle: {
        color: "#68C184",
        shadowColor: "#68C184B3",
      },
    },
    {
      value: 80000,
      name: "Target Selling",
      itemStyle: {
        color: "#3777BC",
        shadowColor: "#3777BCB3",
      },
    },
  ];

  const DeptData = [
    {
      value: 4000,
      name: "Loan Outstanding",
      itemStyle: {
        color: "#68C184",
        shadowColor: "#68C184B3",
      },
    },
    {
      value: 3000,
      name: "Repayment",
      itemStyle: {
        color: "#3777BC",
        shadowColor: "#3777BCB3",
      },
    },
  ];

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>
                Hello, {user?.name || user?.value || "Guest"} ({user?.userRole})
              </span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="mt-6 gap-6 grid grid-cols-12">
              <div className="grid col-span-12 rounded-[20px] bg-[#FAFDFF] shadow-[1px_1px_10px_0px_#0000001A] p-8 gap-10">
                <div className="flex flex-row justify-between gap-6">
                  <div className="flex flex-col gap-[3px] w-1/3 border-r border-r-[#E2E2E2]">
                    <PersonIcon />
                    <span className="text-2xl font-bold">100</span>
                    <span className="text-base font-medium">
                      {t("homepage.totalUsers")}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[3px] w-1/3 border-r border-r-[#E2E2E2]">
                    <CorePartnerIcon />
                    <span className="text-2xl font-bold">100</span>
                    <span className="text-base font-medium">
                      {t("homepage.corePartner")}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[3px] w-1/3">
                    <BasicPartnerIcon />
                    <span className="text-2xl font-bold">100</span>
                    <span className="text-base font-medium">
                      {t("homepage.basicPartner")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="grid col-span-12 rounded-[20px] bg-[#FAFDFF] shadow-[1px_1px_10px_0px_#0000001A] p-8 gap-10">
                <div className="flex flex-row justify-between gap-6">
                  <div className="flex flex-col gap-[3px] w-1/3 border-r border-r-[#E2E2E2]">
                    <ChartSquareIcon />
                    <div className="text-2xl font-bold">100,000</div>
                    <span className="text-base font-medium">
                      {t("homepage.saleRevenue")}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[3px] w-1/3 border-r border-r-[#E2E2E2]">
                    <PigFeedIcon />
                    <span className="text-2xl font-bold">15</span>
                    <span className="text-base font-medium">
                      {t("homepage.saleVolume")}
                    </span>
                  </div>
                  <div className="flex flex-col gap-[3px] w-1/3">
                    <CashIcon />
                    <span className="text-2xl font-bold">260,000</span>
                    <span className="text-base font-medium">
                      {t("homepage.debtCollection")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="grid col-span-4 rounded-[20px] bg-[#FAFDFF] shadow-[1px_1px_10px_0px_#0000001A] p-8 gap-10">
                <span className="text-lg font-bold">
                  {t("homepage.customerCreditVsNonCustomerCredit")}
                </span>
                <div>
                  <ReactECharts
                    option={{
                      legend: {
                        bottom: "0%",
                        left: "center",
                        data: ["Users", "Customers"],
                        textStyle: {
                          fontSize: 14,
                          color: "#000000",
                        },
                        itemGap: 20,
                        icon: "circle",
                        show: true,
                        formatter: function (name: any) {
                          const legendValue = UserData.filter((item) => {
                            return item.name === name;
                          });

                          return `${name} ${legendValue[0].value} คน`;
                        },
                      },
                      tooltip: {
                        trigger: "item",
                      },
                      series: [
                        {
                          type: "pie",
                          radius: ["40%", "70%"],
                          padAngle: 5,
                          itemStyle: {
                            color: undefined,
                            borderRadius: 5,
                            borderWidth: 2,
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                          },
                          emphasis: {
                            itemStyle: {
                              shadowBlur: 30,
                              shadowOffsetX: 0,
                              shadowOffsetY: 0,
                              shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                            label: {
                              show: false,
                            },
                          },
                          labelLine: { show: false },
                          tooltip: {
                            formatter: (params: any) => {
                              return `${params.name}: ${params.percent}%`;
                            },
                          },
                          data: UserData,
                          label: {
                            show: false,
                          },
                        },
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="grid col-span-4 rounded-[20px] bg-[#FAFDFF] shadow-[1px_1px_10px_0px_#0000001A] p-8 gap-10">
                <span className="text-lg font-bold">
                  {t("homepage.saleVolumnGraph")}
                </span>
                <div>
                  <ReactECharts
                    option={{
                      legend: {
                        bottom: "0%",
                        left: "center",
                        data: ["Actual Selling", "Target Selling"],
                        textStyle: {
                          fontSize: 14,
                          color: "#000000",
                        },
                        itemGap: 20,
                        icon: "circle",
                        show: true,
                        formatter: function (name: any) {
                          const legendValue = SaleData.filter((item) => {
                            return item.name === name;
                          });

                          return `${name} ${legendValue[0].value} บาท`;
                        },
                      },
                      tooltip: {
                        trigger: "item",
                      },
                      series: [
                        {
                          type: "pie",
                          radius: ["40%", "70%"],
                          padAngle: 5,
                          itemStyle: {
                            color: undefined,
                            borderRadius: 5,
                            borderWidth: 2,
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                          },
                          emphasis: {
                            itemStyle: {
                              shadowBlur: 30,
                              shadowOffsetX: 0,
                              shadowOffsetY: 0,
                              shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                            label: {
                              show: false,
                            },
                          },
                          labelLine: { show: false },
                          tooltip: {
                            formatter: (params: any) => {
                              return `${params.name}: ${params.percent}%`;
                            },
                          },
                          data: SaleData,
                          label: {
                            show: false,
                          },
                        },
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="grid col-span-4 rounded-[20px] bg-[#FAFDFF] shadow-[1px_1px_10px_0px_#0000001A] p-8 gap-10">
                <span className="text-lg font-bold">
                  {t("homepage.loanOutstandingVsRepayment")}
                </span>
                <div>
                  <ReactECharts
                    option={{
                      legend: {
                        bottom: "0%",
                        left: "center",
                        data: ["Loan Outstanding", "Repayment"],
                        textStyle: {
                          fontSize: 14,
                          color: "#000000",
                        },
                        itemGap: 20,
                        icon: "circle",
                        show: true,
                        formatter: function (name: any) {
                          const legendValue = DeptData.filter((item) => {
                            return item.name === name;
                          });

                          return `${name} ${legendValue[0].value} บาท`;
                        },
                      },
                      tooltip: {
                        trigger: "item",
                      },
                      series: [
                        {
                          type: "pie",
                          radius: ["40%", "70%"],
                          padAngle: 5,
                          itemStyle: {
                            color: undefined,
                            borderRadius: 5,
                            borderWidth: 2,
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                          },
                          emphasis: {
                            itemStyle: {
                              shadowBlur: 30,
                              shadowOffsetX: 0,
                              shadowOffsetY: 0,
                              shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                            label: {
                              show: false,
                            },
                          },
                          labelLine: { show: false },
                          tooltip: {
                            formatter: (params: any) => {
                              return `${params.name}: ${params.percent}%`;
                            },
                          },
                          data: DeptData,
                          label: {
                            show: false,
                          },
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MenuBar>
    </>
  );
}
export default Home;
