import { Dispatch, memo, SetStateAction, useCallback, useState } from "react";
import { tw } from "../../../../../../utils/tw";
import { SvgIcon } from "@mui/material";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import dayjs from "dayjs";
import { DownloadSvg } from "../../../../../assets/svg/Download.svg";
import { makeXlsx } from "../../../../../../utils/make-xlsx";
import { getExportCreditFeed } from "../../../../../../services/CreditFeed.service";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../group-area/views/Dropdown";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import { TFormTabHistory } from "./DefaultFormTabHistory";
import { TStatusMapCreditFeed } from "../../controller";
import { useTranslation } from "react-i18next";

const optionStatus = [
  {
    label: "สำเร็จ",
    value: "approved",
  },
  {
    label: "ไม่อนุมัติ",
    value: "denied",
  },
  {
    label: "ยกเลิกโดยลูกค้า",
    value: "farmer_denied",
  },
];

const classes = {
  container: tw(`flex flex-col gap-6`),
  containerFilter: tw(`flex flex-row justify-between`),
  containerSearchText: tw(`w-[470px]`),
};

const statusMap = {
  "": "",
  approved: "สำเร็จ",
  denied: "ไม่อนุมัติ",
  farmer_denied: "ยกเลิกโดยลูกค้า",
};

interface IProps {
  searchText: string;
  onSearchText: (e: any) => void;
  onClearSearchText: () => void;
  itemSelected: string[];
  form: TFormTabHistory;
  setForm: Dispatch<SetStateAction<TFormTabHistory>>;
  onChangeForm: (key: keyof TFormTabHistory) => (value: any) => void;
  gradeCustomerInDataTabHistory: TDropdownValue[];
  statusCreditInDataTabHistory: TDropdownValue[];
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

interface DataExport {
  customerId: string;
  customerName: string;
  garde: string;
  saleName: string;
  date: string;
  contractNumber: string;
  statues: string;
}
function createData(
  customerId: string,
  contractNumber: string,
  customerName: string,
  garde: string,
  saleName: string,
  date: string,
  statues: string
): DataExport {
  return {
    customerId,
    contractNumber,
    customerName,
    garde,
    saleName,
    date,
    statues,
  };
}

const FilterTabHistory = (props: IProps) => {
  const { t } = useTranslation();
  const {
    searchText,
    onSearchText,
    onClearSearchText,
    itemSelected,
    form,
    setForm,
    onChangeForm,
    gradeCustomerInDataTabHistory,
    statusCreditInDataTabHistory,
    setCurrentPage,
  } = props;

  const [grade, setGrade] = useState<TDropdownValue[]>([]);
  const [statusCredit, setStatusCredit] = useState<TDropdownValue[]>([]);

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `credit_feed_history_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "รหัสลูกค้า"
      | "เลขที่สัญญา"
      | "ชื่อลูกค้า"
      | "เกรดลูกค้า"
      | "พนักงานขาย"
      | "วันที่อัปเดตล่าสุด"
      | "สถานะเครดิต";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getExportCreditFeed(itemSelected);
    if (allSelectItemData) {
      const data: TExcelData[] = allSelectItemData?.map<TExcelData>((row) => {
        const mappedData = createData(
          row.registrationId,
          row.contractNumber,
          row.name + " " + row.surname,
          row.customerGrade.toLocaleUpperCase(),
          row.sellerName + " " + `${row.sellerSurname || ""}`,
          dayjs(row.updatedAt).format("DD/MM/YYYY"),
          statusMap[
            row.contractStatus as "approved" | "denied" | "farmer_denied"
          ]
        );
        return {
          รหัสลูกค้า: mappedData.customerId,
          เลขที่สัญญา: mappedData.contractNumber,
          ชื่อลูกค้า: mappedData.customerName,
          เกรดลูกค้า: mappedData.garde,
          พนักงานขาย: mappedData.saleName,
          วันที่อัปเดตล่าสุด: mappedData.date,
          สถานะเครดิต: mappedData.statues,
        };
      });
      return data;
    }
    return [];
  };

  const onSearch = () => {
    onChangeForm("grade")(grade);
    onChangeForm("statusCredit")(statusCredit);
    setCurrentPage(1);
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerFilter}>
        <div className="flex flex-row gap-3 max-w-[534px] w-full">
          <Dropdown
            options={gradeCustomerInDataTabHistory}
            onChange={(value) => setGrade(value)}
            onReset={() => setGrade([])}
            renderValueArray={grade}
            placeholder="เลือก"
            label={t("creditFeed.creditHistoryTab.grade")}
          />
          <Dropdown
            options={optionStatus}
            onChange={(value) => setStatusCredit(value)}
            onReset={() => setStatusCredit([])}
            renderValueArray={statusCredit}
            placeholder="เลือก"
            label={t("creditFeed.creditHistoryTab.status")}
          />
        </div>
        <ButtonFillCustom title={"ค้นหา"} onClick={onSearch} />
      </div>
      <div className={classes.containerFilter}>
        <div className={classes.containerSearchText}>
          <TextInputSearchCustom
            value={searchText}
            placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
            onChange={onSearchText}
            InputProps={{
              endAdornment:
                searchText.length > 0 ? (
                  <CloseRoundedIcon
                    sx={{
                      cursor: "pointer",
                      fontSize: "18px !important",
                    }}
                    onClick={onClearSearchText}
                  />
                ) : null,
            }}
          />
        </div>
        <ButtonOutlinedCustom
          title={t("common.download")}
          btnTextColor="#68C184"
          btnBorderColor="#68C184"
          onClick={handleExportExcel}
          disabled={itemSelected.length === 0}
          startIcon={
            <SvgIcon
              sx={{
                fontSize: "14px !important",
                color: "currentColor",
                marginBottom: "3px",
              }}
              fontSize="small"
            >
              <DownloadSvg />
            </SvgIcon>
          }
        />
      </div>
    </div>
  );
};

export default memo(FilterTabHistory);
