import { Link } from "@mui/material";
import get from "lodash/get";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import ButtonFillCustom from "../../../admin/components/Button/ButtonFillCustom";
import { unitMap } from "../../../admin/pages/manage-stock/[id]";
import {
  TOrderPartnerStatusEnum,
  TOrderStatusEnum,
} from "../../../types/ParnerOrder.type";
import { getShortDate } from "../../../utils/date";
import { tw } from "../../../utils/tw";
import { actionWord, statusWord } from "../constants";

export interface IOrderItemProps {
  id: string;
  orderNo: string;
  receivedDate: string;
  labelClassName?: string;
  items: {
    id: string;
    brandName: string;
    brandNo: string;
    quantity: number;
    quantityUnit: string;
  }[];
  orderStatus: TOrderStatusEnum;
  partnerStatus: TOrderPartnerStatusEnum;
  status:
    | "new"
    | "ready-for-ship"
    | "shipping"
    | "shipped"
    | "received"
    | "rejected"
    | "canceled";
  actionClassName?: string;
  orderDate: string;
  expectReceivedDate: string;
  shippingType: "pickup" | "shipping";
  // address?: {
  //   name: string
  //   tel: string
  //   address: string
  // }
  address?: string;
  images?: {
    id: string;
    fileName: string;
    fileSize: number;
    url: string;
    documentType:
      | "partnerProofOfDelivery"
      | "partnerPickingSlip"
      | "partnerPickingSlipForSale";
  }[];
  hideButtonAction?: boolean;
}

const classes = {
  container: tw(`w-full flex flex-col mb-[16px]`),
  headerContainer: tw(
    `flex flex-row items-center justify-between py-[7px] px-[14px] rounded-t-[20px] bg-[#D9EBFE]`
  ),
  headerLabel: tw(`text-[14px] leading-[19.6px] font-medium`),
  label: tw(
    `text-warning bg-[#FEF9F2] text-[14px] leading-[19.6px] py-[2px] px-[8px] rounded-full border-[1px] border-warning text-center whitespace-pre`
  ),
  contentContainer: tw(`flex flex-col bg-[#F6FBFF] py-[16px] rounded-b-[20px]`),
  dotContainer: tw(
    `flex flex-row items-center text-[14px] font-semibold leading-[18.2px] mb-[12px]`
  ),
  dot: tw(
    `w-[7.76px] h-[7.76px] rounded-full bg-[#014F79] ml-[15.12px] mr-[17.12px]`
  ),
  contentColumn: tw(
    `flex flex-row items-center justify-between mb-[12px] px-[24px]`
  ),
  columnTitle: tw(`text-[#3777BC] text-[14px] leading-[18.2px]`),
  columnValue: tw(`text-[#34407B] text-[16px] leading-[19.2px]`),
  seeMore: tw(
    `text-[#97BFEA] text-[15px] leading-[18px] p-[8px] text-center cursor-pointer hover:text-[#3777BC] self-center w-[98%] mb-[24px]`
  ),
};

const OrderItem = (
  props: IOrderItemProps & {
    index: number;
    onAction: (id: string, index: number) => void;
    onMoreDetail: () => void;
  }
) => {
  const {
    id,
    index,
    orderNo,
    receivedDate,
    labelClassName,
    status,
    items,
    actionClassName,
    onAction,
    onMoreDetail,
    hideButtonAction = false,
  } = props;

  const onActionFn = useCallback(() => {
    onAction(id, index);
  }, [id, index]);

  const displayStatus = get(statusWord, status, "-");

  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <div className="flex flex-col">
          <div className={tw(classes.headerLabel, "mb-[2.5px]")}>
            {t("feedOrderAndDelivery.myOrder.card.orderNumber")}{" "}
            <span className="font-bold">{orderNo}</span>
          </div>
          <div className={classes.headerLabel}>
            {t("PartnerOrder.ReceiptDate")}{" "}
            <span className="font-bold">{getShortDate(receivedDate)}</span>
          </div>
        </div>
        <div
          className={tw(
            classes.label,
            labelClassName,
            status === "canceled" &&
              "text-status-bad border-status-bad bg-[#F9E0E0] ",
            status === "received" &&
              "text-[#409261] border-[#409261] bg-[#E9FFEF]"
          )}
        >
          {displayStatus}
        </div>
      </div>
      <div className={classes.contentContainer}>
        <div className={classes.dotContainer}>
          <div className={classes.dot} />
          {t("FinancialSupport.ItemNo")} {1}
        </div>
        <div className={classes.contentColumn}>
          <div className={classes.columnTitle}>
            {t("FinancialSupport.Brand")}
          </div>
          <div className={classes.columnValue}>{items[0].brandName}</div>
        </div>
        <div className={classes.contentColumn}>
          <div className={classes.columnTitle}>{t("FinancialSupport.SKU")}</div>
          <div className={classes.columnValue}>{items[0].brandNo}</div>
        </div>
        <div className={classes.contentColumn}>
          <div className={classes.columnTitle}>
            {t("FinancialSupport.Quantity")}
          </div>
          <div className={classes.columnValue}>
            {items[0].quantity} {unitMap[items[0].quantityUnit as "kg" | "bag"]}
          </div>
        </div>
        <div className="text-center my-4">
          <Link
            className="!text-[#68C184] !text-[15px] !font-sans !font-normal"
            color="#68c184"
            onClick={onMoreDetail}
          >
            {t("PartnerOrder.viewAll")}
          </Link>
        </div>
        {!hideButtonAction && (
          <>
            {get(actionWord, status, "-") !== "-" && (
              <div className="self-end mr-[16px]">
                <ButtonFillCustom
                  title={get(actionWord, status, "-")}
                  onClick={onActionFn}
                  className={tw("!rounded-full !mb-[8px]", actionClassName)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default memo(OrderItem);
