import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider, Tab } from "@mui/material";
import { get, isEmpty } from "lodash";
import { useMemo } from "react";
import { tw } from "../../../utils/tw";
import ButtonFillCustom from "../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../components/Input/TextInputSearchCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import { useGroupAreaController } from "./controller";
import CreateGroupAreaModal from "./views/CreateGroupAreaModal/index";
import DetailModal from "./views/DetailModal";
import { Dropdown } from "./views/Dropdown";
import Tabs from "./views/Tabs";
import CardTab from "./views/Tabs/CardTab";
import TableTab from "./views/Tabs/TableTab";
import { useAdminAuth } from "../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  title: tw(`text-black font-bold text-3xl`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  filterRowContainer: tw(`flex flex-row flex-wrap items-end gap-3`),
};

const GroupArea = () => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.settingGroupArea === "edit";
  const {
    isOpenCreate,
    onOpenCreate,
    onHideCreate,
    options,
    form,
    onChangeForm,
    onSearch,
    tabIndex,
    onChangeTab,
    searchText,
    onSearchText,
    onClearSearchText,
    columnSelected,
    onSelectColumn,
    groupAreaOptions,
    isLoadingAreaSelected,
    customerTable,
    tableCurrentPage,
    setTableCurrentPage,
    customerTableCount,
    getFilterCustomerTable,
    onToggleSort,
    groupIdModalDetail,
    setGroupIdModalDetail,
    onCloseModalDetail,
    onOpenEdit,
    editGroupId,
    setEditGroupId,
    detail,
    setDetail,
    refetch,
    showButton,
  } = useGroupAreaController();

  const provinceOptions = useMemo(() => {
    if (isEmpty(form.area)) return [];
    return options.allProvinceOptions.filter(
      ({ areaCode }) => areaCode === get(form.area, [0, "value"])
    );
  }, [options.allProvinceOptions, form.area]);

  const districtOptions = useMemo(() => {
    if (isEmpty(form.province)) return [];
    const newOptions = [];
    for (let i = 0; i < options.allDistrictOptions.length; i++) {
      const { provinceCode } = options.allDistrictOptions[i];
      const isFound =
        form.province.findIndex(({ value }) => value === provinceCode) !== -1;
      isFound && newOptions.push(options.allDistrictOptions[i]);
    }
    return newOptions;
  }, [options.districtOptions, form.province]);

  const groupColumnSelected = useMemo(() => {
    return groupAreaOptions.find(
      ({ id }) => id === get(columnSelected.group, [0, "value"])
    );
  }, [groupAreaOptions, columnSelected]);

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>Group Area</span>
              {roleActionEdit && showButton && (
                <ButtonFillCustom title="สร้างกลุ่ม" onClick={onOpenCreate} />
              )}
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className={classes.mainContainer}>
              {tabIndex === 1 && (
                <div className={classes.filterRowContainer}>
                  <Dropdown
                    label="ภาค"
                    options={options.areaOptions}
                    onChange={onChangeForm("area")}
                    renderValueArray={form.area}
                    containerClassName="min-w-[200px]"
                  />
                  <Dropdown
                    label="จังหวัด"
                    required
                    multiple
                    disabled={isEmpty(form.area)}
                    options={provinceOptions}
                    onChange={onChangeForm("province")}
                    renderValueArray={form.province}
                  />
                  <Dropdown
                    label="เขต/อำเภอ"
                    required
                    multiple
                    disabled={isEmpty(form.province)}
                    options={districtOptions}
                    onChange={onChangeForm("district")}
                    renderValueArray={form.district}
                  />
                  <div className="mb-3.5">
                    <ButtonFillCustom title="ค้นหา" onClick={onSearch} />
                  </div>
                </div>
              )}
              <div className="h-3" />
              <Tabs tabIndex={tabIndex} onChange={onChangeTab}>
                <Tab label={t("groupArea.viewByColumn")} />
                <Tab label={t("groupArea.viewByTable")} />
              </Tabs>
              {tabIndex === 1 && (
                <>
                  <div className="h-6" />
                  <TextInputSearchCustom
                    value={searchText}
                    className="max-w-[470px]"
                    onChange={onSearchText}
                    placeholder="ค้นหาโดยชื่อลูกค้า, พนักงานขาย"
                    InputProps={{
                      endAdornment: searchText ? (
                        <CloseRoundedIcon
                          sx={{ cursor: "pointer" }}
                          onClick={onClearSearchText}
                        />
                      ) : null,
                    }}
                  />
                </>
              )}
              {tabIndex === 0 ? (
                <CardTab
                  columnSelected={columnSelected}
                  groupAreaOptions={groupAreaOptions}
                  groupColumnSelected={groupColumnSelected as any}
                  isLoadingAreaSelected={isLoadingAreaSelected}
                  onSelectColumn={onSelectColumn}
                  options={options}
                />
              ) : (
                <TableTab
                  currentPage={tableCurrentPage}
                  onChangePage={setTableCurrentPage}
                  totalData={customerTableCount}
                  data={customerTable}
                  onSortArea={onToggleSort}
                  setGroupIdModalDetail={setGroupIdModalDetail}
                />
              )}
              {/* End main container */}
            </div>
          </div>
        </div>
      </MenuBar>
      <CreateGroupAreaModal
        isOpen={isOpenCreate}
        onClose={onHideCreate}
        options={options}
        refetch={refetch}
        editGroupId={editGroupId}
        setEditGroupId={setEditGroupId}
        editDetail={detail}
        setDetail={setDetail}
      />
      <DetailModal
        showButton={showButton}
        groupIdModalDetail={groupIdModalDetail}
        isOpen={!!groupIdModalDetail}
        onClose={onCloseModalDetail}
        onOpenEdit={onOpenEdit}
        detail={detail}
        setDetail={setDetail}
      />
    </>
  );
};

export default GroupArea;
