import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import { FormEvent, useRef, useState } from "react";
import { CalendarSvg } from "../../../../assets/svg/Calendar.svg";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import DateRangePickerPopover from "../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import TextInputCustom from "../../../../components/Input/TextInputCustom";
import TextInputSearchCustom from "../../../../components/Input/TextInputSearchCustom";
import { FILTER_DATE_FORMAT } from "../../../sale-order/components/SaleOrderSearchFilter";
import { TUseFarmCommunityFilter } from "../../hooks/farm-community/useFarmCommuFilter.hook";
import { SequenceManualRow } from "../../types/farmCommunity";
import SortUserManualModal from "./Modal/SortUserManualModal";

type Props = {
  tabName: string;
  tabIndex: number;
  searchUserManual: TUseFarmCommunityFilter;
  onSearch: () => void;
  sequenceData: SequenceManualRow[];
};

export const SearchUserManual = ({
  tabName,
  searchUserManual,
  onSearch,
  tabIndex,
  sequenceData,
}: Props) => {
  const { action } = useAdminAuth();
  const roleActionEdit = action?.masterDataFarmCommunity === "edit";
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isOpenSortArticle, setIsOpenSortArticle] = useState(false);
  const refCalendarRangeDate =
    useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSearch();
  };

  const handleSelectDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);

    const formattedStartDateIso = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).toISOString();

    const formattedEndDateIso = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).toISOString();

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    searchUserManual.setStartDate(formattedStartDateIso);
    searchUserManual.setEndDate(formattedEndDateIso);
  };

  const handleCalenderSaveDate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();

    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    const formattedStartDateIso = dayjs(value[0].startDate).toISOString();
    const formattedEndDateIso = dayjs(value[0].endDate).toISOString();

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
    searchUserManual.setStartDate(formattedStartDateIso);
    searchUserManual.setEndDate(formattedEndDateIso);
  };

  return (
    <div className="mt-6">
      <form onSubmit={handleSubmit}>
        <div className="flex justify-between">
          <div className="flex gap-6">
            <div className="w-[340px]">
              <span className="text-sm font-medium font-sans">
                วันที่อัปเดตล่าสุด
              </span>
              <div className="pt-2">
                <TextInputCustom
                  placeholder="เริ่มต้น - สิ้นสุด"
                  value={startDate ? startDate + " - " + endDate : ""}
                  InputProps={{
                    endAdornment:
                      startDate !== "" ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(e) => {
                            searchUserManual.setStartDate("");
                            searchUserManual.setEndDate("");
                            searchUserManual.setToggleFetch((prev) => !prev);
                            setEndDate("");
                            setStartDate("");
                            e.stopPropagation();
                          }}
                        />
                      ) : (
                        <SvgIcon
                          sx={{
                            fontSize: "24px !important",
                            color: "#646464",
                          }}
                          fontSize="small"
                        >
                          <CalendarSvg />
                        </SvgIcon>
                      ),
                  }}
                  onClick={(
                    e: React.MouseEvent<HTMLDivElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    refCalendarRangeDate.current?.onOpen(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pt-8">
            <ButtonFillCustom
              type="submit"
              title="ค้นหา"
              onClick={() => handleSubmit}
            />
          </div>
        </div>
      </form>
      <div className="flex justify-between mt-6">
        <div className="flex w-full max-w-[656px]">
          <div className="w-full max-w-[470px] mr-4">
            <TextInputSearchCustom
              value={searchUserManual.searchText}
              placeholder="ค้นหาโดยชื่อหัวข้อ"
              onChange={(event) => {
                searchUserManual.setSearchText(event.target.value);
              }}
              InputProps={{
                endAdornment:
                  searchUserManual?.searchText.length > 0 ? (
                    <CloseRoundedIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "18px !important",
                      }}
                      onClick={(_) => {
                        searchUserManual.setSearchText("");
                        searchUserManual.setToggleFetch((prev) => !prev);
                      }}
                    />
                  ) : null,
              }}
            />
          </div>
        </div>
        {roleActionEdit && (
          <ButtonOutlinedCustom
            title={"เรียงลำดับ"}
            onClick={() => setIsOpenSortArticle(true)}
          />
        )}
      </div>
      <DateRangePickerPopover
        ref={refCalendarRangeDate}
        id={"calendar-range-popover-farm-community"}
        handleSelect={handleSelectDate}
        openCalender={isOpenDatePicker}
        onClose={() => setIsOpenDatePicker(false)}
        handleCalenderSave={handleCalenderSaveDate}
        startDate={startDate}
        endDate={endDate}
      />
      <SortUserManualModal
        tabName={tabName}
        data={sequenceData}
        isOpen={isOpenSortArticle}
        handleClose={() => setIsOpenSortArticle(false)}
      />
    </div>
  );
};
