import { Checkbox, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { DeleteOutlinedIcon } from "../../../../../assets/svg/DeleteOutlined";
import { DATE_FORMAT_ENG } from "../../../../../const/app.constant";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";
import { SearchFarmCommunity } from "../../components/farmCommunity/SearchFarmCommunity";
import { TUseFarmCommunityFilter } from "../../hooks/farm-community/useFarmCommuFilter.hook";
import {
  IFarmCommunityTable,
  IGetPigPriceRow,
  IPigPriceTable,
} from "../../types/farmCommunity";
import CreatePigPrice from "../../components/farmCommunity/Modal/CreatePigPrice";
import ViewPigPriceModal from "../../components/farmCommunity/Modal/ViewPigPriceModal";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

type Props = {
  data: IGetPigPriceRow[];
  dataTable: IPigPriceTable[];
  searchFarmCommunity: TUseFarmCommunityFilter;
  handleFetch: () => void;
  tabIndex: number;
  handleDeletePigPrice: (id: string) => void;
};

const headCells: ITableCustomHeadCell<keyof IGetPigPriceRow>[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "0px",
  },

  {
    id: "topicName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อหัวข้อ",
    width: "350px",
  },
  {
    id: "announceDate",
    numeric: false,
    disablePadding: false,
    label: "วันที่ประกาศราคา",
    width: "200px",
    isSorting: false,
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัปเดตล่าสุด",
    width: "200px",
    isSorting: true,
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "40px",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "20px",
  },
];

const PigPrice = ({
  data,
  searchFarmCommunity,
  handleFetch,
  dataTable,
  tabIndex,
  handleDeletePigPrice,
}: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.masterDataFarmCommunity === "edit";
  const [isOpenEditPigPrice, setIsOpenEditPigPrice] = useState(false);
  const [isOpenViewPigPrice, setIsOpenViewPigPrice] = useState(false);
  const [selectedIndex, setSelectIndex] = useState<number>(0);
  const filterHeader = roleActionEdit ? headCells : headCells.toSpliced(5, 2);

  return (
    <>
      <SearchFarmCommunity
        searchFarmCommunity={searchFarmCommunity}
        onSearch={handleFetch}
        hideSelect
        tabIndex={tabIndex}
      />
      <div className="pt-6">
        <TableCustom
          order={searchFarmCommunity.direction}
          orderBy={"updateAt"}
          itemsSelected={[]}
          rowsPerPage={searchFarmCommunity.limit}
          page={searchFarmCommunity.page}
          headerItems={filterHeader}
          rows={dataTable}
          onChangePage={(value) => {
            searchFarmCommunity.setPage(value);
          }}
          onChangeOrder={(val) => searchFarmCommunity.setDirection(val)}
          onChangeOrderBy={(val) =>
            searchFarmCommunity.setOrderBy(val as keyof IFarmCommunityTable)
          }
          onSelectItem={() => {}}
          onSelectAllClick={() => {}}
          totalItemSize={searchFarmCommunity.count}
          currentPageItems={dataTable.map((row) => row.id)}
          hideSelect
          rowChildren={dataTable.map((row, index) => (
            <TableRow hover key={index}>
              <TableCell padding="normal" align="center">
                {row.displayPriority && (
                  <Checkbox
                    checked={row.displayPriority === 1}
                    sx={{
                      cursor: "default",
                      "&.Mui-checked": {
                        color: "#68C184",
                      },
                    }}
                    style={{ cursor: "default" }}
                  />
                )}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                {row.topicName}
              </TableCell>

              <TableCell align="left">{row.announceDate}</TableCell>
              <TableCell align="left">{row.updateAt}</TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                  padding: "16px 0",
                }}
              >
                <ButtonOutlinedCustom
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  title={"ดูรายละเอียด"}
                  onClick={() => {
                    setSelectIndex(index);
                    setIsOpenViewPigPrice(true);
                  }}
                />
              </TableCell>
              {roleActionEdit && (
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                    padding: "16px 0",
                  }}
                >
                  <ButtonFillCustom
                    btnBgColor="#68C184"
                    btnTextColor="#FFFFFF"
                    title={"แก้ไข"}
                    onClick={() => {
                      setIsOpenEditPigPrice(true);
                      setSelectIndex(index);
                    }}
                  />
                </TableCell>
              )}
              {roleActionEdit && (
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                    padding: "16px 0",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleDeletePigPrice(row.id);
                    }}
                  >
                    <DeleteOutlinedIcon />
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        />
      </div>
      <CreatePigPrice
        isOpen={isOpenEditPigPrice}
        handleClose={(isSuccess) => {
          if (isSuccess) {
            handleFetch();
          }
          setIsOpenEditPigPrice(false);
          setSelectIndex(0);
        }}
        data={data[selectedIndex]}
      />
      <ViewPigPriceModal
        isOpen={isOpenViewPigPrice}
        handleClose={() => {
          setIsOpenViewPigPrice(false);
          setSelectIndex(0);
        }}
        data={data[selectedIndex]}
      />
    </>
  );
};

export default PigPrice;
