import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
const FooterPartner = ({ selectedTab = 0, handleChange }: any) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();

  const onNavigate = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    []
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50  bg-[#FFFFFF] shadow-2xl">
      <Tabs
        value={value}
        onChange={handleTabChange}
        variant="fullWidth"
        className="flex justify-around !items-center"
        sx={{
          "& .MuiTabs-indicator": {
            top: 0,
            bottom: "auto",
            backgroundColor: "#68C184 !important",
          },
          "& .MuiTab-root": {
            minWidth: "0px",
            flex: 1,
            maxWidth: "none",
            padding: "16px 0",
            "& .Mui-selected": {
              color: "#68C184 !important",
            },
          },
        }}
      >
        <Tab
          icon={
            <div
              onClick={onNavigate("/partner")}
              className={`flex flex-col items-center relative ${
                value === 0 ? "text-[#68C184]" : "text-[#7E7E7E]"
              }`}
            >
              <HomeIcon />
              <span className="text-xs mt-1">หน้าแรก</span>
            </div>
          }
        />
        <Tab
          icon={
            <div
              onClick={onNavigate("/partner/farms/farm-community")}
              className={`flex flex-col items-center relative ${
                value === 1 ? "text-[#68C184]" : "text-[#7E7E7E]"
              }`}
            >
              <PeopleIcon />
              <span className="text-xs mt-1">ชุมชน</span>
            </div>
          }
        />
        <Tab
          icon={
            <div
              onClick={onNavigate("/order")}
              className={`flex flex-col items-center relative ${
                value === 2 ? "text-[#68C184]" : "text-[#7E7E7E]"
              }`}
            >
              <ContentPasteIcon />
              <span className="text-xs mt-1">ออเดอร์</span>
            </div>
          }
        />
        <Tab
          // disabled
          icon={
            <div
              onClick={onNavigate("/partner/stock-details")}
              className={`flex flex-col items-center relative ${
                value === 3 ? "text-[#68C184]" : "text-[#7E7E7E]"
              }`}
            >
              <InventoryIcon />
              <span className="text-xs mt-1">สต๊อก</span>
            </div>
          }
        />
        <Tab
          icon={
            <div
              onClick={onNavigate("/partner/account-center")}
              className={`flex flex-col items-center relative ${
                value === 4 ? "text-[#68C184]" : "text-[#7E7E7E]"
              }`}
            >
              <PersonIcon />
              <span className="text-xs mt-1">โปรไฟล์</span>
            </div>
          }
        />
      </Tabs>
    </div>
  );
};
export default FooterPartner;
