import { Dialog, DialogContent, IconButton } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { get } from "lodash";
import { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../../../redux/store";
import { rejectClaimOrder } from "../../../../../../../services/ClaimOrder.service";
import { IDataClaimOrder } from "../../../../../../../types/ClaimOrder.type";
import getErrorMessage from "../../../../../../../utils/getErrorMessage";
import { tw } from "../../../../../../../utils/tw";
import CrossIcon from "../../../../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../../../components/Button/ButtonOutlinedCustom";
import TextAreaCustom from "../../../../../../components/Input/TextAreaCustom";
import { Dropdown } from "../../../../../group-area/views/Dropdown";

interface ICreateModal {
  isOpen: boolean;
  onCloseFn: () => void;
  refetchListClaimOrderUpcoming?: () => void;
  refetchListClaimOrderHistory?: () => void;
  dataEdit?: IDataClaimOrder;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[548px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
};

const defaultForm = {
  reasonReject: [
    {
      label: "",
      value: "",
    },
  ],
};

const reasonRejectOption = [
  {
    label: "ไม่ตรงตามเงื่อนไขรายงานปัญหา",
    value: "invalid_reason",
  },
];

const RejectClaimOrder = (props: ICreateModal) => {
  const {
    isOpen,
    onCloseFn,
    refetchListClaimOrderUpcoming,
    refetchListClaimOrderHistory,
    dataEdit,
  } = props;
  const [form, setForm] = useState(defaultForm);
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const [remark, setRemark] = useState("");

  const onChangeForm = useCallback(
    (key: keyof typeof form) => (value: any) => {
      setForm((prev) => ({ ...prev, [key]: value }));
    },
    []
  );

  const rejectMutation = useMutation({
    mutationFn: rejectClaimOrder,
    onSuccess: async () => {
      await Promise.all([
        queryClient.refetchQueries({
          queryKey: ["list_claim_order_upcoming"],
        }),
        queryClient.refetchQueries({
          queryKey: ["list_claim_order_history"],
        }),
      ]);
      {
        refetchListClaimOrderUpcoming && refetchListClaimOrderUpcoming();
      }
      {
        refetchListClaimOrderHistory && refetchListClaimOrderHistory();
      }
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "ไม่อนุมัติการเคลมสำเร็จ",
              type: "success",
            })
          );
          setForm(defaultForm);
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onSubmit = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการปิดออเดอร์การเคลม",
        text: "คุณต้องการปิดออเดอร์การเคลมนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(
            loadingActions.show({ message: "กำลังปิดออเดอร์การเคลม..." })
          );
          rejectMutation.mutate({
            orderClaimId: get(dataEdit, ["id"], "-"),
            rejectOption: form.reasonReject[0].value,
            rejectReason: remark,
          });
        },
      })
    );
  }, [form, remark]);

  const onClose = useCallback(() => {
    onCloseFn();
    setForm(defaultForm);
    setRemark("");
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent className="w-[548px]">
        <div className={classes.titleContainer}>
          <p className={classes.title}>ปิดออเดอร์การเคลม</p>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className="flex flex-col py-6 gap-6">
          <div className="flex flex-col gap-[6px]">
            <div className="font-bold text-[14px] text-[#191919]">เหตุผล</div>
            <Dropdown
              options={reasonRejectOption}
              onChange={onChangeForm("reasonReject")}
              renderValueArray={form.reasonReject}
            />
          </div>
          <div className="flex flex-col">
            <span className="font-sans font-medium text-[14px] leading-5 text-[#191919] pb-2">
              รายละเอียด
            </span>
            <TextAreaCustom
              placeholder="กรอก"
              value={remark}
              onChange={(e) => setRemark(e.target.value)}
            />
            <div className="w-full flex justify-end pt-[2px]">
              <span className="font-normal text-[12px] text-[#979797]">
                (maximum 500 characters)
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end gap-[10px]">
          <ButtonOutlinedCustom
            onClick={onClose}
            title="ยกเลิก"
            btnTextColor="#68C184"
            btnBgColor="#ffffff"
            btnBorderColor="#68C184"
          />
          <ButtonFillCustom
            onClick={onSubmit}
            title="ยืนยัน"
            btnTextColor="#ffffff"
            btnBgColor="#68C184"
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(RejectClaimOrder);
