import { Divider, Tab } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { get } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Y from "yup";
import useFormValidator from "../../../../hooks/useFormValidator.hook";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import {
  ApproveLending,
  ApproveLendingConsideration,
  ApproveSaleForward,
  ApproveSaleLead,
  DiscardCredit,
  UploadFileCredit,
  getCreditSettingById,
  getUploadUrlCreateFeed,
  rejectFeed,
} from "../../../../services/CreditFeed.service";
import { ICreditInfoById } from "../../../../types/CreditFeed.type";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { getCommaNumber } from "../../../../utils/number";
import { tw } from "../../../../utils/tw";
import { useAdminAuth } from "../../../components/AdminAuth/AdminAuthProvider";
import MenuBar from "../../../components/MenuBar/MenuBar";
import Tabs from "../../group-area/views/Tabs";
import { useCreditFeedController } from "./controller";
import ModalApplyCredit from "./credit-modal/ModalApplyCredit";
import ModalApproveCredit from "./credit-modal/ModalApproveCredit";
import ModalImportDocument from "./credit-modal/ModalImportDocument";
import ModalReason from "./credit-modal/ModalReason";
import FilterTabApprove from "./credit-tab/TabApprove/FilterTabApprove";
import TableTabApprove from "./credit-tab/TabApprove/TableTabApprove";
import FilterTabDenied from "./credit-tab/TabDenied/FilterTabDenied";
import TableTabDenied from "./credit-tab/TabDenied/TableTabDenied";
import { defaultFormTabFarmer } from "./credit-tab/TabFarmer/DefaultFormTabFarmer";
import FilterTabFarmer from "./credit-tab/TabFarmer/FilterTabFarmer";
import TableTabFarmer from "./credit-tab/TabFarmer/TableTabFarmer";
import FilterTabHistory from "./credit-tab/TabHistory/FilterTabHistory";
import TableTabHistory from "./credit-tab/TabHistory/TableTabHistory";
import FilterTabPending from "./credit-tab/TabPending/FilterTabPending";
import TableTabPending from "./credit-tab/TabPending/TableTabPending";
import FilterTabProcessing from "./credit-tab/TabProcessing/FilterTabProcessing";
import TableTabProcessing from "./credit-tab/TabProcessing/TableTabProcessing";

const errorSchemaRemove = Y.object({
  timeDate: Y.array().min(1, "กรุณาเลือกระยะเวลา"),
  tabFifth_reason: Y.string().max(50, "สามารถกรอกได้ไม่เกิน 500 ตัวอักษร"),
});

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  title: tw(`text-black font-bold text-3xl`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  filterRowContainer: tw(`flex flex-row flex-wrap items-end gap-3`),
  columnContainer: tw(`w-full flex flex-row overflow-x-auto mt-6 gap-x-3`),
};

const errorSchema = Y.object({});

const CreditFeed = () => {
  const { t } = useTranslation();
  const {
    tabIndex,
    onChangeTab,
    ////////////////// TabFarmer //////////////////
    searchTextTabFarmer,
    onSearchTextTabFarmer,
    onClearSearchTextTabFarmer,
    listFarmersCreditFeed,
    idFramers,
    setIdFramers,
    itemSelectedTabFarmer,
    setItemSelectedTabFarmer,
    formTabFarmer,
    setFormTabFarmer,
    onChangeFormTabFarmer,
    currentPageFarmersCreditFeed,
    setCurrentPageFarmersCreditFeed,
    isOpenApplyCreditModal,
    onOpenApplyCreditModal,
    onHideApplyCreditModal,
    ////////////////// TabPending //////////////////
    searchTextTabPending,
    onSearchTextTabPending,
    onClearSearchTextTabPending,
    listCreditFeedTabPending,
    idPending,
    setIdPending,
    itemSelectedTabPending,
    setItemSelectedTabPending,
    formTabPending,
    setFormTabPending,
    onChangeFormTabPending,
    currentPagePendingCreditFeed,
    setCurrentPagePendingCreditFeed,
    gradeCustomerInDataTabPending,
    statusCreditInDataTabPending,
    ////////////////// TabDenied //////////////////
    searchTextTabDenied,
    onSearchTextTabDenied,
    onClearSearchTextTabDenied,
    listCreditFeedTabDenied,
    idDenied,
    setIdDenied,
    itemSelectedTabDenied,
    setItemSelectedTabDenied,
    formTabDenied,
    setFormTabDenied,
    onChangeFormTabDenied,
    currentPageDeniedCreditFeed,
    setCurrentPageDeniedCreditFeed,
    gradeCustomerInDataTabDenied,
    statusCreditInDataTabDenied,
    ////////////////// TabProcessing //////////////////
    searchTextTabProcessing,
    onSearchTextTabProcessing,
    onClearSearchTextTabProcessing,
    idProcessing,
    setIdProcessing,
    listCreditFeedTabProcessing,
    itemSelectedTabProcessing,
    setItemSelectedTabProcessing,
    formTabProcessing,
    setFormTabProcessing,
    onChangeFormTabProcessing,
    currentPageProcessingCreditFeed,
    setCurrentPageProcessingCreditFeed,
    gradeCustomerInDataTabProcessing,
    ////////////////// TabHistory //////////////////
    searchTextTabHistory,
    onSearchTextTabHistory,
    onClearSearchTextTabHistory,
    listCreditFeedTabHistory,
    itemSelectedTabHistory,
    setItemSelectedTabHistory,
    formTabHistory,
    setFormTabHistory,
    onChangeFormTabHistory,
    currentPageHistoryCreditFeed,
    setCurrentPageHistoryCreditFeed,
    gradeCustomerInDataTabHistory,
    statusCreditInDataTabHistory,
    ////////////////// TabApprove //////////////////
    searchTextTabApprove,
    onSearchTextTabApprove,
    onClearSearchTextTabApprove,
    listCreditFeedTabApprove,
    itemSelectedTabApprove,
    setItemSelectedTabApprove,
    formTabApprove,
    setFormTabApprove,
    onChangeFormTabApprove,
    currentPageApproveCreditFeed,
    setCurrentPageApproveCreditFeed,
    gradeCustomerInDataTabApprove,
    statusCreditInDataTabApprove,
    ////////////////// CreditSetting //////////////////
    selectVersionCreditOption,
    businessTypeOption,
    typePersonalOptions,
    agePersonalOptions,
    typeLegalOptions,
    dropdownValuesGeneralFarmInformation,
    dropdownValuesFarmPerformance,
    dropdownValuesFarmFinancialStatusAndInstallment,
    dropdownValuesApplicationUsage,
    ////////////////// AllForm //////////////////
    FormGeneralPersonalInformation,
    setFormGeneralPersonalInformation,
    onChangeFormGeneralPersonalInformation,
    FormGeneralLegalInformation,
    setFormGeneralLegalInformation,
    onChangeFormGeneralLegalInformation,
    FormGeneralFarmInformation,
    setFormGeneralFarmInformation,
    onChangeFormGeneralFarmInformation,
    FormFarmPerformance,
    setFormFarmPerformance,
    onChangeFormFarmPerformance,
    FormFarmFinancialStatusAndInstallment,
    setFormFarmFinancialStatusAndInstallment,
    onChangeFormFarmFinancialStatusAndInstallment,
    FormApplicationUsage,
    setFormApplicationUsage,
    onChangeFormApplicationUsage,
    FormApproveCredit,
    setFormApproveCredit,
    onChangeFormApproveCredit,
    ////////////////// reFetchData //////////////////
    refetchListFarmersCreditFeed,
    refetchListCreditFeedTabPending,
    refetchListCreditFeedTabDenied,
    refetchListCreditFeedTabProcessing,
    refetchListCreditFeedTabHistory,
    refetchListCreditFeedTabApprove,
    ////////////////// OpenModalReasonTabPending //////////////////
    isOpenApproveTabPending,
    onOpenApproveTabPending,
    onHideApproveTabPending,
    isOpenRejectTabPending,
    onOpenRejectTabPending,
    onHideRejectTabPending,
    isOpenRejectWithOptionTabPending,
    onOpenRejectWithOptionTabPending,
    onHideRejectWithOptionTabPending,
    ////////////////// OpenModalReasonTabDenied //////////////////
    isOpenRejectWithOptionTabDenied,
    onOpenRejectWithOptionTabDenied,
    onHideRejectWithOptionTabDenied,
    isOpenReapplyCredit,
    onOpenReapplyCredit,
    onHideReapplyCredit,
    ////////////////// OpenModalImportTabPending //////////////////
    isOpenImportTabPending,
    onOpenImportTabPending,
    onHideImportTabPending,
    ////////////////// OpenModalReasonTabProcessing //////////////////
    isOpenRejectTabProcessing,
    onOpenRejectTabProcessing,
    onHideRejectTabProcessing,
    ////////////////// OpenModalTabProcessing //////////////////
    isOpenApproveTabProcessing,
    onOpenApproveTabProcessing,
    onHideApproveTabProcessing,
    ////////////////// Role //////////////////
    roleSale,
    roleSaleLead,
    roleLending,
    dataReapplyCredit,
    ////////////////// AllCountData //////////////////
    countDataFarmer,
    setCountDataFarmer,
    countDataPending,
    setCountDataPending,
    countDataDenied,
    setCountDataDenied,
    countDataProcessing,
    setCountDataProcessing,
    countDataHistory,
    setCountDataHistory,
    countDataApprove,
    setCountDataApprove,
    ////////////////// option //////////////////
    optionGrade,
    ////////////////// OrderByKey //////////////////
    currentOrderByKey1,
    setCurrentOrderByKey1,
    orderByType1,
    setOrderByType1,
    currentOrderByKey2,
    setCurrentOrderByKey2,
    orderByType2,
    setOrderByType2,
    currentOrderByKey3,
    setCurrentOrderByKey3,
    orderByType3,
    setOrderByType3,
    currentOrderByKey4,
    setCurrentOrderByKey4,
    orderByType4,
    setOrderByType4,
    currentOrderByKey5,
    setCurrentOrderByKey5,
    orderByType5,
    setOrderByType5,
    currentOrderByKey6,
    setCurrentOrderByKey6,
    orderByType6,
    setOrderByType6,
    ///////////////////
    isOpenRejectTabProcessingSale,
    onOpenRejectTabProcessingSale,
    onHideRejectTabProcessingSale,
  } = useCreditFeedController();

  const dispatch = useDispatch<AppDispatch>();
  const [responseDataCreditSetting, setResponseDataCreditSetting] =
    useState<ICreditInfoById>();

  const getCreditSettingByIdMutation = useMutation({
    mutationFn: getCreditSettingById,
    onSuccess: async (data) => {
      setTimeout(() => {
        setResponseDataCreditSetting(data.creditInfo);
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onGetCreditSetting = useCallback((id: string) => {
    getCreditSettingByIdMutation.mutate(id);
  }, []);

  const approveSaleLeadMutation = useMutation({
    mutationFn: roleSaleLead ? ApproveSaleLead : ApproveLending,
    onSuccess: async (data) => {
      refetchListCreditFeedTabPending();
      onCloseFnTabPending();
      setTimeout(() => {
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const approveLendingConsiderationMutation = useMutation({
    mutationFn: ApproveLendingConsideration,
    onSuccess: async (data) => {
      refetchListCreditFeedTabProcessing();
      refetchListCreditFeedTabApprove();
      onCloseFnTabProcessing();
      setTimeout(() => {
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const approveSaleForwardMutation = useMutation({
    mutationFn: ApproveSaleForward,
    onSuccess: async (data) => {
      refetchListCreditFeedTabPending();
      onCloseFnTabPending();
      setTimeout(() => {
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const DiscardCreditMutation = useMutation({
    mutationFn: DiscardCredit,
    onSuccess: async (data) => {
      refetchListCreditFeedTabDenied();
      onCloseFnTabDenied();
      setTimeout(() => {
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const uploadFileCreditMutation = useMutation({
    mutationFn: UploadFileCredit,
    onSuccess: async (data) => {
      refetchListCreditFeedTabPending();
      onCloseFnTabPending();
      setTimeout(() => {
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onApproveSaleForward = useCallback((ids: string) => {
    try {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการส่งให้ Lending",
          text: "คุณต้องการส่งให้ Lendingรายการนี้ใช่หรือไม่?",
          cancelText: "กลับไปแก้ไข",
          confirmText: t("common.button.confirm"),
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: () => {
            dispatch(alertAction.hide());
            dispatch(
              loadingActions.show({ message: "กำลังส่งให้ Lending..." })
            );
            approveSaleForwardMutation.mutate({
              id: ids,
              isApprove: true,
              detail: "ส่งให้ Lending",
              rejectReason: "",
            });
            setTimeout(() => {
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: "ส่งให้ Lending สำเร็จ",
                  type: "success",
                })
              );
            }, 500);
          },
        })
      );
    } catch (error) {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 500);
    }
  }, []);

  const onApproveLendingConsideration = useCallback((ids: string) => {
    try {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการอนุมัติ",
          text: "คุณต้องการอนุมัติรายการนี้ใช่หรือไม่?",
          cancelText: "กลับไปแก้ไข",
          confirmText: t("common.button.confirm"),
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: () => {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังอนุมัติ..." }));
            approveLendingConsiderationMutation.mutate({
              id: ids,
              isApprove: true,
              detail: "อนุมัติ",
            });
            setTimeout(() => {
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: "อนุมัติสำเร็จ",
                  type: "success",
                })
              );
            }, 500);
          },
        })
      );
    } catch (error) {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 500);
    }
  }, []);

  const onRejectLendingConsideration = useCallback(() => {
    dispatch(loadingActions.show({ message: "กำลังไม่อนุมัติ..." }));
    approveLendingConsiderationMutation.mutate({
      id: idProcessing,
      isApprove: false,
      detail: formTabFarmer.tabFifth_reason,
    });
    setTimeout(() => {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: "ไม่อนุมัติสำเร็จ",
          type: "success",
        })
      );
    }, 500);
  }, [formTabFarmer, idProcessing]);

  const {
    onSubmit: onRejectSaleForward,
    errors: errorsRemove,
    resetError: resetErrorRemove,
  } = useFormValidator({
    form: {
      ...formTabFarmer,
    },
    errorSchema: errorSchemaRemove,
    onValidateSuccess: () => {
      dispatch(loadingActions.show({ message: "กำลังไม่อนุมัติ..." }));
      approveSaleForwardMutation.mutate({
        id: idPending,
        isApprove: false,
        detail: formTabFarmer.tabFifth_reason,
        rejectReason: formTabFarmer.timeDate[0].value,
      });
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "ไม่อนุมัติสำเร็จ",
            type: "success",
          })
        );
      }, 500);
    },
  });

  // const onRejectSaleForward = useCallback(() => {
  //   dispatch(loadingActions.show({ message: "กำลังไม่อนุมัติ..." }));
  //   approveSaleForwardMutation.mutate({
  //     id: idPending,
  //     isApprove: false,
  //     detail: formTabFarmer.tabFifth_reason,
  //     rejectReason: formTabFarmer?.timeDate[0]?.value,
  //   });
  //   setTimeout(() => {
  //     dispatch(loadingActions.hide());
  //     dispatch(
  //       snakeActions.showMessage({
  //         message: "ไม่อนุมัติสำเร็จ",
  //         type: "success",
  //       })
  //     );
  //   }, 500);
  // }, [formTabFarmer, idPending]);

  const onDiscardCredit = useCallback(() => {
    dispatch(loadingActions.show({ message: "กำลังไม่อนุมัติ..." }));
    DiscardCreditMutation.mutate({
      id: idDenied,
      duration: formTabFarmer.timeDate[0].value,
      detail: formTabFarmer.tabFifth_reason,
    });
    setTimeout(() => {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: "ไม่อนุมัติสำเร็จ",
          type: "success",
        })
      );
    }, 500);
  }, [formTabFarmer, idDenied]);

  const onUploadFile = useCallback(() => {
    try {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการบันทึก",
          text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่? ",
          cancelText: "กลับไปแก้ไข",
          confirmText: t("common.button.confirm"),
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: async () => {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึกเอกสาร..." }));
            const citizenDocument = [];
            const creditBureauDocument = [];
            if (get(formTabFarmer, ["citizenDocument"]).length > 0) {
              const presign = await getUploadUrlCreateFeed(idPending);
              await axios.put(
                presign.blob.blobUrl,
                get(formTabFarmer, ["citizenDocument", 0]),
                {
                  responseType: "blob",
                  headers: {
                    "Content-Type": get(formTabFarmer, ["citizenDocument", 0])
                      .type,
                    "x-ms-blob-type": "BlockBlob",
                  },
                }
              );
              citizenDocument.push({
                id: presign.blob.fileId,
                fileName: get(formTabFarmer, ["citizenDocument", 0]).name,
                fileSize: get(formTabFarmer, ["citizenDocument", 0]).size,
              });
            }
            if (get(formTabFarmer, ["creditBureauDocument"]).length > 0) {
              const presign = await getUploadUrlCreateFeed(idPending);
              await axios.put(
                presign.blob.blobUrl,
                get(formTabFarmer, ["creditBureauDocument", 0]),
                {
                  responseType: "blob",
                  headers: {
                    "Content-Type": get(formTabFarmer, [
                      "creditBureauDocument",
                      0,
                    ]).type,
                    "x-ms-blob-type": "BlockBlob",
                  },
                }
              );
              creditBureauDocument.push({
                id: presign.blob.fileId,
                fileName: get(formTabFarmer, ["creditBureauDocument", 0]).name,
                fileSize: get(formTabFarmer, ["creditBureauDocument", 0]).size,
              });
            }
            await uploadFileCreditMutation.mutate({
              id: idPending,
              citizenDocument:
                get(formTabFarmer, ["citizenDocument"]).length === 0
                  ? get(formTabFarmer, ["citizenDocumentUpload"], [])
                  : citizenDocument,
              creditBureauDocument:
                get(formTabFarmer, ["creditBureauDocument"]).length === 0
                  ? get(formTabFarmer, ["creditBureauDocumentUpload"], [])
                  : creditBureauDocument,
            });
            setTimeout(() => {
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: "บันทึกสำเร็จ",
                  type: "success",
                })
              );
            }, 500);
          },
        })
      );
    } catch (error) {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 500);
    }
  }, [formTabFarmer, idPending]);

  const onRejectSaleLead = useCallback(() => {
    dispatch(loadingActions.show({ message: "กำลังไม่อนุมัติ..." }));
    approveSaleLeadMutation.mutate({
      id: idPending,
      isApprove: false,
      detail: formTabFarmer.tabFifth_reason,
    });
    setTimeout(() => {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: "ไม่อนุมัติสำเร็จ",
          type: "success",
        })
      );
    }, 500);
  }, [formTabFarmer, idPending]);

  const onApproveSaleLead = useCallback(() => {
    dispatch(loadingActions.show({ message: "กำลังอนุมัติ..." }));
    approveSaleLeadMutation.mutate({
      id: idPending,
      isApprove: true,
      detail: formTabFarmer.tabFifth_reason,
    });
    setTimeout(() => {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: "อนุมัติสำเร็จ",
          type: "success",
        })
      );
    }, 500);
  }, [formTabFarmer, idPending]);

  const onCloseFnTabPending = useCallback(() => {
    onHideRejectTabPending();
    onHideApproveTabPending();
    onHideImportTabPending();
    onHideRejectWithOptionTabPending();
    setFormTabFarmer(defaultFormTabFarmer);
  }, []);

  const onCloseFnTabDenied = useCallback(() => {
    onHideRejectWithOptionTabDenied();
    setFormTabFarmer(defaultFormTabFarmer);
  }, []);

  const onCloseFnTabProcessing = useCallback(() => {
    onHideRejectTabProcessing();
    onHideRejectTabProcessingSale();
    setFormTabFarmer(defaultFormTabFarmer);
  }, []);

  const rejectSaleTapProcessingMutation = useMutation({
    mutationFn: rejectFeed,
    onSuccess: async (data) => {
      refetchListCreditFeedTabProcessing();
      refetchListCreditFeedTabApprove();
      onCloseFnTabProcessing();
      setTimeout(() => {
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });
  const onRejectTapProcessing = useCallback(() => {
    dispatch(loadingActions.show({ message: "กำลังไม่อนุมัติ..." }));
    rejectSaleTapProcessingMutation.mutate({
      id: idProcessing,
      detail: formTabFarmer.tabFifth_reason,
      duration: formTabFarmer.timeDate[0].value,
    });
    setTimeout(() => {
      dispatch(loadingActions.hide());
      dispatch(
        snakeActions.showMessage({
          message: "ไม่อนุมัติสำเร็จ",
          type: "success",
        })
      );
    }, 500);
  }, [formTabFarmer, idProcessing]);

  const { action } = useAdminAuth();

  //* role action sale
  const SaleCreditFeedAllEligible = action?.creditFeedAllEligible === "edit";
  const SaleCreditFeedOnConsideration =
    action?.creditFeedOnConsideration === "edit";
  const SaleCreditFeedDenying = action?.creditFeedDenying === "edit";
  const SaleCreditFeedOnProcess = action?.creditFeedOnProcess === "edit";

  //*role action lending
  const LendingCreditFeedWaitingForApprove =
    action?.creditFeedWaitingForApprove === "edit";
  const LendingCreditFeedApproved = action?.creditFeedApproved === "edit";
  const LendingCreditFeedOnConsideration =
    action?.creditFeedOnConsideration === "edit";

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>
                {t("creditFeed.creditFeed")}
              </span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6">
              {roleSaleLead ? (
                <Tabs tabIndex={tabIndex} onChange={onChangeTab} isScroll>
                  <Tab
                    label={`${t(
                      "creditFeed.waitingToApprovalTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabPending, ["count"], 0)
                    )})`}
                  />
                  <Tab
                    label={`${t(
                      "creditFeed.approvedCreditTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabApprove, ["count"], 0)
                    )})`}
                  />

                  <Tab
                    label={`${t(
                      "creditFeed.creditHistoryTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabHistory, ["count"], 0)
                    )})`}
                  />
                </Tabs>
              ) : roleLending ? (
                <Tabs tabIndex={tabIndex} onChange={onChangeTab} isScroll>
                  <Tab
                    label={`${t(
                      "creditFeed.waitingToApprovalTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabPending, ["count"], 0)
                    )})`}
                  />
                  <Tab
                    label={`${t(
                      "creditFeed.waitingToConsiderTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabProcessing, ["count"], 0)
                    )})`}
                  />
                  <Tab
                    label={`${t(
                      "creditFeed.approvedCreditTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabApprove, ["count"], 0)
                    )})`}
                  />

                  <Tab
                    label={`${t(
                      "creditFeed.creditHistoryTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabHistory, ["count"], 0)
                    )})`}
                  />
                </Tabs>
              ) : (
                <Tabs tabIndex={tabIndex} onChange={onChangeTab} isScroll>
                  <Tab
                    label={`${t(
                      "creditFeed.qualifiedCustomerTab.title"
                    )} (${getCommaNumber(
                      get(listFarmersCreditFeed, ["count"], 0)
                    )})`}
                  />
                  <Tab
                    label={`${t(
                      "creditFeed.waitingToConsiderTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabPending, ["count"], 0)
                    )})`}
                  />
                  <Tab
                    label={`${t(
                      "creditFeed.unapprovedCreditTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabDenied, ["count"], 0)
                    )})`}
                  />
                  <Tab
                    label={`${t(
                      "creditFeed.makeContractTab.title"
                    )}  (${getCommaNumber(
                      get(listCreditFeedTabProcessing, ["count"], 0)
                    )})`}
                  />
                  <Tab
                    label={`${t(
                      "creditFeed.creditHistoryTab.title"
                    )} (${getCommaNumber(
                      get(listCreditFeedTabHistory, ["count"], 0)
                    )})`}
                  />
                </Tabs>
              )}
            </div>
            {roleSaleLead ? (
              <>
                {tabIndex === 0 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabPending
                      searchText={searchTextTabPending}
                      onSearchText={onSearchTextTabPending}
                      onClearSearchText={onClearSearchTextTabPending}
                      itemSelected={itemSelectedTabPending}
                      form={formTabPending}
                      setForm={setFormTabPending}
                      onChangeForm={onChangeFormTabPending}
                      gradeCustomerInDataTabPending={optionGrade}
                      statusCreditInDataTabPending={
                        statusCreditInDataTabPending
                      }
                      setCurrentPage={setCurrentPagePendingCreditFeed}
                    />
                    <TableTabPending
                      data={listCreditFeedTabPending?.creditFeeds || []}
                      itemSelected={itemSelectedTabPending}
                      setItemSelected={setItemSelectedTabPending}
                      currentPage={currentPagePendingCreditFeed}
                      setCurrentPage={setCurrentPagePendingCreditFeed}
                      roleSaleLead={roleSaleLead}
                      onOpenReject={onOpenRejectTabPending}
                      onOpenApproved={onOpenApproveTabPending}
                      idPending={idPending}
                      setIdPending={setIdPending}
                      countData={countDataPending}
                      currentOrderByKey={currentOrderByKey2}
                      setCurrentOrderByKey={setCurrentOrderByKey2}
                      orderByType={orderByType2}
                      setOrderByType={setOrderByType2}
                      isEdit={LendingCreditFeedWaitingForApprove}
                    />
                  </div>
                )}
                {tabIndex === 1 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabApprove
                      searchText={searchTextTabApprove}
                      onSearchText={onSearchTextTabApprove}
                      onClearSearchText={onClearSearchTextTabApprove}
                      itemSelected={itemSelectedTabApprove}
                      form={formTabApprove}
                      setForm={setFormTabApprove}
                      onChangeForm={onChangeFormTabApprove}
                      gradeCustomerInDataTabApprove={optionGrade}
                      statusCreditInDataTabApprove={
                        statusCreditInDataTabApprove
                      }
                      setCurrentPage={setCurrentPageApproveCreditFeed}
                    />
                    <TableTabApprove
                      data={listCreditFeedTabApprove?.creditFeeds || []}
                      itemSelected={itemSelectedTabApprove}
                      setItemSelected={setItemSelectedTabApprove}
                      currentPage={currentPageApproveCreditFeed}
                      setCurrentPage={setCurrentPageApproveCreditFeed}
                      countData={countDataApprove}
                      currentOrderByKey={currentOrderByKey6}
                      setCurrentOrderByKey={setCurrentOrderByKey6}
                      orderByType={orderByType6}
                      setOrderByType={setOrderByType6}
                      isEdit={LendingCreditFeedApproved}
                    />
                  </div>
                )}
                {tabIndex === 2 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabHistory
                      searchText={searchTextTabHistory}
                      onSearchText={onSearchTextTabHistory}
                      onClearSearchText={onClearSearchTextTabHistory}
                      itemSelected={itemSelectedTabHistory}
                      form={formTabHistory}
                      setForm={setFormTabHistory}
                      onChangeForm={onChangeFormTabHistory}
                      gradeCustomerInDataTabHistory={optionGrade}
                      statusCreditInDataTabHistory={
                        statusCreditInDataTabHistory
                      }
                      setCurrentPage={setCurrentPageHistoryCreditFeed}
                    />
                    <TableTabHistory
                      data={listCreditFeedTabHistory?.creditFeeds || []}
                      itemSelected={itemSelectedTabHistory}
                      setItemSelected={setItemSelectedTabHistory}
                      currentPage={currentPageHistoryCreditFeed}
                      setCurrentPage={setCurrentPageHistoryCreditFeed}
                      countData={countDataHistory}
                      currentOrderByKey={currentOrderByKey5}
                      setCurrentOrderByKey={setCurrentOrderByKey5}
                      orderByType={orderByType5}
                      setOrderByType={setOrderByType5}
                    />
                  </div>
                )}
              </>
            ) : roleLending ? (
              <>
                {tabIndex === 0 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabPending
                      searchText={searchTextTabPending}
                      onSearchText={onSearchTextTabPending}
                      onClearSearchText={onClearSearchTextTabPending}
                      itemSelected={itemSelectedTabPending}
                      form={formTabPending}
                      setForm={setFormTabPending}
                      onChangeForm={onChangeFormTabPending}
                      gradeCustomerInDataTabPending={optionGrade}
                      statusCreditInDataTabPending={
                        statusCreditInDataTabPending
                      }
                      setCurrentPage={setCurrentPagePendingCreditFeed}
                    />
                    <TableTabPending
                      data={listCreditFeedTabPending?.creditFeeds || []}
                      itemSelected={itemSelectedTabPending}
                      setItemSelected={setItemSelectedTabPending}
                      currentPage={currentPagePendingCreditFeed}
                      setCurrentPage={setCurrentPagePendingCreditFeed}
                      roleSaleLead={roleLending}
                      onOpenReject={onOpenRejectTabPending}
                      onOpenApproved={onOpenApproveTabPending}
                      idPending={idPending}
                      setIdPending={setIdPending}
                      countData={countDataPending}
                      currentOrderByKey={currentOrderByKey2}
                      setCurrentOrderByKey={setCurrentOrderByKey2}
                      orderByType={orderByType2}
                      setOrderByType={setOrderByType2}
                      isEdit={LendingCreditFeedWaitingForApprove}
                    />
                  </div>
                )}
                {tabIndex === 1 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabProcessing
                      searchText={searchTextTabProcessing}
                      onSearchText={onSearchTextTabProcessing}
                      onClearSearchText={onClearSearchTextTabProcessing}
                      itemSelected={itemSelectedTabProcessing}
                      form={formTabProcessing}
                      setForm={setFormTabProcessing}
                      onChangeForm={onChangeFormTabProcessing}
                      gradeCustomerInDataTabProcessing={optionGrade}
                      setCurrentPage={setCurrentPageProcessingCreditFeed}
                    />
                    <TableTabProcessing
                      data={listCreditFeedTabProcessing?.creditFeeds || []}
                      itemSelected={itemSelectedTabProcessing}
                      setItemSelected={setItemSelectedTabProcessing}
                      currentPage={currentPageProcessingCreditFeed}
                      setCurrentPage={setCurrentPageProcessingCreditFeed}
                      roleSaleLead={roleLending}
                      idProcessing={idProcessing}
                      setIdProcessing={setIdProcessing}
                      onOpenReject={onOpenRejectTabProcessing}
                      onOpenApproved={(id) => onApproveLendingConsideration(id)}
                      countData={countDataProcessing}
                      currentOrderByKey={currentOrderByKey4}
                      setCurrentOrderByKey={setCurrentOrderByKey4}
                      orderByType={orderByType4}
                      setOrderByType={setOrderByType4}
                      isEdit={LendingCreditFeedOnConsideration}
                    />
                  </div>
                )}
                {tabIndex === 2 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabApprove
                      searchText={searchTextTabApprove}
                      onSearchText={onSearchTextTabApprove}
                      onClearSearchText={onClearSearchTextTabApprove}
                      itemSelected={itemSelectedTabApprove}
                      form={formTabApprove}
                      setForm={setFormTabApprove}
                      onChangeForm={onChangeFormTabApprove}
                      gradeCustomerInDataTabApprove={optionGrade}
                      statusCreditInDataTabApprove={
                        statusCreditInDataTabApprove
                      }
                      setCurrentPage={setCurrentPageApproveCreditFeed}
                    />
                    <TableTabApprove
                      data={listCreditFeedTabApprove?.creditFeeds || []}
                      itemSelected={itemSelectedTabApprove}
                      setItemSelected={setItemSelectedTabApprove}
                      currentPage={currentPageApproveCreditFeed}
                      setCurrentPage={setCurrentPageApproveCreditFeed}
                      countData={countDataApprove}
                      currentOrderByKey={currentOrderByKey6}
                      setCurrentOrderByKey={setCurrentOrderByKey6}
                      orderByType={orderByType6}
                      setOrderByType={setOrderByType6}
                      isEdit={LendingCreditFeedApproved}
                    />
                  </div>
                )}
                {tabIndex === 3 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabHistory
                      searchText={searchTextTabHistory}
                      onSearchText={onSearchTextTabHistory}
                      onClearSearchText={onClearSearchTextTabHistory}
                      itemSelected={itemSelectedTabHistory}
                      form={formTabHistory}
                      setForm={setFormTabHistory}
                      onChangeForm={onChangeFormTabHistory}
                      gradeCustomerInDataTabHistory={optionGrade}
                      statusCreditInDataTabHistory={
                        statusCreditInDataTabHistory
                      }
                      setCurrentPage={setCurrentPageHistoryCreditFeed}
                    />
                    <TableTabHistory
                      data={listCreditFeedTabHistory?.creditFeeds || []}
                      itemSelected={itemSelectedTabHistory}
                      setItemSelected={setItemSelectedTabHistory}
                      currentPage={currentPageHistoryCreditFeed}
                      setCurrentPage={setCurrentPageHistoryCreditFeed}
                      countData={countDataHistory}
                      currentOrderByKey={currentOrderByKey5}
                      setCurrentOrderByKey={setCurrentOrderByKey5}
                      orderByType={orderByType5}
                      setOrderByType={setOrderByType5}
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {tabIndex === 0 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabFarmer
                      searchText={searchTextTabFarmer}
                      onSearchText={onSearchTextTabFarmer}
                      onClearSearchText={onClearSearchTextTabFarmer}
                      itemSelected={itemSelectedTabFarmer}
                    />
                    <TableTabFarmer
                      data={listFarmersCreditFeed?.farmers || []}
                      itemSelected={itemSelectedTabFarmer}
                      setItemSelected={setItemSelectedTabFarmer}
                      currentPage={currentPageFarmersCreditFeed}
                      setCurrentPage={setCurrentPageFarmersCreditFeed}
                      onOpenApplyCredit={onOpenApplyCreditModal}
                      idFramers={idFramers}
                      setIdFramers={setIdFramers}
                      onGetCreditSetting={onGetCreditSetting}
                      countData={countDataFarmer}
                      currentOrderByKey={currentOrderByKey1}
                      setCurrentOrderByKey={setCurrentOrderByKey1}
                      orderByType={orderByType1}
                      setOrderByType={setOrderByType1}
                      isEdit={SaleCreditFeedAllEligible}
                    />
                  </div>
                )}
                {tabIndex === 1 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabPending
                      searchText={searchTextTabPending}
                      onSearchText={onSearchTextTabPending}
                      onClearSearchText={onClearSearchTextTabPending}
                      itemSelected={itemSelectedTabPending}
                      form={formTabPending}
                      setForm={setFormTabPending}
                      onChangeForm={onChangeFormTabPending}
                      gradeCustomerInDataTabPending={optionGrade}
                      statusCreditInDataTabPending={
                        statusCreditInDataTabPending
                      }
                      setCurrentPage={setCurrentPagePendingCreditFeed}
                    />
                    <TableTabPending
                      data={listCreditFeedTabPending?.creditFeeds || []}
                      itemSelected={itemSelectedTabPending}
                      setItemSelected={setItemSelectedTabPending}
                      currentPage={currentPagePendingCreditFeed}
                      setCurrentPage={setCurrentPagePendingCreditFeed}
                      idPending={idPending}
                      setIdPending={setIdPending}
                      onOpenImport={onOpenImportTabPending}
                      onSummit={(id) => onApproveSaleForward(id)}
                      onOpenReject={onOpenRejectWithOptionTabPending}
                      countData={countDataPending}
                      currentOrderByKey={currentOrderByKey2}
                      setCurrentOrderByKey={setCurrentOrderByKey2}
                      orderByType={orderByType2}
                      setOrderByType={setOrderByType2}
                      isEdit={SaleCreditFeedOnConsideration}
                    />
                  </div>
                )}
                {tabIndex === 2 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabDenied
                      searchText={searchTextTabDenied}
                      onSearchText={onSearchTextTabDenied}
                      onClearSearchText={onClearSearchTextTabDenied}
                      itemSelected={itemSelectedTabDenied}
                      form={formTabDenied}
                      setForm={setFormTabDenied}
                      onChangeForm={onChangeFormTabDenied}
                      gradeCustomerInDataTabDenied={optionGrade}
                      statusCreditInDataTabDenied={statusCreditInDataTabDenied}
                      setCurrentPage={setCurrentPageDeniedCreditFeed}
                    />
                    <TableTabDenied
                      data={listCreditFeedTabDenied?.creditFeeds || []}
                      itemSelected={itemSelectedTabDenied}
                      setItemSelected={setItemSelectedTabDenied}
                      currentPage={currentPageDeniedCreditFeed}
                      setCurrentPage={setCurrentPageDeniedCreditFeed}
                      onOpenReject={onOpenRejectWithOptionTabDenied}
                      onOpenApproved={onOpenReapplyCredit}
                      idDenied={idDenied}
                      setIdDenied={setIdDenied}
                      countData={countDataDenied}
                      currentOrderByKey={currentOrderByKey3}
                      setCurrentOrderByKey={setCurrentOrderByKey3}
                      orderByType={orderByType3}
                      setOrderByType={setOrderByType3}
                      isEdit={SaleCreditFeedDenying}
                    />
                  </div>
                )}
                {tabIndex === 3 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabProcessing
                      searchText={searchTextTabProcessing}
                      onSearchText={onSearchTextTabProcessing}
                      onClearSearchText={onClearSearchTextTabProcessing}
                      itemSelected={itemSelectedTabProcessing}
                      form={formTabProcessing}
                      setForm={setFormTabProcessing}
                      onChangeForm={onChangeFormTabProcessing}
                      gradeCustomerInDataTabProcessing={optionGrade}
                      setCurrentPage={setCurrentPageProcessingCreditFeed}
                    />
                    <TableTabProcessing
                      data={listCreditFeedTabProcessing?.creditFeeds || []}
                      itemSelected={itemSelectedTabProcessing}
                      setItemSelected={setItemSelectedTabProcessing}
                      currentPage={currentPageProcessingCreditFeed}
                      setCurrentPage={setCurrentPageProcessingCreditFeed}
                      idProcessing={idProcessing}
                      setIdProcessing={setIdProcessing}
                      onOpenApproved={onOpenApproveTabProcessing}
                      countData={countDataProcessing}
                      currentOrderByKey={currentOrderByKey4}
                      setCurrentOrderByKey={setCurrentOrderByKey4}
                      orderByType={orderByType4}
                      setOrderByType={setOrderByType4}
                      onOpenReject={onOpenRejectTabProcessingSale}
                      isEdit={SaleCreditFeedOnProcess}
                    />
                  </div>
                )}
                {tabIndex === 4 && (
                  <div className="flex flex-col gap-6 pt-6">
                    <FilterTabHistory
                      searchText={searchTextTabHistory}
                      onSearchText={onSearchTextTabHistory}
                      onClearSearchText={onClearSearchTextTabHistory}
                      itemSelected={itemSelectedTabHistory}
                      form={formTabHistory}
                      setForm={setFormTabHistory}
                      onChangeForm={onChangeFormTabHistory}
                      gradeCustomerInDataTabHistory={optionGrade}
                      statusCreditInDataTabHistory={
                        statusCreditInDataTabHistory
                      }
                      setCurrentPage={setCurrentPageHistoryCreditFeed}
                    />
                    <TableTabHistory
                      data={listCreditFeedTabHistory?.creditFeeds || []}
                      itemSelected={itemSelectedTabHistory}
                      setItemSelected={setItemSelectedTabHistory}
                      currentPage={currentPageHistoryCreditFeed}
                      setCurrentPage={setCurrentPageHistoryCreditFeed}
                      countData={countDataHistory}
                      currentOrderByKey={currentOrderByKey5}
                      setCurrentOrderByKey={setCurrentOrderByKey5}
                      orderByType={orderByType5}
                      setOrderByType={setOrderByType5}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <ModalApplyCredit
            isOpen={isOpenApplyCreditModal}
            onClose={onHideApplyCreditModal}
            form={formTabFarmer}
            setForm={setFormTabFarmer}
            onChangeForm={onChangeFormTabFarmer}
            selectVersionCreditOption={selectVersionCreditOption}
            businessTypeOption={businessTypeOption}
            typePersonalOptions={typePersonalOptions}
            agePersonalOptions={agePersonalOptions}
            typeLegalOptions={typeLegalOptions}
            dropdownValuesGeneralFarmInformation={
              dropdownValuesGeneralFarmInformation
            }
            dropdownValuesFarmPerformance={dropdownValuesFarmPerformance}
            dropdownValuesFarmFinancialStatusAndInstallment={
              dropdownValuesFarmFinancialStatusAndInstallment
            }
            dropdownValuesApplicationUsage={dropdownValuesApplicationUsage}
            FormGeneralPersonalInformation={FormGeneralPersonalInformation}
            setFormGeneralPersonalInformation={
              setFormGeneralPersonalInformation
            }
            onChangeFormGeneralPersonalInformation={
              onChangeFormGeneralPersonalInformation
            }
            FormGeneralLegalInformation={FormGeneralLegalInformation}
            setFormGeneralLegalInformation={setFormGeneralLegalInformation}
            onChangeFormGeneralLegalInformation={
              onChangeFormGeneralLegalInformation
            }
            FormGeneralFarmInformation={FormGeneralFarmInformation}
            setFormGeneralFarmInformation={setFormGeneralFarmInformation}
            onChangeFormGeneralFarmInformation={
              onChangeFormGeneralFarmInformation
            }
            FormFarmPerformance={FormFarmPerformance}
            setFormFarmPerformance={setFormFarmPerformance}
            onChangeFormFarmPerformance={onChangeFormFarmPerformance}
            FormFarmFinancialStatusAndInstallment={
              FormFarmFinancialStatusAndInstallment
            }
            setFormFarmFinancialStatusAndInstallment={
              setFormFarmFinancialStatusAndInstallment
            }
            onChangeFormFarmFinancialStatusAndInstallment={
              onChangeFormFarmFinancialStatusAndInstallment
            }
            FormApplicationUsage={FormApplicationUsage}
            setFormApplicationUsage={setFormApplicationUsage}
            onChangeFormApplicationUsage={onChangeFormApplicationUsage}
            idFramers={idFramers}
            setIdFramers={setIdFramers}
            refetchListFarmersCreditFeed={refetchListFarmersCreditFeed}
            refetchListCreditFeedTabPending={refetchListCreditFeedTabPending}
            refetchListCreditFeedTabDenied={refetchListCreditFeedTabDenied}
            refetchListCreditFeedTabProcessing={
              refetchListCreditFeedTabProcessing
            }
            listCreditSettingById={responseDataCreditSetting}
            isCredit
          />
          <ModalReason
            isOpen={isOpenRejectTabPending}
            onSubmit={onRejectSaleLead}
            onClose={onHideRejectTabPending}
            form={formTabFarmer}
            setForm={setFormTabFarmer}
            onChangeForm={onChangeFormTabFarmer}
            header={"ไม่อนุมัติเครดิต"}
            title={"รายละเอียด"}
          />
          <ModalReason
            isOpen={isOpenApproveTabPending}
            onSubmit={onApproveSaleLead}
            onClose={onHideApproveTabPending}
            form={formTabFarmer}
            setForm={setFormTabFarmer}
            onChangeForm={onChangeFormTabFarmer}
            header={"อนุมัติเครดิต"}
            title={"เหตุผล"}
          />
          <ModalReason
            isOpen={isOpenRejectWithOptionTabPending}
            onSubmit={onRejectSaleForward}
            onClose={onHideRejectWithOptionTabPending}
            form={formTabFarmer}
            setForm={setFormTabFarmer}
            onChangeForm={onChangeFormTabFarmer}
            header={"ไม่อนุมัติเครดิต"}
            title={"รายละเอียด"}
            isOpenSelectTime
            errorText={errorsRemove}
            resetError={resetErrorRemove}
          />
          <ModalReason
            isOpen={isOpenRejectWithOptionTabDenied}
            onSubmit={onDiscardCredit}
            onClose={onHideRejectWithOptionTabDenied}
            form={formTabFarmer}
            setForm={setFormTabFarmer}
            onChangeForm={onChangeFormTabFarmer}
            header={"ไม่อนุมัติเครดิต"}
            title={"รายละเอียด"}
            isOpenSelectTime
          />
          <ModalImportDocument
            data={listCreditFeedTabPending?.creditFeeds.find(
              (i) => i.id === idPending
            )}
            isOpen={isOpenImportTabPending}
            onSubmit={onUploadFile}
            onClose={onHideImportTabPending}
            form={formTabFarmer}
            setForm={setFormTabFarmer}
            onChangeForm={onChangeFormTabFarmer}
          />
          <ModalApplyCredit
            isOpen={isOpenReapplyCredit}
            onClose={onHideReapplyCredit}
            form={formTabFarmer}
            setForm={setFormTabFarmer}
            onChangeForm={onChangeFormTabFarmer}
            selectVersionCreditOption={selectVersionCreditOption}
            businessTypeOption={businessTypeOption}
            typePersonalOptions={typePersonalOptions}
            agePersonalOptions={agePersonalOptions}
            typeLegalOptions={typeLegalOptions}
            dropdownValuesGeneralFarmInformation={
              dropdownValuesGeneralFarmInformation
            }
            dropdownValuesFarmPerformance={dropdownValuesFarmPerformance}
            dropdownValuesFarmFinancialStatusAndInstallment={
              dropdownValuesFarmFinancialStatusAndInstallment
            }
            dropdownValuesApplicationUsage={dropdownValuesApplicationUsage}
            FormGeneralPersonalInformation={FormGeneralPersonalInformation}
            setFormGeneralPersonalInformation={
              setFormGeneralPersonalInformation
            }
            onChangeFormGeneralPersonalInformation={
              onChangeFormGeneralPersonalInformation
            }
            FormGeneralLegalInformation={FormGeneralLegalInformation}
            setFormGeneralLegalInformation={setFormGeneralLegalInformation}
            onChangeFormGeneralLegalInformation={
              onChangeFormGeneralLegalInformation
            }
            FormGeneralFarmInformation={FormGeneralFarmInformation}
            setFormGeneralFarmInformation={setFormGeneralFarmInformation}
            onChangeFormGeneralFarmInformation={
              onChangeFormGeneralFarmInformation
            }
            FormFarmPerformance={FormFarmPerformance}
            setFormFarmPerformance={setFormFarmPerformance}
            onChangeFormFarmPerformance={onChangeFormFarmPerformance}
            FormFarmFinancialStatusAndInstallment={
              FormFarmFinancialStatusAndInstallment
            }
            setFormFarmFinancialStatusAndInstallment={
              setFormFarmFinancialStatusAndInstallment
            }
            onChangeFormFarmFinancialStatusAndInstallment={
              onChangeFormFarmFinancialStatusAndInstallment
            }
            FormApplicationUsage={FormApplicationUsage}
            setFormApplicationUsage={setFormApplicationUsage}
            onChangeFormApplicationUsage={onChangeFormApplicationUsage}
            idFramers={idDenied}
            setIdFramers={setIdDenied}
            refetchListFarmersCreditFeed={refetchListFarmersCreditFeed}
            refetchListCreditFeedTabPending={refetchListCreditFeedTabPending}
            refetchListCreditFeedTabDenied={refetchListCreditFeedTabDenied}
            refetchListCreditFeedTabProcessing={
              refetchListCreditFeedTabProcessing
            }
            isEdit
            dataEdit={dataReapplyCredit}
            isCredit
          />
          <ModalReason
            isOpen={isOpenRejectTabProcessing}
            onSubmit={onRejectLendingConsideration}
            onClose={onHideRejectTabProcessing}
            form={formTabFarmer}
            setForm={setFormTabFarmer}
            onChangeForm={onChangeFormTabFarmer}
            header={"ไม่อนุมัติเครดิต"}
            title={"รายละเอียด"}
          />
          <ModalReason
            isOpen={isOpenRejectTabProcessingSale}
            onSubmit={onRejectTapProcessing}
            onClose={onHideRejectTabProcessingSale}
            form={formTabFarmer}
            setForm={setFormTabFarmer}
            onChangeForm={onChangeFormTabFarmer}
            header={"ไม่อนุมัติเครดิต"}
            title={"รายละเอียด"}
            isOpenSelectTime
          />
          <ModalApproveCredit
            isOpen={isOpenApproveTabProcessing}
            onClose={onHideApproveTabProcessing}
            data={listCreditFeedTabProcessing?.creditFeeds.find(
              (i) => i.id === idProcessing
            )}
            form={FormApproveCredit}
            setForm={setFormApproveCredit}
            onChangeForm={onChangeFormApproveCredit}
            refetchListCreditFeedTabProcessing={
              refetchListCreditFeedTabProcessing
            }
            refetchListCreditFeedTabHistory={refetchListCreditFeedTabHistory}
          />
        </div>
      </MenuBar>
    </>
  );
};

export default CreditFeed;
