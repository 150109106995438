import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { SvgIcon } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";
import { tw } from "../../../../utils/tw";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import { TUseRevenueFilterHook } from "../hooks/useRevenueFilter.hook";
import { useTranslation } from "react-i18next";

const classes = {
  flexRow: tw(`flex flex-row`),
  flexCol: tw(`flex flex-col`),
  flex: tw(`flex`),
  fullScreen: tw(`w-full h-full`),
  label: tw(`text-sm font-medium font-sans`),
};

type Props = {
  itemSelected: string[];
  onSearch: () => void;
  searchRevenueFilter: TUseRevenueFilterHook;
  onExport: () => void;
};

const RevenueFilter = ({
  itemSelected,
  onSearch,
  searchRevenueFilter,
  onExport,
}: Props) => {
  const { t } = useTranslation();
  const [toggleClear, setToggleClear] = useState<boolean>(false);
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchRevenueFilter.setPage(1);
    onSearch();
  };

  useEffect(() => {
    onSearch();
  }, [toggleClear]);
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.fullScreen}>
        <div className={tw(classes.flexRow, "w-full justify-between pt-3")}>
          <div className="flex w-full max-w-[656px]">
            <div className="w-full max-w-[470px] mr-4">
              <TextInputSearchCustom
                value={searchRevenueFilter.searchText}
                placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
                onChange={(event) =>
                  searchRevenueFilter.setSearchText(event.target.value)
                }
                InputProps={{
                  endAdornment:
                    searchRevenueFilter.searchText.length > 0 ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={(_) => {
                          setToggleClear((prev) => !prev);
                          searchRevenueFilter.setSearchText("");
                        }}
                      />
                    ) : null,
                }}
              />
            </div>
            {itemSelected.length > 0 && (
              <div className="w-full max-w-[170px] flex items-center font-sans text-[14px] text-[#646464]">
                {t("common.checked", { number: itemSelected.length })}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <ButtonOutlinedCustom
              title={t("common.download")}
              disabled={itemSelected.length === 0}
              onClick={onExport}
              startIcon={
                <SvgIcon
                  sx={{
                    fontSize: "14px !important",
                    color: "currentColor",
                    marginBottom: "3px",
                  }}
                  fontSize="small"
                >
                  <DownloadSvg />
                </SvgIcon>
              }
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default RevenueFilter;
