import EventNoteIcon from "@mui/icons-material/EventNote";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkUserRole } from "../../../utils/check-user-role";
import { useAuth } from "../ProtectedRoute/ProtectedRoute";
import SelectFarmModal from "./SelectFarmModal";

const Footer = ({ selectedTab = 0, handleChange, farmId }: any) => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [userRole, setUserRole] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  const onNavigate = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    []
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    //* set user Role
    const currentUser = checkUserRole(user.userRole, user.staffType);
    setUserRole(currentUser);
  }, [user]);

  return (
    <>
      <div className="fixed bottom-0 left-0 right-0 z-50  bg-[#FFFFFF] shadow-2xl">
        <Tabs
          value={value}
          onChange={handleTabChange}
          variant="fullWidth"
          className="flex justify-around !items-center"
          sx={{
            "& .MuiTabs-indicator": {
              top: 0,
              bottom: "auto",
              backgroundColor: "#68C184 !important",
            },
            "& .MuiTab-root": {
              minWidth: "0px",
              flex: 1,
              maxWidth: "none",
              padding: "16px 0",
              "& .Mui-selected": {
                color: "#68C184 !important",
              },
            },
          }}
        >
          <Tab
            icon={
              <div
                onClick={onNavigate("/farms")}
                className={`flex flex-col items-center relative ${
                  value === 0 ? "text-[#68C184]" : "text-[#7E7E7E]"
                }`}
              >
                <HomeIcon />
                <span className="text-xs mt-1">หน้าแรก</span>
              </div>
            }
          />
          <Tab
            icon={
              <div
                onClick={onNavigate("/farms/farm-community")}
                className={`flex flex-col items-center relative ${
                  value === 1 ? "text-[#68C184]" : "text-[#7E7E7E]"
                }`}
              >
                <PeopleIcon />
                <span className="text-xs mt-1">ชุมชน</span>
              </div>
            }
          />
          {["farmer", "manager"].includes(userRole) && (
            <Tab
              icon={
                <div
                  onClick={onNavigate("/daily-log")}
                  className={`flex flex-col items-center relative ${
                    value === 2 ? "text-[#68C184]" : "text-[#7E7E7E]"
                  }`}
                >
                  <EventNoteIcon />
                  <span className="text-xs mt-1">บันทึก</span>
                </div>
              }
            />
          )}
          {["farmer", "manager"].includes(userRole) && (
            <Tab
              icon={
                <div
                  onClick={
                    farmId
                      ? onNavigate("/feed-order-and-delivery/" + farmId)
                      : () => setIsOpen(true)
                  }
                  className={`flex flex-col items-center relative ${
                    value === 3 ? "text-[#68C184]" : "text-[#7E7E7E]"
                  }`}
                >
                  <ShoppingCartIcon />
                  <span className="text-xs mt-1">สั่งสินค้า</span>
                </div>
              }
            />
          )}
          <Tab
            icon={
              <div
                onClick={onNavigate("/farms/account-center")}
                className={`flex flex-col items-center relative ${
                  value === 4 ? "text-[#68C184]" : "text-[#7E7E7E]"
                }`}
              >
                <PersonIcon />
                <span className="text-xs mt-1">โปรไฟล์</span>
              </div>
            }
          />
        </Tabs>
      </div>
      <SelectFarmModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  );
};
export default Footer;
