import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../const/app.constant";
import { thousandComma } from "../../../../utils/common/string";
import { ITarget } from "../types/SaleTargetTable.type";
import DetailSaleTarget from "./DetailSaleTarget";

type Props = {
  targets?: ITarget[];
};

const unitEnum: Record<string, string> = {
  people: "คน",
  currency_baht: "บาท",
  ton: "ตัน",
};

const TargetHistoryTabs = ({ targets }: Props) => {
  const [isOpenDetailModal, setIsOpenDetail] = useState(false);
  const [target, setTarget] = useState<ITarget>();
  return (
    <>
      <TableContainer
        sx={{
          scrollBehavior: "smooth",
          overflow: "auto",
          marginTop: "24px",
          border: "1px solid #E1E1E1",
        }}
      >
        <Table
          sx={{
            width: "100%",
          }}
          aria-labelledby="table-show-data"
          size={"medium"}
        >
          <TableHead
            sx={{
              bgcolor: "#FBFBFB",
              borderBottom: "1px solid #E1E1E1",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                  width: "200px",
                }}
              >
                ปี
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                  width: "200px",
                }}
                align="left"
              >
                จำนวน
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                  width: "200px",
                }}
                align="left"
              >
                หน่วย
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                  width: "200px",
                }}
                align="left"
              >
                วันที่อัปเดตล่าสุด
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                  width: "200px",
                }}
                align="left"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(targets || []).map((target, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{target.year}</TableCell>
                <TableCell align="left">
                  {thousandComma(target.totalAmount)}
                </TableCell>
                <TableCell align="left">{unitEnum[target.unit]}</TableCell>
                <TableCell align="left">
                  {target.updatedAt
                    ? dayjs(target.updatedAt).format(DATE_FORMAT_DATE_PICKER2)
                    : "-"}
                </TableCell>
                <TableCell align="left">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      setIsOpenDetail(true);
                      setTarget(target);
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DetailSaleTarget
        isOpen={isOpenDetailModal}
        handleClose={() => setIsOpenDetail(false)}
        data={target}
      />
    </>
  );
};

export default TargetHistoryTabs;
