import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { IGetPigPriceRow } from "../../../types/farmCommunity";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { commonDownloadFile } from "../../../../../../services/CommonApiService";
import ListOfUploadedFile2 from "../../../../../../components/common/File/ListOfUploadedFile2";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  data?: IGetPigPriceRow;
};

const ViewPigPriceModal = ({ isOpen, handleClose, data }: Props) => {
  const dispatch = useDispatch();

  const onDownload = async (row: { id: string; fileOriginalName: string }) => {
    dispatch(loadingActions.show({ message: "กำลังดาวน์โหลดภาพ..." }));
    await commonDownloadFile(
      `/farm-community/pig-price/download/${row.id}`,
      row.fileOriginalName
    );
    dispatch(loadingActions.hide());
  };

  const formatPriceRange = (
    lowestPrice: number | undefined,
    highestPrice: number | undefined
  ) =>
    lowestPrice === highestPrice
      ? `${lowestPrice}`
      : `${lowestPrice} - ${highestPrice}`;

  return (
    <>
      <AdminModal
        label="รายละเอียด"
        isOpen={isOpen}
        size="sm"
        handleClose={handleClose}
        children={
          <>
            <div className="my-4">
              <h3 className="text-[14px] mb-3 font-sans font-bold">
                ชื่อหัวข้อ
              </h3>
              <div className="text-[14px] mb-3 font-sans">
                {data?.topicName}
              </div>
            </div>

            <div className="table-container">
              <TableContainer
                sx={{
                  scrollBehavior: "smooth",
                  overflow: "auto",
                  border: "1px solid #E1E1E1",
                  borderRadius: "8px",
                }}
              >
                <Table
                  sx={{
                    width: "100%",
                    borderCollapse: "collapse",
                  }}
                  aria-labelledby="table-show-data"
                  size="medium"
                >
                  <TableHead sx={{ bgcolor: "#FBFBFB" }}>
                    <TableRow>
                      <TableCell sx={headerCellStyle}>ภาค</TableCell>
                      <TableCell sx={headerCellStyle} align="left">
                        ราคา (บาท/กิโลกรัม)
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[
                      {
                        region: "ภาคเหนือ",
                        lowest: data?.northLowestPrice,
                        highest: data?.northHighestPrice,
                      },
                      {
                        region: "ภาคตะวันออก",
                        lowest: data?.eastLowestPrice,
                        highest: data?.eastHighestPrice,
                      },
                      {
                        region: "ภาคตะวันตก",
                        lowest: data?.westLowestPrice,
                        highest: data?.westHighestPrice,
                      },
                      {
                        region: "ภาคอีสาน",
                        lowest: data?.northEastLowestPrice,
                        highest: data?.northEastHighestPrice,
                      },
                      {
                        region: "ภาคกลาง",
                        lowest: data?.centralLowestPrice,
                        highest: data?.centralHighestPrice,
                      },
                      {
                        region: "ภาคใต้",
                        lowest: data?.southLowestPrice,
                        highest: data?.southHighestPrice,
                      },
                    ].map((item, index) => (
                      <TableRow key={`${data?.id}-${index}`} sx={rowStyle}>
                        <TableCell component="th" scope="row">
                          {item.region}
                        </TableCell>
                        <TableCell align="left">
                          {formatPriceRange(item.lowest, item.highest)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="items-start mt-4">
              <h4 className="text-sm font-bold pb-3 mb-1">รูปหน้าปก</h4>
              <div className="text-sm">
                <ListOfUploadedFile2
                  files={data?.articleFiles || []}
                  fileKey="fileOriginalName"
                  onDownload={onDownload}
                  hideDeleteBtn
                />
              </div>
            </div>

            <div className="my-4">
              <h4 className="text-[14px] mb-3 font-sans font-bold">คำบรรยาย</h4>
              <div className="text-[14px] mb-3 font-sans">
                {data?.description}
              </div>
            </div>
          </>
        }
      />
    </>
  );
};

// Additional styles for the table headers and rows
const headerCellStyle = {
  height: "50px",
  justifyContent: "start",
  fontFamily: "IBM Plex Sans Thai",
  fontWeight: 700,
  fontSize: "14px",
  border: "0px",
  width: "270px",
};

const rowStyle = {
  "&:last-child td, &:last-child th": { border: 0 },
};

export default ViewPigPriceModal;
