import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MenuItem, TableCell, TableRow } from "@mui/material";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import delay from "../../../../../../utils/delay";
import DeleteFillGreen from "../../../../../assets/svg/DeleteFillGreen.svg";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../../components/Table/TableCustom";
import { useMedicineFilterHook } from "../../../hooks/medicine/useMedicineFilter.hook";
import { useMedicineTableHook } from "../../../hooks/medicine/useMedicineTable.hook";
import {
  IMedicineTable,
  MedicineRow,
  selectUintMedicine,
} from "../../../types/medicine.type";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import RHFSelectCustom from "../../../../../components/Select/RHFSelectCustom";
import { editMedicine } from "../../../services/medicine.service";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleFetch: () => void;
};

interface IEditMedicineForm {
  editMedicines: Medicine[];
}

export interface Medicine {
  id: string;
  tradeName: string;
  tradeNameEn: string;
  categoryName: string;
  categoryNameEn: string;
  unit: string;
}

const EditMedicineModal = ({ isOpen, onClose, handleFetch }: Props) => {
  const { t } = useTranslation();
  const [deletedIds, setDeletedIds] = useState<string[]>([]);
  const [filteredFields, setFilteredFields] = useState<Medicine[]>([]);

  const dispatch = useDispatch();
  const searchMedicine = useMedicineFilterHook();
  const {
    medicine,
    tableData,
    handleFetchEditData,
    categoryMedicine,
    handleFetchCategory,
  } = useMedicineTableHook(searchMedicine);

  const headerItems: ITableCustomHeadCell<keyof IMedicineTable>[] = [
    {
      id: "categoryTh",
      numeric: false,
      disablePadding: false,
      label: `${t(
        "masterData.medicine.editMedicineModal.categoryOfMedicine"
      )} (TH)`,
      width: "250px",
      isSorting: false,
    },
    {
      id: "categoryEn",
      numeric: false,
      disablePadding: false,
      label: `${t(
        "masterData.medicine.editMedicineModal.categoryOfMedicine"
      )} (EN)`,
      width: "250px",
      isSorting: false,
    },
    {
      id: "medicineTh",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.medicine.editMedicineModal.medicineName")} (TH)`,
      width: "250px",
      isSorting: false,
    },
    {
      id: "medicineEn",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.medicine.editMedicineModal.medicineName")} (EN)`,
      width: "250px",
      isSorting: false,
    },
    {
      id: "unit",
      numeric: false,
      disablePadding: false,
      label: t("masterData.medicine.editMedicineModal.units"),
      width: "200px",
      isSorting: false,
    },
  ];

  const methods = useForm<IEditMedicineForm>({
    defaultValues: {
      editMedicines: [
        {
          id: "",
          tradeName: "",
          tradeNameEn: "",
          categoryName: "",
          categoryNameEn: "",
          unit: "",
        },
      ],
    },
  });

  const { fields, remove } = useFieldArray({
    name: "editMedicines",
    control: methods.control,
  });

  const handleDelete = (index: number) => {
    const deletedFieldId = methods.getValues(`editMedicines.${index}.id`);

    if (deletedFieldId) {
      setDeletedIds((prev) => [...prev, deletedFieldId]);
    }
    remove(index);
  };

  const handleSubmit = (value: IEditMedicineForm) => {
    const { dirtyFields } = methods.formState;

    const filterFieldIsDirty = value.editMedicines.filter((_, index) => {
      const dirtyField = dirtyFields.editMedicines?.[index];
      return (
        dirtyField?.tradeName ||
        dirtyField?.tradeNameEn ||
        dirtyField?.categoryName ||
        dirtyField?.categoryNameEn ||
        dirtyField?.unit
      );
    });

    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await editMedicine(filterFieldIsDirty, deletedIds);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleFetch();
            onClose();
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const setFirstForm = () => {
    const medicines = (medicine || []).map((medicine) => ({
      id: medicine.id,
      tradeName: medicine.tradeName || "",
      tradeNameEn: medicine.tradeNameEn || "",
      categoryName: medicine.medicineCategory
        ? medicine.medicineCategory.nameTh
        : "",
      categoryNameEn: medicine.medicineCategory
        ? medicine.medicineCategory.nameEn
        : "",
      unit: medicine.unit,
    }));

    methods.reset({ editMedicines: medicines });
  };

  // const onScrollToBottom = useCallback(async () => {
  //   setCurrentScroll(currentScroll + 1);
  //   const medicines = (tableData || []).map(
  //     (medicine): Medicine => ({
  //       id: medicine.id,
  //       tradeName: medicine.tradeName || "",
  //       tradeNameEn: medicine.tradeNameEn || "",
  //       categoryName: medicine.medicineCategory
  //         ? medicine.medicineCategory.nameTh
  //         : "",
  //       categoryNameEn: medicine.medicineCategory
  //         ? medicine.medicineCategory.nameEn
  //         : "",
  //       unit: medicine.unit,
  //     })
  //   );

  //   dispatch(loadingActions.show({ message: "กำลังโหลด..." }));
  //   methods.reset({
  //     editMedicines: [...methods.getValues("editMedicines"), ...medicines],
  //   });
  //   await delay(2000);
  //   dispatch(loadingActions.hide());
  // }, [currentScroll]);

  const handleSearch = async () => {
    if (searchMedicine.searchText.trim()) {
      const medicines = (medicine || []).map((medicine) => ({
        id: medicine.id,
        tradeName: medicine.tradeName || "",
        tradeNameEn: medicine.tradeNameEn || "",
        categoryName: medicine.medicineCategory
          ? medicine.medicineCategory.nameTh
          : "",
        categoryNameEn: medicine.medicineCategory
          ? medicine.medicineCategory.nameEn
          : "",
        unit: medicine.unit,
      }));

      const filteredMedicines = (medicines || []).filter(
        (med) =>
          med.tradeName
            ?.toLowerCase()
            .includes(searchMedicine.searchText.toLowerCase()) ||
          med.tradeNameEn
            ?.toLowerCase()
            .includes(searchMedicine.searchText.toLowerCase())
      );

      methods.reset({ editMedicines: filteredMedicines });
    } else {
      const medicines = (medicine || []).map((medicine) => ({
        id: medicine.id,
        tradeName: medicine.tradeName || "",
        tradeNameEn: medicine.tradeNameEn || "",
        categoryName: medicine.medicineCategory
          ? medicine.medicineCategory.nameTh
          : "",
        categoryNameEn: medicine.medicineCategory
          ? medicine.medicineCategory.nameEn
          : "",
        unit: medicine.unit,
      }));

      methods.reset({ editMedicines: medicines });
    }
  };

  useEffect(() => {
    handleSearch();
  }, [searchMedicine.searchText, searchMedicine.toggleFetch]);

  useEffect(() => {
    handleFetchEditData();
    handleFetchCategory();
    setFirstForm();
  }, [isOpen, medicine]);

  return (
    <>
      <AdminModal
        label={t("masterData.medicine.editMedicineModal.title")}
        isOpen={isOpen}
        size={"md"}
        handleClose={onClose}
        children={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <TextInputSearchCustom
                value={searchMedicine.searchText}
                placeholder="ค้นหาโดยชื่อยา"
                onChange={(event) =>
                  searchMedicine.setSearchText(event.target.value)
                }
                InputProps={{
                  endAdornment:
                    searchMedicine.searchText.length > 0 ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {
                          searchMedicine.setSearchText("");
                          searchMedicine.setToggleFetch((prev) => !prev);
                        }}
                      />
                    ) : null,
                }}
              />
              <div className="mt-4">
                <TableCustom
                  order={"desc"}
                  orderBy={""}
                  itemsSelected={[]}
                  rowsPerPage={medicine?.length || 0}
                  page={medicine?.length || 0}
                  headerItems={headerItems}
                  rows={medicine ? medicine : []}
                  onChangeOrderBy={() => {}}
                  onChangeOrder={() => {}}
                  onChangePage={() => {}}
                  onSelectItem={() => {}}
                  onSelectAllClick={() => {}}
                  totalItemSize={filteredFields?.length || 0}
                  currentPageItems={[]}
                  hideSelect
                  hidePaginator
                  maxHeightForScroll="550px"
                  rowChildren={fields.map((row, index) => {
                    return (
                      <TableRow hover key={row.id}>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {/*  category medicine thai */}
                          <RHFSelectCustom
                            value={
                              methods.watch("editMedicines")[index].categoryName
                            }
                            controlName={`editMedicines[${index}].categoryName`}
                            placeholder="เลือก"
                            menuItems={(categoryMedicine || []).map((row) => (
                              <MenuItem key={row.id} value={row.id}>
                                {row.nameTh}
                              </MenuItem>
                            ))}
                            renderValueArray={(categoryMedicine || []).map(
                              (row) => ({
                                value: row.id,
                                label: row.nameTh,
                              })
                            )}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {/*  category medicine eng */}
                          <RHFSelectCustom
                            value={
                              methods.watch("editMedicines")[index]
                                .categoryNameEn
                            }
                            controlName={`editMedicines[${index}].categoryNameEn`}
                            placeholder="เลือก"
                            menuItems={(categoryMedicine || []).map((row) => (
                              <MenuItem key={row.id} value={row.id}>
                                {row.nameEn}
                              </MenuItem>
                            ))}
                            renderValueArray={(categoryMedicine || []).map(
                              (row) => ({
                                value: row.id,
                                label: row.nameEn,
                              })
                            )}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {/* medicine name thai */}
                          <RHFTextInputCustom
                            controlName={`editMedicines[${index}].tradeName`}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {/* medicine name eng */}
                          <RHFTextInputCustom
                            controlName={`editMedicines[${index}].tradeNameEn`}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {/* medicine uint */}
                          <RHFSelectCustom
                            value={methods.watch("editMedicines")[index].unit}
                            controlName={`editMedicines[${index}].unit`}
                            placeholder="เลือก"
                            menuItems={(selectUintMedicine || []).map((row) => (
                              <MenuItem key={row} value={row}>
                                {row}
                              </MenuItem>
                            ))}
                            renderValueArray={(selectUintMedicine || []).map(
                              (row) => ({
                                value: row,
                                label: row,
                              })
                            )}
                          />
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          <div
                            onClick={() => {
                              handleDelete(index);
                            }}
                          >
                            <DeleteFillGreen />
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                />
              </div>
              <div className="flex justify-end">
                <ButtonFillCustom
                  title={t("common.button.confirm")}
                  type="submit"
                />
              </div>
            </form>
          </FormProvider>
        }
      />
    </>
  );
};

export default EditMedicineModal;
