import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PersonIcon from "@mui/icons-material/Person";
import { get } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TextInputSearchCustom from "../../../admin/components/Input/TextInputSearchCustom";
import AppBarFS from "../../../components/app-bar/AppBar";
import DetailsAcceptedAssignmentModal from "../details-assignment/views/DetailsAcceptedAssignmentModal";
import { useHistoryAssignmentController } from "./controller";

const HistoryAssignment = () => {
  const {
    listHistoryAssignment,
    isShowDetailsAssignment,
    onShowDetailsAssignment,
    onHideDetailsAssignment,
    selectedIndex,
  } = useHistoryAssignmentController();

  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner/track-debt");
  }, []);

  const { t } = useTranslation();

  return (
    <div className="bg-[#d9ebfe] w-full min-h-full">
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
          <AppBarFS
            title={t("MoneyDebtAndCollection.AllAssignmentHistory")}
            onIconPress={handleBack}
          />
        </div>
        <div className="flex flex-col gap-4 py-[16px] px-4 justify-between">
          <span className="font-semibold text-[20px] text-[#3777BC] flex flex-col justify-center">
            {t("MoneyDebtAndCollection.AllAssignmentHistory")}
          </span>
          <div className="w-full flex justify-between">
            <TextInputSearchCustom
              value={""}
              className="max-w-[470px]"
              onChange={() => {}}
              placeholder={t("MoneyDebtAndCollection.SearchForCustomerName")}
              InputProps={{
                endAdornment: (
                  <CloseRoundedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {}}
                  />
                ),
                sx: {
                  borderRadius: "30px",
                  height: "48px",
                },
              }}
            />
          </div>
        </div>
        {get(listHistoryAssignment, ["count"], 0) > 0 ? (
          <>
            <div className="px-4 flex flex-col gap-4 pb-[37px]">
              {get(listHistoryAssignment, ["rows"], []).map((d, index) => {
                return (
                  <div
                    key={`history-${index}`}
                    className="bg-[#FFFF] rounded-[15px] px-4 pt-4 pb-6 flex flex-col gap-3"
                    onClick={() => {
                      onShowDetailsAssignment(index);
                    }}
                  >
                    <div className="flex flex-row gap-2 items-center">
                      <PersonIcon className="w-[22px] h-[22px] text-[#68C184]" />
                      <div className="text-[#34407B] text-[16px] font-semibold">
                        {d.farmerName}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        {t("MoneyDebtAndCollection.AssignNo")}
                      </div>
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        {d.assignmentNumber}
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full">
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        {t("MoneyDebtAndCollection.PhoneNumber")}
                      </div>
                      <div className="text-[#4B4B4B] text-[14px] font-medium">
                        {d.farmerMobileNumber}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <div className="w-full flex flex-row justify-center">
            {t("MoneyDebtAndCollection.NoAssignmentHistory")}
          </div>
        )}
      </div>
      <DetailsAcceptedAssignmentModal
        isOpen={isShowDetailsAssignment}
        onClose={onHideDetailsAssignment}
        HistoryAssignment={
          listHistoryAssignment?.rows[selectedIndex] || undefined
        }
      />
    </div>
  );
};

export default HistoryAssignment;
