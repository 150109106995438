import { Dialog, DialogContent, IconButton, Tab } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { get, isEmpty, some } from "lodash";
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import * as Y from "yup";
import DownloadLinkWithDelete from "../../../../../components/common/File/DownloadLinkWithDelete";
import ListOfFile from "../../../../../components/common/File/ListOfFile";
import useFormValidator from "../../../../../hooks/useFormValidator.hook";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../redux/store";
import {
  createFeed2,
  getCalGrade,
  getFarmAndHouse,
  getId,
  getUploadUrlCreateFeed,
  ReapplyCredit,
} from "../../../../../services/CreditFeed.service";
import {
  ICreditFeedData,
  ICreditInfoById,
  IGetFarmAndHouse,
  IGetGrade,
} from "../../../../../types/CreditFeed.type";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import { getCommaNumber } from "../../../../../utils/number";
import { tw } from "../../../../../utils/tw";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import DragAndDropUploadImage from "../../../../components/Input/DragAndDropUploadImage";
import TextAreaCustom from "../../../../components/Input/TextAreaCustom";
import {
  calculateFarmerCredit,
  putFarmerCredit,
} from "../../../farm-detail/services/farmDetail.service";
import { Dropdown, TDropdownValue } from "../../../group-area/views/Dropdown";
import { Input } from "../../../group-area/views/Input";
import Tabs from "../../../group-area/views/Tabs";
import {
  defaultFormTabFarmer,
  TDropdownValueId,
  TFormForDynamicDropdown,
  TFormTabFarmer,
} from "../credit-tab/TabFarmer/DefaultFormTabFarmer";
import { AccordionFillData } from "../credit-tab/TabPending/AccordionFillData";
import { useTranslation } from "react-i18next";

const farmSizeOptions = [
  { label: "S", value: "s" },
  { label: "M", value: "m" },
  { label: "L", value: "l" },
  { label: "XL", value: "xl" },
];

const contractOptions = [
  { label: "จ่ายหลังหมดสัญญา", value: "pay_after_expire" },
  { label: "จ่ายตาม invoice", value: "fill_credit" },
];

const classes = {
  containerModal: tw(
    `w-[100vw] !max-w-[1311px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary`
  ),
  container: tw(`flex flex-col gap-6`),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold text-[#191919]`),
  rowContainer: tw(`grid grid-cols-4 gap-3`),
  text: tw(`font-sans font-bold text-[16px]`),
};

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  form: TFormTabFarmer;
  setForm: Dispatch<SetStateAction<TFormTabFarmer>>;
  onChangeForm: (key: keyof TFormTabFarmer) => (value: any) => void;
  selectVersionCreditOption: TDropdownValue[];
  businessTypeOption: TDropdownValue[];
  typePersonalOptions: TDropdownValueId;
  agePersonalOptions: TDropdownValueId;
  typeLegalOptions: TDropdownValueId;
  dropdownValuesGeneralFarmInformation: TDropdownValueId;
  dropdownValuesFarmPerformance: TDropdownValueId;
  dropdownValuesFarmFinancialStatusAndInstallment: TDropdownValueId;
  dropdownValuesApplicationUsage: TDropdownValueId;
  FormGeneralPersonalInformation: TFormForDynamicDropdown;
  setFormGeneralPersonalInformation: Dispatch<
    SetStateAction<TFormForDynamicDropdown>
  >;

  onChangeFormGeneralPersonalInformation: (
    newValue: TDropdownValue & { id: string },
    index: number
  ) => void;
  FormGeneralLegalInformation: TFormForDynamicDropdown;
  setFormGeneralLegalInformation: Dispatch<
    SetStateAction<TFormForDynamicDropdown>
  >;

  onChangeFormGeneralLegalInformation: (
    newValue: TDropdownValue & { id: string },
    index: number
  ) => void;
  FormGeneralFarmInformation: TFormForDynamicDropdown;
  setFormGeneralFarmInformation: Dispatch<
    SetStateAction<TFormForDynamicDropdown>
  >;

  onChangeFormGeneralFarmInformation: (
    newValue: TDropdownValue & { id: string },
    index: number
  ) => void;
  FormFarmPerformance: TFormForDynamicDropdown;
  setFormFarmPerformance: Dispatch<SetStateAction<TFormForDynamicDropdown>>;

  onChangeFormFarmPerformance: (
    newValue: TDropdownValue & { id: string },
    index: number
  ) => void;
  FormFarmFinancialStatusAndInstallment: TFormForDynamicDropdown;
  setFormFarmFinancialStatusAndInstallment: Dispatch<
    SetStateAction<TFormForDynamicDropdown>
  >;

  onChangeFormFarmFinancialStatusAndInstallment: (
    newValue: TDropdownValue & { id: string },
    index: number
  ) => void;
  FormApplicationUsage: TFormForDynamicDropdown;
  setFormApplicationUsage: Dispatch<SetStateAction<TFormForDynamicDropdown>>;
  onChangeFormApplicationUsage: (
    newValue: TDropdownValue & { id: string },
    index: number
  ) => void;
  idFramers: string;
  setIdFramers: Dispatch<SetStateAction<string>>;
  refetchListFarmersCreditFeed: () => void;
  refetchListCreditFeedTabPending: () => void;
  refetchListCreditFeedTabDenied: () => void;
  refetchListCreditFeedTabProcessing: () => void;
  isEdit?: boolean;
  dataEdit?: ICreditFeedData;
  listCreditSettingById?: ICreditInfoById | ICreditFeedData;
  isUpdateCreditFarmDetail?: boolean;
  isReapplyOrCreateFn?: boolean;
  isCalculateCredit?: boolean;
  dataCalculateCredit?: ICreditFeedData;
  isCredit?: boolean;
}

const errorSchemaTab1 = (requiredLFarmInformation: number) =>
  Y.object({
    tabFirst_businessType: Y.array().min(1, "กรุณากรอกข้อมูล"),
    FormGeneralPersonalInformation: Y.array(
      Y.object({
        id: Y.string().uuid().required("กรุณากรอกข้อมูล"),
      })
    ).when("tabFirst_businessType", {
      is: (tabFirst_businessType: TDropdownValue[]) =>
        tabFirst_businessType[0]?.value === "personal",
      then: (schema) => schema.required().length(2),
      otherwise: (schema) => schema.notRequired(),
    }),

    FormGeneralLegalInformation: Y.array(
      Y.object({
        id: Y.string().uuid().required("กรุณากรอกข้อมูล"),
      })
    ).when("tabFirst_businessType", {
      is: (tabFirst_businessType: TDropdownValue[]) =>
        tabFirst_businessType[0]?.value === "legal",
      then: (schema) => schema.required().length(1),
      otherwise: (schema) => schema.notRequired(),
    }),

    FormGeneralFarmInformation: Y.array(
      Y.object({
        id: Y.string().uuid().required("กรุณากรอกข้อมูล"),
      })
    )
      .required()
      .length(requiredLFarmInformation),
  });

const errorSchemaTab2 = (requiredLFarmPerformance: number) =>
  Y.object({
    FormFarmPerformance: Y.array(
      Y.object({
        id: Y.string().uuid().required("กรุณากรอกข้อมูล"),
      })
    )
      .required()
      .length(requiredLFarmPerformance),
  });

const errorSchemaTab3 = (requiredLFarmFinancialStatusAndInstallment: number) =>
  Y.object({
    FormFarmFinancialStatusAndInstallment: Y.array(
      Y.object({
        id: Y.string().uuid().required("กรุณากรอกข้อมูล"),
      })
    )
      .required()
      .length(requiredLFarmFinancialStatusAndInstallment),
  });

const errorSchemaTab4 = (requiredLApplicationUsage: number) =>
  Y.object({
    FormApplicationUsage: Y.array(
      Y.object({
        id: Y.string().uuid().required("กรุณากรอกข้อมูล"),
      })
    )
      .required()
      .length(requiredLApplicationUsage),
  });

const errorSchemaTab5 = Y.object({
  tabFifth_farmSize: Y.array().min(1, "กรุณาเลือกขนาดฟาร์ม"),
  tabFifth_farmName: Y.array().min(1, "กรุณาเลือกฟาร์ม"),
  tabFifth_greenHouse: Y.array().min(1, "กรุณาเลือกโรงเรือน"),
  tabFifth_contractType: Y.array().min(1, "กรุณาเลือกประเภทสัญญา"),
  tabFifth_timeCredit: Y.string().required("กรุณากรอกระยะเวลาเครดิต"),
  tabFifth_creditLineOfferedToCustomer: Y.string().required(
    "กรุณากรอกวงเงินที่นำเสนอลูกค้า"
  ),
  tabFifth_reason: Y.string()
    .required("กรุณากรอกเหตุผล")
    .max(500, "เหตุผลต้องไม่เกิน 500 ตัวอักษร"),
});

const errorSchemaTab5Edit = Y.object({
  tabFifth_farmSize: Y.array().min(1, "กรุณาเลือกขนาดฟาร์ม"),
  tabFifth_contractType: Y.array().min(1, "กรุณาเลือกประเภทสัญญา"),
  tabFifth_timeCredit: Y.string().required("กรุณากรอกระยะเวลาเครดิต"),
  tabFifth_creditLineOfferedToCustomer: Y.string().required(
    "กรุณากรอกวงเงินที่นำเสนอลูกค้า"
  ),
  tabFifth_reason: Y.string()
    .required("กรุณากรอกเหตุผล")
    .max(500, "เหตุผลต้องไม่เกิน 500 ตัวอักษร"),
});

const errorSchemaTab5Cal = Y.object({
  tabFifth_farmSize: Y.array().min(1, "กรุณาเลือกขนาดฟาร์ม"),
  tabFifth_farmName: Y.array().min(1, "กรุณาเลือกฟาร์ม"),
  tabFifth_greenHouse: Y.array().min(1, "กรุณาเลือกโรงเรือน"),
  tabFifth_contractType: Y.array().min(1, "กรุณาเลือกประเภทสัญญา"),
  tabFifth_timeCredit: Y.string().required("กรุณากรอกระยะเวลาเครดิต"),
  tabFifth_creditLineOfferedToCustomer: Y.string().required(
    "กรุณากรอกวงเงินที่นำเสนอลูกค้า"
  ),
});

const errorSchemaTabNone = Y.object({});

const ModalApplyCredit = (props: IProps) => {
  const {
    isOpen,
    onClose,
    form,
    setForm,
    onChangeForm,
    selectVersionCreditOption,
    businessTypeOption,
    typePersonalOptions,
    agePersonalOptions,
    typeLegalOptions,
    dropdownValuesGeneralFarmInformation,
    dropdownValuesFarmPerformance,
    dropdownValuesFarmFinancialStatusAndInstallment,
    dropdownValuesApplicationUsage,
    FormGeneralPersonalInformation,
    setFormGeneralPersonalInformation,
    onChangeFormGeneralPersonalInformation,
    FormGeneralLegalInformation,
    setFormGeneralLegalInformation,
    onChangeFormGeneralLegalInformation,
    FormGeneralFarmInformation,
    setFormGeneralFarmInformation,
    onChangeFormGeneralFarmInformation,
    FormFarmPerformance,
    setFormFarmPerformance,
    onChangeFormFarmPerformance,
    FormFarmFinancialStatusAndInstallment,
    setFormFarmFinancialStatusAndInstallment,
    onChangeFormFarmFinancialStatusAndInstallment,
    FormApplicationUsage,
    setFormApplicationUsage,
    onChangeFormApplicationUsage,
    idFramers,
    setIdFramers,
    refetchListFarmersCreditFeed,
    refetchListCreditFeedTabPending,
    refetchListCreditFeedTabDenied,
    refetchListCreditFeedTabProcessing,
    isEdit = false,
    dataEdit,
    listCreditSettingById,
    isUpdateCreditFarmDetail = false,
    isReapplyOrCreateFn = true,
    isCalculateCredit = false,
    dataCalculateCredit,
    isCredit = false,
  } = props;

  const { t } = useTranslation();

  const [tabIndex, setTabIndex] = useState(0);

  const mainForm = useMemo(() => {
    return {
      ...form,
      FormGeneralPersonalInformation,
      FormGeneralLegalInformation,
      FormGeneralFarmInformation,
      FormFarmPerformance,
      FormFarmFinancialStatusAndInstallment,
      FormApplicationUsage,
      tabIndex,
    };
  }, [
    form,
    FormGeneralPersonalInformation,
    FormGeneralLegalInformation,
    FormGeneralFarmInformation,
    FormFarmPerformance,
    FormFarmFinancialStatusAndInstallment,
    FormApplicationUsage,
    tabIndex,
  ]);

  const dispatch = useDispatch<AppDispatch>();
  const [responseDataCalGrade, setResponseDataCalGrade] = useState<IGetGrade>();

  const [ids, setIds] = useState("");

  const checkForErrors = async () => {
    try {
      if (tabIndex === 0) {
        await errorSchemaTab1(
          dropdownValuesGeneralFarmInformation.length
          // dropdownValuesFarmPerformance.length,
          // dropdownValuesFarmFinancialStatusAndInstallment.length,
          // dropdownValuesApplicationUsage.length
        ).validate(mainForm, { abortEarly: false });
      } else if (tabIndex === 1) {
        await errorSchemaTab2(
          // dropdownValuesGeneralFarmInformation.length
          dropdownValuesFarmPerformance.length
          // dropdownValuesFarmFinancialStatusAndInstallment.length,
          // dropdownValuesApplicationUsage.length
        ).validate(mainForm, { abortEarly: false });
      } else if (tabIndex === 2) {
        await errorSchemaTab3(
          // dropdownValuesGeneralFarmInformation.length
          // dropdownValuesFarmPerformance.length
          dropdownValuesFarmFinancialStatusAndInstallment.length
          // dropdownValuesApplicationUsage.length
        ).validate(mainForm, { abortEarly: false });
      } else if (tabIndex === 3) {
        await errorSchemaTab4(
          // dropdownValuesGeneralFarmInformation.length
          // dropdownValuesFarmPerformance.length
          //  dropdownValuesFarmFinancialStatusAndInstallment.length
          dropdownValuesApplicationUsage.length
        ).validate(mainForm, { abortEarly: false });
      } else if (tabIndex >= 4) {
        if (isUpdateCreditFarmDetail) {
        } else if (isCalculateCredit) {
          await errorSchemaTab5Cal.validate(mainForm, { abortEarly: false });
        } else if (isEdit) {
          if (isEdit) {
            await errorSchemaTab5Edit.validate(mainForm, { abortEarly: false });
          } else
            await errorSchemaTab5.validate(mainForm, { abortEarly: false });
        }
      }
      return false;
    } catch (err) {
      return true;
    }
  };

  const maxIndex = 5;
  const minIndex = 0;
  const errorThresholdIndex = 3;

  const onChangeTab = useCallback(
    async (e: React.SyntheticEvent<Element, Event>, index: number) => {
      let hasErrors = await checkForErrors();
      let success = onSubmit2(index);

      if (index <= tabIndex) {
        setTabIndex(index);
      } else if (!hasErrors && tabIndex <= 3) {
        handleSubmit();
      } else if (hasErrors && tabIndex <= 3) {
        handleSubmit();
      } else if (!hasErrors && tabIndex >= 4) {
        success && setTabIndex(tabIndex + 1);
      }
    },
    [mainForm, tabIndex]
  );

  const onClickNext = useCallback(async () => {
    let checkVersion = !isEmpty(form.tabFirst_versionCredit);
    let hasErrors = await checkForErrors();

    if (tabIndex === maxIndex - 1) {
      let success = await onSubmit2();
      if (success) {
        getId().then((result) => {
          setIds(result.generatedUUID);
          setTabIndex(tabIndex + 1);
        });
      }
      return;
    }

    if (
      checkVersion &&
      tabIndex < maxIndex &&
      (!hasErrors || tabIndex + 1 <= errorThresholdIndex)
    ) {
      setTabIndex(tabIndex + 1);
    }
  }, [tabIndex, mainForm]);

  const onClickPrev = useCallback(() => {
    if (tabIndex > minIndex) {
      if (isUpdateCreditFarmDetail && tabIndex === 5) {
        setTabIndex(tabIndex - 2);
      } else setTabIndex(tabIndex - 1);
    }
  }, [tabIndex]);

  const handleDropdownChange = (
    e: (TDropdownValue & { id: string })[],
    index: number,
    onChange: (newValue: TDropdownValue & { id: string }, index: number) => void
  ) => {
    const selectedOption = {
      label: e[0].label,
      value: e[0].value,
      id: e[0].id,
    };
    onChange(selectedOption, index);
  };

  const calGradeMutation = useMutation({
    mutationFn: getCalGrade,
    onSuccess: async (data) => {
      setTimeout(() => {
        setResponseDataCalGrade(data);
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const { onSubmit, errors, resetError } = useFormValidator({
    form: mainForm,
    errorSchema:
      tabIndex === 0
        ? errorSchemaTab1(dropdownValuesGeneralFarmInformation.length)
        : tabIndex === 1
        ? errorSchemaTab2(dropdownValuesFarmPerformance.length)
        : tabIndex === 2
        ? errorSchemaTab3(
            dropdownValuesFarmFinancialStatusAndInstallment.length
          )
        : errorSchemaTab4(dropdownValuesApplicationUsage.length),
    onValidateSuccess: async () => {
      try {
        if (tabIndex === 3 || tabIndex === 4) {
          dispatch(loadingActions.show({ message: "กำลังคำนวนเกรด..." }));
          await calGradeMutation.mutateAsync({
            creditSettingId: form.tabFirst_versionCredit[0]?.value,
            farmerType: form.tabFirst_businessType[0]?.value,
            generalPersonalInformation: FormGeneralPersonalInformation
              ? FormGeneralPersonalInformation.map(({ id, value }) => ({
                  id: id,
                  score: +value,
                }))
              : [],
            generalLegalInformation: FormGeneralLegalInformation
              ? FormGeneralLegalInformation.map(({ id, value }) => ({
                  id: id,
                  score: +value,
                }))
              : [],
            generalFarmInformation: FormGeneralFarmInformation
              ? FormGeneralFarmInformation.map(({ id, value }) => ({
                  id: id,
                  score: +value,
                }))
              : [],
            farmPerformance: FormFarmPerformance
              ? FormFarmPerformance.map(({ id, value }) => ({
                  id: id,
                  score: +value,
                }))
              : [],
            farmFinancialStatusAndInstallment:
              FormFarmFinancialStatusAndInstallment
                ? FormFarmFinancialStatusAndInstallment.map(
                    ({ id, value }) => ({
                      id: id,
                      score: +value,
                    })
                  )
                : [],
            applicationUsage: FormApplicationUsage
              ? FormApplicationUsage.map(({ id, value }) => ({
                  id: id,
                  score: +value,
                }))
              : [],
          });
          setTimeout(() => {
            dispatch(loadingActions.hide());
            dispatch(
              snakeActions.showMessage({
                message: "คำนวณเกรดสำเร็จ",
                type: "success",
              })
            );
          }, 500);
        }
      } catch (error) {
        setTimeout(() => {
          dispatch(loadingActions.hide());
          dispatch(
            snakeActions.showMessage({
              message: getErrorMessage(error),
              type: "error",
            })
          );
        }, 500);
      }
    },
  });

  const handleSubmit = useCallback(() => {
    let success = onSubmit();
    if (isUpdateCreditFarmDetail && tabIndex === 3) {
      success && setTabIndex(tabIndex + 2);
    } else success && setTabIndex(tabIndex + 1);
  }, [tabIndex, onSubmit]);

  const getShowDataGeneralFarmInformation = () => {
    return FormGeneralFarmInformation.map((info) => {
      if (info) {
        const correspondingDropdown = dropdownValuesGeneralFarmInformation.find(
          (dropdown) => dropdown.id === info.id
        );
        return {
          label: correspondingDropdown
            ? correspondingDropdown.label
            : "Unknown",
          value: info.label || "-",
        };
      } else {
        return [];
      }
    }).filter(Boolean);
  };

  const getShowDataFarmPerformance = () => {
    return FormFarmPerformance.map((info) => {
      if (info) {
        const correspondingDropdown = dropdownValuesFarmPerformance.find(
          (dropdown) => dropdown.id === info.id
        );
        return {
          label: correspondingDropdown
            ? correspondingDropdown.label
            : "Unknown",
          value: info.label || "-",
        };
      } else {
        return [];
      }
    }).filter(Boolean);
  };

  const getShowDataFarmFinancialStatusAndInstallment = () => {
    return FormFarmFinancialStatusAndInstallment.map((info) => {
      if (info) {
        const correspondingDropdown =
          dropdownValuesFarmFinancialStatusAndInstallment.find(
            (dropdown) => dropdown.id === info.id
          );
        return {
          label: correspondingDropdown
            ? correspondingDropdown.label
            : "Unknown",
          value: info.label || "-",
        };
      } else {
        return [];
      }
    }).filter(Boolean);
  };

  const getShowDataApplicationUsage = () => {
    return FormApplicationUsage.map((info) => {
      if (info) {
        const correspondingDropdown = dropdownValuesApplicationUsage.find(
          (dropdown) => dropdown.id === info.id
        );
        return {
          label: correspondingDropdown
            ? correspondingDropdown.label
            : "Unknown",
          value: info.label || "-",
        };
      } else {
        return [];
      }
    }).filter(Boolean);
  };

  const handleDownload = useCallback(
    async (file: File) => {
      const url = window.URL.createObjectURL(new Blob([file]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${file.name}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    },
    [form.tabFifth_creditCalculationDocuments]
  );

  const getFarmAndHouseMutation = useMutation({
    mutationFn: getFarmAndHouse,
    onSuccess: async (data) => {
      setFarmAndHouseData(data);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const [farmAndHouseData, setFarmAndHouseData] = useState<IGetFarmAndHouse>();

  const farmOptions = useMemo(() => {
    const _farmOptions = get(farmAndHouseData, "farms", []).map(
      ({ id, name, houses }) => ({
        label: name,
        value: id,
        houses: houses.map(
          ({ id, name, fattenerPigCount, estimateFinancialAmount }) => ({
            label: name,
            value: id,
            fattenerPigCount,
            estimateFinancialAmount,
          })
        ),
      })
    );

    return _farmOptions;
  }, [farmAndHouseData]);

  const CreateCreditMutation = useMutation({
    mutationFn: createFeed2,
    onSuccess: async () => {
      refetchListFarmersCreditFeed();
      refetchListCreditFeedTabPending();
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "ให้เครดิตสำเร็จ",
            type: "success",
          })
        );
        onCloseFn();
      }, 500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const ReapplyCreditMutation = useMutation({
    mutationFn: ReapplyCredit,
    onSuccess: async () => {
      refetchListCreditFeedTabDenied();
      refetchListCreditFeedTabProcessing();
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "ให้เครดิตสำเร็จ",
            type: "success",
          })
        );
        onCloseFn();
      }, 500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const EditFarmerCredit = useMutation({
    mutationFn: putFarmerCredit,
    onSuccess: async () => {
      refetchListCreditFeedTabDenied();
      refetchListCreditFeedTabProcessing();
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "อัปเดตข้อมูลสำเร็จ",
            type: "success",
          })
        );
        onCloseFn();
      }, 500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const CalculateFarmerCredit = useMutation({
    mutationFn: calculateFarmerCredit,
    onSuccess: async () => {
      refetchListCreditFeedTabDenied();
      refetchListCreditFeedTabProcessing();
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: "อัปเดตข้อมูลสำเร็จ",
            type: "success",
          })
        );
        onCloseFn();
      }, 500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const {
    onSubmit: onSubmit2,
    errors: errors2,
    resetError: resetError2,
  } = useFormValidator({
    form: mainForm,
    errorSchema: isCredit
      ? errorSchemaTab5
      : isCredit && isEdit
      ? errorSchemaTab5Edit
      : isUpdateCreditFarmDetail
      ? errorSchemaTabNone
      : errorSchemaTab5Cal,

    onValidateSuccess: (_tabIndex) => {
      const index =
        !!`${_tabIndex}` && typeof _tabIndex === "number"
          ? _tabIndex
          : tabIndex;
      const isSwitchTab =
        _tabIndex !== tabIndex && typeof _tabIndex === "number";

      if (index === 5 && !isSwitchTab) {
        dispatch(
          alertAction.showDialog({
            title: "ยืนยันการให้เครดิต",
            text: "คุณต้องการให้เครดิตรายการนี้ใช่หรือไม่?",
            cancelText: "กลับไปแก้ไข",
            confirmText: t("common.button.confirm"),
            onCancel: () => {
              dispatch(alertAction.hide());
            },
            onSubmit: async () => {
              const fileArray = [];
              try {
                dispatch(
                  loadingActions.show({ message: "กำลังยืนยันการให้เครดิต..." })
                );
                if (
                  get(form, ["tabFifth_creditCalculationDocuments"]).length > 0
                ) {
                  // upload image
                  const presign = await getUploadUrlCreateFeed(
                    isEdit ? get(dataEdit, ["id"], "") : ids
                  );
                  await axios.put(
                    presign.blob.blobUrl,
                    get(form, ["tabFifth_creditCalculationDocuments", 0]),
                    {
                      responseType: "blob",
                      headers: {
                        "Content-Type": get(form, [
                          "tabFifth_creditCalculationDocuments",
                          0,
                        ]).type,
                        "x-ms-blob-type": "BlockBlob",
                      },
                    }
                  );
                  fileArray.push({
                    id: presign.blob.fileId,
                    fileName: get(form, [
                      "tabFifth_creditCalculationDocuments",
                      0,
                    ]).name,
                    fileSize: get(form, [
                      "tabFifth_creditCalculationDocuments",
                      0,
                    ]).size,
                  });
                }
                {
                  if (isReapplyOrCreateFn) {
                    if (isEdit) {
                      await ReapplyCreditMutation.mutate({
                        id: get(dataEdit, ["id"], ""),
                        creditInformation: {
                          farmSize: get(form, [
                            "tabFifth_farmSize",
                            0,
                            "value",
                          ]),
                          houseId: get(dataEdit, ["houseId"], ""),
                          creditAmount: +get(form, [
                            "tabFifth_creditLineOfferedToCustomer",
                          ]),
                          contractType: get(form, [
                            "tabFifth_contractType",
                            0,
                            "value",
                          ]),
                          calculatedCreditAmount: +get(form, [
                            "tabFifth_customerCreditLimitCalculatedByTheSystem",
                          ]),
                          creditPeriod: +get(form, ["tabFifth_timeCredit"]),
                          reason: get(form, ["tabFifth_reason"]),
                          files:
                            get(form, ["tabFifth_creditCalculationDocuments"])
                              .length === 0
                              ? undefined
                              : fileArray,
                        },
                        creditSettingId: form.tabFirst_versionCredit[0]?.value,
                        farmerType: form.tabFirst_businessType[0]?.value,
                        generalPersonalInformation:
                          FormGeneralPersonalInformation
                            ? FormGeneralPersonalInformation?.map(
                                ({ id, value }) => ({
                                  id: id,
                                  score: +value,
                                })
                              )
                            : [],
                        generalLegalInformation: FormGeneralLegalInformation
                          ? FormGeneralLegalInformation?.map(
                              ({ id, value }) => ({
                                id: id,
                                score: +value,
                              })
                            )
                          : [],
                        generalFarmInformation: FormGeneralFarmInformation
                          ? FormGeneralFarmInformation?.map(
                              ({ id, value }) => ({
                                id: id,
                                score: +value,
                              })
                            )
                          : [],
                        farmPerformance: FormFarmPerformance
                          ? FormFarmPerformance?.map(({ id, value }) => ({
                              id: id,
                              score: +value,
                            }))
                          : [],
                        farmFinancialStatusAndInstallment:
                          FormFarmFinancialStatusAndInstallment
                            ? FormFarmFinancialStatusAndInstallment?.map(
                                ({ id, value }) => ({
                                  id: id,
                                  score: +value,
                                })
                              )
                            : [],
                        applicationUsage: FormApplicationUsage
                          ? FormApplicationUsage?.map(({ id, value }) => ({
                              id: id,
                              score: +value,
                            }))
                          : [],
                      });
                    } else {
                      await CreateCreditMutation.mutate({
                        id: ids,
                        creditInformation: {
                          farmSize: get(form, [
                            "tabFifth_farmSize",
                            0,
                            "value",
                          ]),
                          houseId: get(form, [
                            "tabFifth_greenHouse",
                            0,
                            "value",
                          ]),
                          creditAmount: +get(form, [
                            "tabFifth_creditLineOfferedToCustomer",
                          ]),
                          contractType: get(form, [
                            "tabFifth_contractType",
                            0,
                            "value",
                          ]),
                          creditPeriod: +get(form, ["tabFifth_timeCredit"]),
                          reason: get(form, ["tabFifth_reason"]),
                          calculatedCreditAmount: +get(form, [
                            "tabFifth_customerCreditLimitCalculatedByTheSystem",
                          ]),
                          files:
                            get(form, ["tabFifth_creditCalculationDocuments"])
                              .length === 0
                              ? undefined
                              : fileArray,
                        },
                        creditSettingId: form.tabFirst_versionCredit[0]?.value,
                        farmerType: form.tabFirst_businessType[0]?.value,
                        generalPersonalInformation:
                          FormGeneralPersonalInformation
                            ? FormGeneralPersonalInformation?.map(
                                ({ id, value }) => ({
                                  id: id,
                                  score: +value,
                                })
                              )
                            : [],
                        generalLegalInformation: FormGeneralLegalInformation
                          ? FormGeneralLegalInformation?.map(
                              ({ id, value }) => ({
                                id: id,
                                score: +value,
                              })
                            )
                          : [],
                        generalFarmInformation: FormGeneralFarmInformation
                          ? FormGeneralFarmInformation?.map(
                              ({ id, value }) => ({
                                id: id,
                                score: +value,
                              })
                            )
                          : [],
                        farmPerformance: FormFarmPerformance
                          ? FormFarmPerformance?.map(({ id, value }) => ({
                              id: id,
                              score: +value,
                            }))
                          : [],
                        farmFinancialStatusAndInstallment:
                          FormFarmFinancialStatusAndInstallment
                            ? FormFarmFinancialStatusAndInstallment?.map(
                                ({ id, value }) => ({
                                  id: id,
                                  score: +value,
                                })
                              )
                            : [],
                        applicationUsage: FormApplicationUsage
                          ? FormApplicationUsage?.map(({ id, value }) => ({
                              id: id,
                              score: +value,
                            }))
                          : [],
                      });
                    }
                  } else {
                    if (isUpdateCreditFarmDetail) {
                      await EditFarmerCredit.mutate({
                        id: idFramers,
                        creditInformation: {
                          farmSize: get(form, [
                            "tabFifth_farmSize",
                            0,
                            "value",
                          ]),
                          houseId: get(listCreditSettingById, ["houseId"], ""),
                          creditAmount: +get(form, [
                            "tabFifth_creditLineOfferedToCustomer",
                          ]),
                          contractType: get(form, [
                            "tabFifth_contractType",
                            0,
                            "value",
                          ]),
                          creditPeriod: +get(form, ["tabFifth_timeCredit"]),
                          reason: get(form, ["tabFifth_reason"]),
                          files:
                            get(form, ["tabFifth_creditCalculationDocuments"])
                              .length === 0
                              ? undefined
                              : fileArray,
                        },
                        creditSettingId: form.tabFirst_versionCredit[0]?.value,
                        farmerType: form.tabFirst_businessType[0]?.value,
                        generalPersonalInformation:
                          FormGeneralPersonalInformation
                            ? FormGeneralPersonalInformation?.map(
                                ({ id, value }) => ({
                                  id: id,
                                  score: +value,
                                })
                              )
                            : [],
                        generalLegalInformation: FormGeneralLegalInformation
                          ? FormGeneralLegalInformation?.map(
                              ({ id, value }) => ({
                                id: id,
                                score: +value,
                              })
                            )
                          : [],
                        generalFarmInformation: FormGeneralFarmInformation
                          ? FormGeneralFarmInformation?.map(
                              ({ id, value }) => ({
                                id: id,
                                score: +value,
                              })
                            )
                          : [],
                        farmPerformance: FormFarmPerformance
                          ? FormFarmPerformance?.map(({ id, value }) => ({
                              id: id,
                              score: +value,
                            }))
                          : [],
                        farmFinancialStatusAndInstallment:
                          FormFarmFinancialStatusAndInstallment
                            ? FormFarmFinancialStatusAndInstallment?.map(
                                ({ id, value }) => ({
                                  id: id,
                                  score: +value,
                                })
                              )
                            : [],
                        applicationUsage: FormApplicationUsage
                          ? FormApplicationUsage?.map(({ id, value }) => ({
                              id: id,
                              score: +value,
                            }))
                          : [],
                      });
                    } else {
                      await CalculateFarmerCredit.mutateAsync({
                        id: idFramers,
                        creditInformation: {
                          farmSize: get(form, [
                            "tabFifth_farmSize",
                            0,
                            "value",
                          ]),
                          houseId: get(form, [
                            "tabFifth_greenHouse",
                            0,
                            "value",
                          ]),
                          // dataCalculateCredit.creditInformation.houseId,
                          creditAmount: +get(form, [
                            "tabFifth_creditLineOfferedToCustomer",
                          ]),
                          contractType: get(form, [
                            "tabFifth_contractType",
                            0,
                            "value",
                          ]),
                          creditPeriod: +get(form, ["tabFifth_timeCredit"]),
                          reason: " ",
                        },
                        creditSettingId: form.tabFirst_versionCredit[0]?.value,
                        farmerType: form.tabFirst_businessType[0]?.value,
                        generalPersonalInformation:
                          FormGeneralPersonalInformation
                            ? FormGeneralPersonalInformation?.map(
                                ({ id, value }) => ({
                                  id: id,
                                  score: +value,
                                })
                              )
                            : [],
                        generalLegalInformation: FormGeneralLegalInformation
                          ? FormGeneralLegalInformation?.map(
                              ({ id, value }) => ({
                                id: id,
                                score: +value,
                              })
                            )
                          : [],
                        generalFarmInformation: FormGeneralFarmInformation
                          ? FormGeneralFarmInformation?.map(
                              ({ id, value }) => ({
                                id: id,
                                score: +value,
                              })
                            )
                          : [],
                        farmPerformance: FormFarmPerformance
                          ? FormFarmPerformance?.map(({ id, value }) => ({
                              id: id,
                              score: +value,
                            }))
                          : [],
                        farmFinancialStatusAndInstallment:
                          FormFarmFinancialStatusAndInstallment
                            ? FormFarmFinancialStatusAndInstallment?.map(
                                ({ id, value }) => ({
                                  id: id,
                                  score: +value,
                                })
                              )
                            : [],
                        applicationUsage: FormApplicationUsage
                          ? FormApplicationUsage?.map(({ id, value }) => ({
                              id: id,
                              score: +value,
                            }))
                          : [],
                      });
                    }
                    // else {
                    //   await CalculateFarmerCredit.mutate({
                    //     id: idFramers,
                    //     creditInformation: {
                    //       farmSize: get(form, [
                    //         "tabFifth_farmSize",
                    //         0,
                    //         "value",
                    //       ]),
                    //       houseId: get(form, [
                    //         "tabFifth_greenHouse",
                    //         0,
                    //         "value",
                    //       ]),
                    //       creditAmount: +get(form, [
                    //         "tabFifth_creditLineOfferedToCustomer",
                    //       ]),
                    //       contractType: get(form, [
                    //         "tabFifth_contractType",
                    //         0,
                    //         "value",
                    //       ]),
                    //       creditPeriod: +get(form, ["tabFifth_timeCredit"]),
                    //       reason: " ",
                    //     },
                    //     creditSettingId:
                    //       form.tabFirst_versionCredit[0]?.value,
                    //     farmerType: form.tabFirst_businessType[0]?.value,
                    //     generalPersonalInformation:
                    //       FormGeneralPersonalInformation
                    //         ? FormGeneralPersonalInformation?.map(
                    //             ({ id, value }) => ({
                    //               id: id,
                    //               score: +value,
                    //             })
                    //           )
                    //         : [],
                    //     generalLegalInformation: FormGeneralLegalInformation
                    //       ? FormGeneralLegalInformation?.map(
                    //           ({ id, value }) => ({
                    //             id: id,
                    //             score: +value,
                    //           })
                    //         )
                    //       : [],
                    //     generalFarmInformation: FormGeneralFarmInformation
                    //       ? FormGeneralFarmInformation?.map(
                    //           ({ id, value }) => ({
                    //             id: id,
                    //             score: +value,
                    //           })
                    //         )
                    //       : [],
                    //     farmPerformance: FormFarmPerformance
                    //       ? FormFarmPerformance?.map(({ id, value }) => ({
                    //           id: id,
                    //           score: +value,
                    //         }))
                    //       : [],
                    //     farmFinancialStatusAndInstallment:
                    //       FormFarmFinancialStatusAndInstallment
                    //         ? FormFarmFinancialStatusAndInstallment?.map(
                    //             ({ id, value }) => ({
                    //               id: id,
                    //               score: +value,
                    //             })
                    //           )
                    //         : [],
                    //     applicationUsage: FormApplicationUsage
                    //       ? FormApplicationUsage?.map(({ id, value }) => ({
                    //           id: id,
                    //           score: +value,
                    //         }))
                    //       : [],
                    //   });
                    // }
                  }
                }
              } catch (error) {
                setTimeout(() => {
                  dispatch(alertAction.hide());
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: getErrorMessage(error),
                      type: "error",
                    })
                  );
                }, 500);
              }
            },
          })
        );
      }
    },
  });

  const onCloseFn = useCallback(() => {
    onClose();
    setTabIndex(0);
    setForm(defaultFormTabFarmer);
    setFormGeneralPersonalInformation([]);
    setFormGeneralLegalInformation([]);
    setFormGeneralFarmInformation([]);
    setFormFarmPerformance([]);
    setFormFarmFinancialStatusAndInstallment([]);
    setFormApplicationUsage([]);
    resetError();
    resetError2();
    setResponseDataCalGrade(undefined);
  }, []);

  useEffect(() => {
    onChangeForm("tabFifth_grade")(
      responseDataCalGrade?.customerGrade.grade.toLocaleUpperCase()
    );
  }, [responseDataCalGrade?.customerGrade.grade, onChangeForm]);

  useEffect(() => {
    if (isOpen && idFramers) {
      getFarmAndHouseMutation.mutate(idFramers);
    } else if (isOpen && dataEdit) {
      getFarmAndHouseMutation.mutate(get(dataEdit, ["id"], ""));
    }
  }, [isOpen, idFramers, dataEdit]);

  const [hasInitialized, setHasInitialized] = useState(false);
  const [isManualVersionCreditChange, setIsManualVersionCreditChange] =
    useState(false); // เพิ่ม state เพื่อตรวจจับการเปลี่ยนค่าเวอร์ชั่นเครดิตด้วยมือ

  useEffect(() => {
    if (
      (isOpen && listCreditSettingById) ||
      (isOpen && isEdit && dataEdit) ||
      (isOpen && isCalculateCredit && dataCalculateCredit)
    ) {
      setForm((prev) => {
        const isVersionCreditSet = prev.tabFirst_versionCredit.length > 0;
        const isBusinessTypeSet = prev.tabFirst_businessType.length > 0;

        if (dataCalculateCredit) {
          return {
            ...prev,
            tabFirst_versionCredit: isVersionCreditSet
              ? prev.tabFirst_versionCredit
              : selectVersionCreditOption.filter(
                  (i) => i.value === dataCalculateCredit.creditSettingId
                ),
            tabFirst_businessType: isBusinessTypeSet
              ? prev.tabFirst_businessType
              : businessTypeOption.filter(
                  (i) => i.value === dataCalculateCredit.farmerType
                ),
          };
        } else
          return {
            ...prev,
            tabFirst_versionCredit: isVersionCreditSet
              ? prev.tabFirst_versionCredit
              : selectVersionCreditOption.filter(
                  (i) =>
                    i.value ===
                    (isEdit
                      ? dataEdit?.creditSettingId
                      : listCreditSettingById?.creditSettingId)
                ),
            tabFirst_businessType: isBusinessTypeSet
              ? prev.tabFirst_businessType
              : businessTypeOption.filter(
                  (i) =>
                    i.value ===
                    (isEdit
                      ? dataEdit?.farmerType
                      : listCreditSettingById?.farmerType)
                ),
            tabFifth_countPig: isEdit
              ? get(dataEdit, ["fattenerPigCount"], 0).toString()
              : prev.tabFifth_countPig,
            tabFifth_customerCreditLimitCalculatedByTheSystem: isEdit
              ? get(dataEdit, ["estimateFinancialAmount"], 0).toString()
              : prev.tabFifth_countPig,
          };
      });

      const sourceData = isEdit ? dataEdit : listCreditSettingById;

      const sourceCal = dataCalculateCredit;

      if (sourceData) {
        // เซ็ตข้อมูลฟอร์มอื่นๆ จาก sourceData
        setFormGeneralPersonalInformation(
          sourceData?.generalPersonalInformation?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );
        setFormGeneralLegalInformation(
          sourceData?.generalLegalInformation?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );
        setFormGeneralFarmInformation(
          sourceData?.generalFarmInformation?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );
        setFormFarmPerformance(
          sourceData?.farmPerformance?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );
        setFormFarmFinancialStatusAndInstallment(
          sourceData?.farmFinancialStatusAndInstallment?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );
        setFormApplicationUsage(
          sourceData?.applicationUsage?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );

        setHasInitialized(true);
        setIsManualVersionCreditChange(false);
      } else if (sourceCal) {
        // setFormGeneralPersonalInformation(
        //   sourceCal?.generalPersonalInformation?.map((i) => ({
        //     id: i.id,
        //     label: i.option,
        //     value: i.score.toString(),
        //   })) ?? []
        // );
        // setFormGeneralLegalInformation(
        //   sourceCal?.generalLegalInformation?.map((i) => ({
        //     id: i.id,
        //     label: i.option,
        //     value: i.score.toString(),
        //   })) ?? []
        // );
        setFormGeneralFarmInformation(
          sourceCal?.generalFarmInformation?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );
        setFormFarmPerformance(
          sourceCal?.farmPerformance?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );
        setFormFarmFinancialStatusAndInstallment(
          sourceCal?.farmFinancialStatusAndInstallment?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );
        setFormApplicationUsage(
          sourceCal?.applicationUsage?.map((i) => ({
            id: i.id,
            label: i.option,
            value: i.score.toString(),
          })) ?? []
        );

        setHasInitialized(true);
        setIsManualVersionCreditChange(false);
      } else {
        console.error("data is not defined");
      }
    }
  }, [
    isOpen,
    listCreditSettingById,
    businessTypeOption,
    selectVersionCreditOption,
    isEdit,
    dataEdit,
    isCalculateCredit,
    dataCalculateCredit,
  ]);

  // Reset เมื่อปิด form
  useEffect(() => {
    if (!isOpen) {
      setHasInitialized(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isManualVersionCreditChange && form.tabFirst_versionCredit.length > 0) {
      setForm((prev) => ({
        ...defaultFormTabFarmer,
        tabFirst_versionCredit: prev.tabFirst_versionCredit, // ยกเว้นค่า versionCredit
      }));

      setFormGeneralLegalInformation([]);
      setFormGeneralPersonalInformation([]);
      setFormGeneralFarmInformation(
        new Array(Math.abs(dropdownValuesGeneralFarmInformation.length)).fill(
          undefined
        )
      );
      setFormFarmPerformance(
        new Array(Math.abs(dropdownValuesFarmPerformance.length)).fill(
          undefined
        )
      );
      setFormFarmFinancialStatusAndInstallment(
        new Array(
          Math.abs(dropdownValuesFarmFinancialStatusAndInstallment.length)
        ).fill(undefined)
      );
      setFormApplicationUsage(
        new Array(Math.abs(dropdownValuesApplicationUsage.length)).fill(
          undefined
        )
      );
      resetError();
    }
  }, [form.tabFirst_versionCredit, isManualVersionCreditChange]);

  const handleVersionCreditChange = (newVersionCredit: TDropdownValue[]) => {
    setForm((prev) => ({
      ...prev,
      tabFirst_versionCredit: newVersionCredit,
    }));
    setFormGeneralPersonalInformation([]);
    setFormGeneralLegalInformation([]);

    setIsManualVersionCreditChange(true);
  };

  useEffect(() => {
    if (form.tabFifth_farmName) {
      setForm((prev) => ({
        ...prev,
        tabFifth_greenHouse: [],
        tabFifth_countPig: "",
        tabFifth_customerCreditLimitCalculatedByTheSystem: "",
      }));
    }
  }, [form.tabFifth_farmName]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.containerModal}
      classes={{ paper: "w-[100vw] !max-w-[1311px]" }}
    >
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <p className={classes.title}>
              {t("financialSupport.creditFeed.applyCreditModal.title")}
            </p>
            <IconButton onClick={onCloseFn}>
              <CrossIcon />
            </IconButton>
          </div>
          {tabIndex === 0 && (
            <div className="flex flex-col gap-[6px] w-[391.67px] h-[58px]">
              {/* <Dropdown
                label="เวอร์ชั่นเครดิต"
                options={selectVersionCreditOption}
                onChange={onChangeForm("tabFirst_versionCredit")}
                renderValueArray={form.tabFirst_versionCredit}
                placeholder="เลือก"
                errorMessage={errors.tabFirst_versionCredit}
              /> */}
              <Dropdown
                label="เวอร์ชั่นเครดิต"
                options={selectVersionCreditOption}
                onChange={(selectedOption) =>
                  handleVersionCreditChange(selectedOption)
                }
                renderValueArray={form.tabFirst_versionCredit}
                placeholder="เลือก"
                errorMessage={errors.tabFirst_versionCredit}
              />
            </div>
          )}
          <Tabs tabIndex={tabIndex} onChange={onChangeTab} isScroll>
            <Tab
              label={`1.${t(
                "financialSupport.creditFeed.applyCreditModal.generalInformation"
              )}`}
            />
            <Tab
              label={`2.${t(
                "financialSupport.creditFeed.applyCreditModal.farmPerformance"
              )}`}
            />
            <Tab
              label={`3.${t(
                "financialSupport.creditFeed.applyCreditModal.personalFinancialStatus"
              )}`}
            />
            <Tab
              label={`4.${t(
                "financialSupport.creditFeed.applyCreditModal.applicationUsage"
              )}`}
            />
            <Tab
              label={`5.${t(
                "financialSupport.creditFeed.applyCreditModal.creditOfferDetail"
              )}`}
              disabled={isUpdateCreditFarmDetail}
            />
            <Tab
              label={`6.${t(
                "financialSupport.creditFeed.applyCreditModal.summary"
              )}`}
            />
          </Tabs>
          {tabIndex === 0 && (
            <div className="flex flex-col gap-6 pt-6">
              <div className={classes.rowContainer}>
                <Dropdown
                  options={businessTypeOption}
                  onChange={onChangeForm("tabFirst_businessType")}
                  renderValueArray={form.tabFirst_businessType}
                  placeholder="เลือก"
                  label="ประเภทธุรกิจ"
                  disabled={isEmpty(form.tabFirst_versionCredit)}
                  errorMessage={errors.tabFirst_businessType}
                />
                <Dropdown
                  options={[]}
                  onChange={() => {}}
                  renderValueArray={[]}
                  options2={typeLegalOptions[0]?.options?.map((row) => ({
                    ...row,
                    id: typeLegalOptions[0]?.id,
                  }))}
                  onChange2={(e) =>
                    handleDropdownChange(
                      e,
                      0,
                      onChangeFormGeneralLegalInformation
                    )
                  }
                  renderValueArray2={FormGeneralLegalInformation}
                  isUseChangeValue2
                  index={0}
                  placeholder="เลือก"
                  label="ประเภทนิติบุคคล"
                  disabled={
                    isEmpty(form.tabFirst_versionCredit) ||
                    isEmpty(form.tabFirst_businessType) ||
                    form.tabFirst_businessType[0]?.value === "personal"
                  }
                  errorMessage={errors[`FormGeneralLegalInformation[${0}].id`]}
                />
                <Dropdown
                  options={[]}
                  onChange={() => {}}
                  renderValueArray={[]}
                  options2={typePersonalOptions[0]?.options?.map((row) => ({
                    ...row,
                    id: typePersonalOptions[0]?.id,
                  }))}
                  onChange2={(e) =>
                    handleDropdownChange(
                      e,
                      0,
                      onChangeFormGeneralPersonalInformation
                    )
                  }
                  renderValueArray2={FormGeneralPersonalInformation}
                  isUseChangeValue2
                  index={0}
                  placeholder="เลือก"
                  label="ประเภทบุคคลธรรมดา/คณะบุคคล"
                  disabled={
                    isEmpty(form.tabFirst_versionCredit) ||
                    isEmpty(form.tabFirst_businessType) ||
                    form.tabFirst_businessType[0]?.value === "legal"
                  }
                  errorMessage={
                    errors[`FormGeneralPersonalInformation[${0}].id`]
                  }
                />
                <Dropdown
                  options={[]}
                  onChange={() => {}}
                  renderValueArray={[]}
                  options2={agePersonalOptions[0]?.options?.map((row) => ({
                    ...row,
                    id: agePersonalOptions[0]?.id,
                  }))}
                  onChange2={(e) =>
                    handleDropdownChange(
                      e,
                      1,
                      onChangeFormGeneralPersonalInformation
                    )
                  }
                  renderValueArray2={FormGeneralPersonalInformation}
                  isUseChangeValue2
                  index={1}
                  placeholder="เลือก"
                  label="อายุ"
                  disabled={
                    isEmpty(form.tabFirst_versionCredit) ||
                    isEmpty(form.tabFirst_businessType) ||
                    form.tabFirst_businessType[0]?.value === "legal"
                  }
                  errorMessage={
                    errors[`FormGeneralPersonalInformation[${1}].id`]
                  }
                />
              </div>
              {(dropdownValuesGeneralFarmInformation.length || 0) > 0 && (
                <div className={classes.rowContainer}>
                  {dropdownValuesGeneralFarmInformation.map(
                    ({ id, label, options }, index) => (
                      <Dropdown
                        key={id + index}
                        label={label}
                        options={[]}
                        onChange={() => {}}
                        renderValueArray={[]}
                        options2={options.map((row) => ({ ...row, id }))}
                        onChange2={(e) =>
                          handleDropdownChange(
                            e,
                            index,
                            onChangeFormGeneralFarmInformation
                          )
                        }
                        renderValueArray2={FormGeneralFarmInformation}
                        isUseChangeValue2
                        containerClassName="justify-end"
                        index={index}
                        errorMessage={
                          errors[`FormGeneralFarmInformation[${index}].id`]
                        }
                      />
                    )
                  )}
                </div>
              )}
            </div>
          )}
          {tabIndex === 1 && (
            <div className="flex flex-col gap-6 pt-6">
              {(dropdownValuesFarmPerformance.length || 0) > 0 && (
                <div className={classes.rowContainer}>
                  {dropdownValuesFarmPerformance.map(
                    ({ id, label, options }, index) => (
                      <Dropdown
                        key={id + index}
                        label={label}
                        options={[]}
                        onChange={() => {}}
                        renderValueArray={[]}
                        options2={options.map((row) => ({ ...row, id }))}
                        onChange2={(e) =>
                          handleDropdownChange(
                            e,
                            index,
                            onChangeFormFarmPerformance
                          )
                        }
                        renderValueArray2={FormFarmPerformance}
                        isUseChangeValue2
                        containerClassName="justify-end"
                        index={index}
                        errorMessage={
                          errors[`FormFarmPerformance[${index}].id`]
                        }
                      />
                    )
                  )}
                </div>
              )}
            </div>
          )}
          {tabIndex === 2 && (
            <div className="flex flex-col gap-6 pt-6">
              {(dropdownValuesFarmFinancialStatusAndInstallment.length || 0) >
                0 && (
                <div className={classes.rowContainer}>
                  {dropdownValuesFarmFinancialStatusAndInstallment.map(
                    ({ id, label, options }, index) => (
                      <Dropdown
                        key={id + index}
                        label={label}
                        options={[]}
                        onChange={() => {}}
                        renderValueArray={[]}
                        options2={options.map((row) => ({ ...row, id }))}
                        onChange2={(e) =>
                          handleDropdownChange(
                            e,
                            index,
                            onChangeFormFarmFinancialStatusAndInstallment
                          )
                        }
                        renderValueArray2={
                          FormFarmFinancialStatusAndInstallment
                        }
                        isUseChangeValue2
                        containerClassName="justify-end"
                        index={index}
                        errorMessage={
                          errors[
                            `FormFarmFinancialStatusAndInstallment[${index}].id`
                          ]
                        }
                      />
                    )
                  )}
                </div>
              )}
            </div>
          )}
          {tabIndex === 3 && (
            <div className="flex flex-col gap-6 pt-6">
              {(dropdownValuesApplicationUsage.length || 0) > 0 && (
                <div className={classes.rowContainer}>
                  {dropdownValuesApplicationUsage.map(
                    ({ id, label, options }, index) => (
                      <Dropdown
                        key={id + index}
                        label={label}
                        options={[]}
                        onChange={() => {}}
                        renderValueArray={[]}
                        options2={options.map((row) => ({ ...row, id }))}
                        onChange2={(e) =>
                          handleDropdownChange(
                            e,
                            index,
                            onChangeFormApplicationUsage
                          )
                        }
                        renderValueArray2={FormApplicationUsage}
                        isUseChangeValue2
                        containerClassName="justify-end"
                        index={index}
                        errorMessage={
                          errors[`FormApplicationUsage[${index}].id`]
                        }
                      />
                    )
                  )}
                </div>
              )}
            </div>
          )}
          {tabIndex === 4 && (
            <div className="flex flex-col gap-6 pt-6">
              <div className={classes.rowContainer}>
                <Dropdown
                  options={farmSizeOptions}
                  onChange={onChangeForm("tabFifth_farmSize")}
                  renderValueArray={form.tabFifth_farmSize}
                  placeholder="เลือก"
                  label="ขนาดฟาร์ม"
                  errorMessage={errors2.tabFifth_farmSize}
                />
                {isCredit &&
                  (isEdit && !isCalculateCredit ? (
                    <>
                      <Input
                        label="ฟาร์ม"
                        value={dataEdit?.farmName || ""}
                        onChangeText={() => {}}
                        placeholder=""
                        disabled
                      />
                      <Input
                        label="โรงเรือน"
                        value={dataEdit?.houseName || ""}
                        onChangeText={() => {}}
                        placeholder=""
                        disabled
                      />
                    </>
                  ) : (
                    <>
                      <Dropdown
                        options={farmOptions}
                        onChange={onChangeForm("tabFifth_farmName")}
                        renderValueArray={form.tabFifth_farmName}
                        placeholder="เลือก"
                        label="ฟาร์ม"
                        errorMessage={errors2.tabFifth_farmName}
                      />
                      <Dropdown
                        options={get(
                          form,
                          ["tabFifth_farmName", 0, "houses"],
                          []
                        )}
                        onChange={(value) => {
                          setForm((prev) => ({
                            ...prev,
                            tabFifth_greenHouse: value,
                            tabFifth_countPig: `${getCommaNumber(
                              get(value, [0, "fattenerPigCount"], "")
                            )}`,
                            tabFifth_customerCreditLimitCalculatedByTheSystem: `${getCommaNumber(
                              get(value, [0, "estimateFinancialAmount"], ""),
                              true
                            )}`,
                          }));
                        }}
                        renderValueArray={form.tabFifth_greenHouse}
                        placeholder="เลือก"
                        label="โรงเรือน"
                        disabled={isEmpty(form.tabFifth_farmName)}
                        errorMessage={errors2.tabFifth_greenHouse}
                      />
                    </>
                  ))}
                {
                  !isCredit && (
                    // (isCalculateCredit ? (
                    //   <>
                    //     <Input
                    //       label="ฟาร์ม"
                    //       value={dataCalculateCredit?.farmName || ""}
                    //       onChangeText={() => {}}
                    //       placeholder=""
                    //       disabled
                    //     />
                    //     <Input
                    //       label="โรงเรือน"
                    //       value={dataCalculateCredit?.houseName || ""}
                    //       onChangeText={() => {}}
                    //       placeholder=""
                    //       disabled
                    //     />
                    //   </>
                    // ) : (
                    <>
                      <Dropdown
                        options={farmOptions}
                        onChange={onChangeForm("tabFifth_farmName")}
                        renderValueArray={form.tabFifth_farmName}
                        placeholder="เลือก"
                        label={t(
                          "financialSupport.creditFeed.creditOfferDetail.farmName"
                        )}
                        errorMessage={errors2.tabFifth_farmName}
                      />
                      <Dropdown
                        options={get(
                          form,
                          ["tabFifth_farmName", 0, "houses"],
                          []
                        )}
                        onChange={(value) => {
                          setForm((prev) => ({
                            ...prev,
                            tabFifth_greenHouse: value,
                            tabFifth_countPig: `${getCommaNumber(
                              get(value, [0, "fattenerPigCount"], "")
                            )}`,
                            tabFifth_customerCreditLimitCalculatedByTheSystem: `${getCommaNumber(
                              get(value, [0, "estimateFinancialAmount"], ""),
                              true
                            )}`,
                          }));
                        }}
                        renderValueArray={form.tabFifth_greenHouse}
                        placeholder="เลือก"
                        label={t(
                          "financialSupport.creditFeed.creditOfferDetail.houseName"
                        )}
                        disabled={isEmpty(form.tabFifth_farmName)}
                        errorMessage={errors2.tabFifth_greenHouse}
                      />
                    </>
                  )
                  //   )
                  // )
                }

                <Input
                  label={t(
                    "financialSupport.creditFeed.creditOfferDetail.pigType"
                  )}
                  value={form.tabFifth_pigType}
                  onChangeText={onChangeForm("tabFifth_pigType")}
                  disabled
                />
              </div>
              <div className={classes.rowContainer}>
                <Input
                  label={t(
                    "financialSupport.creditFeed.creditOfferDetail.numberOfPig"
                  )}
                  value={form.tabFifth_countPig}
                  onChangeText={onChangeForm("tabFifth_countPig")}
                  placeholder=""
                  disabled
                  suffix="ตัว"
                />
                <Input
                  label={t(
                    "financialSupport.creditFeed.creditOfferDetail.grading"
                  )}
                  value={
                    responseDataCalGrade?.customerGrade.grade.toLocaleUpperCase() ||
                    get(dataEdit, ["customerGrade"], "").toLocaleUpperCase()
                  }
                  onChangeText={onChangeForm("tabFifth_grade")}
                  placeholder=""
                  disabled
                />
                <Dropdown
                  options={contractOptions}
                  onChange={onChangeForm("tabFifth_contractType")}
                  renderValueArray={form.tabFifth_contractType}
                  placeholder="เลือก"
                  label={t(
                    "financialSupport.creditFeed.creditOfferDetail.contractType"
                  )}
                  errorMessage={errors2.tabFifth_contractType}
                />
                <Input
                  label={t(
                    "financialSupport.creditFeed.creditOfferDetail.creditTerm"
                  )}
                  value={form.tabFifth_timeCredit}
                  onChangeText={onChangeForm("tabFifth_timeCredit")}
                  suffix="วัน"
                  inputType="number"
                  errorMessage={errors2.tabFifth_timeCredit}
                />
              </div>
              <div className={classes.rowContainer}>
                <Input
                  label="วงเงินลูกค้าที่คำนวณจากระบบ"
                  value={form.tabFifth_customerCreditLimitCalculatedByTheSystem}
                  onChangeText={onChangeForm(
                    "tabFifth_customerCreditLimitCalculatedByTheSystem"
                  )}
                  placeholder=""
                  disabled
                  suffix="บาท"
                  inputType="number"
                />
                <Input
                  label="วงเงินที่นำเสนอลูกค้า"
                  value={form.tabFifth_creditLineOfferedToCustomer}
                  onChangeText={onChangeForm(
                    "tabFifth_creditLineOfferedToCustomer"
                  )}
                  suffix="บาท"
                  inputType="number"
                  errorMessage={errors2.tabFifth_creditLineOfferedToCustomer}
                />
              </div>
              {(!isCalculateCredit || !isUpdateCreditFarmDetail) && (
                <>
                  <div className="flex flex-col">
                    <span className="font-medium text-[#191919] mb-[6px] text-[14px] font-sans">
                      เหตุผล
                    </span>
                    <TextAreaCustom
                      placeholder="กรอก"
                      value={form.tabFifth_reason}
                      onChange={(e: any) =>
                        onChangeForm("tabFifth_reason")(e.target.value)
                      }
                      errormessage={errors2.tabFifth_reason}
                    />
                    <div className="w-full flex justify-end pt-[2px]">
                      <span className="font-normal text-[12px] text-[#979797]">
                        (maximum 500 characters)
                      </span>
                    </div>
                  </div>
                  <div>
                    <p className="font-medium text-[#191919] mb-[6px] text-[14px] font-sans">
                      เอกสารการคำนวณเครดิต
                    </p>
                    <ListOfFile
                      files={form.tabFifth_creditCalculationDocuments}
                      onDownload={handleDownload}
                      onDelete={async (idx) => {
                        onChangeForm("tabFifth_creditCalculationDocuments")([]);
                      }}
                    />
                    <DragAndDropUploadImage
                      onUpload={(files) => {
                        onChangeForm("tabFifth_creditCalculationDocuments")(
                          files
                        );
                      }}
                      type={{ "application/pdf": [".pdf"] }}
                    />
                  </div>
                </>
              )}
            </div>
          )}
          {tabIndex === 5 && (
            <div className="flex flex-col gap-6 pt-6">
              <div className={classes.text}>
                {form.tabFirst_versionCredit[0]?.label || ""}
              </div>
              <AccordionFillData
                accordionTitle="General"
                showData={[
                  {
                    label: "ประเภทธุรกิจ",
                    value: form.tabFirst_businessType?.[0]?.label || "-",
                  },
                  {
                    label: "ประเภทนิติบุคคล",
                    value:
                      form.tabFirst_businessType?.[0]?.value === "legal"
                        ? FormGeneralLegalInformation[0]?.label || "-"
                        : "-",
                  },
                  {
                    label: "ประเภทบุคคลธรรมดา/คณะบุคคล",
                    value:
                      form.tabFirst_businessType?.[0]?.value === "personal"
                        ? FormGeneralPersonalInformation[0]?.label || "-"
                        : "-",
                  },
                  {
                    label: "อายุ",
                    value:
                      form.tabFirst_businessType?.[0]?.value === "personal"
                        ? FormGeneralPersonalInformation[1]?.label || "-"
                        : "-",
                  },
                  ...(getShowDataGeneralFarmInformation() as {
                    label: string;
                    value: string;
                  }[]),
                ]}
              />
              <AccordionFillData
                accordionTitle="Farm Performance"
                showData={
                  getShowDataFarmPerformance() as {
                    label: string;
                    value: string;
                  }[]
                }
              />
              <AccordionFillData
                accordionTitle="Farm Financial Status & Installment"
                showData={
                  getShowDataFarmFinancialStatusAndInstallment() as {
                    label: string;
                    value: string;
                  }[]
                }
              />
              <AccordionFillData
                accordionTitle="Application Usage"
                showData={
                  getShowDataApplicationUsage() as {
                    label: string;
                    value: string;
                  }[]
                }
              />
              <AccordionFillData
                accordionTitle="Credit Information"
                showData={[
                  {
                    label: "ขนาดฟาร์ม",
                    value: get(form, ["tabFifth_farmSize", 0, "label"], ""),
                  },
                  {
                    label: "ฟาร์ม",
                    value: get(form, ["tabFifth_farmName", 0, "label"], ""),
                  },
                  {
                    label: "โรงเรือน",
                    value: get(form, ["tabFifth_greenHouse", 0, "label"], ""),
                  },
                  {
                    label: "ประเภทหมู",
                    value: get(form, ["tabFifth_pigType"], ""),
                  },
                  {
                    label: "จำนวนหมู",
                    value:
                      getCommaNumber(+get(form, ["tabFifth_countPig"], "")) +
                        " ตัว" || "",
                  },
                  {
                    label: "เกรดลูกค้า",
                    value: get(form, ["tabFifth_grade"], ""),
                  },
                  {
                    label: "ประเภทสัญญา",
                    value: get(form, ["tabFifth_contractType", 0, "label"], ""),
                  },
                  {
                    label: "ระยะเครดิต",
                    value:
                      getCommaNumber(+get(form, ["tabFifth_timeCredit"], "")) +
                        " วัน" || "",
                  },
                  {
                    label: "วงเงินลูกค้าที่คำนวณจากระบบ",
                    value:
                      getCommaNumber(
                        +get(form, [
                          "tabFifth_customerCreditLimitCalculatedByTheSystem",
                        ]),
                        true
                      ) + " บาท" || "",
                  },
                  {
                    label: "วงเงินที่นำเสนอลูกค้า",
                    value:
                      getCommaNumber(
                        +get(form, ["tabFifth_creditLineOfferedToCustomer"]),
                        true
                      ) + " บาท" || "",
                  },
                  {
                    label: "เหตุผล",
                    value: get(form, ["tabFifth_reason"], ""),
                  },
                ]}
              />
              {get(form, ["tabFifth_creditCalculationDocuments"]).length >
                0 && (
                <div className="flex flex-col gap-3">
                  <div
                    className={tw(
                      "text-[#7E7E7E] font-medium text-[14px] font-sans",
                      "text-[#000000]"
                    )}
                  >
                    เอกสารการคำนวณเครดิต
                  </div>
                  {get(form, ["tabFifth_creditCalculationDocuments"]) &&
                    get(form, ["tabFifth_creditCalculationDocuments"], [])?.map(
                      (row) => {
                        return (
                          <DownloadLinkWithDelete
                            hideDeleteBtn
                            onDownload={() => handleDownload(row)}
                            name={row.name}
                          />
                        );
                      }
                    )}
                </div>
              )}
            </div>
          )}
          <div className="flex flex-row justify-end gap-[10px]">
            {tabIndex !== 0 && (
              <ButtonOutlinedCustom
                title={"ย้อนกลับ"}
                btnTextColor="#68C184"
                btnBorderColor="#68C184"
                onClick={onClickPrev}
              />
            )}
            {tabIndex === maxIndex ? (
              <ButtonFillCustom title={"ยืนยัน"} onClick={onSubmit2} />
            ) : (
              <ButtonFillCustom
                title={"ถัดไป"}
                onClick={
                  isEmpty(form.tabFirst_versionCredit)
                    ? onSubmit
                    : tabIndex <= 3
                    ? handleSubmit
                    : onClickNext
                }
                disabled={some([form.tabFirst_versionCredit], isEmpty)}
              />
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ModalApplyCredit);
