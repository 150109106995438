import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import RHFTextInputCustom from "../../../components/Input/RHFTextInputCustom";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import AdminModal from "../../../components/Modal/AdminModal";
import RHFSelectCustom from "../../../components/Select/RHFSelectCustom";
import {
  createSaleTarget,
  editSaleTarget,
  getSaleTargetYear,
} from "../services/saleTarget.service";
import { ITarget } from "../types/SaleTargetTable.type";
import { unitTypeSaleOrderEnum } from "./DetailSaleTarget";

type Props = {
  data?: ITarget;
  tabName: string;
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
};

export const unitEnum: Record<string, string> = {
  คน: "people",
  บาท: "currency_baht",
  ตัน: "ton",
};

export const getUnitByTab = (tabName: string) => {
  const unitMap: Record<string, string> = {
    user: "คน",
    customer: "คน",
    feed_volume: "ตัน",
    feed_revenue: "บาท",
    basic_partner: "คน",
    core_partner: "คน",
  };
  return unitMap[tabName];
};

export type ICreateSaleTarget = {
  targetType: string;
  year: string;
  unit: string;
  janAmount: number;
  febAmount: number;
  marAmount: number;
  aprAmount: number;
  mayAmount: number;
  junAmount: number;
  julAmount: number;
  augAmount: number;
  sepAmount: number;
  octAmount: number;
  novAmount: number;
  decAmount: number;
};

const CreateSaleTarget = ({ tabName, isOpen, handleClose, data }: Props) => {
  const dispatch = useDispatch();
  const [year, setYear] = useState<string[]>();

  const handleSubmit = (value: ICreateSaleTarget) => {
    const unit = getUnitByTab(tabName);
    const mappedValue = {
      unit: unitEnum[unit],
      targetType: tabName,
      year: value.year.toString(),
      january: +value.janAmount,
      february: +value.febAmount,
      march: +value.marAmount,
      april: +value.aprAmount,
      may: +value.mayAmount,
      june: +value.junAmount,
      july: +value.julAmount,
      august: +value.augAmount,
      september: +value.sepAmount,
      october: +value.octAmount,
      november: +value.novAmount,
      december: +value.decAmount,
    };

    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            if (data) {
              await editSaleTarget(data?.id, mappedValue);
            } else {
              await createSaleTarget(mappedValue);
            }
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const methods = useForm<ICreateSaleTarget>({
    defaultValues: {
      targetType: "",
      year: "",
      unit: getUnitByTab(tabName),
      janAmount: 0,
      febAmount: 0,
      marAmount: 0,
      aprAmount: 0,
      mayAmount: 0,
      junAmount: 0,
      julAmount: 0,
      augAmount: 0,
      sepAmount: 0,
      octAmount: 0,
      novAmount: 0,
      decAmount: 0,
    },
  });

  const defaultForm = [
    { label: "มกราคม", field: "janAmount", unit: getUnitByTab(tabName) },
    { label: "กุมภาพันธ์", field: "febAmount", unit: getUnitByTab(tabName) },
    { label: "มีนาคม", field: "marAmount", unit: getUnitByTab(tabName) },
    { label: "เมษายน", field: "aprAmount", unit: getUnitByTab(tabName) },
    { label: "พฤษภาคม", field: "mayAmount", unit: getUnitByTab(tabName) },
    { label: "มิถุนายน", field: "junAmount", unit: getUnitByTab(tabName) },
    { label: "กรกฎาคม", field: "julAmount", unit: getUnitByTab(tabName) },
    { label: "สิงหาคม", field: "augAmount", unit: getUnitByTab(tabName) },
    { label: "กันยายน", field: "sepAmount", unit: getUnitByTab(tabName) },
    { label: "ตุลาคม", field: "octAmount", unit: getUnitByTab(tabName) },
    { label: "พฤศจิกายน", field: "novAmount", unit: getUnitByTab(tabName) },
    { label: "ธันวาคม", field: "decAmount", unit: getUnitByTab(tabName) },
  ];

  const watchFields = methods.watch();

  const totalAmount = useMemo(() => {
    return (
      +watchFields.janAmount +
      +watchFields.febAmount +
      +watchFields.marAmount +
      +watchFields.aprAmount +
      +watchFields.mayAmount +
      +watchFields.junAmount +
      +watchFields.julAmount +
      +watchFields.augAmount +
      +watchFields.sepAmount +
      +watchFields.octAmount +
      +watchFields.novAmount +
      +watchFields.decAmount
    );
  }, [watchFields]);

  useEffect(() => {
    if (isOpen && data) {
      methods.reset({
        targetType: tabName,
        year: data?.year || "",
        unit: getUnitByTab(tabName),
        janAmount: data?.january || 0,
        febAmount: data?.february || 0,
        marAmount: data?.march || 0,
        aprAmount: data?.april || 0,
        mayAmount: data?.may || 0,
        junAmount: data?.june || 0,
        julAmount: data?.july || 0,
        augAmount: data?.august || 0,
        sepAmount: data?.september || 0,
        octAmount: data?.october || 0,
        novAmount: data?.november || 0,
        decAmount: data?.december || 0,
      });
    } else {
      methods.reset();
    }
  }, [isOpen, data]);

  const handleGetYear = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("targetType", tabName);
    const data = await getSaleTargetYear(searchParams);
    if (data) {
      setYear(data.result);
    }
  };

  useEffect(() => {
    handleGetYear();
  }, []);

  return (
    <>
      <AdminModal
        label={`เพิ่ม target ${unitTypeSaleOrderEnum[tabName]}`}
        isOpen={isOpen}
        size={"md"}
        handleClose={() => handleClose(false)}
        children={
          <>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <div className="my-4">
                  <div className="flex gap-3">
                    <div className="w-[50%]">
                      <p className="text-[14px] mb-3 font-sans">
                        ปี <span className="text-error-300">*</span>
                      </p>
                      <RHFSelectCustom
                        value={methods.watch("year")}
                        controlName="year"
                        menuItems={(year || []).map((item) => (
                          <MenuItem key={item} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                        renderValueArray={(year || []).map((row) => ({
                          value: row.toString(),
                          label: row.toString(),
                        }))}
                      />
                    </div>
                    <div className="w-[50%]">
                      <p className="text-[14px] mb-3 font-sans">จำนวนรวม</p>
                      <TextInputCustom
                        type="number"
                        disabled
                        value={totalAmount}
                      />
                    </div>
                  </div>
                </div>
                <TableContainer
                  component={Paper}
                  sx={{
                    scrollBehavior: "smooth",
                    overflow: "auto",
                    borderRadius: "6px",
                    boxShadow: "none",
                    border: "1px solid #E1E1E1",
                    borderBottom: "none",
                    width: "100%",
                  }}
                >
                  <Table aria-labelledby="table-show-data" size={"medium"}>
                    <TableHead
                      sx={{
                        bgcolor: "#FBFBFB",
                        borderBottom: "1px solid #E1E1E1",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            height: "50px",
                            justifyContent: "start",
                            fontFamily: "IBM Plex Sans Thai",
                            fontWeight: 700,
                            fontSize: "14px",
                            border: "0px",
                            padding: "0px 16px",
                            width: 250,
                          }}
                          align="left"
                        >
                          เดือน
                        </TableCell>
                        <TableCell
                          sx={{
                            height: "50px",
                            justifyContent: "start",
                            fontFamily: "IBM Plex Sans Thai",
                            fontWeight: 700,
                            fontSize: "14px",
                            border: "0px",
                            padding: "0px 16px",
                            width: 250,
                          }}
                          align="left"
                        >
                          จำนวน
                        </TableCell>
                        <TableCell
                          sx={{
                            height: "50px",
                            justifyContent: "start",
                            fontFamily: "IBM Plex Sans Thai",
                            fontWeight: 700,
                            fontSize: "14px",
                            border: "0px",
                            padding: "0px 16px",
                            width: 250,
                          }}
                          align="left"
                        >
                          หน่วย
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {defaultForm.map((form) => (
                        <TableRow key={form.field}>
                          <TableCell align="left">
                            <TextInputCustom
                              disabled
                              defaultValue={form.label}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <RHFTextInputCustom
                              controlName={form.field}
                              type="number"
                            />
                          </TableCell>
                          <TableCell align="left">
                            <TextInputCustom
                              disabled
                              defaultValue={form.unit}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="flex justify-end mt-4">
                  <ButtonFillCustom title={"บันทึก"} type="submit" />
                </div>
              </form>
            </FormProvider>
          </>
        }
      />
    </>
  );
};

export default CreateSaleTarget;
