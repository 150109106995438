export interface IGetDefaultFormRole {
  success: boolean;
  message: string;
  sale_lead: SaleLead;
  sale: Sale;
  lending: Lending;
  management: Management;
  admin: Admin;
}

export interface Admin {
  [key: string]: string;
  settingRole: string;
  settingGroupArea: string;
  settingUserManagementFarmer: string;
  settingUserManagementPartner: string;
  settingUserManagementFarmTech: string;
  masterDataFarmCommunity: string;
  masterDataCredit: string;
  masterDataFeed: string;
  masterDataVaccine: string;
  masterDataMedicine: string;
}

export interface Lending {
  [key: string]: string;
  saleOrderCreditHoldLending: string;
  saleOrderApproveLending: string;
  saleOrderUpcoming: string;
  saleOrderApproved: string;
  saleOrderHistory: string;
  creditFeedOnConsideration: string;
  creditFeedApproved: string;
  creditFeedHistory: string;
  creditStatus: string;
  moneyDebtAndCollectionLending: string;
  invoiceLending: string;
  creditFeedWaitingForApprove: string;
}

export interface Management {
  [key: string]: string;
  creditFeedOnConsideration: string;
  creditFeedHistory: string;
  invoiceManagement: string;
}

export interface Sale {
  [key: string]: string;
  farmDashboard: string;
  farmDetail: string;
  partnerDashboard: string;
  partnerDetail: string;
  manageStock: string;
  saleOrderCreditHoldSale: string;
  saleOrderApproveSale: string;
  saleOrderUpcoming: string;
  saleOrderApproved: string;
  saleOrderHistory: string;
  orderClaimAll: string;
  orderClaimApproved: string;
  orderClaimHistory: string;
  creditFeedAllEligible: string;
  creditFeedOnConsideration: string;
  creditFeedOnProcess: string;
  creditFeedDenying: string;
  creditFeedHistory: string;
  creditStatus: string;
  moneyDebtAndCollectionSale: string;
  masterDataFarmCommunity: string;
  invoiceSale: string;
}
export interface SaleLead {
  [key: string]: string;
  farmDashboard: string;
  farmDetail: string;
  partnerDashboard: string;
  partnerDetail: string;
  manageStock: string;
  saleOrderCreditHoldSale: string;
  saleOrderApproveSale: string;
  saleOrderUpcoming: string;
  saleOrderApproved: string;
  saleOrderHistory: string;
  orderClaimAll: string;
  orderClaimApproved: string;
  orderClaimHistory: string;
  creditFeedAllEligible: string;
  creditFeedOnConsideration: string;
  creditFeedOnProcess: string;
  creditFeedDenying: string;
  creditFeedHistory: string;
  creditStatus: string;
  moneyDebtAndCollectionSale: string;
  invoiceSale: string;
  creditFeedWaitingForApprove: string;
  creditFeedApproved: string;
}

export enum AdminEnum {
  settingRole = "setting - [role]",
  settingGroupArea = "setting - [group area]",
  settingUserManagementFarmer = "user management - [ลูกค้า]",
  settingUserManagementPartner = "user management - [พาร์ทเนอร์]",
  settingUserManagementFarmTech = "user management -  [พนักงาน]",
  masterDataFarmCommunity = "master data - [farm community]",
  masterDataCredit = "master data - [credit]",
  masterDataFeed = "master data - [feed]",
  masterDataVaccine = "master data - [vaccine]",
  masterDataMedicine = "master data - [medicine]",
}

export enum LendingEnum {
  saleOrderCreditHoldLending = "sale order - [ออเดอร์ติดเครดิต]",
  saleOrderApproveLending = "sale order - [ส่งขออนุมัติเครดิต]",
  saleOrderUpcoming = "sale order - [ออเดอร์ใหม่]",
  saleOrderApproved = "sale order - [ออเดอร์รอจัดส่ง]",
  saleOrderHistory = "sale order - [ประวัติออเดอร์]",
  creditFeedOnConsideration = "credit feed - [รออนุมัติ]",
  creditFeedApproved = "credit feed - [เครดิตที่ถูกอนุมัติเเล้ว]",
  creditFeedHistory = "credit feed - [ประวัติการให้เครดิต]",
  creditStatus = "credit status",
  moneyDebtAndCollectionLending = "money debt & collection",
  invoiceLending = "invoice",
  creditFeedWaitingForApprove = "credit feed - [รอพิจารณา]",
}

export enum ManagementEnum {
  creditFeedOnConsideration = "credit feed - [รออนุมัติ]",
  creditFeedHistory = "credit feed - [ประวัติการให้เครดิต]",
  invoiceManagement = "invoice",
}

export enum SaleEnum {
  farmDashboard = "farm dashboard",
  farmDetail = "farm detail",
  partnerDashboard = "partner dashboard",
  partnerDetail = "partner detail",
  manageStock = "manage stock",
  saleOrderCreditHoldSale = "sale order - [ออเดอร์ติดเครดิต]",
  saleOrderApproveSale = "sale order approve - [ส่งขออนุมัติเครดิต]",
  saleOrderUpcoming = "sale order - [ออเดอร์ใหม่]",
  saleOrderApproved = "sale order - [ออเดอร์รอจัดส่ง]",
  saleOrderHistory = "sale order - [ประวัติออเดอร์]",
  orderClaimAll = "order claim - [รายการเคลมทั้งหมด]",
  orderClaimApproved = "order claim - [รายการรออัปเดต]",
  orderClaimHistory = "order claim - [ประวัติการเคลม]",
  creditFeedAllEligible = "credit feed - [ลูกค้าที่ผ่านเกณฑ์ทั้งหมด]",
  creditFeedOnConsideration = "credit feed - [รอพิจารณา]",
  creditFeedOnProcess = "credit feed - [จัดทำสัญญาซื้อขาย]",
  creditFeedApproved = "credit feed - [เครดิตที่ถูกอนุมัติเเล้ว]",
  creditFeedDenying = "credit feed - [เครดิตที่ถูกไม่อนุมัติ]",
  creditFeedHistory = "credit feed - [ประวัติการให้เครดิต]",
  creditStatus = "credit status",
  moneyDebtAndCollectionSale = "money debt & collection",
  invoiceSale = "invoice",
  creditFeedWaitingForApprove = "credit feed - [รอพิจารณา]",
  masterDataFarmCommunity = "master data - [farm community]",
}
