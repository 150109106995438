import dayjs from "dayjs";
import { useState } from "react";
import { thousandComma } from "../../../../utils/common/string";
import { makeXlsx } from "../../../../utils/make-xlsx";
import {
  getPartnerDetail,
  postExportPartnerDetail,
} from "../services/partnerDetail.service";
import { IPartnerDetailTable } from "../types/partnerDetailTable.type";
import { TUsePartnerDetailFilter } from "./usePartnerDetailFilter.hook";

export const usePartnerDetailTableHook = (
  partnerDetailFilter: TUsePartnerDetailFilter
) => {
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [list, setList] = useState<IPartnerDetailTable[]>([]);
  const [count, setCount] = useState(0);

  const createData = (
    id: string,
    partnerId: string,
    partnerName: string,
    type: string,
    groupArea: string,
    province: string,
    totalPrice: string
  ): IPartnerDetailTable => {
    return {
      id,
      partnerId,
      partnerName,
      type,
      groupArea,
      province,
      totalPrice: totalPrice ? thousandComma(+totalPrice) + " บาท" : "-",
    };
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", partnerDetailFilter.searchText);
    searchParams.append("page", partnerDetailFilter.page.toString());
    searchParams.append("limit", partnerDetailFilter.limit.toString());
    searchParams.append("direction", partnerDetailFilter.direction);
    searchParams.append("sellerId", partnerDetailFilter.saleName);
    searchParams.append("userGroupId", partnerDetailFilter.groupArea);
    searchParams.append("partnerType", partnerDetailFilter.partnerType);

    setList([]);
    const data = await getPartnerDetail(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.partnerCode,
          row.name + " " + row.surname,
          row.type,
          row.groupArea.name,
          row.groupArea.groupProvinces.map((group) => group.nameTh).join(" , "),
          row.totalRevenue.toString()
        );
      });

      setCount(data.count);
      setList(newList);
    }
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "รหัสพาร์ทเนอร์"
      | "ขื่อพาร์ทเนอร์"
      | "ประเภท"
      | "Group Area"
      | "จังหวัด"
      | "รายได้ทั้งหมด";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await postExportPartnerDetail(itemSelected);
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            รหัสพาร์ทเนอร์: row.partnerCode,
            ขื่อพาร์ทเนอร์: row.name + " " + row.surname,
            ประเภท: row.type,
            "Group Area": row.groupAreas,
            จังหวัด: row.provinces,
            รายได้ทั้งหมด: thousandComma(row.totalRevenue) + " บาท",
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `partner_detail_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  return {
    handleSelectAllClick,
    handleClick,
    isSelected,
    count,
    list,
    itemSelected,
    setItemSelected,
    handleFetch,
    handleExportExcel,
  };
};
