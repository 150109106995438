export default function () {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3359 7.21224V13.8789M10.0026 9.71224V13.8789M6.66927 12.2122V13.8789M5.0026 17.2122H15.0026C15.9231 17.2122 16.6693 16.466 16.6693 15.5456V5.54557C16.6693 4.6251 15.9231 3.87891 15.0026 3.87891H5.0026C4.08213 3.87891 3.33594 4.6251 3.33594 5.54557V15.5456C3.33594 16.466 4.08213 17.2122 5.0026 17.2122Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
