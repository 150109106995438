import {
  Dialog,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "../../../../components/Switch";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import {
  editActiveBrands,
  getBrands,
} from "../../../../services/FeedFoodApiService";
import { IFeedFoodBrandsResponse } from "../../../../types/FoodFeed.type";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { tw } from "../../../../utils/tw";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TableCustom from "../../../components/Table/TableCustom";
import { useTranslation } from "react-i18next";

interface ISwitchSellingModalProps {
  isOpen: boolean;
  onCloseFn: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[800px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const headerItems = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "แบรนด์อาหารสัตว์",
    isSorting: true,
    width: "auto",
    className: "w-[90%]",
  },
  {
    id: "switch",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "50px",
  },
];

const SwitchSellingModal = (props: ISwitchSellingModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onCloseFn } = props;

  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = useState(1);
  const [direction, setDirection] = useState<"desc" | "asc">("asc");
  const [brands, setBrands] = useState<IFeedFoodBrandsResponse["result"]>([]);

  const { data: initBrands, refetch } = useQuery({
    queryKey: ["food-feed", "brands", direction],
    queryFn: async () => {
      return await getBrands({ direction });
    },
  });

  const editActiveBrandsMutation = useMutation({
    mutationFn: editActiveBrands,
    onSuccess: () => {
      refetch();
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({ message: "บันทึกสำเร็จ", type: "success" })
        );
      }, 1500);
    },
    onError: (e) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(e),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onToggleOrder = useCallback(() => {
    setDirection((prev) => {
      if (prev === "desc") return "asc";
      return "desc";
    });
  }, []);

  const onToggleActive = useCallback(
    (id: string, index: number) => (active: boolean) => {
      setBrands((prev) => {
        const prevState = cloneDeep(prev);
        return set(prevState, [index, "orderFeedActive"], active);
      });
    },
    []
  );

  const tableData = useMemo(() => {
    const _data = brands;
    if (_data.length === 0) return [];
    const pageSize = 10;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = currentPage * pageSize;
    return _data.slice(startIndex, endIndex);
  }, [brands, currentPage]);

  const rowChildren = useMemo(() => {
    return tableData.map((row, index) => {
      return (
        <TableRow key={row.id} hover className="border-x-[1px] border-x-[#ddd]">
          <TableCell>{row.nameTh}</TableCell>
          <TableCell>
            <Switch
              checked={row.orderFeedActive}
              onChange={onToggleActive(row.id, index + (currentPage - 1) * 10)}
            />
          </TableCell>
        </TableRow>
      );
    });
  }, [tableData, currentPage]);

  const onSave = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "ยกเลิก",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(
            loadingActions.show({ message: "กำลังบันทึกเปิด-ปิดการขาย..." })
          );
          editActiveBrandsMutation.mutate(
            {
              feedBrands: brands.map(({ id, orderFeedActive }) => ({
                id,
                orderFeedActive,
              })),
            },
            {
              onSuccess: () => {
                onCloseFn();
              },
            }
          );
        },
      })
    );
  }, [brands]);

  useEffect(() => {
    setBrands(initBrands?.result || []);
  }, [initBrands]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>
            {t("masterData.feed.openCloseModal.title")}
          </p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <TableCustom
          orderBy="name"
          order={direction}
          onChangeOrder={onToggleOrder}
          onChangeOrderBy={() => {}}
          totalItemSize={brands.length}
          headerItems={headerItems}
          page={currentPage}
          onChangePage={setCurrentPage}
          rowsPerPage={10}
          rows={tableData}
          EmptyComponent={
            brands.length === 0 ? (
              <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
            ) : null
          }
          hideSelect
          rowChildren={rowChildren}
          currentPageItems={[]}
          itemsSelected={[]}
          onSelectAllClick={() => {}}
          onSelectItem={() => {}}
        />
        <div className="w-full flex justify-end items-end mt-6">
          <ButtonFillCustom
            title={t("common.button.confirm")}
            onClick={onSave}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(SwitchSellingModal);
