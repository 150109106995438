import { Divider, Link, SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Month } from "../../../const/utils";
import {
  getAllUserGraph,
  getAllUsersGroupArea,
  getAllUsersYear,
  getCreditComparisonGraph,
  getUserVsCustomerGraph,
} from "../../../services/dashboard/AllUsers.service";
import { ICreditCompare } from "../../../types/dashboard/AllUsers.type";
import { echartsExportPng } from "../../../utils/echarts";
import { makeXlsx } from "../../../utils/make-xlsx";
import { DownloadSvg } from "../../assets/svg/Download.svg";
import ButtonFillCustom from "../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../components/Button/ButtonOutlinedCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import { IGroupAreaRow } from "../farm-detail/types/dropdownGroupArea.type";
import { Dropdown, TDropdownValue } from "../group-area/views/Dropdown";

const AllUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const echartRef1 = useRef();
  const echartRef2 = useRef();
  const echartRef3 = useRef();

  const [allUserData, setAllUserData] = useState<number[]>([]);
  const [allUserAccumulateData, setAllUserAccumulateData] = useState<number[]>(
    []
  );

  const [creditComparisonData, setCreditComparisonData] =
    useState<ICreditCompare>();

  const [vsUser, setVsUser] = useState<number[]>([]);
  const [vsCustomer, setVsCustomer] = useState<number[]>([]);
  const [vsAccUser, setVsAccUser] = useState<number[]>([]);
  const [vsAccCustomer, setVsAccCustomer] = useState<number[]>([]);

  const [isUser, setIsUser] = useState<boolean>(true);
  const [isCustomer, setIsCustomer] = useState<boolean>(true);

  const [allUserYear, setAllUserYear] = useState<string>("");
  const [userCustomerYear, setUserCustomerYear] = useState<string>("");
  const [yearOption, setYearOption] = useState<string[]>([]);

  const [allUserGroupArea, setAllUserGroupArea] = useState<TDropdownValue[]>(
    []
  );
  const [userCustomerGroupArea, setUserCustomerGroupArea] = useState<
    TDropdownValue[]
  >([]);

  const [groupAreaOption, setGroupAreaOption] = useState<IGroupAreaRow[]>([]);

  const [allUserSegment, setAllUserSegment] = useState<TDropdownValue[]>([]);
  const [userCustomerSegment, setUserCustomerSegment] = useState<
    TDropdownValue[]
  >([]);
  const segmentOption = ["Potential", "Grower", "Independent"];

  const fetchYearOption = async () => {
    const data = await getAllUsersYear();
    setYearOption(data);
    setAllUserYear(data[0]);
    setUserCustomerYear(data[0] ?? dayjs().year().toString());
  };

  const fetchGroupAreaOption = async () => {
    const data = await getAllUsersGroupArea();
    setGroupAreaOption(data);
  };

  const fetchCreditComparison = async () => {
    const data = await getCreditComparisonGraph();
    setCreditComparisonData(data);
  };

  const fetchAllUsersGraphData = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("year", allUserYear || "");
    searchParams.append("groupAreaIds", allUserGroupArea[0]?.value || "");
    searchParams.append(
      "segments",
      allUserSegment[0]?.value.toLocaleLowerCase() || ""
    );

    const data = await getAllUserGraph(searchParams);

    const tempUsers: number[] = [];
    data.BarGraphData.map((item) => {
      tempUsers.push(item.farmerAmount);
    });
    setAllUserData(tempUsers);

    const tempAccumulateUsers: number[] = [];
    data.accumulatedGraphData.map((item) => {
      tempAccumulateUsers.push(item.farmerAmount);
    });
    setAllUserAccumulateData(tempAccumulateUsers);
  };

  const fetchUserVsCustomerData = async () => {
    const data = await getUserVsCustomerGraph({
      year: userCustomerYear ?? dayjs().year().toString(),
      groupAreaId: userCustomerGroupArea[0]?.value,
      segment: userCustomerSegment[0]?.value.toLocaleLowerCase(),
    });
    if (data) {
      const tempUser: number[] = [];
      data.barGraphData.map((item) => {
        tempUser.push(item.nonCustomerAmount);
      });
      setVsUser(tempUser);

      const tempCustomer: number[] = [];
      data.barGraphData.map((item) => {
        tempCustomer.push(item.customerAmount);
      });
      setVsCustomer(tempCustomer);

      const tempAccUser: number[] = [];
      data.accumulatedGraphData.map((item) => {
        tempAccUser.push(item.nonCustomerAmount);
      });
      setVsAccUser(tempAccUser);

      const tempAccCustomer: number[] = [];
      data.accumulatedGraphData.map((item) => {
        tempAccCustomer.push(item.customerAmount);
      });
      setVsAccCustomer(tempAccCustomer);
    }
  };

  useEffect(() => {
    fetchYearOption();
    fetchGroupAreaOption();
    fetchCreditComparison();
    fetchUserVsCustomerData();
  }, []);

  useEffect(() => {
    if (allUserYear) {
      fetchAllUsersGraphData();
    }
  }, [allUserYear]);

  const exportUsersVsCustomers = () => {
    let data: {
      Month: string;
      Users: number;
      Customers: number;
      "Accumulate Users": number;
      "Accumulate Customers": number;
    }[] = [];
    Month.map((item, index) => {
      data.push({
        Month: item,
        Users: vsUser[index],
        Customers: vsCustomer[index],
        "Accumulate Users": vsAccUser[index],
        "Accumulate Customers": vsAccCustomer[index],
      });
    });
    const fileName = `Users_Customer_Graph_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data || [], fileName, sheetName);
  };

  const exportAllUsers = () => {
    let data: { Month: string; Users: number; "Accumulate Users": number }[] =
      [];
    Month.map((item, index) => {
      data.push({
        Month: item,
        Users: allUserData[index],
        "Accumulate Users": allUserAccumulateData[index],
      });
    });
    const fileName = `All_Users_Graph_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data || [], fileName, sheetName);
  };

  const exportPie = () => {
    const fileName = `All_Users_Graph_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    const data = [
      {
        Customers: creditComparisonData?.farmersWithCredit.amount,
        "Non Customers": creditComparisonData?.farmersWithoutCredit.amount,
      },
    ];
    makeXlsx(data || [], fileName, sheetName);
  };

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <span className="font-bold text-3xl">
              {t("dashboard.allUser.title")}
            </span>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6 grid grid-cols-12 gap-6">
              <div className="grid col-span-8 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="font-bold text-2xl">
                    {t("dashboard.allUser.allUser")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => {
                        echartsExportPng(echartRef1, "All Users");
                      }}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title={t("common.download")}
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => exportAllUsers()}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3 items-end">
                  <Dropdown
                    label="ปี"
                    options={yearOption.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(value) => {
                      setAllUserYear(value[0].value);
                    }}
                    onReset={() => {
                      setAllUserYear("");
                    }}
                    renderValueArray={
                      [
                        {
                          value: allUserYear,
                          label: allUserYear,
                        },
                      ] as TDropdownValue[]
                    }
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Group Area"
                    options={groupAreaOption.map((row) => ({
                      value: row.id,
                      label: row.name,
                    }))}
                    onChange={(value) => {
                      setAllUserGroupArea(value);
                    }}
                    onReset={() => {
                      setAllUserGroupArea([]);
                    }}
                    renderValueArray={allUserGroupArea}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Segment"
                    options={segmentOption.map((row) => ({
                      value: row,
                      label: row,
                    }))}
                    onChange={(value) => setAllUserSegment(value)}
                    onReset={() => {
                      setAllUserSegment([]);
                    }}
                    renderValueArray={allUserSegment}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <ButtonFillCustom
                    title="ค้นหา"
                    onClick={() => {
                      fetchAllUsersGraphData();
                    }}
                    className="!mb-3"
                  />
                </div>
                <div className="flex flex-row justify-end">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      navigate("/admin/all-users/all-users-table");
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </div>
                <div>
                  <ReactECharts
                    ref={(e) => {
                      //@ts-ignore
                      echartRef1.current = e;
                    }}
                    option={{
                      grid: {
                        top: "3%",
                        left: "3%",
                        right: "3%",
                        bottom: "15%",
                        containLabel: true,
                      },
                      xAxis: {
                        type: "category",
                        data: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ],
                        axisLine: {
                          show: false,
                        },
                        axisTick: {
                          show: true,
                        },
                        axisLabel: {
                          fontSize: "10px",
                          fontFamily: "DM Sans",
                          color: "#979797",
                          fontWeight: "normal",
                          interval: 0,
                          hideOverlap: false,
                        },
                      },
                      yAxis: {
                        type: "value",
                        axisLabel: {
                          fontSize: "10px",
                          fontFamily: "DM Sans",
                          color: "#979797",
                          fontWeight: "normal",
                          interval: 0,
                          hideOverlap: false,
                        },
                      },

                      legend: {
                        show: true,
                        bottom: "0",
                        textStyle: {
                          color: "#000000",
                          fontSize: "14px",
                          fontFamily: "DM Sans",
                          fontWeight: "normal",
                        },
                        data: [
                          {
                            name: "Users",
                            icon: "circle",
                            itemStyle: {
                              color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                  {
                                    offset: 0,
                                    color: "#68C184",
                                  },
                                  {
                                    offset: 1,
                                    color: "#77F2A1",
                                  },
                                ],
                                global: false,
                              },
                            },
                          },
                          {
                            name: "Accumulate Users",
                            itemStyle: {
                              borderRadius: 50,
                            },
                          },
                        ],
                      },
                      series: [
                        {
                          name: "Users",
                          data: allUserData,
                          type: "bar",
                          smooth: false,
                          itemStyle: {
                            color: () => {
                              return new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                  { offset: 0, color: "#68C184" },
                                  { offset: 1, color: "#77F2A1" },
                                ]
                              );
                            },
                          },
                        },
                        {
                          name: "Accumulate Users",
                          data: allUserAccumulateData,
                          type: "line",
                          smooth: false,
                          lineStyle: {
                            normal: {
                              color: "#62A665",
                              width: 2,
                              type: "dashed",
                            },
                          },
                          symbol: "circle",
                          symbolSize: 10,
                        },
                      ],
                      tooltip: {
                        trigger: "axis",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="grid col-span-4 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A]">
                <div className="flex flex-col gap-6">
                  <span className="text-[#000] font-bold text-2xl">
                    Customer Credit VS Non Customer Credit
                  </span>
                  <div className="flex flex-row justify-end items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() =>
                        echartsExportPng(
                          echartRef2,
                          "Customer Credit VS Non Customer Credit"
                        )
                      }
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => exportPie()}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                  <ReactECharts
                    ref={(e) => {
                      //@ts-ignore
                      echartRef2.current = e;
                    }}
                    option={{
                      grid: {
                        top: "3%",
                        left: "3%",
                        right: "3%",
                        bottom: "15%",
                        containLabel: true,
                      },

                      legend: {
                        show: true,
                        bottom: "0",
                        textStyle: {
                          color: "#000000",
                          fontSize: "14px",
                          fontFamily: "DM Sans",
                          fontWeight: "normal",
                        },
                        icon: "circle",
                        data: [
                          {
                            name: "Customers",
                          },
                          {
                            name: "Non Customer Credit",
                          },
                        ],
                      },
                      series: [
                        {
                          type: "pie",
                          data: [
                            {
                              value:
                                creditComparisonData?.farmersWithCredit.amount,
                              name: "Customers",
                              itemStyle: {
                                color: {
                                  type: "radial",
                                  x: 0,
                                  y: 0.5,
                                  r: 1,
                                  colorStops: [
                                    {
                                      offset: 0,
                                      color: "#3777BC",
                                    },
                                    {
                                      offset: 1,
                                      color: "#659DDA",
                                    },
                                  ],
                                },
                              },
                            },
                            {
                              value:
                                creditComparisonData?.farmersWithoutCredit
                                  .amount,
                              name: "Non Customer Credit",
                              itemStyle: {
                                color: {
                                  type: "radial",
                                  x: 1,
                                  y: 0.5,
                                  r: 1,
                                  colorStops: [
                                    {
                                      offset: 0,
                                      color: "#68C184",
                                    },
                                    {
                                      offset: 1,
                                      color: "#77F2A1",
                                    },
                                  ],
                                },
                              },
                            },
                          ],
                          label: {
                            position: "inside",
                            formatter: "{d}%",
                            color: "white",
                            fontSize: 14,
                            fontWeight: "bold",
                          },
                        },
                      ],
                      tooltip: {
                        trigger: "item",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="grid col-span-12 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-[#000] font-bold text-2xl">
                    {t("dashboard.allUser.usersVsCustomers")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() =>
                        echartsExportPng(echartRef3, "Users VS Customers")
                      }
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => exportUsersVsCustomers()}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3 items-end">
                  <Dropdown
                    label="ปี"
                    options={yearOption.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(value) => {
                      setUserCustomerYear(value[0].value);
                    }}
                    onReset={() => {
                      setUserCustomerYear("");
                    }}
                    renderValueArray={
                      [
                        {
                          value: userCustomerYear,
                          label: userCustomerYear,
                        },
                      ] as TDropdownValue[]
                    }
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Group Area"
                    options={groupAreaOption.map((row) => ({
                      value: row.id,
                      label: row.name,
                    }))}
                    onChange={(value) => {
                      setUserCustomerGroupArea(value);
                    }}
                    onReset={() => {
                      setUserCustomerGroupArea([]);
                    }}
                    renderValueArray={userCustomerGroupArea}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Segment"
                    options={segmentOption.map((row) => ({
                      value: row,
                      label: row,
                    }))}
                    onChange={(value) => setUserCustomerSegment(value)}
                    onReset={() => {
                      setUserCustomerSegment([]);
                    }}
                    renderValueArray={userCustomerSegment}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <ButtonFillCustom
                    title="ค้นหา"
                    onClick={() => {
                      fetchUserVsCustomerData();
                    }}
                    className="!mb-3"
                  />
                </div>
                <div className="flex flex-row justify-end">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      navigate("/admin/all-users/users-customers-table");
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </div>
                <div>
                  <ReactECharts
                    ref={(e) => {
                      //@ts-ignore
                      echartRef3.current = e;
                    }}
                    option={{
                      grid: {
                        top: "3%",
                        left: "3%",
                        right: "3%",
                        bottom: "15%",
                        containLabel: true,
                      },
                      xAxis: {
                        type: "category",
                        data: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ],
                        axisLine: {
                          show: false,
                        },
                        axisTick: {
                          show: true,
                        },
                        axisLabel: {
                          fontSize: "10px",
                          fontFamily: "DM Sans",
                          color: "#979797",
                          fontWeight: "normal",
                          interval: 0,
                          hideOverlap: false,
                        },
                      },
                      yAxis: {
                        type: "value",
                        axisLabel: {
                          fontSize: "10px",
                          fontFamily: "DM Sans",
                          color: "#979797",
                          fontWeight: "normal",
                          interval: 0,
                          hideOverlap: false,
                        },
                      },

                      legend: {
                        show: true,
                        bottom: "0",
                        textStyle: {
                          color: "#000000",
                          fontSize: "14px",
                          fontFamily: "DM Sans",
                          fontWeight: "normal",
                        },
                        data: [
                          {
                            name: "Users",
                            icon: "circle",
                            itemStyle: {
                              color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                  {
                                    offset: 0,
                                    color: "#68C184",
                                  },
                                  {
                                    offset: 1,
                                    color: "#77F2A1",
                                  },
                                ],
                                global: false,
                              },
                            },
                          },
                          {
                            name: "Customers",
                            icon: "circle",
                            itemStyle: {
                              color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                  {
                                    offset: 0,
                                    color: "#3777BC",
                                  },
                                  {
                                    offset: 1,
                                    color: "#659DDA",
                                  },
                                ],
                                global: false,
                              },
                            },
                          },
                          {
                            name: "Accumulate Users",
                            itemStyle: {
                              borderRadius: 50,
                            },
                          },
                          {
                            name: "Accumulate Customers",
                            itemStyle: {
                              borderRadius: 50,
                            },
                          },
                        ],
                      },
                      series: [
                        {
                          name: "Users",
                          data: vsUser,
                          type: "bar",
                          smooth: false,
                          itemStyle: {
                            color: () => {
                              return new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                  { offset: 0, color: "#68C184" },
                                  { offset: 1, color: "#77F2A1" },
                                ]
                              );
                            },
                          },
                        },
                        {
                          name: "Customers",
                          data: vsCustomer,
                          type: "bar",
                          smooth: false,
                          itemStyle: {
                            color: () => {
                              return new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                  { offset: 0, color: "#3777BC" },
                                  { offset: 1, color: "#659DDA" },
                                ]
                              );
                            },
                          },
                        },
                        {
                          name: "Accumulate Users",
                          data: isUser ? vsAccUser : [],
                          type: "line",
                          smooth: false,
                          lineStyle: {
                            normal: {
                              color: "#62A665",
                              width: 2,
                              type: "dashed",
                            },
                          },
                          itemStyle: {
                            color: "#62A665",
                          },
                          symbol: "circle",
                          symbolSize: 10,
                        },
                        {
                          name: "Accumulate Customers",
                          data: isCustomer ? vsAccCustomer : [],
                          type: "line",
                          smooth: false,
                          lineStyle: {
                            normal: {
                              color: "#3777BC",
                              width: 2,
                              type: "dashed",
                            },
                          },
                          itemStyle: {
                            color: "#3777BC",
                          },
                          symbol: "circle",
                          symbolSize: 10,
                        },
                      ],
                      tooltip: {
                        trigger: "axis",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default AllUsers;
