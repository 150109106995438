import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Toolbar,
  Typography,
} from "@mui/material";
import { cloneDeep, get, set, snakeCase } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ADMIN_AUTH_REFRESH_TOKEN_KEY,
  ADMIN_AUTH_TOKEN_KEY,
} from "../../../const/app.constant";
import { deleteItem } from "../../../services/StorageService";
import { UserFunctionRole } from "../../../utils/enum/user-role.enum";
import {
  ChartSquareBarIcon,
  DeviceMobileIcon,
  DocumentTextIcon,
} from "../../assets";
import FarmSookLogo from "../../assets/fs-admin-logo.png";
import { ClaimOrderSvg } from "../../assets/svg/ClaimOrder.svg";
import { CreditSvg } from "../../assets/svg/Credit.svg";
import { FarmSvg } from "../../assets/svg/Farm.svg";
import { FarmTechWarehouseSvg } from "../../assets/svg/FarmTechWarehouse.svg";
import { HomeSvg } from "../../assets/svg/Home.svg";
import { ManageStockSvg } from "../../assets/svg/ManageStock.svg";
import { NotificationSvg } from "../../assets/svg/Notification.svg";
import { PartnerSvg } from "../../assets/svg/Partner.svg";
import { SaleOrderSvg } from "../../assets/svg/SaleOrder.svg";
import { SettingSvg } from "../../assets/svg/Setting.svg";
import { UserSvg } from "../../assets/svg/User.svg";
import { useAdminAuth } from "../AdminAuth/AdminAuthProvider";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RadioButton from "../../../components/common/RadioButton";
import { useTranslation } from "react-i18next";

const drawerWidth = 280;

const linkMenu = [
  //* description group first position 0 = sale and sale-lead, 1 = lending, 2 = management, 3 = admin
  //* description group second and third position same menu, same numbers

  {
    group: "g-015",
    name: "Dashboard",
    link: "/admin/home",
    icon: <HomeSvg />,
    subGroup: [],
  },
  {
    group: "g-011",
    name: "Performance",
    link: "",
    icon: <ChartSquareBarIcon />,
    subGroup: [
      {
        name: "Sale performance",
        link: "/admin/performance/sale",
      },
      {
        name: "Partner",
        link: "/admin/performance/partner",
      },
      {
        name: "Debt collection",
        link: "/admin/performance/dept-collection",
      },
    ],
  },
  {
    group: "g-012",
    name: "All Users",
    link: "/admin/all-users",
    icon: <UserSvg />,
    subGroup: [],
  },
  {
    group: "g-013",
    name: "Application Usage",
    link: "/admin/application-usage",
    icon: <DeviceMobileIcon />,
    subGroup: [],
  },
  {
    group: "g-014",
    name: "Feed Customer",
    link: "/admin/feed-customer",
    icon: <DocumentTextIcon />,
    subGroup: [],
  },
  {
    group: "g-001",
    name: "รายละเอียดฟาร์ม",
    link: "/admin/farm/detail",
    icon: <FarmSvg />,
    subGroup: [],
  },
  {
    group: "g-002",
    name: "พาร์ทเนอร์ทั้งหมด",
    icon: <PartnerSvg />,
    subGroup: [
      {
        name: "ภาพรวมพาร์ทเนอร์",
        link: "",
      },
      {
        name: "รายละเอียดพาร์ทเนอร์",
        link: "/admin/partner/detail",
      },
    ],
  },
  {
    group: "g-003",
    name: "จัดการสต๊อกพาร์ทเนอร์",
    link: "/admin/manage-stock",
    icon: <ManageStockSvg />,
    subGroup: [],
  },
  {
    group: "g-004",
    name: "จัดการออเดอร์ลูกค้า",
    link: "/admin/sale-order",
    icon: <SaleOrderSvg />,
    subGroup: [],
  },
  {
    group: "g-104",
    name: "จัดการออเดอร์ลูกค้า",
    //* Additional components must be created role lending
    link: "/admin/sale-order",
    icon: <SaleOrderSvg />,
    subGroup: [],
  },
  {
    group: "g-005",
    name: "จัดการรายการเคลม",
    link: "/admin/claim-order",
    icon: <ClaimOrderSvg />,
    subGroup: [],
  },
  {
    group: "g-006",
    name: "Credit",
    icon: <CreditSvg />,
    subGroup: [
      {
        name: "Credit Feed",
        //* Additional components must be created role sale
        link: "/admin/credit/credit-feed",
      },
      {
        name: "Credit Status",
        //* Additional components must be created role sale
        link: "/admin/credit/credit-status",
      },
    ],
  },
  {
    group: "g-106",
    name: "Credit",
    icon: <CreditSvg />,
    subGroup: [
      {
        name: "Credit Feed",
        //* Additional components must be created role lending
        link: "/admin/credit/credit-feed",
      },
      {
        name: "Credit Status",
        //* Additional components must be created role lending
        link: "/admin/credit/credit-status",
      },
    ],
  },
  {
    group: "g-206",
    name: "Credit",
    icon: <CreditSvg />,
    subGroup: [
      {
        name: "Credit Feed",
        //* Additional components must be created role management
        link: "/admin/credit/credit-feed",
      },
    ],
  },
  {
    group: "g-007",
    name: "Money Debt and Collection",
    icon: <CreditSvg />,
    subGroup: [
      {
        name: "Debt and Collection",
        link: "/admin/sale/debt-and-collection",
      },
      {
        name: "History",
        link: "",
      },
    ],
  },
  {
    group: "g-107",
    name: "Money Debt and Collection",
    icon: <CreditSvg />,
    subGroup: [
      {
        name: "Debt and Collection",
        link: "/admin/lending/debt-and-collection",
      },
      {
        name: "History",
        link: "",
      },
    ],
  },
  {
    group: "g-008",
    name: "Invoice List",
    link: "/admin/sale-invoice",
    icon: <FarmTechWarehouseSvg />,
    subGroup: [],
  },
  {
    group: "g-108",
    name: "Invoice List",
    link: "/admin/lending-invoice",
    icon: <FarmTechWarehouseSvg />,
    subGroup: [],
  },
  {
    group: "g-208",
    name: "Invoice List",
    link: "/admin/sale-management-invoice",
    icon: <FarmTechWarehouseSvg />,
    subGroup: [],
  },
  {
    group: "g-309",
    name: "Setting",
    icon: <SettingSvg />,
    subGroup: [
      {
        name: "Role",
        link: "/admin/role",
      },
      {
        name: "Group Area",
        link: "/admin/group-area",
      },
      {
        name: "User Management",
        link: "/admin/user-management",
      },
    ],
  },
  {
    group: "g-010",
    name: "Master Data",
    icon: <SettingSvg />,
    subGroup: [
      {
        name: "Farm Community",
        link: "/admin/master-data/farm-community",
      },
      {
        name: "Sale Target",
        link: "/admin/master-data/sale/sale-target",
      },
    ],
  },
  {
    group: "g-210",
    name: "Master Data",
    icon: <SettingSvg />,
    subGroup: [
      {
        name: "Sale Target",
        link: "/admin/master-data/management/sale-target",
      },
    ],
  },
  {
    group: "g-410",
    name: "Master Data",
    icon: <SettingSvg />,
    subGroup: [
      {
        name: "Sale Target",
        link: "/admin/master-data/sale-lead/sale-target",
      },
    ],
  },

  {
    group: "g-310",
    name: "Master Data",
    icon: <SettingSvg />,
    subGroup: [
      {
        name: "Farm Community",
        link: "/admin/master-data/farm-community",
      },
      {
        name: "Credit",
        link: "/admin/master-data/credit",
      },
      {
        name: "Feed",
        link: "/admin/master-data/feed",
      },
      {
        name: "Vaccine",
        link: "/admin/master-data/vaccine",
      },
      {
        name: "Medicine",
        link: "/admin/master-data/medicine",
      },
    ],
  },
];

export const canViewMenus: Record<UserFunctionRole, string[]> = {
  //* none is default role in case don't give a permission
  none: [],
  sale: [
    "g-015",
    "g-010",
    "g-011",
    "g-012",
    "g-013",
    "g-014",
    "g-001",
    "g-002",
    "g-003",
    "g-004",
    "g-005",
    "g-006",
    "g-007",
    "g-008",
  ],
  sale_lead: [
    "g-015",
    "g-011",
    "g-012",
    "g-013",
    "g-014",
    "g-001",
    "g-002",
    "g-003",
    "g-004",
    "g-005",
    "g-006",
    "g-007",
    "g-008",
    "g-410",
  ],
  lending: ["g-104", "g-106", "g-107", "g-108"],
  management: [
    "g-015",
    "g-011",
    "g-012",
    "g-013",
    "g-014",
    "g-206",
    "g-208",
    "g-210",
  ],
  admin: ["g-309", "g-310"],
};

interface IState {
  option: number;
  pin: string;
}

const defaultState: IState = {
  option: 0,
  pin: "",
};
export interface IMenuBarProps {
  children: JSX.Element;
  window?: () => Window;
}

function MenuBar({ children, window }: IMenuBarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [isOpenSubmenu, setIsOpenSubMenu] = React.useState(0);
  const { user } = useAdminAuth();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;
  const [isSetting, setIsSetting] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const handleOpenSubMenu = (index: number) => {
    if (isOpenSubmenu === index) {
      setIsOpenSubMenu(0);
    } else {
      setIsOpenSubMenu(index);
    }
  };

  const handleLogout = () => {
    deleteItem(ADMIN_AUTH_TOKEN_KEY);
    deleteItem(ADMIN_AUTH_REFRESH_TOKEN_KEY);
    navigate("/admin");
  };

  const filteredMenu = useMemo(
    () =>
      linkMenu.filter((row) =>
        (
          canViewMenus[user?.functionName || UserFunctionRole.none] || []
        ).includes(row.group)
      ),
    [user]
  );

  const drawer = (
    <div className="px-4">
      <div className="flex w-full py-5 pl-5">
        <img
          alt="logo"
          src={FarmSookLogo}
          height="28px"
          style={{ height: "28px" }}
        />
      </div>
      <List key={`app-bar-menu-list`}>
        {filteredMenu.map((item, index) => (
          <div key={index}>
            <ListItem
              key={item.group}
              disablePadding
              sx={{
                color: "#4B4B4B",
                "&:hover": {
                  bgcolor: "#68C184",
                  color: "#ffffff",
                  borderRadius: "4px",
                },
              }}
              onClick={() => {
                handleOpenSubMenu(index);
                if (item.link && item.link !== "") {
                  navigate(item.link);
                }
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: "44px",
                  color: "currentcolor",
                  "&.Mui-selected": {
                    bgcolor: "#68C184",
                    color: "#ffffff",
                    borderRadius: "4px",
                  },
                }}
                selected={Boolean(
                  item.link && item.link.includes(location.pathname)
                )}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "unset",
                    paddingRight: "1rem",
                    color: "currentcolor",
                  }}
                >
                  <SvgIcon
                    inheritViewBox={true}
                    sx={{
                      color: "currentColor",
                      width: "24px",
                      height: "auto",
                    }}
                    component={"div"}
                  >
                    {item.icon}
                  </SvgIcon>
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{}}
                  primaryTypographyProps={{
                    sx: {
                      fontFamily: "DM Sans !important",
                      fontWeight: 500,
                      fontSize: "1rem",
                      lineHeight: "20px",
                    },
                  }}
                />
                {item.subGroup.length > 0 &&
                  (isOpenSubmenu !== index ? (
                    <KeyboardArrowDownRoundedIcon sx={{ stroke: "none" }} />
                  ) : (
                    <KeyboardArrowUpIcon sx={{ stroke: "none" }} />
                  ))}
              </ListItemButton>
            </ListItem>
            {isOpenSubmenu === index && item.subGroup.length > 0 ? (
              <List key={`head-list-${index}`}>
                {item.subGroup.map((val, idx) => (
                  <ListItem
                    key={`sub-item-${snakeCase(val.name)}-${idx}`}
                    disablePadding
                    sx={{
                      color: "#4B4B4B",
                      "&:hover": {
                        bgcolor: "#68C184",
                        color: "#ffffff",
                        borderRadius: "4px",
                      },
                    }}
                    onClick={() => {
                      navigate(val.link);
                    }}
                  >
                    <ListItemButton
                      sx={{
                        "&.Mui-selected": {
                          bgcolor: "#68C184",
                          color: "#ffffff",
                          borderRadius: "4px",
                        },
                      }}
                      selected={Boolean(
                        item.link && item.link.includes(location.pathname)
                      )}
                    >
                      <ListItemText
                        primary={val.name}
                        sx={{}}
                        primaryTypographyProps={{
                          sx: {
                            fontFamily: "DM Sans !important",
                            fontWeight: 500,
                            fontSize: "1rem",
                            lineHeight: "20px",
                            paddingLeft: "40px",
                          },
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            ) : null}
          </div>
        ))}
      </List>
    </div>
  );

  const [state, setState] = useState<IState>(defaultState);

  const currentOption = get(state, ["option"]);

  const onChangeState = useCallback(
    (key: keyof IState) => (value: any) => {
      setState((prev) => {
        const prevState = cloneDeep(prev);
        return set(prevState, [key], value);
      });
    },
    []
  );

  useEffect(() => {
    if (i18n.language === "th") {
      setState({
        option: 0,
        pin: "",
      });
    } else if (i18n.language === "en") {
      setState({
        option: 1,
        pin: "",
      });
    }
  }, []);

  useEffect(() => {
    if (currentOption === 0) {
      i18n.changeLanguage("th");
      localStorage.setItem("language", "th");
    } else if (currentOption === 1) {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    }
  }, [currentOption]);

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", fontFamily: "DM Sans !important" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          bgcolor: "#F5F5F5FB",
          border: "1px solid #EEEEEE",
        }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            component="div"
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <div className="flex flex-row h-full align-middle items-center gap-6">
              <Badge
                color="secondary"
                badgeContent={0}
                showZero
                sx={{
                  paddingTop: "4px",
                  "& .MuiBadge-badge": {
                    transform: "scale(1) translate(30%, -40%)",
                  },
                }}
              >
                <SvgIcon sx={{ fontSize: "24px", stroke: "#68C184" }}>
                  <NotificationSvg />
                </SvgIcon>
              </Badge>
              <Avatar
                alt="user-avatar"
                src="https://i.pravatar.cc/300"
                sx={{
                  width: "25px",
                  height: "25px",
                  border: "1px solid #D9D9D9",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                {!isSetting ? (
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    padding={"12px"}
                    borderRadius={"6px"}
                    width={230}
                  >
                    <Avatar
                      alt="user-avatar"
                      src="https://i.pravatar.cc/300"
                      sx={{
                        width: "49px",
                        height: "49px",
                        border: "1px solid #D9D9D9",
                      }}
                    />
                    <Typography fontWeight={700} fontSize={16}>
                      {user?.name}
                    </Typography>
                    <Typography fontWeight={400} fontSize={14}>
                      {user?.value}
                    </Typography>
                    <Typography
                      fontWeight={400}
                      fontSize={14}
                      color={"#334155"}
                    >
                      {user?.userRole}
                    </Typography>
                    <Divider sx={{ paddingTop: "12px", width: "100%" }} />
                    <ListItem
                      disablePadding
                      sx={{
                        color: "#4B4B4B",
                        "&:hover": {
                          bgcolor: "#68C184",
                          color: "#ffffff",
                          borderRadius: "4px",
                        },
                      }}
                      onClick={() => {
                        setIsSetting(true);
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: "44px",
                          color: "currentcolor",
                          "&.Mui-selected": {
                            bgcolor: "#68C184",
                            color: "#ffffff",
                            borderRadius: "4px",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "unset",
                            paddingRight: "1rem",
                            color: "currentcolor",
                          }}
                        >
                          <SvgIcon
                            inheritViewBox={true}
                            sx={{
                              color: "currentColor",
                              width: "24px",
                              height: "auto",
                            }}
                            component={"div"}
                          >
                            <SettingsOutlinedIcon
                              sx={{ width: "24px", height: "24px" }}
                            />
                          </SvgIcon>
                        </ListItemIcon>
                        <ListItemText
                          primary={"ตั้งค่า"}
                          primaryTypographyProps={{
                            sx: {
                              fontFamily: "DM Sans !important",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "20px",
                            },
                          }}
                        />
                        <KeyboardArrowRightIcon />
                      </ListItemButton>
                    </ListItem>
                    <ListItem
                      disablePadding
                      sx={{
                        color: "#4B4B4B",
                        "&:hover": {
                          bgcolor: "#68C184",
                          color: "#ffffff",
                          borderRadius: "4px",
                        },
                      }}
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      <ListItemButton
                        sx={{
                          minHeight: "44px",
                          color: "currentcolor",
                          "&.Mui-selected": {
                            bgcolor: "#68C184",
                            color: "#ffffff",
                            borderRadius: "4px",
                          },
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "unset",
                            paddingRight: "1rem",
                            color: "currentcolor",
                          }}
                        >
                          <SvgIcon
                            inheritViewBox={true}
                            sx={{
                              color: "currentColor",
                              width: "24px",
                              height: "auto",
                            }}
                            component={"div"}
                          >
                            <LogoutIcon
                              sx={{ width: "24px", height: "24px" }}
                            />
                          </SvgIcon>
                        </ListItemIcon>
                        <ListItemText
                          primary={"ออกจากระบบ"}
                          primaryTypographyProps={{
                            sx: {
                              fontFamily: "DM Sans !important",
                              fontWeight: 400,
                              fontSize: "14px",
                              lineHeight: "20px",
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Stack>
                ) : (
                  <Stack
                    width={230}
                    onClick={() => setIsSetting(false)}
                    padding={"12px"}
                    borderRadius={"6px"}
                  >
                    <Stack flexDirection="row" alignItems="center">
                      <ChevronLeftIcon fontSize="large" />
                      <Typography
                        fontWeight={700}
                        fontSize={20}
                        color="#3777BC"
                      >
                        เปลี่ยนภาษา
                      </Typography>
                    </Stack>
                    <div
                      className="flex flex-row "
                      onClick={() => onChangeState("option")(0)}
                    >
                      <RadioButton checked={currentOption === 0} />
                      <div className="text-[16px] text-[#646464] font-normal flex items-center">
                        ไทย
                      </div>
                    </div>
                    <div
                      className="flex flex-row "
                      onClick={() => onChangeState("option")(1)}
                    >
                      <RadioButton checked={currentOption === 1} />
                      <div className="text-[16px] text-[#646464] font-normal flex items-center">
                        อังกฤษ
                      </div>
                    </div>
                  </Stack>
                )}
              </Popover>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#F5F5F5",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "#F5F5F5",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default MenuBar;
