import { Divider, Link, SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  getRevenueGraph,
  getSalePerformanceGroupArea,
  getSalePerformanceYear,
  getSaleVolumeGraph,
} from "../../../services/dashboard/SalePerformance.service";
import {
  DataSalePerformance,
  IRevenueGraphData,
} from "../../../types/dashboard/SalePerformance.type";
import { echartsExportPng } from "../../../utils/echarts";
import { makeXlsx } from "../../../utils/make-xlsx";
import { DownloadSvg } from "../../assets/svg/Download.svg";
import ButtonFillCustom from "../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../components/Button/ButtonOutlinedCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import { IGroupAreaRow } from "../farm-detail/types/dropdownGroupArea.type";
import { Dropdown, TDropdownValue } from "../group-area/views/Dropdown";

type Props = {};

const SalePerformance = (props: Props) => {
  const { t } = useTranslation();
  const eChartRef1 = useRef<any>();
  const eChartRef2 = useRef<any>();

  const handleExport = () => {};
  const navigate = useNavigate();

  const [dataSale, setDataSale] = useState<DataSalePerformance[] | null>();
  const [dataRevenue, setDataRevenue] = useState<IRevenueGraphData[] | null>();

  const [feedYear, setFeedYear] = useState<string>(dayjs().year().toString());
  const [feedGroupArea, setFeedGroupArea] = useState<TDropdownValue[]>([]);
  const [feedSegment, setFeedSegment] = useState<TDropdownValue[]>([]);

  const [gradeYear, setGradeYear] = useState<string>(dayjs().year().toString());
  const [gradeGroupArea, setGradeGroupArea] = useState<TDropdownValue[]>([]);
  const [gradeSegment, setGradeSegment] = useState<TDropdownValue[]>([]);

  const [yearOption, setYearOption] = useState<string[]>([]);
  const [groupAreaOption, setGroupAreaOption] = useState<IGroupAreaRow[]>([]);
  const segmentOption = ["Potential", "Grower", "Independent"];

  const fetchYearOption = async () => {
    const data = await getSalePerformanceYear();
    setYearOption(data);
    setFeedYear(data[0] ?? dayjs().year().toString());
    setGradeYear(data[0] ?? dayjs().year().toString());
  };

  const fetchGroupAreaOption = async () => {
    const data = await getSalePerformanceGroupArea();
    setGroupAreaOption(data);
  };

  const fetchSalePerformanceGraph = async () => {
    const data = await getSaleVolumeGraph({
      year: feedYear,
      groupAreaIds: feedGroupArea[0]?.value,
      segments: feedSegment[0]?.value.toLocaleLowerCase(),
    });
    setDataSale(data);
  };

  const fetchRevenueGraph = async () => {
    const data = await getRevenueGraph({
      year: gradeYear,
      groupAreaIds: gradeGroupArea[0]?.value,
      segments: gradeSegment[0]?.value.toLocaleLowerCase(),
    });
    setDataRevenue(data);
  };

  const exportSaleVolume = () => {
    let data: {
      Month: string;
      "Actual Selling FeedVolume": number;
      "Actual Selling Feed Volume Growth Rate": number;
      "Target Selling Feed Volume": number;
    }[] = [];
    dataSale?.map((item, index) => {
      data.push({
        Month: item.month.toString(),
        "Actual Selling FeedVolume": item.actualSellingFeedVolume,
        "Actual Selling Feed Volume Growth Rate":
          item.actualSellingFeedVolumeGrowthRate,
        "Target Selling Feed Volume": item.targetSellingFeedVolume,
      });
    });
    const fileName = `Sale_Volume_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data || [], fileName, sheetName);
  };
  const exportSaleRevenue = () => {
    let data: {
      Month: string;
      "Actual Selling Feed Revenue": number;
      "Actual Selling Feed Revenue Growth Rate": number;
      "Target Selling Feed Revenue": number;
    }[] = [];
    dataSale?.map((item, index) => {
      data.push({
        Month: item.month.toString(),
        "Actual Selling Feed Revenue": item.actualSellingFeedRevenue,
        "Actual Selling Feed Revenue Growth Rate":
          item.actualSellingFeedRevenueGrowthRate,
        "Target Selling Feed Revenue": item.targetSellingFeedRevenue,
      });
    });
    const fileName = `Sale_Revenue_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data || [], fileName, sheetName);
  };

  const optionsSale = {
    grid: {
      top: "3%",
      left: "3%",
      right: "3%",
      bottom: "15%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: true,
      },
      axisLabel: {
        fontSize: "10px",
        fontFamily: "DM Sans",
        color: "#979797",
        fontWeight: "normal",
        interval: 0,
        hideOverlap: false,
      },
    },
    yAxis: [
      {
        type: "value",
        axisLabel: {
          fontSize: "10px",
          fontFamily: "DM Sans",
          color: "#979797",
          fontWeight: "normal",
          interval: 0,
          hideOverlap: false,
        },
      },
      {
        type: "category",
        data: ["0%", "20%", "40%", "60%", "80%", "100%"],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: "10px",
          fontFamily: "DM Sans",
          color: "#979797",
          fontWeight: "normal",
          interval: 0,
          hideOverlap: false,
        },
      },
    ],

    legend: {
      show: true,
      bottom: "0",
      itemGap: 20,
      textStyle: {
        color: "#000000",
        fontSize: "14px",
        fontFamily: "DM Sans",
        fontWeight: "normal",
      },
      data: [
        {
          name: "Actual Selling Feed Volume",
          icon: "circle",
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#68C184", // color at 0%
                },
                {
                  offset: 1,
                  color: "#77F2A1", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        {
          name: "Target Selling Feed Volume",
          icon: "circle",
          itemStyle: {
            borderRadius: 50,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#3777BC", // color at 0%
                },
                {
                  offset: 1,
                  color: "#659DDA", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        {
          name: "% Actual Selling Growth Rate",
          itemStyle: {
            borderRadius: 50,
          },
        },
      ],
    },
    series: [
      {
        name: "Actual Selling Feed Volume",
        data: dataSale?.map((item) => item.actualSellingFeedVolume),
        type: "bar",
        smooth: false,
        itemStyle: {
          color: () => {
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#68C184" },
              { offset: 1, color: "#77F2A1" },
            ]);
          },
        },
      },
      {
        name: "Target Selling Feed Volume",
        data: dataSale?.map((item) => item.targetSellingFeedVolume),
        type: "bar",
        smooth: false,
        itemStyle: {
          color: () => {
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#3777BC" },
              { offset: 1, color: "#659DDA" },
            ]);
          },
        },
      },
      {
        name: "% Actual Selling Growth Rate",
        data: dataSale?.map((item) => item.actualSellingFeedVolumeGrowthRate),
        type: "line",
        smooth: false,
        lineStyle: {
          normal: {
            color: "#62A665",
            width: 2,
            type: "dashed",
          },
        },
        itemStyle: {
          color: "#62A665",
        },
        symbol: "circle",
        symbolSize: 10,
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  const optionsRevenue = {
    grid: {
      top: "3%",
      left: "3%",
      right: "3%",
      bottom: "15%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: true,
      },
      axisLabel: {
        fontSize: "10px",
        fontFamily: "DM Sans",
        color: "#979797",
        fontWeight: "normal",
        interval: 0,
        hideOverlap: false,
      },
    },
    yAxis: [
      {
        type: "value",
        axisLabel: {
          fontSize: "10px",
          fontFamily: "DM Sans",
          color: "#979797",
          fontWeight: "normal",
          interval: 0,
          hideOverlap: false,
        },
      },
      {
        type: "category",
        data: ["0%", "20%", "40%", "60%", "80%", "100%"],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: "10px",
          fontFamily: "DM Sans",
          color: "#979797",
          fontWeight: "normal",
          interval: 0,
          hideOverlap: false,
        },
      },
    ],

    legend: {
      show: true,
      bottom: "0",
      itemGap: 20,
      textStyle: {
        color: "#000000",
        fontSize: "14px",
        fontFamily: "DM Sans",
        fontWeight: "normal",
      },
      data: [
        {
          name: "Actual Selling Feed Revenue",
          icon: "circle",
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#68C184", // color at 0%
                },
                {
                  offset: 1,
                  color: "#77F2A1", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        {
          name: "Target Selling Feed Revenue",
          icon: "circle",
          itemStyle: {
            borderRadius: 50,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#3777BC", // color at 0%
                },
                {
                  offset: 1,
                  color: "#659DDA", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        {
          name: "% Actual Selling Growth Rate",
          itemStyle: {
            borderRadius: 50,
          },
        },
      ],
    },
    series: [
      {
        name: "Actual Selling Feed Revenue",
        data: dataRevenue?.map((item) => item.actualSellingFeedRevenue),
        type: "bar",
        smooth: false,
        itemStyle: {
          color: () => {
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#68C184" },
              { offset: 1, color: "#77F2A1" },
            ]);
          },
        },
      },
      {
        name: "Target Selling Feed Revenue",
        data: dataRevenue?.map((item) => item.targetSellingFeedRevenue),
        type: "bar",
        smooth: false,
        itemStyle: {
          color: () => {
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#3777BC" },
              { offset: 1, color: "#659DDA" },
            ]);
          },
        },
      },
      {
        name: "% Actual Selling Growth Rate",
        data: dataRevenue?.map(
          (item) => item.actualSellingFeedRevenueGrowthRate
        ),
        type: "line",
        smooth: false,
        lineStyle: {
          normal: {
            color: "#62A665",
            width: 2,
            type: "dashed",
          },
        },
        itemStyle: {
          color: "#62A665",
        },
        symbol: "circle",
        symbolSize: 10,
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  useEffect(() => {
    fetchYearOption();
    fetchGroupAreaOption();
    fetchSalePerformanceGraph();
    fetchRevenueGraph();
  }, []);

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <span className="text-[#000] font-bold text-3xl">
              {t("dashboard.performance.salePerformance")}
            </span>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6 grid grid-cols-12 gap-6">
              <div className="grid col-span-12 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-[#000] font-bold text-2xl">
                    {t("dashboard.performance.saleVolumeTon")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() =>
                        echartsExportPng(eChartRef1, "Sale Volume")
                      }
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => exportSaleVolume()}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3 items-end">
                  <Dropdown
                    label="ปี"
                    options={yearOption.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(value) => {
                      setFeedYear(value[0].value);
                    }}
                    onReset={() => {
                      setFeedYear("");
                    }}
                    renderValueArray={
                      [
                        {
                          value: feedYear,
                          label: feedYear,
                        },
                      ] as TDropdownValue[]
                    }
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Group Area"
                    options={groupAreaOption.map((row) => ({
                      value: row.id,
                      label: row.name,
                    }))}
                    onChange={(value) => {
                      setFeedGroupArea(value);
                    }}
                    onReset={() => {
                      setFeedGroupArea([]);
                    }}
                    renderValueArray={feedGroupArea}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Segment"
                    options={segmentOption.map((row) => ({
                      value: row,
                      label: row,
                    }))}
                    onChange={(value) => setFeedSegment(value)}
                    onReset={() => {
                      setFeedSegment([]);
                    }}
                    renderValueArray={feedSegment}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <ButtonFillCustom
                    title="ค้นหา"
                    onClick={() => fetchSalePerformanceGraph()}
                    className="!mb-3"
                  />
                </div>
                <div className="flex flex-row justify-end">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      navigate("/admin/performance/sale/sale-volume-table");
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </div>
                <div>
                  <ReactECharts option={optionsSale} ref={eChartRef1} />
                </div>
              </div>

              <div className="grid col-span-12 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-[#000] font-bold text-2xl">
                    {t("dashboard.performance.revenueTHB")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() =>
                        echartsExportPng(eChartRef2, "Sale Revenue")
                      }
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => exportSaleRevenue()}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3 items-end">
                  <Dropdown
                    label="ปี"
                    options={yearOption.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(value) => {
                      setGradeYear(value[0].value);
                    }}
                    onReset={() => {
                      setGradeYear("");
                    }}
                    renderValueArray={
                      [
                        {
                          value: feedYear,
                          label: feedYear,
                        },
                      ] as TDropdownValue[]
                    }
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Group Area"
                    options={groupAreaOption.map((row) => ({
                      value: row.id,
                      label: row.name,
                    }))}
                    onChange={(value) => {
                      setGradeGroupArea(value);
                    }}
                    onReset={() => {
                      setGradeGroupArea([]);
                    }}
                    renderValueArray={gradeGroupArea}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Segment"
                    options={segmentOption.map((row) => ({
                      value: row,
                      label: row,
                    }))}
                    onChange={(value) => setGradeSegment(value)}
                    onReset={() => {
                      setGradeSegment([]);
                    }}
                    renderValueArray={gradeSegment}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <ButtonFillCustom
                    title="ค้นหา"
                    onClick={() => fetchRevenueGraph()}
                    className="!mb-3"
                  />
                </div>
                <div className="flex flex-row justify-end">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      navigate("/admin/performance/sale/revenue-table");
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </div>
                <div>
                  <ReactECharts option={optionsRevenue} ref={eChartRef2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default SalePerformance;
