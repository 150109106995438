import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import MyOrderSVG from "../../assets/svg/MyOrder";
import OrderFeedSVG from "../../assets/svg/OrderFeed";
import OrderHistorySVG from "../../assets/svg/OrderHistory";
import PaymentSVG from "../../assets/svg/Payment";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";
import { useAuth } from "../../components/common/ProtectedRoute/ProtectedRoute";
import { checkUserRole } from "../../utils/check-user-role";

export default function FeedOrderAndDelivery() {
  const navigate = useNavigate();
  const { farmId } = useLoaderData() as { farmId: string };
  const { t } = useTranslation();
  const { user } = useAuth();
  const [userRole, setUserRole] = useState<string>("");

  useEffect(() => {
    //* set user role
    const currentUser = checkUserRole(user.userRole, user.staffType);
    setUserRole(currentUser);
  }, [user]);

  return (
    <div className="h-screen overflow-auto flex flex-col w-full bg-[#ffff]">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate("/farms")}
          title={t("feedOrderAndDelivery.titleBar")}
        />
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={`${t("feedOrderAndDelivery.cards.orderFeed.title")}`}
            subTitle={`${t("feedOrderAndDelivery.cards.orderFeed.subtitle")}`}
            icon={<OrderFeedSVG />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={() =>
              navigate("/feed-order-and-delivery/order-feed/" + farmId)
            }
          />
        </div>
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={`${t("feedOrderAndDelivery.cards.myOrder.title")}`}
            subTitle={`${t("feedOrderAndDelivery.cards.orderFeed.subtitle")}`}
            icon={<MyOrderSVG />}
            removeBgIcon={true}
            bgColor="#EEF2FF"
            onPress={() => navigate("/my-order/" + farmId)}
          />
        </div>
        <div className="mx-4 mt-4">
          <DashboardInfoCard
            title={`${t("feedOrderAndDelivery.cards.orderHistory.title")}`}
            subTitle={`${t(
              "feedOrderAndDelivery.cards.orderHistory.subtitle"
            )}`}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={() => navigate("/order-history/" + farmId)}
          />
        </div>
        {userRole === "farmer" && (
          <div className="mx-4 mt-4">
            <DashboardInfoCard
              title={`${t("feedOrderAndDelivery.cards.payment.title")}`}
              subTitle={`${t("feedOrderAndDelivery.cards.payment.subtitle")}`}
              icon={<PaymentSVG />}
              removeBgIcon={true}
              bgColor="#F0FDF4"
              onPress={() =>
                navigate(`/financial-support/financial-bill-payment`)
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
