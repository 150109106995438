import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleIcon from "@mui/icons-material/Circle";
import { Button, Link, Step, StepLabel, Stepper } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DocumentBlue from "../../../assets/svg/DocumentBlue";
import { StepperConnectorFS } from "../../../components/common/Stepper";

import { useTranslation } from "react-i18next";
import { TGetListPartnerStockRefillRep } from "../../../types/partner/stock.type";
import StatusFarmerOrderFeed from "../../feed-order-and-delivery/my-order/my-order-component/StatusFarmerOrderFeed";
import OrderFeedPartnerItemList from "./OrderFeedPartnerItemList";
import PartnerViewDocument from "./PartnerViewDocument";
import { StatusEnum } from "./type";

type OOrderFeedPartnerDetailProps = {
  //props display
  isDisplayPrimaryButton?: boolean;
  isTextDescription?: boolean;
  isShippingProof?: boolean;
  //props order data
  stockList: TGetListPartnerStockRefillRep;
  images1?: string[];
  images2?: string[];
};

export default function OrderFeedPartnerDetail({
  isDisplayPrimaryButton,
  stockList,
  isTextDescription,
  isShippingProof,
  images1,
  images2,
}: OOrderFeedPartnerDetailProps) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const navigate = useNavigate();
  const [isShowPartnerDocument, setIsShowPartnerDocument] = useState(false);
  const { t } = useTranslation();

  const currentStatus = stockList.status;
  const stepsData = [
    {
      id: "step-1",
      label: (
        <div className="text-[13px]">
          {t("feedOrderAndDelivery.myOrder.card.status.delivery")}
        </div>
      ),
      completed: false,
    },
    {
      id: "step-2",
      label: (
        <div className="text-[13px]">
          {t("feedOrderAndDelivery.myOrder.card.status.waitToAccept")}
        </div>
      ),
      completed: false,
    },
    {
      id: "step-3",
      label: (
        <div className="text-[13px]">
          {t("feedOrderAndDelivery.myOrder.card.status.accepted")}
        </div>
      ),
      completed: false,
    },
  ];
  const [steps, setSteps] = useState<any[]>(stepsData);

  useEffect(() => {
    const tempStepsData = [...stepsData];
    if (currentStatus === "delivery") {
      setActiveStep(0);
    }
    if (currentStatus === "waiting_for_acceptance") {
      setActiveStep(1);
      tempStepsData[0].completed = true;
    }
    if (currentStatus === "accepted") {
      setActiveStep(2);
      tempStepsData[0].completed = true;
      tempStepsData[1].completed = true;
      tempStepsData[2].completed = true;
    }
    setSteps(tempStepsData);
  }, [currentStatus]);

  const CustomStepIcon = (isComplete: boolean, isPending: boolean) => {
    // TODO : MOVE color code to one common themeing
    return isComplete ? (
      <FontAwesomeIcon
        color="secondary"
        className={"text-secondary"}
        size="lg"
        icon={faCircleCheck}
      />
    ) : (
      <CircleIcon
        fontSize="small"
        className="text-[#EBEBEB]"
        color={isPending ? "secondary" : undefined}
      />
    );
  };

  return (
    <>
      <div className="flex justify-between mx-4 items-center">
        <p className=" font-semibold text-[18px]">
          {t("feedOrderAndDelivery.myOrder.card.orderNumber")}{" "}
          {stockList?.stockRefillNumber}
        </p>
        <div>
          <StatusFarmerOrderFeed status={stockList?.status as StatusEnum} />
        </div>
      </div>
      <div className="pt-4">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<StepperConnectorFS />}
        >
          {steps?.map((step, index) => {
            return (
              <Step key={step?.id} completed={step?.completed} disabled={false}>
                <StepLabel
                  key={index}
                  StepIconComponent={() =>
                    CustomStepIcon(step?.completed, index <= activeStep)
                  }
                >
                  {step?.label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </div>
      {stockList?.stockRefillItems.map((stock, index) => {
        return (
          <div className="border-b border-[#D9EBFE] pb-2">
            <div className="mx-2">
              <OrderFeedPartnerItemList
                key={index}
                stockList={stock}
                index={index + 1}
              />
            </div>
          </div>
        );
      })}
      <div className="flex justify-between text-sm my-2 mx-4">
        <p className="text-primary-grey-200 my-2">
          {stockList?.stockRefillItems?.length}{" "}
          {t("feedOrderAndDelivery.myOrder.card.sku")}
        </p>
      </div>
      <div className="border-y-8 border-[#E2E2E2]">
        <div className="mx-4 my-2">
          <div className="flex items-center">
            <DocumentBlue />
            <p className="text-[15px] mt-2 text-tertiary ml-2">
              {t("PartnerOrder.ProofDelivery")}
            </p>
          </div>
          <div className="flex justify-between text-[15px] mt-2 ml-6 text-primary-grey-200">
            <p>{t("PartnerOrder.ProofDelivery")}</p>
            <Link
              onClick={() => {
                setIsShowPartnerDocument(true);
              }}
              sx={{
                color: "#B0B0B0",
              }}
            >
              {t("PartnerOrder.SeeEvidence")}
            </Link>
          </div>
        </div>
      </div>
      {isShippingProof && (
        <>
          <div className="border-t-8 border-[#E2E2E2] flex justify-between items-center px-4 pt-2">
            <p className="text-[15px] text-tertiary mt-2">
              {t("PartnerOrder.ProofDelivery")}
            </p>
            <Link
              onClick={() => {
                navigate("/stock/prove-shipping/" + stockList.id);
              }}
              sx={{
                color: "#3777bc",
                fontSize: "15px",
              }}
            >
              {t("PartnerOrder.Upload")}
            </Link>
          </div>
          <div className="flex gap-[10px] p-4">
            {images1!.map((img1Src, index) => (
              <img
                key={`${index}`}
                className={`w-[80px] h-[80px] rounded-[10px]`}
                alt="image1"
                src={img1Src}
              />
            ))}
          </div>
          <p className="text-[15px] text-tertiary px-4">
            {t("PartnerOrder.PickingSlip")}
          </p>
          <div className="flex gap-[10px] p-4">
            {images2!.map((img2Src, index) => (
              <img
                key={`${index}`}
                className={`w-[80px] h-[80px] rounded-[10px]`}
                alt="image2"
                src={img2Src}
              />
            ))}
          </div>
        </>
      )}
      {isTextDescription && (
        <p className="text-center text-status-bad font-semibold text-[15px] mt-5">
          {t("feedOrderAndDelivery.myOrder.textAcceptDescription")}
        </p>
      )}
      <div className="flex justify-center gap-2 mt-4">
        <Button
          variant="outlined"
          color="secondary"
          sx={{
            width: isDisplayPrimaryButton ? "90%" : "45%",
            border: "1px solid #68C184",
            backgroundColor: "#ffff",
            position: "sticky",
          }}
          disabled={currentStatus === "delivery"}
        >
          {t("feedOrderAndDelivery.myOrder.button.report")}
        </Button>
        {isDisplayPrimaryButton ?? (
          <Button
            color="secondary"
            variant="contained"
            sx={{
              width: "45%",
              position: "sticky",
              top: 0,
            }}
            onClick={() => {
              navigate("/stock/prove-shipping/" + stockList.id);
            }}
            disabled={currentStatus === "delivery"}
          >
            {t("feedOrderAndDelivery.myOrder.button.confirmReceipt")}
          </Button>
        )}
      </div>
      <PartnerViewDocument
        isOpen={isShowPartnerDocument}
        onClose={() => setIsShowPartnerDocument(false)}
        stockList={stockList}
      />
    </>
  );
}
