import { useEffect } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { DeleteOutlinedIcon } from "../../../../../../assets/svg/DeleteOutlined";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import {
  DragHandle,
  SortableItem,
} from "../../../../../components/DragAndDrop/SortableItem";
import { SortableList } from "../../../../../components/DragAndDrop/SortableList";
import {
  arrangeArticleOrder,
  sequenceArticle,
} from "../../../services/farmCommunity.service";

type SelectArticleTabProps = {
  handleClose: (isSuccess: boolean) => void;
  handleFetch: () => void;
  toggleFetch: boolean;
  type: string;
};

export type ISortArticleForm = {
  articleType: string;
  arrangedArticles: ArrangedArticles[];
};

export type ArrangedArticles = {
  id: string;
  topicName: string;
  displayPriority: number;
  type: string;
  category: string;
};

const SelectArticleTab = ({
  handleClose,
  handleFetch,
  toggleFetch,
  type,
}: SelectArticleTabProps) => {
  const dispatch = useDispatch();
  const methods = useForm<ISortArticleForm>({
    defaultValues: {
      articleType: "",
      arrangedArticles: [
        {
          id: "",
          topicName: "",
          displayPriority: 0,
          type: "",
          category: "",
        },
      ],
    },
  });

  const { remove } = useFieldArray({
    name: "arrangedArticles",
    control: methods.control,
  });

  const handleSubmit = (value: ISortArticleForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await arrangeArticleOrder(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
            handleFetch();
          } catch (error) {
            console.error(error);
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const handleChange = (newOrder: ArrangedArticles[]) => {
    methods.setValue("arrangedArticles", newOrder);
  };

  useEffect(() => {
    const handleFetchSequenceArticle = async () => {
      const data = await sequenceArticle(type);
      if (data) {
        methods.reset({
          articleType: type,
          arrangedArticles: data.rows,
        });
      }
    };
    handleFetchSequenceArticle();
  }, [toggleFetch]);

  return (
    <div className="mt-6">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <SortableList
            items={methods.getValues("arrangedArticles")}
            onChange={handleChange}
            renderItem={(item, index) => (
              <SortableItem id={item.id}>
                <div className="flex items-center justify-between w-full">
                  <div className="flex items-center">
                    <DragHandle />
                    <span
                      className="ml-2 truncate"
                      style={{
                        display: "inline-block",
                        maxWidth: "450px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >{`${index + 1}. ${item.topicName}`}</span>
                  </div>
                  <div className="cursor-pointer" onClick={() => remove(index)}>
                    <DeleteOutlinedIcon />
                  </div>
                </div>
              </SortableItem>
            )}
          />
          <div className="flex justify-end mt-6">
            <ButtonFillCustom title={"บันทึก"} type="submit" />
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SelectArticleTab;
