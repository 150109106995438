import React, {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Dialog,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { tw } from "../../../../utils/tw";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import { getGroupAreaTableDetail } from "../../../../services/GroupArea.service";
import { IGroupAreaTableDetail } from "../../../../types/GroupArea.type";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import TableCustom from "../../../components/Table/TableCustom";
import { useAdminAuth } from "../../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

interface IDetailModalProps {
  groupIdModalDetail: string;
  isOpen: boolean;
  onClose: () => void;
  onOpenEdit: (groupId: string) => void;
  detail?: IGroupAreaTableDetail;
  setDetail: Dispatch<SetStateAction<IGroupAreaTableDetail | undefined>>;
  showButton: boolean;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] max-w-[716px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(`flex flex-row justify-between w-full items-center gap-x-[24px]`),
  col: tw(`flex flex-col flex-1`),
  subTitle: tw(`text-[#3777BC] text-[18px] font-[700]`),
  label: tw(`text-[#1B2128] text-[14px] font-bold mb-3`),
  text: tw(`text-[#1B2128] text-[14px] font-[400]`),
  rowWrap: tw(`flex flex-row items-center flex-wrap gap-x-[6px]`),
  chip: tw(
    `flex justify-center items-center py-[4px] px-[8px] rounded-full bg-[#D9EBFE] text-[#3777BC] text-[12px] font-[400]`
  ),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const DetailModal = (props: IDetailModalProps) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.settingGroupArea === "edit";
  const {
    groupIdModalDetail,
    isOpen,
    onClose,
    onOpenEdit,
    detail,
    setDetail,
    showButton,
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const onCloseFn = useCallback(() => {
    onClose();
  }, []);

  const getGroupDetail = useCallback(() => {
    getGroupAreaTableDetail({ id: groupIdModalDetail })
      .then((result) => {
        setDetail(result);
      })
      .catch((e) => {
        console.log("fail to getGroupDetail error:", e);
      });
  }, [groupIdModalDetail]);

  useEffect(() => {
    if (isOpen) {
      getGroupDetail();
    }
  }, [isOpen, groupIdModalDetail]);

  const farmersDisplay = useMemo(() => {
    if ((detail?.farmers || []).length === 0) return [];
    const pageSize = 10;
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = currentPage * pageSize;
    return (detail?.farmers || []).slice(startIndex, endIndex);
  }, [detail?.farmers, currentPage]);

  const onOpenEditFn = useCallback(() => {
    onClose();
    setTimeout(() => {
      onOpenEdit(groupIdModalDetail);
    }, 500);
  }, [groupIdModalDetail]);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>{t("groupArea.button.seeMore")}</p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className="h-3" />
        <div className={tw(classes.row, "mb-6")}>
          <div className={classes.subTitle}>ข้อมูลกลุ่ม</div>
          {roleActionEdit && showButton && (
            <ButtonOutlinedCustom
              title="แก้ไข"
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              onClick={onOpenEditFn}
            />
          )}
        </div>
        <div className={tw(classes.row, "mb-6")}>
          <div className={classes.col}>
            <div className={classes.label}>ชื่อกลุ่ม</div>
            <div className={classes.text}>{detail?.userGroup.name}</div>
          </div>
          <div className={classes.col}>
            <div className={classes.label}>ภาค</div>
            <div className={classes.text}>
              {detail?.userGroup.region.nameTh}
            </div>
          </div>
        </div>
        <div className={classes.label}>จังหวัด</div>
        <div className={classes.rowWrap}>
          {detail?.userGroup.userGroupProvinces.map(({ province }, index) => (
            <div className={classes.chip} key={`${index}`}>
              {province.nameTh}
            </div>
          ))}
        </div>
        <div className="h-6" />
        <div className={classes.label}>เขต/อำเภอ</div>
        <div className={classes.rowWrap}>
          {detail?.userGroup.userGroupDistricts.map(({ district }, index) => (
            <div className={classes.chip} key={`${index}`}>
              {district.nameTh}
            </div>
          ))}
        </div>
        <div className="h-6" />
        <div className={tw(classes.row, "mb-6")}>
          <div className={classes.col}>
            <div className={classes.label}>พนักงานขาย</div>
            <div className={classes.text}>{`${
              detail?.userGroup.seller.employeeCode
            } ${detail?.userGroup.seller.name} ${
              detail?.userGroup.seller.surname || ""
            }`}</div>
          </div>
          <div className={classes.col}>
            <div className={classes.label}>ชื่อพาร์ทเนอร์</div>
            <div className={classes.text}>
              {detail?.userGroup.partner
                ? `${detail?.userGroup?.partner?.partnerCode} ${detail?.userGroup?.partner?.name} ${detail?.userGroup?.partner?.surname}`
                : "-"}
            </div>
          </div>
        </div>
        <div className="h-6" />
        <TableCustom
          order="desc"
          orderBy={""}
          totalItemSize={detail?.farmers.length || 0}
          rowsPerPage={10}
          page={currentPage}
          headerItems={[
            {
              id: "customerCode",
              numeric: false,
              disablePadding: false,
              label: "รหัสลูกค้า",
              width: "33%",
              isSorting: false,
              className: tw(`whitespace-pre`),
            },
            {
              id: "customerName",
              numeric: false,
              disablePadding: false,
              label: "ชื่อลูกค้า",
              width: "33%",
              isSorting: false,
            },
            {
              id: "customerProvince",
              numeric: false,
              disablePadding: false,
              label: "จังหวัด",
              width: "33%",
              isSorting: false,
            },
          ]}
          rows={farmersDisplay}
          onChangePage={setCurrentPage}
          currentPageItems={[]}
          itemsSelected={[]}
          onSelectItem={(_) => {}}
          onSelectAllClick={(_) => {}}
          hideSelect
          onChangeOrder={() => {}}
          onChangeOrderBy={() => {}}
          EmptyComponent={
            farmersDisplay.length === 0 ? (
              <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
            ) : null
          }
          rowChildren={farmersDisplay.map((row) => {
            return (
              <TableRow
                key={row.id}
                hover
                className="border-x-[1px] border-x-[#ddd]"
              >
                <TableCell className="!text-secondary underline">
                  {row.registrationId}
                </TableCell>
                <TableCell>{`${row.name} ${row.surname}`}</TableCell>
                <TableCell>{row.provinceName}</TableCell>
              </TableRow>
            );
          })}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(DetailModal);
