import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { FormEvent, useEffect, useState } from "react";
import RadioButton from "../../../../../../components/common/RadioButton";
import { DATE_FORMAT_ENG } from "../../../../../../const/app.constant";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import TableCustom, {
  ITableCustomHeadCell,
  Order,
} from "../../../../../components/Table/TableCustom";
import {
  addArticlesToSort,
  getIsActiveArticles,
} from "../../../services/farmCommunity.service";
import { TTypeFarmCommunity } from "../../../types/farmCommunity";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";

type Props = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
  type: string;
};

type ISelectArticleTable = {
  id: string;
  topicName: string;
  type: string;
  updated_at: string;
  category: string;
  displayPriority: number;
};

export type IArticle = {
  id: string;
  displayPriority: number;
  topicName: string;
  updatedAt: string;
  type: string;
  category: string;
};

export type IAddArticle = {
  articleType: string;
  article: IArticle;
};

const headerItems: ITableCustomHeadCell<keyof ISelectArticleTable>[] = [
  {
    id: "topicName",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "10px",
    isSorting: false,
  },
  {
    id: "topicName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อหัวข้อ",
    width: "250px",
    isSorting: false,
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัปเดตล่าสุด",
    width: "150px",
    isSorting: true,
  },
];

const AddSelectSortArticle = ({ isOpen, handleClose, type }: Props) => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [direction, setDirection] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState("");
  const [list, setList] = useState<ISelectArticleTable[]>([]);
  const [count, setCount] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<ISelectArticleTable | null>(
    null
  );
  const [toggleFetch, setToggleFetch] = useState(false);

  const createData = (
    id: string,
    topicName: string,
    updated_at: string,
    type: string,
    category: string,
    displayPriority: number
  ): ISelectArticleTable => {
    return {
      id,
      topicName,
      updated_at: updated_at ? dayjs(updated_at).format(DATE_FORMAT_ENG) : "-",
      type,
      category,
      displayPriority,
    };
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchText);
    searchParams.append("page", page.toString());
    searchParams.append("limit", limit.toString());
    searchParams.append("direction", direction);
    searchParams.append("orderBy", orderBy);
    setList([]);

    const data = await getIsActiveArticles(type, searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.topicName,
          row.updatedAt,
          row.type as TTypeFarmCommunity,
          row.category,
          row.displayPriority
        );
      });
      setCount(data.count);
      setList(newList);
    }
  };

  const submitAPI = async (value: ISelectArticleTable | null) => {
    if (value) {
      const body: IAddArticle = {
        articleType: type,
        article: {
          id: value.id,
          displayPriority: value.displayPriority,
          topicName: value.topicName,
          updatedAt: value.updated_at,
          type: value.type,
          category: value.category,
        },
      };
      await addArticlesToSort(body);
    }
  };

  const handleSubmit = (value: ISelectArticleTable | null) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  useEffect(() => {
    if (isOpen) {
      handleFetch();
    }
  }, [direction, page, orderBy, isOpen, toggleFetch]);

  const handleSetSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setToggleFetch((prev) => !prev);
  };

  return (
    <>
      <AdminModal
        label={"เพิ่มบทความ"}
        isOpen={isOpen}
        size={"md"}
        handleClose={() => handleClose(false)}
        children={
          <>
            <form onSubmit={handleSetSearch}>
              <TextInputSearchCustom
                value={searchText}
                placeholder="ค้นหาโดยชื่อหัวข้อ"
                onChange={(event) => setSearchText(event.target.value)}
                InputProps={{
                  endAdornment:
                    searchText.length > 0 ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {
                          setSearchText("");
                          setToggleFetch((prev) => !prev);
                        }}
                      />
                    ) : null,
                }}
              />
            </form>
            <div className="mt-4">
              <TableCustom
                order={direction}
                orderBy={"updated_at"}
                itemsSelected={[]}
                rowsPerPage={limit}
                page={page}
                headerItems={headerItems}
                rows={list}
                onChangePage={(value) => {
                  setPage(value);
                }}
                onChangeOrder={(val) => setDirection(val)}
                onChangeOrderBy={(val) =>
                  setOrderBy(val as keyof ISelectArticleTable)
                }
                onSelectItem={() => {}}
                onSelectAllClick={() => {}}
                totalItemSize={count}
                currentPageItems={list.map((row) => row.id)}
                hideSelect
                rowChildren={list.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell
                      align="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "16px 0",
                      }}
                    >
                      <div className="ml-3">
                        <RadioButton
                          checked={selectedRowId === row.id}
                          isCheck={selectedRowId === row.id}
                          onClick={() => {
                            setSelectedRowId(row.id);
                            setSelectedRow(row);
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.topicName}
                    </TableCell>
                    <TableCell align="left">{row.updated_at}</TableCell>
                  </TableRow>
                ))}
              />
            </div>
            <div className="flex justify-end mt-6">
              <ButtonFillCustom
                title="ยืนยัน"
                onClick={() => handleSubmit(selectedRow)}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default AddSelectSortArticle;
