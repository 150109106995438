import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DATE_FORMAT_ENG } from "../../../../../const/app.constant";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import {
  deletePigPrice,
  getPigPriceTable,
} from "../../services/farmCommunity.service";
import {
  IFarmCommunityTable,
  IGetPigPriceRow,
  IPigPriceTable,
} from "../../types/farmCommunity";
import { TUseFarmCommunityFilter } from "./useFarmCommuFilter.hook";

export const usePigPriceTableHook = (
  searchFarmCommunityFilter: TUseFarmCommunityFilter
) => {
  const dispatch = useDispatch();
  const [pigPriceData, setPigPriceData] = useState<IGetPigPriceRow[]>([]);
  const [dataTable, setDataTable] = useState<IPigPriceTable[]>([]);

  const createData = (
    id: string,
    displayPriority: number | null,
    topicName: string,
    announceDate: string,
    updateAt: string
  ): IPigPriceTable => {
    return {
      id,
      displayPriority: displayPriority ? displayPriority : null,
      topicName,
      announceDate: announceDate
        ? dayjs(announceDate).format(DATE_FORMAT_ENG)
        : "-",
      updateAt: dayjs(updateAt).format(DATE_FORMAT_ENG),
    };
  };

  const MapOrderByWithFilter: { [K in keyof IFarmCommunityTable]?: string } = {
    updatedAt: "updated_at",
  };

  const handleFetchPigPrice = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("updatedAtStart", searchFarmCommunityFilter.startDate);
    searchParams.append("updatedAtEnd", searchFarmCommunityFilter.endDate);
    searchParams.append("type", searchFarmCommunityFilter.type);
    searchParams.append("page", searchFarmCommunityFilter.page.toString());
    searchParams.append("limit", searchFarmCommunityFilter.limit.toString());
    searchParams.append("direction", searchFarmCommunityFilter.direction);
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFarmCommunityFilter.orderBy] ?? ""
    );
    setPigPriceData([]);

    const data = await getPigPriceTable(searchParams);
    if (data) {
      const newList = data.rows.map((item) => {
        return createData(
          item.id,
          item.displayPriority,
          item.topicName,
          item.announceDate,
          item.updatedAt
        );
      });
      setDataTable(newList);
      searchFarmCommunityFilter.setCount(data.count);
      setPigPriceData(data.rows);
    }
  };

  useEffect(() => {
    handleFetchPigPrice();
  }, [
    searchFarmCommunityFilter.direction,
    searchFarmCommunityFilter.page,
    searchFarmCommunityFilter.orderBy,
    searchFarmCommunityFilter.toggleFetch,
  ]);

  const handleDeletePigPrice = async (id: string) => {
    try {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการลบ",
          text: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          cancelText: "ยกเลิก",
          confirmText: "ยืนยัน",
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: () => {
            dispatch(loadingActions.show({ message: "กำลังลบรายการ..." }));
            deletePigPrice(id)
              .then(() => {
                dispatch(alertAction.hide());
                setTimeout(() => {
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: "ลบสำเร็จ",
                      type: "success",
                    })
                  );
                }, 1000);
                handleFetchPigPrice();
              })
              .catch((e) => {
                dispatch(alertAction.hide());
                setTimeout(() => {
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: getErrorMessage(e),
                      type: "error",
                    })
                  );
                }, 1000);
              });
          },
        })
      );
    } catch (error) {
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
    }
  };

  return {
    handleDeletePigPrice,
    handleFetchPigPrice,
    pigPriceData,
    dataTable,
  };
};
