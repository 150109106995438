import { cloneDeep, get, isNull, set } from "lodash";
import { getCommaNumber } from "../../../../../utils/number";
import RadioButton from "../../../../../components/common/RadioButton";
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import TextAreaCustom from "../../../../components/Input/TextAreaCustom";
import { Input } from "../../../group-area/views/Input";
import {
  EClaimOrderOption,
  IDataClaimOrder,
} from "../../../../../types/ClaimOrder.type";
import { unitMap } from "../../../manage-stock/[id]";
import { reason } from "../../orderClaim/orderClaimDetail/Tabs/ReportDetail";
import { tw } from "../../../../../utils/tw";
import { useTranslation } from "react-i18next";

interface IState {
  option: number;
  creditReturnUse: string;
  creditReturnReturn: string;
  creditAddUse: string;
  creditAddAdd: string;
  remark: string;
}

interface IAddDetail {
  dataEdit?: IDataClaimOrder;
  onEdit?: boolean;
  state: IState[];
  setState: any;
  onChangeState: (index: number, key: keyof IState) => (value: any) => void;
  isFirst?: boolean;
}

const AddDetail = (props: IAddDetail) => {
  const { t } = useTranslation();
  const { dataEdit, onEdit, state, setState, onChangeState, isFirst } = props;

  const getOptionValue = useCallback((type: EClaimOrderOption) => {
    if (type === EClaimOrderOption.DeliverProduct) {
      return 0;
    } else if (type === EClaimOrderOption.RefundCredit) {
      return 1;
    } else if (type === EClaimOrderOption.AddDebt) {
      return 2;
    } else {
      return 0;
    }
  }, []);

  console.log(state);

  useEffect(() => {
    if (onEdit) {
      const stateInit = (dataEdit?.orderClaimItems || []).map(
        (d) =>
          ({
            option: getOptionValue(d.claimOption),
            creditReturnUse: get(d, "orderFeedItem.totalItemPrice", ""),
            creditReturnReturn: get(d, "claimRefundAmount", ""),
            creditAddUse: get(d, "orderFeedItem.totalItemPrice", ""),
            creditAddAdd: get(d, "claimRefundAmount", ""),
            remark: get(d, "remark", "-"),
          } as IState)
      );

      setState(stateInit);
    }
  }, [onEdit]);

  return (
    <div className={tw("py-6", onEdit && "pt-0 pb-6")}>
      {!onEdit && (
        <div className="flex flex-col w-full gap-3">
          <div className="font-bold text-[14px] text-[#1B2128]">
            {t("claimOrder.createClaimApproval.step1.soNumber")}
          </div>
          <div className="font-normal text-[14px] text-[#1B2128]">
            {get(dataEdit, ["orderNumber"], "-")}
          </div>
        </div>
      )}
      <div className={tw("pt-6 flex flex-col gap-6", onEdit && "pt-0")}>
        {(dataEdit?.orderClaimItems || []).map((row, index) => {
          const currentOption = get(state, [index, "option"]);

          return (
            <div key={`${index}-show-report`} className="flex flex-col gap-6">
              <div className="flex flex-col gap-3">
                <div className="font-bold text-[18px] text-[#3777BC]">
                  {t("claimOrder.createClaimApproval.step1.claimItemNo")}{" "}
                  {index + 1}
                </div>
                {!isNull(row.orderFeedItem) && (
                  <div className="w-full rounded-[20px] border-[1px] border-[#E2E2E2] p-3">
                    <div className="grid grid-cols-3 gap-3">
                      <div className="h-[54px] flex flex-col gap-3">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          {t("claimOrder.createClaimApproval.step1.brand")}
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {get(
                            row,
                            ["orderFeedItem", "feedBrand", "nameTh"],
                            ""
                          )}
                        </div>
                      </div>
                      <div className="h-[54px] flex flex-col gap-3">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          {t("claimOrder.createClaimApproval.step1.sku")}
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {get(
                            row,
                            ["orderFeedItem", "feedBrandItem", "skuTh"],
                            ""
                          )}
                        </div>
                      </div>
                      <div className="h-[54px] flex flex-col gap-3">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          {t(
                            "claimOrder.createClaimApproval.step1.pricePerUnit"
                          )}
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {getCommaNumber(
                            +get(row, ["orderFeedItem", "feedPricePerUnit"], 0),
                            true
                          )}{" "}
                          บาท
                        </div>
                      </div>
                      <div className="h-[54px] flex flex-col gap-3">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          {t(
                            "claimOrder.createClaimApproval.step1.totalPriceOfItem"
                          )}
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {getCommaNumber(
                            get(row, ["orderFeedItem", "totalItemPrice"], 0),
                            true
                          )}{" "}
                          บาท
                        </div>
                      </div>
                      <div className="h-[54px] flex flex-col gap-3">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          {t(
                            "claimOrder.createClaimApproval.step1.quantityOfOrderedSKU"
                          )}
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {get(row, [
                            "orderFeedItem",
                            "feedBrandItem",
                            "unit",
                          ]) === "bag"
                            ? getCommaNumber(
                                +get(row, ["orderFeedItem", "feedAmount"], 0)
                              )
                            : getCommaNumber(
                                +get(row, ["orderFeedItem", "feedAmount"], 0),
                                true
                              )}{" "}
                          {
                            unitMap[
                              get(
                                row,
                                ["orderFeedItem", "feedBrandItem", "unit"],
                                ""
                              )
                            ]
                          }
                        </div>
                      </div>
                      <div className="h-[54px] flex flex-col gap-3">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          {t(
                            "claimOrder.createClaimApproval.step1.quantityOfClaimedSKU"
                          )}
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {get(row, [
                            "orderFeedItem",
                            "feedBrandItem",
                            "unit",
                          ]) === "bag"
                            ? getCommaNumber(+get(row, ["reportAmount"], 0))
                            : getCommaNumber(
                                +get(row, ["reportAmount"], 0),
                                true
                              )}{" "}
                          {
                            unitMap[
                              get(
                                row,
                                ["orderFeedItem", "feedBrandItem", "unit"],
                                ""
                              )
                            ]
                          }
                        </div>
                      </div>
                      <div className="h-[54px] flex flex-col gap-3">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          {t("claimOrder.createClaimApproval.step1.reason")}
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {reason[get(row, ["reportReason"], "")]}
                        </div>
                      </div>
                      <div className="h-[54px] flex flex-col gap-3 col-span-2">
                        <div className="font-bold text-[14px] text-[#1B2128]">
                          {t(
                            "claimOrder.createClaimApproval.step1.detailOfReason"
                          )}
                        </div>
                        <div className="font-normal text-[14px] text-[#1B2128]">
                          {get(row, ["reportDetail"]) || "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-6">
                  <div className="font-bold text-[1ุpx] text-[#191919]">
                    {t(
                      "claimOrder.createClaimApproval.step1.selectHowtoApprove"
                    )}
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="font-medium text-[15px] text-[#4B4B4B]">
                      ตัวเลือก
                    </div>
                    <div className="flex flex-col">
                      <div className="flex flex-row items-center gap-[10px]">
                        <RadioButton
                          checked={currentOption === 0}
                          onClick={() => onChangeState(index, "option")(0)}
                          disable={!isFirst}
                          isCheck={!isFirst}
                        />
                        <div className="font-normal text-[14px] text-[#646464]">
                          ส่งของเพิ่ม
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-[10px]">
                        <RadioButton
                          checked={currentOption === 1}
                          onClick={() => onChangeState(index, "option")(1)}
                          disable={!isFirst}
                          isCheck={!isFirst}
                        />
                        <div className="font-normal text-[14px] text-[#646464]">
                          คืนเครดิต
                        </div>
                      </div>
                      {currentOption === 1 && (
                        <div className="flex flex-row gap-[6px] w-[50%]">
                          <Input
                            label="เครดิตที่ใช้"
                            suffix="บาท"
                            disabled
                            value={getCommaNumber(
                              get(row, ["orderFeedItem", "totalItemPrice"], 0),
                              true
                            )}
                            onChangeText={() => {}}
                          />
                          <Input
                            label="กรอก (placeholder)"
                            suffix="บาท"
                            placeholder="กรอก"
                            value={get(
                              state,
                              [index, "creditReturnReturn"],
                              ""
                            )}
                            inputType="number"
                            onChangeText={onChangeState(
                              index,
                              "creditReturnReturn"
                            )}
                          />
                        </div>
                      )}
                      <div className="flex flex-row items-center gap-[10px]">
                        <RadioButton
                          checked={currentOption === 2}
                          onClick={() => onChangeState(index, "option")(2)}
                          disable={!isFirst}
                          isCheck={!isFirst}
                        />
                        <div className="font-normal text-[14px] text-[#646464]">
                          แจ้งหนี้เพิ่ม
                        </div>
                      </div>
                      {currentOption === 2 && (
                        <div className="flex flex-row gap-[6px] w-[50%]">
                          <Input
                            label="เครดิตที่ใช้"
                            suffix="บาท"
                            disabled
                            value={getCommaNumber(
                              get(row, ["orderFeedItem", "totalItemPrice"], 0),
                              true
                            )}
                            onChangeText={() => {}}
                          />
                          <Input
                            label="เครดิตที่เพิ่ม"
                            suffix="บาท"
                            placeholder="กรอก"
                            inputType="number"
                            value={get(state, [index, "creditAddAdd"], "")}
                            onChangeText={onChangeState(index, "creditAddAdd")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col">
                  <span className="font-sans font-medium text-[14px] leading-5 text-[#191919] pb-2">
                    {t("claimOrder.createClaimApproval.step1.remark")}
                  </span>
                  <TextAreaCustom
                    placeholder="กรอก"
                    value={get(state, [index, "remark"], "")}
                    onChange={(e: any) =>
                      onChangeState(index, "remark")(e.target.value)
                    }
                  />
                  <div className="w-full flex justify-end pt-[2px]">
                    <span className="font-normal text-[12px] text-[#979797]">
                      (maximum 500 characters)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default memo(AddDetail);
