import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Modal } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import ChatErrorSvg from "../../assets/svg/ChatErrorSvg";
import FlagSvg from "../../assets/svg/FlagSvg";
import AppBarFS from "../../components/app-bar/AppBar";
import { IGetMyOrderByID } from "../../types/MyOrderFeed.type";
import SelectSKU from "./SelectSKU";
import { EnumTopicReport } from "./type";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  myOrderList: IGetMyOrderByID;
  toggleResetForm: boolean;
  setToggleResetForm: Dispatch<SetStateAction<boolean>>;
};

const ReportModal = ({
  isOpen,
  onClose,
  myOrderList,
  toggleResetForm,
}: Props) => {
  const { t } = useTranslation();
  const [selectReportTopic, setSelectReportTopic] = useState<EnumTopicReport>();
  const [isOpenSelectSKUPage, setIsOpenSelectSKUPage] = useState(false);

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <div className="min-h-screen w-full flex flex-col bg-[white] pb-[24px]">
          <div className="py-4 bg-white">
            <AppBarFS title={t("reportOrder.page")} onIconPress={onClose} />
          </div>
          <div className="flex flex-col gap-6 bg-white pb-6">
            <div
              className="flex mx-4 items-center"
              onClick={() => {
                setSelectReportTopic(EnumTopicReport.DAMAGED_PRODUCT);
                setIsOpenSelectSKUPage(true);
              }}
            >
              <FlagSvg />
              <div className="pl-8 mr-auto">
                <span className="font-bold text-[16px]">
                  {t("reportOrder.defectiveProduct")}
                </span>
                <br />
                <span className="text-[#979797] text-[14px]">
                  {t("reportOrder.defectiveProductDetail")}
                </span>
              </div>
              <ChevronRightIcon />
            </div>
            <div
              className="flex mx-4 items-center"
              onClick={() => {
                setSelectReportTopic(EnumTopicReport.PRODUCT_IS_NOT_OF_QUALITY);
                setIsOpenSelectSKUPage(true);
              }}
            >
              <FlagSvg />
              <div className="pl-8 mr-auto">
                <span className="font-bold text-[16px]">
                  {t("reportOrder.productNotQuality")}
                </span>
                <br />
                <span className="text-[#979797] text-[14px]">
                  {t("reportOrder.productNotQualityDetailOne")}
                </span>
                <br />
                <span className="text-[#979797] text-[14px]">
                  {t("reportOrder.productNotQualityDetailTwo")}
                </span>
              </div>
              <ChevronRightIcon />
            </div>
            <div
              className="flex mx-4 items-center"
              onClick={() => {
                setSelectReportTopic(EnumTopicReport.PRODUCT_DOES_NOT_MATCH);
                setIsOpenSelectSKUPage(true);
              }}
            >
              <ChatErrorSvg />
              <div className="pl-8 mr-auto">
                <span className="font-bold text-[16px]">
                  {t("reportOrder.productNotMatch")}
                </span>
                <br />
                <span className="text-[#979797] text-[14px]">
                  {t("reportOrder.productNotQualityDetailOne")}
                </span>{" "}
                <br />
                <span className="text-[#979797] text-[14px]">
                  {t("reportOrder.productNotMatchDetail")}
                </span>
              </div>
              <ChevronRightIcon />
            </div>
          </div>
        </div>
      </Modal>
      <SelectSKU
        isOpen={isOpenSelectSKUPage}
        onClose={() => setIsOpenSelectSKUPage(false)}
        myOrderList={myOrderList}
        selectReportTopic={selectReportTopic!}
        toggleResetForm={toggleResetForm}
      />
    </>
  );
};

export default ReportModal;
