import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../const/app.constant";
import { thousandComma } from "../../../../utils/common/string";
import AdminModal from "../../../components/Modal/AdminModal";
import TableCustom, {
  ITableCustomHeadCell,
  Order,
} from "../../../components/Table/TableCustom";
import { getRevenueDetail } from "../services/revenue.service";
import { IGetRevenueResult } from "../types/revenueTable.type";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  type: string;
  partnerId: string;
  customerId: string;
};

interface IRevenueModalTable {
  id: string;
  totalPrice: string;
  transactionDate: string;
}

const RevenueModal = ({
  isOpen,
  handleClose,
  type,
  partnerId,
  customerId,
}: Props) => {
  const { t } = useTranslation();
  const [list, setList] = useState<IRevenueModalTable[]>([]);
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [revenueDetail, setRevenueDetail] = useState<IGetRevenueResult>();

  const createData = (
    id: string,
    totalPrice: string,
    transactionDate: string
  ): IRevenueModalTable => {
    return {
      id,
      totalPrice: totalPrice ? thousandComma(+totalPrice, 2) + " บาท" : "-",
      transactionDate: transactionDate
        ? dayjs(transactionDate).format(DATE_FORMAT_DATE_PICKER2)
        : "-",
    };
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("page", page.toString());
    searchParams.append("limit", limit.toString());
    searchParams.append("direction", direction);

    setList([]);
    const data = await getRevenueDetail(
      partnerId,
      customerId,
      type,
      searchParams
    );
    if (data?.result.revenues) {
      const newList = data.result.revenues.rows.map((row) => {
        return createData(row.id, row.amount.toString(), row.createdAt);
      });
      setCount(data.result.revenues.rows.length);
      setList(newList);
    }
    setRevenueDetail(data?.result);
  };

  useEffect(() => {
    handleFetch();
  }, []);

  const headCells: ITableCustomHeadCell<keyof IRevenueModalTable>[] = [
    {
      id: "totalPrice",
      numeric: false,
      disablePadding: false,
      label: t("partner.revenueInformationTab.revenueDetailsModal.revenue"),
      width: "200px",
      isSorting: false,
    },
    {
      id: "transactionDate",
      numeric: false,
      disablePadding: false,
      label: t("partner.revenueInformationTab.revenueDetailsModal.date"),
      width: "200px",
      isSorting: true,
    },
  ];
  return (
    <>
      <AdminModal
        label={t("partner.revenueInformationTab.title")}
        isOpen={isOpen}
        size={"md"}
        handleClose={handleClose}
        children={
          <>
            <div className="flex gap-3 mt-4">
              <div className="w-[50%]">
                <p className="text-[14px] mb-3 font-sans font-bold">
                  {t("partner.revenueInformationTab.core.customerId")}
                </p>
                <p className="text-[14px]  pb-3 font-sans">
                  {revenueDetail?.farmerRegistrationId}
                </p>
              </div>
              <div className="w-[50%]">
                <p className="text-[14px] mb-3 font-sans font-bold">
                  {t("partner.revenueInformationTab.core.customerName")}
                </p>
                <p className="text-[14px]  pb-3 font-sans">
                  {revenueDetail?.farmerName +
                    " " +
                    revenueDetail?.farmerSurname}
                </p>
              </div>
            </div>
            <div className="flex gap-3 mt-4">
              <div className="w-full">
                <p className="text-[14px] mb-3 font-sans font-bold">
                  {t("partner.revenueInformationTab.core.type")}
                </p>
                <p className="text-[14px]  pb-3 font-sans"></p>
              </div>
            </div>

            <TableCustom
              order={direction}
              orderBy={"transactionDate"}
              itemsSelected={[]}
              rowsPerPage={10}
              page={page}
              headerItems={headCells}
              rows={list}
              onChangePage={(val) => setPage(val)}
              onChangeOrder={(val) => {
                setDirection(val);
              }}
              onChangeOrderBy={() => {}}
              onSelectItem={() => {}}
              onSelectAllClick={() => {}}
              totalItemSize={count}
              currentPageItems={list.map((row) => row.id)}
              hideSelect
              rowChildren={
                <>
                  {list.map((row, index) => {
                    return (
                      <TableRow hover>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.totalPrice}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            fontFamily: "IBM Plex Sans Thai",
                            fontSize: "14px",
                            fontWeight: 400,
                            lineHeight: "19px",
                          }}
                        >
                          {row.transactionDate}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              }
            />
          </>
        }
      />
    </>
  );
};

export default RevenueModal;
