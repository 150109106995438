import adminAPI from "../../../services/AdminApiService";
import { IGetDefaultFormRole } from "./role.type";

export const getDefaultFormRole = async () => {
  const { data } = await adminAPI.get<IGetDefaultFormRole>(
    `/manage-role/farmTech`
  );
  return data;
};

export const editRolePerMission = async (id: string, body: any) => {
  const { data } = await adminAPI.put(`/manage-role/${id}`, body);
  return data;
};
