import { IGetPigPrice } from "../../../../pages/farm-community/type";
import adminAPI from "../../../../services/AdminApiService";
import { getDownloadUrlResponse } from "../../../../services/admin/invoice/type";
import { IAddArticle } from "../components/farmCommunity/Modal/AddSelectSortArticle";
import { ICreatePigPrice } from "../components/farmCommunity/Modal/CreatePigPrice";
import { ISortUserManualForm } from "../components/farmCommunity/Modal/SortUserManualModal";
import { ISortArticleForm } from "../components/farmCommunity/tabs/SelectArticleTab";
import {
  ICountFarmCommunity,
  ICountManual,
  ICreateArticle,
  IGetDetailUserManual,
  IGetIsActiveArticle,
  IGetListNewsAndArticles,
  IGetListUserManual,
  IGetSequenceArticle,
  IGetSequenceManual,
  TCreateUserManual,
} from "../types/farmCommunity";

export const getNewsAndArticles = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetListNewsAndArticles>(
      `/farm-community/article?${searchParams}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const toggleArticles = async (id: string) => {
  return adminAPI.put(`/farm-community/article/${id}/active`);
};

export const deleteArticle = async (id: string) => {
  return adminAPI.delete(`/farm-community/article/${id}`);
};

export const getArticleUploadUrl = async () => {
  try {
    const response = await adminAPI.get<getDownloadUrlResponse>(
      `/farm-community/article/upload`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("getInvoiceDownloadUrlAPI", error);
  }
};

export const createArticle = async (body: ICreateArticle) => {
  return adminAPI.post(`/farm-community/article`, body);
};

export const editArticle = async (id: string, body: ICreateArticle) => {
  return adminAPI.put(`/farm-community/article/${id}`, body);
};

export const sequenceArticle = async (type: string) => {
  try {
    const { data } = await adminAPI.get<IGetSequenceArticle>(
      `/farm-community/article/sequence/${type}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const arrangeArticleOrder = async (body: ISortArticleForm) => {
  return adminAPI.put(`/farm-community/article/arrange-order`, body);
};

export const getIsActiveArticles = async (
  type: string,
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetIsActiveArticle>(
      `/farm-community/article/${type}?${searchParams}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const addArticlesToSort = async (body: IAddArticle) => {
  return adminAPI.put(`/farm-community/article/add-sequence`, body);
};

/// api pig price

export const getPigPriceTable = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<IGetPigPrice>(
      `/farm-community/pig-price?${searchParams}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getPigPriceUploadUrl = async () => {
  try {
    const response = await adminAPI.get<getDownloadUrlResponse>(
      `/farm-community/pig-price/upload`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("getInvoiceDownloadUrlAPI", error);
  }
};

export const createPigPrice = async (body: ICreatePigPrice) => {
  return adminAPI.post(`/farm-community/pig-price`, body);
};

export const editPigPrice = async (id: string, body: ICreatePigPrice) => {
  return adminAPI.put(`/farm-community/pig-price/${id}`, body);
};

export const addSortPigPice = async (id: string) => {
  return adminAPI.put(`/farm-community/pig-price/change-display/${id}`);
};

export const deletePigPrice = async (id: string) => {
  return adminAPI.delete(`/farm-community/pig-price/${id}`);
};

// * api user manual *//

export const getListUserManual = async () => {
  try {
    const { data } = await adminAPI.get<IGetListUserManual>(
      `farm-community/manual`
    );

    return data;
  } catch (error) {
    return null;
  }
};

export const getListTopicUserManual = async (
  id: string,
  searchParams: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<IGetDetailUserManual>(
      `/farm-community/manual/${id}?${searchParams}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const createUserManual = async (body: TCreateUserManual) => {
  const { data } = await adminAPI.post<{ message: string }>(
    "/farm-community/manual",
    body
  );
  return data;
};
export const editUserManual = async (
  manualId: string,
  manualArticleId: string,
  body: TCreateUserManual
) => {
  return adminAPI.put(
    `/farm-community/manual/${manualId}/${manualArticleId}`,
    body
  );
};

export const getUserManualUploadUrl = async () => {
  try {
    const response = await adminAPI.get<getDownloadUrlResponse>(
      `/farm-community/manual/upload`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("getInvoiceDownloadUrlAPI", error);
  }
};

export const getListSequenceUserManual = async (
  manualId: string,
  articleType: string
) => {
  try {
    const { data } = await adminAPI.get<IGetSequenceManual>(
      `/farm-community/manual/${manualId}/sequence/${articleType}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const toggleActiveManual = async (
  manualId: string,
  manualArticleId: string
) => {
  return adminAPI.put(
    `/farm-community/manual/toggle-active/${manualId}/${manualArticleId}`
  );
};

export const arrangeUserManual = async (
  manualId: string,
  body: ISortUserManualForm
) => {
  return adminAPI.put(`/farm-community/manual/${manualId}/arrange`, body);
};

export const getCountFarmCommunity = async () => {
  try {
    const { data } = await adminAPI.get<ICountFarmCommunity>(
      `/farm-community/count`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getCountManual = async (manualId: string) => {
  try {
    const { data } = await adminAPI.get<ICountManual>(
      `/farm-community/manual/${manualId}/count`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const deleteManualArticle = async (
  manualId: string,
  manualArticleId: string
) => {
  return adminAPI.delete(
    `/farm-community/manual/${manualId}/${manualArticleId}`
  );
};
export const deleteManual = async (manualId: string) => {
  return adminAPI.delete(`/farm-community/manual/${manualId}`);
};
