import { useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { useLocation, useNavigate } from "react-router-dom";
import AppBarFS from "../../components/app-bar/AppBar";
const ArticleDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();

  const playerRef = useRef<ReactPlayer | null>(null);

  const handlePlay = () => {
    if (playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      if (player && typeof player.requestFullscreen === "function") {
        player.requestFullscreen();
      } else if (player && typeof player.mozRequestFullScreen === "function") {
        player.mozRequestFullScreen();
      } else if (
        player &&
        typeof player.webkitRequestFullscreen === "function"
      ) {
        player.webkitRequestFullscreen();
      } else if (player && typeof player.msRequestFullscreen === "function") {
        player.msRequestFullscreen();
      }
    }
  };

  return (
    <div className="!h-screen !min-h-screen flex flex-col overflow-scroll">
      <div className="my-4 flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title={t("FarmCommunity.DetailPage")}
        />
      </div>
      <div className="mx-4">
        <p className="text-[#68C184] font-semibold text-[18px]">
          {state.data.topicName}
        </p>
        <div className=" flex justify-center my-3">
          {state.tabName === "article" ? (
            state.file ? (
              <img
                alt={`article`}
                src={state.file}
                style={{
                  maxWidth: "100%",
                  maxHeight: "400px",
                  objectFit: "contain",
                }}
              />
            ) : (
              <p>กำลังโหลดข้อมูล...</p>
            )
          ) : state.file ? (
            <ReactPlayer
              ref={playerRef}
              playing={false}
              controls={true}
              width="100%"
              height="100%"
              onPlay={handlePlay}
              url={state.file}
            />
          ) : (
            <p>กำลังโหลดข้อมูล...</p>
          )}
        </div>
        <div dangerouslySetInnerHTML={{ __html: state.data.description }}></div>
        <div className="mt-3 ">
          <a
            href={state.data.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#3777BC] underline"
          >
            {state.data.url}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ArticleDetail;
