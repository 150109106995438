import { Checkbox, Link, TableCell } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useLoaderData } from "react-router-dom";
import { DATE_FORMAT_TABLE } from "../../../../const/app.constant";
import { useShowButtonByRoles } from "../../../../hooks/useActionButtonByRoles";
import { useSaleOrderSearchFilter } from "../../../../hooks/useSaleOrderSearchFilter.hook";
import {
  getHistoryOrder,
  getListExportCreditHistoryOrder,
} from "../../../../services/admin/sale-order/historyOrder.service";
import { ITableRowDetail } from "../../../../types/EditOrderFeed";
import { FeedBrands } from "../../../../types/FeedBrand.type";
import { TOrderStatus } from "../../../../types/OrderFeed.type";
import { TRejectReason } from "../../../../types/OrderHistory.type";
import {
  UserFunctionRole,
  UserRole,
} from "../../../../utils/enum/user-role.enum";
import { makeXlsx } from "../../../../utils/make-xlsx";
import ChipOrderStatus from "../../../components/Chip/ChipOrderStatus";
import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../components/Table/TableCustom";
import CellOrderDetail from "../components/CellOrderDetail";
import {
  FILTER_DATE_FORMAT,
  SaleOrderSearchFilter,
} from "../components/SaleOrderSearchFilter";
import { IOrderHistoryPanelProps } from "./types";

type TApprovedOrderStatus = Extract<
  TOrderStatus,
  "waiting_for_acceptance" | "accepted" | "rejected" | "canceled"
>;
export interface ISaleOrderData {
  id: string;
  orderId: number;
  customerId: string;
  customerName: string;
  requestDeliveryDate: string;
  deliveryDate: string;
  orderDate: string;
  saleName: string;
  partnerId: string;
  partnerName: string;
  status: TApprovedOrderStatus;
}

function createData(
  id: string,
  orderId: number,
  customerId: string,
  customerName: string,
  requestDeliveryDate: string,
  deliveryDate: string,
  orderDate: string,
  saleName: string,
  partnerId: string,
  partnerName: string,
  status: TOrderStatus
): ISaleOrderData {
  return {
    id,
    orderId,
    customerId,
    customerName,
    deliveryDate: deliveryDate ? dayjs(deliveryDate).format("DD/MM/YYYY") : "-",
    requestDeliveryDate: requestDeliveryDate
      ? dayjs(requestDeliveryDate).format("DD/MM/YYYY")
      : "-",
    orderDate: orderDate ? dayjs(orderDate).format("DD/MM/YYYY") : "-",
    saleName,
    partnerId,
    partnerName,
    status: status as TApprovedOrderStatus,
  };
}

const headCells: ITableCustomHeadCell<keyof ISaleOrderData>[] = [
  {
    id: "orderId",
    numeric: false,
    disablePadding: false,
    label: "หมายเลขสั่งซื้อ",
    width: "150px",
    isSorting: true,
  },
  {
    id: "customerId",
    numeric: false,
    disablePadding: false,
    label: "รหัสลูกค้า",
    width: "120px",
    isSorting: true,
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อลูกค้า",
    width: "180px",
    isSorting: false,
  },
  {
    id: "orderDate",
    numeric: false,
    disablePadding: false,
    label: "วันที่สั่งสินค้า",
    width: "120px",
    isSorting: true,
  },
  {
    id: "requestDeliveryDate",
    numeric: false,
    disablePadding: false,
    label: "วันที่ต้องการรับสินค้า",
    width: "160px",
    isSorting: true,
  },
  {
    id: "deliveryDate",
    numeric: false,
    disablePadding: false,
    label: "วันที่จัดส่งสินค้า",
    width: "140px",
    isSorting: true,
  },
  {
    id: "saleName",
    numeric: false,
    disablePadding: false,
    label: "พนักงานขาย",
    width: "180px",
    isSorting: false,
  },
  {
    id: "partnerId",
    numeric: false,
    disablePadding: false,
    label: "รหัสพาร์ทเนอร์",
    width: "120px",
    isSorting: false,
  },
  {
    id: "partnerName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อพาร์ทเนอร์",
    width: "180px",
    isSorting: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "สถานะ",
    width: "120px",
    isSorting: false,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "30px",
    isSorting: false,
  },
];

const OrderStatusMap: Record<
  TApprovedOrderStatus,
  { color: string; bgColor: string; label: string }
> = {
  waiting_for_acceptance: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "จัดส่งสำเร็จ",
  },
  accepted: {
    color: "#409261",
    bgColor: "#E9FFEF",
    label: "รับสินค้าสำเร็จ",
  },
  rejected: {
    color: "#D32F2F",
    bgColor: "#F9E0E0",
    label: "ไม่อนุมัติ",
  },
  canceled: {
    color: "#D32F2F",
    bgColor: "#F9E0E0",
    label: "ออเดอร์ถูกยกเลิก",
  },
};

function OrderHistoryPanel({}: IOrderHistoryPanelProps) {
  const [itemSelected, setItemSelected] = React.useState<string[]>([]);
  const [list, setList] = React.useState<ISaleOrderData[]>([]);
  const [order, setOrder] = React.useState<ITableRowDetail[]>([]);
  const [count, setCount] = React.useState(0);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;
  const searchFilter = useSaleOrderSearchFilter();
  const loaderData = useLoaderData() as { feedBrands: FeedBrands[] };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `order_history_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "หมายเลขสั่งซื้อ"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "วันที่จัดส่งสินค้า"
      | "วันที่สั่งสินค้า"
      | "พนักงานขาย"
      | "รหัสพาร์ทเนอร์"
      | "ชื่อพาร์ทเนอร์"
      | "สถานะ"
      | "เลขที่สัญญา"
      | "ที่อยู่ลูกค้า"
      | "ที่อยู่พาร์ทเนอร์"
      | "วงเงินลูกค้า"
      | "จำนวนหนี้ที่คงค้าง"
      | "จำนวนเงินที่วงเงินเกิน"
      | "ยอดสั่งซื้อใหม่"
      | "จำนวนวงเงินที่ใช้ไป"
      | "วงเงินเครดิตที่ใช้ไป"
      | "ระยะเครดิต"
      | "จำนวนวันคงค้างหนี้"
      | "เกรดลูกค้า"
      | "แบรนด์อาหารสัตว์"
      | "เบอร์อาหารสัตว์"
      | "ราคาต่อหน่วย"
      | "จำนวน"
      | "ราคารวม"
      | "สถานะ(รอ stock)"
      | "เหตุผลที่ไม่อนุมัติ"
      | "ไม่อนุมัติโดย";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getListExportCreditHistoryOrder(
      itemSelected
    );

    const mapRejectReason: Record<TRejectReason, string> = {
      overdue: "ค้างชำระ",
      over_credit_limit: "เกินวงเงิน",
      cancel_order: "ยกเลิกออเดอร์",
      other: "อื่น ๆ",
      out_of_stock: "สต็อคสินค้าไม่พอ",
      farmer_cancel_order: "ลูกค้ายกเลิกออเดอร์",
    };

    if (allSelectItemData?.result?.rows) {
      return allSelectItemData.result.rows
        .map<TExcelData[]>((row) => {
          return row.orderFeedItems.map<TExcelData>((each): TExcelData => {
            return {
              หมายเลขสั่งซื้อ: row.id,
              รหัสลูกค้า: row.farmerName,
              ชื่อลูกค้า: row.farmerRegistrationId,
              วันที่จัดส่งสินค้า: row.actualShippingDate
                ? dayjs(row.actualShippingDate).format(DATE_FORMAT_TABLE)
                : "-",
              วันที่สั่งสินค้า: row.orderedDate
                ? dayjs(row.orderedDate).format(DATE_FORMAT_TABLE)
                : "-",
              พนักงานขาย: row.saleName,
              รหัสพาร์ทเนอร์: row.partnerId,
              ชื่อพาร์ทเนอร์: row.partnerName,
              สถานะ: OrderStatusMap[row.status as TApprovedOrderStatus].label,
              เลขที่สัญญา: row.contract.contractNumber,
              ที่อยู่ลูกค้า: `${row.farmerAddress} ${row.farmerSubDistrict} ${row.farmerDistrict} ${row.farmerProvince} ${row.farmerPostalCode}`,
              ที่อยู่พาร์ทเนอร์: `33/12 ซอยเจริญกรุง ถนนเจริญกรุง เเขวงบางรัก เขตบางรัก กรุงเทพมหานคร 11000`,
              วงเงินลูกค้า: "100 บาท",
              จำนวนหนี้ที่คงค้าง: "100 บาท",
              จำนวนเงินที่วงเงินเกิน: "100 บาท",
              ยอดสั่งซื้อใหม่: row.totalFeedPrice,
              จำนวนวงเงินที่ใช้ไป: "50%",
              วงเงินเครดิตที่ใช้ไป: "100 บาท",
              ระยะเครดิต: "0 วัน",
              จำนวนวันคงค้างหนี้: "0 วัน",
              เกรดลูกค้า: "A",
              แบรนด์อาหารสัตว์: each.brandName,
              เบอร์อาหารสัตว์: each.brandItemName,
              ราคาต่อหน่วย: each.pricePerUnit,
              จำนวน: each.feedAmount,
              ราคารวม: each.feedPrice,
              "สถานะ(รอ stock)": "พร้อมจัดส่ง",
              เหตุผลที่ไม่อนุมัติ:
                (row.rejectReason === "other"
                  ? row.rejectReason
                  : mapRejectReason[row.rejectReason]) || "-",
              ไม่อนุมัติโดย: row.rejectedBy || "-",
            };
          });
        })
        .flat();
    }
    return [];
  };

  const MapOrderByWithFilter: { [K in keyof ISaleOrderData]?: string } = {
    orderId: "order_no",
    customerId: "customer_no",
    deliveryDate: "actual_shipping_date",
    requestDeliveryDate: "delivery_date",
    orderDate: "order_date",
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    const combinedFeedBrandIds = searchFilter.feedBrandId.join(",");
    searchParams.append("searchText", searchFilter.searchText);
    searchParams.append("page", searchFilter.page.toString());
    searchParams.append("limit", searchFilter.limit.toString());
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFilter.orderBy] ?? ""
    );
    searchParams.append("direction", searchFilter.direction);
    searchParams.append(
      "orderDateStart",
      searchFilter.orderStartDate
        ? dayjs(searchFilter.orderStartDate, FILTER_DATE_FORMAT).toISOString()
        : ""
    );
    searchParams.append(
      "orderDateEnd",
      searchFilter.orderEndDate
        ? dayjs(searchFilter.orderEndDate, FILTER_DATE_FORMAT)
            .endOf("day")
            .toISOString()
        : ""
    );
    searchParams.append(
      "deliveryDateStart",
      searchFilter.deliveryStartDate
        ? dayjs(
            searchFilter.deliveryStartDate,
            FILTER_DATE_FORMAT
          ).toISOString()
        : ""
    );
    searchParams.append(
      "deliveryDateEnd",
      searchFilter.deliveryEndDate
        ? dayjs(searchFilter.deliveryEndDate, FILTER_DATE_FORMAT)
            .endOf("day")
            .toISOString()
        : ""
    );
    searchParams.append(
      "requestDeliveryDateStart",
      searchFilter.requestDeliveryStartDate
        ? dayjs(
            searchFilter.requestDeliveryStartDate,
            FILTER_DATE_FORMAT
          ).toISOString()
        : ""
    );
    searchParams.append(
      "requestDeliveryDateEnd",
      searchFilter.requestDeliveryEndDate
        ? dayjs(searchFilter.requestDeliveryEndDate, FILTER_DATE_FORMAT)
            .endOf("day")
            .toISOString()
        : ""
    );
    searchParams.append("feedBrandId", searchFilter.feedId);
    searchParams.append("feedBrandItemIds", combinedFeedBrandIds);
    setList([]);
    const data = await getHistoryOrder(searchParams);
    if (data?.result.rows) {
      const newList = data.result.rows.map((row) => {
        return createData(
          row.id,
          Number(row.orderNumber),
          row.contract.house.farm.farmer.registrationId,
          row.contract.house.farm.farmer.name +
            " " +
            row.contract.house.farm.farmer.surname,
          row.shippingDate,
          row.actualShippingDate,
          row.createdAt,
          `${row.sellerName} ${row.sellerSurname}`,
          row.partnerCode,
          `${row.partnerName} ${row.partnerSurname}`,
          row.status
        );
      });

      setCount(data.result.count);
      setList(newList);
      setOrder(data.result.rows.map((row) => row));
    }
  };

  React.useEffect(() => {
    handleFetch();
  }, [searchFilter.orderBy, searchFilter.direction, searchFilter.page]);

  return (
    <div className="w-full h-full pt-8">
      <SaleOrderSearchFilter
        onExport={handleExportExcel}
        itemSelected={itemSelected}
        feedBrands={loaderData.feedBrands}
        searchFilter={searchFilter}
        onSearch={handleFetch}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchFilter.direction}
          orderBy={searchFilter.orderBy}
          rowsPerPage={10}
          rows={order}
          itemsSelected={itemSelected}
          headerItems={headCells}
          page={searchFilter.page}
          onChangeOrderBy={(val) =>
            searchFilter.setOrderBy(val as keyof ISaleOrderData)
          }
          onChangeOrder={(val) => searchFilter.setDirection(val)}
          onChangePage={(val) => searchFilter.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={list.map((row) => row.id)}
          rowChildren={
            <>
              {list.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <ExpandableTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    expandComponent={
                      <CellOrderDetail
                        order={order[index]}
                        key={row.orderId}
                        id={String(row.orderId)}
                        index={index}
                        colSpan={11}
                        orderStatus={row.status}
                        showDocumentDetail
                        showReadyToDeliveryChip={false}
                        handleFetch={async () => await handleFetch()}
                        tabName=""
                      />
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          console.info("I'm a button.");
                        }}
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.requestDeliveryDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.deliveryDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.saleName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.partnerId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.partnerName}
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        <ChipOrderStatus
                          bgColor={OrderStatusMap[row.status].bgColor}
                          fontColor={OrderStatusMap[row.status].color}
                          label={OrderStatusMap[row.status].label}
                        />
                      </div>
                    </TableCell>
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
    </div>
  );
}

export default OrderHistoryPanel;
