import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import GroupIcon from "@mui/icons-material/Group";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PaymentsIcon from "@mui/icons-material/Payments";
import PersonIcon from "@mui/icons-material/Person";
import { Divider, IconButton, Tab, Tabs } from "@mui/material";
import dayjs from "dayjs";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { OrderStockFood } from "../../admin/assets/svg/OrderStockFood.svg";
import {
  Dropdown,
  TDropdownValue,
} from "../../admin/pages/group-area/views/Dropdown";
import BackgroundImage from "../../assets/images/Farms/FarmBackground.png";
import FarmIcon from "../../assets/images/OnboardingImages/FarmIcon.svg";
import topIcon from "../../assets/images/OnboardingImages/top_img-2.svg";
import topIcon3 from "../../assets/images/OnboardingImages/top_img-3.svg";
import ImgMngStock from "../../assets/images/manage-stock-bg.jpeg";
import ChartSquareBarSvg from "../../assets/svg/ChartSquareBarSvg";
import DocumentOutlined from "../../assets/svg/DocumentOutlined";
import FarmSvg from "../../assets/svg/FarmSvg";
import IconHomeSvg from "../../assets/svg/IconHomeSvg";
import IconPersonSvg from "../../assets/svg/IconPersonSvg";
import PigFeedSvg from "../../assets/svg/PigFeedSvg";
import UserGroupSvg from "../../assets/svg/UserGroupSvg";
import ButtonBackgroundImageCard from "../../components/common/ButtonBackgroundImageCard/ButtonBackgroundImageCard";
import FooterPartner from "../../components/common/Footer/FooterPartner";
import { getPartnerUnitStock } from "../../services/partner/historyStock.service";
import { TUintStock } from "../../types/partner/stock.type";
import { getCommaNumber } from "../../utils/number";
import { tw } from "../../utils/tw";
import { useNotificationController } from "../homepage/notificaction-page/controller";
import { useNewOrderController } from "../order-history/new-order/controller";
import OrderItem from "../order-history/views/OrderItem";
import { usePartnerLandingController } from "./controller";

type Props = {};

const PartnerLanding = (props: Props) => {
  const {
    GetProfilePartner,
    SummaryCustomer,
    SummaryRevenue,
    SummaryAssignment,
    SummaryContract,
    formPartnerRevenue,
    onChangeFormPartnerRevenue,
  } = usePartnerLandingController();

  const [selectMonth, setSelectMonth] = useState<TDropdownValue[]>([]);

  const { orderList, onMoreDetail, onAction } = useNewOrderController(false);

  const { dataNotification } = useNotificationController();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const [unitStock, setUnitStock] = useState<TUintStock>();

  const [isNotificationAllRead, setIsNotificationAllRead] =
    useState<boolean>(true);

  const fetchUintStock = async () => {
    const unitStock = await getPartnerUnitStock();
    if (unitStock) {
      setUnitStock(unitStock);
    }
  };
  useEffect(() => {
    fetchUintStock();
  }, []);

  useEffect(() => {
    onChangeFormPartnerRevenue("mount")(selectMonth);
  }, [selectMonth]);

  const [value, setValue] = useState(0);

  const onNavigate = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    []
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (dataNotification.length) {
      const isReadAll = dataNotification.every((item) => item.read === true);
      setIsNotificationAllRead(isReadAll);
    }
  }, [dataNotification]);

  return (
    <div>
      <div className="flex">
        <div className="flex-grow">
          <div
            className={`top-img-container bg-gray-200 flex flex-col bg-cover relative`}
            style={{
              backgroundColor: "rgb(229 231 235 / 0)",
              backgroundImage: `url(${BackgroundImage})`,
              minHeight: "100vh",
            }}
          >
            <img
              src={topIcon}
              className="w-[65%] top-10 right-4 opacity-25 absolute"
              alt="Top Icon"
            />
            <img
              src={topIcon3}
              className="topIcon1 w-[20%] top-[19%] right-12 opacity-25 absolute"
              alt="Top Icon 3"
            />
            <div className="flex w-11/12 relative justify-between items-center top-6 z-1 mx-auto">
              <img src={FarmIcon} className="w-[60%]" alt="farm-icon" />
              <Link to={"/notifications"}>
                <IconButton className="relative">
                  <NotificationsIcon className="notification-icon" />
                  {!isNotificationAllRead && (
                    <span className="absolute w-[7px] h-[7px] bg-[#F57C00] rounded-full top-[12px] left-[12px]"></span>
                  )}
                </IconButton>
              </Link>
            </div>
            <div className="farms-headTitle-container flex relative justify-left top-6 z-1 ml-4 mt-9">
              <h1 className="farms-headTitle text-[#ffff]">
                {t("farms.welcomeTitle")}
              </h1>
            </div>

            <div
              className="w-11/12 mt-[35px] mx-auto my-10 flex flex-col bg-white-200 pt-[10px] pb-9 px-[25px] rounded-[30px] bg-[#ffffff] transform "
              style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}
            >
              <div className="flex justify-between mt-[10px] gap-0 text-tertiary">
                <div className="flex ">
                  <PersonIcon />
                  <h2 className="text-base font-semibold ml-1">
                    {t("PartnerHomePage.Customer")}
                  </h2>
                </div>
                <div className="flex items-center">
                  <h2 className="text-[14px] font-normal">
                    {t("manageStock.detailBtn")}
                  </h2>
                  <ChevronRightOutlinedIcon />
                </div>
              </div>
              <div className="flex justify-start gap-6 mt-5 text-primary-900">
                <div className="flex-col">
                  <p className="text-xl font-medium">
                    {getCommaNumber(
                      get(SummaryCustomer?.customer, ["onboard"], 0)
                    )}
                  </p>
                  <span>{t("PartnerHomePage.OnboardCustomer")}</span>
                </div>
                {GetProfilePartner?.profile?.type === "core" && (
                  <div className="flex-col">
                    <p className="text-xl font-medium">
                      {getCommaNumber(
                        get(SummaryCustomer?.customer, ["credit"], 0)
                      )}
                    </p>
                    <span>{t("PartnerHomePage.CustomerCredit")}</span>
                  </div>
                )}
              </div>
            </div>

            <div className="pb-8 px-4 flex flex-col gap-3">
              <div className="font-sans flex flex-col gap-2">
                <div className="flex flex-row justify-between">
                  <div className="text-[#34407B] text-[20px] font-semibold">
                    {t("PartnerHomePage.title")}
                  </div>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => {
                      navigate("/partner/financial-overview");
                    }}
                  >
                    <h2 className={"text-primary text-[14px] whitespace-pre"}>
                      {t("PartnerHomePage.seeDetails")}
                    </h2>
                    <ChevronRightOutlinedIcon sx={{ color: "#34407b" }} />
                  </div>
                </div>
                <div className="text-[#3777BC] text-[14px]">
                  {t("PartnerHomePage.subtitle")}
                </div>
              </div>
              <div className="flex flex-row items-center justify-between">
                <div className="text-[#68C184] text-[16px] font-semibold">
                  {t("PartnerHomePage.year")} {dayjs().year() + 543}
                </div>
                <div>
                  <Dropdown
                    options={[
                      { label: t("BasicAccounting.January"), value: "1" },
                      { label: t("BasicAccounting.February"), value: "2" },
                      { label: t("BasicAccounting.March"), value: "3" },
                      { label: t("BasicAccounting.April"), value: "4" },
                      { label: t("BasicAccounting.May"), value: "5" },
                      { label: t("BasicAccounting.June"), value: "6" },
                      { label: t("BasicAccounting.July"), value: "7" },
                      { label: t("BasicAccounting.August"), value: "8" },
                      { label: t("BasicAccounting.September"), value: "9" },
                      { label: t("BasicAccounting.October"), value: "10" },
                      { label: t("BasicAccounting.November"), value: "11" },
                      { label: t("BasicAccounting.December"), value: "12" },
                    ]}
                    placeholder={t("BasicAccounting.month")}
                    onChange={(value) => setSelectMonth(value)}
                    renderValueArray={selectMonth}
                    choseStyle2
                    isCheckbox
                    multiple
                    containerDropdown="pb-0"
                    styleMenuItem={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenuItem-root": {
                            backgroundColor: "#FFFFFF",
                            boxShadow: "0px -1px 0px 0px #00000040 inset",
                            "&.Mui-selected": {
                              backgroundColor: "#FFFFFF",
                              "&:hover": {
                                backgroundColor: "#D9EBFE !important",
                              },
                            },
                            "&:hover": {
                              backgroundColor: "#D9EBFE",
                            },
                          },
                        },
                      },
                      MenuListProps: {
                        sx: {
                          padding: 0,
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div
                className="bg-[#D9EBFE] rounded-[24px] p-3 gap-3 flex flex-col"
                style={{
                  boxShadow: "1px 1px 10px 0px #0000001A",
                }}
              >
                <div className="font-sans text-[#34407B] flex flex-col gap-[6px] items-center">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="text-[30px] font-medium">
                      {getCommaNumber(
                        get(SummaryRevenue?.revenue, ["onboard"], 0) +
                          get(SummaryRevenue?.revenue, ["orderFeed"], 0) +
                          get(SummaryRevenue?.revenue, ["debtCollection"], 0),
                        true
                      )}
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      <ArrowDropUpIcon className="text-[#68C184] -mt-[10px]" />
                      <div className="text-[12px] font-semibold -mt-[12px]">
                        {t("PartnerHomePage.bath")}
                      </div>
                    </div>
                  </div>
                  <div className="text-[16px] font-normal">
                    {t("PartnerHomePage.income")}
                  </div>
                </div>
                <div className="bg-[#FFFFFF] rounded-[10px] p-3 gap-4 flex flex-row">
                  <div className=" w-full flex flex-col items-center">
                    <PersonIcon className="text-[#68C184]" />
                    <div className="text-[#191919] text-[18px] font-medium whitespace-pre">
                      {getCommaNumber(
                        get(SummaryRevenue?.revenue, ["onboard"], 0),
                        true
                      )}
                    </div>
                    <div className="text-[#646464] text-[12px] font-medium whitespace-pre">
                      {t("PartnerHomePage.newCustomers")}
                    </div>
                  </div>
                  {GetProfilePartner?.profile?.type === "core" && (
                    <>
                      <Divider orientation="vertical" />
                      <div className=" w-full flex flex-col items-center">
                        <ChartSquareBarSvg />
                        <div className="text-[#191919] text-[18px] font-medium whitespace-pre">
                          {getCommaNumber(
                            get(SummaryRevenue?.revenue, ["orderFeed"], 0),
                            true
                          )}
                        </div>
                        <div className="text-[#646464] text-[12px] font-medium whitespace-pre">
                          {t("PartnerHomePage.sales")}
                        </div>
                      </div>
                      <Divider orientation="vertical" />
                      <div className=" w-full flex flex-col items-center">
                        <PaymentsIcon className="rotate-180 text-[#68C184]" />
                        <div className="text-[#191919] text-[18px] font-medium whitespace-pre">
                          {getCommaNumber(
                            get(SummaryRevenue?.revenue, ["debtCollection"], 0),
                            true
                          )}
                        </div>
                        <div className="text-[#646464] text-[12px] font-medium whitespace-pre">
                          {t("PartnerHomePage.collectDebts")}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {GetProfilePartner?.profile?.type === "core" && (
              <>
                <div className="flex flex-col mx-4 mb-3">
                  <div className="flex justify-between">
                    <h2 className="text-primary text-xl font-semibold">
                      {t("feedOrderAndDelivery.myOrder.titleBar")}
                    </h2>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => navigate("/order")}
                    >
                      <h2 className={"text-primary text-[14px]"}>
                        {t("PartnerHomePage.seeDetails")}
                      </h2>
                      <ChevronRightOutlinedIcon sx={{ color: "#34407b" }} />
                    </div>
                  </div>
                  <span className="text-sm font-normal text-[#747474] pt-2">
                    {t("PartnerHomePage.manageOrder")}
                  </span>
                  {orderList.filter(
                    (order) =>
                      order.status === "new" ||
                      order.status === "ready-for-ship" ||
                      order.status === "shipping" ||
                      order.status === "shipped"
                  ).length > 0 ? (
                    <div className="pt-4 pb-4">
                      {(orderList || [])
                        .filter(
                          (order) =>
                            order.status === "new" ||
                            order.status === "ready-for-ship" ||
                            order.status === "shipping" ||
                            order.status === "shipped"
                        )
                        .slice(0, 3)
                        .map((order, index) => (
                          <OrderItem
                            key={order.id}
                            {...order}
                            index={index}
                            onMoreDetail={onMoreDetail(order.id)}
                            onAction={onAction(order)}
                            hideButtonAction
                          />
                        ))}
                    </div>
                  ) : (
                    <h2 className="w-full flex justify-center text-sm my-4 text-[#C9C9C9]">
                      {t("feedOrderAndDelivery.myOrder.titleBar")}
                    </h2>
                  )}
                </div>
                <div className="flex flex-col mx-4 mb-3">
                  <div className="flex justify-between">
                    <h2 className="text-primary text-xl font-semibold">
                      {t("PartnerHomePage.manageStock")}
                    </h2>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        navigate("/partner/stock-details");
                      }}
                    >
                      <h2 className={"text-primary text-[14px]"}>
                        {t("manageStock.detailBtn")}
                      </h2>
                      <ChevronRightOutlinedIcon sx={{ color: "#34407b" }} />
                    </div>
                  </div>
                  <span className="text-sm font-normal text-[#747474] pt-2">
                    {t("PartnerHomePage.manageMyStock")}
                  </span>
                </div>
                <div className="mx-4">
                  <span className="font-semibold">{t("manageStock.feed")}</span>
                  <div
                    className="w-full flex justify-between my-3 items-center bg-white-200 px-4 py-2 rounded-[20px] bg-[#ffffff] transform "
                    style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 5px" }}
                  >
                    <div className="flex items-center">
                      <OrderStockFood />
                      <span className="text-tertiary font-semibold">
                        {t("PartnerHomePage.AllStock")}
                      </span>
                    </div>
                    <span className="text-primary font-medium">
                      {unitStock?.result}
                    </span>
                  </div>
                </div>
                <div className="mt-6 flex flex-col gap-4 mx-4 pb-6">
                  <div className="flex flex-col">
                    <div className="flex justify-between">
                      <h2 className="text-primary text-xl font-semibold">
                        {t("PartnerHomePage.OrderStock")}
                      </h2>
                    </div>
                    <span className="text-sm font-normal text-tertiary pt-2">
                      {t("PartnerHomePage.MyOrderStock")}
                    </span>
                  </div>
                  <ButtonBackgroundImageCard
                    image={ImgMngStock}
                    title={t("HomePage.LetHelpYourBusiness")}
                    btnTitle={t("PartnerHomePage.manageStock")}
                    onClick={() => {
                      navigate("/stock");
                    }}
                  />
                </div>
                <div className="flex flex-col mt-8 mx-4 mb-8">
                  <div className="flex justify-between">
                    <h2 className="text-primary text-xl font-semibold">
                      {t("PartnerHomePage.DetailDebtCustomer")}
                    </h2>
                    <div
                      className="flex items-center cursor-pointer"
                      onClick={() => {
                        navigate("/partner/track-debt");
                      }}
                    >
                      <h2 className={"text-primary text-[14px] whitespace-pre"}>
                        {t("manageStock.detailBtn")}
                      </h2>
                      <ChevronRightOutlinedIcon sx={{ color: "#34407b" }} />
                    </div>
                  </div>
                  <span className="text-sm font-normal text-[#747474] pt-2">
                    {t("PartnerHomePage.DebtTitle")}
                  </span>
                  <div className="pt-4 flex flex-row gap-1">
                    <div className="bg-[#EEF2FF] flex flex-col py-[10px] px-4 gap-1 rounded-[10px] w-full">
                      <div className="text-[#3777BC] text-[12px] font-semibold">
                        {t("BasicAccounting.all")}
                      </div>
                      <div className="flex flex-row gap-2 items-center">
                        <DocumentOutlined />
                        <div className="text-[#34407B] text-[20px] font-medium">
                          {getCommaNumber(
                            get(SummaryAssignment, ["result", "all"], 0)
                          )}
                        </div>
                        <div className="text-[#34407B] text-[12px] font-semibold">
                          {t("PartnerHomePage.Blade")}
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#EEF2FF] flex flex-col py-[10px] px-4 gap-1 rounded-[10px] w-full">
                      <div className="text-[#3777BC] text-[12px] font-semibold">
                        {t("PartnerHomePage.FollowUp")}
                      </div>
                      <div className="flex flex-row gap-2 items-center">
                        <DocumentOutlined />
                        <div className="text-[#34407B] text-[20px] font-medium">
                          {getCommaNumber(
                            get(SummaryAssignment, ["result", "following"], 0)
                          )}
                        </div>
                        <div className="text-[#34407B] text-[12px] font-semibold">
                          {t("PartnerHomePage.Blade")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-col gap-2 mx-4 pb-8">
              <div className="font-sans text-[#34407B] flex flex-row justify-between">
                <div className="font-semibold text-[20px]">
                  {t("FinancialSupport.ContractInformation")}
                </div>
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    navigate("/partner/partner-contract");
                  }}
                >
                  <h2 className={"text-primary text-[14px] whitespace-pre"}>
                    {t("manageStock.detailBtn")}
                  </h2>
                  <ChevronRightOutlinedIcon sx={{ color: "#34407b" }} />
                </div>
              </div>
              <div className={tw("grid grid-cols-2 gap-3 ", "pb-[20%]")}>
                <div className="bg-[#EEF2FF] p-4 rounded-[20px] gap-[6px] flex flex-col justify-center items-center col-span-2">
                  <div className="w-[48px] h-[48px] bg-[#FFFFFF] rounded-[100px] gap-[10px] flex items-center justify-center">
                    <GroupIcon className="text-[#68C184]" />
                  </div>
                  <div className="flex flex-col gap-[6px] items-center justify-center">
                    <div className="flex gap-2 font-sans text-[#34407B]">
                      <div className="text-[30px] font-medium">
                        {getCommaNumber(
                          get(SummaryContract?.contract, ["onboardRate"], 0)
                        )}
                      </div>
                      <div className="flex flex-col justify-center items-center">
                        <ArrowDropUpIcon
                          className="text-[#68C184] -mt-[10px]"
                          fontSize="large"
                        />
                        <div className="text-[12px] font-semibold -mt-[15px]">
                          {t("PartnerHomePage.BahtPerList")}
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#5572EB] px-2 py-1 rounded-[20px] flex items-center justify-center">
                      <div className="text-[#EEF2FF] font-sans font-normal text-[16px] text-center">
                        {t("PartnerHomePage.ActiveUser")}
                      </div>
                    </div>
                  </div>
                </div>
                {GetProfilePartner?.profile?.type === "core" && (
                  <>
                    <div className="bg-[#EEF2FF] p-4 rounded-[20px] gap-[6px] flex flex-col justify-center items-center">
                      <div className="w-[48px] h-[48px] bg-[#FFFFFF] rounded-[100px] gap-[10px] flex items-center justify-center">
                        <PigFeedSvg />
                      </div>
                      <div className="flex flex-col gap-[6px] items-center justify-center">
                        <div className="flex gap-2 font-sans text-[#34407B]">
                          <div className="text-[30px] font-medium">
                            {getCommaNumber(
                              get(SummaryContract?.contract, ["revenueRate"], 0)
                            )}
                            %
                          </div>
                        </div>
                        <div className="bg-[#5572EB] px-2 py-1 rounded-[20px] flex items-center justify-center">
                          <div className="text-[#EEF2FF] font-sans font-normal text-[16px] text-center">
                            %{t("PartnerHomePage.FoodSales")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#EEF2FF] p-4 rounded-[20px] gap-[6px] flex flex-col justify-center items-center">
                      <div className="w-[48px] h-[48px] bg-[#FFFFFF] rounded-[100px] gap-[10px] flex items-center justify-center">
                        <FarmSvg />
                      </div>
                      <div className="flex flex-col gap-[6px] items-center justify-center">
                        <div className="flex gap-2 font-sans text-[#34407B]">
                          <div className="text-[30px] font-medium">
                            {getCommaNumber(
                              get(
                                SummaryContract?.contract,
                                ["invoiceFollowRate"],
                                0
                              )
                            )}
                            %
                          </div>
                        </div>
                        <div className="bg-[#5572EB] px-2 py-1 rounded-[20px] flex items-center justify-center">
                          <div className="text-[#EEF2FF] font-sans font-normal text-[16px] text-center">
                            %{t("PartnerHomePage.DebtCollection")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div />
          </div>
        </div>
      </div>
      {GetProfilePartner?.profile?.type === "core" && (
        <FooterPartner handleChange={() => {}} selectedTab={0} />
      )}
      {GetProfilePartner?.profile?.type === "basic" && (
        <div className="fixed w-full bottom-0 flex justify-center p-4">
          <div
            className="h-[70px] w-[100%] rounded-full shadow-lg flex items-center justify-center"
            style={{
              background:
                "linear-gradient(180deg, #68C184 -42.84%, #68C184 15.93%, #2E894A 100%)",
            }}
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              className="w-full"
              aria-label="icon tabs example"
              variant="fullWidth"
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "translateY(-8px)",
                    width: "50px",
                    bgcolor: "#FFFFFF",
                    height: "3px",
                    borderRadius: "8px",
                  },
                },
              }}
              sx={{
                height: "70px",
                minHeight: "unset",
                paddingX: "20px",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "center",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#FFFFFF !important",
                      "&.Mui-selected": {
                        color: "#FFFFFF",
                      },
                    },
                  },
                },
              }}
            >
              <Tab
                icon={
                  <div className="text-[#FFFFFF]">
                    <IconHomeSvg />
                  </div>
                }
                aria-label="home"
              />
              <Tab icon={<UserGroupSvg />} aria-label="group" />
              <Tab
                icon={<IconPersonSvg />}
                onClick={onNavigate("/partner/account-center")}
              />
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerLanding;
