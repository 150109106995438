const invoice = {
  uploadInvoice: (invoiceId: string) =>
    `invoice/common/document/${invoiceId}/upload/invoice`,
  uploadOther: (invoiceId: string) =>
    `invoice/common/document/${invoiceId}/upload/other`,
  updateInvoice: (invoiceId: string) => `/invoice/sale/upcoming/${invoiceId}`,
  editInvoiceDoc: (invoiceId: string) =>
    `/invoice/common/edit-document/${invoiceId}`,
  getInvoiceDownloadURL: (
    invoiceId: string,
    documentType: string,
    fileId: string
  ) =>
    `/invoice/common/document/${invoiceId}/download/${documentType}/${fileId}`,
  getReceiptDownloadURL: (documentType: string, fileId: string) =>
    `/invoice/common/receipt/document/download/${documentType}/${fileId}`,
  editInvoiceDocument: (invoiceId: string) =>
    `/invoice/common/document/${invoiceId}`,
  uploadPresign: (documentType: string) =>
    `/invoice/common/receipt/document/upload/${documentType}`,
};

const adminApplicationUsage = {
  getYear: "/dashboard/application-usage/dropdown/years",
  getGroupArea: "/dashboard/application-usage/dropdown/group-area",
  getDailyLogUsersGraph: "/dashboard/application-usage/daily-log-users/graph",
  getListDailyLogUsers: "/dashboard/application-usage/daily-log-users",
  exportListDailyLogUsers: "/dashboard/application-usage/daily-log-users",
  getActiveUsersGraph: "/dashboard/application-usage/active-users/graph",
  getListActiveUsers: "/dashboard/application-usage/active-users",
  exportListActiveUsers: "/dashboard/application-usage/active-users/export",
};

const feedCustomer = {
  getYear: "/dashboard/feed-customers/dropdown/years",
  getGroupArea: "/dashboard/feed-customers/dropdown/group-area",
  getFeedCustomerGraph: "/dashboard/feed-customers/graph",
  getListFeedCustomer: "/dashboard/feed-customers",
  getListFeedCustomerExport: "/dashboard/feed-customers/export",
  getFeedCustomerGradeGraph: "/dashboard/feed-customers/grade/graph",
  getListFeedCustomerGrade: "/dashboard/feed-customers/grade",
  getListFeedCustomerGradeExport: "/dashboard/feed-customers/grade/export",
};

const performance = {
  getGroupArea: "/dashboard/performance/dropdown/group-area",
};

const performancePartner = {
  getCoreGraph: "/dashboard/performance/partners/core/map",
  getListCore: "/dashboard/performance/partners/core",
  getListCoreExport: "/dashboard/performance/partners/core/export",
  getBasicGraph: "/dashboard/performance/partners/basic/map",
  getListBasic: "/dashboard/performance/partners/basic",
  getListBasicExport: "/dashboard/performance/partners/basic/export",
};

const utils = {
  getGeoLocations: "/geolocation",
};

export const apiPaths = {
  invoice,
  adminApplicationUsage,
  feedCustomer,
  performance,
  performancePartner,
  utils,
};
