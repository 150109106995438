export default function () {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.664062" width="40" height="40" rx="20" fill="#62A665" />
      <path
        d="M24.2672 15.2016C24.2672 17.1898 22.6554 18.8016 20.6672 18.8016C18.679 18.8016 17.0672 17.1898 17.0672 15.2016C17.0672 13.2133 18.679 11.6016 20.6672 11.6016C22.6554 11.6016 24.2672 13.2133 24.2672 15.2016Z"
        fill="white"
      />
      <path
        d="M30.2672 17.6016C30.2672 18.927 29.1927 20.0016 27.8672 20.0016C26.5417 20.0016 25.4672 18.927 25.4672 17.6016C25.4672 16.2761 26.5417 15.2016 27.8672 15.2016C29.1927 15.2016 30.2672 16.2761 30.2672 17.6016Z"
        fill="white"
      />
      <path
        d="M25.4672 26.0016C25.4672 23.3506 23.3181 21.2016 20.6672 21.2016C18.0162 21.2016 15.8672 23.3506 15.8672 26.0016V29.6016H25.4672V26.0016Z"
        fill="white"
      />
      <path
        d="M15.8672 17.6016C15.8672 18.927 14.7927 20.0016 13.4672 20.0016C12.1417 20.0016 11.0672 18.927 11.0672 17.6016C11.0672 16.2761 12.1417 15.2016 13.4672 15.2016C14.7927 15.2016 15.8672 16.2761 15.8672 17.6016Z"
        fill="white"
      />
      <path
        d="M27.8672 29.6016V26.0016C27.8672 24.7366 27.541 23.5478 26.9681 22.5148C27.2555 22.4409 27.5567 22.4016 27.8672 22.4016C29.8554 22.4016 31.4672 24.0133 31.4672 26.0016V29.6016H27.8672Z"
        fill="white"
      />
      <path
        d="M14.3663 22.5148C13.7934 23.5478 13.4672 24.7366 13.4672 26.0016V29.6016H9.86719V26.0016C9.86719 24.0133 11.479 22.4016 13.4672 22.4016C13.7776 22.4016 14.0789 22.4409 14.3663 22.5148Z"
        fill="white"
      />
    </svg>
  );
}
