import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import {
  DragHandle,
  SortableItem,
} from "../../../../../components/DragAndDrop/SortableItem";
import { SortableList } from "../../../../../components/DragAndDrop/SortableList";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { arrangeUserManual } from "../../../services/farmCommunity.service";
import { SequenceManualRow } from "../../../types/farmCommunity";

type SortUserManualProps = {
  tabName: string;
  data: SequenceManualRow[];
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
};

export type ISortUserManualForm = {
  articleType: string;
  arrangedManualArticles: ArrangedUserManual[];
};

export type ArrangedUserManual = {
  id: string;
  displayPriority: number;
  topicName: string;
  type: string;
  category: string;
  manualId: string;
};

const SortUserManualModal = ({
  tabName,
  data,
  isOpen,
  handleClose,
}: SortUserManualProps) => {
  const dispatch = useDispatch();
  const methods = useForm<ISortUserManualForm>({
    defaultValues: {
      arrangedManualArticles: [
        {
          id: "",
          topicName: "",
          displayPriority: 0,
          type: "",
          category: "",
          manualId: "",
        },
      ],
    },
  });

  const handleSubmit = (value: ISortUserManualForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await arrangeUserManual(data[0].manualId, value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            console.error(error);
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const handleChange = (newOrder: ArrangedUserManual[]) => {
    methods.setValue("arrangedManualArticles", newOrder);
  };

  useEffect(() => {
    if (data) {
      methods.reset({
        articleType: tabName,
        arrangedManualArticles: data || [],
      });
    }
  }, [data]);

  return (
    <>
      <AdminModal
        label={"เรียงลำดับ"}
        isOpen={isOpen}
        size={"sm"}
        handleClose={() => handleClose(false)}
        children={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <SortableList
                items={methods.watch("arrangedManualArticles")}
                onChange={handleChange}
                renderItem={(item, index) => (
                  <SortableItem id={item.id}>
                    <div className="flex items-center justify-between w-full">
                      <div className="flex items-center">
                        <DragHandle />
                        <span
                          className="ml-2 truncate"
                          style={{
                            display: "inline-block",
                            maxWidth: "450px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >{`${index + 1}. ${item.topicName}`}</span>
                      </div>
                      {/* <div
                        className="cursor-pointer"
                        onClick={() => remove(index)}
                      >
                        <DeleteOutlinedIcon />
                      </div> */}
                    </div>
                  </SortableItem>
                )}
              />
              <div className="flex justify-end mt-6">
                <ButtonFillCustom title={"บันทึก"} type="submit" />
              </div>
            </form>
          </FormProvider>
        }
      />
    </>
  );
};

export default SortUserManualModal;
