import { Tab, TableCell, Tabs } from "@mui/material";
import React, { useState } from "react";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import { ITarget } from "../types/SaleTargetTable.type";
import TargetHistoryTabs from "./TargetHistoryTabs";

type Props = {
  userTarget?: ITarget[];
  customerTarget?: ITarget[];
  feedVolumeTarget?: ITarget[];
  feedRevenueTarget?: ITarget[];
  basicPartnerTarget?: ITarget[];
  corePartnerTarget?: ITarget[];
};

const ExpandSaleTargetSaleLead = ({
  userTarget,
  customerTarget,
  feedVolumeTarget,
  feedRevenueTarget,
  basicPartnerTarget,
  corePartnerTarget,
}: Props) => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };

  return (
    <>
      <TableCell colSpan={15} sx={{ bgcolor: "#F6FBFF" }}>
        <div className="w-full h-full py-2">
          <div className="px-[40px] py-[24px] bg-[#FFFFFF] rounded-[20px]">
            <p className="font-sans text-[24px] font-bold text-[#68C184]">
              ประวัติ target
            </p>
            <div className="mt-6">
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label="farm-information-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
                sx={{
                  height: "36px",
                  minHeight: "unset",
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-flexContainer": {
                      alignItems: "end",
                      height: "100%",
                      display: "flex",
                      gap: "12px",
                      "& .MuiTab-root": {
                        padding: "6px 28px",
                        textTransform: "unset",
                        fontFamily: "IBM Plex Sans Thai",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "unset",
                        color: "#000000",
                        borderBottom: "3px solid #DDF3E4",
                        "& .Mui-selected": {
                          color: "#000000",
                        },
                      },
                    },
                  },
                }}
              >
                <Tab label={"ผู้ใช้"} />
                <Tab label={"ลูกค้า"} />
                <Tab label={"Selling Feed Volume"} />
                <Tab label={"Selling Feed Revenue"} />
                <Tab label={"Basic Partner"} />
                <Tab label={"Core Partner"} />
              </Tabs>
            </div>
            <TabPanelCustom value={tabIndex} index={0} prefix="user">
              <TargetHistoryTabs targets={userTarget} />
            </TabPanelCustom>
            <TabPanelCustom value={tabIndex} index={1} prefix="customer">
              <TargetHistoryTabs targets={customerTarget} />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={2}
              prefix="selling-feed-volume"
            >
              <TargetHistoryTabs targets={feedVolumeTarget} />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={3}
              prefix="selling-feed-revenue"
            >
              <TargetHistoryTabs targets={feedRevenueTarget} />
            </TabPanelCustom>
            <TabPanelCustom value={tabIndex} index={4} prefix="basic-partner">
              <TargetHistoryTabs targets={basicPartnerTarget} />
            </TabPanelCustom>
            <TabPanelCustom value={tabIndex} index={5} prefix="core-partner">
              <TargetHistoryTabs targets={corePartnerTarget} />
            </TabPanelCustom>
          </div>
        </div>
      </TableCell>
    </>
  );
};

export default ExpandSaleTargetSaleLead;
