import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  getExportCreditHoldOrder,
  getListCreditHoldOrder,
} from "../../../../../services/SaleOrderService";
import { thousandComma } from "../../../../../utils/common/string";
import { UserRole } from "../../../../../utils/enum/user-role.enum";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import TableCustom, {
  ITableCustomHeadCell,
  Order,
} from "../../../../components/Table/TableCustom";
import { usePaginationFilter } from "../../../../hooks/usePaginationFilter.hook";
import CreditHoldOrderSearchFilter from "../../components/CreditHoldOrderSearchFilter";
import { TChipLabelWithText, TCreditHoldStatus } from "../types";

interface Data {
  id: string;
  orderId: string;
  customerId: string;
  customerName: string;
  customerGrade: string;
  approvalLimit: string;
  remainingCreditLimit: string;
  outstandingDeptAmount: string;
  numberOfDaysDebtOutstanding: string;
  newOrder: string;
  orderDate: string;
  sendToLendingDate: string;
  reason: string;
  status: string;
  approveReason: string;
}

const MapOrderByWithFilter: { [K in keyof Data]?: string } = {
  orderId: "order_no",
  customerId: "customer_no",
  customerGrade: "customer_grade",
  orderDate: "order_date",
};

function createData(
  id: string,
  orderId: string,
  customerId: string,
  customerName: string,
  customerGrade: string,
  approvalLimit: number,
  remainingCreditLimit: number,
  outstandingDeptAmount: number,
  numberOfDaysDebtOutstanding: number,
  newOrder: number,
  orderDate: string,
  status: string,
  reason?: string,
  approveReason?: string
): Data {
  return {
    id,
    orderId,
    customerId,
    customerName,
    customerGrade,
    approvalLimit: thousandComma(approvalLimit) + " บาท",
    remainingCreditLimit: thousandComma(remainingCreditLimit) + " บาท",
    outstandingDeptAmount: thousandComma(outstandingDeptAmount) + " บาท",
    numberOfDaysDebtOutstanding:
      thousandComma(numberOfDaysDebtOutstanding) + " วัน",
    newOrder: thousandComma(newOrder) + " บาท",
    orderDate: dayjs(orderDate).format("DD/MM/YYYY"),
    sendToLendingDate: dayjs(orderDate).format("DD/MM/YYYY"),
    reason: reason ? reason : "",
    status: status,
    approveReason: approveReason ? approveReason : "",
  };
}

const ChipLabelWithText: TChipLabelWithText = {
  waiting_for_lender_approval: {
    label: "รออนุมัติ",
    fontColor: "#F57C00",
    bgColor: "#FBCFA166",
  },
  lender_approved: {
    label: "อนุมัติแล้ว",
    fontColor: "#409261",
    bgColor: "#E9FFEF",
  },
  waiting_for_sale_approval: {
    label: "อนุมัติแล้ว",
    fontColor: "#409261",
    bgColor: "#E9FFEF",
  },
};
export interface ISearchCreditHoldFilter {
  searchText: string;
  daysOfDebt: string;
}

function SubmitToLendingTab({
  setCount,
  currentTab,
}: {
  setCount: (valueTab1: number, valueTab2: number) => void;
  currentTab: number;
}) {
  const { user } = useAdminAuth();

  const { t } = useTranslation();

  const isLending = user?.userRole === UserRole.Lending;
  const tabName = "approved_credit_hold";
  const [searchFilter, setSearchFilter] =
    React.useState<ISearchCreditHoldFilter>({
      searchText: "",
      daysOfDebt: "",
    });
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("orderId");
  const [itemSelected, setItemSelected] = React.useState<string[]>([]);
  const { page, limit, setPage } = usePaginationFilter();
  const [list, setList] = React.useState<Data[]>([]);
  const [countRows, setCountRows] = React.useState<number>(0);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const headCells: ITableCustomHeadCell<keyof Data>[] = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.soNumber"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.farmerID"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.nameSurname"),
      width: "180px",
      isSorting: false,
    },
    {
      id: "customerGrade",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.grading"),
      width: "120px",
      isSorting: true,
    },
    {
      id: "approvalLimit",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.creditLimit"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "remainingCreditLimit",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.creditBalance"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "outstandingDeptAmount",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.overDueAmount"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "numberOfDaysDebtOutstanding",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.overDueDays"),
      width: "120px",
      isSorting: false,
    },
    {
      id: "newOrder",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.newOrderAmount"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "orderDate",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.orderDate"),
      width: "120px",
      isSorting: true,
    },
    {
      id: "sendToLendingDate",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.submitDate"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "reason",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.reason"),
      width: "200px",
      isSorting: false,
    },
    {
      id: "approveReason",
      numeric: false,
      disablePadding: false,
      label: "เหตุผลการอนุมัติ",
      width: "200px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: t("saleOrder.submitToLendingTab.table.status"),
      width: "120px",
      isSorting: false,
    },
  ];

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("tabName", tabName);
    searchParams.append("page", page.toString());
    searchParams.append("limit", limit.toString());
    searchParams.append("orderBy", MapOrderByWithFilter[orderBy] ?? "");
    searchParams.append("direction", order);
    searchParams.append("searchText", searchFilter.searchText);
    searchParams.append("daysOfDebt", searchFilter.daysOfDebt);
    setList([]);
    const data = await getListCreditHoldOrder(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.orderNumber,
          row.farmerRegistrationId,
          row.name + " " + row.surname,
          row.creditRating,
          row.credit,
          row.remainingCredit,
          row.debt,
          row.dayOfDebtOutStanding,
          row.newOrderAmount,
          row.orderedAt,
          row.status,
          row.saleReason,
          row.approveReason
        );
      });
      setList(newList);
    }
    if (isLending) {
      setCount(
        data?.countWaitingToApproveCredit || 0,
        data?.countApprovedCredit || 0
      );
    } else {
      setCount(
        data?.countWaitingToSendToApproveCredit || 0,
        data?.countWaitingToApproveCredit || 0
      );
    }
    setCountRows(data?.countRows ?? 0);
  };

  const filterRoleSaleHeadCells = headCells.filter((column) => {
    return column.label !== "เหตุผลการอนุมัติ";
  });

  const handleChangeOrder = async (order: keyof Data) => {
    setOrderBy(order);
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `credit_hold_tracking_tab_${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "หมายเลขสั่งซื้อ"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "เกรดลูกค้า"
      | "วงเงินอนุมัติ"
      | "วงเงินคงเหลือ"
      | "จำนวนหนี้คงค้าง"
      | "จำนวนวันคงค้างหนี้"
      | "ยอดสั่งซื้อใหม่"
      | "วันที่สั่งสินค้า"
      | "วันที่ส่งให้ Lending"
      | "เหตุผล"
      | "สถานะ";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getExportCreditHoldOrder(itemSelected);
    if (allSelectItemData?.rows) {
      const data: TExcelData[] = allSelectItemData?.rows.map<TExcelData>(
        (row) => {
          const mappedData = createData(
            row.id,
            row.orderNumber,
            row.farmerRegistrationId,
            row.name + " " + row.surname,
            row.creditRating,
            row.credit,
            row.remainingCredit,
            row.debt,
            row.dayOfDebtOutStanding,
            row.dayOfDebtOutStanding,
            row.orderedAt,
            row.status,
            row.saleReason
          );
          return {
            หมายเลขสั่งซื้อ: mappedData.orderId,
            รหัสลูกค้า: mappedData.customerId,
            ชื่อลูกค้า: mappedData.customerName,
            เกรดลูกค้า: mappedData.customerGrade,
            วงเงินอนุมัติ: mappedData.approvalLimit,
            วงเงินคงเหลือ: mappedData.remainingCreditLimit,
            จำนวนหนี้คงค้าง: mappedData.outstandingDeptAmount,
            จำนวนวันคงค้างหนี้: mappedData.numberOfDaysDebtOutstanding,
            ยอดสั่งซื้อใหม่: mappedData.newOrder,
            วันที่สั่งสินค้า: mappedData.orderDate,
            "วันที่ส่งให้ Lending": mappedData.sendToLendingDate,
            เหตุผล: mappedData.reason || "-",
            สถานะ:
              ChipLabelWithText[mappedData.status as TCreditHoldStatus].label,
          };
        }
      );
      return data;
    }
    return [];
  };

  React.useEffect(() => {
    handleFetch();
  }, [orderBy, order, page, currentTab]);

  return (
    <div className="w-full h-full">
      <CreditHoldOrderSearchFilter
        onExport={handleExportExcel}
        itemSelected={itemSelected}
        onSearch={handleFetch}
        daysOfDebt={searchFilter.daysOfDebt}
        onChangeDaysOfDebt={(value: string) => {
          setSearchFilter((prev) => ({
            ...prev,
            daysOfDebt: value,
          }));
        }}
        searchText={searchFilter.searchText}
        onChangeSearchText={(value: string) => {
          setSearchFilter((prev) => ({
            ...prev,
            searchText: value,
          }));
        }}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={order}
          orderBy={orderBy}
          rowsPerPage={limit}
          rows={list}
          itemsSelected={itemSelected}
          headerItems={isLending ? headCells : filterRoleSaleHeadCells}
          page={page}
          onChangeOrderBy={(val) => handleChangeOrder(val as keyof Data)}
          onChangeOrder={(val) => setOrder(val)}
          onChangePage={(val) => setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={countRows}
          currentPageItems={list.map((row) => row.id)}
          rowChildren={
            <>
              {list.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const status = row.status as TCreditHoldStatus;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          console.info("I'm a button.");
                        }}
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerGrade}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.approvalLimit}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.remainingCreditLimit}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.outstandingDeptAmount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.numberOfDaysDebtOutstanding}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.newOrder}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.reason ? row.reason : "-"}
                    </TableCell>
                    {isLending && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {row.approveReason ? row.approveReason : "-"}
                      </TableCell>
                    )}
                    <TableCell>
                      <ChipOrderStatus
                        label={ChipLabelWithText[status].label}
                        fontColor={ChipLabelWithText[status].fontColor}
                        bgColor={ChipLabelWithText[status].bgColor}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
    </div>
  );
}

export default SubmitToLendingTab;
