import { memo } from "react";
import {
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { get, isEmpty } from "lodash";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Input } from "../Input";
import { Dropdown } from "../Dropdown";
import { tw } from "../../../../../utils/tw";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import TableCustom from "../../../../components/Table/TableCustom";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../../components/Input/TextInputSearchCustom";
import { ICreateGroupAreaModalProps } from "./interface";
import { useCreateAndEditGroupAreaModal } from "./controller";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import MoveToModal from "./views/MoveToModal";
import AddModal from "./views/AddModal";
import { useTranslation } from "react-i18next";

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  row: tw(
    `w-full flex flex-col md:flex-row item-start justify-between gap-x-3`
  ),
  space: tw(`h-3`),
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
  editActionContainer: tw(`flex flex-row w-full mt-3 gap-x-3 items-center`),
};

const CreateGroupAreaModal = (props: ICreateGroupAreaModalProps) => {
  const { t } = useTranslation();
  const { isOpen, options, editDetail, refetch } = props;

  const {
    isEdit,
    currentPage,
    setCurrentPage,
    onCloseFn,
    errors,
    form,
    onChangeForm,
    provinceOptions,
    districtOptions,
    itemSelected,
    onSelectAllClick,
    customerCount,
    customerList,
    customerSelectedList,
    onSelectCustomer,
    onCreate,
    searchText,
    onSearch,
    onClearSearchText,
    isOpenMoveToModal,
    openMoveTo,
    closeMoveTo,
    isOpenAddModal,
    openAdd,
    closeAdd,
  } = useCreateAndEditGroupAreaModal(props);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={tw(
        classes.container,
        isEdit ? "max-w-[900px]" : "max-w-[716px]"
      )}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>
            {!isEdit
              ? t("groupArea.button.createNewGroupArea")
              : t("groupArea.button.editGroupArea")}
          </p>
          <IconButton onClick={onCloseFn}>
            <CrossIcon />
          </IconButton>
        </div>
        <Input
          label={t("groupArea.modal.create.nameOfGroupArea")}
          required
          errorMessage={errors.name}
          value={form.name}
          onChangeText={onChangeForm("name")}
        />
        <div className={classes.space} />
        <div className={classes.row}>
          <Dropdown
            label={t("groupArea.modal.create.region")}
            required
            options={options.areaOptions}
            onChange={onChangeForm("area")}
            renderValueArray={form.area}
            errorMessage={errors.area}
          />
          <Dropdown
            label={t("groupArea.modal.create.province")}
            required
            multiple
            disabled={isEmpty(form.area)}
            options={provinceOptions}
            onChange={onChangeForm("province")}
            renderValueArray={form.province}
            errorMessage={errors.province}
          />
        </div>
        <div className={classes.space} />
        <Dropdown
          label={t("groupArea.modal.create.district")}
          required
          multiple
          disabled={isEmpty(form.province)}
          options={districtOptions}
          onChange={onChangeForm("district")}
          renderValueArray={form.district}
          errorMessage={errors.district}
        />
        <div className={classes.space} />
        <div className={classes.row}>
          <Dropdown
            label={t("groupArea.modal.create.nameOfSales")}
            required
            options={options.employeeOptions}
            onChange={onChangeForm("employee")}
            renderValueArray={form.employee}
            errorMessage={errors.employee}
          />
          <Dropdown
            label={t("groupArea.modal.create.nameOfPartner")}
            required
            options={options.partnerOptions}
            onChange={onChangeForm("partner")}
            renderValueArray={form.partner}
            errorMessage={errors.partner}
          />
        </div>
        {isEdit && (
          <div className={classes.editActionContainer}>
            <TextInputSearchCustom
              value={searchText}
              className="max-w-[470px]"
              onChange={onSearch}
              placeholder="ค้นหาโดยชื่อลูกค้า, พนักงานขาย"
              InputProps={{
                endAdornment: searchText ? (
                  <CloseRoundedIcon
                    sx={{ cursor: "pointer" }}
                    onClick={onClearSearchText}
                  />
                ) : null,
              }}
            />
            {itemSelected.length > 0 && (
              <>
                <div className="text-[#646464] text-[14px] font-medium whitespace-pre flex flex-1">
                  เลือกไว้ {itemSelected.length} รายการ
                </div>
                <ButtonOutlinedCustom
                  title="ย้ายไป"
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  onClick={openMoveTo}
                />
              </>
            )}
            <ButtonFillCustom
              title="เพิ่ม"
              disabled={itemSelected.length >= 1}
              onClick={openAdd}
            />
          </div>
        )}
        <div className="h-6" />
        <TableCustom
          order="desc"
          orderBy={""}
          totalItemSize={customerCount}
          rowsPerPage={10}
          page={currentPage}
          headerItems={[
            {
              id: "customerCode",
              numeric: false,
              disablePadding: false,
              label: "รหัสลูกค้า",
              width: "50%",
              isSorting: false,
            },
            {
              id: "customerName",
              numeric: false,
              disablePadding: false,
              label: "ชื่อลูกค้า",
              width: "50%",
              isSorting: false,
            },
          ]}
          rows={customerList}
          onChangePage={setCurrentPage}
          currentPageItems={customerList.map(({ id }) => id)}
          itemsSelected={itemSelected}
          onSelectItem={() => {}}
          onSelectAllClick={onSelectAllClick}
          hideSelect={!isEdit}
          onChangeOrder={() => {}}
          onChangeOrderBy={() => {}}
          EmptyComponent={
            customerList.length === 0 ? (
              <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
            ) : null
          }
          rowChildren={customerList.map((row) => {
            const isSelected = get(customerSelectedList, row.id, false);
            return (
              <TableRow
                key={row.id}
                hover
                className="border-x-[1px] border-x-[#ddd]"
              >
                {isEdit && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isSelected}
                      onClick={onSelectCustomer(row.id)}
                      sx={{
                        borderWidth: "1px",
                        color: "#C9C9C9",
                        "&.Mui-checked": {
                          color: "#68C184",
                        },
                      }}
                    />
                  </TableCell>
                )}
                <TableCell className="!text-secondary underline">
                  {row.code}
                </TableCell>
                <TableCell>{row.name}</TableCell>
              </TableRow>
            );
          })}
        />
        <div className="w-full flex justify-end items-end mt-6">
          <ButtonFillCustom
            title={t("common.button.confirm")}
            onClick={onCreate}
          />
        </div>
      </DialogContent>
      <MoveToModal
        isOpen={isOpenMoveToModal}
        onClose={closeMoveTo}
        editDetail={editDetail}
        farmerSelected={itemSelected}
        refetch={refetch}
      />
      <AddModal
        isOpen={isOpenAddModal}
        onClose={closeAdd}
        options={options}
        editDetail={editDetail}
        refetch={refetch}
      />
    </Dialog>
  );
};

export default memo(CreateGroupAreaModal);
