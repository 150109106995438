import { useCallback, useEffect, useState } from "react";
import { ICreditFeedData } from "../../../../../types/CreditFeed.type";
import CreditFarmSvg from "../../../../assets/svg/CreditFarm.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import { useCreditFeedController } from "../../../credit/credit-feed/controller";
import ModalApplyCredit from "../../../credit/credit-feed/credit-modal/ModalApplyCredit";
import FillDataExpand from "../../../credit/credit-feed/credit-tab/TabPending/FillDataExpand";
import {
  getCalculateCredit,
  getCreditInformation,
} from "../../services/farmDetail.service";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

type Props = {
  farmId?: string;
};

const CreditInformationTab = ({ farmId }: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.farmDetail === "edit";
  const [credit, setCredit] = useState<ICreditFeedData>();
  const [calculateCredit, setCalculateCredit] = useState<ICreditFeedData>();

  const [isOpenModalUpdateCredit, setIsOpenModalUpdateCredit] = useState(false);
  const [isOpenModalCalculateCredit, setIsOpenModalCalculateCredit] =
    useState(false);

  const handleFetchCredit = async () => {
    const data = await getCreditInformation(farmId || "");
    setCredit(data?.creditInfo);
  };

  const handleFetchCalculateCredit = async () => {
    const data = await getCalculateCredit(farmId || "");
    setCalculateCredit(data?.creditInfo);
  };

  const onCloseModalUpdateCredit = useCallback(
    () => setIsOpenModalUpdateCredit(false),
    []
  );
  const onCloseModalCalculateCredit = useCallback(
    () => setIsOpenModalCalculateCredit(false),
    []
  );

  useEffect(() => {
    handleFetchCredit();
    handleFetchCalculateCredit();
  }, []);

  const {
    setIdFramers,
    formTabFarmer,
    setFormTabFarmer,
    onChangeFormTabFarmer,
    selectVersionCreditOption,
    businessTypeOption,
    typePersonalOptions,
    agePersonalOptions,
    typeLegalOptions,
    dropdownValuesGeneralFarmInformation,
    dropdownValuesFarmPerformance,
    dropdownValuesFarmFinancialStatusAndInstallment,
    dropdownValuesApplicationUsage,
    FormGeneralPersonalInformation,
    setFormGeneralPersonalInformation,
    onChangeFormGeneralPersonalInformation,
    FormGeneralLegalInformation,
    setFormGeneralLegalInformation,
    onChangeFormGeneralLegalInformation,
    FormGeneralFarmInformation,
    setFormGeneralFarmInformation,
    onChangeFormGeneralFarmInformation,
    FormFarmPerformance,
    setFormFarmPerformance,
    onChangeFormFarmPerformance,
    FormFarmFinancialStatusAndInstallment,
    setFormFarmFinancialStatusAndInstallment,
    onChangeFormFarmFinancialStatusAndInstallment,
    FormApplicationUsage,
    setFormApplicationUsage,
    onChangeFormApplicationUsage,
    refetchListFarmersCreditFeed,
    refetchListCreditFeedTabPending,
    refetchListCreditFeedTabDenied,
    refetchListCreditFeedTabProcessing,
  } = useCreditFeedController(true);

  return (
    <>
      <div className="flex text-center items-center mt-6 justify-between">
        <div className="flex gap-2">
          <CreditFarmSvg />
          <p className="text-lg font-bold text-[#3777BC] font-sans">
            {t("farmDetail.creditInformation.title")}
          </p>
        </div>
        {roleActionEdit && (
          <div className="flex gap-2">
            <ButtonOutlinedCustom
              title={"อัปเดตข้อมูล"}
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              onClick={() => setIsOpenModalUpdateCredit(true)}
            />
            <ButtonFillCustom
              title={"คำนวณเครดิต"}
              onClick={() => setIsOpenModalCalculateCredit(true)}
            />
          </div>
        )}
      </div>
      {credit && <FillDataExpand data={credit} isFarmDetail isCreditStatus />}

      <ModalApplyCredit
        isOpen={isOpenModalUpdateCredit}
        onClose={onCloseModalUpdateCredit}
        form={formTabFarmer}
        setForm={setFormTabFarmer}
        onChangeForm={onChangeFormTabFarmer}
        selectVersionCreditOption={selectVersionCreditOption}
        businessTypeOption={businessTypeOption}
        typePersonalOptions={typePersonalOptions}
        agePersonalOptions={agePersonalOptions}
        typeLegalOptions={typeLegalOptions}
        dropdownValuesGeneralFarmInformation={
          dropdownValuesGeneralFarmInformation
        }
        dropdownValuesFarmPerformance={dropdownValuesFarmPerformance}
        dropdownValuesFarmFinancialStatusAndInstallment={
          dropdownValuesFarmFinancialStatusAndInstallment
        }
        dropdownValuesApplicationUsage={dropdownValuesApplicationUsage}
        FormGeneralPersonalInformation={FormGeneralPersonalInformation}
        setFormGeneralPersonalInformation={setFormGeneralPersonalInformation}
        onChangeFormGeneralPersonalInformation={
          onChangeFormGeneralPersonalInformation
        }
        FormGeneralLegalInformation={FormGeneralLegalInformation}
        setFormGeneralLegalInformation={setFormGeneralLegalInformation}
        onChangeFormGeneralLegalInformation={
          onChangeFormGeneralLegalInformation
        }
        FormGeneralFarmInformation={FormGeneralFarmInformation}
        setFormGeneralFarmInformation={setFormGeneralFarmInformation}
        onChangeFormGeneralFarmInformation={onChangeFormGeneralFarmInformation}
        FormFarmPerformance={FormFarmPerformance}
        setFormFarmPerformance={setFormFarmPerformance}
        onChangeFormFarmPerformance={onChangeFormFarmPerformance}
        FormFarmFinancialStatusAndInstallment={
          FormFarmFinancialStatusAndInstallment
        }
        setFormFarmFinancialStatusAndInstallment={
          setFormFarmFinancialStatusAndInstallment
        }
        onChangeFormFarmFinancialStatusAndInstallment={
          onChangeFormFarmFinancialStatusAndInstallment
        }
        FormApplicationUsage={FormApplicationUsage}
        setFormApplicationUsage={setFormApplicationUsage}
        onChangeFormApplicationUsage={onChangeFormApplicationUsage}
        idFramers={farmId as string}
        setIdFramers={setIdFramers}
        refetchListFarmersCreditFeed={refetchListFarmersCreditFeed}
        refetchListCreditFeedTabPending={refetchListCreditFeedTabPending}
        refetchListCreditFeedTabDenied={refetchListCreditFeedTabDenied}
        refetchListCreditFeedTabProcessing={refetchListCreditFeedTabProcessing}
        listCreditSettingById={credit}
        isUpdateCreditFarmDetail={true}
        isReapplyOrCreateFn={false}
      />
      <ModalApplyCredit
        isOpen={isOpenModalCalculateCredit}
        onClose={onCloseModalCalculateCredit}
        form={formTabFarmer}
        setForm={setFormTabFarmer}
        onChangeForm={onChangeFormTabFarmer}
        selectVersionCreditOption={selectVersionCreditOption}
        businessTypeOption={businessTypeOption}
        typePersonalOptions={typePersonalOptions}
        agePersonalOptions={agePersonalOptions}
        typeLegalOptions={typeLegalOptions}
        dropdownValuesGeneralFarmInformation={
          dropdownValuesGeneralFarmInformation
        }
        dropdownValuesFarmPerformance={dropdownValuesFarmPerformance}
        dropdownValuesFarmFinancialStatusAndInstallment={
          dropdownValuesFarmFinancialStatusAndInstallment
        }
        dropdownValuesApplicationUsage={dropdownValuesApplicationUsage}
        FormGeneralPersonalInformation={FormGeneralPersonalInformation}
        setFormGeneralPersonalInformation={setFormGeneralPersonalInformation}
        onChangeFormGeneralPersonalInformation={
          onChangeFormGeneralPersonalInformation
        }
        FormGeneralLegalInformation={FormGeneralLegalInformation}
        setFormGeneralLegalInformation={setFormGeneralLegalInformation}
        onChangeFormGeneralLegalInformation={
          onChangeFormGeneralLegalInformation
        }
        FormGeneralFarmInformation={FormGeneralFarmInformation}
        setFormGeneralFarmInformation={setFormGeneralFarmInformation}
        onChangeFormGeneralFarmInformation={onChangeFormGeneralFarmInformation}
        FormFarmPerformance={FormFarmPerformance}
        setFormFarmPerformance={setFormFarmPerformance}
        onChangeFormFarmPerformance={onChangeFormFarmPerformance}
        FormFarmFinancialStatusAndInstallment={
          FormFarmFinancialStatusAndInstallment
        }
        setFormFarmFinancialStatusAndInstallment={
          setFormFarmFinancialStatusAndInstallment
        }
        onChangeFormFarmFinancialStatusAndInstallment={
          onChangeFormFarmFinancialStatusAndInstallment
        }
        FormApplicationUsage={FormApplicationUsage}
        setFormApplicationUsage={setFormApplicationUsage}
        onChangeFormApplicationUsage={onChangeFormApplicationUsage}
        idFramers={farmId as string}
        setIdFramers={setIdFramers}
        refetchListFarmersCreditFeed={refetchListFarmersCreditFeed}
        refetchListCreditFeedTabPending={refetchListCreditFeedTabPending}
        refetchListCreditFeedTabDenied={refetchListCreditFeedTabDenied}
        refetchListCreditFeedTabProcessing={refetchListCreditFeedTabProcessing}
        isCalculateCredit={true}
        isReapplyOrCreateFn={false}
        dataCalculateCredit={calculateCredit}
      />
    </>
  );
};

export default CreditInformationTab;
