import { yupResolver } from "@hookform/resolvers/yup";
import { memo, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { number, object, string } from "yup";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import { uploadReceiptPresignAPI } from "../../../../../../services/admin/invoice/invoiceSale.service";
import { thousandComma } from "../../../../../../utils/common/string";
import {
  handleDownloadObject,
  handleUploadFileToAzure,
} from "../../../../../../utils/downloadFile";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import DragAndDropUploadImage from "../../../../../components/Input/DragAndDropUploadImage";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { confirmInvoicePayment } from "../../services/trackingDebt.service";
import { TrackingDebtRow } from "../../types/trackingDebt.type";
import { useTranslation } from "react-i18next";

type TFullPaidModalProps = {
  data?: TrackingDebtRow;
  isOpen: boolean;
  isPayExactAmount: boolean;
  handleClose: (isSuccess: boolean) => void;
};

type TInvoiceConfirmForm = {
  invoiceId: string;
  installmentId: string;
  isExactAmount: boolean;
  receiveAmount: number | null;
  receiptNumber: string;
  invoiceReceiptId: string;
  receipt: {
    fileId: string;
    fileName: string;
  }[];
  file: File[];
};

export interface IConfirmInvoicePayment {
  invoiceId: string;
  installmentId: string;
  isExactAmount: boolean;
  receiveAmount: number;
  receiptNumber: string;
  invoiceReceiptId: string;
  receipt:
    | {
        fileId: string;
        fileName: string;
      }
    | undefined;
}

const validationSchema = object({
  receiveAmount: number().min(0.00001).required("กรุณากรอก"),
  receiptNumber: string().required("กรุณากรอก"),
});

const ConfirmPaymentModal = ({
  data,
  isOpen,
  handleClose,
  isPayExactAmount,
}: TFullPaidModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<TInvoiceConfirmForm>({
    defaultValues: {
      invoiceId: "",
      invoiceReceiptId: "",
      installmentId: "",
      isExactAmount: false,
      receiveAmount: null,
      receiptNumber: "",
      receipt: [],
    },
    resolver: yupResolver(validationSchema) as any,
  });

  const handleDropImage = (files: File[]) => {
    methods.setValue("file", files);
  };

  const handleDeleteImage = async () => {
    methods.setValue("file", []);
  };

  const handleSubmit = async (value: TInvoiceConfirmForm) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการตรวจสอบยอดเงินชำระ",
        text: "คุณต้องการยืนยันรายการนี้ใช่หรือไม่",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังยืนยัน..." }));
            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "ยืนยันการขำระเงินสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const submitAPI = async (value: TInvoiceConfirmForm) => {
    const fileReceipt = await handleUploadFile(value.file?.[0]);
    const body: IConfirmInvoicePayment = {
      invoiceId: value.invoiceId,
      installmentId: value.installmentId,
      isExactAmount: value.isExactAmount,
      receiveAmount: value.receiveAmount || 0,
      receiptNumber: value.receiptNumber,
      invoiceReceiptId: value.invoiceReceiptId,
      receipt: fileReceipt || value.receipt[0],
    };
    await confirmInvoicePayment(body);
    console.log(body);
  };

  const handleUploadFile = async (file?: File) => {
    if (file) {
      const preSign = await uploadReceiptPresignAPI("receipt");
      if (preSign?.blob?.blobUrl) {
        await handleUploadFileToAzure(preSign?.blob?.blobUrl, file);
        return { fileId: preSign?.blob.fileId, fileName: file.name };
      }
    }
  };

  useEffect(() => {
    if (isOpen && data) {
      methods.reset({
        isExactAmount: isPayExactAmount,
        invoiceId: data?.invoiceId || "",
        invoiceReceiptId: data?.invoiceReceiptId || "",
        installmentId: data?.debtInstallmentId || "",
      });
    }
  }, [isOpen]);

  return (
    <AdminModal
      label={`ยืนยันการชำระเงิน${
        isPayExactAmount ? "สำเร็จ" : "ไม่ตรงยอด"
      } Invoice เลขที่ ${data?.invoiceNumber}`}
      isOpen={isOpen}
      handleClose={handleClose}
      size="md"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <div className="w-1/2">
                <div className="flex flex-col gap-2 text-sm">
                  <p className="font-bold">
                    {t(
                      "moneyDebtAndCollectionLending.onProcess.match.paymentAmount"
                    )}
                  </p>
                  <p>
                    <span>
                      {data?.totalPrice
                        ? thousandComma(data.totalPrice) +
                          ` ${t("common.bath")}`
                        : "-"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex flex-col gap-2 text-sm">
                  <p className="font-bold">
                    {t(
                      "moneyDebtAndCollectionLending.onProcess.match.paymentAmountPerMonth"
                    )}
                  </p>
                  <p>
                    <span>{data?.debtPayPerMonth}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-4">
              <div className="w-1/2">
                <div className="flex flex-col gap-2 text-sm">
                  <p className="font-bold">
                    {t(
                      "moneyDebtAndCollectionLending.onProcess.match.totalMonthInInstallment"
                    )}
                  </p>
                  <p>
                    <span>{data?.debtNumberOfMonth}</span>
                  </p>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex flex-col gap-2 text-sm">
                  <p className="font-bold">
                    {t(
                      "moneyDebtAndCollectionLending.onProcess.match.installmentTime"
                    )}
                  </p>
                  <p>
                    <span>
                      {data?.debtNumberOfMonth
                        ? data?.debtInstallmentNumber +
                          "/" +
                          data?.debtNumberOfMonth
                        : "-"}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex items-center gap-4">
                <div className="w-1/2">
                  <label htmlFor="receiveAmount" className="text-sm">
                    {t(
                      "moneyDebtAndCollectionLending.onProcess.match.amountPaid"
                    )}
                  </label>
                  <RHFTextInputCustom
                    placeholder="กรอก"
                    controlName="receiveAmount"
                    type="number"
                    error={!!methods.formState.errors.receiveAmount}
                    helperText={methods.formState.errors.receiveAmount?.message}
                  />
                </div>
                <div className="w-1/2">
                  <label htmlFor="receiptNumber" className="text-sm">
                    {t(
                      "moneyDebtAndCollectionLending.onProcess.match.receiptNo"
                    )}
                  </label>
                  <RHFTextInputCustom
                    placeholder="กรอก"
                    controlName="receiptNumber"
                    type="text"
                    error={!!methods.formState.errors.receiptNumber}
                    helperText={methods.formState.errors.receiptNumber?.message}
                  />
                </div>
              </div>
              <div>
                <label className="font-bold text-sm">
                  {t(
                    "moneyDebtAndCollectionLending.onProcess.match.receiptPic"
                  )}
                </label>
                <ListOfFile
                  files={methods.watch("file") || []}
                  onDelete={handleDeleteImage}
                  onDownload={handleDownloadObject}
                />
                <DragAndDropUploadImage onUpload={handleDropImage} />
              </div>
            </div>
            <div className="flex justify-end">
              <ButtonFillCustom title={"บันทึก"} type="submit" />
            </div>
          </div>
        </form>
      </FormProvider>
    </AdminModal>
  );
};

export default memo(ConfirmPaymentModal);
