import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../../components/app-bar/AppBar";

import { useTranslation } from "react-i18next";
import { TBaseGetListPartnerStockList } from "../../../../types/partner/stock.type";
import OrderFeedPartnerDetail from "../../stock-component/OrderFeedPartnerDetail";

type Props = {};

const UpcomingStockDetail = (props: Props) => {
  const navigate = useNavigate();
  const { stockList } = useLoaderData() as {
    stockList: TBaseGetListPartnerStockList;
  };
  const { t } = useTranslation();
  return (
    <div className="pb-4 h-screen flex flex-col  bg-[#ffff] overflow-scroll">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title={t("OrderStock.LandingPage")}
        />
      </div>
      <OrderFeedPartnerDetail
        stockList={stockList.stockRefill}
        isTextDescription={true}
      />
    </div>
  );
};

export default UpcomingStockDetail;
