import { Divider, SvgIcon, Tab, TableCell, Tabs } from "@mui/material";
import { get } from "lodash";
import { IDataClaimOrder } from "../../../../../types/ClaimOrder.type";
import { getCommaNumber } from "../../../../../utils/number";
import { DocumentSvg } from "../../../../assets/svg/Document.svg";
import { TabPanelCustom } from "../../../../components/TabPanelCustom/TabPanelCustom";
import TableBasicInfo from "../../../../components/Table/TableBasicInfo";
import HistoryChangePanel from "../../../sale-order/components/HistoryChangePanel";
import OrderDetail from "./Tabs/OrderDetail";
import ReportDetail from "./Tabs/ReportDetail";
import { useOrderClaimDetailController } from "./controller";
import { useTranslation } from "react-i18next";

interface IOrderClaimDetail {
  data: IDataClaimOrder;
  refetchListClaimOrderUpcoming?: () => void;
  refetchListClaimOrderProgress?: () => void;
  refetchListClaimOrderHistory?: () => void;
  isFirst?: boolean;
}

const OrderClaimDetail = (props: IOrderClaimDetail) => {
  const {
    data,
    refetchListClaimOrderUpcoming,
    refetchListClaimOrderProgress,
    refetchListClaimOrderHistory,
    isFirst,
  } = props;

  const { t } = useTranslation();

  const { tabIndex, setTabIndex, onChangeTab } =
    useOrderClaimDetailController();

  return (
    <TableCell colSpan={11} sx={{ bgcolor: "#F6FBFF" }}>
      <div className="w-full h-full px-6 py-2">
        {data.orderClaimStatus === "rejected" && (
          <div className="flex flex-row pb-6 gap-6">
            <div className="font-bold text-[14px] text-[#D32F2F] w-[143px]">
              เหตุผลที่ไม่อนุมัติ
            </div>
            <div className="font-normal text-[14px] text-[#D32F2F]">
              {data.orderClaimRejectReason}
            </div>
          </div>
        )}
        <div className="flex justify-between items-center">
          <div>
            <SvgIcon sx={{ color: "#68C184", fontSize: "24px" }}>
              <DocumentSvg />
            </SvgIcon>
            <span className="font-sans font-bold text-[18px] leading-5 text-[#3777BC]">
              {t("claimOrder.contractNo")} {data.contract.contractNumber}
            </span>
          </div>
        </div>
        <div className="flex flex-row pt-3 gap-6">
          <div className="flex flex-col min-w-[488px] max-w-[488px]">
            <div className="w-full">
              <span className="font-sans font-bold text-[1rem] leading-6 text-[#68C184]">
                {t("claimOrder.customerInfo")}
              </span>
              <div className="pt-3 pr-10">
                <TableBasicInfo
                  tableData={[
                    {
                      detailName: "รหัสลูกค้า",
                      detailValue:
                        data.contract.house.farm.farmer.registrationId,
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: "ชื่อลูกค้า",
                      detailValue:
                        data.contract.house.farm.farmer.name +
                        " " +
                        data.contract.house.farm.farmer.surname,
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: "ที่อยู่",
                      detailValue:
                        data.contract.house.farm.address +
                        " " +
                        data.contract.house.farm.province.nameTh +
                        " " +
                        data.contract.house.farm.district.nameTh +
                        " " +
                        data.contract.house.farm.subDistrict.nameTh +
                        " " +
                        data.contract.house.farm.postalCode,
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                  ]}
                  titleWidth="166px"
                />
              </div>
            </div>
            <div className="w-full pt-3">
              <span className="font-sans font-bold text-[1rem] leading-6 text-[#68C184]">
                ข้อมูลพาร์ทเนอร์
              </span>
              <div className="pt-3 pr-10">
                <TableBasicInfo
                  tableData={[
                    {
                      detailName: t("claimOrder.partnerID"),
                      detailValue: data.partnerCode,
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: t("claimOrder.nameSurnameParter"),
                      detailValue: data.partnerName + " " + data.partnerSurname,
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                    {
                      detailName: "ที่อยู่",
                      detailValue:
                        data.partnerAddress.address +
                        " " +
                        data.partnerAddress.province.nameTh +
                        " " +
                        data.partnerAddress.district.nameTh +
                        " " +
                        data.partnerAddress.subDistrict.nameTh +
                        " " +
                        data.partnerAddress.subDistrict.zipCode,
                      color: "#1B2128",
                      fontSize: "14px",
                    },
                  ]}
                  titleWidth="166px"
                />
              </div>
            </div>
          </div>
          <Divider
            orientation="vertical"
            variant="fullWidth"
            flexItem
            sx={{
              borderColor: "#C9C9C9",
            }}
          />
          <div>
            <span className="font-sans font-bold text-[1rem] leading-6 text-[#68C184]">
              {t("claimOrder.contractInfo")}
            </span>
            <div className="pt-3">
              <TableBasicInfo
                tableData={[
                  {
                    detailName: t("claimOrder.creditLimit"),
                    detailValue:
                      getCommaNumber(+get(data, ["contract", "credit"]), true) +
                      " บาท",
                    color: "#1B2128",
                    fontSize: "14px",
                  },
                  {
                    detailName: t("claimOrder.overdueAmount"),
                    detailValue:
                      getCommaNumber(+get(data, ["contract", "debt"]), true) +
                      " บาท",
                    color: "#1B2128",
                    fontSize: "14px",
                  },
                  {
                    detailName: t("claimOrder.amountExceedingTheLimit"),
                    detailValue:
                      getCommaNumber(
                        get(data, ["contract", "exceedAmount"], 0),
                        true
                      ) + " บาท",
                    color: "#1B2128",
                    fontSize: "14px",
                  },
                  {
                    detailName: t("claimOrder.newOrderAmount"),
                    detailValue:
                      getCommaNumber(
                        +get(data, ["contract", "newOrderAmount"]),
                        true
                      ) + " บาท",
                    color: "#1B2128",
                    fontSize: "14px",
                  },
                  {
                    detailName: t("claimOrder.amountSpend"),
                    detailValue:
                      getCommaNumber(
                        Math.min(
                          +get(data, ["contract", "creditUsed"]),
                          +get(data, ["contract", "credit"])
                        ),
                        true
                      ) + " บาท",
                    color: "#1B2128",
                    fontSize: "14px",
                  },
                  {
                    detailName: "วงเงินเครดิตคงเหลือ",
                    detailValue:
                      getCommaNumber(
                        (Math.max(
                          0,
                          +get(data, ["contract", "credit"]) -
                            +get(data, ["contract", "creditUsed"])
                        ) /
                          +get(data, ["contract", "credit"])) *
                          100,
                        true
                      ) + " %",
                    color: "#1B2128",
                    fontSize: "14px",
                  },
                  {
                    detailName: t("claimOrder.creditTerm"),
                    detailValue:
                      getCommaNumber(+get(data, ["contract", "creditTerm"])) +
                      " วัน",
                    color: "#1B2128",
                    fontSize: "14px",
                  },
                  {
                    detailName: t("claimOrder.overdueDays"),
                    detailValue:
                      getCommaNumber(
                        +get(data, ["contract", "dayOfDebtOutStanding"])
                      ) + " วัน",
                    color: "#1B2128",
                    fontSize: "14px",
                  },
                  {
                    detailName: t("claimOrder.grading"),
                    detailValue: data.contract.creditRating.toLocaleUpperCase(),
                    color: "#1B2128",
                    fontSize: "14px",
                  },
                ]}
                titleWidth="155px"
              />
            </div>
          </div>
        </div>
        <div className="pt-6 h-full w-full">
          <div className="p-6 bg-white rounded-[20px]">
            <Tabs
              value={tabIndex}
              onChange={onChangeTab}
              aria-label={`order-tab-control-${tabIndex}`}
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "#68C184",
                    height: "3px",
                  },
                },
              }}
              sx={{
                height: "36px",
                minHeight: "unset",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#000000",
                      borderBottom: "3px solid #DDF3E4",
                      "& .Mui-selected": {
                        color: "#000000",
                      },
                    },
                  },
                },
              }}
            >
              <Tab label={`รายละเอียดรายงานปัญหา`} />
              <Tab label={`รายละเอียดคำสั่งซื้อ`} />
              <Tab label={`ประวัติการเเก้ไข`} />
            </Tabs>
            <TabPanelCustom
              value={tabIndex}
              index={0}
              prefix={`order-item-${tabIndex}`}
            >
              <ReportDetail
                data={data}
                refetchListClaimOrderUpcoming={refetchListClaimOrderUpcoming}
                refetchListClaimOrderProgress={refetchListClaimOrderProgress}
                refetchListClaimOrderHistory={refetchListClaimOrderHistory}
                isFirst={isFirst}
              />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={1}
              prefix={`order-item-${tabIndex}`}
            >
              {data.orderFeedItems.length > 0 ? (
                <OrderDetail data={data} />
              ) : (
                <div className="pt-6">
                  <div className="font-bold text-[14px] text-[#1B2128]">
                    ยังไม่มีรายละเอียดคำสั่งซื้อ
                  </div>
                </div>
              )}
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={2}
              prefix={`order-item-${tabIndex}`}
            >
              {data.orderFeedLogs.length > 0 ? (
                <HistoryChangePanel orderFeedLogs={data.orderFeedLogs} />
              ) : (
                <div className="pt-6">
                  <div className="font-bold text-[14px] text-[#1B2128]">
                    ยังไม่มีประวัติการแก้ไข
                  </div>
                </div>
              )}
            </TabPanelCustom>
          </div>
        </div>
      </div>
    </TableCell>
  );
};

export default OrderClaimDetail;
