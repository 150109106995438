import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Divider } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ButtonOutlinedCustom from "../../../admin/components/Button/ButtonOutlinedCustom";
import {
  Dropdown,
  TDropdownValue,
} from "../../../admin/pages/group-area/views/Dropdown";
import DocumentSize20pxSvg from "../../../assets/svg/DocumentSize20pxSvg";
import DollarWithCalculatorSvg from "../../../assets/svg/DollarWithCalculatorSvg";
import DollarWithCalculatorSvgBlue from "../../../assets/svg/DollarWithCalculatorSvgBlue";
import DollarWithCoinSvg from "../../../assets/svg/DollarWithCoinSvg";
import DollarWithCoinSvgBlue from "../../../assets/svg/DollarWithCoinSvgBlue";
import PencilAltSize20pxSvg from "../../../assets/svg/PencilAltSize20pxSvg";
import AppBarFS from "../../../components/app-bar/AppBar";
import { getCommaNumber } from "../../../utils/number";
import { tw } from "../../../utils/tw";
import { useBasicAccountingController } from "./controller";

const BasicAccounting = () => {
  const { farmId } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { form, onChangeForm, summaryAllMonths, summaryCurrentMonth } =
    useBasicAccountingController(farmId || "");

  const monthNamesThai = [
    t("BasicAccounting.January"),
    t("BasicAccounting.February"),
    t("BasicAccounting.March"),
    t("BasicAccounting.April"),
    t("BasicAccounting.May"),
    t("BasicAccounting.June"),
    t("BasicAccounting.July"),
    t("BasicAccounting.August"),
    t("BasicAccounting.September"),
    t("BasicAccounting.October"),
    t("BasicAccounting.November"),
    t("BasicAccounting.December"),
  ];

  const [selectMonth, setSelectMonth] = useState<TDropdownValue[]>([
    {
      label: monthNamesThai[dayjs().month()],
      value: (dayjs().month() + 1).toString(),
    },
  ]);

  useEffect(() => {
    if (selectMonth) {
      onChangeForm("month")(selectMonth);
    }
  }, [selectMonth]);

  return (
    <div
      style={{
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#68C184",
      }}
      className="!h-auto !min-h-screen flex flex-col"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          title={t("BasicAccounting.BasicAccounting")}
          onIconPress={() => navigate(-1)}
        />
      </div>
      <div className="flex flex-col gap-6 pt-6 pb-4 px-4 font-sans">
        <div className="text-[#FFFF] font-semibold text-[20px] py-2">
          เดือนมกราคม - ปัจจุบัน ปี {dayjs().year() + 543}
        </div>
        <div className="flex flex-col gap-3">
          <div
            className="bg-[#F0FDF4] p-4 gap-3 rounded-[20px]"
            style={{
              boxShadow: "4px 4px 20px 0px #0000001A",
            }}
          >
            <div className="flex flex-col gap-3 font-sans justify-center items-center">
              <div className="flex flex-row">
                <div
                  className={tw(
                    "text-[30px] font-medium ",

                    (summaryAllMonths?.totalIncome || 0) -
                      (summaryAllMonths?.totalExpense || 0) >=
                      0
                      ? "text-[#68C184]"
                      : "text-error-300"
                  )}
                >
                  {(summaryAllMonths?.totalIncome || 0) -
                    (summaryAllMonths?.totalExpense || 0) >=
                  0
                    ? "+"
                    : "-"}
                  {getCommaNumber(
                    (summaryAllMonths?.totalIncome || 0) -
                      (summaryAllMonths?.totalExpense || 0)
                  )}
                </div>
                <div className="flex flex-col justify-center items-center">
                  <ArrowDropUpIcon
                    className="text-[#68C184] -mt-[10px]"
                    fontSize="large"
                  />
                  <div className="text-[#34407B] text-[12px] font-semibold -mt-[15px]">
                    {t("reportOrder.currency")}
                  </div>
                </div>
              </div>
              <div className="text-[#646464] font-medium text-[12px]">
                {t("BasicAccounting.TotalP&L")}
              </div>
            </div>
          </div>
          <div className="flex flex-row py-4 px-3 justify-evenly">
            <div className="flex flex-col gap-[6px] px-4">
              <DollarWithCalculatorSvg />
              <div className="flex flex-col gap-[6px]">
                <div className="flex flex-row">
                  <div className="text-[18px] font-medium text-[#FFFFFF]">
                    {getCommaNumber(summaryAllMonths?.totalIncome || 0)}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <ArrowDropUpIcon
                      className="text-[#3777BC] -mt-[10px]"
                      fontSize="large"
                    />
                    <div className="text-[#FFFFFF] text-[12px] font-semibold -mt-[15px]">
                      {t("reportOrder.currency")}
                    </div>
                  </div>
                </div>
                <div className="text-[12px] text-[#FFFFFF] font-normal whitespace-pre">
                  {t("BasicAccounting.TotalFarmIncome")}
                </div>
              </div>
            </div>
            <Divider
              orientation="vertical"
              variant="fullWidth"
              flexItem
              sx={{
                borderColor: "#FFFFFF",
              }}
            />
            <div className="flex flex-col gap-[6px] px-4">
              <DollarWithCoinSvg />
              <div className="flex flex-col gap-[6px]">
                <div className="flex flex-row">
                  <div className="text-[18px] font-medium text-[#FFFFFF]">
                    {getCommaNumber(summaryAllMonths?.totalExpense || 0)}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <ArrowDropUpIcon
                      className="text-[#3777BC] -mt-[10px]"
                      fontSize="large"
                    />
                    <div className="text-[#FFFFFF] text-[12px] font-semibold -mt-[15px]">
                      {t("reportOrder.currency")}
                    </div>
                  </div>
                </div>
                <div className="text-[12px] text-[#FFFFFF] font-normal whitespace-pre">
                  {t("BasicAccounting.TotalExpenses")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#FFFF] px-4 py-6 rounded-t-[40px] flex flex-col gap-4 h-full flex-1">
        <div className="flex flex-row items-center justify-between">
          <div className="text-[#68C184] text-[16px] font-semibold">
            {t("BasicAccounting.year")} {dayjs().year() + 543}
          </div>
          <div>
            <Dropdown
              options={[
                { label: t("BasicAccounting.January"), value: "1" },
                { label: t("BasicAccounting.February"), value: "2" },
                { label: t("BasicAccounting.March"), value: "3" },
                { label: t("BasicAccounting.April"), value: "4" },
                { label: t("BasicAccounting.May"), value: "5" },
                { label: t("BasicAccounting.June"), value: "6" },
                { label: t("BasicAccounting.July"), value: "7" },
                { label: t("BasicAccounting.August"), value: "8" },
                { label: t("BasicAccounting.September"), value: "9" },
                { label: t("BasicAccounting.October"), value: "10" },
                { label: t("BasicAccounting.November"), value: "11" },
                { label: t("BasicAccounting.December"), value: "12" },
              ]}
              placeholder={t("BasicAccounting.month")}
              onChange={(value) => setSelectMonth(value)}
              renderValueArray={selectMonth}
              choseStyle2
              containerDropdown="pb-0 text-center"
            />
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-[6px] font-sans justify-center items-center">
            <div className="flex flex-row">
              <div
                className={tw(
                  "text-[30px] font-medium ",

                  (summaryCurrentMonth?.totalIncome || 0) -
                    (summaryCurrentMonth?.totalExpense || 0) >=
                    0
                    ? "text-[#68C184]"
                    : "text-error-300"
                )}
              >
                {(summaryCurrentMonth?.totalIncome || 0) -
                  (summaryCurrentMonth?.totalExpense || 0) >=
                0
                  ? "+"
                  : "-"}
                {getCommaNumber(
                  (summaryCurrentMonth?.totalIncome || 0) -
                    (summaryCurrentMonth?.totalExpense || 0)
                )}
              </div>
              <div className="flex flex-col justify-center items-center">
                <ArrowDropUpIcon
                  className="text-[#68C184] -mt-[10px]"
                  fontSize="large"
                />
                <div className="text-[#34407B] text-[12px] font-semibold -mt-[15px]">
                  {t("reportOrder.currency")}
                </div>
              </div>
            </div>
            <div className="text-[#646464] font-medium text-[12px]">
              {t("BasicAccounting.TotalP&L")}
            </div>
          </div>
          <div className="flex flex-row py-4 px-3 justify-evenly border-[#97BFEA] border-[1px] rounded-[20px] min-w-max">
            <div className="flex flex-col gap-[6px] px-4">
              <DollarWithCalculatorSvgBlue />
              <div className="flex flex-col gap-[6px]">
                <div className="flex flex-row">
                  <div className="text-[18px] font-medium text-[#34407B]">
                    {getCommaNumber(summaryCurrentMonth?.totalIncome || 0)}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <ArrowDropUpIcon
                      className="text-[#68C184] -mt-[10px]"
                      fontSize="large"
                    />
                    <div className="text-[#191919] text-[12px] font-semibold -mt-[15px]">
                      {t("reportOrder.currency")}
                    </div>
                  </div>
                </div>
                <div className="text-[12px] text-[#646464] font-normal whitespace-pre">
                  {t("BasicAccounting.TotalFarmIncome")}
                </div>
              </div>
            </div>
            <Divider
              orientation="vertical"
              variant="fullWidth"
              flexItem
              sx={{
                borderColor: "#97BFEA",
              }}
            />
            <div className="flex flex-col gap-[6px] px-4">
              <DollarWithCoinSvgBlue />
              <div className="flex flex-col gap-[6px]">
                <div className="flex flex-row">
                  <div className="text-[18px] font-medium text-[#34407B]">
                    {getCommaNumber(summaryCurrentMonth?.totalExpense || 0)}
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <ArrowDropUpIcon
                      className="text-[#68C184] -mt-[10px]"
                      fontSize="large"
                    />
                    <div className="text-[#191919] text-[12px] font-semibold -mt-[15px]">
                      {t("reportOrder.currency")}
                    </div>
                  </div>
                </div>
                <div className="text-[12px] text-[#646464] font-normal whitespace-pre">
                  {t("BasicAccounting.TotalExpenses")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center gap-[10px] px-6">
          <ButtonOutlinedCustom
            title={t("BasicAccounting.FillInTheInformation")}
            className="!h-[30px] !px-[14px] !py-[5px] !rounded-[50px]"
            btnBorderColor="#68C184"
            startIcon={<PencilAltSize20pxSvg />}
            onClick={() =>
              navigate(`/farm/${farmId}/basic-accounting/save-accounting`, {
                state: form,
              })
            }
          />
          <ButtonOutlinedCustom
            title={t("BasicAccounting.SeeAll")}
            className="!h-[30px] !px-[14px] !py-[5px] !rounded-[50px]"
            btnBorderColor="#68C184"
            startIcon={<DocumentSize20pxSvg />}
            onClick={() =>
              navigate(`/farm/${farmId}/basic-accounting/see-details`)
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BasicAccounting;
