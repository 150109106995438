import { IGetGroupArea, IGetYear } from "../../types/Utils.type";
import {
  ISalePerformanceGraph,
  ISalePerformanceTable,
} from "../../types/dashboard/SalePerformance.type";
import adminAPI from "../AdminApiService";

export const getSalePerformanceYear = async () => {
  const { data } = await adminAPI.get<IGetYear>(
    "/dashboard/performance/sale/dropdown-year"
  );
  return data.rows;
};

export const getSalePerformanceGroupArea = async () => {
  const { data } = await adminAPI.get<IGetGroupArea>(
    "/dashboard/performance/dropdown/group-area"
  );
  return data.rows;
};

export const getSaleVolumeGraph = async (params: {
  year: string;
  groupAreaIds?: string;
  segments?: string;
}) => {
  try {
    const { data } = await adminAPI.get<ISalePerformanceGraph>(
      "/dashboard/performance/sale/volume/graph",
      {
        params: {
          year: params.year,
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
        },
      }
    );
    return data.data;
  } catch (error) {
    return null;
  }
};

export const getRevenueGraph = async (params: {
  year: string;
  groupAreaIds?: string;
  segments?: string;
}) => {
  try {
    const { data } = await adminAPI.get<ISalePerformanceGraph>(
      "/dashboard/performance/sale/revenue/graph",
      {
        params: {
          year: params.year,
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
        },
      }
    );
    return data.data;
  } catch (error) {
    return null;
  }
};

export const getListSaleVolume = async (params: {
  year: string;
  month?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<ISalePerformanceTable>(
      "/dashboard/performance/sale/volume",
      {
        params: {
          year: params.year,
          months: params?.month,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListFeedCustomer", error);
    return null;
  }
};

export const getSaleVolumeExport = async (params: {
  year: string;
  month?: string;
  contractType?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<ISalePerformanceTable>(
      "/dashboard/performance/sale/volume",
      {
        params: {
          year: params.year,
          month: params?.month,
          contractType: params?.contractType,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListFeedCustomer", error);
    return null;
  }
};
export const getListSaleRevenue = async (params: {
  year: string;
  month?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<ISalePerformanceTable>(
      "/dashboard/performance/sale/revenue",
      {
        params: {
          year: params.year,
          months: params?.month,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListFeedCustomer", error);
    return null;
  }
};

export const getSaleRevenueExport = async (params: {
  year: string;
  month?: string;
  contractType?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<ISalePerformanceTable>(
      "/dashboard/performance/sale/revenue",
      {
        params: {
          year: params.year,
          months: params?.month,
          contractType: params?.contractType,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListFeedCustomer", error);
    return null;
  }
};
