import { Tab, TableCell, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TabPanelCustom } from "../../../../../components/TabPanelCustom/TabPanelCustom";
import { AcceptDebtRow } from "../../types/acceptDebt.type";
import ExpandListLog from "./ExpandListLog";
import ExpandListTable from "./ExpandListTable";
import { NotAcceptDebtRow } from "../../types/notAcceptDebt.type";
import { useTranslation } from "react-i18next";

type Props = {
  colSpan: number;
  dataExpand: AcceptDebtRow | undefined;
  isStatusFullAmount?: boolean;
};

const ExpandTableMoneyDebt = ({
  colSpan,
  dataExpand,
  isStatusFullAmount,
}: Props) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };

  return (
    <>
      <TableCell colSpan={colSpan} sx={{ bgcolor: "#F6FBFF" }}>
        <div className="w-full h-full py-2">
          <div className="px-[40px] py-[24px] bg-[#FFFFFF] rounded-[20px]">
            <div className="mt-6">
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label="farm-information-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
                sx={{
                  height: "36px",
                  minHeight: "unset",
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-flexContainer": {
                      alignItems: "end",
                      height: "100%",
                      display: "flex",
                      gap: "12px",
                      "& .MuiTab-root": {
                        padding: "6px 28px",
                        textTransform: "unset",
                        fontFamily: "IBM Plex Sans Thai",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "unset",
                        color: "#000000",
                        borderBottom: "3px solid #DDF3E4",
                        "& .Mui-selected": {
                          color: "#000000",
                        },
                      },
                    },
                  },
                }}
              >
                <Tab
                  label={t(
                    "moneyDeptAndCollection.acceptDeptStatus.onProcess.activityDetail"
                  )}
                />
                {!isStatusFullAmount && <Tab label={"รายการ"} />}
              </Tabs>
            </div>
            <TabPanelCustom
              value={tabIndex}
              index={0}
              prefix="personal-information"
            >
              <ExpandListLog dataExpandLog={dataExpand} />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={1}
              prefix="farm-information"
            >
              <ExpandListTable dataExpandTable={dataExpand} />
            </TabPanelCustom>
          </div>
        </div>
      </TableCell>
    </>
  );
};

export default ExpandTableMoneyDebt;
