import api from "../../services/ApiService";
import { getDownloadUrlResponse } from "../../services/admin/invoice/type";
import {
  IGetArticleFarmer,
  IGetGraphPerMonths,
  IGetGraphPerYears,
  IGetPigPrice,
  IGetUserManualFarmer,
} from "./type";

export const sequenceArticleFarmer = async (
  tabArticleType: string,
  role: string
) => {
  try {
    const { data } = await api.get<IGetArticleFarmer>(
      `/${role}/farm-community/article/${tabArticleType}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const sequenceSwiperArticleFarmer = async (
  tabArticleType: string,
  role: string
) => {
  try {
    const { data } = await api.get<IGetArticleFarmer>(
      `/${role}/farm-community/article/sequence/${tabArticleType}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getSequencePigPrice = async (role: string) => {
  try {
    const { data } = await api.get<IGetPigPrice>(
      `/${role}/farm-community/pig-price/sequence`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getPigPrice = async (role: string) => {
  try {
    const { data } = await api.get<IGetPigPrice>(
      `/${role}/farm-community/pig-price`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getArticleDownloadUrlAPI = async (
  fileId: string,
  role: string
) => {
  try {
    const response = await api.get<getDownloadUrlResponse>(
      `/${role}/farm-community/download/${fileId}`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    return null;
  }
};

export const getPigPriceDownloadUrlAPI = async (
  fileId: string,
  role: string
) => {
  try {
    const response = await api.get<getDownloadUrlResponse>(
      `/${role}/farm-community/pig-price/download/${fileId}`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    return null;
  }
};

export const getGraphPerYears = async (
  role: string,
  searchParam?: URLSearchParams
) => {
  try {
    const { data } = await api.get<IGetGraphPerYears>(
      `/${role}/farm-community/pig-price/summary/annual?${searchParam}`
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const getGraphPerMonth = async (
  role: string,
  searchParam?: URLSearchParams
) => {
  try {
    const { data } = await api.get<IGetGraphPerMonths>(
      `/${role}/farm-community/pig-price/summary/monthly?${searchParam}`
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const getUserManual = async (role: string, articleType: string) => {
  try {
    const { data } = await api.get<IGetUserManualFarmer>(
      `/${role}/farm-community/manual/${articleType}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getManualDownloadUrlAPI = async (fileId: string, role: string) => {
  try {
    const response = await api.get<getDownloadUrlResponse>(
      `/${role}/farm-community/manual/download/${fileId}`
    );
    if (response) {
      return response.data;
    }
  } catch (error) {
    return null;
  }
};
