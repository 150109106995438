import { Button, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseModal } from "../../../pages/feed-order-and-delivery/order-feed/OrderFeedBaseModal";
import { farmList } from "../../../services/AuthService";
import SelectCustom from "../Input/SelectCustom";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type SelectFarm = {
  label: string;
  value: string;
};

const SelectFarmModal = ({ isOpen, onClose }: Props) => {
  const navigate = useNavigate();
  const [farms, setFarms] = useState<SelectFarm[]>([]);
  const [farmId, setFarmId] = useState();

  const handleGetFarm = async () => {
    const res = await farmList();

    if (res.data) {
      const resMapped = res.data.farms.map((farm: any) => ({
        label: farm.name,
        value: farm.id,
      }));
      setFarms(resMapped);
    }
  };

  useEffect(() => {
    handleGetFarm();
  }, []);

  return (
    <BaseModal isOpen={isOpen} handleClose={onClose} iconCloseModal={true}>
      <div className="flex  flex-col text-center">
        <p className="text-[24px] font-medium items-center text-tertiary mb-4">
          ฟาร์มที่จะสั่งอาหาร
        </p>
        <SelectCustom
          displayEmpty
          label="เลือกฟาร์ม"
          fullWidth
          variant="outlined"
          shrink={true}
          menuItems={(farms || []).map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
          onChange={(e) => setFarmId(e.target.value as any)}
          renderValue={(value) => (
            <div>{farms.find((farm) => farm.value === value)?.label}</div>
          )}
        />
        <Button
          size="large"
          variant="contained"
          onClick={() => navigate("/feed-order-and-delivery/" + farmId)}
          sx={{
            marginTop: "12px",
            color: "#ffff",
            bgcolor: "#68C184",
            width: "100%",
            ":hover": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
            ":active": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
          }}
        >
          ยืนยัน
        </Button>
      </div>
    </BaseModal>
  );
};

export default SelectFarmModal;
