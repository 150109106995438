import { Divider, Link, TableCell, TableRow } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuBar from "../../../components/MenuBar/MenuBar";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../components/Table/TableCustom";
import { SaleTargetSaleLeadSearch } from "../components/SaleTargetSaleLeadSearch";
import { useSaleTargetManagementTableHook } from "../hooks/useSaleTargetManagementTable.hook";
import { useSaleTargetSaleLead } from "../hooks/useSaleTargetSaleLead.hook";
import { ISaleTargetSaleLeadTable } from "../types/SaleTargetTable.type";

type Props = {};

const headCells: ITableCustomHeadCell<keyof ISaleTargetSaleLeadTable>[] = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "รหัสพนักงาน",
    width: "150px",
    isSorting: true,
  },
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    label: "ชื่อผู้ใช้",
    width: "150px",
    isSorting: false,
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "ชื่อพนักงาน",
    width: "200px",
    isSorting: false,
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "อีเมล",
    width: "200px",
    isSorting: false,
  },
  {
    id: "groupName",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "200px",
    isSorting: false,
  },
];

const SaleTargetManagement = (props: Props) => {
  const navigate = useNavigate();
  const searchSaleTarget = useSaleTargetSaleLead();
  const { handleFetch, list } =
    useSaleTargetManagementTableHook(searchSaleTarget);

  useEffect(() => {
    handleFetch();
  }, [
    searchSaleTarget.page,
    searchSaleTarget.order,
    searchSaleTarget.limit,
    searchSaleTarget.direction,
  ]);

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <span className="text-[#000] font-bold text-3xl">Sale Target</span>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="mt-6">
              <SaleTargetSaleLeadSearch
                searchSaleTarget={searchSaleTarget}
                onSearch={() => {}}
                hideSelect
              />
            </div>
            <div className="flex w-full pt-6">
              <TableCustom
                order={searchSaleTarget.direction}
                orderBy={searchSaleTarget.order}
                rowsPerPage={searchSaleTarget.page}
                itemsSelected={[]}
                page={searchSaleTarget.page}
                headerItems={headCells}
                onSelectAllClick={() => {}}
                rows={list}
                currentPageItems={list.map((row) => row.id)}
                onChangePage={(val) => searchSaleTarget.setPage(val)}
                onSelectItem={() => {}}
                totalItemSize={searchSaleTarget.count}
                onChangeOrder={(val) => searchSaleTarget.setOrder(val)}
                onChangeOrderBy={(val) =>
                  searchSaleTarget.setOrder(
                    val as keyof ISaleTargetSaleLeadTable
                  )
                }
                hideSelect
                rowChildren={
                  <>
                    {list.map((row, index) => {
                      return (
                        <TableRow hover key={row.id}>
                          <TableCell
                            scope="row"
                            padding="none"
                            sx={{
                              padding: "0px 16px",
                              fontFamily: "IBM Plex Sans Thai",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "19px",
                            }}
                          >
                            {row.employeeCode}
                          </TableCell>
                          <TableCell
                            scope="row"
                            padding="none"
                            sx={{
                              padding: "0px 16px",
                              fontFamily: "IBM Plex Sans Thai",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "19px",
                            }}
                          >
                            {row.username}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontFamily: "IBM Plex Sans Thai",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "19px",
                            }}
                          >
                            {row.fullName}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontFamily: "IBM Plex Sans Thai",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "19px",
                            }}
                          >
                            {row.email}
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontFamily: "IBM Plex Sans Thai",
                              fontSize: "14px",
                              fontWeight: 400,
                              lineHeight: "19px",
                            }}
                          >
                            <Link
                              component="button"
                              variant="body2"
                              sx={{
                                width: "80px",
                                color: "#68C184",
                                textDecorationColor: "#68C184",
                              }}
                              onClick={() => {
                                navigate(
                                  "/admin/master-data/management/sale-target/" +
                                    row.id,
                                  { state: row }
                                );
                              }}
                            >
                              ดูรายละเอียด
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </>
                }
              ></TableCustom>
            </div>
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default SaleTargetManagement;
