import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from "react";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { tw } from "../../../../../../utils/tw";
import CrossIcon from "../../../../../assets/svg/CrossIcon.svg";
import { TCreditLogDetails } from "../../../../../../types/CreditFeed.type";
import { isNull } from "lodash";
import { useTranslation } from "react-i18next";

const classes = {
  containerModal: tw(
    `w-[100vw] !max-w-[612px] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary rounded-[12px]`
  ),
  container: tw(`flex flex-col gap-6 font-sans`),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold text-[#191919]`),
  rowContainer: tw(`grid grid-cols-4 gap-3`),
  text: tw(`font-sans font-bold text-[16px]`),
};

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  dataLog: TCreditLogDetails;
}

const ViewDetailsLogModal = (props: IProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose, dataLog } = props;

  const onCloseFn = useCallback(() => {
    onClose();
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.containerModal}
      classes={{ paper: "w-[100vw] !max-w-[612px]" }}
    >
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <p className={classes.title}>
              {t("creditFeed.waitingToApprovalTab.details")}
            </p>
            <IconButton onClick={onCloseFn}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-3">
              <div className="text-[#7E7E7E] text-[14px] font-medium">
                {t("creditFeed.waitingToApprovalTab.details")}
              </div>
              <div className="text-[#191919] text-[14px] font-bold">
                {dataLog.logDetail.detail || "-"}
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="text-[#7E7E7E] text-[14px] font-medium">
                {dataLog.logDetail.isApprove === true
                  ? `อนุมัติโดย`
                  : `ไม่อนุมัติโดย`}
              </div>
              <div className="text-[#191919] text-[14px] font-bold">
                {dataLog.logType === "farmer_approve_offer" ||
                dataLog.logType === "farmer_deny_offer"
                  ? !isNull(dataLog.farmer.name)
                    ? dataLog.farmer.name
                    : "" + " " + !isNull(dataLog.farmer.surname)
                    ? dataLog.user.surname
                    : ""
                  : !isNull(dataLog.user.name)
                  ? dataLog.user.name
                  : "" + " " + !isNull(dataLog.user.surname)
                  ? dataLog.user.surname
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(ViewDetailsLogModal);
