import {
  Dialog,
  DialogContent,
  Slider,
  styled,
  TableCell,
  tableCellClasses,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import i18next from "i18next";
import { get, isEmpty } from "lodash";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import scrollImg from "../../../../../assets/images/feed/bnt-scroll.png";
import PigFeedSvg from "../../../../../assets/svg/PigFeedSvg";
import AppBarFS from "../../../../../components/app-bar/AppBar";
import { IStockDetailHistory } from "../../../../../types/partner/stock.type";
import { getCommaNumber } from "../../../../../utils/number";
import { tw } from "../../../../../utils/tw";
import TableCustomMobile from "../../../../financial-support/financial-billPayment/unpaid-bill/views/TableCustomMobile";

interface IFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  brandName: string;
  skuName: string;
  data: IStockDetailHistory[];
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] [&_.MuiDialog-paperScrollPaper]:max-h-screen [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 `
  ),
};

const classesTable = {
  container: tw(`min-h-[240px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const unitMap = {
  bag: "ถุง",
  kg: "กิโลกรัม",
  "": "",
};

interface ITable {
  swapCellColor?: boolean;
  data: IStockDetailHistory[];
}

const Table = (props: ITable) => {
  const { swapCellColor, data } = props;
  const [scrollValue, setScrollValue] = useState(0);
  const { t } = useTranslation();

  const headerItems = [
    {
      id: "Date",
      numeric: false,
      disablePadding: false,
      label: t("OrderStock.Date"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "orderNumber",
      numeric: false,
      disablePadding: false,
      label: t("feedOrderAndDelivery.myOrder.card.orderNumber"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "deliveryNumber",
      numeric: false,
      disablePadding: false,
      label: t("OrderStock.OrderStockNo"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "inStock",
      numeric: false,
      disablePadding: false,
      label: t("OrderStock.FeedIn"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "outStock",
      numeric: false,
      disablePadding: false,
      label: t("OrderStock.FeedOut"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
  ];

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#4B4B4B",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    },
    "&:nth-of-type(even)": {
      backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    },
  }));

  const scrollGridRef = useRef(null);
  const handleScroll = (e: any) => {
    const target: any = scrollGridRef.current;
    target.scrollLeft =
      (target.scrollWidth / 100) * e.target.value - (target.clientWidth ?? 200);
    setScrollValue(e.target.value);
  };

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      return (
        <StyledTableRow key={index}>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {/* {dayjs(get(row, ["activityDate"], ""))
              .locale(i18next.language)
              .format(i18next.language === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY")} */}
            {dayjs(get(row, ["activityDate"], ""))
              .locale(i18next.language)
              .format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {!isEmpty(get(row, ["orderNumber"], []))
              ? get(row, ["orderNumber"], ["-"]).join("\n")
              : "-"}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {!isEmpty(get(row, ["stockNumber"], []))
              ? get(row, ["stockNumber"], ["-"]).join("\n")
              : "-"}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {get(row, ["depositAmount"]) > 0
              ? getCommaNumber(get(row, ["depositAmount"], 0))
              : "-"}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {get(row, ["withdrawAmount"]) > 0
              ? getCommaNumber(get(row, ["withdrawAmount"], 0))
              : "-"}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }, [data]);

  return (
    <div className={classesTable.container}>
      <TableCustomMobile
        orderBy={""}
        order={"desc"}
        onChangeOrder={() => {}}
        onChangeOrderBy={() => {}}
        totalItemSize={data.length}
        headerItems={headerItems}
        page={0}
        onChangePage={() => {}}
        rowsPerPage={data.length}
        rows={data}
        EmptyComponent={
          data?.length === 0 ? (
            <div className={classesTable.emptyTable}>
              {t("manageStock.noData")}
            </div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={[]}
        itemsSelected={[]}
        onSelectAllClick={() => {}}
        onSelectItem={() => {}}
        hideSelect
        hidePaginator
        _ref={scrollGridRef}
        isScrollBar
      />
      <div className="flex flex-row justify-center w-[100%] p-2">
        <Slider
          sx={{
            width: "90%",
            "& .MuiSlider-thumb": {
              borderRadius: "10px",
              backgroundImage: `url(${scrollImg})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              color: "unset !important",
              backgroundColor: "transparent",
              width: 40,
            },
            "& .MuiSlider-rail": {
              color: "#E2E2E2 !important",
            },
            "& .MuiSlider-track": {
              backgroundColor: "grey !important",
              border: "1px solid grey !important",
            },
            "& .MuiSlider-valueLabelLabel": {
              fontSize: "20px",
              fontWeight: "bold",
              color: "unset",
              background: "unset",
            },
            cursor: "pointer",
          }}
          defaultValue={0}
          value={scrollValue}
          onChange={handleScroll}
          aria-label="Disabled slider"
        />
      </div>
    </div>
  );
};

const ModalSeeDetails = (props: IFilterModalProps) => {
  const { isOpen, onClose, brandName, skuName, data } = props;

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full h-full" }}
    >
      <DialogContent className="!p-0">
        <div className="h-[120px] flex items-center bg-[#FFFFFF]">
          <AppBarFS title={"รายละเอียดเพิ่มเติม"} onIconPress={onClose} />
        </div>
        <div className="px-4 flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row justify-between font-sans">
              <div className="flex flex-row gap-[6px]">
                <PigFeedSvg />
                <div className="text-[#34407B] text-[20px] font-semibold">
                  {brandName}
                </div>
              </div>
              <div className="text-[#34407B] text-[18px] font-bold">
                {skuName}
              </div>
            </div>
          </div>
          <Table data={data} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSeeDetails;
