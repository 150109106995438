import { covertToBlobUrl, handleDownloadBlob } from "../utils/downloadFile";
import adminAPI from "./AdminApiService";
import { getDownloadUrlResponse } from "./admin/invoice/type";

export const commonDownloadFile = async (
  url: string,
  fileOriginalName: string,
  queryParams?: Record<string, string>
) => {
  const response = await commonGetDownloadUrlAPI(url, queryParams);
  if (response) {
    const objectURL = await covertToBlobUrl(response.blob.blobUrl);
    handleDownloadBlob(objectURL, fileOriginalName);
  }
};

export const commonGetDownloadUrlAPI = async (
  url: string,
  queryParams?: Record<string, string>
) => {
  try {
    const response = await adminAPI.get<getDownloadUrlResponse>(url, {
      params: queryParams,
    });
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.log("commonGetDownloadUrlAPI: " + url, error);
  }
};
