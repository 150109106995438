import Delete from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import { editFeed, removeFeed } from "../../../../services/FeedFoodApiService";
import { IFeedFoodListResponse } from "../../../../types/FoodFeed.type";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { tw } from "../../../../utils/tw";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TableCustom from "../../../components/Table/TableCustom";
import { Dropdown, TDropdownValue } from "../../group-area/views/Dropdown";
import { Input } from "../../group-area/views/Input";
import * as Y from "yup";
import useFormValidator from "../../../../hooks/useFormValidator.hook";
import { get } from "lodash";
import { useTranslation } from "react-i18next";

interface IEditTableModalProps {
  isOpen: boolean;
  onCloseFn: () => void;
  data?: IFeedFoodListResponse["listOfFeeds"];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  totalTableSize: number;
  currentOrderByKey: "name_th" | "sku_th" | "name_en" | "sku_en";
  setCurrentOrderByKey: Dispatch<
    SetStateAction<"name_th" | "sku_th" | "name_en" | "sku_en">
  >;
  orderByType: "desc" | "asc";
  setOrderByType: Dispatch<SetStateAction<"desc" | "asc">>;
  refetchFeeds: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0 [&_.MuiDialog-paperScrollPaper]:!min-w-[99vw]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
  actionContainer: tw(`flex w-full justify-end items-end mt-8`),
};

const errorSchema = (lengthData: number) =>
  Y.object({
    data: Y.array(
      Y.object({
        feed_brand: Y.object({
          nameTh: Y.string().required("กรุณากรอกแบรนด์อาหารสัตว์ (TH)"),
          nameEn: Y.string().required("กรุณากรอกแบรนด์อาหารสัตว์ (EN)"),
        }),
        skuTh: Y.string().required("กรุณากรอกเบอร์อาหารสัตว์ (TH)"),
        skuEn: Y.string().required("กรุณากรอกเบอร์อาหารสัตว์ (EN)"),
        startWeight: Y.string().required("กรุณากรอกน้ำหนักเริ่มต้น"),
        endWeight: Y.string().required("กรุณากรอกน้ำหนักสูงสุด"),
        protein: Y.string().required("กรุณากรอกโปรตีน"),
        consumption: Y.string().required("กรุณากรอกการกินได้"),
        pricePerUnit: Y.string().required("กรุณากรอกราคาต่อหน่อย"),
      })
    )
      .required()
      .length(lengthData),
  });

const EditTableModal = (props: IEditTableModalProps) => {
  const { t } = useTranslation();
  const {
    isOpen,
    onCloseFn,
    data: initData = [],
    currentPage,
    setCurrentPage,
    totalTableSize,
    currentOrderByKey,
    orderByType,
    setCurrentOrderByKey,
    setOrderByType,
    refetchFeeds,
  } = props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = useState<IFeedFoodListResponse["listOfFeeds"]>([]);

  const headerItems = [
    {
      id: "name_th",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.feed.editModal.feedBrand")} (TH)`,
      width: "auto",
      isSorting: true,
      className: "whitespace-pre",
    },
    {
      id: "name_en",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.feed.editModal.feedBrand")} (EN)`,
      width: "auto",
      isSorting: true,
      className: "whitespace-pre",
    },
    {
      id: "sku_th",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.feed.editModal.sku")} (TH)`,
      width: "auto",
      isSorting: true,
      className: "whitespace-pre",
    },
    {
      id: "sku_en",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.feed.editModal.sku")} (EN)`,
      width: "auto",
      isSorting: true,
      className: "whitespace-pre",
    },
    {
      id: "unitPrice",
      numeric: false,
      disablePadding: false,
      label: t("masterData.feed.editModal.pricePerUnit"),
      width: "auto",
      isSorting: false,
      className: "!text-center whitespace-pre",
    },
    {
      id: "startWeight",
      numeric: false,
      disablePadding: false,
      label: "น้ำหนักเริ่มต้น",
      width: "120px",
      isSorting: false,
      className: "!text-center whitespace-pre",
    },
    {
      id: "endWeight",
      numeric: false,
      disablePadding: false,
      label: "น้ำหนักสิ้นสุด",
      width: "120px",
      isSorting: false,
      className: "!text-center whitespace-pre",
    },
    {
      id: "protein",
      numeric: false,
      disablePadding: false,
      label: "โปรตีน",
      width: "120px",
      isSorting: false,
      className: "!text-center whitespace-pre",
    },
    {
      id: "consumption",
      numeric: false,
      disablePadding: false,
      label: "การกินได้",
      width: "120px",
      isSorting: false,
      className: "!text-center whitespace-pre",
    },
    {
      id: "unit",
      numeric: false,
      disablePadding: false,
      label: t("masterData.feed.editModal.units"),
      width: "auto",
      isSorting: false,
      className: "!text-center whitespace-pre",
    },

    {
      id: "remove",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "30px",
      isSorting: false,
    },
  ];

  const unitOptions = [
    { label: t("common.kg"), value: "kg" },
    { label: t("common.bag"), value: "bag" },
  ];

  const onChangeText = useCallback(
    (rowIndex: number, key: string) => (value: string) => {
      setData((prev) => {
        const prevState = cloneDeep(prev);
        return set(prevState, [rowIndex, ...key.split(".")], value);
      });
    },
    []
  );

  const onChangeDropdown = useCallback(
    (rowIndex: number) => (value: TDropdownValue[]) => {
      const v = value[0].value;
      setData((prev) => {
        const prevState = cloneDeep(prev);
        return set(prevState, [rowIndex, "unit"], v);
      });
    },
    []
  );

  const removeMutation = useMutation({
    mutationFn: removeFeed,
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["food-feed", "brands"] });
      refetchFeeds();
      setTimeout(() => {
        dispatch(loadingActions.hide());
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "ลบอาหารสัตว์สำเร็จ",
              type: "success",
            })
          );
        }, 500);
      }, 1500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const editMutation = useMutation({
    mutationFn: editFeed,
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["food-feed", "brands"] });
      refetchFeeds();
      setTimeout(() => {
        dispatch(loadingActions.hide());
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "บันทึกสำเร็จ",
              type: "success",
            })
          );
          onClose();
        }, 500);
      }, 1500);
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onRemove = useCallback(
    (row: { id: string }) => () => {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการลบ",
          text: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          cancelText: "ยกเลิก",
          confirmText: "ยืนยัน",
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: async () => {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังลบอาหารสัตว์..." }));
            removeMutation.mutate({ ids: [row.id] });
          },
        })
      );
    },
    [data]
  );

  const {
    onSubmit: onEdit,
    errors,
    resetError,
  } = useFormValidator({
    form: {
      data,
    },
    errorSchema: errorSchema(data.length),
    onValidateSuccess: () => {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการบันทึก",
          text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
          cancelText: "ยกเลิก",
          confirmText: "ยืนยัน",
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: async () => {
            dispatch(alertAction.hide());
            dispatch(
              loadingActions.show({ message: "กำลังแก้ไขอาหารสัตว์..." })
            );
            editMutation.mutate({
              feedBrandItems: data.map((row) => ({
                id: row.id,
                feedBrandName: row.feed_brand.nameTh,
                feedBrandNameEn: row.feed_brand.nameEn,
                feedBrandNumber: row.skuTh,
                feedBrandNumberEn: row.skuEn,
                unit: row.unit as "kg" | "bag",
                pricePerUnit:
                  typeof row.pricePerUnit === "string"
                    ? +row.pricePerUnit
                    : row.pricePerUnit,
                startWeight:
                  typeof row.startWeight === "string"
                    ? +row.startWeight
                    : row.startWeight,
                endWeight:
                  typeof row.endWeight === "string"
                    ? +row.endWeight
                    : row.endWeight,
                protein:
                  typeof row.protein === "string" ? +row.protein : row.protein,
                consumption:
                  typeof row.consumption === "string"
                    ? +row.consumption
                    : row.consumption,
                orderFeedActive: true,
              })),
            });
          },
        })
      );
    },
  });

  const onClose = useCallback(() => {
    onCloseFn();
    resetError();
  }, []);

  useEffect(() => {
    if (isOpen) {
      setData(initData);
    }
  }, [isOpen, initData]);

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      const unitValue = unitOptions.find(({ value }) => value === row.unit);

      return (
        <TableRow key={row.id} hover className="border-x-[1px] border-x-[#ddd]">
          <TableCell>
            <Input
              onChangeText={onChangeText(index, "feed_brand.nameTh")}
              value={row.feed_brand.nameTh}
              errorMessage={errors[`data[${index}].feed_brand.nameTh`]}
              disabled
            />
          </TableCell>
          <TableCell>
            <Input
              onChangeText={onChangeText(index, "feed_brand.nameEn")}
              value={row.feed_brand.nameEn}
              errorMessage={errors[`data[${index}].feed_brand.nameEn`]}
              disabled
            />
          </TableCell>
          <TableCell>
            <Input
              onChangeText={onChangeText(index, "skuTh")}
              value={row.skuTh}
              errorMessage={errors[`data[${index}].skuTh`]}
            />
          </TableCell>
          <TableCell>
            <Input
              onChangeText={onChangeText(index, "skuEn")}
              value={row.skuEn}
              errorMessage={errors[`data[${index}].startWeight`]}
            />
          </TableCell>
          <TableCell>
            <Input
              onChangeText={onChangeText(index, "pricePerUnit")}
              value={(get(row, ["pricePerUnit"], 0) || "").toString()}
              inputType="number"
              errorMessage={errors[`data[${index}].pricePerUnit`]}
            />
          </TableCell>
          <TableCell>
            <Input
              onChangeText={onChangeText(index, "startWeight")}
              value={(get(row, ["startWeight"], 0) || "").toString()}
              inputType="number"
              errorMessage={errors[`data[${index}].startWeight`]}
            />
          </TableCell>
          <TableCell>
            <Input
              onChangeText={onChangeText(index, "endWeight")}
              value={(get(row, ["endWeight"], 0) || "").toString()}
              inputType="number"
              errorMessage={errors[`data[${index}].endWeight`]}
            />
          </TableCell>
          <TableCell>
            <Input
              onChangeText={onChangeText(index, "protein")}
              value={(get(row, ["protein"], 0) || "").toString()}
              inputType="number"
              errorMessage={errors[`data[${index}].protein`]}
            />
          </TableCell>
          <TableCell>
            <Input
              onChangeText={onChangeText(index, "consumption")}
              value={(get(row, ["consumption"], 0) || "").toString()}
              inputType="number"
              errorMessage={errors[`data[${index}].consumption`]}
            />
          </TableCell>
          <TableCell>
            <Dropdown
              options={unitOptions}
              onChange={onChangeDropdown(index)}
              renderValueArray={unitValue ? [unitValue] : []}
            />
          </TableCell>
          <TableCell onClick={onRemove(row)}>
            <Delete className="mx-auto !text-secondary !cursor-pointer" />
          </TableCell>
        </TableRow>
      );
    });
  }, [data, errors]);

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>
            {t("masterData.feed.editModal.title")}
          </p>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className={classes.space} />
        <TableCustom
          orderBy={currentOrderByKey}
          order={orderByType}
          onChangeOrder={setOrderByType}
          onChangeOrderBy={setCurrentOrderByKey as any}
          totalItemSize={totalTableSize}
          headerItems={headerItems}
          page={currentPage}
          onChangePage={setCurrentPage}
          rowsPerPage={10}
          rows={data}
          EmptyComponent={
            data.length === 0 ? (
              <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
            ) : null
          }
          hideSelect
          rowChildren={rowChildren}
          currentPageItems={[]}
          itemsSelected={[]}
          onSelectAllClick={() => {}}
          onSelectItem={() => {}}
        />
        <div className={classes.actionContainer}>
          <ButtonFillCustom title="ยืนยัน" onClick={onEdit} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(EditTableModal);
