import AddCircleIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
} from "@mui/material";
import { FormEvent, memo, useEffect, useState } from "react";
import { tw } from "../../../../../../utils/tw";
import CrossIcon from "../../../../../assets/svg/CrossIcon.svg";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../../components/Table/TableCustom";
import { IMedicineTable } from "../../../types/medicine.type";
import { useMedicineTableHook } from "../../../hooks/medicine/useMedicineTable.hook";
import { useMedicineFilterHook } from "../../../hooks/medicine/useMedicineFilter.hook";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import SelectAdminCustom from "../../../../../components/Select/Select";
import CreateVaccineModal from "./CreateMedicine";
import EditMedicineModal from "./EditMedicine";
import { useAdminAuth } from "../../../../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

interface IFoodModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[1120px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
};

const MedicineModal = ({ isOpen, onClose }: IFoodModalProps) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.masterDataMedicine === "edit";
  const [isOpenCreateModal, setIsOpenCreteModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);

  const searchMedicine = useMedicineFilterHook();

  const headerItems: ITableCustomHeadCell<keyof IMedicineTable>[] = [
    {
      id: "categoryTh",
      numeric: false,
      disablePadding: false,
      label: `${t(
        "masterData.medicine.medicineModal.categoryOfMedicine"
      )} (TH)`,
      width: "250px",
      isSorting: true,
    },
    {
      id: "categoryEn",
      numeric: false,
      disablePadding: false,
      label: `${t(
        "masterData.medicine.medicineModal.categoryOfMedicine"
      )} (EN)`,
      width: "250px",
      isSorting: true,
    },
    {
      id: "medicineTh",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.medicine.medicineModal.medicineName")} (TH)`,
      width: "250px",
      isSorting: true,
    },
    {
      id: "medicineEn",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.medicine.medicineModal.medicineName")} (EN)`,
      width: "250px",
      isSorting: true,
    },
  ];

  const { list, handleFetch, categoryMedicine, handleFetchCategory } =
    useMedicineTableHook(searchMedicine);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchMedicine.setPage(1);
    searchMedicine.setToggleFetch((prev) => !prev);
  };

  useEffect(() => {
    handleFetch();
  }, [
    searchMedicine.count,
    searchMedicine.page,
    searchMedicine.orderBy,
    searchMedicine.toggleFetch,
  ]);

  useEffect(() => {
    handleFetchCategory();
  }, []);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={slotProps}
        className={classes.container}
        classes={{ paper: "w-full" }}
        onClose={onClose}
      >
        <DialogContent>
          <div className={classes.titleContainer}>
            <p className={classes.title}>
              {t("masterData.medicine.medicineModal.title")}
            </p>
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.space} />

          <div className={classes.row}>
            <div className="min-w-[420px]">
              <SelectAdminCustom
                value={searchMedicine.categoryId}
                placeholder="เลือก"
                menuItems={(categoryMedicine || []).map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.nameTh}
                  </MenuItem>
                ))}
                renderValueArray={(categoryMedicine || []).map((row) => ({
                  value: row.id,
                  label: row.nameTh,
                }))}
                endAdornment={
                  searchMedicine.categoryId !== "" ? (
                    <CloseRoundedIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "16px !important",
                        marginRight: "20px",
                      }}
                      onClick={(_) => {
                        searchMedicine.setCategoryId("");
                        searchMedicine.setToggleFetch((prev) => !prev);
                      }}
                    />
                  ) : null
                }
                onChange={(e) =>
                  searchMedicine.setCategoryId(e.target.value as string)
                }
              />
            </div>
            <ButtonFillCustom
              title={t("common.button.search")}
              btnTextColor="#FFFFFF"
              onClick={() => {
                searchMedicine.setToggleFetch((prev) => !prev);
              }}
            />
          </div>
          <div className={classes.space} />
          <div className={classes.row}>
            <form onSubmit={handleSubmit}>
              <div className="min-w-[470px]">
                <TextInputSearchCustom
                  value={searchMedicine.searchText}
                  placeholder="ค้นหาโดยชื่อยา"
                  onChange={(event) =>
                    searchMedicine.setSearchText(event.target.value)
                  }
                  InputProps={{
                    endAdornment:
                      searchMedicine.searchText.length > 0 ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(_) => {
                            searchMedicine.setSearchText("");
                            searchMedicine.setToggleFetch((prev) => !prev);
                          }}
                        />
                      ) : null,
                  }}
                />
              </div>
            </form>
            {roleActionEdit && (
              <div className="flex gap-x-3">
                <ButtonOutlinedCustom
                  title="เพิ่ม"
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  startIcon={<AddCircleIcon />}
                  onClick={() => setIsOpenCreteModal(true)}
                />
                <ButtonOutlinedCustom
                  title="แก้ไข"
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  onClick={() => setIsOpenEditModal(true)}
                />
              </div>
            )}
          </div>

          <div className="mt-6">
            <TableCustom
              order={searchMedicine.direction}
              orderBy={searchMedicine.orderBy}
              itemsSelected={[]}
              rowsPerPage={10}
              page={searchMedicine.page}
              headerItems={headerItems}
              rows={list}
              onChangeOrderBy={(val) =>
                searchMedicine.setOrderBy(val as keyof IMedicineTable)
              }
              onChangeOrder={(val) => searchMedicine.setDirection(val)}
              onChangePage={(val) => searchMedicine.setPage(val)}
              onSelectItem={() => {}}
              onSelectAllClick={() => {}}
              totalItemSize={searchMedicine.count}
              currentPageItems={[]}
              hideSelect
              maxHeightForScroll="500px"
              rowChildren={list.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.categoryTh}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.categoryEn}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.medicineTh}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.medicineEn}
                    </TableCell>
                  </TableRow>
                );
              })}
            />
          </div>
        </DialogContent>
      </Dialog>
      <CreateVaccineModal
        isOpen={isOpenCreateModal}
        onClose={() => setIsOpenCreteModal(false)}
        handleFetch={() => {}}
      />
      <EditMedicineModal
        isOpen={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
        handleFetch={() => {}}
      />
    </>
  );
};

export default memo(MedicineModal);
