import { get } from "lodash";
import { useTranslation } from "react-i18next";
import AppBarFS from "../../../components/app-bar/AppBar";
import { tw } from "../../../utils/tw";
import FilterOrder from "../../feed-order-and-delivery/my-order/my-order-component/filter/FilterOrder";
import { useActionController } from "../actionController";
import { dialogWord } from "../constants";
import Dialog from "../views/Dialog";
import OrderItem from "../views/OrderItem";
import { useNewOrderController } from "./controller";

const classes = {
  container: tw(`flex flex-col min-h-screen w-full`),
  appbarContainer: tw(`py-4`),
  orderListContainer: tw(`flex flex-col w-full px-[16px]`),
};

const NewOrder = (props: { isHistory?: boolean }) => {
  const { isHistory = false } = props;
  const {
    orderList,
    onBack,
    onMoreDetail,
    orderBy,
    setOrderBy,
    onAction,
    onCloseAction,
    actionModalDetail,
    isShowActionModal,
  } = useNewOrderController(isHistory);

  const { actionFunctions } = useActionController({
    orderDetail: actionModalDetail,
    onCloseModal: onCloseAction,
  });

  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.appbarContainer}>
        <AppBarFS
          title={
            !isHistory
              ? t("PartnerOrder.UpcomingOrder")
              : t("PartnerOrder.OrderHistory")
          }
          onIconPress={onBack}
        />
      </div>
      <FilterOrder
        title={t("feedOrderAndDelivery.myOrder.subtitleBar")}
        titleContainer="flex flex-1"
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <div className={classes.orderListContainer}>
        {(orderList || []).map((order, index) => (
          <OrderItem
            key={order.id}
            {...order}
            index={index}
            onMoreDetail={onMoreDetail(order.id)}
            onAction={onAction(order)}
          />
        ))}
      </div>
      <Dialog
        isOpen={isShowActionModal}
        title={get(
          dialogWord,
          [actionModalDetail?.status || "-", "title"],
          "-"
        )}
        text={get(dialogWord, [actionModalDetail?.status || "-", "text"], "-")}
        confirmText={t("FinancialSupport.Confirm")}
        onClose={onCloseAction}
        onConfirm={actionFunctions[actionModalDetail?.status || "new"]}
      />
    </div>
  );
};

export default NewOrder;
