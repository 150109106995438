import { Divider, Modal } from "@mui/material";
import { cloneDeep, set } from "lodash";
import { Dispatch, SetStateAction, memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonFillCustom from "../../../../../admin/components/Button/ButtonFillCustom";
import BankSvg from "../../../../../assets/svg/BankSvg";
import AppBarFS from "../../../../../components/app-bar/AppBar";
import RadioButton from "../../../../../components/common/RadioButton";
import { TFarmerGetListBillItem } from "../../../../../types/Invoice.type";
import { getCommaNumber } from "../../../../../utils/number";
import { tw } from "../../../../../utils/tw";
import ThaiQRPayment from "./views/ThaiQRPayment";
import UploadProofOfPayment from "./views/UploadProofOfPayment";

interface IPaymentMethod {
  isOpen: boolean;
  onClose: () => void;
  summary: string;
  customPrice: string;
  data?: TFarmerGetListBillItem[];
  itemSelected?: string[];
  setItemSelected?: Dispatch<SetStateAction<string[]>>;
}

interface IState {
  option: number;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`w-full h-full bg-[white]`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(`flex flex-col w-full h-full gap-[16px] px-[16px]`),
  tableContainer: tw(`pb-[163px]`),
  textAndFilterContainer: tw(`flex flex-row py-[16px] px-4 justify-between`),
  buttonContainer: tw(`pt-1`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  buttonTextContainer: tw(
    `flex flex-col gap-6 pt-8 px-[17.5px] pb-6 fixed bottom-0 bg-[#FFFFFF] w-full z-10`
  ),
  textContainer: tw(`flex flex-row justify-between`),
  text1: tw(`font-semibold text-[18px] text-[#3777BC]`),
  text2: tw(`font-normal text-[1rem] text-secondary`),
  text3: tw(`font-medium text-[14px] text-[#4B4B4B]`),
};

const PaymentMethodModal = (props: IPaymentMethod) => {
  const {
    isOpen,
    onClose,
    summary,
    customPrice,
    data,
    itemSelected,
    setItemSelected,
  } = props;

  const [state, setState] = useState<IState>({ option: -1 });

  const onChangeState = useCallback(
    (key: keyof IState) => (value: any) => {
      setState((prev) => {
        const prevState = cloneDeep(prev);
        return set(prevState, key, value);
      });
    },
    []
  );

  const [isShowThaiQR, setIsShowThaiQR] = useState(false);

  const onShowThaiQR = useCallback(() => {
    setIsShowThaiQR(true);
  }, []);

  const onHideThaiQR = useCallback(() => {
    setIsShowThaiQR(false);
  }, []);

  const [isShowProofOfPayment, setIsShowProofOfPayment] = useState(false);

  const onShowProofOfPayment = useCallback(() => {
    setIsShowProofOfPayment(true);
  }, []);

  const onHideProofOfPayment = useCallback(() => {
    setIsShowProofOfPayment(false);
  }, []);

  const openModalFormCheck = useCallback(() => {
    if (state.option === 3) {
      onShowThaiQR();
    } else if (state.option === 4) {
      onShowProofOfPayment();
    }
  }, [state]);

  const onCloseFn = useCallback(() => {
    onClose();
    setState({ option: -1 });
  }, []);

  const { t } = useTranslation();

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS
            title={t("FinancialSupport.PaymentMethod")}
            onIconPress={onCloseFn}
          />
        </div>
        <div className="px-4">
          {
            //* Don't delete
            /* <Accordion
            style={{
              boxShadow: "none",
            }}
          >
            <AccordionSummary
              expandIcon={
                <ArrowDropDownIcon
                  className={"text-[#3777BC] !h-[32px] !w-[32px]"}
                />
              }
              aria-controls="panel1-content"
              id="panel1-header"
              className="!px-0"
            >
              <div className={classes.text1}>บิลทั้งหมด</div>
            </AccordionSummary>
            <AccordionDetails className="flex flex-col gap-4 !p-0">
              <div
                className="flex flex-row justify-between"
                onClick={() => onChangeState("option")(0)}
              >
                <div
                  className={tw(
                    classes.text3,
                    "flex flex-row gap-3 items-center"
                  )}
                >
                  <KPLUSSvg />K PLUS
                </div>
                <RadioButton checked={state.option === 0} />
              </div>
              <div
                className="flex flex-row justify-between"
                onClick={() => onChangeState("option")(1)}
              >
                <div
                  className={tw(
                    classes.text3,
                    "flex flex-row gap-3 items-center"
                  )}
                >
                  <SCBSvg />
                  SCB
                </div>
                <RadioButton checked={state.option === 1} />
              </div>
              <div
                className="flex flex-row justify-between"
                onClick={() => onChangeState("option")(2)}
              >
                <div
                  className={tw(
                    classes.text3,
                    "flex flex-row gap-3 items-center"
                  )}
                >
                  <KTBSvg />
                  KTB
                </div>
                <RadioButton checked={state.option === 2} />
              </div>
            </AccordionDetails>
          </Accordion>
          <Divider></Divider> */
          }
          {/* <div className="pt-4 pb-3">
            <div className={classes.text1}>ช่องทางอื่นๆ</div>
            <div
              className="flex flex-row justify-between"
              onClick={() => onChangeState("option")(3)}
            >
              <div
                className={tw(
                  classes.text3,
                  "flex flex-row gap-3 items-center pt-4"
                )}
              >
                <ThaiQRSvg />
                QR Payment
              </div>
              <RadioButton checked={state.option === 3} />
            </div>
          </div>
          <Divider></Divider> */}
          <div className="pt-4 pb-3 ">
            <div className={classes.text1}>{t("FinancialSupport.Bank")}</div>
            <div
              className="flex flex-row justify-between items-center mt-3"
              onClick={() => onChangeState("option")(4)}
            >
              <div
                className={tw(
                  classes.text3,
                  "flex flex-row gap-3 items-center"
                )}
              >
                <BankSvg />
                {t("FinancialSupport.UploadTransfer")}
              </div>
              <RadioButton checked={state.option === 4} />
            </div>
          </div>
          <Divider></Divider>
        </div>
        <div className={classes.buttonContainer}>
          <div
            className={classes.buttonTextContainer}
            style={{
              boxShadow: "2px 0px 4px 0px #00000020",
            }}
          >
            <div className="flex flex-col gap-[6px]">
              <div className={classes.textContainer}>
                <div className={tw(classes.text1, "text-[16px]")}>
                  {t("FinancialSupport.DueAmount")}
                </div>
                <div
                  className={tw(classes.text1, "text-[16px] text-[#34407B]")}
                >
                  {getCommaNumber(+(customPrice || summary), true)}{" "}
                  {t("reportOrder.currency")}
                </div>
              </div>
            </div>
            <ButtonFillCustom
              title={t("FinancialSupport.Next")}
              className={classes.button}
              disabled={state.option === -1}
              onClick={openModalFormCheck}
            />
          </div>
        </div>
        <ThaiQRPayment
          isOpen={isShowThaiQR}
          onClose={onHideThaiQR}
          summary={summary}
          customPrice={customPrice}
        />
        <UploadProofOfPayment
          isOpen={isShowProofOfPayment}
          onClose={onHideProofOfPayment}
          summary={summary}
          customPrice={customPrice}
          data={data}
          itemSelected={itemSelected}
          setItemSelected={setItemSelected}
        />
      </div>
    </Modal>
  );
};

export default memo(PaymentMethodModal);
