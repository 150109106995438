export default function () {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#68C184" />
      <mask
        id="mask0_21601_926596"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="9"
        y="9"
        width="22"
        height="22"
      >
        <rect x="9" y="9" width="22" height="22" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_21601_926596)">
        <path
          d="M19.9974 20.0013C18.9891 20.0013 18.1259 19.6423 17.4078 18.9242C16.6898 18.2062 16.3307 17.343 16.3307 16.3346C16.3307 15.3263 16.6898 14.4631 17.4078 13.7451C18.1259 13.027 18.9891 12.668 19.9974 12.668C21.0057 12.668 21.8689 13.027 22.587 13.7451C23.305 14.4631 23.6641 15.3263 23.6641 16.3346C23.6641 17.343 23.305 18.2062 22.587 18.9242C21.8689 19.6423 21.0057 20.0013 19.9974 20.0013ZM12.6641 27.3346V24.768C12.6641 24.2485 12.7977 23.7711 13.0651 23.3357C13.3325 22.9003 13.6877 22.568 14.1307 22.3388C15.078 21.8652 16.0405 21.51 17.0182 21.2732C17.996 21.0364 18.9891 20.918 19.9974 20.918C21.0057 20.918 21.9988 21.0364 22.9766 21.2732C23.9543 21.51 24.9168 21.8652 25.8641 22.3388C26.3071 22.568 26.6623 22.9003 26.9297 23.3357C27.197 23.7711 27.3307 24.2485 27.3307 24.768V27.3346H12.6641Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
