import {
  Link,
  TableCell,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useMemo,
  useState,
} from "react";
import { tw } from "../../../../utils/tw";
import TableCustomMobile from "../../../financial-support/financial-billPayment/unpaid-bill/views/TableCustomMobile";
import { RowListVaccineStock } from "../../../../types/StockMangement.type";
import PencilSvg from "../../../../assets/svg/PencilSvg";
import { getCommaNumber } from "../../../../utils/number";
import { useTranslation } from "react-i18next";

const classes = {
  container: tw(`min-h-[240px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

const unit = {
  bottle: "ขวด",
  "": "",
};

interface ITable {
  swapCellColor?: boolean;
  data: RowListVaccineStock[];
  setItemId: Dispatch<SetStateAction<string>>;
  onEdit: () => void;
  onHistory: () => void;
}

const TableVaccineStock = (props: ITable) => {
  const { t } = useTranslation();
  const { swapCellColor, data, setItemId, onEdit, onHistory } = props;
  const [currentPage, setCurrentPage] = useState(1);

  const headerItems = [
    {
      id: "SKU",
      numeric: false,
      disablePadding: false,
      label: t("manageStock.sku"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "Quantity",
      numeric: false,
      disablePadding: false,
      label: t("manageStock.quantity"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "Measure",
      numeric: false,
      disablePadding: false,
      label: t("manageStock.measure"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "Edit",
      numeric: false,
      disablePadding: false,
      label: t("manageStock.edit"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
  ];

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#4B4B4B",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    },
    "&:nth-of-type(even)": {
      backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    },
  }));

  const onClickId = useCallback(
    (editId: string) => () => {
      setItemId(editId);
      onEdit();
    },
    []
  );

  const onHistoryId = useCallback(
    (historyId: string) => () => {
      setItemId(historyId);
      onHistory();
    },
    []
  );

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      return (
        <StyledTableRow key={row.id + index}>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={onHistoryId(row.id)}
              sx={{
                color: "#68C184",
                textDecorationColor: "#68C184",
                ":hover": {
                  fontSize: "16px",
                },
              }}
            >
              {row.vaccine.nameTh}
            </Link>
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {getCommaNumber(row.amount, true)}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
          >
            {unit[row.unit as "bottle" | ""]}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
              whiteSpace: "pre",
            }}
            className="!flex !justify-center"
            onClick={onClickId(row.id)}
          >
            <PencilSvg />
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }, [data, currentPage]);

  return (
    <div className={classes.container}>
      <TableCustomMobile
        orderBy={""}
        order={"desc"}
        onChangeOrder={() => {}}
        onChangeOrderBy={() => {}}
        totalItemSize={data.length}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={data.length}
        rows={data}
        EmptyComponent={
          data?.length === 0 ? (
            <div className={classes.emptyTable}>{`${t(
              "manageStock.noData"
            )}`}</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={[]}
        onSelectAllClick={() => {}}
        onSelectItem={() => {}}
        hideSelect
        hidePaginator
      />
    </div>
  );
};

export default memo(TableVaccineStock);
