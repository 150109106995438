export default function () {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.335938" width="40" height="40" rx="20" fill="#2C702F" />
      <path
        d="M23.9391 15.2016C23.9391 17.1898 22.3273 18.8016 20.3391 18.8016C18.3508 18.8016 16.7391 17.1898 16.7391 15.2016C16.7391 13.2133 18.3508 11.6016 20.3391 11.6016C22.3273 11.6016 23.9391 13.2133 23.9391 15.2016Z"
        fill="white"
      />
      <path
        d="M29.939 17.6016C29.939 18.927 28.8645 20.0016 27.539 20.0016C26.2136 20.0016 25.1391 18.927 25.1391 17.6016C25.1391 16.2761 26.2136 15.2016 27.539 15.2016C28.8645 15.2016 29.939 16.2761 29.939 17.6016Z"
        fill="white"
      />
      <path
        d="M25.1391 26.0016C25.1391 23.3506 22.99 21.2016 20.3391 21.2016C17.6881 21.2016 15.5391 23.3506 15.5391 26.0016V29.6016H25.1391V26.0016Z"
        fill="white"
      />
      <path
        d="M15.5391 17.6016C15.5391 18.927 14.4645 20.0016 13.1391 20.0016C11.8136 20.0016 10.7391 18.927 10.7391 17.6016C10.7391 16.2761 11.8136 15.2016 13.1391 15.2016C14.4645 15.2016 15.5391 16.2761 15.5391 17.6016Z"
        fill="white"
      />
      <path
        d="M27.539 29.6016V26.0016C27.539 24.7366 27.2128 23.5478 26.6399 22.5148C26.9273 22.4409 27.2286 22.4016 27.539 22.4016C29.5273 22.4016 31.139 24.0133 31.139 26.0016V29.6016H27.539Z"
        fill="white"
      />
      <path
        d="M14.0382 22.5148C13.4653 23.5478 13.1391 24.7366 13.1391 26.0016V29.6016H9.53906V26.0016C9.53906 24.0133 11.1508 22.4016 13.1391 22.4016C13.4495 22.4016 13.7508 22.4409 14.0382 22.5148Z"
        fill="white"
      />
    </svg>
  );
}
