import { apiPaths } from "../../const/apiPaths";
import { IGeoLocationResponse, IGetGroupArea } from "../../types/Utils.type";
import {
  IBasicPartnerGraphResponse,
  ICorePartnerGraphResponse,
  IListBasicPartnerResponse,
  IListCorePartnerResponse,
} from "../../types/dashboard/PerformancePartner.type";
import adminAPI from "../AdminApiService";

export const getCorePartnerGraph = async () => {
  try {
    const { data } = await adminAPI.get<ICorePartnerGraphResponse>(
      apiPaths.performancePartner.getCoreGraph
    );
    return data.data;
  } catch (error) {
    console.log("getCorePartnerGraph", error);
    return null;
  }
};

export const getListCorePartner = async (params: {
  groupAreaIds?: string;
  segments?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IListCorePartnerResponse>(
      apiPaths.performancePartner.getListCore,
      {
        params: {
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: 10,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListCorePartner", error);
    return null;
  }
};

export const getExportListCorePartner = async (params: {
  groupAreaIds?: string;
  segments?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IListCorePartnerResponse>(
      apiPaths.performancePartner.getListCore,
      {
        params: {
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: 10,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListCorePartner", error);
    return null;
  }
};

export const getBasicPartnerGraph = async () => {
  try {
    const { data } = await adminAPI.get<IBasicPartnerGraphResponse>(
      apiPaths.performancePartner.getBasicGraph
    );
    return data.data;
  } catch (error) {
    console.log("getBasicPartnerGraph", error);
    return null;
  }
};

export const getListBasicPartner = async (params: {
  groupAreaIds?: string;
  segments?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IListBasicPartnerResponse>(
      apiPaths.performancePartner.getListBasic,
      {
        params: {
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: 10,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListBasicPartner", error);
    return null;
  }
};

export const getExportListBasicPartner = async (params: {
  groupAreaIds?: string;
  segments?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IListBasicPartnerResponse>(
      apiPaths.performancePartner.getListBasic,
      {
        params: {
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: 10,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getExportListBasicPartner", error);
    return null;
  }
};

export const getGeoLocations = async () => {
  try {
    const { data } = await adminAPI.get<IGeoLocationResponse>(
      apiPaths.utils.getGeoLocations
    );
    return data.rows;
  } catch (error) {
    console.log("getGeoLocations", error);
    return null;
  }
};

export const getPartnerGroupArea = async () => {
  try {
    const { data } = await adminAPI.get<IGetGroupArea>(
      apiPaths.performance.getGroupArea
    );
    return data.rows;
  } catch (error) {
    console.log("getPartnerGroupArea", error);
    return null;
  }
};
