import { Modal } from "@mui/material";
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../../../../../assets/images/OrderFeedImage/bg.png";
import CheckSuccessIcon from "../../../../../../assets/images/check-success.svg";
import CustomButton from "../../../../../../components/common/Button";
import { tw } from "../../../../../../utils/tw";

interface ISuccessModal {
  isOpen: boolean;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`w-full h-full bg-[white]`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(`flex flex-col w-full h-full gap-[16px] px-[16px]`),
  text1: tw(`font-bold text-[16px] text-[#3777BC]`),
};

const SuccessModal = (props: ISuccessModal) => {
  const { isOpen } = props;

  const navigate = useNavigate();

  const [isShowReceipt, setIsShowReceipt] = useState(false);

  const onShowReceipt = useCallback(() => {
    setIsShowReceipt(true);
  }, []);

  const onCloseReceipt = useCallback(() => {
    setIsShowReceipt(false);
  }, []);

  const { t } = useTranslation();
  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div
        className="h-full py-4 flex flex-col justify-between"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          height: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="flex flex-col gap-4 items-center h-[80%] justify-center">
          <img
            width="100px"
            height="100px"
            src={CheckSuccessIcon}
            alt="success"
          />
          <span className="text-[36px] text-[#ffff] font-semibold">
            {t("FinancialSupport.PaymentSuccessfulPage")}
          </span>
          <span className="text-[16px] text-[#ffff] font-medium">
            {t("FinancialSupport.ThankForPayBill")}
          </span>
        </div>
        <div className="flex flex-col gap-4 items-center px-4 h-[20%] justify-end">
          {/* <CustomButton
            variant="secondary"
            label={"ดูใบเสร็จ"}
            onClick={onShowReceipt}
          /> */}
          <CustomButton
            label={t("FinancialSupport.WaitForValidation")}
            onClick={() =>
              navigate("/financial-support/financial-bill-payment/verify-bill")
            }
          />
        </div>
        {/* <ReceiptModal isOpen={isShowReceipt} onClose={onCloseReceipt} /> */}
      </div>
    </Modal>
  );
};

export default memo(SuccessModal);
