import { useState } from "react";
import { Order } from "../../../components/Table/TableCustom";

export const useSaleTargetSaleLead = () => {
  const [searchText, setSearchText] = useState("");
  const [province, setProvince] = useState("");
  const [groupId, setGroupId] = useState("");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [order, setOrder] = useState("");
  const [count, setCount] = useState(0);

  return {
    province,
    groupId,
    searchText,
    direction,
    page,
    limit,
    toggleFetch,
    order,
    count,
    setCount,
    setOrder,
    setProvince,
    setGroupId,
    setSearchText,
    setDirection,
    setPage,
    setLimit,
    setToggleFetch,
  };
};

export type TUseSaleTargetSaleLead = ReturnType<typeof useSaleTargetSaleLead>;
