import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../../../const/app.constant";
import { thousandComma } from "../../../../../../utils/common/string";
import ChipOrderStatus from "../../../../../components/Chip/ChipOrderStatus";
import {
  AcceptDebtRow,
  TDebtInstallmentStatus,
} from "../../types/acceptDebt.type";
import { NotAcceptDebtRow } from "../../types/notAcceptDebt.type";
import { useTranslation } from "react-i18next";

type Props = {
  dataExpandTable: AcceptDebtRow | NotAcceptDebtRow | undefined;
};

const ExpandListTable = ({ dataExpandTable }: Props) => {
  const { t } = useTranslation();
  const MoneyDebtStatusMap: Record<
    TDebtInstallmentStatus,
    { color: string; bgColor: string; label: string }
  > = {
    complete: {
      color: "#409261",
      bgColor: "#E9FFEF",
      label: "สำเร็จ",
    },
    pending_review: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "อยู่ระหว่างตรวจสอบ",
    },
    unpaid: {
      color: "#3F3748",
      bgColor: "#E4E4E4",
      label: "ยังไม่ได้ชำระ",
    },
  };

  return (
    <TableCell colSpan={11} sx={{ bgcolor: "#FFFFFF", padding: 0 }}>
      <p className="text-[#68C184] font-bold text-xl my-4">
        {t(
          "moneyDeptAndCollection.acceptDeptStatus.onProcess.installmentDetails.title"
        )}
      </p>
      <TableContainer
        sx={{
          scrollBehavior: "smooth",
          overflow: "auto",
        }}
      >
        <Table
          sx={{
            width: "100%",
          }}
          aria-labelledby="table-show-data"
          size={"medium"}
        >
          <TableHead
            sx={{
              bgcolor: "#FBFBFB",
              borderBottom: "1px solid #E1E1E1",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                }}
              >
                {t(
                  "moneyDeptAndCollection.acceptDeptStatus.onProcess.installmentDetails.no"
                )}
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                }}
                align="left"
              >
                {t(
                  "moneyDeptAndCollection.acceptDeptStatus.onProcess.installmentDetails.paymentAmountPerMonth"
                )}
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                }}
                align="left"
              >
                {t(
                  "moneyDeptAndCollection.acceptDeptStatus.onProcess.installmentDetails.installmentTime"
                )}
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                }}
                align="left"
              >
                {t(
                  "moneyDeptAndCollection.acceptDeptStatus.onProcess.installmentDetails.amountPaid"
                )}
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                }}
                align="left"
              >
                {t(
                  "moneyDeptAndCollection.acceptDeptStatus.onProcess.installmentDetails.paymentDueDate"
                )}
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                }}
                align="left"
              >
                {t(
                  "moneyDeptAndCollection.acceptDeptStatus.onProcess.installmentDetails.lastUpdated"
                )}
              </TableCell>
              <TableCell
                sx={{
                  height: "50px",
                  justifyContent: "start",
                  fontFamily: "IBM Plex Sans Thai",
                  fontWeight: 700,
                  fontSize: "14px",
                  border: "0px",
                  padding: "0px 16px",
                }}
                align="left"
              >
                {t(
                  "moneyDeptAndCollection.acceptDeptStatus.onProcess.installmentDetails.status"
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(dataExpandTable?.debtInstallments || []).map((debt, index) => (
              <TableRow
                key={debt.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}.
                </TableCell>
                <TableCell align="left">
                  {thousandComma(+debt.paymentPerMonth) +
                    ` ${t("common.bath")}`}
                </TableCell>
                <TableCell align="left">{debt.paymentNumber}</TableCell>
                <TableCell align="left">{debt.paymentPaid}</TableCell>
                <TableCell align="left">
                  {debt.dueDate
                    ? dayjs(debt.dueDate).format(DATE_FORMAT_DATE_PICKER2)
                    : "-"}
                </TableCell>
                <TableCell align="left">
                  {debt.updatedAt
                    ? dayjs(debt.updatedAt).format(DATE_FORMAT_DATE_PICKER2)
                    : "-"}
                </TableCell>
                <TableCell align="left">
                  <ChipOrderStatus
                    bgColor={MoneyDebtStatusMap[debt.status].bgColor}
                    fontColor={MoneyDebtStatusMap[debt.status].color}
                    label={MoneyDebtStatusMap[debt.status].label}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TableCell>
  );
};

export default ExpandListTable;
