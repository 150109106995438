import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterMarketListType } from "./DashBoardPigPrice";

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  filterList: FilterMarketListType;
  setFilterList: Dispatch<SetStateAction<FilterMarketListType>>;
  hideSelectYear?: boolean;
  dataYear?: number[];
};

const FilterChartModal: React.FC<Props> = ({
  isOpen,
  handleClose,
  filterList,
  setFilterList,
  hideSelectYear,
  dataYear,
}) => {
  const [tempRegion, setTempRegion] = useState(filterList.region);
  const [tempDisplayPrice, setTempDisplayPrice] = useState(
    filterList.displayPrice
  );
  const [tempYear, setTempYear] = useState(filterList.year);

  const handleChangeSector = (event: SelectChangeEvent) => {
    setTempRegion(event.target.value as string);
  };

  const handleChangeChartType = (event: SelectChangeEvent) => {
    setTempDisplayPrice(event.target.value as string);
  };
  const handleChangeYear = (event: SelectChangeEvent) => {
    setTempYear(event.target.value as string);
  };

  const handleApply = () => {
    setFilterList({
      region: tempRegion,
      displayPrice: tempDisplayPrice,
      year: tempYear,
    });
    handleClose();
  };

  const handleReset = () => {
    setTempRegion("north");
    setTempDisplayPrice("all");
    setTempYear(dayjs().format("YYYY"));
    setFilterList({
      region: "north",
      displayPrice: "all",
      year: dayjs().format("YYYY"),
    });
  };

  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      disableEnforceFocus={false}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "313px",
          minWidth: "313px",
          bgcolor: "#000",
          borderRadius: "40px",
          boxShadow: 24,
        }}
      >
        <div className="flex flex-col relative px-6 pt-4 pb-6 bg-white w-full h-full rounded-[40px]">
          <div className="flex flex-row gap-2">
            <FilterListIcon
              sx={{
                color: "#68C184",
                fontWeight: 500,
                alignSelf: "center",
                height: "100%",
              }}
            />
            <span className="text-secondary font-medium text-2xl h-full self-center pt-1">
              {t("manageStock.filterBy")}
            </span>
          </div>
          <div className="absolute right-6 top-4">
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
          </div>
          <div className="flex flex-col h-full justify-start pt-6 gap-6">
            <div className="flex flex-col w-full">
              <span className="text-tertiary font-semibold text-xl pb-3">
                {t("FinancialSupport.FilterBy")}
              </span>
              <Select
                defaultValue={tempRegion}
                value={tempRegion}
                onChange={handleChangeSector}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #68C184",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #68C184",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #68C184",
                  },
                  height: "2rem",
                  borderRadius: "10px !important",
                  minWidth: "132px",
                  padding: "10px",
                  border: "1px solid #68C184",
                  color: "#68C184",
                  bgcolor: "#F0FDF4",
                  justifyContent: "space-between",
                  width: "fit-content",
                  textAlign: "center",
                  fontWeight: "normal",
                  fontSize: "1rem",
                }}
              >
                <MenuItem value={"north"}>ภาคเหนือ</MenuItem>
                <MenuItem value={"south"}>ภาคใต้</MenuItem>
                <MenuItem value={"north_east"}>ภาคอีสาน</MenuItem>
                <MenuItem value={"east"}>ภาคตะวันออก</MenuItem>
                <MenuItem value={"west"}>ภาคตะวันตก</MenuItem>
              </Select>
            </div>
          </div>
          <div className="flex flex-col h-full justify-start pt-6 gap-6">
            <div className="flex flex-col w-full">
              <span className="text-tertiary font-semibold text-xl pb-3">
                การเเสดงกราฟ
              </span>
              <Select
                defaultValue={tempDisplayPrice}
                value={tempDisplayPrice}
                onChange={handleChangeChartType}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #68C184",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #68C184",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #68C184",
                  },
                  height: "2rem",
                  borderRadius: "10px !important",
                  minWidth: "132px",
                  padding: "10px",
                  border: "1px solid #68C184",
                  color: "#68C184",
                  bgcolor: "#F0FDF4",
                  justifyContent: "space-between",
                  width: "fit-content",
                  textAlign: "center",
                  fontWeight: "normal",
                  fontSize: "1rem",
                }}
              >
                <MenuItem value={"all"}>{t("FarmCommunity.All")}</MenuItem>
                <MenuItem value={"market_price"}>
                  {t("FarmCommunity.MarketPrice")}
                </MenuItem>
                <MenuItem value={"sell_price"}>
                  {t("FarmCommunity.SellPrice")}
                </MenuItem>
              </Select>
            </div>
          </div>
          {!hideSelectYear && (
            <div className="flex flex-col h-full justify-start pt-6 gap-6">
              <div className="flex flex-col w-full">
                <span className="text-tertiary font-semibold text-xl pb-3">
                  {t("BasicAccounting.year")}
                </span>
                <Select
                  defaultValue={tempYear}
                  value={tempYear}
                  onChange={handleChangeYear}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #68C184",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #68C184",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #68C184",
                    },
                    height: "2rem",
                    borderRadius: "10px !important",
                    minWidth: "132px",
                    padding: "10px",
                    border: "1px solid #68C184",
                    color: "#68C184",
                    bgcolor: "#F0FDF4",
                    justifyContent: "space-between",
                    width: "fit-content",
                    textAlign: "center",
                    fontWeight: "normal",
                    fontSize: "1rem",
                  }}
                >
                  {dataYear?.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          )}

          <div className="flex w-full gap-2 pt-6">
            <Button
              variant="outlined"
              onClick={handleReset}
              sx={{
                border: "1px solid #68C184",
                color: "#68C184",
                ":active": {
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                  color: "#FFFFFF",
                },
                ":hover": {
                  border: "1px solid #68C184",
                  bgcolor: "#68C184",
                  color: "#FFFFFF",
                },
                height: "37px",
              }}
              fullWidth
            >
              {t("farmPastPerformance.filter.button.reset")}
            </Button>
            <Button
              sx={{
                bgcolor: "#68C184",
                color: "#FFFFFF",
                ":active": {
                  border: "1px solid #68C184",
                  bgcolor: "#FFFFFF",
                  color: "#68C184",
                },
                ":hover": {
                  border: "1px solid #68C184",
                  bgcolor: "#FFFFFF",
                  color: "#68C184",
                },
                height: "37px",
              }}
              fullWidth
              onClick={handleApply}
            >
              {t("farmPastPerformance.filter.button.apply")}
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default FilterChartModal;
