import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import StockImage from "../../../../../assets/images/stock-img.png";
import DownloadLinkWithDelete from "../../../../../components/common/File/DownloadLinkWithDelete";
import ImagePreview from "../../../../../components/common/ImagePreviewWithModal/ImagePreview";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../redux/store";
import {
  deliverStockRefill,
  getDownloadUrlStockRefill,
} from "../../../../../services/ManageStock.service";
import { IStockRefillItemsDetail } from "../../../../../types/ManageStock.type";
import { handleDownloadBlob } from "../../../../../utils/downloadFile";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import ImportIcon from "../../../../assets/svg/ImportIcon.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import { TStockRefill } from "./FillProductPanel";
import PartnerImportDocumentModal from "./PartnerImportDocumentModal";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";

const mockFileStatic = [
  {
    url: StockImage,
    name: "stock-img.png",
  },
  {
    url: StockImage,
    name: "stock-img.png",
  },
];

interface IFillProductCellDetailProps {
  status: TStockRefill;
  data: IStockRefillItemsDetail[];
  partnerId: string;
  stockId: string;
  stockFiles: {
    id: string;
    fileName: string;
    fileSize: number;
    documentType: string;
  }[];
  refetch: () => void;
  showButton: boolean;
}

function FillProductCellDetail({
  status,
  data,
  partnerId,
  stockId,
  stockFiles,
  refetch,
  showButton,
}: IFillProductCellDetailProps) {
  const { action } = useAdminAuth();
  const roleActionEdit = action?.manageStock === "edit";
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [openImportDocumentModal, setOpenImportDocumentModal] = useState(false);
  const handleGetBlob = useCallback(
    async (
      partnerId: string,
      stockRefillId: string,
      fileId: string,
      documentType: string
    ) => {
      const preSignURL = await getDownloadUrlStockRefill(
        partnerId,
        stockRefillId,
        fileId,
        documentType
      );
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        return response.data;
      }
      return null;
    },
    [stockFiles]
  );
  const handleDownloadByUrl = useCallback(
    (
        partnerId: string,
        stockRefillId: string,
        fileId: string,
        name: string,
        documentType: string
      ) =>
      async () => {
        const blob = await handleGetBlob(
          partnerId,
          stockRefillId,
          fileId,
          documentType
        );
        if (blob) {
          const objectURL = window.URL.createObjectURL(blob);
          handleDownloadBlob(objectURL, name);
        }
      },
    [stockFiles]
  );

  const onSubmit = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: t("manageStockPartner.modal.confirmDelivery"),
        text: "คุณต้องการจัดส่งสินค้าใช่หรือไม่?",
        cancelText: "ยกเลิก",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(loadingActions.show({ message: "กำลังจัดส่งสินค้า..." }));
            deliverStockRefill(
              status === "stock_created" ? "deliver" : "deliver-success",
              {
                partnerId: partnerId,
                stockRefillId: stockId,
              }
            );
            setTimeout(() => {
              dispatch(alertAction.hide());
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: "จัดส่งสินค้าสำเร็จ",
                  type: "success",
                })
              );
              onCloseFn();
            }, 500);
          } catch (error) {
            setTimeout(() => {
              dispatch(alertAction.hide());
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: getErrorMessage(error),
                  type: "error",
                })
              );
            }, 500);
          }
        },
      })
    );
  }, [partnerId, stockId, status]);

  const onCloseFn = useCallback(() => {
    refetch();
  }, []);

  const file = useCallback(
    (
      documentType:
        | "salePickingSlip"
        | "partnerPickingSlip"
        | "partnerProofOfDelivery"
    ) => stockFiles.filter((row) => row.documentType === documentType),
    [stockFiles]
  );

  return (
    <TableCell colSpan={6} sx={{ background: "#F6FBFF" }}>
      <div className="flex flex-col gap-4 pt-4">
        <div className="flex justify-between items-center px-4">
          <span className="text-xl font-bold text-secondary">รายละเอียด</span>
          <div className="flex gap-4">
            {showButton && roleActionEdit && (
              <>
                <ButtonOutlinedCustom
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  title={t("manageStockPartner.uploadDocuments")}
                  startIcon={<ImportIcon />}
                  onClick={() => setOpenImportDocumentModal(true)}
                />
                <ButtonFillCustom
                  disabled={(
                    [
                      "accepted",
                      "rejected",
                      "waiting_for_acceptance",
                    ] as TStockRefill[]
                  ).includes(status)}
                  title={
                    status === "stock_created"
                      ? t("manageStockPartner.delivery")
                      : "จัดส่งสินค้าสำเร็จ"
                  }
                  onClick={onSubmit}
                />
              </>
            )}
          </div>
        </div>
        <div className="w-1/2">
          <Table sx={{ "& td,th": { border: 0 } }} size="small">
            <TableHead>
              <TableRow className="h-[50px]">
                <TableCell></TableCell>
                <TableCell sx={{ fontWeight: 700 }}>แบรนด์อาหารสัตว์</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>เบอร์อาหารสัตว์</TableCell>
                <TableCell sx={{ fontWeight: 700 }}>จำนวน</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                return (
                  <TableRow sx={{ height: "40px" }} key={index}>
                    <TableCell sx={{ fontWeight: 700 }}>{`${
                      index + 1
                    }.`}</TableCell>
                    <TableCell>{row.brandName.th}</TableCell>
                    <TableCell>{row.brandItemName.th}</TableCell>
                    <TableCell>{`${row.feedAmount} ${t(
                      `common.${row.feedUnit.toLowerCase()}` as any
                    )}`}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {/* Download Document Section */}
        {stockFiles.length > 0 && (
          <div className="flex flex-col px-4">
            <span className="text-xl font-bold text-secondary">เอกสาร</span>
            <div className="py-4">
              <span className="font-bold">ใบส่งของ</span>
              <div className="flex flex-col gap-4 mt-2">
                {file("salePickingSlip").map((row, idx) => (
                  <div key={idx}>
                    <DownloadLinkWithDelete
                      onDownload={handleDownloadByUrl(
                        partnerId,
                        stockId,
                        row.id,
                        row.fileName,
                        row.documentType
                      )}
                      hideDeleteBtn
                      name={row.fileName}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {/* Image Section */}
        {["success_shipping", "success_receive", "accepted"].includes(
          status
        ) && (
          <div className="flex flex-col px-4">
            <span className="text-xl font-bold text-secondary">รูปหลักฐาน</span>
            <div className="py-4 flex">
              <div className="w-1/2">
                <span className="font-bold">หลักฐานการจัดส่ง</span>
                <div className="flex gap-4 mt-2">
                  {file("partnerProofOfDelivery").map((row, idx) => (
                    <div key={idx}>
                      <ImagePreview
                        url={""}
                        useRealImage
                        onDownloadImage={() =>
                          handleGetBlob(
                            partnerId,
                            stockId,
                            row.id,
                            row.documentType
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-1/2">
                <span className="font-bold">ใบ Picking Slip</span>
                <div className="flex gap-4 mt-2">
                  {file("partnerPickingSlip").map((row, idx) => (
                    <div key={idx}>
                      <ImagePreview
                        url={""}
                        useRealImage
                        onDownloadImage={() =>
                          handleGetBlob(
                            partnerId,
                            stockId,
                            row.id,
                            row.documentType
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <PartnerImportDocumentModal
        open={openImportDocumentModal}
        onClose={() => setOpenImportDocumentModal(false)}
        partnerId={partnerId}
        stockId={stockId}
        refetch={refetch}
        initialFile={file("salePickingSlip")}
        onDownloadInitialFile={handleDownloadByUrl}
      />
    </TableCell>
  );
}
export default FillProductCellDetail;
