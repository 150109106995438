import { TableCell, TableRow } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlinedIcon } from "../../../../../assets/svg/DeleteOutlined";
import Switch from "../../../../../components/Switch";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";
import CreateArticleModal from "../../components/farmCommunity/Modal/CreateArticleModal";
import ViewArticleModal from "../../components/farmCommunity/Modal/ViewArticleModal";
import { SearchFarmCommunity } from "../../components/farmCommunity/SearchFarmCommunity";
import { TUseFarmCommunityFilter } from "../../hooks/farm-community/useFarmCommuFilter.hook";
import {
  IFarmCommunityTable,
  ListNewsAndArticles,
} from "../../types/farmCommunity";

type Props = {
  list: IFarmCommunityTable[];
  FarmCommunityStatusMap: any;
  handleToggleChangeStatus: (id: string) => void;
  handleDeleteArticle: (id: string) => void;
  searchFarmCommunity: TUseFarmCommunityFilter;
  handleFetch: () => void;
  listFarmCommunity: ListNewsAndArticles[];
  tabIndex: number;
};

const NewsAndArticles = ({
  list,
  FarmCommunityStatusMap,
  handleDeleteArticle,
  handleToggleChangeStatus,
  searchFarmCommunity,
  handleFetch,
  listFarmCommunity,
  tabIndex,
}: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.masterDataFarmCommunity === "edit";
  const [isOpenCreateArticle, setIsOpenCreateArticle] = useState(false);
  const [isOpenViewArticle, setIsOpenViewArticle] = useState(false);
  const [selectedIndex, setSelectIndex] = useState<number>(0);

  const headCells: ITableCustomHeadCell<keyof IFarmCommunityTable>[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "50px",
    },
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
    },
    {
      id: "header",
      numeric: false,
      disablePadding: false,
      label: t("farmCommunity.newsAndArticlePage.topic"),
      width: "300px",
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: t("farmCommunity.newsAndArticlePage.types"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: t("farmCommunity.newsAndArticlePage.lastUpdatedDate"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "50px",
    },
    {
      id: "updatedAt",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "0px",
    },
  ];

  const filterHeader = roleActionEdit
    ? headCells
    : headCells.toSpliced(0, 1).toSpliced(5, 2);

  return (
    <>
      <SearchFarmCommunity
        searchFarmCommunity={searchFarmCommunity}
        onSearch={handleFetch}
        tabIndex={tabIndex}
      />
      <div className="pt-6">
        <TableCustom
          order={searchFarmCommunity.direction}
          orderBy={"updatedAt"}
          itemsSelected={[]}
          rowsPerPage={searchFarmCommunity.limit}
          page={searchFarmCommunity.page}
          headerItems={filterHeader}
          rows={list}
          onChangePage={(value) => {
            searchFarmCommunity.setPage(value);
          }}
          onChangeOrder={(val) => searchFarmCommunity.setDirection(val)}
          onChangeOrderBy={(val) =>
            searchFarmCommunity.setOrderBy(val as keyof IFarmCommunityTable)
          }
          onSelectItem={() => {}}
          onSelectAllClick={() => {}}
          totalItemSize={searchFarmCommunity.count}
          currentPageItems={list.map((row) => row.id)}
          hideSelect
          rowChildren={list.map((row, index) => (
            <TableRow hover key={index}>
              {roleActionEdit && (
                <TableCell padding="normal">
                  <Switch
                    checked={row.isActive}
                    onChange={async () => {
                      handleToggleChangeStatus(row.id);
                    }}
                  />
                </TableCell>
              )}
              <TableCell padding="normal">
                {row.displayPriority ? (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={
                        row.type === "video" && row.displayPriority === 1
                      }
                      readOnly
                      style={{
                        appearance: "none",
                        width: "20px",
                        height: "20px",
                        cursor: "default",
                        backgroundColor: "#68C184",
                        borderRadius: "3px",
                        position: "relative",
                        border: "1px solid #E1E1E1",
                      }}
                    />
                    {row.type === "video" && row.displayPriority === 1 && (
                      <span
                        style={{
                          content: '""',
                          position: "absolute",
                          top: "40%",
                          left: "50%",
                          width: "6px",
                          height: "10px",
                          border: "solid white",
                          borderWidth: "0 2px 2px 0",
                          transform: "translate(-50%, -50%) rotate(45deg)",
                        }}
                      ></span>
                    )}
                    <span
                      style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        fontSize: "12px",
                        color: "white",
                        pointerEvents: "none",
                        fontWeight: "600",
                      }}
                    >
                      {row.type === "video" ? "" : row.displayPriority}
                    </span>
                  </div>
                ) : null}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                {row.header}
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                {row.type && (
                  <div className="flex flex-row w-full">
                    <ChipOrderStatus
                      bgColor={FarmCommunityStatusMap[row.type]["bgColor"]}
                      fontColor={FarmCommunityStatusMap[row.type].color}
                      label={FarmCommunityStatusMap[row.type].label}
                    />
                  </div>
                )}
              </TableCell>
              <TableCell align="left">{row.updatedAt}</TableCell>
              <TableCell
                align="left"
                sx={{
                  fontFamily: "IBM Plex Sans Thai",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "19px",
                }}
              >
                <ButtonOutlinedCustom
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  title={"ดูรายละเอียด"}
                  onClick={() => {
                    setSelectIndex(index);
                    setIsOpenViewArticle(true);
                  }}
                />
              </TableCell>
              {roleActionEdit && (
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  <ButtonFillCustom
                    btnBgColor="#68C184"
                    btnTextColor="#FFFFFF"
                    title={t("farmCommunity.newsAndArticlePage.edit.title")}
                    onClick={() => {
                      setIsOpenCreateArticle(true);
                      setSelectIndex(index);
                    }}
                  />
                </TableCell>
              )}
              {roleActionEdit && (
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleDeleteArticle(row.id)}
                  >
                    <DeleteOutlinedIcon />
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        />
      </div>
      <CreateArticleModal
        isOpen={isOpenCreateArticle}
        handleClose={(success) => {
          if (success) {
            handleFetch();
          }
          setIsOpenCreateArticle(false);
          setSelectIndex(0);
        }}
        data={listFarmCommunity[selectedIndex]}
      />
      <ViewArticleModal
        data={listFarmCommunity[selectedIndex]}
        isOpen={isOpenViewArticle}
        handleClose={() => setIsOpenViewArticle(false)}
      />
    </>
  );
};

export default NewsAndArticles;
