import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SuccessSnackBar from "../../../admin/components/SnackBar/SuccessSnackBar";
import AppBarFS from "../../../components/app-bar/AppBar";
import CustomUploadImage from "../../../components/common/uploadImage/CustomUploadImage";
import delay from "../../../utils/delay";
import ConfirmUploadDocumentsModal from "./ConfirmUploadDocumentsModal";

type Props = {};

function StockUploadDocument({}: Props) {
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = React.useState<string[]>([]);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const { t } = useTranslation();
  const [openConfirmSnackBar, setOpenConfirmSnackBar] = React.useState(false);

  return (
    <>
      <div className="pb-4 h-screen flex flex-col  bg-[#ffff] overflow-scroll">
        <div className="py-4 rounded-b-[40px] bg-white">
          <AppBarFS
            onIconPress={() => navigate(-1)}
            title={t("PartnerOrder.UploadImageEvidenceDelivery")}
          />
        </div>
        <div className="flex flex-col w-full mx-3">
          <div className=" text-tertiary font-semibold ml-1 capitalize text-lg mt-2">
            {t("PartnerOrder.UploadImageEvidenceDelivery")} (Optional)
          </div>
          <div className="h-full">
            <CustomUploadImage
              onChange={() => {}}
              imageUrl={imageUrl}
              handleRemoveImage={() => {}}
              t={t}
              // errorMessage={formErrorMessage(formik, "picturesOfReceipt")}
            />
          </div>
          <span className="text-[#D32F2F]">
            {/* {formErrorMessage(formik, "picturesOfReceipt")} */}
          </span>
        </div>
        <div className="flex flex-col w-full mx-3 mb-16">
          <div className=" text-tertiary font-semibold ml-1 capitalize text-lg mt-2">
            {t("PartnerOrder.UploadPickingSlip")}
          </div>
          <div className="h-full">
            <CustomUploadImage
              onChange={() => {}}
              imageUrl={imageUrl}
              handleRemoveImage={() => {}}
              t={t}
              // errorMessage={formErrorMessage(formik, "picturesOfReceipt")}
            />
          </div>
          <span className="text-[#D32F2F]">
            {/* {formErrorMessage(formik, "picturesOfReceipt")} */}
          </span>
        </div>
        <div className="mx-3">
          <Button
            size="large"
            variant="contained"
            onClick={() => setIsOpenModal(true)}
            sx={{
              color: "#fffff",
              borderColor: "#68C184",
              backgroundColor: "#68C184",
              fontWeight: "600",
              width: "100%",
              marginBottom: "8px",
              ":hover": {
                backgroundColor: "#68C184",
              },
              ":active": {
                backgroundColor: "#68C184",
              },
            }}
          >
            {t("MoneyDebtAndCollection.Confirm")}
          </Button>
          <Button
            size="large"
            variant="outlined"
            // onClick={() => navigate("/contact-seller")}
            sx={{
              color: "#68C184",
              borderColor: "#68C184",
              backgroundColor: "#ffffff",
              fontWeight: "600",
              width: "100%",
            }}
          >
            {t("sellingReport.modalInfo.btnClose")}
          </Button>
        </div>
      </div>
      <ConfirmUploadDocumentsModal
        onOpenModal={isOpenModal}
        onCloseModal={async (isSuccess) => {
          if (isSuccess) {
            setIsOpenModal(false);
            setOpenConfirmSnackBar(true);
            await delay(2000);
            navigate(-1);
          }
          setIsOpenModal(false);
        }}
      />
      <SuccessSnackBar
        openSnackbar={openConfirmSnackBar}
        handleCloseSnackbar={() => {
          setOpenConfirmSnackBar(false);
        }}
        snackbarText={t("OrderStock.EditEvidentSuccess")}
      />
    </>
  );
}

export default StockUploadDocument;
