import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../components/app-bar/AppBar";

import { useTranslation } from "react-i18next";
import { TBaseGetListPartnerStock } from "../../../types/partner/stock.type";
import FilterOrder from "../../feed-order-and-delivery/my-order/my-order-component/filter/FilterOrder";
import CardPartnerOrder from "../stock-component/CardPartnerOrder";

export default function UpcomingStock() {
  const navigate = useNavigate();
  const { stock } = useLoaderData() as {
    stock: TBaseGetListPartnerStock;
  };
  const { t } = useTranslation();
  return (
    <div className="h-screen flex flex-col bg-[#ffff] overflow-scroll">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate("/stock")}
          title={t("OrderStock.LandingPage")}
        />
      </div>
      <FilterOrder
        orderBy={"asc"}
        setOrderBy={() => {}}
        title={t("OrderStock.AllStock")}
      />
      {stock.stockRefills.map((stock, key) => {
        return (
          <CardPartnerOrder
            key={key}
            onMenuClick={() => navigate("/stock/upcoming-stock/" + stock.id)}
            stock={stock}
            displayButton={true}
          />
        );
      })}
    </div>
  );
}
