import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Dialog, DialogContent, IconButton, SvgIcon } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { memo, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../../../redux/store";
import { createdOrder } from "../../../../../../../services/ClaimOrder.service";
import { IDataClaimOrder } from "../../../../../../../types/ClaimOrder.type";
import getErrorMessage from "../../../../../../../utils/getErrorMessage";
import { tw } from "../../../../../../../utils/tw";
import { CalendarSvg } from "../../../../../../assets/svg/Calendar.svg";
import CrossIcon from "../../../../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../../../components/Button/ButtonOutlinedCustom";
import DatePickerPopover from "../../../../../../components/Input/DatePickerPopover/DatePickerPopover";
import DateRangePickerPopover from "../../../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import TextInputCustom from "../../../../../../components/Input/TextInputCustom";
import { FILTER_DATE_FORMAT } from "../../../../../sale-order/components/SaleOrderSearchFilter";
import { useTranslation } from "react-i18next";

interface ICreateModal {
  isOpen: boolean;
  onCloseFn: () => void;
  refetchListClaimOrderProgress?: () => void;
  ids?: string;
  dataEdit?: IDataClaimOrder;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[400px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
};

const CreateOrder = (props: ICreateModal) => {
  const { t } = useTranslation();
  const { isOpen, onCloseFn, dataEdit, refetchListClaimOrderProgress, ids } =
    props;
  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();
  const [shippingDate, setShippingDate] = useState("");

  const refCalendarShippingDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);
  const handleSelectShippingDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    setShippingDate(formattedDate);
  };

  const createOrderMutation = useMutation({
    mutationFn: createdOrder,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["list_claim_order_progress"],
      });
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "สร้างออเดอร์สำเร็จ",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onSubmit = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการสร้างออเดอร์",
        text: "คุณต้องการสร้างออเดอร์นี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(loadingActions.show({ message: "กำลังสร้างออเดอร์..." }));
          createOrderMutation.mutate({
            orderClaimItemId: ids ? ids : "",
            shippingDate: dayjs(shippingDate, FILTER_DATE_FORMAT)
              .startOf("day")
              .toDate(),
          });
        },
      })
    );
  }, [shippingDate]);

  const onClose = useCallback(() => {
    onCloseFn();
    setShippingDate("");
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent className="w-[400px]">
        <div className={classes.titleContainer}>
          <p className={classes.title}>สร้างออเดอร์</p>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className="flex flex-col pb-6 gap-6">
          {" "}
          <div className="w-full">
            <span className="font-normal text-[14px] mb-2">
              วันที่ต้องการรับสินค้า
            </span>
            <div className="pt-2">
              <TextInputCustom
                placeholder="เลือก"
                value={shippingDate}
                InputProps={{
                  endAdornment:
                    shippingDate !== "" ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {}}
                      />
                    ) : (
                      <SvgIcon
                        sx={{
                          fontSize: "24px !important",
                          color: "#646464",
                        }}
                        fontSize="small"
                      >
                        <CalendarSvg />
                      </SvgIcon>
                    ),
                }}
                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  e.stopPropagation();
                  refCalendarShippingDate.current?.onOpen(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex justify-end gap-[10px]">
          <ButtonOutlinedCustom
            onClick={onClose}
            title="ยกเลิก"
            btnTextColor="#68C184"
            btnBgColor="#ffffff"
            btnBorderColor="#68C184"
          />
          <ButtonFillCustom
            onClick={onSubmit}
            title="ยืนยัน"
            btnTextColor="#ffffff"
            btnBgColor="#68C184"
          />
        </div>
      </DialogContent>
      <DatePickerPopover
        ref={refCalendarShippingDate}
        id={"date-picker-shipping-date"}
        date={shippingDate}
        handleCalenderSave={handleSelectShippingDate}
      />
    </Dialog>
  );
};

export default memo(CreateOrder);
