import {
  Link,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { thousandComma } from "../../../../../utils/common/string";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import PerformanceSvg from "../../../../assets/svg/PerformanceSvg";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import { TableCellHeaderCustom } from "../../../master-data/pages/credit/Modal/TableCellCustom";
import { PigType } from "../../enums/pig.enum";
import {
  getExportExcelDailyLog,
  getPerformanceInformation,
} from "../../services/farmDetail.service";
import {
  IGetPerformanceInfo,
  TExportExcelLogs,
} from "../../types/performanceInformation.type";
import { useTranslation } from "react-i18next";

type Props = {
  farmId?: string;
};

type TPerformanceStatus = "moved_out" | "active";

const PerformanceInformation = ({ farmId }: Props) => {
  const { t } = useTranslation();
  const [performance, setPerformance] = useState<IGetPerformanceInfo | null>();

  const tableCellHeader = [
    {
      value: t("farmDetail.performance.houseName"),
      width: "200px",
    },
    {
      value: t("farmDetail.performance.farmName"),
      width: "200px",
    },
    {
      value: t("farmDetail.performance.houseStatus"),
      width: "200px",
    },
    {
      value: t("farmDetail.performance.pigType"),
      width: "250px",
    },
    {
      value: t("farmDetail.performance.numberOfPigsAdmitted"),
      width: "180px",
    },
    {
      value: t("farmDetail.performance.currentNumberOfPigs"),
      width: "180px",
    },
    {
      value: t("farmDetail.performance.fcr"),
      width: "100px",
    },
    {
      value: t("farmDetail.performance.adg"),
      width: "100px",
    },
    {
      value: t("farmDetail.performance.fcg"),
      width: "100px",
    },
    {
      value: t("farmDetail.performance.death"),
      width: "100px",
    },
    {
      value: t("farmDetail.performance.totalSellingPrice"),
      width: "200px",
    },
    {
      value: t("farmDetail.performance.targetedDailyLogDays"),
      width: "200px",
    },
    {
      value: t("farmDetail.performance.actualDailyLogDays"),
      width: "200px",
    },
    {
      value: t("farmDetail.performance.percentAchieve"),
      width: "100px",
    },
    {
      value: "",
      width: "100px",
    },
  ];

  const OrderStatusMap: Record<
    TPerformanceStatus,
    { color: string; bgColor: string; label: string }
  > = {
    moved_out: {
      color: "#3F3748",
      bgColor: "#E4E4E4",
      label: "Moved out",
    },
    active: {
      color: "#409261",
      bgColor: "#E9FFEF",
      label: "Active",
    },
  };

  const handleFetchPerformance = async () => {
    const data = await getPerformanceInformation(farmId || "");
    setPerformance(data);
  };

  const handleExportExcel = async (houseId: string) => {
    const data = await getExportExcelDailyLog(farmId!, houseId);
    const dataExcel = data?.rows
      .map((item) => {
        return item?.houses.map((house) => {
          return (house?.dailyLogs || []).map((log) => {
            return {
              "Farm name": item.farmName,
              "House name": house.name,
              ...log,
            };
          });
        });
      })
      .flat(2);

    const fileName = `daily_log_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(dataExcel || [], fileName, sheetName);
  };

  useEffect(() => {
    handleFetchPerformance();
  }, []);
  return (
    <>
      <div className="flex gap-2 text-center items-center mt-6">
        <PerformanceSvg />
        <p className="text-lg font-bold text-[#3777BC] font-sans">
          {t("farmDetail.performance.title")}
        </p>
      </div>
      <div className="flex w-full">
        <TableContainer
          sx={{
            scrollBehavior: "smooth",
            overflow: "scroll",
            borderRadius: "6px",
            boxShadow: "none",
            border: "1px solid #E1E1E1",
            borderBottom: "none",
            marginTop: "32px",
          }}
        >
          <Table
            aria-labelledby="table-show-data"
            size={"medium"}
            sx={{
              tableLayout: "fixed",
            }}
          >
            <TableHead
              sx={{
                width: "100%",
                bgcolor: "#FBFBFB",
                borderBottom: "1px solid #E1E1E1",
              }}
            >
              <TableRow>
                {tableCellHeader.map((cell, index) => (
                  <TableCellHeaderCustom
                    key={index}
                    value={cell.value}
                    width={cell.width}
                  />
                ))}
              </TableRow>
            </TableHead>
            {performance?.rows.map((performance, index) => (
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0px 16px",
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.name}
                </TableCell>
                <TableCell
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.farmName}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  <div className="flex flex-row w-full">
                    <ChipOrderStatus
                      bgColor={
                        OrderStatusMap[
                          performance.houseStatus as TPerformanceStatus
                        ].bgColor
                      }
                      fontColor={
                        OrderStatusMap[
                          performance.houseStatus as TPerformanceStatus
                        ].color
                      }
                      label={
                        OrderStatusMap[
                          performance.houseStatus as TPerformanceStatus
                        ].label
                      }
                    />
                  </div>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.pigTypes
                    .map((pig) => PigType[pig as keyof typeof PigType])
                    .join(" , ") || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.totalSellingPrice || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.currentPigAmount || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.fcr || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.adg || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.fcg || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.deathPercentage || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {thousandComma(performance.totalSellingPrice, 2) +
                    ` ${t("common.bath")}` || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.targetDailyLogDays || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.actualDailyLogDays || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  {performance.achievePercentage || "-"}
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    fontFamily: "IBM Plex Sans Thai",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "19px",
                  }}
                >
                  <Link
                    onClick={() => {
                      handleExportExcel(performance.id);
                    }}
                    component="button"
                    variant="body2"
                    sx={{
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                  >
                    {t("farmDetail.performance.dailyLog")}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default PerformanceInformation;
