import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { DeleteOutlinedIcon } from "../../../../../../assets/svg/DeleteOutlined";
import ListOfFile from "../../../../../../components/common/File/ListOfFile";
import ListOfUploadedFile2 from "../../../../../../components/common/File/ListOfUploadedFile2";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import { commonDownloadFile } from "../../../../../../services/CommonApiService";
import {
  handleDownloadObject,
  handleUploadFileToAzure,
} from "../../../../../../utils/downloadFile";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import PlusIconSVG from "../../../../../assets/svg/PlusIcon.svg";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import {
  DragHandle,
  SortableItem,
} from "../../../../../components/DragAndDrop/SortableItem";
import { SortableList } from "../../../../../components/DragAndDrop/SortableList";
import DragAndDropUploadImage from "../../../../../components/Input/DragAndDropUploadImage";
import RHFTextAreaCustom from "../../../../../components/Input/RHFTextAreaCustom";
import RHFTextInputCustom from "../../../../../components/Input/RHFTextInputCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import RHFSelectCustom from "../../../../../components/Select/RHFSelectCustom";
import {
  createUserManual,
  editUserManual,
  getListUserManual,
  getUserManualUploadUrl,
} from "../../../services/farmCommunity.service";
import {
  ArticleFile,
  IGetDetailUserManualRow,
  IUserManualListRow,
  TCreateUserManual,
  TUserManual,
} from "../../../types/farmCommunity";
import { farmCommunityTypeOptions } from "../SearchFarmCommunity";
import { useTranslation } from "react-i18next";

type Props = {
  data?: IGetDetailUserManualRow;
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
};

export type TFormUserManual = {
  manualName: string;
  manualId: string;
  topicName: string;
  type: string;
  manualPages: TUserManualDetail[];
};

export type TUserManualDetail = {
  id: string;
  pageFile: File[];
  description: string;
  uploadedFile: ArticleFile[];
  pageNumber: number;
};

export interface TUserManualFile {
  fileId: string;
  fileName: string;
}
export interface IUserManualDoc {
  file: TUserManualFile[];
}

const CreateUserManual = ({ isOpen, handleClose, data }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validateSchema = yup.object().shape({
    type: yup.string().required("กรุณากรอก"),
    topicName: yup.string().required("กรุณากรอก"),
    manualId: yup.string().required("กรุณากรอก"),
  });

  const submitAPI = async (value: TFormUserManual) => {
    const manualPages = value.manualPages;
    const manualPageMapped: TUserManual[] = [];
    for (const page of manualPages) {
      if (page.pageFile.length) {
        const response = await getUserManualUploadUrl();
        if (response?.blob.blobUrl) {
          await handleUploadFileToAzure(
            response?.blob?.blobUrl,
            page.pageFile[0]
          );
          manualPageMapped.push({
            id: page.id === page.pageNumber?.toString() ? undefined : page.id,
            description: page.description,
            pageNumber: page.pageNumber,
            pageFile: [
              {
                fileId: response.blob.fileId || "",
                fileName: page.pageFile[0].name,
              },
            ],
          });
        }
      } else {
        manualPageMapped.push({
          id: page.id,
          description: page.description,
          pageNumber: page.pageNumber,
          pageFile: [
            {
              fileId: page.uploadedFile[0].id || "",
              fileName: page.uploadedFile[0].fileOriginalName,
            },
          ],
        });
      }
    }

    const body: TCreateUserManual = {
      manualName: value.manualName,
      manualId: value.manualId !== "99" ? value.manualId : "",
      topicName: value.topicName,
      type: value.type,
      manualPages: manualPageMapped,
    };

    const editBody: any = {
      id: data ? data.id : undefined,
      manualName: value.manualName,
      manualId: value.manualId !== "99" ? value.manualId : "",
      topicName: value.topicName,
      type: value.type,
      manualPages: manualPageMapped,
    };

    if (data) {
      await editUserManual(data.manual.id, data.id, editBody);
    } else {
      await createUserManual(body);
    }
  };

  const handleSubmit = (value: TFormUserManual) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));

            await submitAPI(value);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            console.log(error);
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  const methods = useForm<TFormUserManual>({
    defaultValues: {
      manualName: "",
      manualId: "",
      topicName: "",
      type: "",
      manualPages: [
        {
          id: "",
          description: "",
          pageFile: [],
          uploadedFile: [],
        },
      ],
    },
    resolver: yupResolver(validateSchema as any),
  });

  const { append, remove } = useFieldArray({
    name: "manualPages",
    control: methods.control,
  });

  const [categoryNameSelect, setCategoryNameSelect] = useState<
    IUserManualListRow[]
  >([]);

  const selectCategory = [
    ...(categoryNameSelect || []),
    { id: "99", name: "อื่นๆ" },
  ];

  const handleChange = (newOrder: TUserManualDetail[]) => {
    methods.setValue("manualPages", newOrder);
  };

  const handleUploadFiles = (index: number) => (files: File[]) =>
    methods.setValue(`manualPages.${index}.pageFile`, files);

  const handleDeleteFile = async (index: number) => {
    methods.setValue(`manualPages.${index}.pageFile`, []);
  };

  const handleDeleteUploadFile = async (index: number) => {
    methods.setValue(`manualPages.${index}.uploadedFile`, []);
  };

  const onDownload = async (row: { id: string; fileOriginalName: string }) => {
    await commonDownloadFile(
      `/farm-community/manual/download/${row.id}`,
      row.fileOriginalName
    );
  };

  useEffect(() => {
    if (isOpen && data) {
      methods.reset({
        manualId: data?.manual.id,
        manualName: "",
        topicName: data?.topicName,
        type: data?.type,
        manualPages: (data?.articlePages || []).map((category) => {
          return {
            id: category.id,
            pageNumber: category.pageNumber,
            description: category.description,
            pageFile: [],
            uploadedFile: category.pageFile,
          };
        }),
      });
    } else {
      methods.reset();
    }
  }, [isOpen, data]);

  useEffect(() => {
    if (isOpen) {
      const fetch = async () => {
        const data = await getListUserManual();
        if (data) {
          setCategoryNameSelect(data.rows);
        }
      };
      fetch();
    }
  }, [isOpen]);

  return (
    <>
      <AdminModal
        label={t("farmCommunity.userManualPage.addUserManual.title")}
        isOpen={isOpen}
        size={"md"}
        handleClose={() => handleClose(false)}
        children={
          <>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <div className="my-4">
                  <p className="text-[14px] mb-3 font-sans">
                    {t(
                      "farmCommunity.userManualPage.addUserManual.categoryName"
                    )}{" "}
                    <span className="text-error-300">*</span>
                  </p>
                  <RHFSelectCustom
                    value={methods.watch("manualId")}
                    controlName="manualId"
                    placeholder="เลือก"
                    menuItems={(selectCategory || []).map((row) => (
                      <MenuItem key={row.id} value={row.id}>
                        {row.name}
                      </MenuItem>
                    ))}
                    renderValueArray={(selectCategory || []).map((row) => ({
                      value: row.id,
                      label: row.name,
                    }))}
                    error={Boolean(methods.formState.errors.type)}
                    helperText={methods.formState.errors.type?.message || ""}
                    onChange={(e) =>
                      methods.setValue("manualId", e.target.value as string)
                    }
                  />
                </div>
                <div className="my-4">
                  <p className="text-[14px] mb-3 font-sans">
                    {t(
                      "farmCommunity.userManualPage.addUserManual.categoryName"
                    )}{" "}
                    <span className="text-error-300">*</span>
                  </p>
                  <RHFTextInputCustom
                    controlName="manualName"
                    disabled={methods.watch("manualId") !== "99"}
                    error={Boolean(methods.formState.errors.manualId)}
                    helperText={
                      methods.formState.errors.manualId?.message || ""
                    }
                  />
                </div>
                <div className="my-4">
                  <p className="text-[14px] mb-3 font-sans">
                    {t("farmCommunity.userManualPage.addUserManual.topic")}{" "}
                    <span className="text-error-300">*</span>
                  </p>
                  <RHFTextInputCustom
                    controlName="topicName"
                    error={Boolean(methods.formState.errors.topicName)}
                    helperText={
                      methods.formState.errors.topicName?.message || ""
                    }
                  />
                </div>
                <div className="my-4">
                  <p className="text-[14px] mb-3 font-sans">
                    {t("farmCommunity.userManualPage.addUserManual.type")}{" "}
                    <span className="text-error-300">*</span>
                  </p>
                  <RHFSelectCustom
                    value={methods.watch("type")}
                    controlName="type"
                    placeholder="เลือก"
                    menuItems={(farmCommunityTypeOptions || []).map((row) => (
                      <MenuItem key={row.value} value={row.value}>
                        {row.label}
                      </MenuItem>
                    ))}
                    renderValueArray={(farmCommunityTypeOptions || []).map(
                      (row) => ({
                        value: row.value,
                        label: row.label,
                      })
                    )}
                    error={Boolean(methods.formState.errors.type)}
                    helperText={methods.formState.errors.type?.message || ""}
                    onChange={(e) =>
                      methods.setValue("type", e.target.value as string)
                    }
                  />
                </div>
                <div className="flex justify-between py-5">
                  <p className="text-[#3777BC] text-lg font-sans font-bold">
                    {t(
                      "farmCommunity.userManualPage.addUserManual.pageDetails"
                    )}
                  </p>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      append({
                        id: (
                          methods.watch("manualPages").length + 1
                        ).toString(),
                        pageNumber: methods.watch("manualPages").length + 1,
                        pageFile: [],
                        uploadedFile: [],
                        description: "",
                      })
                    }
                  >
                    <PlusIconSVG />
                  </div>
                </div>
                <SortableList
                  items={methods.getValues("manualPages")}
                  onChange={handleChange}
                  renderItem={(item, index) => (
                    <SortableItem id={item.id}>
                      <Accordion
                        key={item.id}
                        sx={{
                          margin: "0px !important",
                          padding: "0px !important",
                          boxShadow: "none",
                          width: "100%",
                          "&:before": {
                            display: "none",
                          },
                          "&.Mui-expanded": {
                            margin: "0 !important",
                          },
                        }}
                        defaultExpanded
                      >
                        <AccordionSummary
                          style={{ padding: 0, margin: "0px !important" }}
                          expandIcon={
                            <ExpandMoreIcon style={{ color: "#68C184" }} />
                          }
                          sx={{
                            fontSize: "16px !important",
                            fontWeight: "700",
                            color: "#000000",
                            margin: "0 !important",
                            "&.Mui-expanded": {
                              margin: "0 !important",
                            },
                            padding: "0 !important",
                          }}
                        >
                          <div className="flex items-center">
                            <DragHandle />
                            <span className="font-sans">{` หน้า ${
                              index + 1
                            }`}</span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            backgroundColor: "#FFFFFF",
                            padding: "0 !important",
                          }}
                        >
                          {methods.watch("type") && (
                            <div className="flex flex-col gap-2">
                              <label
                                className="font-semibold text-[14px] font-sans"
                                htmlFor="files"
                              >
                                {methods.watch("type") === "article"
                                  ? t(
                                      "farmCommunity.userManualPage.addUserManual.uploadImage"
                                    )
                                  : t(
                                      "farmCommunity.userManualPage.addUserManual.uploadVideo"
                                    )}
                              </label>
                              {item.pageFile &&
                                methods.watch("manualPages")[index]
                                  .pageFile && (
                                  <ListOfFile
                                    files={
                                      methods.watch("manualPages")[index]
                                        .pageFile
                                    }
                                    onDownload={handleDownloadObject}
                                    onDelete={() => handleDeleteFile(index)}
                                  />
                                )}
                              {item.uploadedFile &&
                                methods.watch("manualPages")[index]
                                  .uploadedFile && (
                                  <ListOfUploadedFile2
                                    files={
                                      methods.watch("manualPages")[index]
                                        .uploadedFile
                                    }
                                    fileKey="fileOriginalName"
                                    onDownload={(row) => onDownload(row)}
                                    hideDeleteBtn={false}
                                    onDelete={() =>
                                      handleDeleteUploadFile(index)
                                    }
                                  />
                                )}
                              {methods.watch("type") === "article" ? (
                                <DragAndDropUploadImage
                                  onUpload={handleUploadFiles(index)}
                                />
                              ) : (
                                <DragAndDropUploadImage
                                  onUpload={handleUploadFiles(index)}
                                  type={{
                                    "video/mp4": [".mp4"],
                                    "video/x-msvideo": [".avi"],
                                    "video/x-flv": [".flv"],
                                    "video/x-matroska": [".mkv"],
                                    "video/ogg": [".ogv"],
                                    "video/quicktime": [".mov"],
                                    "video/x-ms-wmv": [".wmv"],
                                  }}
                                />
                              )}
                            </div>
                          )}
                          <div className="pt-6 flex flex-col mb-4">
                            <span className="font-sans font-medium text-[14px] leading-5 text-[#191919] pb-2">
                              {t(
                                "farmCommunity.userManualPage.addUserManual.description"
                              )}
                            </span>
                            <RHFTextAreaCustom
                              placeholder="กรอก"
                              controlName={`manualPages.${index}.description`}
                            />
                            <div className="w-full flex justify-end pt-[2px]">
                              <span className="font-normal text-[12px] text-[#979797]">
                                (maximum 500 characters)
                              </span>
                            </div>
                          </div>
                          <div
                            className="flex justify-end cursor-pointer mb-4"
                            onClick={() => remove(index)}
                          >
                            <DeleteOutlinedIcon />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </SortableItem>
                  )}
                />
                <div className="flex justify-end mt-4">
                  <ButtonFillCustom title={"บันทึก"} type="submit" />
                </div>
              </form>
            </FormProvider>
          </>
        }
      />
    </>
  );
};

export default CreateUserManual;
