import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { BaseModal } from "../../feed-order-and-delivery/order-feed/OrderFeedBaseModal";

type TConfirmUploadDocumentsModalProps = {
  onOpenModal: boolean;
  onCloseModal: (isSuccess: boolean) => void;
};

const ConfirmUploadDocumentsModal = ({
  onOpenModal,
  onCloseModal,
}: TConfirmUploadDocumentsModalProps) => {
  const { t } = useTranslation();
  return (
    <BaseModal
      isOpen={onOpenModal}
      handleClose={() => onCloseModal(false)}
      iconCloseModal={true}
    >
      <div className="flex  flex-col text-center">
        <p className="text-[24px] font-medium items-center text-warning mb-4">
          {`${t("PartnerOrder.ConfirmTitleOne")}\n${t(
            "PartnerOrder.ConfirmTitleTwo"
          )}`}
        </p>
        <p className="text-[16px]">
          {`${t("PartnerOrder.ConfirmTextOne")}\n${t(
            "PartnerOrder.ConfirmTextTwo"
          )}\n${t("PartnerOrder.ConfirmTextThree")}`}
        </p>

        <Button
          size="large"
          variant="outlined"
          sx={{
            marginTop: "64px",
            color: "#68C184",
            borderColor: "#68C184",
            width: "100%",
            ":hover": {
              border: "1px solid #68C184",
            },
            ":active": {
              border: "1px solid #68C184",
            },
          }}
          onClick={() => {
            onCloseModal(false);
          }}
        >
          {t("PartnerOrder.Back")}
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            onCloseModal(true);
          }}
          sx={{
            marginTop: "16px",
            color: "#ffff",
            bgcolor: "#68C184",
            width: "100%",
            ":hover": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
            ":active": {
              color: "#FFFFFF",
              border: "1px solid #68C184",
              bgcolor: "#68C184",
            },
          }}
        >
          {t("FinancialSupport.Confirm")}
        </Button>
      </div>
    </BaseModal>
  );
};

export default ConfirmUploadDocumentsModal;
