import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  MenuItem,
  Modal,
  SxProps,
  Theme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TRejectType } from "../../../types/SaleOrder.type";
import ButtonFillCustom from "../Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../Button/ButtonOutlinedCustom";
import TextAreaCustom from "../Input/TextAreaCustom";
import SelectAdminCustom from "../Select/Select";
import { useTranslation } from "react-i18next";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "548px",
  bgcolor: "background.paper",
  boxShadow: 12,
  padding: "24px",
  borderRadius: "12px",
};

const RejectTypeItem: {
  id: TRejectType;
  label: string;
}[] = [
  {
    id: "overdue",
    label: "ค้างชำระ",
  },
  {
    id: "over_credit_limit",
    label: "เกินวงเงิน",
  },
  {
    id: "cancel_order",
    label: "ยกเลิกออเดอร์",
  },
  {
    id: "other",
    label: "อื่น ๆ",
  },
];

interface IRejectOrderCreditModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (option: TRejectType, reason: string) => void;
}
function RejectOrderCreditModal({
  open,
  onClose,
  onSubmit,
}: IRejectOrderCreditModalProps) {
  const { t } = useTranslation();
  const [typeSelected, setTypeSelected] = React.useState<TRejectType>("");
  const [reason, setReason] = useState("");
  useEffect(() => {
    if (open) {
      setReason("");
      setTypeSelected("");
    }
  }, [open]);
  return (
    <Modal
      aria-labelledby="reject-credit-modal-title"
      aria-describedby="reject-credit-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="w-full h-full">
            <div className="flex flex-row justify-between items-center">
              <span className="font-sans font-bold text-[1rem] text-[#191919]">
                {t("saleOrder.creditHoldOrdersTab.modal.rejectCreditHoldOrder")}
              </span>
              <IconButton
                aria-label="close-modal"
                size="small"
                onClick={onClose}
              >
                <CloseRoundedIcon
                  sx={{
                    fontSize: "24px",
                    color: "#191919",
                  }}
                />
              </IconButton>
            </div>
            <div className="pt-6 flex flex-col">
              <span className="font-sans font-medium text-[14px] leading-5 text-[#191919] pb-2">
                {t("saleOrder.creditHoldOrdersTab.modal.reason")}
              </span>
              <SelectAdminCustom
                value={typeSelected}
                renderValueArray={RejectTypeItem.map((row) => ({
                  value: row.id,
                  label: row.label,
                }))}
                placeholder="เลือก"
                onChange={(e) => setTypeSelected(e.target.value as TRejectType)}
                menuItems={RejectTypeItem.map((item, index) => (
                  <MenuItem key={`reject-item-${index}`} value={item.id}>
                    {item.label}
                  </MenuItem>
                ))}
              />
            </div>
            {typeSelected === "other" && (
              <div className="pt-6 flex flex-col">
                <span className="font-sans font-medium text-[14px] leading-5 text-[#191919] pb-2">
                  {t("saleOrder.creditHoldOrdersTab.modal.description")}
                </span>
                <TextAreaCustom
                  placeholder="กรอก"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
                <div className="w-full flex justify-end pt-[2px]">
                  <span className="font-normal text-[12px] text-[#979797]">
                    (maximum 500 characters)
                  </span>
                </div>
              </div>
            )}
            <div className="flex w-full justify-end gap-2 pt-6">
              <ButtonOutlinedCustom
                title={t("common.button.cancel")}
                btnBorderColor="#68C184"
                btnTextColor="#68C184"
                onClick={onClose}
              />
              <ButtonFillCustom
                title={t("common.button.confirm")}
                disabled={
                  !typeSelected || (typeSelected === "other" && !reason)
                }
                onClick={() => onSubmit(typeSelected, reason)}
              />
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}

export default RejectOrderCreditModal;
