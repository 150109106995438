import { useState } from "react";
import { getSaleTarget } from "../services/saleTarget.service";
import { ISaleTargetTable, ITarget } from "../types/SaleTargetTable.type";
import { TUseSaleTargetHook } from "./useSaleTarget.hook";

export const useSaleTargetTableHook = (
  saleTargetHook: TUseSaleTargetHook,
  tabName: string
) => {
  const [list, setList] = useState<ITarget[]>([]);

  const handleChangeOrder = (order: keyof ISaleTargetTable) => {
    saleTargetHook.setOrderBy(order);
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("targetType", tabName);
    setList([]);
    const data = await getSaleTarget(searchParams);
    if (data) {
      setList(data.rows);
    }
  };

  return {
    list,
    handleFetch,
    handleChangeOrder,
  };
};
