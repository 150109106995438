import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ContractActive from "../../assets/svg/ContractActive";
import List from "../../assets/svg/List";
import OrderHistorySVG from "../../assets/svg/OrderHistory";
import AppBarFS from "../../components/app-bar/AppBar";
import DashboardInfoCard from "../../components/common/DashboardInfoCard/DashboardInfoCard";

const TrackDebt = () => {
  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner");
  }, []);

  const handleClickToNewAssignment = useCallback(() => {
    navigate("/partner/track-debt/new-assignment");
  }, []);

  const handleClickToAcceptedAssignment = useCallback(() => {
    navigate("/partner/track-debt/accepted-assignment");
  }, []);

  const handleClickToHistoryAssignment = useCallback(() => {
    navigate("/partner/track-debt/history-assignment");
  }, []);

  const { t } = useTranslation();

  return (
    <div>
      <div className="w-full h-full">
        <div className="h-[120px] flex items-center">
          <AppBarFS
            title={t("MoneyDebtAndCollection.LandingPage")}
            onIconPress={handleBack}
          />
        </div>
        <div className="flex flex-col w-full h-full gap-[16px] px-[16px]">
          <DashboardInfoCard
            title={t("MoneyDebtAndCollection.NewAssignment")}
            subTitle={"ใบงานใหม่ทั้งหมด"}
            icon={<List />}
            removeBgIcon={true}
            bgColor="#F0FDF4"
            onPress={handleClickToNewAssignment}
          />
          <DashboardInfoCard
            title={t("MoneyDebtAndCollection.AcceptedAssignment")}
            subTitle={t("MoneyDebtAndCollection.AcceptedAssignment")}
            icon={<ContractActive />}
            removeBgIcon={true}
            bgColor="#EEF2FF"
            onPress={handleClickToAcceptedAssignment}
          />
          <DashboardInfoCard
            title={t("MoneyDebtAndCollection.AssignmentHistory")}
            subTitle={t("MoneyDebtAndCollection.AllAssignmentHistory")}
            icon={<OrderHistorySVG />}
            removeBgIcon={true}
            bgColor="#D9EBFEB2"
            onPress={handleClickToHistoryAssignment}
          />
        </div>
      </div>
    </div>
  );
};

export default TrackDebt;
