import { memo, useCallback } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import { tw } from "../../../../utils/tw";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import { Dropdown } from "../../group-area/views/Dropdown";
import { useFoodModalController } from "./controller";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import Table from "./Table";
import AddModal from "./AddModal";
import EditTableModal from "./EditTableModal";
import { useAdminAuth } from "../../../components/AdminAuth/AdminAuthProvider";

interface IFoodModalProps {
  isOpen: boolean;
  onCloseFn: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
};

const FoodModal = (props: IFoodModalProps) => {
  const { isOpen, onCloseFn } = props;

  const { action } = useAdminAuth();
  const roleActionEdit = action?.masterDataFeed === "edit";

  const {
    brandsOptions,
    brandsOptionsAdd,
    brands,
    brand,
    setBrand,
    onSearch,
    searchText,
    onSearchText,
    onClearSearchText,
    feedResult,
    isShowAddModal,
    onShowAddModal,
    onHideAddModal,
    isShowEditModal,
    onShowEditModal,
    onHideEditModal,
    currentOrderByKey,
    setCurrentOrderByKey,
    orderByType,
    setOrderByType,
    currentPage,
    setCurrentPage,
    tableData,
    totalTableSize,
    refetchFeeds,
  } = useFoodModalController();

  const onClose = useCallback(() => {
    onCloseFn();
    setBrand([]);
    setCurrentPage(1);
  }, []);

  return (
    <Dialog
      open={isOpen}
      maxWidth="xl"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent>
        <div className={classes.titleContainer}>
          <p className={classes.title}>อาหารสัตว์</p>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className={tw(classes.row, "pt-3")}>
          <Dropdown
            label="แบรนด์อาหารสัตว์"
            containerClassName="max-w-[400px]"
            options={brandsOptions}
            onChange={setBrand}
            renderValueArray={brand}
          />
          <div className="mt-7">
            <ButtonFillCustom title="ค้นหา" onClick={onSearch} />
          </div>
        </div>
        <div className={classes.space} />
        <div className={classes.row}>
          <TextInputSearchCustom
            value={searchText}
            className="max-w-[470px]"
            onChange={onSearchText}
            placeholder="ค้นหาโดยเบอร์อาหารสัตว์"
            InputProps={{
              endAdornment: searchText ? (
                <CloseRoundedIcon
                  sx={{ cursor: "pointer" }}
                  onClick={onClearSearchText}
                />
              ) : null,
            }}
          />
          {roleActionEdit && (
            <div className="flex gap-x-3">
              <ButtonOutlinedCustom
                title="เพิ่ม"
                btnBorderColor="#68C184"
                btnTextColor="#68C184"
                startIcon={<AddCircleIcon />}
                onClick={onShowAddModal}
              />
              <ButtonOutlinedCustom
                title="แก้ไข"
                btnBorderColor="#68C184"
                btnTextColor="#68C184"
                onClick={onShowEditModal}
              />
            </div>
          )}
        </div>
        {/* BASE TABLE SHOULD FOLLOW */}
        <Table
          data={tableData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalTableSize={totalTableSize}
          currentOrderByKey={currentOrderByKey}
          setCurrentOrderByKey={setCurrentOrderByKey}
          orderByType={orderByType}
          setOrderByType={setOrderByType}
          brand={brand}
        />
      </DialogContent>
      <AddModal
        isOpen={isShowAddModal}
        onCloseFn={onHideAddModal}
        refetchFeeds={refetchFeeds}
        brandsOptions={brandsOptionsAdd}
        brands={brands?.result || []}
        brand={brand}
      />
      <EditTableModal
        isOpen={isShowEditModal}
        onCloseFn={onHideEditModal}
        data={tableData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalTableSize={totalTableSize}
        currentOrderByKey={currentOrderByKey}
        setCurrentOrderByKey={setCurrentOrderByKey}
        orderByType={orderByType}
        setOrderByType={setOrderByType}
        refetchFeeds={refetchFeeds}
      />
    </Dialog>
  );
};

export default memo(FoodModal);
