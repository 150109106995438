export const subDistrictData = [
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "พระบรมมหาราชวัง",
    zipcode: 10200,
    latitude: 13.751,
    longitude: 100.492,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "วังบูรพาภิรมย์",
    zipcode: 10200,
    latitude: 13.744,
    longitude: 100.499,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "วัดราชบพิธ",
    zipcode: 10200,
    latitude: 13.75,
    longitude: 100.499,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "สำราญราษฎร์",
    zipcode: 10200,
    latitude: 13.751,
    longitude: 100.503,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "ศาลเจ้าพ่อเสือ",
    zipcode: 10200,
    latitude: 13.754,
    longitude: 100.497,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "เสาชิงช้า",
    zipcode: 10200,
    latitude: 13.753,
    longitude: 100.5,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "บวรนิเวศ",
    zipcode: 10200,
    latitude: 13.757,
    longitude: 100.501,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "ตลาดยอด",
    zipcode: 10200,
    latitude: 13.76,
    longitude: 100.498,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "ชนะสงคราม",
    zipcode: 10200,
    latitude: 13.762,
    longitude: 100.495,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "บ้านพานถม",
    zipcode: 10200,
    latitude: 13.762,
    longitude: 100.503,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "บางขุนพรหม",
    zipcode: 10200,
    latitude: 13.765,
    longitude: 100.505,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระนคร",
    subdistrict: "วัดสามพระยา",
    zipcode: 10200,
    latitude: 13.768,
    longitude: 100.499,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ดุสิต",
    subdistrict: "ดุสิต",
    zipcode: 10300,
    latitude: 13.772,
    longitude: 100.513,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ดุสิต",
    subdistrict: "วชิรพยาบาล",
    zipcode: 10300,
    latitude: 13.778,
    longitude: 100.505,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ดุสิต",
    subdistrict: "สวนจิตรลดา",
    zipcode: 10300,
    latitude: 13.767,
    longitude: 100.52,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ดุสิต",
    subdistrict: "สี่แยกมหานาค",
    zipcode: 10300,
    latitude: 13.758,
    longitude: 100.517,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ดุสิต",
    subdistrict: "ถนนนครไชยศรี",
    zipcode: 10300,
    latitude: 13.789,
    longitude: 100.522,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองจอก",
    subdistrict: "กระทุ่มราย",
    zipcode: 10530,
    latitude: 13.823,
    longitude: 100.897,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองจอก",
    subdistrict: "หนองจอก",
    zipcode: 10530,
    latitude: 13.869,
    longitude: 100.885,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองจอก",
    subdistrict: "คลองสิบ",
    zipcode: 10530,
    latitude: 13.914,
    longitude: 100.82,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองจอก",
    subdistrict: "คลองสิบสอง",
    zipcode: 10530,
    latitude: 13.914,
    longitude: 100.88,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองจอก",
    subdistrict: "โคกแฝด",
    zipcode: 10530,
    latitude: 13.84,
    longitude: 100.836,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองจอก",
    subdistrict: "คู้ฝั่งเหนือ",
    zipcode: 10530,
    latitude: 13.872,
    longitude: 100.81,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองจอก",
    subdistrict: "ลำผักชี",
    zipcode: 10530,
    latitude: 13.797,
    longitude: 100.844,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองจอก",
    subdistrict: "ลำต้อยติ่ง",
    zipcode: 10530,
    latitude: 13.781,
    longitude: 100.877,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางรัก",
    subdistrict: "มหาพฤฒาราม",
    zipcode: 10500,
    latitude: 13.734,
    longitude: 100.52,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางรัก",
    subdistrict: "สีลม",
    zipcode: 10500,
    latitude: 13.73,
    longitude: 100.525,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางรัก",
    subdistrict: "สุริยวงศ์",
    zipcode: 10500,
    latitude: 13.724,
    longitude: 100.53,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางรัก",
    subdistrict: "บางรัก",
    zipcode: 10500,
    latitude: 13.727,
    longitude: 100.527,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางรัก",
    subdistrict: "สี่พระยา",
    zipcode: 10500,
    latitude: 13.725,
    longitude: 100.514,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางเขน",
    subdistrict: "อนุสาวรีย์",
    zipcode: 10220,
    latitude: 13.868,
    longitude: 100.606,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางเขน",
    subdistrict: "ท่าแร้ง",
    zipcode: 10220,
    latitude: 13.866,
    longitude: 100.65,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางกะปิ",
    subdistrict: "คลองจั่น",
    zipcode: 10240,
    latitude: 13.786,
    longitude: 100.635,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางกะปิ",
    subdistrict: "หัวหมาก",
    zipcode: 10240,
    latitude: 13.756,
    longitude: 100.66,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ปทุมวัน",
    subdistrict: "รองเมือง",
    zipcode: 10330,
    latitude: 13.744,
    longitude: 100.52,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ปทุมวัน",
    subdistrict: "วังใหม่",
    zipcode: 10330,
    latitude: 13.742,
    longitude: 100.526,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ปทุมวัน",
    subdistrict: "ปทุมวัน",
    zipcode: 10330,
    latitude: 13.74,
    longitude: 100.535,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ปทุมวัน",
    subdistrict: "ลุมพินี",
    zipcode: 10330,
    latitude: 13.736,
    longitude: 100.546,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ป้อมปราบศัตรูพ่าย",
    subdistrict: "ป้อมปราบ",
    zipcode: 10100,
    latitude: 13.743,
    longitude: 100.514,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ป้อมปราบศัตรูพ่าย",
    subdistrict: "วัดเทพศิรินทร์",
    zipcode: 10100,
    latitude: 13.749,
    longitude: 100.512,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ป้อมปราบศัตรูพ่าย",
    subdistrict: "คลองมหานาค",
    zipcode: 10100,
    latitude: 13.753,
    longitude: 100.513,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ป้อมปราบศัตรูพ่าย",
    subdistrict: "บ้านบาตร",
    zipcode: 10100,
    latitude: 13.752,
    longitude: 100.507,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ป้อมปราบศัตรูพ่าย",
    subdistrict: "วัดโสมนัส",
    zipcode: 10100,
    latitude: 13.759,
    longitude: 100.511,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พระโขนง",
    subdistrict: "บางจาก",
    zipcode: 10260,
    latitude: 13.692,
    longitude: 100.626,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "มีนบุรี",
    subdistrict: "มีนบุรี",
    zipcode: 10510,
    latitude: 13.804,
    longitude: 100.723,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "มีนบุรี",
    subdistrict: "แสนแสบ",
    zipcode: 10510,
    latitude: 13.812,
    longitude: 100.774,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ลาดกระบัง",
    subdistrict: "ลาดกระบัง",
    zipcode: 10520,
    latitude: 13.723,
    longitude: 100.771,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ลาดกระบัง",
    subdistrict: "คลองสองต้นนุ่น",
    zipcode: 10520,
    latitude: 13.753,
    longitude: 100.723,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ลาดกระบัง",
    subdistrict: "คลองสามประเวศ",
    zipcode: 10520,
    latitude: 13.753,
    longitude: 100.754,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ลาดกระบัง",
    subdistrict: "ลำปลาทิว",
    zipcode: 10520,
    latitude: 13.765,
    longitude: 100.817,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ลาดกระบัง",
    subdistrict: "ทับยาว",
    zipcode: 10520,
    latitude: 13.728,
    longitude: 100.815,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ลาดกระบัง",
    subdistrict: "ขุมทอง",
    zipcode: 10520,
    latitude: 13.736,
    longitude: 100.855,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ยานนาวา",
    subdistrict: "ช่องนนทรี",
    zipcode: 10120,
    latitude: 13.682,
    longitude: 100.533,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ยานนาวา",
    subdistrict: "บางโพงพาง",
    zipcode: 10120,
    latitude: 13.697,
    longitude: 100.544,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สัมพันธวงศ์",
    subdistrict: "จักรวรรดิ",
    zipcode: 10100,
    latitude: 13.741,
    longitude: 100.504,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สัมพันธวงศ์",
    subdistrict: "สัมพันธวงศ์",
    zipcode: 10100,
    latitude: 13.739,
    longitude: 100.511,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สัมพันธวงศ์",
    subdistrict: "ตลาดน้อย",
    zipcode: 10100,
    latitude: 13.734,
    longitude: 100.513,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "พญาไท",
    subdistrict: "สามเสนใน",
    zipcode: 10400,
    latitude: 13.782,
    longitude: 100.545,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ธนบุรี",
    subdistrict: "วัดกัลยาณ์",
    zipcode: 10600,
    latitude: 13.737,
    longitude: 100.493,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ธนบุรี",
    subdistrict: "หิรัญรูจี",
    zipcode: 10600,
    latitude: 13.731,
    longitude: 100.49,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ธนบุรี",
    subdistrict: "บางยี่เรือ",
    zipcode: 10600,
    latitude: 13.72,
    longitude: 100.482,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ธนบุรี",
    subdistrict: "บุคคโล",
    zipcode: 10600,
    latitude: 13.707,
    longitude: 100.486,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ธนบุรี",
    subdistrict: "ตลาดพลู",
    zipcode: 10600,
    latitude: 13.715,
    longitude: 100.473,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ธนบุรี",
    subdistrict: "ดาวคะนอง",
    zipcode: 10600,
    latitude: 13.725,
    longitude: 100.48583,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ธนบุรี",
    subdistrict: "สำเหร่",
    zipcode: 10600,
    latitude: 13.713,
    longitude: 100.492,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางกอกใหญ่",
    subdistrict: "วัดอรุณ",
    zipcode: 10600,
    latitude: 13.743,
    longitude: 100.486,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางกอกใหญ่",
    subdistrict: "วัดท่าพระ",
    zipcode: 10600,
    latitude: 13.733,
    longitude: 100.475,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ห้วยขวาง",
    subdistrict: "ห้วยขวาง",
    zipcode: 10310,
    latitude: 13.769,
    longitude: 100.577,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ห้วยขวาง",
    subdistrict: "บางกะปิ",
    zipcode: 10310,
    latitude: 13.752,
    longitude: 100.586,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ห้วยขวาง",
    subdistrict: "สามเสนนอก",
    zipcode: 10310,
    latitude: 13.796,
    longitude: 100.579,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองสาน",
    subdistrict: "สมเด็จเจ้าพระยา",
    zipcode: 10600,
    latitude: 13.731,
    longitude: 100.497,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองสาน",
    subdistrict: "คลองสาน",
    zipcode: 10600,
    latitude: 13.735,
    longitude: 100.504,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองสาน",
    subdistrict: "บางลำภูล่าง",
    zipcode: 10600,
    latitude: 13.715,
    longitude: 100.501,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองสาน",
    subdistrict: "คลองต้นไทร",
    zipcode: 10600,
    latitude: 13.725,
    longitude: 100.508,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ตลิ่งชัน",
    subdistrict: "คลองชักพระ",
    zipcode: 10170,
    latitude: 13.76,
    longitude: 100.456,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ตลิ่งชัน",
    subdistrict: "ตลิ่งชัน",
    zipcode: 10170,
    latitude: 13.789,
    longitude: 100.459,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ตลิ่งชัน",
    subdistrict: "ฉิมพลี",
    zipcode: 10170,
    latitude: 13.786,
    longitude: 100.432,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ตลิ่งชัน",
    subdistrict: "บางพรม",
    zipcode: 10170,
    latitude: 13.752,
    longitude: 100.442,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ตลิ่งชัน",
    subdistrict: "บางระมาด",
    zipcode: 10170,
    latitude: 13.767,
    longitude: 100.431,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ตลิ่งชัน",
    subdistrict: "บางเชือกหนัง",
    zipcode: 10170,
    latitude: 13.751,
    longitude: 100.419,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางกอกน้อย",
    subdistrict: "ศิริราช",
    zipcode: 10700,
    latitude: 13.759,
    longitude: 100.481,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางกอกน้อย",
    subdistrict: "บ้านช่างหล่อ",
    zipcode: 10700,
    latitude: 13.752,
    longitude: 100.477,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางกอกน้อย",
    subdistrict: "บางขุนนนท์",
    zipcode: 10700,
    latitude: 13.774,
    longitude: 100.466,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางกอกน้อย",
    subdistrict: "บางขุนศรี",
    zipcode: 10700,
    latitude: 13.76,
    longitude: 100.463,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางกอกน้อย",
    subdistrict: "อรุณอมรินทร์",
    zipcode: 10700,
    latitude: 13.772,
    longitude: 100.477,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางขุนเทียน",
    subdistrict: "ท่าข้าม",
    zipcode: 10150,
    latitude: 13.555,
    longitude: 100.434,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางขุนเทียน",
    subdistrict: "แสมดำ",
    zipcode: 10150,
    latitude: 13.605,
    longitude: 100.395,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ภาษีเจริญ",
    subdistrict: "บางหว้า",
    zipcode: 10160,
    latitude: 13.714,
    longitude: 100.443,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ภาษีเจริญ",
    subdistrict: "บางด้วน",
    zipcode: 10160,
    latitude: 13.723,
    longitude: 100.437,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ภาษีเจริญ",
    subdistrict: "บางจาก",
    zipcode: 10160,
    latitude: 13.727,
    longitude: 100.45,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ภาษีเจริญ",
    subdistrict: "บางแวก",
    zipcode: 10160,
    latitude: 13.736,
    longitude: 100.445,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ภาษีเจริญ",
    subdistrict: "คลองขวาง",
    zipcode: 10160,
    latitude: 13.738,
    longitude: 100.423,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ภาษีเจริญ",
    subdistrict: "ปากคลองภาษีเจริญ",
    zipcode: 10160,
    latitude: 13.722,
    longitude: 100.464,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ภาษีเจริญ",
    subdistrict: "คูหาสวรรค์",
    zipcode: 10160,
    latitude: 13.727,
    longitude: 100.457,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองแขม",
    subdistrict: "หนองแขม",
    zipcode: 10160,
    latitude: 13.68,
    longitude: 100.358,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หนองแขม",
    subdistrict: "หนองค้างพลู",
    zipcode: 10160,
    latitude: 13.714,
    longitude: 100.351,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ราษฎร์บูรณะ",
    subdistrict: "ราษฎร์บูรณะ",
    zipcode: 10140,
    latitude: 13.67,
    longitude: 100.51,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ราษฎร์บูรณะ",
    subdistrict: "บางปะกอก",
    zipcode: 10140,
    latitude: 13.675,
    longitude: 100.491,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางพลัด",
    subdistrict: "บางพลัด",
    zipcode: 10700,
    latitude: 13.791,
    longitude: 100.487,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางพลัด",
    subdistrict: "บางอ้อ",
    zipcode: 10700,
    latitude: 13.802,
    longitude: 100.512,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางพลัด",
    subdistrict: "บางบำหรุ",
    zipcode: 10700,
    latitude: 13.781,
    longitude: 100.482,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางพลัด",
    subdistrict: "บางยี่ขัน",
    zipcode: 10700,
    latitude: 13.774,
    longitude: 100.492,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ดินแดง",
    subdistrict: "ดินแดง",
    zipcode: 10400,
    latitude: 13.778,
    longitude: 100.567,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บึงกุ่ม",
    subdistrict: "คลองกุ่ม",
    zipcode: 10240,
    latitude: 13.808,
    longitude: 100.65,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บึงกุ่ม",
    subdistrict: "นวมินทร์",
    zipcode: 10240,
    latitude: 13.802,
    longitude: 100.625,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บึงกุ่ม",
    subdistrict: "นวลจันทร์",
    zipcode: 10240,
    latitude: 13.834,
    longitude: 100.628,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สาทร",
    subdistrict: "ทุ่งวัดดอน",
    zipcode: 10120,
    latitude: 13.71,
    longitude: 100.532,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สาทร",
    subdistrict: "ยานนาวา",
    zipcode: 10120,
    latitude: 13.714,
    longitude: 100.514,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สาทร",
    subdistrict: "ทุ่งมหาเมฆ",
    zipcode: 10120,
    latitude: 13.718,
    longitude: 100.541,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางซื่อ",
    subdistrict: "บางซื่อ",
    zipcode: 10800,
    latitude: 13.82,
    longitude: 100.529,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางซื่อ",
    subdistrict: "วงศ์สว่าง",
    zipcode: 10800,
    latitude: 13.818,
    longitude: 100.531,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "จตุจักร",
    subdistrict: "ลาดยาว",
    zipcode: 10900,
    latitude: 13.826,
    longitude: 100.565,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "จตุจักร",
    subdistrict: "เสนานิคม",
    zipcode: 10900,
    latitude: 13.8439,
    longitude: 100.5831,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "จตุจักร",
    subdistrict: "จันทรเกษม",
    zipcode: 10900,
    latitude: 13.821,
    longitude: 100.5831,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "จตุจักร",
    subdistrict: "จอมพล",
    zipcode: 10900,
    latitude: 13.7844892,
    longitude: 100.4824099,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "จตุจักร",
    subdistrict: "จตุจักร",
    zipcode: 10900,
    latitude: 13.8244,
    longitude: 100.5548,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางคอแหลม",
    subdistrict: "บางคอแหลม",
    zipcode: 10120,
    latitude: 13.696,
    longitude: 100.494,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางคอแหลม",
    subdistrict: "วัดพระยาไกร",
    zipcode: 10120,
    latitude: 13.706,
    longitude: 100.508,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางคอแหลม",
    subdistrict: "บางโคล่",
    zipcode: 10120,
    latitude: 13.694,
    longitude: 100.516,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ประเวศ",
    subdistrict: "ประเวศ",
    zipcode: 10250,
    latitude: 13.719,
    longitude: 100.664,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ประเวศ",
    subdistrict: "หนองบอน",
    zipcode: 10250,
    latitude: 13.687,
    longitude: 100.656,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ประเวศ",
    subdistrict: "ดอกไม้",
    zipcode: 10250,
    latitude: 13.68,
    longitude: 100.689,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองเตย",
    subdistrict: "คลองเตย",
    zipcode: 10110,
    latitude: 13.71,
    longitude: 100.57,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองเตย",
    subdistrict: "คลองตัน",
    zipcode: 10110,
    latitude: 13.723,
    longitude: 100.571,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองเตย",
    subdistrict: "พระโขนง",
    zipcode: 10110,
    latitude: 13.707,
    longitude: 100.595,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สวนหลวง",
    subdistrict: "สวนหลวง",
    zipcode: 10250,
    latitude: 13.726,
    longitude: 100.628,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "จอมทอง",
    subdistrict: "บางขุนเทียน",
    zipcode: 10150,
    latitude: 13.694,
    longitude: 100.45,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "จอมทอง",
    subdistrict: "บางค้อ",
    zipcode: 10150,
    latitude: 13.702,
    longitude: 100.476,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "จอมทอง",
    subdistrict: "บางมด",
    zipcode: 10150,
    latitude: 13.672,
    longitude: 100.468,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "จอมทอง",
    subdistrict: "จอมทอง",
    zipcode: 10150,
    latitude: 13.693,
    longitude: 100.468,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ดอนเมือง",
    subdistrict: "สีกัน",
    zipcode: 10210,
    latitude: 13.925,
    longitude: 100.593,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ดอนเมือง",
    subdistrict: "ดอนเมือง",
    zipcode: 10210,
    latitude: 13.9255,
    longitude: 100.5923,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ดอนเมือง",
    subdistrict: "สนามบิน",
    zipcode: 10210,
    latitude: 13.9394,
    longitude: 100.6129,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ราชเทวี",
    subdistrict: "ทุ่งพญาไท",
    zipcode: 10400,
    latitude: 13.763,
    longitude: 100.53,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ราชเทวี",
    subdistrict: "ถนนพญาไท",
    zipcode: 10400,
    latitude: 13.757,
    longitude: 100.538,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ราชเทวี",
    subdistrict: "ถนนเพชรบุรี",
    zipcode: 10400,
    latitude: 13.752,
    longitude: 100.559,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ราชเทวี",
    subdistrict: "มักกะสัน",
    zipcode: 10400,
    latitude: 13.752,
    longitude: 100.528,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ลาดพร้าว",
    subdistrict: "ลาดพร้าว",
    zipcode: 10230,
    latitude: 13.811,
    longitude: 100.612,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ลาดพร้าว",
    subdistrict: "จรเข้บัว",
    zipcode: 10230,
    latitude: 13.84,
    longitude: 100.6,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "วัฒนา",
    subdistrict: "คลองเตยเหนือ",
    zipcode: 10110,
    latitude: 13.743,
    longitude: 100.56,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "วัฒนา",
    subdistrict: "คลองตันเหนือ",
    zipcode: 10110,
    latitude: 13.736,
    longitude: 100.576,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "วัฒนา",
    subdistrict: "พระโขนงเหนือ",
    zipcode: 10110,
    latitude: 13.719,
    longitude: 100.596,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางแค",
    subdistrict: "บางแค",
    zipcode: 10160,
    latitude: 13.698,
    longitude: 100.409,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางแค",
    subdistrict: "บางแคเหนือ",
    zipcode: 10160,
    latitude: 13.72,
    longitude: 100.4,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางแค",
    subdistrict: "บางไผ่",
    zipcode: 10160,
    latitude: 13.741,
    longitude: 100.385,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางแค",
    subdistrict: "หลักสอง",
    zipcode: 10160,
    latitude: 13.683,
    longitude: 100.396,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หลักสี่",
    subdistrict: "ทุ่งสองห้อง",
    zipcode: 10210,
    latitude: 13.883,
    longitude: 100.564,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "หลักสี่",
    subdistrict: "ตลาดบางเขน",
    zipcode: 10210,
    latitude: 13.871,
    longitude: 100.58,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สายไหม",
    subdistrict: "สายไหม",
    zipcode: 10220,
    latitude: 13.921,
    longitude: 100.654,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สายไหม",
    subdistrict: "ออเงิน",
    zipcode: 10220,
    latitude: 13.896,
    longitude: 100.672,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สายไหม",
    subdistrict: "คลองถนน",
    zipcode: 10220,
    latitude: 13.898,
    longitude: 100.63,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คันนายาว",
    subdistrict: "คันนายาว",
    zipcode: 10230,
    latitude: 13.821,
    longitude: 100.677,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คันนายาว",
    subdistrict: "รามอินทรา",
    zipcode: 10230,
    latitude: 13.8193104,
    longitude: 100.6243197,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "สะพานสูง",
    subdistrict: "สะพานสูง",
    zipcode: 10240,
    latitude: 13.761,
    longitude: 100.688,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "วังทองหลาง",
    subdistrict: "วังทองหลาง",
    zipcode: 10310,
    latitude: 13.779,
    longitude: 100.609,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "วังทองหลาง",
    subdistrict: "สะพานสอง",
    zipcode: 10310,
    latitude: 13.7945939,
    longitude: 100.5903391,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "วังทองหลาง",
    subdistrict: "คลองเจ้าคุณสิงห์",
    zipcode: 10310,
    latitude: 13.7876756,
    longitude: 100.6096349,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "วังทองหลาง",
    subdistrict: "พลับพลา",
    zipcode: 10310,
    latitude: 13.7687949,
    longitude: 100.5929597,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองสามวา",
    subdistrict: "สามวาตะวันตก",
    zipcode: 10510,
    latitude: 13.889,
    longitude: 100.708,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองสามวา",
    subdistrict: "สามวาตะวันออก",
    zipcode: 10510,
    latitude: 13.896,
    longitude: 100.76,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองสามวา",
    subdistrict: "บางชัน",
    zipcode: 10510,
    latitude: 13.839,
    longitude: 100.7,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองสามวา",
    subdistrict: "ทรายกองดิน",
    zipcode: 10510,
    latitude: 13.854,
    longitude: 100.745,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "คลองสามวา",
    subdistrict: "ทรายกองดินใต้",
    zipcode: 10510,
    latitude: 13.861,
    longitude: 100.786,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางนา",
    subdistrict: "บางนา",
    zipcode: 10260,
    latitude: 13.672,
    longitude: 100.616,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ทวีวัฒนา",
    subdistrict: "ทวีวัฒนา",
    zipcode: 10170,
    latitude: 13.758,
    longitude: 100.348,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ทวีวัฒนา",
    subdistrict: "ศาลาธรรมสพน์",
    zipcode: 10170,
    latitude: 13.783,
    longitude: 100.39,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ทุ่งครุ",
    subdistrict: "บางมด",
    zipcode: 10140,
    latitude: 13.651,
    longitude: 100.51,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "ทุ่งครุ",
    subdistrict: "ทุ่งครุ",
    zipcode: 10140,
    latitude: 13.614,
    longitude: 100.497,
  },
  {
    province: "กรุงเทพมหานคร",
    district: "บางบอน",
    subdistrict: "บางบอน",
    zipcode: 10150,
    latitude: 13.646,
    longitude: 100.37,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "ปากน้ำ",
    zipcode: 10270,
    latitude: 13.6007954,
    longitude: 100.5775554,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "สำโรงเหนือ",
    zipcode: 10270,
    latitude: 13.649,
    longitude: 100.617,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "บางเมือง",
    zipcode: 10270,
    latitude: 13.601,
    longitude: 100.622,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "ท้ายบ้าน",
    zipcode: 10280,
    latitude: 13.556,
    longitude: 100.599,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "บางปูใหม่",
    zipcode: 10280,
    latitude: 13.526,
    longitude: 100.67,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "แพรกษา",
    zipcode: 10280,
    latitude: 13.564,
    longitude: 100.653,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "บางโปรง",
    zipcode: 10270,
    latitude: 13.623,
    longitude: 100.565,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "บางปู",
    zipcode: 10280,
    latitude: 13.5057292,
    longitude: 100.7041707,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "บางด้วน",
    zipcode: 10270,
    latitude: 13.622,
    longitude: 100.581,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "บางเมืองใหม่",
    zipcode: 10270,
    latitude: 13.6220669,
    longitude: 100.5856475,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "เทพารักษ์",
    zipcode: 10270,
    latitude: 13.634,
    longitude: 100.61,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "ท้ายบ้านใหม่",
    zipcode: 10280,
    latitude: 13.572,
    longitude: 100.621,
  },
  {
    province: "สมุทรปราการ",
    district: "เมืองสมุทรปราการ",
    subdistrict: "แพรกษาใหม่",
    zipcode: 10280,
    latitude: 13.559,
    longitude: 100.691,
  },
  {
    province: "สมุทรปราการ",
    district: "บางบ่อ",
    subdistrict: "บางบ่อ",
    zipcode: 10560,
    latitude: 13.611,
    longitude: 100.862,
  },
  {
    province: "สมุทรปราการ",
    district: "บางบ่อ",
    subdistrict: "บ้านระกาศ",
    zipcode: 10560,
    latitude: 13.639,
    longitude: 100.904,
  },
  {
    province: "สมุทรปราการ",
    district: "บางบ่อ",
    subdistrict: "บางพลีน้อย",
    zipcode: 10560,
    latitude: 13.574,
    longitude: 100.9,
  },
  {
    province: "สมุทรปราการ",
    district: "บางบ่อ",
    subdistrict: "บางเพรียง",
    zipcode: 10560,
    latitude: 13.545,
    longitude: 100.805,
  },
  {
    province: "สมุทรปราการ",
    district: "บางบ่อ",
    subdistrict: "คลองด่าน",
    zipcode: 10550,
    latitude: 13.511,
    longitude: 100.835,
  },
  {
    province: "สมุทรปราการ",
    district: "บางบ่อ",
    subdistrict: "คลองสวน",
    zipcode: 10560,
    latitude: 13.659,
    longitude: 100.925,
  },
  {
    province: "สมุทรปราการ",
    district: "บางบ่อ",
    subdistrict: "เปร็ง",
    zipcode: 10560,
    latitude: 13.672,
    longitude: 100.881,
  },
  {
    province: "สมุทรปราการ",
    district: "บางบ่อ",
    subdistrict: "คลองนิยมยาตรา",
    zipcode: 10560,
    latitude: 13.624,
    longitude: 100.935,
  },
  {
    province: "สมุทรปราการ",
    district: "บางพลี",
    subdistrict: "บางพลีใหญ่",
    zipcode: 10540,
    latitude: 13.618,
    longitude: 100.694,
  },
  {
    province: "สมุทรปราการ",
    district: "บางพลี",
    subdistrict: "บางแก้ว",
    zipcode: 10540,
    latitude: 13.642,
    longitude: 100.662,
  },
  {
    province: "สมุทรปราการ",
    district: "บางพลี",
    subdistrict: "บางปลา",
    zipcode: 10540,
    latitude: 13.561,
    longitude: 100.739,
  },
  {
    province: "สมุทรปราการ",
    district: "บางพลี",
    subdistrict: "บางโฉลง",
    zipcode: 10540,
    latitude: 13.623,
    longitude: 100.755,
  },
  {
    province: "สมุทรปราการ",
    district: "บางพลี",
    subdistrict: "ราชาเทวะ",
    zipcode: 10540,
    latitude: 13.681,
    longitude: 100.733,
  },
  {
    province: "สมุทรปราการ",
    district: "บางพลี",
    subdistrict: "หนองปรือ",
    zipcode: 10540,
    latitude: 13.678,
    longitude: 100.75,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "ตลาด",
    zipcode: 10130,
    latitude: 13.671,
    longitude: 100.52,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางพึ่ง",
    zipcode: 10130,
    latitude: 13.6578184,
    longitude: 100.4844048,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางจาก",
    zipcode: 10130,
    latitude: 13.616,
    longitude: 100.536,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางครุ",
    zipcode: 10130,
    latitude: 13.631,
    longitude: 100.526,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางหญ้าแพรก",
    zipcode: 10130,
    latitude: 13.645,
    longitude: 100.546,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางหัวเสือ",
    zipcode: 10130,
    latitude: 13.625,
    longitude: 100.549,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "สำโรงใต้",
    zipcode: 10130,
    latitude: 13.640504,
    longitude: 100.5520242,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางยอ",
    zipcode: 10130,
    latitude: 13.679,
    longitude: 100.558,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางกะเจ้า",
    zipcode: 10130,
    latitude: 13.697,
    longitude: 100.561,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางน้ำผึ้ง",
    zipcode: 10130,
    latitude: 13.679,
    longitude: 100.578,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางกระสอบ",
    zipcode: 10130,
    latitude: 13.666,
    longitude: 100.566,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "บางกอบัว",
    zipcode: 10130,
    latitude: 13.693,
    longitude: 100.577,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "ทรงคนอง",
    zipcode: 10130,
    latitude: 13.665,
    longitude: 100.545,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "สำโรง",
    zipcode: 10130,
    latitude: 13.659,
    longitude: 100.584,
  },
  {
    province: "สมุทรปราการ",
    district: "พระประแดง",
    subdistrict: "สำโรงกลาง",
    zipcode: 10130,
    latitude: 13.653,
    longitude: 100.564,
  },
  {
    province: "สมุทรปราการ",
    district: "พระสมุทรเจดีย์",
    subdistrict: "นาเกลือ",
    zipcode: 10290,
    latitude: 13.53,
    longitude: 100.494,
  },
  {
    province: "สมุทรปราการ",
    district: "พระสมุทรเจดีย์",
    subdistrict: "บ้านคลองสวน",
    zipcode: 10290,
    latitude: 13.571,
    longitude: 100.476,
  },
  {
    province: "สมุทรปราการ",
    district: "พระสมุทรเจดีย์",
    subdistrict: "แหลมฟ้าผ่า",
    zipcode: 10290,
    latitude: 13.539,
    longitude: 100.559,
  },
  {
    province: "สมุทรปราการ",
    district: "พระสมุทรเจดีย์",
    subdistrict: "ปากคลองบางปลากด",
    zipcode: 10290,
    latitude: 13.606,
    longitude: 100.567,
  },
  {
    province: "สมุทรปราการ",
    district: "พระสมุทรเจดีย์",
    subdistrict: "ในคลองบางปลากด",
    zipcode: 10290,
    latitude: 13.588,
    longitude: 100.541,
  },
  {
    province: "สมุทรปราการ",
    district: "บางเสาธง",
    subdistrict: "บางเสาธง",
    zipcode: 10540,
    latitude: 13.6,
    longitude: 100.815,
  },
  {
    province: "สมุทรปราการ",
    district: "บางเสาธง",
    subdistrict: "ศีรษะจรเข้น้อย",
    zipcode: 10540,
    latitude: 13.689,
    longitude: 100.799,
  },
  {
    province: "สมุทรปราการ",
    district: "บางเสาธง",
    subdistrict: "ศีรษะจรเข้ใหญ่",
    zipcode: 10540,
    latitude: 13.641,
    longitude: 100.803,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "สวนใหญ่",
    zipcode: 11000,
    latitude: 13.842,
    longitude: 100.494,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "ตลาดขวัญ",
    zipcode: 11000,
    latitude: 13.85,
    longitude: 100.509,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "บางเขน",
    zipcode: 11000,
    latitude: 13.835,
    longitude: 100.516,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "บางกระสอ",
    zipcode: 11000,
    latitude: 13.869,
    longitude: 100.491,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "ท่าทราย",
    zipcode: 11000,
    latitude: 13.884,
    longitude: 100.501,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "บางไผ่",
    zipcode: 11000,
    latitude: 13.823,
    longitude: 100.492,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "บางศรีเมือง",
    zipcode: 11000,
    latitude: 13.841,
    longitude: 100.475,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "บางกร่าง",
    zipcode: 11000,
    latitude: 13.835,
    longitude: 100.438,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "ไทรม้า",
    zipcode: 11000,
    latitude: 13.87,
    longitude: 100.472,
  },
  {
    province: "นนทบุรี",
    district: "เมืองนนทบุรี",
    subdistrict: "บางรักน้อย",
    zipcode: 11000,
    latitude: 13.859,
    longitude: 100.464,
  },
  {
    province: "นนทบุรี",
    district: "บางกรวย",
    subdistrict: "วัดชลอ",
    zipcode: 11130,
    latitude: 13.802,
    longitude: 100.476,
  },
  {
    province: "นนทบุรี",
    district: "บางกรวย",
    subdistrict: "บางกรวย",
    zipcode: 11130,
    latitude: 13.809,
    longitude: 100.499,
  },
  {
    province: "นนทบุรี",
    district: "บางกรวย",
    subdistrict: "บางสีทอง",
    zipcode: 11130,
    latitude: 13.818,
    longitude: 100.479,
  },
  {
    province: "นนทบุรี",
    district: "บางกรวย",
    subdistrict: "บางขนุน",
    zipcode: 11130,
    latitude: 13.811,
    longitude: 100.453,
  },
  {
    province: "นนทบุรี",
    district: "บางกรวย",
    subdistrict: "บางขุนกอง",
    zipcode: 11130,
    latitude: 13.821,
    longitude: 100.444,
  },
  {
    province: "นนทบุรี",
    district: "บางกรวย",
    subdistrict: "บางคูเวียง",
    zipcode: 11130,
    latitude: 13.822,
    longitude: 100.417,
  },
  {
    province: "นนทบุรี",
    district: "บางกรวย",
    subdistrict: "มหาสวัสดิ์",
    zipcode: 11130,
    latitude: 13.806,
    longitude: 100.434,
  },
  {
    province: "นนทบุรี",
    district: "บางกรวย",
    subdistrict: "ปลายบาง",
    zipcode: 11130,
    latitude: 13.814,
    longitude: 100.402,
  },
  {
    province: "นนทบุรี",
    district: "บางกรวย",
    subdistrict: "ศาลากลาง",
    zipcode: 11130,
    latitude: 13.81,
    longitude: 100.358,
  },
  {
    province: "นนทบุรี",
    district: "บางใหญ่",
    subdistrict: "บางม่วง",
    zipcode: 11140,
    latitude: 13.838,
    longitude: 100.406,
  },
  {
    province: "นนทบุรี",
    district: "บางใหญ่",
    subdistrict: "บางแม่นาง",
    zipcode: 11140,
    latitude: 13.868,
    longitude: 100.381,
  },
  {
    province: "นนทบุรี",
    district: "บางใหญ่",
    subdistrict: "บางเลน",
    zipcode: 11140,
    latitude: 13.854,
    longitude: 100.434,
  },
  {
    province: "นนทบุรี",
    district: "บางใหญ่",
    subdistrict: "เสาธงหิน",
    zipcode: 11140,
    latitude: 13.875,
    longitude: 100.404,
  },
  {
    province: "นนทบุรี",
    district: "บางใหญ่",
    subdistrict: "บางใหญ่",
    zipcode: 11140,
    latitude: 13.837,
    longitude: 100.375,
  },
  {
    province: "นนทบุรี",
    district: "บางใหญ่",
    subdistrict: "บ้านใหม่",
    zipcode: 11140,
    latitude: 13.861,
    longitude: 100.333,
  },
  {
    province: "นนทบุรี",
    district: "บางบัวทอง",
    subdistrict: "โสนลอย",
    zipcode: 11110,
    latitude: 13.914,
    longitude: 100.422,
  },
  {
    province: "นนทบุรี",
    district: "บางบัวทอง",
    subdistrict: "บางบัวทอง",
    zipcode: 11110,
    latitude: 13.944,
    longitude: 100.39,
  },
  {
    province: "นนทบุรี",
    district: "บางบัวทอง",
    subdistrict: "บางรักใหญ่",
    zipcode: 11110,
    latitude: 13.878,
    longitude: 100.438,
  },
  {
    province: "นนทบุรี",
    district: "บางบัวทอง",
    subdistrict: "บางคูรัด",
    zipcode: 11110,
    latitude: 13.907,
    longitude: 100.351,
  },
  {
    province: "นนทบุรี",
    district: "บางบัวทอง",
    subdistrict: "ละหาร",
    zipcode: 11110,
    latitude: 13.964,
    longitude: 100.401,
  },
  {
    province: "นนทบุรี",
    district: "บางบัวทอง",
    subdistrict: "ลำโพ",
    zipcode: 11110,
    latitude: 13.97,
    longitude: 100.416,
  },
  {
    province: "นนทบุรี",
    district: "บางบัวทอง",
    subdistrict: "พิมลราช",
    zipcode: 11110,
    latitude: 13.934,
    longitude: 100.366,
  },
  {
    province: "นนทบุรี",
    district: "บางบัวทอง",
    subdistrict: "บางรักพัฒนา",
    zipcode: 11110,
    latitude: 13.894,
    longitude: 100.41,
  },
  {
    province: "นนทบุรี",
    district: "ไทรน้อย",
    subdistrict: "ไทรน้อย",
    zipcode: 11150,
    latitude: 13.976,
    longitude: 100.344,
  },
  {
    province: "นนทบุรี",
    district: "ไทรน้อย",
    subdistrict: "ราษฎร์นิยม",
    zipcode: 11150,
    latitude: 14.088,
    longitude: 100.329,
  },
  {
    province: "นนทบุรี",
    district: "ไทรน้อย",
    subdistrict: "หนองเพรางาย",
    zipcode: 11150,
    latitude: 13.906,
    longitude: 100.322,
  },
  {
    province: "นนทบุรี",
    district: "ไทรน้อย",
    subdistrict: "ไทรใหญ่",
    zipcode: 11150,
    latitude: 14.085,
    longitude: 100.292,
  },
  {
    province: "นนทบุรี",
    district: "ไทรน้อย",
    subdistrict: "ขุนศรี",
    zipcode: 11150,
    latitude: 14.005,
    longitude: 100.287,
  },
  {
    province: "นนทบุรี",
    district: "ไทรน้อย",
    subdistrict: "คลองขวาง",
    zipcode: 11150,
    latitude: 14.004,
    longitude: 100.306,
  },
  {
    province: "นนทบุรี",
    district: "ไทรน้อย",
    subdistrict: "ทวีวัฒนา",
    zipcode: 11150,
    latitude: 13.942,
    longitude: 100.312,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "ปากเกร็ด",
    zipcode: 11120,
    latitude: 13.912,
    longitude: 100.506,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "บางตลาด",
    zipcode: 11120,
    latitude: 13.896,
    longitude: 100.521,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "บ้านใหม่",
    zipcode: 11120,
    latitude: 13.93,
    longitude: 100.546,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "บางพูด",
    zipcode: 11120,
    latitude: 13.927,
    longitude: 100.516,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "บางตะไนย์",
    zipcode: 11120,
    latitude: 13.931,
    longitude: 100.492,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "คลองพระอุดม",
    zipcode: 11120,
    latitude: 13.93,
    longitude: 100.479,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "ท่าอิฐ",
    zipcode: 11120,
    latitude: 13.894,
    longitude: 100.48,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "เกาะเกร็ด",
    zipcode: 11120,
    latitude: 13.91,
    longitude: 100.472,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "อ้อมเกร็ด",
    zipcode: 11120,
    latitude: 13.909,
    longitude: 100.453,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "คลองข่อย",
    zipcode: 11120,
    latitude: 13.959,
    longitude: 100.45,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "บางพลับ",
    zipcode: 11120,
    latitude: 13.929,
    longitude: 100.458,
  },
  {
    province: "นนทบุรี",
    district: "ปากเกร็ด",
    subdistrict: "คลองเกลือ",
    zipcode: 11120,
    latitude: 13.902,
    longitude: 100.55,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บางปรอก",
    zipcode: 12000,
    latitude: 14.012,
    longitude: 100.528,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บ้านใหม่",
    zipcode: 12000,
    latitude: 13.963,
    longitude: 100.553,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บ้านกลาง",
    zipcode: 12000,
    latitude: 14.001,
    longitude: 100.558,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บ้านฉาง",
    zipcode: 12000,
    latitude: 14.029,
    longitude: 100.507,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บ้านกระแชง",
    zipcode: 12000,
    latitude: 14.031,
    longitude: 100.552,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บางขะแยง",
    zipcode: 12000,
    latitude: 13.967,
    longitude: 100.523,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บางคูวัด",
    zipcode: 12000,
    latitude: 13.963,
    longitude: 100.495,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บางหลวง",
    zipcode: 12000,
    latitude: 14.007,
    longitude: 100.509,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บางเดื่อ",
    zipcode: 12000,
    latitude: 13.99,
    longitude: 100.487,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บางพูด",
    zipcode: 12000,
    latitude: 14.035,
    longitude: 100.572,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บางพูน",
    zipcode: 12000,
    latitude: 13.988,
    longitude: 100.594,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "บางกะดี",
    zipcode: 12000,
    latitude: 13.983,
    longitude: 100.537,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "สวนพริกไทย",
    zipcode: 12000,
    latitude: 14.018,
    longitude: 100.568,
  },
  {
    province: "ปทุมธานี",
    district: "เมืองปทุมธานี",
    subdistrict: "หลักหก",
    zipcode: 12000,
    latitude: 13.963,
    longitude: 100.59,
  },
  {
    province: "ปทุมธานี",
    district: "คลองหลวง",
    subdistrict: "คลองหนึ่ง",
    zipcode: 12120,
    latitude: 14.066,
    longitude: 100.607,
  },
  {
    province: "ปทุมธานี",
    district: "คลองหลวง",
    subdistrict: "คลองสอง",
    zipcode: 12120,
    latitude: 14.079,
    longitude: 100.642,
  },
  {
    province: "ปทุมธานี",
    district: "คลองหลวง",
    subdistrict: "คลองสาม",
    zipcode: 12120,
    latitude: 14.087,
    longitude: 100.664,
  },
  {
    province: "ปทุมธานี",
    district: "คลองหลวง",
    subdistrict: "คลองสี่",
    zipcode: 12120,
    latitude: 14.097,
    longitude: 100.687,
  },
  {
    province: "ปทุมธานี",
    district: "คลองหลวง",
    subdistrict: "คลองห้า",
    zipcode: 12120,
    latitude: 14.106,
    longitude: 100.71,
  },
  {
    province: "ปทุมธานี",
    district: "คลองหลวง",
    subdistrict: "คลองหก",
    zipcode: 12120,
    latitude: 14.117,
    longitude: 100.733,
  },
  {
    province: "ปทุมธานี",
    district: "คลองหลวง",
    subdistrict: "คลองเจ็ด",
    zipcode: 12120,
    latitude: 14.125,
    longitude: 100.75,
  },
  {
    province: "ปทุมธานี",
    district: "ธัญบุรี",
    subdistrict: "ประชาธิปัตย์",
    zipcode: 12130,
    latitude: 13.987,
    longitude: 100.632,
  },
  {
    province: "ปทุมธานี",
    district: "ธัญบุรี",
    subdistrict: "บึงยี่โถ",
    zipcode: 12130,
    latitude: 13.999,
    longitude: 100.687,
  },
  {
    province: "ปทุมธานี",
    district: "ธัญบุรี",
    subdistrict: "รังสิต",
    zipcode: 12110,
    latitude: 14.018,
    longitude: 100.731,
  },
  {
    province: "ปทุมธานี",
    district: "ธัญบุรี",
    subdistrict: "ลำผักกูด",
    zipcode: 12110,
    latitude: 14.036,
    longitude: 100.779,
  },
  {
    province: "ปทุมธานี",
    district: "ธัญบุรี",
    subdistrict: "บึงสนั่น",
    zipcode: 12110,
    latitude: 14.052,
    longitude: 100.824,
  },
  {
    province: "ปทุมธานี",
    district: "ธัญบุรี",
    subdistrict: "บึงน้ำรักษ์",
    zipcode: 12110,
    latitude: 14.071,
    longitude: 100.88,
  },
  {
    province: "ปทุมธานี",
    district: "หนองเสือ",
    subdistrict: "บึงบา",
    zipcode: 12170,
    latitude: 14.112,
    longitude: 100.824,
  },
  {
    province: "ปทุมธานี",
    district: "หนองเสือ",
    subdistrict: "บึงบอน",
    zipcode: 12170,
    latitude: 14.087,
    longitude: 100.778,
  },
  {
    province: "ปทุมธานี",
    district: "หนองเสือ",
    subdistrict: "บึงกาสาม",
    zipcode: 12170,
    latitude: 14.2,
    longitude: 100.824,
  },
  {
    province: "ปทุมธานี",
    district: "หนองเสือ",
    subdistrict: "บึงชำอ้อ",
    zipcode: 12170,
    latitude: 14.172,
    longitude: 100.778,
  },
  {
    province: "ปทุมธานี",
    district: "หนองเสือ",
    subdistrict: "หนองสามวัง",
    zipcode: 12170,
    latitude: 14.13,
    longitude: 100.881,
  },
  {
    province: "ปทุมธานี",
    district: "หนองเสือ",
    subdistrict: "ศาลาครุ",
    zipcode: 12170,
    latitude: 14.244,
    longitude: 100.928,
  },
  {
    province: "ปทุมธานี",
    district: "หนองเสือ",
    subdistrict: "นพรัตน์",
    zipcode: 12170,
    latitude: 14.223,
    longitude: 100.866,
  },
  {
    province: "ปทุมธานี",
    district: "ลาดหลุมแก้ว",
    subdistrict: "ระแหง",
    zipcode: 12140,
    latitude: 14.059,
    longitude: 100.396,
  },
  {
    province: "ปทุมธานี",
    district: "ลาดหลุมแก้ว",
    subdistrict: "ลาดหลุมแก้ว",
    zipcode: 12140,
    latitude: 14.006,
    longitude: 100.41,
  },
  {
    province: "ปทุมธานี",
    district: "ลาดหลุมแก้ว",
    subdistrict: "คูบางหลวง",
    zipcode: 12140,
    latitude: 14.047,
    longitude: 100.467,
  },
  {
    province: "ปทุมธานี",
    district: "ลาดหลุมแก้ว",
    subdistrict: "คูขวาง",
    zipcode: 12140,
    latitude: 14.068,
    longitude: 100.437,
  },
  {
    province: "ปทุมธานี",
    district: "ลาดหลุมแก้ว",
    subdistrict: "คลองพระอุดม",
    zipcode: 12140,
    latitude: 13.99,
    longitude: 100.449,
  },
  {
    province: "ปทุมธานี",
    district: "ลาดหลุมแก้ว",
    subdistrict: "บ่อเงิน",
    zipcode: 12140,
    latitude: 14.102,
    longitude: 100.383,
  },
  {
    province: "ปทุมธานี",
    district: "ลาดหลุมแก้ว",
    subdistrict: "หน้าไม้",
    zipcode: 12140,
    latitude: 14.034,
    longitude: 100.359,
  },
  {
    province: "ปทุมธานี",
    district: "ลำลูกกา",
    subdistrict: "คูคต",
    zipcode: 12130,
    latitude: 13.955,
    longitude: 100.641,
  },
  {
    province: "ปทุมธานี",
    district: "ลำลูกกา",
    subdistrict: "ลาดสวาย",
    zipcode: 12150,
    latitude: 13.958,
    longitude: 100.685,
  },
  {
    province: "ปทุมธานี",
    district: "ลำลูกกา",
    subdistrict: "บึงคำพร้อย",
    zipcode: 12150,
    latitude: 13.961,
    longitude: 100.731,
  },
  {
    province: "ปทุมธานี",
    district: "ลำลูกกา",
    subdistrict: "ลำลูกกา",
    zipcode: 12150,
    latitude: 13.972,
    longitude: 100.774,
  },
  {
    province: "ปทุมธานี",
    district: "ลำลูกกา",
    subdistrict: "บึงทองหลาง",
    zipcode: 12150,
    latitude: 13.984,
    longitude: 100.82,
  },
  {
    province: "ปทุมธานี",
    district: "ลำลูกกา",
    subdistrict: "ลำไทร",
    zipcode: 12150,
    latitude: 13.973,
    longitude: 100.861,
  },
  {
    province: "ปทุมธานี",
    district: "ลำลูกกา",
    subdistrict: "บึงคอไห",
    zipcode: 12150,
    latitude: 14.029,
    longitude: 100.862,
  },
  {
    province: "ปทุมธานี",
    district: "ลำลูกกา",
    subdistrict: "พืชอุดม",
    zipcode: 12150,
    latitude: 13.975,
    longitude: 100.896,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "บางเตย",
    zipcode: 12160,
    latitude: 14.062,
    longitude: 100.504,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "คลองควาย",
    zipcode: 12160,
    latitude: 14.097,
    longitude: 100.478,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "สามโคก",
    zipcode: 12160,
    latitude: 14.052,
    longitude: 100.529,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "กระแชง",
    zipcode: 12160,
    latitude: 14.043,
    longitude: 100.544,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "บางโพธิ์เหนือ",
    zipcode: 12160,
    latitude: 14.042,
    longitude: 100.515,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "เชียงรากใหญ่",
    zipcode: 12160,
    latitude: 14.057,
    longitude: 100.574,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "บ้านปทุม",
    zipcode: 12160,
    latitude: 14.067,
    longitude: 100.546,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "บ้านงิ้ว",
    zipcode: 12160,
    latitude: 14.084,
    longitude: 100.539,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "เชียงรากน้อย",
    zipcode: 12160,
    latitude: 14.105,
    longitude: 100.567,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "บางกระบือ",
    zipcode: 12160,
    latitude: 14.103,
    longitude: 100.538,
  },
  {
    province: "ปทุมธานี",
    district: "สามโคก",
    subdistrict: "ท้ายเกาะ",
    zipcode: 12160,
    latitude: 14.105,
    longitude: 100.496,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "ประตูชัย",
    zipcode: 13000,
    latitude: 14.349,
    longitude: 100.551,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "กะมัง",
    zipcode: 13000,
    latitude: 14.341,
    longitude: 100.581,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "หอรัตนไชย",
    zipcode: 13000,
    latitude: 14.354,
    longitude: 100.578,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "หัวรอ",
    zipcode: 13000,
    latitude: 14.368,
    longitude: 100.578,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "ท่าวาสุกรี",
    zipcode: 13000,
    latitude: 14.361,
    longitude: 100.552,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "ไผ่ลิง",
    zipcode: 13000,
    latitude: 14.356,
    longitude: 100.589,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "ปากกราน",
    zipcode: 13000,
    latitude: 14.31,
    longitude: 100.532,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "ภูเขาทอง",
    zipcode: 13000,
    latitude: 14.365,
    longitude: 100.542,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "สำเภาล่ม",
    zipcode: 13000,
    latitude: 14.34,
    longitude: 100.57,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "สวนพริก",
    zipcode: 13000,
    latitude: 14.391,
    longitude: 100.558,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "คลองตะเคียน",
    zipcode: 13000,
    latitude: 14.331,
    longitude: 100.554,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "วัดตูม",
    zipcode: 13000,
    latitude: 14.39,
    longitude: 100.539,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "หันตรา",
    zipcode: 13000,
    latitude: 14.371,
    longitude: 100.6,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "ลุมพลี",
    zipcode: 13000,
    latitude: 14.376,
    longitude: 100.548,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "บ้านใหม่",
    zipcode: 13000,
    latitude: 14.39,
    longitude: 100.521,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "บ้านเกาะ",
    zipcode: 13000,
    latitude: 14.389,
    longitude: 100.582,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "คลองสวนพลู",
    zipcode: 13000,
    latitude: 14.334,
    longitude: 100.595,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "คลองสระบัว",
    zipcode: 13000,
    latitude: 14.371,
    longitude: 100.563,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "เกาะเรียน",
    zipcode: 13000,
    latitude: 14.318,
    longitude: 100.579,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "บ้านป้อม",
    zipcode: 13000,
    latitude: 14.347,
    longitude: 100.529,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "พระนครศรีอยุธยา",
    subdistrict: "บ้านรุน",
    zipcode: 13000,
    latitude: 12.9456105,
    longitude: 100.5601931,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "ท่าเรือ",
    zipcode: 13130,
    latitude: 14.566,
    longitude: 100.719,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "จำปา",
    zipcode: 13130,
    latitude: 14.55,
    longitude: 100.741,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "ท่าหลวง",
    zipcode: 13130,
    latitude: 14.548,
    longitude: 100.764,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "บ้านร่อม",
    zipcode: 13130,
    latitude: 14.567,
    longitude: 100.698,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "ศาลาลอย",
    zipcode: 13130,
    latitude: 14.532,
    longitude: 100.7,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "วังแดง",
    zipcode: 13130,
    latitude: 14.541,
    longitude: 100.67,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "โพธิ์เอน",
    zipcode: 13130,
    latitude: 14.519,
    longitude: 100.676,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "ปากท่า",
    zipcode: 13130,
    latitude: 14.504,
    longitude: 100.692,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "หนองขนาก",
    zipcode: 13130,
    latitude: 14.512,
    longitude: 100.732,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ท่าเรือ",
    subdistrict: "ท่าเจ้าสนุก",
    zipcode: 13130,
    latitude: 14.543,
    longitude: 100.717,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "นครหลวง",
    zipcode: 13260,
    latitude: 14.469,
    longitude: 100.622,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "ท่าช้าง",
    zipcode: 13260,
    latitude: 14.511,
    longitude: 100.652,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "บ่อโพง",
    zipcode: 13260,
    latitude: 14.406,
    longitude: 100.603,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "บ้านชุ้ง",
    zipcode: 13260,
    latitude: 14.458,
    longitude: 100.655,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "ปากจั่น",
    zipcode: 13260,
    latitude: 14.44,
    longitude: 100.62,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "บางระกำ",
    zipcode: 13260,
    latitude: 14.466,
    longitude: 100.589,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "บางพระครู",
    zipcode: 13260,
    latitude: 14.483,
    longitude: 100.613,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "แม่ลา",
    zipcode: 13260,
    latitude: 14.503,
    longitude: 100.619,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "หนองปลิง",
    zipcode: 13260,
    latitude: 14.423,
    longitude: 100.64,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "คลองสะแก",
    zipcode: 13260,
    latitude: 14.424,
    longitude: 100.604,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "สามไถ",
    zipcode: 13260,
    latitude: 14.489,
    longitude: 100.675,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "นครหลวง",
    subdistrict: "พระนอน",
    zipcode: 13260,
    latitude: 14.485,
    longitude: 100.65,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "บางไทร",
    zipcode: 13190,
    latitude: 14.191,
    longitude: 100.48,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "บางพลี",
    zipcode: 13190,
    latitude: 14.212,
    longitude: 100.465,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "สนามชัย",
    zipcode: 13190,
    latitude: 14.213,
    longitude: 100.522,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "บ้านแป้ง",
    zipcode: 13190,
    latitude: 14.232,
    longitude: 100.507,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "หน้าไม้",
    zipcode: 13190,
    latitude: 14.315,
    longitude: 100.462,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "บางยี่โท",
    zipcode: 13190,
    latitude: 14.299,
    longitude: 100.445,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "แคออก",
    zipcode: 13190,
    latitude: 14.305,
    longitude: 100.472,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "แคตก",
    zipcode: 13190,
    latitude: 14.287,
    longitude: 100.48,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "ช่างเหล็ก",
    zipcode: 13190,
    latitude: 14.278,
    longitude: 100.453,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "กระแชง",
    zipcode: 13190,
    latitude: 14.279,
    longitude: 100.505,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "บ้านกลึง",
    zipcode: 13190,
    latitude: 14.3081324,
    longitude: 100.4805115,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "ช้างน้อย",
    zipcode: 13190,
    latitude: 14.252,
    longitude: 100.505,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "ห่อหมก",
    zipcode: 13190,
    latitude: 14.259,
    longitude: 100.474,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "ไผ่พระ",
    zipcode: 13190,
    latitude: 14.222,
    longitude: 100.432,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "กกแก้วบูรพา",
    zipcode: 13190,
    latitude: 14.188,
    longitude: 100.435,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "ไม้ตรา",
    zipcode: 13190,
    latitude: 14.164,
    longitude: 100.473,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "บ้านม้า",
    zipcode: 13190,
    latitude: 14.141,
    longitude: 100.49,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "บ้านเกาะ",
    zipcode: 13190,
    latitude: 14.236,
    longitude: 100.46,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "ราชคราม",
    zipcode: 13290,
    latitude: 14.186,
    longitude: 100.519,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "ช้างใหญ่",
    zipcode: 13290,
    latitude: 14.161,
    longitude: 100.522,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "โพแตง",
    zipcode: 13290,
    latitude: 14.1357103,
    longitude: 100.4572345,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "เชียงรากน้อย",
    zipcode: 13290,
    latitude: 14.134,
    longitude: 100.561,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางไทร",
    subdistrict: "โคกช้าง",
    zipcode: 13190,
    latitude: 14.123,
    longitude: 100.492,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "บางบาล",
    zipcode: 13250,
    latitude: 14.41,
    longitude: 100.464,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "วัดยม",
    zipcode: 13250,
    latitude: 14.39,
    longitude: 100.497,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "ไทรน้อย",
    zipcode: 13250,
    latitude: 14.407,
    longitude: 100.486,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "สะพานไทย",
    zipcode: 13250,
    latitude: 14.363,
    longitude: 100.486,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "มหาพราหมณ์",
    zipcode: 13250,
    latitude: 14.364,
    longitude: 100.505,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "กบเจา",
    zipcode: 13250,
    latitude: 14.347,
    longitude: 100.485,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "บ้านคลัง",
    zipcode: 13250,
    latitude: 14.347,
    longitude: 100.457,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "พระขาว",
    zipcode: 13250,
    latitude: 14.328,
    longitude: 100.482,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "น้ำเต้า",
    zipcode: 13250,
    latitude: 14.339,
    longitude: 100.438,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "ทางช้าง",
    zipcode: 13250,
    latitude: 14.375,
    longitude: 100.432,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "วัดตะกู",
    zipcode: 13250,
    latitude: 14.388,
    longitude: 100.424,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "บางหลวง",
    zipcode: 13250,
    latitude: 14.403,
    longitude: 100.433,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "บางหลวงโดด",
    zipcode: 13250,
    latitude: 14.419,
    longitude: 100.43,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "บางหัก",
    zipcode: 13250,
    latitude: 14.436,
    longitude: 100.439,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "บางชะนี",
    zipcode: 13250,
    latitude: 14.429,
    longitude: 100.461,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางบาล",
    subdistrict: "บ้านกุ่ม",
    zipcode: 13250,
    latitude: 14.436,
    longitude: 100.492,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "บ้านเลน",
    zipcode: 13160,
    latitude: 14.241,
    longitude: 100.598,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "เชียงรากน้อย",
    zipcode: 13180,
    latitude: 14.16,
    longitude: 100.594,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "บ้านโพ",
    zipcode: 13160,
    latitude: 14.272,
    longitude: 100.588,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "บ้านกรด",
    zipcode: 13160,
    latitude: 14.307,
    longitude: 100.604,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "บางกระสั้น",
    zipcode: 13160,
    latitude: 14.189,
    longitude: 100.565,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "คลองจิก",
    zipcode: 13160,
    latitude: 14.214,
    longitude: 100.597,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "บ้านหว้า",
    zipcode: 13160,
    latitude: 14.258,
    longitude: 100.616,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "วัดยม",
    zipcode: 13160,
    latitude: 14.26,
    longitude: 100.568,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "บางประแดง",
    zipcode: 13160,
    latitude: 14.288,
    longitude: 100.544,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "สามเรือน",
    zipcode: 13160,
    latitude: 14.292,
    longitude: 100.647,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "เกาะเกิด",
    zipcode: 13160,
    latitude: 14.206,
    longitude: 100.539,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "บ้านพลับ",
    zipcode: 13160,
    latitude: 14.223,
    longitude: 100.552,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "บ้านแป้ง",
    zipcode: 13160,
    latitude: 14.239,
    longitude: 100.557,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "คุ้งลาน",
    zipcode: 13160,
    latitude: 14.295,
    longitude: 100.621,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "ตลิ่งชัน",
    zipcode: 13160,
    latitude: 14.25,
    longitude: 100.642,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "บ้านสร้าง",
    zipcode: 13170,
    latitude: 14.289,
    longitude: 100.668,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "ตลาดเกรียบ",
    zipcode: 13160,
    latitude: 14.2689024,
    longitude: 100.5310172,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะอิน",
    subdistrict: "ขนอนหลวง",
    zipcode: 13160,
    latitude: 14.266,
    longitude: 100.542,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "บางปะหัน",
    zipcode: 13220,
    latitude: 14.458,
    longitude: 100.558,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "ขยาย",
    zipcode: 13220,
    latitude: 14.414,
    longitude: 100.564,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "บางเดื่อ",
    zipcode: 13220,
    latitude: 14.436,
    longitude: 100.576,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "เสาธง",
    zipcode: 13220,
    latitude: 14.496,
    longitude: 100.549,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "ทางกลาง",
    zipcode: 13220,
    latitude: 14.513,
    longitude: 100.544,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "บางเพลิง",
    zipcode: 13220,
    latitude: 14.5,
    longitude: 100.577,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "หันสัง",
    zipcode: 13220,
    latitude: 14.512,
    longitude: 100.517,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "บางนางร้า",
    zipcode: 13220,
    latitude: 14.474,
    longitude: 100.531,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "ตานิม",
    zipcode: 13220,
    latitude: 14.491,
    longitude: 100.532,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "ทับน้ำ",
    zipcode: 13220,
    latitude: 14.464,
    longitude: 100.508,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "บ้านม้า",
    zipcode: 13220,
    latitude: 14.492,
    longitude: 100.51,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "ขวัญเมือง",
    zipcode: 13220,
    latitude: 14.444,
    longitude: 100.536,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "บ้านลี่",
    zipcode: 13220,
    latitude: 14.449,
    longitude: 100.521,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "โพธิ์สามต้น",
    zipcode: 13220,
    latitude: 14.421,
    longitude: 100.547,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "พุทเลา",
    zipcode: 13220,
    latitude: 14.419,
    longitude: 100.525,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "ตาลเอน",
    zipcode: 13220,
    latitude: 14.524,
    longitude: 100.563,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางปะหัน",
    subdistrict: "บ้านขล้อ",
    zipcode: 13220,
    latitude: 14.532,
    longitude: 100.586,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "ผักไห่",
    zipcode: 13120,
    latitude: 14.462,
    longitude: 100.379,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "อมฤต",
    zipcode: 13120,
    latitude: 14.472,
    longitude: 100.361,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "บ้านแค",
    zipcode: 13120,
    latitude: 14.495,
    longitude: 100.405,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "ลาดน้ำเค็ม",
    zipcode: 13120,
    latitude: 14.482,
    longitude: 100.393,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "ตาลาน",
    zipcode: 13120,
    latitude: 14.446,
    longitude: 100.387,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "ท่าดินแดง",
    zipcode: 13120,
    latitude: 14.416,
    longitude: 100.388,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "ดอนลาน",
    zipcode: 13280,
    latitude: 14.433,
    longitude: 100.286,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "นาคู",
    zipcode: 13280,
    latitude: 14.468,
    longitude: 100.272,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "กุฎี",
    zipcode: 13120,
    latitude: 14.439,
    longitude: 100.407,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "ลำตะเคียน",
    zipcode: 13280,
    latitude: 14.419,
    longitude: 100.318,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "โคกช้าง",
    zipcode: 13120,
    latitude: 14.5,
    longitude: 100.388,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "จักราช",
    zipcode: 13280,
    latitude: 14.447,
    longitude: 100.34,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "หนองน้ำใหญ่",
    zipcode: 13280,
    latitude: 14.474,
    longitude: 100.311,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "ลาดชิด",
    zipcode: 13120,
    latitude: 14.422,
    longitude: 100.358,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "หน้าโคก",
    zipcode: 13120,
    latitude: 14.49,
    longitude: 100.351,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ผักไห่",
    subdistrict: "บ้านใหญ่",
    zipcode: 13120,
    latitude: 14.435,
    longitude: 100.384,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ภาชี",
    subdistrict: "ภาชี",
    zipcode: 13140,
    latitude: 14.442,
    longitude: 100.725,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ภาชี",
    subdistrict: "โคกม่วง",
    zipcode: 13140,
    latitude: 14.428,
    longitude: 100.75,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ภาชี",
    subdistrict: "ระโสม",
    zipcode: 13140,
    latitude: 14.39,
    longitude: 100.77,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ภาชี",
    subdistrict: "หนองน้ำใส",
    zipcode: 13140,
    latitude: 14.457,
    longitude: 100.751,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ภาชี",
    subdistrict: "ดอนหญ้านาง",
    zipcode: 13140,
    latitude: 14.478,
    longitude: 100.739,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ภาชี",
    subdistrict: "ไผ่ล้อม",
    zipcode: 13140,
    latitude: 14.474,
    longitude: 100.693,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ภาชี",
    subdistrict: "กระจิว",
    zipcode: 13140,
    latitude: 14.42,
    longitude: 100.69,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ภาชี",
    subdistrict: "พระแก้ว",
    zipcode: 13140,
    latitude: 14.44,
    longitude: 100.675,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ลาดบัวหลวง",
    subdistrict: "ลาดบัวหลวง",
    zipcode: 13230,
    latitude: 14.193,
    longitude: 100.319,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ลาดบัวหลวง",
    subdistrict: "หลักชัย",
    zipcode: 13230,
    latitude: 14.195,
    longitude: 100.277,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ลาดบัวหลวง",
    subdistrict: "สามเมือง",
    zipcode: 13230,
    latitude: 14.153,
    longitude: 100.297,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ลาดบัวหลวง",
    subdistrict: "พระยาบันลือ",
    zipcode: 13230,
    latitude: 14.161,
    longitude: 100.395,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ลาดบัวหลวง",
    subdistrict: "สิงหนาท",
    zipcode: 13230,
    latitude: 14.132,
    longitude: 100.423,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ลาดบัวหลวง",
    subdistrict: "คู้สลอด",
    zipcode: 13230,
    latitude: 14.196,
    longitude: 100.369,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "ลาดบัวหลวง",
    subdistrict: "คลองพระยาบันลือ",
    zipcode: 13230,
    latitude: 14.134,
    longitude: 100.368,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "ลำตาเสา",
    zipcode: 13170,
    latitude: 14.275,
    longitude: 100.708,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "บ่อตาโล่",
    zipcode: 13170,
    latitude: 14.241,
    longitude: 100.674,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "วังน้อย",
    zipcode: 13170,
    latitude: 14.201,
    longitude: 100.699,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "ลำไทร",
    zipcode: 13170,
    latitude: 14.2,
    longitude: 100.641,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "สนับทึบ",
    zipcode: 13170,
    latitude: 14.298,
    longitude: 100.813,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "พยอม",
    zipcode: 13170,
    latitude: 14.166,
    longitude: 100.632,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "หันตะเภา",
    zipcode: 13170,
    latitude: 14.304,
    longitude: 100.78,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "วังจุฬา",
    zipcode: 13170,
    latitude: 14.249,
    longitude: 100.8,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "ข้าวงาม",
    zipcode: 13170,
    latitude: 14.229,
    longitude: 100.758,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "วังน้อย",
    subdistrict: "ชะแมบ",
    zipcode: 13170,
    latitude: 14.257,
    longitude: 100.748,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "เสนา",
    zipcode: 13110,
    latitude: 14.328,
    longitude: 100.404,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "บ้านแพน",
    zipcode: 13110,
    latitude: 14.342,
    longitude: 100.417,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "เจ้าเจ็ด",
    zipcode: 13110,
    latitude: 14.303,
    longitude: 100.378,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "สามกอ",
    zipcode: 13110,
    latitude: 14.306,
    longitude: 100.399,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "บางนมโค",
    zipcode: 13110,
    latitude: 14.292,
    longitude: 100.424,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "หัวเวียง",
    zipcode: 13110,
    latitude: 14.376,
    longitude: 100.402,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "มารวิชัย",
    zipcode: 13110,
    latitude: 14.24,
    longitude: 100.371,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "บ้านโพธิ์",
    zipcode: 13110,
    latitude: 14.351,
    longitude: 100.394,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "รางจรเข้",
    zipcode: 13110,
    latitude: 14.351,
    longitude: 100.366,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "บ้านกระทุ่ม",
    zipcode: 13110,
    latitude: 14.39,
    longitude: 100.383,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "บ้านแถว",
    zipcode: 13110,
    latitude: 14.301,
    longitude: 100.348,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "ชายนา",
    zipcode: 13110,
    latitude: 14.258,
    longitude: 100.348,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "สามตุ่ม",
    zipcode: 13110,
    latitude: 14.24,
    longitude: 100.406,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "ลาดงา",
    zipcode: 13110,
    latitude: 14.372,
    longitude: 100.358,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "ดอนทอง",
    zipcode: 13110,
    latitude: 14.24,
    longitude: 100.306,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "บ้านหลวง",
    zipcode: 13110,
    latitude: 14.278,
    longitude: 100.401,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "เสนา",
    subdistrict: "เจ้าเสด็จ",
    zipcode: 13110,
    latitude: 14.331,
    longitude: 100.363,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางซ้าย",
    subdistrict: "บางซ้าย",
    zipcode: 13270,
    latitude: 14.314,
    longitude: 100.286,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางซ้าย",
    subdistrict: "แก้วฟ้า",
    zipcode: 13270,
    latitude: 14.313,
    longitude: 100.326,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางซ้าย",
    subdistrict: "เต่าเล่า",
    zipcode: 13270,
    latitude: 14.34,
    longitude: 100.319,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางซ้าย",
    subdistrict: "ปลายกลัด",
    zipcode: 13270,
    latitude: 14.382,
    longitude: 100.315,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางซ้าย",
    subdistrict: "เทพมงคล",
    zipcode: 13270,
    latitude: 14.236,
    longitude: 100.258,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บางซ้าย",
    subdistrict: "วังพัฒนา",
    zipcode: 13270,
    latitude: 14.272,
    longitude: 100.296,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "คานหาม",
    zipcode: 13210,
    latitude: 14.335,
    longitude: 100.641,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "บ้านช้าง",
    zipcode: 13210,
    latitude: 14.322,
    longitude: 100.68,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "สามบัณฑิต",
    zipcode: 13210,
    latitude: 14.349,
    longitude: 100.742,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "บ้านหีบ",
    zipcode: 13210,
    latitude: 14.372,
    longitude: 100.712,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "หนองไม้ซุง",
    zipcode: 13210,
    latitude: 14.376,
    longitude: 100.751,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "อุทัย",
    zipcode: 13210,
    latitude: 14.373,
    longitude: 100.655,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "เสนา",
    zipcode: 13210,
    latitude: 14.392,
    longitude: 100.689,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "หนองน้ำส้ม",
    zipcode: 13210,
    latitude: 14.32,
    longitude: 100.714,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "โพสาวหาญ",
    zipcode: 13210,
    latitude: 14.332,
    longitude: 100.759,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "ธนู",
    zipcode: 13210,
    latitude: 14.355,
    longitude: 100.622,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "อุทัย",
    subdistrict: "ข้าวเม่า",
    zipcode: 13210,
    latitude: 14.379,
    longitude: 100.624,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "หัวไผ่",
    zipcode: 13150,
    latitude: 14.532,
    longitude: 100.525,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "กะทุ่ม",
    zipcode: 13150,
    latitude: 14.546,
    longitude: 100.556,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "มหาราช",
    zipcode: 13150,
    latitude: 14.552,
    longitude: 100.528,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "น้ำเต้า",
    zipcode: 13150,
    latitude: 14.566,
    longitude: 100.542,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "บางนา",
    zipcode: 13150,
    latitude: 14.576,
    longitude: 100.581,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "โรงช้าง",
    zipcode: 13150,
    latitude: 14.602,
    longitude: 100.563,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "เจ้าปลุก",
    zipcode: 13150,
    latitude: 14.584,
    longitude: 100.538,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "พิตเพียน",
    zipcode: 13150,
    latitude: 14.608,
    longitude: 100.544,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "บ้านนา",
    zipcode: 13150,
    latitude: 14.606,
    longitude: 100.51,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "บ้านขวาง",
    zipcode: 13150,
    latitude: 14.576,
    longitude: 100.518,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "ท่าตอ",
    zipcode: 13150,
    latitude: 14.556,
    longitude: 100.511,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "มหาราช",
    subdistrict: "บ้านใหม่",
    zipcode: 13150,
    latitude: 14.531,
    longitude: 100.5,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บ้านแพรก",
    subdistrict: "บ้านแพรก",
    zipcode: 13240,
    latitude: 14.64,
    longitude: 100.585,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บ้านแพรก",
    subdistrict: "บ้านใหม่",
    zipcode: 13240,
    latitude: 14.655,
    longitude: 100.586,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บ้านแพรก",
    subdistrict: "สำพะเนียง",
    zipcode: 13240,
    latitude: 14.638,
    longitude: 100.567,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บ้านแพรก",
    subdistrict: "คลองน้อย",
    zipcode: 13240,
    latitude: 14.627,
    longitude: 100.553,
  },
  {
    province: "พระนครศรีอยุธยา",
    district: "บ้านแพรก",
    subdistrict: "สองห้อง",
    zipcode: 13240,
    latitude: 14.646,
    longitude: 100.519,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "ตลาดหลวง",
    zipcode: 14000,
    latitude: 14.592,
    longitude: 100.453,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "บางแก้ว",
    zipcode: 14000,
    latitude: 14.588,
    longitude: 100.459,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "ศาลาแดง",
    zipcode: 14000,
    latitude: 14.599,
    longitude: 100.445,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "ป่างิ้ว",
    zipcode: 14000,
    latitude: 14.612,
    longitude: 100.413,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "บ้านแห",
    zipcode: 14000,
    latitude: 14.565,
    longitude: 100.434,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "ตลาดกรวด",
    zipcode: 14000,
    latitude: 14.619,
    longitude: 100.478,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "มหาดไทย",
    zipcode: 14000,
    latitude: 14.568,
    longitude: 100.4,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "บ้านอิฐ",
    zipcode: 14000,
    latitude: 14.583,
    longitude: 100.476,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "หัวไผ่",
    zipcode: 14000,
    latitude: 14.553,
    longitude: 100.474,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "จำปาหล่อ",
    zipcode: 14000,
    latitude: 14.539,
    longitude: 100.433,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "โพสะ",
    zipcode: 14000,
    latitude: 14.542,
    longitude: 100.457,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "บ้านรี",
    zipcode: 14000,
    latitude: 14.605,
    longitude: 100.485,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "คลองวัว",
    zipcode: 14000,
    latitude: 14.578,
    longitude: 100.432,
  },
  {
    province: "อ่างทอง",
    district: "เมืองอ่างทอง",
    subdistrict: "ย่านซื่อ",
    zipcode: 14000,
    latitude: 14.619,
    longitude: 100.447,
  },
  {
    province: "อ่างทอง",
    district: "ไชโย",
    subdistrict: "จรเข้ร้อง",
    zipcode: 14140,
    latitude: 14.658,
    longitude: 100.482,
  },
  {
    province: "อ่างทอง",
    district: "ไชโย",
    subdistrict: "ไชยภูมิ",
    zipcode: 14140,
    latitude: 14.705,
    longitude: 100.465,
  },
  {
    province: "อ่างทอง",
    district: "ไชโย",
    subdistrict: "ชัยฤทธิ์",
    zipcode: 14140,
    latitude: 14.637,
    longitude: 100.472,
  },
  {
    province: "อ่างทอง",
    district: "ไชโย",
    subdistrict: "เทวราช",
    zipcode: 14140,
    latitude: 14.646,
    longitude: 100.449,
  },
  {
    province: "อ่างทอง",
    district: "ไชโย",
    subdistrict: "ราชสถิตย์",
    zipcode: 14140,
    latitude: 14.672,
    longitude: 100.456,
  },
  {
    province: "อ่างทอง",
    district: "ไชโย",
    subdistrict: "ไชโย",
    zipcode: 14140,
    latitude: 14.707,
    longitude: 100.436,
  },
  {
    province: "อ่างทอง",
    district: "ไชโย",
    subdistrict: "หลักฟ้า",
    zipcode: 14140,
    latitude: 14.684,
    longitude: 100.462,
  },
  {
    province: "อ่างทอง",
    district: "ไชโย",
    subdistrict: "ชะไว",
    zipcode: 14140,
    latitude: 14.687,
    longitude: 100.494,
  },
  {
    province: "อ่างทอง",
    district: "ไชโย",
    subdistrict: "ตรีณรงค์",
    zipcode: 14140,
    latitude: 14.676,
    longitude: 100.498,
  },
  {
    province: "อ่างทอง",
    district: "ป่าโมก",
    subdistrict: "บางปลากด",
    zipcode: 14130,
    latitude: 14.509,
    longitude: 100.461,
  },
  {
    province: "อ่างทอง",
    district: "ป่าโมก",
    subdistrict: "ป่าโมก",
    zipcode: 14130,
    latitude: 14.501,
    longitude: 100.437,
  },
  {
    province: "อ่างทอง",
    district: "ป่าโมก",
    subdistrict: "สายทอง",
    zipcode: 14130,
    latitude: 14.513,
    longitude: 100.484,
  },
  {
    province: "อ่างทอง",
    district: "ป่าโมก",
    subdistrict: "โรงช้าง",
    zipcode: 14130,
    latitude: 14.488,
    longitude: 100.472,
  },
  {
    province: "อ่างทอง",
    district: "ป่าโมก",
    subdistrict: "บางเสด็จ",
    zipcode: 14130,
    latitude: 14.461,
    longitude: 100.473,
  },
  {
    province: "อ่างทอง",
    district: "ป่าโมก",
    subdistrict: "นรสิงห์",
    zipcode: 14130,
    latitude: 14.518,
    longitude: 100.431,
  },
  {
    province: "อ่างทอง",
    district: "ป่าโมก",
    subdistrict: "เอกราช",
    zipcode: 14130,
    latitude: 14.477,
    longitude: 100.426,
  },
  {
    province: "อ่างทอง",
    district: "ป่าโมก",
    subdistrict: "โผงเผง",
    zipcode: 14130,
    latitude: 14.455,
    longitude: 100.442,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "อ่างแก้ว",
    zipcode: 14120,
    latitude: 14.666,
    longitude: 100.393,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "อินทประมูล",
    zipcode: 14120,
    latitude: 14.651,
    longitude: 100.425,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "บางพลับ",
    zipcode: 14120,
    latitude: 14.641,
    longitude: 100.406,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "หนองแม่ไก่",
    zipcode: 14120,
    latitude: 14.708,
    longitude: 100.351,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "รำมะสัก",
    zipcode: 14120,
    latitude: 14.679,
    longitude: 100.242,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "บางระกำ",
    zipcode: 14120,
    latitude: 14.691,
    longitude: 100.428,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "โพธิ์รังนก",
    zipcode: 14120,
    latitude: 14.614,
    longitude: 100.378,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "องครักษ์",
    zipcode: 14120,
    latitude: 14.731,
    longitude: 100.398,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "โคกพุทรา",
    zipcode: 14120,
    latitude: 14.689,
    longitude: 100.375,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "ยางช้าย",
    zipcode: 14120,
    latitude: 14.6612672,
    longitude: 100.276187,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "บ่อแร่",
    zipcode: 14120,
    latitude: 14.637,
    longitude: 100.372,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "ทางพระ",
    zipcode: 14120,
    latitude: 14.677,
    longitude: 100.36,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "สามง่าม",
    zipcode: 14120,
    latitude: 14.638,
    longitude: 100.389,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "บางเจ้าฉ่า",
    zipcode: 14120,
    latitude: 14.695,
    longitude: 100.398,
  },
  {
    province: "อ่างทอง",
    district: "โพธิ์ทอง",
    subdistrict: "คำหยาด",
    zipcode: 14120,
    latitude: 14.676,
    longitude: 100.323,
  },
  {
    province: "อ่างทอง",
    district: "แสวงหา",
    subdistrict: "แสวงหา",
    zipcode: 14150,
    latitude: 14.763,
    longitude: 100.31,
  },
  {
    province: "อ่างทอง",
    district: "แสวงหา",
    subdistrict: "ศรีพราน",
    zipcode: 14150,
    latitude: 14.725,
    longitude: 100.328,
  },
  {
    province: "อ่างทอง",
    district: "แสวงหา",
    subdistrict: "บ้านพราน",
    zipcode: 14150,
    latitude: 14.709,
    longitude: 100.306,
  },
  {
    province: "อ่างทอง",
    district: "แสวงหา",
    subdistrict: "วังน้ำเย็น",
    zipcode: 14150,
    latitude: 14.738,
    longitude: 100.221,
  },
  {
    province: "อ่างทอง",
    district: "แสวงหา",
    subdistrict: "สีบัวทอง",
    zipcode: 14150,
    latitude: 14.769,
    longitude: 100.248,
  },
  {
    province: "อ่างทอง",
    district: "แสวงหา",
    subdistrict: "ห้วยไผ่",
    zipcode: 14150,
    latitude: 14.789,
    longitude: 100.324,
  },
  {
    province: "อ่างทอง",
    district: "แสวงหา",
    subdistrict: "จำลอง",
    zipcode: 14150,
    latitude: 14.741,
    longitude: 100.364,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "ไผ่จำศิล",
    zipcode: 14110,
    latitude: 14.5934503,
    longitude: 100.3449169,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "ศาลเจ้าโรงทอง",
    zipcode: 14110,
    latitude: 14.597,
    longitude: 100.34,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "ไผ่ดำพัฒนา",
    zipcode: 14110,
    latitude: 14.529,
    longitude: 100.392,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "สาวร้องไห้",
    zipcode: 14110,
    latitude: 14.546,
    longitude: 100.223,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "ท่าช้าง",
    zipcode: 14110,
    latitude: 14.562,
    longitude: 100.333,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "ยี่ล้น",
    zipcode: 14110,
    latitude: 14.579,
    longitude: 100.301,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "บางจัก",
    zipcode: 14110,
    latitude: 14.502,
    longitude: 100.33,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "ห้วยคันแหลน",
    zipcode: 14110,
    latitude: 14.555,
    longitude: 100.294,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "คลองขนาก",
    zipcode: 14110,
    latitude: 14.53,
    longitude: 100.337,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "ไผ่วง",
    zipcode: 14110,
    latitude: 14.505,
    longitude: 100.251,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "สี่ร้อย",
    zipcode: 14110,
    latitude: 14.55,
    longitude: 100.37,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "ม่วงเตี้ย",
    zipcode: 14110,
    latitude: 14.636,
    longitude: 100.323,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "หัวตะพาน",
    zipcode: 14110,
    latitude: 14.583,
    longitude: 100.387,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "หลักแก้ว",
    zipcode: 14110,
    latitude: 14.517,
    longitude: 100.289,
  },
  {
    province: "อ่างทอง",
    district: "วิเศษชัยชาญ",
    subdistrict: "ตลาดใหม่",
    zipcode: 14110,
    latitude: 14.542,
    longitude: 100.315,
  },
  {
    province: "อ่างทอง",
    district: "สามโก้",
    subdistrict: "สามโก้",
    zipcode: 14160,
    latitude: 14.606,
    longitude: 100.252,
  },
  {
    province: "อ่างทอง",
    district: "สามโก้",
    subdistrict: "ราษฎรพัฒนา",
    zipcode: 14160,
    latitude: 14.605,
    longitude: 100.293,
  },
  {
    province: "อ่างทอง",
    district: "สามโก้",
    subdistrict: "อบทม",
    zipcode: 14160,
    latitude: 14.581,
    longitude: 100.246,
  },
  {
    province: "อ่างทอง",
    district: "สามโก้",
    subdistrict: "โพธิ์ม่วงพันธ์",
    zipcode: 14160,
    latitude: 14.561,
    longitude: 100.254,
  },
  {
    province: "อ่างทอง",
    district: "สามโก้",
    subdistrict: "มงคลธรรมนิมิต",
    zipcode: 14160,
    latitude: 14.64,
    longitude: 100.26,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "ทะเลชุบศร",
    zipcode: 15000,
    latitude: 14.804,
    longitude: 100.634,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "ท่าหิน",
    zipcode: 15000,
    latitude: 14.802,
    longitude: 100.611,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "กกโก",
    zipcode: 15000,
    latitude: 14.767,
    longitude: 100.684,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "โก่งธนู",
    zipcode: 13240,
    latitude: 14.674,
    longitude: 100.597,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "เขาพระงาม",
    zipcode: 15160,
    latitude: 14.901,
    longitude: 100.67,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "เขาสามยอด",
    zipcode: 15000,
    latitude: 14.83,
    longitude: 100.671,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "โคกกะเทียม",
    zipcode: 15160,
    latitude: 14.8898976,
    longitude: 100.5453076,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "โคกลำพาน",
    zipcode: 15000,
    latitude: 14.74,
    longitude: 100.64,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "โคกตูม",
    zipcode: 15210,
    latitude: 14.866,
    longitude: 100.817,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "งิ้วราย",
    zipcode: 15000,
    latitude: 14.704,
    longitude: 100.592,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "ดอนโพธิ์",
    zipcode: 15000,
    latitude: 14.691,
    longitude: 100.627,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "ตะลุง",
    zipcode: 15000,
    latitude: 14.737,
    longitude: 100.601,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "ท่าแค",
    zipcode: 15000,
    latitude: 14.865,
    longitude: 100.619,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "ท่าศาลา",
    zipcode: 15000,
    latitude: 14.792,
    longitude: 100.671,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "นิคมสร้างตนเอง",
    zipcode: 15000,
    latitude: 14.824,
    longitude: 100.732,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "บางขันหมาก",
    zipcode: 15000,
    latitude: 14.82,
    longitude: 100.583,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "บ้านข่อย",
    zipcode: 15000,
    latitude: 14.683,
    longitude: 100.529,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "ท้ายตลาด",
    zipcode: 15000,
    latitude: 14.725,
    longitude: 100.563,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "ป่าตาล",
    zipcode: 15000,
    latitude: 14.778,
    longitude: 100.64,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "พรหมมาสตร์",
    zipcode: 15000,
    latitude: 14.82,
    longitude: 100.603,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "โพธิ์เก้าต้น",
    zipcode: 15000,
    latitude: 14.775,
    longitude: 100.599,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "โพธิ์ตรุ",
    zipcode: 15000,
    latitude: 14.725,
    longitude: 100.532,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "สี่คลอง",
    zipcode: 15000,
    latitude: 14.681,
    longitude: 100.563,
  },
  {
    province: "ลพบุรี",
    district: "เมืองลพบุรี",
    subdistrict: "ถนนใหญ่",
    zipcode: 15000,
    latitude: 14.831,
    longitude: 100.629,
  },
  {
    province: "ลพบุรี",
    district: "พัฒนานิคม",
    subdistrict: "พัฒนานิคม",
    zipcode: 15140,
    latitude: 14.896,
    longitude: 100.966,
  },
  {
    province: "ลพบุรี",
    district: "พัฒนานิคม",
    subdistrict: "ช่องสาริกา",
    zipcode: 15220,
    latitude: 14.802,
    longitude: 100.909,
  },
  {
    province: "ลพบุรี",
    district: "พัฒนานิคม",
    subdistrict: "มะนาวหวาน",
    zipcode: 15140,
    latitude: 14.934,
    longitude: 101.093,
  },
  {
    province: "ลพบุรี",
    district: "พัฒนานิคม",
    subdistrict: "ดีลัง",
    zipcode: 15220,
    latitude: 14.94,
    longitude: 100.852,
  },
  {
    province: "ลพบุรี",
    district: "พัฒนานิคม",
    subdistrict: "โคกสลุง",
    zipcode: 15140,
    latitude: 15.01,
    longitude: 100.969,
  },
  {
    province: "ลพบุรี",
    district: "พัฒนานิคม",
    subdistrict: "ชอนน้อย",
    zipcode: 15140,
    latitude: 14.795,
    longitude: 100.985,
  },
  {
    province: "ลพบุรี",
    district: "พัฒนานิคม",
    subdistrict: "หนองบัว",
    zipcode: 15140,
    latitude: 14.851,
    longitude: 101.054,
  },
  {
    province: "ลพบุรี",
    district: "พัฒนานิคม",
    subdistrict: "ห้วยขุนราม",
    zipcode: 18220,
    latitude: 14.951,
    longitude: 101.205,
  },
  {
    province: "ลพบุรี",
    district: "พัฒนานิคม",
    subdistrict: "น้ำสุด",
    zipcode: 15140,
    latitude: 14.952,
    longitude: 101.146,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "โคกสำโรง",
    zipcode: 15120,
    latitude: 15.071,
    longitude: 100.713,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "เกาะแก้ว",
    zipcode: 15120,
    latitude: 15.136,
    longitude: 100.736,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "ถลุงเหล็ก",
    zipcode: 15120,
    latitude: 15.064,
    longitude: 100.68,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "หลุมข้าว",
    zipcode: 15120,
    latitude: 15.024,
    longitude: 100.642,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "ห้วยโป่ง",
    zipcode: 15120,
    latitude: 14.981,
    longitude: 100.677,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "คลองเกตุ",
    zipcode: 15120,
    latitude: 14.967,
    longitude: 100.802,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "สะแกราบ",
    zipcode: 15120,
    latitude: 15.12,
    longitude: 100.797,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "เพนียด",
    zipcode: 15120,
    latitude: 15.036,
    longitude: 100.812,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "วังเพลิง",
    zipcode: 15120,
    latitude: 15.062,
    longitude: 100.841,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "ดงมะรุม",
    zipcode: 15120,
    latitude: 15.127,
    longitude: 100.877,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "วังขอนขว้าง",
    zipcode: 15120,
    latitude: 15.023,
    longitude: 100.697,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "วังจั่น",
    zipcode: 15120,
    latitude: 15.102,
    longitude: 100.673,
  },
  {
    province: "ลพบุรี",
    district: "โคกสำโรง",
    subdistrict: "หนองแขม",
    zipcode: 15120,
    latitude: 15.143,
    longitude: 100.685,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "ลำนารายณ์",
    zipcode: 15130,
    latitude: 15.214,
    longitude: 101.146,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "ชัยนารายณ์",
    zipcode: 15130,
    latitude: 15.195,
    longitude: 101.037,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "ศิลาทิพย์",
    zipcode: 15130,
    latitude: 15.314,
    longitude: 101.087,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "ห้วยหิน",
    zipcode: 15130,
    latitude: 15.154,
    longitude: 101.044,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "ม่วงค่อม",
    zipcode: 15230,
    latitude: 15.078,
    longitude: 101.024,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "บัวชุม",
    zipcode: 15130,
    latitude: 15.176,
    longitude: 101.238,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "ท่าดินดำ",
    zipcode: 15130,
    latitude: 15.15,
    longitude: 101.134,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "มะกอกหวาน",
    zipcode: 15230,
    latitude: 15.059,
    longitude: 101.077,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "ซับตะเคียน",
    zipcode: 15130,
    latitude: 15.112,
    longitude: 101.327,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "นาโสม",
    zipcode: 15190,
    latitude: 15.245,
    longitude: 101.282,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "หนองยายโต๊ะ",
    zipcode: 15130,
    latitude: 15.22,
    longitude: 101.264,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "เกาะรัง",
    zipcode: 15130,
    latitude: 15.318,
    longitude: 101.251,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "ท่ามะนาว",
    zipcode: 15130,
    latitude: 15.187,
    longitude: 101.162,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "นิคมลำนารายณ์",
    zipcode: 15130,
    latitude: 15.264,
    longitude: 101.15,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "ชัยบาดาล",
    zipcode: 15230,
    latitude: 15.095,
    longitude: 101.058,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "บ้านใหม่สามัคคี",
    zipcode: 15130,
    latitude: 15.258,
    longitude: 101.022,
  },
  {
    province: "ลพบุรี",
    district: "ชัยบาดาล",
    subdistrict: "เขาแหลม",
    zipcode: 15130,
    latitude: 15.126,
    longitude: 100.964,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "ท่าวุ้ง",
    zipcode: 15150,
    latitude: 14.82,
    longitude: 100.496,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "บางคู้",
    zipcode: 15150,
    latitude: 14.804,
    longitude: 100.529,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "โพตลาดแก้ว",
    zipcode: 15150,
    latitude: 14.795,
    longitude: 100.558,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "บางลี่",
    zipcode: 15150,
    latitude: 14.849,
    longitude: 100.526,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "บางงา",
    zipcode: 15150,
    latitude: 14.85,
    longitude: 100.492,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "โคกสลุด",
    zipcode: 15150,
    latitude: 14.881,
    longitude: 100.482,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "เขาสมอคอน",
    zipcode: 15180,
    latitude: 14.906,
    longitude: 100.447,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "หัวสำโรง",
    zipcode: 15150,
    latitude: 14.776,
    longitude: 100.494,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "ลาดสาลี่",
    zipcode: 15150,
    latitude: 14.723,
    longitude: 100.487,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "บ้านเบิก",
    zipcode: 15150,
    latitude: 14.7112567,
    longitude: 100.4521055,
  },
  {
    province: "ลพบุรี",
    district: "ท่าวุ้ง",
    subdistrict: "มุจลินท์",
    zipcode: 15150,
    latitude: 14.876,
    longitude: 100.509,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "ไผ่ใหญ่",
    zipcode: 15110,
    latitude: 15.089,
    longitude: 100.459,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "บ้านทราย",
    zipcode: 15110,
    latitude: 15.034,
    longitude: 100.582,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "บ้านกล้วย",
    zipcode: 15110,
    latitude: 15.016,
    longitude: 100.578,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "ดงพลับ",
    zipcode: 15110,
    latitude: 15.059,
    longitude: 100.63,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "บ้านชี",
    zipcode: 15180,
    latitude: 14.947,
    longitude: 100.5,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "พุคา",
    zipcode: 15110,
    latitude: 14.953,
    longitude: 100.598,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "หินปัก",
    zipcode: 15110,
    latitude: 15.048,
    longitude: 100.592,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "บางพึ่ง",
    zipcode: 15110,
    latitude: 14.994,
    longitude: 100.496,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "หนองทรายขาว",
    zipcode: 15110,
    latitude: 14.996,
    longitude: 100.597,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "บางกะพี้",
    zipcode: 15110,
    latitude: 15.073,
    longitude: 100.613,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "หนองเต่า",
    zipcode: 15110,
    latitude: 14.963,
    longitude: 100.555,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "โพนทอง",
    zipcode: 15110,
    latitude: 15.04,
    longitude: 100.559,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "บางขาม",
    zipcode: 15180,
    latitude: 14.953,
    longitude: 100.457,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "ดอนดึง",
    zipcode: 15110,
    latitude: 15.146,
    longitude: 100.612,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "ชอนม่วง",
    zipcode: 15110,
    latitude: 15.21,
    longitude: 100.61,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "หนองกระเบียน",
    zipcode: 15110,
    latitude: 15.148,
    longitude: 100.534,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "สายห้วยแก้ว",
    zipcode: 15110,
    latitude: 15.095,
    longitude: 100.509,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "มหาสอน",
    zipcode: 15110,
    latitude: 15.021,
    longitude: 100.484,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "บ้านหมี่",
    zipcode: 15110,
    latitude: 15.0797202,
    longitude: 100.3741048,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "เชียงงา",
    zipcode: 15110,
    latitude: 15.0552751,
    longitude: 100.4854999,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "หนองเมือง",
    zipcode: 15110,
    latitude: 15.124,
    longitude: 100.569,
  },
  {
    province: "ลพบุรี",
    district: "บ้านหมี่",
    subdistrict: "สนามแจง",
    zipcode: 15110,
    latitude: 15.008,
    longitude: 100.538,
  },
  {
    province: "ลพบุรี",
    district: "ท่าหลวง",
    subdistrict: "ท่าหลวง",
    zipcode: 15230,
    latitude: 15.071,
    longitude: 101.117,
  },
  {
    province: "ลพบุรี",
    district: "ท่าหลวง",
    subdistrict: "แก่งผักกูด",
    zipcode: 15230,
    latitude: 15.014,
    longitude: 101.085,
  },
  {
    province: "ลพบุรี",
    district: "ท่าหลวง",
    subdistrict: "ซับจำปา",
    zipcode: 15230,
    latitude: 15.026,
    longitude: 101.228,
  },
  {
    province: "ลพบุรี",
    district: "ท่าหลวง",
    subdistrict: "หนองผักแว่น",
    zipcode: 15230,
    latitude: 15.119,
    longitude: 101.216,
  },
  {
    province: "ลพบุรี",
    district: "ท่าหลวง",
    subdistrict: "ทะเลวังวัด",
    zipcode: 15230,
    latitude: 15.023,
    longitude: 101.184,
  },
  {
    province: "ลพบุรี",
    district: "ท่าหลวง",
    subdistrict: "หัวลำ",
    zipcode: 15230,
    latitude: 15.029,
    longitude: 101.292,
  },
  {
    province: "ลพบุรี",
    district: "สระโบสถ์",
    subdistrict: "สระโบสถ์",
    zipcode: 15240,
    latitude: 15.224,
    longitude: 100.846,
  },
  {
    province: "ลพบุรี",
    district: "สระโบสถ์",
    subdistrict: "มหาโพธิ",
    zipcode: 15240,
    latitude: 15.289,
    longitude: 100.861,
  },
  {
    province: "ลพบุรี",
    district: "สระโบสถ์",
    subdistrict: "ทุ่งท่าช้าง",
    zipcode: 15240,
    latitude: 15.169,
    longitude: 100.817,
  },
  {
    province: "ลพบุรี",
    district: "สระโบสถ์",
    subdistrict: "ห้วยใหญ่",
    zipcode: 15240,
    latitude: 15.169,
    longitude: 100.793,
  },
  {
    province: "ลพบุรี",
    district: "สระโบสถ์",
    subdistrict: "นิยมชัย",
    zipcode: 15240,
    latitude: 15.183,
    longitude: 100.904,
  },
  {
    province: "ลพบุรี",
    district: "โคกเจริญ",
    subdistrict: "โคกเจริญ",
    zipcode: 15250,
    latitude: 15.408,
    longitude: 100.777,
  },
  {
    province: "ลพบุรี",
    district: "โคกเจริญ",
    subdistrict: "ยางราก",
    zipcode: 15250,
    latitude: 15.366,
    longitude: 100.935,
  },
  {
    province: "ลพบุรี",
    district: "โคกเจริญ",
    subdistrict: "หนองมะค่า",
    zipcode: 15250,
    latitude: 15.515,
    longitude: 100.839,
  },
  {
    province: "ลพบุรี",
    district: "โคกเจริญ",
    subdistrict: "วังทอง",
    zipcode: 15250,
    latitude: 15.469,
    longitude: 100.811,
  },
  {
    province: "ลพบุรี",
    district: "โคกเจริญ",
    subdistrict: "โคกแสมสาร",
    zipcode: 15250,
    latitude: 15.344,
    longitude: 100.85,
  },
  {
    province: "ลพบุรี",
    district: "ลำสนธิ",
    subdistrict: "ลำสนธิ",
    zipcode: 15190,
    latitude: 15.341,
    longitude: 101.362,
  },
  {
    province: "ลพบุรี",
    district: "ลำสนธิ",
    subdistrict: "ซับสมบูรณ์",
    zipcode: 15190,
    latitude: 15.193,
    longitude: 101.354,
  },
  {
    province: "ลพบุรี",
    district: "ลำสนธิ",
    subdistrict: "หนองรี",
    zipcode: 15190,
    latitude: 15.282,
    longitude: 101.319,
  },
  {
    province: "ลพบุรี",
    district: "ลำสนธิ",
    subdistrict: "กุดตาเพชร",
    zipcode: 15190,
    latitude: 15.523,
    longitude: 101.346,
  },
  {
    province: "ลพบุรี",
    district: "ลำสนธิ",
    subdistrict: "เขารวก",
    zipcode: 15190,
    latitude: 15.374,
    longitude: 101.33,
  },
  {
    province: "ลพบุรี",
    district: "ลำสนธิ",
    subdistrict: "เขาน้อย",
    zipcode: 15130,
    latitude: 15.112,
    longitude: 101.387,
  },
  {
    province: "ลพบุรี",
    district: "หนองม่วง",
    subdistrict: "หนองม่วง",
    zipcode: 15170,
    latitude: 15.23,
    longitude: 100.664,
  },
  {
    province: "ลพบุรี",
    district: "หนองม่วง",
    subdistrict: "บ่อทอง",
    zipcode: 15170,
    latitude: 15.293,
    longitude: 100.652,
  },
  {
    province: "ลพบุรี",
    district: "หนองม่วง",
    subdistrict: "ดงดินแดง",
    zipcode: 15170,
    latitude: 15.385,
    longitude: 100.679,
  },
  {
    province: "ลพบุรี",
    district: "หนองม่วง",
    subdistrict: "ชอนสมบูรณ์",
    zipcode: 15170,
    latitude: 15.288,
    longitude: 100.763,
  },
  {
    province: "ลพบุรี",
    district: "หนองม่วง",
    subdistrict: "ยางโทน",
    zipcode: 15170,
    latitude: 15.248,
    longitude: 100.702,
  },
  {
    province: "ลพบุรี",
    district: "หนองม่วง",
    subdistrict: "ชอนสารเดช",
    zipcode: 15170,
    latitude: 15.195,
    longitude: 100.709,
  },
  {
    province: "สิงห์บุรี",
    district: "เมืองสิงห์บุรี",
    subdistrict: "บางพุทรา",
    zipcode: 16000,
    latitude: 14.89,
    longitude: 100.404,
  },
  {
    province: "สิงห์บุรี",
    district: "เมืองสิงห์บุรี",
    subdistrict: "บางมัญ",
    zipcode: 16000,
    latitude: 14.916,
    longitude: 100.4,
  },
  {
    province: "สิงห์บุรี",
    district: "เมืองสิงห์บุรี",
    subdistrict: "โพกรวม",
    zipcode: 16000,
    latitude: 14.944,
    longitude: 100.394,
  },
  {
    province: "สิงห์บุรี",
    district: "เมืองสิงห์บุรี",
    subdistrict: "ม่วงหมู่",
    zipcode: 16000,
    latitude: 14.869,
    longitude: 100.432,
  },
  {
    province: "สิงห์บุรี",
    district: "เมืองสิงห์บุรี",
    subdistrict: "หัวไผ่",
    zipcode: 16000,
    latitude: 14.965,
    longitude: 100.429,
  },
  {
    province: "สิงห์บุรี",
    district: "เมืองสิงห์บุรี",
    subdistrict: "ต้นโพธิ์",
    zipcode: 16000,
    latitude: 14.869,
    longitude: 100.398,
  },
  {
    province: "สิงห์บุรี",
    district: "เมืองสิงห์บุรี",
    subdistrict: "จักรสีห์",
    zipcode: 16000,
    latitude: 14.836,
    longitude: 100.398,
  },
  {
    province: "สิงห์บุรี",
    district: "เมืองสิงห์บุรี",
    subdistrict: "บางกระบือ",
    zipcode: 16000,
    latitude: 14.907,
    longitude: 100.363,
  },
  {
    province: "สิงห์บุรี",
    district: "บางระจัน",
    subdistrict: "สิงห์",
    zipcode: 16130,
    latitude: 14.89,
    longitude: 100.338,
  },
  {
    province: "สิงห์บุรี",
    district: "บางระจัน",
    subdistrict: "ไม้ดัด",
    zipcode: 16130,
    latitude: 14.858,
    longitude: 100.345,
  },
  {
    province: "สิงห์บุรี",
    district: "บางระจัน",
    subdistrict: "เชิงกลัด",
    zipcode: 16130,
    latitude: 14.91,
    longitude: 100.269,
  },
  {
    province: "สิงห์บุรี",
    district: "บางระจัน",
    subdistrict: "โพชนไก่",
    zipcode: 16130,
    latitude: 14.929,
    longitude: 100.292,
  },
  {
    province: "สิงห์บุรี",
    district: "บางระจัน",
    subdistrict: "แม่ลา",
    zipcode: 16130,
    latitude: 14.941,
    longitude: 100.319,
  },
  {
    province: "สิงห์บุรี",
    district: "บางระจัน",
    subdistrict: "บ้านจ่า",
    zipcode: 16130,
    latitude: 14.852,
    longitude: 100.284,
  },
  {
    province: "สิงห์บุรี",
    district: "บางระจัน",
    subdistrict: "พักทัน",
    zipcode: 16130,
    latitude: 14.924,
    longitude: 100.22,
  },
  {
    province: "สิงห์บุรี",
    district: "บางระจัน",
    subdistrict: "สระแจง",
    zipcode: 16130,
    latitude: 14.873,
    longitude: 100.235,
  },
  {
    province: "สิงห์บุรี",
    district: "ค่ายบางระจัน",
    subdistrict: "โพทะเล",
    zipcode: 16150,
    latitude: 14.829,
    longitude: 100.257,
  },
  {
    province: "สิงห์บุรี",
    district: "ค่ายบางระจัน",
    subdistrict: "บางระจัน",
    zipcode: 16150,
    latitude: 14.799,
    longitude: 100.296,
  },
  {
    province: "สิงห์บุรี",
    district: "ค่ายบางระจัน",
    subdistrict: "โพสังโฆ",
    zipcode: 16150,
    latitude: 14.833,
    longitude: 100.349,
  },
  {
    province: "สิงห์บุรี",
    district: "ค่ายบางระจัน",
    subdistrict: "ท่าข้าม",
    zipcode: 16150,
    latitude: 14.827,
    longitude: 100.368,
  },
  {
    province: "สิงห์บุรี",
    district: "ค่ายบางระจัน",
    subdistrict: "คอทราย",
    zipcode: 16150,
    latitude: 14.817,
    longitude: 100.281,
  },
  {
    province: "สิงห์บุรี",
    district: "ค่ายบางระจัน",
    subdistrict: "หนองกระทุ่ม",
    zipcode: 16150,
    latitude: 14.82,
    longitude: 100.225,
  },
  {
    province: "สิงห์บุรี",
    district: "พรหมบุรี",
    subdistrict: "พระงาม",
    zipcode: 16120,
    latitude: 14.748,
    longitude: 100.422,
  },
  {
    province: "สิงห์บุรี",
    district: "พรหมบุรี",
    subdistrict: "พรหมบุรี",
    zipcode: 16160,
    latitude: 14.837,
    longitude: 100.451,
  },
  {
    province: "สิงห์บุรี",
    district: "พรหมบุรี",
    subdistrict: "บางน้ำเชี่ยว",
    zipcode: 16120,
    latitude: 14.783,
    longitude: 100.458,
  },
  {
    province: "สิงห์บุรี",
    district: "พรหมบุรี",
    subdistrict: "บ้านหม้อ",
    zipcode: 16120,
    latitude: 14.746,
    longitude: 100.461,
  },
  {
    province: "สิงห์บุรี",
    district: "พรหมบุรี",
    subdistrict: "บ้านแป้ง",
    zipcode: 16120,
    latitude: 14.811,
    longitude: 100.458,
  },
  {
    province: "สิงห์บุรี",
    district: "พรหมบุรี",
    subdistrict: "หัวป่า",
    zipcode: 16120,
    latitude: 14.831,
    longitude: 100.425,
  },
  {
    province: "สิงห์บุรี",
    district: "พรหมบุรี",
    subdistrict: "โรงช้าง",
    zipcode: 16120,
    latitude: 14.801,
    longitude: 100.425,
  },
  {
    province: "สิงห์บุรี",
    district: "ท่าช้าง",
    subdistrict: "ถอนสมอ",
    zipcode: 16140,
    latitude: 14.765,
    longitude: 100.384,
  },
  {
    province: "สิงห์บุรี",
    district: "ท่าช้าง",
    subdistrict: "โพประจักษ์",
    zipcode: 16140,
    latitude: 14.774,
    longitude: 100.36,
  },
  {
    province: "สิงห์บุรี",
    district: "ท่าช้าง",
    subdistrict: "วิหารขาว",
    zipcode: 16140,
    latitude: 14.801,
    longitude: 100.409,
  },
  {
    province: "สิงห์บุรี",
    district: "ท่าช้าง",
    subdistrict: "พิกุลทอง",
    zipcode: 16140,
    latitude: 14.772,
    longitude: 100.41,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "อินทร์บุรี",
    zipcode: 16110,
    latitude: 15.009,
    longitude: 100.329,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "ประศุก",
    zipcode: 16110,
    latitude: 15.042,
    longitude: 100.291,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "ทับยา",
    zipcode: 16110,
    latitude: 14.96,
    longitude: 100.334,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "งิ้วราย",
    zipcode: 16110,
    latitude: 14.988,
    longitude: 100.389,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "ชีน้ำร้าย",
    zipcode: 16110,
    latitude: 15.081,
    longitude: 100.331,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "ท่างาม",
    zipcode: 16110,
    latitude: 15.041,
    longitude: 100.343,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "น้ำตาล",
    zipcode: 16110,
    latitude: 14.976,
    longitude: 100.361,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "ทองเอน",
    zipcode: 16110,
    latitude: 15.046,
    longitude: 100.385,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "ห้วยชัน",
    zipcode: 16110,
    latitude: 15.019,
    longitude: 100.269,
  },
  {
    province: "สิงห์บุรี",
    district: "อินทร์บุรี",
    subdistrict: "โพธิ์ชัย",
    zipcode: 16110,
    latitude: 15.027,
    longitude: 100.439,
  },
  {
    province: "ชัยนาท",
    district: "เมืองชัยนาท",
    subdistrict: "ในเมือง",
    zipcode: 17000,
    latitude: 15.181,
    longitude: 100.127,
  },
  {
    province: "ชัยนาท",
    district: "เมืองชัยนาท",
    subdistrict: "บ้านกล้วย",
    zipcode: 17000,
    latitude: 15.182,
    longitude: 100.153,
  },
  {
    province: "ชัยนาท",
    district: "เมืองชัยนาท",
    subdistrict: "ท่าชัย",
    zipcode: 17000,
    latitude: 15.163,
    longitude: 100.113,
  },
  {
    province: "ชัยนาท",
    district: "เมืองชัยนาท",
    subdistrict: "ชัยนาท",
    zipcode: 17000,
    latitude: 15.129,
    longitude: 100.151,
  },
  {
    province: "ชัยนาท",
    district: "เมืองชัยนาท",
    subdistrict: "เขาท่าพระ",
    zipcode: 17000,
    latitude: 15.218,
    longitude: 100.134,
  },
  {
    province: "ชัยนาท",
    district: "เมืองชัยนาท",
    subdistrict: "หาดท่าเสา",
    zipcode: 17000,
    latitude: 15.2,
    longitude: 100.079,
  },
  {
    province: "ชัยนาท",
    district: "เมืองชัยนาท",
    subdistrict: "ธรรมามูล",
    zipcode: 17000,
    latitude: 15.264,
    longitude: 100.133,
  },
  {
    province: "ชัยนาท",
    district: "เมืองชัยนาท",
    subdistrict: "เสือโฮก",
    zipcode: 17000,
    latitude: 15.238,
    longitude: 100.224,
  },
  {
    province: "ชัยนาท",
    district: "เมืองชัยนาท",
    subdistrict: "นางลือ",
    zipcode: 17000,
    latitude: 15.122,
    longitude: 100.082,
  },
  {
    province: "ชัยนาท",
    district: "มโนรมย์",
    subdistrict: "คุ้งสำเภา",
    zipcode: 17110,
    latitude: 15.291,
    longitude: 100.091,
  },
  {
    province: "ชัยนาท",
    district: "มโนรมย์",
    subdistrict: "วัดโคก",
    zipcode: 17110,
    latitude: 15.326,
    longitude: 100.107,
  },
  {
    province: "ชัยนาท",
    district: "มโนรมย์",
    subdistrict: "ศิลาดาน",
    zipcode: 17110,
    latitude: 15.354,
    longitude: 100.122,
  },
  {
    province: "ชัยนาท",
    district: "มโนรมย์",
    subdistrict: "ท่าฉนวน",
    zipcode: 17110,
    latitude: 15.379,
    longitude: 100.138,
  },
  {
    province: "ชัยนาท",
    district: "มโนรมย์",
    subdistrict: "หางน้ำสาคร",
    zipcode: 17170,
    latitude: 15.292,
    longitude: 100.146,
  },
  {
    province: "ชัยนาท",
    district: "มโนรมย์",
    subdistrict: "ไร่พัฒนา",
    zipcode: 17170,
    latitude: 15.333,
    longitude: 100.225,
  },
  {
    province: "ชัยนาท",
    district: "มโนรมย์",
    subdistrict: "อู่ตะเภา",
    zipcode: 17170,
    latitude: 15.274,
    longitude: 100.219,
  },
  {
    province: "ชัยนาท",
    district: "วัดสิงห์",
    subdistrict: "วัดสิงห์",
    zipcode: 17120,
    latitude: 15.263,
    longitude: 100.041,
  },
  {
    province: "ชัยนาท",
    district: "วัดสิงห์",
    subdistrict: "มะขามเฒ่า",
    zipcode: 17120,
    latitude: 15.227,
    longitude: 100.04,
  },
  {
    province: "ชัยนาท",
    district: "วัดสิงห์",
    subdistrict: "หนองน้อย",
    zipcode: 17120,
    latitude: 15.177,
    longitude: 100.015,
  },
  {
    province: "ชัยนาท",
    district: "วัดสิงห์",
    subdistrict: "หนองบัว",
    zipcode: 17120,
    latitude: 15.283,
    longitude: 100.013,
  },
  {
    province: "ชัยนาท",
    district: "วัดสิงห์",
    subdistrict: "หนองขุ่น",
    zipcode: 17120,
    latitude: 15.235,
    longitude: 99.97,
  },
  {
    province: "ชัยนาท",
    district: "วัดสิงห์",
    subdistrict: "บ่อแร่",
    zipcode: 17120,
    latitude: 15.283,
    longitude: 99.958,
  },
  {
    province: "ชัยนาท",
    district: "วัดสิงห์",
    subdistrict: "วังหมัน",
    zipcode: 17120,
    latitude: 15.171,
    longitude: 99.916,
  },
  {
    province: "ชัยนาท",
    district: "สรรพยา",
    subdistrict: "สรรพยา",
    zipcode: 17150,
    latitude: 15.128,
    longitude: 100.233,
  },
  {
    province: "ชัยนาท",
    district: "สรรพยา",
    subdistrict: "ตลุก",
    zipcode: 17150,
    latitude: 15.195,
    longitude: 100.198,
  },
  {
    province: "ชัยนาท",
    district: "สรรพยา",
    subdistrict: "เขาแก้ว",
    zipcode: 17150,
    latitude: 15.142,
    longitude: 100.316,
  },
  {
    province: "ชัยนาท",
    district: "สรรพยา",
    subdistrict: "โพนางดำตก",
    zipcode: 17150,
    latitude: 15.084,
    longitude: 100.272,
  },
  {
    province: "ชัยนาท",
    district: "สรรพยา",
    subdistrict: "โพนางดำออก",
    zipcode: 17150,
    latitude: 15.12,
    longitude: 100.283,
  },
  {
    province: "ชัยนาท",
    district: "สรรพยา",
    subdistrict: "บางหลวง",
    zipcode: 17150,
    latitude: 15.143,
    longitude: 100.197,
  },
  {
    province: "ชัยนาท",
    district: "สรรพยา",
    subdistrict: "หาดอาษา",
    zipcode: 17150,
    latitude: 15.165,
    longitude: 100.262,
  },
  {
    province: "ชัยนาท",
    district: "สรรคบุรี",
    subdistrict: "แพรกศรีราชา",
    zipcode: 17140,
    latitude: 15.062,
    longitude: 100.107,
  },
  {
    province: "ชัยนาท",
    district: "สรรคบุรี",
    subdistrict: "เที่ยงแท้",
    zipcode: 17140,
    latitude: 15.089,
    longitude: 100.161,
  },
  {
    province: "ชัยนาท",
    district: "สรรคบุรี",
    subdistrict: "ห้วยกรด",
    zipcode: 17140,
    latitude: 15.096,
    longitude: 100.203,
  },
  {
    province: "ชัยนาท",
    district: "สรรคบุรี",
    subdistrict: "โพงาม",
    zipcode: 17140,
    latitude: 14.995,
    longitude: 100.249,
  },
  {
    province: "ชัยนาท",
    district: "สรรคบุรี",
    subdistrict: "บางขุด",
    zipcode: 17140,
    latitude: 14.991,
    longitude: 100.194,
  },
  {
    province: "ชัยนาท",
    district: "สรรคบุรี",
    subdistrict: "ดงคอน",
    zipcode: 17140,
    latitude: 14.976,
    longitude: 100.135,
  },
  {
    province: "ชัยนาท",
    district: "สรรคบุรี",
    subdistrict: "ดอนกำ",
    zipcode: 17140,
    latitude: 14.966,
    longitude: 100.236,
  },
  {
    province: "ชัยนาท",
    district: "สรรคบุรี",
    subdistrict: "ห้วยกรดพัฒนา",
    zipcode: 17140,
    latitude: 15.074,
    longitude: 100.228,
  },
  {
    province: "ชัยนาท",
    district: "หันคา",
    subdistrict: "หันคา",
    zipcode: 17130,
    latitude: 15.016,
    longitude: 99.998,
  },
  {
    province: "ชัยนาท",
    district: "หันคา",
    subdistrict: "บ้านเชี่ยน",
    zipcode: 17130,
    latitude: 14.942,
    longitude: 99.997,
  },
  {
    province: "ชัยนาท",
    district: "หันคา",
    subdistrict: "ไพรนกยูง",
    zipcode: 17130,
    latitude: 15.081,
    longitude: 99.856,
  },
  {
    province: "ชัยนาท",
    district: "หันคา",
    subdistrict: "หนองแซง",
    zipcode: 17160,
    latitude: 15.107,
    longitude: 99.973,
  },
  {
    province: "ชัยนาท",
    district: "หันคา",
    subdistrict: "ห้วยงู",
    zipcode: 17160,
    latitude: 15.072,
    longitude: 100.067,
  },
  {
    province: "ชัยนาท",
    district: "หันคา",
    subdistrict: "วังไก่เถื่อน",
    zipcode: 17130,
    latitude: 15.004,
    longitude: 100.056,
  },
  {
    province: "ชัยนาท",
    district: "หันคา",
    subdistrict: "เด่นใหญ่",
    zipcode: 17130,
    latitude: 15.017,
    longitude: 99.93,
  },
  {
    province: "ชัยนาท",
    district: "หันคา",
    subdistrict: "สามง่ามท่าโบสถ์",
    zipcode: 17160,
    latitude: 15.065,
    longitude: 100.026,
  },
  {
    province: "ชัยนาท",
    district: "หนองมะโมง",
    subdistrict: "หนองมะโมง",
    zipcode: 17120,
    latitude: 15.216,
    longitude: 99.772,
  },
  {
    province: "ชัยนาท",
    district: "หนองมะโมง",
    subdistrict: "วังตะเคียน",
    zipcode: 17120,
    latitude: 15.278,
    longitude: 99.802,
  },
  {
    province: "ชัยนาท",
    district: "หนองมะโมง",
    subdistrict: "สะพานหิน",
    zipcode: 17120,
    latitude: 15.197,
    longitude: 99.827,
  },
  {
    province: "ชัยนาท",
    district: "หนองมะโมง",
    subdistrict: "กุดจอก",
    zipcode: 17120,
    latitude: 15.241,
    longitude: 99.894,
  },
  {
    province: "ชัยนาท",
    district: "เนินขาม",
    subdistrict: "เนินขาม",
    zipcode: 17130,
    latitude: 14.957,
    longitude: 99.901,
  },
  {
    province: "ชัยนาท",
    district: "เนินขาม",
    subdistrict: "กะบกเตี้ย",
    zipcode: 17130,
    latitude: 15.036,
    longitude: 99.816,
  },
  {
    province: "ชัยนาท",
    district: "เนินขาม",
    subdistrict: "สุขเดือนห้า",
    zipcode: 17130,
    latitude: 14.968,
    longitude: 99.828,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "ปากเพรียว",
    zipcode: 18000,
    latitude: 14.527,
    longitude: 100.915,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "ดาวเรือง",
    zipcode: 18000,
    latitude: 14.563,
    longitude: 100.901,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "นาโฉง",
    zipcode: 18000,
    latitude: 14.529,
    longitude: 100.886,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "โคกสว่าง",
    zipcode: 18000,
    latitude: 14.51,
    longitude: 100.886,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "หนองโน",
    zipcode: 18000,
    latitude: 14.489,
    longitude: 100.861,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "หนองยาว",
    zipcode: 18000,
    latitude: 14.472,
    longitude: 100.89,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "ปากข้าวสาร",
    zipcode: 18000,
    latitude: 14.49,
    longitude: 100.913,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "หนองปลาไหล",
    zipcode: 18000,
    latitude: 14.456,
    longitude: 100.962,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "กุดนกเปล้า",
    zipcode: 18000,
    latitude: 14.502,
    longitude: 100.976,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "ตลิ่งชัน",
    zipcode: 18000,
    latitude: 14.544,
    longitude: 100.956,
  },
  {
    province: "สระบุรี",
    district: "เมืองสระบุรี",
    subdistrict: "ตะกุด",
    zipcode: 18000,
    latitude: 14.553,
    longitude: 100.933,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "แก่งคอย",
    zipcode: 18110,
    latitude: 14.586,
    longitude: 101.002,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "ทับกวาง",
    zipcode: 18260,
    latitude: 14.605,
    longitude: 101.095,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "ตาลเดี่ยว",
    zipcode: 18110,
    latitude: 14.552,
    longitude: 101.015,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "ห้วยแห้ง",
    zipcode: 18110,
    latitude: 14.477,
    longitude: 101.004,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "ท่าคล้อ",
    zipcode: 18110,
    latitude: 14.712,
    longitude: 100.993,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "หินซ้อน",
    zipcode: 18110,
    latitude: 14.753,
    longitude: 101.033,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "บ้านธาตุ",
    zipcode: 18110,
    latitude: 14.626,
    longitude: 100.997,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "บ้านป่า",
    zipcode: 18110,
    latitude: 14.622,
    longitude: 101.029,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "ท่าตูม",
    zipcode: 18110,
    latitude: 14.662,
    longitude: 100.993,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "ชะอม",
    zipcode: 18110,
    latitude: 14.418,
    longitude: 101.134,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "สองคอน",
    zipcode: 18110,
    latitude: 14.636,
    longitude: 100.968,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "เตาปูน",
    zipcode: 18110,
    latitude: 14.603,
    longitude: 100.956,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "ชำผักแพว",
    zipcode: 18110,
    latitude: 14.529,
    longitude: 101.09,
  },
  {
    province: "สระบุรี",
    district: "แก่งคอย",
    subdistrict: "ท่ามะปราง",
    zipcode: 18110,
    latitude: 14.483,
    longitude: 101.103,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "หนองแค",
    zipcode: 18140,
    latitude: 14.337,
    longitude: 100.869,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "กุ่มหัก",
    zipcode: 18140,
    latitude: 14.312,
    longitude: 100.863,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "คชสิทธิ์",
    zipcode: 18250,
    latitude: 14.391,
    longitude: 100.807,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "โคกตูม",
    zipcode: 18250,
    latitude: 14.421,
    longitude: 100.784,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "โคกแย้",
    zipcode: 18230,
    latitude: 14.387,
    longitude: 100.928,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "บัวลอย",
    zipcode: 18230,
    latitude: 14.404,
    longitude: 100.851,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "ไผ่ต่ำ",
    zipcode: 18140,
    latitude: 14.326,
    longitude: 100.834,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "โพนทอง",
    zipcode: 18250,
    latitude: 14.425,
    longitude: 100.813,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "ห้วยขมิ้น",
    zipcode: 18230,
    latitude: 14.381,
    longitude: 100.871,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "ห้วยทราย",
    zipcode: 18230,
    latitude: 14.42,
    longitude: 100.933,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "หนองไข่น้ำ",
    zipcode: 18140,
    latitude: 14.36,
    longitude: 100.878,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "หนองแขม",
    zipcode: 18140,
    latitude: 14.339,
    longitude: 100.797,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "หนองจิก",
    zipcode: 18230,
    latitude: 14.441,
    longitude: 100.866,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "หนองจรเข้",
    zipcode: 18140,
    latitude: 14.334,
    longitude: 100.907,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "หนองนาก",
    zipcode: 18230,
    latitude: 14.444,
    longitude: 100.891,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "หนองปลาหมอ",
    zipcode: 18140,
    latitude: 14.369,
    longitude: 100.845,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "หนองปลิง",
    zipcode: 18140,
    latitude: 14.37,
    longitude: 100.829,
  },
  {
    province: "สระบุรี",
    district: "หนองแค",
    subdistrict: "หนองโรง",
    zipcode: 18140,
    latitude: 14.279,
    longitude: 100.865,
  },
  {
    province: "สระบุรี",
    district: "วิหารแดง",
    subdistrict: "หนองหมู",
    zipcode: 18150,
    latitude: 14.288,
    longitude: 100.932,
  },
  {
    province: "สระบุรี",
    district: "วิหารแดง",
    subdistrict: "บ้านลำ",
    zipcode: 18150,
    latitude: 14.391,
    longitude: 100.979,
  },
  {
    province: "สระบุรี",
    district: "วิหารแดง",
    subdistrict: "คลองเรือ",
    zipcode: 18150,
    latitude: 14.366,
    longitude: 101.032,
  },
  {
    province: "สระบุรี",
    district: "วิหารแดง",
    subdistrict: "วิหารแดง",
    zipcode: 18150,
    latitude: 14.313,
    longitude: 100.99,
  },
  {
    province: "สระบุรี",
    district: "วิหารแดง",
    subdistrict: "หนองสรวง",
    zipcode: 18150,
    latitude: 14.34,
    longitude: 100.983,
  },
  {
    province: "สระบุรี",
    district: "วิหารแดง",
    subdistrict: "เจริญธรรม",
    zipcode: 18150,
    latitude: 14.387,
    longitude: 101.006,
  },
  {
    province: "สระบุรี",
    district: "หนองแซง",
    subdistrict: "หนองแซง",
    zipcode: 18170,
    latitude: 14.51,
    longitude: 100.759,
  },
  {
    province: "สระบุรี",
    district: "หนองแซง",
    subdistrict: "หนองควายโซ",
    zipcode: 18170,
    latitude: 14.487,
    longitude: 100.795,
  },
  {
    province: "สระบุรี",
    district: "หนองแซง",
    subdistrict: "หนองหัวโพ",
    zipcode: 18170,
    latitude: 14.483,
    longitude: 100.817,
  },
  {
    province: "สระบุรี",
    district: "หนองแซง",
    subdistrict: "หนองสีดา",
    zipcode: 18170,
    latitude: 14.515,
    longitude: 100.841,
  },
  {
    province: "สระบุรี",
    district: "หนองแซง",
    subdistrict: "หนองกบ",
    zipcode: 18170,
    latitude: 14.456,
    longitude: 100.776,
  },
  {
    province: "สระบุรี",
    district: "หนองแซง",
    subdistrict: "ไก่เส่า",
    zipcode: 18170,
    latitude: 14.516,
    longitude: 100.793,
  },
  {
    province: "สระบุรี",
    district: "หนองแซง",
    subdistrict: "โคกสะอาด",
    zipcode: 18170,
    latitude: 14.456,
    longitude: 100.802,
  },
  {
    province: "สระบุรี",
    district: "หนองแซง",
    subdistrict: "ม่วงหวาน",
    zipcode: 18170,
    latitude: 14.476,
    longitude: 100.836,
  },
  {
    province: "สระบุรี",
    district: "หนองแซง",
    subdistrict: "เขาดิน",
    zipcode: 18170,
    latitude: 14.4882391,
    longitude: 100.8022012,
  },
  {
    province: "สระบุรี",
    district: "บ้านหมอ",
    subdistrict: "บ้านหมอ",
    zipcode: 18130,
    latitude: 14.616,
    longitude: 100.724,
  },
  {
    province: "สระบุรี",
    district: "บ้านหมอ",
    subdistrict: "บางโขมด",
    zipcode: 18130,
    latitude: 14.591,
    longitude: 100.742,
  },
  {
    province: "สระบุรี",
    district: "บ้านหมอ",
    subdistrict: "สร่างโศก",
    zipcode: 18130,
    latitude: 14.622,
    longitude: 100.754,
  },
  {
    province: "สระบุรี",
    district: "บ้านหมอ",
    subdistrict: "ตลาดน้อย",
    zipcode: 18130,
    latitude: 14.643,
    longitude: 100.716,
  },
  {
    province: "สระบุรี",
    district: "บ้านหมอ",
    subdistrict: "หรเทพ",
    zipcode: 18130,
    latitude: 14.628,
    longitude: 100.67,
  },
  {
    province: "สระบุรี",
    district: "บ้านหมอ",
    subdistrict: "โคกใหญ่",
    zipcode: 18130,
    latitude: 14.606,
    longitude: 100.677,
  },
  {
    province: "สระบุรี",
    district: "บ้านหมอ",
    subdistrict: "ไผ่ขวาง",
    zipcode: 18130,
    latitude: 14.579,
    longitude: 100.648,
  },
  {
    province: "สระบุรี",
    district: "บ้านหมอ",
    subdistrict: "บ้านครัว",
    zipcode: 18270,
    latitude: 14.584,
    longitude: 100.767,
  },
  {
    province: "สระบุรี",
    district: "บ้านหมอ",
    subdistrict: "หนองบัว",
    zipcode: 18130,
    latitude: 14.618,
    longitude: 100.774,
  },
  {
    province: "สระบุรี",
    district: "ดอนพุด",
    subdistrict: "ดอนพุด",
    zipcode: 18210,
    latitude: 14.596,
    longitude: 100.621,
  },
  {
    province: "สระบุรี",
    district: "ดอนพุด",
    subdistrict: "ไผ่หลิ่ว",
    zipcode: 18210,
    latitude: 14.625,
    longitude: 100.632,
  },
  {
    province: "สระบุรี",
    district: "ดอนพุด",
    subdistrict: "บ้านหลวง",
    zipcode: 18210,
    latitude: 14.571,
    longitude: 100.62,
  },
  {
    province: "สระบุรี",
    district: "ดอนพุด",
    subdistrict: "ดงตะงาว",
    zipcode: 18210,
    latitude: 14.624,
    longitude: 100.599,
  },
  {
    province: "สระบุรี",
    district: "หนองโดน",
    subdistrict: "หนองโดน",
    zipcode: 18190,
    latitude: 14.688,
    longitude: 100.693,
  },
  {
    province: "สระบุรี",
    district: "หนองโดน",
    subdistrict: "บ้านกลับ",
    zipcode: 18190,
    latitude: 14.73,
    longitude: 100.684,
  },
  {
    province: "สระบุรี",
    district: "หนองโดน",
    subdistrict: "ดอนทอง",
    zipcode: 18190,
    latitude: 14.664,
    longitude: 100.654,
  },
  {
    province: "สระบุรี",
    district: "หนองโดน",
    subdistrict: "บ้านโปร่ง",
    zipcode: 18190,
    latitude: 14.716,
    longitude: 100.734,
  },
  {
    province: "สระบุรี",
    district: "พระพุทธบาท",
    subdistrict: "พระพุทธบาท",
    zipcode: 18120,
    latitude: 14.732,
    longitude: 100.782,
  },
  {
    province: "สระบุรี",
    district: "พระพุทธบาท",
    subdistrict: "ขุนโขลน",
    zipcode: 18120,
    latitude: 14.713,
    longitude: 100.831,
  },
  {
    province: "สระบุรี",
    district: "พระพุทธบาท",
    subdistrict: "ธารเกษม",
    zipcode: 18120,
    latitude: 14.763,
    longitude: 100.812,
  },
  {
    province: "สระบุรี",
    district: "พระพุทธบาท",
    subdistrict: "นายาว",
    zipcode: 18120,
    latitude: 14.768,
    longitude: 100.763,
  },
  {
    province: "สระบุรี",
    district: "พระพุทธบาท",
    subdistrict: "พุคำจาน",
    zipcode: 18120,
    latitude: 14.744,
    longitude: 100.847,
  },
  {
    province: "สระบุรี",
    district: "พระพุทธบาท",
    subdistrict: "เขาวง",
    zipcode: 18120,
    latitude: 14.679,
    longitude: 100.83,
  },
  {
    province: "สระบุรี",
    district: "พระพุทธบาท",
    subdistrict: "ห้วยป่าหวาย",
    zipcode: 18120,
    latitude: 14.638,
    longitude: 100.83,
  },
  {
    province: "สระบุรี",
    district: "พระพุทธบาท",
    subdistrict: "พุกร่าง",
    zipcode: 18120,
    latitude: 14.682,
    longitude: 100.784,
  },
  {
    province: "สระบุรี",
    district: "พระพุทธบาท",
    subdistrict: "หนองแก",
    zipcode: 18120,
    latitude: 14.7164135,
    longitude: 100.701556,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "เสาไห้",
    zipcode: 18160,
    latitude: 14.538,
    longitude: 100.85,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "บ้านยาง",
    zipcode: 18160,
    latitude: 14.582,
    longitude: 100.816,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "หัวปลวก",
    zipcode: 18160,
    latitude: 14.615,
    longitude: 100.844,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "งิ้วงาม",
    zipcode: 18160,
    latitude: 14.59,
    longitude: 100.851,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "ศาลารีไทย",
    zipcode: 18160,
    latitude: 14.565,
    longitude: 100.848,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "ต้นตาล",
    zipcode: 18160,
    latitude: 14.574,
    longitude: 100.885,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "ท่าช้าง",
    zipcode: 18160,
    latitude: 14.586,
    longitude: 100.863,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "พระยาทด",
    zipcode: 18160,
    latitude: 14.572,
    longitude: 100.871,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "ม่วงงาม",
    zipcode: 18160,
    latitude: 14.544,
    longitude: 100.807,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "เริงราง",
    zipcode: 18160,
    latitude: 14.558,
    longitude: 100.786,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "เมืองเก่า",
    zipcode: 18160,
    latitude: 14.54,
    longitude: 100.825,
  },
  {
    province: "สระบุรี",
    district: "เสาไห้",
    subdistrict: "สวนดอกไม้",
    zipcode: 18160,
    latitude: 14.54,
    longitude: 100.868,
  },
  {
    province: "สระบุรี",
    district: "มวกเหล็ก",
    subdistrict: "มวกเหล็ก",
    zipcode: 18180,
    latitude: 14.705,
    longitude: 101.151,
  },
  {
    province: "สระบุรี",
    district: "มวกเหล็ก",
    subdistrict: "มิตรภาพ",
    zipcode: 18180,
    latitude: 14.474,
    longitude: 101.213,
  },
  {
    province: "สระบุรี",
    district: "มวกเหล็ก",
    subdistrict: "หนองย่างเสือ",
    zipcode: 18180,
    latitude: 14.789,
    longitude: 101.27,
  },
  {
    province: "สระบุรี",
    district: "มวกเหล็ก",
    subdistrict: "ลำสมพุง",
    zipcode: 30130,
    latitude: 15.007,
    longitude: 101.381,
  },
  {
    province: "สระบุรี",
    district: "มวกเหล็ก",
    subdistrict: "ลำพญากลาง",
    zipcode: 30130,
    latitude: 14.871,
    longitude: 101.367,
  },
  {
    province: "สระบุรี",
    district: "มวกเหล็ก",
    subdistrict: "ซับสนุ่น",
    zipcode: 18220,
    latitude: 14.932,
    longitude: 101.309,
  },
  {
    province: "สระบุรี",
    district: "วังม่วง",
    subdistrict: "แสลงพัน",
    zipcode: 18220,
    latitude: 14.745,
    longitude: 101.116,
  },
  {
    province: "สระบุรี",
    district: "วังม่วง",
    subdistrict: "คำพราน",
    zipcode: 18220,
    latitude: 14.818,
    longitude: 101.132,
  },
  {
    province: "สระบุรี",
    district: "วังม่วง",
    subdistrict: "วังม่วง",
    zipcode: 18220,
    latitude: 14.861,
    longitude: 101.151,
  },
  {
    province: "สระบุรี",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "เขาดินพัฒนา",
    zipcode: 18000,
    latitude: 14.612,
    longitude: 100.906,
  },
  {
    province: "สระบุรี",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "บ้านแก้ง",
    zipcode: 18000,
    latitude: 14.582,
    longitude: 100.936,
  },
  {
    province: "สระบุรี",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ผึ้งรวง",
    zipcode: 18000,
    latitude: 14.607,
    longitude: 100.931,
  },
  {
    province: "สระบุรี",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "พุแค",
    zipcode: 18240,
    latitude: 14.656,
    longitude: 100.905,
  },
  {
    province: "สระบุรี",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ห้วยบง",
    zipcode: 18000,
    latitude: 14.626,
    longitude: 100.877,
  },
  {
    province: "สระบุรี",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "หน้าพระลาน",
    zipcode: 18240,
    latitude: 14.713,
    longitude: 100.904,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "บางปลาสร้อย",
    zipcode: 20000,
    latitude: 13.364,
    longitude: 100.987,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "มะขามหย่ง",
    zipcode: 20000,
    latitude: 13.372,
    longitude: 100.985,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "บ้านโขด",
    zipcode: 20000,
    latitude: 13.376,
    longitude: 100.99,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "แสนสุข",
    zipcode: 20130,
    latitude: 13.274,
    longitude: 100.928,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "บ้านสวน",
    zipcode: 20000,
    latitude: 13.347,
    longitude: 100.977,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "หนองรี",
    zipcode: 20000,
    latitude: 13.307,
    longitude: 101.057,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "นาป่า",
    zipcode: 20000,
    latitude: 13.385,
    longitude: 101.03,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "หนองข้างคอก",
    zipcode: 20000,
    latitude: 13.305,
    longitude: 101.006,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "ดอนหัวฬ่อ",
    zipcode: 20000,
    latitude: 13.421,
    longitude: 101.044,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "หนองไม้แดง",
    zipcode: 20000,
    latitude: 13.406,
    longitude: 101.003,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "บางทราย",
    zipcode: 20000,
    latitude: 13.393,
    longitude: 100.996,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "คลองตำหรุ",
    zipcode: 20000,
    latitude: 13.441,
    longitude: 100.982,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "เหมือง",
    zipcode: 20130,
    latitude: 13.263,
    longitude: 100.959,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "บ้านปึก",
    zipcode: 20130,
    latitude: 13.302,
    longitude: 100.928,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "ห้วยกะปิ",
    zipcode: 20000,
    latitude: 13.304,
    longitude: 100.968,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "เสม็ด",
    zipcode: 20000,
    latitude: 13.326,
    longitude: 100.951,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "อ่างศิลา",
    zipcode: 20000,
    latitude: 13.325,
    longitude: 100.929,
  },
  {
    province: "ชลบุรี",
    district: "เมืองชลบุรี",
    subdistrict: "สำนักบก",
    zipcode: 20000,
    latitude: 13.368,
    longitude: 101.061,
  },
  {
    province: "ชลบุรี",
    district: "บ้านบึง",
    subdistrict: "บ้านบึง",
    zipcode: 20170,
    latitude: 13.297,
    longitude: 101.107,
  },
  {
    province: "ชลบุรี",
    district: "บ้านบึง",
    subdistrict: "คลองกิ่ว",
    zipcode: 20220,
    latitude: 13.225,
    longitude: 101.107,
  },
  {
    province: "ชลบุรี",
    district: "บ้านบึง",
    subdistrict: "มาบไผ่",
    zipcode: 20170,
    latitude: 13.359,
    longitude: 101.093,
  },
  {
    province: "ชลบุรี",
    district: "บ้านบึง",
    subdistrict: "หนองซ้ำซาก",
    zipcode: 20170,
    latitude: 13.33,
    longitude: 101.072,
  },
  {
    province: "ชลบุรี",
    district: "บ้านบึง",
    subdistrict: "หนองบอนแดง",
    zipcode: 20170,
    latitude: 13.353,
    longitude: 101.159,
  },
  {
    province: "ชลบุรี",
    district: "บ้านบึง",
    subdistrict: "หนองชาก",
    zipcode: 20170,
    latitude: 13.298,
    longitude: 101.172,
  },
  {
    province: "ชลบุรี",
    district: "บ้านบึง",
    subdistrict: "หนองอิรุณ",
    zipcode: 20220,
    latitude: 13.28,
    longitude: 101.247,
  },
  {
    province: "ชลบุรี",
    district: "บ้านบึง",
    subdistrict: "หนองไผ่แก้ว",
    zipcode: 20220,
    latitude: 13.213,
    longitude: 101.246,
  },
  {
    province: "ชลบุรี",
    district: "หนองใหญ่",
    subdistrict: "หนองใหญ่",
    zipcode: 20190,
    latitude: 13.153,
    longitude: 101.375,
  },
  {
    province: "ชลบุรี",
    district: "หนองใหญ่",
    subdistrict: "คลองพลู",
    zipcode: 20190,
    latitude: 13.115,
    longitude: 101.476,
  },
  {
    province: "ชลบุรี",
    district: "หนองใหญ่",
    subdistrict: "หนองเสือช้าง",
    zipcode: 20190,
    latitude: 13.117,
    longitude: 101.283,
  },
  {
    province: "ชลบุรี",
    district: "หนองใหญ่",
    subdistrict: "ห้างสูง",
    zipcode: 20190,
    latitude: 13.243,
    longitude: 101.347,
  },
  {
    province: "ชลบุรี",
    district: "หนองใหญ่",
    subdistrict: "เขาซก",
    zipcode: 20190,
    latitude: 13.07,
    longitude: 101.42,
  },
  {
    province: "ชลบุรี",
    district: "บางละมุง",
    subdistrict: "บางละมุง",
    zipcode: 20150,
    latitude: 13.052,
    longitude: 100.922,
  },
  {
    province: "ชลบุรี",
    district: "บางละมุง",
    subdistrict: "หนองปรือ",
    zipcode: 20150,
    latitude: 12.92,
    longitude: 100.935,
  },
  {
    province: "ชลบุรี",
    district: "บางละมุง",
    subdistrict: "หนองปลาไหล",
    zipcode: 20150,
    latitude: 12.982,
    longitude: 100.945,
  },
  {
    province: "ชลบุรี",
    district: "บางละมุง",
    subdistrict: "โป่ง",
    zipcode: 20150,
    latitude: 12.933,
    longitude: 100.987,
  },
  {
    province: "ชลบุรี",
    district: "บางละมุง",
    subdistrict: "เขาไม้แก้ว",
    zipcode: 20150,
    latitude: 12.951,
    longitude: 101.057,
  },
  {
    province: "ชลบุรี",
    district: "บางละมุง",
    subdistrict: "ห้วยใหญ่",
    zipcode: 20150,
    latitude: 12.835,
    longitude: 100.985,
  },
  {
    province: "ชลบุรี",
    district: "บางละมุง",
    subdistrict: "ตะเคียนเตี้ย",
    zipcode: 20150,
    latitude: 13.002,
    longitude: 101.015,
  },
  {
    province: "ชลบุรี",
    district: "บางละมุง",
    subdistrict: "นาเกลือ",
    zipcode: 20150,
    latitude: 12.928,
    longitude: 100.805,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "พานทอง",
    zipcode: 20160,
    latitude: 13.461,
    longitude: 101.084,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "หนองตำลึง",
    zipcode: 20160,
    latitude: 13.408,
    longitude: 101.078,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "มาบโป่ง",
    zipcode: 20160,
    latitude: 13.431,
    longitude: 101.121,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "หนองกะขะ",
    zipcode: 20160,
    latitude: 13.431,
    longitude: 101.095,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "หนองหงษ์",
    zipcode: 20160,
    latitude: 13.389,
    longitude: 101.113,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "โคกขี้หนอน",
    zipcode: 20160,
    latitude: 13.552,
    longitude: 101.114,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "บ้านเก่า",
    zipcode: 20160,
    latitude: 13.454,
    longitude: 101.059,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "หน้าประดู่",
    zipcode: 20160,
    latitude: 13.497,
    longitude: 101.099,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "บางนาง",
    zipcode: 20160,
    latitude: 13.487,
    longitude: 101.067,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "เกาะลอย",
    zipcode: 20160,
    latitude: 13.519,
    longitude: 101.074,
  },
  {
    province: "ชลบุรี",
    district: "พานทอง",
    subdistrict: "บางหัก",
    zipcode: 20160,
    latitude: 13.544,
    longitude: 101.075,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "พนัสนิคม",
    zipcode: 20140,
    latitude: 13.45,
    longitude: 101.18,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "หน้าพระธาตุ",
    zipcode: 20140,
    latitude: 13.468,
    longitude: 101.147,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "วัดหลวง",
    zipcode: 20140,
    latitude: 13.518,
    longitude: 101.161,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "บ้านเซิด",
    zipcode: 20140,
    latitude: 13.4423515,
    longitude: 101.1303065,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "นาเริก",
    zipcode: 20140,
    latitude: 13.405,
    longitude: 101.248,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "หมอนนาง",
    zipcode: 20140,
    latitude: 13.346,
    longitude: 101.257,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "สระสี่เหลี่ยม",
    zipcode: 20140,
    latitude: 13.554,
    longitude: 101.239,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "วัดโบสถ์",
    zipcode: 20140,
    latitude: 13.498,
    longitude: 101.139,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "กุฎโง้ง",
    zipcode: 20140,
    latitude: 13.444,
    longitude: 101.165,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "หัวถนน",
    zipcode: 20140,
    latitude: 13.531,
    longitude: 101.265,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "ท่าข้าม",
    zipcode: 20140,
    latitude: 13.559,
    longitude: 101.177,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "หนองปรือ",
    zipcode: 20140,
    latitude: 13.511,
    longitude: 101.254,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "หนองขยาด",
    zipcode: 20140,
    latitude: 13.402,
    longitude: 101.151,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "ทุ่งขวาง",
    zipcode: 20140,
    latitude: 13.4,
    longitude: 101.188,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "หนองเหียง",
    zipcode: 20140,
    latitude: 13.464,
    longitude: 101.33,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "นาวังหิน",
    zipcode: 20140,
    latitude: 13.442,
    longitude: 101.251,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "บ้านช้าง",
    zipcode: 20140,
    latitude: 13.435,
    longitude: 101.213,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "โคกเพลาะ",
    zipcode: 20140,
    latitude: 13.52,
    longitude: 101.121,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "ไร่หลักทอง",
    zipcode: 20140,
    latitude: 13.484,
    longitude: 101.184,
  },
  {
    province: "ชลบุรี",
    district: "พนัสนิคม",
    subdistrict: "นามะตูม",
    zipcode: 20140,
    latitude: 13.4246496,
    longitude: 101.0961272,
  },
  {
    province: "ชลบุรี",
    district: "ศรีราชา",
    subdistrict: "ศรีราชา",
    zipcode: 20110,
    latitude: 13.174,
    longitude: 100.92,
  },
  {
    province: "ชลบุรี",
    district: "ศรีราชา",
    subdistrict: "สุรศักดิ์",
    zipcode: 20110,
    latitude: 13.16,
    longitude: 100.983,
  },
  {
    province: "ชลบุรี",
    district: "ศรีราชา",
    subdistrict: "ทุ่งสุขลา",
    zipcode: 20230,
    latitude: 13.097,
    longitude: 100.912,
  },
  {
    province: "ชลบุรี",
    district: "ศรีราชา",
    subdistrict: "บึง",
    zipcode: 20230,
    latitude: 13.073,
    longitude: 101.006,
  },
  {
    province: "ชลบุรี",
    district: "ศรีราชา",
    subdistrict: "หนองขาม",
    zipcode: 20110,
    latitude: 13.134,
    longitude: 101.038,
  },
  {
    province: "ชลบุรี",
    district: "ศรีราชา",
    subdistrict: "เขาคันทรง",
    zipcode: 20110,
    latitude: 13.129,
    longitude: 101.123,
  },
  {
    province: "ชลบุรี",
    district: "ศรีราชา",
    subdistrict: "บางพระ",
    zipcode: 20110,
    latitude: 13.223,
    longitude: 101.007,
  },
  {
    province: "ชลบุรี",
    district: "ศรีราชา",
    subdistrict: "บ่อวิน",
    zipcode: 20230,
    latitude: 13.052,
    longitude: 101.093,
  },
  {
    province: "ชลบุรี",
    district: "เกาะสีชัง",
    subdistrict: "ท่าเทววงษ์",
    zipcode: 20120,
    latitude: 13.151,
    longitude: 100.809,
  },
  {
    province: "ชลบุรี",
    district: "สัตหีบ",
    subdistrict: "สัตหีบ",
    zipcode: 20180,
    latitude: 12.611,
    longitude: 100.889,
  },
  {
    province: "ชลบุรี",
    district: "สัตหีบ",
    subdistrict: "นาจอมเทียน",
    zipcode: 20250,
    latitude: 12.775,
    longitude: 100.94,
  },
  {
    province: "ชลบุรี",
    district: "สัตหีบ",
    subdistrict: "พลูตาหลวง",
    zipcode: 20180,
    latitude: 12.701,
    longitude: 100.967,
  },
  {
    province: "ชลบุรี",
    district: "สัตหีบ",
    subdistrict: "บางเสร่",
    zipcode: 20250,
    latitude: 12.761,
    longitude: 100.844,
  },
  {
    province: "ชลบุรี",
    district: "สัตหีบ",
    subdistrict: "แสมสาร",
    zipcode: 20180,
    latitude: 12.616,
    longitude: 100.918,
  },
  {
    province: "ชลบุรี",
    district: "บ่อทอง",
    subdistrict: "บ่อทอง",
    zipcode: 20270,
    latitude: 13.193,
    longitude: 101.504,
  },
  {
    province: "ชลบุรี",
    district: "บ่อทอง",
    subdistrict: "วัดสุวรรณ",
    zipcode: 20270,
    latitude: 13.332,
    longitude: 101.419,
  },
  {
    province: "ชลบุรี",
    district: "บ่อทอง",
    subdistrict: "บ่อกวางทอง",
    zipcode: 20270,
    latitude: 13.318,
    longitude: 101.308,
  },
  {
    province: "ชลบุรี",
    district: "บ่อทอง",
    subdistrict: "ธาตุทอง",
    zipcode: 20270,
    latitude: 13.259,
    longitude: 101.389,
  },
  {
    province: "ชลบุรี",
    district: "บ่อทอง",
    subdistrict: "เกษตรสุวรรณ",
    zipcode: 20270,
    latitude: 13.326,
    longitude: 101.526,
  },
  {
    province: "ชลบุรี",
    district: "บ่อทอง",
    subdistrict: "พลวงทอง",
    zipcode: 20270,
    latitude: 13.217,
    longitude: 101.626,
  },
  {
    province: "ชลบุรี",
    district: "เกาะจันทร์",
    subdistrict: "เกาะจันทร์",
    zipcode: 20240,
    latitude: 13.403,
    longitude: 101.421,
  },
  {
    province: "ชลบุรี",
    district: "เกาะจันทร์",
    subdistrict: "ท่าบุญมี",
    zipcode: 20240,
    latitude: 13.389,
    longitude: 101.288,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "ท่าประดู่",
    zipcode: 21000,
    latitude: 12.672,
    longitude: 101.266,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "เชิงเนิน",
    zipcode: 21000,
    latitude: 12.677,
    longitude: 101.318,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "ตะพง",
    zipcode: 21000,
    latitude: 12.653,
    longitude: 101.364,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "ปากน้ำ",
    zipcode: 21000,
    latitude: 12.6592268,
    longitude: 101.2591226,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "เพ",
    zipcode: 21160,
    latitude: 12.568,
    longitude: 101.455,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "แกลง",
    zipcode: 21160,
    latitude: 12.663,
    longitude: 101.467,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "บ้านแลง",
    zipcode: 21000,
    latitude: 12.707,
    longitude: 101.377,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "นาตาขวัญ",
    zipcode: 21000,
    latitude: 12.741,
    longitude: 101.369,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "เนินพระ",
    zipcode: 21000,
    latitude: 12.681,
    longitude: 101.223,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "กะเฉด",
    zipcode: 21100,
    latitude: 12.7661771,
    longitude: 101.4088117,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "ทับมา",
    zipcode: 21000,
    latitude: 12.708,
    longitude: 101.247,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "น้ำคอก",
    zipcode: 21000,
    latitude: 12.716,
    longitude: 101.269,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "ห้วยโป่ง",
    zipcode: 21150,
    latitude: 12.7551759,
    longitude: 101.0867608,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "มาบตาพุด",
    zipcode: 21150,
    latitude: 12.646,
    longitude: 101.171,
  },
  {
    province: "ระยอง",
    district: "เมืองระยอง",
    subdistrict: "สำนักทอง",
    zipcode: 21100,
    latitude: 12.721,
    longitude: 101.472,
  },
  {
    province: "ระยอง",
    district: "บ้านฉาง",
    subdistrict: "สำนักท้อน",
    zipcode: 21130,
    latitude: 12.804,
    longitude: 101.066,
  },
  {
    province: "ระยอง",
    district: "บ้านฉาง",
    subdistrict: "พลา",
    zipcode: 21130,
    latitude: 12.688,
    longitude: 101.025,
  },
  {
    province: "ระยอง",
    district: "บ้านฉาง",
    subdistrict: "บ้านฉาง",
    zipcode: 21130,
    latitude: 12.732,
    longitude: 101.076,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "ทางเกวียน",
    zipcode: 21110,
    latitude: 12.807,
    longitude: 101.625,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "วังหว้า",
    zipcode: 21110,
    latitude: 12.763,
    longitude: 101.624,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "ชากโดน",
    zipcode: 21110,
    latitude: 12.7,
    longitude: 101.604,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "เนินฆ้อ",
    zipcode: 21110,
    latitude: 12.704,
    longitude: 101.678,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "กร่ำ",
    zipcode: 21190,
    latitude: 12.584,
    longitude: 101.51,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "ชากพง",
    zipcode: 21190,
    latitude: 12.668,
    longitude: 101.549,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "กระแสบน",
    zipcode: 21110,
    latitude: 12.871,
    longitude: 101.611,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "บ้านนา",
    zipcode: 21110,
    latitude: 12.846,
    longitude: 101.674,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "ทุ่งควายกิน",
    zipcode: 21110,
    latitude: 12.808,
    longitude: 101.733,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "กองดิน",
    zipcode: 22160,
    latitude: 12.815,
    longitude: 101.792,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "คลองปูน",
    zipcode: 21170,
    latitude: 12.765,
    longitude: 101.75,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "พังราด",
    zipcode: 21170,
    latitude: 12.729,
    longitude: 101.772,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "ปากน้ำกระแส",
    zipcode: 21170,
    latitude: 12.717,
    longitude: 101.709,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "ห้วยยาง",
    zipcode: 21110,
    latitude: 12.812,
    longitude: 101.533,
  },
  {
    province: "ระยอง",
    district: "แกลง",
    subdistrict: "สองสลึง",
    zipcode: 21110,
    latitude: 12.743,
    longitude: 101.548,
  },
  {
    province: "ระยอง",
    district: "วังจันทร์",
    subdistrict: "วังจันทร์",
    zipcode: 21210,
    latitude: 12.858,
    longitude: 101.528,
  },
  {
    province: "ระยอง",
    district: "วังจันทร์",
    subdistrict: "ชุมแสง",
    zipcode: 21210,
    latitude: 12.956,
    longitude: 101.526,
  },
  {
    province: "ระยอง",
    district: "วังจันทร์",
    subdistrict: "ป่ายุบใน",
    zipcode: 21210,
    latitude: 13.005,
    longitude: 101.479,
  },
  {
    province: "ระยอง",
    district: "วังจันทร์",
    subdistrict: "พลงตาเอี่ยม",
    zipcode: 21210,
    latitude: 12.912,
    longitude: 101.541,
  },
  {
    province: "ระยอง",
    district: "บ้านค่าย",
    subdistrict: "บ้านค่าย",
    zipcode: 21120,
    latitude: 12.775,
    longitude: 101.304,
  },
  {
    province: "ระยอง",
    district: "บ้านค่าย",
    subdistrict: "หนองละลอก",
    zipcode: 21120,
    latitude: 12.821,
    longitude: 101.256,
  },
  {
    province: "ระยอง",
    district: "บ้านค่าย",
    subdistrict: "หนองตะพาน",
    zipcode: 21120,
    latitude: 12.752,
    longitude: 101.258,
  },
  {
    province: "ระยอง",
    district: "บ้านค่าย",
    subdistrict: "ตาขัน",
    zipcode: 21120,
    latitude: 12.735,
    longitude: 101.321,
  },
  {
    province: "ระยอง",
    district: "บ้านค่าย",
    subdistrict: "บางบุตร",
    zipcode: 21120,
    latitude: 12.848,
    longitude: 101.391,
  },
  {
    province: "ระยอง",
    district: "บ้านค่าย",
    subdistrict: "หนองบัว",
    zipcode: 21120,
    latitude: 12.906,
    longitude: 101.373,
  },
  {
    province: "ระยอง",
    district: "บ้านค่าย",
    subdistrict: "ชากบก",
    zipcode: 21120,
    latitude: 12.783,
    longitude: 101.364,
  },
  {
    province: "ระยอง",
    district: "ปลวกแดง",
    subdistrict: "ปลวกแดง",
    zipcode: 21140,
    latitude: 12.978,
    longitude: 101.226,
  },
  {
    province: "ระยอง",
    district: "ปลวกแดง",
    subdistrict: "ตาสิทธิ์",
    zipcode: 21140,
    latitude: 13.038,
    longitude: 101.225,
  },
  {
    province: "ระยอง",
    district: "ปลวกแดง",
    subdistrict: "ละหาร",
    zipcode: 21140,
    latitude: 12.973,
    longitude: 101.311,
  },
  {
    province: "ระยอง",
    district: "ปลวกแดง",
    subdistrict: "แม่น้ำคู้",
    zipcode: 21140,
    latitude: 12.908,
    longitude: 101.23,
  },
  {
    province: "ระยอง",
    district: "ปลวกแดง",
    subdistrict: "มาบยางพร",
    zipcode: 21140,
    latitude: 12.97,
    longitude: 101.136,
  },
  {
    province: "ระยอง",
    district: "ปลวกแดง",
    subdistrict: "หนองไร่",
    zipcode: 21140,
    latitude: 13.039,
    longitude: 101.338,
  },
  {
    province: "ระยอง",
    district: "เขาชะเมา",
    subdistrict: "น้ำเป็น",
    zipcode: 21110,
    latitude: 12.901,
    longitude: 101.749,
  },
  {
    province: "ระยอง",
    district: "เขาชะเมา",
    subdistrict: "ห้วยทับมอญ",
    zipcode: 21110,
    latitude: 13.027,
    longitude: 101.686,
  },
  {
    province: "ระยอง",
    district: "เขาชะเมา",
    subdistrict: "ชำฆ้อ",
    zipcode: 21110,
    latitude: 12.933,
    longitude: 101.641,
  },
  {
    province: "ระยอง",
    district: "เขาชะเมา",
    subdistrict: "เขาน้อย",
    zipcode: 21110,
    latitude: 13.062,
    longitude: 101.617,
  },
  {
    province: "ระยอง",
    district: "นิคมพัฒนา",
    subdistrict: "นิคมพัฒนา",
    zipcode: 21180,
    latitude: 12.856,
    longitude: 101.177,
  },
  {
    province: "ระยอง",
    district: "นิคมพัฒนา",
    subdistrict: "มาบข่า",
    zipcode: 21180,
    latitude: 12.782,
    longitude: 101.193,
  },
  {
    province: "ระยอง",
    district: "นิคมพัฒนา",
    subdistrict: "พนานิคม",
    zipcode: 21180,
    latitude: 12.902,
    longitude: 101.135,
  },
  {
    province: "ระยอง",
    district: "นิคมพัฒนา",
    subdistrict: "มะขามคู่",
    zipcode: 21180,
    latitude: 12.847,
    longitude: 101.101,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "ตลาด",
    zipcode: 22000,
    latitude: 12.591,
    longitude: 102.087,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "วัดใหม่",
    zipcode: 22000,
    latitude: 12.611,
    longitude: 102.105,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "คลองนารายณ์",
    zipcode: 22000,
    latitude: 12.574,
    longitude: 102.166,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "เกาะขวาง",
    zipcode: 22000,
    latitude: 12.562,
    longitude: 102.09,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "คมบาง",
    zipcode: 22000,
    latitude: 12.542,
    longitude: 102.169,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "ท่าช้าง",
    zipcode: 22000,
    latitude: 12.644,
    longitude: 102.098,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "จันทนิมิต",
    zipcode: 22000,
    latitude: 12.61,
    longitude: 102.123,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "บางกะจะ",
    zipcode: 22000,
    latitude: 12.563,
    longitude: 102.056,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "แสลง",
    zipcode: 22000,
    latitude: 12.694,
    longitude: 102.107,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "หนองบัว",
    zipcode: 22000,
    latitude: 12.534,
    longitude: 102.101,
  },
  {
    province: "จันทบุรี",
    district: "เมืองจันทบุรี",
    subdistrict: "พลับพลา",
    zipcode: 22000,
    latitude: 12.605,
    longitude: 102.172,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "ขลุง",
    zipcode: 22110,
    latitude: 12.452,
    longitude: 102.218,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "บ่อ",
    zipcode: 22110,
    latitude: 12.412,
    longitude: 102.279,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "เกวียนหัก",
    zipcode: 22110,
    latitude: 12.468,
    longitude: 102.2,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "ตะปอน",
    zipcode: 22110,
    latitude: 12.493,
    longitude: 102.175,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "บางชัน",
    zipcode: 22110,
    latitude: 12.288,
    longitude: 102.251,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "วันยาว",
    zipcode: 22110,
    latitude: 12.439,
    longitude: 102.234,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "ซึ้ง",
    zipcode: 22110,
    latitude: 12.487,
    longitude: 102.288,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "มาบไพ",
    zipcode: 22110,
    latitude: 12.598,
    longitude: 102.258,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "วังสรรพรส",
    zipcode: 22110,
    latitude: 12.56,
    longitude: 102.29,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "ตรอกนอง",
    zipcode: 22110,
    latitude: 12.533,
    longitude: 102.275,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "ตกพรม",
    zipcode: 22110,
    latitude: 12.625,
    longitude: 102.339,
  },
  {
    province: "จันทบุรี",
    district: "ขลุง",
    subdistrict: "บ่อเวฬุ",
    zipcode: 22150,
    latitude: 12.723,
    longitude: 102.367,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "ท่าใหม่",
    zipcode: 22120,
    latitude: 12.628,
    longitude: 101.985,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "ยายร้า",
    zipcode: 22120,
    latitude: 12.612,
    longitude: 102.027,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "สีพยา",
    zipcode: 22120,
    latitude: 12.578,
    longitude: 102.029,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "บ่อพุ",
    zipcode: 22120,
    latitude: 12.595,
    longitude: 102.028,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "พลอยแหวน",
    zipcode: 22120,
    latitude: 12.617,
    longitude: 102.053,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "เขาวัว",
    zipcode: 22120,
    latitude: 12.637,
    longitude: 102.035,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "เขาบายศรี",
    zipcode: 22120,
    latitude: 12.74,
    longitude: 102.061,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "สองพี่น้อง",
    zipcode: 22120,
    latitude: 12.672,
    longitude: 101.973,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "ทุ่งเบญจา",
    zipcode: 22170,
    latitude: 12.814,
    longitude: 102.014,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "รำพัน",
    zipcode: 22170,
    latitude: 12.669,
    longitude: 101.914,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "โขมง",
    zipcode: 22170,
    latitude: 12.62,
    longitude: 101.94,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "ตะกาดเง้า",
    zipcode: 22120,
    latitude: 12.581,
    longitude: 101.989,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "คลองขุด",
    zipcode: 22120,
    latitude: 12.512,
    longitude: 101.95,
  },
  {
    province: "จันทบุรี",
    district: "ท่าใหม่",
    subdistrict: "เขาแก้ว",
    zipcode: 22170,
    latitude: 12.867,
    longitude: 101.939,
  },
  {
    province: "จันทบุรี",
    district: "โป่งน้ำร้อน",
    subdistrict: "ทับไทร",
    zipcode: 22140,
    latitude: 12.95,
    longitude: 102.247,
  },
  {
    province: "จันทบุรี",
    district: "โป่งน้ำร้อน",
    subdistrict: "โป่งน้ำร้อน",
    zipcode: 22140,
    latitude: 12.89,
    longitude: 102.382,
  },
  {
    province: "จันทบุรี",
    district: "โป่งน้ำร้อน",
    subdistrict: "หนองตาคง",
    zipcode: 22140,
    latitude: 13.067,
    longitude: 102.381,
  },
  {
    province: "จันทบุรี",
    district: "โป่งน้ำร้อน",
    subdistrict: "เทพนิมิต",
    zipcode: 22140,
    latitude: 13.044,
    longitude: 102.453,
  },
  {
    province: "จันทบุรี",
    district: "โป่งน้ำร้อน",
    subdistrict: "คลองใหญ่",
    zipcode: 22140,
    latitude: 12.873,
    longitude: 102.476,
  },
  {
    province: "จันทบุรี",
    district: "มะขาม",
    subdistrict: "มะขาม",
    zipcode: 22150,
    latitude: 12.677,
    longitude: 102.187,
  },
  {
    province: "จันทบุรี",
    district: "มะขาม",
    subdistrict: "ท่าหลวง",
    zipcode: 22150,
    latitude: 12.7,
    longitude: 102.147,
  },
  {
    province: "จันทบุรี",
    district: "มะขาม",
    subdistrict: "ปัถวี",
    zipcode: 22150,
    latitude: 12.743,
    longitude: 102.272,
  },
  {
    province: "จันทบุรี",
    district: "มะขาม",
    subdistrict: "วังแซ้ม",
    zipcode: 22150,
    latitude: 12.756,
    longitude: 102.156,
  },
  {
    province: "จันทบุรี",
    district: "มะขาม",
    subdistrict: "ฉมัน",
    zipcode: 22150,
    latitude: 12.817,
    longitude: 102.224,
  },
  {
    province: "จันทบุรี",
    district: "มะขาม",
    subdistrict: "อ่างคีรี",
    zipcode: 22150,
    latitude: 12.644,
    longitude: 102.226,
  },
  {
    province: "จันทบุรี",
    district: "แหลมสิงห์",
    subdistrict: "ปากน้ำแหลมสิงห์",
    zipcode: 22130,
    latitude: 12.452,
    longitude: 102.122,
  },
  {
    province: "จันทบุรี",
    district: "แหลมสิงห์",
    subdistrict: "เกาะเปริด",
    zipcode: 22130,
    latitude: 12.424,
    longitude: 102.109,
  },
  {
    province: "จันทบุรี",
    district: "แหลมสิงห์",
    subdistrict: "หนองชิ่ม",
    zipcode: 22130,
    latitude: 12.417,
    longitude: 102.178,
  },
  {
    province: "จันทบุรี",
    district: "แหลมสิงห์",
    subdistrict: "พลิ้ว",
    zipcode: 22190,
    latitude: 12.516,
    longitude: 102.161,
  },
  {
    province: "จันทบุรี",
    district: "แหลมสิงห์",
    subdistrict: "คลองน้ำเค็ม",
    zipcode: 22190,
    latitude: 12.498,
    longitude: 102.123,
  },
  {
    province: "จันทบุรี",
    district: "แหลมสิงห์",
    subdistrict: "บางสระเก้า",
    zipcode: 22190,
    latitude: 12.511,
    longitude: 102.109,
  },
  {
    province: "จันทบุรี",
    district: "แหลมสิงห์",
    subdistrict: "บางกะไชย",
    zipcode: 22120,
    latitude: 12.465,
    longitude: 102.066,
  },
  {
    province: "จันทบุรี",
    district: "สอยดาว",
    subdistrict: "ปะตง",
    zipcode: 22180,
    latitude: 13.17,
    longitude: 102.194,
  },
  {
    province: "จันทบุรี",
    district: "สอยดาว",
    subdistrict: "ทุ่งขนาน",
    zipcode: 22180,
    latitude: 13.232,
    longitude: 102.319,
  },
  {
    province: "จันทบุรี",
    district: "สอยดาว",
    subdistrict: "ทับช้าง",
    zipcode: 22180,
    latitude: 13.265,
    longitude: 102.161,
  },
  {
    province: "จันทบุรี",
    district: "สอยดาว",
    subdistrict: "ทรายขาว",
    zipcode: 22180,
    latitude: 13.086,
    longitude: 102.197,
  },
  {
    province: "จันทบุรี",
    district: "สอยดาว",
    subdistrict: "สะตอน",
    zipcode: 22180,
    latitude: 13.174,
    longitude: 102.371,
  },
  {
    province: "จันทบุรี",
    district: "แก่งหางแมว",
    subdistrict: "แก่งหางแมว",
    zipcode: 22160,
    latitude: 12.982,
    longitude: 101.929,
  },
  {
    province: "จันทบุรี",
    district: "แก่งหางแมว",
    subdistrict: "ขุนซ่อง",
    zipcode: 22160,
    latitude: 13.169,
    longitude: 101.961,
  },
  {
    province: "จันทบุรี",
    district: "แก่งหางแมว",
    subdistrict: "สามพี่น้อง",
    zipcode: 22160,
    latitude: 12.977,
    longitude: 101.817,
  },
  {
    province: "จันทบุรี",
    district: "แก่งหางแมว",
    subdistrict: "พวา",
    zipcode: 22160,
    latitude: 13.105,
    longitude: 101.791,
  },
  {
    province: "จันทบุรี",
    district: "แก่งหางแมว",
    subdistrict: "เขาวงกต",
    zipcode: 22160,
    latitude: 12.881,
    longitude: 101.848,
  },
  {
    province: "จันทบุรี",
    district: "นายายอาม",
    subdistrict: "นายายอาม",
    zipcode: 22160,
    latitude: 12.782,
    longitude: 101.847,
  },
  {
    province: "จันทบุรี",
    district: "นายายอาม",
    subdistrict: "วังโตนด",
    zipcode: 22170,
    latitude: 12.701,
    longitude: 101.933,
  },
  {
    province: "จันทบุรี",
    district: "นายายอาม",
    subdistrict: "กระแจะ",
    zipcode: 22170,
    latitude: 12.689,
    longitude: 101.861,
  },
  {
    province: "จันทบุรี",
    district: "นายายอาม",
    subdistrict: "สนามไชย",
    zipcode: 22170,
    latitude: 12.625,
    longitude: 101.874,
  },
  {
    province: "จันทบุรี",
    district: "นายายอาม",
    subdistrict: "ช้างข้าม",
    zipcode: 22160,
    latitude: 12.707,
    longitude: 101.819,
  },
  {
    province: "จันทบุรี",
    district: "นายายอาม",
    subdistrict: "วังใหม่",
    zipcode: 22170,
    latitude: 12.764,
    longitude: 101.904,
  },
  {
    province: "จันทบุรี",
    district: "เขาคิชฌกูฏ",
    subdistrict: "ชากไทย",
    zipcode: 22210,
    latitude: 12.9653095,
    longitude: 101.9304749,
  },
  {
    province: "จันทบุรี",
    district: "เขาคิชฌกูฏ",
    subdistrict: "พลวง",
    zipcode: 22210,
    latitude: 12.822,
    longitude: 102.106,
  },
  {
    province: "จันทบุรี",
    district: "เขาคิชฌกูฏ",
    subdistrict: "ตะเคียนทอง",
    zipcode: 22210,
    latitude: 12.903,
    longitude: 102.132,
  },
  {
    province: "จันทบุรี",
    district: "เขาคิชฌกูฏ",
    subdistrict: "คลองพลู",
    zipcode: 22210,
    latitude: 12.931,
    longitude: 102.014,
  },
  {
    province: "จันทบุรี",
    district: "เขาคิชฌกูฏ",
    subdistrict: "จันทเขลม",
    zipcode: 22210,
    latitude: 13.071,
    longitude: 102.09,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "บางพระ",
    zipcode: 23000,
    latitude: 12.232823,
    longitude: 102.5095649,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "หนองเสม็ด",
    zipcode: 23000,
    latitude: 12.216,
    longitude: 102.504,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "หนองโสน",
    zipcode: 23000,
    latitude: 12.184,
    longitude: 102.488,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "หนองคันทรง",
    zipcode: 23000,
    latitude: 12.194,
    longitude: 102.542,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "ห้วงน้ำขาว",
    zipcode: 23000,
    latitude: 12.143,
    longitude: 102.518,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "อ่าวใหญ่",
    zipcode: 23000,
    latitude: 12.078,
    longitude: 102.562,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "วังกระแจะ",
    zipcode: 23000,
    latitude: 12.272,
    longitude: 102.479,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "ห้วยแร้ง",
    zipcode: 23000,
    latitude: 12.387,
    longitude: 102.56,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "เนินทราย",
    zipcode: 23000,
    latitude: 12.283,
    longitude: 102.547,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "ท่าพริก",
    zipcode: 23000,
    latitude: 12.243,
    longitude: 102.589,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "ท่ากุ่ม",
    zipcode: 23000,
    latitude: 12.345,
    longitude: 102.675,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "ตะกาง",
    zipcode: 23000,
    latitude: 12.25,
    longitude: 102.659,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "ชำราก",
    zipcode: 23000,
    latitude: 12.194,
    longitude: 102.677,
  },
  {
    province: "ตราด",
    district: "เมืองตราด",
    subdistrict: "แหลมกลัด",
    zipcode: 23000,
    latitude: 12.117,
    longitude: 102.704,
  },
  {
    province: "ตราด",
    district: "คลองใหญ่",
    subdistrict: "คลองใหญ่",
    zipcode: 23110,
    latitude: 11.774,
    longitude: 102.889,
  },
  {
    province: "ตราด",
    district: "คลองใหญ่",
    subdistrict: "ไม้รูด",
    zipcode: 23110,
    latitude: 11.91,
    longitude: 102.805,
  },
  {
    province: "ตราด",
    district: "คลองใหญ่",
    subdistrict: "หาดเล็ก",
    zipcode: 23110,
    latitude: 11.699,
    longitude: 102.909,
  },
  {
    province: "ตราด",
    district: "เขาสมิง",
    subdistrict: "เขาสมิง",
    zipcode: 23130,
    latitude: 12.34,
    longitude: 102.437,
  },
  {
    province: "ตราด",
    district: "เขาสมิง",
    subdistrict: "แสนตุ้ง",
    zipcode: 23150,
    latitude: 12.398,
    longitude: 102.382,
  },
  {
    province: "ตราด",
    district: "เขาสมิง",
    subdistrict: "วังตะเคียน",
    zipcode: 23130,
    latitude: 12.478,
    longitude: 102.531,
  },
  {
    province: "ตราด",
    district: "เขาสมิง",
    subdistrict: "ท่าโสม",
    zipcode: 23150,
    latitude: 12.296,
    longitude: 102.346,
  },
  {
    province: "ตราด",
    district: "เขาสมิง",
    subdistrict: "สะตอ",
    zipcode: 23150,
    latitude: 12.553,
    longitude: 102.434,
  },
  {
    province: "ตราด",
    district: "เขาสมิง",
    subdistrict: "ประณีต",
    zipcode: 23150,
    latitude: 12.525,
    longitude: 102.359,
  },
  {
    province: "ตราด",
    district: "เขาสมิง",
    subdistrict: "เทพนิมิต",
    zipcode: 23150,
    latitude: 12.469,
    longitude: 102.435,
  },
  {
    province: "ตราด",
    district: "เขาสมิง",
    subdistrict: "ทุ่งนนทรี",
    zipcode: 23130,
    latitude: 12.405,
    longitude: 102.501,
  },
  {
    province: "ตราด",
    district: "บ่อไร่",
    subdistrict: "บ่อพลอย",
    zipcode: 23140,
    latitude: 12.604,
    longitude: 102.559,
  },
  {
    province: "ตราด",
    district: "บ่อไร่",
    subdistrict: "ช้างทูน",
    zipcode: 23140,
    latitude: 12.585,
    longitude: 102.469,
  },
  {
    province: "ตราด",
    district: "บ่อไร่",
    subdistrict: "ด่านชุมพล",
    zipcode: 23140,
    latitude: 12.461,
    longitude: 102.664,
  },
  {
    province: "ตราด",
    district: "บ่อไร่",
    subdistrict: "หนองบอน",
    zipcode: 23140,
    latitude: 12.683,
    longitude: 102.447,
  },
  {
    province: "ตราด",
    district: "บ่อไร่",
    subdistrict: "นนทรีย์",
    zipcode: 23140,
    latitude: 12.544,
    longitude: 102.6,
  },
  {
    province: "ตราด",
    district: "แหลมงอบ",
    subdistrict: "แหลมงอบ",
    zipcode: 23120,
    latitude: 12.186,
    longitude: 102.419,
  },
  {
    province: "ตราด",
    district: "แหลมงอบ",
    subdistrict: "น้ำเชี่ยว",
    zipcode: 23120,
    latitude: 12.209,
    longitude: 102.436,
  },
  {
    province: "ตราด",
    district: "แหลมงอบ",
    subdistrict: "บางปิด",
    zipcode: 23120,
    latitude: 12.241,
    longitude: 102.301,
  },
  {
    province: "ตราด",
    district: "แหลมงอบ",
    subdistrict: "คลองใหญ่",
    zipcode: 23120,
    latitude: 12.226,
    longitude: 102.362,
  },
  {
    province: "ตราด",
    district: "เกาะกูด",
    subdistrict: "เกาะหมาก",
    zipcode: 23000,
    latitude: 11.817,
    longitude: 102.397,
  },
  {
    province: "ตราด",
    district: "เกาะกูด",
    subdistrict: "เกาะกูด",
    zipcode: 23000,
    latitude: 11.719,
    longitude: 102.519,
  },
  {
    province: "ตราด",
    district: "เกาะช้าง",
    subdistrict: "เกาะช้าง",
    zipcode: 23170,
    latitude: 12.161,
    longitude: 102.247,
  },
  {
    province: "ตราด",
    district: "เกาะช้าง",
    subdistrict: "เกาะช้างใต้",
    zipcode: 23170,
    latitude: 11.996,
    longitude: 102.331,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "หน้าเมือง",
    zipcode: 24000,
    latitude: 13.686,
    longitude: 101.066,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "ท่าไข่",
    zipcode: 24000,
    latitude: 13.736,
    longitude: 101.06,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "บ้านใหม่",
    zipcode: 24000,
    latitude: 13.707,
    longitude: 101.1,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "คลองนา",
    zipcode: 24000,
    latitude: 13.648,
    longitude: 101.102,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "บางตีนเป็ด",
    zipcode: 24000,
    latitude: 13.661,
    longitude: 101.075,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "บางไผ่",
    zipcode: 24000,
    latitude: 13.692,
    longitude: 101.102,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "คลองจุกกระเฌอ",
    zipcode: 24000,
    latitude: 13.686,
    longitude: 101.125,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "บางแก้ว",
    zipcode: 24000,
    latitude: 13.765,
    longitude: 101.094,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "บางขวัญ",
    zipcode: 24000,
    latitude: 13.78,
    longitude: 101.054,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "คลองนครเนื่องเขต",
    zipcode: 24000,
    latitude: 13.766,
    longitude: 101.008,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "วังตะเคียน",
    zipcode: 24000,
    latitude: 13.719,
    longitude: 101.034,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "โสธร",
    zipcode: 24000,
    latitude: 13.68,
    longitude: 101.053,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "บางพระ",
    zipcode: 24000,
    latitude: 13.653,
    longitude: 101.02,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "บางกะไห",
    zipcode: 24000,
    latitude: 13.670775,
    longitude: 100.9826466,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "หนามแดง",
    zipcode: 24000,
    latitude: 13.736,
    longitude: 100.989,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "คลองเปรง",
    zipcode: 24000,
    latitude: 13.707,
    longitude: 100.933,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "คลองอุดมชลจร",
    zipcode: 24000,
    latitude: 13.736,
    longitude: 100.92,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "คลองหลวงแพ่ง",
    zipcode: 24000,
    latitude: 13.78,
    longitude: 100.927,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "เมืองฉะเชิงเทรา",
    subdistrict: "บางเตย",
    zipcode: 24000,
    latitude: 13.706,
    longitude: 100.993,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางคล้า",
    subdistrict: "บางคล้า",
    zipcode: 24110,
    latitude: 13.722,
    longitude: 101.21,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางคล้า",
    subdistrict: "บางสวน",
    zipcode: 24110,
    latitude: 13.696,
    longitude: 101.177,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางคล้า",
    subdistrict: "บางกระเจ็ด",
    zipcode: 24110,
    latitude: 13.852,
    longitude: 101.208,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางคล้า",
    subdistrict: "ปากน้ำ",
    zipcode: 24110,
    latitude: 13.753,
    longitude: 101.212,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางคล้า",
    subdistrict: "ท่าทองหลาง",
    zipcode: 24110,
    latitude: 13.694,
    longitude: 101.229,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางคล้า",
    subdistrict: "สาวชะโงก",
    zipcode: 24110,
    latitude: 13.683,
    longitude: 101.152,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางคล้า",
    subdistrict: "เสม็ดเหนือ",
    zipcode: 24110,
    latitude: 13.673,
    longitude: 101.201,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางคล้า",
    subdistrict: "เสม็ดใต้",
    zipcode: 24110,
    latitude: 13.653,
    longitude: 101.162,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางคล้า",
    subdistrict: "หัวไทร",
    zipcode: 24110,
    latitude: 13.799,
    longitude: 101.221,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "บางน้ำเปรี้ยว",
    zipcode: 24150,
    latitude: 13.829,
    longitude: 101.002,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "บางขนาก",
    zipcode: 24150,
    latitude: 13.858,
    longitude: 101.115,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "สิงโตทอง",
    zipcode: 24150,
    latitude: 13.911,
    longitude: 101.073,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "หมอนทอง",
    zipcode: 24150,
    latitude: 13.874,
    longitude: 101.043,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "บึงน้ำรักษ์",
    zipcode: 24170,
    latitude: 13.908,
    longitude: 100.926,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "ดอนเกาะกา",
    zipcode: 24170,
    latitude: 13.944,
    longitude: 101.016,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "โยธะกา",
    zipcode: 24150,
    latitude: 13.925,
    longitude: 101.128,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "ดอนฉิมพลี",
    zipcode: 24170,
    latitude: 13.908,
    longitude: 100.964,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "ศาลาแดง",
    zipcode: 24000,
    latitude: 13.838,
    longitude: 100.928,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางน้ำเปรี้ยว",
    subdistrict: "โพรงอากาศ",
    zipcode: 24150,
    latitude: 13.827,
    longitude: 101.062,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "บางปะกง",
    zipcode: 24130,
    latitude: 13.496,
    longitude: 100.966,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "ท่าสะอ้าน",
    zipcode: 24130,
    latitude: 13.553,
    longitude: 100.995,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "บางวัว",
    zipcode: 24180,
    latitude: 13.569,
    longitude: 100.964,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "บางสมัคร",
    zipcode: 24180,
    latitude: 13.575,
    longitude: 100.936,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "บางผึ้ง",
    zipcode: 24130,
    latitude: 13.527,
    longitude: 101.054,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "บางเกลือ",
    zipcode: 24180,
    latitude: 13.526,
    longitude: 100.919,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "สองคลอง",
    zipcode: 24130,
    latitude: 13.484,
    longitude: 100.91,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "หนองจอก",
    zipcode: 24130,
    latitude: 13.603,
    longitude: 100.989,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "พิมพา",
    zipcode: 24180,
    latitude: 13.599,
    longitude: 100.937,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "ท่าข้าม",
    zipcode: 24130,
    latitude: 13.479,
    longitude: 100.994,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "หอมศีล",
    zipcode: 24180,
    latitude: 13.543,
    longitude: 100.892,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บางปะกง",
    subdistrict: "เขาดิน",
    zipcode: 24130,
    latitude: 13.528,
    longitude: 101.026,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "บ้านโพธิ์",
    zipcode: 24140,
    latitude: 13.588,
    longitude: 101.086,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "เกาะไร่",
    zipcode: 24140,
    latitude: 13.678,
    longitude: 100.958,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "คลองขุด",
    zipcode: 24140,
    latitude: 13.611,
    longitude: 101.194,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "คลองบ้านโพธิ์",
    zipcode: 24140,
    latitude: 13.572,
    longitude: 101.057,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "คลองประเวศ",
    zipcode: 24140,
    latitude: 13.63,
    longitude: 101.024,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "ดอนทราย",
    zipcode: 24140,
    latitude: 13.631,
    longitude: 101.125,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "เทพราช",
    zipcode: 24140,
    latitude: 13.641,
    longitude: 100.982,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "ท่าพลับ",
    zipcode: 24140,
    latitude: 13.616,
    longitude: 101.075,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "หนองตีนนก",
    zipcode: 24140,
    latitude: 13.585,
    longitude: 101.107,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "หนองบัว",
    zipcode: 24140,
    latitude: 13.617,
    longitude: 101.101,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "บางซ่อน",
    zipcode: 24140,
    latitude: 13.558,
    longitude: 101.058,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "บางกรูด",
    zipcode: 24140,
    latitude: 13.633,
    longitude: 101.078,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "แหลมประดู่",
    zipcode: 24140,
    latitude: 13.597,
    longitude: 101.209,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "ลาดขวาง",
    zipcode: 24140,
    latitude: 13.605,
    longitude: 101.023,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "สนามจันทร์",
    zipcode: 24140,
    latitude: 13.594,
    longitude: 101.066,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "แสนภูดาษ",
    zipcode: 24140,
    latitude: 13.582,
    longitude: 101.015,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "บ้านโพธิ์",
    subdistrict: "สิบเอ็ดศอก",
    zipcode: 24140,
    latitude: 13.605,
    longitude: 101.148,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "พนมสารคาม",
    subdistrict: "เกาะขนุน",
    zipcode: 24120,
    latitude: 13.695,
    longitude: 101.399,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "พนมสารคาม",
    subdistrict: "บ้านซ่อง",
    zipcode: 24120,
    latitude: 13.803,
    longitude: 101.403,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "พนมสารคาม",
    subdistrict: "พนมสารคาม",
    zipcode: 24120,
    latitude: 13.771,
    longitude: 101.324,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "พนมสารคาม",
    subdistrict: "เมืองเก่า",
    zipcode: 24120,
    latitude: 13.728,
    longitude: 101.319,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "พนมสารคาม",
    subdistrict: "หนองยาว",
    zipcode: 24120,
    latitude: 13.816,
    longitude: 101.351,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "พนมสารคาม",
    subdistrict: "ท่าถ่าน",
    zipcode: 24120,
    latitude: 13.76,
    longitude: 101.383,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "พนมสารคาม",
    subdistrict: "หนองแหน",
    zipcode: 24120,
    latitude: 13.661,
    longitude: 101.335,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "พนมสารคาม",
    subdistrict: "เขาหินซ้อน",
    zipcode: 24120,
    latitude: 13.75,
    longitude: 101.494,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "ราชสาส์น",
    subdistrict: "บางคา",
    zipcode: 24120,
    latitude: 13.788,
    longitude: 101.278,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "ราชสาส์น",
    subdistrict: "เมืองใหม่",
    zipcode: 24120,
    latitude: 13.715,
    longitude: 101.278,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "ราชสาส์น",
    subdistrict: "ดงน้อย",
    zipcode: 24120,
    latitude: 13.836,
    longitude: 101.291,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "สนามชัยเขต",
    subdistrict: "คู้ยายหมี",
    zipcode: 24160,
    latitude: 13.644,
    longitude: 101.461,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "สนามชัยเขต",
    subdistrict: "ท่ากระดาน",
    zipcode: 24160,
    latitude: 13.606,
    longitude: 101.71,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "สนามชัยเขต",
    subdistrict: "ทุ่งพระยา",
    zipcode: 24160,
    latitude: 13.687,
    longitude: 101.745,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "สนามชัยเขต",
    subdistrict: "ลาดกระทิง",
    zipcode: 24160,
    latitude: 13.548,
    longitude: 101.475,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "แปลงยาว",
    subdistrict: "แปลงยาว",
    zipcode: 24190,
    latitude: 13.599,
    longitude: 101.293,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "แปลงยาว",
    subdistrict: "วังเย็น",
    zipcode: 24190,
    latitude: 13.536,
    longitude: 101.334,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "แปลงยาว",
    subdistrict: "หัวสำโรง",
    zipcode: 24190,
    latitude: 13.624,
    longitude: 101.315,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "แปลงยาว",
    subdistrict: "หนองไม้แก่น",
    zipcode: 24190,
    latitude: 13.504,
    longitude: 101.404,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "ท่าตะเกียบ",
    subdistrict: "ท่าตะเกียบ",
    zipcode: 24160,
    latitude: 13.508,
    longitude: 101.639,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "ท่าตะเกียบ",
    subdistrict: "คลองตะเกรา",
    zipcode: 24160,
    latitude: 13.299,
    longitude: 101.739,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "คลองเขื่อน",
    subdistrict: "ก้อนแก้ว",
    zipcode: 24000,
    latitude: 13.765,
    longitude: 101.136,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "คลองเขื่อน",
    subdistrict: "คลองเขื่อน",
    zipcode: 24000,
    latitude: 13.772,
    longitude: 101.185,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "คลองเขื่อน",
    subdistrict: "บางเล่า",
    zipcode: 24000,
    latitude: 13.721,
    longitude: 101.15,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "คลองเขื่อน",
    subdistrict: "บางโรง",
    zipcode: 24000,
    latitude: 13.824,
    longitude: 101.137,
  },
  {
    province: "ฉะเชิงเทรา",
    district: "คลองเขื่อน",
    subdistrict: "บางตลาด",
    zipcode: 24110,
    latitude: 13.736,
    longitude: 101.165,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "หน้าเมือง",
    zipcode: 25000,
    latitude: 14.057,
    longitude: 101.374,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "รอบเมือง",
    zipcode: 25000,
    latitude: 14.066,
    longitude: 101.327,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "วัดโบสถ์",
    zipcode: 25000,
    latitude: 14.034,
    longitude: 101.31,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "บางเดชะ",
    zipcode: 25000,
    latitude: 13.991,
    longitude: 101.33,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "ท่างาม",
    zipcode: 25000,
    latitude: 14.017,
    longitude: 101.413,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "บางบริบูรณ์",
    zipcode: 25000,
    latitude: 14.039,
    longitude: 101.429,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "ดงพระราม",
    zipcode: 25000,
    latitude: 14.072,
    longitude: 101.394,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "บ้านพระ",
    zipcode: 25230,
    latitude: 14.116,
    longitude: 101.361,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "โคกไม้ลาย",
    zipcode: 25230,
    latitude: 14.14,
    longitude: 101.298,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "ไม้เค็ด",
    zipcode: 25230,
    latitude: 14.1158389,
    longitude: 101.296806,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "ดงขี้เหล็ก",
    zipcode: 25000,
    latitude: 14.132,
    longitude: 101.439,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "เนินหอม",
    zipcode: 25230,
    latitude: 14.181,
    longitude: 101.378,
  },
  {
    province: "ปราจีนบุรี",
    district: "เมืองปราจีนบุรี",
    subdistrict: "โนนห้อม",
    zipcode: 25000,
    latitude: 14.094,
    longitude: 101.463,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "กบินทร์",
    zipcode: 25110,
    latitude: 13.939,
    longitude: 101.711,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "เมืองเก่า",
    zipcode: 25240,
    latitude: 13.999,
    longitude: 101.77,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "วังดาล",
    zipcode: 25110,
    latitude: 14.006,
    longitude: 101.645,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "นนทรี",
    zipcode: 25110,
    latitude: 14.056,
    longitude: 101.668,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "ย่านรี",
    zipcode: 25110,
    latitude: 13.897,
    longitude: 101.785,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "วังตะเคียน",
    zipcode: 25110,
    latitude: 13.849,
    longitude: 101.87,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "หาดนางแก้ว",
    zipcode: 25110,
    latitude: 13.963,
    longitude: 101.642,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "ลาดตะเคียน",
    zipcode: 25110,
    latitude: 13.873,
    longitude: 101.658,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "บ้านนา",
    zipcode: 25110,
    latitude: 13.99,
    longitude: 101.88,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "บ่อทอง",
    zipcode: 25110,
    latitude: 13.935,
    longitude: 101.881,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "หนองกี่",
    zipcode: 25110,
    latitude: 14.068,
    longitude: 101.841,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "นาแขม",
    zipcode: 25110,
    latitude: 14.052,
    longitude: 101.723,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "เขาไม้แก้ว",
    zipcode: 25110,
    latitude: 13.801,
    longitude: 101.775,
  },
  {
    province: "ปราจีนบุรี",
    district: "กบินทร์บุรี",
    subdistrict: "วังท่าช้าง",
    zipcode: 25110,
    latitude: 13.694,
    longitude: 101.885,
  },
  {
    province: "ปราจีนบุรี",
    district: "นาดี",
    subdistrict: "นาดี",
    zipcode: 25220,
    latitude: 14.189,
    longitude: 101.792,
  },
  {
    province: "ปราจีนบุรี",
    district: "นาดี",
    subdistrict: "สำพันตา",
    zipcode: 25220,
    latitude: 14.081,
    longitude: 101.766,
  },
  {
    province: "ปราจีนบุรี",
    district: "นาดี",
    subdistrict: "สะพานหิน",
    zipcode: 25220,
    latitude: 14.152,
    longitude: 101.665,
  },
  {
    province: "ปราจีนบุรี",
    district: "นาดี",
    subdistrict: "ทุ่งโพธิ์",
    zipcode: 25220,
    latitude: 14.141,
    longitude: 101.889,
  },
  {
    province: "ปราจีนบุรี",
    district: "นาดี",
    subdistrict: "แก่งดินสอ",
    zipcode: 25220,
    latitude: 14.095,
    longitude: 101.984,
  },
  {
    province: "ปราจีนบุรี",
    district: "นาดี",
    subdistrict: "บุพราหมณ์",
    zipcode: 25220,
    latitude: 14.268,
    longitude: 101.872,
  },
  {
    province: "ปราจีนบุรี",
    district: "บ้านสร้าง",
    subdistrict: "บ้านสร้าง",
    zipcode: 25150,
    latitude: 14.026,
    longitude: 101.21,
  },
  {
    province: "ปราจีนบุรี",
    district: "บ้านสร้าง",
    subdistrict: "บางกระเบา",
    zipcode: 25150,
    latitude: 13.984,
    longitude: 101.202,
  },
  {
    province: "ปราจีนบุรี",
    district: "บ้านสร้าง",
    subdistrict: "บางเตย",
    zipcode: 25150,
    latitude: 13.946,
    longitude: 101.223,
  },
  {
    province: "ปราจีนบุรี",
    district: "บ้านสร้าง",
    subdistrict: "บางยาง",
    zipcode: 25150,
    latitude: 13.94,
    longitude: 101.175,
  },
  {
    province: "ปราจีนบุรี",
    district: "บ้านสร้าง",
    subdistrict: "บางแตน",
    zipcode: 25150,
    latitude: 13.89,
    longitude: 101.177,
  },
  {
    province: "ปราจีนบุรี",
    district: "บ้านสร้าง",
    subdistrict: "บางพลวง",
    zipcode: 25150,
    latitude: 13.986,
    longitude: 101.282,
  },
  {
    province: "ปราจีนบุรี",
    district: "บ้านสร้าง",
    subdistrict: "บางปลาร้า",
    zipcode: 25150,
    latitude: 13.926,
    longitude: 101.313,
  },
  {
    province: "ปราจีนบุรี",
    district: "บ้านสร้าง",
    subdistrict: "บางขาม",
    zipcode: 25150,
    latitude: 13.893,
    longitude: 101.274,
  },
  {
    province: "ปราจีนบุรี",
    district: "บ้านสร้าง",
    subdistrict: "กระทุ่มแพ้ว",
    zipcode: 25150,
    latitude: 13.891,
    longitude: 101.346,
  },
  {
    province: "ปราจีนบุรี",
    district: "ประจันตคาม",
    subdistrict: "ประจันตคาม",
    zipcode: 25130,
    latitude: 14.055,
    longitude: 101.497,
  },
  {
    province: "ปราจีนบุรี",
    district: "ประจันตคาม",
    subdistrict: "เกาะลอย",
    zipcode: 25130,
    latitude: 14.021,
    longitude: 101.515,
  },
  {
    province: "ปราจีนบุรี",
    district: "ประจันตคาม",
    subdistrict: "บ้านหอย",
    zipcode: 25130,
    latitude: 13.996,
    longitude: 101.567,
  },
  {
    province: "ปราจีนบุรี",
    district: "ประจันตคาม",
    subdistrict: "หนองแสง",
    zipcode: 25130,
    latitude: 14.052,
    longitude: 101.563,
  },
  {
    province: "ปราจีนบุรี",
    district: "ประจันตคาม",
    subdistrict: "ดงบัง",
    zipcode: 25130,
    latitude: 14.02,
    longitude: 101.579,
  },
  {
    province: "ปราจีนบุรี",
    district: "ประจันตคาม",
    subdistrict: "คำโตนด",
    zipcode: 25130,
    latitude: 14.112,
    longitude: 101.597,
  },
  {
    province: "ปราจีนบุรี",
    district: "ประจันตคาม",
    subdistrict: "บุฝ้าย",
    zipcode: 25130,
    latitude: 14.298,
    longitude: 101.601,
  },
  {
    province: "ปราจีนบุรี",
    district: "ประจันตคาม",
    subdistrict: "หนองแก้ว",
    zipcode: 25130,
    latitude: 14.111,
    longitude: 101.548,
  },
  {
    province: "ปราจีนบุรี",
    district: "ประจันตคาม",
    subdistrict: "โพธิ์งาม",
    zipcode: 25130,
    latitude: 14.214,
    longitude: 101.466,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "ศรีมหาโพธิ",
    zipcode: 25140,
    latitude: 13.849,
    longitude: 101.567,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "สัมพันธ์",
    zipcode: 25140,
    latitude: 13.997,
    longitude: 101.48,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "บ้านทาม",
    zipcode: 25140,
    latitude: 13.976,
    longitude: 101.552,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "ท่าตูม",
    zipcode: 25140,
    latitude: 13.932,
    longitude: 101.571,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "บางกุ้ง",
    zipcode: 25140,
    latitude: 13.964,
    longitude: 101.483,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "ดงกระทงยาม",
    zipcode: 25140,
    latitude: 13.958,
    longitude: 101.426,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "หนองโพรง",
    zipcode: 25140,
    latitude: 13.787,
    longitude: 101.581,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "หัวหว้า",
    zipcode: 25140,
    latitude: 13.908,
    longitude: 101.461,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "หาดยาง",
    zipcode: 25140,
    latitude: 13.988,
    longitude: 101.432,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมหาโพธิ",
    subdistrict: "กรอกสมบูรณ์",
    zipcode: 25140,
    latitude: 13.767,
    longitude: 101.66,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมโหสถ",
    subdistrict: "โคกปีบ",
    zipcode: 25190,
    latitude: 13.88,
    longitude: 101.412,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมโหสถ",
    subdistrict: "โคกไทย",
    zipcode: 25190,
    latitude: 13.845,
    longitude: 101.448,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมโหสถ",
    subdistrict: "คู้ลำพัน",
    zipcode: 25190,
    latitude: 13.926,
    longitude: 101.41,
  },
  {
    province: "ปราจีนบุรี",
    district: "ศรีมโหสถ",
    subdistrict: "ไผ่ชะเลือด",
    zipcode: 25190,
    latitude: 13.919,
    longitude: 101.372,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "นครนายก",
    zipcode: 26000,
    latitude: 14.204,
    longitude: 101.219,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "ท่าช้าง",
    zipcode: 26000,
    latitude: 14.192,
    longitude: 101.176,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "บ้านใหญ่",
    zipcode: 26000,
    latitude: 14.221,
    longitude: 101.225,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "วังกระโจม",
    zipcode: 26000,
    latitude: 14.176,
    longitude: 101.212,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "ท่าทราย",
    zipcode: 26000,
    latitude: 14.174,
    longitude: 101.111,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "ดอนยอ",
    zipcode: 26000,
    latitude: 14.128,
    longitude: 101.109,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "ศรีจุฬา",
    zipcode: 26000,
    latitude: 14.072,
    longitude: 101.132,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "ดงละคร",
    zipcode: 26000,
    latitude: 14.136,
    longitude: 101.167,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "ศรีนาวา",
    zipcode: 26000,
    latitude: 14.209,
    longitude: 101.267,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "สาริกา",
    zipcode: 26000,
    latitude: 14.359,
    longitude: 101.261,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "หินตั้ง",
    zipcode: 26000,
    latitude: 14.408,
    longitude: 101.352,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "เขาพระ",
    zipcode: 26000,
    latitude: 14.301,
    longitude: 101.212,
  },
  {
    province: "นครนายก",
    district: "เมืองนครนายก",
    subdistrict: "พรหมณี",
    zipcode: 26000,
    latitude: 14.264,
    longitude: 101.164,
  },
  {
    province: "นครนายก",
    district: "ปากพลี",
    subdistrict: "เกาะหวาย",
    zipcode: 26130,
    latitude: 14.175,
    longitude: 101.271,
  },
  {
    province: "นครนายก",
    district: "ปากพลี",
    subdistrict: "เกาะโพธิ์",
    zipcode: 26130,
    latitude: 14.156,
    longitude: 101.231,
  },
  {
    province: "นครนายก",
    district: "ปากพลี",
    subdistrict: "ปากพลี",
    zipcode: 26130,
    latitude: 14.132,
    longitude: 101.263,
  },
  {
    province: "นครนายก",
    district: "ปากพลี",
    subdistrict: "โคกกรวด",
    zipcode: 26130,
    latitude: 14.18,
    longitude: 101.306,
  },
  {
    province: "นครนายก",
    district: "ปากพลี",
    subdistrict: "ท่าเรือ",
    zipcode: 26130,
    latitude: 14.085,
    longitude: 101.235,
  },
  {
    province: "นครนายก",
    district: "ปากพลี",
    subdistrict: "หนองแสง",
    zipcode: 26130,
    latitude: 14.211,
    longitude: 101.307,
  },
  {
    province: "นครนายก",
    district: "ปากพลี",
    subdistrict: "นาหินลาด",
    zipcode: 26130,
    latitude: 14.317,
    longitude: 101.457,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "บ้านนา",
    zipcode: 26110,
    latitude: 14.262,
    longitude: 101.059,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "บ้านพร้าว",
    zipcode: 26110,
    latitude: 14.222,
    longitude: 101.101,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "บ้านพริก",
    zipcode: 26110,
    latitude: 14.252,
    longitude: 100.976,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "อาษา",
    zipcode: 26110,
    latitude: 14.226,
    longitude: 101.009,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "ทองหลาง",
    zipcode: 26110,
    latitude: 14.207,
    longitude: 101.037,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "บางอ้อ",
    zipcode: 26110,
    latitude: 14.176,
    longitude: 101.069,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "พิกุลออก",
    zipcode: 26110,
    latitude: 14.244,
    longitude: 101.027,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "ป่าขะ",
    zipcode: 26110,
    latitude: 14.297,
    longitude: 101.065,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "เขาเพิ่ม",
    zipcode: 26110,
    latitude: 14.362,
    longitude: 101.096,
  },
  {
    province: "นครนายก",
    district: "บ้านนา",
    subdistrict: "ศรีกะอาง",
    zipcode: 26110,
    latitude: 14.308,
    longitude: 101.117,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "พระอาจารย์",
    zipcode: 26120,
    latitude: 13.986,
    longitude: 101.02,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "บึงศาล",
    zipcode: 26120,
    latitude: 14.052,
    longitude: 100.938,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "ศีรษะกระบือ",
    zipcode: 26120,
    latitude: 14.043,
    longitude: 100.986,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "โพธิ์แทน",
    zipcode: 26120,
    latitude: 14.208,
    longitude: 100.949,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "บางสมบูรณ์",
    zipcode: 26120,
    latitude: 14.021,
    longitude: 101.112,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "ทรายมูล",
    zipcode: 26120,
    latitude: 14.123,
    longitude: 101.069,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "บางปลากด",
    zipcode: 26120,
    latitude: 14.166,
    longitude: 100.971,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "บางลูกเสือ",
    zipcode: 26120,
    latitude: 14.054,
    longitude: 101.066,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "องครักษ์",
    zipcode: 26120,
    latitude: 14.107,
    longitude: 101.005,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "ชุมพล",
    zipcode: 26120,
    latitude: 13.99,
    longitude: 100.937,
  },
  {
    province: "นครนายก",
    district: "องครักษ์",
    subdistrict: "คลองใหญ่",
    zipcode: 26120,
    latitude: 14.127,
    longitude: 100.98,
  },
  {
    province: "สระแก้ว",
    district: "เมืองสระแก้ว",
    subdistrict: "สระแก้ว",
    zipcode: 27000,
    latitude: 13.803,
    longitude: 102.077,
  },
  {
    province: "สระแก้ว",
    district: "เมืองสระแก้ว",
    subdistrict: "บ้านแก้ง",
    zipcode: 27000,
    latitude: 13.984,
    longitude: 101.99,
  },
  {
    province: "สระแก้ว",
    district: "เมืองสระแก้ว",
    subdistrict: "ศาลาลำดวน",
    zipcode: 27000,
    latitude: 13.838,
    longitude: 101.964,
  },
  {
    province: "สระแก้ว",
    district: "เมืองสระแก้ว",
    subdistrict: "โคกปี่ฆ้อง",
    zipcode: 27000,
    latitude: 13.925,
    longitude: 102.028,
  },
  {
    province: "สระแก้ว",
    district: "เมืองสระแก้ว",
    subdistrict: "ท่าแยก",
    zipcode: 27000,
    latitude: 14.064,
    longitude: 102.266,
  },
  {
    province: "สระแก้ว",
    district: "เมืองสระแก้ว",
    subdistrict: "ท่าเกษม",
    zipcode: 27000,
    latitude: 13.766,
    longitude: 102.164,
  },
  {
    province: "สระแก้ว",
    district: "เมืองสระแก้ว",
    subdistrict: "สระขวัญ",
    zipcode: 27000,
    latitude: 13.763,
    longitude: 102.008,
  },
  {
    province: "สระแก้ว",
    district: "เมืองสระแก้ว",
    subdistrict: "หนองบอน",
    zipcode: 27000,
    latitude: 13.85,
    longitude: 102.087,
  },
  {
    province: "สระแก้ว",
    district: "คลองหาด",
    subdistrict: "คลองหาด",
    zipcode: 27260,
    latitude: 13.429,
    longitude: 102.329,
  },
  {
    province: "สระแก้ว",
    district: "คลองหาด",
    subdistrict: "ไทยอุดม",
    zipcode: 27260,
    latitude: 13.407,
    longitude: 102.26,
  },
  {
    province: "สระแก้ว",
    district: "คลองหาด",
    subdistrict: "ซับมะกรูด",
    zipcode: 27260,
    latitude: 13.519,
    longitude: 102.228,
  },
  {
    province: "สระแก้ว",
    district: "คลองหาด",
    subdistrict: "ไทรเดี่ยว",
    zipcode: 27260,
    latitude: 13.593,
    longitude: 102.212,
  },
  {
    province: "สระแก้ว",
    district: "คลองหาด",
    subdistrict: "คลองไก่เถื่อน",
    zipcode: 27260,
    latitude: 13.346,
    longitude: 102.301,
  },
  {
    province: "สระแก้ว",
    district: "คลองหาด",
    subdistrict: "เบญจขร",
    zipcode: 27260,
    latitude: 13.542,
    longitude: 102.307,
  },
  {
    province: "สระแก้ว",
    district: "คลองหาด",
    subdistrict: "ไทรทอง",
    zipcode: 27260,
    latitude: 13.614,
    longitude: 102.309,
  },
  {
    province: "สระแก้ว",
    district: "ตาพระยา",
    subdistrict: "ตาพระยา",
    zipcode: 27180,
    latitude: 13.956,
    longitude: 102.764,
  },
  {
    province: "สระแก้ว",
    district: "ตาพระยา",
    subdistrict: "ทัพเสด็จ",
    zipcode: 27180,
    latitude: 14.047,
    longitude: 102.813,
  },
  {
    province: "สระแก้ว",
    district: "ตาพระยา",
    subdistrict: "ทัพราช",
    zipcode: 27180,
    latitude: 14.079,
    longitude: 102.639,
  },
  {
    province: "สระแก้ว",
    district: "ตาพระยา",
    subdistrict: "ทัพไทย",
    zipcode: 27180,
    latitude: 14.123,
    longitude: 102.845,
  },
  {
    province: "สระแก้ว",
    district: "ตาพระยา",
    subdistrict: "โคคลาน",
    zipcode: 27180,
    latitude: 13.968,
    longitude: 102.65,
  },
  {
    province: "สระแก้ว",
    district: "วังน้ำเย็น",
    subdistrict: "วังน้ำเย็น",
    zipcode: 27210,
    latitude: 13.517,
    longitude: 102.166,
  },
  {
    province: "สระแก้ว",
    district: "วังน้ำเย็น",
    subdistrict: "ตาหลังใน",
    zipcode: 27210,
    latitude: 13.471,
    longitude: 102.13,
  },
  {
    province: "สระแก้ว",
    district: "วังน้ำเย็น",
    subdistrict: "คลองหินปูน",
    zipcode: 27210,
    latitude: 13.606,
    longitude: 102.104,
  },
  {
    province: "สระแก้ว",
    district: "วังน้ำเย็น",
    subdistrict: "ทุ่งมหาเจริญ",
    zipcode: 27210,
    latitude: 13.482,
    longitude: 102.009,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "วัฒนานคร",
    zipcode: 27160,
    latitude: 13.741,
    longitude: 102.325,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "ท่าเกวียน",
    zipcode: 27160,
    latitude: 13.666,
    longitude: 102.251,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "ผักขะ",
    zipcode: 27160,
    latitude: 13.71,
    longitude: 102.382,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "โนนหมากเค็ง",
    zipcode: 27160,
    latitude: 13.824,
    longitude: 102.315,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "หนองน้ำใส",
    zipcode: 27160,
    latitude: 13.871,
    longitude: 102.341,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "ช่องกุ่ม",
    zipcode: 27160,
    latitude: 14.001,
    longitude: 102.449,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "หนองแวง",
    zipcode: 27160,
    latitude: 13.799,
    longitude: 102.372,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "แซร์ออ",
    zipcode: 27160,
    latitude: 13.974,
    longitude: 102.518,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "หนองหมากฝ้าย",
    zipcode: 27160,
    latitude: 14.024,
    longitude: 102.358,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "หนองตะเคียนบอน",
    zipcode: 27160,
    latitude: 13.916,
    longitude: 102.265,
  },
  {
    province: "สระแก้ว",
    district: "วัฒนานคร",
    subdistrict: "ห้วยโจด",
    zipcode: 27160,
    latitude: 13.771,
    longitude: 102.281,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "อรัญประเทศ",
    zipcode: 27120,
    latitude: 13.68,
    longitude: 102.517,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "เมืองไผ่",
    zipcode: 27120,
    latitude: 13.651,
    longitude: 102.427,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "หันทราย",
    zipcode: 27120,
    latitude: 13.806,
    longitude: 102.452,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "คลองน้ำใส",
    zipcode: 27120,
    latitude: 13.59,
    longitude: 102.514,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "ท่าข้าม",
    zipcode: 27120,
    latitude: 13.639,
    longitude: 102.531,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "ป่าไร่",
    zipcode: 27120,
    latitude: 13.745,
    longitude: 102.585,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "ทับพริก",
    zipcode: 27120,
    latitude: 13.506,
    longitude: 102.336,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "บ้านใหม่หนองไทร",
    zipcode: 27120,
    latitude: 13.715,
    longitude: 102.51,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "ผ่านศึก",
    zipcode: 27120,
    latitude: 13.592,
    longitude: 102.432,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "หนองสังข์",
    zipcode: 27120,
    latitude: 13.804,
    longitude: 102.539,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "คลองทับจันทร์",
    zipcode: 27120,
    latitude: 13.643,
    longitude: 102.387,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "ฟากห้วย",
    zipcode: 27120,
    latitude: 13.646,
    longitude: 102.48,
  },
  {
    province: "สระแก้ว",
    district: "อรัญประเทศ",
    subdistrict: "บ้านด่าน",
    zipcode: 27120,
    latitude: 13.746,
    longitude: 102.526,
  },
  {
    province: "สระแก้ว",
    district: "เขาฉกรรจ์",
    subdistrict: "เขาฉกรรจ์",
    zipcode: 27000,
    latitude: 13.663,
    longitude: 102.098,
  },
  {
    province: "สระแก้ว",
    district: "เขาฉกรรจ์",
    subdistrict: "หนองหว้า",
    zipcode: 27000,
    latitude: 13.672,
    longitude: 102.037,
  },
  {
    province: "สระแก้ว",
    district: "เขาฉกรรจ์",
    subdistrict: "พระเพลิง",
    zipcode: 27000,
    latitude: 13.596,
    longitude: 102.042,
  },
  {
    province: "สระแก้ว",
    district: "เขาฉกรรจ์",
    subdistrict: "เขาสามสิบ",
    zipcode: 27000,
    latitude: 13.708,
    longitude: 102.118,
  },
  {
    province: "สระแก้ว",
    district: "โคกสูง",
    subdistrict: "โคกสูง",
    zipcode: 27120,
    latitude: 13.831,
    longitude: 102.719,
  },
  {
    province: "สระแก้ว",
    district: "โคกสูง",
    subdistrict: "หนองม่วง",
    zipcode: 27180,
    latitude: 13.874,
    longitude: 102.582,
  },
  {
    province: "สระแก้ว",
    district: "โคกสูง",
    subdistrict: "หนองแวง",
    zipcode: 27180,
    latitude: 13.866,
    longitude: 102.674,
  },
  {
    province: "สระแก้ว",
    district: "โคกสูง",
    subdistrict: "โนนหมากมุ่น",
    zipcode: 27120,
    latitude: 13.774,
    longitude: 102.664,
  },
  {
    province: "สระแก้ว",
    district: "วังสมบูรณ์",
    subdistrict: "วังสมบูรณ์",
    zipcode: 27250,
    latitude: 13.344,
    longitude: 102.207,
  },
  {
    province: "สระแก้ว",
    district: "วังสมบูรณ์",
    subdistrict: "วังใหม่",
    zipcode: 27250,
    latitude: 13.413,
    longitude: 102.039,
  },
  {
    province: "สระแก้ว",
    district: "วังสมบูรณ์",
    subdistrict: "วังทอง",
    zipcode: 27250,
    latitude: 13.338,
    longitude: 102.081,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "ในเมือง",
    zipcode: 30000,
    latitude: 14.9653664,
    longitude: 101.9166858,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "โพธิ์กลาง",
    zipcode: 30000,
    latitude: 14.906,
    longitude: 102.102,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "หนองจะบก",
    zipcode: 30000,
    latitude: 14.916,
    longitude: 102.064,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "โคกสูง",
    zipcode: 30310,
    latitude: 15.095,
    longitude: 102.116,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "มะเริง",
    zipcode: 30000,
    latitude: 14.98,
    longitude: 102.164,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "หนองระเวียง",
    zipcode: 30000,
    latitude: 14.946,
    longitude: 102.199,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "ปรุใหญ่",
    zipcode: 30000,
    latitude: 14.994,
    longitude: 102.052,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "หมื่นไวย",
    zipcode: 30000,
    latitude: 15.011,
    longitude: 102.108,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "พลกรัง",
    zipcode: 30000,
    latitude: 15.026,
    longitude: 102.011,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "หนองไผ่ล้อม",
    zipcode: 30000,
    latitude: 14.947,
    longitude: 102.085,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "หัวทะเล",
    zipcode: 30000,
    latitude: 14.965,
    longitude: 102.14,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "บ้านเกาะ",
    zipcode: 30000,
    latitude: 14.981,
    longitude: 102.091,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "บ้านใหม่",
    zipcode: 30000,
    latitude: 14.960596,
    longitude: 102.0017964,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "พุดซา",
    zipcode: 30000,
    latitude: 15.049,
    longitude: 102.043,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "บ้านโพธิ์",
    zipcode: 30310,
    latitude: 15.038,
    longitude: 102.191,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "จอหอ",
    zipcode: 30310,
    latitude: 15.055,
    longitude: 102.148,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "โคกกรวด",
    zipcode: 30280,
    latitude: 14.897,
    longitude: 101.974,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "ไชยมงคล",
    zipcode: 30000,
    latitude: 14.849,
    longitude: 102.08,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "หนองบัวศาลา",
    zipcode: 30000,
    latitude: 14.899,
    longitude: 102.152,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "สุรนารี",
    zipcode: 30000,
    latitude: 14.915,
    longitude: 102.037,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "สีมุม",
    zipcode: 30000,
    latitude: 14.986,
    longitude: 102.007,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "ตลาด",
    zipcode: 30310,
    latitude: 15.018,
    longitude: 102.154,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "พะเนา",
    zipcode: 30000,
    latitude: 14.986,
    longitude: 102.189,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "หนองกระทุ่ม",
    zipcode: 30000,
    latitude: 15.009,
    longitude: 102.086,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองนครราชสีมา",
    subdistrict: "หนองไข่น้ำ",
    zipcode: 30310,
    latitude: 15.105,
    longitude: 102.174,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "แชะ",
    zipcode: 30250,
    latitude: 14.569,
    longitude: 102.278,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "เฉลียง",
    zipcode: 30250,
    latitude: 14.489,
    longitude: 102.279,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "ครบุรี",
    zipcode: 30250,
    latitude: 14.599,
    longitude: 102.13,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "โคกกระชาย",
    zipcode: 30250,
    latitude: 14.301,
    longitude: 102.196,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "จระเข้หิน",
    zipcode: 30250,
    latitude: 14.367,
    longitude: 102.1,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "มาบตะโกเอน",
    zipcode: 30250,
    latitude: 14.57,
    longitude: 102.385,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "อรพิมพ์",
    zipcode: 30250,
    latitude: 14.569,
    longitude: 102.207,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "บ้านใหม่",
    zipcode: 30250,
    latitude: 14.449,
    longitude: 102.207,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "ลำเพียก",
    zipcode: 30250,
    latitude: 14.255,
    longitude: 102.302,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "ครบุรีใต้",
    zipcode: 30250,
    latitude: 14.529,
    longitude: 102.103,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "ตะแบกบาน",
    zipcode: 30250,
    latitude: 14.462,
    longitude: 102.343,
  },
  {
    province: "นครราชสีมา",
    district: "ครบุรี",
    subdistrict: "สระว่านพระยา",
    zipcode: 30250,
    latitude: 14.528,
    longitude: 102.384,
  },
  {
    province: "นครราชสีมา",
    district: "เสิงสาง",
    subdistrict: "เสิงสาง",
    zipcode: 30330,
    latitude: 14.426,
    longitude: 102.429,
  },
  {
    province: "นครราชสีมา",
    district: "เสิงสาง",
    subdistrict: "สระตะเคียน",
    zipcode: 30330,
    latitude: 14.325,
    longitude: 102.552,
  },
  {
    province: "นครราชสีมา",
    district: "เสิงสาง",
    subdistrict: "โนนสมบูรณ์",
    zipcode: 30330,
    latitude: 14.278,
    longitude: 102.398,
  },
  {
    province: "นครราชสีมา",
    district: "เสิงสาง",
    subdistrict: "กุดโบสถ์",
    zipcode: 30330,
    latitude: 14.457,
    longitude: 102.54,
  },
  {
    province: "นครราชสีมา",
    district: "เสิงสาง",
    subdistrict: "สุขไพบูลย์",
    zipcode: 30330,
    latitude: 14.526,
    longitude: 102.448,
  },
  {
    province: "นครราชสีมา",
    district: "เสิงสาง",
    subdistrict: "บ้านราษฎร์",
    zipcode: 30330,
    latitude: 14.247,
    longitude: 102.474,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "เมืองคง",
    zipcode: 30260,
    latitude: 15.451,
    longitude: 102.334,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "คูขาด",
    zipcode: 30260,
    latitude: 15.445,
    longitude: 102.407,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "เทพาลัย",
    zipcode: 30260,
    latitude: 15.368,
    longitude: 102.466,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "ตาจั่น",
    zipcode: 30260,
    latitude: 15.314,
    longitude: 102.368,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "บ้านปรางค์",
    zipcode: 30260,
    latitude: 15.474,
    longitude: 102.08,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "หนองมะนาว",
    zipcode: 30260,
    latitude: 15.5,
    longitude: 102.28,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "หนองบัว",
    zipcode: 30260,
    latitude: 15.496,
    longitude: 102.167,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "โนนเต็ง",
    zipcode: 30260,
    latitude: 15.449,
    longitude: 102.266,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "ดอนใหญ่",
    zipcode: 30260,
    latitude: 15.397,
    longitude: 102.357,
  },
  {
    province: "นครราชสีมา",
    district: "คง",
    subdistrict: "ขามสมบูรณ์",
    zipcode: 30260,
    latitude: 15.371,
    longitude: 102.403,
  },
  {
    province: "นครราชสีมา",
    district: "บ้านเหลื่อม",
    subdistrict: "บ้านเหลื่อม",
    zipcode: 30350,
    latitude: 15.625,
    longitude: 102.136,
  },
  {
    province: "นครราชสีมา",
    district: "บ้านเหลื่อม",
    subdistrict: "วังโพธิ์",
    zipcode: 30350,
    latitude: 15.571,
    longitude: 102.103,
  },
  {
    province: "นครราชสีมา",
    district: "บ้านเหลื่อม",
    subdistrict: "โคกกระเบื้อง",
    zipcode: 30350,
    latitude: 15.571,
    longitude: 102.187,
  },
  {
    province: "นครราชสีมา",
    district: "บ้านเหลื่อม",
    subdistrict: "ช่อระกา",
    zipcode: 30350,
    latitude: 15.54,
    longitude: 102.057,
  },
  {
    province: "นครราชสีมา",
    district: "จักราช",
    subdistrict: "จักราช",
    zipcode: 30230,
    latitude: 15.027,
    longitude: 102.432,
  },
  {
    province: "นครราชสีมา",
    district: "จักราช",
    subdistrict: "ทองหลาง",
    zipcode: 30230,
    latitude: 15.052,
    longitude: 102.353,
  },
  {
    province: "นครราชสีมา",
    district: "จักราช",
    subdistrict: "สีสุก",
    zipcode: 30230,
    latitude: 14.874,
    longitude: 102.385,
  },
  {
    province: "นครราชสีมา",
    district: "จักราช",
    subdistrict: "หนองขาม",
    zipcode: 30230,
    latitude: 14.931,
    longitude: 102.506,
  },
  {
    province: "นครราชสีมา",
    district: "จักราช",
    subdistrict: "หนองพลวง",
    zipcode: 30230,
    latitude: 15.07,
    longitude: 102.424,
  },
  {
    province: "นครราชสีมา",
    district: "จักราช",
    subdistrict: "ศรีละกอ",
    zipcode: 30230,
    latitude: 14.938,
    longitude: 102.427,
  },
  {
    province: "นครราชสีมา",
    district: "จักราช",
    subdistrict: "คลองเมือง",
    zipcode: 30230,
    latitude: 14.852,
    longitude: 102.502,
  },
  {
    province: "นครราชสีมา",
    district: "จักราช",
    subdistrict: "หินโคน",
    zipcode: 30230,
    latitude: 15.013,
    longitude: 102.498,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "กระโทก",
    zipcode: 30190,
    latitude: 14.714,
    longitude: 102.189,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "พลับพลา",
    zipcode: 30190,
    latitude: 14.755,
    longitude: 102.129,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "ท่าอ่าง",
    zipcode: 30190,
    latitude: 14.821,
    longitude: 102.16,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "ทุ่งอรุณ",
    zipcode: 30190,
    latitude: 14.631,
    longitude: 102.184,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "ท่าลาดขาว",
    zipcode: 30190,
    latitude: 14.672,
    longitude: 102.154,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "ท่าจะหลุง",
    zipcode: 30190,
    latitude: 14.87,
    longitude: 102.264,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "ท่าเยี่ยม",
    zipcode: 30190,
    latitude: 14.716,
    longitude: 102.237,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "โชคชัย",
    zipcode: 30190,
    latitude: 14.763,
    longitude: 102.172,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "ละลมใหม่พัฒนา",
    zipcode: 30190,
    latitude: 14.811,
    longitude: 102.243,
  },
  {
    province: "นครราชสีมา",
    district: "โชคชัย",
    subdistrict: "ด่านเกวียน",
    zipcode: 30190,
    latitude: 14.864,
    longitude: 102.189,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "กุดพิมาน",
    zipcode: 30210,
    latitude: 15.303,
    longitude: 101.759,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "ด่านขุนทด",
    zipcode: 30210,
    latitude: 15.214,
    longitude: 101.788,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "ด่านนอก",
    zipcode: 30210,
    latitude: 15.157,
    longitude: 101.87,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "ด่านใน",
    zipcode: 30210,
    latitude: 15.119,
    longitude: 101.855,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "ตะเคียน",
    zipcode: 30210,
    latitude: 15.124,
    longitude: 101.724,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "บ้านเก่า",
    zipcode: 30210,
    latitude: 15.211,
    longitude: 101.673,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "บ้านแปรง",
    zipcode: 36220,
    latitude: 15.372,
    longitude: 101.809,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "พันชนะ",
    zipcode: 30210,
    latitude: 15.27,
    longitude: 101.689,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "สระจรเข้",
    zipcode: 30210,
    latitude: 15.147,
    longitude: 101.808,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "หนองกราด",
    zipcode: 30210,
    latitude: 15.348,
    longitude: 101.667,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "หนองบัวตะเกียด",
    zipcode: 30210,
    latitude: 15.281,
    longitude: 101.821,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "หนองบัวละคร",
    zipcode: 30210,
    latitude: 15.243,
    longitude: 101.863,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "หินดาด",
    zipcode: 30210,
    latitude: 15.149,
    longitude: 101.618,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "ห้วยบง",
    zipcode: 30210,
    latitude: 15.161,
    longitude: 101.486,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "โนนเมืองพัฒนา",
    zipcode: 30210,
    latitude: 15.304,
    longitude: 101.897,
  },
  {
    province: "นครราชสีมา",
    district: "ด่านขุนทด",
    subdistrict: "หนองไทร",
    zipcode: 36220,
    latitude: 15.36,
    longitude: 101.855,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "โนนไทย",
    zipcode: 30220,
    latitude: 15.213,
    longitude: 102.061,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "ด่านจาก",
    zipcode: 30220,
    latitude: 15.162,
    longitude: 102.095,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "กำปัง",
    zipcode: 30220,
    latitude: 15.118,
    longitude: 102.06,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "สำโรง",
    zipcode: 30220,
    latitude: 15.128,
    longitude: 102.018,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "ค้างพลู",
    zipcode: 30220,
    latitude: 15.149,
    longitude: 101.973,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "บ้านวัง",
    zipcode: 30220,
    latitude: 15.152,
    longitude: 101.914,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "บัลลังก์",
    zipcode: 30220,
    latitude: 15.223,
    longitude: 101.928,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "สายออ",
    zipcode: 30220,
    latitude: 15.226,
    longitude: 102.002,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "ถนนโพธิ์",
    zipcode: 30220,
    latitude: 15.274,
    longitude: 102.138,
  },
  {
    province: "นครราชสีมา",
    district: "โนนไทย",
    subdistrict: "มะค่า",
    zipcode: 30220,
    latitude: 15.265,
    longitude: 102.098,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "โนนสูง",
    zipcode: 30160,
    latitude: 15.179,
    longitude: 102.256,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "ใหม่",
    zipcode: 30160,
    latitude: 15.152,
    longitude: 102.287,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "โตนด",
    zipcode: 30160,
    latitude: 15.097,
    longitude: 102.293,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "บิง",
    zipcode: 30160,
    latitude: 15.118,
    longitude: 102.333,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "ดอนชมพู",
    zipcode: 30160,
    latitude: 15.171,
    longitude: 102.339,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "ธารปราสาท",
    zipcode: 30420,
    latitude: 15.263,
    longitude: 102.392,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "หลุมข้าว",
    zipcode: 30160,
    latitude: 15.224,
    longitude: 102.313,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "มะค่า",
    zipcode: 30160,
    latitude: 15.315,
    longitude: 102.321,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "พลสงคราม",
    zipcode: 30160,
    latitude: 15.299,
    longitude: 102.273,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "จันอัด",
    zipcode: 30160,
    latitude: 15.161,
    longitude: 102.158,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "ขามเฒ่า",
    zipcode: 30160,
    latitude: 15.305,
    longitude: 102.219,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "ด่านคล้า",
    zipcode: 30160,
    latitude: 15.152,
    longitude: 102.212,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "ลำคอหงษ์",
    zipcode: 30160,
    latitude: 15.252,
    longitude: 102.21,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "เมืองปราสาท",
    zipcode: 30160,
    latitude: 15.201,
    longitude: 102.194,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "ดอนหวาย",
    zipcode: 30160,
    latitude: 15.116,
    longitude: 102.287,
  },
  {
    province: "นครราชสีมา",
    district: "โนนสูง",
    subdistrict: "ลำมูล",
    zipcode: 30160,
    latitude: 15.072,
    longitude: 102.308,
  },
  {
    province: "นครราชสีมา",
    district: "ขามสะแกแสง",
    subdistrict: "ขามสะแกแสง",
    zipcode: 30290,
    latitude: 15.36,
    longitude: 102.195,
  },
  {
    province: "นครราชสีมา",
    district: "ขามสะแกแสง",
    subdistrict: "โนนเมือง",
    zipcode: 30290,
    latitude: 15.416,
    longitude: 102.116,
  },
  {
    province: "นครราชสีมา",
    district: "ขามสะแกแสง",
    subdistrict: "เมืองนาท",
    zipcode: 30290,
    latitude: 15.386,
    longitude: 102.248,
  },
  {
    province: "นครราชสีมา",
    district: "ขามสะแกแสง",
    subdistrict: "ชีวึก",
    zipcode: 30290,
    latitude: 15.351,
    longitude: 102.099,
  },
  {
    province: "นครราชสีมา",
    district: "ขามสะแกแสง",
    subdistrict: "พะงาด",
    zipcode: 30290,
    latitude: 15.334,
    longitude: 102.149,
  },
  {
    province: "นครราชสีมา",
    district: "ขามสะแกแสง",
    subdistrict: "หนองหัวฟาน",
    zipcode: 30290,
    latitude: 15.433,
    longitude: 102.237,
  },
  {
    province: "นครราชสีมา",
    district: "ขามสะแกแสง",
    subdistrict: "เมืองเกษตร",
    zipcode: 30290,
    latitude: 15.424,
    longitude: 102.159,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "บัวใหญ่",
    zipcode: 30120,
    latitude: 15.602,
    longitude: 102.438,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "ห้วยยาง",
    zipcode: 30120,
    latitude: 15.626,
    longitude: 102.363,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "เสมาใหญ่",
    zipcode: 30120,
    latitude: 15.516,
    longitude: 102.429,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "ดอนตะหนิน",
    zipcode: 30120,
    latitude: 15.486,
    longitude: 102.466,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "หนองบัวสะอาด",
    zipcode: 30120,
    latitude: 15.593,
    longitude: 102.3,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "โนนทองหลาง",
    zipcode: 30120,
    latitude: 15.522,
    longitude: 102.374,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "กุดจอก",
    zipcode: 30120,
    latitude: 15.571,
    longitude: 102.502,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "ด่านช้าง",
    zipcode: 30120,
    latitude: 15.623,
    longitude: 102.407,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "ขุนทอง",
    zipcode: 30120,
    latitude: 15.683,
    longitude: 102.364,
  },
  {
    province: "นครราชสีมา",
    district: "บัวใหญ่",
    subdistrict: "หนองแจ้งใหญ่",
    zipcode: 30120,
    latitude: 15.567,
    longitude: 102.372,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "ประทาย",
    zipcode: 30180,
    latitude: 15.536,
    longitude: 102.724,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "กระทุ่มราย",
    zipcode: 30180,
    latitude: 15.53,
    longitude: 102.659,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "วังไม้แดง",
    zipcode: 30180,
    latitude: 15.684,
    longitude: 102.632,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "ตลาดไทร",
    zipcode: 30180,
    latitude: 15.528,
    longitude: 102.78,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "หนองพลวง",
    zipcode: 30180,
    latitude: 15.583,
    longitude: 102.707,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "หนองค่าย",
    zipcode: 30180,
    latitude: 15.596,
    longitude: 102.759,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "หันห้วยทราย",
    zipcode: 30180,
    latitude: 15.611,
    longitude: 102.656,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "ดอนมัน",
    zipcode: 30180,
    latitude: 15.491,
    longitude: 102.822,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "นางรำ",
    zipcode: 30180,
    latitude: 15.484,
    longitude: 102.623,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "โนนเพ็ด",
    zipcode: 30180,
    latitude: 15.677,
    longitude: 102.684,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "ทุ่งสว่าง",
    zipcode: 30180,
    latitude: 15.493,
    longitude: 102.686,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "โคกกลาง",
    zipcode: 30180,
    latitude: 15.463,
    longitude: 102.737,
  },
  {
    province: "นครราชสีมา",
    district: "ประทาย",
    subdistrict: "เมืองโดน",
    zipcode: 30180,
    latitude: 15.635,
    longitude: 102.712,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "เมืองปัก",
    zipcode: 30150,
    latitude: 14.719,
    longitude: 102.055,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "ตะคุ",
    zipcode: 30150,
    latitude: 14.754,
    longitude: 101.953,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "โคกไทย",
    zipcode: 30150,
    latitude: 14.741,
    longitude: 102.09,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "สำโรง",
    zipcode: 30150,
    latitude: 14.65,
    longitude: 102.063,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "ตะขบ",
    zipcode: 30150,
    latitude: 14.686,
    longitude: 101.745,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "นกออก",
    zipcode: 30150,
    latitude: 14.686,
    longitude: 102.036,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "ดอน",
    zipcode: 30150,
    latitude: 14.679,
    longitude: 102.108,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "ตูม",
    zipcode: 30150,
    latitude: 14.701,
    longitude: 101.986,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "งิ้ว",
    zipcode: 30150,
    latitude: 14.666,
    longitude: 101.997,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "สะแกราช",
    zipcode: 30150,
    latitude: 14.604,
    longitude: 102.048,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "ลำนางแก้ว",
    zipcode: 30150,
    latitude: 14.563,
    longitude: 101.87,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "ภูหลวง",
    zipcode: 30150,
    latitude: 14.602,
    longitude: 101.984,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "ธงชัยเหนือ",
    zipcode: 30150,
    latitude: 14.793,
    longitude: 102.034,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "สุขเกษม",
    zipcode: 30150,
    latitude: 14.667,
    longitude: 101.939,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "เกษมทรัพย์",
    zipcode: 30150,
    latitude: 14.663,
    longitude: 102.046,
  },
  {
    province: "นครราชสีมา",
    district: "ปักธงชัย",
    subdistrict: "บ่อปลาทอง",
    zipcode: 30150,
    latitude: 14.637,
    longitude: 101.902,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "ในเมือง",
    zipcode: 30110,
    latitude: 15.206,
    longitude: 102.489,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "สัมฤทธิ์",
    zipcode: 30110,
    latitude: 15.216,
    longitude: 102.424,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "โบสถ์",
    zipcode: 30110,
    latitude: 15.215,
    longitude: 102.628,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "กระเบื้องใหญ่",
    zipcode: 30110,
    latitude: 15.271,
    longitude: 102.458,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "ท่าหลวง",
    zipcode: 30110,
    latitude: 15.282,
    longitude: 102.547,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "รังกาใหญ่",
    zipcode: 30110,
    latitude: 15.19,
    longitude: 102.555,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "ชีวาน",
    zipcode: 30110,
    latitude: 15.344,
    longitude: 102.54,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "นิคมสร้างตนเอง",
    zipcode: 30110,
    latitude: 15.128,
    longitude: 102.49,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "กระชอน",
    zipcode: 30110,
    latitude: 15.443,
    longitude: 102.67,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "ดงใหญ่",
    zipcode: 30110,
    latitude: 15.338,
    longitude: 102.591,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "ธารละหลอด",
    zipcode: 30110,
    latitude: 15.162,
    longitude: 102.403,
  },
  {
    province: "นครราชสีมา",
    district: "พิมาย",
    subdistrict: "หนองระเวียง",
    zipcode: 30110,
    latitude: 15.113,
    longitude: 102.44,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "ห้วยแถลง",
    zipcode: 30240,
    latitude: 14.967,
    longitude: 102.652,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "ทับสวาย",
    zipcode: 30240,
    latitude: 15.021,
    longitude: 102.634,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "เมืองพลับพลา",
    zipcode: 30240,
    latitude: 15.063,
    longitude: 102.634,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "หลุ่งตะเคียน",
    zipcode: 30240,
    latitude: 15.12,
    longitude: 102.638,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "หินดาด",
    zipcode: 30240,
    latitude: 14.995,
    longitude: 102.565,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "งิ้ว",
    zipcode: 30240,
    latitude: 15.077,
    longitude: 102.689,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "กงรถ",
    zipcode: 30240,
    latitude: 15.047,
    longitude: 102.752,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "หลุ่งประดู่",
    zipcode: 30240,
    latitude: 15.06,
    longitude: 102.582,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "ตะโก",
    zipcode: 30240,
    latitude: 15.019,
    longitude: 102.718,
  },
  {
    province: "นครราชสีมา",
    district: "ห้วยแถลง",
    subdistrict: "ห้วยแคน",
    zipcode: 30240,
    latitude: 14.985,
    longitude: 102.687,
  },
  {
    province: "นครราชสีมา",
    district: "ชุมพวง",
    subdistrict: "ชุมพวง",
    zipcode: 30270,
    latitude: 15.344,
    longitude: 102.75,
  },
  {
    province: "นครราชสีมา",
    district: "ชุมพวง",
    subdistrict: "ประสุข",
    zipcode: 30270,
    latitude: 15.342,
    longitude: 102.685,
  },
  {
    province: "นครราชสีมา",
    district: "ชุมพวง",
    subdistrict: "ท่าลาด",
    zipcode: 30270,
    latitude: 15.243,
    longitude: 102.72,
  },
  {
    province: "นครราชสีมา",
    district: "ชุมพวง",
    subdistrict: "สาหร่าย",
    zipcode: 30270,
    latitude: 15.184,
    longitude: 102.735,
  },
  {
    province: "นครราชสีมา",
    district: "ชุมพวง",
    subdistrict: "ตลาดไทร",
    zipcode: 30270,
    latitude: 15.109,
    longitude: 102.773,
  },
  {
    province: "นครราชสีมา",
    district: "ชุมพวง",
    subdistrict: "โนนรัง",
    zipcode: 30270,
    latitude: 15.258,
    longitude: 102.81,
  },
  {
    province: "นครราชสีมา",
    district: "ชุมพวง",
    subdistrict: "หนองหลัก",
    zipcode: 30270,
    latitude: 15.411,
    longitude: 102.732,
  },
  {
    province: "นครราชสีมา",
    district: "ชุมพวง",
    subdistrict: "โนนตูม",
    zipcode: 30270,
    latitude: 15.167,
    longitude: 102.769,
  },
  {
    province: "นครราชสีมา",
    district: "ชุมพวง",
    subdistrict: "โนนยอ",
    zipcode: 30270,
    latitude: 15.339,
    longitude: 102.828,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "สูงเนิน",
    zipcode: 30170,
    latitude: 14.861,
    longitude: 101.842,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "เสมา",
    zipcode: 30170,
    latitude: 14.982,
    longitude: 101.777,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "โคราช",
    zipcode: 30170,
    latitude: 14.909,
    longitude: 101.851,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "บุ่งขี้เหล็ก",
    zipcode: 30170,
    latitude: 14.952,
    longitude: 101.839,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "โนนค่า",
    zipcode: 30170,
    latitude: 15.034,
    longitude: 101.84,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "โค้งยาง",
    zipcode: 30170,
    latitude: 14.922,
    longitude: 101.903,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "มะเกลือเก่า",
    zipcode: 30170,
    latitude: 14.779,
    longitude: 101.805,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "มะเกลือใหม่",
    zipcode: 30170,
    latitude: 14.798,
    longitude: 101.728,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "นากลาง",
    zipcode: 30380,
    latitude: 14.855,
    longitude: 101.942,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "หนองตะไก้",
    zipcode: 30380,
    latitude: 14.79,
    longitude: 101.895,
  },
  {
    province: "นครราชสีมา",
    district: "สูงเนิน",
    subdistrict: "กุดจิก",
    zipcode: 30380,
    latitude: 14.9145085,
    longitude: 101.8606793,
  },
  {
    province: "นครราชสีมา",
    district: "ขามทะเลสอ",
    subdistrict: "ขามทะเลสอ",
    zipcode: 30280,
    latitude: 14.974,
    longitude: 101.959,
  },
  {
    province: "นครราชสีมา",
    district: "ขามทะเลสอ",
    subdistrict: "โป่งแดง",
    zipcode: 30280,
    latitude: 14.97,
    longitude: 101.916,
  },
  {
    province: "นครราชสีมา",
    district: "ขามทะเลสอ",
    subdistrict: "พันดุง",
    zipcode: 30280,
    latitude: 15.069,
    longitude: 101.952,
  },
  {
    province: "นครราชสีมา",
    district: "ขามทะเลสอ",
    subdistrict: "หนองสรวง",
    zipcode: 30280,
    latitude: 15.086,
    longitude: 101.894,
  },
  {
    province: "นครราชสีมา",
    district: "ขามทะเลสอ",
    subdistrict: "บึงอ้อ",
    zipcode: 30280,
    latitude: 15.028,
    longitude: 101.932,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "สีคิ้ว",
    zipcode: 30140,
    latitude: 14.905,
    longitude: 101.687,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "บ้านหัน",
    zipcode: 30140,
    latitude: 14.985,
    longitude: 101.75,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "กฤษณา",
    zipcode: 30140,
    latitude: 15.046,
    longitude: 101.556,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "ลาดบัวขาว",
    zipcode: 30340,
    latitude: 14.823,
    longitude: 101.629,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "หนองหญ้าขาว",
    zipcode: 30140,
    latitude: 14.959,
    longitude: 101.574,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "กุดน้อย",
    zipcode: 30140,
    latitude: 14.931,
    longitude: 101.753,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "หนองน้ำใส",
    zipcode: 30140,
    latitude: 14.932,
    longitude: 101.485,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "วังโรงใหญ่",
    zipcode: 30140,
    latitude: 15.045,
    longitude: 101.659,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "มิตรภาพ",
    zipcode: 30140,
    latitude: 14.797,
    longitude: 101.685,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "คลองไผ่",
    zipcode: 30340,
    latitude: 14.883,
    longitude: 101.515,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "ดอนเมือง",
    zipcode: 30140,
    latitude: 15.02,
    longitude: 101.456,
  },
  {
    province: "นครราชสีมา",
    district: "สีคิ้ว",
    subdistrict: "หนองบัวน้อย",
    zipcode: 30140,
    latitude: 15.047,
    longitude: 101.76,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "ปากช่อง",
    zipcode: 30130,
    latitude: 14.722,
    longitude: 101.363,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "กลางดง",
    zipcode: 30320,
    latitude: 14.65,
    longitude: 101.283,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "จันทึก",
    zipcode: 30130,
    latitude: 14.806,
    longitude: 101.456,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "วังกะทะ",
    zipcode: 30130,
    latitude: 14.536,
    longitude: 101.64,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "หมูสี",
    zipcode: 30130,
    latitude: 14.501,
    longitude: 101.402,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "หนองสาหร่าย",
    zipcode: 30130,
    latitude: 14.689,
    longitude: 101.488,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "ขนงพระ",
    zipcode: 30130,
    latitude: 14.594,
    longitude: 101.53,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "โป่งตาลอง",
    zipcode: 30130,
    latitude: 14.472,
    longitude: 101.605,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "คลองม่วง",
    zipcode: 30130,
    latitude: 14.647,
    longitude: 101.649,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "หนองน้ำแดง",
    zipcode: 30130,
    latitude: 14.613,
    longitude: 101.398,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "วังไทร",
    zipcode: 30130,
    latitude: 14.706,
    longitude: 101.573,
  },
  {
    province: "นครราชสีมา",
    district: "ปากช่อง",
    subdistrict: "พญาเย็น",
    zipcode: 30320,
    latitude: 14.548,
    longitude: 101.246,
  },
  {
    province: "นครราชสีมา",
    district: "หนองบุญมาก",
    subdistrict: "หนองบุนนาก",
    zipcode: 30410,
    latitude: 14.609,
    longitude: 102.4,
  },
  {
    province: "นครราชสีมา",
    district: "หนองบุญมาก",
    subdistrict: "สารภี",
    zipcode: 30410,
    latitude: 14.814,
    longitude: 102.417,
  },
  {
    province: "นครราชสีมา",
    district: "หนองบุญมาก",
    subdistrict: "ไทยเจริญ",
    zipcode: 30410,
    latitude: 14.743,
    longitude: 102.45,
  },
  {
    province: "นครราชสีมา",
    district: "หนองบุญมาก",
    subdistrict: "หนองหัวแรต",
    zipcode: 30410,
    latitude: 14.715,
    longitude: 102.332,
  },
  {
    province: "นครราชสีมา",
    district: "หนองบุญมาก",
    subdistrict: "แหลมทอง",
    zipcode: 30410,
    latitude: 14.78,
    longitude: 102.304,
  },
  {
    province: "นครราชสีมา",
    district: "หนองบุญมาก",
    subdistrict: "หนองตะไก้",
    zipcode: 30410,
    latitude: 14.654,
    longitude: 102.397,
  },
  {
    province: "นครราชสีมา",
    district: "หนองบุญมาก",
    subdistrict: "ลุงเขว้า",
    zipcode: 30410,
    latitude: 14.813,
    longitude: 102.339,
  },
  {
    province: "นครราชสีมา",
    district: "หนองบุญมาก",
    subdistrict: "หนองไม้ไผ่",
    zipcode: 30410,
    latitude: 14.641,
    longitude: 102.32,
  },
  {
    province: "นครราชสีมา",
    district: "หนองบุญมาก",
    subdistrict: "บ้านใหม่",
    zipcode: 30410,
    latitude: 14.754,
    longitude: 102.398,
  },
  {
    province: "นครราชสีมา",
    district: "แก้งสนามนาง",
    subdistrict: "แก้งสนามนาง",
    zipcode: 30440,
    latitude: 15.727,
    longitude: 102.277,
  },
  {
    province: "นครราชสีมา",
    district: "แก้งสนามนาง",
    subdistrict: "โนนสำราญ",
    zipcode: 30440,
    latitude: 15.75,
    longitude: 102.325,
  },
  {
    province: "นครราชสีมา",
    district: "แก้งสนามนาง",
    subdistrict: "บึงพะไล",
    zipcode: 30440,
    latitude: 15.661,
    longitude: 102.236,
  },
  {
    province: "นครราชสีมา",
    district: "แก้งสนามนาง",
    subdistrict: "สีสุก",
    zipcode: 30440,
    latitude: 15.64,
    longitude: 102.175,
  },
  {
    province: "นครราชสีมา",
    district: "แก้งสนามนาง",
    subdistrict: "บึงสำโรง",
    zipcode: 30440,
    latitude: 15.715,
    longitude: 102.198,
  },
  {
    province: "นครราชสีมา",
    district: "โนนแดง",
    subdistrict: "โนนแดง",
    zipcode: 30360,
    latitude: 15.3040206,
    longitude: 101.6829169,
  },
  {
    province: "นครราชสีมา",
    district: "โนนแดง",
    subdistrict: "โนนตาเถร",
    zipcode: 30360,
    latitude: 15.435,
    longitude: 102.488,
  },
  {
    province: "นครราชสีมา",
    district: "โนนแดง",
    subdistrict: "สำพะเนียง",
    zipcode: 30360,
    latitude: 15.452,
    longitude: 102.568,
  },
  {
    province: "นครราชสีมา",
    district: "โนนแดง",
    subdistrict: "วังหิน",
    zipcode: 30360,
    latitude: 15.505,
    longitude: 102.567,
  },
  {
    province: "นครราชสีมา",
    district: "โนนแดง",
    subdistrict: "ดอนยาวใหญ่",
    zipcode: 30360,
    latitude: 15.41,
    longitude: 102.536,
  },
  {
    province: "นครราชสีมา",
    district: "วังน้ำเขียว",
    subdistrict: "วังน้ำเขียว",
    zipcode: 30370,
    latitude: 14.399,
    longitude: 101.817,
  },
  {
    province: "นครราชสีมา",
    district: "วังน้ำเขียว",
    subdistrict: "วังหมี",
    zipcode: 30370,
    latitude: 14.474,
    longitude: 101.769,
  },
  {
    province: "นครราชสีมา",
    district: "วังน้ำเขียว",
    subdistrict: "ระเริง",
    zipcode: 30150,
    latitude: 14.574,
    longitude: 101.731,
  },
  {
    province: "นครราชสีมา",
    district: "วังน้ำเขียว",
    subdistrict: "อุดมทรัพย์",
    zipcode: 30370,
    latitude: 14.49,
    longitude: 101.969,
  },
  {
    province: "นครราชสีมา",
    district: "วังน้ำเขียว",
    subdistrict: "ไทยสามัคคี",
    zipcode: 30370,
    latitude: 14.353,
    longitude: 101.966,
  },
  {
    province: "นครราชสีมา",
    district: "เทพารักษ์",
    subdistrict: "สำนักตะคร้อ",
    zipcode: 30210,
    latitude: 15.297,
    longitude: 101.564,
  },
  {
    province: "นครราชสีมา",
    district: "เทพารักษ์",
    subdistrict: "หนองแวง",
    zipcode: 30210,
    latitude: 15.256,
    longitude: 101.436,
  },
  {
    province: "นครราชสีมา",
    district: "เทพารักษ์",
    subdistrict: "บึงปรือ",
    zipcode: 30210,
    latitude: 15.303,
    longitude: 101.411,
  },
  {
    province: "นครราชสีมา",
    district: "เทพารักษ์",
    subdistrict: "วังยายทอง",
    zipcode: 30210,
    latitude: 15.349,
    longitude: 101.518,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองยาง",
    subdistrict: "เมืองยาง",
    zipcode: 30270,
    latitude: 15.418,
    longitude: 102.886,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองยาง",
    subdistrict: "กระเบื้องนอก",
    zipcode: 30270,
    latitude: 15.44,
    longitude: 102.957,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองยาง",
    subdistrict: "ละหานปลาค้าว",
    zipcode: 30270,
    latitude: 15.487,
    longitude: 102.889,
  },
  {
    province: "นครราชสีมา",
    district: "เมืองยาง",
    subdistrict: "โนนอุดม",
    zipcode: 30270,
    latitude: 15.426,
    longitude: 102.812,
  },
  {
    province: "นครราชสีมา",
    district: "พระทองคำ",
    subdistrict: "สระพระ",
    zipcode: 30220,
    latitude: 15.311,
    longitude: 101.959,
  },
  {
    province: "นครราชสีมา",
    district: "พระทองคำ",
    subdistrict: "มาบกราด",
    zipcode: 30220,
    latitude: 15.369,
    longitude: 101.926,
  },
  {
    province: "นครราชสีมา",
    district: "พระทองคำ",
    subdistrict: "พังเทียม",
    zipcode: 30220,
    latitude: 15.275,
    longitude: 101.995,
  },
  {
    province: "นครราชสีมา",
    district: "พระทองคำ",
    subdistrict: "ทัพรั้ง",
    zipcode: 30220,
    latitude: 15.406,
    longitude: 101.976,
  },
  {
    province: "นครราชสีมา",
    district: "พระทองคำ",
    subdistrict: "หนองหอย",
    zipcode: 30220,
    latitude: 15.334,
    longitude: 102.056,
  },
  {
    province: "นครราชสีมา",
    district: "ลำทะเมนชัย",
    subdistrict: "ขุย",
    zipcode: 30270,
    latitude: 15.353,
    longitude: 102.866,
  },
  {
    province: "นครราชสีมา",
    district: "ลำทะเมนชัย",
    subdistrict: "บ้านยาง",
    zipcode: 30270,
    latitude: 15.25,
    longitude: 102.944,
  },
  {
    province: "นครราชสีมา",
    district: "ลำทะเมนชัย",
    subdistrict: "ช่องแมว",
    zipcode: 30270,
    latitude: 15.276,
    longitude: 102.874,
  },
  {
    province: "นครราชสีมา",
    district: "ลำทะเมนชัย",
    subdistrict: "ไพล",
    zipcode: 30270,
    latitude: 15.36,
    longitude: 102.971,
  },
  {
    province: "นครราชสีมา",
    district: "บัวลาย",
    subdistrict: "เมืองพะไล",
    zipcode: 30120,
    latitude: 15.649,
    longitude: 102.532,
  },
  {
    province: "นครราชสีมา",
    district: "บัวลาย",
    subdistrict: "โนนจาน",
    zipcode: 30120,
    latitude: 15.699,
    longitude: 102.472,
  },
  {
    province: "นครราชสีมา",
    district: "บัวลาย",
    subdistrict: "บัวลาย",
    zipcode: 30120,
    latitude: 15.661,
    longitude: 102.468,
  },
  {
    province: "นครราชสีมา",
    district: "บัวลาย",
    subdistrict: "หนองหว้า",
    zipcode: 30120,
    latitude: 15.667,
    longitude: 102.573,
  },
  {
    province: "นครราชสีมา",
    district: "สีดา",
    subdistrict: "สีดา",
    zipcode: 30430,
    latitude: 15.564,
    longitude: 102.588,
  },
  {
    province: "นครราชสีมา",
    district: "สีดา",
    subdistrict: "โพนทอง",
    zipcode: 30430,
    latitude: 15.54,
    longitude: 102.59,
  },
  {
    province: "นครราชสีมา",
    district: "สีดา",
    subdistrict: "โนนประดู่",
    zipcode: 30430,
    latitude: 15.521,
    longitude: 102.479,
  },
  {
    province: "นครราชสีมา",
    district: "สีดา",
    subdistrict: "สามเมือง",
    zipcode: 30430,
    latitude: 15.591,
    longitude: 102.537,
  },
  {
    province: "นครราชสีมา",
    district: "สีดา",
    subdistrict: "หนองตาดใหญ่",
    zipcode: 30430,
    latitude: 15.617,
    longitude: 102.591,
  },
  {
    province: "นครราชสีมา",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ช้างทอง",
    zipcode: 30230,
    latitude: 15.029,
    longitude: 102.31,
  },
  {
    province: "นครราชสีมา",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ท่าช้าง",
    zipcode: 30230,
    latitude: 14.981,
    longitude: 102.323,
  },
  {
    province: "นครราชสีมา",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "พระพุทธ",
    zipcode: 30230,
    latitude: 14.975,
    longitude: 102.232,
  },
  {
    province: "นครราชสีมา",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "หนองงูเหลือม",
    zipcode: 30000,
    latitude: 15.053,
    longitude: 102.234,
  },
  {
    province: "นครราชสีมา",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "หนองยาง",
    zipcode: 30230,
    latitude: 14.93,
    longitude: 102.287,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "ในเมือง",
    zipcode: 31000,
    latitude: 14.999,
    longitude: 103.104,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "อิสาณ",
    zipcode: 31000,
    latitude: 14.979,
    longitude: 103.128,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "เสม็ด",
    zipcode: 31000,
    latitude: 14.941,
    longitude: 103.098,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "บ้านบัว",
    zipcode: 31000,
    latitude: 14.949,
    longitude: 102.997,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "สะแกโพรง",
    zipcode: 31000,
    latitude: 14.853,
    longitude: 102.942,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "สวายจีก",
    zipcode: 31000,
    latitude: 14.8946589,
    longitude: 103.0694343,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "บ้านยาง",
    zipcode: 31000,
    latitude: 15.018,
    longitude: 103.156,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "พระครู",
    zipcode: 31000,
    latitude: 15.099,
    longitude: 103.018,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "ถลุงเหล็ก",
    zipcode: 31000,
    latitude: 15.116,
    longitude: 103.078,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "หนองตาด",
    zipcode: 31000,
    latitude: 15.031,
    longitude: 103.024,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "ลุมปุ๊ก",
    zipcode: 31000,
    latitude: 14.908,
    longitude: 102.951,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "สองห้อง",
    zipcode: 31000,
    latitude: 14.808,
    longitude: 102.953,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "บัวทอง",
    zipcode: 31000,
    latitude: 15.058,
    longitude: 103.136,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "ชุมเห็ด",
    zipcode: 31000,
    latitude: 15.033,
    longitude: 103.093,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "หลักเขต",
    zipcode: 31000,
    latitude: 14.824,
    longitude: 103.159,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "สะแกซำ",
    zipcode: 31000,
    latitude: 14.871,
    longitude: 103.099,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "กลันทา",
    zipcode: 31000,
    latitude: 15.095,
    longitude: 103.109,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "กระสัง",
    zipcode: 31000,
    latitude: 14.974,
    longitude: 103.047,
  },
  {
    province: "บุรีรัมย์",
    district: "เมืองบุรีรัมย์",
    subdistrict: "เมืองฝาง",
    zipcode: 31000,
    latitude: 14.782,
    longitude: 102.982,
  },
  {
    province: "บุรีรัมย์",
    district: "คูเมือง",
    subdistrict: "คูเมือง",
    zipcode: 31190,
    latitude: 15.24,
    longitude: 103,
  },
  {
    province: "บุรีรัมย์",
    district: "คูเมือง",
    subdistrict: "ปะเคียบ",
    zipcode: 31190,
    latitude: 15.391,
    longitude: 103.054,
  },
  {
    province: "บุรีรัมย์",
    district: "คูเมือง",
    subdistrict: "บ้านแพ",
    zipcode: 31190,
    latitude: 15.388,
    longitude: 103.008,
  },
  {
    province: "บุรีรัมย์",
    district: "คูเมือง",
    subdistrict: "พรสำราญ",
    zipcode: 31190,
    latitude: 15.169,
    longitude: 103.027,
  },
  {
    province: "บุรีรัมย์",
    district: "คูเมือง",
    subdistrict: "หินเหล็กไฟ",
    zipcode: 31190,
    latitude: 15.157,
    longitude: 103.064,
  },
  {
    province: "บุรีรัมย์",
    district: "คูเมือง",
    subdistrict: "ตูมใหญ่",
    zipcode: 31190,
    latitude: 15.165,
    longitude: 103.137,
  },
  {
    province: "บุรีรัมย์",
    district: "คูเมือง",
    subdistrict: "หนองขมาร",
    zipcode: 31190,
    latitude: 15.314,
    longitude: 102.995,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "กระสัง",
    zipcode: 31160,
    latitude: 14.922,
    longitude: 103.296,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "ลำดวน",
    zipcode: 31160,
    latitude: 15.061,
    longitude: 103.403,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "สองชั้น",
    zipcode: 31160,
    latitude: 14.887,
    longitude: 103.224,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "สูงเนิน",
    zipcode: 31160,
    latitude: 14.82,
    longitude: 103.239,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "หนองเต็ง",
    zipcode: 31160,
    latitude: 14.905,
    longitude: 103.374,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "เมืองไผ่",
    zipcode: 31160,
    latitude: 14.994,
    longitude: 103.292,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "ชุมแสง",
    zipcode: 31160,
    latitude: 14.984,
    longitude: 103.395,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "บ้านปรือ",
    zipcode: 31160,
    latitude: 14.848,
    longitude: 103.299,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "ห้วยสำราญ",
    zipcode: 31160,
    latitude: 15.072,
    longitude: 103.336,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "กันทรารมย์",
    zipcode: 31160,
    latitude: 14.966,
    longitude: 103.34,
  },
  {
    province: "บุรีรัมย์",
    district: "กระสัง",
    subdistrict: "ศรีภูมิ",
    zipcode: 31160,
    latitude: 15.027,
    longitude: 103.338,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "นางรอง",
    zipcode: 31110,
    latitude: 14.673,
    longitude: 102.806,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "สะเดา",
    zipcode: 31110,
    latitude: 14.577,
    longitude: 102.77,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "ชุมแสง",
    zipcode: 31110,
    latitude: 14.561,
    longitude: 102.666,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "หนองโบสถ์",
    zipcode: 31110,
    latitude: 14.612,
    longitude: 102.691,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "หนองกง",
    zipcode: 31110,
    latitude: 14.674,
    longitude: 102.896,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "ถนนหัก",
    zipcode: 31110,
    latitude: 14.616,
    longitude: 102.833,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "หนองไทร",
    zipcode: 31110,
    latitude: 14.499,
    longitude: 102.757,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "ก้านเหลือง",
    zipcode: 31110,
    latitude: 14.73,
    longitude: 102.711,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "บ้านสิงห์",
    zipcode: 31110,
    latitude: 14.735,
    longitude: 102.789,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "ลำไทรโยง",
    zipcode: 31110,
    latitude: 14.665,
    longitude: 102.735,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "ทรัพย์พระยา",
    zipcode: 31110,
    latitude: 14.516,
    longitude: 102.662,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "หนองยายพิมพ์",
    zipcode: 31110,
    latitude: 14.69,
    longitude: 102.863,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "หัวถนน",
    zipcode: 31110,
    latitude: 14.661,
    longitude: 102.648,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "ทุ่งแสงทอง",
    zipcode: 31110,
    latitude: 14.581,
    longitude: 102.705,
  },
  {
    province: "บุรีรัมย์",
    district: "นางรอง",
    subdistrict: "หนองโสน",
    zipcode: 31110,
    latitude: 14.737,
    longitude: 102.934,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "หนองกี่",
    zipcode: 31210,
    latitude: 14.669,
    longitude: 102.571,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "เย้ยปราสาท",
    zipcode: 31210,
    latitude: 14.739,
    longitude: 102.564,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "เมืองไผ่",
    zipcode: 31210,
    latitude: 14.774,
    longitude: 102.645,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "ดอนอะราง",
    zipcode: 31210,
    latitude: 14.642,
    longitude: 102.571,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "โคกสว่าง",
    zipcode: 31210,
    latitude: 14.782,
    longitude: 102.489,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "ทุ่งกระตาดพัฒนา",
    zipcode: 31210,
    latitude: 14.674,
    longitude: 102.497,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "ทุ่งกระเต็น",
    zipcode: 31210,
    latitude: 14.693,
    longitude: 102.564,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "ท่าโพธิ์ชัย",
    zipcode: 31210,
    latitude: 14.736,
    longitude: 102.635,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "โคกสูง",
    zipcode: 31210,
    latitude: 14.806,
    longitude: 102.562,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองกี่",
    subdistrict: "บุกระสัง",
    zipcode: 31210,
    latitude: 14.724,
    longitude: 102.501,
  },
  {
    province: "บุรีรัมย์",
    district: "ละหานทราย",
    subdistrict: "ละหานทราย",
    zipcode: 31170,
    latitude: 14.418,
    longitude: 102.854,
  },
  {
    province: "บุรีรัมย์",
    district: "ละหานทราย",
    subdistrict: "ตาจง",
    zipcode: 31170,
    latitude: 14.439,
    longitude: 102.92,
  },
  {
    province: "บุรีรัมย์",
    district: "ละหานทราย",
    subdistrict: "สำโรงใหม่",
    zipcode: 31170,
    latitude: 14.284,
    longitude: 102.85,
  },
  {
    province: "บุรีรัมย์",
    district: "ละหานทราย",
    subdistrict: "หนองแวง",
    zipcode: 31170,
    latitude: 14.268,
    longitude: 102.935,
  },
  {
    province: "บุรีรัมย์",
    district: "ละหานทราย",
    subdistrict: "หนองตะครอง",
    zipcode: 31170,
    latitude: 14.3876997,
    longitude: 102.7520277,
  },
  {
    province: "บุรีรัมย์",
    district: "ละหานทราย",
    subdistrict: "โคกว่าน",
    zipcode: 31170,
    latitude: 14.449,
    longitude: 102.784,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "ประโคนชัย",
    zipcode: 31140,
    latitude: 14.613,
    longitude: 103.081,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "แสลงโทน",
    zipcode: 31140,
    latitude: 14.787,
    longitude: 103.055,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "บ้านไทร",
    zipcode: 31140,
    latitude: 14.705,
    longitude: 103.065,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "ละเวี้ย",
    zipcode: 31140,
    latitude: 14.599,
    longitude: 103.191,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "จรเข้มาก",
    zipcode: 31140,
    latitude: 14.5,
    longitude: 102.989,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "ปังกู",
    zipcode: 31140,
    latitude: 14.537,
    longitude: 103.067,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "โคกย่าง",
    zipcode: 31140,
    latitude: 14.562,
    longitude: 103.005,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "โคกม้า",
    zipcode: 31140,
    latitude: 14.641,
    longitude: 103.035,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "ไพศาล",
    zipcode: 31140,
    latitude: 14.662,
    longitude: 103.208,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "ตะโกตาพิ",
    zipcode: 31140,
    latitude: 14.638,
    longitude: 102.981,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "เขาคอก",
    zipcode: 31140,
    latitude: 14.44,
    longitude: 103.004,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "หนองบอน",
    zipcode: 31140,
    latitude: 14.542,
    longitude: 103.143,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "โคกมะขาม",
    zipcode: 31140,
    latitude: 14.602,
    longitude: 103.129,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "โคกตูม",
    zipcode: 31140,
    latitude: 14.702,
    longitude: 102.989,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "ประทัดบุ",
    zipcode: 31140,
    latitude: 14.569,
    longitude: 102.962,
  },
  {
    province: "บุรีรัมย์",
    district: "ประโคนชัย",
    subdistrict: "สี่เหลี่ยม",
    zipcode: 31140,
    latitude: 14.75,
    longitude: 103.003,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านกรวด",
    subdistrict: "บ้านกรวด",
    zipcode: 31180,
    latitude: 14.429,
    longitude: 103.077,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านกรวด",
    subdistrict: "โนนเจริญ",
    zipcode: 31180,
    latitude: 14.464,
    longitude: 103.189,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านกรวด",
    subdistrict: "หนองไม้งาม",
    zipcode: 31180,
    latitude: 14.344,
    longitude: 102.997,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านกรวด",
    subdistrict: "ปราสาท",
    zipcode: 31180,
    latitude: 14.362,
    longitude: 103.109,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านกรวด",
    subdistrict: "สายตะกู",
    zipcode: 31180,
    latitude: 14.397,
    longitude: 103.211,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านกรวด",
    subdistrict: "หินลาด",
    zipcode: 31180,
    latitude: 14.473,
    longitude: 103.143,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านกรวด",
    subdistrict: "บึงเจริญ",
    zipcode: 31180,
    latitude: 14.337,
    longitude: 103.037,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านกรวด",
    subdistrict: "จันทบเพชร",
    zipcode: 31180,
    latitude: 14.384,
    longitude: 103.164,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านกรวด",
    subdistrict: "เขาดินเหนือ",
    zipcode: 31180,
    latitude: 14.524,
    longitude: 103.199,
  },
  {
    province: "บุรีรัมย์",
    district: "พุทไธสง",
    subdistrict: "พุทไธสง",
    zipcode: 31120,
    latitude: 15.582,
    longitude: 103.014,
  },
  {
    province: "บุรีรัมย์",
    district: "พุทไธสง",
    subdistrict: "มะเฟือง",
    zipcode: 31120,
    latitude: 15.497,
    longitude: 103.032,
  },
  {
    province: "บุรีรัมย์",
    district: "พุทไธสง",
    subdistrict: "บ้านจาน",
    zipcode: 31120,
    latitude: 15.507,
    longitude: 102.983,
  },
  {
    province: "บุรีรัมย์",
    district: "พุทไธสง",
    subdistrict: "บ้านเป้า",
    zipcode: 31120,
    latitude: 15.627,
    longitude: 102.892,
  },
  {
    province: "บุรีรัมย์",
    district: "พุทไธสง",
    subdistrict: "บ้านแวง",
    zipcode: 31120,
    latitude: 15.58,
    longitude: 102.959,
  },
  {
    province: "บุรีรัมย์",
    district: "พุทไธสง",
    subdistrict: "บ้านยาง",
    zipcode: 31120,
    latitude: 15.463,
    longitude: 103.097,
  },
  {
    province: "บุรีรัมย์",
    district: "พุทไธสง",
    subdistrict: "หายโศก",
    zipcode: 31120,
    latitude: 15.526,
    longitude: 102.937,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "ลำปลายมาศ",
    zipcode: 31130,
    latitude: 15.019,
    longitude: 102.834,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "หนองคู",
    zipcode: 31130,
    latitude: 14.999,
    longitude: 102.803,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "แสลงพัน",
    zipcode: 31130,
    latitude: 14.99,
    longitude: 102.957,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "ทะเมนชัย",
    zipcode: 31130,
    latitude: 15.051,
    longitude: 102.945,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "ตลาดโพธิ์",
    zipcode: 31130,
    latitude: 15.083,
    longitude: 102.893,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "หนองกะทิง",
    zipcode: 31130,
    latitude: 14.9532624,
    longitude: 102.6823816,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "โคกกลาง",
    zipcode: 31130,
    latitude: 14.949,
    longitude: 102.862,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "โคกสะอาด",
    zipcode: 31130,
    latitude: 15.154,
    longitude: 102.902,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "เมืองแฝก",
    zipcode: 31130,
    latitude: 15.154,
    longitude: 102.973,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "บ้านยาง",
    zipcode: 31130,
    latitude: 14.873,
    longitude: 102.886,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "ผไทรินทร์",
    zipcode: 31130,
    latitude: 14.917,
    longitude: 102.777,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "โคกล่าม",
    zipcode: 31130,
    latitude: 15.114,
    longitude: 102.839,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "หินโคน",
    zipcode: 31130,
    latitude: 15.057,
    longitude: 102.82,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "หนองบัวโคก",
    zipcode: 31130,
    latitude: 15.006,
    longitude: 102.889,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "บุโพธิ์",
    zipcode: 31130,
    latitude: 15.109,
    longitude: 102.938,
  },
  {
    province: "บุรีรัมย์",
    district: "ลำปลายมาศ",
    subdistrict: "หนองโดน",
    zipcode: 31130,
    latitude: 14.882,
    longitude: 102.824,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "สตึก",
    zipcode: 31150,
    latitude: 15.263,
    longitude: 103.301,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "นิคม",
    zipcode: 31150,
    latitude: 15.274,
    longitude: 103.225,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "ทุ่งวัง",
    zipcode: 31150,
    latitude: 15.259,
    longitude: 103.386,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "เมืองแก",
    zipcode: 31150,
    latitude: 15.19,
    longitude: 103.308,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "หนองใหญ่",
    zipcode: 31150,
    latitude: 15.131,
    longitude: 103.291,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "ร่อนทอง",
    zipcode: 31150,
    latitude: 15.2,
    longitude: 103.244,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "ดอนมนต์",
    zipcode: 31150,
    latitude: 15.209,
    longitude: 103.183,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "ชุมแสง",
    zipcode: 31150,
    latitude: 15.137,
    longitude: 103.377,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "ท่าม่วง",
    zipcode: 31150,
    latitude: 15.3,
    longitude: 103.442,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "สะแก",
    zipcode: 31150,
    latitude: 15.3,
    longitude: 103.354,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "สนามชัย",
    zipcode: 31150,
    latitude: 15.207,
    longitude: 103.356,
  },
  {
    province: "บุรีรัมย์",
    district: "สตึก",
    subdistrict: "กระสัง",
    zipcode: 31150,
    latitude: 15.195,
    longitude: 103.405,
  },
  {
    province: "บุรีรัมย์",
    district: "ปะคำ",
    subdistrict: "ปะคำ",
    zipcode: 31220,
    latitude: 14.454,
    longitude: 102.721,
  },
  {
    province: "บุรีรัมย์",
    district: "ปะคำ",
    subdistrict: "ไทยเจริญ",
    zipcode: 31220,
    latitude: 14.461,
    longitude: 102.683,
  },
  {
    province: "บุรีรัมย์",
    district: "ปะคำ",
    subdistrict: "หนองบัว",
    zipcode: 31220,
    latitude: 14.432,
    longitude: 102.657,
  },
  {
    province: "บุรีรัมย์",
    district: "ปะคำ",
    subdistrict: "โคกมะม่วง",
    zipcode: 31220,
    latitude: 14.402,
    longitude: 102.612,
  },
  {
    province: "บุรีรัมย์",
    district: "ปะคำ",
    subdistrict: "หูทำนบ",
    zipcode: 31220,
    latitude: 14.364,
    longitude: 102.696,
  },
  {
    province: "บุรีรัมย์",
    district: "นาโพธิ์",
    subdistrict: "นาโพธิ์",
    zipcode: 31230,
    latitude: 15.635,
    longitude: 102.944,
  },
  {
    province: "บุรีรัมย์",
    district: "นาโพธิ์",
    subdistrict: "บ้านคู",
    zipcode: 31230,
    latitude: 15.712,
    longitude: 102.933,
  },
  {
    province: "บุรีรัมย์",
    district: "นาโพธิ์",
    subdistrict: "บ้านดู่",
    zipcode: 31230,
    latitude: 15.713,
    longitude: 102.977,
  },
  {
    province: "บุรีรัมย์",
    district: "นาโพธิ์",
    subdistrict: "ดอนกอก",
    zipcode: 31230,
    latitude: 15.763,
    longitude: 102.895,
  },
  {
    province: "บุรีรัมย์",
    district: "นาโพธิ์",
    subdistrict: "ศรีสว่าง",
    zipcode: 31230,
    latitude: 15.673,
    longitude: 102.934,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองหงส์",
    subdistrict: "สระแก้ว",
    zipcode: 31240,
    latitude: 14.828,
    longitude: 102.681,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองหงส์",
    subdistrict: "ห้วยหิน",
    zipcode: 31240,
    latitude: 14.871,
    longitude: 102.594,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองหงส์",
    subdistrict: "ไทยสามัคคี",
    zipcode: 31240,
    latitude: 14.885,
    longitude: 102.763,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองหงส์",
    subdistrict: "หนองชัยศรี",
    zipcode: 31240,
    latitude: 14.823,
    longitude: 102.645,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองหงส์",
    subdistrict: "เสาเดียว",
    zipcode: 31240,
    latitude: 14.911,
    longitude: 102.609,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองหงส์",
    subdistrict: "เมืองฝ้าย",
    zipcode: 31240,
    latitude: 14.843,
    longitude: 102.741,
  },
  {
    province: "บุรีรัมย์",
    district: "หนองหงส์",
    subdistrict: "สระทอง",
    zipcode: 31240,
    latitude: 14.889,
    longitude: 102.67,
  },
  {
    province: "บุรีรัมย์",
    district: "พลับพลาชัย",
    subdistrict: "จันดุม",
    zipcode: 31250,
    latitude: 14.678,
    longitude: 103.118,
  },
  {
    province: "บุรีรัมย์",
    district: "พลับพลาชัย",
    subdistrict: "โคกขมิ้น",
    zipcode: 31250,
    latitude: 14.769,
    longitude: 103.193,
  },
  {
    province: "บุรีรัมย์",
    district: "พลับพลาชัย",
    subdistrict: "ป่าชัน",
    zipcode: 31250,
    latitude: 14.717,
    longitude: 103.241,
  },
  {
    province: "บุรีรัมย์",
    district: "พลับพลาชัย",
    subdistrict: "สะเดา",
    zipcode: 31250,
    latitude: 14.722,
    longitude: 103.168,
  },
  {
    province: "บุรีรัมย์",
    district: "พลับพลาชัย",
    subdistrict: "สำโรง",
    zipcode: 31250,
    latitude: 14.776,
    longitude: 103.111,
  },
  {
    province: "บุรีรัมย์",
    district: "ห้วยราช",
    subdistrict: "ห้วยราช",
    zipcode: 31000,
    latitude: 14.982,
    longitude: 103.203,
  },
  {
    province: "บุรีรัมย์",
    district: "ห้วยราช",
    subdistrict: "สามแวง",
    zipcode: 31000,
    latitude: 15.009,
    longitude: 103.192,
  },
  {
    province: "บุรีรัมย์",
    district: "ห้วยราช",
    subdistrict: "ตาเสา",
    zipcode: 31000,
    latitude: 15.088,
    longitude: 103.285,
  },
  {
    province: "บุรีรัมย์",
    district: "ห้วยราช",
    subdistrict: "บ้านตะโก",
    zipcode: 31000,
    latitude: 14.969,
    longitude: 103.233,
  },
  {
    province: "บุรีรัมย์",
    district: "ห้วยราช",
    subdistrict: "สนวน",
    zipcode: 31000,
    latitude: 14.933,
    longitude: 103.182,
  },
  {
    province: "บุรีรัมย์",
    district: "ห้วยราช",
    subdistrict: "โคกเหล็ก",
    zipcode: 31000,
    latitude: 15.023,
    longitude: 103.23,
  },
  {
    province: "บุรีรัมย์",
    district: "ห้วยราช",
    subdistrict: "เมืองโพธิ์",
    zipcode: 31000,
    latitude: 15.042,
    longitude: 103.268,
  },
  {
    province: "บุรีรัมย์",
    district: "ห้วยราช",
    subdistrict: "ห้วยราชา",
    zipcode: 31000,
    latitude: 14.982,
    longitude: 103.203,
  },
  {
    province: "บุรีรัมย์",
    district: "โนนสุวรรณ",
    subdistrict: "โนนสุวรรณ",
    zipcode: 31110,
    latitude: 14.538,
    longitude: 102.604,
  },
  {
    province: "บุรีรัมย์",
    district: "โนนสุวรรณ",
    subdistrict: "ทุ่งจังหัน",
    zipcode: 31110,
    latitude: 14.566,
    longitude: 102.558,
  },
  {
    province: "บุรีรัมย์",
    district: "โนนสุวรรณ",
    subdistrict: "โกรกแก้ว",
    zipcode: 31110,
    latitude: 14.621,
    longitude: 102.637,
  },
  {
    province: "บุรีรัมย์",
    district: "โนนสุวรรณ",
    subdistrict: "ดงอีจาน",
    zipcode: 31110,
    latitude: 14.546,
    longitude: 102.517,
  },
  {
    province: "บุรีรัมย์",
    district: "ชำนิ",
    subdistrict: "ชำนิ",
    zipcode: 31110,
    latitude: 14.785,
    longitude: 102.81,
  },
  {
    province: "บุรีรัมย์",
    district: "ชำนิ",
    subdistrict: "หนองปล่อง",
    zipcode: 31110,
    latitude: 14.743,
    longitude: 102.844,
  },
  {
    province: "บุรีรัมย์",
    district: "ชำนิ",
    subdistrict: "เมืองยาง",
    zipcode: 31110,
    latitude: 14.794,
    longitude: 102.906,
  },
  {
    province: "บุรีรัมย์",
    district: "ชำนิ",
    subdistrict: "ช่อผกา",
    zipcode: 31110,
    latitude: 14.809,
    longitude: 102.787,
  },
  {
    province: "บุรีรัมย์",
    district: "ชำนิ",
    subdistrict: "ละลวด",
    zipcode: 31110,
    latitude: 14.78,
    longitude: 102.745,
  },
  {
    province: "บุรีรัมย์",
    district: "ชำนิ",
    subdistrict: "โคกสนวน",
    zipcode: 31110,
    latitude: 14.755,
    longitude: 102.888,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านใหม่ไชยพจน์",
    subdistrict: "หนองแวง",
    zipcode: 31120,
    latitude: 15.553,
    longitude: 102.907,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านใหม่ไชยพจน์",
    subdistrict: "ทองหลาง",
    zipcode: 31120,
    latitude: 15.608,
    longitude: 102.805,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านใหม่ไชยพจน์",
    subdistrict: "แดงใหญ่",
    zipcode: 31120,
    latitude: 15.605,
    longitude: 102.857,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านใหม่ไชยพจน์",
    subdistrict: "กู่สวนแตง",
    zipcode: 31120,
    latitude: 15.534,
    longitude: 102.86,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านใหม่ไชยพจน์",
    subdistrict: "หนองเยือง",
    zipcode: 31120,
    latitude: 15.555,
    longitude: 102.811,
  },
  {
    province: "บุรีรัมย์",
    district: "โนนดินแดง",
    subdistrict: "โนนดินแดง",
    zipcode: 31260,
    latitude: 14.316,
    longitude: 102.721,
  },
  {
    province: "บุรีรัมย์",
    district: "โนนดินแดง",
    subdistrict: "ส้มป่อย",
    zipcode: 31260,
    latitude: 14.346,
    longitude: 102.764,
  },
  {
    province: "บุรีรัมย์",
    district: "โนนดินแดง",
    subdistrict: "ลำนางรอง",
    zipcode: 31260,
    latitude: 14.213,
    longitude: 102.698,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านด่าน",
    subdistrict: "บ้านด่าน",
    zipcode: 31000,
    latitude: 15.112,
    longitude: 103.162,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านด่าน",
    subdistrict: "ปราสาท",
    zipcode: 31000,
    latitude: 15.081,
    longitude: 103.217,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านด่าน",
    subdistrict: "วังเหนือ",
    zipcode: 31000,
    latitude: 15.138,
    longitude: 103.219,
  },
  {
    province: "บุรีรัมย์",
    district: "บ้านด่าน",
    subdistrict: "โนนขวาง",
    zipcode: 31000,
    latitude: 15.191,
    longitude: 103.157,
  },
  {
    province: "บุรีรัมย์",
    district: "แคนดง",
    subdistrict: "แคนดง",
    zipcode: 31150,
    latitude: 15.317,
    longitude: 103.123,
  },
  {
    province: "บุรีรัมย์",
    district: "แคนดง",
    subdistrict: "ดงพลอง",
    zipcode: 31150,
    latitude: 15.321,
    longitude: 103.172,
  },
  {
    province: "บุรีรัมย์",
    district: "แคนดง",
    subdistrict: "สระบัว",
    zipcode: 31150,
    latitude: 15.32,
    longitude: 103.077,
  },
  {
    province: "บุรีรัมย์",
    district: "แคนดง",
    subdistrict: "หัวฝาย",
    zipcode: 31150,
    latitude: 15.267,
    longitude: 103.18,
  },
  {
    province: "บุรีรัมย์",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "เจริญสุข",
    zipcode: 31110,
    latitude: 14.574,
    longitude: 102.865,
  },
  {
    province: "บุรีรัมย์",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ตาเป๊ก",
    zipcode: 31110,
    latitude: 14.568,
    longitude: 102.924,
  },
  {
    province: "บุรีรัมย์",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "อีสานเขต",
    zipcode: 31110,
    latitude: 14.641,
    longitude: 102.931,
  },
  {
    province: "บุรีรัมย์",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ถาวร",
    zipcode: 31170,
    latitude: 14.518,
    longitude: 102.87,
  },
  {
    province: "บุรีรัมย์",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ยายแย้มวัฒนา",
    zipcode: 31170,
    latitude: 14.505,
    longitude: 102.918,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "ในเมือง",
    zipcode: 32000,
    latitude: 14.884,
    longitude: 103.49,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "ตั้งใจ",
    zipcode: 32000,
    latitude: 15.074,
    longitude: 103.55,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "เพี้ยราม",
    zipcode: 32000,
    latitude: 15.067,
    longitude: 103.471,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "นาดี",
    zipcode: 32000,
    latitude: 15.024,
    longitude: 103.528,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "ท่าสว่าง",
    zipcode: 32000,
    latitude: 14.955,
    longitude: 103.452,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "สลักได",
    zipcode: 32000,
    latitude: 14.855,
    longitude: 103.562,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "ตาอ็อง",
    zipcode: 32000,
    latitude: 14.748,
    longitude: 103.617,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "สำโรง",
    zipcode: 32000,
    latitude: 14.802,
    longitude: 103.627,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "แกใหญ่",
    zipcode: 32000,
    latitude: 14.951,
    longitude: 103.513,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "นอกเมือง",
    zipcode: 32000,
    latitude: 14.858,
    longitude: 103.491,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "คอโค",
    zipcode: 32000,
    latitude: 14.903,
    longitude: 103.425,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "สวาย",
    zipcode: 32000,
    latitude: 14.793,
    longitude: 103.337,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "เฉนียง",
    zipcode: 32000,
    latitude: 14.796,
    longitude: 103.477,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "เทนมีย์",
    zipcode: 32000,
    latitude: 14.774,
    longitude: 103.542,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "นาบัว",
    zipcode: 32000,
    latitude: 14.782,
    longitude: 103.412,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "เมืองที",
    zipcode: 32000,
    latitude: 14.91,
    longitude: 103.652,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "ราม",
    zipcode: 32000,
    latitude: 14.848,
    longitude: 103.65,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "บุฤาษี",
    zipcode: 32000,
    latitude: 14.904,
    longitude: 103.597,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "ตระแสง",
    zipcode: 32000,
    latitude: 14.855,
    longitude: 103.403,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "แสลงพันธ์",
    zipcode: 32000,
    latitude: 14.918,
    longitude: 103.545,
  },
  {
    province: "สุรินทร์",
    district: "เมืองสุรินทร์",
    subdistrict: "กาเกาะ",
    zipcode: 32000,
    latitude: 15.114,
    longitude: 103.473,
  },
  {
    province: "สุรินทร์",
    district: "ชุมพลบุรี",
    subdistrict: "ชุมพลบุรี",
    zipcode: 32190,
    latitude: 15.377,
    longitude: 103.411,
  },
  {
    province: "สุรินทร์",
    district: "ชุมพลบุรี",
    subdistrict: "นาหนองไผ่",
    zipcode: 32190,
    latitude: 15.376,
    longitude: 103.487,
  },
  {
    province: "สุรินทร์",
    district: "ชุมพลบุรี",
    subdistrict: "ไพรขลา",
    zipcode: 32190,
    latitude: 15.395,
    longitude: 103.556,
  },
  {
    province: "สุรินทร์",
    district: "ชุมพลบุรี",
    subdistrict: "ศรีณรงค์",
    zipcode: 32190,
    latitude: 15.373,
    longitude: 103.338,
  },
  {
    province: "สุรินทร์",
    district: "ชุมพลบุรี",
    subdistrict: "ยะวึก",
    zipcode: 32190,
    latitude: 15.359,
    longitude: 103.23,
  },
  {
    province: "สุรินทร์",
    district: "ชุมพลบุรี",
    subdistrict: "เมืองบัว",
    zipcode: 32190,
    latitude: 15.429,
    longitude: 103.205,
  },
  {
    province: "สุรินทร์",
    district: "ชุมพลบุรี",
    subdistrict: "สระขุด",
    zipcode: 32190,
    latitude: 15.44,
    longitude: 103.15,
  },
  {
    province: "สุรินทร์",
    district: "ชุมพลบุรี",
    subdistrict: "กระเบื้อง",
    zipcode: 32190,
    latitude: 15.359,
    longitude: 103.291,
  },
  {
    province: "สุรินทร์",
    district: "ชุมพลบุรี",
    subdistrict: "หนองเรือ",
    zipcode: 32190,
    latitude: 15.312,
    longitude: 103.557,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "ท่าตูม",
    zipcode: 32120,
    latitude: 15.295,
    longitude: 103.639,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "กระโพ",
    zipcode: 32120,
    latitude: 15.245,
    longitude: 103.492,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "พรมเทพ",
    zipcode: 32120,
    latitude: 15.351,
    longitude: 103.622,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "โพนครก",
    zipcode: 32120,
    latitude: 15.401,
    longitude: 103.725,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "เมืองแก",
    zipcode: 32120,
    latitude: 15.221,
    longitude: 103.67,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "บะ",
    zipcode: 32120,
    latitude: 15.242,
    longitude: 103.569,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "หนองบัว",
    zipcode: 32120,
    latitude: 15.327,
    longitude: 103.742,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "บัวโคก",
    zipcode: 32120,
    latitude: 15.228,
    longitude: 103.71,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "หนองเมธี",
    zipcode: 32120,
    latitude: 15.232,
    longitude: 103.615,
  },
  {
    province: "สุรินทร์",
    district: "ท่าตูม",
    subdistrict: "ทุ่งกุลา",
    zipcode: 32120,
    latitude: 15.429,
    longitude: 103.62,
  },
  {
    province: "สุรินทร์",
    district: "จอมพระ",
    subdistrict: "จอมพระ",
    zipcode: 32180,
    latitude: 15.107,
    longitude: 103.599,
  },
  {
    province: "สุรินทร์",
    district: "จอมพระ",
    subdistrict: "เมืองลีง",
    zipcode: 32180,
    latitude: 15.17,
    longitude: 103.465,
  },
  {
    province: "สุรินทร์",
    district: "จอมพระ",
    subdistrict: "กระหาด",
    zipcode: 32180,
    latitude: 15.13,
    longitude: 103.529,
  },
  {
    province: "สุรินทร์",
    district: "จอมพระ",
    subdistrict: "บุแกรง",
    zipcode: 32180,
    latitude: 15.079,
    longitude: 103.683,
  },
  {
    province: "สุรินทร์",
    district: "จอมพระ",
    subdistrict: "หนองสนิท",
    zipcode: 32180,
    latitude: 15.16,
    longitude: 103.655,
  },
  {
    province: "สุรินทร์",
    district: "จอมพระ",
    subdistrict: "บ้านผือ",
    zipcode: 32180,
    latitude: 15.123,
    longitude: 103.682,
  },
  {
    province: "สุรินทร์",
    district: "จอมพระ",
    subdistrict: "ลุ่มระวี",
    zipcode: 32180,
    latitude: 15.162,
    longitude: 103.602,
  },
  {
    province: "สุรินทร์",
    district: "จอมพระ",
    subdistrict: "ชุมแสง",
    zipcode: 32180,
    latitude: 15.178,
    longitude: 103.53,
  },
  {
    province: "สุรินทร์",
    district: "จอมพระ",
    subdistrict: "เป็นสุข",
    zipcode: 32180,
    latitude: 15.136,
    longitude: 103.565,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "กังแอน",
    zipcode: 32140,
    latitude: 14.656,
    longitude: 103.406,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "ทมอ",
    zipcode: 32140,
    latitude: 14.722,
    longitude: 103.486,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "ไพล",
    zipcode: 32140,
    latitude: 14.688,
    longitude: 103.377,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "ปรือ",
    zipcode: 32140,
    latitude: 14.645,
    longitude: 103.288,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "ทุ่งมน",
    zipcode: 32140,
    latitude: 14.72,
    longitude: 103.282,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "ตาเบา",
    zipcode: 32140,
    latitude: 14.615,
    longitude: 103.493,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "หนองใหญ่",
    zipcode: 32140,
    latitude: 14.552,
    longitude: 103.462,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "โคกยาง",
    zipcode: 32140,
    latitude: 14.673,
    longitude: 103.529,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "โคกสะอาด",
    zipcode: 32140,
    latitude: 14.541,
    longitude: 103.373,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "บ้านไทร",
    zipcode: 32140,
    latitude: 14.622,
    longitude: 103.602,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "โชคนาสาม",
    zipcode: 32140,
    latitude: 14.544,
    longitude: 103.28,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "เชื้อเพลิง",
    zipcode: 32140,
    latitude: 14.713,
    longitude: 103.418,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "ปราสาททนง",
    zipcode: 32140,
    latitude: 14.652,
    longitude: 103.356,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "ตานี",
    zipcode: 32140,
    latitude: 14.617,
    longitude: 103.258,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "บ้านพลวง",
    zipcode: 32140,
    latitude: 14.602,
    longitude: 103.408,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "กันตวจระมวล",
    zipcode: 32140,
    latitude: 14.5144967,
    longitude: 103.4962968,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "สมุด",
    zipcode: 32140,
    latitude: 14.686,
    longitude: 103.339,
  },
  {
    province: "สุรินทร์",
    district: "ปราสาท",
    subdistrict: "ประทัดบุ",
    zipcode: 32140,
    latitude: 14.727,
    longitude: 103.341,
  },
  {
    province: "สุรินทร์",
    district: "กาบเชิง",
    subdistrict: "กาบเชิง",
    zipcode: 32210,
    latitude: 14.488,
    longitude: 103.585,
  },
  {
    province: "สุรินทร์",
    district: "กาบเชิง",
    subdistrict: "คูตัน",
    zipcode: 32210,
    latitude: 14.533,
    longitude: 103.667,
  },
  {
    province: "สุรินทร์",
    district: "กาบเชิง",
    subdistrict: "ด่าน",
    zipcode: 32210,
    latitude: 14.465,
    longitude: 103.709,
  },
  {
    province: "สุรินทร์",
    district: "กาบเชิง",
    subdistrict: "แนงมุด",
    zipcode: 32210,
    latitude: 14.397,
    longitude: 103.459,
  },
  {
    province: "สุรินทร์",
    district: "กาบเชิง",
    subdistrict: "โคกตะเคียน",
    zipcode: 32210,
    latitude: 14.48,
    longitude: 103.505,
  },
  {
    province: "สุรินทร์",
    district: "กาบเชิง",
    subdistrict: "ตะเคียน",
    zipcode: 32210,
    latitude: 14.464,
    longitude: 103.632,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "รัตนบุรี",
    zipcode: 32130,
    latitude: 15.311,
    longitude: 103.843,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "ธาตุ",
    zipcode: 32130,
    latitude: 15.317,
    longitude: 103.94,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "แก",
    zipcode: 32130,
    latitude: 15.355,
    longitude: 103.808,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "ดอนแรด",
    zipcode: 32130,
    latitude: 15.369,
    longitude: 104.014,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "หนองบัวทอง",
    zipcode: 32130,
    latitude: 15.392,
    longitude: 103.933,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "หนองบัวบาน",
    zipcode: 32130,
    latitude: 15.239,
    longitude: 103.826,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "ไผ่",
    zipcode: 32130,
    latitude: 15.324,
    longitude: 103.895,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "เบิด",
    zipcode: 32130,
    latitude: 15.268,
    longitude: 103.921,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "น้ำเขียว",
    zipcode: 32130,
    latitude: 15.316,
    longitude: 103.786,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "กุดขาคีม",
    zipcode: 32130,
    latitude: 15.407,
    longitude: 103.846,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "ยางสว่าง",
    zipcode: 32130,
    latitude: 15.306,
    longitude: 103.987,
  },
  {
    province: "สุรินทร์",
    district: "รัตนบุรี",
    subdistrict: "ทับใหญ่",
    zipcode: 32130,
    latitude: 15.41,
    longitude: 103.89,
  },
  {
    province: "สุรินทร์",
    district: "สนม",
    subdistrict: "สนม",
    zipcode: 32160,
    latitude: 15.203,
    longitude: 103.761,
  },
  {
    province: "สุรินทร์",
    district: "สนม",
    subdistrict: "โพนโก",
    zipcode: 32160,
    latitude: 15.251,
    longitude: 103.766,
  },
  {
    province: "สุรินทร์",
    district: "สนม",
    subdistrict: "หนองระฆัง",
    zipcode: 32160,
    latitude: 15.152,
    longitude: 103.755,
  },
  {
    province: "สุรินทร์",
    district: "สนม",
    subdistrict: "นานวน",
    zipcode: 32160,
    latitude: 15.139,
    longitude: 103.793,
  },
  {
    province: "สุรินทร์",
    district: "สนม",
    subdistrict: "แคน",
    zipcode: 32160,
    latitude: 15.17,
    longitude: 103.828,
  },
  {
    province: "สุรินทร์",
    district: "สนม",
    subdistrict: "หัวงัว",
    zipcode: 32160,
    latitude: 15.124,
    longitude: 103.838,
  },
  {
    province: "สุรินทร์",
    district: "สนม",
    subdistrict: "หนองอียอ",
    zipcode: 32160,
    latitude: 15.157,
    longitude: 103.714,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "ระแงง",
    zipcode: 32110,
    latitude: 14.898,
    longitude: 103.824,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "ตรึม",
    zipcode: 32110,
    latitude: 15.035,
    longitude: 103.845,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "จารพัต",
    zipcode: 32110,
    latitude: 14.867,
    longitude: 103.735,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "ยาง",
    zipcode: 32110,
    latitude: 14.955,
    longitude: 103.856,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "แตล",
    zipcode: 32110,
    latitude: 15.029,
    longitude: 103.71,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "หนองบัว",
    zipcode: 32110,
    latitude: 15.083,
    longitude: 103.76,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "คาละแมะ",
    zipcode: 32110,
    latitude: 15.081,
    longitude: 103.825,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "หนองเหล็ก",
    zipcode: 32110,
    latitude: 14.812,
    longitude: 103.722,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "หนองขวาว",
    zipcode: 32110,
    latitude: 14.979,
    longitude: 103.794,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "ช่างปี่",
    zipcode: 32110,
    latitude: 14.946,
    longitude: 103.705,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "กุดหวาย",
    zipcode: 32110,
    latitude: 14.92,
    longitude: 103.868,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "ขวาวใหญ่",
    zipcode: 32110,
    latitude: 15.018,
    longitude: 103.77,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "นารุ่ง",
    zipcode: 32110,
    latitude: 14.984,
    longitude: 103.829,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "ตรมไพร",
    zipcode: 32110,
    latitude: 14.845,
    longitude: 103.785,
  },
  {
    province: "สุรินทร์",
    district: "ศีขรภูมิ",
    subdistrict: "ผักไหม",
    zipcode: 32110,
    latitude: 14.848,
    longitude: 103.83,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "สังขะ",
    zipcode: 32150,
    latitude: 14.663,
    longitude: 103.839,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "ขอนแตก",
    zipcode: 32150,
    latitude: 14.717,
    longitude: 103.932,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "ดม",
    zipcode: 32150,
    latitude: 14.575,
    longitude: 103.856,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "พระแก้ว",
    zipcode: 32150,
    latitude: 14.653,
    longitude: 103.954,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "บ้านจารย์",
    zipcode: 32150,
    latitude: 14.561,
    longitude: 103.749,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "กระเทียม",
    zipcode: 32150,
    latitude: 14.604,
    longitude: 103.673,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "สะกาด",
    zipcode: 32150,
    latitude: 14.612,
    longitude: 103.753,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "ตาตุม",
    zipcode: 32150,
    latitude: 14.449,
    longitude: 103.779,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "ทับทัน",
    zipcode: 32150,
    latitude: 14.676,
    longitude: 103.786,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "ตาคง",
    zipcode: 32150,
    latitude: 14.66,
    longitude: 104.034,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "บ้านชบ",
    zipcode: 32150,
    latitude: 14.618,
    longitude: 103.849,
  },
  {
    province: "สุรินทร์",
    district: "สังขะ",
    subdistrict: "เทพรักษา",
    zipcode: 32150,
    latitude: 14.481,
    longitude: 103.868,
  },
  {
    province: "สุรินทร์",
    district: "ลำดวน",
    subdistrict: "ลำดวน",
    zipcode: 32220,
    latitude: 14.702,
    longitude: 103.642,
  },
  {
    province: "สุรินทร์",
    district: "ลำดวน",
    subdistrict: "โชคเหนือ",
    zipcode: 32220,
    latitude: 14.738,
    longitude: 103.721,
  },
  {
    province: "สุรินทร์",
    district: "ลำดวน",
    subdistrict: "อู่โลก",
    zipcode: 32220,
    latitude: 14.684,
    longitude: 103.691,
  },
  {
    province: "สุรินทร์",
    district: "ลำดวน",
    subdistrict: "ตรำดม",
    zipcode: 32220,
    latitude: 14.763,
    longitude: 103.68,
  },
  {
    province: "สุรินทร์",
    district: "ลำดวน",
    subdistrict: "ตระเปียงเตีย",
    zipcode: 32220,
    latitude: 14.751,
    longitude: 103.77,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "สำโรงทาบ",
    zipcode: 32170,
    latitude: 14.989,
    longitude: 103.926,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "หนองไผ่ล้อม",
    zipcode: 32170,
    latitude: 15.02,
    longitude: 103.905,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "กระออม",
    zipcode: 32170,
    latitude: 15.13,
    longitude: 103.968,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "หนองฮะ",
    zipcode: 32170,
    latitude: 15.097,
    longitude: 103.911,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "ศรีสุข",
    zipcode: 32170,
    latitude: 15.07,
    longitude: 103.949,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "เกาะแก้ว",
    zipcode: 32170,
    latitude: 14.958,
    longitude: 103.952,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "หมื่นศรี",
    zipcode: 32170,
    latitude: 15.003,
    longitude: 103.968,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "เสม็จ",
    zipcode: 32170,
    latitude: 15.092,
    longitude: 103.875,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "สะโน",
    zipcode: 32170,
    latitude: 15.066,
    longitude: 103.999,
  },
  {
    province: "สุรินทร์",
    district: "สำโรงทาบ",
    subdistrict: "ประดู่",
    zipcode: 32170,
    latitude: 15.039,
    longitude: 103.952,
  },
  {
    province: "สุรินทร์",
    district: "บัวเชด",
    subdistrict: "บัวเชด",
    zipcode: 32230,
    latitude: 14.527,
    longitude: 103.942,
  },
  {
    province: "สุรินทร์",
    district: "บัวเชด",
    subdistrict: "สะเดา",
    zipcode: 32230,
    latitude: 14.532,
    longitude: 104.014,
  },
  {
    province: "สุรินทร์",
    district: "บัวเชด",
    subdistrict: "จรัส",
    zipcode: 32230,
    latitude: 14.413,
    longitude: 103.976,
  },
  {
    province: "สุรินทร์",
    district: "บัวเชด",
    subdistrict: "ตาวัง",
    zipcode: 32230,
    latitude: 14.609,
    longitude: 104.016,
  },
  {
    province: "สุรินทร์",
    district: "บัวเชด",
    subdistrict: "อาโพน",
    zipcode: 32230,
    latitude: 14.479,
    longitude: 103.921,
  },
  {
    province: "สุรินทร์",
    district: "บัวเชด",
    subdistrict: "สำเภาลูน",
    zipcode: 32230,
    latitude: 14.57,
    longitude: 103.953,
  },
  {
    province: "สุรินทร์",
    district: "พนมดงรัก",
    subdistrict: "บักได",
    zipcode: 32140,
    latitude: 14.416,
    longitude: 103.35,
  },
  {
    province: "สุรินทร์",
    district: "พนมดงรัก",
    subdistrict: "โคกกลาง",
    zipcode: 32140,
    latitude: 14.49,
    longitude: 103.249,
  },
  {
    province: "สุรินทร์",
    district: "พนมดงรัก",
    subdistrict: "จีกแดก",
    zipcode: 32140,
    latitude: 14.497,
    longitude: 103.354,
  },
  {
    province: "สุรินทร์",
    district: "พนมดงรัก",
    subdistrict: "ตาเมียง",
    zipcode: 32140,
    latitude: 14.386,
    longitude: 103.26,
  },
  {
    province: "สุรินทร์",
    district: "ศรีณรงค์",
    subdistrict: "ณรงค์",
    zipcode: 32150,
    latitude: 14.754,
    longitude: 103.882,
  },
  {
    province: "สุรินทร์",
    district: "ศรีณรงค์",
    subdistrict: "แจนแวน",
    zipcode: 32150,
    latitude: 14.797,
    longitude: 103.922,
  },
  {
    province: "สุรินทร์",
    district: "ศรีณรงค์",
    subdistrict: "ตรวจ",
    zipcode: 32150,
    latitude: 14.802,
    longitude: 103.846,
  },
  {
    province: "สุรินทร์",
    district: "ศรีณรงค์",
    subdistrict: "หนองแวง",
    zipcode: 32150,
    latitude: 14.757,
    longitude: 103.977,
  },
  {
    province: "สุรินทร์",
    district: "ศรีณรงค์",
    subdistrict: "ศรีสุข",
    zipcode: 32150,
    latitude: 14.738,
    longitude: 103.818,
  },
  {
    province: "สุรินทร์",
    district: "เขวาสินรินทร์",
    subdistrict: "เขวาสินรินทร์",
    zipcode: 32000,
    latitude: 15.009,
    longitude: 103.582,
  },
  {
    province: "สุรินทร์",
    district: "เขวาสินรินทร์",
    subdistrict: "บึง",
    zipcode: 32000,
    latitude: 15.06,
    longitude: 103.61,
  },
  {
    province: "สุรินทร์",
    district: "เขวาสินรินทร์",
    subdistrict: "ตากูก",
    zipcode: 32000,
    latitude: 15.026,
    longitude: 103.644,
  },
  {
    province: "สุรินทร์",
    district: "เขวาสินรินทร์",
    subdistrict: "ปราสาททอง",
    zipcode: 32000,
    latitude: 14.976,
    longitude: 103.648,
  },
  {
    province: "สุรินทร์",
    district: "เขวาสินรินทร์",
    subdistrict: "บ้านแร่",
    zipcode: 32000,
    latitude: 14.966,
    longitude: 103.576,
  },
  {
    province: "สุรินทร์",
    district: "โนนนารายณ์",
    subdistrict: "หนองหลวง",
    zipcode: 32130,
    latitude: 15.228,
    longitude: 103.927,
  },
  {
    province: "สุรินทร์",
    district: "โนนนารายณ์",
    subdistrict: "คำผง",
    zipcode: 32130,
    latitude: 15.186,
    longitude: 103.942,
  },
  {
    province: "สุรินทร์",
    district: "โนนนารายณ์",
    subdistrict: "โนน",
    zipcode: 32130,
    latitude: 15.173,
    longitude: 103.911,
  },
  {
    province: "สุรินทร์",
    district: "โนนนารายณ์",
    subdistrict: "ระเวียง",
    zipcode: 32130,
    latitude: 15.251,
    longitude: 103.885,
  },
  {
    province: "สุรินทร์",
    district: "โนนนารายณ์",
    subdistrict: "หนองเทพ",
    zipcode: 32130,
    latitude: 15.14,
    longitude: 103.88,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "เมืองเหนือ",
    zipcode: 33000,
    latitude: 15.103,
    longitude: 104.336,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "เมืองใต้",
    zipcode: 33000,
    latitude: 15.0941537,
    longitude: 104.3152987,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "คูซอด",
    zipcode: 33000,
    latitude: 15.213,
    longitude: 104.278,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "ซำ",
    zipcode: 33000,
    latitude: 15.04,
    longitude: 104.332,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "จาน",
    zipcode: 33000,
    latitude: 14.989,
    longitude: 104.398,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "ตะดอบ",
    zipcode: 33000,
    latitude: 15.037,
    longitude: 104.434,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "หนองครก",
    zipcode: 33000,
    latitude: 15.078,
    longitude: 104.295,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "โพนข่า",
    zipcode: 33000,
    latitude: 15.055,
    longitude: 104.385,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "โพนค้อ",
    zipcode: 33000,
    latitude: 15.015,
    longitude: 104.369,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "โพนเขวา",
    zipcode: 33000,
    latitude: 15.1257774,
    longitude: 104.4270736,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "หญ้าปล้อง",
    zipcode: 33000,
    latitude: 15.135,
    longitude: 104.293,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "ทุ่ม",
    zipcode: 33000,
    latitude: 14.98,
    longitude: 104.336,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "หนองไฮ",
    zipcode: 33000,
    latitude: 15.007,
    longitude: 104.427,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "หนองแก้ว",
    zipcode: 33000,
    latitude: 15.096,
    longitude: 104.433,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "น้ำคำ",
    zipcode: 33000,
    latitude: 15.167,
    longitude: 104.34,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "โพธิ์",
    zipcode: 33000,
    latitude: 15.124,
    longitude: 104.394,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "หมากเขียบ",
    zipcode: 33000,
    latitude: 15.051,
    longitude: 104.258,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองศรีสะเกษ",
    subdistrict: "หนองไผ่",
    zipcode: 33000,
    latitude: 15.143,
    longitude: 104.271,
  },
  {
    province: "ศรีสะเกษ",
    district: "ยางชุมน้อย",
    subdistrict: "ยางชุมน้อย",
    zipcode: 33190,
    latitude: 15.26,
    longitude: 104.401,
  },
  {
    province: "ศรีสะเกษ",
    district: "ยางชุมน้อย",
    subdistrict: "ลิ้นฟ้า",
    zipcode: 33190,
    latitude: 15.18,
    longitude: 104.422,
  },
  {
    province: "ศรีสะเกษ",
    district: "ยางชุมน้อย",
    subdistrict: "คอนกาม",
    zipcode: 33190,
    latitude: 15.223,
    longitude: 104.36,
  },
  {
    province: "ศรีสะเกษ",
    district: "ยางชุมน้อย",
    subdistrict: "โนนคูณ",
    zipcode: 33190,
    latitude: 15.302,
    longitude: 104.354,
  },
  {
    province: "ศรีสะเกษ",
    district: "ยางชุมน้อย",
    subdistrict: "กุดเมืองฮาม",
    zipcode: 33190,
    latitude: 15.235,
    longitude: 104.319,
  },
  {
    province: "ศรีสะเกษ",
    district: "ยางชุมน้อย",
    subdistrict: "บึงบอน",
    zipcode: 33190,
    latitude: 15.251,
    longitude: 104.444,
  },
  {
    province: "ศรีสะเกษ",
    district: "ยางชุมน้อย",
    subdistrict: "ยางชุมใหญ่",
    zipcode: 33190,
    latitude: 15.212,
    longitude: 104.413,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "ดูน",
    zipcode: 33130,
    latitude: 15.113,
    longitude: 104.578,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "โนนสัง",
    zipcode: 33130,
    latitude: 15.1,
    longitude: 104.642,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "หนองหัวช้าง",
    zipcode: 33130,
    latitude: 15.049,
    longitude: 104.643,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "ยาง",
    zipcode: 33130,
    latitude: 15.072,
    longitude: 104.526,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "หนองแวง",
    zipcode: 33130,
    latitude: 15.194,
    longitude: 104.658,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "หนองแก้ว",
    zipcode: 33130,
    latitude: 15.143,
    longitude: 104.702,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "ทาม",
    zipcode: 33130,
    latitude: 15.232,
    longitude: 104.616,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "ละทาย",
    zipcode: 33130,
    latitude: 15.182,
    longitude: 104.583,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "เมืองน้อย",
    zipcode: 33130,
    latitude: 15.203,
    longitude: 104.547,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "อีปาด",
    zipcode: 33130,
    latitude: 15.223,
    longitude: 104.5,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "บัวน้อย",
    zipcode: 33130,
    latitude: 15.173,
    longitude: 104.478,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "หนองบัว",
    zipcode: 33130,
    latitude: 15.12,
    longitude: 104.525,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "ดู่",
    zipcode: 33130,
    latitude: 15.08,
    longitude: 104.476,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "ผักแพว",
    zipcode: 33130,
    latitude: 15.034,
    longitude: 104.537,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "จาน",
    zipcode: 33130,
    latitude: 14.995,
    longitude: 104.494,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรารมย์",
    subdistrict: "คำเนียม",
    zipcode: 33130,
    latitude: 15.066,
    longitude: 104.583,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "บึงมะลู",
    zipcode: 33110,
    latitude: 14.535,
    longitude: 104.714,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "กุดเสลา",
    zipcode: 33110,
    latitude: 14.679,
    longitude: 104.833,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "เมือง",
    zipcode: 33110,
    latitude: 14.582,
    longitude: 104.67,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "สังเม็ก",
    zipcode: 33110,
    latitude: 14.643,
    longitude: 104.729,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "น้ำอ้อม",
    zipcode: 33110,
    latitude: 14.66,
    longitude: 104.657,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "ละลาย",
    zipcode: 33110,
    latitude: 14.444,
    longitude: 104.547,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "รุง",
    zipcode: 33110,
    latitude: 14.48,
    longitude: 104.63,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "ตระกาจ",
    zipcode: 33110,
    latitude: 14.712,
    longitude: 104.596,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "จานใหญ่",
    zipcode: 33110,
    latitude: 14.724,
    longitude: 104.655,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "ภูเงิน",
    zipcode: 33110,
    latitude: 14.745,
    longitude: 104.571,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "ชำ",
    zipcode: 33110,
    latitude: 14.543,
    longitude: 104.575,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "กระแชง",
    zipcode: 33110,
    latitude: 14.65,
    longitude: 104.566,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "โนนสำราญ",
    zipcode: 33110,
    latitude: 14.534,
    longitude: 104.8,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "หนองหญ้าลาด",
    zipcode: 33110,
    latitude: 14.654,
    longitude: 104.621,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "เสาธงชัย",
    zipcode: 33110,
    latitude: 14.447,
    longitude: 104.685,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "ขนุน",
    zipcode: 33110,
    latitude: 14.602,
    longitude: 104.798,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "สวนกล้วย",
    zipcode: 33110,
    latitude: 14.682,
    longitude: 104.777,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "เวียงเหนือ",
    zipcode: 33110,
    latitude: 14.637,
    longitude: 104.686,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "ทุ่งใหญ่",
    zipcode: 33110,
    latitude: 14.59,
    longitude: 104.591,
  },
  {
    province: "ศรีสะเกษ",
    district: "กันทรลักษ์",
    subdistrict: "ภูผาหมอก",
    zipcode: 33110,
    latitude: 14.466,
    longitude: 104.809,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "กันทรารมย์",
    zipcode: 33140,
    latitude: 14.717,
    longitude: 104.07,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "จะกง",
    zipcode: 33140,
    latitude: 14.821,
    longitude: 104.253,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ใจดี",
    zipcode: 33140,
    latitude: 14.753,
    longitude: 104.15,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ดองกำเม็ด",
    zipcode: 33140,
    latitude: 14.775,
    longitude: 104.186,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "โสน",
    zipcode: 33140,
    latitude: 14.657,
    longitude: 104.104,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ปรือใหญ่",
    zipcode: 33140,
    latitude: 14.601,
    longitude: 104.246,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "สะเดาใหญ่",
    zipcode: 33140,
    latitude: 14.712,
    longitude: 104.268,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ตาอุด",
    zipcode: 33140,
    latitude: 14.669,
    longitude: 104.271,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ห้วยเหนือ",
    zipcode: 33140,
    latitude: 14.72,
    longitude: 104.205,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ห้วยใต้",
    zipcode: 33140,
    latitude: 14.662,
    longitude: 104.179,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "หัวเสือ",
    zipcode: 33140,
    latitude: 14.834,
    longitude: 104.186,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ตะเคียน",
    zipcode: 33140,
    latitude: 14.773,
    longitude: 104.248,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "นิคมพัฒนา",
    zipcode: 33140,
    latitude: 14.644,
    longitude: 104.234,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "โคกเพชร",
    zipcode: 33140,
    latitude: 14.804,
    longitude: 104.149,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ปราสาท",
    zipcode: 33140,
    latitude: 14.727,
    longitude: 104.016,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "สำโรงตาเจ็น",
    zipcode: 33140,
    latitude: 14.872,
    longitude: 104.17,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ห้วยสำราญ",
    zipcode: 33140,
    latitude: 14.715,
    longitude: 104.115,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "กฤษณา",
    zipcode: 33140,
    latitude: 14.874,
    longitude: 104.255,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ลมศักดิ์",
    zipcode: 33140,
    latitude: 14.762,
    longitude: 104.283,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "หนองฉลอง",
    zipcode: 33140,
    latitude: 14.684,
    longitude: 104.227,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ศรีตระกูล",
    zipcode: 33140,
    latitude: 14.639,
    longitude: 104.28,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุขันธ์",
    subdistrict: "ศรีสะอาด",
    zipcode: 33140,
    latitude: 14.754,
    longitude: 104.102,
  },
  {
    province: "ศรีสะเกษ",
    district: "ไพรบึง",
    subdistrict: "ไพรบึง",
    zipcode: 33180,
    latitude: 14.744,
    longitude: 104.373,
  },
  {
    province: "ศรีสะเกษ",
    district: "ไพรบึง",
    subdistrict: "ดินแดง",
    zipcode: 33180,
    latitude: 14.801,
    longitude: 104.308,
  },
  {
    province: "ศรีสะเกษ",
    district: "ไพรบึง",
    subdistrict: "ปราสาทเยอ",
    zipcode: 33180,
    latitude: 14.811,
    longitude: 104.364,
  },
  {
    province: "ศรีสะเกษ",
    district: "ไพรบึง",
    subdistrict: "สำโรงพลัน",
    zipcode: 33180,
    latitude: 14.7,
    longitude: 104.342,
  },
  {
    province: "ศรีสะเกษ",
    district: "ไพรบึง",
    subdistrict: "สุขสวัสดิ์",
    zipcode: 33180,
    latitude: 14.856,
    longitude: 104.359,
  },
  {
    province: "ศรีสะเกษ",
    district: "ไพรบึง",
    subdistrict: "โนนปูน",
    zipcode: 33180,
    latitude: 14.857,
    longitude: 104.303,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "พิมาย",
    zipcode: 33170,
    latitude: 14.835,
    longitude: 104.032,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "กู่",
    zipcode: 33170,
    latitude: 14.853,
    longitude: 103.957,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "หนองเชียงทูน",
    zipcode: 33170,
    latitude: 14.875,
    longitude: 104.101,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "ตูม",
    zipcode: 33170,
    latitude: 14.903,
    longitude: 104.009,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "สมอ",
    zipcode: 33170,
    latitude: 14.82,
    longitude: 104.094,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "โพธิ์ศรี",
    zipcode: 33170,
    latitude: 14.78,
    longitude: 104.082,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "สำโรงปราสาท",
    zipcode: 33170,
    latitude: 14.917,
    longitude: 104.081,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "ดู่",
    zipcode: 33170,
    latitude: 14.782,
    longitude: 104.027,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "สวาย",
    zipcode: 33170,
    latitude: 14.913,
    longitude: 103.962,
  },
  {
    province: "ศรีสะเกษ",
    district: "ปรางค์กู่",
    subdistrict: "พิมายเหนือ",
    zipcode: 33170,
    latitude: 14.881,
    longitude: 104.042,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "สิ",
    zipcode: 33150,
    latitude: 14.625,
    longitude: 104.436,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "บักดอง",
    zipcode: 33150,
    latitude: 14.476,
    longitude: 104.444,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "พราน",
    zipcode: 33150,
    latitude: 14.592,
    longitude: 104.501,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "โพธิ์วงศ์",
    zipcode: 33150,
    latitude: 14.666,
    longitude: 104.458,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "ไพร",
    zipcode: 33150,
    latitude: 14.706,
    longitude: 104.454,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "กระหวัน",
    zipcode: 33150,
    latitude: 14.673,
    longitude: 104.414,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "ขุนหาญ",
    zipcode: 33150,
    latitude: 14.635,
    longitude: 104.371,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "โนนสูง",
    zipcode: 33150,
    latitude: 14.581,
    longitude: 104.416,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "กันทรอม",
    zipcode: 33150,
    latitude: 14.575,
    longitude: 104.353,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "ภูฝ้าย",
    zipcode: 33150,
    latitude: 14.683,
    longitude: 104.51,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "โพธิ์กระสังข์",
    zipcode: 33150,
    latitude: 14.628,
    longitude: 104.327,
  },
  {
    province: "ศรีสะเกษ",
    district: "ขุนหาญ",
    subdistrict: "ห้วยจันทร์",
    zipcode: 33150,
    latitude: 14.465,
    longitude: 104.353,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "เมืองคง",
    zipcode: 33160,
    latitude: 15.356,
    longitude: 104.159,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "เมืองแคน",
    zipcode: 33160,
    latitude: 15.351,
    longitude: 104.214,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "หนองแค",
    zipcode: 33160,
    latitude: 15.38,
    longitude: 104.106,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "จิกสังข์ทอง",
    zipcode: 33160,
    latitude: 15.435,
    longitude: 104.125,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "ด่าน",
    zipcode: 33160,
    latitude: 15.421,
    longitude: 104.026,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "ดู่",
    zipcode: 33160,
    latitude: 15.416,
    longitude: 104.162,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "หนองอึ่ง",
    zipcode: 33160,
    latitude: 15.278,
    longitude: 104.176,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "บัวหุ่ง",
    zipcode: 33160,
    latitude: 15.321,
    longitude: 104.105,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "ไผ่",
    zipcode: 33160,
    latitude: 15.332,
    longitude: 104.257,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "ส้มป่อย",
    zipcode: 33160,
    latitude: 15.283,
    longitude: 104.232,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "หนองหมี",
    zipcode: 33160,
    latitude: 15.271,
    longitude: 104.293,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "หว้านคำ",
    zipcode: 33160,
    latitude: 15.407,
    longitude: 104.2,
  },
  {
    province: "ศรีสะเกษ",
    district: "ราษีไศล",
    subdistrict: "สร้างปี่",
    zipcode: 33160,
    latitude: 15.317,
    longitude: 104.305,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "กำแพง",
    zipcode: 33120,
    latitude: 15.1,
    longitude: 104.15,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "อี่หล่ำ",
    zipcode: 33120,
    latitude: 15.198,
    longitude: 104.188,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "ก้านเหลือง",
    zipcode: 33120,
    latitude: 15.052,
    longitude: 104.143,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "ทุ่งไชย",
    zipcode: 33120,
    latitude: 15.016,
    longitude: 104.109,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "สำโรง",
    zipcode: 33120,
    latitude: 15.146,
    longitude: 104.148,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "แขม",
    zipcode: 33120,
    latitude: 15.198,
    longitude: 104.14,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "หนองไฮ",
    zipcode: 33120,
    latitude: 15.145,
    longitude: 104.093,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "ขะยูง",
    zipcode: 33120,
    latitude: 15.126,
    longitude: 104.238,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "ตาเกษ",
    zipcode: 33120,
    latitude: 15.143,
    longitude: 104.21,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "หัวช้าง",
    zipcode: 33120,
    latitude: 15.251,
    longitude: 104.132,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "รังแร้ง",
    zipcode: 33120,
    latitude: 15.223,
    longitude: 104.228,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "แต้",
    zipcode: 33120,
    latitude: 15.12,
    longitude: 104.178,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "แข้",
    zipcode: 33120,
    latitude: 15.067,
    longitude: 104.176,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "โพธิ์ชัย",
    zipcode: 33120,
    latitude: 15.173,
    longitude: 104.227,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "ปะอาว",
    zipcode: 33120,
    latitude: 15.101,
    longitude: 104.214,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "หนองห้าง",
    zipcode: 33120,
    latitude: 15.098,
    longitude: 104.088,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "สระกำแพงใหญ่",
    zipcode: 33120,
    latitude: 15.093,
    longitude: 104.128,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "โคกหล่าม",
    zipcode: 33120,
    latitude: 15.22,
    longitude: 104.171,
  },
  {
    province: "ศรีสะเกษ",
    district: "อุทุมพรพิสัย",
    subdistrict: "โคกจาน",
    zipcode: 33120,
    latitude: 14.966,
    longitude: 104.104,
  },
  {
    province: "ศรีสะเกษ",
    district: "บึงบูรพ์",
    subdistrict: "เป๊าะ",
    zipcode: 33220,
    latitude: 15.297,
    longitude: 104.06,
  },
  {
    province: "ศรีสะเกษ",
    district: "บึงบูรพ์",
    subdistrict: "บึงบูรพ์",
    zipcode: 33220,
    latitude: 15.328,
    longitude: 104.067,
  },
  {
    province: "ศรีสะเกษ",
    district: "ห้วยทับทัน",
    subdistrict: "ห้วยทับทัน",
    zipcode: 33210,
    latitude: 15.065,
    longitude: 104.044,
  },
  {
    province: "ศรีสะเกษ",
    district: "ห้วยทับทัน",
    subdistrict: "เมืองหลวง",
    zipcode: 33210,
    latitude: 15.021,
    longitude: 104.047,
  },
  {
    province: "ศรีสะเกษ",
    district: "ห้วยทับทัน",
    subdistrict: "กล้วยกว้าง",
    zipcode: 33210,
    latitude: 14.966,
    longitude: 104.069,
  },
  {
    province: "ศรีสะเกษ",
    district: "ห้วยทับทัน",
    subdistrict: "ผักไหม",
    zipcode: 33210,
    latitude: 14.947,
    longitude: 104.013,
  },
  {
    province: "ศรีสะเกษ",
    district: "ห้วยทับทัน",
    subdistrict: "จานแสนไชย",
    zipcode: 33210,
    latitude: 14.981,
    longitude: 104.019,
  },
  {
    province: "ศรีสะเกษ",
    district: "ห้วยทับทัน",
    subdistrict: "ปราสาท",
    zipcode: 33210,
    latitude: 15.11,
    longitude: 104.027,
  },
  {
    province: "ศรีสะเกษ",
    district: "โนนคูณ",
    subdistrict: "โนนค้อ",
    zipcode: 33250,
    latitude: 14.899,
    longitude: 104.71,
  },
  {
    province: "ศรีสะเกษ",
    district: "โนนคูณ",
    subdistrict: "บก",
    zipcode: 33250,
    latitude: 14.976,
    longitude: 104.64,
  },
  {
    province: "ศรีสะเกษ",
    district: "โนนคูณ",
    subdistrict: "โพธิ์",
    zipcode: 33250,
    latitude: 14.923,
    longitude: 104.614,
  },
  {
    province: "ศรีสะเกษ",
    district: "โนนคูณ",
    subdistrict: "หนองกุง",
    zipcode: 33250,
    latitude: 14.861,
    longitude: 104.774,
  },
  {
    province: "ศรีสะเกษ",
    district: "โนนคูณ",
    subdistrict: "เหล่ากวาง",
    zipcode: 33250,
    latitude: 14.88,
    longitude: 104.638,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศรีรัตนะ",
    subdistrict: "ศรีแก้ว",
    zipcode: 33240,
    latitude: 14.766,
    longitude: 104.476,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศรีรัตนะ",
    subdistrict: "พิงพวย",
    zipcode: 33240,
    latitude: 14.744,
    longitude: 104.453,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศรีรัตนะ",
    subdistrict: "สระเยาว์",
    zipcode: 33240,
    latitude: 14.785,
    longitude: 104.515,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศรีรัตนะ",
    subdistrict: "ตูม",
    zipcode: 33240,
    latitude: 14.866,
    longitude: 104.488,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศรีรัตนะ",
    subdistrict: "เสื่องข้าว",
    zipcode: 33240,
    latitude: 14.813,
    longitude: 104.555,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศรีรัตนะ",
    subdistrict: "ศรีโนนงาม",
    zipcode: 33240,
    latitude: 14.806,
    longitude: 104.419,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศรีรัตนะ",
    subdistrict: "สะพุง",
    zipcode: 33240,
    latitude: 14.828,
    longitude: 104.474,
  },
  {
    province: "ศรีสะเกษ",
    district: "น้ำเกลี้ยง",
    subdistrict: "น้ำเกลี้ยง",
    zipcode: 33130,
    latitude: 14.917,
    longitude: 104.55,
  },
  {
    province: "ศรีสะเกษ",
    district: "น้ำเกลี้ยง",
    subdistrict: "ละเอาะ",
    zipcode: 33130,
    latitude: 14.93,
    longitude: 104.451,
  },
  {
    province: "ศรีสะเกษ",
    district: "น้ำเกลี้ยง",
    subdistrict: "ตองปิด",
    zipcode: 33130,
    latitude: 14.964,
    longitude: 104.479,
  },
  {
    province: "ศรีสะเกษ",
    district: "น้ำเกลี้ยง",
    subdistrict: "เขิน",
    zipcode: 33130,
    latitude: 14.852,
    longitude: 104.58,
  },
  {
    province: "ศรีสะเกษ",
    district: "น้ำเกลี้ยง",
    subdistrict: "รุ่งระวี",
    zipcode: 33130,
    latitude: 14.876,
    longitude: 104.525,
  },
  {
    province: "ศรีสะเกษ",
    district: "น้ำเกลี้ยง",
    subdistrict: "คูบ",
    zipcode: 33130,
    latitude: 14.971,
    longitude: 104.569,
  },
  {
    province: "ศรีสะเกษ",
    district: "วังหิน",
    subdistrict: "บุสูง",
    zipcode: 33270,
    latitude: 14.957,
    longitude: 104.209,
  },
  {
    province: "ศรีสะเกษ",
    district: "วังหิน",
    subdistrict: "ธาตุ",
    zipcode: 33270,
    latitude: 14.988,
    longitude: 104.186,
  },
  {
    province: "ศรีสะเกษ",
    district: "วังหิน",
    subdistrict: "ดวนใหญ่",
    zipcode: 33270,
    latitude: 14.945,
    longitude: 104.271,
  },
  {
    province: "ศรีสะเกษ",
    district: "วังหิน",
    subdistrict: "บ่อแก้ว",
    zipcode: 33270,
    latitude: 14.992,
    longitude: 104.27,
  },
  {
    province: "ศรีสะเกษ",
    district: "วังหิน",
    subdistrict: "ศรีสำราญ",
    zipcode: 33270,
    latitude: 14.909,
    longitude: 104.155,
  },
  {
    province: "ศรีสะเกษ",
    district: "วังหิน",
    subdistrict: "ทุ่งสว่าง",
    zipcode: 33270,
    latitude: 15.018,
    longitude: 104.224,
  },
  {
    province: "ศรีสะเกษ",
    district: "วังหิน",
    subdistrict: "วังหิน",
    zipcode: 33270,
    latitude: 14.92,
    longitude: 104.24,
  },
  {
    province: "ศรีสะเกษ",
    district: "วังหิน",
    subdistrict: "โพนยาง",
    zipcode: 33270,
    latitude: 14.926,
    longitude: 104.204,
  },
  {
    province: "ศรีสะเกษ",
    district: "ภูสิงห์",
    subdistrict: "โคกตาล",
    zipcode: 33140,
    latitude: 14.549,
    longitude: 104.203,
  },
  {
    province: "ศรีสะเกษ",
    district: "ภูสิงห์",
    subdistrict: "ห้วยตามอญ",
    zipcode: 33140,
    latitude: 14.525,
    longitude: 104.077,
  },
  {
    province: "ศรีสะเกษ",
    district: "ภูสิงห์",
    subdistrict: "ห้วยตึ๊กชู",
    zipcode: 33140,
    latitude: 14.566,
    longitude: 104.12,
  },
  {
    province: "ศรีสะเกษ",
    district: "ภูสิงห์",
    subdistrict: "ละลม",
    zipcode: 33140,
    latitude: 14.588,
    longitude: 104.079,
  },
  {
    province: "ศรีสะเกษ",
    district: "ภูสิงห์",
    subdistrict: "ตะเคียนราม",
    zipcode: 33140,
    latitude: 14.606,
    longitude: 104.169,
  },
  {
    province: "ศรีสะเกษ",
    district: "ภูสิงห์",
    subdistrict: "ดงรัก",
    zipcode: 33140,
    latitude: 14.44,
    longitude: 104.189,
  },
  {
    province: "ศรีสะเกษ",
    district: "ภูสิงห์",
    subdistrict: "ไพรพัฒนา",
    zipcode: 33140,
    latitude: 14.426,
    longitude: 104.085,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองจันทร์",
    subdistrict: "เมืองจันทร์",
    zipcode: 33120,
    latitude: 15.155,
    longitude: 104.011,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองจันทร์",
    subdistrict: "ตาโกน",
    zipcode: 33120,
    latitude: 15.205,
    longitude: 103.996,
  },
  {
    province: "ศรีสะเกษ",
    district: "เมืองจันทร์",
    subdistrict: "หนองใหญ่",
    zipcode: 33120,
    latitude: 15.198,
    longitude: 104.035,
  },
  {
    province: "ศรีสะเกษ",
    district: "เบญจลักษ์",
    subdistrict: "เสียว",
    zipcode: 33110,
    latitude: 14.799,
    longitude: 104.668,
  },
  {
    province: "ศรีสะเกษ",
    district: "เบญจลักษ์",
    subdistrict: "หนองหว้า",
    zipcode: 33110,
    latitude: 14.754,
    longitude: 104.722,
  },
  {
    province: "ศรีสะเกษ",
    district: "เบญจลักษ์",
    subdistrict: "หนองงูเหลือม",
    zipcode: 33110,
    latitude: 14.836,
    longitude: 104.653,
  },
  {
    province: "ศรีสะเกษ",
    district: "เบญจลักษ์",
    subdistrict: "หนองฮาง",
    zipcode: 33110,
    latitude: 14.778,
    longitude: 104.594,
  },
  {
    province: "ศรีสะเกษ",
    district: "เบญจลักษ์",
    subdistrict: "ท่าคล้อ",
    zipcode: 33110,
    latitude: 14.761,
    longitude: 104.783,
  },
  {
    province: "ศรีสะเกษ",
    district: "พยุห์",
    subdistrict: "พยุห์",
    zipcode: 33230,
    latitude: 14.935,
    longitude: 104.387,
  },
  {
    province: "ศรีสะเกษ",
    district: "พยุห์",
    subdistrict: "พรหมสวัสดิ์",
    zipcode: 33230,
    latitude: 14.852,
    longitude: 104.427,
  },
  {
    province: "ศรีสะเกษ",
    district: "พยุห์",
    subdistrict: "ตำแย",
    zipcode: 33230,
    latitude: 14.918,
    longitude: 104.422,
  },
  {
    province: "ศรีสะเกษ",
    district: "พยุห์",
    subdistrict: "โนนเพ็ก",
    zipcode: 33230,
    latitude: 14.933,
    longitude: 104.327,
  },
  {
    province: "ศรีสะเกษ",
    district: "พยุห์",
    subdistrict: "หนองค้า",
    zipcode: 33230,
    latitude: 14.898,
    longitude: 104.334,
  },
  {
    province: "ศรีสะเกษ",
    district: "โพธิ์ศรีสุวรรณ",
    subdistrict: "โดด",
    zipcode: 33120,
    latitude: 15.204,
    longitude: 104.078,
  },
  {
    province: "ศรีสะเกษ",
    district: "โพธิ์ศรีสุวรรณ",
    subdistrict: "เสียว",
    zipcode: 33120,
    latitude: 15.251,
    longitude: 104.043,
  },
  {
    province: "ศรีสะเกษ",
    district: "โพธิ์ศรีสุวรรณ",
    subdistrict: "หนองม้า",
    zipcode: 33120,
    latitude: 15.254,
    longitude: 104.083,
  },
  {
    province: "ศรีสะเกษ",
    district: "โพธิ์ศรีสุวรรณ",
    subdistrict: "ผือใหญ่",
    zipcode: 33120,
    latitude: 15.233,
    longitude: 104.111,
  },
  {
    province: "ศรีสะเกษ",
    district: "โพธิ์ศรีสุวรรณ",
    subdistrict: "อีเซ",
    zipcode: 33120,
    latitude: 15.269,
    longitude: 104.009,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศิลาลาด",
    subdistrict: "กุง",
    zipcode: 33160,
    latitude: 15.474,
    longitude: 104.084,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศิลาลาด",
    subdistrict: "คลีกลิ้ง",
    zipcode: 33160,
    latitude: 15.529,
    longitude: 104.109,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศิลาลาด",
    subdistrict: "หนองบัวดง",
    zipcode: 33160,
    latitude: 15.458,
    longitude: 104.046,
  },
  {
    province: "ศรีสะเกษ",
    district: "ศิลาลาด",
    subdistrict: "โจดม่วง",
    zipcode: 33160,
    latitude: 15.473,
    longitude: 104.139,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "ในเมือง",
    zipcode: 34000,
    latitude: 15.232,
    longitude: 104.859,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "หัวเรือ",
    zipcode: 34000,
    latitude: 15.367,
    longitude: 104.845,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "หนองขอน",
    zipcode: 34000,
    latitude: 15.32,
    longitude: 104.766,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "ปทุม",
    zipcode: 34000,
    latitude: 15.244,
    longitude: 104.894,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "ขามใหญ่",
    zipcode: 34000,
    latitude: 15.302,
    longitude: 104.836,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "แจระแม",
    zipcode: 34000,
    latitude: 15.251,
    longitude: 104.802,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "หนองบ่อ",
    zipcode: 34000,
    latitude: 15.239,
    longitude: 104.727,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "ไร่น้อย",
    zipcode: 34000,
    latitude: 15.314,
    longitude: 104.905,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "กระโสบ",
    zipcode: 34000,
    latitude: 15.309,
    longitude: 104.97,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "กุดลาด",
    zipcode: 34000,
    latitude: 15.291,
    longitude: 105,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "ขี้เหล็ก",
    zipcode: 34000,
    latitude: 15.351,
    longitude: 104.815,
  },
  {
    province: "อุบลราชธานี",
    district: "เมืองอุบลราชธานี",
    subdistrict: "ปะอาว",
    zipcode: 34000,
    latitude: 15.365,
    longitude: 104.716,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "นาคำ",
    zipcode: 34250,
    latitude: 15.507,
    longitude: 105.327,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "แก้งกอก",
    zipcode: 34250,
    latitude: 15.478,
    longitude: 105.237,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "เอือดใหญ่",
    zipcode: 34250,
    latitude: 15.52,
    longitude: 105.219,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "วาริน",
    zipcode: 34250,
    latitude: 15.415,
    longitude: 105.26,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "ลาดควาย",
    zipcode: 34250,
    latitude: 15.564,
    longitude: 105.329,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "สงยาง",
    zipcode: 34250,
    latitude: 15.623,
    longitude: 105.286,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "ตะบ่าย",
    zipcode: 34250,
    latitude: 15.56,
    longitude: 105.227,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "คำไหล",
    zipcode: 34250,
    latitude: 15.409,
    longitude: 105.346,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "หนามแท่ง",
    zipcode: 34250,
    latitude: 15.695,
    longitude: 105.565,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "นาเลิน",
    zipcode: 34250,
    latitude: 15.607,
    longitude: 105.383,
  },
  {
    province: "อุบลราชธานี",
    district: "ศรีเมืองใหม่",
    subdistrict: "ดอนใหญ่",
    zipcode: 34250,
    latitude: 15.447,
    longitude: 105.316,
  },
  {
    province: "อุบลราชธานี",
    district: "โขงเจียม",
    subdistrict: "โขงเจียม",
    zipcode: 34220,
    latitude: 15.296,
    longitude: 105.452,
  },
  {
    province: "อุบลราชธานี",
    district: "โขงเจียม",
    subdistrict: "ห้วยยาง",
    zipcode: 34220,
    latitude: 15.467,
    longitude: 105.455,
  },
  {
    province: "อุบลราชธานี",
    district: "โขงเจียม",
    subdistrict: "นาโพธิ์กลาง",
    zipcode: 34220,
    latitude: 15.553,
    longitude: 105.557,
  },
  {
    province: "อุบลราชธานี",
    district: "โขงเจียม",
    subdistrict: "หนองแสงใหญ่",
    zipcode: 34220,
    latitude: 15.339,
    longitude: 105.389,
  },
  {
    province: "อุบลราชธานี",
    district: "โขงเจียม",
    subdistrict: "ห้วยไผ่",
    zipcode: 34220,
    latitude: 15.42,
    longitude: 105.551,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "เขื่องใน",
    zipcode: 34150,
    latitude: 15.371,
    longitude: 104.556,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "สร้างถ่อ",
    zipcode: 34150,
    latitude: 15.367,
    longitude: 104.503,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "ค้อทอง",
    zipcode: 34150,
    latitude: 15.399,
    longitude: 104.429,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "ก่อเอ้",
    zipcode: 34150,
    latitude: 15.392,
    longitude: 104.623,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "หัวดอน",
    zipcode: 34150,
    latitude: 15.307,
    longitude: 104.69,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "ชีทวน",
    zipcode: 34150,
    latitude: 15.269,
    longitude: 104.653,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "ท่าไห",
    zipcode: 34150,
    latitude: 15.319,
    longitude: 104.602,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "นาคำใหญ่",
    zipcode: 34150,
    latitude: 15.283,
    longitude: 104.566,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "แดงหม้อ",
    zipcode: 34150,
    latitude: 15.29,
    longitude: 104.513,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "ธาตุน้อย",
    zipcode: 34150,
    latitude: 15.281,
    longitude: 104.48,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "บ้านไทย",
    zipcode: 34320,
    latitude: 15.454,
    longitude: 104.424,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "บ้านกอก",
    zipcode: 34320,
    latitude: 15.489,
    longitude: 104.464,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "กลางใหญ่",
    zipcode: 34320,
    latitude: 15.515,
    longitude: 104.407,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "โนนรัง",
    zipcode: 34320,
    latitude: 15.521,
    longitude: 104.5,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "ยางขี้นก",
    zipcode: 34150,
    latitude: 15.446,
    longitude: 104.508,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "ศรีสุข",
    zipcode: 34150,
    latitude: 15.477,
    longitude: 104.515,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "สหธาตุ",
    zipcode: 34150,
    latitude: 15.338,
    longitude: 104.459,
  },
  {
    province: "อุบลราชธานี",
    district: "เขื่องใน",
    subdistrict: "หนองเหล่า",
    zipcode: 34150,
    latitude: 15.453,
    longitude: 104.592,
  },
  {
    province: "อุบลราชธานี",
    district: "เขมราฐ",
    subdistrict: "เขมราฐ",
    zipcode: 34170,
    latitude: 16.049,
    longitude: 105.136,
  },
  {
    province: "อุบลราชธานี",
    district: "เขมราฐ",
    subdistrict: "ขามป้อม",
    zipcode: 34170,
    latitude: 15.977,
    longitude: 105.179,
  },
  {
    province: "อุบลราชธานี",
    district: "เขมราฐ",
    subdistrict: "เจียด",
    zipcode: 34170,
    latitude: 15.872,
    longitude: 105.222,
  },
  {
    province: "อุบลราชธานี",
    district: "เขมราฐ",
    subdistrict: "หนองผือ",
    zipcode: 34170,
    latitude: 15.95,
    longitude: 105.058,
  },
  {
    province: "อุบลราชธานี",
    district: "เขมราฐ",
    subdistrict: "นาแวง",
    zipcode: 34170,
    latitude: 16.011,
    longitude: 105.329,
  },
  {
    province: "อุบลราชธานี",
    district: "เขมราฐ",
    subdistrict: "แก้งเหนือ",
    zipcode: 34170,
    latitude: 15.932,
    longitude: 105.128,
  },
  {
    province: "อุบลราชธานี",
    district: "เขมราฐ",
    subdistrict: "หนองนกทา",
    zipcode: 34170,
    latitude: 16.019,
    longitude: 105.096,
  },
  {
    province: "อุบลราชธานี",
    district: "เขมราฐ",
    subdistrict: "หนองสิม",
    zipcode: 34170,
    latitude: 16.025,
    longitude: 105.037,
  },
  {
    province: "อุบลราชธานี",
    district: "เขมราฐ",
    subdistrict: "หัวนา",
    zipcode: 34170,
    latitude: 15.867,
    longitude: 105.155,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "เมืองเดช",
    zipcode: 34160,
    latitude: 14.92,
    longitude: 105.055,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "นาส่วง",
    zipcode: 34160,
    latitude: 15.012,
    longitude: 104.983,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "นาเจริญ",
    zipcode: 34160,
    latitude: 14.902,
    longitude: 104.955,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "ทุ่งเทิง",
    zipcode: 34160,
    latitude: 14.819,
    longitude: 104.846,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "สมสะอาด",
    zipcode: 34160,
    latitude: 14.824,
    longitude: 104.987,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "กุดประทาย",
    zipcode: 34160,
    latitude: 14.966,
    longitude: 105.196,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "ตบหู",
    zipcode: 34160,
    latitude: 14.702,
    longitude: 105.09,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "กลาง",
    zipcode: 34160,
    latitude: 14.797,
    longitude: 105.141,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "แก้ง",
    zipcode: 34160,
    latitude: 14.695,
    longitude: 105.014,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "ท่าโพธิ์ศรี",
    zipcode: 34160,
    latitude: 14.84,
    longitude: 105.049,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "บัวงาม",
    zipcode: 34160,
    latitude: 14.801,
    longitude: 105.218,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "คำครั่ง",
    zipcode: 34160,
    latitude: 14.889,
    longitude: 105.205,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "นากระแซง",
    zipcode: 34160,
    latitude: 14.87,
    longitude: 104.908,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "โพนงาม",
    zipcode: 34160,
    latitude: 14.904,
    longitude: 105.115,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "ป่าโมง",
    zipcode: 34160,
    latitude: 14.961,
    longitude: 104.976,
  },
  {
    province: "อุบลราชธานี",
    district: "เดชอุดม",
    subdistrict: "โนนสมบูรณ์",
    zipcode: 34160,
    latitude: 14.76,
    longitude: 105.03,
  },
  {
    province: "อุบลราชธานี",
    district: "นาจะหลวย",
    subdistrict: "นาจะหลวย",
    zipcode: 34280,
    latitude: 14.487,
    longitude: 105.261,
  },
  {
    province: "อุบลราชธานี",
    district: "นาจะหลวย",
    subdistrict: "โนนสมบูรณ์",
    zipcode: 34280,
    latitude: 14.688,
    longitude: 105.201,
  },
  {
    province: "อุบลราชธานี",
    district: "นาจะหลวย",
    subdistrict: "พรสวรรค์",
    zipcode: 34280,
    latitude: 14.627,
    longitude: 105.18,
  },
  {
    province: "อุบลราชธานี",
    district: "นาจะหลวย",
    subdistrict: "บ้านตูม",
    zipcode: 34280,
    latitude: 14.608,
    longitude: 105.241,
  },
  {
    province: "อุบลราชธานี",
    district: "นาจะหลวย",
    subdistrict: "โสกแสง",
    zipcode: 34280,
    latitude: 14.661,
    longitude: 105.258,
  },
  {
    province: "อุบลราชธานี",
    district: "นาจะหลวย",
    subdistrict: "โนนสวรรค์",
    zipcode: 34280,
    latitude: 14.659,
    longitude: 105.141,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำยืน",
    subdistrict: "โซง",
    zipcode: 34260,
    latitude: 14.46,
    longitude: 104.947,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำยืน",
    subdistrict: "ยาง",
    zipcode: 34260,
    latitude: 14.634,
    longitude: 105.011,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำยืน",
    subdistrict: "โดมประดิษฐ์",
    zipcode: 34260,
    latitude: 14.337,
    longitude: 105.059,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำยืน",
    subdistrict: "บุเปือย",
    zipcode: 34260,
    latitude: 14.538,
    longitude: 105.056,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำยืน",
    subdistrict: "สีวิเชียร",
    zipcode: 34260,
    latitude: 14.483,
    longitude: 105.04,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำยืน",
    subdistrict: "ยางใหญ่",
    zipcode: 34260,
    latitude: 14.575,
    longitude: 105.094,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำยืน",
    subdistrict: "เก่าขาม",
    zipcode: 34260,
    latitude: 14.577,
    longitude: 105.02,
  },
  {
    province: "อุบลราชธานี",
    district: "บุณฑริก",
    subdistrict: "โพนงาม",
    zipcode: 34230,
    latitude: 14.759,
    longitude: 105.464,
  },
  {
    province: "อุบลราชธานี",
    district: "บุณฑริก",
    subdistrict: "ห้วยข่า",
    zipcode: 34230,
    latitude: 14.567,
    longitude: 105.424,
  },
  {
    province: "อุบลราชธานี",
    district: "บุณฑริก",
    subdistrict: "คอแลน",
    zipcode: 34230,
    latitude: 14.867,
    longitude: 105.461,
  },
  {
    province: "อุบลราชธานี",
    district: "บุณฑริก",
    subdistrict: "นาโพธิ์",
    zipcode: 34230,
    latitude: 14.891,
    longitude: 105.294,
  },
  {
    province: "อุบลราชธานี",
    district: "บุณฑริก",
    subdistrict: "หนองสะโน",
    zipcode: 34230,
    latitude: 14.771,
    longitude: 105.288,
  },
  {
    province: "อุบลราชธานี",
    district: "บุณฑริก",
    subdistrict: "โนนค้อ",
    zipcode: 34230,
    latitude: 14.672,
    longitude: 105.307,
  },
  {
    province: "อุบลราชธานี",
    district: "บุณฑริก",
    subdistrict: "บัวงาม",
    zipcode: 34230,
    latitude: 14.741,
    longitude: 105.371,
  },
  {
    province: "อุบลราชธานี",
    district: "บุณฑริก",
    subdistrict: "บ้านแมด",
    zipcode: 34230,
    latitude: 14.906,
    longitude: 105.348,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "ขุหลุ",
    zipcode: 34130,
    latitude: 15.601,
    longitude: 105.021,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "กระเดียน",
    zipcode: 34130,
    latitude: 15.618,
    longitude: 105.089,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "เกษม",
    zipcode: 34130,
    latitude: 15.691,
    longitude: 105.049,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "กุศกร",
    zipcode: 34130,
    latitude: 15.57,
    longitude: 105.102,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "ขามเปี้ย",
    zipcode: 34130,
    latitude: 15.542,
    longitude: 104.934,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "คอนสาย",
    zipcode: 34130,
    latitude: 15.736,
    longitude: 105.128,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "โคกจาน",
    zipcode: 34130,
    latitude: 15.507,
    longitude: 105.1,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "นาพิน",
    zipcode: 34130,
    latitude: 15.562,
    longitude: 105.034,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "นาสะไม",
    zipcode: 34130,
    latitude: 15.687,
    longitude: 104.913,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "โนนกุง",
    zipcode: 34130,
    latitude: 15.659,
    longitude: 105.168,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "ตระการ",
    zipcode: 34130,
    latitude: 15.466,
    longitude: 105.05,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "ตากแดด",
    zipcode: 34130,
    latitude: 15.589,
    longitude: 105.183,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "ไหล่ทุ่ง",
    zipcode: 34130,
    latitude: 15.6,
    longitude: 104.968,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "เป้า",
    zipcode: 34130,
    latitude: 15.69,
    longitude: 104.978,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "เซเป็ด",
    zipcode: 34130,
    latitude: 15.547,
    longitude: 104.988,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "สะพือ",
    zipcode: 34130,
    latitude: 15.51,
    longitude: 105.029,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "หนองเต่า",
    zipcode: 34130,
    latitude: 15.725,
    longitude: 104.933,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "ถ้ำแข้",
    zipcode: 34130,
    latitude: 15.672,
    longitude: 105.134,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "ท่าหลวง",
    zipcode: 34130,
    latitude: 15.673,
    longitude: 105.212,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "ห้วยฝ้ายพัฒนา",
    zipcode: 34130,
    latitude: 15.522,
    longitude: 105.168,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "กุดยาลวน",
    zipcode: 34130,
    latitude: 15.731,
    longitude: 105.029,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "บ้านแดง",
    zipcode: 34130,
    latitude: 15.658,
    longitude: 105.081,
  },
  {
    province: "อุบลราชธานี",
    district: "ตระการพืชผล",
    subdistrict: "คำเจริญ",
    zipcode: 34130,
    latitude: 15.642,
    longitude: 105.032,
  },
  {
    province: "อุบลราชธานี",
    district: "กุดข้าวปุ้น",
    subdistrict: "ข้าวปุ้น",
    zipcode: 34270,
    latitude: 15.792,
    longitude: 105.006,
  },
  {
    province: "อุบลราชธานี",
    district: "กุดข้าวปุ้น",
    subdistrict: "โนนสวาง",
    zipcode: 34270,
    latitude: 15.867,
    longitude: 105.026,
  },
  {
    province: "อุบลราชธานี",
    district: "กุดข้าวปุ้น",
    subdistrict: "แก่งเค็ง",
    zipcode: 34270,
    latitude: 15.776,
    longitude: 105.101,
  },
  {
    province: "อุบลราชธานี",
    district: "กุดข้าวปุ้น",
    subdistrict: "กาบิน",
    zipcode: 34270,
    latitude: 15.757,
    longitude: 104.964,
  },
  {
    province: "อุบลราชธานี",
    district: "กุดข้าวปุ้น",
    subdistrict: "หนองทันน้ำ",
    zipcode: 34270,
    latitude: 15.828,
    longitude: 105.099,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "ม่วงสามสิบ",
    zipcode: 34140,
    latitude: 15.515,
    longitude: 104.73,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "เหล่าบก",
    zipcode: 34140,
    latitude: 15.657,
    longitude: 104.667,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "ดุมใหญ่",
    zipcode: 34140,
    latitude: 15.615,
    longitude: 104.739,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "หนองช้างใหญ่",
    zipcode: 34140,
    latitude: 15.564,
    longitude: 104.827,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "หนองเมือง",
    zipcode: 34140,
    latitude: 15.571,
    longitude: 104.735,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "เตย",
    zipcode: 34140,
    latitude: 15.5,
    longitude: 104.828,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "ยางสักกระโพหลุ่ม",
    zipcode: 34140,
    latitude: 15.449,
    longitude: 104.811,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "หนองไข่นก",
    zipcode: 34140,
    latitude: 15.397,
    longitude: 104.765,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "หนองเหล่า",
    zipcode: 34140,
    latitude: 15.443,
    longitude: 104.679,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "หนองฮาง",
    zipcode: 34140,
    latitude: 15.501,
    longitude: 104.661,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "ยางโยภาพ",
    zipcode: 34140,
    latitude: 15.593,
    longitude: 104.667,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "ไผ่ใหญ่",
    zipcode: 34140,
    latitude: 15.598,
    longitude: 104.617,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "นาเลิง",
    zipcode: 34140,
    latitude: 15.541,
    longitude: 104.586,
  },
  {
    province: "อุบลราชธานี",
    district: "ม่วงสามสิบ",
    subdistrict: "โพนแพง",
    zipcode: 34140,
    latitude: 15.444,
    longitude: 104.743,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "วารินชำราบ",
    zipcode: 34190,
    latitude: 15.2,
    longitude: 104.853,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "ธาตุ",
    zipcode: 34190,
    latitude: 15.139,
    longitude: 104.887,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "ท่าลาด",
    zipcode: 34310,
    latitude: 15.105,
    longitude: 104.776,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "โนนโหนน",
    zipcode: 34190,
    latitude: 15.101,
    longitude: 104.818,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "คูเมือง",
    zipcode: 34190,
    latitude: 15.08,
    longitude: 104.865,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "สระสมิง",
    zipcode: 34190,
    latitude: 14.999,
    longitude: 104.903,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "คำน้ำแซบ",
    zipcode: 34190,
    latitude: 15.198,
    longitude: 104.815,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "บุ่งหวาย",
    zipcode: 34310,
    latitude: 15.158,
    longitude: 104.753,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "คำขวาง",
    zipcode: 34190,
    latitude: 15.165,
    longitude: 104.924,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "โพธิ์ใหญ่",
    zipcode: 34190,
    latitude: 15.122,
    longitude: 104.963,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "แสนสุข",
    zipcode: 34190,
    latitude: 15.173,
    longitude: 104.872,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "หนองกินเพล",
    zipcode: 34190,
    latitude: 15.204,
    longitude: 104.782,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "โนนผึ้ง",
    zipcode: 34190,
    latitude: 15.162,
    longitude: 104.823,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "เมืองศรีไค",
    zipcode: 34190,
    latitude: 15.087,
    longitude: 104.904,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "ห้วยขะยูง",
    zipcode: 34310,
    latitude: 15.0914711,
    longitude: 104.6772131,
  },
  {
    province: "อุบลราชธานี",
    district: "วารินชำราบ",
    subdistrict: "บุ่งไหม",
    zipcode: 34190,
    latitude: 15.197,
    longitude: 104.961,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "พิบูล",
    zipcode: 34110,
    latitude: 15.248,
    longitude: 105.226,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "กุดชมภู",
    zipcode: 34110,
    latitude: 15.202,
    longitude: 105.249,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "ดอนจิก",
    zipcode: 34110,
    latitude: 15.132,
    longitude: 105.313,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "ทรายมูล",
    zipcode: 34110,
    latitude: 15.304,
    longitude: 105.344,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "นาโพธิ์",
    zipcode: 34110,
    latitude: 15.069,
    longitude: 105.128,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "โนนกลาง",
    zipcode: 34110,
    latitude: 15.066,
    longitude: 105.344,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "โพธิ์ไทร",
    zipcode: 34110,
    latitude: 15.216,
    longitude: 105.158,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "โพธิ์ศรี",
    zipcode: 34110,
    latitude: 15.293,
    longitude: 105.237,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "ระเว",
    zipcode: 34110,
    latitude: 15.34,
    longitude: 105.297,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "ไร่ใต้",
    zipcode: 34110,
    latitude: 15.136,
    longitude: 105.122,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "หนองบัวฮี",
    zipcode: 34110,
    latitude: 15.065,
    longitude: 105.19,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "อ่างศิลา",
    zipcode: 34110,
    latitude: 15.031,
    longitude: 105.288,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "โนนกาหลง",
    zipcode: 34110,
    latitude: 14.993,
    longitude: 105.336,
  },
  {
    province: "อุบลราชธานี",
    district: "พิบูลมังสาหาร",
    subdistrict: "บ้านแขม",
    zipcode: 34110,
    latitude: 15.022,
    longitude: 105.25,
  },
  {
    province: "อุบลราชธานี",
    district: "ตาลสุม",
    subdistrict: "ตาลสุม",
    zipcode: 34330,
    latitude: 15.326,
    longitude: 105.156,
  },
  {
    province: "อุบลราชธานี",
    district: "ตาลสุม",
    subdistrict: "สำโรง",
    zipcode: 34330,
    latitude: 15.38,
    longitude: 105.233,
  },
  {
    province: "อุบลราชธานี",
    district: "ตาลสุม",
    subdistrict: "จิกเทิง",
    zipcode: 34330,
    latitude: 15.352,
    longitude: 105.086,
  },
  {
    province: "อุบลราชธานี",
    district: "ตาลสุม",
    subdistrict: "หนองกุง",
    zipcode: 34330,
    latitude: 15.444,
    longitude: 105.185,
  },
  {
    province: "อุบลราชธานี",
    district: "ตาลสุม",
    subdistrict: "นาคาย",
    zipcode: 34330,
    latitude: 15.417,
    longitude: 105.108,
  },
  {
    province: "อุบลราชธานี",
    district: "ตาลสุม",
    subdistrict: "คำหว้า",
    zipcode: 34330,
    latitude: 15.385,
    longitude: 105.166,
  },
  {
    province: "อุบลราชธานี",
    district: "โพธิ์ไทร",
    subdistrict: "โพธิ์ไทร",
    zipcode: 34340,
    latitude: 15.816,
    longitude: 105.261,
  },
  {
    province: "อุบลราชธานี",
    district: "โพธิ์ไทร",
    subdistrict: "ม่วงใหญ่",
    zipcode: 34340,
    latitude: 15.86,
    longitude: 105.273,
  },
  {
    province: "อุบลราชธานี",
    district: "โพธิ์ไทร",
    subdistrict: "สำโรง",
    zipcode: 34340,
    latitude: 15.728,
    longitude: 105.494,
  },
  {
    province: "อุบลราชธานี",
    district: "โพธิ์ไทร",
    subdistrict: "สองคอน",
    zipcode: 34340,
    latitude: 15.834,
    longitude: 105.345,
  },
  {
    province: "อุบลราชธานี",
    district: "โพธิ์ไทร",
    subdistrict: "สารภี",
    zipcode: 34340,
    latitude: 15.756,
    longitude: 105.254,
  },
  {
    province: "อุบลราชธานี",
    district: "โพธิ์ไทร",
    subdistrict: "เหล่างาม",
    zipcode: 34340,
    latitude: 15.76,
    longitude: 105.374,
  },
  {
    province: "อุบลราชธานี",
    district: "สำโรง",
    subdistrict: "สำโรง",
    zipcode: 34360,
    latitude: 15.01,
    longitude: 104.793,
  },
  {
    province: "อุบลราชธานี",
    district: "สำโรง",
    subdistrict: "โคกก่อง",
    zipcode: 34360,
    latitude: 15.033,
    longitude: 104.839,
  },
  {
    province: "อุบลราชธานี",
    district: "สำโรง",
    subdistrict: "หนองไฮ",
    zipcode: 34360,
    latitude: 14.96,
    longitude: 104.76,
  },
  {
    province: "อุบลราชธานี",
    district: "สำโรง",
    subdistrict: "ค้อน้อย",
    zipcode: 34360,
    latitude: 14.922,
    longitude: 104.812,
  },
  {
    province: "อุบลราชธานี",
    district: "สำโรง",
    subdistrict: "โนนกาเล็น",
    zipcode: 34360,
    latitude: 15.02,
    longitude: 104.72,
  },
  {
    province: "อุบลราชธานี",
    district: "สำโรง",
    subdistrict: "โคกสว่าง",
    zipcode: 34360,
    latitude: 14.936,
    longitude: 104.865,
  },
  {
    province: "อุบลราชธานี",
    district: "สำโรง",
    subdistrict: "โนนกลาง",
    zipcode: 34360,
    latitude: 14.959,
    longitude: 104.713,
  },
  {
    province: "อุบลราชธานี",
    district: "สำโรง",
    subdistrict: "บอน",
    zipcode: 34360,
    latitude: 15.055,
    longitude: 104.765,
  },
  {
    province: "อุบลราชธานี",
    district: "สำโรง",
    subdistrict: "ขามป้อม",
    zipcode: 34360,
    latitude: 14.981,
    longitude: 104.821,
  },
  {
    province: "อุบลราชธานี",
    district: "ดอนมดแดง",
    subdistrict: "ดอนมดแดง",
    zipcode: 34000,
    latitude: 15.315,
    longitude: 105.065,
  },
  {
    province: "อุบลราชธานี",
    district: "ดอนมดแดง",
    subdistrict: "เหล่าแดง",
    zipcode: 34000,
    latitude: 15.375,
    longitude: 105.028,
  },
  {
    province: "อุบลราชธานี",
    district: "ดอนมดแดง",
    subdistrict: "ท่าเมือง",
    zipcode: 34000,
    latitude: 15.473,
    longitude: 104.968,
  },
  {
    province: "อุบลราชธานี",
    district: "ดอนมดแดง",
    subdistrict: "คำไฮใหญ่",
    zipcode: 34000,
    latitude: 15.372,
    longitude: 104.968,
  },
  {
    province: "อุบลราชธานี",
    district: "สิรินธร",
    subdistrict: "คันไร่",
    zipcode: 34350,
    latitude: 15.225,
    longitude: 105.33,
  },
  {
    province: "อุบลราชธานี",
    district: "สิรินธร",
    subdistrict: "ช่องเม็ก",
    zipcode: 34350,
    latitude: 15.126,
    longitude: 105.414,
  },
  {
    province: "อุบลราชธานี",
    district: "สิรินธร",
    subdistrict: "โนนก่อ",
    zipcode: 34350,
    latitude: 14.993,
    longitude: 105.425,
  },
  {
    province: "อุบลราชธานี",
    district: "สิรินธร",
    subdistrict: "นิคมสร้างตนเองลำโดมน้อย",
    zipcode: 34350,
    latitude: 15.2296211,
    longitude: 105.3413061,
  },
  {
    province: "อุบลราชธานี",
    district: "สิรินธร",
    subdistrict: "ฝางคำ",
    zipcode: 34350,
    latitude: 15.134,
    longitude: 105.364,
  },
  {
    province: "อุบลราชธานี",
    district: "สิรินธร",
    subdistrict: "คำเขื่อนแก้ว",
    zipcode: 34350,
    latitude: 15.24,
    longitude: 105.507,
  },
  {
    province: "อุบลราชธานี",
    district: "ทุ่งศรีอุดม",
    subdistrict: "หนองอ้ม",
    zipcode: 34160,
    latitude: 14.804,
    longitude: 104.922,
  },
  {
    province: "อุบลราชธานี",
    district: "ทุ่งศรีอุดม",
    subdistrict: "นาเกษม",
    zipcode: 34160,
    latitude: 14.723,
    longitude: 104.931,
  },
  {
    province: "อุบลราชธานี",
    district: "ทุ่งศรีอุดม",
    subdistrict: "กุดเรือ",
    zipcode: 34160,
    latitude: 14.678,
    longitude: 104.955,
  },
  {
    province: "อุบลราชธานี",
    district: "ทุ่งศรีอุดม",
    subdistrict: "โคกชำแระ",
    zipcode: 34160,
    latitude: 14.752,
    longitude: 104.908,
  },
  {
    province: "อุบลราชธานี",
    district: "ทุ่งศรีอุดม",
    subdistrict: "นาห่อม",
    zipcode: 34160,
    latitude: 14.76,
    longitude: 104.855,
  },
  {
    province: "อุบลราชธานี",
    district: "นาเยีย",
    subdistrict: "นาเยีย",
    zipcode: 34160,
    latitude: 15.006,
    longitude: 105.143,
  },
  {
    province: "อุบลราชธานี",
    district: "นาเยีย",
    subdistrict: "นาดี",
    zipcode: 34160,
    latitude: 15.116,
    longitude: 105.018,
  },
  {
    province: "อุบลราชธานี",
    district: "นาเยีย",
    subdistrict: "นาเรือง",
    zipcode: 34160,
    latitude: 14.987,
    longitude: 105.068,
  },
  {
    province: "อุบลราชธานี",
    district: "นาตาล",
    subdistrict: "นาตาล",
    zipcode: 34170,
    latitude: 15.893,
    longitude: 105.31,
  },
  {
    province: "อุบลราชธานี",
    district: "นาตาล",
    subdistrict: "พะลาน",
    zipcode: 34170,
    latitude: 15.96,
    longitude: 105.332,
  },
  {
    province: "อุบลราชธานี",
    district: "นาตาล",
    subdistrict: "กองโพน",
    zipcode: 34170,
    latitude: 15.965,
    longitude: 105.27,
  },
  {
    province: "อุบลราชธานี",
    district: "นาตาล",
    subdistrict: "พังเคน",
    zipcode: 34170,
    latitude: 15.921,
    longitude: 105.212,
  },
  {
    province: "อุบลราชธานี",
    district: "เหล่าเสือโก้ก",
    subdistrict: "เหล่าเสือโก้ก",
    zipcode: 34000,
    latitude: 15.432,
    longitude: 104.918,
  },
  {
    province: "อุบลราชธานี",
    district: "เหล่าเสือโก้ก",
    subdistrict: "โพนเมือง",
    zipcode: 34000,
    latitude: 15.492,
    longitude: 104.891,
  },
  {
    province: "อุบลราชธานี",
    district: "เหล่าเสือโก้ก",
    subdistrict: "แพงใหญ่",
    zipcode: 34000,
    latitude: 15.395,
    longitude: 104.881,
  },
  {
    province: "อุบลราชธานี",
    district: "เหล่าเสือโก้ก",
    subdistrict: "หนองบก",
    zipcode: 34000,
    latitude: 15.423,
    longitude: 104.988,
  },
  {
    province: "อุบลราชธานี",
    district: "สว่างวีระวงศ์",
    subdistrict: "แก่งโดม",
    zipcode: 34190,
    latitude: 15.204,
    longitude: 105.087,
  },
  {
    province: "อุบลราชธานี",
    district: "สว่างวีระวงศ์",
    subdistrict: "ท่าช้าง",
    zipcode: 34190,
    latitude: 15.229,
    longitude: 104.99,
  },
  {
    province: "อุบลราชธานี",
    district: "สว่างวีระวงศ์",
    subdistrict: "บุ่งมะแลง",
    zipcode: 34190,
    latitude: 15.238,
    longitude: 105.045,
  },
  {
    province: "อุบลราชธานี",
    district: "สว่างวีระวงศ์",
    subdistrict: "สว่าง",
    zipcode: 34190,
    latitude: 15.274,
    longitude: 105.118,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำขุ่น",
    subdistrict: "ตาเกา",
    zipcode: 34260,
    latitude: 14.568,
    longitude: 104.919,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำขุ่น",
    subdistrict: "ไพบูลย์",
    zipcode: 34260,
    latitude: 14.603,
    longitude: 104.854,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำขุ่น",
    subdistrict: "ขี้เหล็ก",
    zipcode: 34260,
    latitude: 14.624,
    longitude: 104.899,
  },
  {
    province: "อุบลราชธานี",
    district: "น้ำขุ่น",
    subdistrict: "โคกสะอาด",
    zipcode: 34260,
    latitude: 14.443,
    longitude: 104.848,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "ในเมือง",
    zipcode: 35000,
    latitude: 15.799,
    longitude: 104.148,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "น้ำคำใหญ่",
    zipcode: 35000,
    latitude: 15.828,
    longitude: 104.167,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "ตาดทอง",
    zipcode: 35000,
    latitude: 15.777,
    longitude: 104.205,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "สำราญ",
    zipcode: 35000,
    latitude: 15.838,
    longitude: 104.113,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "ค้อเหนือ",
    zipcode: 35000,
    latitude: 15.835,
    longitude: 104.044,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "ดู่ทุ่ง",
    zipcode: 35000,
    latitude: 15.899,
    longitude: 104.041,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "เดิด",
    zipcode: 35000,
    latitude: 15.945,
    longitude: 104.062,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "ขั้นไดใหญ่",
    zipcode: 35000,
    latitude: 15.915,
    longitude: 104.12,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "ทุ่งแต้",
    zipcode: 35000,
    latitude: 15.913,
    longitude: 104.179,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "สิงห์",
    zipcode: 35000,
    latitude: 15.823,
    longitude: 104.241,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "นาสะไมย์",
    zipcode: 35000,
    latitude: 15.895,
    longitude: 104.246,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "เขื่องคำ",
    zipcode: 35000,
    latitude: 15.74,
    longitude: 104.16,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "หนองหิน",
    zipcode: 35000,
    latitude: 15.859,
    longitude: 104.282,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "หนองคู",
    zipcode: 35000,
    latitude: 15.78,
    longitude: 104.252,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "ขุมเงิน",
    zipcode: 35000,
    latitude: 15.713,
    longitude: 104.194,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "ทุ่งนางโอก",
    zipcode: 35000,
    latitude: 15.886,
    longitude: 104.147,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "หนองเรือ",
    zipcode: 35000,
    latitude: 15.861,
    longitude: 104.207,
  },
  {
    province: "ยโสธร",
    district: "เมืองยโสธร",
    subdistrict: "หนองเป็ด",
    zipcode: 35000,
    latitude: 15.877,
    longitude: 104.335,
  },
  {
    province: "ยโสธร",
    district: "ทรายมูล",
    subdistrict: "ทรายมูล",
    zipcode: 35170,
    latitude: 15.956,
    longitude: 104.224,
  },
  {
    province: "ยโสธร",
    district: "ทรายมูล",
    subdistrict: "ดู่ลาด",
    zipcode: 35170,
    latitude: 16.012,
    longitude: 104.208,
  },
  {
    province: "ยโสธร",
    district: "ทรายมูล",
    subdistrict: "ดงมะไฟ",
    zipcode: 35170,
    latitude: 15.981,
    longitude: 104.135,
  },
  {
    province: "ยโสธร",
    district: "ทรายมูล",
    subdistrict: "นาเวียง",
    zipcode: 35170,
    latitude: 16.07,
    longitude: 104.128,
  },
  {
    province: "ยโสธร",
    district: "ทรายมูล",
    subdistrict: "ไผ่",
    zipcode: 35170,
    latitude: 15.927,
    longitude: 104.293,
  },
  {
    province: "ยโสธร",
    district: "กุดชุม",
    subdistrict: "กุดชุม",
    zipcode: 35140,
    latitude: 16.069,
    longitude: 104.349,
  },
  {
    province: "ยโสธร",
    district: "กุดชุม",
    subdistrict: "โนนเปือย",
    zipcode: 35140,
    latitude: 16.011,
    longitude: 104.387,
  },
  {
    province: "ยโสธร",
    district: "กุดชุม",
    subdistrict: "กำแมด",
    zipcode: 35140,
    latitude: 15.943,
    longitude: 104.379,
  },
  {
    province: "ยโสธร",
    district: "กุดชุม",
    subdistrict: "นาโส่",
    zipcode: 35140,
    latitude: 15.972,
    longitude: 104.317,
  },
  {
    province: "ยโสธร",
    district: "กุดชุม",
    subdistrict: "ห้วยแก้ง",
    zipcode: 35140,
    latitude: 16.007,
    longitude: 104.291,
  },
  {
    province: "ยโสธร",
    district: "กุดชุม",
    subdistrict: "หนองหมี",
    zipcode: 35140,
    latitude: 16.051,
    longitude: 104.296,
  },
  {
    province: "ยโสธร",
    district: "กุดชุม",
    subdistrict: "โพนงาม",
    zipcode: 35140,
    latitude: 16.045,
    longitude: 104.166,
  },
  {
    province: "ยโสธร",
    district: "กุดชุม",
    subdistrict: "คำน้ำสร้าง",
    zipcode: 35140,
    latitude: 16.099,
    longitude: 104.258,
  },
  {
    province: "ยโสธร",
    district: "กุดชุม",
    subdistrict: "หนองแหน",
    zipcode: 35140,
    latitude: 16.127,
    longitude: 104.217,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "ลุมพุก",
    zipcode: 35110,
    latitude: 15.643,
    longitude: 104.323,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "ย่อ",
    zipcode: 35110,
    latitude: 15.693,
    longitude: 104.222,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "สงเปือย",
    zipcode: 35110,
    latitude: 15.647,
    longitude: 104.221,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "โพนทัน",
    zipcode: 35110,
    latitude: 15.665,
    longitude: 104.282,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "ทุ่งมน",
    zipcode: 35110,
    latitude: 15.747,
    longitude: 104.286,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "นาคำ",
    zipcode: 35180,
    latitude: 15.633,
    longitude: 104.412,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "ดงแคนใหญ่",
    zipcode: 35180,
    latitude: 15.572,
    longitude: 104.395,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "กู่จาน",
    zipcode: 35110,
    latitude: 15.682,
    longitude: 104.399,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "นาแก",
    zipcode: 35180,
    latitude: 15.597,
    longitude: 104.426,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "กุดกุง",
    zipcode: 35110,
    latitude: 15.615,
    longitude: 104.246,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "เหล่าไฮ",
    zipcode: 35110,
    latitude: 15.724,
    longitude: 104.382,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "แคนน้อย",
    zipcode: 35180,
    latitude: 15.594,
    longitude: 104.347,
  },
  {
    province: "ยโสธร",
    district: "คำเขื่อนแก้ว",
    subdistrict: "ดงเจริญ",
    zipcode: 35110,
    latitude: 15.698,
    longitude: 104.292,
  },
  {
    province: "ยโสธร",
    district: "ป่าติ้ว",
    subdistrict: "โพธิ์ไทร",
    zipcode: 35150,
    latitude: 15.832,
    longitude: 104.398,
  },
  {
    province: "ยโสธร",
    district: "ป่าติ้ว",
    subdistrict: "กระจาย",
    zipcode: 35150,
    latitude: 15.801,
    longitude: 104.299,
  },
  {
    province: "ยโสธร",
    district: "ป่าติ้ว",
    subdistrict: "โคกนาโก",
    zipcode: 35150,
    latitude: 15.925,
    longitude: 104.43,
  },
  {
    province: "ยโสธร",
    district: "ป่าติ้ว",
    subdistrict: "เชียงเพ็ง",
    zipcode: 35150,
    latitude: 15.772,
    longitude: 104.409,
  },
  {
    province: "ยโสธร",
    district: "ป่าติ้ว",
    subdistrict: "ศรีฐาน",
    zipcode: 35150,
    latitude: 15.777,
    longitude: 104.343,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "ฟ้าหยาด",
    zipcode: 35130,
    latitude: 15.514,
    longitude: 104.256,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "หัวเมือง",
    zipcode: 35130,
    latitude: 15.566,
    longitude: 104.207,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "คูเมือง",
    zipcode: 35130,
    latitude: 15.502,
    longitude: 104.184,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "ผือฮี",
    zipcode: 35130,
    latitude: 15.45,
    longitude: 104.27,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "บากเรือ",
    zipcode: 35130,
    latitude: 15.564,
    longitude: 104.278,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "ม่วง",
    zipcode: 35130,
    latitude: 15.473,
    longitude: 104.324,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "โนนทราย",
    zipcode: 35130,
    latitude: 15.552,
    longitude: 104.329,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "บึงแก",
    zipcode: 35130,
    latitude: 15.511,
    longitude: 104.358,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "พระเสาร์",
    zipcode: 35130,
    latitude: 15.566,
    longitude: 104.153,
  },
  {
    province: "ยโสธร",
    district: "มหาชนะชัย",
    subdistrict: "สงยาง",
    zipcode: 35130,
    latitude: 15.446,
    longitude: 104.225,
  },
  {
    province: "ยโสธร",
    district: "ค้อวัง",
    subdistrict: "ฟ้าห่วน",
    zipcode: 35160,
    latitude: 15.339,
    longitude: 104.405,
  },
  {
    province: "ยโสธร",
    district: "ค้อวัง",
    subdistrict: "กุดน้ำใส",
    zipcode: 35160,
    latitude: 15.415,
    longitude: 104.353,
  },
  {
    province: "ยโสธร",
    district: "ค้อวัง",
    subdistrict: "น้ำอ้อม",
    zipcode: 35160,
    latitude: 15.397,
    longitude: 104.28,
  },
  {
    province: "ยโสธร",
    district: "ค้อวัง",
    subdistrict: "ค้อวัง",
    zipcode: 35160,
    latitude: 15.363,
    longitude: 104.31,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "บุ่งค้า",
    zipcode: 35120,
    latitude: 16.176,
    longitude: 104.669,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "สวาท",
    zipcode: 35120,
    latitude: 16.153,
    longitude: 104.538,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "ห้องแซง",
    zipcode: 35120,
    latitude: 16.269,
    longitude: 104.373,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "สามัคคี",
    zipcode: 35120,
    latitude: 16.232,
    longitude: 104.453,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "กุดเชียงหมี",
    zipcode: 35120,
    latitude: 16.313,
    longitude: 104.446,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "สามแยก",
    zipcode: 35120,
    latitude: 16.217,
    longitude: 104.562,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "กุดแห่",
    zipcode: 35120,
    latitude: 16.275,
    longitude: 104.52,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "โคกสำราญ",
    zipcode: 35120,
    latitude: 16.149,
    longitude: 104.592,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "สร้างมิ่ง",
    zipcode: 35120,
    latitude: 16.095,
    longitude: 104.506,
  },
  {
    province: "ยโสธร",
    district: "เลิงนกทา",
    subdistrict: "ศรีแก้ว",
    zipcode: 35120,
    latitude: 16.194,
    longitude: 104.332,
  },
  {
    province: "ยโสธร",
    district: "ไทยเจริญ",
    subdistrict: "ไทยเจริญ",
    zipcode: 35120,
    latitude: 16.06,
    longitude: 104.432,
  },
  {
    province: "ยโสธร",
    district: "ไทยเจริญ",
    subdistrict: "น้ำคำ",
    zipcode: 35120,
    latitude: 16.015,
    longitude: 104.486,
  },
  {
    province: "ยโสธร",
    district: "ไทยเจริญ",
    subdistrict: "ส้มผ่อ",
    zipcode: 35120,
    latitude: 16.162,
    longitude: 104.432,
  },
  {
    province: "ยโสธร",
    district: "ไทยเจริญ",
    subdistrict: "คำเตย",
    zipcode: 35120,
    latitude: 16.1,
    longitude: 104.388,
  },
  {
    province: "ยโสธร",
    district: "ไทยเจริญ",
    subdistrict: "คำไผ่",
    zipcode: 35120,
    latitude: 16.061,
    longitude: 104.521,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "ในเมือง",
    zipcode: 36000,
    latitude: 15.804,
    longitude: 102.037,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "รอบเมือง",
    zipcode: 36000,
    latitude: 15.846,
    longitude: 102.027,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "โพนทอง",
    zipcode: 36000,
    latitude: 15.863,
    longitude: 102.114,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "นาฝาย",
    zipcode: 36000,
    latitude: 15.919,
    longitude: 101.982,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "บ้านค่าย",
    zipcode: 36240,
    latitude: 15.684,
    longitude: 102.028,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "กุดตุ้ม",
    zipcode: 36000,
    latitude: 15.771,
    longitude: 102.181,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "ชีลอง",
    zipcode: 36000,
    latitude: 15.73,
    longitude: 101.999,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "บ้านเล่า",
    zipcode: 36000,
    latitude: 15.872,
    longitude: 102.061,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "นาเสียว",
    zipcode: 36000,
    latitude: 15.941,
    longitude: 102.11,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "หนองนาแซง",
    zipcode: 36000,
    latitude: 15.742,
    longitude: 102.022,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "ลาดใหญ่",
    zipcode: 36000,
    latitude: 15.809,
    longitude: 102.189,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "หนองไผ่",
    zipcode: 36240,
    latitude: 15.707,
    longitude: 102.052,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "ท่าหินโงม",
    zipcode: 36000,
    latitude: 16.021,
    longitude: 101.993,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "ห้วยต้อน",
    zipcode: 36000,
    latitude: 15.93,
    longitude: 101.919,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "ห้วยบง",
    zipcode: 36000,
    latitude: 15.843,
    longitude: 102.167,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "โนนสำราญ",
    zipcode: 36240,
    latitude: 15.661,
    longitude: 102.093,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "โคกสูง",
    zipcode: 36000,
    latitude: 15.827,
    longitude: 101.958,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "บุ่งคล้า",
    zipcode: 36000,
    latitude: 15.759,
    longitude: 102.056,
  },
  {
    province: "ชัยภูมิ",
    district: "เมืองชัยภูมิ",
    subdistrict: "ซับสีทอง",
    zipcode: 36000,
    latitude: 16.137,
    longitude: 102.017,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านเขว้า",
    subdistrict: "บ้านเขว้า",
    zipcode: 36170,
    latitude: 15.753,
    longitude: 101.911,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านเขว้า",
    subdistrict: "ตลาดแร้ง",
    zipcode: 36170,
    latitude: 15.778,
    longitude: 101.861,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านเขว้า",
    subdistrict: "ลุ่มลำชี",
    zipcode: 36170,
    latitude: 15.697,
    longitude: 101.932,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านเขว้า",
    subdistrict: "ชีบน",
    zipcode: 36170,
    latitude: 15.866,
    longitude: 101.759,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านเขว้า",
    subdistrict: "ภูแลนคา",
    zipcode: 36170,
    latitude: 15.876,
    longitude: 101.84,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านเขว้า",
    subdistrict: "โนนแดง",
    zipcode: 36170,
    latitude: 15.804,
    longitude: 101.93,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสวรรค์",
    subdistrict: "คอนสวรรค์",
    zipcode: 36140,
    latitude: 15.93,
    longitude: 102.327,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสวรรค์",
    subdistrict: "ยางหวาย",
    zipcode: 36140,
    latitude: 15.887,
    longitude: 102.31,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสวรรค์",
    subdistrict: "ช่องสามหมอ",
    zipcode: 36140,
    latitude: 16.018,
    longitude: 102.292,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสวรรค์",
    subdistrict: "โนนสะอาด",
    zipcode: 36140,
    latitude: 15.816,
    longitude: 102.275,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสวรรค์",
    subdistrict: "ห้วยไร่",
    zipcode: 36140,
    latitude: 16.021,
    longitude: 102.247,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสวรรค์",
    subdistrict: "บ้านโสก",
    zipcode: 36140,
    latitude: 15.892,
    longitude: 102.263,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสวรรค์",
    subdistrict: "โคกมั่งงอย",
    zipcode: 36140,
    latitude: 15.955,
    longitude: 102.259,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสวรรค์",
    subdistrict: "หนองขาม",
    zipcode: 36140,
    latitude: 15.853,
    longitude: 102.234,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสวรรค์",
    subdistrict: "ศรีสำราญ",
    zipcode: 36140,
    latitude: 15.993,
    longitude: 102.339,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "บ้านยาง",
    zipcode: 36120,
    latitude: 16.286,
    longitude: 101.869,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "บ้านหัน",
    zipcode: 36120,
    latitude: 16.307,
    longitude: 102.027,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "บ้านเดื่อ",
    zipcode: 36120,
    latitude: 16.089,
    longitude: 101.908,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "บ้านเป้า",
    zipcode: 36120,
    latitude: 16.372,
    longitude: 101.951,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "กุดเลาะ",
    zipcode: 36120,
    latitude: 16.309,
    longitude: 101.943,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "โนนกอก",
    zipcode: 36120,
    latitude: 16.171,
    longitude: 101.949,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "สระโพนทอง",
    zipcode: 36120,
    latitude: 16.246,
    longitude: 101.984,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "หนองข่า",
    zipcode: 36120,
    latitude: 16.169,
    longitude: 101.869,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "หนองโพนงาม",
    zipcode: 36120,
    latitude: 16.434,
    longitude: 101.877,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "บ้านบัว",
    zipcode: 36120,
    latitude: 16.242,
    longitude: 101.874,
  },
  {
    province: "ชัยภูมิ",
    district: "เกษตรสมบูรณ์",
    subdistrict: "โนนทอง",
    zipcode: 36120,
    latitude: 16.379,
    longitude: 101.797,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวแดง",
    subdistrict: "หนองบัวแดง",
    zipcode: 36210,
    latitude: 16.351,
    longitude: 101.721,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวแดง",
    subdistrict: "กุดชุมแสง",
    zipcode: 36210,
    latitude: 16.012,
    longitude: 101.834,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวแดง",
    subdistrict: "ถ้ำวัวแดง",
    zipcode: 36210,
    latitude: 16.161,
    longitude: 101.44,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวแดง",
    subdistrict: "นางแดด",
    zipcode: 36210,
    latitude: 16.28,
    longitude: 101.518,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวแดง",
    subdistrict: "หนองแวง",
    zipcode: 36210,
    latitude: 16.177,
    longitude: 101.722,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวแดง",
    subdistrict: "คูเมือง",
    zipcode: 36210,
    latitude: 16.012,
    longitude: 101.723,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวแดง",
    subdistrict: "ท่าใหญ่",
    zipcode: 36210,
    latitude: 16.028,
    longitude: 101.624,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวแดง",
    subdistrict: "วังชมภู",
    zipcode: 36210,
    latitude: 16.119,
    longitude: 101.578,
  },
  {
    province: "ชัยภูมิ",
    district: "จัตุรัส",
    subdistrict: "บ้านกอก",
    zipcode: 36130,
    latitude: 15.467,
    longitude: 101.902,
  },
  {
    province: "ชัยภูมิ",
    district: "จัตุรัส",
    subdistrict: "หนองบัวบาน",
    zipcode: 36130,
    latitude: 15.652,
    longitude: 101.802,
  },
  {
    province: "ชัยภูมิ",
    district: "จัตุรัส",
    subdistrict: "บ้านขาม",
    zipcode: 36130,
    latitude: 15.474,
    longitude: 101.805,
  },
  {
    province: "ชัยภูมิ",
    district: "จัตุรัส",
    subdistrict: "กุดน้ำใส",
    zipcode: 36130,
    latitude: 15.596,
    longitude: 101.794,
  },
  {
    province: "ชัยภูมิ",
    district: "จัตุรัส",
    subdistrict: "หนองโดน",
    zipcode: 36130,
    latitude: 15.567,
    longitude: 101.736,
  },
  {
    province: "ชัยภูมิ",
    district: "จัตุรัส",
    subdistrict: "ละหาน",
    zipcode: 36130,
    latitude: 15.629,
    longitude: 101.938,
  },
  {
    province: "ชัยภูมิ",
    district: "จัตุรัส",
    subdistrict: "หนองบัวใหญ่",
    zipcode: 36130,
    latitude: 15.561,
    longitude: 101.888,
  },
  {
    province: "ชัยภูมิ",
    district: "จัตุรัส",
    subdistrict: "หนองบัวโคก",
    zipcode: 36220,
    latitude: 15.448,
    longitude: 101.854,
  },
  {
    province: "ชัยภูมิ",
    district: "จัตุรัส",
    subdistrict: "ส้มป่อย",
    zipcode: 36130,
    latitude: 15.711,
    longitude: 101.806,
  },
  {
    province: "ชัยภูมิ",
    district: "บำเหน็จณรงค์",
    subdistrict: "บ้านชวน",
    zipcode: 36160,
    latitude: 15.518,
    longitude: 101.653,
  },
  {
    province: "ชัยภูมิ",
    district: "บำเหน็จณรงค์",
    subdistrict: "บ้านเพชร",
    zipcode: 36160,
    latitude: 15.434,
    longitude: 101.697,
  },
  {
    province: "ชัยภูมิ",
    district: "บำเหน็จณรงค์",
    subdistrict: "บ้านตาล",
    zipcode: 36220,
    latitude: 15.484,
    longitude: 101.756,
  },
  {
    province: "ชัยภูมิ",
    district: "บำเหน็จณรงค์",
    subdistrict: "หัวทะเล",
    zipcode: 36220,
    latitude: 15.424,
    longitude: 101.757,
  },
  {
    province: "ชัยภูมิ",
    district: "บำเหน็จณรงค์",
    subdistrict: "โคกเริงรมย์",
    zipcode: 36160,
    latitude: 15.391,
    longitude: 101.576,
  },
  {
    province: "ชัยภูมิ",
    district: "บำเหน็จณรงค์",
    subdistrict: "เกาะมะนาว",
    zipcode: 36160,
    latitude: 15.504,
    longitude: 101.61,
  },
  {
    province: "ชัยภูมิ",
    district: "บำเหน็จณรงค์",
    subdistrict: "โคกเพชรพัฒนา",
    zipcode: 36160,
    latitude: 15.471,
    longitude: 101.578,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวระเหว",
    subdistrict: "หนองบัวระเหว",
    zipcode: 36250,
    latitude: 15.757,
    longitude: 101.786,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวระเหว",
    subdistrict: "วังตะเฆ่",
    zipcode: 36250,
    latitude: 15.83,
    longitude: 101.583,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวระเหว",
    subdistrict: "ห้วยแย้",
    zipcode: 36250,
    latitude: 15.921,
    longitude: 101.64,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวระเหว",
    subdistrict: "โคกสะอาด",
    zipcode: 36250,
    latitude: 15.778,
    longitude: 101.699,
  },
  {
    province: "ชัยภูมิ",
    district: "หนองบัวระเหว",
    subdistrict: "โสกปลาดุก",
    zipcode: 36250,
    latitude: 15.718,
    longitude: 101.695,
  },
  {
    province: "ชัยภูมิ",
    district: "เทพสถิต",
    subdistrict: "วะตะแบก",
    zipcode: 36230,
    latitude: 15.426,
    longitude: 101.436,
  },
  {
    province: "ชัยภูมิ",
    district: "เทพสถิต",
    subdistrict: "ห้วยยายจิ๋ว",
    zipcode: 36230,
    latitude: 15.432,
    longitude: 101.547,
  },
  {
    province: "ชัยภูมิ",
    district: "เทพสถิต",
    subdistrict: "นายางกลัก",
    zipcode: 36230,
    latitude: 15.676,
    longitude: 101.517,
  },
  {
    province: "ชัยภูมิ",
    district: "เทพสถิต",
    subdistrict: "บ้านไร่",
    zipcode: 36230,
    latitude: 15.595,
    longitude: 101.456,
  },
  {
    province: "ชัยภูมิ",
    district: "เทพสถิต",
    subdistrict: "โป่งนก",
    zipcode: 36230,
    latitude: 15.808,
    longitude: 101.469,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "ผักปัง",
    zipcode: 36110,
    latitude: 16.357,
    longitude: 102.136,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "กวางโจน",
    zipcode: 36110,
    latitude: 16.282,
    longitude: 102.232,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "หนองคอนไทย",
    zipcode: 36110,
    latitude: 16.461,
    longitude: 102.181,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "บ้านแก้ง",
    zipcode: 36110,
    latitude: 16.42,
    longitude: 102.049,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "กุดยม",
    zipcode: 36110,
    latitude: 16.417,
    longitude: 102.202,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "บ้านเพชร",
    zipcode: 36110,
    latitude: 16.344,
    longitude: 102.256,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "โคกสะอาด",
    zipcode: 36110,
    latitude: 16.475,
    longitude: 102.125,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "หนองตูม",
    zipcode: 36110,
    latitude: 16.325,
    longitude: 102.092,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "โอโล",
    zipcode: 36110,
    latitude: 16.346,
    longitude: 102.184,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "ธาตุทอง",
    zipcode: 36110,
    latitude: 16.235,
    longitude: 102.16,
  },
  {
    province: "ชัยภูมิ",
    district: "ภูเขียว",
    subdistrict: "บ้านดอน",
    zipcode: 36110,
    latitude: 16.305,
    longitude: 102.304,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านแท่น",
    subdistrict: "บ้านแท่น",
    zipcode: 36190,
    latitude: 16.405,
    longitude: 102.349,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านแท่น",
    subdistrict: "สามสวน",
    zipcode: 36190,
    latitude: 16.419,
    longitude: 102.27,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านแท่น",
    subdistrict: "สระพัง",
    zipcode: 36190,
    latitude: 16.401,
    longitude: 102.418,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านแท่น",
    subdistrict: "บ้านเต่า",
    zipcode: 36190,
    latitude: 16.35,
    longitude: 102.387,
  },
  {
    province: "ชัยภูมิ",
    district: "บ้านแท่น",
    subdistrict: "หนองคู",
    zipcode: 36190,
    latitude: 16.293,
    longitude: 102.398,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "ช่องสามหมอ",
    zipcode: 36150,
    latitude: 16.107,
    longitude: 102.236,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "หนองขาม",
    zipcode: 36150,
    latitude: 16.164,
    longitude: 102.194,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "นาหนองทุ่ม",
    zipcode: 36150,
    latitude: 16.032,
    longitude: 102.164,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "บ้านแก้ง",
    zipcode: 36150,
    latitude: 16.172,
    longitude: 102.325,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "หนองสังข์",
    zipcode: 36150,
    latitude: 16.242,
    longitude: 102.382,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "หลุบคา",
    zipcode: 36150,
    latitude: 16.202,
    longitude: 102.259,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "โคกกุง",
    zipcode: 36150,
    latitude: 16.111,
    longitude: 102.154,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "เก่าย่าดี",
    zipcode: 36150,
    latitude: 16.056,
    longitude: 102.074,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "ท่ามะไฟหวาน",
    zipcode: 36150,
    latitude: 16.155,
    longitude: 102.097,
  },
  {
    province: "ชัยภูมิ",
    district: "แก้งคร้อ",
    subdistrict: "หนองไผ่",
    zipcode: 36150,
    latitude: 16.13,
    longitude: 102.273,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสาร",
    subdistrict: "คอนสาร",
    zipcode: 36180,
    latitude: 16.61,
    longitude: 101.921,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสาร",
    subdistrict: "ทุ่งพระ",
    zipcode: 36180,
    latitude: 16.649,
    longitude: 101.642,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสาร",
    subdistrict: "โนนคูณ",
    zipcode: 36180,
    latitude: 16.485,
    longitude: 101.981,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสาร",
    subdistrict: "ห้วยยาง",
    zipcode: 36180,
    latitude: 16.536,
    longitude: 101.896,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสาร",
    subdistrict: "ทุ่งลุยลาย",
    zipcode: 36180,
    latitude: 16.522,
    longitude: 101.634,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสาร",
    subdistrict: "ดงบัง",
    zipcode: 36180,
    latitude: 16.587,
    longitude: 101.955,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสาร",
    subdistrict: "ทุ่งนาเลา",
    zipcode: 36180,
    latitude: 16.59,
    longitude: 101.864,
  },
  {
    province: "ชัยภูมิ",
    district: "คอนสาร",
    subdistrict: "ดงกลาง",
    zipcode: 36180,
    latitude: 16.533,
    longitude: 101.983,
  },
  {
    province: "ชัยภูมิ",
    district: "ภักดีชุมพล",
    subdistrict: "บ้านเจียง",
    zipcode: 36260,
    latitude: 16.131,
    longitude: 101.375,
  },
  {
    province: "ชัยภูมิ",
    district: "ภักดีชุมพล",
    subdistrict: "เจาทอง",
    zipcode: 36260,
    latitude: 15.888,
    longitude: 101.397,
  },
  {
    province: "ชัยภูมิ",
    district: "ภักดีชุมพล",
    subdistrict: "วังทอง",
    zipcode: 36260,
    latitude: 15.793,
    longitude: 101.372,
  },
  {
    province: "ชัยภูมิ",
    district: "ภักดีชุมพล",
    subdistrict: "แหลมทอง",
    zipcode: 36260,
    latitude: 16.014,
    longitude: 101.379,
  },
  {
    province: "ชัยภูมิ",
    district: "เนินสง่า",
    subdistrict: "หนองฉิม",
    zipcode: 36130,
    latitude: 15.559,
    longitude: 101.968,
  },
  {
    province: "ชัยภูมิ",
    district: "เนินสง่า",
    subdistrict: "ตาเนิน",
    zipcode: 36130,
    latitude: 15.527,
    longitude: 102.016,
  },
  {
    province: "ชัยภูมิ",
    district: "เนินสง่า",
    subdistrict: "กะฮาด",
    zipcode: 36130,
    latitude: 15.632,
    longitude: 102.026,
  },
  {
    province: "ชัยภูมิ",
    district: "เนินสง่า",
    subdistrict: "รังงาม",
    zipcode: 36130,
    latitude: 15.487,
    longitude: 101.964,
  },
  {
    province: "ชัยภูมิ",
    district: "ซับใหญ่",
    subdistrict: "ซับใหญ่",
    zipcode: 36130,
    latitude: 15.602,
    longitude: 101.573,
  },
  {
    province: "ชัยภูมิ",
    district: "ซับใหญ่",
    subdistrict: "ท่ากูบ",
    zipcode: 36130,
    latitude: 15.623,
    longitude: 101.708,
  },
  {
    province: "ชัยภูมิ",
    district: "ซับใหญ่",
    subdistrict: "ตะโกทอง",
    zipcode: 36130,
    latitude: 15.565,
    longitude: 101.67,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "บุ่ง",
    zipcode: 37000,
    latitude: 15.872,
    longitude: 104.635,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "ไก่คำ",
    zipcode: 37000,
    latitude: 15.804,
    longitude: 104.64,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "นาจิก",
    zipcode: 37000,
    latitude: 15.787,
    longitude: 104.564,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "ปลาค้าว",
    zipcode: 37000,
    latitude: 15.785,
    longitude: 104.79,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "เหล่าพรวน",
    zipcode: 37000,
    latitude: 15.867,
    longitude: 104.794,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "สร้างนกทา",
    zipcode: 37000,
    latitude: 15.841,
    longitude: 104.75,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "คึมใหญ่",
    zipcode: 37000,
    latitude: 15.948,
    longitude: 104.751,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "นาผือ",
    zipcode: 37000,
    latitude: 15.982,
    longitude: 104.626,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "น้ำปลีก",
    zipcode: 37000,
    latitude: 15.811,
    longitude: 104.46,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "นาวัง",
    zipcode: 37000,
    latitude: 15.997,
    longitude: 104.579,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "นาหมอม้า",
    zipcode: 37000,
    latitude: 15.885,
    longitude: 104.478,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "โนนโพธิ์",
    zipcode: 37000,
    latitude: 15.873,
    longitude: 104.558,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "โนนหนามแท่ง",
    zipcode: 37000,
    latitude: 15.929,
    longitude: 104.631,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "ห้วยไร่",
    zipcode: 37000,
    latitude: 15.851,
    longitude: 104.689,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "หนองมะแซว",
    zipcode: 37000,
    latitude: 15.774,
    longitude: 104.746,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "กุดปลาดุก",
    zipcode: 37000,
    latitude: 15.945,
    longitude: 104.525,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "ดอนเมย",
    zipcode: 37000,
    latitude: 15.823,
    longitude: 104.562,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "นายม",
    zipcode: 37000,
    latitude: 15.878,
    longitude: 104.513,
  },
  {
    province: "อำนาจเจริญ",
    district: "เมืองอำนาจเจริญ",
    subdistrict: "นาแต้",
    zipcode: 37000,
    latitude: 15.954,
    longitude: 104.692,
  },
  {
    province: "อำนาจเจริญ",
    district: "ชานุมาน",
    subdistrict: "ชานุมาน",
    zipcode: 37210,
    latitude: 16.232,
    longitude: 104.944,
  },
  {
    province: "อำนาจเจริญ",
    district: "ชานุมาน",
    subdistrict: "โคกสาร",
    zipcode: 37210,
    latitude: 16.156,
    longitude: 104.996,
  },
  {
    province: "อำนาจเจริญ",
    district: "ชานุมาน",
    subdistrict: "คำเขื่อนแก้ว",
    zipcode: 37210,
    latitude: 16.15,
    longitude: 104.922,
  },
  {
    province: "อำนาจเจริญ",
    district: "ชานุมาน",
    subdistrict: "โคกก่ง",
    zipcode: 37210,
    latitude: 16.066,
    longitude: 104.97,
  },
  {
    province: "อำนาจเจริญ",
    district: "ชานุมาน",
    subdistrict: "ป่าก่อ",
    zipcode: 37210,
    latitude: 16.046,
    longitude: 104.86,
  },
  {
    province: "อำนาจเจริญ",
    district: "ปทุมราชวงศา",
    subdistrict: "หนองข่า",
    zipcode: 37110,
    latitude: 15.973,
    longitude: 104.892,
  },
  {
    province: "อำนาจเจริญ",
    district: "ปทุมราชวงศา",
    subdistrict: "คำโพน",
    zipcode: 37110,
    latitude: 15.968,
    longitude: 104.967,
  },
  {
    province: "อำนาจเจริญ",
    district: "ปทุมราชวงศา",
    subdistrict: "นาหว้า",
    zipcode: 37110,
    latitude: 15.897,
    longitude: 104.918,
  },
  {
    province: "อำนาจเจริญ",
    district: "ปทุมราชวงศา",
    subdistrict: "ลือ",
    zipcode: 37110,
    latitude: 15.796,
    longitude: 104.868,
  },
  {
    province: "อำนาจเจริญ",
    district: "ปทุมราชวงศา",
    subdistrict: "ห้วย",
    zipcode: 37110,
    latitude: 15.83,
    longitude: 104.948,
  },
  {
    province: "อำนาจเจริญ",
    district: "ปทุมราชวงศา",
    subdistrict: "โนนงาม",
    zipcode: 37110,
    latitude: 15.862,
    longitude: 104.828,
  },
  {
    province: "อำนาจเจริญ",
    district: "ปทุมราชวงศา",
    subdistrict: "นาป่าแซง",
    zipcode: 37110,
    latitude: 15.872,
    longitude: 104.876,
  },
  {
    province: "อำนาจเจริญ",
    district: "พนา",
    subdistrict: "พนา",
    zipcode: 37180,
    latitude: 15.678,
    longitude: 104.882,
  },
  {
    province: "อำนาจเจริญ",
    district: "พนา",
    subdistrict: "จานลาน",
    zipcode: 37180,
    latitude: 15.619,
    longitude: 104.901,
  },
  {
    province: "อำนาจเจริญ",
    district: "พนา",
    subdistrict: "ไม้กลอน",
    zipcode: 37180,
    latitude: 15.731,
    longitude: 104.818,
  },
  {
    province: "อำนาจเจริญ",
    district: "พนา",
    subdistrict: "พระเหลา",
    zipcode: 37180,
    latitude: 15.737,
    longitude: 104.882,
  },
  {
    province: "อำนาจเจริญ",
    district: "เสนางคนิคม",
    subdistrict: "เสนางคนิคม",
    zipcode: 37290,
    latitude: 16.056,
    longitude: 104.685,
  },
  {
    province: "อำนาจเจริญ",
    district: "เสนางคนิคม",
    subdistrict: "โพนทอง",
    zipcode: 37290,
    latitude: 16.069,
    longitude: 104.764,
  },
  {
    province: "อำนาจเจริญ",
    district: "เสนางคนิคม",
    subdistrict: "ไร่สีสุก",
    zipcode: 37290,
    latitude: 16.092,
    longitude: 104.639,
  },
  {
    province: "อำนาจเจริญ",
    district: "เสนางคนิคม",
    subdistrict: "นาเวียง",
    zipcode: 37290,
    latitude: 16.029,
    longitude: 104.592,
  },
  {
    province: "อำนาจเจริญ",
    district: "เสนางคนิคม",
    subdistrict: "หนองไฮ",
    zipcode: 37290,
    latitude: 16.022,
    longitude: 104.787,
  },
  {
    province: "อำนาจเจริญ",
    district: "เสนางคนิคม",
    subdistrict: "หนองสามสี",
    zipcode: 37290,
    latitude: 16.05,
    longitude: 104.616,
  },
  {
    province: "อำนาจเจริญ",
    district: "หัวตะพาน",
    subdistrict: "หัวตะพาน",
    zipcode: 37240,
    latitude: 15.718,
    longitude: 104.457,
  },
  {
    province: "อำนาจเจริญ",
    district: "หัวตะพาน",
    subdistrict: "คำพระ",
    zipcode: 37240,
    latitude: 15.761,
    longitude: 104.518,
  },
  {
    province: "อำนาจเจริญ",
    district: "หัวตะพาน",
    subdistrict: "เค็งใหญ่",
    zipcode: 37240,
    latitude: 15.738,
    longitude: 104.564,
  },
  {
    province: "อำนาจเจริญ",
    district: "หัวตะพาน",
    subdistrict: "หนองแก้ว",
    zipcode: 37240,
    latitude: 15.696,
    longitude: 104.531,
  },
  {
    province: "อำนาจเจริญ",
    district: "หัวตะพาน",
    subdistrict: "โพนเมืองน้อย",
    zipcode: 37240,
    latitude: 15.65,
    longitude: 104.58,
  },
  {
    province: "อำนาจเจริญ",
    district: "หัวตะพาน",
    subdistrict: "สร้างถ่อน้อย",
    zipcode: 37240,
    latitude: 15.641,
    longitude: 104.49,
  },
  {
    province: "อำนาจเจริญ",
    district: "หัวตะพาน",
    subdistrict: "จิกดู่",
    zipcode: 37240,
    latitude: 15.583,
    longitude: 104.545,
  },
  {
    province: "อำนาจเจริญ",
    district: "หัวตะพาน",
    subdistrict: "รัตนวารี",
    zipcode: 37240,
    latitude: 15.723,
    longitude: 104.513,
  },
  {
    province: "อำนาจเจริญ",
    district: "ลืออำนาจ",
    subdistrict: "อำนาจ",
    zipcode: 37000,
    latitude: 15.71,
    longitude: 104.668,
  },
  {
    province: "อำนาจเจริญ",
    district: "ลืออำนาจ",
    subdistrict: "ดงมะยาง",
    zipcode: 37000,
    latitude: 15.716,
    longitude: 104.617,
  },
  {
    province: "อำนาจเจริญ",
    district: "ลืออำนาจ",
    subdistrict: "เปือย",
    zipcode: 37000,
    latitude: 15.687,
    longitude: 104.697,
  },
  {
    province: "อำนาจเจริญ",
    district: "ลืออำนาจ",
    subdistrict: "ดงบัง",
    zipcode: 37000,
    latitude: 15.716,
    longitude: 104.765,
  },
  {
    province: "อำนาจเจริญ",
    district: "ลืออำนาจ",
    subdistrict: "ไร่ขี",
    zipcode: 37000,
    latitude: 15.663,
    longitude: 104.78,
  },
  {
    province: "อำนาจเจริญ",
    district: "ลืออำนาจ",
    subdistrict: "แมด",
    zipcode: 37000,
    latitude: 15.761,
    longitude: 104.689,
  },
  {
    province: "อำนาจเจริญ",
    district: "ลืออำนาจ",
    subdistrict: "โคกกลาง",
    zipcode: 37000,
    latitude: 15.751,
    longitude: 104.637,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "บึงกาฬ",
    zipcode: 38000,
    latitude: 18.2848054,
    longitude: 103.2825853,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "โนนสมบูรณ์",
    zipcode: 38000,
    latitude: 18.2610554,
    longitude: 103.5830749,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "โนนสว่าง",
    zipcode: 38000,
    latitude: 18.2883514,
    longitude: 103.3849799,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "หอคำ",
    zipcode: 38000,
    latitude: 18.4013608,
    longitude: 103.3032203,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "หนองเลิง",
    zipcode: 38000,
    latitude: 18.2165924,
    longitude: 103.3190917,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "โคกก่อง",
    zipcode: 38000,
    latitude: 18.2964794,
    longitude: 103.690047,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "นาสวรรค์",
    zipcode: 38000,
    latitude: 18.2569978,
    longitude: 103.5377088,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "ไคสี",
    zipcode: 38000,
    latitude: 18.393849,
    longitude: 103.468556,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "ชัยพร",
    zipcode: 38000,
    latitude: 18.2068639,
    longitude: 103.7814883,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "วิศิษฐ์",
    zipcode: 38000,
    latitude: 18.3457965,
    longitude: 103.5537985,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "คำนาดี",
    zipcode: 38000,
    latitude: 18.2088231,
    longitude: 103.5860504,
  },
  {
    province: "บึงกาฬ",
    district: "เมืองบึงกาฬ",
    subdistrict: "โป่งเปือย",
    zipcode: 38000,
    latitude: 18.3330827,
    longitude: 103.4874168,
  },
  {
    province: "บึงกาฬ",
    district: "พรเจริญ",
    subdistrict: "ศรีชมภู",
    zipcode: 38180,
    latitude: 18.044,
    longitude: 103.679,
  },
  {
    province: "บึงกาฬ",
    district: "พรเจริญ",
    subdistrict: "ดอนหญ้านาง",
    zipcode: 38180,
    latitude: 18.046,
    longitude: 103.616,
  },
  {
    province: "บึงกาฬ",
    district: "พรเจริญ",
    subdistrict: "พรเจริญ",
    zipcode: 38180,
    latitude: 18.031,
    longitude: 103.711,
  },
  {
    province: "บึงกาฬ",
    district: "พรเจริญ",
    subdistrict: "หนองหัวช้าง",
    zipcode: 38180,
    latitude: 18.125,
    longitude: 103.583,
  },
  {
    province: "บึงกาฬ",
    district: "พรเจริญ",
    subdistrict: "วังชมภู",
    zipcode: 38180,
    latitude: 17.983,
    longitude: 103.683,
  },
  {
    province: "บึงกาฬ",
    district: "พรเจริญ",
    subdistrict: "ป่าแฝก",
    zipcode: 38180,
    latitude: 18.094,
    longitude: 103.698,
  },
  {
    province: "บึงกาฬ",
    district: "พรเจริญ",
    subdistrict: "ศรีสำราญ",
    zipcode: 38180,
    latitude: 18.121,
    longitude: 103.646,
  },
  {
    province: "บึงกาฬ",
    district: "โซ่พิสัย",
    subdistrict: "โซ่",
    zipcode: 38170,
    latitude: 18.039,
    longitude: 103.391,
  },
  {
    province: "บึงกาฬ",
    district: "โซ่พิสัย",
    subdistrict: "หนองพันทา",
    zipcode: 38170,
    latitude: 18.129,
    longitude: 103.384,
  },
  {
    province: "บึงกาฬ",
    district: "โซ่พิสัย",
    subdistrict: "ศรีชมภู",
    zipcode: 38170,
    latitude: 18.271,
    longitude: 103.464,
  },
  {
    province: "บึงกาฬ",
    district: "โซ่พิสัย",
    subdistrict: "คำแก้ว",
    zipcode: 38170,
    latitude: 18.093,
    longitude: 103.489,
  },
  {
    province: "บึงกาฬ",
    district: "โซ่พิสัย",
    subdistrict: "บัวตูม",
    zipcode: 38170,
    latitude: 18.072,
    longitude: 103.364,
  },
  {
    province: "บึงกาฬ",
    district: "โซ่พิสัย",
    subdistrict: "ถ้ำเจริญ",
    zipcode: 38170,
    latitude: 18.208,
    longitude: 103.497,
  },
  {
    province: "บึงกาฬ",
    district: "โซ่พิสัย",
    subdistrict: "เหล่าทอง",
    zipcode: 38170,
    latitude: 18.144,
    longitude: 103.442,
  },
  {
    province: "บึงกาฬ",
    district: "เซกา",
    subdistrict: "เซกา",
    zipcode: 38150,
    latitude: 17.964,
    longitude: 103.945,
  },
  {
    province: "บึงกาฬ",
    district: "เซกา",
    subdistrict: "ซาง",
    zipcode: 38150,
    latitude: 17.839,
    longitude: 103.958,
  },
  {
    province: "บึงกาฬ",
    district: "เซกา",
    subdistrict: "ท่ากกแดง",
    zipcode: 38150,
    latitude: 17.911,
    longitude: 103.816,
  },
  {
    province: "บึงกาฬ",
    district: "เซกา",
    subdistrict: "บ้านต้อง",
    zipcode: 38150,
    latitude: 18.099,
    longitude: 103.946,
  },
  {
    province: "บึงกาฬ",
    district: "เซกา",
    subdistrict: "ป่งไฮ",
    zipcode: 38150,
    latitude: 17.976,
    longitude: 103.873,
  },
  {
    province: "บึงกาฬ",
    district: "เซกา",
    subdistrict: "น้ำจั้น",
    zipcode: 38150,
    latitude: 18.063,
    longitude: 103.894,
  },
  {
    province: "บึงกาฬ",
    district: "เซกา",
    subdistrict: "ท่าสะอาด",
    zipcode: 38150,
    latitude: 17.975,
    longitude: 103.744,
  },
  {
    province: "บึงกาฬ",
    district: "เซกา",
    subdistrict: "หนองทุ่ม",
    zipcode: 38150,
    latitude: 17.914,
    longitude: 103.891,
  },
  {
    province: "บึงกาฬ",
    district: "เซกา",
    subdistrict: "โสกก่าม",
    zipcode: 38150,
    latitude: 18.075,
    longitude: 104.015,
  },
  {
    province: "บึงกาฬ",
    district: "ปากคาด",
    subdistrict: "ปากคาด",
    zipcode: 38190,
    latitude: 18.343,
    longitude: 103.316,
  },
  {
    province: "บึงกาฬ",
    district: "ปากคาด",
    subdistrict: "หนองยอง",
    zipcode: 38190,
    latitude: 18.208,
    longitude: 103.351,
  },
  {
    province: "บึงกาฬ",
    district: "ปากคาด",
    subdistrict: "นากั้ง",
    zipcode: 38190,
    latitude: 18.382,
    longitude: 103.285,
  },
  {
    province: "บึงกาฬ",
    district: "ปากคาด",
    subdistrict: "โนนศิลา",
    zipcode: 38190,
    latitude: 18.292,
    longitude: 103.346,
  },
  {
    province: "บึงกาฬ",
    district: "ปากคาด",
    subdistrict: "สมสนุก",
    zipcode: 38190,
    latitude: 18.222,
    longitude: 103.398,
  },
  {
    province: "บึงกาฬ",
    district: "ปากคาด",
    subdistrict: "นาดง",
    zipcode: 38190,
    latitude: 18.256,
    longitude: 103.304,
  },
  {
    province: "บึงกาฬ",
    district: "บึงโขงหลง",
    subdistrict: "บึงโขงหลง",
    zipcode: 38220,
    latitude: 18.002,
    longitude: 104.045,
  },
  {
    province: "บึงกาฬ",
    district: "บึงโขงหลง",
    subdistrict: "โพธิ์หมากแข้ง",
    zipcode: 38220,
    latitude: 17.941,
    longitude: 104.075,
  },
  {
    province: "บึงกาฬ",
    district: "บึงโขงหลง",
    subdistrict: "ดงบัง",
    zipcode: 38220,
    latitude: 18.033,
    longitude: 104.133,
  },
  {
    province: "บึงกาฬ",
    district: "บึงโขงหลง",
    subdistrict: "ท่าดอกคำ",
    zipcode: 38220,
    latitude: 18.123,
    longitude: 104.058,
  },
  {
    province: "บึงกาฬ",
    district: "ศรีวิไล",
    subdistrict: "ศรีวิไล",
    zipcode: 38210,
    latitude: 18.225,
    longitude: 103.732,
  },
  {
    province: "บึงกาฬ",
    district: "ศรีวิไล",
    subdistrict: "ชุมภูพร",
    zipcode: 38210,
    latitude: 18.146,
    longitude: 103.714,
  },
  {
    province: "บึงกาฬ",
    district: "ศรีวิไล",
    subdistrict: "นาแสง",
    zipcode: 38210,
    latitude: 18.082,
    longitude: 103.843,
  },
  {
    province: "บึงกาฬ",
    district: "ศรีวิไล",
    subdistrict: "นาสะแบง",
    zipcode: 38210,
    latitude: 18.14,
    longitude: 103.814,
  },
  {
    province: "บึงกาฬ",
    district: "ศรีวิไล",
    subdistrict: "นาสิงห์",
    zipcode: 38210,
    latitude: 18.212,
    longitude: 103.789,
  },
  {
    province: "บึงกาฬ",
    district: "บุ่งคล้า",
    subdistrict: "บุ่งคล้า",
    zipcode: 38000,
    latitude: 18.252,
    longitude: 103.962,
  },
  {
    province: "บึงกาฬ",
    district: "บุ่งคล้า",
    subdistrict: "หนองเดิ่น",
    zipcode: 38000,
    latitude: 18.294,
    longitude: 103.925,
  },
  {
    province: "บึงกาฬ",
    district: "บุ่งคล้า",
    subdistrict: "โคกกว้าง",
    zipcode: 38000,
    latitude: 18.218,
    longitude: 104.01,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "หนองบัว",
    zipcode: 39000,
    latitude: 17.168,
    longitude: 102.444,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "หนองภัยศูนย์",
    zipcode: 39000,
    latitude: 17.263,
    longitude: 102.395,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "โพธิ์ชัย",
    zipcode: 39000,
    latitude: 17.211,
    longitude: 102.381,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "หนองสวรรค์",
    zipcode: 39000,
    latitude: 17.2,
    longitude: 102.317,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "หัวนา",
    zipcode: 39000,
    latitude: 17.032,
    longitude: 102.39,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "บ้านขาม",
    zipcode: 39000,
    latitude: 17.111,
    longitude: 102.477,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "นามะเฟือง",
    zipcode: 39000,
    latitude: 17.046,
    longitude: 102.506,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "บ้านพร้าว",
    zipcode: 39000,
    latitude: 17.102,
    longitude: 102.392,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "โนนขมิ้น",
    zipcode: 39000,
    latitude: 17.164,
    longitude: 102.283,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "ลำภู",
    zipcode: 39000,
    latitude: 17.243,
    longitude: 102.44,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "กุดจิก",
    zipcode: 39000,
    latitude: 17.333,
    longitude: 102.372,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "โนนทัน",
    zipcode: 39000,
    latitude: 17.245,
    longitude: 102.503,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "นาคำไฮ",
    zipcode: 39000,
    latitude: 17.28,
    longitude: 102.304,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "ป่าไม้งาม",
    zipcode: 39000,
    latitude: 16.974,
    longitude: 102.366,
  },
  {
    province: "หนองบัวลำภู",
    district: "เมืองหนองบัวลำภู",
    subdistrict: "หนองหว้า",
    zipcode: 39000,
    latitude: 17.147,
    longitude: 102.35,
  },
  {
    province: "หนองบัวลำภู",
    district: "นากลาง",
    subdistrict: "นากลาง",
    zipcode: 39170,
    latitude: 17.312,
    longitude: 102.214,
  },
  {
    province: "หนองบัวลำภู",
    district: "นากลาง",
    subdistrict: "ด่านช้าง",
    zipcode: 39170,
    latitude: 17.315,
    longitude: 102.14,
  },
  {
    province: "หนองบัวลำภู",
    district: "นากลาง",
    subdistrict: "กุดดินจี่",
    zipcode: 39350,
    latitude: 17.349,
    longitude: 102.273,
  },
  {
    province: "หนองบัวลำภู",
    district: "นากลาง",
    subdistrict: "ฝั่งแดง",
    zipcode: 39170,
    latitude: 17.221,
    longitude: 102.223,
  },
  {
    province: "หนองบัวลำภู",
    district: "นากลาง",
    subdistrict: "เก่ากลอย",
    zipcode: 39350,
    latitude: 17.397,
    longitude: 102.339,
  },
  {
    province: "หนองบัวลำภู",
    district: "นากลาง",
    subdistrict: "โนนเมือง",
    zipcode: 39170,
    latitude: 17.181,
    longitude: 102.154,
  },
  {
    province: "หนองบัวลำภู",
    district: "นากลาง",
    subdistrict: "อุทัยสวรรค์",
    zipcode: 39170,
    latitude: 17.235,
    longitude: 102.143,
  },
  {
    province: "หนองบัวลำภู",
    district: "นากลาง",
    subdistrict: "ดงสวรรค์",
    zipcode: 39350,
    latitude: 17.443,
    longitude: 102.193,
  },
  {
    province: "หนองบัวลำภู",
    district: "นากลาง",
    subdistrict: "กุดแห่",
    zipcode: 39170,
    latitude: 17.391,
    longitude: 102.149,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "โนนสัง",
    zipcode: 39140,
    latitude: 16.872,
    longitude: 102.56,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "บ้านถิ่น",
    zipcode: 39140,
    latitude: 16.987,
    longitude: 102.527,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "หนองเรือ",
    zipcode: 39140,
    latitude: 16.844,
    longitude: 102.504,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "กุดดู่",
    zipcode: 39140,
    latitude: 16.971,
    longitude: 102.65,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "บ้านค้อ",
    zipcode: 39140,
    latitude: 16.855,
    longitude: 102.601,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "โนนเมือง",
    zipcode: 39140,
    latitude: 16.84,
    longitude: 102.437,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "โคกใหญ่",
    zipcode: 39140,
    latitude: 16.809,
    longitude: 102.569,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "โคกม่วง",
    zipcode: 39140,
    latitude: 16.892,
    longitude: 102.4,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "นิคมพัฒนา",
    zipcode: 39140,
    latitude: 16.93,
    longitude: 102.524,
  },
  {
    province: "หนองบัวลำภู",
    district: "โนนสัง",
    subdistrict: "ปางกู่",
    zipcode: 39140,
    latitude: 17.003,
    longitude: 102.593,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "เมืองใหม่",
    zipcode: 39180,
    latitude: 16.992,
    longitude: 102.291,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "ศรีบุญเรือง",
    zipcode: 39180,
    latitude: 16.922,
    longitude: 102.302,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "หนองบัวใต้",
    zipcode: 39180,
    latitude: 16.92,
    longitude: 102.353,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "กุดสะเทียน",
    zipcode: 39180,
    latitude: 17.056,
    longitude: 102.238,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "นากอก",
    zipcode: 39180,
    latitude: 16.904,
    longitude: 102.201,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "โนนสะอาด",
    zipcode: 39180,
    latitude: 16.977,
    longitude: 102.176,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "ยางหล่อ",
    zipcode: 39180,
    latitude: 17.062,
    longitude: 102.159,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "โนนม่วง",
    zipcode: 39180,
    latitude: 17.128,
    longitude: 102.181,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "หนองกุงแก้ว",
    zipcode: 39180,
    latitude: 17.149,
    longitude: 102.073,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "หนองแก",
    zipcode: 39180,
    latitude: 17.083,
    longitude: 102.294,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "ทรายทอง",
    zipcode: 39180,
    latitude: 16.856,
    longitude: 102.349,
  },
  {
    province: "หนองบัวลำภู",
    district: "ศรีบุญเรือง",
    subdistrict: "หันนางาม",
    zipcode: 39180,
    latitude: 16.985,
    longitude: 102.246,
  },
  {
    province: "หนองบัวลำภู",
    district: "สุวรรณคูหา",
    subdistrict: "นาสี",
    zipcode: 39270,
    latitude: 17.631,
    longitude: 102.313,
  },
  {
    province: "หนองบัวลำภู",
    district: "สุวรรณคูหา",
    subdistrict: "บ้านโคก",
    zipcode: 39270,
    latitude: 17.528,
    longitude: 102.199,
  },
  {
    province: "หนองบัวลำภู",
    district: "สุวรรณคูหา",
    subdistrict: "นาดี",
    zipcode: 39270,
    latitude: 17.473,
    longitude: 102.269,
  },
  {
    province: "หนองบัวลำภู",
    district: "สุวรรณคูหา",
    subdistrict: "นาด่าน",
    zipcode: 39270,
    latitude: 17.47,
    longitude: 102.347,
  },
  {
    province: "หนองบัวลำภู",
    district: "สุวรรณคูหา",
    subdistrict: "ดงมะไฟ",
    zipcode: 39270,
    latitude: 17.635,
    longitude: 102.245,
  },
  {
    province: "หนองบัวลำภู",
    district: "สุวรรณคูหา",
    subdistrict: "สุวรรณคูหา",
    zipcode: 39270,
    latitude: 17.551,
    longitude: 102.277,
  },
  {
    province: "หนองบัวลำภู",
    district: "สุวรรณคูหา",
    subdistrict: "บุญทัน",
    zipcode: 39270,
    latitude: 17.582,
    longitude: 102.14,
  },
  {
    province: "หนองบัวลำภู",
    district: "สุวรรณคูหา",
    subdistrict: "กุดผึ้ง",
    zipcode: 39270,
    latitude: 17.549,
    longitude: 102.315,
  },
  {
    province: "หนองบัวลำภู",
    district: "นาวัง",
    subdistrict: "นาเหล่า",
    zipcode: 39170,
    latitude: 17.294,
    longitude: 102.073,
  },
  {
    province: "หนองบัวลำภู",
    district: "นาวัง",
    subdistrict: "นาแก",
    zipcode: 39170,
    latitude: 17.396,
    longitude: 102.078,
  },
  {
    province: "หนองบัวลำภู",
    district: "นาวัง",
    subdistrict: "วังทอง",
    zipcode: 39170,
    latitude: 17.32,
    longitude: 102.03,
  },
  {
    province: "หนองบัวลำภู",
    district: "นาวัง",
    subdistrict: "วังปลาป้อม",
    zipcode: 39170,
    latitude: 17.426,
    longitude: 102.024,
  },
  {
    province: "หนองบัวลำภู",
    district: "นาวัง",
    subdistrict: "เทพคีรี",
    zipcode: 39170,
    latitude: 17.308,
    longitude: 102.117,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "ในเมือง",
    zipcode: 40000,
    latitude: 16.438,
    longitude: 102.837,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "สำราญ",
    zipcode: 40000,
    latitude: 16.537,
    longitude: 102.837,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "โคกสี",
    zipcode: 40000,
    latitude: 16.481,
    longitude: 102.985,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "ท่าพระ",
    zipcode: 40260,
    latitude: 16.314,
    longitude: 102.813,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "บ้านทุ่ม",
    zipcode: 40000,
    latitude: 16.459,
    longitude: 102.701,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "เมืองเก่า",
    zipcode: 40000,
    latitude: 16.385,
    longitude: 102.809,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "พระลับ",
    zipcode: 40000,
    latitude: 16.401,
    longitude: 102.885,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "สาวะถี",
    zipcode: 40000,
    latitude: 16.538,
    longitude: 102.684,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "บ้านหว้า",
    zipcode: 40000,
    latitude: 16.384,
    longitude: 102.713,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "บ้านค้อ",
    zipcode: 40000,
    latitude: 16.569,
    longitude: 102.753,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "แดงใหญ่",
    zipcode: 40000,
    latitude: 16.485,
    longitude: 102.752,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "ดอนช้าง",
    zipcode: 40000,
    latitude: 16.363,
    longitude: 102.759,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "ดอนหัน",
    zipcode: 40260,
    latitude: 16.334,
    longitude: 102.869,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "ศิลา",
    zipcode: 40000,
    latitude: 16.49,
    longitude: 102.857,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "บ้านเป็ด",
    zipcode: 40000,
    latitude: 16.435,
    longitude: 102.781,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "หนองตูม",
    zipcode: 40000,
    latitude: 16.516,
    longitude: 102.931,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "บึงเนียม",
    zipcode: 40000,
    latitude: 16.43,
    longitude: 102.922,
  },
  {
    province: "ขอนแก่น",
    district: "เมืองขอนแก่น",
    subdistrict: "โนนท่อน",
    zipcode: 40000,
    latitude: 16.595,
    longitude: 102.837,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านฝาง",
    subdistrict: "หนองบัว",
    zipcode: 40270,
    latitude: 16.497,
    longitude: 102.596,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านฝาง",
    subdistrict: "ป่าหวายนั่ง",
    zipcode: 40270,
    latitude: 16.609,
    longitude: 102.65,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านฝาง",
    subdistrict: "โนนฆ้อง",
    zipcode: 40270,
    latitude: 16.416,
    longitude: 102.589,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านฝาง",
    subdistrict: "บ้านเหล่า",
    zipcode: 40270,
    latitude: 16.399,
    longitude: 102.65,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านฝาง",
    subdistrict: "ป่ามะนาว",
    zipcode: 40270,
    latitude: 16.367,
    longitude: 102.573,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านฝาง",
    subdistrict: "บ้านฝาง",
    zipcode: 40270,
    latitude: 16.443,
    longitude: 102.654,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านฝาง",
    subdistrict: "โคกงาม",
    zipcode: 40270,
    latitude: 16.582,
    longitude: 102.6,
  },
  {
    province: "ขอนแก่น",
    district: "พระยืน",
    subdistrict: "พระยืน",
    zipcode: 40320,
    latitude: 16.323,
    longitude: 102.612,
  },
  {
    province: "ขอนแก่น",
    district: "พระยืน",
    subdistrict: "พระบุ",
    zipcode: 40320,
    latitude: 16.265,
    longitude: 102.697,
  },
  {
    province: "ขอนแก่น",
    district: "พระยืน",
    subdistrict: "บ้านโต้น",
    zipcode: 40320,
    latitude: 16.274,
    longitude: 102.751,
  },
  {
    province: "ขอนแก่น",
    district: "พระยืน",
    subdistrict: "หนองแวง",
    zipcode: 40320,
    latitude: 16.318,
    longitude: 102.738,
  },
  {
    province: "ขอนแก่น",
    district: "พระยืน",
    subdistrict: "ขามป้อม",
    zipcode: 40320,
    latitude: 16.301,
    longitude: 102.657,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "หนองเรือ",
    zipcode: 40210,
    latitude: 16.501,
    longitude: 102.464,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "บ้านเม็ง",
    zipcode: 40210,
    latitude: 16.435,
    longitude: 102.46,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "บ้านกง",
    zipcode: 40240,
    latitude: 16.5,
    longitude: 102.553,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "ยางคำ",
    zipcode: 40240,
    latitude: 16.398,
    longitude: 102.515,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "จระเข้",
    zipcode: 40240,
    latitude: 16.458,
    longitude: 102.536,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "โนนทอง",
    zipcode: 40210,
    latitude: 16.552,
    longitude: 102.384,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "กุดกว้าง",
    zipcode: 40210,
    latitude: 16.475,
    longitude: 102.32,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "โนนทัน",
    zipcode: 40210,
    latitude: 16.507,
    longitude: 102.377,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "โนนสะอาด",
    zipcode: 40210,
    latitude: 16.538,
    longitude: 102.312,
  },
  {
    province: "ขอนแก่น",
    district: "หนองเรือ",
    subdistrict: "บ้านผือ",
    zipcode: 40240,
    latitude: 16.54,
    longitude: 102.556,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "ชุมแพ",
    zipcode: 40130,
    latitude: 16.526,
    longitude: 102.074,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "โนนหัน",
    zipcode: 40290,
    latitude: 16.603,
    longitude: 102.019,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "นาหนองทุ่ม",
    zipcode: 40290,
    latitude: 16.767,
    longitude: 101.98,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "โนนอุดม",
    zipcode: 40130,
    latitude: 16.505,
    longitude: 102.231,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "ขัวเรียง",
    zipcode: 40130,
    latitude: 16.561,
    longitude: 102.214,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "หนองไผ่",
    zipcode: 40130,
    latitude: 16.565,
    longitude: 102.147,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "ไชยสอ",
    zipcode: 40130,
    latitude: 16.514,
    longitude: 102.14,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "วังหินลาด",
    zipcode: 40130,
    latitude: 16.625,
    longitude: 102.093,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "นาเพียง",
    zipcode: 40130,
    latitude: 16.49,
    longitude: 102.27,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "หนองเขียด",
    zipcode: 40290,
    latitude: 16.658,
    longitude: 102.014,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "หนองเสาเล้า",
    zipcode: 40130,
    latitude: 16.586,
    longitude: 102.298,
  },
  {
    province: "ขอนแก่น",
    district: "ชุมแพ",
    subdistrict: "โนนสะอาด",
    zipcode: 40290,
    latitude: 16.576,
    longitude: 101.999,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "สีชมพู",
    zipcode: 40220,
    latitude: 16.841,
    longitude: 102.156,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "ศรีสุข",
    zipcode: 40220,
    latitude: 16.751,
    longitude: 102.206,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "นาจาน",
    zipcode: 40220,
    latitude: 16.657,
    longitude: 102.147,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "วังเพิ่ม",
    zipcode: 40220,
    latitude: 16.82,
    longitude: 102.204,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "ซำยาง",
    zipcode: 40220,
    latitude: 16.688,
    longitude: 102.091,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "หนองแดง",
    zipcode: 40220,
    latitude: 16.711,
    longitude: 102.113,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "ดงลาน",
    zipcode: 40220,
    latitude: 16.805,
    longitude: 102.075,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "บริบูรณ์",
    zipcode: 40220,
    latitude: 16.855,
    longitude: 102.092,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "บ้านใหม่",
    zipcode: 40220,
    latitude: 16.761,
    longitude: 102.068,
  },
  {
    province: "ขอนแก่น",
    district: "สีชมพู",
    subdistrict: "ภูห่าน",
    zipcode: 40220,
    latitude: 16.72,
    longitude: 102.046,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "น้ำพอง",
    zipcode: 40140,
    latitude: 16.76,
    longitude: 102.825,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "วังชัย",
    zipcode: 40140,
    latitude: 16.728,
    longitude: 102.862,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "หนองกุง",
    zipcode: 40140,
    latitude: 16.73,
    longitude: 102.897,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "บัวใหญ่",
    zipcode: 40140,
    latitude: 16.632,
    longitude: 102.951,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "สะอาด",
    zipcode: 40310,
    latitude: 16.788,
    longitude: 102.768,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "ม่วงหวาน",
    zipcode: 40310,
    latitude: 16.646,
    longitude: 102.779,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "บ้านขาม",
    zipcode: 40140,
    latitude: 16.568,
    longitude: 102.952,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "บัวเงิน",
    zipcode: 40140,
    latitude: 16.793,
    longitude: 102.995,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "ทรายมูล",
    zipcode: 40140,
    latitude: 16.669,
    longitude: 102.913,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "ท่ากระเสริม",
    zipcode: 40140,
    latitude: 16.613,
    longitude: 102.878,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "พังทุย",
    zipcode: 40140,
    latitude: 16.774,
    longitude: 102.928,
  },
  {
    province: "ขอนแก่น",
    district: "น้ำพอง",
    subdistrict: "กุดน้ำใส",
    zipcode: 40310,
    latitude: 16.697,
    longitude: 102.789,
  },
  {
    province: "ขอนแก่น",
    district: "อุบลรัตน์",
    subdistrict: "โคกสูง",
    zipcode: 40250,
    latitude: 16.679,
    longitude: 102.676,
  },
  {
    province: "ขอนแก่น",
    district: "อุบลรัตน์",
    subdistrict: "บ้านดง",
    zipcode: 40250,
    latitude: 16.801,
    longitude: 102.645,
  },
  {
    province: "ขอนแก่น",
    district: "อุบลรัตน์",
    subdistrict: "เขื่อนอุบลรัตน์",
    zipcode: 40250,
    latitude: 16.715,
    longitude: 102.636,
  },
  {
    province: "ขอนแก่น",
    district: "อุบลรัตน์",
    subdistrict: "นาคำ",
    zipcode: 40250,
    latitude: 16.855,
    longitude: 102.68,
  },
  {
    province: "ขอนแก่น",
    district: "อุบลรัตน์",
    subdistrict: "ศรีสุขสำราญ",
    zipcode: 40250,
    latitude: 16.92,
    longitude: 102.703,
  },
  {
    province: "ขอนแก่น",
    district: "อุบลรัตน์",
    subdistrict: "ทุ่งโป่ง",
    zipcode: 40250,
    latitude: 16.731,
    longitude: 102.676,
  },
  {
    province: "ขอนแก่น",
    district: "กระนวน",
    subdistrict: "หนองโก",
    zipcode: 40170,
    latitude: 16.727,
    longitude: 103.11,
  },
  {
    province: "ขอนแก่น",
    district: "กระนวน",
    subdistrict: "หนองกุงใหญ่",
    zipcode: 40170,
    latitude: 16.646,
    longitude: 103.086,
  },
  {
    province: "ขอนแก่น",
    district: "กระนวน",
    subdistrict: "ห้วยโจด",
    zipcode: 40170,
    latitude: 16.667,
    longitude: 103.022,
  },
  {
    province: "ขอนแก่น",
    district: "กระนวน",
    subdistrict: "ห้วยยาง",
    zipcode: 40170,
    latitude: 16.87,
    longitude: 103.085,
  },
  {
    province: "ขอนแก่น",
    district: "กระนวน",
    subdistrict: "บ้านฝาง",
    zipcode: 40170,
    latitude: 16.769,
    longitude: 103.05,
  },
  {
    province: "ขอนแก่น",
    district: "กระนวน",
    subdistrict: "ดูนสาด",
    zipcode: 40170,
    latitude: 16.81,
    longitude: 103.149,
  },
  {
    province: "ขอนแก่น",
    district: "กระนวน",
    subdistrict: "หนองโน",
    zipcode: 40170,
    latitude: 16.722,
    longitude: 103.03,
  },
  {
    province: "ขอนแก่น",
    district: "กระนวน",
    subdistrict: "น้ำอ้อม",
    zipcode: 40170,
    latitude: 16.671,
    longitude: 103.127,
  },
  {
    province: "ขอนแก่น",
    district: "กระนวน",
    subdistrict: "หัวนาคำ",
    zipcode: 40170,
    latitude: 16.813,
    longitude: 103.08,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "บ้านไผ่",
    zipcode: 40110,
    latitude: 16.063,
    longitude: 102.728,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "ในเมือง",
    zipcode: 40110,
    latitude: 16.035,
    longitude: 102.723,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "เมืองเพีย",
    zipcode: 40110,
    latitude: 16.102,
    longitude: 102.684,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "บ้านลาน",
    zipcode: 40110,
    latitude: 15.992,
    longitude: 102.838,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "แคนเหนือ",
    zipcode: 40110,
    latitude: 15.99,
    longitude: 102.771,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "ภูเหล็ก",
    zipcode: 40110,
    latitude: 16.115,
    longitude: 102.828,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "ป่าปอ",
    zipcode: 40110,
    latitude: 15.919,
    longitude: 102.777,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "หินตั้ง",
    zipcode: 40110,
    latitude: 16.045,
    longitude: 102.831,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "หนองน้ำใส",
    zipcode: 40110,
    latitude: 16.042,
    longitude: 102.76,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านไผ่",
    subdistrict: "หัวหนอง",
    zipcode: 40110,
    latitude: 16.04,
    longitude: 102.697,
  },
  {
    province: "ขอนแก่น",
    district: "เปือยน้อย",
    subdistrict: "เปือยน้อย",
    zipcode: 40340,
    latitude: 15.879,
    longitude: 102.904,
  },
  {
    province: "ขอนแก่น",
    district: "เปือยน้อย",
    subdistrict: "วังม่วง",
    zipcode: 40340,
    latitude: 15.884,
    longitude: 102.829,
  },
  {
    province: "ขอนแก่น",
    district: "เปือยน้อย",
    subdistrict: "ขามป้อม",
    zipcode: 40340,
    latitude: 15.938,
    longitude: 102.874,
  },
  {
    province: "ขอนแก่น",
    district: "เปือยน้อย",
    subdistrict: "สระแก้ว",
    zipcode: 40340,
    latitude: 15.85,
    longitude: 102.895,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "เมืองพล",
    zipcode: 40120,
    latitude: 15.793,
    longitude: 102.64,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "โจดหนองแก",
    zipcode: 40120,
    latitude: 15.856,
    longitude: 102.65,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "เก่างิ้ว",
    zipcode: 40120,
    latitude: 15.844,
    longitude: 102.598,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "หนองมะเขือ",
    zipcode: 40120,
    latitude: 15.753,
    longitude: 102.546,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "หนองแวงโสกพระ",
    zipcode: 40120,
    latitude: 15.746,
    longitude: 102.644,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "เพ็กใหญ่",
    zipcode: 40120,
    latitude: 15.841,
    longitude: 102.538,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "โคกสง่า",
    zipcode: 40120,
    latitude: 15.754,
    longitude: 102.488,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "หนองแวงนางเบ้า",
    zipcode: 40120,
    latitude: 15.887,
    longitude: 102.611,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "ลอมคอม",
    zipcode: 40120,
    latitude: 15.794,
    longitude: 102.525,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "โนนข่า",
    zipcode: 40120,
    latitude: 15.764,
    longitude: 102.591,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "โสกนกเต็น",
    zipcode: 40120,
    latitude: 15.816,
    longitude: 102.66,
  },
  {
    province: "ขอนแก่น",
    district: "พล",
    subdistrict: "หัวทุ่ง",
    zipcode: 40120,
    latitude: 15.879,
    longitude: 102.556,
  },
  {
    province: "ขอนแก่น",
    district: "แวงใหญ่",
    subdistrict: "คอนฉิม",
    zipcode: 40330,
    latitude: 15.947,
    longitude: 102.486,
  },
  {
    province: "ขอนแก่น",
    district: "แวงใหญ่",
    subdistrict: "ใหม่นาเพียง",
    zipcode: 40330,
    latitude: 15.906,
    longitude: 102.471,
  },
  {
    province: "ขอนแก่น",
    district: "แวงใหญ่",
    subdistrict: "โนนทอง",
    zipcode: 40330,
    latitude: 15.904,
    longitude: 102.401,
  },
  {
    province: "ขอนแก่น",
    district: "แวงใหญ่",
    subdistrict: "แวงใหญ่",
    zipcode: 40330,
    latitude: 15.954,
    longitude: 102.533,
  },
  {
    province: "ขอนแก่น",
    district: "แวงใหญ่",
    subdistrict: "โนนสะอาด",
    zipcode: 40330,
    latitude: 15.954,
    longitude: 102.421,
  },
  {
    province: "ขอนแก่น",
    district: "แวงน้อย",
    subdistrict: "แวงน้อย",
    zipcode: 40230,
    latitude: 15.813,
    longitude: 102.423,
  },
  {
    province: "ขอนแก่น",
    district: "แวงน้อย",
    subdistrict: "ก้านเหลือง",
    zipcode: 40230,
    latitude: 15.844,
    longitude: 102.464,
  },
  {
    province: "ขอนแก่น",
    district: "แวงน้อย",
    subdistrict: "ท่านางแนว",
    zipcode: 40230,
    latitude: 15.866,
    longitude: 102.372,
  },
  {
    province: "ขอนแก่น",
    district: "แวงน้อย",
    subdistrict: "ละหานนา",
    zipcode: 40230,
    latitude: 15.813,
    longitude: 102.367,
  },
  {
    province: "ขอนแก่น",
    district: "แวงน้อย",
    subdistrict: "ท่าวัด",
    zipcode: 40230,
    latitude: 15.735,
    longitude: 102.397,
  },
  {
    province: "ขอนแก่น",
    district: "แวงน้อย",
    subdistrict: "ทางขวาง",
    zipcode: 40230,
    latitude: 15.758,
    longitude: 102.429,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "หนองสองห้อง",
    zipcode: 40190,
    latitude: 15.707,
    longitude: 102.771,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "คึมชาด",
    zipcode: 40190,
    latitude: 15.818,
    longitude: 102.802,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "โนนธาตุ",
    zipcode: 40190,
    latitude: 15.705,
    longitude: 102.72,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "ตะกั่วป่า",
    zipcode: 40190,
    latitude: 15.714,
    longitude: 102.824,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "สำโรง",
    zipcode: 40190,
    latitude: 15.833,
    longitude: 102.744,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "หนองเม็ก",
    zipcode: 40190,
    latitude: 15.787,
    longitude: 102.724,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "ดอนดู่",
    zipcode: 40190,
    latitude: 15.809,
    longitude: 102.874,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "ดงเค็ง",
    zipcode: 40190,
    latitude: 15.67,
    longitude: 102.812,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "หันโจด",
    zipcode: 40190,
    latitude: 15.758,
    longitude: 102.695,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "ดอนดั่ง",
    zipcode: 40190,
    latitude: 15.77,
    longitude: 102.788,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "วังหิน",
    zipcode: 40190,
    latitude: 15.873,
    longitude: 102.742,
  },
  {
    province: "ขอนแก่น",
    district: "หนองสองห้อง",
    subdistrict: "หนองไผ่ล้อม",
    zipcode: 40190,
    latitude: 15.752,
    longitude: 102.841,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "บ้านเรือ",
    zipcode: 40150,
    latitude: 16.676,
    longitude: 102.395,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "หว้าทอง",
    zipcode: 40150,
    latitude: 16.761,
    longitude: 102.469,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "กุดขอนแก่น",
    zipcode: 40150,
    latitude: 16.58,
    longitude: 102.502,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "นาชุมแสง",
    zipcode: 40150,
    latitude: 16.602,
    longitude: 102.331,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "นาหว้า",
    zipcode: 40150,
    latitude: 16.721,
    longitude: 102.572,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "หนองกุงธนสาร",
    zipcode: 40150,
    latitude: 16.65,
    longitude: 102.437,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "หนองกุงเซิน",
    zipcode: 40150,
    latitude: 16.633,
    longitude: 102.518,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "สงเปือย",
    zipcode: 40150,
    latitude: 16.605,
    longitude: 102.394,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "ทุ่งชมพู",
    zipcode: 40150,
    latitude: 16.762,
    longitude: 102.524,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "ดินดำ",
    zipcode: 40150,
    latitude: 16.722,
    longitude: 102.398,
  },
  {
    province: "ขอนแก่น",
    district: "ภูเวียง",
    subdistrict: "ภูเวียง",
    zipcode: 40150,
    latitude: 16.679,
    longitude: 102.364,
  },
  {
    province: "ขอนแก่น",
    district: "มัญจาคีรี",
    subdistrict: "กุดเค้า",
    zipcode: 40160,
    latitude: 16.114,
    longitude: 102.492,
  },
  {
    province: "ขอนแก่น",
    district: "มัญจาคีรี",
    subdistrict: "สวนหม่อน",
    zipcode: 40160,
    latitude: 16.165,
    longitude: 102.561,
  },
  {
    province: "ขอนแก่น",
    district: "มัญจาคีรี",
    subdistrict: "หนองแปน",
    zipcode: 40160,
    latitude: 16.178,
    longitude: 102.629,
  },
  {
    province: "ขอนแก่น",
    district: "มัญจาคีรี",
    subdistrict: "โพนเพ็ก",
    zipcode: 40160,
    latitude: 16.237,
    longitude: 102.534,
  },
  {
    province: "ขอนแก่น",
    district: "มัญจาคีรี",
    subdistrict: "คำแคน",
    zipcode: 40160,
    latitude: 16.323,
    longitude: 102.476,
  },
  {
    province: "ขอนแก่น",
    district: "มัญจาคีรี",
    subdistrict: "นาข่า",
    zipcode: 40160,
    latitude: 16.183,
    longitude: 102.5,
  },
  {
    province: "ขอนแก่น",
    district: "มัญจาคีรี",
    subdistrict: "นางาม",
    zipcode: 40160,
    latitude: 16.196,
    longitude: 102.419,
  },
  {
    province: "ขอนแก่น",
    district: "มัญจาคีรี",
    subdistrict: "ท่าศาลา",
    zipcode: 40160,
    latitude: 16.269,
    longitude: 102.615,
  },
  {
    province: "ขอนแก่น",
    district: "ชนบท",
    subdistrict: "ชนบท",
    zipcode: 40180,
    latitude: 16.067,
    longitude: 102.632,
  },
  {
    province: "ขอนแก่น",
    district: "ชนบท",
    subdistrict: "กุดเพียขอม",
    zipcode: 40180,
    latitude: 16.032,
    longitude: 102.613,
  },
  {
    province: "ขอนแก่น",
    district: "ชนบท",
    subdistrict: "วังแสง",
    zipcode: 40180,
    latitude: 16.013,
    longitude: 102.482,
  },
  {
    province: "ขอนแก่น",
    district: "ชนบท",
    subdistrict: "ห้วยแก",
    zipcode: 40180,
    latitude: 15.993,
    longitude: 102.531,
  },
  {
    province: "ขอนแก่น",
    district: "ชนบท",
    subdistrict: "บ้านแท่น",
    zipcode: 40180,
    latitude: 15.966,
    longitude: 102.603,
  },
  {
    province: "ขอนแก่น",
    district: "ชนบท",
    subdistrict: "ศรีบุญเรือง",
    zipcode: 40180,
    latitude: 16.115,
    longitude: 102.623,
  },
  {
    province: "ขอนแก่น",
    district: "ชนบท",
    subdistrict: "โนนพะยอม",
    zipcode: 40180,
    latitude: 16.054,
    longitude: 102.547,
  },
  {
    province: "ขอนแก่น",
    district: "ชนบท",
    subdistrict: "ปอแดง",
    zipcode: 40180,
    latitude: 15.95,
    longitude: 102.579,
  },
  {
    province: "ขอนแก่น",
    district: "เขาสวนกวาง",
    subdistrict: "เขาสวนกวาง",
    zipcode: 40280,
    latitude: 16.861,
    longitude: 102.84,
  },
  {
    province: "ขอนแก่น",
    district: "เขาสวนกวาง",
    subdistrict: "ดงเมืองแอม",
    zipcode: 40280,
    latitude: 16.862,
    longitude: 102.767,
  },
  {
    province: "ขอนแก่น",
    district: "เขาสวนกวาง",
    subdistrict: "นางิ้ว",
    zipcode: 40280,
    latitude: 16.932,
    longitude: 102.784,
  },
  {
    province: "ขอนแก่น",
    district: "เขาสวนกวาง",
    subdistrict: "โนนสมบูรณ์",
    zipcode: 40280,
    latitude: 17.025,
    longitude: 102.709,
  },
  {
    province: "ขอนแก่น",
    district: "เขาสวนกวาง",
    subdistrict: "คำม่วง",
    zipcode: 40280,
    latitude: 16.819,
    longitude: 102.867,
  },
  {
    province: "ขอนแก่น",
    district: "ภูผาม่าน",
    subdistrict: "โนนคอม",
    zipcode: 40350,
    latitude: 16.648,
    longitude: 101.935,
  },
  {
    province: "ขอนแก่น",
    district: "ภูผาม่าน",
    subdistrict: "นาฝาย",
    zipcode: 40350,
    latitude: 16.68,
    longitude: 101.871,
  },
  {
    province: "ขอนแก่น",
    district: "ภูผาม่าน",
    subdistrict: "ภูผาม่าน",
    zipcode: 40350,
    latitude: 16.677,
    longitude: 101.91,
  },
  {
    province: "ขอนแก่น",
    district: "ภูผาม่าน",
    subdistrict: "วังสวาบ",
    zipcode: 40350,
    latitude: 16.714,
    longitude: 101.817,
  },
  {
    province: "ขอนแก่น",
    district: "ภูผาม่าน",
    subdistrict: "ห้วยม่วง",
    zipcode: 40350,
    latitude: 16.765,
    longitude: 101.909,
  },
  {
    province: "ขอนแก่น",
    district: "ซำสูง",
    subdistrict: "กระนวน",
    zipcode: 40170,
    latitude: 16.534,
    longitude: 103.069,
  },
  {
    province: "ขอนแก่น",
    district: "ซำสูง",
    subdistrict: "คำแมด",
    zipcode: 40170,
    latitude: 16.616,
    longitude: 103.122,
  },
  {
    province: "ขอนแก่น",
    district: "ซำสูง",
    subdistrict: "บ้านโนน",
    zipcode: 40170,
    latitude: 16.56,
    longitude: 103.027,
  },
  {
    province: "ขอนแก่น",
    district: "ซำสูง",
    subdistrict: "คูคำ",
    zipcode: 40170,
    latitude: 16.508,
    longitude: 103.014,
  },
  {
    province: "ขอนแก่น",
    district: "ซำสูง",
    subdistrict: "ห้วยเตย",
    zipcode: 40170,
    latitude: 16.582,
    longitude: 103.089,
  },
  {
    province: "ขอนแก่น",
    district: "โคกโพธิ์ไชย",
    subdistrict: "บ้านโคก",
    zipcode: 40160,
    latitude: 16.112,
    longitude: 102.378,
  },
  {
    province: "ขอนแก่น",
    district: "โคกโพธิ์ไชย",
    subdistrict: "โพธิ์ไชย",
    zipcode: 40160,
    latitude: 16.027,
    longitude: 102.399,
  },
  {
    province: "ขอนแก่น",
    district: "โคกโพธิ์ไชย",
    subdistrict: "ซับสมบูรณ์",
    zipcode: 40160,
    latitude: 16.074,
    longitude: 102.329,
  },
  {
    province: "ขอนแก่น",
    district: "โคกโพธิ์ไชย",
    subdistrict: "นาแพง",
    zipcode: 40160,
    latitude: 16.078,
    longitude: 102.472,
  },
  {
    province: "ขอนแก่น",
    district: "หนองนาคำ",
    subdistrict: "กุดธาตุ",
    zipcode: 40150,
    latitude: 16.834,
    longitude: 102.308,
  },
  {
    province: "ขอนแก่น",
    district: "หนองนาคำ",
    subdistrict: "บ้านโคก",
    zipcode: 40150,
    latitude: 16.791,
    longitude: 102.335,
  },
  {
    province: "ขอนแก่น",
    district: "หนองนาคำ",
    subdistrict: "ขนวน",
    zipcode: 40150,
    latitude: 16.774,
    longitude: 102.387,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านแฮด",
    subdistrict: "บ้านแฮด",
    zipcode: 40110,
    latitude: 16.211,
    longitude: 102.778,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านแฮด",
    subdistrict: "โคกสำราญ",
    zipcode: 40110,
    latitude: 16.19,
    longitude: 102.71,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านแฮด",
    subdistrict: "โนนสมบูรณ์",
    zipcode: 40110,
    latitude: 16.259,
    longitude: 102.788,
  },
  {
    province: "ขอนแก่น",
    district: "บ้านแฮด",
    subdistrict: "หนองแซง",
    zipcode: 40110,
    latitude: 16.175,
    longitude: 102.809,
  },
  {
    province: "ขอนแก่น",
    district: "โนนศิลา",
    subdistrict: "โนนศิลา",
    zipcode: 40110,
    latitude: 15.94,
    longitude: 102.664,
  },
  {
    province: "ขอนแก่น",
    district: "โนนศิลา",
    subdistrict: "หนองปลาหมอ",
    zipcode: 40110,
    latitude: 15.989,
    longitude: 102.635,
  },
  {
    province: "ขอนแก่น",
    district: "โนนศิลา",
    subdistrict: "บ้านหัน",
    zipcode: 40110,
    latitude: 15.94,
    longitude: 102.72,
  },
  {
    province: "ขอนแก่น",
    district: "โนนศิลา",
    subdistrict: "เปือยใหญ่",
    zipcode: 40110,
    latitude: 16.027,
    longitude: 102.651,
  },
  {
    province: "ขอนแก่น",
    district: "โนนศิลา",
    subdistrict: "โนนแดง",
    zipcode: 40110,
    latitude: 16.005,
    longitude: 102.687,
  },
  {
    province: "ขอนแก่น",
    district: "เวียงเก่า",
    subdistrict: "ในเมือง",
    zipcode: 40150,
    latitude: 16.742,
    longitude: 102.259,
  },
  {
    province: "ขอนแก่น",
    district: "เวียงเก่า",
    subdistrict: "เมืองเก่าพัฒนา",
    zipcode: 40150,
    latitude: 16.625,
    longitude: 102.206,
  },
  {
    province: "ขอนแก่น",
    district: "เวียงเก่า",
    subdistrict: "เขาน้อย",
    zipcode: 40150,
    latitude: 16.723,
    longitude: 102.33,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "หมากแข้ง",
    zipcode: 41000,
    latitude: 17.411,
    longitude: 102.792,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "นิคมสงเคราะห์",
    zipcode: 41000,
    latitude: 17.365,
    longitude: 102.688,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "บ้านขาว",
    zipcode: 41000,
    latitude: 17.545,
    longitude: 102.775,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "หนองบัว",
    zipcode: 41000,
    latitude: 17.406,
    longitude: 102.822,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "บ้านตาด",
    zipcode: 41000,
    latitude: 17.292,
    longitude: 102.762,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "โนนสูง",
    zipcode: 41330,
    latitude: 17.287,
    longitude: 102.846,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "หมูม่น",
    zipcode: 41000,
    latitude: 17.469,
    longitude: 102.771,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "เชียงยืน",
    zipcode: 41000,
    latitude: 17.431,
    longitude: 102.651,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "หนองนาคำ",
    zipcode: 41000,
    latitude: 17.367,
    longitude: 102.896,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "กุดสระ",
    zipcode: 41000,
    latitude: 17.491,
    longitude: 102.839,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "นาดี",
    zipcode: 41000,
    latitude: 17.3548841,
    longitude: 102.7067617,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "บ้านเลื่อม",
    zipcode: 41000,
    latitude: 17.421,
    longitude: 102.764,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "เชียงพิณ",
    zipcode: 41000,
    latitude: 17.417,
    longitude: 102.699,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "สามพร้าว",
    zipcode: 41000,
    latitude: 17.456,
    longitude: 102.886,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "หนองไฮ",
    zipcode: 41000,
    latitude: 17.271,
    longitude: 102.697,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "นาข่า",
    zipcode: 41000,
    latitude: 17.54,
    longitude: 102.849,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "บ้านจั่น",
    zipcode: 41000,
    latitude: 17.343,
    longitude: 102.779,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "หนองขอนกว้าง",
    zipcode: 41000,
    latitude: 17.376,
    longitude: 102.816,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "โคกสะอาด",
    zipcode: 41000,
    latitude: 17.356,
    longitude: 102.638,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "นากว้าง",
    zipcode: 41000,
    latitude: 17.533,
    longitude: 102.701,
  },
  {
    province: "อุดรธานี",
    district: "เมืองอุดรธานี",
    subdistrict: "หนองไผ่",
    zipcode: 41330,
    latitude: 17.256,
    longitude: 102.898,
  },
  {
    province: "อุดรธานี",
    district: "กุดจับ",
    subdistrict: "กุดจับ",
    zipcode: 41250,
    latitude: 17.382,
    longitude: 102.515,
  },
  {
    province: "อุดรธานี",
    district: "กุดจับ",
    subdistrict: "ปะโค",
    zipcode: 41250,
    latitude: 17.48,
    longitude: 102.596,
  },
  {
    province: "อุดรธานี",
    district: "กุดจับ",
    subdistrict: "ขอนยูง",
    zipcode: 41250,
    latitude: 17.385,
    longitude: 102.46,
  },
  {
    province: "อุดรธานี",
    district: "กุดจับ",
    subdistrict: "เชียงเพ็ง",
    zipcode: 41250,
    latitude: 17.49,
    longitude: 102.652,
  },
  {
    province: "อุดรธานี",
    district: "กุดจับ",
    subdistrict: "สร้างก่อ",
    zipcode: 41250,
    latitude: 17.479,
    longitude: 102.506,
  },
  {
    province: "อุดรธานี",
    district: "กุดจับ",
    subdistrict: "เมืองเพีย",
    zipcode: 41250,
    latitude: 17.411,
    longitude: 102.574,
  },
  {
    province: "อุดรธานี",
    district: "กุดจับ",
    subdistrict: "ตาลเลียน",
    zipcode: 41250,
    latitude: 17.44,
    longitude: 102.429,
  },
  {
    province: "อุดรธานี",
    district: "หนองวัวซอ",
    subdistrict: "หมากหญ้า",
    zipcode: 41360,
    latitude: 17.238,
    longitude: 102.628,
  },
  {
    province: "อุดรธานี",
    district: "หนองวัวซอ",
    subdistrict: "หนองอ้อ",
    zipcode: 41220,
    latitude: 17.187,
    longitude: 102.541,
  },
  {
    province: "อุดรธานี",
    district: "หนองวัวซอ",
    subdistrict: "อูบมุง",
    zipcode: 41220,
    latitude: 17.087,
    longitude: 102.564,
  },
  {
    province: "อุดรธานี",
    district: "หนองวัวซอ",
    subdistrict: "กุดหมากไฟ",
    zipcode: 41220,
    latitude: 17.057,
    longitude: 102.645,
  },
  {
    province: "อุดรธานี",
    district: "หนองวัวซอ",
    subdistrict: "น้ำพ่น",
    zipcode: 41360,
    latitude: 17.309,
    longitude: 102.473,
  },
  {
    province: "อุดรธานี",
    district: "หนองวัวซอ",
    subdistrict: "หนองบัวบาน",
    zipcode: 41360,
    latitude: 17.288,
    longitude: 102.645,
  },
  {
    province: "อุดรธานี",
    district: "หนองวัวซอ",
    subdistrict: "โนนหวาย",
    zipcode: 41220,
    latitude: 17.142,
    longitude: 102.601,
  },
  {
    province: "อุดรธานี",
    district: "หนองวัวซอ",
    subdistrict: "หนองวัวซอ",
    zipcode: 41360,
    latitude: 17.288,
    longitude: 102.576,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "ตูมใต้",
    zipcode: 41110,
    latitude: 17.084,
    longitude: 102.963,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "พันดอน",
    zipcode: 41370,
    latitude: 17.137,
    longitude: 102.938,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "เวียงคำ",
    zipcode: 41110,
    latitude: 17.097,
    longitude: 103.116,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "แชแล",
    zipcode: 41110,
    latitude: 17.153,
    longitude: 103.075,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "เชียงแหว",
    zipcode: 41110,
    latitude: 17.198,
    longitude: 103.026,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "ห้วยเกิ้ง",
    zipcode: 41110,
    latitude: 17.044,
    longitude: 102.934,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "เสอเพลอ",
    zipcode: 41370,
    latitude: 17.202,
    longitude: 102.874,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "สีออ",
    zipcode: 41110,
    latitude: 16.995,
    longitude: 103.038,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "ปะโค",
    zipcode: 41370,
    latitude: 17.056,
    longitude: 102.896,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "ผาสุก",
    zipcode: 41370,
    latitude: 17.169,
    longitude: 102.94,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "ท่าลี่",
    zipcode: 41110,
    latitude: 16.933,
    longitude: 103.064,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "กุมภวาปี",
    zipcode: 41110,
    latitude: 17.112,
    longitude: 103.009,
  },
  {
    province: "อุดรธานี",
    district: "กุมภวาปี",
    subdistrict: "หนองหว้า",
    zipcode: 41110,
    latitude: 17.033,
    longitude: 102.981,
  },
  {
    province: "อุดรธานี",
    district: "โนนสะอาด",
    subdistrict: "โนนสะอาด",
    zipcode: 41240,
    latitude: 16.977,
    longitude: 102.902,
  },
  {
    province: "อุดรธานี",
    district: "โนนสะอาด",
    subdistrict: "บุ่งแก้ว",
    zipcode: 41240,
    latitude: 16.969,
    longitude: 102.981,
  },
  {
    province: "อุดรธานี",
    district: "โนนสะอาด",
    subdistrict: "โพธิ์ศรีสำราญ",
    zipcode: 41240,
    latitude: 16.983,
    longitude: 102.839,
  },
  {
    province: "อุดรธานี",
    district: "โนนสะอาด",
    subdistrict: "ทมนางาม",
    zipcode: 41240,
    latitude: 16.888,
    longitude: 102.956,
  },
  {
    province: "อุดรธานี",
    district: "โนนสะอาด",
    subdistrict: "หนองกุงศรี",
    zipcode: 41240,
    latitude: 17.048,
    longitude: 102.772,
  },
  {
    province: "อุดรธานี",
    district: "โนนสะอาด",
    subdistrict: "โคกกลาง",
    zipcode: 41240,
    latitude: 16.929,
    longitude: 102.911,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "หนองหาน",
    zipcode: 41130,
    latitude: 17.381,
    longitude: 103.095,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "หนองเม็ก",
    zipcode: 41130,
    latitude: 17.355,
    longitude: 103.169,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "พังงู",
    zipcode: 41130,
    latitude: 17.257,
    longitude: 103.117,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "สะแบง",
    zipcode: 41130,
    latitude: 17.453,
    longitude: 103.116,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "สร้อยพร้าว",
    zipcode: 41130,
    latitude: 17.43,
    longitude: 103.056,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "บ้านเชียง",
    zipcode: 41320,
    latitude: 17.398,
    longitude: 103.228,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "บ้านยา",
    zipcode: 41320,
    latitude: 17.395,
    longitude: 103.273,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "โพนงาม",
    zipcode: 41130,
    latitude: 17.36,
    longitude: 102.968,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "ผักตบ",
    zipcode: 41130,
    latitude: 17.339,
    longitude: 103.021,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "หนองไผ่",
    zipcode: 41130,
    latitude: 17.327,
    longitude: 103.111,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "ดอนหายโศก",
    zipcode: 41130,
    latitude: 17.422,
    longitude: 102.993,
  },
  {
    province: "อุดรธานี",
    district: "หนองหาน",
    subdistrict: "หนองสระปลา",
    zipcode: 41320,
    latitude: 17.348,
    longitude: 103.254,
  },
  {
    province: "อุดรธานี",
    district: "ทุ่งฝน",
    subdistrict: "ทุ่งฝน",
    zipcode: 41310,
    latitude: 17.478,
    longitude: 103.263,
  },
  {
    province: "อุดรธานี",
    district: "ทุ่งฝน",
    subdistrict: "ทุ่งใหญ่",
    zipcode: 41310,
    latitude: 17.475,
    longitude: 103.174,
  },
  {
    province: "อุดรธานี",
    district: "ทุ่งฝน",
    subdistrict: "นาชุมแสง",
    zipcode: 41310,
    latitude: 17.561,
    longitude: 103.193,
  },
  {
    province: "อุดรธานี",
    district: "ทุ่งฝน",
    subdistrict: "นาทม",
    zipcode: 41310,
    latitude: 17.542,
    longitude: 103.159,
  },
  {
    province: "อุดรธานี",
    district: "ไชยวาน",
    subdistrict: "ไชยวาน",
    zipcode: 41290,
    latitude: 17.303,
    longitude: 103.205,
  },
  {
    province: "อุดรธานี",
    district: "ไชยวาน",
    subdistrict: "หนองหลัก",
    zipcode: 41290,
    latitude: 17.237,
    longitude: 103.204,
  },
  {
    province: "อุดรธานี",
    district: "ไชยวาน",
    subdistrict: "คำเลาะ",
    zipcode: 41290,
    latitude: 17.212,
    longitude: 103.345,
  },
  {
    province: "อุดรธานี",
    district: "ไชยวาน",
    subdistrict: "โพนสูง",
    zipcode: 41290,
    latitude: 17.173,
    longitude: 103.24,
  },
  {
    province: "อุดรธานี",
    district: "ศรีธาตุ",
    subdistrict: "ศรีธาตุ",
    zipcode: 41230,
    latitude: 16.987,
    longitude: 103.211,
  },
  {
    province: "อุดรธานี",
    district: "ศรีธาตุ",
    subdistrict: "จำปี",
    zipcode: 41230,
    latitude: 16.998,
    longitude: 103.171,
  },
  {
    province: "อุดรธานี",
    district: "ศรีธาตุ",
    subdistrict: "บ้านโปร่ง",
    zipcode: 41230,
    latitude: 16.991,
    longitude: 103.089,
  },
  {
    province: "อุดรธานี",
    district: "ศรีธาตุ",
    subdistrict: "หัวนาคำ",
    zipcode: 41230,
    latitude: 17.083,
    longitude: 103.284,
  },
  {
    province: "อุดรธานี",
    district: "ศรีธาตุ",
    subdistrict: "หนองนกเขียน",
    zipcode: 41230,
    latitude: 17.012,
    longitude: 103.291,
  },
  {
    province: "อุดรธานี",
    district: "ศรีธาตุ",
    subdistrict: "นายูง",
    zipcode: 41230,
    latitude: 16.952,
    longitude: 103.308,
  },
  {
    province: "อุดรธานี",
    district: "ศรีธาตุ",
    subdistrict: "ตาดทอง",
    zipcode: 41230,
    latitude: 17.067,
    longitude: 103.155,
  },
  {
    province: "อุดรธานี",
    district: "วังสามหมอ",
    subdistrict: "หนองกุงทับม้า",
    zipcode: 41280,
    latitude: 17.019,
    longitude: 103.472,
  },
  {
    province: "อุดรธานี",
    district: "วังสามหมอ",
    subdistrict: "หนองหญ้าไซ",
    zipcode: 41280,
    latitude: 16.952,
    longitude: 103.368,
  },
  {
    province: "อุดรธานี",
    district: "วังสามหมอ",
    subdistrict: "บะยาว",
    zipcode: 41280,
    latitude: 17.084,
    longitude: 103.405,
  },
  {
    province: "อุดรธานี",
    district: "วังสามหมอ",
    subdistrict: "ผาสุก",
    zipcode: 41280,
    latitude: 17.134,
    longitude: 103.537,
  },
  {
    province: "อุดรธานี",
    district: "วังสามหมอ",
    subdistrict: "คำโคกสูง",
    zipcode: 41280,
    latitude: 16.882,
    longitude: 103.426,
  },
  {
    province: "อุดรธานี",
    district: "วังสามหมอ",
    subdistrict: "วังสามหมอ",
    zipcode: 41280,
    latitude: 16.941,
    longitude: 103.456,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "ศรีสุทโธ",
    zipcode: 41190,
    latitude: 17.684,
    longitude: 103.259,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "บ้านดุง",
    zipcode: 41190,
    latitude: 17.741,
    longitude: 103.25,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "ดงเย็น",
    zipcode: 41190,
    latitude: 17.637,
    longitude: 103.379,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "โพนสูง",
    zipcode: 41190,
    latitude: 17.672,
    longitude: 103.296,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "อ้อมกอ",
    zipcode: 41190,
    latitude: 17.594,
    longitude: 103.305,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "บ้านจันทน์",
    zipcode: 41190,
    latitude: 17.796,
    longitude: 103.296,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "บ้านชัย",
    zipcode: 41190,
    latitude: 17.623,
    longitude: 103.19,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "นาไหม",
    zipcode: 41190,
    latitude: 17.768,
    longitude: 103.17,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "ถ่อนนาลับ",
    zipcode: 41190,
    latitude: 17.844,
    longitude: 103.199,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "วังทอง",
    zipcode: 41190,
    latitude: 17.759,
    longitude: 103.32,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "บ้านม่วง",
    zipcode: 41190,
    latitude: 17.713,
    longitude: 103.362,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "บ้านตาด",
    zipcode: 41190,
    latitude: 17.573,
    longitude: 103.248,
  },
  {
    province: "อุดรธานี",
    district: "บ้านดุง",
    subdistrict: "นาคำ",
    zipcode: 41190,
    latitude: 17.691,
    longitude: 103.17,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "บ้านผือ",
    zipcode: 41160,
    latitude: 17.697,
    longitude: 102.448,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "หายโศก",
    zipcode: 41160,
    latitude: 17.692,
    longitude: 102.531,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "เขือน้ำ",
    zipcode: 41160,
    latitude: 17.565,
    longitude: 102.646,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "คำบง",
    zipcode: 41160,
    latitude: 17.54,
    longitude: 102.556,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "โนนทอง",
    zipcode: 41160,
    latitude: 17.56,
    longitude: 102.424,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "ข้าวสาร",
    zipcode: 41160,
    latitude: 17.634,
    longitude: 102.439,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "จำปาโมง",
    zipcode: 41160,
    latitude: 17.613,
    longitude: 102.376,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "กลางใหญ่",
    zipcode: 41160,
    latitude: 17.785,
    longitude: 102.375,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "เมืองพาน",
    zipcode: 41160,
    latitude: 17.684,
    longitude: 102.365,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "คำด้วง",
    zipcode: 41160,
    latitude: 17.872,
    longitude: 102.346,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "หนองหัวคู",
    zipcode: 41160,
    latitude: 17.574,
    longitude: 102.56,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "บ้านค้อ",
    zipcode: 41160,
    latitude: 17.75,
    longitude: 102.446,
  },
  {
    province: "อุดรธานี",
    district: "บ้านผือ",
    subdistrict: "หนองแวง",
    zipcode: 41160,
    latitude: 17.509,
    longitude: 102.391,
  },
  {
    province: "อุดรธานี",
    district: "น้ำโสม",
    subdistrict: "นางัว",
    zipcode: 41210,
    latitude: 17.741,
    longitude: 102.215,
  },
  {
    province: "อุดรธานี",
    district: "น้ำโสม",
    subdistrict: "น้ำโสม",
    zipcode: 41210,
    latitude: 17.715,
    longitude: 102.092,
  },
  {
    province: "อุดรธานี",
    district: "น้ำโสม",
    subdistrict: "หนองแวง",
    zipcode: 41210,
    latitude: 17.81,
    longitude: 102.3,
  },
  {
    province: "อุดรธานี",
    district: "น้ำโสม",
    subdistrict: "บ้านหยวก",
    zipcode: 41210,
    latitude: 17.697,
    longitude: 102.187,
  },
  {
    province: "อุดรธานี",
    district: "น้ำโสม",
    subdistrict: "โสมเยี่ยม",
    zipcode: 41210,
    latitude: 17.823,
    longitude: 102.207,
  },
  {
    province: "อุดรธานี",
    district: "น้ำโสม",
    subdistrict: "ศรีสำราญ",
    zipcode: 41210,
    latitude: 17.732,
    longitude: 102.162,
  },
  {
    province: "อุดรธานี",
    district: "น้ำโสม",
    subdistrict: "สามัคคี",
    zipcode: 41210,
    latitude: 17.73,
    longitude: 102.288,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "เพ็ญ",
    zipcode: 41150,
    latitude: 17.673,
    longitude: 102.936,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "บ้านธาตุ",
    zipcode: 41150,
    latitude: 17.695,
    longitude: 102.85,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "นาพู่",
    zipcode: 41150,
    latitude: 17.6,
    longitude: 102.764,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "เชียงหวาง",
    zipcode: 41150,
    latitude: 17.617,
    longitude: 102.849,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "สุมเส้า",
    zipcode: 41150,
    latitude: 17.603,
    longitude: 102.984,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "นาบัว",
    zipcode: 41150,
    latitude: 17.551,
    longitude: 102.976,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "บ้านเหล่า",
    zipcode: 41150,
    latitude: 17.685,
    longitude: 103.04,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "จอมศรี",
    zipcode: 41150,
    latitude: 17.845,
    longitude: 102.899,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "เตาไห",
    zipcode: 41150,
    latitude: 17.634,
    longitude: 103.066,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "โคกกลาง",
    zipcode: 41150,
    latitude: 17.729,
    longitude: 102.979,
  },
  {
    province: "อุดรธานี",
    district: "เพ็ญ",
    subdistrict: "สร้างแป้น",
    zipcode: 41150,
    latitude: 17.513,
    longitude: 102.944,
  },
  {
    province: "อุดรธานี",
    district: "สร้างคอม",
    subdistrict: "สร้างคอม",
    zipcode: 41260,
    latitude: 17.814,
    longitude: 103.09,
  },
  {
    province: "อุดรธานี",
    district: "สร้างคอม",
    subdistrict: "เชียงดา",
    zipcode: 41260,
    latitude: 17.859,
    longitude: 103.027,
  },
  {
    province: "อุดรธานี",
    district: "สร้างคอม",
    subdistrict: "บ้านยวด",
    zipcode: 41260,
    latitude: 17.734,
    longitude: 103.04,
  },
  {
    province: "อุดรธานี",
    district: "สร้างคอม",
    subdistrict: "บ้านโคก",
    zipcode: 41260,
    latitude: 17.786,
    longitude: 103.016,
  },
  {
    province: "อุดรธานี",
    district: "สร้างคอม",
    subdistrict: "นาสะอาด",
    zipcode: 41260,
    latitude: 17.843,
    longitude: 103.058,
  },
  {
    province: "อุดรธานี",
    district: "สร้างคอม",
    subdistrict: "บ้านหินโงม",
    zipcode: 41260,
    latitude: 17.738,
    longitude: 103.099,
  },
  {
    province: "อุดรธานี",
    district: "หนองแสง",
    subdistrict: "หนองแสง",
    zipcode: 41340,
    latitude: 17.172,
    longitude: 102.842,
  },
  {
    province: "อุดรธานี",
    district: "หนองแสง",
    subdistrict: "แสงสว่าง",
    zipcode: 41340,
    latitude: 17.121,
    longitude: 102.804,
  },
  {
    province: "อุดรธานี",
    district: "หนองแสง",
    subdistrict: "นาดี",
    zipcode: 41340,
    latitude: 17.081,
    longitude: 102.844,
  },
  {
    province: "อุดรธานี",
    district: "หนองแสง",
    subdistrict: "ทับกุง",
    zipcode: 41340,
    latitude: 17.182,
    longitude: 102.737,
  },
  {
    province: "อุดรธานี",
    district: "นายูง",
    subdistrict: "นายูง",
    zipcode: 41380,
    latitude: 17.916,
    longitude: 102.213,
  },
  {
    province: "อุดรธานี",
    district: "นายูง",
    subdistrict: "บ้านก้อง",
    zipcode: 41380,
    latitude: 17.821,
    longitude: 102.079,
  },
  {
    province: "อุดรธานี",
    district: "นายูง",
    subdistrict: "นาแค",
    zipcode: 41380,
    latitude: 17.963,
    longitude: 102.085,
  },
  {
    province: "อุดรธานี",
    district: "นายูง",
    subdistrict: "โนนทอง",
    zipcode: 41380,
    latitude: 17.989,
    longitude: 102.198,
  },
  {
    province: "อุดรธานี",
    district: "พิบูลย์รักษ์",
    subdistrict: "บ้านแดง",
    zipcode: 41130,
    latitude: 17.539,
    longitude: 103.072,
  },
  {
    province: "อุดรธานี",
    district: "พิบูลย์รักษ์",
    subdistrict: "นาทราย",
    zipcode: 41130,
    latitude: 17.563,
    longitude: 103.123,
  },
  {
    province: "อุดรธานี",
    district: "พิบูลย์รักษ์",
    subdistrict: "ดอนกลอย",
    zipcode: 41130,
    latitude: 17.48,
    longitude: 103.023,
  },
  {
    province: "อุดรธานี",
    district: "กู่แก้ว",
    subdistrict: "บ้านจีต",
    zipcode: 41130,
    latitude: 17.135,
    longitude: 103.197,
  },
  {
    province: "อุดรธานี",
    district: "กู่แก้ว",
    subdistrict: "โนนทองอินทร์",
    zipcode: 41130,
    latitude: 17.197,
    longitude: 103.177,
  },
  {
    province: "อุดรธานี",
    district: "กู่แก้ว",
    subdistrict: "ค้อใหญ่",
    zipcode: 41130,
    latitude: 17.205,
    longitude: 103.141,
  },
  {
    province: "อุดรธานี",
    district: "กู่แก้ว",
    subdistrict: "คอนสาย",
    zipcode: 41130,
    latitude: 17.158,
    longitude: 103.134,
  },
  {
    province: "อุดรธานี",
    district: "ประจักษ์ศิลปาคม",
    subdistrict: "นาม่วง",
    zipcode: 41110,
    latitude: 17.256,
    longitude: 102.976,
  },
  {
    province: "อุดรธานี",
    district: "ประจักษ์ศิลปาคม",
    subdistrict: "ห้วยสามพาด",
    zipcode: 41110,
    latitude: 17.237,
    longitude: 102.934,
  },
  {
    province: "อุดรธานี",
    district: "ประจักษ์ศิลปาคม",
    subdistrict: "อุ่มจาน",
    zipcode: 41110,
    latitude: 17.234,
    longitude: 103.062,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "กุดป่อง",
    zipcode: 42000,
    latitude: 17.493,
    longitude: 101.732,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "เมือง",
    zipcode: 42000,
    latitude: 17.523,
    longitude: 101.705,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "นาอ้อ",
    zipcode: 42100,
    latitude: 17.582,
    longitude: 101.732,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "กกดู่",
    zipcode: 42000,
    latitude: 17.604,
    longitude: 101.635,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "น้ำหมาน",
    zipcode: 42000,
    latitude: 17.525,
    longitude: 101.646,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "เสี้ยว",
    zipcode: 42000,
    latitude: 17.458,
    longitude: 101.636,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "นาอาน",
    zipcode: 42000,
    latitude: 17.46,
    longitude: 101.741,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "นาโป่ง",
    zipcode: 42000,
    latitude: 17.408,
    longitude: 101.688,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "นาดินดำ",
    zipcode: 42000,
    latitude: 17.455,
    longitude: 101.842,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "น้ำสวย",
    zipcode: 42000,
    latitude: 17.613,
    longitude: 101.852,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "ชัยพฤกษ์",
    zipcode: 42000,
    latitude: 17.523,
    longitude: 101.777,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "นาแขม",
    zipcode: 42000,
    latitude: 17.687,
    longitude: 101.66,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "ศรีสองรัก",
    zipcode: 42100,
    latitude: 17.642,
    longitude: 101.769,
  },
  {
    province: "เลย",
    district: "เมืองเลย",
    subdistrict: "กกทอง",
    zipcode: 42000,
    latitude: 17.519,
    longitude: 101.562,
  },
  {
    province: "เลย",
    district: "นาด้วง",
    subdistrict: "นาด้วง",
    zipcode: 42210,
    latitude: 17.5,
    longitude: 102.043,
  },
  {
    province: "เลย",
    district: "นาด้วง",
    subdistrict: "นาดอกคำ",
    zipcode: 42210,
    latitude: 17.591,
    longitude: 101.99,
  },
  {
    province: "เลย",
    district: "นาด้วง",
    subdistrict: "ท่าสะอาด",
    zipcode: 42210,
    latitude: 17.497,
    longitude: 101.917,
  },
  {
    province: "เลย",
    district: "นาด้วง",
    subdistrict: "ท่าสวรรค์",
    zipcode: 42210,
    latitude: 17.432,
    longitude: 101.94,
  },
  {
    province: "เลย",
    district: "เชียงคาน",
    subdistrict: "เชียงคาน",
    zipcode: 42110,
    latitude: 17.881,
    longitude: 101.665,
  },
  {
    province: "เลย",
    district: "เชียงคาน",
    subdistrict: "ธาตุ",
    zipcode: 42110,
    latitude: 17.738,
    longitude: 101.847,
  },
  {
    province: "เลย",
    district: "เชียงคาน",
    subdistrict: "นาซ่าว",
    zipcode: 42110,
    latitude: 17.814,
    longitude: 101.663,
  },
  {
    province: "เลย",
    district: "เชียงคาน",
    subdistrict: "เขาแก้ว",
    zipcode: 42110,
    latitude: 17.82,
    longitude: 101.781,
  },
  {
    province: "เลย",
    district: "เชียงคาน",
    subdistrict: "ปากตม",
    zipcode: 42110,
    latitude: 17.768,
    longitude: 101.554,
  },
  {
    province: "เลย",
    district: "เชียงคาน",
    subdistrict: "บุฮม",
    zipcode: 42110,
    latitude: 17.958,
    longitude: 101.78,
  },
  {
    province: "เลย",
    district: "เชียงคาน",
    subdistrict: "จอมศรี",
    zipcode: 42110,
    latitude: 17.763,
    longitude: 101.735,
  },
  {
    province: "เลย",
    district: "เชียงคาน",
    subdistrict: "หาดทรายขาว",
    zipcode: 42110,
    latitude: 17.743,
    longitude: 101.658,
  },
  {
    province: "เลย",
    district: "ปากชม",
    subdistrict: "ปากชม",
    zipcode: 42150,
    latitude: 17.96,
    longitude: 101.902,
  },
  {
    province: "เลย",
    district: "ปากชม",
    subdistrict: "เชียงกลม",
    zipcode: 42150,
    latitude: 17.851,
    longitude: 101.962,
  },
  {
    province: "เลย",
    district: "ปากชม",
    subdistrict: "หาดคัมภีร์",
    zipcode: 42150,
    latitude: 18.111,
    longitude: 102.01,
  },
  {
    province: "เลย",
    district: "ปากชม",
    subdistrict: "ห้วยบ่อซืน",
    zipcode: 42150,
    latitude: 17.73,
    longitude: 101.986,
  },
  {
    province: "เลย",
    district: "ปากชม",
    subdistrict: "ห้วยพิชัย",
    zipcode: 42150,
    latitude: 18.017,
    longitude: 101.971,
  },
  {
    province: "เลย",
    district: "ปากชม",
    subdistrict: "ชมเจริญ",
    zipcode: 42150,
    latitude: 17.7,
    longitude: 101.922,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "ด่านซ้าย",
    zipcode: 42120,
    latitude: 17.267,
    longitude: 101.155,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "ปากหมัน",
    zipcode: 42120,
    latitude: 17.483,
    longitude: 101.223,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "นาดี",
    zipcode: 42120,
    latitude: 17.42,
    longitude: 101.154,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "โคกงาม",
    zipcode: 42120,
    latitude: 17.385,
    longitude: 101.233,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "โพนสูง",
    zipcode: 42120,
    latitude: 17.277,
    longitude: 101.282,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "อิปุ่ม",
    zipcode: 42120,
    latitude: 17.187,
    longitude: 101.254,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "กกสะทอน",
    zipcode: 42120,
    latitude: 17.041,
    longitude: 101.09,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "โป่ง",
    zipcode: 42120,
    latitude: 17.156,
    longitude: 101.198,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "วังยาว",
    zipcode: 42120,
    latitude: 17.21,
    longitude: 101.328,
  },
  {
    province: "เลย",
    district: "ด่านซ้าย",
    subdistrict: "นาหอ",
    zipcode: 42120,
    latitude: 17.331,
    longitude: 101.142,
  },
  {
    province: "เลย",
    district: "นาแห้ว",
    subdistrict: "นาแห้ว",
    zipcode: 42170,
    latitude: 17.484,
    longitude: 101.066,
  },
  {
    province: "เลย",
    district: "นาแห้ว",
    subdistrict: "แสงภา",
    zipcode: 42170,
    latitude: 17.507,
    longitude: 100.965,
  },
  {
    province: "เลย",
    district: "นาแห้ว",
    subdistrict: "นาพึง",
    zipcode: 42170,
    latitude: 17.416,
    longitude: 101.027,
  },
  {
    province: "เลย",
    district: "นาแห้ว",
    subdistrict: "นามาลา",
    zipcode: 42170,
    latitude: 17.336,
    longitude: 101.071,
  },
  {
    province: "เลย",
    district: "นาแห้ว",
    subdistrict: "เหล่ากอหก",
    zipcode: 42170,
    latitude: 17.485,
    longitude: 100.864,
  },
  {
    province: "เลย",
    district: "ภูเรือ",
    subdistrict: "หนองบัว",
    zipcode: 42160,
    latitude: 17.454,
    longitude: 101.371,
  },
  {
    province: "เลย",
    district: "ภูเรือ",
    subdistrict: "ท่าศาลา",
    zipcode: 42160,
    latitude: 17.391,
    longitude: 101.463,
  },
  {
    province: "เลย",
    district: "ภูเรือ",
    subdistrict: "ร่องจิก",
    zipcode: 42160,
    latitude: 17.346,
    longitude: 101.333,
  },
  {
    province: "เลย",
    district: "ภูเรือ",
    subdistrict: "ปลาบ่า",
    zipcode: 42160,
    latitude: 17.318,
    longitude: 101.439,
  },
  {
    province: "เลย",
    district: "ภูเรือ",
    subdistrict: "ลาดค่าง",
    zipcode: 42160,
    latitude: 17.497,
    longitude: 101.285,
  },
  {
    province: "เลย",
    district: "ภูเรือ",
    subdistrict: "สานตม",
    zipcode: 42160,
    latitude: 17.46,
    longitude: 101.482,
  },
  {
    province: "เลย",
    district: "ท่าลี่",
    subdistrict: "ท่าลี่",
    zipcode: 42140,
    latitude: 17.573,
    longitude: 101.412,
  },
  {
    province: "เลย",
    district: "ท่าลี่",
    subdistrict: "หนองผือ",
    zipcode: 42140,
    latitude: 17.678,
    longitude: 101.448,
  },
  {
    province: "เลย",
    district: "ท่าลี่",
    subdistrict: "อาฮี",
    zipcode: 42140,
    latitude: 17.636,
    longitude: 101.347,
  },
  {
    province: "เลย",
    district: "ท่าลี่",
    subdistrict: "น้ำแคม",
    zipcode: 42140,
    latitude: 17.676,
    longitude: 101.527,
  },
  {
    province: "เลย",
    district: "ท่าลี่",
    subdistrict: "โคกใหญ่",
    zipcode: 42140,
    latitude: 17.556,
    longitude: 101.491,
  },
  {
    province: "เลย",
    district: "ท่าลี่",
    subdistrict: "น้ำทูน",
    zipcode: 42140,
    latitude: 17.592,
    longitude: 101.288,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "วังสะพุง",
    zipcode: 42130,
    latitude: 17.296,
    longitude: 101.746,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "ทรายขาว",
    zipcode: 42130,
    latitude: 17.213,
    longitude: 101.535,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "หนองหญ้าปล้อง",
    zipcode: 42130,
    latitude: 17.234,
    longitude: 101.926,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "หนองงิ้ว",
    zipcode: 42130,
    latitude: 17.374,
    longitude: 101.571,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "ปากปวน",
    zipcode: 42130,
    latitude: 17.357,
    longitude: 101.739,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "ผาน้อย",
    zipcode: 42130,
    latitude: 17.351,
    longitude: 101.831,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "ผาบิ้ง",
    zipcode: 42130,
    latitude: 17.193,
    longitude: 101.773,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "เขาหลวง",
    zipcode: 42130,
    latitude: 17.336,
    longitude: 101.674,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "โคกขมิ้น",
    zipcode: 42130,
    latitude: 17.171,
    longitude: 101.938,
  },
  {
    province: "เลย",
    district: "วังสะพุง",
    subdistrict: "ศรีสงคราม",
    zipcode: 42130,
    latitude: 17.254,
    longitude: 101.799,
  },
  {
    province: "เลย",
    district: "ภูกระดึง",
    subdistrict: "ศรีฐาน",
    zipcode: 42180,
    latitude: 16.88,
    longitude: 101.771,
  },
  {
    province: "เลย",
    district: "ภูกระดึง",
    subdistrict: "ผานกเค้า",
    zipcode: 42180,
    latitude: 16.896,
    longitude: 101.986,
  },
  {
    province: "เลย",
    district: "ภูกระดึง",
    subdistrict: "ภูกระดึง",
    zipcode: 42180,
    latitude: 16.971,
    longitude: 101.85,
  },
  {
    province: "เลย",
    district: "ภูกระดึง",
    subdistrict: "ห้วยส้ม",
    zipcode: 42180,
    latitude: 16.937,
    longitude: 101.939,
  },
  {
    province: "เลย",
    district: "ภูหลวง",
    subdistrict: "ภูหอ",
    zipcode: 42230,
    latitude: 17.135,
    longitude: 101.629,
  },
  {
    province: "เลย",
    district: "ภูหลวง",
    subdistrict: "หนองคัน",
    zipcode: 42230,
    latitude: 17.141,
    longitude: 101.701,
  },
  {
    province: "เลย",
    district: "ภูหลวง",
    subdistrict: "ห้วยสีเสียด",
    zipcode: 42230,
    latitude: 17.138,
    longitude: 101.74,
  },
  {
    province: "เลย",
    district: "ภูหลวง",
    subdistrict: "เลยวังไสย์",
    zipcode: 42230,
    latitude: 17.086,
    longitude: 101.549,
  },
  {
    province: "เลย",
    district: "ภูหลวง",
    subdistrict: "แก่งศรีภูมิ",
    zipcode: 42230,
    latitude: 17.049,
    longitude: 101.678,
  },
  {
    province: "เลย",
    district: "ผาขาว",
    subdistrict: "ผาขาว",
    zipcode: 42240,
    latitude: 17.019,
    longitude: 101.96,
  },
  {
    province: "เลย",
    district: "ผาขาว",
    subdistrict: "ท่าช้างคล้อง",
    zipcode: 42240,
    latitude: 17.019,
    longitude: 102.09,
  },
  {
    province: "เลย",
    district: "ผาขาว",
    subdistrict: "โนนปอแดง",
    zipcode: 42240,
    latitude: 16.983,
    longitude: 101.985,
  },
  {
    province: "เลย",
    district: "ผาขาว",
    subdistrict: "โนนป่าซาง",
    zipcode: 42240,
    latitude: 17.102,
    longitude: 102.052,
  },
  {
    province: "เลย",
    district: "ผาขาว",
    subdistrict: "บ้านเพิ่ม",
    zipcode: 42240,
    latitude: 17.1,
    longitude: 101.938,
  },
  {
    province: "เลย",
    district: "เอราวัณ",
    subdistrict: "เอราวัณ",
    zipcode: 42220,
    latitude: 17.373,
    longitude: 101.892,
  },
  {
    province: "เลย",
    district: "เอราวัณ",
    subdistrict: "ผาอินทร์แปลง",
    zipcode: 42220,
    latitude: 17.303,
    longitude: 101.962,
  },
  {
    province: "เลย",
    district: "เอราวัณ",
    subdistrict: "ผาสามยอด",
    zipcode: 42220,
    latitude: 17.228,
    longitude: 102.066,
  },
  {
    province: "เลย",
    district: "เอราวัณ",
    subdistrict: "ทรัพย์ไพวัลย์",
    zipcode: 42220,
    latitude: 17.193,
    longitude: 101.994,
  },
  {
    province: "เลย",
    district: "หนองหิน",
    subdistrict: "หนองหิน",
    zipcode: 42190,
    latitude: 17.112,
    longitude: 101.86,
  },
  {
    province: "เลย",
    district: "หนองหิน",
    subdistrict: "ตาดข่า",
    zipcode: 42190,
    latitude: 17.033,
    longitude: 101.879,
  },
  {
    province: "เลย",
    district: "หนองหิน",
    subdistrict: "ปวนพุ",
    zipcode: 42190,
    latitude: 17.031,
    longitude: 101.741,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "ในเมือง",
    zipcode: 43000,
    latitude: 17.89,
    longitude: 102.761,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "มีชัย",
    zipcode: 43000,
    latitude: 17.868,
    longitude: 102.729,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "โพธิ์ชัย",
    zipcode: 43000,
    latitude: 17.847,
    longitude: 102.773,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "กวนวัน",
    zipcode: 43000,
    latitude: 17.858,
    longitude: 102.7,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "เวียงคุก",
    zipcode: 43000,
    latitude: 17.783,
    longitude: 102.673,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "วัดธาตุ",
    zipcode: 43000,
    latitude: 17.851,
    longitude: 102.826,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "หาดคำ",
    zipcode: 43000,
    latitude: 17.9,
    longitude: 102.825,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "หินโงม",
    zipcode: 43000,
    latitude: 17.94,
    longitude: 102.849,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "บ้านเดื่อ",
    zipcode: 43000,
    latitude: 17.985,
    longitude: 102.954,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "ค่ายบกหวาน",
    zipcode: 43100,
    latitude: 17.781,
    longitude: 102.783,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "โพนสว่าง",
    zipcode: 43100,
    latitude: 17.7235309,
    longitude: 102.7637252,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "พระธาตุบังพวน",
    zipcode: 43100,
    latitude: 17.736,
    longitude: 102.693,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "หนองกอมเกาะ",
    zipcode: 43000,
    latitude: 17.83,
    longitude: 102.742,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "ปะโค",
    zipcode: 43000,
    latitude: 17.801,
    longitude: 102.716,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "เมืองหมี",
    zipcode: 43000,
    latitude: 17.833,
    longitude: 102.702,
  },
  {
    province: "หนองคาย",
    district: "เมืองหนองคาย",
    subdistrict: "สีกาย",
    zipcode: 43000,
    latitude: 17.958,
    longitude: 102.884,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "ท่าบ่อ",
    zipcode: 43110,
    latitude: 17.827,
    longitude: 102.568,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "น้ำโมง",
    zipcode: 43110,
    latitude: 17.84,
    longitude: 102.524,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "กองนาง",
    zipcode: 43110,
    latitude: 17.889,
    longitude: 102.592,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "โคกคอน",
    zipcode: 43110,
    latitude: 17.781,
    longitude: 102.496,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "บ้านเดื่อ",
    zipcode: 43110,
    latitude: 17.775,
    longitude: 102.579,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "บ้านถ่อน",
    zipcode: 43110,
    latitude: 17.776,
    longitude: 102.632,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "บ้านว่าน",
    zipcode: 43110,
    latitude: 17.743,
    longitude: 102.558,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "นาข่า",
    zipcode: 43110,
    latitude: 17.784,
    longitude: 102.458,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "โพนสา",
    zipcode: 43110,
    latitude: 17.819,
    longitude: 102.61,
  },
  {
    province: "หนองคาย",
    district: "ท่าบ่อ",
    subdistrict: "หนองนาง",
    zipcode: 43110,
    latitude: 17.713,
    longitude: 102.621,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "จุมพล",
    zipcode: 43120,
    latitude: 18.027,
    longitude: 103.146,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "วัดหลวง",
    zipcode: 43120,
    latitude: 17.949,
    longitude: 103.013,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "กุดบง",
    zipcode: 43120,
    latitude: 18.105,
    longitude: 103.118,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "ชุมช้าง",
    zipcode: 43120,
    latitude: 17.974,
    longitude: 103.132,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "ทุ่งหลวง",
    zipcode: 43120,
    latitude: 17.896,
    longitude: 102.976,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "เหล่าต่างคำ",
    zipcode: 43120,
    latitude: 17.917,
    longitude: 102.911,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "นาหนัง",
    zipcode: 43120,
    latitude: 17.888,
    longitude: 103.136,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "เซิม",
    zipcode: 43120,
    latitude: 17.926,
    longitude: 103.277,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "บ้านโพธิ์",
    zipcode: 43120,
    latitude: 17.86,
    longitude: 103.291,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "บ้านผือ",
    zipcode: 43120,
    latitude: 17.93,
    longitude: 103.213,
  },
  {
    province: "หนองคาย",
    district: "โพนพิสัย",
    subdistrict: "สร้างนางขาว",
    zipcode: 43120,
    latitude: 17.899,
    longitude: 103.01,
  },
  {
    province: "หนองคาย",
    district: "ศรีเชียงใหม่",
    subdistrict: "พานพร้าว",
    zipcode: 43130,
    latitude: 17.942,
    longitude: 102.594,
  },
  {
    province: "หนองคาย",
    district: "ศรีเชียงใหม่",
    subdistrict: "บ้านหม้อ",
    zipcode: 43130,
    latitude: 17.957,
    longitude: 102.502,
  },
  {
    province: "หนองคาย",
    district: "ศรีเชียงใหม่",
    subdistrict: "พระพุทธบาท",
    zipcode: 43130,
    latitude: 17.965,
    longitude: 102.424,
  },
  {
    province: "หนองคาย",
    district: "ศรีเชียงใหม่",
    subdistrict: "หนองปลาปาก",
    zipcode: 43130,
    latitude: 17.913,
    longitude: 102.514,
  },
  {
    province: "หนองคาย",
    district: "สังคม",
    subdistrict: "แก้งไก่",
    zipcode: 43160,
    latitude: 18.037,
    longitude: 102.235,
  },
  {
    province: "หนองคาย",
    district: "สังคม",
    subdistrict: "ผาตั้ง",
    zipcode: 43160,
    latitude: 17.996,
    longitude: 102.328,
  },
  {
    province: "หนองคาย",
    district: "สังคม",
    subdistrict: "บ้านม่วง",
    zipcode: 43160,
    latitude: 18.181,
    longitude: 102.128,
  },
  {
    province: "หนองคาย",
    district: "สังคม",
    subdistrict: "นางิ้ว",
    zipcode: 43160,
    latitude: 18.092,
    longitude: 102.125,
  },
  {
    province: "หนองคาย",
    district: "สังคม",
    subdistrict: "สังคม",
    zipcode: 43160,
    latitude: 18.091,
    longitude: 102.216,
  },
  {
    province: "หนองคาย",
    district: "สระใคร",
    subdistrict: "สระใคร",
    zipcode: 43100,
    latitude: 17.703,
    longitude: 102.75,
  },
  {
    province: "หนองคาย",
    district: "สระใคร",
    subdistrict: "คอกช้าง",
    zipcode: 43100,
    latitude: 17.638,
    longitude: 102.736,
  },
  {
    province: "หนองคาย",
    district: "สระใคร",
    subdistrict: "บ้านฝาง",
    zipcode: 43100,
    latitude: 17.651,
    longitude: 102.665,
  },
  {
    province: "หนองคาย",
    district: "เฝ้าไร่",
    subdistrict: "เฝ้าไร่",
    zipcode: 43120,
    latitude: 17.969,
    longitude: 103.27,
  },
  {
    province: "หนองคาย",
    district: "เฝ้าไร่",
    subdistrict: "นาดี",
    zipcode: 43120,
    latitude: 17.999,
    longitude: 103.366,
  },
  {
    province: "หนองคาย",
    district: "เฝ้าไร่",
    subdistrict: "หนองหลวง",
    zipcode: 43120,
    latitude: 18.021,
    longitude: 103.24,
  },
  {
    province: "หนองคาย",
    district: "เฝ้าไร่",
    subdistrict: "วังหลวง",
    zipcode: 43120,
    latitude: 18.086,
    longitude: 103.256,
  },
  {
    province: "หนองคาย",
    district: "เฝ้าไร่",
    subdistrict: "อุดมพร",
    zipcode: 43120,
    latitude: 17.891,
    longitude: 103.357,
  },
  {
    province: "หนองคาย",
    district: "รัตนวาปี",
    subdistrict: "รัตนวาปี",
    zipcode: 43120,
    latitude: 18.223,
    longitude: 103.183,
  },
  {
    province: "หนองคาย",
    district: "รัตนวาปี",
    subdistrict: "นาทับไฮ",
    zipcode: 43120,
    latitude: 18.177,
    longitude: 103.306,
  },
  {
    province: "หนองคาย",
    district: "รัตนวาปี",
    subdistrict: "บ้านต้อน",
    zipcode: 43120,
    latitude: 18.258,
    longitude: 103.236,
  },
  {
    province: "หนองคาย",
    district: "รัตนวาปี",
    subdistrict: "พระบาทนาสิงห์",
    zipcode: 43120,
    latitude: 18.163,
    longitude: 103.209,
  },
  {
    province: "หนองคาย",
    district: "รัตนวาปี",
    subdistrict: "โพนแพง",
    zipcode: 43120,
    latitude: 18.252,
    longitude: 103.202,
  },
  {
    province: "หนองคาย",
    district: "โพธิ์ตาก",
    subdistrict: "โพธิ์ตาก",
    zipcode: 43130,
    latitude: 17.846,
    longitude: 102.422,
  },
  {
    province: "หนองคาย",
    district: "โพธิ์ตาก",
    subdistrict: "โพนทอง",
    zipcode: 43130,
    latitude: 17.881,
    longitude: 102.454,
  },
  {
    province: "หนองคาย",
    district: "โพธิ์ตาก",
    subdistrict: "ด่านศรีสุข",
    zipcode: 43130,
    latitude: 17.925,
    longitude: 102.398,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "ตลาด",
    zipcode: 44000,
    latitude: 16.175,
    longitude: 103.319,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "เขวา",
    zipcode: 44000,
    latitude: 16.15,
    longitude: 103.389,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "ท่าตูม",
    zipcode: 44000,
    latitude: 16.162,
    longitude: 103.484,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "แวงน่าง",
    zipcode: 44000,
    latitude: 16.128,
    longitude: 103.313,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "โคกก่อ",
    zipcode: 44000,
    latitude: 16.026,
    longitude: 103.239,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "ดอนหว่าน",
    zipcode: 44000,
    latitude: 15.997,
    longitude: 103.318,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "เกิ้ง",
    zipcode: 44000,
    latitude: 16.21,
    longitude: 103.33,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "แก่งเลิงจาน",
    zipcode: 44000,
    latitude: 16.139,
    longitude: 103.226,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "ท่าสองคอน",
    zipcode: 44000,
    latitude: 16.163,
    longitude: 103.15,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "ลาดพัฒนา",
    zipcode: 44000,
    latitude: 16.207,
    longitude: 103.397,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "หนองปลิง",
    zipcode: 44000,
    latitude: 16.07,
    longitude: 103.313,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "ห้วยแอ่ง",
    zipcode: 44000,
    latitude: 16.117,
    longitude: 103.432,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "หนองโน",
    zipcode: 44000,
    latitude: 16.098,
    longitude: 103.252,
  },
  {
    province: "มหาสารคาม",
    district: "เมืองมหาสารคาม",
    subdistrict: "บัวค้อ",
    zipcode: 44000,
    latitude: 15.986,
    longitude: 103.278,
  },
  {
    province: "มหาสารคาม",
    district: "แกดำ",
    subdistrict: "แกดำ",
    zipcode: 44190,
    latitude: 16.026,
    longitude: 103.401,
  },
  {
    province: "มหาสารคาม",
    district: "แกดำ",
    subdistrict: "วังแสง",
    zipcode: 44190,
    latitude: 16.047,
    longitude: 103.358,
  },
  {
    province: "มหาสารคาม",
    district: "แกดำ",
    subdistrict: "มิตรภาพ",
    zipcode: 44190,
    latitude: 16.09,
    longitude: 103.387,
  },
  {
    province: "มหาสารคาม",
    district: "แกดำ",
    subdistrict: "หนองกุง",
    zipcode: 44190,
    latitude: 16.072,
    longitude: 103.437,
  },
  {
    province: "มหาสารคาม",
    district: "แกดำ",
    subdistrict: "โนนภิบาล",
    zipcode: 44190,
    latitude: 16.022,
    longitude: 103.442,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "หัวขวาง",
    zipcode: 44140,
    latitude: 16.249,
    longitude: 103.065,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "ยางน้อย",
    zipcode: 44140,
    latitude: 16.301,
    longitude: 103.098,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "วังยาว",
    zipcode: 44140,
    latitude: 16.223,
    longitude: 102.928,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "เขวาไร่",
    zipcode: 44140,
    latitude: 16.249,
    longitude: 102.87,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "แพง",
    zipcode: 44140,
    latitude: 16.26,
    longitude: 102.967,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "แก้งแก",
    zipcode: 44140,
    latitude: 16.197,
    longitude: 103.103,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "หนองเหล็ก",
    zipcode: 44140,
    latitude: 16.134,
    longitude: 102.948,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "หนองบัว",
    zipcode: 44140,
    latitude: 16.338,
    longitude: 102.995,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "เหล่า",
    zipcode: 44140,
    latitude: 16.18,
    longitude: 103.068,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "เขื่อน",
    zipcode: 44140,
    latitude: 16.344,
    longitude: 103.068,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "หนองบอน",
    zipcode: 44140,
    latitude: 16.274,
    longitude: 103.018,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "โพนงาม",
    zipcode: 44140,
    latitude: 16.323,
    longitude: 102.959,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "ยางท่าแจ้ง",
    zipcode: 44140,
    latitude: 16.315,
    longitude: 103.036,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "แห่ใต้",
    zipcode: 44140,
    latitude: 16.281,
    longitude: 103.139,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "หนองกุงสวรรค์",
    zipcode: 44140,
    latitude: 16.194,
    longitude: 102.975,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "เลิงใต้",
    zipcode: 44140,
    latitude: 16.216,
    longitude: 103.142,
  },
  {
    province: "มหาสารคาม",
    district: "โกสุมพิสัย",
    subdistrict: "ดอนกลาง",
    zipcode: 44140,
    latitude: 16.165,
    longitude: 102.891,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "โคกพระ",
    zipcode: 44150,
    latitude: 16.337,
    longitude: 103.316,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "คันธารราษฎร์",
    zipcode: 44150,
    latitude: 16.299,
    longitude: 103.283,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "มะค่า",
    zipcode: 44150,
    latitude: 16.254,
    longitude: 103.351,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "ท่าขอนยาง",
    zipcode: 44150,
    latitude: 16.256,
    longitude: 103.275,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "นาสีนวน",
    zipcode: 44150,
    latitude: 16.317,
    longitude: 103.202,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "ขามเรียง",
    zipcode: 44150,
    latitude: 16.247,
    longitude: 103.236,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "เขวาใหญ่",
    zipcode: 44150,
    latitude: 16.228,
    longitude: 103.184,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "ศรีสุข",
    zipcode: 44150,
    latitude: 16.348,
    longitude: 103.263,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "กุดใส้จ่อ",
    zipcode: 44150,
    latitude: 16.283,
    longitude: 103.38,
  },
  {
    province: "มหาสารคาม",
    district: "กันทรวิชัย",
    subdistrict: "ขามเฒ่าพัฒนา",
    zipcode: 44150,
    latitude: 16.277,
    longitude: 103.321,
  },
  {
    province: "มหาสารคาม",
    district: "เชียงยืน",
    subdistrict: "เชียงยืน",
    zipcode: 44160,
    latitude: 16.431,
    longitude: 103.093,
  },
  {
    province: "มหาสารคาม",
    district: "เชียงยืน",
    subdistrict: "หนองซอน",
    zipcode: 44160,
    latitude: 16.334,
    longitude: 103.156,
  },
  {
    province: "มหาสารคาม",
    district: "เชียงยืน",
    subdistrict: "ดอนเงิน",
    zipcode: 44160,
    latitude: 16.424,
    longitude: 103.151,
  },
  {
    province: "มหาสารคาม",
    district: "เชียงยืน",
    subdistrict: "กู่ทอง",
    zipcode: 44160,
    latitude: 16.426,
    longitude: 103.003,
  },
  {
    province: "มหาสารคาม",
    district: "เชียงยืน",
    subdistrict: "นาทอง",
    zipcode: 44160,
    latitude: 16.389,
    longitude: 103.059,
  },
  {
    province: "มหาสารคาม",
    district: "เชียงยืน",
    subdistrict: "เสือเฒ่า",
    zipcode: 44160,
    latitude: 16.47,
    longitude: 103.053,
  },
  {
    province: "มหาสารคาม",
    district: "เชียงยืน",
    subdistrict: "โพนทอง",
    zipcode: 44160,
    latitude: 16.372,
    longitude: 103.144,
  },
  {
    province: "มหาสารคาม",
    district: "เชียงยืน",
    subdistrict: "เหล่าบัวบาน",
    zipcode: 44160,
    latitude: 16.367,
    longitude: 103.012,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "บรบือ",
    zipcode: 44130,
    latitude: 16.061,
    longitude: 103.143,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "บ่อใหญ่",
    zipcode: 44130,
    latitude: 16.115,
    longitude: 103.176,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "วังไชย",
    zipcode: 44130,
    latitude: 15.986,
    longitude: 103.064,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "หนองม่วง",
    zipcode: 44130,
    latitude: 15.961,
    longitude: 103.212,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "กำพี้",
    zipcode: 44130,
    latitude: 15.959,
    longitude: 103.144,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "โนนราษี",
    zipcode: 44130,
    latitude: 15.888,
    longitude: 103.142,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "โนนแดง",
    zipcode: 44130,
    latitude: 15.903,
    longitude: 103.08,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "หนองจิก",
    zipcode: 44130,
    latitude: 16.024,
    longitude: 103.17,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "บัวมาศ",
    zipcode: 44130,
    latitude: 15.897,
    longitude: 103.202,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "หนองคูขาด",
    zipcode: 44130,
    latitude: 15.938,
    longitude: 102.989,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "วังใหม่",
    zipcode: 44130,
    latitude: 16.031,
    longitude: 103.039,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "ยาง",
    zipcode: 44130,
    latitude: 15.919,
    longitude: 103.236,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "หนองสิม",
    zipcode: 44130,
    latitude: 16.012,
    longitude: 103.114,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "หนองโก",
    zipcode: 44130,
    latitude: 16.068,
    longitude: 103.201,
  },
  {
    province: "มหาสารคาม",
    district: "บรบือ",
    subdistrict: "ดอนงัว",
    zipcode: 44130,
    latitude: 15.877,
    longitude: 103.159,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "นาเชือก",
    zipcode: 44170,
    latitude: 15.773,
    longitude: 103.047,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "สำโรง",
    zipcode: 44170,
    latitude: 15.87,
    longitude: 103.007,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "หนองแดง",
    zipcode: 44170,
    latitude: 15.889,
    longitude: 102.948,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "เขวาไร่",
    zipcode: 44170,
    latitude: 15.841,
    longitude: 103.065,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "หนองโพธิ์",
    zipcode: 44170,
    latitude: 15.755,
    longitude: 103.13,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "ปอพาน",
    zipcode: 44170,
    latitude: 15.816,
    longitude: 102.954,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "หนองเม็ก",
    zipcode: 44170,
    latitude: 15.786,
    longitude: 102.998,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "หนองเรือ",
    zipcode: 44170,
    latitude: 15.828,
    longitude: 103.123,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "หนองกุง",
    zipcode: 44170,
    latitude: 15.722,
    longitude: 103.038,
  },
  {
    province: "มหาสารคาม",
    district: "นาเชือก",
    subdistrict: "สันป่าตอง",
    zipcode: 44170,
    latitude: 15.735,
    longitude: 103.076,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "ปะหลาน",
    zipcode: 44110,
    latitude: 15.503,
    longitude: 103.163,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "ก้ามปู",
    zipcode: 44110,
    latitude: 15.558,
    longitude: 103.222,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "เวียงสะอาด",
    zipcode: 44110,
    latitude: 15.461,
    longitude: 103.262,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "เม็กดำ",
    zipcode: 44110,
    latitude: 15.573,
    longitude: 103.072,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "นาสีนวล",
    zipcode: 44110,
    latitude: 15.635,
    longitude: 103.223,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "ราษฎร์เจริญ",
    zipcode: 44110,
    latitude: 15.456,
    longitude: 103.321,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "หนองบัวแก้ว",
    zipcode: 44110,
    latitude: 15.543,
    longitude: 103.309,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "เมืองเตา",
    zipcode: 44110,
    latitude: 15.479,
    longitude: 103.378,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "ลานสะแก",
    zipcode: 44110,
    latitude: 15.568,
    longitude: 103.159,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "เวียงชัย",
    zipcode: 44110,
    latitude: 15.517,
    longitude: 103.266,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "หนองบัว",
    zipcode: 44110,
    latitude: 15.591,
    longitude: 103.106,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "ราษฎร์พัฒนา",
    zipcode: 44110,
    latitude: 15.506,
    longitude: 103.31,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "เมืองเสือ",
    zipcode: 44110,
    latitude: 15.484,
    longitude: 103.206,
  },
  {
    province: "มหาสารคาม",
    district: "พยัคฆภูมิพิสัย",
    subdistrict: "ภารแอ่น",
    zipcode: 44110,
    latitude: 15.593,
    longitude: 103.258,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "หนองแสง",
    zipcode: 44120,
    latitude: 15.829,
    longitude: 103.36,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "ขามป้อม",
    zipcode: 44120,
    latitude: 15.921,
    longitude: 103.377,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "เสือโก้ก",
    zipcode: 44120,
    latitude: 15.948,
    longitude: 103.442,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "ดงใหญ่",
    zipcode: 44120,
    latitude: 15.898,
    longitude: 103.308,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "โพธิ์ชัย",
    zipcode: 44120,
    latitude: 15.729,
    longitude: 103.415,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "หัวเรือ",
    zipcode: 44120,
    latitude: 15.762,
    longitude: 103.349,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "แคน",
    zipcode: 44120,
    latitude: 15.875,
    longitude: 103.244,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "งัวบา",
    zipcode: 44120,
    latitude: 15.955,
    longitude: 103.347,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "นาข่า",
    zipcode: 44120,
    latitude: 15.836,
    longitude: 103.193,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "บ้านหวาย",
    zipcode: 44120,
    latitude: 15.812,
    longitude: 103.294,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "หนองไฮ",
    zipcode: 44120,
    latitude: 15.885,
    longitude: 103.432,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "ประชาพัฒนา",
    zipcode: 44120,
    latitude: 15.816,
    longitude: 103.419,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "หนองทุ่ม",
    zipcode: 44120,
    latitude: 15.875,
    longitude: 103.399,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "หนองแสน",
    zipcode: 44120,
    latitude: 15.983,
    longitude: 103.444,
  },
  {
    province: "มหาสารคาม",
    district: "วาปีปทุม",
    subdistrict: "โคกสีทองหลาง",
    zipcode: 44120,
    latitude: 15.852,
    longitude: 103.312,
  },
  {
    province: "มหาสารคาม",
    district: "นาดูน",
    subdistrict: "นาดูน",
    zipcode: 44180,
    latitude: 15.738,
    longitude: 103.205,
  },
  {
    province: "มหาสารคาม",
    district: "นาดูน",
    subdistrict: "หนองไผ่",
    zipcode: 44180,
    latitude: 15.726,
    longitude: 103.318,
  },
  {
    province: "มหาสารคาม",
    district: "นาดูน",
    subdistrict: "หนองคู",
    zipcode: 44180,
    latitude: 15.782,
    longitude: 103.226,
  },
  {
    province: "มหาสารคาม",
    district: "นาดูน",
    subdistrict: "ดงบัง",
    zipcode: 44180,
    latitude: 15.663,
    longitude: 103.257,
  },
  {
    province: "มหาสารคาม",
    district: "นาดูน",
    subdistrict: "ดงดวน",
    zipcode: 44180,
    latitude: 15.731,
    longitude: 103.162,
  },
  {
    province: "มหาสารคาม",
    district: "นาดูน",
    subdistrict: "หัวดง",
    zipcode: 44180,
    latitude: 15.691,
    longitude: 103.194,
  },
  {
    province: "มหาสารคาม",
    district: "นาดูน",
    subdistrict: "ดงยาง",
    zipcode: 44180,
    latitude: 15.757,
    longitude: 103.296,
  },
  {
    province: "มหาสารคาม",
    district: "นาดูน",
    subdistrict: "กู่สันตรัตน์",
    zipcode: 44180,
    latitude: 15.719,
    longitude: 103.277,
  },
  {
    province: "มหาสารคาม",
    district: "นาดูน",
    subdistrict: "พระธาตุ",
    zipcode: 44180,
    latitude: 15.697,
    longitude: 103.258,
  },
  {
    province: "มหาสารคาม",
    district: "ยางสีสุราช",
    subdistrict: "ยางสีสุราช",
    zipcode: 44210,
    latitude: 15.677,
    longitude: 103.093,
  },
  {
    province: "มหาสารคาม",
    district: "ยางสีสุราช",
    subdistrict: "นาภู",
    zipcode: 44210,
    latitude: 15.682,
    longitude: 103.032,
  },
  {
    province: "มหาสารคาม",
    district: "ยางสีสุราช",
    subdistrict: "แวงดง",
    zipcode: 44210,
    latitude: 15.638,
    longitude: 103.035,
  },
  {
    province: "มหาสารคาม",
    district: "ยางสีสุราช",
    subdistrict: "บ้านกู่",
    zipcode: 44210,
    latitude: 15.686,
    longitude: 103.136,
  },
  {
    province: "มหาสารคาม",
    district: "ยางสีสุราช",
    subdistrict: "ดงเมือง",
    zipcode: 44210,
    latitude: 15.649,
    longitude: 103.146,
  },
  {
    province: "มหาสารคาม",
    district: "ยางสีสุราช",
    subdistrict: "สร้างแซ่ง",
    zipcode: 44210,
    latitude: 15.6526271,
    longitude: 103.011507,
  },
  {
    province: "มหาสารคาม",
    district: "ยางสีสุราช",
    subdistrict: "หนองบัวสันตุ",
    zipcode: 44210,
    latitude: 15.637,
    longitude: 103.177,
  },
  {
    province: "มหาสารคาม",
    district: "กุดรัง",
    subdistrict: "กุดรัง",
    zipcode: 44130,
    latitude: 16.072,
    longitude: 103,
  },
  {
    province: "มหาสารคาม",
    district: "กุดรัง",
    subdistrict: "นาโพธิ์",
    zipcode: 44130,
    latitude: 16.077,
    longitude: 102.907,
  },
  {
    province: "มหาสารคาม",
    district: "กุดรัง",
    subdistrict: "เลิงแฝก",
    zipcode: 44130,
    latitude: 15.968,
    longitude: 102.96,
  },
  {
    province: "มหาสารคาม",
    district: "กุดรัง",
    subdistrict: "หนองแวง",
    zipcode: 44130,
    latitude: 16.006,
    longitude: 102.935,
  },
  {
    province: "มหาสารคาม",
    district: "กุดรัง",
    subdistrict: "ห้วยเตย",
    zipcode: 44130,
    latitude: 16.123,
    longitude: 103.049,
  },
  {
    province: "มหาสารคาม",
    district: "ชื่นชม",
    subdistrict: "ชื่นชม",
    zipcode: 44160,
    latitude: 16.551,
    longitude: 103.144,
  },
  {
    province: "มหาสารคาม",
    district: "ชื่นชม",
    subdistrict: "กุดปลาดุก",
    zipcode: 44160,
    latitude: 16.588,
    longitude: 103.138,
  },
  {
    province: "มหาสารคาม",
    district: "ชื่นชม",
    subdistrict: "เหล่าดอกไม้",
    zipcode: 44160,
    latitude: 16.492,
    longitude: 103.145,
  },
  {
    province: "มหาสารคาม",
    district: "ชื่นชม",
    subdistrict: "หนองกุง",
    zipcode: 44160,
    latitude: 16.475,
    longitude: 103.124,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "ในเมือง",
    zipcode: 45000,
    latitude: 16.051,
    longitude: 103.653,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "รอบเมือง",
    zipcode: 45000,
    latitude: 16.006,
    longitude: 103.632,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "เหนือเมือง",
    zipcode: 45000,
    latitude: 16.079,
    longitude: 103.652,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "ขอนแก่น",
    zipcode: 45000,
    latitude: 16.025,
    longitude: 103.591,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "นาโพธิ์",
    zipcode: 45000,
    latitude: 15.961,
    longitude: 103.569,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "สะอาดสมบูรณ์",
    zipcode: 45000,
    latitude: 15.932,
    longitude: 103.626,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "สีแก้ว",
    zipcode: 45000,
    latitude: 16.126,
    longitude: 103.527,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "ปอภาร  (ปอพาน)",
    zipcode: 45000,
    latitude: 16.0854108,
    longitude: 103.4937501,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "โนนรัง",
    zipcode: 45000,
    latitude: 16.002,
    longitude: 103.664,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "หนองแก้ว",
    zipcode: 45000,
    latitude: 15.939,
    longitude: 103.66,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "หนองแวง",
    zipcode: 45000,
    latitude: 16.103,
    longitude: 103.603,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "ดงลาน",
    zipcode: 45000,
    latitude: 16.064,
    longitude: 103.578,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "แคนใหญ่",
    zipcode: 45000,
    latitude: 15.897,
    longitude: 103.672,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "โนนตาล",
    zipcode: 45000,
    latitude: 15.969,
    longitude: 103.666,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองร้อยเอ็ด",
    subdistrict: "เมืองทอง",
    zipcode: 45000,
    latitude: 15.932,
    longitude: 103.591,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "เกษตรวิสัย",
    zipcode: 45150,
    latitude: 15.662,
    longitude: 103.609,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "เมืองบัว",
    zipcode: 45150,
    latitude: 15.593,
    longitude: 103.595,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "เหล่าหลวง",
    zipcode: 45150,
    latitude: 15.72,
    longitude: 103.552,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "สิงห์โคก",
    zipcode: 45150,
    latitude: 15.704,
    longitude: 103.668,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "ดงครั่งใหญ่",
    zipcode: 45150,
    latitude: 15.487,
    longitude: 103.534,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "บ้านฝาง",
    zipcode: 45150,
    latitude: 15.703,
    longitude: 103.452,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "หนองแวง",
    zipcode: 45150,
    latitude: 15.741,
    longitude: 103.631,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "กำแพง",
    zipcode: 45150,
    latitude: 15.56,
    longitude: 103.496,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "กู่กาสิงห์",
    zipcode: 45150,
    latitude: 15.591,
    longitude: 103.655,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "น้ำอ้อม",
    zipcode: 45150,
    latitude: 15.639,
    longitude: 103.511,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "โนนสว่าง",
    zipcode: 45150,
    latitude: 15.704,
    longitude: 103.506,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "ทุ่งทอง",
    zipcode: 45150,
    latitude: 15.487,
    longitude: 103.451,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เกษตรวิสัย",
    subdistrict: "ดงครั่งน้อย",
    zipcode: 45150,
    latitude: 15.511,
    longitude: 103.617,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ปทุมรัตต์",
    subdistrict: "บัวแดง",
    zipcode: 45190,
    latitude: 15.65,
    longitude: 103.359,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ปทุมรัตต์",
    subdistrict: "ดอกล้ำ",
    zipcode: 45190,
    latitude: 15.639,
    longitude: 103.309,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ปทุมรัตต์",
    subdistrict: "หนองแคน",
    zipcode: 45190,
    latitude: 15.688,
    longitude: 103.374,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ปทุมรัตต์",
    subdistrict: "โพนสูง",
    zipcode: 45190,
    latitude: 15.655,
    longitude: 103.444,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ปทุมรัตต์",
    subdistrict: "โนนสวรรค์",
    zipcode: 45190,
    latitude: 15.584,
    longitude: 103.435,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ปทุมรัตต์",
    subdistrict: "สระบัว",
    zipcode: 45190,
    latitude: 15.568,
    longitude: 103.371,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ปทุมรัตต์",
    subdistrict: "โนนสง่า",
    zipcode: 45190,
    latitude: 15.608,
    longitude: 103.349,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ปทุมรัตต์",
    subdistrict: "ขี้เหล็ก",
    zipcode: 45190,
    latitude: 15.635,
    longitude: 103.471,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "หัวช้าง",
    zipcode: 45180,
    latitude: 15.845,
    longitude: 103.569,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "หนองผือ",
    zipcode: 45180,
    latitude: 15.836,
    longitude: 103.514,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "เมืองหงส์",
    zipcode: 45180,
    latitude: 15.874,
    longitude: 103.48,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "โคกล่าม",
    zipcode: 45180,
    latitude: 15.908,
    longitude: 103.535,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "น้ำใส",
    zipcode: 45180,
    latitude: 15.901,
    longitude: 103.599,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "ดงแดง",
    zipcode: 45180,
    latitude: 15.854,
    longitude: 103.669,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "ดงกลาง",
    zipcode: 45180,
    latitude: 15.803,
    longitude: 103.604,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "ป่าสังข์",
    zipcode: 45180,
    latitude: 15.805,
    longitude: 103.665,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "อีง่อง",
    zipcode: 45180,
    latitude: 15.776,
    longitude: 103.515,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "ลิ้นฟ้า",
    zipcode: 45180,
    latitude: 15.756,
    longitude: 103.434,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "ดู่น้อย",
    zipcode: 45180,
    latitude: 15.784,
    longitude: 103.467,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จตุรพักตรพิมาน",
    subdistrict: "ศรีโคตร",
    zipcode: 45180,
    latitude: 15.773,
    longitude: 103.566,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "นิเวศน์",
    zipcode: 45170,
    latitude: 16.042,
    longitude: 103.737,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "ธงธานี",
    zipcode: 45170,
    latitude: 16.103,
    longitude: 103.853,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "หนองไผ่",
    zipcode: 45170,
    latitude: 15.996,
    longitude: 103.722,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "ธวัชบุรี",
    zipcode: 45170,
    latitude: 16.116,
    longitude: 103.811,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "อุ่มเม้า",
    zipcode: 45170,
    latitude: 16.025,
    longitude: 103.773,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "มะอึ",
    zipcode: 45170,
    latitude: 16.081,
    longitude: 103.731,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "เขวาทุ่ง",
    zipcode: 45170,
    latitude: 15.945,
    longitude: 103.76,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "ไพศาล",
    zipcode: 45170,
    latitude: 16.026,
    longitude: 103.817,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "เมืองน้อย",
    zipcode: 45170,
    latitude: 15.985,
    longitude: 103.764,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "บึงนคร",
    zipcode: 45170,
    latitude: 16.072,
    longitude: 103.852,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "ราชธานี",
    zipcode: 45170,
    latitude: 15.883,
    longitude: 103.728,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ธวัชบุรี",
    subdistrict: "หนองพอก",
    zipcode: 45170,
    latitude: 16.099,
    longitude: 103.767,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "พนมไพร",
    zipcode: 45140,
    latitude: 15.688,
    longitude: 104.118,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "แสนสุข",
    zipcode: 45140,
    latitude: 15.733,
    longitude: 104.076,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "กุดน้ำใส",
    zipcode: 45140,
    latitude: 15.762,
    longitude: 104.065,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "หนองทัพไทย",
    zipcode: 45140,
    latitude: 15.689,
    longitude: 103.984,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "โพธิ์ใหญ่",
    zipcode: 45140,
    latitude: 15.757,
    longitude: 104.013,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "วารีสวัสดิ์",
    zipcode: 45140,
    latitude: 15.684,
    longitude: 104.044,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "โคกสว่าง",
    zipcode: 45140,
    latitude: 15.637,
    longitude: 104.074,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "โพธิ์ชัย",
    zipcode: 45140,
    latitude: 15.591,
    longitude: 104.08,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "นานวล",
    zipcode: 45140,
    latitude: 15.639,
    longitude: 104.124,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "คำไฮ",
    zipcode: 45140,
    latitude: 15.639,
    longitude: 104.159,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "สระแก้ว",
    zipcode: 45140,
    latitude: 15.704,
    longitude: 104.08,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "ค้อใหญ่",
    zipcode: 45140,
    latitude: 15.6,
    longitude: 104.139,
  },
  {
    province: "ร้อยเอ็ด",
    district: "พนมไพร",
    subdistrict: "ชานุวรรณ",
    zipcode: 45140,
    latitude: 15.803,
    longitude: 104.088,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "แวง",
    zipcode: 45110,
    latitude: 16.329,
    longitude: 103.984,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "โคกกกม่วง",
    zipcode: 45110,
    latitude: 16.181,
    longitude: 104.028,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "นาอุดม",
    zipcode: 45110,
    latitude: 16.318,
    longitude: 103.856,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "สว่าง",
    zipcode: 45110,
    latitude: 16.218,
    longitude: 104.005,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "หนองใหญ่",
    zipcode: 45110,
    latitude: 16.401,
    longitude: 103.998,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "โพธิ์ทอง",
    zipcode: 45110,
    latitude: 16.322,
    longitude: 103.92,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "โนนชัยศรี",
    zipcode: 45110,
    latitude: 16.213,
    longitude: 103.956,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "โพธิ์ศรีสว่าง",
    zipcode: 45110,
    latitude: 16.242,
    longitude: 103.888,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "อุ่มเม่า",
    zipcode: 45110,
    latitude: 16.277,
    longitude: 103.862,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "คำนาดี",
    zipcode: 45110,
    latitude: 16.396,
    longitude: 103.915,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "พรมสวรรค์",
    zipcode: 45110,
    latitude: 16.199,
    longitude: 104.084,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "สระนกแก้ว",
    zipcode: 45110,
    latitude: 16.271,
    longitude: 103.986,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "วังสามัคคี",
    zipcode: 45110,
    latitude: 16.267,
    longitude: 104.041,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทอง",
    subdistrict: "โคกสูง",
    zipcode: 45110,
    latitude: 16.206,
    longitude: 103.873,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพธิ์ชัย",
    subdistrict: "ขามเปี้ย",
    zipcode: 45230,
    latitude: 16.334,
    longitude: 103.748,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพธิ์ชัย",
    subdistrict: "เชียงใหม่",
    zipcode: 45230,
    latitude: 16.242,
    longitude: 103.804,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพธิ์ชัย",
    subdistrict: "บัวคำ",
    zipcode: 45230,
    latitude: 16.281,
    longitude: 103.812,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพธิ์ชัย",
    subdistrict: "อัคคะคำ",
    zipcode: 45230,
    latitude: 16.385,
    longitude: 103.746,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพธิ์ชัย",
    subdistrict: "สะอาด",
    zipcode: 45230,
    latitude: 16.209,
    longitude: 103.767,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพธิ์ชัย",
    subdistrict: "คำพอุง",
    zipcode: 45230,
    latitude: 16.423,
    longitude: 103.84,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพธิ์ชัย",
    subdistrict: "หนองตาไก้",
    zipcode: 45230,
    latitude: 16.256,
    longitude: 103.76,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพธิ์ชัย",
    subdistrict: "ดอนโอง",
    zipcode: 45230,
    latitude: 16.206,
    longitude: 103.826,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพธิ์ชัย",
    subdistrict: "โพธิ์ศรี",
    zipcode: 45230,
    latitude: 16.344,
    longitude: 103.83,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองพอก",
    subdistrict: "หนองพอก",
    zipcode: 45210,
    latitude: 16.318,
    longitude: 104.159,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองพอก",
    subdistrict: "บึงงาม",
    zipcode: 45210,
    latitude: 16.388,
    longitude: 104.3,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองพอก",
    subdistrict: "ภูเขาทอง",
    zipcode: 45210,
    latitude: 16.398,
    longitude: 104.228,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองพอก",
    subdistrict: "กกโพธิ์",
    zipcode: 45210,
    latitude: 16.296,
    longitude: 104.113,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองพอก",
    subdistrict: "โคกสว่าง",
    zipcode: 45210,
    latitude: 16.268,
    longitude: 104.275,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองพอก",
    subdistrict: "หนองขุ่นใหญ่",
    zipcode: 45210,
    latitude: 16.234,
    longitude: 104.146,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองพอก",
    subdistrict: "รอบเมือง",
    zipcode: 45210,
    latitude: 16.283,
    longitude: 104.207,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองพอก",
    subdistrict: "ผาน้ำย้อย",
    zipcode: 45210,
    latitude: 16.331,
    longitude: 104.289,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองพอก",
    subdistrict: "ท่าสีดา",
    zipcode: 45210,
    latitude: 16.2,
    longitude: 104.215,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "กลาง",
    zipcode: 45120,
    latitude: 16.024,
    longitude: 103.921,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "นางาม",
    zipcode: 45120,
    latitude: 15.911,
    longitude: 103.94,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "เมืองไพร",
    zipcode: 45120,
    latitude: 16.001,
    longitude: 103.993,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "นาแซง",
    zipcode: 45120,
    latitude: 16.144,
    longitude: 103.968,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "นาเมือง",
    zipcode: 45120,
    latitude: 16.074,
    longitude: 103.94,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "วังหลวง",
    zipcode: 45120,
    latitude: 16.09,
    longitude: 103.995,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "ท่าม่วง",
    zipcode: 45120,
    latitude: 16.124,
    longitude: 103.893,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "ขวาว",
    zipcode: 45120,
    latitude: 15.946,
    longitude: 103.99,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "โพธิ์ทอง",
    zipcode: 45120,
    latitude: 16.136,
    longitude: 104.138,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "ภูเงิน",
    zipcode: 45120,
    latitude: 16.04,
    longitude: 104.078,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "เกาะแก้ว",
    zipcode: 45120,
    latitude: 16.161,
    longitude: 103.868,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "นาเลิง",
    zipcode: 45120,
    latitude: 15.933,
    longitude: 103.915,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "เหล่าน้อย",
    zipcode: 45120,
    latitude: 16.095,
    longitude: 104.06,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "ศรีวิลัย",
    zipcode: 45120,
    latitude: 16.1355793,
    longitude: 104.0135443,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "หนองหลวง",
    zipcode: 45120,
    latitude: 16.148,
    longitude: 103.923,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "พรสวรรค์",
    zipcode: 45120,
    latitude: 16.184,
    longitude: 104.148,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "ขวัญเมือง",
    zipcode: 45120,
    latitude: 16.028,
    longitude: 103.958,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เสลภูมิ",
    subdistrict: "บึงเกลือ",
    zipcode: 45120,
    latitude: 16.02,
    longitude: 104.021,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "สระคู",
    zipcode: 45130,
    latitude: 15.552,
    longitude: 103.766,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "ดอกไม้",
    zipcode: 45130,
    latitude: 15.654,
    longitude: 103.774,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "นาใหญ่",
    zipcode: 45130,
    latitude: 15.704,
    longitude: 103.75,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "หินกอง",
    zipcode: 45130,
    latitude: 15.599,
    longitude: 103.73,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "เมืองทุ่ง",
    zipcode: 45130,
    latitude: 15.627,
    longitude: 103.85,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "หัวโทน",
    zipcode: 45130,
    latitude: 15.736,
    longitude: 103.877,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "บ่อพันขัน",
    zipcode: 45130,
    latitude: 15.604,
    longitude: 103.898,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "ทุ่งหลวง",
    zipcode: 45130,
    latitude: 15.482,
    longitude: 103.713,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "หัวช้าง",
    zipcode: 45130,
    latitude: 15.645,
    longitude: 103.915,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "น้ำคำ",
    zipcode: 45130,
    latitude: 15.734,
    longitude: 103.824,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "ห้วยหินลาด",
    zipcode: 45130,
    latitude: 15.685,
    longitude: 103.879,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "ช้างเผือก",
    zipcode: 45130,
    latitude: 15.681,
    longitude: 103.938,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "ทุ่งกุลา",
    zipcode: 45130,
    latitude: 15.482,
    longitude: 103.805,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "ทุ่งศรีเมือง",
    zipcode: 45130,
    latitude: 15.575,
    longitude: 103.877,
  },
  {
    province: "ร้อยเอ็ด",
    district: "สุวรรณภูมิ",
    subdistrict: "จำปาขัน",
    zipcode: 45130,
    latitude: 15.591,
    longitude: 103.928,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองสรวง",
    subdistrict: "หนองผือ",
    zipcode: 45220,
    latitude: 15.817,
    longitude: 103.714,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองสรวง",
    subdistrict: "หนองหิน",
    zipcode: 45220,
    latitude: 15.786,
    longitude: 103.797,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองสรวง",
    subdistrict: "คูเมือง",
    zipcode: 45220,
    latitude: 15.749,
    longitude: 103.722,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองสรวง",
    subdistrict: "กกกุง",
    zipcode: 45220,
    latitude: 15.77,
    longitude: 103.758,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมืองสรวง",
    subdistrict: "เมืองสรวง",
    zipcode: 45220,
    latitude: 15.817,
    longitude: 103.755,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทราย",
    subdistrict: "โพนทราย",
    zipcode: 45240,
    latitude: 15.497,
    longitude: 104.005,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทราย",
    subdistrict: "สามขา",
    zipcode: 45240,
    latitude: 15.474,
    longitude: 103.944,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทราย",
    subdistrict: "ศรีสว่าง",
    zipcode: 45240,
    latitude: 15.533,
    longitude: 103.868,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทราย",
    subdistrict: "ยางคำ",
    zipcode: 45240,
    latitude: 15.443,
    longitude: 103.971,
  },
  {
    province: "ร้อยเอ็ด",
    district: "โพนทราย",
    subdistrict: "ท่าหาดยาว",
    zipcode: 45240,
    latitude: 15.493,
    longitude: 103.91,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "อาจสามารถ",
    zipcode: 45160,
    latitude: 15.864,
    longitude: 103.85,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "โพนเมือง",
    zipcode: 45160,
    latitude: 15.913,
    longitude: 103.881,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "บ้านแจ้ง",
    zipcode: 45160,
    latitude: 15.865,
    longitude: 103.925,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "หน่อม",
    zipcode: 45160,
    latitude: 15.808,
    longitude: 103.955,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "หนองหมื่นถ่าน",
    zipcode: 45160,
    latitude: 15.755,
    longitude: 103.937,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "หนองขาม",
    zipcode: 45160,
    latitude: 15.8,
    longitude: 103.867,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "โหรา",
    zipcode: 45160,
    latitude: 15.854,
    longitude: 103.802,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "หนองบัว",
    zipcode: 45160,
    latitude: 15.861,
    longitude: 103.765,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "ขี้เหล็ก",
    zipcode: 45160,
    latitude: 15.903,
    longitude: 103.791,
  },
  {
    province: "ร้อยเอ็ด",
    district: "อาจสามารถ",
    subdistrict: "บ้านดู่",
    zipcode: 45160,
    latitude: 15.8821749,
    longitude: 103.6910374,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมยวดี",
    subdistrict: "เมยวดี",
    zipcode: 45250,
    latitude: 16.395,
    longitude: 104.167,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมยวดี",
    subdistrict: "ชุมพร",
    zipcode: 45250,
    latitude: 16.342,
    longitude: 104.067,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมยวดี",
    subdistrict: "บุ่งเลิศ",
    zipcode: 45250,
    latitude: 16.416,
    longitude: 104.123,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เมยวดี",
    subdistrict: "ชมสะอาด",
    zipcode: 45250,
    latitude: 16.393,
    longitude: 104.098,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ศรีสมเด็จ",
    subdistrict: "โพธิ์ทอง",
    zipcode: 45000,
    latitude: 15.973,
    longitude: 103.535,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ศรีสมเด็จ",
    subdistrict: "ศรีสมเด็จ",
    zipcode: 45000,
    latitude: 15.98,
    longitude: 103.474,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ศรีสมเด็จ",
    subdistrict: "เมืองเปลือย",
    zipcode: 45000,
    latitude: 16.011,
    longitude: 103.551,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ศรีสมเด็จ",
    subdistrict: "หนองใหญ่",
    zipcode: 45000,
    latitude: 16.044,
    longitude: 103.541,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ศรีสมเด็จ",
    subdistrict: "สวนจิก",
    zipcode: 45280,
    latitude: 16.034,
    longitude: 103.492,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ศรีสมเด็จ",
    subdistrict: "โพธิ์สัย",
    zipcode: 45280,
    latitude: 16.096,
    longitude: 103.475,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ศรีสมเด็จ",
    subdistrict: "หนองแวงควง",
    zipcode: 45000,
    latitude: 15.95,
    longitude: 103.506,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ศรีสมเด็จ",
    subdistrict: "บ้านบาก",
    zipcode: 45000,
    latitude: 15.987,
    longitude: 103.518,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จังหาร",
    subdistrict: "ดินดำ",
    zipcode: 45000,
    latitude: 16.183,
    longitude: 103.679,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จังหาร",
    subdistrict: "ปาฝา",
    zipcode: 45000,
    latitude: 16.14,
    longitude: 103.637,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จังหาร",
    subdistrict: "ม่วงลาด",
    zipcode: 45000,
    latitude: 16.204,
    longitude: 103.543,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จังหาร",
    subdistrict: "จังหาร",
    zipcode: 45000,
    latitude: 16.14,
    longitude: 103.594,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จังหาร",
    subdistrict: "ดงสิงห์",
    zipcode: 45000,
    latitude: 16.175,
    longitude: 103.58,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จังหาร",
    subdistrict: "ยางใหญ่",
    zipcode: 45000,
    latitude: 16.132,
    longitude: 103.679,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จังหาร",
    subdistrict: "ผักแว่น",
    zipcode: 45000,
    latitude: 16.174,
    longitude: 103.535,
  },
  {
    province: "ร้อยเอ็ด",
    district: "จังหาร",
    subdistrict: "แสนชาติ",
    zipcode: 45000,
    latitude: 16.176,
    longitude: 103.639,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เชียงขวัญ",
    subdistrict: "เชียงขวัญ",
    zipcode: 45000,
    latitude: 16.191,
    longitude: 103.724,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เชียงขวัญ",
    subdistrict: "พลับพลา",
    zipcode: 45170,
    latitude: 16.135,
    longitude: 103.782,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เชียงขวัญ",
    subdistrict: "พระธาตุ",
    zipcode: 45000,
    latitude: 16.16,
    longitude: 103.769,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เชียงขวัญ",
    subdistrict: "พระเจ้า",
    zipcode: 45000,
    latitude: 16.128,
    longitude: 103.741,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เชียงขวัญ",
    subdistrict: "หมูม้น",
    zipcode: 45170,
    latitude: 16.101,
    longitude: 103.705,
  },
  {
    province: "ร้อยเอ็ด",
    district: "เชียงขวัญ",
    subdistrict: "บ้านเขือง",
    zipcode: 45000,
    latitude: 16.15,
    longitude: 103.71,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองฮี",
    subdistrict: "หนองฮี",
    zipcode: 45140,
    latitude: 15.621,
    longitude: 104,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองฮี",
    subdistrict: "สาวแห",
    zipcode: 45140,
    latitude: 15.55,
    longitude: 104.062,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองฮี",
    subdistrict: "ดูกอึ่ง",
    zipcode: 45140,
    latitude: 15.559,
    longitude: 104.016,
  },
  {
    province: "ร้อยเอ็ด",
    district: "หนองฮี",
    subdistrict: "เด่นราษฎร์",
    zipcode: 45140,
    latitude: 15.582,
    longitude: 103.965,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ทุ่งเขาหลวง",
    subdistrict: "ทุ่งเขาหลวง",
    zipcode: 45170,
    latitude: 16.032,
    longitude: 103.853,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ทุ่งเขาหลวง",
    subdistrict: "เทอดไทย",
    zipcode: 45170,
    latitude: 15.963,
    longitude: 103.866,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ทุ่งเขาหลวง",
    subdistrict: "บึงงาม",
    zipcode: 45170,
    latitude: 15.986,
    longitude: 103.894,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ทุ่งเขาหลวง",
    subdistrict: "มะบ้า",
    zipcode: 45170,
    latitude: 16.039,
    longitude: 103.889,
  },
  {
    province: "ร้อยเอ็ด",
    district: "ทุ่งเขาหลวง",
    subdistrict: "เหล่า",
    zipcode: 45170,
    latitude: 15.965,
    longitude: 103.827,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "กาฬสินธุ์",
    zipcode: 46000,
    latitude: 16.437,
    longitude: 103.522,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "เหนือ",
    zipcode: 46000,
    latitude: 16.442,
    longitude: 103.574,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "หลุบ",
    zipcode: 46000,
    latitude: 16.393,
    longitude: 103.512,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "ไผ่",
    zipcode: 46000,
    latitude: 16.522,
    longitude: 103.575,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "ลำปาว",
    zipcode: 46000,
    latitude: 16.591,
    longitude: 103.496,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "ลำพาน",
    zipcode: 46000,
    latitude: 16.457,
    longitude: 103.484,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "เชียงเครือ",
    zipcode: 46000,
    latitude: 16.434,
    longitude: 103.612,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "บึงวิชัย",
    zipcode: 46000,
    latitude: 16.5,
    longitude: 103.499,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "ห้วยโพธิ์",
    zipcode: 46000,
    latitude: 16.392,
    longitude: 103.578,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "ภูปอ",
    zipcode: 46000,
    latitude: 16.59,
    longitude: 103.615,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "ภูดิน",
    zipcode: 46000,
    latitude: 16.636,
    longitude: 103.528,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "หนองกุง",
    zipcode: 46000,
    latitude: 16.534,
    longitude: 103.526,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "กลางหมื่น",
    zipcode: 46000,
    latitude: 16.518,
    longitude: 103.641,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "ขมิ้น",
    zipcode: 46000,
    latitude: 16.607,
    longitude: 103.569,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "โพนทอง",
    zipcode: 46000,
    latitude: 16.478,
    longitude: 103.548,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "นาจารย์",
    zipcode: 46000,
    latitude: 16.561,
    longitude: 103.643,
  },
  {
    province: "กาฬสินธุ์",
    district: "เมืองกาฬสินธุ์",
    subdistrict: "ลำคลอง",
    zipcode: 46000,
    latitude: 16.587,
    longitude: 103.45,
  },
  {
    province: "กาฬสินธุ์",
    district: "นามน",
    subdistrict: "นามน",
    zipcode: 46230,
    latitude: 16.561,
    longitude: 103.776,
  },
  {
    province: "กาฬสินธุ์",
    district: "นามน",
    subdistrict: "ยอดแกง",
    zipcode: 46230,
    latitude: 16.622,
    longitude: 103.735,
  },
  {
    province: "กาฬสินธุ์",
    district: "นามน",
    subdistrict: "สงเปลือย",
    zipcode: 46230,
    latitude: 16.624,
    longitude: 103.793,
  },
  {
    province: "กาฬสินธุ์",
    district: "นามน",
    subdistrict: "หลักเหลี่ยม",
    zipcode: 46230,
    latitude: 16.548,
    longitude: 103.82,
  },
  {
    province: "กาฬสินธุ์",
    district: "นามน",
    subdistrict: "หนองบัว",
    zipcode: 46230,
    latitude: 16.537,
    longitude: 103.855,
  },
  {
    province: "กาฬสินธุ์",
    district: "กมลาไสย",
    subdistrict: "กมลาไสย",
    zipcode: 46130,
    latitude: 16.31,
    longitude: 103.551,
  },
  {
    province: "กาฬสินธุ์",
    district: "กมลาไสย",
    subdistrict: "หลักเมือง",
    zipcode: 46130,
    latitude: 16.332,
    longitude: 103.595,
  },
  {
    province: "กาฬสินธุ์",
    district: "กมลาไสย",
    subdistrict: "โพนงาม",
    zipcode: 46130,
    latitude: 16.316,
    longitude: 103.658,
  },
  {
    province: "กาฬสินธุ์",
    district: "กมลาไสย",
    subdistrict: "ดงลิง",
    zipcode: 46130,
    latitude: 16.25,
    longitude: 103.661,
  },
  {
    province: "กาฬสินธุ์",
    district: "กมลาไสย",
    subdistrict: "ธัญญา",
    zipcode: 46130,
    latitude: 16.259,
    longitude: 103.567,
  },
  {
    province: "กาฬสินธุ์",
    district: "กมลาไสย",
    subdistrict: "หนองแปน",
    zipcode: 46130,
    latitude: 16.289,
    longitude: 103.511,
  },
  {
    province: "กาฬสินธุ์",
    district: "กมลาไสย",
    subdistrict: "เจ้าท่า",
    zipcode: 46130,
    latitude: 16.225,
    longitude: 103.591,
  },
  {
    province: "กาฬสินธุ์",
    district: "กมลาไสย",
    subdistrict: "โคกสมบูรณ์",
    zipcode: 46130,
    latitude: 16.36,
    longitude: 103.667,
  },
  {
    province: "กาฬสินธุ์",
    district: "ร่องคำ",
    subdistrict: "ร่องคำ",
    zipcode: 46210,
    latitude: 16.266,
    longitude: 103.737,
  },
  {
    province: "กาฬสินธุ์",
    district: "ร่องคำ",
    subdistrict: "สามัคคี",
    zipcode: 46210,
    latitude: 16.318,
    longitude: 103.713,
  },
  {
    province: "กาฬสินธุ์",
    district: "ร่องคำ",
    subdistrict: "เหล่าอ้อย",
    zipcode: 46210,
    latitude: 16.254,
    longitude: 103.708,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "บัวขาว",
    zipcode: 46110,
    latitude: 16.521,
    longitude: 104.055,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "แจนแลน",
    zipcode: 46110,
    latitude: 16.554,
    longitude: 103.998,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "เหล่าใหญ่",
    zipcode: 46110,
    latitude: 16.622,
    longitude: 103.993,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "จุมจัง",
    zipcode: 46110,
    latitude: 16.458,
    longitude: 104.107,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "เหล่าไฮงาม",
    zipcode: 46110,
    latitude: 16.471,
    longitude: 104.171,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "กุดหว้า",
    zipcode: 46110,
    latitude: 16.537,
    longitude: 104.167,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "สามขา",
    zipcode: 46110,
    latitude: 16.464,
    longitude: 103.975,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "นาขาม",
    zipcode: 46110,
    latitude: 16.508,
    longitude: 103.905,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "หนองห้าง",
    zipcode: 46110,
    latitude: 16.572,
    longitude: 104.124,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "นาโก",
    zipcode: 46110,
    latitude: 16.606,
    longitude: 104.055,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "สมสะอาด",
    zipcode: 46110,
    latitude: 16.451,
    longitude: 104.048,
  },
  {
    province: "กาฬสินธุ์",
    district: "กุฉินารายณ์",
    subdistrict: "กุดค้าว",
    zipcode: 46110,
    latitude: 16.506,
    longitude: 104.001,
  },
  {
    province: "กาฬสินธุ์",
    district: "เขาวง",
    subdistrict: "คุ้มเก่า",
    zipcode: 46160,
    latitude: 16.679,
    longitude: 104.132,
  },
  {
    province: "กาฬสินธุ์",
    district: "เขาวง",
    subdistrict: "สงเปลือย",
    zipcode: 46160,
    latitude: 16.626,
    longitude: 104.119,
  },
  {
    province: "กาฬสินธุ์",
    district: "เขาวง",
    subdistrict: "หนองผือ",
    zipcode: 46160,
    latitude: 16.751,
    longitude: 104.094,
  },
  {
    province: "กาฬสินธุ์",
    district: "เขาวง",
    subdistrict: "กุดสิมคุ้มใหม่",
    zipcode: 46160,
    latitude: 16.676,
    longitude: 104.049,
  },
  {
    province: "กาฬสินธุ์",
    district: "เขาวง",
    subdistrict: "สระพังทอง",
    zipcode: 46160,
    latitude: 16.719,
    longitude: 104.055,
  },
  {
    province: "กาฬสินธุ์",
    district: "เขาวง",
    subdistrict: "กุดปลาค้าว",
    zipcode: 46160,
    latitude: 16.715,
    longitude: 104.115,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "ยางตลาด",
    zipcode: 46120,
    latitude: 16.393,
    longitude: 103.352,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "หัวงัว",
    zipcode: 46120,
    latitude: 16.337,
    longitude: 103.446,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "อุ่มเม่า",
    zipcode: 46120,
    latitude: 16.398,
    longitude: 103.464,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "บัวบาน",
    zipcode: 46120,
    latitude: 16.479,
    longitude: 103.416,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "เว่อ",
    zipcode: 46120,
    latitude: 16.543,
    longitude: 103.351,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "อิตื้อ",
    zipcode: 46120,
    latitude: 16.464,
    longitude: 103.262,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "หัวนาคำ",
    zipcode: 46120,
    latitude: 16.392,
    longitude: 103.227,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "หนองอิเฒ่า",
    zipcode: 46120,
    latitude: 16.3414312,
    longitude: 103.3319768,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "ดอนสมบูรณ์",
    zipcode: 46120,
    latitude: 16.437,
    longitude: 103.406,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "นาเชือก",
    zipcode: 46120,
    latitude: 16.557,
    longitude: 103.415,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "คลองขาม",
    zipcode: 46120,
    latitude: 16.456,
    longitude: 103.338,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "เขาพระนอน",
    zipcode: 46120,
    latitude: 16.529,
    longitude: 103.38,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "นาดี",
    zipcode: 46120,
    latitude: 16.361,
    longitude: 103.474,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "โนนสูง",
    zipcode: 46120,
    latitude: 16.464,
    longitude: 103.207,
  },
  {
    province: "กาฬสินธุ์",
    district: "ยางตลาด",
    subdistrict: "หนองตอกแป้น",
    zipcode: 46120,
    latitude: 16.319,
    longitude: 103.406,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยเม็ก",
    subdistrict: "ห้วยเม็ก",
    zipcode: 46170,
    latitude: 16.596,
    longitude: 103.216,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยเม็ก",
    subdistrict: "คำใหญ่",
    zipcode: 46170,
    latitude: 16.664,
    longitude: 103.177,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยเม็ก",
    subdistrict: "กุดโดน",
    zipcode: 46170,
    latitude: 16.544,
    longitude: 103.268,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยเม็ก",
    subdistrict: "บึงนาเรียง",
    zipcode: 46170,
    latitude: 16.6,
    longitude: 103.288,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยเม็ก",
    subdistrict: "หัวหิน",
    zipcode: 46170,
    latitude: 16.602,
    longitude: 103.358,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยเม็ก",
    subdistrict: "พิมูล",
    zipcode: 46170,
    latitude: 16.703,
    longitude: 103.181,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยเม็ก",
    subdistrict: "คำเหมือดแก้ว",
    zipcode: 46170,
    latitude: 16.523,
    longitude: 103.199,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยเม็ก",
    subdistrict: "โนนสะอาด",
    zipcode: 46170,
    latitude: 16.538,
    longitude: 103.314,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยเม็ก",
    subdistrict: "ทรายทอง",
    zipcode: 46170,
    latitude: 16.74,
    longitude: 103.175,
  },
  {
    province: "กาฬสินธุ์",
    district: "สหัสขันธ์",
    subdistrict: "ภูสิงห์",
    zipcode: 46140,
    latitude: 16.7374836,
    longitude: 103.4598585,
  },
  {
    province: "กาฬสินธุ์",
    district: "สหัสขันธ์",
    subdistrict: "สหัสขันธ์",
    zipcode: 46140,
    latitude: 16.728,
    longitude: 103.55,
  },
  {
    province: "กาฬสินธุ์",
    district: "สหัสขันธ์",
    subdistrict: "นามะเขือ",
    zipcode: 46140,
    latitude: 16.678,
    longitude: 103.638,
  },
  {
    province: "กาฬสินธุ์",
    district: "สหัสขันธ์",
    subdistrict: "โนนศิลา",
    zipcode: 46140,
    latitude: 16.8,
    longitude: 103.605,
  },
  {
    province: "กาฬสินธุ์",
    district: "สหัสขันธ์",
    subdistrict: "นิคม",
    zipcode: 46140,
    latitude: 16.677,
    longitude: 103.492,
  },
  {
    province: "กาฬสินธุ์",
    district: "สหัสขันธ์",
    subdistrict: "โนนแหลมทอง",
    zipcode: 46140,
    latitude: 16.751,
    longitude: 103.619,
  },
  {
    province: "กาฬสินธุ์",
    district: "สหัสขันธ์",
    subdistrict: "โนนบุรี",
    zipcode: 46140,
    latitude: 16.706,
    longitude: 103.491,
  },
  {
    province: "กาฬสินธุ์",
    district: "สหัสขันธ์",
    subdistrict: "โนนน้ำเกลี้ยง",
    zipcode: 46140,
    latitude: 16.683,
    longitude: 103.538,
  },
  {
    province: "กาฬสินธุ์",
    district: "คำม่วง",
    subdistrict: "ทุ่งคลอง",
    zipcode: 46180,
    latitude: 16.938,
    longitude: 103.679,
  },
  {
    province: "กาฬสินธุ์",
    district: "คำม่วง",
    subdistrict: "โพน",
    zipcode: 46180,
    latitude: 16.841,
    longitude: 103.637,
  },
  {
    province: "กาฬสินธุ์",
    district: "คำม่วง",
    subdistrict: "ดินจี่",
    zipcode: 46180,
    latitude: 16.962,
    longitude: 103.719,
  },
  {
    province: "กาฬสินธุ์",
    district: "คำม่วง",
    subdistrict: "นาบอน",
    zipcode: 46180,
    latitude: 16.881,
    longitude: 103.673,
  },
  {
    province: "กาฬสินธุ์",
    district: "คำม่วง",
    subdistrict: "นาทัน",
    zipcode: 46180,
    latitude: 17.024,
    longitude: 103.613,
  },
  {
    province: "กาฬสินธุ์",
    district: "คำม่วง",
    subdistrict: "เนินยาง",
    zipcode: 46180,
    latitude: 16.803,
    longitude: 103.655,
  },
  {
    province: "กาฬสินธุ์",
    district: "ท่าคันโท",
    subdistrict: "ท่าคันโท",
    zipcode: 46190,
    latitude: 16.869,
    longitude: 103.237,
  },
  {
    province: "กาฬสินธุ์",
    district: "ท่าคันโท",
    subdistrict: "กุงเก่า",
    zipcode: 46190,
    latitude: 16.87,
    longitude: 103.188,
  },
  {
    province: "กาฬสินธุ์",
    district: "ท่าคันโท",
    subdistrict: "ยางอู้ม",
    zipcode: 46190,
    latitude: 16.902,
    longitude: 103.286,
  },
  {
    province: "กาฬสินธุ์",
    district: "ท่าคันโท",
    subdistrict: "กุดจิก",
    zipcode: 46190,
    latitude: 16.843,
    longitude: 103.115,
  },
  {
    province: "กาฬสินธุ์",
    district: "ท่าคันโท",
    subdistrict: "นาตาล",
    zipcode: 46190,
    latitude: 16.847,
    longitude: 103.28,
  },
  {
    province: "กาฬสินธุ์",
    district: "ท่าคันโท",
    subdistrict: "ดงสมบูรณ์",
    zipcode: 46190,
    latitude: 16.869,
    longitude: 103.366,
  },
  {
    province: "กาฬสินธุ์",
    district: "หนองกุงศรี",
    subdistrict: "หนองกุงศรี",
    zipcode: 46220,
    latitude: 16.649,
    longitude: 103.274,
  },
  {
    province: "กาฬสินธุ์",
    district: "หนองกุงศรี",
    subdistrict: "หนองบัว",
    zipcode: 46220,
    latitude: 16.728,
    longitude: 103.376,
  },
  {
    province: "กาฬสินธุ์",
    district: "หนองกุงศรี",
    subdistrict: "โคกเครือ",
    zipcode: 46220,
    latitude: 16.781,
    longitude: 103.238,
  },
  {
    province: "กาฬสินธุ์",
    district: "หนองกุงศรี",
    subdistrict: "หนองสรวง",
    zipcode: 46220,
    latitude: 16.77,
    longitude: 103.422,
  },
  {
    province: "กาฬสินธุ์",
    district: "หนองกุงศรี",
    subdistrict: "เสาเล้า",
    zipcode: 46220,
    latitude: 16.651,
    longitude: 103.392,
  },
  {
    province: "กาฬสินธุ์",
    district: "หนองกุงศรี",
    subdistrict: "หนองใหญ่",
    zipcode: 46220,
    latitude: 16.701,
    longitude: 103.223,
  },
  {
    province: "กาฬสินธุ์",
    district: "หนองกุงศรี",
    subdistrict: "ดงมูล",
    zipcode: 46220,
    latitude: 16.779,
    longitude: 103.313,
  },
  {
    province: "กาฬสินธุ์",
    district: "หนองกุงศรี",
    subdistrict: "ลำหนองแสน",
    zipcode: 46220,
    latitude: 16.678,
    longitude: 103.307,
  },
  {
    province: "กาฬสินธุ์",
    district: "หนองกุงศรี",
    subdistrict: "หนองหิน",
    zipcode: 46220,
    latitude: 16.823,
    longitude: 103.397,
  },
  {
    province: "กาฬสินธุ์",
    district: "สมเด็จ",
    subdistrict: "สมเด็จ",
    zipcode: 46150,
    latitude: 16.705,
    longitude: 103.754,
  },
  {
    province: "กาฬสินธุ์",
    district: "สมเด็จ",
    subdistrict: "หนองแวง",
    zipcode: 46150,
    latitude: 16.66,
    longitude: 103.69,
  },
  {
    province: "กาฬสินธุ์",
    district: "สมเด็จ",
    subdistrict: "แซงบาดาล",
    zipcode: 46150,
    latitude: 16.819,
    longitude: 103.7,
  },
  {
    province: "กาฬสินธุ์",
    district: "สมเด็จ",
    subdistrict: "มหาไชย",
    zipcode: 46150,
    latitude: 16.823,
    longitude: 103.783,
  },
  {
    province: "กาฬสินธุ์",
    district: "สมเด็จ",
    subdistrict: "หมูม่น",
    zipcode: 46150,
    latitude: 16.759,
    longitude: 103.679,
  },
  {
    province: "กาฬสินธุ์",
    district: "สมเด็จ",
    subdistrict: "ผาเสวย",
    zipcode: 46150,
    latitude: 16.797,
    longitude: 103.863,
  },
  {
    province: "กาฬสินธุ์",
    district: "สมเด็จ",
    subdistrict: "ศรีสมเด็จ",
    zipcode: 46150,
    latitude: 16.738,
    longitude: 103.706,
  },
  {
    province: "กาฬสินธุ์",
    district: "สมเด็จ",
    subdistrict: "ลำห้วยหลัว",
    zipcode: 46150,
    latitude: 16.732,
    longitude: 103.815,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยผึ้ง",
    subdistrict: "คำบง",
    zipcode: 46240,
    latitude: 16.758,
    longitude: 103.912,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยผึ้ง",
    subdistrict: "ไค้นุ่น",
    zipcode: 46240,
    latitude: 16.612,
    longitude: 103.933,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยผึ้ง",
    subdistrict: "นิคมห้วยผึ้ง",
    zipcode: 46240,
    latitude: 16.675,
    longitude: 103.909,
  },
  {
    province: "กาฬสินธุ์",
    district: "ห้วยผึ้ง",
    subdistrict: "หนองอีบุตร",
    zipcode: 46240,
    latitude: 16.597,
    longitude: 103.873,
  },
  {
    province: "กาฬสินธุ์",
    district: "สามชัย",
    subdistrict: "สำราญ",
    zipcode: 46180,
    latitude: 16.954,
    longitude: 103.522,
  },
  {
    province: "กาฬสินธุ์",
    district: "สามชัย",
    subdistrict: "สำราญใต้",
    zipcode: 46180,
    latitude: 16.814,
    longitude: 103.48,
  },
  {
    province: "กาฬสินธุ์",
    district: "สามชัย",
    subdistrict: "คำสร้างเที่ยง",
    zipcode: 46180,
    latitude: 16.814,
    longitude: 103.546,
  },
  {
    province: "กาฬสินธุ์",
    district: "สามชัย",
    subdistrict: "หนองช้าง",
    zipcode: 46180,
    latitude: 16.913,
    longitude: 103.571,
  },
  {
    province: "กาฬสินธุ์",
    district: "นาคู",
    subdistrict: "นาคู",
    zipcode: 46160,
    latitude: 16.808,
    longitude: 104.07,
  },
  {
    province: "กาฬสินธุ์",
    district: "นาคู",
    subdistrict: "สายนาวัง",
    zipcode: 46160,
    latitude: 16.761,
    longitude: 104.063,
  },
  {
    province: "กาฬสินธุ์",
    district: "นาคู",
    subdistrict: "โนนนาจาน",
    zipcode: 46160,
    latitude: 16.744771,
    longitude: 103.9386613,
  },
  {
    province: "กาฬสินธุ์",
    district: "นาคู",
    subdistrict: "บ่อแก้ว",
    zipcode: 46160,
    latitude: 16.791,
    longitude: 103.994,
  },
  {
    province: "กาฬสินธุ์",
    district: "นาคู",
    subdistrict: "ภูแล่นช้าง",
    zipcode: 46160,
    latitude: 16.688,
    longitude: 103.97,
  },
  {
    province: "กาฬสินธุ์",
    district: "ดอนจาน",
    subdistrict: "ดอนจาน",
    zipcode: 46000,
    latitude: 16.425,
    longitude: 103.696,
  },
  {
    province: "กาฬสินธุ์",
    district: "ดอนจาน",
    subdistrict: "สะอาดไชยศรี",
    zipcode: 46000,
    latitude: 16.471,
    longitude: 103.773,
  },
  {
    province: "กาฬสินธุ์",
    district: "ดอนจาน",
    subdistrict: "ดงพยุง",
    zipcode: 46000,
    latitude: 16.4768047,
    longitude: 103.6320436,
  },
  {
    province: "กาฬสินธุ์",
    district: "ดอนจาน",
    subdistrict: "ม่วงนา",
    zipcode: 46000,
    latitude: 16.475,
    longitude: 103.639,
  },
  {
    province: "กาฬสินธุ์",
    district: "ดอนจาน",
    subdistrict: "นาจำปา",
    zipcode: 46000,
    latitude: 16.48,
    longitude: 103.696,
  },
  {
    province: "กาฬสินธุ์",
    district: "ฆ้องชัย",
    subdistrict: "ฆ้องชัยพัฒนา",
    zipcode: 46130,
    latitude: 16.257,
    longitude: 103.463,
  },
  {
    province: "กาฬสินธุ์",
    district: "ฆ้องชัย",
    subdistrict: "เหล่ากลาง",
    zipcode: 46130,
    latitude: 16.245,
    longitude: 103.515,
  },
  {
    province: "กาฬสินธุ์",
    district: "ฆ้องชัย",
    subdistrict: "โคกสะอาด",
    zipcode: 46130,
    latitude: 16.215,
    longitude: 103.468,
  },
  {
    province: "กาฬสินธุ์",
    district: "ฆ้องชัย",
    subdistrict: "โนนศิลาเลิง",
    zipcode: 46130,
    latitude: 16.3237753,
    longitude: 103.4456601,
  },
  {
    province: "กาฬสินธุ์",
    district: "ฆ้องชัย",
    subdistrict: "ลำชี",
    zipcode: 46130,
    latitude: 16.321,
    longitude: 103.493,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "ธาตุเชิงชุม",
    zipcode: 47000,
    latitude: 17.163,
    longitude: 104.162,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "ขมิ้น",
    zipcode: 47220,
    latitude: 17.26,
    longitude: 104.014,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "งิ้วด่อน",
    zipcode: 47000,
    latitude: 17.114,
    longitude: 104.165,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "โนนหอม",
    zipcode: 47000,
    latitude: 17.053,
    longitude: 104.196,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "เชียงเครือ",
    zipcode: 47000,
    latitude: 17.279,
    longitude: 104.123,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "ท่าแร่",
    zipcode: 47230,
    latitude: 17.269,
    longitude: 104.207,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "ม่วงลาย",
    zipcode: 47000,
    latitude: 17.125,
    longitude: 104.26,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "ดงชน",
    zipcode: 47000,
    latitude: 17.096,
    longitude: 104.24,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "ห้วยยาง",
    zipcode: 47000,
    latitude: 17.095,
    longitude: 104.033,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "พังขว้าง",
    zipcode: 47000,
    latitude: 17.177,
    longitude: 104.017,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "ดงมะไฟ",
    zipcode: 47000,
    latitude: 17.059,
    longitude: 104.105,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "ธาตุนาเวง",
    zipcode: 47000,
    latitude: 17.147,
    longitude: 104.095,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "เหล่าปอแดง",
    zipcode: 47000,
    latitude: 17.137,
    longitude: 104.212,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "หนองลาด",
    zipcode: 47220,
    latitude: 17.337,
    longitude: 104.072,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "ฮางโฮง",
    zipcode: 47000,
    latitude: 17.22,
    longitude: 104.122,
  },
  {
    province: "สกลนคร",
    district: "เมืองสกลนคร",
    subdistrict: "โคกก่อง",
    zipcode: 47000,
    latitude: 17.133,
    longitude: 104.294,
  },
  {
    province: "สกลนคร",
    district: "กุสุมาลย์",
    subdistrict: "กุสุมาลย์",
    zipcode: 47210,
    latitude: 17.302,
    longitude: 104.386,
  },
  {
    province: "สกลนคร",
    district: "กุสุมาลย์",
    subdistrict: "นาโพธิ์",
    zipcode: 47210,
    latitude: 17.352,
    longitude: 104.287,
  },
  {
    province: "สกลนคร",
    district: "กุสุมาลย์",
    subdistrict: "นาเพียง",
    zipcode: 47230,
    latitude: 17.367,
    longitude: 104.216,
  },
  {
    province: "สกลนคร",
    district: "กุสุมาลย์",
    subdistrict: "โพธิไพศาล",
    zipcode: 47210,
    latitude: 17.379,
    longitude: 104.378,
  },
  {
    province: "สกลนคร",
    district: "กุสุมาลย์",
    subdistrict: "อุ่มจาน",
    zipcode: 47230,
    latitude: 17.35,
    longitude: 104.137,
  },
  {
    province: "สกลนคร",
    district: "กุดบาก",
    subdistrict: "กุดบาก",
    zipcode: 47180,
    latitude: 17.051,
    longitude: 103.762,
  },
  {
    province: "สกลนคร",
    district: "กุดบาก",
    subdistrict: "นาม่อง",
    zipcode: 47180,
    latitude: 17.093,
    longitude: 103.906,
  },
  {
    province: "สกลนคร",
    district: "กุดบาก",
    subdistrict: "กุดไห",
    zipcode: 47180,
    latitude: 17.1,
    longitude: 103.696,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "พรรณา",
    zipcode: 47130,
    latitude: 17.356,
    longitude: 103.856,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "วังยาง",
    zipcode: 47130,
    latitude: 17.341,
    longitude: 103.911,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "พอกน้อย",
    zipcode: 47220,
    latitude: 17.315,
    longitude: 103.952,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "นาหัวบ่อ",
    zipcode: 47220,
    latitude: 17.226,
    longitude: 103.917,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "ไร่",
    zipcode: 47130,
    latitude: 17.274,
    longitude: 103.846,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "ช้างมิ่ง",
    zipcode: 47130,
    latitude: 17.342,
    longitude: 103.796,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "นาใน",
    zipcode: 47130,
    latitude: 17.207,
    longitude: 103.821,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "สว่าง",
    zipcode: 47130,
    latitude: 17.367,
    longitude: 103.981,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "บะฮี",
    zipcode: 47130,
    latitude: 17.426,
    longitude: 103.901,
  },
  {
    province: "สกลนคร",
    district: "พรรณานิคม",
    subdistrict: "เชิงชุม",
    zipcode: 47130,
    latitude: 17.435,
    longitude: 104.007,
  },
  {
    province: "สกลนคร",
    district: "พังโคน",
    subdistrict: "พังโคน",
    zipcode: 47160,
    latitude: 17.39,
    longitude: 103.704,
  },
  {
    province: "สกลนคร",
    district: "พังโคน",
    subdistrict: "ม่วงไข่",
    zipcode: 47160,
    latitude: 17.405,
    longitude: 103.665,
  },
  {
    province: "สกลนคร",
    district: "พังโคน",
    subdistrict: "แร่",
    zipcode: 47160,
    latitude: 17.297,
    longitude: 103.767,
  },
  {
    province: "สกลนคร",
    district: "พังโคน",
    subdistrict: "ไฮหย่อง",
    zipcode: 47160,
    latitude: 17.406,
    longitude: 103.765,
  },
  {
    province: "สกลนคร",
    district: "พังโคน",
    subdistrict: "ต้นผึ้ง",
    zipcode: 47160,
    latitude: 17.432,
    longitude: 103.827,
  },
  {
    province: "สกลนคร",
    district: "วาริชภูมิ",
    subdistrict: "วาริชภูมิ",
    zipcode: 47150,
    latitude: 17.232,
    longitude: 103.642,
  },
  {
    province: "สกลนคร",
    district: "วาริชภูมิ",
    subdistrict: "ปลาโหล",
    zipcode: 47150,
    latitude: 17.327,
    longitude: 103.671,
  },
  {
    province: "สกลนคร",
    district: "วาริชภูมิ",
    subdistrict: "หนองลาด",
    zipcode: 47150,
    latitude: 17.319,
    longitude: 103.543,
  },
  {
    province: "สกลนคร",
    district: "วาริชภูมิ",
    subdistrict: "คำบ่อ",
    zipcode: 47150,
    latitude: 17.225,
    longitude: 103.551,
  },
  {
    province: "สกลนคร",
    district: "วาริชภูมิ",
    subdistrict: "ค้อเขียว",
    zipcode: 47150,
    latitude: 17.276,
    longitude: 103.527,
  },
  {
    province: "สกลนคร",
    district: "นิคมน้ำอูน",
    subdistrict: "นิคมน้ำอูน",
    zipcode: 47270,
    latitude: 17.142,
    longitude: 103.645,
  },
  {
    province: "สกลนคร",
    district: "นิคมน้ำอูน",
    subdistrict: "หนองปลิง",
    zipcode: 47270,
    latitude: 17.18,
    longitude: 103.749,
  },
  {
    province: "สกลนคร",
    district: "นิคมน้ำอูน",
    subdistrict: "หนองบัว",
    zipcode: 47270,
    latitude: 17.148,
    longitude: 103.827,
  },
  {
    province: "สกลนคร",
    district: "นิคมน้ำอูน",
    subdistrict: "สุวรรณคาม",
    zipcode: 47270,
    latitude: 17.155,
    longitude: 103.712,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "วานรนิวาส",
    zipcode: 47120,
    latitude: 17.645,
    longitude: 103.752,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "เดื่อศรีคันไชย",
    zipcode: 47120,
    latitude: 17.473,
    longitude: 103.73,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "ขัวก่าย",
    zipcode: 47120,
    latitude: 17.579,
    longitude: 103.814,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "หนองสนม",
    zipcode: 47120,
    latitude: 17.526,
    longitude: 103.86,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "คูสะคาม",
    zipcode: 47120,
    latitude: 17.727,
    longitude: 103.61,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "ธาตุ",
    zipcode: 47120,
    latitude: 17.641,
    longitude: 103.651,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "หนองแวง",
    zipcode: 47120,
    latitude: 17.767,
    longitude: 103.726,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "ศรีวิชัย",
    zipcode: 47120,
    latitude: 17.536,
    longitude: 103.68,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "นาซอ",
    zipcode: 47120,
    latitude: 17.674,
    longitude: 103.848,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "อินทร์แปลง",
    zipcode: 47120,
    latitude: 17.787,
    longitude: 103.593,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "นาคำ",
    zipcode: 47120,
    latitude: 17.692,
    longitude: 103.792,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "คอนสวรรค์",
    zipcode: 47120,
    latitude: 17.593,
    longitude: 103.748,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "กุดเรือคำ",
    zipcode: 47120,
    latitude: 17.76,
    longitude: 103.646,
  },
  {
    province: "สกลนคร",
    district: "วานรนิวาส",
    subdistrict: "หนองแวงใต้",
    zipcode: 47120,
    latitude: 17.724,
    longitude: 103.76,
  },
  {
    province: "สกลนคร",
    district: "คำตากล้า",
    subdistrict: "คำตากล้า",
    zipcode: 47250,
    latitude: 17.834,
    longitude: 103.754,
  },
  {
    province: "สกลนคร",
    district: "คำตากล้า",
    subdistrict: "หนองบัวสิม",
    zipcode: 47250,
    latitude: 17.841,
    longitude: 103.859,
  },
  {
    province: "สกลนคร",
    district: "คำตากล้า",
    subdistrict: "นาแต้",
    zipcode: 47250,
    latitude: 17.866,
    longitude: 103.699,
  },
  {
    province: "สกลนคร",
    district: "คำตากล้า",
    subdistrict: "แพด",
    zipcode: 47250,
    latitude: 17.775,
    longitude: 103.842,
  },
  {
    province: "สกลนคร",
    district: "บ้านม่วง",
    subdistrict: "ม่วง",
    zipcode: 47140,
    latitude: 17.858,
    longitude: 103.561,
  },
  {
    province: "สกลนคร",
    district: "บ้านม่วง",
    subdistrict: "มาย",
    zipcode: 47140,
    latitude: 17.855,
    longitude: 103.48,
  },
  {
    province: "สกลนคร",
    district: "บ้านม่วง",
    subdistrict: "ดงหม้อทอง",
    zipcode: 47140,
    latitude: 17.995,
    longitude: 103.528,
  },
  {
    province: "สกลนคร",
    district: "บ้านม่วง",
    subdistrict: "ดงเหนือ",
    zipcode: 47140,
    latitude: 17.985,
    longitude: 103.584,
  },
  {
    province: "สกลนคร",
    district: "บ้านม่วง",
    subdistrict: "ดงหม้อทองใต้",
    zipcode: 47140,
    latitude: 17.932,
    longitude: 103.441,
  },
  {
    province: "สกลนคร",
    district: "บ้านม่วง",
    subdistrict: "ห้วยหลัว",
    zipcode: 47140,
    latitude: 17.795,
    longitude: 103.416,
  },
  {
    province: "สกลนคร",
    district: "บ้านม่วง",
    subdistrict: "โนนสะอาด",
    zipcode: 47140,
    latitude: 17.929,
    longitude: 103.653,
  },
  {
    province: "สกลนคร",
    district: "บ้านม่วง",
    subdistrict: "หนองกวั่ง",
    zipcode: 47140,
    latitude: 17.796,
    longitude: 103.487,
  },
  {
    province: "สกลนคร",
    district: "บ้านม่วง",
    subdistrict: "บ่อแก้ว",
    zipcode: 47140,
    latitude: 17.863,
    longitude: 103.626,
  },
  {
    province: "สกลนคร",
    district: "อากาศอำนวย",
    subdistrict: "อากาศ",
    zipcode: 47170,
    latitude: 17.572,
    longitude: 104.013,
  },
  {
    province: "สกลนคร",
    district: "อากาศอำนวย",
    subdistrict: "โพนแพง",
    zipcode: 47170,
    latitude: 17.538,
    longitude: 103.956,
  },
  {
    province: "สกลนคร",
    district: "อากาศอำนวย",
    subdistrict: "วาใหญ่",
    zipcode: 47170,
    latitude: 17.63,
    longitude: 103.926,
  },
  {
    province: "สกลนคร",
    district: "อากาศอำนวย",
    subdistrict: "โพนงาม",
    zipcode: 47170,
    latitude: 17.757,
    longitude: 104.006,
  },
  {
    province: "สกลนคร",
    district: "อากาศอำนวย",
    subdistrict: "ท่าก้อน",
    zipcode: 47170,
    latitude: 17.759,
    longitude: 103.961,
  },
  {
    province: "สกลนคร",
    district: "อากาศอำนวย",
    subdistrict: "นาฮี",
    zipcode: 47170,
    latitude: 17.718,
    longitude: 103.92,
  },
  {
    province: "สกลนคร",
    district: "อากาศอำนวย",
    subdistrict: "บะหว้า",
    zipcode: 47170,
    latitude: 17.495,
    longitude: 103.987,
  },
  {
    province: "สกลนคร",
    district: "อากาศอำนวย",
    subdistrict: "สามัคคีพัฒนา",
    zipcode: 47170,
    latitude: 17.669,
    longitude: 104.019,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "สว่างแดนดิน",
    zipcode: 47110,
    latitude: 17.448,
    longitude: 103.462,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "คำสะอาด",
    zipcode: 47110,
    latitude: 17.511,
    longitude: 103.351,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "บ้านต้าย",
    zipcode: 47110,
    latitude: 17.441,
    longitude: 103.385,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "บงเหนือ",
    zipcode: 47110,
    latitude: 17.319,
    longitude: 103.301,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "โพนสูง",
    zipcode: 47110,
    latitude: 17.538,
    longitude: 103.4,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "โคกสี",
    zipcode: 47110,
    latitude: 17.605,
    longitude: 103.446,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "หนองหลวง",
    zipcode: 47110,
    latitude: 17.377,
    longitude: 103.383,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "บงใต้",
    zipcode: 47110,
    latitude: 17.422,
    longitude: 103.327,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "ค้อใต้",
    zipcode: 47110,
    latitude: 17.368,
    longitude: 103.33,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "พันนา",
    zipcode: 47240,
    latitude: 17.416,
    longitude: 103.569,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "แวง",
    zipcode: 47240,
    latitude: 17.456,
    longitude: 103.63,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "ทรายมูล",
    zipcode: 47110,
    latitude: 17.47,
    longitude: 103.526,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "ตาลโกน",
    zipcode: 47240,
    latitude: 17.345,
    longitude: 103.564,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "ตาลเนิ้ง",
    zipcode: 47240,
    latitude: 17.346,
    longitude: 103.611,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "ธาตุทอง",
    zipcode: 47240,
    latitude: 17.494,
    longitude: 103.579,
  },
  {
    province: "สกลนคร",
    district: "สว่างแดนดิน",
    subdistrict: "บ้านถ่อน",
    zipcode: 47110,
    latitude: 17.532,
    longitude: 103.452,
  },
  {
    province: "สกลนคร",
    district: "ส่องดาว",
    subdistrict: "ส่องดาว",
    zipcode: 47190,
    latitude: 17.35,
    longitude: 103.449,
  },
  {
    province: "สกลนคร",
    district: "ส่องดาว",
    subdistrict: "ท่าศิลา",
    zipcode: 47190,
    latitude: 17.284,
    longitude: 103.387,
  },
  {
    province: "สกลนคร",
    district: "ส่องดาว",
    subdistrict: "วัฒนา",
    zipcode: 47190,
    latitude: 17.362,
    longitude: 103.54,
  },
  {
    province: "สกลนคร",
    district: "ส่องดาว",
    subdistrict: "ปทุมวาปี",
    zipcode: 47190,
    latitude: 17.298,
    longitude: 103.482,
  },
  {
    province: "สกลนคร",
    district: "เต่างอย",
    subdistrict: "เต่างอย",
    zipcode: 47260,
    latitude: 16.944,
    longitude: 104.242,
  },
  {
    province: "สกลนคร",
    district: "เต่างอย",
    subdistrict: "บึงทวาย",
    zipcode: 47260,
    latitude: 17.034,
    longitude: 104.157,
  },
  {
    province: "สกลนคร",
    district: "เต่างอย",
    subdistrict: "นาตาล",
    zipcode: 47260,
    latitude: 16.973,
    longitude: 104.116,
  },
  {
    province: "สกลนคร",
    district: "เต่างอย",
    subdistrict: "จันทร์เพ็ญ",
    zipcode: 47260,
    latitude: 16.925,
    longitude: 104.155,
  },
  {
    province: "สกลนคร",
    district: "โคกศรีสุพรรณ",
    subdistrict: "ตองโขบ",
    zipcode: 47280,
    latitude: 17.01,
    longitude: 104.257,
  },
  {
    province: "สกลนคร",
    district: "โคกศรีสุพรรณ",
    subdistrict: "เหล่าโพนค้อ",
    zipcode: 47280,
    latitude: 17,
    longitude: 104.331,
  },
  {
    province: "สกลนคร",
    district: "โคกศรีสุพรรณ",
    subdistrict: "ด่านม่วงคำ",
    zipcode: 47280,
    latitude: 17.096,
    longitude: 104.32,
  },
  {
    province: "สกลนคร",
    district: "โคกศรีสุพรรณ",
    subdistrict: "แมดนาท่ม",
    zipcode: 47280,
    latitude: 17.045,
    longitude: 104.347,
  },
  {
    province: "สกลนคร",
    district: "เจริญศิลป์",
    subdistrict: "บ้านเหล่า",
    zipcode: 47290,
    latitude: 17.699,
    longitude: 103.524,
  },
  {
    province: "สกลนคร",
    district: "เจริญศิลป์",
    subdistrict: "เจริญศิลป์",
    zipcode: 47290,
    latitude: 17.568,
    longitude: 103.561,
  },
  {
    province: "สกลนคร",
    district: "เจริญศิลป์",
    subdistrict: "ทุ่งแก",
    zipcode: 47290,
    latitude: 17.602,
    longitude: 103.528,
  },
  {
    province: "สกลนคร",
    district: "เจริญศิลป์",
    subdistrict: "โคกศิลา",
    zipcode: 47290,
    latitude: 17.536,
    longitude: 103.605,
  },
  {
    province: "สกลนคร",
    district: "เจริญศิลป์",
    subdistrict: "หนองแปน",
    zipcode: 47290,
    latitude: 17.706,
    longitude: 103.443,
  },
  {
    province: "สกลนคร",
    district: "โพนนาแก้ว",
    subdistrict: "บ้านโพน",
    zipcode: 47230,
    latitude: 17.219,
    longitude: 104.375,
  },
  {
    province: "สกลนคร",
    district: "โพนนาแก้ว",
    subdistrict: "นาแก้ว",
    zipcode: 47230,
    latitude: 17.268,
    longitude: 104.239,
  },
  {
    province: "สกลนคร",
    district: "โพนนาแก้ว",
    subdistrict: "นาตงวัฒนา",
    zipcode: 47230,
    latitude: 17.242,
    longitude: 104.341,
  },
  {
    province: "สกลนคร",
    district: "โพนนาแก้ว",
    subdistrict: "บ้านแป้น",
    zipcode: 47230,
    latitude: 17.196,
    longitude: 104.234,
  },
  {
    province: "สกลนคร",
    district: "โพนนาแก้ว",
    subdistrict: "เชียงสือ",
    zipcode: 47230,
    latitude: 17.137,
    longitude: 104.361,
  },
  {
    province: "สกลนคร",
    district: "ภูพาน",
    subdistrict: "สร้างค้อ",
    zipcode: 47180,
    latitude: 16.883,
    longitude: 103.918,
  },
  {
    province: "สกลนคร",
    district: "ภูพาน",
    subdistrict: "หลุบเลา",
    zipcode: 47180,
    latitude: 16.881,
    longitude: 104.022,
  },
  {
    province: "สกลนคร",
    district: "ภูพาน",
    subdistrict: "โคกภู",
    zipcode: 47180,
    latitude: 16.972,
    longitude: 103.859,
  },
  {
    province: "สกลนคร",
    district: "ภูพาน",
    subdistrict: "กกปลาซิว",
    zipcode: 47180,
    latitude: 16.969,
    longitude: 104.042,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "ในเมือง",
    zipcode: 48000,
    latitude: 17.409,
    longitude: 104.781,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "หนองแสง",
    zipcode: 48000,
    latitude: 17.4296747,
    longitude: 104.7444444,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "นาทราย",
    zipcode: 48000,
    latitude: 17.401,
    longitude: 104.65,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "นาราชควาย",
    zipcode: 48000,
    latitude: 17.416,
    longitude: 104.707,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "กุรุคุ",
    zipcode: 48000,
    latitude: 17.338,
    longitude: 104.545,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "บ้านผึ้ง",
    zipcode: 48000,
    latitude: 17.336,
    longitude: 104.631,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "อาจสามารถ",
    zipcode: 48000,
    latitude: 17.461,
    longitude: 104.725,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "ขามเฒ่า",
    zipcode: 48000,
    latitude: 17.263,
    longitude: 104.764,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "บ้านกลาง",
    zipcode: 48000,
    latitude: 17.157,
    longitude: 104.784,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "ท่าค้อ",
    zipcode: 48000,
    latitude: 17.316,
    longitude: 104.746,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "คำเตย",
    zipcode: 48000,
    latitude: 17.21,
    longitude: 104.709,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "หนองญาติ",
    zipcode: 48000,
    latitude: 17.342,
    longitude: 104.732,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "ดงขวาง",
    zipcode: 48000,
    latitude: 17.207,
    longitude: 104.782,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "วังตามัว",
    zipcode: 48000,
    latitude: 17.312,
    longitude: 104.492,
  },
  {
    province: "นครพนม",
    district: "เมืองนครพนม",
    subdistrict: "โพธิ์ตาก",
    zipcode: 48000,
    latitude: 17.344,
    longitude: 104.69,
  },
  {
    province: "นครพนม",
    district: "ปลาปาก",
    subdistrict: "ปลาปาก",
    zipcode: 48160,
    latitude: 17.197,
    longitude: 104.515,
  },
  {
    province: "นครพนม",
    district: "ปลาปาก",
    subdistrict: "หนองฮี",
    zipcode: 48160,
    latitude: 17.128,
    longitude: 104.587,
  },
  {
    province: "นครพนม",
    district: "ปลาปาก",
    subdistrict: "กุตาไก้",
    zipcode: 48160,
    latitude: 17.217,
    longitude: 104.608,
  },
  {
    province: "นครพนม",
    district: "ปลาปาก",
    subdistrict: "โคกสว่าง",
    zipcode: 48160,
    latitude: 17.145,
    longitude: 104.465,
  },
  {
    province: "นครพนม",
    district: "ปลาปาก",
    subdistrict: "โคกสูง",
    zipcode: 48160,
    latitude: 17.178,
    longitude: 104.413,
  },
  {
    province: "นครพนม",
    district: "ปลาปาก",
    subdistrict: "มหาชัย",
    zipcode: 48160,
    latitude: 17.247,
    longitude: 104.492,
  },
  {
    province: "นครพนม",
    district: "ปลาปาก",
    subdistrict: "นามะเขือ",
    zipcode: 48160,
    latitude: 17.273,
    longitude: 104.654,
  },
  {
    province: "นครพนม",
    district: "ปลาปาก",
    subdistrict: "หนองเทาใหญ่",
    zipcode: 48160,
    latitude: 17.121,
    longitude: 104.523,
  },
  {
    province: "นครพนม",
    district: "ท่าอุเทน",
    subdistrict: "ท่าอุเทน",
    zipcode: 48120,
    latitude: 17.576,
    longitude: 104.591,
  },
  {
    province: "นครพนม",
    district: "ท่าอุเทน",
    subdistrict: "โนนตาล",
    zipcode: 48120,
    latitude: 17.534,
    longitude: 104.598,
  },
  {
    province: "นครพนม",
    district: "ท่าอุเทน",
    subdistrict: "ท่าจำปา",
    zipcode: 48120,
    latitude: 17.592,
    longitude: 104.494,
  },
  {
    province: "นครพนม",
    district: "ท่าอุเทน",
    subdistrict: "ไชยบุรี",
    zipcode: 48120,
    latitude: 17.636,
    longitude: 104.433,
  },
  {
    province: "นครพนม",
    district: "ท่าอุเทน",
    subdistrict: "พนอม",
    zipcode: 48120,
    latitude: 17.697,
    longitude: 104.393,
  },
  {
    province: "นครพนม",
    district: "ท่าอุเทน",
    subdistrict: "พะทาย",
    zipcode: 48120,
    latitude: 17.756,
    longitude: 104.316,
  },
  {
    province: "นครพนม",
    district: "ท่าอุเทน",
    subdistrict: "เวินพระบาท",
    zipcode: 48120,
    latitude: 17.509,
    longitude: 104.676,
  },
  {
    province: "นครพนม",
    district: "ท่าอุเทน",
    subdistrict: "รามราช",
    zipcode: 48120,
    latitude: 17.468,
    longitude: 104.635,
  },
  {
    province: "นครพนม",
    district: "ท่าอุเทน",
    subdistrict: "หนองเทา",
    zipcode: 48120,
    latitude: 17.783,
    longitude: 104.352,
  },
  {
    province: "นครพนม",
    district: "บ้านแพง",
    subdistrict: "บ้านแพง",
    zipcode: 48140,
    latitude: 17.967,
    longitude: 104.215,
  },
  {
    province: "นครพนม",
    district: "บ้านแพง",
    subdistrict: "ไผ่ล้อม",
    zipcode: 48140,
    latitude: 17.989,
    longitude: 104.154,
  },
  {
    province: "นครพนม",
    district: "บ้านแพง",
    subdistrict: "โพนทอง",
    zipcode: 48140,
    latitude: 17.876,
    longitude: 104.217,
  },
  {
    province: "นครพนม",
    district: "บ้านแพง",
    subdistrict: "หนองแวง",
    zipcode: 48140,
    latitude: 17.785,
    longitude: 104.233,
  },
  {
    province: "นครพนม",
    district: "บ้านแพง",
    subdistrict: "นางัว",
    zipcode: 48140,
    latitude: 17.943,
    longitude: 104.182,
  },
  {
    province: "นครพนม",
    district: "บ้านแพง",
    subdistrict: "นาเข",
    zipcode: 48140,
    latitude: 17.874369,
    longitude: 104.0414069,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "ธาตุพนม",
    zipcode: 48110,
    latitude: 16.939,
    longitude: 104.72,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "ฝั่งแดง",
    zipcode: 48110,
    latitude: 16.914,
    longitude: 104.675,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "โพนแพง",
    zipcode: 48110,
    latitude: 17.148,
    longitude: 104.695,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "พระกลางทุ่ง",
    zipcode: 48110,
    latitude: 16.986,
    longitude: 104.699,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "นาถ่อน",
    zipcode: 48110,
    latitude: 17.137,
    longitude: 104.744,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "แสนพัน",
    zipcode: 48110,
    latitude: 17.066,
    longitude: 104.749,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "ดอนนางหงส์",
    zipcode: 48110,
    latitude: 17.097,
    longitude: 104.743,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "น้ำก่ำ",
    zipcode: 48110,
    latitude: 16.872,
    longitude: 104.723,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "อุ่มเหม้า",
    zipcode: 48110,
    latitude: 16.839,
    longitude: 104.67,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "นาหนาด",
    zipcode: 48110,
    latitude: 16.915,
    longitude: 104.654,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "กุดฉิม",
    zipcode: 48110,
    latitude: 17.059,
    longitude: 104.726,
  },
  {
    province: "นครพนม",
    district: "ธาตุพนม",
    subdistrict: "ธาตุพนมเหนือ",
    zipcode: 48110,
    latitude: 16.975,
    longitude: 104.667,
  },
  {
    province: "นครพนม",
    district: "เรณูนคร",
    subdistrict: "เรณู",
    zipcode: 48170,
    latitude: 17.081,
    longitude: 104.644,
  },
  {
    province: "นครพนม",
    district: "เรณูนคร",
    subdistrict: "โพนทอง",
    zipcode: 48170,
    latitude: 17.068,
    longitude: 104.697,
  },
  {
    province: "นครพนม",
    district: "เรณูนคร",
    subdistrict: "ท่าลาด",
    zipcode: 48170,
    latitude: 17.007,
    longitude: 104.589,
  },
  {
    province: "นครพนม",
    district: "เรณูนคร",
    subdistrict: "นางาม",
    zipcode: 48170,
    latitude: 17.106,
    longitude: 104.672,
  },
  {
    province: "นครพนม",
    district: "เรณูนคร",
    subdistrict: "โคกหินแฮ่",
    zipcode: 48170,
    latitude: 17.046,
    longitude: 104.628,
  },
  {
    province: "นครพนม",
    district: "เรณูนคร",
    subdistrict: "หนองย่างชิ้น",
    zipcode: 48170,
    latitude: 17.019,
    longitude: 104.698,
  },
  {
    province: "นครพนม",
    district: "เรณูนคร",
    subdistrict: "เรณูใต้",
    zipcode: 48170,
    latitude: 17.019,
    longitude: 104.66,
  },
  {
    province: "นครพนม",
    district: "เรณูนคร",
    subdistrict: "นาขาม",
    zipcode: 48170,
    latitude: 16.984,
    longitude: 104.63,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "นาแก",
    zipcode: 48130,
    latitude: 16.897,
    longitude: 104.495,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "พระซอง",
    zipcode: 48130,
    latitude: 17.021,
    longitude: 104.551,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "หนองสังข์",
    zipcode: 48130,
    latitude: 16.989,
    longitude: 104.468,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "นาคู่",
    zipcode: 48130,
    latitude: 16.978,
    longitude: 104.539,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "พิมาน",
    zipcode: 48130,
    latitude: 16.909,
    longitude: 104.54,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "พุ่มแก",
    zipcode: 48130,
    latitude: 16.911,
    longitude: 104.621,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "ก้านเหลือง",
    zipcode: 48130,
    latitude: 16.897,
    longitude: 104.383,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "หนองบ่อ",
    zipcode: 48130,
    latitude: 16.932,
    longitude: 104.322,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "นาเลียง",
    zipcode: 48130,
    latitude: 17.063,
    longitude: 104.551,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "บ้านแก้ง",
    zipcode: 48130,
    latitude: 16.918,
    longitude: 104.446,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "คำพี้",
    zipcode: 48130,
    latitude: 16.937,
    longitude: 104.369,
  },
  {
    province: "นครพนม",
    district: "นาแก",
    subdistrict: "สีชมพู",
    zipcode: 48130,
    latitude: 16.918,
    longitude: 104.575,
  },
  {
    province: "นครพนม",
    district: "ศรีสงคราม",
    subdistrict: "ศรีสงคราม",
    zipcode: 48150,
    latitude: 17.641,
    longitude: 104.289,
  },
  {
    province: "นครพนม",
    district: "ศรีสงคราม",
    subdistrict: "นาเดื่อ",
    zipcode: 48150,
    latitude: 17.555,
    longitude: 104.333,
  },
  {
    province: "นครพนม",
    district: "ศรีสงคราม",
    subdistrict: "บ้านเอื้อง",
    zipcode: 48150,
    latitude: 17.548,
    longitude: 104.232,
  },
  {
    province: "นครพนม",
    district: "ศรีสงคราม",
    subdistrict: "สามผง",
    zipcode: 48150,
    latitude: 17.73,
    longitude: 104.164,
  },
  {
    province: "นครพนม",
    district: "ศรีสงคราม",
    subdistrict: "ท่าบ่อสงคราม",
    zipcode: 48150,
    latitude: 17.633,
    longitude: 104.196,
  },
  {
    province: "นครพนม",
    district: "ศรีสงคราม",
    subdistrict: "บ้านข่า",
    zipcode: 48150,
    latitude: 17.666,
    longitude: 104.121,
  },
  {
    province: "นครพนม",
    district: "ศรีสงคราม",
    subdistrict: "นาคำ",
    zipcode: 48150,
    latitude: 17.619,
    longitude: 104.354,
  },
  {
    province: "นครพนม",
    district: "ศรีสงคราม",
    subdistrict: "โพนสว่าง",
    zipcode: 48150,
    latitude: 17.542,
    longitude: 104.167,
  },
  {
    province: "นครพนม",
    district: "ศรีสงคราม",
    subdistrict: "หาดแพง",
    zipcode: 48150,
    latitude: 17.712,
    longitude: 104.3,
  },
  {
    province: "นครพนม",
    district: "นาหว้า",
    subdistrict: "นาหว้า",
    zipcode: 48180,
    latitude: 17.471,
    longitude: 104.06,
  },
  {
    province: "นครพนม",
    district: "นาหว้า",
    subdistrict: "นางัว",
    zipcode: 48180,
    latitude: 17.424,
    longitude: 104.071,
  },
  {
    province: "นครพนม",
    district: "นาหว้า",
    subdistrict: "บ้านเสียว",
    zipcode: 48180,
    latitude: 17.554,
    longitude: 104.082,
  },
  {
    province: "นครพนม",
    district: "นาหว้า",
    subdistrict: "นาคูณใหญ่",
    zipcode: 48180,
    latitude: 17.507,
    longitude: 104.145,
  },
  {
    province: "นครพนม",
    district: "นาหว้า",
    subdistrict: "เหล่าพัฒนา",
    zipcode: 48180,
    latitude: 17.617,
    longitude: 104.07,
  },
  {
    province: "นครพนม",
    district: "นาหว้า",
    subdistrict: "ท่าเรือ",
    zipcode: 48180,
    latitude: 17.503,
    longitude: 104.052,
  },
  {
    province: "นครพนม",
    district: "โพนสวรรค์",
    subdistrict: "โพนสวรรค์",
    zipcode: 48190,
    latitude: 17.444,
    longitude: 104.504,
  },
  {
    province: "นครพนม",
    district: "โพนสวรรค์",
    subdistrict: "นาหัวบ่อ",
    zipcode: 48190,
    latitude: 17.482,
    longitude: 104.368,
  },
  {
    province: "นครพนม",
    district: "โพนสวรรค์",
    subdistrict: "นาขมิ้น",
    zipcode: 48190,
    latitude: 17.523,
    longitude: 104.513,
  },
  {
    province: "นครพนม",
    district: "โพนสวรรค์",
    subdistrict: "โพนบก",
    zipcode: 48190,
    latitude: 17.4591056,
    longitude: 104.2482317,
  },
  {
    province: "นครพนม",
    district: "โพนสวรรค์",
    subdistrict: "บ้านค้อ",
    zipcode: 48190,
    latitude: 17.473,
    longitude: 104.264,
  },
  {
    province: "นครพนม",
    district: "โพนสวรรค์",
    subdistrict: "โพนจาน",
    zipcode: 48190,
    latitude: 17.409,
    longitude: 104.424,
  },
  {
    province: "นครพนม",
    district: "โพนสวรรค์",
    subdistrict: "นาใน",
    zipcode: 48190,
    latitude: 17.4591056,
    longitude: 104.2482317,
  },
  {
    province: "นครพนม",
    district: "นาทม",
    subdistrict: "นาทม",
    zipcode: 48140,
    latitude: 17.845,
    longitude: 104.037,
  },
  {
    province: "นครพนม",
    district: "นาทม",
    subdistrict: "หนองซน",
    zipcode: 48140,
    latitude: 17.866,
    longitude: 104.147,
  },
  {
    province: "นครพนม",
    district: "นาทม",
    subdistrict: "ดอนเตย",
    zipcode: 48140,
    latitude: 17.798,
    longitude: 104.108,
  },
  {
    province: "นครพนม",
    district: "วังยาง",
    subdistrict: "วังยาง",
    zipcode: 48130,
    latitude: 17.063,
    longitude: 104.442,
  },
  {
    province: "นครพนม",
    district: "วังยาง",
    subdistrict: "โคกสี",
    zipcode: 48130,
    latitude: 17.081,
    longitude: 104.387,
  },
  {
    province: "นครพนม",
    district: "วังยาง",
    subdistrict: "ยอดชาด",
    zipcode: 48130,
    latitude: 17.065,
    longitude: 104.482,
  },
  {
    province: "นครพนม",
    district: "วังยาง",
    subdistrict: "หนองโพธิ์",
    zipcode: 48130,
    latitude: 17.091,
    longitude: 104.491,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "มุกดาหาร",
    zipcode: 49000,
    latitude: 16.558,
    longitude: 104.646,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "ศรีบุญเรือง",
    zipcode: 49000,
    latitude: 16.53,
    longitude: 104.724,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "บ้านโคก",
    zipcode: 49000,
    latitude: 16.64,
    longitude: 104.565,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "บางทรายใหญ่",
    zipcode: 49000,
    latitude: 16.619,
    longitude: 104.693,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "โพนทราย",
    zipcode: 49000,
    latitude: 16.541,
    longitude: 104.584,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "ผึ่งแดด",
    zipcode: 49000,
    latitude: 16.631,
    longitude: 104.476,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "นาโสก",
    zipcode: 49000,
    latitude: 16.487,
    longitude: 104.522,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "นาสีนวน",
    zipcode: 49000,
    latitude: 16.455,
    longitude: 104.785,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "คำป่าหลาย",
    zipcode: 49000,
    latitude: 16.711,
    longitude: 104.621,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "คำอาฮวน",
    zipcode: 49000,
    latitude: 16.494,
    longitude: 104.667,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "ดงเย็น",
    zipcode: 49000,
    latitude: 16.371,
    longitude: 104.697,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "ดงมอน",
    zipcode: 49000,
    latitude: 16.687,
    longitude: 104.5,
  },
  {
    province: "มุกดาหาร",
    district: "เมืองมุกดาหาร",
    subdistrict: "กุดแข้",
    zipcode: 49000,
    latitude: 16.574,
    longitude: 104.565,
  },
  {
    province: "มุกดาหาร",
    district: "นิคมคำสร้อย",
    subdistrict: "นิคมคำสร้อย",
    zipcode: 49130,
    latitude: 16.306,
    longitude: 104.582,
  },
  {
    province: "มุกดาหาร",
    district: "นิคมคำสร้อย",
    subdistrict: "นากอก",
    zipcode: 49130,
    latitude: 16.346,
    longitude: 104.614,
  },
  {
    province: "มุกดาหาร",
    district: "นิคมคำสร้อย",
    subdistrict: "หนองแวง",
    zipcode: 49130,
    latitude: 16.412,
    longitude: 104.605,
  },
  {
    province: "มุกดาหาร",
    district: "นิคมคำสร้อย",
    subdistrict: "กกแดง",
    zipcode: 49130,
    latitude: 16.41,
    longitude: 104.505,
  },
  {
    province: "มุกดาหาร",
    district: "นิคมคำสร้อย",
    subdistrict: "นาอุดม",
    zipcode: 49130,
    latitude: 16.274,
    longitude: 104.615,
  },
  {
    province: "มุกดาหาร",
    district: "นิคมคำสร้อย",
    subdistrict: "โชคชัย",
    zipcode: 49130,
    latitude: 16.358,
    longitude: 104.499,
  },
  {
    province: "มุกดาหาร",
    district: "นิคมคำสร้อย",
    subdistrict: "ร่มเกล้า",
    zipcode: 49130,
    latitude: 16.381,
    longitude: 104.437,
  },
  {
    province: "มุกดาหาร",
    district: "ดอนตาล",
    subdistrict: "ดอนตาล",
    zipcode: 49120,
    latitude: 16.299,
    longitude: 104.905,
  },
  {
    province: "มุกดาหาร",
    district: "ดอนตาล",
    subdistrict: "โพธิ์ไทร",
    zipcode: 49120,
    latitude: 16.376,
    longitude: 104.862,
  },
  {
    province: "มุกดาหาร",
    district: "ดอนตาล",
    subdistrict: "ป่าไร่",
    zipcode: 49120,
    latitude: 16.254,
    longitude: 104.701,
  },
  {
    province: "มุกดาหาร",
    district: "ดอนตาล",
    subdistrict: "เหล่าหมี",
    zipcode: 49120,
    latitude: 16.346,
    longitude: 104.781,
  },
  {
    province: "มุกดาหาร",
    district: "ดอนตาล",
    subdistrict: "บ้านบาก",
    zipcode: 49120,
    latitude: 16.232,
    longitude: 104.852,
  },
  {
    province: "มุกดาหาร",
    district: "ดอนตาล",
    subdistrict: "นาสะเม็ง",
    zipcode: 49120,
    latitude: 16.287,
    longitude: 104.837,
  },
  {
    province: "มุกดาหาร",
    district: "ดอนตาล",
    subdistrict: "บ้านแก้ง",
    zipcode: 49120,
    latitude: 16.41,
    longitude: 104.814,
  },
  {
    province: "มุกดาหาร",
    district: "ดงหลวง",
    subdistrict: "ดงหลวง",
    zipcode: 49140,
    latitude: 16.818,
    longitude: 104.523,
  },
  {
    province: "มุกดาหาร",
    district: "ดงหลวง",
    subdistrict: "หนองบัว",
    zipcode: 49140,
    latitude: 16.811,
    longitude: 104.568,
  },
  {
    province: "มุกดาหาร",
    district: "ดงหลวง",
    subdistrict: "กกตูม",
    zipcode: 49140,
    latitude: 16.792,
    longitude: 104.196,
  },
  {
    province: "มุกดาหาร",
    district: "ดงหลวง",
    subdistrict: "หนองแคน",
    zipcode: 49140,
    latitude: 16.76,
    longitude: 104.492,
  },
  {
    province: "มุกดาหาร",
    district: "ดงหลวง",
    subdistrict: "ชะโนดน้อย",
    zipcode: 49140,
    latitude: 16.805,
    longitude: 104.618,
  },
  {
    province: "มุกดาหาร",
    district: "ดงหลวง",
    subdistrict: "พังแดง",
    zipcode: 49140,
    latitude: 16.797,
    longitude: 104.373,
  },
  {
    province: "มุกดาหาร",
    district: "คำชะอี",
    subdistrict: "บ้านซ่ง",
    zipcode: 49110,
    latitude: 16.5684138,
    longitude: 104.4075818,
  },
  {
    province: "มุกดาหาร",
    district: "คำชะอี",
    subdistrict: "คำชะอี",
    zipcode: 49110,
    latitude: 16.589,
    longitude: 104.279,
  },
  {
    province: "มุกดาหาร",
    district: "คำชะอี",
    subdistrict: "หนองเอี่ยน",
    zipcode: 49110,
    latitude: 16.616,
    longitude: 104.453,
  },
  {
    province: "มุกดาหาร",
    district: "คำชะอี",
    subdistrict: "บ้านค้อ",
    zipcode: 49110,
    latitude: 16.701,
    longitude: 104.352,
  },
  {
    province: "มุกดาหาร",
    district: "คำชะอี",
    subdistrict: "บ้านเหล่า",
    zipcode: 49110,
    latitude: 16.686,
    longitude: 104.282,
  },
  {
    province: "มุกดาหาร",
    district: "คำชะอี",
    subdistrict: "โพนงาม",
    zipcode: 49110,
    latitude: 16.675,
    longitude: 104.435,
  },
  {
    province: "มุกดาหาร",
    district: "คำชะอี",
    subdistrict: "เหล่าสร้างถ่อ",
    zipcode: 49110,
    latitude: 16.561,
    longitude: 104.492,
  },
  {
    province: "มุกดาหาร",
    district: "คำชะอี",
    subdistrict: "คำบก",
    zipcode: 49110,
    latitude: 16.499,
    longitude: 104.411,
  },
  {
    province: "มุกดาหาร",
    district: "คำชะอี",
    subdistrict: "น้ำเที่ยง",
    zipcode: 49110,
    latitude: 16.569,
    longitude: 104.398,
  },
  {
    province: "มุกดาหาร",
    district: "หว้านใหญ่",
    subdistrict: "หว้านใหญ่",
    zipcode: 49150,
    latitude: 16.728262,
    longitude: 104.6457853,
  },
  {
    province: "มุกดาหาร",
    district: "หว้านใหญ่",
    subdistrict: "ป่งขาม",
    zipcode: 49150,
    latitude: 16.728262,
    longitude: 104.6457853,
  },
  {
    province: "มุกดาหาร",
    district: "หว้านใหญ่",
    subdistrict: "บางทรายน้อย",
    zipcode: 49150,
    latitude: 16.7066852,
    longitude: 104.5398829,
  },
  {
    province: "มุกดาหาร",
    district: "หว้านใหญ่",
    subdistrict: "ชะโนด",
    zipcode: 49150,
    latitude: 16.728262,
    longitude: 104.6457853,
  },
  {
    province: "มุกดาหาร",
    district: "หว้านใหญ่",
    subdistrict: "ดงหมู",
    zipcode: 49150,
    latitude: 16.8057103,
    longitude: 104.6897473,
  },
  {
    province: "มุกดาหาร",
    district: "หนองสูง",
    subdistrict: "หนองสูง",
    zipcode: 49160,
    latitude: 16.459,
    longitude: 104.361,
  },
  {
    province: "มุกดาหาร",
    district: "หนองสูง",
    subdistrict: "โนนยาง",
    zipcode: 49160,
    latitude: 16.49,
    longitude: 104.278,
  },
  {
    province: "มุกดาหาร",
    district: "หนองสูง",
    subdistrict: "ภูวง",
    zipcode: 49160,
    latitude: 16.455,
    longitude: 104.447,
  },
  {
    province: "มุกดาหาร",
    district: "หนองสูง",
    subdistrict: "บ้านเป้า",
    zipcode: 49160,
    latitude: 16.41,
    longitude: 104.337,
  },
  {
    province: "มุกดาหาร",
    district: "หนองสูง",
    subdistrict: "หนองสูงใต้",
    zipcode: 49160,
    latitude: 16.388,
    longitude: 104.382,
  },
  {
    province: "มุกดาหาร",
    district: "หนองสูง",
    subdistrict: "หนองสูงเหนือ",
    zipcode: 49160,
    latitude: 16.491,
    longitude: 104.36,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "ศรีภูมิ",
    zipcode: 50200,
    latitude: 18.796,
    longitude: 98.985,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "พระสิงห์",
    zipcode: 50200,
    latitude: 18.785,
    longitude: 98.985,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "หายยา",
    zipcode: 50100,
    latitude: 18.777,
    longitude: 98.984,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "ช้างม่อย",
    zipcode: 50300,
    latitude: 18.796,
    longitude: 98.996,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "ช้างคลาน",
    zipcode: 50100,
    latitude: 18.774,
    longitude: 98.996,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "วัดเกต",
    zipcode: 50000,
    latitude: 18.785,
    longitude: 99.011,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "ช้างเผือก",
    zipcode: 50300,
    latitude: 18.821,
    longitude: 98.969,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "สุเทพ",
    zipcode: 50200,
    latitude: 18.768,
    longitude: 98.921,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "แม่เหียะ",
    zipcode: 50100,
    latitude: 18.7485321,
    longitude: 98.8919204,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "ป่าแดด",
    zipcode: 50100,
    latitude: 18.742,
    longitude: 98.978,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "หนองหอย",
    zipcode: 50000,
    latitude: 18.758,
    longitude: 99.015,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "ท่าศาลา",
    zipcode: 50000,
    latitude: 18.77,
    longitude: 99.034,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "หนองป่าครั่ง",
    zipcode: 50000,
    latitude: 18.788,
    longitude: 99.034,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "ฟ้าฮ่าม",
    zipcode: 50000,
    latitude: 18.816,
    longitude: 99.008,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "ป่าตัน",
    zipcode: 50300,
    latitude: 18.811,
    longitude: 98.999,
  },
  {
    province: "เชียงใหม่",
    district: "เมืองเชียงใหม่",
    subdistrict: "สันผีเสื้อ",
    zipcode: 50300,
    latitude: 18.841,
    longitude: 98.998,
  },
  {
    province: "เชียงใหม่",
    district: "จอมทอง",
    subdistrict: "บ้านหลวง",
    zipcode: 50160,
    latitude: 18.502,
    longitude: 98.6,
  },
  {
    province: "เชียงใหม่",
    district: "จอมทอง",
    subdistrict: "ข่วงเปา",
    zipcode: 50160,
    latitude: 18.472,
    longitude: 98.726,
  },
  {
    province: "เชียงใหม่",
    district: "จอมทอง",
    subdistrict: "สบเตี๊ยะ",
    zipcode: 50160,
    latitude: 18.352,
    longitude: 98.668,
  },
  {
    province: "เชียงใหม่",
    district: "จอมทอง",
    subdistrict: "บ้านแปะ",
    zipcode: 50240,
    latitude: 18.256,
    longitude: 98.565,
  },
  {
    province: "เชียงใหม่",
    district: "จอมทอง",
    subdistrict: "ดอยแก้ว",
    zipcode: 50160,
    latitude: 18.39,
    longitude: 98.576,
  },
  {
    province: "เชียงใหม่",
    district: "จอมทอง",
    subdistrict: "แม่สอย",
    zipcode: 50240,
    latitude: 18.222,
    longitude: 98.721,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แจ่ม",
    subdistrict: "ช่างเคิ่ง",
    zipcode: 50270,
    latitude: 18.552,
    longitude: 98.401,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แจ่ม",
    subdistrict: "ท่าผา",
    zipcode: 50270,
    latitude: 18.485,
    longitude: 98.416,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แจ่ม",
    subdistrict: "บ้านทับ",
    zipcode: 50270,
    latitude: 18.342,
    longitude: 98.234,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แจ่ม",
    subdistrict: "แม่ศึก",
    zipcode: 50270,
    latitude: 18.738,
    longitude: 98.224,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แจ่ม",
    subdistrict: "แม่นาจร",
    zipcode: 50270,
    latitude: 18.802,
    longitude: 98.373,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แจ่ม",
    subdistrict: "ปางหินฝน",
    zipcode: 50270,
    latitude: 18.451,
    longitude: 98.136,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แจ่ม",
    subdistrict: "กองแขก",
    zipcode: 50270,
    latitude: 18.358,
    longitude: 98.376,
  },
  {
    province: "เชียงใหม่",
    district: "เชียงดาว",
    subdistrict: "เชียงดาว",
    zipcode: 50170,
    latitude: 19.389,
    longitude: 98.947,
  },
  {
    province: "เชียงใหม่",
    district: "เชียงดาว",
    subdistrict: "เมืองนะ",
    zipcode: 50170,
    latitude: 19.312,
    longitude: 98.939,
  },
  {
    province: "เชียงใหม่",
    district: "เชียงดาว",
    subdistrict: "เมืองงาย",
    zipcode: 50170,
    latitude: 19.477,
    longitude: 98.833,
  },
  {
    province: "เชียงใหม่",
    district: "เชียงดาว",
    subdistrict: "แม่นะ",
    zipcode: 50170,
    latitude: 19.687,
    longitude: 98.9,
  },
  {
    province: "เชียงใหม่",
    district: "เชียงดาว",
    subdistrict: "เมืองคอง",
    zipcode: 50170,
    latitude: 19.388,
    longitude: 98.753,
  },
  {
    province: "เชียงใหม่",
    district: "เชียงดาว",
    subdistrict: "ปิงโค้ง",
    zipcode: 50170,
    latitude: 19.507,
    longitude: 99.066,
  },
  {
    province: "เชียงใหม่",
    district: "เชียงดาว",
    subdistrict: "ทุ่งข้าวพวง",
    zipcode: 50170,
    latitude: 19.552,
    longitude: 98.906,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "เชิงดอย",
    zipcode: 50220,
    latitude: 18.899,
    longitude: 99.17,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "สันปูเลย",
    zipcode: 50220,
    latitude: 18.808,
    longitude: 99.072,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "ลวงเหนือ",
    zipcode: 50220,
    latitude: 18.965,
    longitude: 99.141,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "ป่าป้อง",
    zipcode: 50220,
    latitude: 18.85,
    longitude: 99.16,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "สง่าบ้าน",
    zipcode: 50220,
    latitude: 18.832,
    longitude: 99.12,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "ป่าลาน",
    zipcode: 50220,
    latitude: 18.836,
    longitude: 99.106,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "ตลาดขวัญ",
    zipcode: 50220,
    latitude: 18.841,
    longitude: 99.088,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "สำราญราษฎร์",
    zipcode: 50220,
    latitude: 18.807,
    longitude: 99.095,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "แม่คือ",
    zipcode: 50220,
    latitude: 18.782,
    longitude: 99.101,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "ตลาดใหญ่",
    zipcode: 50220,
    latitude: 18.81,
    longitude: 99.127,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "แม่ฮ้อยเงิน",
    zipcode: 50220,
    latitude: 18.805,
    longitude: 99.149,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "แม่โป่ง",
    zipcode: 50220,
    latitude: 18.847,
    longitude: 99.196,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "ป่าเมี่ยง",
    zipcode: 50220,
    latitude: 18.935,
    longitude: 99.243,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยสะเก็ด",
    subdistrict: "เทพเสด็จ",
    zipcode: 50220,
    latitude: 18.956,
    longitude: 99.326,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "สันมหาพน",
    zipcode: 50150,
    latitude: 19.118,
    longitude: 98.946,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "แม่แตง",
    zipcode: 50150,
    latitude: 19.155,
    longitude: 98.91,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "ขี้เหล็ก",
    zipcode: 50150,
    latitude: 19.071,
    longitude: 98.916,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "ช่อแล",
    zipcode: 50150,
    latitude: 19.144,
    longitude: 99.024,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "แม่หอพระ",
    zipcode: 50150,
    latitude: 19.117,
    longitude: 99.072,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "สบเปิง",
    zipcode: 50150,
    latitude: 19.104,
    longitude: 98.81,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "บ้านเป้า",
    zipcode: 50150,
    latitude: 19.218,
    longitude: 99.047,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "สันป่ายาง",
    zipcode: 50330,
    latitude: 19.049,
    longitude: 98.83,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "ป่าแป๋",
    zipcode: 50150,
    latitude: 19.179,
    longitude: 98.671,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "เมืองก๋าย",
    zipcode: 50150,
    latitude: 19.186,
    longitude: 98.79,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "บ้านช้าง",
    zipcode: 50150,
    latitude: 19.147,
    longitude: 98.861,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "กื้ดช้าง",
    zipcode: 50150,
    latitude: 19.269,
    longitude: 98.768,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "อินทขิล",
    zipcode: 50150,
    latitude: 19.2,
    longitude: 98.953,
  },
  {
    province: "เชียงใหม่",
    district: "แม่แตง",
    subdistrict: "สมก๋าย",
    zipcode: 50150,
    latitude: 19.1898777,
    longitude: 98.5318273,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "ริมใต้",
    zipcode: 50180,
    latitude: 18.914,
    longitude: 98.964,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "ริมเหนือ",
    zipcode: 50180,
    latitude: 18.929,
    longitude: 98.948,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "สันโป่ง",
    zipcode: 50180,
    latitude: 18.954,
    longitude: 98.954,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "ขี้เหล็ก",
    zipcode: 50180,
    latitude: 19.011,
    longitude: 98.942,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "สะลวง",
    zipcode: 50330,
    latitude: 19.005,
    longitude: 98.837,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "ห้วยทราย",
    zipcode: 50180,
    latitude: 18.967,
    longitude: 98.915,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "แม่แรม",
    zipcode: 50180,
    latitude: 18.911,
    longitude: 98.893,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "โป่งแยง",
    zipcode: 50180,
    latitude: 18.885,
    longitude: 98.82,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "แม่สา",
    zipcode: 50180,
    latitude: 18.894,
    longitude: 98.945,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "ดอนแก้ว",
    zipcode: 50180,
    latitude: 18.86,
    longitude: 98.939,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ริม",
    subdistrict: "เหมืองแก้ว",
    zipcode: 50180,
    latitude: 18.898,
    longitude: 98.983,
  },
  {
    province: "เชียงใหม่",
    district: "สะเมิง",
    subdistrict: "สะเมิงใต้",
    zipcode: 50250,
    latitude: 18.823,
    longitude: 98.714,
  },
  {
    province: "เชียงใหม่",
    district: "สะเมิง",
    subdistrict: "สะเมิงเหนือ",
    zipcode: 50250,
    latitude: 18.977,
    longitude: 98.739,
  },
  {
    province: "เชียงใหม่",
    district: "สะเมิง",
    subdistrict: "แม่สาบ",
    zipcode: 50250,
    latitude: 18.945,
    longitude: 98.641,
  },
  {
    province: "เชียงใหม่",
    district: "สะเมิง",
    subdistrict: "บ่อแก้ว",
    zipcode: 50250,
    latitude: 18.791,
    longitude: 98.604,
  },
  {
    province: "เชียงใหม่",
    district: "สะเมิง",
    subdistrict: "ยั้งเมิน",
    zipcode: 50250,
    latitude: 19.001,
    longitude: 98.464,
  },
  {
    province: "เชียงใหม่",
    district: "ฝาง",
    subdistrict: "เวียง",
    zipcode: 50110,
    latitude: 19.944,
    longitude: 99.214,
  },
  {
    province: "เชียงใหม่",
    district: "ฝาง",
    subdistrict: "ม่อนปิ่น",
    zipcode: 50110,
    latitude: 19.955,
    longitude: 99.112,
  },
  {
    province: "เชียงใหม่",
    district: "ฝาง",
    subdistrict: "แม่งอน",
    zipcode: 50320,
    latitude: 19.793,
    longitude: 99.124,
  },
  {
    province: "เชียงใหม่",
    district: "ฝาง",
    subdistrict: "แม่สูน",
    zipcode: 50110,
    latitude: 19.8518257,
    longitude: 99.0300988,
  },
  {
    province: "เชียงใหม่",
    district: "ฝาง",
    subdistrict: "สันทราย",
    zipcode: 50110,
    latitude: 19.879,
    longitude: 99.209,
  },
  {
    province: "เชียงใหม่",
    district: "ฝาง",
    subdistrict: "แม่คะ",
    zipcode: 50110,
    latitude: 19.821,
    longitude: 99.213,
  },
  {
    province: "เชียงใหม่",
    district: "ฝาง",
    subdistrict: "แม่ข่า",
    zipcode: 50320,
    latitude: 19.7913355,
    longitude: 99.1560904,
  },
  {
    province: "เชียงใหม่",
    district: "ฝาง",
    subdistrict: "โป่งน้ำร้อน",
    zipcode: 50110,
    latitude: 20.061,
    longitude: 99.121,
  },
  {
    province: "เชียงใหม่",
    district: "แม่อาย",
    subdistrict: "แม่อาย",
    zipcode: 50280,
    latitude: 20.065,
    longitude: 99.262,
  },
  {
    province: "เชียงใหม่",
    district: "แม่อาย",
    subdistrict: "แม่สาว",
    zipcode: 50280,
    latitude: 20.06,
    longitude: 99.168,
  },
  {
    province: "เชียงใหม่",
    district: "แม่อาย",
    subdistrict: "สันต้นหมื้อ",
    zipcode: 50280,
    latitude: 19.948,
    longitude: 99.28,
  },
  {
    province: "เชียงใหม่",
    district: "แม่อาย",
    subdistrict: "แม่นาวาง",
    zipcode: 50280,
    latitude: 19.98,
    longitude: 99.382,
  },
  {
    province: "เชียงใหม่",
    district: "แม่อาย",
    subdistrict: "ท่าตอน",
    zipcode: 50280,
    latitude: 20.066,
    longitude: 99.387,
  },
  {
    province: "เชียงใหม่",
    district: "แม่อาย",
    subdistrict: "บ้านหลวง",
    zipcode: 50280,
    latitude: 19.871,
    longitude: 99.343,
  },
  {
    province: "เชียงใหม่",
    district: "แม่อาย",
    subdistrict: "มะลิกา",
    zipcode: 50280,
    latitude: 20.0659319,
    longitude: 99.2842478,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "เวียง",
    zipcode: 50190,
    latitude: 19.378,
    longitude: 99.203,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "ทุ่งหลวง",
    zipcode: 50190,
    latitude: 19.359,
    longitude: 99.202,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "ป่าตุ้ม",
    zipcode: 50190,
    latitude: 19.37,
    longitude: 99.266,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "ป่าไหน่",
    zipcode: 50190,
    latitude: 19.44,
    longitude: 99.261,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "สันทราย",
    zipcode: 50190,
    latitude: 19.504,
    longitude: 99.204,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "บ้านโป่ง",
    zipcode: 50190,
    latitude: 19.392,
    longitude: 99.121,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "น้ำแพร่",
    zipcode: 50190,
    latitude: 19.33,
    longitude: 99.109,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "เขื่อนผาก",
    zipcode: 50190,
    latitude: 19.308,
    longitude: 99.164,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "แม่แวน",
    zipcode: 50190,
    latitude: 19.303,
    longitude: 99.261,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "แม่ปั๋ง",
    zipcode: 50190,
    latitude: 19.213,
    longitude: 99.212,
  },
  {
    province: "เชียงใหม่",
    district: "พร้าว",
    subdistrict: "โหล่งขอด",
    zipcode: 50190,
    latitude: 19.074,
    longitude: 99.225,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "ยุหว่า",
    zipcode: 50120,
    latitude: 18.62,
    longitude: 98.875,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "สันกลาง",
    zipcode: 50120,
    latitude: 18.665,
    longitude: 98.871,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "ท่าวังพร้าว",
    zipcode: 50120,
    latitude: 18.531,
    longitude: 98.864,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "มะขามหลวง",
    zipcode: 50120,
    latitude: 18.601,
    longitude: 98.907,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "แม่ก๊า",
    zipcode: 50120,
    latitude: 18.574,
    longitude: 98.939,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "บ้านแม",
    zipcode: 50120,
    latitude: 18.626,
    longitude: 98.854,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "บ้านกลาง",
    zipcode: 50120,
    latitude: 18.557,
    longitude: 98.887,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "ทุ่งสะโตก",
    zipcode: 50120,
    latitude: 18.586,
    longitude: 98.849,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "ทุ่งต้อม",
    zipcode: 50120,
    latitude: 18.613,
    longitude: 98.92,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "น้ำบ่อหลวง",
    zipcode: 50120,
    latitude: 18.674,
    longitude: 98.841,
  },
  {
    province: "เชียงใหม่",
    district: "สันป่าตอง",
    subdistrict: "มะขุนหวาน",
    zipcode: 50120,
    latitude: 18.574,
    longitude: 98.907,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "สันกำแพง",
    zipcode: 50130,
    latitude: 18.756,
    longitude: 99.133,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "ทรายมูล",
    zipcode: 50130,
    latitude: 18.735,
    longitude: 99.136,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "ร้องวัวแดง",
    zipcode: 50130,
    latitude: 18.746,
    longitude: 99.206,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "บวกค้าง",
    zipcode: 50130,
    latitude: 18.704,
    longitude: 99.107,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "แช่ช้าง",
    zipcode: 50130,
    latitude: 18.722,
    longitude: 99.154,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "ออนใต้",
    zipcode: 50130,
    latitude: 18.7,
    longitude: 99.229,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "แม่ปูคา",
    zipcode: 50130,
    latitude: 18.785,
    longitude: 99.126,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "ห้วยทราย",
    zipcode: 50130,
    latitude: 18.781,
    longitude: 99.189,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "ต้นเปา",
    zipcode: 50130,
    latitude: 18.76,
    longitude: 99.082,
  },
  {
    province: "เชียงใหม่",
    district: "สันกำแพง",
    subdistrict: "สันกลาง",
    zipcode: 50130,
    latitude: 18.775,
    longitude: 99.056,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "สันทรายหลวง",
    zipcode: 50210,
    latitude: 18.857,
    longitude: 99.046,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "สันทรายน้อย",
    zipcode: 50210,
    latitude: 18.819,
    longitude: 99.024,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "สันพระเนตร",
    zipcode: 50210,
    latitude: 18.808,
    longitude: 99.038,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "สันนาเม็ง",
    zipcode: 50210,
    latitude: 18.842,
    longitude: 99.062,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "สันป่าเปา",
    zipcode: 50210,
    latitude: 18.861,
    longitude: 99.084,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "หนองแหย่ง",
    zipcode: 50210,
    latitude: 18.911,
    longitude: 99.101,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "หนองจ๊อม",
    zipcode: 50210,
    latitude: 18.852,
    longitude: 99.023,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "หนองหาร",
    zipcode: 50290,
    latitude: 18.93,
    longitude: 99.014,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "แม่แฝก",
    zipcode: 50290,
    latitude: 19.0276775,
    longitude: 98.9304198,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "แม่แฝกใหม่",
    zipcode: 50290,
    latitude: 19.026,
    longitude: 99.007,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "เมืองเล็น",
    zipcode: 50210,
    latitude: 18.896,
    longitude: 99.08,
  },
  {
    province: "เชียงใหม่",
    district: "สันทราย",
    subdistrict: "ป่าไผ่",
    zipcode: 50210,
    latitude: 18.907,
    longitude: 99.051,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "หางดง",
    zipcode: 50230,
    latitude: 18.692,
    longitude: 98.915,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "หนองแก๋ว",
    zipcode: 50230,
    latitude: 18.674,
    longitude: 98.933,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "หารแก้ว",
    zipcode: 50230,
    latitude: 18.65,
    longitude: 98.922,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "หนองตอง",
    zipcode: 50340,
    latitude: 18.613,
    longitude: 98.945,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "ขุนคง",
    zipcode: 50230,
    latitude: 18.673,
    longitude: 98.956,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "สบแม่ข่า",
    zipcode: 50230,
    latitude: 18.689,
    longitude: 98.98,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "บ้านแหวน",
    zipcode: 50230,
    latitude: 18.701,
    longitude: 98.944,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "สันผักหวาน",
    zipcode: 50230,
    latitude: 18.719,
    longitude: 98.97,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "หนองควาย",
    zipcode: 50230,
    latitude: 18.731,
    longitude: 98.923,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "บ้านปง",
    zipcode: 50230,
    latitude: 18.769,
    longitude: 98.868,
  },
  {
    province: "เชียงใหม่",
    district: "หางดง",
    subdistrict: "น้ำแพร่",
    zipcode: 50230,
    latitude: 18.709,
    longitude: 98.881,
  },
  {
    province: "เชียงใหม่",
    district: "ฮอด",
    subdistrict: "หางดง",
    zipcode: 50240,
    latitude: 18.16,
    longitude: 98.483,
  },
  {
    province: "เชียงใหม่",
    district: "ฮอด",
    subdistrict: "ฮอด",
    zipcode: 50240,
    latitude: 18.103,
    longitude: 98.583,
  },
  {
    province: "เชียงใหม่",
    district: "ฮอด",
    subdistrict: "บ้านตาล",
    zipcode: 50240,
    latitude: 18.088,
    longitude: 98.709,
  },
  {
    province: "เชียงใหม่",
    district: "ฮอด",
    subdistrict: "บ่อหลวง",
    zipcode: 50240,
    latitude: 18.158,
    longitude: 98.379,
  },
  {
    province: "เชียงใหม่",
    district: "ฮอด",
    subdistrict: "บ่อสลี",
    zipcode: 50240,
    latitude: 18.155,
    longitude: 98.244,
  },
  {
    province: "เชียงใหม่",
    district: "ฮอด",
    subdistrict: "นาคอเรือ",
    zipcode: 50240,
    latitude: 18.009,
    longitude: 98.52,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยเต่า",
    subdistrict: "ดอยเต่า",
    zipcode: 50260,
    latitude: 17.897,
    longitude: 98.731,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยเต่า",
    subdistrict: "ท่าเดื่อ",
    zipcode: 50260,
    latitude: 17.959,
    longitude: 98.676,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยเต่า",
    subdistrict: "มืดกา",
    zipcode: 50260,
    latitude: 17.873,
    longitude: 98.593,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยเต่า",
    subdistrict: "บ้านแอ่น",
    zipcode: 50260,
    latitude: 18.053,
    longitude: 98.626,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยเต่า",
    subdistrict: "บงตัน",
    zipcode: 50260,
    latitude: 18.017,
    longitude: 98.661,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยเต่า",
    subdistrict: "โปงทุ่ง",
    zipcode: 50260,
    latitude: 17.836,
    longitude: 98.736,
  },
  {
    province: "เชียงใหม่",
    district: "อมก๋อย",
    subdistrict: "อมก๋อย",
    zipcode: 50310,
    latitude: 17.915,
    longitude: 98.335,
  },
  {
    province: "เชียงใหม่",
    district: "อมก๋อย",
    subdistrict: "ยางเปียง",
    zipcode: 50310,
    latitude: 17.682,
    longitude: 98.392,
  },
  {
    province: "เชียงใหม่",
    district: "อมก๋อย",
    subdistrict: "แม่ตื่น",
    zipcode: 50310,
    latitude: 17.398,
    longitude: 98.395,
  },
  {
    province: "เชียงใหม่",
    district: "อมก๋อย",
    subdistrict: "ม่อนจอง",
    zipcode: 50310,
    latitude: 17.43,
    longitude: 98.5,
  },
  {
    province: "เชียงใหม่",
    district: "อมก๋อย",
    subdistrict: "สบโขง",
    zipcode: 50310,
    latitude: 17.654,
    longitude: 98.217,
  },
  {
    province: "เชียงใหม่",
    district: "อมก๋อย",
    subdistrict: "นาเกียน",
    zipcode: 50310,
    latitude: 17.864,
    longitude: 98.156,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "ยางเนิ้ง",
    zipcode: 50140,
    latitude: 18.707,
    longitude: 99.036,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "สารภี",
    zipcode: 50140,
    latitude: 18.681,
    longitude: 99.047,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "ชมภู",
    zipcode: 50140,
    latitude: 18.694,
    longitude: 99.071,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "ไชยสถาน",
    zipcode: 50140,
    latitude: 18.745,
    longitude: 99.043,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "ขัวมุง",
    zipcode: 50140,
    latitude: 18.678,
    longitude: 98.991,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "หนองแฝก",
    zipcode: 50140,
    latitude: 18.691,
    longitude: 99.019,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "หนองผึ้ง",
    zipcode: 50140,
    latitude: 18.735,
    longitude: 99.021,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "ท่ากว้าง",
    zipcode: 50140,
    latitude: 18.659,
    longitude: 98.998,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "ดอนแก้ว",
    zipcode: 50140,
    latitude: 18.697,
    longitude: 98.997,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "ท่าวังตาล",
    zipcode: 50140,
    latitude: 18.718,
    longitude: 99.007,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "สันทราย",
    zipcode: 50140,
    latitude: 18.64,
    longitude: 98.972,
  },
  {
    province: "เชียงใหม่",
    district: "สารภี",
    subdistrict: "ป่าบง",
    zipcode: 50140,
    latitude: 18.736,
    longitude: 99.059,
  },
  {
    province: "เชียงใหม่",
    district: "เวียงแหง",
    subdistrict: "เมืองแหง",
    zipcode: 50350,
    latitude: 19.515,
    longitude: 98.639,
  },
  {
    province: "เชียงใหม่",
    district: "เวียงแหง",
    subdistrict: "เปียงหลวง",
    zipcode: 50350,
    latitude: 19.692,
    longitude: 98.693,
  },
  {
    province: "เชียงใหม่",
    district: "เวียงแหง",
    subdistrict: "แสนไห",
    zipcode: 50350,
    latitude: 19.615,
    longitude: 98.632,
  },
  {
    province: "เชียงใหม่",
    district: "ไชยปราการ",
    subdistrict: "ปงตำ",
    zipcode: 50320,
    latitude: 19.748,
    longitude: 99.145,
  },
  {
    province: "เชียงใหม่",
    district: "ไชยปราการ",
    subdistrict: "ศรีดงเย็น",
    zipcode: 50320,
    latitude: 19.646,
    longitude: 99.178,
  },
  {
    province: "เชียงใหม่",
    district: "ไชยปราการ",
    subdistrict: "แม่ทะลบ",
    zipcode: 50320,
    latitude: 19.73,
    longitude: 99.247,
  },
  {
    province: "เชียงใหม่",
    district: "ไชยปราการ",
    subdistrict: "หนองบัว",
    zipcode: 50320,
    latitude: 19.736,
    longitude: 99.073,
  },
  {
    province: "เชียงใหม่",
    district: "แม่วาง",
    subdistrict: "บ้านกาด",
    zipcode: 50360,
    latitude: 18.637,
    longitude: 98.752,
  },
  {
    province: "เชียงใหม่",
    district: "แม่วาง",
    subdistrict: "ทุ่งปี๊",
    zipcode: 50360,
    latitude: 18.587,
    longitude: 98.741,
  },
  {
    province: "เชียงใหม่",
    district: "แม่วาง",
    subdistrict: "ทุ่งรวงทอง",
    zipcode: 50360,
    latitude: 18.554,
    longitude: 98.849,
  },
  {
    province: "เชียงใหม่",
    district: "แม่วาง",
    subdistrict: "แม่วิน",
    zipcode: 50360,
    latitude: 18.683,
    longitude: 98.674,
  },
  {
    province: "เชียงใหม่",
    district: "แม่วาง",
    subdistrict: "ดอนเปา",
    zipcode: 50360,
    latitude: 18.679,
    longitude: 98.783,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ออน",
    subdistrict: "ออนเหนือ",
    zipcode: 50130,
    latitude: 18.793,
    longitude: 99.308,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ออน",
    subdistrict: "ออนกลาง",
    zipcode: 50130,
    latitude: 18.758,
    longitude: 99.263,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ออน",
    subdistrict: "บ้านสหกรณ์",
    zipcode: 50130,
    latitude: 18.825,
    longitude: 99.242,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ออน",
    subdistrict: "ห้วยแก้ว",
    zipcode: 50130,
    latitude: 18.884,
    longitude: 99.327,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ออน",
    subdistrict: "แม่ทา",
    zipcode: 50130,
    latitude: 18.591,
    longitude: 99.289,
  },
  {
    province: "เชียงใหม่",
    district: "แม่ออน",
    subdistrict: "ทาเหนือ",
    zipcode: 50130,
    latitude: 18.702,
    longitude: 99.308,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยหล่อ",
    subdistrict: "ดอยหล่อ",
    zipcode: 50160,
    latitude: 18.488,
    longitude: 98.798,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยหล่อ",
    subdistrict: "สองแคว",
    zipcode: 50160,
    latitude: 18.504,
    longitude: 98.841,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยหล่อ",
    subdistrict: "ยางคราม",
    zipcode: 50160,
    latitude: 18.56,
    longitude: 98.782,
  },
  {
    province: "เชียงใหม่",
    district: "ดอยหล่อ",
    subdistrict: "สันติสุข",
    zipcode: 50160,
    latitude: 18.581,
    longitude: 98.682,
  },
  {
    province: "เชียงใหม่",
    district: "กัลยาณิวัฒนา",
    subdistrict: "บ้านจันทร์",
    zipcode: 58130,
    latitude: 19.11,
    longitude: 98.281,
  },
  {
    province: "เชียงใหม่",
    district: "กัลยาณิวัฒนา",
    subdistrict: "แม่แดด",
    zipcode: 58130,
    latitude: 18.936,
    longitude: 98.38,
  },
  {
    province: "เชียงใหม่",
    district: "กัลยาณิวัฒนา",
    subdistrict: "แจ่มหลวง",
    zipcode: 58130,
    latitude: 19.011,
    longitude: 98.218,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "ในเมือง",
    zipcode: 51000,
    latitude: 18.573,
    longitude: 99.002,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "เหมืองง่า",
    zipcode: 51000,
    latitude: 18.613,
    longitude: 99.032,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "อุโมงค์",
    zipcode: 51150,
    latitude: 18.648,
    longitude: 99.042,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "หนองช้างคืน",
    zipcode: 51150,
    latitude: 18.656,
    longitude: 99.013,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "ประตูป่า",
    zipcode: 51000,
    latitude: 18.623,
    longitude: 98.986,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "ริมปิง",
    zipcode: 51000,
    latitude: 18.598,
    longitude: 98.984,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "ต้นธง",
    zipcode: 51000,
    latitude: 18.572,
    longitude: 98.985,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "บ้านแป้น",
    zipcode: 51000,
    latitude: 18.523,
    longitude: 98.951,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "เหมืองจี้",
    zipcode: 51000,
    latitude: 18.468,
    longitude: 98.971,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "ป่าสัก",
    zipcode: 51000,
    latitude: 18.519,
    longitude: 99.03,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "เวียงยอง",
    zipcode: 51000,
    latitude: 18.558,
    longitude: 99.009,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "บ้านกลาง",
    zipcode: 51000,
    latitude: 18.575,
    longitude: 99.063,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "มะเขือแจ้",
    zipcode: 51000,
    latitude: 18.592,
    longitude: 99.128,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "ศรีบัวบาน",
    zipcode: 51000,
    latitude: 18.517,
    longitude: 99.109,
  },
  {
    province: "ลำพูน",
    district: "เมืองลำพูน",
    subdistrict: "หนองหนาม",
    zipcode: 51000,
    latitude: 18.502,
    longitude: 98.989,
  },
  {
    province: "ลำพูน",
    district: "แม่ทา",
    subdistrict: "ทาปลาดุก",
    zipcode: 51140,
    latitude: 18.532,
    longitude: 99.245,
  },
  {
    province: "ลำพูน",
    district: "แม่ทา",
    subdistrict: "ทาสบเส้า",
    zipcode: 51140,
    latitude: 18.417,
    longitude: 99.135,
  },
  {
    province: "ลำพูน",
    district: "แม่ทา",
    subdistrict: "ทากาศ",
    zipcode: 51170,
    latitude: 18.342,
    longitude: 99.052,
  },
  {
    province: "ลำพูน",
    district: "แม่ทา",
    subdistrict: "ทาขุมเงิน",
    zipcode: 51170,
    latitude: 18.363,
    longitude: 98.962,
  },
  {
    province: "ลำพูน",
    district: "แม่ทา",
    subdistrict: "ทาทุ่งหลวง",
    zipcode: 51170,
    latitude: 18.438,
    longitude: 99.03,
  },
  {
    province: "ลำพูน",
    district: "แม่ทา",
    subdistrict: "ทาแม่ลอบ",
    zipcode: 51170,
    latitude: 18.241,
    longitude: 98.97,
  },
  {
    province: "ลำพูน",
    district: "บ้านโฮ่ง",
    subdistrict: "บ้านโฮ่ง",
    zipcode: 51130,
    latitude: 18.308,
    longitude: 98.815,
  },
  {
    province: "ลำพูน",
    district: "บ้านโฮ่ง",
    subdistrict: "ป่าพลู",
    zipcode: 51130,
    latitude: 18.211,
    longitude: 98.867,
  },
  {
    province: "ลำพูน",
    district: "บ้านโฮ่ง",
    subdistrict: "เหล่ายาว",
    zipcode: 51130,
    latitude: 18.36,
    longitude: 98.794,
  },
  {
    province: "ลำพูน",
    district: "บ้านโฮ่ง",
    subdistrict: "ศรีเตี้ย",
    zipcode: 51130,
    latitude: 18.383,
    longitude: 98.75,
  },
  {
    province: "ลำพูน",
    district: "บ้านโฮ่ง",
    subdistrict: "หนองปลาสะวาย",
    zipcode: 51130,
    latitude: 18.314,
    longitude: 98.697,
  },
  {
    province: "ลำพูน",
    district: "ลี้",
    subdistrict: "ลี้",
    zipcode: 51110,
    latitude: 17.79,
    longitude: 98.986,
  },
  {
    province: "ลำพูน",
    district: "ลี้",
    subdistrict: "แม่ตืน",
    zipcode: 51110,
    latitude: 17.956,
    longitude: 98.956,
  },
  {
    province: "ลำพูน",
    district: "ลี้",
    subdistrict: "นาทราย",
    zipcode: 51110,
    latitude: 17.687,
    longitude: 98.97,
  },
  {
    province: "ลำพูน",
    district: "ลี้",
    subdistrict: "ดงดำ",
    zipcode: 51110,
    latitude: 17.742,
    longitude: 99.062,
  },
  {
    province: "ลำพูน",
    district: "ลี้",
    subdistrict: "ก้อ",
    zipcode: 51110,
    latitude: 17.604,
    longitude: 98.783,
  },
  {
    province: "ลำพูน",
    district: "ลี้",
    subdistrict: "แม่ลาน",
    zipcode: 51110,
    latitude: 17.761,
    longitude: 98.809,
  },
  {
    province: "ลำพูน",
    district: "ลี้",
    subdistrict: "ป่าไผ่",
    zipcode: 51110,
    latitude: 17.855,
    longitude: 98.936,
  },
  {
    province: "ลำพูน",
    district: "ลี้",
    subdistrict: "ศรีวิชัย",
    zipcode: 51110,
    latitude: 18.079,
    longitude: 98.898,
  },
  {
    province: "ลำพูน",
    district: "ทุ่งหัวช้าง",
    subdistrict: "ทุ่งหัวช้าง",
    zipcode: 51160,
    latitude: 17.983,
    longitude: 99.048,
  },
  {
    province: "ลำพูน",
    district: "ทุ่งหัวช้าง",
    subdistrict: "บ้านปวง",
    zipcode: 51160,
    latitude: 17.87,
    longitude: 99.089,
  },
  {
    province: "ลำพูน",
    district: "ทุ่งหัวช้าง",
    subdistrict: "ตะเคียนปม",
    zipcode: 51160,
    latitude: 18.106,
    longitude: 99.025,
  },
  {
    province: "ลำพูน",
    district: "ป่าซาง",
    subdistrict: "ปากบ่อง",
    zipcode: 51120,
    latitude: 18.537,
    longitude: 98.933,
  },
  {
    province: "ลำพูน",
    district: "ป่าซาง",
    subdistrict: "ป่าซาง",
    zipcode: 51120,
    latitude: 18.512,
    longitude: 98.937,
  },
  {
    province: "ลำพูน",
    district: "ป่าซาง",
    subdistrict: "แม่แรง",
    zipcode: 51120,
    latitude: 18.498,
    longitude: 98.91,
  },
  {
    province: "ลำพูน",
    district: "ป่าซาง",
    subdistrict: "ม่วงน้อย",
    zipcode: 51120,
    latitude: 18.479,
    longitude: 98.941,
  },
  {
    province: "ลำพูน",
    district: "ป่าซาง",
    subdistrict: "บ้านเรือน",
    zipcode: 51120,
    latitude: 18.509,
    longitude: 98.871,
  },
  {
    province: "ลำพูน",
    district: "ป่าซาง",
    subdistrict: "มะกอก",
    zipcode: 51120,
    latitude: 18.449,
    longitude: 98.923,
  },
  {
    province: "ลำพูน",
    district: "ป่าซาง",
    subdistrict: "ท่าตุ้ม",
    zipcode: 51120,
    latitude: 18.478,
    longitude: 98.873,
  },
  {
    province: "ลำพูน",
    district: "ป่าซาง",
    subdistrict: "น้ำดิบ",
    zipcode: 51120,
    latitude: 18.424,
    longitude: 98.833,
  },
  {
    province: "ลำพูน",
    district: "ป่าซาง",
    subdistrict: "นครเจดีย์",
    zipcode: 51120,
    latitude: 18.371,
    longitude: 98.898,
  },
  {
    province: "ลำพูน",
    district: "บ้านธิ",
    subdistrict: "บ้านธิ",
    zipcode: 51180,
    latitude: 18.614,
    longitude: 99.19,
  },
  {
    province: "ลำพูน",
    district: "บ้านธิ",
    subdistrict: "ห้วยยาบ",
    zipcode: 51180,
    latitude: 18.679,
    longitude: 99.163,
  },
  {
    province: "ลำพูน",
    district: "เวียงหนองล่อง",
    subdistrict: "หนองล่อง",
    zipcode: 51120,
    latitude: 18.404,
    longitude: 98.732,
  },
  {
    province: "ลำพูน",
    district: "เวียงหนองล่อง",
    subdistrict: "หนองยวง",
    zipcode: 51120,
    latitude: 18.415,
    longitude: 98.78,
  },
  {
    province: "ลำพูน",
    district: "เวียงหนองล่อง",
    subdistrict: "วังผาง",
    zipcode: 51120,
    latitude: 18.422,
    longitude: 98.744,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "เวียงเหนือ",
    zipcode: 52000,
    latitude: 18.3003096,
    longitude: 99.488158,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "หัวเวียง",
    zipcode: 52000,
    latitude: 18.2942901,
    longitude: 99.5020783,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "สวนดอก",
    zipcode: 52100,
    latitude: 18.2870952,
    longitude: 99.4882195,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "สบตุ๋ย",
    zipcode: 52100,
    latitude: 18.2861264,
    longitude: 99.4672253,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "พระบาท",
    zipcode: 52000,
    latitude: 18.255,
    longitude: 99.518,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "ชมพู",
    zipcode: 52100,
    latitude: 18.254,
    longitude: 99.479,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "กล้วยแพะ",
    zipcode: 52000,
    latitude: 18.211,
    longitude: 99.502,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "ปงแสนทอง",
    zipcode: 52100,
    latitude: 18.268,
    longitude: 99.432,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "บ้านแลง",
    zipcode: 52000,
    latitude: 18.526,
    longitude: 99.691,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "บ้านเสด็จ",
    zipcode: 52000,
    latitude: 18.392,
    longitude: 99.645,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "พิชัย",
    zipcode: 52000,
    latitude: 18.316,
    longitude: 99.571,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "ทุ่งฝาย",
    zipcode: 52000,
    latitude: 18.374,
    longitude: 99.55,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "บ้านเอื้อม",
    zipcode: 52100,
    latitude: 18.478,
    longitude: 99.357,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "บ้านเป้า",
    zipcode: 52100,
    latitude: 18.357,
    longitude: 99.455,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "บ้านค่า",
    zipcode: 52100,
    latitude: 18.52,
    longitude: 99.414,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "บ่อแฮ้ว",
    zipcode: 52100,
    latitude: 18.31,
    longitude: 99.433,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "ต้นธงชัย",
    zipcode: 52000,
    latitude: 18.39,
    longitude: 99.485,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "นิคมพัฒนา",
    zipcode: 52000,
    latitude: 18.445,
    longitude: 99.545,
  },
  {
    province: "ลำปาง",
    district: "เมืองลำปาง",
    subdistrict: "บุญนาคพัฒนา",
    zipcode: 52000,
    latitude: 18.46,
    longitude: 99.598,
  },
  {
    province: "ลำปาง",
    district: "แม่เมาะ",
    subdistrict: "บ้านดง",
    zipcode: 52220,
    latitude: 18.374,
    longitude: 99.701,
  },
  {
    province: "ลำปาง",
    district: "แม่เมาะ",
    subdistrict: "นาสัก",
    zipcode: 52220,
    latitude: 18.343,
    longitude: 99.836,
  },
  {
    province: "ลำปาง",
    district: "แม่เมาะ",
    subdistrict: "จางเหนือ",
    zipcode: 52220,
    latitude: 18.456,
    longitude: 99.941,
  },
  {
    province: "ลำปาง",
    district: "แม่เมาะ",
    subdistrict: "แม่เมาะ",
    zipcode: 52220,
    latitude: 18.297,
    longitude: 99.668,
  },
  {
    province: "ลำปาง",
    district: "แม่เมาะ",
    subdistrict: "สบป้าด",
    zipcode: 52220,
    latitude: 18.2481191,
    longitude: 99.6652358,
  },
  {
    province: "ลำปาง",
    district: "เกาะคา",
    subdistrict: "ลำปางหลวง",
    zipcode: 52130,
    latitude: 18.238,
    longitude: 99.387,
  },
  {
    province: "ลำปาง",
    district: "เกาะคา",
    subdistrict: "นาแก้ว",
    zipcode: 52130,
    latitude: 18.031,
    longitude: 99.301,
  },
  {
    province: "ลำปาง",
    district: "เกาะคา",
    subdistrict: "ไหล่หิน",
    zipcode: 52130,
    latitude: 18.186,
    longitude: 99.255,
  },
  {
    province: "ลำปาง",
    district: "เกาะคา",
    subdistrict: "วังพร้าว",
    zipcode: 52130,
    latitude: 18.147,
    longitude: 99.407,
  },
  {
    province: "ลำปาง",
    district: "เกาะคา",
    subdistrict: "ศาลา",
    zipcode: 52130,
    latitude: 18.196,
    longitude: 99.413,
  },
  {
    province: "ลำปาง",
    district: "เกาะคา",
    subdistrict: "เกาะคา",
    zipcode: 52130,
    latitude: 18.202,
    longitude: 99.372,
  },
  {
    province: "ลำปาง",
    district: "เกาะคา",
    subdistrict: "นาแส่ง",
    zipcode: 52130,
    latitude: 18.074,
    longitude: 99.381,
  },
  {
    province: "ลำปาง",
    district: "เกาะคา",
    subdistrict: "ท่าผา",
    zipcode: 52130,
    latitude: 18.173,
    longitude: 99.365,
  },
  {
    province: "ลำปาง",
    district: "เกาะคา",
    subdistrict: "ใหม่พัฒนา",
    zipcode: 52130,
    latitude: 18.254,
    longitude: 99.318,
  },
  {
    province: "ลำปาง",
    district: "เสริมงาม",
    subdistrict: "ทุ่งงาม",
    zipcode: 52210,
    latitude: 18.071,
    longitude: 99.244,
  },
  {
    province: "ลำปาง",
    district: "เสริมงาม",
    subdistrict: "เสริมขวา",
    zipcode: 52210,
    latitude: 18.138,
    longitude: 99.196,
  },
  {
    province: "ลำปาง",
    district: "เสริมงาม",
    subdistrict: "เสริมซ้าย",
    zipcode: 52210,
    latitude: 17.975,
    longitude: 99.165,
  },
  {
    province: "ลำปาง",
    district: "เสริมงาม",
    subdistrict: "เสริมกลาง",
    zipcode: 52210,
    latitude: 18.132,
    longitude: 99.087,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "หลวงเหนือ",
    zipcode: 52110,
    latitude: 18.752,
    longitude: 99.996,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "หลวงใต้",
    zipcode: 52110,
    latitude: 18.743,
    longitude: 99.958,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "บ้านโป่ง",
    zipcode: 52110,
    latitude: 18.726,
    longitude: 99.886,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "บ้านร้อง",
    zipcode: 52110,
    latitude: 18.972,
    longitude: 99.824,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "ปงเตา",
    zipcode: 52110,
    latitude: 18.839,
    longitude: 99.927,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "นาแก",
    zipcode: 52110,
    latitude: 18.817,
    longitude: 99.885,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "บ้านอ้อน",
    zipcode: 52110,
    latitude: 18.788,
    longitude: 99.852,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "บ้านแหง",
    zipcode: 52110,
    latitude: 18.782,
    longitude: 100.054,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "บ้านหวด",
    zipcode: 52110,
    latitude: 18.609,
    longitude: 99.888,
  },
  {
    province: "ลำปาง",
    district: "งาว",
    subdistrict: "แม่ตีบ",
    zipcode: 52110,
    latitude: 18.634,
    longitude: 100.038,
  },
  {
    province: "ลำปาง",
    district: "แจ้ห่ม",
    subdistrict: "แจ้ห่ม",
    zipcode: 52120,
    latitude: 18.7,
    longitude: 99.616,
  },
  {
    province: "ลำปาง",
    district: "แจ้ห่ม",
    subdistrict: "บ้านสา",
    zipcode: 52120,
    latitude: 18.592,
    longitude: 99.561,
  },
  {
    province: "ลำปาง",
    district: "แจ้ห่ม",
    subdistrict: "ปงดอน",
    zipcode: 52120,
    latitude: 18.783,
    longitude: 99.695,
  },
  {
    province: "ลำปาง",
    district: "แจ้ห่ม",
    subdistrict: "แม่สุก",
    zipcode: 52120,
    latitude: 18.836,
    longitude: 99.578,
  },
  {
    province: "ลำปาง",
    district: "แจ้ห่ม",
    subdistrict: "เมืองมาย",
    zipcode: 52120,
    latitude: 18.645,
    longitude: 99.725,
  },
  {
    province: "ลำปาง",
    district: "แจ้ห่ม",
    subdistrict: "ทุ่งผึ้ง",
    zipcode: 52120,
    latitude: 18.914,
    longitude: 99.703,
  },
  {
    province: "ลำปาง",
    district: "แจ้ห่ม",
    subdistrict: "วิเชตนคร",
    zipcode: 52120,
    latitude: 18.726,
    longitude: 99.557,
  },
  {
    province: "ลำปาง",
    district: "วังเหนือ",
    subdistrict: "ทุ่งฮั้ว",
    zipcode: 52140,
    latitude: 19.229,
    longitude: 99.631,
  },
  {
    province: "ลำปาง",
    district: "วังเหนือ",
    subdistrict: "วังเหนือ",
    zipcode: 52140,
    latitude: 19.16,
    longitude: 99.585,
  },
  {
    province: "ลำปาง",
    district: "วังเหนือ",
    subdistrict: "วังใต้",
    zipcode: 52140,
    latitude: 19.084,
    longitude: 99.577,
  },
  {
    province: "ลำปาง",
    district: "วังเหนือ",
    subdistrict: "ร่องเคาะ",
    zipcode: 52140,
    latitude: 18.992,
    longitude: 99.601,
  },
  {
    province: "ลำปาง",
    district: "วังเหนือ",
    subdistrict: "วังทอง",
    zipcode: 52140,
    latitude: 19.07,
    longitude: 99.713,
  },
  {
    province: "ลำปาง",
    district: "วังเหนือ",
    subdistrict: "วังซ้าย",
    zipcode: 52140,
    latitude: 19.157,
    longitude: 99.645,
  },
  {
    province: "ลำปาง",
    district: "วังเหนือ",
    subdistrict: "วังแก้ว",
    zipcode: 52140,
    latitude: 19.329,
    longitude: 99.636,
  },
  {
    province: "ลำปาง",
    district: "วังเหนือ",
    subdistrict: "วังทรายคำ",
    zipcode: 52140,
    latitude: 19.08,
    longitude: 99.649,
  },
  {
    province: "ลำปาง",
    district: "เถิน",
    subdistrict: "ล้อมแรด",
    zipcode: 52160,
    latitude: 17.634,
    longitude: 99.212,
  },
  {
    province: "ลำปาง",
    district: "เถิน",
    subdistrict: "แม่วะ",
    zipcode: 52230,
    latitude: 17.468,
    longitude: 99.216,
  },
  {
    province: "ลำปาง",
    district: "เถิน",
    subdistrict: "แม่ปะ",
    zipcode: 52160,
    latitude: 17.684,
    longitude: 99.299,
  },
  {
    province: "ลำปาง",
    district: "เถิน",
    subdistrict: "แม่มอก",
    zipcode: 52160,
    latitude: 17.609,
    longitude: 99.381,
  },
  {
    province: "ลำปาง",
    district: "เถิน",
    subdistrict: "เวียงมอก",
    zipcode: 52160,
    latitude: 17.396,
    longitude: 99.353,
  },
  {
    province: "ลำปาง",
    district: "เถิน",
    subdistrict: "นาโป่ง",
    zipcode: 52160,
    latitude: 17.651,
    longitude: 99.116,
  },
  {
    province: "ลำปาง",
    district: "เถิน",
    subdistrict: "แม่ถอด",
    zipcode: 52160,
    latitude: 17.766,
    longitude: 99.201,
  },
  {
    province: "ลำปาง",
    district: "เถิน",
    subdistrict: "เถินบุรี",
    zipcode: 52160,
    latitude: 17.558,
    longitude: 99.207,
  },
  {
    province: "ลำปาง",
    district: "แม่พริก",
    subdistrict: "แม่พริก",
    zipcode: 52180,
    latitude: 17.513,
    longitude: 99.009,
  },
  {
    province: "ลำปาง",
    district: "แม่พริก",
    subdistrict: "ผาปัง",
    zipcode: 52180,
    latitude: 17.564,
    longitude: 99.069,
  },
  {
    province: "ลำปาง",
    district: "แม่พริก",
    subdistrict: "แม่ปุ",
    zipcode: 52180,
    latitude: 17.539,
    longitude: 99.152,
  },
  {
    province: "ลำปาง",
    district: "แม่พริก",
    subdistrict: "พระบาทวังตวง",
    zipcode: 52180,
    latitude: 17.412,
    longitude: 99.162,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "แม่ทะ",
    zipcode: 52150,
    latitude: 18.162,
    longitude: 99.563,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "นาครัว",
    zipcode: 52150,
    latitude: 18.109,
    longitude: 99.545,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "ป่าตัน",
    zipcode: 52150,
    latitude: 18.122,
    longitude: 99.477,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "บ้านกิ่ว",
    zipcode: 52150,
    latitude: 18.089,
    longitude: 99.491,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "บ้านบอม",
    zipcode: 52150,
    latitude: 18.054,
    longitude: 99.489,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "น้ำโจ้",
    zipcode: 52150,
    latitude: 18.17,
    longitude: 99.46,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "ดอนไฟ",
    zipcode: 52150,
    latitude: 18.104,
    longitude: 99.671,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "หัวเสือ",
    zipcode: 52150,
    latitude: 18.173,
    longitude: 99.696,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "วังเงิน",
    zipcode: 52150,
    latitude: 18.09,
    longitude: 99.614,
  },
  {
    province: "ลำปาง",
    district: "แม่ทะ",
    subdistrict: "สันดอนแก้ว",
    zipcode: 52150,
    latitude: 17.984,
    longitude: 99.484,
  },
  {
    province: "ลำปาง",
    district: "สบปราบ",
    subdistrict: "สบปราบ",
    zipcode: 52170,
    latitude: 17.835,
    longitude: 99.344,
  },
  {
    province: "ลำปาง",
    district: "สบปราบ",
    subdistrict: "สมัย",
    zipcode: 52170,
    latitude: 17.9,
    longitude: 99.421,
  },
  {
    province: "ลำปาง",
    district: "สบปราบ",
    subdistrict: "แม่กัวะ",
    zipcode: 52170,
    latitude: 17.956,
    longitude: 99.353,
  },
  {
    province: "ลำปาง",
    district: "สบปราบ",
    subdistrict: "นายาง",
    zipcode: 52170,
    latitude: 17.904,
    longitude: 99.266,
  },
  {
    province: "ลำปาง",
    district: "ห้างฉัตร",
    subdistrict: "ห้างฉัตร",
    zipcode: 52190,
    latitude: 18.337,
    longitude: 99.353,
  },
  {
    province: "ลำปาง",
    district: "ห้างฉัตร",
    subdistrict: "หนองหล่ม",
    zipcode: 52190,
    latitude: 18.349,
    longitude: 99.387,
  },
  {
    province: "ลำปาง",
    district: "ห้างฉัตร",
    subdistrict: "เมืองยาว",
    zipcode: 52190,
    latitude: 18.269,
    longitude: 99.2,
  },
  {
    province: "ลำปาง",
    district: "ห้างฉัตร",
    subdistrict: "ปงยางคก",
    zipcode: 52190,
    latitude: 18.284,
    longitude: 99.365,
  },
  {
    province: "ลำปาง",
    district: "ห้างฉัตร",
    subdistrict: "เวียงตาล",
    zipcode: 52190,
    latitude: 18.408,
    longitude: 99.277,
  },
  {
    province: "ลำปาง",
    district: "ห้างฉัตร",
    subdistrict: "แม่สัน",
    zipcode: 52190,
    latitude: 18.291,
    longitude: 99.273,
  },
  {
    province: "ลำปาง",
    district: "ห้างฉัตร",
    subdistrict: "วอแก้ว",
    zipcode: 52190,
    latitude: 18.421,
    longitude: 99.339,
  },
  {
    province: "ลำปาง",
    district: "เมืองปาน",
    subdistrict: "เมืองปาน",
    zipcode: 52240,
    latitude: 18.797,
    longitude: 99.446,
  },
  {
    province: "ลำปาง",
    district: "เมืองปาน",
    subdistrict: "บ้านขอ",
    zipcode: 52240,
    latitude: 18.685,
    longitude: 99.481,
  },
  {
    province: "ลำปาง",
    district: "เมืองปาน",
    subdistrict: "ทุ่งกว๋าว",
    zipcode: 52240,
    latitude: 18.57,
    longitude: 99.47,
  },
  {
    province: "ลำปาง",
    district: "เมืองปาน",
    subdistrict: "แจ้ซ้อน",
    zipcode: 52240,
    latitude: 18.838,
    longitude: 99.501,
  },
  {
    province: "ลำปาง",
    district: "เมืองปาน",
    subdistrict: "หัวเมือง",
    zipcode: 52240,
    latitude: 18.946,
    longitude: 99.531,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "ท่าอิฐ",
    zipcode: 53000,
    latitude: 17.613,
    longitude: 100.079,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "ท่าเสา",
    zipcode: 53000,
    latitude: 17.652,
    longitude: 100.086,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "บ้านเกาะ",
    zipcode: 53000,
    latitude: 17.579,
    longitude: 100.117,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "ป่าเซ่า",
    zipcode: 53000,
    latitude: 17.6,
    longitude: 100.16,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "คุ้งตะเภา",
    zipcode: 53000,
    latitude: 17.641,
    longitude: 100.166,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "วังกะพี้",
    zipcode: 53170,
    latitude: 17.545,
    longitude: 100.079,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "หาดกรวด",
    zipcode: 53000,
    latitude: 17.562,
    longitude: 100.199,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "น้ำริด",
    zipcode: 53000,
    latitude: 17.684,
    longitude: 100.132,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "งิ้วงาม",
    zipcode: 53000,
    latitude: 17.702,
    longitude: 100.174,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "บ้านด่านนาขาม",
    zipcode: 53000,
    latitude: 17.784,
    longitude: 100.161,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "บ้านด่าน",
    zipcode: 53000,
    latitude: 17.663,
    longitude: 100.28,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "ผาจุก",
    zipcode: 53000,
    latitude: 17.639,
    longitude: 100.243,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "วังดิน",
    zipcode: 53000,
    latitude: 17.737,
    longitude: 100.266,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "แสนตอ",
    zipcode: 53000,
    latitude: 17.651,
    longitude: 100.365,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "หาดงิ้ว",
    zipcode: 53000,
    latitude: 17.681,
    longitude: 100.337,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "ขุนฝาง",
    zipcode: 53000,
    latitude: 17.76,
    longitude: 100.223,
  },
  {
    province: "อุตรดิตถ์",
    district: "เมืองอุตรดิตถ์",
    subdistrict: "ถ้ำฉลอง",
    zipcode: 53000,
    latitude: 17.606,
    longitude: 100.36,
  },
  {
    province: "อุตรดิตถ์",
    district: "ตรอน",
    subdistrict: "วังแดง",
    zipcode: 53140,
    latitude: 17.488,
    longitude: 100.113,
  },
  {
    province: "อุตรดิตถ์",
    district: "ตรอน",
    subdistrict: "บ้านแก่ง",
    zipcode: 53140,
    latitude: 17.441,
    longitude: 100.158,
  },
  {
    province: "อุตรดิตถ์",
    district: "ตรอน",
    subdistrict: "หาดสองแคว",
    zipcode: 53140,
    latitude: 17.426,
    longitude: 100.115,
  },
  {
    province: "อุตรดิตถ์",
    district: "ตรอน",
    subdistrict: "น้ำอ่าง",
    zipcode: 53140,
    latitude: 17.456,
    longitude: 100.215,
  },
  {
    province: "อุตรดิตถ์",
    district: "ตรอน",
    subdistrict: "ข่อยสูง",
    zipcode: 53140,
    latitude: 17.502,
    longitude: 99.976,
  },
  {
    province: "อุตรดิตถ์",
    district: "ท่าปลา",
    subdistrict: "ท่าปลา",
    zipcode: 53150,
    latitude: 17.845,
    longitude: 100.448,
  },
  {
    province: "อุตรดิตถ์",
    district: "ท่าปลา",
    subdistrict: "หาดล้า",
    zipcode: 53150,
    latitude: 17.76,
    longitude: 100.333,
  },
  {
    province: "อุตรดิตถ์",
    district: "ท่าปลา",
    subdistrict: "ผาเลือด",
    zipcode: 53190,
    latitude: 17.749,
    longitude: 100.491,
  },
  {
    province: "อุตรดิตถ์",
    district: "ท่าปลา",
    subdistrict: "จริม",
    zipcode: 53150,
    latitude: 17.834,
    longitude: 100.353,
  },
  {
    province: "อุตรดิตถ์",
    district: "ท่าปลา",
    subdistrict: "น้ำหมัน",
    zipcode: 53150,
    latitude: 17.862,
    longitude: 100.25,
  },
  {
    province: "อุตรดิตถ์",
    district: "ท่าปลา",
    subdistrict: "ท่าแฝก",
    zipcode: 53110,
    latitude: 17.92,
    longitude: 100.615,
  },
  {
    province: "อุตรดิตถ์",
    district: "ท่าปลา",
    subdistrict: "นางพญา",
    zipcode: 53150,
    latitude: 17.97,
    longitude: 100.441,
  },
  {
    province: "อุตรดิตถ์",
    district: "ท่าปลา",
    subdistrict: "ร่วมจิต",
    zipcode: 53190,
    latitude: 17.718,
    longitude: 100.329,
  },
  {
    province: "อุตรดิตถ์",
    district: "น้ำปาด",
    subdistrict: "แสนตอ",
    zipcode: 53110,
    latitude: 17.72,
    longitude: 100.646,
  },
  {
    province: "อุตรดิตถ์",
    district: "น้ำปาด",
    subdistrict: "บ้านฝาย",
    zipcode: 53110,
    latitude: 17.74,
    longitude: 100.758,
  },
  {
    province: "อุตรดิตถ์",
    district: "น้ำปาด",
    subdistrict: "เด่นเหล็ก",
    zipcode: 53110,
    latitude: 17.82,
    longitude: 100.788,
  },
  {
    province: "อุตรดิตถ์",
    district: "น้ำปาด",
    subdistrict: "น้ำไคร้",
    zipcode: 53110,
    latitude: 17.608,
    longitude: 100.542,
  },
  {
    province: "อุตรดิตถ์",
    district: "น้ำปาด",
    subdistrict: "น้ำไผ่",
    zipcode: 53110,
    latitude: 17.6098268,
    longitude: 100.5339948,
  },
  {
    province: "อุตรดิตถ์",
    district: "น้ำปาด",
    subdistrict: "ห้วยมุ่น",
    zipcode: 53110,
    latitude: 17.794,
    longitude: 100.935,
  },
  {
    province: "อุตรดิตถ์",
    district: "ฟากท่า",
    subdistrict: "ฟากท่า",
    zipcode: 53160,
    latitude: 18.068,
    longitude: 100.937,
  },
  {
    province: "อุตรดิตถ์",
    district: "ฟากท่า",
    subdistrict: "สองคอน",
    zipcode: 53160,
    latitude: 17.955,
    longitude: 100.906,
  },
  {
    province: "อุตรดิตถ์",
    district: "ฟากท่า",
    subdistrict: "บ้านเสี้ยว",
    zipcode: 53160,
    latitude: 17.914,
    longitude: 100.848,
  },
  {
    province: "อุตรดิตถ์",
    district: "ฟากท่า",
    subdistrict: "สองห้อง",
    zipcode: 53160,
    latitude: 17.93,
    longitude: 100.757,
  },
  {
    province: "อุตรดิตถ์",
    district: "บ้านโคก",
    subdistrict: "ม่วงเจ็ดต้น",
    zipcode: 53180,
    latitude: 18.115,
    longitude: 101.062,
  },
  {
    province: "อุตรดิตถ์",
    district: "บ้านโคก",
    subdistrict: "บ้านโคก",
    zipcode: 53180,
    latitude: 18.008,
    longitude: 101.084,
  },
  {
    province: "อุตรดิตถ์",
    district: "บ้านโคก",
    subdistrict: "นาขุม",
    zipcode: 53180,
    latitude: 17.951,
    longitude: 100.998,
  },
  {
    province: "อุตรดิตถ์",
    district: "บ้านโคก",
    subdistrict: "บ่อเบี้ย",
    zipcode: 53180,
    latitude: 18.253,
    longitude: 101.044,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "ในเมือง",
    zipcode: 53120,
    latitude: 17.301,
    longitude: 100.107,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "บ้านดารา",
    zipcode: 53220,
    latitude: 17.37,
    longitude: 100.13,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "ไร่อ้อย",
    zipcode: 53120,
    latitude: 17.358,
    longitude: 100.027,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "ท่าสัก",
    zipcode: 53220,
    latitude: 17.379,
    longitude: 100.178,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "คอรุม",
    zipcode: 53120,
    latitude: 17.292,
    longitude: 100.031,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "บ้านหม้อ",
    zipcode: 53120,
    latitude: 17.256,
    longitude: 100.123,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "ท่ามะเฟือง",
    zipcode: 53120,
    latitude: 17.238,
    longitude: 100.021,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "บ้านโคน",
    zipcode: 53120,
    latitude: 17.208,
    longitude: 100.138,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "พญาแมน",
    zipcode: 53120,
    latitude: 17.187,
    longitude: 100.016,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "นาอิน",
    zipcode: 53120,
    latitude: 17.245,
    longitude: 100.193,
  },
  {
    province: "อุตรดิตถ์",
    district: "พิชัย",
    subdistrict: "นายาง",
    zipcode: 53120,
    latitude: 17.318,
    longitude: 100.21,
  },
  {
    province: "อุตรดิตถ์",
    district: "ลับแล",
    subdistrict: "ศรีพนมมาศ",
    zipcode: 53130,
    latitude: 17.652,
    longitude: 100.039,
  },
  {
    province: "อุตรดิตถ์",
    district: "ลับแล",
    subdistrict: "แม่พูล",
    zipcode: 53130,
    latitude: 17.746,
    longitude: 99.968,
  },
  {
    province: "อุตรดิตถ์",
    district: "ลับแล",
    subdistrict: "นานกกก",
    zipcode: 53130,
    latitude: 17.754,
    longitude: 100.072,
  },
  {
    province: "อุตรดิตถ์",
    district: "ลับแล",
    subdistrict: "ฝายหลวง",
    zipcode: 53130,
    latitude: 17.651,
    longitude: 99.997,
  },
  {
    province: "อุตรดิตถ์",
    district: "ลับแล",
    subdistrict: "ชัยจุมพล",
    zipcode: 53130,
    latitude: 17.631,
    longitude: 100.037,
  },
  {
    province: "อุตรดิตถ์",
    district: "ลับแล",
    subdistrict: "ไผ่ล้อม",
    zipcode: 53210,
    latitude: 17.529,
    longitude: 100.035,
  },
  {
    province: "อุตรดิตถ์",
    district: "ลับแล",
    subdistrict: "ทุ่งยั้ง",
    zipcode: 53210,
    latitude: 17.58,
    longitude: 100.015,
  },
  {
    province: "อุตรดิตถ์",
    district: "ลับแล",
    subdistrict: "ด่านแม่คำมัน",
    zipcode: 53210,
    latitude: 17.547,
    longitude: 99.963,
  },
  {
    province: "อุตรดิตถ์",
    district: "ทองแสนขัน",
    subdistrict: "ผักขวง",
    zipcode: 53230,
    latitude: 17.535,
    longitude: 100.415,
  },
  {
    province: "อุตรดิตถ์",
    district: "ทองแสนขัน",
    subdistrict: "บ่อทอง",
    zipcode: 53230,
    latitude: 17.444,
    longitude: 100.356,
  },
  {
    province: "อุตรดิตถ์",
    district: "ทองแสนขัน",
    subdistrict: "ป่าคาย",
    zipcode: 53230,
    latitude: 17.495,
    longitude: 100.267,
  },
  {
    province: "อุตรดิตถ์",
    district: "ทองแสนขัน",
    subdistrict: "น้ำพี้",
    zipcode: 53230,
    latitude: 17.581,
    longitude: 100.299,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "ในเวียง",
    zipcode: 54000,
    latitude: 18.14,
    longitude: 100.142,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "นาจักร",
    zipcode: 54000,
    latitude: 18.113,
    longitude: 100.164,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "น้ำชำ",
    zipcode: 54000,
    latitude: 18.186,
    longitude: 100.255,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "ป่าแดง",
    zipcode: 54000,
    latitude: 18.063,
    longitude: 100.316,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "ทุ่งโฮ้ง",
    zipcode: 54000,
    latitude: 18.188,
    longitude: 100.196,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "เหมืองหม้อ",
    zipcode: 54000,
    latitude: 18.163,
    longitude: 100.21,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "วังธง",
    zipcode: 54000,
    latitude: 18.209,
    longitude: 100.11,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "แม่หล่าย",
    zipcode: 54000,
    latitude: 18.215,
    longitude: 100.204,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "ห้วยม้า",
    zipcode: 54000,
    latitude: 18.228,
    longitude: 100.237,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "ป่าแมต",
    zipcode: 54000,
    latitude: 18.158,
    longitude: 100.092,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "บ้านถิ่น",
    zipcode: 54000,
    latitude: 18.154,
    longitude: 100.224,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "สวนเขื่อน",
    zipcode: 54000,
    latitude: 18.128,
    longitude: 100.306,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "วังหงส์",
    zipcode: 54000,
    latitude: 18.2484255,
    longitude: 100.0971083,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "แม่คำมี",
    zipcode: 54000,
    latitude: 18.247,
    longitude: 100.217,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "ทุ่งกวาว",
    zipcode: 54000,
    latitude: 18.165,
    longitude: 100.154,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "ท่าข้าม",
    zipcode: 54000,
    latitude: 18.229,
    longitude: 100.126,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "แม่ยม",
    zipcode: 54000,
    latitude: 18.187,
    longitude: 100.157,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "ช่อแฮ",
    zipcode: 54000,
    latitude: 17.954,
    longitude: 100.323,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "ร่องฟอง",
    zipcode: 54000,
    latitude: 18.167,
    longitude: 100.193,
  },
  {
    province: "แพร่",
    district: "เมืองแพร่",
    subdistrict: "กาญจนา",
    zipcode: 54000,
    latitude: 18.123,
    longitude: 100.184,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "ร้องกวาง",
    zipcode: 54140,
    latitude: 18.351,
    longitude: 100.336,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "ร้องเข็ม",
    zipcode: 54140,
    latitude: 18.293,
    longitude: 100.313,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "น้ำเลา",
    zipcode: 54140,
    latitude: 18.254,
    longitude: 100.309,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "บ้านเวียง",
    zipcode: 54140,
    latitude: 18.208,
    longitude: 100.381,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "ทุ่งศรี",
    zipcode: 54140,
    latitude: 18.316,
    longitude: 100.34,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "แม่ยางตาล",
    zipcode: 54140,
    latitude: 18.304,
    longitude: 100.257,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "แม่ยางฮ่อ",
    zipcode: 54140,
    latitude: 18.35,
    longitude: 100.26,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "ไผ่โทน",
    zipcode: 54140,
    latitude: 18.32,
    longitude: 100.464,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "ห้วยโรง",
    zipcode: 54140,
    latitude: 18.432,
    longitude: 100.513,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "แม่ทราย",
    zipcode: 54140,
    latitude: 18.411,
    longitude: 100.314,
  },
  {
    province: "แพร่",
    district: "ร้องกวาง",
    subdistrict: "แม่ยางร้อง",
    zipcode: 54140,
    latitude: 18.396,
    longitude: 100.248,
  },
  {
    province: "แพร่",
    district: "ลอง",
    subdistrict: "ห้วยอ้อ",
    zipcode: 54150,
    latitude: 18.131,
    longitude: 99.825,
  },
  {
    province: "แพร่",
    district: "ลอง",
    subdistrict: "บ้านปิน",
    zipcode: 54150,
    latitude: 18.065,
    longitude: 99.937,
  },
  {
    province: "แพร่",
    district: "ลอง",
    subdistrict: "ต้าผามอก",
    zipcode: 54150,
    latitude: 18.151,
    longitude: 99.983,
  },
  {
    province: "แพร่",
    district: "ลอง",
    subdistrict: "เวียงต้า",
    zipcode: 54150,
    latitude: 18.303,
    longitude: 99.999,
  },
  {
    province: "แพร่",
    district: "ลอง",
    subdistrict: "ปากกาง",
    zipcode: 54150,
    latitude: 18.042,
    longitude: 99.811,
  },
  {
    province: "แพร่",
    district: "ลอง",
    subdistrict: "หัวทุ่ง",
    zipcode: 54150,
    latitude: 18.093,
    longitude: 99.776,
  },
  {
    province: "แพร่",
    district: "ลอง",
    subdistrict: "ทุ่งแล้ง",
    zipcode: 54150,
    latitude: 17.959,
    longitude: 99.752,
  },
  {
    province: "แพร่",
    district: "ลอง",
    subdistrict: "บ่อเหล็กลอง",
    zipcode: 54150,
    latitude: 18.013,
    longitude: 99.719,
  },
  {
    province: "แพร่",
    district: "ลอง",
    subdistrict: "แม่ปาน",
    zipcode: 54150,
    latitude: 17.986,
    longitude: 99.871,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "สูงเม่น",
    zipcode: 54130,
    latitude: 18.059,
    longitude: 100.087,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "น้ำชำ",
    zipcode: 54130,
    latitude: 18.034,
    longitude: 100.091,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "หัวฝาย",
    zipcode: 54130,
    latitude: 17.961,
    longitude: 100.188,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "ดอนมูล",
    zipcode: 54130,
    latitude: 18.067,
    longitude: 100.126,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "บ้านเหล่า",
    zipcode: 54130,
    latitude: 18.015,
    longitude: 100.206,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "บ้านกวาง",
    zipcode: 54130,
    latitude: 18.033,
    longitude: 100.222,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "บ้านปง",
    zipcode: 54130,
    latitude: 18.06,
    longitude: 100.026,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "บ้านกาศ",
    zipcode: 54130,
    latitude: 18.09,
    longitude: 100.168,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "ร่องกาศ",
    zipcode: 54130,
    latitude: 18.096,
    longitude: 100.126,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "สบสาย",
    zipcode: 54130,
    latitude: 18.102,
    longitude: 100.023,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "เวียงทอง",
    zipcode: 54000,
    latitude: 18.124,
    longitude: 100.046,
  },
  {
    province: "แพร่",
    district: "สูงเม่น",
    subdistrict: "พระหลวง",
    zipcode: 54130,
    latitude: 18.069,
    longitude: 100.111,
  },
  {
    province: "แพร่",
    district: "เด่นชัย",
    subdistrict: "เด่นชัย",
    zipcode: 54110,
    latitude: 17.973,
    longitude: 100.04,
  },
  {
    province: "แพร่",
    district: "เด่นชัย",
    subdistrict: "แม่จั๊วะ",
    zipcode: 54110,
    latitude: 17.975,
    longitude: 100.104,
  },
  {
    province: "แพร่",
    district: "เด่นชัย",
    subdistrict: "ไทรย้อย",
    zipcode: 54110,
    latitude: 17.892,
    longitude: 99.956,
  },
  {
    province: "แพร่",
    district: "เด่นชัย",
    subdistrict: "ห้วยไร่",
    zipcode: 54110,
    latitude: 17.898,
    longitude: 100.09,
  },
  {
    province: "แพร่",
    district: "เด่นชัย",
    subdistrict: "ปงป่าหวาย",
    zipcode: 54110,
    latitude: 18.024,
    longitude: 99.991,
  },
  {
    province: "แพร่",
    district: "สอง",
    subdistrict: "บ้านหนุน",
    zipcode: 54120,
    latitude: 18.437,
    longitude: 100.234,
  },
  {
    province: "แพร่",
    district: "สอง",
    subdistrict: "บ้านกลาง",
    zipcode: 54120,
    latitude: 18.485,
    longitude: 100.183,
  },
  {
    province: "แพร่",
    district: "สอง",
    subdistrict: "ห้วยหม้าย",
    zipcode: 54120,
    latitude: 18.418,
    longitude: 100.089,
  },
  {
    province: "แพร่",
    district: "สอง",
    subdistrict: "เตาปูน",
    zipcode: 54120,
    latitude: 18.531,
    longitude: 100.271,
  },
  {
    province: "แพร่",
    district: "สอง",
    subdistrict: "หัวเมือง",
    zipcode: 54120,
    latitude: 18.333,
    longitude: 100.181,
  },
  {
    province: "แพร่",
    district: "สอง",
    subdistrict: "สะเอียบ",
    zipcode: 54120,
    latitude: 18.709,
    longitude: 100.247,
  },
  {
    province: "แพร่",
    district: "สอง",
    subdistrict: "แดนชุมพล",
    zipcode: 54120,
    latitude: 18.365,
    longitude: 100.198,
  },
  {
    province: "แพร่",
    district: "สอง",
    subdistrict: "ทุ่งน้าว",
    zipcode: 54120,
    latitude: 18.402,
    longitude: 100.2,
  },
  {
    province: "แพร่",
    district: "วังชิ้น",
    subdistrict: "วังชิ้น",
    zipcode: 54160,
    latitude: 17.861,
    longitude: 99.647,
  },
  {
    province: "แพร่",
    district: "วังชิ้น",
    subdistrict: "สรอย",
    zipcode: 54160,
    latitude: 17.759,
    longitude: 99.425,
  },
  {
    province: "แพร่",
    district: "วังชิ้น",
    subdistrict: "แม่ป้าก",
    zipcode: 54160,
    latitude: 18.005,
    longitude: 99.638,
  },
  {
    province: "แพร่",
    district: "วังชิ้น",
    subdistrict: "นาพูน",
    zipcode: 54160,
    latitude: 17.85,
    longitude: 99.79,
  },
  {
    province: "แพร่",
    district: "วังชิ้น",
    subdistrict: "แม่พุง",
    zipcode: 54160,
    latitude: 17.866,
    longitude: 99.539,
  },
  {
    province: "แพร่",
    district: "วังชิ้น",
    subdistrict: "ป่าสัก",
    zipcode: 54160,
    latitude: 17.826,
    longitude: 99.472,
  },
  {
    province: "แพร่",
    district: "วังชิ้น",
    subdistrict: "แม่เกิ๋ง",
    zipcode: 54160,
    latitude: 17.945,
    longitude: 99.556,
  },
  {
    province: "แพร่",
    district: "หนองม่วงไข่",
    subdistrict: "แม่คำมี",
    zipcode: 54170,
    latitude: 18.271,
    longitude: 100.218,
  },
  {
    province: "แพร่",
    district: "หนองม่วงไข่",
    subdistrict: "หนองม่วงไข่",
    zipcode: 54170,
    latitude: 18.295,
    longitude: 100.208,
  },
  {
    province: "แพร่",
    district: "หนองม่วงไข่",
    subdistrict: "น้ำรัด",
    zipcode: 54170,
    latitude: 18.268,
    longitude: 100.138,
  },
  {
    province: "แพร่",
    district: "หนองม่วงไข่",
    subdistrict: "วังหลวง",
    zipcode: 54170,
    latitude: 18.294,
    longitude: 100.115,
  },
  {
    province: "แพร่",
    district: "หนองม่วงไข่",
    subdistrict: "ตำหนักธรรม",
    zipcode: 54170,
    latitude: 18.271,
    longitude: 100.266,
  },
  {
    province: "แพร่",
    district: "หนองม่วงไข่",
    subdistrict: "ทุ่งแค้ว",
    zipcode: 54170,
    latitude: 18.32,
    longitude: 100.12,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "ในเวียง",
    zipcode: 55000,
    latitude: 18.793,
    longitude: 100.786,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "บ่อ",
    zipcode: 55000,
    latitude: 18.97,
    longitude: 100.721,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "ผาสิงห์",
    zipcode: 55000,
    latitude: 18.85,
    longitude: 100.749,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "ไชยสถาน",
    zipcode: 55000,
    latitude: 18.783,
    longitude: 100.731,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "ถืมตอง",
    zipcode: 55000,
    latitude: 18.797,
    longitude: 100.712,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "เรือง",
    zipcode: 55000,
    latitude: 18.783,
    longitude: 100.615,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "นาซาว",
    zipcode: 55000,
    latitude: 18.7135143,
    longitude: 100.6103845,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "ดู่ใต้",
    zipcode: 55000,
    latitude: 18.724,
    longitude: 100.739,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "กองควาย",
    zipcode: 55000,
    latitude: 18.686,
    longitude: 100.732,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "บ่อสวก",
    zipcode: 55000,
    latitude: 18.7336325,
    longitude: 100.6122483,
  },
  {
    province: "น่าน",
    district: "เมืองน่าน",
    subdistrict: "สะเนียน",
    zipcode: 55000,
    latitude: 18.912,
    longitude: 100.592,
  },
  {
    province: "น่าน",
    district: "แม่จริม",
    subdistrict: "หนองแดง",
    zipcode: 55170,
    latitude: 18.736,
    longitude: 101.025,
  },
  {
    province: "น่าน",
    district: "แม่จริม",
    subdistrict: "หมอเมือง",
    zipcode: 55170,
    latitude: 18.687,
    longitude: 100.975,
  },
  {
    province: "น่าน",
    district: "แม่จริม",
    subdistrict: "น้ำพาง",
    zipcode: 55170,
    latitude: 18.611,
    longitude: 101.057,
  },
  {
    province: "น่าน",
    district: "แม่จริม",
    subdistrict: "น้ำปาย",
    zipcode: 55170,
    latitude: 18.641,
    longitude: 100.961,
  },
  {
    province: "น่าน",
    district: "แม่จริม",
    subdistrict: "แม่จริม",
    zipcode: 55170,
    latitude: 18.824,
    longitude: 101.045,
  },
  {
    province: "น่าน",
    district: "บ้านหลวง",
    subdistrict: "บ้านฟ้า",
    zipcode: 55190,
    latitude: 18.782,
    longitude: 100.429,
  },
  {
    province: "น่าน",
    district: "บ้านหลวง",
    subdistrict: "ป่าคาหลวง",
    zipcode: 55190,
    latitude: 18.858,
    longitude: 100.405,
  },
  {
    province: "น่าน",
    district: "บ้านหลวง",
    subdistrict: "สวด",
    zipcode: 55190,
    latitude: 18.849,
    longitude: 100.484,
  },
  {
    province: "น่าน",
    district: "บ้านหลวง",
    subdistrict: "บ้านพี้",
    zipcode: 55190,
    latitude: 18.907,
    longitude: 100.425,
  },
  {
    province: "น่าน",
    district: "นาน้อย",
    subdistrict: "นาน้อย",
    zipcode: 55150,
    latitude: 18.313,
    longitude: 100.681,
  },
  {
    province: "น่าน",
    district: "นาน้อย",
    subdistrict: "เชียงของ",
    zipcode: 55150,
    latitude: 18.273,
    longitude: 100.884,
  },
  {
    province: "น่าน",
    district: "นาน้อย",
    subdistrict: "ศรีษะเกษ",
    zipcode: 55150,
    latitude: 18.369,
    longitude: 100.771,
  },
  {
    province: "น่าน",
    district: "นาน้อย",
    subdistrict: "สถาน",
    zipcode: 55150,
    latitude: 18.236,
    longitude: 100.745,
  },
  {
    province: "น่าน",
    district: "นาน้อย",
    subdistrict: "สันทะ",
    zipcode: 55150,
    latitude: 18.276,
    longitude: 100.567,
  },
  {
    province: "น่าน",
    district: "นาน้อย",
    subdistrict: "บัวใหญ่",
    zipcode: 55150,
    latitude: 18.35,
    longitude: 100.57,
  },
  {
    province: "น่าน",
    district: "นาน้อย",
    subdistrict: "น้ำตก",
    zipcode: 55150,
    latitude: 18.394,
    longitude: 100.629,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "ปัว",
    zipcode: 55120,
    latitude: 19.168,
    longitude: 100.903,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "แงง",
    zipcode: 55120,
    latitude: 19.197,
    longitude: 100.849,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "สถาน",
    zipcode: 55120,
    latitude: 19.224,
    longitude: 100.951,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "ศิลาแลง",
    zipcode: 55120,
    latitude: 19.148,
    longitude: 100.967,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "ศิลาเพชร",
    zipcode: 55120,
    latitude: 19.097,
    longitude: 100.957,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "อวน",
    zipcode: 55120,
    latitude: 19.029,
    longitude: 100.998,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "ไชยวัฒนา",
    zipcode: 55120,
    latitude: 19.222,
    longitude: 100.895,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "เจดีย์ชัย",
    zipcode: 55120,
    latitude: 19.1907607,
    longitude: 100.7979617,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "ภูคา",
    zipcode: 55120,
    latitude: 19.254,
    longitude: 101.091,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "สกาด",
    zipcode: 55120,
    latitude: 19.27,
    longitude: 101.031,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "ป่ากลาง",
    zipcode: 55120,
    latitude: 19.13,
    longitude: 100.9,
  },
  {
    province: "น่าน",
    district: "ปัว",
    subdistrict: "วรนคร",
    zipcode: 55120,
    latitude: 19.176,
    longitude: 100.947,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "ริม",
    zipcode: 55140,
    latitude: 19.119,
    longitude: 100.798,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "ป่าคา",
    zipcode: 55140,
    latitude: 19.107,
    longitude: 100.665,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "ผาตอ",
    zipcode: 55140,
    latitude: 19.214,
    longitude: 100.774,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "ยม",
    zipcode: 55140,
    latitude: 19.061,
    longitude: 100.926,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "ตาลชุม",
    zipcode: 55140,
    latitude: 19.022,
    longitude: 100.829,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "ศรีภูมิ",
    zipcode: 55140,
    latitude: 19.069,
    longitude: 100.726,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "จอมพระ",
    zipcode: 55140,
    latitude: 19.103,
    longitude: 100.865,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "แสนทอง",
    zipcode: 55140,
    latitude: 19.14,
    longitude: 100.751,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "ท่าวังผา",
    zipcode: 55140,
    latitude: 19.128,
    longitude: 100.831,
  },
  {
    province: "น่าน",
    district: "ท่าวังผา",
    subdistrict: "ผาทอง",
    zipcode: 55140,
    latitude: 19.2308247,
    longitude: 100.6083149,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "กลางเวียง",
    zipcode: 55110,
    latitude: 18.577,
    longitude: 100.74,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "ขึ่ง",
    zipcode: 55110,
    latitude: 18.53,
    longitude: 100.834,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "ไหล่น่าน",
    zipcode: 55110,
    latitude: 18.59,
    longitude: 100.93,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "ตาลชุม",
    zipcode: 55110,
    latitude: 18.637,
    longitude: 100.783,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "นาเหลือง",
    zipcode: 55110,
    latitude: 18.679,
    longitude: 100.837,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "ส้าน",
    zipcode: 55110,
    latitude: 18.464,
    longitude: 100.729,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "น้ำมวบ",
    zipcode: 55110,
    latitude: 18.444,
    longitude: 100.94,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "น้ำปั้ว",
    zipcode: 55110,
    latitude: 18.639,
    longitude: 100.725,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "ยาบหัวนา",
    zipcode: 55110,
    latitude: 18.611,
    longitude: 100.471,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "ปงสนุก",
    zipcode: 55110,
    latitude: 18.6,
    longitude: 100.648,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "อ่ายนาไลย",
    zipcode: 55110,
    latitude: 18.514,
    longitude: 100.541,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "ส้านนาหนองใหม่",
    zipcode: 55110,
    latitude: 18.531,
    longitude: 100.996,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "แม่ขะนิง",
    zipcode: 55110,
    latitude: 18.718,
    longitude: 100.552,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "แม่สาคร",
    zipcode: 55110,
    latitude: 18.474,
    longitude: 100.637,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "จอมจันทร์",
    zipcode: 55110,
    latitude: 18.637,
    longitude: 100.896,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "แม่สา",
    zipcode: 55110,
    latitude: 18.564,
    longitude: 100.66,
  },
  {
    province: "น่าน",
    district: "เวียงสา",
    subdistrict: "ทุ่งศรีทอง",
    zipcode: 55110,
    latitude: 18.661,
    longitude: 100.694,
  },
  {
    province: "น่าน",
    district: "ทุ่งช้าง",
    subdistrict: "ปอน",
    zipcode: 55130,
    latitude: 19.535,
    longitude: 100.975,
  },
  {
    province: "น่าน",
    district: "ทุ่งช้าง",
    subdistrict: "งอบ",
    zipcode: 55130,
    latitude: 19.514,
    longitude: 100.849,
  },
  {
    province: "น่าน",
    district: "ทุ่งช้าง",
    subdistrict: "และ",
    zipcode: 55130,
    latitude: 19.447,
    longitude: 100.811,
  },
  {
    province: "น่าน",
    district: "ทุ่งช้าง",
    subdistrict: "ทุ่งช้าง",
    zipcode: 55130,
    latitude: 19.39,
    longitude: 100.834,
  },
  {
    province: "น่าน",
    district: "เชียงกลาง",
    subdistrict: "เชียงกลาง",
    zipcode: 55160,
    latitude: 19.32,
    longitude: 100.97,
  },
  {
    province: "น่าน",
    district: "เชียงกลาง",
    subdistrict: "เปือ",
    zipcode: 55160,
    latitude: 19.324,
    longitude: 100.843,
  },
  {
    province: "น่าน",
    district: "เชียงกลาง",
    subdistrict: "เชียงคาน",
    zipcode: 55160,
    latitude: 19.246,
    longitude: 100.855,
  },
  {
    province: "น่าน",
    district: "เชียงกลาง",
    subdistrict: "พระธาตุ",
    zipcode: 55160,
    latitude: 19.338,
    longitude: 100.925,
  },
  {
    province: "น่าน",
    district: "เชียงกลาง",
    subdistrict: "พญาแก้ว",
    zipcode: 55160,
    latitude: 19.27,
    longitude: 100.909,
  },
  {
    province: "น่าน",
    district: "เชียงกลาง",
    subdistrict: "พระพุทธบาท",
    zipcode: 55160,
    latitude: 19.258,
    longitude: 100.814,
  },
  {
    province: "น่าน",
    district: "นาหมื่น",
    subdistrict: "นาทะนุง",
    zipcode: 55180,
    latitude: 18.117,
    longitude: 100.615,
  },
  {
    province: "น่าน",
    district: "นาหมื่น",
    subdistrict: "บ่อแก้ว",
    zipcode: 55180,
    latitude: 18.139,
    longitude: 100.832,
  },
  {
    province: "น่าน",
    district: "นาหมื่น",
    subdistrict: "เมืองลี",
    zipcode: 55180,
    latitude: 18.226,
    longitude: 100.505,
  },
  {
    province: "น่าน",
    district: "นาหมื่น",
    subdistrict: "ปิงหลวง",
    zipcode: 55180,
    latitude: 18.115,
    longitude: 100.486,
  },
  {
    province: "น่าน",
    district: "สันติสุข",
    subdistrict: "ดู่พงษ์",
    zipcode: 55210,
    latitude: 18.898,
    longitude: 100.902,
  },
  {
    province: "น่าน",
    district: "สันติสุข",
    subdistrict: "ป่าแลวหลวง",
    zipcode: 55210,
    latitude: 18.957,
    longitude: 100.95,
  },
  {
    province: "น่าน",
    district: "สันติสุข",
    subdistrict: "พงษ์",
    zipcode: 55210,
    latitude: 18.899,
    longitude: 100.983,
  },
  {
    province: "น่าน",
    district: "บ่อเกลือ",
    subdistrict: "บ่อเกลือเหนือ",
    zipcode: 55220,
    latitude: 19.297,
    longitude: 101.176,
  },
  {
    province: "น่าน",
    district: "บ่อเกลือ",
    subdistrict: "บ่อเกลือใต้",
    zipcode: 55220,
    latitude: 19.127,
    longitude: 101.13,
  },
  {
    province: "น่าน",
    district: "บ่อเกลือ",
    subdistrict: "ภูฟ้า",
    zipcode: 55220,
    latitude: 18.973,
    longitude: 101.201,
  },
  {
    province: "น่าน",
    district: "บ่อเกลือ",
    subdistrict: "ดงพญา",
    zipcode: 55220,
    latitude: 19.22,
    longitude: 101.198,
  },
  {
    province: "น่าน",
    district: "สองแคว",
    subdistrict: "นาไร่หลวง",
    zipcode: 55160,
    latitude: 19.339,
    longitude: 100.704,
  },
  {
    province: "น่าน",
    district: "สองแคว",
    subdistrict: "ชนแดน",
    zipcode: 55160,
    latitude: 19.462,
    longitude: 100.691,
  },
  {
    province: "น่าน",
    district: "สองแคว",
    subdistrict: "ยอด",
    zipcode: 55160,
    latitude: 19.384,
    longitude: 100.567,
  },
  {
    province: "น่าน",
    district: "ภูเพียง",
    subdistrict: "ม่วงตึ๊ด",
    zipcode: 55000,
    latitude: 18.756,
    longitude: 100.816,
  },
  {
    province: "น่าน",
    district: "ภูเพียง",
    subdistrict: "นาปัง",
    zipcode: 55000,
    latitude: 18.696,
    longitude: 100.801,
  },
  {
    province: "น่าน",
    district: "ภูเพียง",
    subdistrict: "น้ำแก่น",
    zipcode: 55000,
    latitude: 18.698,
    longitude: 100.88,
  },
  {
    province: "น่าน",
    district: "ภูเพียง",
    subdistrict: "น้ำเกี๋ยน",
    zipcode: 55000,
    latitude: 18.737,
    longitude: 100.841,
  },
  {
    province: "น่าน",
    district: "ภูเพียง",
    subdistrict: "เมืองจัง",
    zipcode: 55000,
    latitude: 18.878,
    longitude: 100.83,
  },
  {
    province: "น่าน",
    district: "ภูเพียง",
    subdistrict: "ท่าน้าว",
    zipcode: 55000,
    latitude: 18.731,
    longitude: 100.791,
  },
  {
    province: "น่าน",
    district: "ภูเพียง",
    subdistrict: "ฝายแก้ว",
    zipcode: 55000,
    latitude: 18.803,
    longitude: 100.933,
  },
  {
    province: "น่าน",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ห้วยโก๋น",
    zipcode: 55130,
    latitude: 19.559,
    longitude: 101.046,
  },
  {
    province: "น่าน",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ขุนน่าน",
    zipcode: 55130,
    latitude: 19.45,
    longitude: 101.128,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "เวียง",
    zipcode: 56000,
    latitude: 19.172,
    longitude: 99.894,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "แม่ต๋ำ",
    zipcode: 56000,
    latitude: 19.142,
    longitude: 99.912,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "แม่นาเรือ",
    zipcode: 56000,
    latitude: 19.088,
    longitude: 99.797,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "บ้านตุ่น",
    zipcode: 56000,
    latitude: 19.141,
    longitude: 99.823,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "บ้านต๊ำ",
    zipcode: 56000,
    latitude: 19.216,
    longitude: 99.756,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "บ้านต๋อม",
    zipcode: 56000,
    latitude: 19.198,
    longitude: 99.8,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "แม่ปืม",
    zipcode: 56000,
    latitude: 19.3208545,
    longitude: 99.7941789,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "แม่กา",
    zipcode: 56000,
    latitude: 19.049,
    longitude: 99.917,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "บ้านใหม่",
    zipcode: 56000,
    latitude: 19.295,
    longitude: 99.733,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "จำป่าหวาย",
    zipcode: 56000,
    latitude: 19.105,
    longitude: 99.945,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "ท่าวังทอง",
    zipcode: 56000,
    latitude: 19.202,
    longitude: 99.927,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "แม่ใส",
    zipcode: 56000,
    latitude: 19.127,
    longitude: 99.886,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "บ้านสาง",
    zipcode: 56000,
    latitude: 19.156,
    longitude: 99.811,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "ท่าจำปี",
    zipcode: 56000,
    latitude: 19.245,
    longitude: 99.815,
  },
  {
    province: "พะเยา",
    district: "เมืองพะเยา",
    subdistrict: "สันป่าม่วง",
    zipcode: 56000,
    latitude: 19.166,
    longitude: 99.759,
  },
  {
    province: "พะเยา",
    district: "จุน",
    subdistrict: "ห้วยข้าวก่ำ",
    zipcode: 56150,
    latitude: 19.352,
    longitude: 100.12,
  },
  {
    province: "พะเยา",
    district: "จุน",
    subdistrict: "จุน",
    zipcode: 56150,
    latitude: 19.282,
    longitude: 100.152,
  },
  {
    province: "พะเยา",
    district: "จุน",
    subdistrict: "ลอ",
    zipcode: 56150,
    latitude: 19.414,
    longitude: 100.073,
  },
  {
    province: "พะเยา",
    district: "จุน",
    subdistrict: "หงส์หิน",
    zipcode: 56150,
    latitude: 19.507,
    longitude: 100.103,
  },
  {
    province: "พะเยา",
    district: "จุน",
    subdistrict: "ทุ่งรวงทอง",
    zipcode: 56150,
    latitude: 19.436,
    longitude: 100.166,
  },
  {
    province: "พะเยา",
    district: "จุน",
    subdistrict: "ห้วยยางขาม",
    zipcode: 56150,
    latitude: 19.378,
    longitude: 100.163,
  },
  {
    province: "พะเยา",
    district: "จุน",
    subdistrict: "พระธาตุขิงแกง",
    zipcode: 56150,
    latitude: 19.273,
    longitude: 100.186,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "หย่วน",
    zipcode: 56110,
    latitude: 19.536,
    longitude: 100.293,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "น้ำแวน",
    zipcode: 56110,
    latitude: 19.477,
    longitude: 100.244,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "เวียง",
    zipcode: 56110,
    latitude: 19.495,
    longitude: 100.327,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "ฝายกวาง",
    zipcode: 56110,
    latitude: 19.394,
    longitude: 100.244,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "เจดีย์คำ",
    zipcode: 56110,
    latitude: 19.537,
    longitude: 100.337,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "ร่มเย็น",
    zipcode: 56110,
    latitude: 19.511,
    longitude: 100.504,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "เชียงบาน",
    zipcode: 56110,
    latitude: 19.586,
    longitude: 100.234,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "แม่ลาว",
    zipcode: 56110,
    latitude: 19.432,
    longitude: 100.451,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "อ่างทอง",
    zipcode: 56110,
    latitude: 19.552,
    longitude: 100.201,
  },
  {
    province: "พะเยา",
    district: "เชียงคำ",
    subdistrict: "ทุ่งผาสุข",
    zipcode: 56110,
    latitude: 19.473,
    longitude: 100.291,
  },
  {
    province: "พะเยา",
    district: "เชียงม่วน",
    subdistrict: "เชียงม่วน",
    zipcode: 56160,
    latitude: 18.888,
    longitude: 100.334,
  },
  {
    province: "พะเยา",
    district: "เชียงม่วน",
    subdistrict: "บ้านมาง",
    zipcode: 56160,
    latitude: 18.869,
    longitude: 100.22,
  },
  {
    province: "พะเยา",
    district: "เชียงม่วน",
    subdistrict: "สระ",
    zipcode: 56160,
    latitude: 18.992,
    longitude: 100.404,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "ดอกคำใต้",
    zipcode: 56120,
    latitude: 19.152,
    longitude: 99.955,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "ดอนศรีชุม",
    zipcode: 56120,
    latitude: 19.157,
    longitude: 100.026,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "บ้านถ้ำ",
    zipcode: 56120,
    latitude: 19.105,
    longitude: 100.074,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "บ้านปิน",
    zipcode: 56120,
    latitude: 18.988,
    longitude: 100.034,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "ห้วยลาน",
    zipcode: 56120,
    latitude: 19.37,
    longitude: 100.04,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "สันโค้ง",
    zipcode: 56120,
    latitude: 19.195,
    longitude: 100.034,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "ป่าซาง",
    zipcode: 56120,
    latitude: 19.284,
    longitude: 100.084,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "หนองหล่ม",
    zipcode: 56120,
    latitude: 18.925,
    longitude: 100.096,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "ดงสุวรรณ",
    zipcode: 56120,
    latitude: 19.242,
    longitude: 100.053,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "บุญเกิด",
    zipcode: 56120,
    latitude: 19.134,
    longitude: 99.998,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "สว่างอารมณ์",
    zipcode: 56120,
    latitude: 19.194,
    longitude: 99.982,
  },
  {
    province: "พะเยา",
    district: "ดอกคำใต้",
    subdistrict: "คือเวียง",
    zipcode: 56120,
    latitude: 19.085,
    longitude: 100.014,
  },
  {
    province: "พะเยา",
    district: "ปง",
    subdistrict: "ปง",
    zipcode: 56140,
    latitude: 19.102,
    longitude: 100.205,
  },
  {
    province: "พะเยา",
    district: "ปง",
    subdistrict: "ควร",
    zipcode: 56140,
    latitude: 19.182,
    longitude: 100.374,
  },
  {
    province: "พะเยา",
    district: "ปง",
    subdistrict: "ออย",
    zipcode: 56140,
    latitude: 19.211,
    longitude: 100.329,
  },
  {
    province: "พะเยา",
    district: "ปง",
    subdistrict: "งิม",
    zipcode: 56140,
    latitude: 19.284,
    longitude: 100.403,
  },
  {
    province: "พะเยา",
    district: "ปง",
    subdistrict: "ผาช้างน้อย",
    zipcode: 56140,
    latitude: 19.282,
    longitude: 100.502,
  },
  {
    province: "พะเยา",
    district: "ปง",
    subdistrict: "นาปรัง",
    zipcode: 56140,
    latitude: 19.219,
    longitude: 100.242,
  },
  {
    province: "พะเยา",
    district: "ปง",
    subdistrict: "ขุนควร",
    zipcode: 56140,
    latitude: 19.111,
    longitude: 100.457,
  },
  {
    province: "พะเยา",
    district: "แม่ใจ",
    subdistrict: "แม่ใจ",
    zipcode: 56130,
    latitude: 19.33,
    longitude: 99.846,
  },
  {
    province: "พะเยา",
    district: "แม่ใจ",
    subdistrict: "ศรีถ้อย",
    zipcode: 56130,
    latitude: 19.357,
    longitude: 99.725,
  },
  {
    province: "พะเยา",
    district: "แม่ใจ",
    subdistrict: "แม่สุก",
    zipcode: 56130,
    latitude: 19.313,
    longitude: 99.796,
  },
  {
    province: "พะเยา",
    district: "แม่ใจ",
    subdistrict: "ป่าแฝก",
    zipcode: 56130,
    latitude: 19.426,
    longitude: 99.763,
  },
  {
    province: "พะเยา",
    district: "แม่ใจ",
    subdistrict: "บ้านเหล่า",
    zipcode: 56130,
    latitude: 19.399,
    longitude: 99.859,
  },
  {
    province: "พะเยา",
    district: "แม่ใจ",
    subdistrict: "เจริญราษฎร์",
    zipcode: 56130,
    latitude: 19.396,
    longitude: 99.756,
  },
  {
    province: "พะเยา",
    district: "ภูซาง",
    subdistrict: "ภูซาง",
    zipcode: 56110,
    latitude: 19.671,
    longitude: 100.378,
  },
  {
    province: "พะเยา",
    district: "ภูซาง",
    subdistrict: "ป่าสัก",
    zipcode: 56110,
    latitude: 19.622,
    longitude: 100.37,
  },
  {
    province: "พะเยา",
    district: "ภูซาง",
    subdistrict: "ทุ่งกล้วย",
    zipcode: 56110,
    latitude: 19.606,
    longitude: 100.417,
  },
  {
    province: "พะเยา",
    district: "ภูซาง",
    subdistrict: "เชียงแรง",
    zipcode: 56110,
    latitude: 19.616,
    longitude: 100.289,
  },
  {
    province: "พะเยา",
    district: "ภูซาง",
    subdistrict: "สบบง",
    zipcode: 56110,
    latitude: 19.57,
    longitude: 100.317,
  },
  {
    province: "พะเยา",
    district: "ภูกามยาว",
    subdistrict: "ห้วยแก้ว",
    zipcode: 56000,
    latitude: 19.311,
    longitude: 99.999,
  },
  {
    province: "พะเยา",
    district: "ภูกามยาว",
    subdistrict: "ดงเจน",
    zipcode: 56000,
    latitude: 19.24,
    longitude: 99.952,
  },
  {
    province: "พะเยา",
    district: "ภูกามยาว",
    subdistrict: "แม่อิง",
    zipcode: 56000,
    latitude: 19.241,
    longitude: 99.989,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "เวียง",
    zipcode: 57000,
    latitude: 19.907,
    longitude: 99.832,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "รอบเวียง",
    zipcode: 57000,
    latitude: 19.898,
    longitude: 99.803,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "บ้านดู่",
    zipcode: 57100,
    latitude: 19.971,
    longitude: 99.847,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "นางแล",
    zipcode: 57100,
    latitude: 20.048,
    longitude: 99.876,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "แม่ข้าวต้ม",
    zipcode: 57100,
    latitude: 20.021,
    longitude: 99.922,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "แม่ยาว",
    zipcode: 57100,
    latitude: 19.991,
    longitude: 99.729,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "สันทราย",
    zipcode: 57000,
    latitude: 19.855,
    longitude: 99.805,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "แม่กรณ์",
    zipcode: 57000,
    latitude: 19.862,
    longitude: 99.698,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "ห้วยชมภู",
    zipcode: 57000,
    latitude: 20.015,
    longitude: 99.506,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "ห้วยสัก",
    zipcode: 57000,
    latitude: 19.756,
    longitude: 99.872,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "ริมกก",
    zipcode: 57100,
    latitude: 19.937,
    longitude: 99.887,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "ดอยลาน",
    zipcode: 57000,
    latitude: 19.682,
    longitude: 99.945,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "ป่าอ้อดอนชัย",
    zipcode: 57000,
    latitude: 19.768,
    longitude: 99.819,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "ท่าสาย",
    zipcode: 57000,
    latitude: 19.837,
    longitude: 99.851,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "ดอยฮาง",
    zipcode: 57000,
    latitude: 19.931,
    longitude: 99.713,
  },
  {
    province: "เชียงราย",
    district: "เมืองเชียงราย",
    subdistrict: "ท่าสุด",
    zipcode: 57100,
    latitude: 20.0796233,
    longitude: 99.8306232,
  },
  {
    province: "เชียงราย",
    district: "เวียงชัย",
    subdistrict: "เวียงชัย",
    zipcode: 57210,
    latitude: 19.866,
    longitude: 99.912,
  },
  {
    province: "เชียงราย",
    district: "เวียงชัย",
    subdistrict: "ผางาม",
    zipcode: 57210,
    latitude: 19.882,
    longitude: 100.046,
  },
  {
    province: "เชียงราย",
    district: "เวียงชัย",
    subdistrict: "เวียงเหนือ",
    zipcode: 57210,
    latitude: 19.932,
    longitude: 99.94,
  },
  {
    province: "เชียงราย",
    district: "เวียงชัย",
    subdistrict: "ดอนศิลา",
    zipcode: 57210,
    latitude: 19.805,
    longitude: 100.005,
  },
  {
    province: "เชียงราย",
    district: "เวียงชัย",
    subdistrict: "เมืองชุม",
    zipcode: 57210,
    latitude: 19.904,
    longitude: 99.974,
  },
  {
    province: "เชียงราย",
    district: "เชียงของ",
    subdistrict: "เวียง",
    zipcode: 57140,
    latitude: 20.266,
    longitude: 100.369,
  },
  {
    province: "เชียงราย",
    district: "เชียงของ",
    subdistrict: "สถาน",
    zipcode: 57140,
    latitude: 20.202,
    longitude: 100.364,
  },
  {
    province: "เชียงราย",
    district: "เชียงของ",
    subdistrict: "ครึ่ง",
    zipcode: 57140,
    latitude: 20.058,
    longitude: 100.402,
  },
  {
    province: "เชียงราย",
    district: "เชียงของ",
    subdistrict: "บุญเรือง",
    zipcode: 57140,
    latitude: 19.989,
    longitude: 100.347,
  },
  {
    province: "เชียงราย",
    district: "เชียงของ",
    subdistrict: "ห้วยซ้อ",
    zipcode: 57140,
    latitude: 20.061,
    longitude: 100.284,
  },
  {
    province: "เชียงราย",
    district: "เชียงของ",
    subdistrict: "ศรีดอนชัย",
    zipcode: 57140,
    latitude: 20.15,
    longitude: 100.324,
  },
  {
    province: "เชียงราย",
    district: "เชียงของ",
    subdistrict: "ริมโขง",
    zipcode: 57140,
    latitude: 20.361,
    longitude: 100.313,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "เวียง",
    zipcode: 57160,
    latitude: 19.692,
    longitude: 100.213,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "งิ้ว",
    zipcode: 57160,
    latitude: 19.697,
    longitude: 100.096,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "ปล้อง",
    zipcode: 57230,
    latitude: 19.658,
    longitude: 100.062,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "แม่ลอย",
    zipcode: 57230,
    latitude: 19.573,
    longitude: 100.063,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "เชียงเคี่ยน",
    zipcode: 57230,
    latitude: 19.625,
    longitude: 99.99,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "ตับเต่า",
    zipcode: 57160,
    latitude: 19.787,
    longitude: 100.355,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "หงาว",
    zipcode: 57160,
    latitude: 19.678,
    longitude: 100.283,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "สันทรายงาม",
    zipcode: 57160,
    latitude: 19.717,
    longitude: 100.18,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "ศรีดอนไชย",
    zipcode: 57230,
    latitude: 19.609,
    longitude: 100.092,
  },
  {
    province: "เชียงราย",
    district: "เทิง",
    subdistrict: "หนองแรด",
    zipcode: 57230,
    latitude: 19.607,
    longitude: 100.143,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "สันมะเค็ด",
    zipcode: 57120,
    latitude: 19.609,
    longitude: 99.864,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "แม่อ้อ",
    zipcode: 57120,
    latitude: 19.672,
    longitude: 99.821,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "ธารทอง",
    zipcode: 57250,
    latitude: 19.71,
    longitude: 99.723,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "สันติสุข",
    zipcode: 57120,
    latitude: 19.575,
    longitude: 99.765,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "ดอยงาม",
    zipcode: 57120,
    latitude: 19.548,
    longitude: 99.808,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "หัวง้ม",
    zipcode: 57120,
    latitude: 19.538,
    longitude: 99.771,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "เจริญเมือง",
    zipcode: 57120,
    latitude: 19.616,
    longitude: 99.746,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "ป่าหุ่ง",
    zipcode: 57120,
    latitude: 19.495,
    longitude: 99.648,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "ม่วงคำ",
    zipcode: 57120,
    latitude: 19.49,
    longitude: 99.711,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "ทรายขาว",
    zipcode: 57120,
    latitude: 19.663,
    longitude: 99.733,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "สันกลาง",
    zipcode: 57120,
    latitude: 19.607,
    longitude: 99.684,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "แม่เย็น",
    zipcode: 57280,
    latitude: 19.454,
    longitude: 99.732,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "เมืองพาน",
    zipcode: 57120,
    latitude: 19.554,
    longitude: 99.746,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "ทานตะวัน",
    zipcode: 57280,
    latitude: 19.47,
    longitude: 99.832,
  },
  {
    province: "เชียงราย",
    district: "พาน",
    subdistrict: "เวียงห้าว",
    zipcode: 57120,
    latitude: 19.524,
    longitude: 99.88,
  },
  {
    province: "เชียงราย",
    district: "ป่าแดด",
    subdistrict: "ป่าแดด",
    zipcode: 57190,
    latitude: 19.507,
    longitude: 100.015,
  },
  {
    province: "เชียงราย",
    district: "ป่าแดด",
    subdistrict: "ป่าแงะ",
    zipcode: 57190,
    latitude: 19.579,
    longitude: 99.933,
  },
  {
    province: "เชียงราย",
    district: "ป่าแดด",
    subdistrict: "สันมะค่า",
    zipcode: 57190,
    latitude: 19.466,
    longitude: 100.048,
  },
  {
    province: "เชียงราย",
    district: "ป่าแดด",
    subdistrict: "โรงช้าง",
    zipcode: 57190,
    latitude: 19.494,
    longitude: 99.947,
  },
  {
    province: "เชียงราย",
    district: "ป่าแดด",
    subdistrict: "ศรีโพธิ์เงิน",
    zipcode: 57190,
    latitude: 19.435,
    longitude: 99.934,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "แม่จัน",
    zipcode: 57110,
    latitude: 20.138,
    longitude: 99.87,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "จันจว้า",
    zipcode: 57270,
    latitude: 20.239,
    longitude: 99.982,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "แม่คำ",
    zipcode: 57240,
    latitude: 20.251,
    longitude: 99.902,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "ป่าซาง",
    zipcode: 57110,
    latitude: 20.181,
    longitude: 99.846,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "สันทราย",
    zipcode: 57110,
    latitude: 20.171,
    longitude: 99.891,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "ท่าข้าวเปลือก",
    zipcode: 57110,
    latitude: 20.163,
    longitude: 100.018,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "ป่าตึง",
    zipcode: 57110,
    latitude: 20.134,
    longitude: 99.77,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "แม่ไร่",
    zipcode: 57240,
    latitude: 20.256,
    longitude: 99.864,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "ศรีค้ำ",
    zipcode: 57110,
    latitude: 20.221,
    longitude: 99.797,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "จันจว้าใต้",
    zipcode: 57270,
    latitude: 20.239,
    longitude: 99.936,
  },
  {
    province: "เชียงราย",
    district: "แม่จัน",
    subdistrict: "จอมสวรรค์",
    zipcode: 57110,
    latitude: 20.17,
    longitude: 99.95,
  },
  {
    province: "เชียงราย",
    district: "เชียงแสน",
    subdistrict: "เวียง",
    zipcode: 57150,
    latitude: 20.305,
    longitude: 100.081,
  },
  {
    province: "เชียงราย",
    district: "เชียงแสน",
    subdistrict: "ป่าสัก",
    zipcode: 57150,
    latitude: 20.28,
    longitude: 100.003,
  },
  {
    province: "เชียงราย",
    district: "เชียงแสน",
    subdistrict: "บ้านแซว",
    zipcode: 57150,
    latitude: 20.212,
    longitude: 100.265,
  },
  {
    province: "เชียงราย",
    district: "เชียงแสน",
    subdistrict: "ศรีดอนมูล",
    zipcode: 57150,
    latitude: 20.336,
    longitude: 100.002,
  },
  {
    province: "เชียงราย",
    district: "เชียงแสน",
    subdistrict: "แม่เงิน",
    zipcode: 57150,
    latitude: 20.298,
    longitude: 100.262,
  },
  {
    province: "เชียงราย",
    district: "เชียงแสน",
    subdistrict: "โยนก",
    zipcode: 57150,
    latitude: 20.238,
    longitude: 100.062,
  },
  {
    province: "เชียงราย",
    district: "แม่สาย",
    subdistrict: "แม่สาย",
    zipcode: 57130,
    latitude: 20.431,
    longitude: 99.913,
  },
  {
    province: "เชียงราย",
    district: "แม่สาย",
    subdistrict: "ห้วยไคร้",
    zipcode: 57220,
    latitude: 20.286,
    longitude: 99.87,
  },
  {
    province: "เชียงราย",
    district: "แม่สาย",
    subdistrict: "เกาะช้าง",
    zipcode: 57130,
    latitude: 20.398,
    longitude: 100.01,
  },
  {
    province: "เชียงราย",
    district: "แม่สาย",
    subdistrict: "โป่งผา",
    zipcode: 57130,
    latitude: 20.37,
    longitude: 99.894,
  },
  {
    province: "เชียงราย",
    district: "แม่สาย",
    subdistrict: "ศรีเมืองชุม",
    zipcode: 57130,
    latitude: 20.378,
    longitude: 99.957,
  },
  {
    province: "เชียงราย",
    district: "แม่สาย",
    subdistrict: "เวียงพางคำ",
    zipcode: 57130,
    latitude: 20.409,
    longitude: 99.9,
  },
  {
    province: "เชียงราย",
    district: "แม่สาย",
    subdistrict: "บ้านด้าย",
    zipcode: 57220,
    latitude: 20.31,
    longitude: 99.929,
  },
  {
    province: "เชียงราย",
    district: "แม่สาย",
    subdistrict: "โป่งงาม",
    zipcode: 57130,
    latitude: 20.325,
    longitude: 99.869,
  },
  {
    province: "เชียงราย",
    district: "แม่สรวย",
    subdistrict: "แม่สรวย",
    zipcode: 57180,
    latitude: 19.676,
    longitude: 99.56,
  },
  {
    province: "เชียงราย",
    district: "แม่สรวย",
    subdistrict: "ป่าแดด",
    zipcode: 57180,
    latitude: 19.715,
    longitude: 99.427,
  },
  {
    province: "เชียงราย",
    district: "แม่สรวย",
    subdistrict: "แม่พริก",
    zipcode: 57180,
    latitude: 19.63,
    longitude: 99.524,
  },
  {
    province: "เชียงราย",
    district: "แม่สรวย",
    subdistrict: "ศรีถ้อย",
    zipcode: 57180,
    latitude: 19.677,
    longitude: 99.317,
  },
  {
    province: "เชียงราย",
    district: "แม่สรวย",
    subdistrict: "ท่าก๊อ",
    zipcode: 57180,
    latitude: 19.504,
    longitude: 99.528,
  },
  {
    province: "เชียงราย",
    district: "แม่สรวย",
    subdistrict: "วาวี",
    zipcode: 57180,
    latitude: 19.848,
    longitude: 99.497,
  },
  {
    province: "เชียงราย",
    district: "แม่สรวย",
    subdistrict: "เจดีย์หลวง",
    zipcode: 57180,
    latitude: 19.569,
    longitude: 99.546,
  },
  {
    province: "เชียงราย",
    district: "เวียงป่าเป้า",
    subdistrict: "สันสลี",
    zipcode: 57170,
    latitude: 19.419,
    longitude: 99.514,
  },
  {
    province: "เชียงราย",
    district: "เวียงป่าเป้า",
    subdistrict: "เวียง",
    zipcode: 57170,
    latitude: 19.358,
    longitude: 99.422,
  },
  {
    province: "เชียงราย",
    district: "เวียงป่าเป้า",
    subdistrict: "บ้านโป่ง",
    zipcode: 57170,
    latitude: 19.302,
    longitude: 99.459,
  },
  {
    province: "เชียงราย",
    district: "เวียงป่าเป้า",
    subdistrict: "ป่างิ้ว",
    zipcode: 57170,
    latitude: 19.252,
    longitude: 99.441,
  },
  {
    province: "เชียงราย",
    district: "เวียงป่าเป้า",
    subdistrict: "เวียงกาหลง",
    zipcode: 57260,
    latitude: 19.241,
    longitude: 99.525,
  },
  {
    province: "เชียงราย",
    district: "เวียงป่าเป้า",
    subdistrict: "แม่เจดีย์",
    zipcode: 57260,
    latitude: 19.164,
    longitude: 99.367,
  },
  {
    province: "เชียงราย",
    district: "เวียงป่าเป้า",
    subdistrict: "แม่เจดีย์ใหม่",
    zipcode: 57260,
    latitude: 19.098,
    longitude: 99.451,
  },
  {
    province: "เชียงราย",
    district: "พญาเม็งราย",
    subdistrict: "แม่เปา",
    zipcode: 57290,
    latitude: 19.902,
    longitude: 100.118,
  },
  {
    province: "เชียงราย",
    district: "พญาเม็งราย",
    subdistrict: "แม่ต๋ำ",
    zipcode: 57290,
    latitude: 19.926,
    longitude: 100.239,
  },
  {
    province: "เชียงราย",
    district: "พญาเม็งราย",
    subdistrict: "ไม้ยา",
    zipcode: 57290,
    latitude: 19.769,
    longitude: 100.107,
  },
  {
    province: "เชียงราย",
    district: "พญาเม็งราย",
    subdistrict: "เม็งราย",
    zipcode: 57290,
    latitude: 19.861,
    longitude: 100.182,
  },
  {
    province: "เชียงราย",
    district: "พญาเม็งราย",
    subdistrict: "ตาดควัน",
    zipcode: 57290,
    latitude: 19.991,
    longitude: 100.184,
  },
  {
    province: "เชียงราย",
    district: "เวียงแก่น",
    subdistrict: "ม่วงยาย",
    zipcode: 57310,
    latitude: 20.108,
    longitude: 100.539,
  },
  {
    province: "เชียงราย",
    district: "เวียงแก่น",
    subdistrict: "ปอ",
    zipcode: 57310,
    latitude: 19.94,
    longitude: 100.449,
  },
  {
    province: "เชียงราย",
    district: "เวียงแก่น",
    subdistrict: "หล่ายงาว",
    zipcode: 57310,
    latitude: 20.117,
    longitude: 100.48,
  },
  {
    province: "เชียงราย",
    district: "เวียงแก่น",
    subdistrict: "ท่าข้าม",
    zipcode: 57310,
    latitude: 20.042,
    longitude: 100.52,
  },
  {
    province: "เชียงราย",
    district: "ขุนตาล",
    subdistrict: "ต้า",
    zipcode: 57340,
    latitude: 19.804,
    longitude: 100.233,
  },
  {
    province: "เชียงราย",
    district: "ขุนตาล",
    subdistrict: "ป่าตาล",
    zipcode: 57340,
    latitude: 19.847,
    longitude: 100.272,
  },
  {
    province: "เชียงราย",
    district: "ขุนตาล",
    subdistrict: "ยางฮอม",
    zipcode: 57340,
    latitude: 19.924,
    longitude: 100.306,
  },
  {
    province: "เชียงราย",
    district: "แม่ฟ้าหลวง",
    subdistrict: "เทอดไทย",
    zipcode: 57240,
    latitude: 20.306,
    longitude: 99.603,
  },
  {
    province: "เชียงราย",
    district: "แม่ฟ้าหลวง",
    subdistrict: "แม่สลองใน",
    zipcode: 57110,
    latitude: 20.194,
    longitude: 99.706,
  },
  {
    province: "เชียงราย",
    district: "แม่ฟ้าหลวง",
    subdistrict: "แม่สลองนอก",
    zipcode: 57110,
    latitude: 20.141,
    longitude: 99.654,
  },
  {
    province: "เชียงราย",
    district: "แม่ฟ้าหลวง",
    subdistrict: "แม่ฟ้าหลวง",
    zipcode: 57240,
    latitude: 20.288,
    longitude: 99.783,
  },
  {
    province: "เชียงราย",
    district: "แม่ลาว",
    subdistrict: "ดงมะดะ",
    zipcode: 57250,
    latitude: 19.734,
    longitude: 99.67,
  },
  {
    province: "เชียงราย",
    district: "แม่ลาว",
    subdistrict: "จอมหมอกแก้ว",
    zipcode: 57250,
    latitude: 19.757,
    longitude: 99.747,
  },
  {
    province: "เชียงราย",
    district: "แม่ลาว",
    subdistrict: "บัวสลี",
    zipcode: 57250,
    latitude: 19.804,
    longitude: 99.768,
  },
  {
    province: "เชียงราย",
    district: "แม่ลาว",
    subdistrict: "ป่าก่อดำ",
    zipcode: 57250,
    latitude: 19.794,
    longitude: 99.715,
  },
  {
    province: "เชียงราย",
    district: "แม่ลาว",
    subdistrict: "โป่งแพร่",
    zipcode: 57000,
    latitude: 19.812,
    longitude: 99.653,
  },
  {
    province: "เชียงราย",
    district: "เวียงเชียงรุ้ง",
    subdistrict: "ทุ่งก่อ",
    zipcode: 57210,
    latitude: 19.984,
    longitude: 100.049,
  },
  {
    province: "เชียงราย",
    district: "เวียงเชียงรุ้ง",
    subdistrict: "ดงมหาวัน",
    zipcode: 57210,
    latitude: 20.041,
    longitude: 100.006,
  },
  {
    province: "เชียงราย",
    district: "เวียงเชียงรุ้ง",
    subdistrict: "ป่าซาง",
    zipcode: 57210,
    latitude: 20.048,
    longitude: 100.12,
  },
  {
    province: "เชียงราย",
    district: "ดอยหลวง",
    subdistrict: "ปงน้อย",
    zipcode: 57110,
    latitude: 20.104,
    longitude: 100.068,
  },
  {
    province: "เชียงราย",
    district: "ดอยหลวง",
    subdistrict: "โชคชัย",
    zipcode: 57110,
    latitude: 20.134,
    longitude: 100.193,
  },
  {
    province: "เชียงราย",
    district: "ดอยหลวง",
    subdistrict: "หนองป่าก่อ",
    zipcode: 57110,
    latitude: 20.181,
    longitude: 100.124,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "เมืองแม่ฮ่องสอน",
    subdistrict: "จองคำ",
    zipcode: 58000,
    latitude: 19.302,
    longitude: 97.97,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "เมืองแม่ฮ่องสอน",
    subdistrict: "ห้วยโป่ง",
    zipcode: 58000,
    latitude: 19.038,
    longitude: 98.006,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "เมืองแม่ฮ่องสอน",
    subdistrict: "ผาบ่อง",
    zipcode: 58000,
    latitude: 19.215,
    longitude: 97.91,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "เมืองแม่ฮ่องสอน",
    subdistrict: "ปางหมู",
    zipcode: 58000,
    latitude: 19.344,
    longitude: 97.915,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "เมืองแม่ฮ่องสอน",
    subdistrict: "หมอกจำแป่",
    zipcode: 58000,
    latitude: 19.497,
    longitude: 97.995,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "เมืองแม่ฮ่องสอน",
    subdistrict: "ห้วยผา",
    zipcode: 58000,
    latitude: 19.4996771,
    longitude: 97.87865,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "เมืองแม่ฮ่องสอน",
    subdistrict: "ห้วยปูลิง",
    zipcode: 58000,
    latitude: 19.188,
    longitude: 98.112,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ขุนยวม",
    subdistrict: "ขุนยวม",
    zipcode: 58140,
    latitude: 18.992,
    longitude: 97.801,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ขุนยวม",
    subdistrict: "แม่เงา",
    zipcode: 58140,
    latitude: 18.836,
    longitude: 97.796,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ขุนยวม",
    subdistrict: "เมืองปอน",
    zipcode: 58140,
    latitude: 18.702,
    longitude: 97.945,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ขุนยวม",
    subdistrict: "แม่ยวมน้อย",
    zipcode: 58140,
    latitude: 18.742,
    longitude: 98.051,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ขุนยวม",
    subdistrict: "แม่กิ๊",
    zipcode: 58140,
    latitude: 18.673,
    longitude: 97.827,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ขุนยวม",
    subdistrict: "แม่อูคอ",
    zipcode: 58140,
    latitude: 18.884,
    longitude: 98.046,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปาย",
    subdistrict: "เวียงใต้",
    zipcode: 58130,
    latitude: 19.362,
    longitude: 98.419,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปาย",
    subdistrict: "เวียงเหนือ",
    zipcode: 58130,
    latitude: 19.512,
    longitude: 98.497,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปาย",
    subdistrict: "แม่นาเติง",
    zipcode: 58130,
    latitude: 19.456,
    longitude: 98.363,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปาย",
    subdistrict: "แม่ฮี้",
    zipcode: 58130,
    latitude: 19.3,
    longitude: 98.525,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปาย",
    subdistrict: "ทุ่งยาว",
    zipcode: 58130,
    latitude: 19.336,
    longitude: 98.233,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปาย",
    subdistrict: "เมืองแปง",
    zipcode: 58130,
    latitude: 19.216,
    longitude: 98.313,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปาย",
    subdistrict: "โป่งสา",
    zipcode: 58130,
    latitude: 19.12,
    longitude: 98.581,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่สะเรียง",
    subdistrict: "บ้านกาศ",
    zipcode: 58110,
    latitude: 18.259,
    longitude: 97.88,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่สะเรียง",
    subdistrict: "แม่สะเรียง",
    zipcode: 58110,
    latitude: 18.148,
    longitude: 97.972,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่สะเรียง",
    subdistrict: "แม่คง",
    zipcode: 58110,
    latitude: 18.237,
    longitude: 97.638,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่สะเรียง",
    subdistrict: "แม่เหาะ",
    zipcode: 58110,
    latitude: 18.12,
    longitude: 98.074,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่สะเรียง",
    subdistrict: "แม่ยวม",
    zipcode: 58110,
    latitude: 18.063,
    longitude: 97.808,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่สะเรียง",
    subdistrict: "เสาหิน",
    zipcode: 58110,
    latitude: 18.432,
    longitude: 97.719,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่สะเรียง",
    subdistrict: "ป่าแป๋",
    zipcode: 58110,
    latitude: 18.268,
    longitude: 98.072,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่ลาน้อย",
    subdistrict: "แม่ลาน้อย",
    zipcode: 58120,
    latitude: 18.435,
    longitude: 97.852,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่ลาน้อย",
    subdistrict: "แม่ลาหลวง",
    zipcode: 58120,
    latitude: 18.548,
    longitude: 97.872,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่ลาน้อย",
    subdistrict: "ท่าผาปุ้ม",
    zipcode: 58120,
    latitude: 18.315,
    longitude: 97.991,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่ลาน้อย",
    subdistrict: "แม่โถ",
    zipcode: 58120,
    latitude: 18.609,
    longitude: 98.032,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่ลาน้อย",
    subdistrict: "ห้วยห้อม",
    zipcode: 58120,
    latitude: 18.37,
    longitude: 98.102,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่ลาน้อย",
    subdistrict: "แม่นาจาง",
    zipcode: 58120,
    latitude: 18.529,
    longitude: 98.093,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่ลาน้อย",
    subdistrict: "สันติคีรี",
    zipcode: 58120,
    latitude: 18.508,
    longitude: 97.992,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "แม่ลาน้อย",
    subdistrict: "ขุนแม่ลาน้อย",
    zipcode: 58120,
    latitude: 18.586,
    longitude: 98.161,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "สบเมย",
    subdistrict: "สบเมย",
    zipcode: 58110,
    latitude: 17.898,
    longitude: 97.872,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "สบเมย",
    subdistrict: "แม่คะตวน",
    zipcode: 58110,
    latitude: 18.003,
    longitude: 97.981,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "สบเมย",
    subdistrict: "กองก๋อย",
    zipcode: 58110,
    latitude: 18.015,
    longitude: 98.16,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "สบเมย",
    subdistrict: "แม่สวด",
    zipcode: 58110,
    latitude: 17.796,
    longitude: 98.049,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "สบเมย",
    subdistrict: "ป่าโปง",
    zipcode: 58110,
    latitude: 18.113,
    longitude: 98.166,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "สบเมย",
    subdistrict: "แม่สามแลบ",
    zipcode: 58110,
    latitude: 17.899,
    longitude: 97.759,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปางมะผ้า",
    subdistrict: "สบป่อง",
    zipcode: 58150,
    latitude: 19.453,
    longitude: 98.169,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปางมะผ้า",
    subdistrict: "ปางมะผ้า",
    zipcode: 58150,
    latitude: 19.615,
    longitude: 98.204,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปางมะผ้า",
    subdistrict: "ถ้ำลอด",
    zipcode: 58150,
    latitude: 19.582,
    longitude: 98.309,
  },
  {
    province: "แม่ฮ่องสอน",
    district: "ปางมะผ้า",
    subdistrict: "นาปู่ป้อม",
    zipcode: 58150,
    latitude: 19.659,
    longitude: 98.114,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "ปากน้ำโพ",
    zipcode: 60000,
    latitude: 15.703,
    longitude: 100.128,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "กลางแดด",
    zipcode: 60000,
    latitude: 15.663,
    longitude: 100.118,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "เกรียงไกร",
    zipcode: 60000,
    latitude: 15.719,
    longitude: 100.218,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "แควใหญ่",
    zipcode: 60000,
    latitude: 15.712,
    longitude: 100.174,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "ตะเคียนเลื่อน",
    zipcode: 60000,
    latitude: 15.63,
    longitude: 100.086,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "นครสวรรค์ตก",
    zipcode: 60000,
    latitude: 15.681,
    longitude: 100.073,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "นครสวรรค์ออก",
    zipcode: 60000,
    latitude: 15.633,
    longitude: 100.132,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "บางพระหลวง",
    zipcode: 60000,
    latitude: 15.771,
    longitude: 100.188,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "บางม่วง",
    zipcode: 60000,
    latitude: 15.755,
    longitude: 100.11,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "บ้านมะเกลือ",
    zipcode: 60000,
    latitude: 15.801,
    longitude: 100.143,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "บ้านแก่ง",
    zipcode: 60000,
    latitude: 15.788,
    longitude: 100.057,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "พระนอน",
    zipcode: 60000,
    latitude: 15.663,
    longitude: 100.216,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "วัดไทร",
    zipcode: 60000,
    latitude: 15.7374891,
    longitude: 100.0502695,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "หนองกรด",
    zipcode: 60240,
    latitude: 15.724,
    longitude: 99.997,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "หนองกระโดน",
    zipcode: 60240,
    latitude: 15.804,
    longitude: 99.965,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "หนองปลิง",
    zipcode: 60000,
    latitude: 15.645,
    longitude: 100.169,
  },
  {
    province: "นครสวรรค์",
    district: "เมืองนครสวรรค์",
    subdistrict: "บึงเสนาท",
    zipcode: 60000,
    latitude: 15.734,
    longitude: 100.14,
  },
  {
    province: "นครสวรรค์",
    district: "โกรกพระ",
    subdistrict: "โกรกพระ",
    zipcode: 60170,
    latitude: 15.559,
    longitude: 100.056,
  },
  {
    province: "นครสวรรค์",
    district: "โกรกพระ",
    subdistrict: "ยางตาล",
    zipcode: 60170,
    latitude: 15.585,
    longitude: 100.114,
  },
  {
    province: "นครสวรรค์",
    district: "โกรกพระ",
    subdistrict: "บางมะฝ่อ",
    zipcode: 60170,
    latitude: 15.594,
    longitude: 100.081,
  },
  {
    province: "นครสวรรค์",
    district: "โกรกพระ",
    subdistrict: "บางประมุง",
    zipcode: 60170,
    latitude: 15.662,
    longitude: 99.997,
  },
  {
    province: "นครสวรรค์",
    district: "โกรกพระ",
    subdistrict: "นากลาง",
    zipcode: 60170,
    latitude: 15.62,
    longitude: 99.981,
  },
  {
    province: "นครสวรรค์",
    district: "โกรกพระ",
    subdistrict: "ศาลาแดง",
    zipcode: 60170,
    latitude: 15.581,
    longitude: 99.996,
  },
  {
    province: "นครสวรรค์",
    district: "โกรกพระ",
    subdistrict: "เนินกว้าว",
    zipcode: 60170,
    latitude: 15.54,
    longitude: 100.015,
  },
  {
    province: "นครสวรรค์",
    district: "โกรกพระ",
    subdistrict: "เนินศาลา",
    zipcode: 60170,
    latitude: 15.54,
    longitude: 99.96,
  },
  {
    province: "นครสวรรค์",
    district: "โกรกพระ",
    subdistrict: "หาดสูง",
    zipcode: 60170,
    latitude: 15.503,
    longitude: 99.989,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "ชุมแสง",
    zipcode: 60120,
    latitude: 15.896,
    longitude: 100.315,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "ทับกฤช",
    zipcode: 60250,
    latitude: 15.77,
    longitude: 100.233,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "พิกุล",
    zipcode: 60120,
    latitude: 15.889,
    longitude: 100.346,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "เกยไชย",
    zipcode: 60120,
    latitude: 15.869,
    longitude: 100.286,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "ท่าไม้",
    zipcode: 60120,
    latitude: 15.915,
    longitude: 100.253,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "บางเคียน",
    zipcode: 60120,
    latitude: 15.806,
    longitude: 100.176,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "หนองกระเจา",
    zipcode: 60120,
    latitude: 15.901,
    longitude: 100.395,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "พันลาน",
    zipcode: 60250,
    latitude: 15.833,
    longitude: 100.28,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "โคกหม้อ",
    zipcode: 60120,
    latitude: 15.83,
    longitude: 100.235,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "ไผ่สิงห์",
    zipcode: 60120,
    latitude: 15.829,
    longitude: 100.36,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "ฆะมัง",
    zipcode: 60120,
    latitude: 15.948,
    longitude: 100.312,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมแสง",
    subdistrict: "ทับกฤชใต้",
    zipcode: 60250,
    latitude: 15.757,
    longitude: 100.286,
  },
  {
    province: "นครสวรรค์",
    district: "หนองบัว",
    subdistrict: "หนองบัว",
    zipcode: 60110,
    latitude: 15.85,
    longitude: 100.654,
  },
  {
    province: "นครสวรรค์",
    district: "หนองบัว",
    subdistrict: "หนองกลับ",
    zipcode: 60110,
    latitude: 15.924,
    longitude: 100.614,
  },
  {
    province: "นครสวรรค์",
    district: "หนองบัว",
    subdistrict: "ธารทหาร",
    zipcode: 60110,
    latitude: 15.787,
    longitude: 100.549,
  },
  {
    province: "นครสวรรค์",
    district: "หนองบัว",
    subdistrict: "ห้วยร่วม",
    zipcode: 60110,
    latitude: 15.908,
    longitude: 100.494,
  },
  {
    province: "นครสวรรค์",
    district: "หนองบัว",
    subdistrict: "ห้วยถั่วใต้",
    zipcode: 60110,
    latitude: 15.837,
    longitude: 100.464,
  },
  {
    province: "นครสวรรค์",
    district: "หนองบัว",
    subdistrict: "ห้วยถั่วเหนือ",
    zipcode: 60110,
    latitude: 15.855,
    longitude: 100.504,
  },
  {
    province: "นครสวรรค์",
    district: "หนองบัว",
    subdistrict: "ห้วยใหญ่",
    zipcode: 60110,
    latitude: 15.864,
    longitude: 100.429,
  },
  {
    province: "นครสวรรค์",
    district: "หนองบัว",
    subdistrict: "ทุ่งทอง",
    zipcode: 60110,
    latitude: 15.937,
    longitude: 100.713,
  },
  {
    province: "นครสวรรค์",
    district: "หนองบัว",
    subdistrict: "วังบ่อ",
    zipcode: 60110,
    latitude: 15.783,
    longitude: 100.649,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "ท่างิ้ว",
    zipcode: 60180,
    latitude: 15.918,
    longitude: 99.976,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "บางตาหงาย",
    zipcode: 60180,
    latitude: 15.943,
    longitude: 100.022,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "หูกวาง",
    zipcode: 60180,
    latitude: 15.879,
    longitude: 100.014,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "อ่างทอง",
    zipcode: 60180,
    latitude: 15.871,
    longitude: 99.942,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "บ้านแดน",
    zipcode: 60180,
    latitude: 15.937,
    longitude: 99.89,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "บางแก้ว",
    zipcode: 60180,
    latitude: 15.982,
    longitude: 99.872,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "ตาขีด",
    zipcode: 60180,
    latitude: 16.017,
    longitude: 99.917,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "ตาสัง",
    zipcode: 60180,
    latitude: 15.993,
    longitude: 99.967,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "ด่านช้าง",
    zipcode: 60180,
    latitude: 16.08,
    longitude: 99.999,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "หนองกรด",
    zipcode: 60180,
    latitude: 15.997,
    longitude: 100.095,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "หนองตางู",
    zipcode: 60180,
    latitude: 16.13,
    longitude: 100.054,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "บึงปลาทู",
    zipcode: 60180,
    latitude: 16.073,
    longitude: 100.065,
  },
  {
    province: "นครสวรรค์",
    district: "บรรพตพิสัย",
    subdistrict: "เจริญผล",
    zipcode: 60180,
    latitude: 15.956,
    longitude: 99.988,
  },
  {
    province: "นครสวรรค์",
    district: "เก้าเลี้ยว",
    subdistrict: "มหาโพธิ",
    zipcode: 60230,
    latitude: 15.828,
    longitude: 100.104,
  },
  {
    province: "นครสวรรค์",
    district: "เก้าเลี้ยว",
    subdistrict: "เก้าเลี้ยว",
    zipcode: 60230,
    latitude: 15.849,
    longitude: 100.085,
  },
  {
    province: "นครสวรรค์",
    district: "เก้าเลี้ยว",
    subdistrict: "หนองเต่า",
    zipcode: 60230,
    latitude: 15.884,
    longitude: 100.146,
  },
  {
    province: "นครสวรรค์",
    district: "เก้าเลี้ยว",
    subdistrict: "เขาดิน",
    zipcode: 60230,
    latitude: 15.839,
    longitude: 100.039,
  },
  {
    province: "นครสวรรค์",
    district: "เก้าเลี้ยว",
    subdistrict: "หัวดง",
    zipcode: 60230,
    latitude: 15.908,
    longitude: 100.072,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "ตาคลี",
    zipcode: 60140,
    latitude: 15.271,
    longitude: 100.339,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "ช่องแค",
    zipcode: 60210,
    latitude: 15.197,
    longitude: 100.422,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "จันเสน",
    zipcode: 60260,
    latitude: 15.103,
    longitude: 100.439,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "ห้วยหอม",
    zipcode: 60210,
    latitude: 15.225,
    longitude: 100.483,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "หัวหวาย",
    zipcode: 60140,
    latitude: 15.384,
    longitude: 100.383,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "หนองโพ",
    zipcode: 60140,
    latitude: 15.403,
    longitude: 100.278,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "หนองหม้อ",
    zipcode: 60140,
    latitude: 15.194,
    longitude: 100.325,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "สร้อยทอง",
    zipcode: 60210,
    latitude: 15.12,
    longitude: 100.387,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "ลาดทิพรส",
    zipcode: 60260,
    latitude: 15.235,
    longitude: 100.549,
  },
  {
    province: "นครสวรรค์",
    district: "ตาคลี",
    subdistrict: "พรหมนิมิต",
    zipcode: 60210,
    latitude: 15.175,
    longitude: 100.393,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "ท่าตะโก",
    zipcode: 60160,
    latitude: 15.631,
    longitude: 100.48,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "พนมรอก",
    zipcode: 60160,
    latitude: 15.704,
    longitude: 100.442,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "หัวถนน",
    zipcode: 60160,
    latitude: 15.583,
    longitude: 100.432,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "สายลำโพง",
    zipcode: 60160,
    latitude: 15.768,
    longitude: 100.487,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "วังมหากร",
    zipcode: 60160,
    latitude: 15.649,
    longitude: 100.355,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "ดอนคา",
    zipcode: 60160,
    latitude: 15.685,
    longitude: 100.538,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "ทำนบ",
    zipcode: 60160,
    latitude: 15.603,
    longitude: 100.518,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "วังใหญ่",
    zipcode: 60160,
    latitude: 15.781,
    longitude: 100.413,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "พนมเศษ",
    zipcode: 60160,
    latitude: 15.731,
    longitude: 100.34,
  },
  {
    province: "นครสวรรค์",
    district: "ท่าตะโก",
    subdistrict: "หนองหลวง",
    zipcode: 60160,
    latitude: 15.486,
    longitude: 100.533,
  },
  {
    province: "นครสวรรค์",
    district: "ไพศาลี",
    subdistrict: "โคกเดื่อ",
    zipcode: 60220,
    latitude: 15.622,
    longitude: 100.604,
  },
  {
    province: "นครสวรรค์",
    district: "ไพศาลี",
    subdistrict: "สำโรงชัย",
    zipcode: 60220,
    latitude: 15.524,
    longitude: 100.633,
  },
  {
    province: "นครสวรรค์",
    district: "ไพศาลี",
    subdistrict: "วังน้ำลัด",
    zipcode: 60220,
    latitude: 15.709,
    longitude: 100.666,
  },
  {
    province: "นครสวรรค์",
    district: "ไพศาลี",
    subdistrict: "ตะคร้อ",
    zipcode: 60220,
    latitude: 15.476,
    longitude: 100.71,
  },
  {
    province: "นครสวรรค์",
    district: "ไพศาลี",
    subdistrict: "โพธิ์ประสาท",
    zipcode: 60220,
    latitude: 15.44,
    longitude: 100.587,
  },
  {
    province: "นครสวรรค์",
    district: "ไพศาลี",
    subdistrict: "วังข่อย",
    zipcode: 60220,
    latitude: 15.598,
    longitude: 100.761,
  },
  {
    province: "นครสวรรค์",
    district: "ไพศาลี",
    subdistrict: "นาขอม",
    zipcode: 60220,
    latitude: 15.721,
    longitude: 100.784,
  },
  {
    province: "นครสวรรค์",
    district: "ไพศาลี",
    subdistrict: "ไพศาลี",
    zipcode: 60220,
    latitude: 15.637,
    longitude: 100.7,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "พยุหะ",
    zipcode: 60130,
    latitude: 15.455,
    longitude: 100.145,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "เนินมะกอก",
    zipcode: 60130,
    latitude: 15.449,
    longitude: 100.2,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "นิคมเขาบ่อแก้ว",
    zipcode: 60130,
    latitude: 15.471,
    longitude: 100.245,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "ม่วงหัก",
    zipcode: 60130,
    latitude: 15.389,
    longitude: 100.2,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "ยางขาว",
    zipcode: 60130,
    latitude: 15.514,
    longitude: 100.069,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "ย่านมัทรี",
    zipcode: 60130,
    latitude: 15.524,
    longitude: 100.106,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "เขาทอง",
    zipcode: 60130,
    latitude: 15.567,
    longitude: 100.161,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "ท่าน้ำอ้อย",
    zipcode: 60130,
    latitude: 15.423,
    longitude: 100.149,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "น้ำทรง",
    zipcode: 60130,
    latitude: 15.46,
    longitude: 100.074,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "เขากะลา",
    zipcode: 60130,
    latitude: 15.542,
    longitude: 100.316,
  },
  {
    province: "นครสวรรค์",
    district: "พยุหะคีรี",
    subdistrict: "สระทะเล",
    zipcode: 60130,
    latitude: 15.51,
    longitude: 100.156,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "ลาดยาว",
    zipcode: 60150,
    latitude: 15.756,
    longitude: 99.759,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "ห้วยน้ำหอม",
    zipcode: 60150,
    latitude: 15.729,
    longitude: 99.696,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "วังม้า",
    zipcode: 60150,
    latitude: 15.686,
    longitude: 99.871,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "วังเมือง",
    zipcode: 60150,
    latitude: 15.64,
    longitude: 99.846,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "สร้อยละคร",
    zipcode: 60150,
    latitude: 15.726,
    longitude: 99.824,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "มาบแก",
    zipcode: 60150,
    latitude: 15.677,
    longitude: 99.781,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "หนองยาว",
    zipcode: 60150,
    latitude: 15.737,
    longitude: 99.887,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "หนองนมวัว",
    zipcode: 60150,
    latitude: 15.777,
    longitude: 99.866,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "บ้านไร่",
    zipcode: 60150,
    latitude: 15.873,
    longitude: 99.791,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "เนินขี้เหล็ก",
    zipcode: 60150,
    latitude: 15.839,
    longitude: 99.858,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "ศาลเจ้าไก่ต่อ",
    zipcode: 60150,
    latitude: 15.788,
    longitude: 99.669,
  },
  {
    province: "นครสวรรค์",
    district: "ลาดยาว",
    subdistrict: "สระแก้ว",
    zipcode: 60150,
    latitude: 15.784,
    longitude: 99.803,
  },
  {
    province: "นครสวรรค์",
    district: "ตากฟ้า",
    subdistrict: "ตากฟ้า",
    zipcode: 60190,
    latitude: 15.313,
    longitude: 100.488,
  },
  {
    province: "นครสวรรค์",
    district: "ตากฟ้า",
    subdistrict: "ลำพยนต์",
    zipcode: 60190,
    latitude: 15.32,
    longitude: 100.573,
  },
  {
    province: "นครสวรรค์",
    district: "ตากฟ้า",
    subdistrict: "สุขสำราญ",
    zipcode: 60190,
    latitude: 15.353,
    longitude: 100.523,
  },
  {
    province: "นครสวรรค์",
    district: "ตากฟ้า",
    subdistrict: "หนองพิกุล",
    zipcode: 60190,
    latitude: 15.331,
    longitude: 100.428,
  },
  {
    province: "นครสวรรค์",
    district: "ตากฟ้า",
    subdistrict: "พุนกยูง",
    zipcode: 60190,
    latitude: 15.402,
    longitude: 100.467,
  },
  {
    province: "นครสวรรค์",
    district: "ตากฟ้า",
    subdistrict: "อุดมธัญญา",
    zipcode: 60190,
    latitude: 15.479,
    longitude: 100.407,
  },
  {
    province: "นครสวรรค์",
    district: "ตากฟ้า",
    subdistrict: "เขาชายธง",
    zipcode: 60190,
    latitude: 15.278,
    longitude: 100.448,
  },
  {
    province: "นครสวรรค์",
    district: "แม่วงก์",
    subdistrict: "แม่วงก์",
    zipcode: 60150,
    latitude: 15.781,
    longitude: 99.57,
  },
  {
    province: "นครสวรรค์",
    district: "แม่วงก์",
    subdistrict: "แม่เล่ย์",
    zipcode: 60150,
    latitude: 15.842,
    longitude: 99.298,
  },
  {
    province: "นครสวรรค์",
    district: "แม่วงก์",
    subdistrict: "วังซ่าน",
    zipcode: 60150,
    latitude: 15.859,
    longitude: 99.644,
  },
  {
    province: "นครสวรรค์",
    district: "แม่วงก์",
    subdistrict: "เขาชนกัน",
    zipcode: 60150,
    latitude: 15.883,
    longitude: 99.516,
  },
  {
    province: "นครสวรรค์",
    district: "แม่เปิน",
    subdistrict: "แม่เปิน",
    zipcode: 60150,
    latitude: 15.738,
    longitude: 99.294,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมตาบง",
    subdistrict: "ชุมตาบง",
    zipcode: 60150,
    latitude: 15.627,
    longitude: 99.572,
  },
  {
    province: "นครสวรรค์",
    district: "ชุมตาบง",
    subdistrict: "ปางสวรรค์",
    zipcode: 60150,
    latitude: 15.688,
    longitude: 99.56,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "อุทัยใหม่",
    zipcode: 61000,
    latitude: 15.375,
    longitude: 100.031,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "น้ำซึม",
    zipcode: 61000,
    latitude: 15.353,
    longitude: 100.033,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "สะแกกรัง",
    zipcode: 61000,
    latitude: 15.354,
    longitude: 100.068,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "ดอนขวาง",
    zipcode: 61000,
    latitude: 15.415,
    longitude: 100.032,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "หาดทนง",
    zipcode: 61000,
    latitude: 15.382,
    longitude: 100.096,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "เกาะเทโพ",
    zipcode: 61000,
    latitude: 15.35,
    longitude: 100.088,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "ท่าซุง",
    zipcode: 61000,
    latitude: 15.31,
    longitude: 100.058,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "หนองแก",
    zipcode: 61000,
    latitude: 15.417,
    longitude: 99.974,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "โนนเหล็ก",
    zipcode: 61000,
    latitude: 15.462,
    longitude: 99.985,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "หนองเต่า",
    zipcode: 61000,
    latitude: 15.455,
    longitude: 99.963,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "หนองไผ่แบน",
    zipcode: 61000,
    latitude: 15.444,
    longitude: 100.018,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "หนองพังค่า",
    zipcode: 61000,
    latitude: 15.398,
    longitude: 99.959,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "ทุ่งใหญ่",
    zipcode: 61000,
    latitude: 15.483,
    longitude: 99.974,
  },
  {
    province: "อุทัยธานี",
    district: "เมืองอุทัยธานี",
    subdistrict: "เนินแจง",
    zipcode: 61000,
    latitude: 15.46,
    longitude: 100.031,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "ทัพทัน",
    zipcode: 61120,
    latitude: 15.467,
    longitude: 99.888,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "ทุ่งนาไทย",
    zipcode: 61120,
    latitude: 15.45,
    longitude: 99.926,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "เขาขี้ฝอย",
    zipcode: 61120,
    latitude: 15.469,
    longitude: 99.939,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "หนองหญ้าปล้อง",
    zipcode: 61120,
    latitude: 15.495,
    longitude: 99.909,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "โคกหม้อ",
    zipcode: 61120,
    latitude: 15.55,
    longitude: 99.915,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "หนองยายดา",
    zipcode: 61120,
    latitude: 15.519,
    longitude: 99.87,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "หนองกลางดง",
    zipcode: 61120,
    latitude: 15.465,
    longitude: 99.844,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "หนองกระทุ่ม",
    zipcode: 61120,
    latitude: 15.508,
    longitude: 99.71,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "หนองสระ",
    zipcode: 61120,
    latitude: 15.445,
    longitude: 99.82,
  },
  {
    province: "อุทัยธานี",
    district: "ทัพทัน",
    subdistrict: "ตลุกดู่",
    zipcode: 61120,
    latitude: 15.452,
    longitude: 99.74,
  },
  {
    province: "อุทัยธานี",
    district: "สว่างอารมณ์",
    subdistrict: "สว่างอารมณ์",
    zipcode: 61150,
    latitude: 15.548,
    longitude: 99.844,
  },
  {
    province: "อุทัยธานี",
    district: "สว่างอารมณ์",
    subdistrict: "หนองหลวง",
    zipcode: 61150,
    latitude: 15.622,
    longitude: 99.9,
  },
  {
    province: "อุทัยธานี",
    district: "สว่างอารมณ์",
    subdistrict: "พลวงสองนาง",
    zipcode: 61150,
    latitude: 15.55,
    longitude: 99.779,
  },
  {
    province: "อุทัยธานี",
    district: "สว่างอารมณ์",
    subdistrict: "ไผ่เขียว",
    zipcode: 61150,
    latitude: 15.623,
    longitude: 99.762,
  },
  {
    province: "อุทัยธานี",
    district: "สว่างอารมณ์",
    subdistrict: "บ่อยาง",
    zipcode: 61150,
    latitude: 15.592,
    longitude: 99.715,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "หนองฉาง",
    zipcode: 61110,
    latitude: 15.397,
    longitude: 99.854,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "หนองยาง",
    zipcode: 61110,
    latitude: 15.343,
    longitude: 99.815,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "หนองนางนวล",
    zipcode: 61110,
    latitude: 15.34,
    longitude: 99.864,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "หนองสรวง",
    zipcode: 61110,
    latitude: 15.372,
    longitude: 99.81,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "บ้านเก่า",
    zipcode: 61110,
    latitude: 15.427,
    longitude: 99.874,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "อุทัยเก่า",
    zipcode: 61110,
    latitude: 15.408,
    longitude: 99.8,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "ทุ่งโพ",
    zipcode: 61110,
    latitude: 15.344,
    longitude: 99.712,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "ทุ่งพง",
    zipcode: 61110,
    latitude: 15.425,
    longitude: 99.843,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "เขาบางแกรก",
    zipcode: 61170,
    latitude: 15.328,
    longitude: 99.657,
  },
  {
    province: "อุทัยธานี",
    district: "หนองฉาง",
    subdistrict: "เขากวางทอง",
    zipcode: 61110,
    latitude: 15.394,
    longitude: 99.694,
  },
  {
    province: "อุทัยธานี",
    district: "หนองขาหย่าง",
    subdistrict: "หนองขาหย่าง",
    zipcode: 61130,
    latitude: 15.349,
    longitude: 99.95,
  },
  {
    province: "อุทัยธานี",
    district: "หนองขาหย่าง",
    subdistrict: "หนองไผ่",
    zipcode: 61130,
    latitude: 15.341,
    longitude: 99.902,
  },
  {
    province: "อุทัยธานี",
    district: "หนองขาหย่าง",
    subdistrict: "ดอนกลอย",
    zipcode: 61130,
    latitude: 15.389,
    longitude: 99.903,
  },
  {
    province: "อุทัยธานี",
    district: "หนองขาหย่าง",
    subdistrict: "ห้วยรอบ",
    zipcode: 61130,
    latitude: 15.371,
    longitude: 99.9,
  },
  {
    province: "อุทัยธานี",
    district: "หนองขาหย่าง",
    subdistrict: "ทุ่งพึ่ง",
    zipcode: 61130,
    latitude: 15.422,
    longitude: 99.934,
  },
  {
    province: "อุทัยธานี",
    district: "หนองขาหย่าง",
    subdistrict: "ท่าโพ",
    zipcode: 61130,
    latitude: 15.369,
    longitude: 99.984,
  },
  {
    province: "อุทัยธานี",
    district: "หนองขาหย่าง",
    subdistrict: "หมกแถว",
    zipcode: 61130,
    latitude: 15.338,
    longitude: 99.991,
  },
  {
    province: "อุทัยธานี",
    district: "หนองขาหย่าง",
    subdistrict: "หลุมเข้า",
    zipcode: 61130,
    latitude: 15.3643764,
    longitude: 99.874513,
  },
  {
    province: "อุทัยธานี",
    district: "หนองขาหย่าง",
    subdistrict: "ดงขวาง",
    zipcode: 61130,
    latitude: 15.315,
    longitude: 99.999,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "บ้านไร่",
    zipcode: 61140,
    latitude: 15.05,
    longitude: 99.464,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "ทัพหลวง",
    zipcode: 61140,
    latitude: 15.054,
    longitude: 99.603,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "ห้วยแห้ง",
    zipcode: 61140,
    latitude: 15.175,
    longitude: 99.556,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "คอกควาย",
    zipcode: 61140,
    latitude: 15.226,
    longitude: 99.412,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "วังหิน",
    zipcode: 61180,
    latitude: 15.27,
    longitude: 99.7,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "เมืองการุ้ง",
    zipcode: 61180,
    latitude: 15.177,
    longitude: 99.724,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "แก่นมะกรูด",
    zipcode: 61140,
    latitude: 15.292,
    longitude: 99.168,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "หนองจอก",
    zipcode: 61180,
    latitude: 15.047,
    longitude: 99.685,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "หูช้าง",
    zipcode: 61180,
    latitude: 15.142,
    longitude: 99.681,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "บ้านบึง",
    zipcode: 61140,
    latitude: 15.028,
    longitude: 99.553,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "บ้านใหม่คลองเคียน",
    zipcode: 61180,
    latitude: 15.238,
    longitude: 99.674,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "หนองบ่มกล้วย",
    zipcode: 61180,
    latitude: 15.021,
    longitude: 99.73,
  },
  {
    province: "อุทัยธานี",
    district: "บ้านไร่",
    subdistrict: "เจ้าวัด",
    zipcode: 61140,
    latitude: 15.132,
    longitude: 99.431,
  },
  {
    province: "อุทัยธานี",
    district: "ลานสัก",
    subdistrict: "ลานสัก",
    zipcode: 61160,
    latitude: 15.486,
    longitude: 99.544,
  },
  {
    province: "อุทัยธานี",
    district: "ลานสัก",
    subdistrict: "ประดู่ยืน",
    zipcode: 61160,
    latitude: 15.452,
    longitude: 99.643,
  },
  {
    province: "อุทัยธานี",
    district: "ลานสัก",
    subdistrict: "ป่าอ้อ",
    zipcode: 61160,
    latitude: 15.408,
    longitude: 99.519,
  },
  {
    province: "อุทัยธานี",
    district: "ลานสัก",
    subdistrict: "ระบำ",
    zipcode: 61160,
    latitude: 15.538,
    longitude: 99.336,
  },
  {
    province: "อุทัยธานี",
    district: "ลานสัก",
    subdistrict: "น้ำรอบ",
    zipcode: 61160,
    latitude: 15.534,
    longitude: 99.605,
  },
  {
    province: "อุทัยธานี",
    district: "ลานสัก",
    subdistrict: "ทุ่งนางาม",
    zipcode: 61160,
    latitude: 15.385,
    longitude: 99.61,
  },
  {
    province: "อุทัยธานี",
    district: "ห้วยคต",
    subdistrict: "สุขฤทัย",
    zipcode: 61170,
    latitude: 15.274,
    longitude: 99.646,
  },
  {
    province: "อุทัยธานี",
    district: "ห้วยคต",
    subdistrict: "ทองหลาง",
    zipcode: 61170,
    latitude: 15.316,
    longitude: 99.481,
  },
  {
    province: "อุทัยธานี",
    district: "ห้วยคต",
    subdistrict: "ห้วยคต",
    zipcode: 61170,
    latitude: 15.272,
    longitude: 99.568,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "ในเมือง",
    zipcode: 62000,
    latitude: 16.473,
    longitude: 99.528,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "ไตรตรึงษ์",
    zipcode: 62160,
    latitude: 16.328,
    longitude: 99.553,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "อ่างทอง",
    zipcode: 62000,
    latitude: 16.267,
    longitude: 99.465,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "นาบ่อคำ",
    zipcode: 62000,
    latitude: 16.458,
    longitude: 99.321,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "นครชุม",
    zipcode: 62000,
    latitude: 16.49,
    longitude: 99.457,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "ทรงธรรม",
    zipcode: 62000,
    latitude: 16.53,
    longitude: 99.454,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "ลานดอกไม้",
    zipcode: 62000,
    latitude: 16.634,
    longitude: 99.44,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "หนองปลิง",
    zipcode: 62000,
    latitude: 16.566,
    longitude: 99.521,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "คณฑี",
    zipcode: 62000,
    latitude: 16.367,
    longitude: 99.695,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "นิคมทุ่งโพธิ์ทะเล",
    zipcode: 62000,
    latitude: 16.485,
    longitude: 99.703,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "เทพนคร",
    zipcode: 62000,
    latitude: 16.427,
    longitude: 99.611,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "วังทอง",
    zipcode: 62000,
    latitude: 16.27,
    longitude: 99.376,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "ท่าขุนราม",
    zipcode: 62000,
    latitude: 16.451,
    longitude: 99.452,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "คลองแม่ลาย",
    zipcode: 62000,
    latitude: 16.375,
    longitude: 99.41,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "ธำมรงค์",
    zipcode: 62160,
    latitude: 16.33,
    longitude: 99.628,
  },
  {
    province: "กำแพงเพชร",
    district: "เมืองกำแพงเพชร",
    subdistrict: "สระแก้ว",
    zipcode: 62000,
    latitude: 16.521,
    longitude: 99.59,
  },
  {
    province: "กำแพงเพชร",
    district: "ไทรงาม",
    subdistrict: "ไทรงาม",
    zipcode: 62150,
    latitude: 16.487,
    longitude: 99.897,
  },
  {
    province: "กำแพงเพชร",
    district: "ไทรงาม",
    subdistrict: "หนองคล้า",
    zipcode: 62150,
    latitude: 16.452,
    longitude: 99.827,
  },
  {
    province: "กำแพงเพชร",
    district: "ไทรงาม",
    subdistrict: "หนองทอง",
    zipcode: 62150,
    latitude: 16.417,
    longitude: 99.756,
  },
  {
    province: "กำแพงเพชร",
    district: "ไทรงาม",
    subdistrict: "หนองไม้กอง",
    zipcode: 62150,
    latitude: 16.464,
    longitude: 99.973,
  },
  {
    province: "กำแพงเพชร",
    district: "ไทรงาม",
    subdistrict: "มหาชัย",
    zipcode: 62150,
    latitude: 16.507,
    longitude: 99.798,
  },
  {
    province: "กำแพงเพชร",
    district: "ไทรงาม",
    subdistrict: "พานทอง",
    zipcode: 62150,
    latitude: 16.384,
    longitude: 99.833,
  },
  {
    province: "กำแพงเพชร",
    district: "ไทรงาม",
    subdistrict: "หนองแม่แตง",
    zipcode: 62150,
    latitude: 16.399,
    longitude: 99.909,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองลาน",
    subdistrict: "คลองน้ำไหล",
    zipcode: 62180,
    latitude: 16.157,
    longitude: 99.144,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองลาน",
    subdistrict: "โป่งน้ำร้อน",
    zipcode: 62180,
    latitude: 16.389,
    longitude: 99.258,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองลาน",
    subdistrict: "คลองลานพัฒนา",
    zipcode: 62180,
    latitude: 16.096,
    longitude: 99.252,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองลาน",
    subdistrict: "สักงาม",
    zipcode: 62180,
    latitude: 16.324,
    longitude: 99.348,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "ยางสูง",
    zipcode: 62130,
    latitude: 16.124,
    longitude: 99.857,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "ป่าพุทรา",
    zipcode: 62130,
    latitude: 16.094,
    longitude: 99.918,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "แสนตอ",
    zipcode: 62130,
    latitude: 16.051,
    longitude: 99.831,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "สลกบาตร",
    zipcode: 62140,
    latitude: 16,
    longitude: 99.801,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "บ่อถ้ำ",
    zipcode: 62140,
    latitude: 15.912,
    longitude: 99.738,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "ดอนแตง",
    zipcode: 62140,
    latitude: 16.053,
    longitude: 99.752,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "วังชะพลู",
    zipcode: 62140,
    latitude: 16.011,
    longitude: 99.639,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "โค้งไผ่",
    zipcode: 62140,
    latitude: 16.108,
    longitude: 99.657,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "ปางมะค่า",
    zipcode: 62140,
    latitude: 15.967,
    longitude: 99.461,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "วังหามแห",
    zipcode: 62140,
    latitude: 16.066,
    longitude: 99.603,
  },
  {
    province: "กำแพงเพชร",
    district: "ขาณุวรลักษบุรี",
    subdistrict: "เกาะตาล",
    zipcode: 62130,
    latitude: 16.103,
    longitude: 99.788,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "คลองขลุง",
    zipcode: 62120,
    latitude: 16.176,
    longitude: 99.698,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "ท่ามะเขือ",
    zipcode: 62120,
    latitude: 16.261,
    longitude: 99.752,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "ท่าพุทรา",
    zipcode: 62120,
    latitude: 16.269,
    longitude: 99.679,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "แม่ลาด",
    zipcode: 62120,
    latitude: 16.153,
    longitude: 99.785,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "วังยาง",
    zipcode: 62120,
    latitude: 16.204,
    longitude: 99.774,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "วังแขม",
    zipcode: 62120,
    latitude: 16.208,
    longitude: 99.837,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "หัวถนน",
    zipcode: 62120,
    latitude: 16.267,
    longitude: 99.631,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "วังไทร",
    zipcode: 62120,
    latitude: 16.2,
    longitude: 99.631,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "วังบัว",
    zipcode: 62120,
    latitude: 16.308,
    longitude: 99.729,
  },
  {
    province: "กำแพงเพชร",
    district: "คลองขลุง",
    subdistrict: "คลองสมบูรณ์",
    zipcode: 62120,
    latitude: 16.25,
    longitude: 99.543,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "พรานกระต่าย",
    zipcode: 62110,
    latitude: 16.657,
    longitude: 99.538,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "หนองหัววัว",
    zipcode: 62110,
    latitude: 16.748,
    longitude: 99.579,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "ท่าไม้",
    zipcode: 62110,
    latitude: 16.679,
    longitude: 99.434,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "วังควง",
    zipcode: 62110,
    latitude: 16.779,
    longitude: 99.398,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "วังตะแบก",
    zipcode: 62110,
    latitude: 16.712,
    longitude: 99.666,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "เขาคีริส",
    zipcode: 62110,
    latitude: 16.588,
    longitude: 99.683,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "คุยบ้านโอง",
    zipcode: 62110,
    latitude: 16.659,
    longitude: 99.667,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "คลองพิไกร",
    zipcode: 62110,
    latitude: 16.666,
    longitude: 99.742,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "ถ้ำกระต่ายทอง",
    zipcode: 62110,
    latitude: 16.729,
    longitude: 99.541,
  },
  {
    province: "กำแพงเพชร",
    district: "พรานกระต่าย",
    subdistrict: "ห้วยยั้ง",
    zipcode: 62110,
    latitude: 16.824,
    longitude: 99.559,
  },
  {
    province: "กำแพงเพชร",
    district: "ลานกระบือ",
    subdistrict: "ลานกระบือ",
    zipcode: 62170,
    latitude: 16.625,
    longitude: 99.861,
  },
  {
    province: "กำแพงเพชร",
    district: "ลานกระบือ",
    subdistrict: "ช่องลม",
    zipcode: 62170,
    latitude: 16.568,
    longitude: 99.913,
  },
  {
    province: "กำแพงเพชร",
    district: "ลานกระบือ",
    subdistrict: "หนองหลวง",
    zipcode: 62170,
    latitude: 16.64,
    longitude: 99.78,
  },
  {
    province: "กำแพงเพชร",
    district: "ลานกระบือ",
    subdistrict: "โนนพลวง",
    zipcode: 62170,
    latitude: 16.56,
    longitude: 99.857,
  },
  {
    province: "กำแพงเพชร",
    district: "ลานกระบือ",
    subdistrict: "ประชาสุขสันต์",
    zipcode: 62170,
    latitude: 16.521,
    longitude: 99.974,
  },
  {
    province: "กำแพงเพชร",
    district: "ลานกระบือ",
    subdistrict: "บึงทับแรต",
    zipcode: 62170,
    latitude: 16.689,
    longitude: 99.855,
  },
  {
    province: "กำแพงเพชร",
    district: "ลานกระบือ",
    subdistrict: "จันทิมา",
    zipcode: 62170,
    latitude: 16.57,
    longitude: 99.791,
  },
  {
    province: "กำแพงเพชร",
    district: "ทรายทองวัฒนา",
    subdistrict: "ทุ่งทราย",
    zipcode: 62190,
    latitude: 16.305,
    longitude: 99.862,
  },
  {
    province: "กำแพงเพชร",
    district: "ทรายทองวัฒนา",
    subdistrict: "ทุ่งทอง",
    zipcode: 62190,
    latitude: 16.341,
    longitude: 99.899,
  },
  {
    province: "กำแพงเพชร",
    district: "ทรายทองวัฒนา",
    subdistrict: "ถาวรวัฒนา",
    zipcode: 62190,
    latitude: 16.287,
    longitude: 99.915,
  },
  {
    province: "กำแพงเพชร",
    district: "ปางศิลาทอง",
    subdistrict: "โพธิ์ทอง",
    zipcode: 62120,
    latitude: 16.11,
    longitude: 99.527,
  },
  {
    province: "กำแพงเพชร",
    district: "ปางศิลาทอง",
    subdistrict: "หินดาต",
    zipcode: 62120,
    latitude: 16.052,
    longitude: 99.426,
  },
  {
    province: "กำแพงเพชร",
    district: "ปางศิลาทอง",
    subdistrict: "ปางตาไว",
    zipcode: 62120,
    latitude: 16.017,
    longitude: 99.164,
  },
  {
    province: "กำแพงเพชร",
    district: "บึงสามัคคี",
    subdistrict: "บึงสามัคคี",
    zipcode: 62210,
    latitude: 16.285,
    longitude: 99.985,
  },
  {
    province: "กำแพงเพชร",
    district: "บึงสามัคคี",
    subdistrict: "วังชะโอน",
    zipcode: 62210,
    latitude: 16.233,
    longitude: 99.926,
  },
  {
    province: "กำแพงเพชร",
    district: "บึงสามัคคี",
    subdistrict: "ระหาน",
    zipcode: 62210,
    latitude: 16.158,
    longitude: 99.941,
  },
  {
    province: "กำแพงเพชร",
    district: "บึงสามัคคี",
    subdistrict: "เทพนิมิต",
    zipcode: 62210,
    latitude: 16.182,
    longitude: 99.914,
  },
  {
    province: "กำแพงเพชร",
    district: "โกสัมพีนคร",
    subdistrict: "โกสัมพี",
    zipcode: 62000,
    latitude: 16.501,
    longitude: 99.215,
  },
  {
    province: "กำแพงเพชร",
    district: "โกสัมพีนคร",
    subdistrict: "เพชรชมภู",
    zipcode: 62000,
    latitude: 16.58,
    longitude: 99.361,
  },
  {
    province: "กำแพงเพชร",
    district: "โกสัมพีนคร",
    subdistrict: "ลานดอกไม้ตก",
    zipcode: 62000,
    latitude: 16.581,
    longitude: 99.429,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "ระแหง",
    zipcode: 63000,
    latitude: 16.862,
    longitude: 99.129,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "หนองหลวง",
    zipcode: 63000,
    latitude: 16.3646497,
    longitude: 98.2373535,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "เชียงเงิน",
    zipcode: 63000,
    latitude: 16.8693593,
    longitude: 99.1044974,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "หัวเดียด",
    zipcode: 63000,
    latitude: 16.8694394,
    longitude: 99.1044974,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "หนองบัวเหนือ",
    zipcode: 63000,
    latitude: 16.923,
    longitude: 99.049,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "ไม้งาม",
    zipcode: 63000,
    latitude: 16.972,
    longitude: 99.186,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "โป่งแดง",
    zipcode: 63000,
    latitude: 17.059,
    longitude: 99.291,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "น้ำรึม",
    zipcode: 63000,
    latitude: 16.897,
    longitude: 99.168,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "วังหิน",
    zipcode: 63000,
    latitude: 16.786,
    longitude: 99.231,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "แม่ท้อ",
    zipcode: 63000,
    latitude: 16.789,
    longitude: 98.967,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "ป่ามะม่วง",
    zipcode: 63000,
    latitude: 16.866,
    longitude: 99.075,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "หนองบัวใต้",
    zipcode: 63000,
    latitude: 16.795,
    longitude: 99.103,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "วังประจบ",
    zipcode: 63000,
    latitude: 16.888,
    longitude: 99.373,
  },
  {
    province: "ตาก",
    district: "เมืองตาก",
    subdistrict: "ตลุกกลางทุ่ง",
    zipcode: 63000,
    latitude: 16.861,
    longitude: 99.249,
  },
  {
    province: "ตาก",
    district: "บ้านตาก",
    subdistrict: "ตากออก",
    zipcode: 63120,
    latitude: 17.066,
    longitude: 99.107,
  },
  {
    province: "ตาก",
    district: "บ้านตาก",
    subdistrict: "สมอโคน",
    zipcode: 63120,
    latitude: 17.046,
    longitude: 99.183,
  },
  {
    province: "ตาก",
    district: "บ้านตาก",
    subdistrict: "แม่สลิด",
    zipcode: 63120,
    latitude: 17.295,
    longitude: 99.255,
  },
  {
    province: "ตาก",
    district: "บ้านตาก",
    subdistrict: "ตากตก",
    zipcode: 63120,
    latitude: 17.001,
    longitude: 99.068,
  },
  {
    province: "ตาก",
    district: "บ้านตาก",
    subdistrict: "เกาะตะเภา",
    zipcode: 63120,
    latitude: 17.11,
    longitude: 99.011,
  },
  {
    province: "ตาก",
    district: "บ้านตาก",
    subdistrict: "ทุ่งกระเชาะ",
    zipcode: 63120,
    latitude: 16.9841832,
    longitude: 98.8841812,
  },
  {
    province: "ตาก",
    district: "บ้านตาก",
    subdistrict: "ท้องฟ้า",
    zipcode: 63120,
    latitude: 17.086,
    longitude: 98.918,
  },
  {
    province: "ตาก",
    district: "สามเงา",
    subdistrict: "สามเงา",
    zipcode: 63130,
    latitude: 17.25,
    longitude: 99.034,
  },
  {
    province: "ตาก",
    district: "สามเงา",
    subdistrict: "วังหมัน",
    zipcode: 63130,
    latitude: 17.201,
    longitude: 99.117,
  },
  {
    province: "ตาก",
    district: "สามเงา",
    subdistrict: "ยกกระบัตร",
    zipcode: 63130,
    latitude: 17.335,
    longitude: 99.034,
  },
  {
    province: "ตาก",
    district: "สามเงา",
    subdistrict: "ย่านรี",
    zipcode: 63130,
    latitude: 17.188,
    longitude: 99.022,
  },
  {
    province: "ตาก",
    district: "สามเงา",
    subdistrict: "บ้านนา",
    zipcode: 63130,
    latitude: 17.333,
    longitude: 98.864,
  },
  {
    province: "ตาก",
    district: "สามเงา",
    subdistrict: "วังจันทร์",
    zipcode: 63130,
    latitude: 17.275,
    longitude: 99.147,
  },
  {
    province: "ตาก",
    district: "แม่ระมาด",
    subdistrict: "แม่ระมาด",
    zipcode: 63140,
    latitude: 16.971,
    longitude: 98.525,
  },
  {
    province: "ตาก",
    district: "แม่ระมาด",
    subdistrict: "แม่จะเรา",
    zipcode: 63140,
    latitude: 16.947,
    longitude: 98.591,
  },
  {
    province: "ตาก",
    district: "แม่ระมาด",
    subdistrict: "ขะเนจื้อ",
    zipcode: 63140,
    latitude: 17.055,
    longitude: 98.541,
  },
  {
    province: "ตาก",
    district: "แม่ระมาด",
    subdistrict: "แม่ตื่น",
    zipcode: 63140,
    latitude: 17.194,
    longitude: 98.566,
  },
  {
    province: "ตาก",
    district: "แม่ระมาด",
    subdistrict: "สามหมื่น",
    zipcode: 63140,
    latitude: 17.092,
    longitude: 98.698,
  },
  {
    province: "ตาก",
    district: "แม่ระมาด",
    subdistrict: "พระธาตุ",
    zipcode: 63140,
    latitude: 17.004,
    longitude: 98.668,
  },
  {
    province: "ตาก",
    district: "ท่าสองยาง",
    subdistrict: "ท่าสองยาง",
    zipcode: 63150,
    latitude: 17.724,
    longitude: 97.817,
  },
  {
    province: "ตาก",
    district: "ท่าสองยาง",
    subdistrict: "แม่ต้าน",
    zipcode: 63150,
    latitude: 17.268,
    longitude: 98.275,
  },
  {
    province: "ตาก",
    district: "ท่าสองยาง",
    subdistrict: "แม่สอง",
    zipcode: 63150,
    latitude: 17.494,
    longitude: 98.151,
  },
  {
    province: "ตาก",
    district: "ท่าสองยาง",
    subdistrict: "แม่หละ",
    zipcode: 63150,
    latitude: 17.17,
    longitude: 98.359,
  },
  {
    province: "ตาก",
    district: "ท่าสองยาง",
    subdistrict: "แม่วะหลวง",
    zipcode: 63150,
    latitude: 17.763,
    longitude: 97.923,
  },
  {
    province: "ตาก",
    district: "ท่าสองยาง",
    subdistrict: "แม่อุสุ",
    zipcode: 63150,
    latitude: 17.361,
    longitude: 98.214,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "แม่สอด",
    zipcode: 63110,
    latitude: 16.716,
    longitude: 98.573,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "แม่กุ",
    zipcode: 63110,
    latitude: 16.62,
    longitude: 98.651,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "พะวอ",
    zipcode: 63110,
    latitude: 16.671,
    longitude: 98.784,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "แม่ตาว",
    zipcode: 63110,
    latitude: 16.681,
    longitude: 98.577,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "แม่กาษา",
    zipcode: 63110,
    latitude: 16.897,
    longitude: 98.654,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "ท่าสายลวด",
    zipcode: 63110,
    latitude: 16.696,
    longitude: 98.533,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "แม่ปะ",
    zipcode: 63110,
    latitude: 16.757,
    longitude: 98.586,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "มหาวัน",
    zipcode: 63110,
    latitude: 16.565,
    longitude: 98.676,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "ด่านแม่ละเมา",
    zipcode: 63110,
    latitude: 16.748,
    longitude: 98.862,
  },
  {
    province: "ตาก",
    district: "แม่สอด",
    subdistrict: "พระธาตุผาแดง",
    zipcode: 63110,
    latitude: 16.692,
    longitude: 98.639,
  },
  {
    province: "ตาก",
    district: "พบพระ",
    subdistrict: "พบพระ",
    zipcode: 63160,
    latitude: 16.402,
    longitude: 98.697,
  },
  {
    province: "ตาก",
    district: "พบพระ",
    subdistrict: "ช่องแคบ",
    zipcode: 63160,
    latitude: 16.512,
    longitude: 98.749,
  },
  {
    province: "ตาก",
    district: "พบพระ",
    subdistrict: "คีรีราษฎร์",
    zipcode: 63160,
    latitude: 16.537,
    longitude: 98.845,
  },
  {
    province: "ตาก",
    district: "พบพระ",
    subdistrict: "วาเล่ย์",
    zipcode: 63160,
    latitude: 16.368,
    longitude: 98.768,
  },
  {
    province: "ตาก",
    district: "พบพระ",
    subdistrict: "รวมไทยพัฒนา",
    zipcode: 63160,
    latitude: 16.44,
    longitude: 98.786,
  },
  {
    province: "ตาก",
    district: "อุ้มผาง",
    subdistrict: "อุ้มผาง",
    zipcode: 63170,
    latitude: 16.019,
    longitude: 98.955,
  },
  {
    province: "ตาก",
    district: "อุ้มผาง",
    subdistrict: "หนองหลวง",
    zipcode: 63170,
    latitude: 16.033,
    longitude: 98.739,
  },
  {
    province: "ตาก",
    district: "อุ้มผาง",
    subdistrict: "โมโกร",
    zipcode: 63170,
    latitude: 16.22,
    longitude: 98.964,
  },
  {
    province: "ตาก",
    district: "อุ้มผาง",
    subdistrict: "แม่จัน",
    zipcode: 63170,
    latitude: 15.727,
    longitude: 98.716,
  },
  {
    province: "ตาก",
    district: "อุ้มผาง",
    subdistrict: "แม่ละมุ้ง",
    zipcode: 63170,
    latitude: 15.641,
    longitude: 98.966,
  },
  {
    province: "ตาก",
    district: "อุ้มผาง",
    subdistrict: "แม่กลอง",
    zipcode: 63170,
    latitude: 16.066,
    longitude: 98.834,
  },
  {
    province: "ตาก",
    district: "วังเจ้า",
    subdistrict: "เชียงทอง",
    zipcode: 63000,
    latitude: 16.571,
    longitude: 99.122,
  },
  {
    province: "ตาก",
    district: "วังเจ้า",
    subdistrict: "นาโบสถ์",
    zipcode: 63000,
    latitude: 16.705,
    longitude: 99.156,
  },
  {
    province: "ตาก",
    district: "วังเจ้า",
    subdistrict: "ประดาง",
    zipcode: 63000,
    latitude: 16.748,
    longitude: 99.184,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "ธานี",
    zipcode: 64000,
    latitude: 17.012,
    longitude: 99.822,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "บ้านสวน",
    zipcode: 64220,
    latitude: 17.045,
    longitude: 99.903,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "เมืองเก่า",
    zipcode: 64210,
    latitude: 17.021,
    longitude: 99.682,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "ปากแคว",
    zipcode: 64000,
    latitude: 17.043,
    longitude: 99.824,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "ยางซ้าย",
    zipcode: 64000,
    latitude: 16.974,
    longitude: 99.821,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "บ้านกล้วย",
    zipcode: 64000,
    latitude: 17.029,
    longitude: 99.776,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "บ้านหลุม",
    zipcode: 64000,
    latitude: 16.983,
    longitude: 99.87,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "ตาลเตี้ย",
    zipcode: 64220,
    latitude: 17.063,
    longitude: 99.87,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "ปากพระ",
    zipcode: 64000,
    latitude: 16.915,
    longitude: 99.836,
  },
  {
    province: "สุโขทัย",
    district: "เมืองสุโขทัย",
    subdistrict: "วังทองแดง",
    zipcode: 64210,
    latitude: 17.107,
    longitude: 99.687,
  },
  {
    province: "สุโขทัย",
    district: "บ้านด่านลานหอย",
    subdistrict: "ลานหอย",
    zipcode: 64140,
    latitude: 16.887,
    longitude: 99.609,
  },
  {
    province: "สุโขทัย",
    district: "บ้านด่านลานหอย",
    subdistrict: "บ้านด่าน",
    zipcode: 64140,
    latitude: 17.048,
    longitude: 99.549,
  },
  {
    province: "สุโขทัย",
    district: "บ้านด่านลานหอย",
    subdistrict: "วังตะคร้อ",
    zipcode: 64140,
    latitude: 16.948,
    longitude: 99.5,
  },
  {
    province: "สุโขทัย",
    district: "บ้านด่านลานหอย",
    subdistrict: "วังน้ำขาว",
    zipcode: 64140,
    latitude: 17.096,
    longitude: 99.608,
  },
  {
    province: "สุโขทัย",
    district: "บ้านด่านลานหอย",
    subdistrict: "ตลิ่งชัน",
    zipcode: 64140,
    latitude: 17.156,
    longitude: 99.414,
  },
  {
    province: "สุโขทัย",
    district: "บ้านด่านลานหอย",
    subdistrict: "หนองหญ้าปล้อง",
    zipcode: 64140,
    latitude: 16.995,
    longitude: 99.481,
  },
  {
    province: "สุโขทัย",
    district: "บ้านด่านลานหอย",
    subdistrict: "วังลึก",
    zipcode: 64140,
    latitude: 17.04,
    longitude: 99.461,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "โตนด",
    zipcode: 64160,
    latitude: 16.839,
    longitude: 99.824,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "ทุ่งหลวง",
    zipcode: 64160,
    latitude: 16.877,
    longitude: 99.774,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "บ้านป้อม",
    zipcode: 64160,
    latitude: 16.931,
    longitude: 99.776,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "สามพวง",
    zipcode: 64160,
    latitude: 16.79,
    longitude: 99.785,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "ศรีคีรีมาศ",
    zipcode: 64160,
    latitude: 16.827,
    longitude: 99.706,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "หนองจิก",
    zipcode: 64160,
    latitude: 16.764,
    longitude: 99.749,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "นาเชิงคีรี",
    zipcode: 64160,
    latitude: 16.929,
    longitude: 99.688,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "หนองกระดิ่ง",
    zipcode: 64160,
    latitude: 16.798,
    longitude: 99.851,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "บ้านน้ำพุ",
    zipcode: 64160,
    latitude: 16.815,
    longitude: 99.632,
  },
  {
    province: "สุโขทัย",
    district: "คีรีมาศ",
    subdistrict: "ทุ่งยางเมือง",
    zipcode: 64160,
    latitude: 16.748,
    longitude: 99.834,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "กง",
    zipcode: 64170,
    latitude: 16.893,
    longitude: 99.984,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "บ้านกร่าง",
    zipcode: 64170,
    latitude: 16.947,
    longitude: 99.939,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "ไกรนอก",
    zipcode: 64170,
    latitude: 16.907,
    longitude: 100.083,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "ไกรกลาง",
    zipcode: 64170,
    latitude: 16.996,
    longitude: 99.997,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "ไกรใน",
    zipcode: 64170,
    latitude: 17.022,
    longitude: 99.947,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "ดงเดือย",
    zipcode: 64170,
    latitude: 16.92,
    longitude: 100.011,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "ป่าแฝก",
    zipcode: 64170,
    latitude: 16.969,
    longitude: 99.927,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "กกแรต",
    zipcode: 64170,
    latitude: 17.005,
    longitude: 100.027,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "ท่าฉนวน",
    zipcode: 64170,
    latitude: 16.891,
    longitude: 99.878,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "หนองตูม",
    zipcode: 64170,
    latitude: 16.88,
    longitude: 99.949,
  },
  {
    province: "สุโขทัย",
    district: "กงไกรลาศ",
    subdistrict: "บ้านใหม่สุขเกษม",
    zipcode: 64170,
    latitude: 16.954,
    longitude: 100.078,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "หาดเสี้ยว",
    zipcode: 64130,
    latitude: 17.52,
    longitude: 99.784,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "ป่างิ้ว",
    zipcode: 64130,
    latitude: 17.55,
    longitude: 99.825,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "แม่สำ",
    zipcode: 64130,
    latitude: 17.608,
    longitude: 99.713,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "แม่สิน",
    zipcode: 64130,
    latitude: 17.736,
    longitude: 99.585,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "บ้านตึก",
    zipcode: 64130,
    latitude: 17.649,
    longitude: 99.881,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "หนองอ้อ",
    zipcode: 64130,
    latitude: 17.484,
    longitude: 99.751,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "ท่าชัย",
    zipcode: 64190,
    latitude: 17.444,
    longitude: 99.846,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "ศรีสัชนาลัย",
    zipcode: 64190,
    latitude: 17.409,
    longitude: 99.778,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "ดงคู่",
    zipcode: 64130,
    latitude: 17.547,
    longitude: 99.899,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "บ้านแก่ง",
    zipcode: 64130,
    latitude: 17.449,
    longitude: 99.643,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสัชนาลัย",
    subdistrict: "สารจิตร",
    zipcode: 64130,
    latitude: 17.42,
    longitude: 99.728,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "คลองตาล",
    zipcode: 64120,
    latitude: 17.158,
    longitude: 99.858,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "วังลึก",
    zipcode: 64120,
    latitude: 17.147,
    longitude: 99.883,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "สามเรือน",
    zipcode: 64120,
    latitude: 17.183,
    longitude: 99.872,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "บ้านนา",
    zipcode: 64120,
    latitude: 17.171,
    longitude: 99.835,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "วังทอง",
    zipcode: 64120,
    latitude: 17.139,
    longitude: 99.811,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "นาขุนไกร",
    zipcode: 64120,
    latitude: 17.196,
    longitude: 99.582,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "เกาะตาเลี้ยง",
    zipcode: 64120,
    latitude: 17.111,
    longitude: 99.908,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "วัดเกาะ",
    zipcode: 64120,
    latitude: 17.188,
    longitude: 99.827,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "บ้านไร่",
    zipcode: 64120,
    latitude: 17.164,
    longitude: 99.764,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "ทับผึ้ง",
    zipcode: 64120,
    latitude: 17.084,
    longitude: 99.83,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "บ้านซ่าน",
    zipcode: 64120,
    latitude: 17.203,
    longitude: 99.77,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "วังใหญ่",
    zipcode: 64120,
    latitude: 17.118,
    longitude: 99.765,
  },
  {
    province: "สุโขทัย",
    district: "ศรีสำโรง",
    subdistrict: "ราวต้นจันทร์",
    zipcode: 64120,
    latitude: 17.174,
    longitude: 99.723,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "เมืองสวรรคโลก",
    zipcode: 64110,
    latitude: 17.315,
    longitude: 99.834,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "ในเมือง",
    zipcode: 64110,
    latitude: 17.326,
    longitude: 99.878,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "คลองกระจง",
    zipcode: 64110,
    latitude: 17.239,
    longitude: 99.828,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "วังพิณพาทย์",
    zipcode: 64110,
    latitude: 17.335,
    longitude: 99.815,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "วังไม้ขอน",
    zipcode: 64110,
    latitude: 17.31,
    longitude: 99.807,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "ย่านยาว",
    zipcode: 64110,
    latitude: 17.273,
    longitude: 99.816,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "นาทุ่ง",
    zipcode: 64110,
    latitude: 17.311,
    longitude: 99.74,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "คลองยาง",
    zipcode: 64110,
    latitude: 17.414,
    longitude: 99.915,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "เมืองบางยม",
    zipcode: 64110,
    latitude: 17.214,
    longitude: 99.876,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "ท่าทอง",
    zipcode: 64110,
    latitude: 17.212,
    longitude: 99.85,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "ปากน้ำ",
    zipcode: 64110,
    latitude: 17.233,
    longitude: 99.925,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "ป่ากุมเกาะ",
    zipcode: 64110,
    latitude: 17.365,
    longitude: 99.794,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "เมืองบางขลัง",
    zipcode: 64110,
    latitude: 17.242,
    longitude: 99.71,
  },
  {
    province: "สุโขทัย",
    district: "สวรรคโลก",
    subdistrict: "หนองกลับ",
    zipcode: 64110,
    latitude: 17.245,
    longitude: 99.782,
  },
  {
    province: "สุโขทัย",
    district: "ศรีนคร",
    subdistrict: "ศรีนคร",
    zipcode: 64180,
    latitude: 17.373,
    longitude: 99.98,
  },
  {
    province: "สุโขทัย",
    district: "ศรีนคร",
    subdistrict: "นครเดิฐ",
    zipcode: 64180,
    latitude: 17.471,
    longitude: 99.955,
  },
  {
    province: "สุโขทัย",
    district: "ศรีนคร",
    subdistrict: "น้ำขุม",
    zipcode: 64180,
    latitude: 17.412,
    longitude: 99.982,
  },
  {
    province: "สุโขทัย",
    district: "ศรีนคร",
    subdistrict: "คลองมะพลับ",
    zipcode: 64180,
    latitude: 17.319,
    longitude: 99.962,
  },
  {
    province: "สุโขทัย",
    district: "ศรีนคร",
    subdistrict: "หนองบัว",
    zipcode: 64180,
    latitude: 17.285,
    longitude: 99.958,
  },
  {
    province: "สุโขทัย",
    district: "ทุ่งเสลี่ยม",
    subdistrict: "บ้านใหม่ไชยมงคล",
    zipcode: 64230,
    latitude: 17.251,
    longitude: 99.652,
  },
  {
    province: "สุโขทัย",
    district: "ทุ่งเสลี่ยม",
    subdistrict: "ไทยชนะศึก",
    zipcode: 64150,
    latitude: 17.376,
    longitude: 99.589,
  },
  {
    province: "สุโขทัย",
    district: "ทุ่งเสลี่ยม",
    subdistrict: "ทุ่งเสลี่ยม",
    zipcode: 64150,
    latitude: 17.301,
    longitude: 99.548,
  },
  {
    province: "สุโขทัย",
    district: "ทุ่งเสลี่ยม",
    subdistrict: "กลางดง",
    zipcode: 64150,
    latitude: 17.394,
    longitude: 99.491,
  },
  {
    province: "สุโขทัย",
    district: "ทุ่งเสลี่ยม",
    subdistrict: "เขาแก้วศรีสมบูรณ์",
    zipcode: 64230,
    latitude: 17.32,
    longitude: 99.643,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "ในเมือง",
    zipcode: 65000,
    latitude: 16.815,
    longitude: 100.26,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "วังน้ำคู้",
    zipcode: 65230,
    latitude: 16.652,
    longitude: 100.269,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "วัดจันทร์",
    zipcode: 65000,
    latitude: 16.789,
    longitude: 100.25,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "วัดพริก",
    zipcode: 65230,
    latitude: 16.715,
    longitude: 100.243,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "ท่าทอง",
    zipcode: 65000,
    latitude: 16.798,
    longitude: 100.197,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "ท่าโพธิ์",
    zipcode: 65000,
    latitude: 16.758,
    longitude: 100.195,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "สมอแข",
    zipcode: 65000,
    latitude: 16.823,
    longitude: 100.325,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "ดอนทอง",
    zipcode: 65000,
    latitude: 16.884,
    longitude: 100.392,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "บ้านป่า",
    zipcode: 65000,
    latitude: 16.926,
    longitude: 100.368,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "ปากโทก",
    zipcode: 65000,
    latitude: 16.905,
    longitude: 100.259,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "หัวรอ",
    zipcode: 65000,
    latitude: 16.875,
    longitude: 100.274,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "จอมทอง",
    zipcode: 65000,
    latitude: 16.902,
    longitude: 100.215,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "บ้านกร่าง",
    zipcode: 65000,
    latitude: 16.855,
    longitude: 100.184,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "บ้านคลอง",
    zipcode: 65000,
    latitude: 16.83,
    longitude: 100.239,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "พลายชุมพล",
    zipcode: 65000,
    latitude: 16.854,
    longitude: 100.226,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "มะขามสูง",
    zipcode: 65000,
    latitude: 16.929,
    longitude: 100.256,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "อรัญญิก",
    zipcode: 65000,
    latitude: 16.8,
    longitude: 100.288,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "บึงพระ",
    zipcode: 65000,
    latitude: 16.749,
    longitude: 100.273,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "ไผ่ขอดอน",
    zipcode: 65000,
    latitude: 16.911,
    longitude: 100.158,
  },
  {
    province: "พิษณุโลก",
    district: "เมืองพิษณุโลก",
    subdistrict: "งิ้วงาม",
    zipcode: 65230,
    latitude: 16.671,
    longitude: 100.237,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "นครไทย",
    zipcode: 65120,
    latitude: 17.126,
    longitude: 100.839,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "หนองกะท้าว",
    zipcode: 65120,
    latitude: 17.096,
    longitude: 100.674,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "บ้านแยง",
    zipcode: 65120,
    latitude: 16.942,
    longitude: 100.71,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "เนินเพิ่ม",
    zipcode: 65120,
    latitude: 17.033,
    longitude: 100.946,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "นาบัว",
    zipcode: 65120,
    latitude: 17.203,
    longitude: 100.851,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "นครชุม",
    zipcode: 65120,
    latitude: 17.26,
    longitude: 100.806,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "น้ำกุ่ม",
    zipcode: 65120,
    latitude: 17.398,
    longitude: 100.866,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "ยางโกลน",
    zipcode: 65120,
    latitude: 17.207,
    longitude: 100.95,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "บ่อโพธิ์",
    zipcode: 65120,
    latitude: 17.161,
    longitude: 101.017,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "บ้านพร้าว",
    zipcode: 65120,
    latitude: 17.146,
    longitude: 100.76,
  },
  {
    province: "พิษณุโลก",
    district: "นครไทย",
    subdistrict: "ห้วยเฮี้ย",
    zipcode: 65120,
    latitude: 16.899,
    longitude: 100.923,
  },
  {
    province: "พิษณุโลก",
    district: "ชาติตระการ",
    subdistrict: "ป่าแดง",
    zipcode: 65170,
    latitude: 17.285,
    longitude: 100.561,
  },
  {
    province: "พิษณุโลก",
    district: "ชาติตระการ",
    subdistrict: "ชาติตระการ",
    zipcode: 65170,
    latitude: 17.32,
    longitude: 100.704,
  },
  {
    province: "พิษณุโลก",
    district: "ชาติตระการ",
    subdistrict: "สวนเมี่ยง",
    zipcode: 65170,
    latitude: 17.194,
    longitude: 100.529,
  },
  {
    province: "พิษณุโลก",
    district: "ชาติตระการ",
    subdistrict: "บ้านดง",
    zipcode: 65170,
    latitude: 17.361,
    longitude: 100.505,
  },
  {
    province: "พิษณุโลก",
    district: "ชาติตระการ",
    subdistrict: "บ่อภาค",
    zipcode: 65170,
    latitude: 17.54,
    longitude: 100.817,
  },
  {
    province: "พิษณุโลก",
    district: "ชาติตระการ",
    subdistrict: "ท่าสะแก",
    zipcode: 65170,
    latitude: 17.241,
    longitude: 100.711,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "บางระกำ",
    zipcode: 65140,
    latitude: 16.738,
    longitude: 100.145,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "ปลักแรด",
    zipcode: 65140,
    latitude: 16.693,
    longitude: 100.094,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "พันเสา",
    zipcode: 65140,
    latitude: 16.622,
    longitude: 100.064,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "วังอิทก",
    zipcode: 65140,
    latitude: 16.665,
    longitude: 100.164,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "บึงกอก",
    zipcode: 65140,
    latitude: 16.7,
    longitude: 100.011,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "หนองกุลา",
    zipcode: 65140,
    latitude: 16.636,
    longitude: 99.96,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "ชุมแสงสงคราม",
    zipcode: 65240,
    latitude: 16.76,
    longitude: 99.977,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "นิคมพัฒนา",
    zipcode: 65140,
    latitude: 16.741,
    longitude: 99.885,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "บ่อทอง",
    zipcode: 65140,
    latitude: 16.631,
    longitude: 100.134,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "ท่านางงาม",
    zipcode: 65140,
    latitude: 16.81,
    longitude: 100.114,
  },
  {
    province: "พิษณุโลก",
    district: "บางระกำ",
    subdistrict: "คุยม่วง",
    zipcode: 65240,
    latitude: 16.82,
    longitude: 99.962,
  },
  {
    province: "พิษณุโลก",
    district: "บางกระทุ่ม",
    subdistrict: "บางกระทุ่ม",
    zipcode: 65110,
    latitude: 16.591,
    longitude: 100.297,
  },
  {
    province: "พิษณุโลก",
    district: "บางกระทุ่ม",
    subdistrict: "บ้านไร่",
    zipcode: 65110,
    latitude: 16.62,
    longitude: 100.253,
  },
  {
    province: "พิษณุโลก",
    district: "บางกระทุ่ม",
    subdistrict: "โคกสลุด",
    zipcode: 65110,
    latitude: 16.577,
    longitude: 100.245,
  },
  {
    province: "พิษณุโลก",
    district: "บางกระทุ่ม",
    subdistrict: "สนามคลี",
    zipcode: 65110,
    latitude: 16.547,
    longitude: 100.256,
  },
  {
    province: "พิษณุโลก",
    district: "บางกระทุ่ม",
    subdistrict: "ท่าตาล",
    zipcode: 65110,
    latitude: 16.658,
    longitude: 100.331,
  },
  {
    province: "พิษณุโลก",
    district: "บางกระทุ่ม",
    subdistrict: "ไผ่ล้อม",
    zipcode: 65110,
    latitude: 16.56,
    longitude: 100.347,
  },
  {
    province: "พิษณุโลก",
    district: "บางกระทุ่ม",
    subdistrict: "นครป่าหมาก",
    zipcode: 65110,
    latitude: 16.609,
    longitude: 100.351,
  },
  {
    province: "พิษณุโลก",
    district: "บางกระทุ่ม",
    subdistrict: "เนินกุ่ม",
    zipcode: 65210,
    latitude: 16.541,
    longitude: 100.458,
  },
  {
    province: "พิษณุโลก",
    district: "บางกระทุ่ม",
    subdistrict: "วัดตายม",
    zipcode: 65210,
    latitude: 16.595,
    longitude: 100.444,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "พรหมพิราม",
    zipcode: 65150,
    latitude: 17.004,
    longitude: 100.135,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "ท่าช้าง",
    zipcode: 65150,
    latitude: 16.976,
    longitude: 100.168,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "วงฆ้อง",
    zipcode: 65180,
    latitude: 17.113,
    longitude: 100.107,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "มะตูม",
    zipcode: 65150,
    latitude: 16.936,
    longitude: 100.205,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "หอกลอง",
    zipcode: 65150,
    latitude: 16.985,
    longitude: 100.251,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "ศรีภิรมย์",
    zipcode: 65180,
    latitude: 17.094,
    longitude: 100.029,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "ตลุกเทียม",
    zipcode: 65180,
    latitude: 17.149,
    longitude: 100.039,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "วังวน",
    zipcode: 65150,
    latitude: 17.05,
    longitude: 100.029,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "หนองแขม",
    zipcode: 65150,
    latitude: 17.046,
    longitude: 100.131,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "มะต้อง",
    zipcode: 65180,
    latitude: 17.086,
    longitude: 100.166,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "ทับยายเชียง",
    zipcode: 65150,
    latitude: 17.095,
    longitude: 100.294,
  },
  {
    province: "พิษณุโลก",
    district: "พรหมพิราม",
    subdistrict: "ดงประคำ",
    zipcode: 65180,
    latitude: 17.189,
    longitude: 100.26,
  },
  {
    province: "พิษณุโลก",
    district: "วัดโบสถ์",
    subdistrict: "วัดโบสถ์",
    zipcode: 65160,
    latitude: 17.021,
    longitude: 100.306,
  },
  {
    province: "พิษณุโลก",
    district: "วัดโบสถ์",
    subdistrict: "ท่างาม",
    zipcode: 65160,
    latitude: 16.97,
    longitude: 100.377,
  },
  {
    province: "พิษณุโลก",
    district: "วัดโบสถ์",
    subdistrict: "ท้อแท้",
    zipcode: 65160,
    latitude: 16.968,
    longitude: 100.296,
  },
  {
    province: "พิษณุโลก",
    district: "วัดโบสถ์",
    subdistrict: "บ้านยาง",
    zipcode: 65160,
    latitude: 17.089,
    longitude: 100.413,
  },
  {
    province: "พิษณุโลก",
    district: "วัดโบสถ์",
    subdistrict: "หินลาด",
    zipcode: 65160,
    latitude: 17.168,
    longitude: 100.333,
  },
  {
    province: "พิษณุโลก",
    district: "วัดโบสถ์",
    subdistrict: "คันโช้ง",
    zipcode: 65160,
    latitude: 17.307,
    longitude: 100.298,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "วังทอง",
    zipcode: 65130,
    latitude: 16.822,
    longitude: 100.403,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "พันชาลี",
    zipcode: 65130,
    latitude: 16.608,
    longitude: 100.523,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "แม่ระกา",
    zipcode: 65130,
    latitude: 16.707,
    longitude: 100.362,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "บ้านกลาง",
    zipcode: 65220,
    latitude: 17.017,
    longitude: 100.549,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "วังพิกุล",
    zipcode: 65130,
    latitude: 16.775,
    longitude: 100.358,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "แก่งโสภา",
    zipcode: 65220,
    latitude: 16.907,
    longitude: 100.625,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "ท่าหมื่นราม",
    zipcode: 65130,
    latitude: 16.691,
    longitude: 100.514,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "วังนกแอ่น",
    zipcode: 65130,
    latitude: 16.819,
    longitude: 100.582,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "หนองพระ",
    zipcode: 65130,
    latitude: 16.681,
    longitude: 100.441,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "ชัยนาม",
    zipcode: 65130,
    latitude: 16.839,
    longitude: 100.467,
  },
  {
    province: "พิษณุโลก",
    district: "วังทอง",
    subdistrict: "ดินทอง",
    zipcode: 65130,
    latitude: 16.767,
    longitude: 100.445,
  },
  {
    province: "พิษณุโลก",
    district: "เนินมะปราง",
    subdistrict: "ชมพู",
    zipcode: 65190,
    latitude: 16.717,
    longitude: 100.641,
  },
  {
    province: "พิษณุโลก",
    district: "เนินมะปราง",
    subdistrict: "บ้านมุง",
    zipcode: 65190,
    latitude: 16.543,
    longitude: 100.752,
  },
  {
    province: "พิษณุโลก",
    district: "เนินมะปราง",
    subdistrict: "ไทรย้อย",
    zipcode: 65190,
    latitude: 16.452,
    longitude: 100.634,
  },
  {
    province: "พิษณุโลก",
    district: "เนินมะปราง",
    subdistrict: "วังโพรง",
    zipcode: 65190,
    latitude: 16.383,
    longitude: 100.664,
  },
  {
    province: "พิษณุโลก",
    district: "เนินมะปราง",
    subdistrict: "บ้านน้อยซุ้มขี้เหล็ก",
    zipcode: 65190,
    latitude: 16.542,
    longitude: 100.596,
  },
  {
    province: "พิษณุโลก",
    district: "เนินมะปราง",
    subdistrict: "เนินมะปราง",
    zipcode: 65190,
    latitude: 16.595,
    longitude: 100.626,
  },
  {
    province: "พิษณุโลก",
    district: "เนินมะปราง",
    subdistrict: "วังยาง",
    zipcode: 65190,
    latitude: 16.457,
    longitude: 100.741,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ในเมือง",
    zipcode: 66000,
    latitude: 16.443,
    longitude: 100.343,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ไผ่ขวาง",
    zipcode: 66000,
    latitude: 16.504,
    longitude: 100.303,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ย่านยาว",
    zipcode: 66000,
    latitude: 16.507,
    longitude: 100.268,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ท่าฬ่อ",
    zipcode: 66000,
    latitude: 16.51,
    longitude: 100.352,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ปากทาง",
    zipcode: 66000,
    latitude: 16.453,
    longitude: 100.366,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "คลองคะเชนทร์",
    zipcode: 66000,
    latitude: 16.458,
    longitude: 100.293,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "โรงช้าง",
    zipcode: 66000,
    latitude: 16.422,
    longitude: 100.259,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "เมืองเก่า",
    zipcode: 66000,
    latitude: 16.393,
    longitude: 100.303,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ท่าหลวง",
    zipcode: 66000,
    latitude: 16.419,
    longitude: 100.363,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "บ้านบุ่ง",
    zipcode: 66000,
    latitude: 16.394,
    longitude: 100.427,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ฆะมัง",
    zipcode: 66000,
    latitude: 16.369,
    longitude: 100.373,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ดงป่าคำ",
    zipcode: 66170,
    latitude: 16.295,
    longitude: 100.367,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "หัวดง",
    zipcode: 66170,
    latitude: 16.332,
    longitude: 100.441,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ป่ามะคาบ",
    zipcode: 66000,
    latitude: 16.501,
    longitude: 100.392,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "สายคำโห้",
    zipcode: 66000,
    latitude: 16.407,
    longitude: 100.486,
  },
  {
    province: "พิจิตร",
    district: "เมืองพิจิตร",
    subdistrict: "ดงกลาง",
    zipcode: 66170,
    latitude: 16.335,
    longitude: 100.363,
  },
  {
    province: "พิจิตร",
    district: "วังทรายพูน",
    subdistrict: "วังทรายพูน",
    zipcode: 66180,
    latitude: 16.383,
    longitude: 100.605,
  },
  {
    province: "พิจิตร",
    district: "วังทรายพูน",
    subdistrict: "หนองปลาไหล",
    zipcode: 66180,
    latitude: 16.443,
    longitude: 100.535,
  },
  {
    province: "พิจิตร",
    district: "วังทรายพูน",
    subdistrict: "หนองพระ",
    zipcode: 66180,
    latitude: 16.342,
    longitude: 100.566,
  },
  {
    province: "พิจิตร",
    district: "วังทรายพูน",
    subdistrict: "หนองปล้อง",
    zipcode: 66180,
    latitude: 16.339,
    longitude: 100.504,
  },
  {
    province: "พิจิตร",
    district: "โพธิ์ประทับช้าง",
    subdistrict: "โพธิ์ประทับช้าง",
    zipcode: 66190,
    latitude: 16.323,
    longitude: 100.3,
  },
  {
    province: "พิจิตร",
    district: "โพธิ์ประทับช้าง",
    subdistrict: "ไผ่ท่าโพ",
    zipcode: 66190,
    latitude: 16.278,
    longitude: 100.235,
  },
  {
    province: "พิจิตร",
    district: "โพธิ์ประทับช้าง",
    subdistrict: "วังจิก",
    zipcode: 66190,
    latitude: 16.351,
    longitude: 100.264,
  },
  {
    province: "พิจิตร",
    district: "โพธิ์ประทับช้าง",
    subdistrict: "ไผ่รอบ",
    zipcode: 66190,
    latitude: 16.354,
    longitude: 100.152,
  },
  {
    province: "พิจิตร",
    district: "โพธิ์ประทับช้าง",
    subdistrict: "ดงเสือเหลือง",
    zipcode: 66190,
    latitude: 16.27,
    longitude: 100.182,
  },
  {
    province: "พิจิตร",
    district: "โพธิ์ประทับช้าง",
    subdistrict: "เนินสว่าง",
    zipcode: 66190,
    latitude: 16.318,
    longitude: 100.138,
  },
  {
    province: "พิจิตร",
    district: "โพธิ์ประทับช้าง",
    subdistrict: "ทุ่งใหญ่",
    zipcode: 66190,
    latitude: 16.292,
    longitude: 100.071,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "ตะพานหิน",
    zipcode: 66110,
    latitude: 16.222,
    longitude: 100.427,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "งิ้วราย",
    zipcode: 66110,
    latitude: 16.267,
    longitude: 100.427,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "ห้วยเกตุ",
    zipcode: 66110,
    latitude: 16.238,
    longitude: 100.399,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "ไทรโรงโขน",
    zipcode: 66110,
    latitude: 16.168,
    longitude: 100.421,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "หนองพยอม",
    zipcode: 66110,
    latitude: 16.235,
    longitude: 100.46,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "ทุ่งโพธิ์",
    zipcode: 66150,
    latitude: 16.129,
    longitude: 100.501,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "ดงตะขบ",
    zipcode: 66110,
    latitude: 16.144,
    longitude: 100.467,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "คลองคูณ",
    zipcode: 66110,
    latitude: 16.176,
    longitude: 100.395,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "วังสำโรง",
    zipcode: 66110,
    latitude: 16.252,
    longitude: 100.308,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "วังหว้า",
    zipcode: 66110,
    latitude: 16.207,
    longitude: 100.354,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "วังหลุม",
    zipcode: 66150,
    latitude: 16.19,
    longitude: 100.484,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "ทับหมัน",
    zipcode: 66110,
    latitude: 16.207,
    longitude: 100.277,
  },
  {
    province: "พิจิตร",
    district: "ตะพานหิน",
    subdistrict: "ไผ่หลวง",
    zipcode: 66110,
    latitude: 16.193,
    longitude: 100.428,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "บางมูลนาก",
    zipcode: 66120,
    latitude: 16.044622,
    longitude: 100.3390109,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "บางไผ่",
    zipcode: 66120,
    latitude: 16.123,
    longitude: 100.403,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "หอไกร",
    zipcode: 66120,
    latitude: 16.068,
    longitude: 100.381,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "เนินมะกอก",
    zipcode: 66120,
    latitude: 15.991,
    longitude: 100.362,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "วังสำโรง",
    zipcode: 66120,
    latitude: 15.968,
    longitude: 100.433,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "ภูมิ",
    zipcode: 66120,
    latitude: 16.037,
    longitude: 100.445,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "วังกรด",
    zipcode: 66120,
    latitude: 15.997,
    longitude: 100.465,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "ห้วยเขน",
    zipcode: 66120,
    latitude: 16.033,
    longitude: 100.479,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "วังตะกู",
    zipcode: 66210,
    latitude: 16.049,
    longitude: 100.514,
  },
  {
    province: "พิจิตร",
    district: "บางมูลนาก",
    subdistrict: "ลำประดา",
    zipcode: 66120,
    latitude: 16.082,
    longitude: 100.464,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "โพทะเล",
    zipcode: 66130,
    latitude: 16.103,
    longitude: 100.236,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "ท้ายน้ำ",
    zipcode: 66130,
    latitude: 16.147,
    longitude: 100.207,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "ทะนง",
    zipcode: 66130,
    latitude: 15.963,
    longitude: 100.276,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "ท่าบัว",
    zipcode: 66130,
    latitude: 16.059,
    longitude: 100.326,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "ทุ่งน้อย",
    zipcode: 66130,
    latitude: 16.117,
    longitude: 100.315,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "ท่าขมิ้น",
    zipcode: 66130,
    latitude: 16.025,
    longitude: 100.2,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "ท่าเสา",
    zipcode: 66130,
    latitude: 15.966,
    longitude: 100.212,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "บางคลาน",
    zipcode: 66130,
    latitude: 16.001,
    longitude: 100.286,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "ท่านั่ง",
    zipcode: 66130,
    latitude: 15.963,
    longitude: 100.276,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "บ้านน้อย",
    zipcode: 66130,
    latitude: 16.043,
    longitude: 100.288,
  },
  {
    province: "พิจิตร",
    district: "โพทะเล",
    subdistrict: "วัดขวาง",
    zipcode: 66130,
    latitude: 16.16,
    longitude: 100.298,
  },
  {
    province: "พิจิตร",
    district: "สามง่าม",
    subdistrict: "สามง่าม",
    zipcode: 66140,
    latitude: 16.498,
    longitude: 100.213,
  },
  {
    province: "พิจิตร",
    district: "สามง่าม",
    subdistrict: "กำแพงดิน",
    zipcode: 66220,
    latitude: 16.585,
    longitude: 100.219,
  },
  {
    province: "พิจิตร",
    district: "สามง่าม",
    subdistrict: "รังนก",
    zipcode: 66140,
    latitude: 16.432,
    longitude: 100.212,
  },
  {
    province: "พิจิตร",
    district: "สามง่าม",
    subdistrict: "เนินปอ",
    zipcode: 66140,
    latitude: 16.408,
    longitude: 100.136,
  },
  {
    province: "พิจิตร",
    district: "สามง่าม",
    subdistrict: "หนองโสน",
    zipcode: 66140,
    latitude: 16.389,
    longitude: 100.044,
  },
  {
    province: "พิจิตร",
    district: "ทับคล้อ",
    subdistrict: "ทับคล้อ",
    zipcode: 66150,
    latitude: 16.189,
    longitude: 100.568,
  },
  {
    province: "พิจิตร",
    district: "ทับคล้อ",
    subdistrict: "เขาทราย",
    zipcode: 66230,
    latitude: 16.193,
    longitude: 100.637,
  },
  {
    province: "พิจิตร",
    district: "ทับคล้อ",
    subdistrict: "เขาเจ็ดลูก",
    zipcode: 66230,
    latitude: 16.274,
    longitude: 100.569,
  },
  {
    province: "พิจิตร",
    district: "ทับคล้อ",
    subdistrict: "ท้ายทุ่ง",
    zipcode: 66150,
    latitude: 16.086,
    longitude: 100.618,
  },
  {
    province: "พิจิตร",
    district: "สากเหล็ก",
    subdistrict: "สากเหล็ก",
    zipcode: 66160,
    latitude: 16.486,
    longitude: 100.461,
  },
  {
    province: "พิจิตร",
    district: "สากเหล็ก",
    subdistrict: "ท่าเยี่ยม",
    zipcode: 66160,
    latitude: 16.522,
    longitude: 100.556,
  },
  {
    province: "พิจิตร",
    district: "สากเหล็ก",
    subdistrict: "คลองทราย",
    zipcode: 66160,
    latitude: 16.552,
    longitude: 100.559,
  },
  {
    province: "พิจิตร",
    district: "สากเหล็ก",
    subdistrict: "หนองหญ้าไทร",
    zipcode: 66160,
    latitude: 16.544,
    longitude: 100.527,
  },
  {
    province: "พิจิตร",
    district: "สากเหล็ก",
    subdistrict: "วังทับไทร",
    zipcode: 66160,
    latitude: 16.489,
    longitude: 100.555,
  },
  {
    province: "พิจิตร",
    district: "บึงนาราง",
    subdistrict: "ห้วยแก้ว",
    zipcode: 66130,
    latitude: 16.162,
    longitude: 100.111,
  },
  {
    province: "พิจิตร",
    district: "บึงนาราง",
    subdistrict: "โพธิ์ไทรงาม",
    zipcode: 66130,
    latitude: 16.121,
    longitude: 100.147,
  },
  {
    province: "พิจิตร",
    district: "บึงนาราง",
    subdistrict: "แหลมรัง",
    zipcode: 66130,
    latitude: 16.225,
    longitude: 100.073,
  },
  {
    province: "พิจิตร",
    district: "บึงนาราง",
    subdistrict: "บางลาย",
    zipcode: 66130,
    latitude: 16.208,
    longitude: 100.231,
  },
  {
    province: "พิจิตร",
    district: "บึงนาราง",
    subdistrict: "บึงนาราง",
    zipcode: 66130,
    latitude: 16.205,
    longitude: 100.163,
  },
  {
    province: "พิจิตร",
    district: "ดงเจริญ",
    subdistrict: "วังงิ้วใต้",
    zipcode: 66210,
    latitude: 16.028,
    longitude: 100.648,
  },
  {
    province: "พิจิตร",
    district: "ดงเจริญ",
    subdistrict: "วังงิ้ว",
    zipcode: 66210,
    latitude: 16,
    longitude: 100.741,
  },
  {
    province: "พิจิตร",
    district: "ดงเจริญ",
    subdistrict: "ห้วยร่วม",
    zipcode: 66210,
    latitude: 15.972,
    longitude: 100.536,
  },
  {
    province: "พิจิตร",
    district: "ดงเจริญ",
    subdistrict: "ห้วยพุก",
    zipcode: 66210,
    latitude: 16.004,
    longitude: 100.609,
  },
  {
    province: "พิจิตร",
    district: "ดงเจริญ",
    subdistrict: "สำนักขุนเณร",
    zipcode: 66210,
    latitude: 16.042,
    longitude: 100.568,
  },
  {
    province: "พิจิตร",
    district: "วชิรบารมี",
    subdistrict: "บ้านนา",
    zipcode: 66140,
    latitude: 16.495,
    longitude: 100.128,
  },
  {
    province: "พิจิตร",
    district: "วชิรบารมี",
    subdistrict: "บึงบัว",
    zipcode: 66140,
    latitude: 16.484,
    longitude: 100.053,
  },
  {
    province: "พิจิตร",
    district: "วชิรบารมี",
    subdistrict: "วังโมกข์",
    zipcode: 66140,
    latitude: 16.563,
    longitude: 100.091,
  },
  {
    province: "พิจิตร",
    district: "วชิรบารมี",
    subdistrict: "หนองหลุม",
    zipcode: 66220,
    latitude: 16.597,
    longitude: 100.114,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "ในเมือง",
    zipcode: 67000,
    latitude: 16.42,
    longitude: 101.156,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "ตะเบาะ",
    zipcode: 67000,
    latitude: 16.322,
    longitude: 101.299,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "บ้านโตก",
    zipcode: 67000,
    latitude: 16.347,
    longitude: 101.056,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "สะเดียง",
    zipcode: 67000,
    latitude: 16.396,
    longitude: 101.164,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "ป่าเลา",
    zipcode: 67000,
    latitude: 16.449,
    longitude: 100.972,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "นางั่ว",
    zipcode: 67000,
    latitude: 16.507,
    longitude: 101.146,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "ท่าพล",
    zipcode: 67250,
    latitude: 16.59,
    longitude: 101.134,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "ดงมูลเหล็ก",
    zipcode: 67000,
    latitude: 16.478,
    longitude: 101.203,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "บ้านโคก",
    zipcode: 67000,
    latitude: 16.471,
    longitude: 101.25,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "ชอนไพร",
    zipcode: 67000,
    latitude: 16.341,
    longitude: 101.153,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "นาป่า",
    zipcode: 67000,
    latitude: 16.39,
    longitude: 101.218,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "นายม",
    zipcode: 67210,
    latitude: 16.229,
    longitude: 101.23,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "วังชมภู",
    zipcode: 67210,
    latitude: 16.266,
    longitude: 101.027,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "น้ำร้อน",
    zipcode: 67000,
    latitude: 16.322,
    longitude: 101.187,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "ห้วยสะแก",
    zipcode: 67210,
    latitude: 16.191,
    longitude: 101.067,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "ห้วยใหญ่",
    zipcode: 67000,
    latitude: 16.505,
    longitude: 101.383,
  },
  {
    province: "เพชรบูรณ์",
    district: "เมืองเพชรบูรณ์",
    subdistrict: "ระวิง",
    zipcode: 67210,
    latitude: 16.146,
    longitude: 101.138,
  },
  {
    province: "เพชรบูรณ์",
    district: "ชนแดน",
    subdistrict: "ชนแดน",
    zipcode: 67150,
    latitude: 16.158,
    longitude: 100.895,
  },
  {
    province: "เพชรบูรณ์",
    district: "ชนแดน",
    subdistrict: "ดงขุย",
    zipcode: 67190,
    latitude: 16.131,
    longitude: 100.677,
  },
  {
    province: "เพชรบูรณ์",
    district: "ชนแดน",
    subdistrict: "ท่าข้าม",
    zipcode: 67150,
    latitude: 16.204,
    longitude: 100.792,
  },
  {
    province: "เพชรบูรณ์",
    district: "ชนแดน",
    subdistrict: "พุทธบาท",
    zipcode: 67150,
    latitude: 16.271,
    longitude: 100.892,
  },
  {
    province: "เพชรบูรณ์",
    district: "ชนแดน",
    subdistrict: "ลาดแค",
    zipcode: 67150,
    latitude: 15.983,
    longitude: 100.839,
  },
  {
    province: "เพชรบูรณ์",
    district: "ชนแดน",
    subdistrict: "บ้านกล้วย",
    zipcode: 67190,
    latitude: 16.05,
    longitude: 100.744,
  },
  {
    province: "เพชรบูรณ์",
    district: "ชนแดน",
    subdistrict: "ซับพุทรา",
    zipcode: 67150,
    latitude: 16.035,
    longitude: 100.928,
  },
  {
    province: "เพชรบูรณ์",
    district: "ชนแดน",
    subdistrict: "ตะกุดไร",
    zipcode: 67190,
    latitude: 16.18,
    longitude: 100.726,
  },
  {
    province: "เพชรบูรณ์",
    district: "ชนแดน",
    subdistrict: "ศาลาลาย",
    zipcode: 67150,
    latitude: 16.092,
    longitude: 100.832,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "หล่มสัก",
    zipcode: 67110,
    latitude: 16.742693,
    longitude: 101.1218008,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "วัดป่า",
    zipcode: 67110,
    latitude: 16.795,
    longitude: 101.234,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "ตาลเดี่ยว",
    zipcode: 67110,
    latitude: 16.785,
    longitude: 101.248,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "ฝายนาแซง",
    zipcode: 67110,
    latitude: 16.811,
    longitude: 101.206,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "หนองสว่าง",
    zipcode: 67110,
    latitude: 16.839,
    longitude: 101.239,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "น้ำเฮี้ย",
    zipcode: 67110,
    latitude: 16.83,
    longitude: 101.206,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "สักหลง",
    zipcode: 67110,
    latitude: 16.844,
    longitude: 101.275,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "ท่าอิบุญ",
    zipcode: 67110,
    latitude: 16.889,
    longitude: 101.346,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "บ้านโสก",
    zipcode: 67110,
    latitude: 16.803,
    longitude: 101.284,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "บ้านติ้ว",
    zipcode: 67110,
    latitude: 16.782,
    longitude: 101.357,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "ห้วยไร่",
    zipcode: 67110,
    latitude: 16.825,
    longitude: 101.319,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "น้ำก้อ",
    zipcode: 67110,
    latitude: 16.794,
    longitude: 101.158,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "ปากช่อง",
    zipcode: 67110,
    latitude: 16.708,
    longitude: 101.304,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "น้ำชุน",
    zipcode: 67110,
    latitude: 16.739,
    longitude: 101.143,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "หนองไขว่",
    zipcode: 67110,
    latitude: 16.757,
    longitude: 101.22,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "ลานบ่า",
    zipcode: 67110,
    latitude: 16.68,
    longitude: 101.208,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "บุ่งคล้า",
    zipcode: 67110,
    latitude: 16.629,
    longitude: 101.178,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "บุ่งน้ำเต้า",
    zipcode: 67110,
    latitude: 16.677,
    longitude: 101.146,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "บ้านกลาง",
    zipcode: 67110,
    latitude: 16.642,
    longitude: 101.298,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "ช้างตะลูด",
    zipcode: 67110,
    latitude: 16.581,
    longitude: 101.324,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "บ้านไร่",
    zipcode: 67110,
    latitude: 16.608,
    longitude: 101.212,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "ปากดุก",
    zipcode: 67110,
    latitude: 16.72,
    longitude: 101.249,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มสัก",
    subdistrict: "บ้านหวาย",
    zipcode: 67110,
    latitude: 16.759,
    longitude: 101.293,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มเก่า",
    subdistrict: "หล่มเก่า",
    zipcode: 67120,
    latitude: 16.893,
    longitude: 101.242,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มเก่า",
    subdistrict: "นาซำ",
    zipcode: 67120,
    latitude: 17.026,
    longitude: 101.217,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มเก่า",
    subdistrict: "หินฮาว",
    zipcode: 67120,
    latitude: 16.951,
    longitude: 101.215,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มเก่า",
    subdistrict: "บ้านเนิน",
    zipcode: 67120,
    latitude: 16.857,
    longitude: 101.095,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มเก่า",
    subdistrict: "ศิลา",
    zipcode: 67120,
    latitude: 17.108,
    longitude: 101.407,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มเก่า",
    subdistrict: "นาแซง",
    zipcode: 67120,
    latitude: 16.857,
    longitude: 101.229,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มเก่า",
    subdistrict: "วังบาล",
    zipcode: 67120,
    latitude: 16.929,
    longitude: 101.156,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มเก่า",
    subdistrict: "นาเกาะ",
    zipcode: 67120,
    latitude: 16.832,
    longitude: 101.176,
  },
  {
    province: "เพชรบูรณ์",
    district: "หล่มเก่า",
    subdistrict: "ตาดกลอย",
    zipcode: 67120,
    latitude: 16.998,
    longitude: 101.402,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "ท่าโรง",
    zipcode: 67130,
    latitude: 15.66,
    longitude: 101.141,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "สระประดู่",
    zipcode: 67130,
    latitude: 15.628,
    longitude: 101.061,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "สามแยก",
    zipcode: 67130,
    latitude: 15.709,
    longitude: 101.029,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "โคกปรง",
    zipcode: 67130,
    latitude: 15.814,
    longitude: 101.227,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "น้ำร้อน",
    zipcode: 67130,
    latitude: 15.687,
    longitude: 101.306,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "บ่อรัง",
    zipcode: 67130,
    latitude: 15.608,
    longitude: 101.217,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "พุเตย",
    zipcode: 67180,
    latitude: 15.586,
    longitude: 101.018,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "พุขาม",
    zipcode: 67180,
    latitude: 15.529,
    longitude: 101.044,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "ภูน้ำหยด",
    zipcode: 67180,
    latitude: 15.53,
    longitude: 100.92,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "ซับสมบูรณ์",
    zipcode: 67180,
    latitude: 15.669,
    longitude: 100.983,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "บึงกระจับ",
    zipcode: 67130,
    latitude: 15.744,
    longitude: 101.095,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "วังใหญ่",
    zipcode: 67180,
    latitude: 15.607,
    longitude: 100.949,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "ยางสาว",
    zipcode: 67130,
    latitude: 15.755,
    longitude: 101.216,
  },
  {
    province: "เพชรบูรณ์",
    district: "วิเชียรบุรี",
    subdistrict: "ซับน้อย",
    zipcode: 67180,
    latitude: 15.657,
    longitude: 100.858,
  },
  {
    province: "เพชรบูรณ์",
    district: "ศรีเทพ",
    subdistrict: "ศรีเทพ",
    zipcode: 67170,
    latitude: 15.439,
    longitude: 101.14,
  },
  {
    province: "เพชรบูรณ์",
    district: "ศรีเทพ",
    subdistrict: "สระกรวด",
    zipcode: 67170,
    latitude: 15.42,
    longitude: 101.07,
  },
  {
    province: "เพชรบูรณ์",
    district: "ศรีเทพ",
    subdistrict: "คลองกระจัง",
    zipcode: 67170,
    latitude: 15.37,
    longitude: 101.164,
  },
  {
    province: "เพชรบูรณ์",
    district: "ศรีเทพ",
    subdistrict: "นาสนุ่น",
    zipcode: 67170,
    latitude: 15.527,
    longitude: 101.136,
  },
  {
    province: "เพชรบูรณ์",
    district: "ศรีเทพ",
    subdistrict: "โคกสะอาด",
    zipcode: 67170,
    latitude: 15.477,
    longitude: 100.971,
  },
  {
    province: "เพชรบูรณ์",
    district: "ศรีเทพ",
    subdistrict: "หนองย่างทอย",
    zipcode: 67170,
    latitude: 15.402,
    longitude: 101.252,
  },
  {
    province: "เพชรบูรณ์",
    district: "ศรีเทพ",
    subdistrict: "ประดู่งาม",
    zipcode: 67170,
    latitude: 15.436,
    longitude: 100.971,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "กองทูล",
    zipcode: 67140,
    latitude: 15.956,
    longitude: 101.103,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "นาเฉลียง",
    zipcode: 67220,
    latitude: 16.075,
    longitude: 101.084,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "บ้านโภชน์",
    zipcode: 67140,
    latitude: 15.933,
    longitude: 100.928,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "ท่าแดง",
    zipcode: 67140,
    latitude: 15.957,
    longitude: 101.183,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "เพชรละคร",
    zipcode: 67140,
    latitude: 15.897,
    longitude: 101.154,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "บ่อไทย",
    zipcode: 67140,
    latitude: 16.129,
    longitude: 101.276,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "ห้วยโป่ง",
    zipcode: 67220,
    latitude: 16.141,
    longitude: 101.034,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "วังท่าดี",
    zipcode: 67140,
    latitude: 15.995,
    longitude: 101.223,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "บัววัฒนา",
    zipcode: 67140,
    latitude: 15.953,
    longitude: 101.034,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "หนองไผ่",
    zipcode: 67140,
    latitude: 16.004,
    longitude: 101.037,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "วังโบสถ์",
    zipcode: 67140,
    latitude: 16.057,
    longitude: 101.147,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "ยางงาม",
    zipcode: 67220,
    latitude: 16.101,
    longitude: 100.951,
  },
  {
    province: "เพชรบูรณ์",
    district: "หนองไผ่",
    subdistrict: "ท่าด้วง",
    zipcode: 67140,
    latitude: 16.001,
    longitude: 101.301,
  },
  {
    province: "เพชรบูรณ์",
    district: "บึงสามพัน",
    subdistrict: "ซับสมอทอด",
    zipcode: 67160,
    latitude: 15.846,
    longitude: 100.939,
  },
  {
    province: "เพชรบูรณ์",
    district: "บึงสามพัน",
    subdistrict: "ซับไม้แดง",
    zipcode: 67160,
    latitude: 15.788,
    longitude: 100.922,
  },
  {
    province: "เพชรบูรณ์",
    district: "บึงสามพัน",
    subdistrict: "หนองแจง",
    zipcode: 67160,
    latitude: 15.877,
    longitude: 100.985,
  },
  {
    province: "เพชรบูรณ์",
    district: "บึงสามพัน",
    subdistrict: "กันจุ",
    zipcode: 67160,
    latitude: 15.817,
    longitude: 101.075,
  },
  {
    province: "เพชรบูรณ์",
    district: "บึงสามพัน",
    subdistrict: "วังพิกุล",
    zipcode: 67230,
    latitude: 15.747,
    longitude: 100.861,
  },
  {
    province: "เพชรบูรณ์",
    district: "บึงสามพัน",
    subdistrict: "พญาวัง",
    zipcode: 67160,
    latitude: 15.88,
    longitude: 100.847,
  },
  {
    province: "เพชรบูรณ์",
    district: "บึงสามพัน",
    subdistrict: "ศรีมงคล",
    zipcode: 67160,
    latitude: 15.82,
    longitude: 100.835,
  },
  {
    province: "เพชรบูรณ์",
    district: "บึงสามพัน",
    subdistrict: "สระแก้ว",
    zipcode: 67160,
    latitude: 15.894,
    longitude: 101.324,
  },
  {
    province: "เพชรบูรณ์",
    district: "บึงสามพัน",
    subdistrict: "บึงสามพัน",
    zipcode: 67160,
    latitude: 15.791,
    longitude: 101.022,
  },
  {
    province: "เพชรบูรณ์",
    district: "น้ำหนาว",
    subdistrict: "น้ำหนาว",
    zipcode: 67260,
    latitude: 16.764,
    longitude: 101.681,
  },
  {
    province: "เพชรบูรณ์",
    district: "น้ำหนาว",
    subdistrict: "หลักด่าน",
    zipcode: 67260,
    latitude: 16.937,
    longitude: 101.477,
  },
  {
    province: "เพชรบูรณ์",
    district: "น้ำหนาว",
    subdistrict: "วังกวาง",
    zipcode: 67260,
    latitude: 16.936,
    longitude: 101.595,
  },
  {
    province: "เพชรบูรณ์",
    district: "น้ำหนาว",
    subdistrict: "โคกมน",
    zipcode: 67260,
    latitude: 16.709,
    longitude: 101.71,
  },
  {
    province: "เพชรบูรณ์",
    district: "วังโป่ง",
    subdistrict: "วังโป่ง",
    zipcode: 67240,
    latitude: 16.347,
    longitude: 100.809,
  },
  {
    province: "เพชรบูรณ์",
    district: "วังโป่ง",
    subdistrict: "ท้ายดง",
    zipcode: 67240,
    latitude: 16.297,
    longitude: 100.678,
  },
  {
    province: "เพชรบูรณ์",
    district: "วังโป่ง",
    subdistrict: "ซับเปิบ",
    zipcode: 67240,
    latitude: 16.362,
    longitude: 100.918,
  },
  {
    province: "เพชรบูรณ์",
    district: "วังโป่ง",
    subdistrict: "วังหิน",
    zipcode: 67240,
    latitude: 16.387,
    longitude: 100.754,
  },
  {
    province: "เพชรบูรณ์",
    district: "วังโป่ง",
    subdistrict: "วังศาล",
    zipcode: 67240,
    latitude: 16.283,
    longitude: 100.758,
  },
  {
    province: "เพชรบูรณ์",
    district: "เขาค้อ",
    subdistrict: "ทุ่งสมอ",
    zipcode: 67270,
    latitude: 16.717,
    longitude: 100.962,
  },
  {
    province: "เพชรบูรณ์",
    district: "เขาค้อ",
    subdistrict: "แคมป์สน",
    zipcode: 67280,
    latitude: 16.78,
    longitude: 101.055,
  },
  {
    province: "เพชรบูรณ์",
    district: "เขาค้อ",
    subdistrict: "เขาค้อ",
    zipcode: 67270,
    latitude: 16.631,
    longitude: 100.999,
  },
  {
    province: "เพชรบูรณ์",
    district: "เขาค้อ",
    subdistrict: "ริมสีม่วง",
    zipcode: 67270,
    latitude: 16.52,
    longitude: 101.042,
  },
  {
    province: "เพชรบูรณ์",
    district: "เขาค้อ",
    subdistrict: "สะเดาะพง",
    zipcode: 67270,
    latitude: 16.545,
    longitude: 100.968,
  },
  {
    province: "เพชรบูรณ์",
    district: "เขาค้อ",
    subdistrict: "หนองแม่นา",
    zipcode: 67270,
    latitude: 16.578,
    longitude: 100.898,
  },
  {
    province: "เพชรบูรณ์",
    district: "เขาค้อ",
    subdistrict: "เข็กน้อย",
    zipcode: 67280,
    latitude: 16.832,
    longitude: 101.015,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "หน้าเมือง",
    zipcode: 70000,
    latitude: 13.535,
    longitude: 99.82,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "เจดีย์หัก",
    zipcode: 70000,
    latitude: 13.539,
    longitude: 99.781,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "ดอนตะโก",
    zipcode: 70000,
    latitude: 13.509,
    longitude: 99.792,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "หนองกลางนา",
    zipcode: 70000,
    latitude: 13.593,
    longitude: 99.796,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "ห้วยไผ่",
    zipcode: 70000,
    latitude: 13.51,
    longitude: 99.745,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "คุ้งน้ำวน",
    zipcode: 70000,
    latitude: 13.508,
    longitude: 99.898,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "คุ้งกระถิน",
    zipcode: 70000,
    latitude: 13.504,
    longitude: 99.876,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "อ่างทอง",
    zipcode: 70000,
    latitude: 13.465,
    longitude: 99.787,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "โคกหม้อ",
    zipcode: 70000,
    latitude: 13.571,
    longitude: 99.834,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "สามเรือน",
    zipcode: 70000,
    latitude: 13.603,
    longitude: 99.86,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "พิกุลทอง",
    zipcode: 70000,
    latitude: 13.575,
    longitude: 99.866,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "น้ำพุ",
    zipcode: 70000,
    latitude: 13.547,
    longitude: 99.631,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "ดอนแร่",
    zipcode: 70000,
    latitude: 13.471,
    longitude: 99.758,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "หินกอง",
    zipcode: 70000,
    latitude: 13.565,
    longitude: 99.705,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "เขาแร้ง",
    zipcode: 70000,
    latitude: 13.622,
    longitude: 99.773,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "เกาะพลับพลา",
    zipcode: 70000,
    latitude: 13.585,
    longitude: 99.75,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "หลุมดิน",
    zipcode: 70000,
    latitude: 13.571,
    longitude: 99.808,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "บางป่า",
    zipcode: 70000,
    latitude: 13.553,
    longitude: 99.886,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "พงสวาย",
    zipcode: 70000,
    latitude: 13.545,
    longitude: 99.848,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "คูบัว",
    zipcode: 70000,
    latitude: 13.48,
    longitude: 99.822,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "ท่าราบ",
    zipcode: 70000,
    latitude: 13.602,
    longitude: 99.819,
  },
  {
    province: "ราชบุรี",
    district: "เมืองราชบุรี",
    subdistrict: "บ้านไร่",
    zipcode: 70000,
    latitude: 13.511,
    longitude: 99.845,
  },
  {
    province: "ราชบุรี",
    district: "จอมบึง",
    subdistrict: "จอมบึง",
    zipcode: 70150,
    latitude: 13.622,
    longitude: 99.606,
  },
  {
    province: "ราชบุรี",
    district: "จอมบึง",
    subdistrict: "ปากช่อง",
    zipcode: 70150,
    latitude: 13.667,
    longitude: 99.649,
  },
  {
    province: "ราชบุรี",
    district: "จอมบึง",
    subdistrict: "เบิกไพร",
    zipcode: 70150,
    latitude: 13.685,
    longitude: 99.521,
  },
  {
    province: "ราชบุรี",
    district: "จอมบึง",
    subdistrict: "ด่านทับตะโก",
    zipcode: 70150,
    latitude: 13.663,
    longitude: 99.426,
  },
  {
    province: "ราชบุรี",
    district: "จอมบึง",
    subdistrict: "แก้มอ้น",
    zipcode: 70150,
    latitude: 13.74,
    longitude: 99.428,
  },
  {
    province: "ราชบุรี",
    district: "จอมบึง",
    subdistrict: "รางบัว",
    zipcode: 70150,
    latitude: 13.533,
    longitude: 99.533,
  },
  {
    province: "ราชบุรี",
    district: "สวนผึ้ง",
    subdistrict: "สวนผึ้ง",
    zipcode: 70180,
    latitude: 13.612,
    longitude: 99.249,
  },
  {
    province: "ราชบุรี",
    district: "สวนผึ้ง",
    subdistrict: "ป่าหวาย",
    zipcode: 70180,
    latitude: 13.578,
    longitude: 99.423,
  },
  {
    province: "ราชบุรี",
    district: "สวนผึ้ง",
    subdistrict: "ท่าเคย",
    zipcode: 70180,
    latitude: 13.522,
    longitude: 99.42,
  },
  {
    province: "ราชบุรี",
    district: "สวนผึ้ง",
    subdistrict: "ตะนาวศรี",
    zipcode: 70180,
    latitude: 13.418,
    longitude: 99.273,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "ดำเนินสะดวก",
    zipcode: 70130,
    latitude: 13.517,
    longitude: 99.949,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "ประสาทสิทธิ์",
    zipcode: 70210,
    latitude: 13.555,
    longitude: 100.036,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "ศรีสุราษฎร์",
    zipcode: 70130,
    latitude: 13.533,
    longitude: 99.984,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "ตาหลวง",
    zipcode: 70130,
    latitude: 13.504,
    longitude: 99.953,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "ดอนกรวย",
    zipcode: 70130,
    latitude: 13.58,
    longitude: 99.971,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "ดอนคลัง",
    zipcode: 70130,
    latitude: 13.619,
    longitude: 99.974,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "บัวงาม",
    zipcode: 70210,
    latitude: 13.608,
    longitude: 100.016,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "บ้านไร่",
    zipcode: 70130,
    latitude: 13.606,
    longitude: 99.909,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "แพงพวย",
    zipcode: 70130,
    latitude: 13.571,
    longitude: 99.925,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "สี่หมื่น",
    zipcode: 70130,
    latitude: 13.522,
    longitude: 99.929,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "ท่านัด",
    zipcode: 70130,
    latitude: 13.544,
    longitude: 99.96,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "ขุนพิทักษ์",
    zipcode: 70130,
    latitude: 13.509,
    longitude: 99.982,
  },
  {
    province: "ราชบุรี",
    district: "ดำเนินสะดวก",
    subdistrict: "ดอนไผ่",
    zipcode: 70130,
    latitude: 13.551,
    longitude: 100.009,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "บ้านโป่ง",
    zipcode: 70110,
    latitude: 13.816,
    longitude: 99.873,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "ท่าผา",
    zipcode: 70110,
    latitude: 13.865,
    longitude: 99.865,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "กรับใหญ่",
    zipcode: 70190,
    latitude: 13.908,
    longitude: 99.868,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "ปากแรต",
    zipcode: 70110,
    latitude: 13.833,
    longitude: 99.89,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "หนองกบ",
    zipcode: 70110,
    latitude: 13.821,
    longitude: 99.934,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "หนองอ้อ",
    zipcode: 70110,
    latitude: 13.793,
    longitude: 99.906,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "ดอนกระเบื้อง",
    zipcode: 70110,
    latitude: 13.769,
    longitude: 99.891,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "สวนกล้วย",
    zipcode: 70110,
    latitude: 13.798,
    longitude: 99.882,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "นครชุมน์",
    zipcode: 70110,
    latitude: 13.773,
    longitude: 99.863,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "บ้านม่วง",
    zipcode: 70110,
    latitude: 13.771,
    longitude: 99.815,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "คุ้งพยอม",
    zipcode: 70110,
    latitude: 13.797,
    longitude: 99.83,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "หนองปลาหมอ",
    zipcode: 70110,
    latitude: 13.794,
    longitude: 99.776,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "เขาขลุง",
    zipcode: 70110,
    latitude: 13.798,
    longitude: 99.728,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "เบิกไพร",
    zipcode: 70110,
    latitude: 13.819,
    longitude: 99.851,
  },
  {
    province: "ราชบุรี",
    district: "บ้านโป่ง",
    subdistrict: "ลาดบัวขาว",
    zipcode: 70110,
    latitude: 13.841,
    longitude: 99.831,
  },
  {
    province: "ราชบุรี",
    district: "บางแพ",
    subdistrict: "บางแพ",
    zipcode: 70160,
    latitude: 13.691,
    longitude: 99.951,
  },
  {
    province: "ราชบุรี",
    district: "บางแพ",
    subdistrict: "วังเย็น",
    zipcode: 70160,
    latitude: 13.705,
    longitude: 99.909,
  },
  {
    province: "ราชบุรี",
    district: "บางแพ",
    subdistrict: "หัวโพ",
    zipcode: 70160,
    latitude: 13.654,
    longitude: 99.978,
  },
  {
    province: "ราชบุรี",
    district: "บางแพ",
    subdistrict: "วัดแก้ว",
    zipcode: 70160,
    latitude: 13.657,
    longitude: 99.918,
  },
  {
    province: "ราชบุรี",
    district: "บางแพ",
    subdistrict: "ดอนใหญ่",
    zipcode: 70160,
    latitude: 13.709,
    longitude: 100.002,
  },
  {
    province: "ราชบุรี",
    district: "บางแพ",
    subdistrict: "ดอนคา",
    zipcode: 70160,
    latitude: 13.668,
    longitude: 100.006,
  },
  {
    province: "ราชบุรี",
    district: "บางแพ",
    subdistrict: "โพหัก",
    zipcode: 70160,
    latitude: 13.64,
    longitude: 100.043,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "โพธาราม",
    zipcode: 70120,
    latitude: 13.695,
    longitude: 99.853,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "ดอนกระเบื้อง",
    zipcode: 70120,
    latitude: 13.752,
    longitude: 99.899,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "หนองโพ",
    zipcode: 70120,
    latitude: 13.741,
    longitude: 99.924,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "บ้านเลือก",
    zipcode: 70120,
    latitude: 13.725,
    longitude: 99.886,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "คลองตาคต",
    zipcode: 70120,
    latitude: 13.672,
    longitude: 99.854,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "บ้านฆ้อง",
    zipcode: 70120,
    latitude: 13.681,
    longitude: 99.888,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "บ้านสิงห์",
    zipcode: 70120,
    latitude: 13.636,
    longitude: 99.895,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "ดอนทราย",
    zipcode: 70120,
    latitude: 13.632,
    longitude: 99.858,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "เจ็ดเสมียน",
    zipcode: 70120,
    latitude: 13.638,
    longitude: 99.828,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "คลองข่อย",
    zipcode: 70120,
    latitude: 13.675,
    longitude: 99.827,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "ชำแระ",
    zipcode: 70120,
    latitude: 13.738,
    longitude: 99.809,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "สร้อยฟ้า",
    zipcode: 70120,
    latitude: 13.721,
    longitude: 99.835,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "ท่าชุมพล",
    zipcode: 70120,
    latitude: 13.691,
    longitude: 99.802,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "บางโตนด",
    zipcode: 70120,
    latitude: 13.653,
    longitude: 99.796,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "เตาปูน",
    zipcode: 70120,
    latitude: 13.749,
    longitude: 99.742,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "นางแก้ว",
    zipcode: 70120,
    latitude: 13.697,
    longitude: 99.768,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "ธรรมเสน",
    zipcode: 70120,
    latitude: 13.669,
    longitude: 99.726,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "เขาชะงุ้ม",
    zipcode: 70120,
    latitude: 13.726,
    longitude: 99.7,
  },
  {
    province: "ราชบุรี",
    district: "โพธาราม",
    subdistrict: "หนองกวาง",
    zipcode: 70120,
    latitude: 13.747,
    longitude: 99.597,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "ทุ่งหลวง",
    zipcode: 70140,
    latitude: 13.411,
    longitude: 99.683,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "วังมะนาว",
    zipcode: 70140,
    latitude: 13.341,
    longitude: 99.802,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "ดอนทราย",
    zipcode: 70140,
    latitude: 13.368,
    longitude: 99.776,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "หนองกระทุ่ม",
    zipcode: 70140,
    latitude: 13.404,
    longitude: 99.779,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "ปากท่อ",
    zipcode: 70140,
    latitude: 13.382,
    longitude: 99.841,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "ป่าไก่",
    zipcode: 70140,
    latitude: 13.412,
    longitude: 99.827,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "วัดยางงาม",
    zipcode: 70140,
    latitude: 13.399,
    longitude: 99.864,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "อ่างหิน",
    zipcode: 70140,
    latitude: 13.46,
    longitude: 99.626,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "บ่อกระดาน",
    zipcode: 70140,
    latitude: 13.438,
    longitude: 99.801,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "ยางหัก",
    zipcode: 70140,
    latitude: 13.283,
    longitude: 99.596,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "วันดาว",
    zipcode: 70140,
    latitude: 13.354,
    longitude: 99.856,
  },
  {
    province: "ราชบุรี",
    district: "ปากท่อ",
    subdistrict: "ห้วยยางโทน",
    zipcode: 70140,
    latitude: 13.345,
    longitude: 99.693,
  },
  {
    province: "ราชบุรี",
    district: "วัดเพลง",
    subdistrict: "เกาะศาลพระ",
    zipcode: 70170,
    latitude: 13.473,
    longitude: 99.86,
  },
  {
    province: "ราชบุรี",
    district: "วัดเพลง",
    subdistrict: "จอมประทัด",
    zipcode: 70170,
    latitude: 13.424,
    longitude: 99.863,
  },
  {
    province: "ราชบุรี",
    district: "วัดเพลง",
    subdistrict: "วัดเพลง",
    zipcode: 70170,
    latitude: 13.449,
    longitude: 99.872,
  },
  {
    province: "ราชบุรี",
    district: "บ้านคา",
    subdistrict: "บ้านคา",
    zipcode: 70180,
    latitude: 13.418,
    longitude: 99.386,
  },
  {
    province: "ราชบุรี",
    district: "บ้านคา",
    subdistrict: "บ้านบึง",
    zipcode: 70180,
    latitude: 13.283,
    longitude: 99.444,
  },
  {
    province: "ราชบุรี",
    district: "บ้านคา",
    subdistrict: "หนองพันจันทร์",
    zipcode: 70180,
    latitude: 13.448,
    longitude: 99.462,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "บ้านเหนือ",
    zipcode: 71000,
    latitude: 14.0060978,
    longitude: 99.4899411,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "บ้านใต้",
    zipcode: 71000,
    latitude: 14.0289302,
    longitude: 99.5009614,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "ปากแพรก",
    zipcode: 71000,
    latitude: 14.039,
    longitude: 99.551,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "ท่ามะขาม",
    zipcode: 71000,
    latitude: 14.035,
    longitude: 99.503,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "แก่งเสี้ยน",
    zipcode: 71000,
    latitude: 14.103,
    longitude: 99.501,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "หนองบัว",
    zipcode: 71190,
    latitude: 14.05,
    longitude: 99.417,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "ลาดหญ้า",
    zipcode: 71190,
    latitude: 14.138,
    longitude: 99.419,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "วังด้ง",
    zipcode: 71190,
    latitude: 14.151,
    longitude: 99.306,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "ช่องสะเดา",
    zipcode: 71190,
    latitude: 14.228,
    longitude: 99.187,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "หนองหญ้า",
    zipcode: 71000,
    latitude: 13.981,
    longitude: 99.451,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "เกาะสำโรง",
    zipcode: 71000,
    latitude: 13.951,
    longitude: 99.52,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "บ้านเก่า",
    zipcode: 71000,
    latitude: 13.981,
    longitude: 99.305,
  },
  {
    province: "กาญจนบุรี",
    district: "เมืองกาญจนบุรี",
    subdistrict: "วังเย็น",
    zipcode: 71000,
    latitude: 13.954,
    longitude: 99.4,
  },
  {
    province: "กาญจนบุรี",
    district: "ไทรโยค",
    subdistrict: "ลุ่มสุ่ม",
    zipcode: 71150,
    latitude: 14.104,
    longitude: 99.133,
  },
  {
    province: "กาญจนบุรี",
    district: "ไทรโยค",
    subdistrict: "ท่าเสา",
    zipcode: 71150,
    latitude: 14.303,
    longitude: 99.037,
  },
  {
    province: "กาญจนบุรี",
    district: "ไทรโยค",
    subdistrict: "สิงห์",
    zipcode: 71150,
    latitude: 14.035,
    longitude: 99.287,
  },
  {
    province: "กาญจนบุรี",
    district: "ไทรโยค",
    subdistrict: "ไทรโยค",
    zipcode: 71150,
    latitude: 14.421,
    longitude: 98.741,
  },
  {
    province: "กาญจนบุรี",
    district: "ไทรโยค",
    subdistrict: "วังกระแจะ",
    zipcode: 71150,
    latitude: 14.26,
    longitude: 98.911,
  },
  {
    province: "กาญจนบุรี",
    district: "ไทรโยค",
    subdistrict: "ศรีมงคล",
    zipcode: 71150,
    latitude: 13.992,
    longitude: 99.139,
  },
  {
    province: "กาญจนบุรี",
    district: "ไทรโยค",
    subdistrict: "บ้องตี้",
    zipcode: 71150,
    latitude: 14.134,
    longitude: 98.916,
  },
  {
    province: "กาญจนบุรี",
    district: "บ่อพลอย",
    subdistrict: "บ่อพลอย",
    zipcode: 71160,
    latitude: 14.305,
    longitude: 99.466,
  },
  {
    province: "กาญจนบุรี",
    district: "บ่อพลอย",
    subdistrict: "หนองกุ่ม",
    zipcode: 71160,
    latitude: 14.205,
    longitude: 99.462,
  },
  {
    province: "กาญจนบุรี",
    district: "บ่อพลอย",
    subdistrict: "หนองรี",
    zipcode: 71220,
    latitude: 14.54,
    longitude: 99.356,
  },
  {
    province: "กาญจนบุรี",
    district: "บ่อพลอย",
    subdistrict: "หลุมรัง",
    zipcode: 71160,
    latitude: 14.485,
    longitude: 99.481,
  },
  {
    province: "กาญจนบุรี",
    district: "บ่อพลอย",
    subdistrict: "ช่องด่าน",
    zipcode: 71160,
    latitude: 14.412,
    longitude: 99.437,
  },
  {
    province: "กาญจนบุรี",
    district: "บ่อพลอย",
    subdistrict: "หนองกร่าง",
    zipcode: 71220,
    latitude: 14.5395054,
    longitude: 99.53722,
  },
  {
    province: "กาญจนบุรี",
    district: "ศรีสวัสดิ์",
    subdistrict: "นาสวน",
    zipcode: 71250,
    latitude: 14.895,
    longitude: 99.029,
  },
  {
    province: "กาญจนบุรี",
    district: "ศรีสวัสดิ์",
    subdistrict: "ด่านแม่แฉลบ",
    zipcode: 71250,
    latitude: 14.691,
    longitude: 98.98,
  },
  {
    province: "กาญจนบุรี",
    district: "ศรีสวัสดิ์",
    subdistrict: "หนองเป็ด",
    zipcode: 71250,
    latitude: 14.464,
    longitude: 99.232,
  },
  {
    province: "กาญจนบุรี",
    district: "ศรีสวัสดิ์",
    subdistrict: "ท่ากระดาน",
    zipcode: 71250,
    latitude: 14.366,
    longitude: 99.174,
  },
  {
    province: "กาญจนบุรี",
    district: "ศรีสวัสดิ์",
    subdistrict: "เขาโจด",
    zipcode: 71220,
    latitude: 14.885,
    longitude: 99.183,
  },
  {
    province: "กาญจนบุรี",
    district: "ศรีสวัสดิ์",
    subdistrict: "แม่กระบุง",
    zipcode: 71250,
    latitude: 14.527,
    longitude: 98.996,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "พงตึก",
    zipcode: 71120,
    latitude: 13.869,
    longitude: 99.776,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "ยางม่วง",
    zipcode: 71120,
    latitude: 13.921,
    longitude: 99.834,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "ดอนชะเอม",
    zipcode: 71130,
    latitude: 13.971,
    longitude: 99.807,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "ท่าไม้",
    zipcode: 71120,
    latitude: 13.906,
    longitude: 99.801,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "ตะคร้ำเอน",
    zipcode: 71130,
    latitude: 13.992,
    longitude: 99.744,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "ท่ามะกา",
    zipcode: 71120,
    latitude: 13.93,
    longitude: 99.779,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "ท่าเรือ",
    zipcode: 71130,
    latitude: 13.9543504,
    longitude: 99.6079459,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "โคกตะบอง",
    zipcode: 71120,
    latitude: 13.839,
    longitude: 99.746,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "ดอนขมิ้น",
    zipcode: 71120,
    latitude: 13.884,
    longitude: 99.826,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "อุโลกสี่หมื่น",
    zipcode: 71130,
    latitude: 14.06,
    longitude: 99.773,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "เขาสามสิบหาบ",
    zipcode: 71120,
    latitude: 13.865,
    longitude: 99.719,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "พระแท่น",
    zipcode: 71130,
    latitude: 14.018,
    longitude: 99.792,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "หวายเหนียว",
    zipcode: 71120,
    latitude: 13.902,
    longitude: 99.759,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "แสนตอ",
    zipcode: 71130,
    latitude: 13.922,
    longitude: 99.732,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "สนามแย้",
    zipcode: 70190,
    latitude: 13.954,
    longitude: 99.851,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "ท่าเสา",
    zipcode: 71120,
    latitude: 13.843,
    longitude: 99.805,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่ามะกา",
    subdistrict: "หนองลาน",
    zipcode: 71130,
    latitude: 14.037,
    longitude: 99.827,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "ท่าม่วง",
    zipcode: 71110,
    latitude: 13.974,
    longitude: 99.625,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "วังขนาย",
    zipcode: 71110,
    latitude: 13.96,
    longitude: 99.663,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "วังศาลา",
    zipcode: 71110,
    latitude: 13.971,
    longitude: 99.697,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "ท่าล้อ",
    zipcode: 71110,
    latitude: 13.987,
    longitude: 99.589,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "หนองขาว",
    zipcode: 71110,
    latitude: 14.055,
    longitude: 99.613,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "ทุ่งทอง",
    zipcode: 71110,
    latitude: 14.008,
    longitude: 99.666,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "เขาน้อย",
    zipcode: 71110,
    latitude: 13.919,
    longitude: 99.588,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "ม่วงชุม",
    zipcode: 71110,
    latitude: 13.937,
    longitude: 99.617,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "บ้านใหม่",
    zipcode: 71110,
    latitude: 13.868,
    longitude: 99.604,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "พังตรุ",
    zipcode: 71110,
    latitude: 13.863,
    longitude: 99.645,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "ท่าตะคร้อ",
    zipcode: 71130,
    latitude: 13.917,
    longitude: 99.699,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "รางสาลี่",
    zipcode: 71110,
    latitude: 13.866,
    longitude: 99.543,
  },
  {
    province: "กาญจนบุรี",
    district: "ท่าม่วง",
    subdistrict: "หนองตากยา",
    zipcode: 71110,
    latitude: 13.795,
    longitude: 99.546,
  },
  {
    province: "กาญจนบุรี",
    district: "ทองผาภูมิ",
    subdistrict: "ท่าขนุน",
    zipcode: 71180,
    latitude: 14.762,
    longitude: 98.641,
  },
  {
    province: "กาญจนบุรี",
    district: "ทองผาภูมิ",
    subdistrict: "ปิล๊อก",
    zipcode: 71180,
    latitude: 14.779,
    longitude: 98.403,
  },
  {
    province: "กาญจนบุรี",
    district: "ทองผาภูมิ",
    subdistrict: "หินดาด",
    zipcode: 71180,
    latitude: 14.608,
    longitude: 98.723,
  },
  {
    province: "กาญจนบุรี",
    district: "ทองผาภูมิ",
    subdistrict: "ลิ่นถิ่น",
    zipcode: 71180,
    latitude: 14.584,
    longitude: 98.833,
  },
  {
    province: "กาญจนบุรี",
    district: "ทองผาภูมิ",
    subdistrict: "ชะแล",
    zipcode: 71180,
    latitude: 14.996,
    longitude: 98.833,
  },
  {
    province: "กาญจนบุรี",
    district: "ทองผาภูมิ",
    subdistrict: "ห้วยเขย่ง",
    zipcode: 71180,
    latitude: 14.635,
    longitude: 98.583,
  },
  {
    province: "กาญจนบุรี",
    district: "ทองผาภูมิ",
    subdistrict: "สหกรณ์นิคม",
    zipcode: 71180,
    latitude: 14.735,
    longitude: 98.763,
  },
  {
    province: "กาญจนบุรี",
    district: "สังขละบุรี",
    subdistrict: "หนองลู",
    zipcode: 71240,
    latitude: 15.112,
    longitude: 98.341,
  },
  {
    province: "กาญจนบุรี",
    district: "สังขละบุรี",
    subdistrict: "ปรังเผล",
    zipcode: 71240,
    latitude: 14.999,
    longitude: 98.545,
  },
  {
    province: "กาญจนบุรี",
    district: "สังขละบุรี",
    subdistrict: "ไล่โว่",
    zipcode: 71240,
    latitude: 15.345,
    longitude: 98.664,
  },
  {
    province: "กาญจนบุรี",
    district: "พนมทวน",
    subdistrict: "พนมทวน",
    zipcode: 71140,
    latitude: 14.129,
    longitude: 99.705,
  },
  {
    province: "กาญจนบุรี",
    district: "พนมทวน",
    subdistrict: "หนองโรง",
    zipcode: 71140,
    latitude: 14.162,
    longitude: 99.622,
  },
  {
    province: "กาญจนบุรี",
    district: "พนมทวน",
    subdistrict: "ทุ่งสมอ",
    zipcode: 71140,
    latitude: 14.076,
    longitude: 99.667,
  },
  {
    province: "กาญจนบุรี",
    district: "พนมทวน",
    subdistrict: "ดอนเจดีย์",
    zipcode: 71140,
    latitude: 14.036,
    longitude: 99.674,
  },
  {
    province: "กาญจนบุรี",
    district: "พนมทวน",
    subdistrict: "พังตรุ",
    zipcode: 71140,
    latitude: 14.134,
    longitude: 99.746,
  },
  {
    province: "กาญจนบุรี",
    district: "พนมทวน",
    subdistrict: "รางหวาย",
    zipcode: 71170,
    latitude: 14.232,
    longitude: 99.767,
  },
  {
    province: "กาญจนบุรี",
    district: "พนมทวน",
    subdistrict: "หนองสาหร่าย",
    zipcode: 71140,
    latitude: 14.067,
    longitude: 99.728,
  },
  {
    province: "กาญจนบุรี",
    district: "พนมทวน",
    subdistrict: "ดอนตาเพชร",
    zipcode: 71140,
    latitude: 14.192,
    longitude: 99.678,
  },
  {
    province: "กาญจนบุรี",
    district: "เลาขวัญ",
    subdistrict: "เลาขวัญ",
    zipcode: 71210,
    latitude: 14.58,
    longitude: 99.753,
  },
  {
    province: "กาญจนบุรี",
    district: "เลาขวัญ",
    subdistrict: "หนองโสน",
    zipcode: 71210,
    latitude: 14.648,
    longitude: 99.758,
  },
  {
    province: "กาญจนบุรี",
    district: "เลาขวัญ",
    subdistrict: "หนองประดู่",
    zipcode: 71210,
    latitude: 14.456,
    longitude: 99.738,
  },
  {
    province: "กาญจนบุรี",
    district: "เลาขวัญ",
    subdistrict: "หนองปลิง",
    zipcode: 71210,
    latitude: 14.715,
    longitude: 99.743,
  },
  {
    province: "กาญจนบุรี",
    district: "เลาขวัญ",
    subdistrict: "หนองนกแก้ว",
    zipcode: 71210,
    latitude: 14.516,
    longitude: 99.655,
  },
  {
    province: "กาญจนบุรี",
    district: "เลาขวัญ",
    subdistrict: "ทุ่งกระบ่ำ",
    zipcode: 71210,
    latitude: 14.616,
    longitude: 99.586,
  },
  {
    province: "กาญจนบุรี",
    district: "เลาขวัญ",
    subdistrict: "หนองฝ้าย",
    zipcode: 71210,
    latitude: 14.682,
    longitude: 99.642,
  },
  {
    province: "กาญจนบุรี",
    district: "ด่านมะขามเตี้ย",
    subdistrict: "ด่านมะขามเตี้ย",
    zipcode: 71260,
    latitude: 13.836,
    longitude: 99.388,
  },
  {
    province: "กาญจนบุรี",
    district: "ด่านมะขามเตี้ย",
    subdistrict: "กลอนโด",
    zipcode: 71260,
    latitude: 13.88,
    longitude: 99.44,
  },
  {
    province: "กาญจนบุรี",
    district: "ด่านมะขามเตี้ย",
    subdistrict: "จรเข้เผือก",
    zipcode: 71260,
    latitude: 13.811,
    longitude: 99.21,
  },
  {
    province: "กาญจนบุรี",
    district: "ด่านมะขามเตี้ย",
    subdistrict: "หนองไผ่",
    zipcode: 71260,
    latitude: 13.808,
    longitude: 99.44,
  },
  {
    province: "กาญจนบุรี",
    district: "หนองปรือ",
    subdistrict: "หนองปรือ",
    zipcode: 71220,
    latitude: 14.63,
    longitude: 99.454,
  },
  {
    province: "กาญจนบุรี",
    district: "หนองปรือ",
    subdistrict: "หนองปลาไหล",
    zipcode: 71220,
    latitude: 14.626,
    longitude: 99.527,
  },
  {
    province: "กาญจนบุรี",
    district: "หนองปรือ",
    subdistrict: "สมเด็จเจริญ",
    zipcode: 71220,
    latitude: 14.746,
    longitude: 99.338,
  },
  {
    province: "กาญจนบุรี",
    district: "ห้วยกระเจา",
    subdistrict: "ห้วยกระเจา",
    zipcode: 71170,
    latitude: 14.312,
    longitude: 99.618,
  },
  {
    province: "กาญจนบุรี",
    district: "ห้วยกระเจา",
    subdistrict: "วังไผ่",
    zipcode: 71170,
    latitude: 14.434,
    longitude: 99.597,
  },
  {
    province: "กาญจนบุรี",
    district: "ห้วยกระเจา",
    subdistrict: "ดอนแสลบ",
    zipcode: 71170,
    latitude: 14.292,
    longitude: 99.701,
  },
  {
    province: "กาญจนบุรี",
    district: "ห้วยกระเจา",
    subdistrict: "สระลงเรือ",
    zipcode: 71170,
    latitude: 14.357,
    longitude: 99.764,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ท่าพี่เลี้ยง",
    zipcode: 72000,
    latitude: 14.471,
    longitude: 100.116,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "รั้วใหญ่",
    zipcode: 72000,
    latitude: 14.451,
    longitude: 100.113,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ทับตีเหล็ก",
    zipcode: 72000,
    latitude: 14.434,
    longitude: 100.108,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ท่าระหัด",
    zipcode: 72000,
    latitude: 14.445,
    longitude: 100.164,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ไผ่ขวาง",
    zipcode: 72000,
    latitude: 14.47,
    longitude: 100.165,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "โคกโคเฒ่า",
    zipcode: 72000,
    latitude: 14.464,
    longitude: 100.233,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ดอนตาล",
    zipcode: 72000,
    latitude: 14.49,
    longitude: 100.208,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ดอนมะสังข์",
    zipcode: 72000,
    latitude: 14.524,
    longitude: 100.181,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "พิหารแดง",
    zipcode: 72000,
    latitude: 14.506,
    longitude: 100.107,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ดอนกำยาน",
    zipcode: 72000,
    latitude: 14.454,
    longitude: 100.064,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ดอนโพธิ์ทอง",
    zipcode: 72000,
    latitude: 14.426,
    longitude: 100.032,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "บ้านโพธิ์",
    zipcode: 72000,
    latitude: 14.524,
    longitude: 100.071,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "สระแก้ว",
    zipcode: 72230,
    latitude: 14.502,
    longitude: 100.014,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ตลิ่งชัน",
    zipcode: 72230,
    latitude: 14.559,
    longitude: 100.029,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "บางกุ้ง",
    zipcode: 72210,
    latitude: 14.457,
    longitude: 100.003,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "ศาลาขาว",
    zipcode: 72210,
    latitude: 14.433,
    longitude: 99.973,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "สวนแตง",
    zipcode: 72210,
    latitude: 14.436,
    longitude: 100.018,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "สนามชัย",
    zipcode: 72000,
    latitude: 14.492,
    longitude: 100.169,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "โพธิ์พระยา",
    zipcode: 72000,
    latitude: 14.531,
    longitude: 100.116,
  },
  {
    province: "สุพรรณบุรี",
    district: "เมืองสุพรรณบุรี",
    subdistrict: "สนามคลี",
    zipcode: 72230,
    latitude: 14.539,
    longitude: 99.982,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "เขาพระ",
    zipcode: 72120,
    latitude: 14.853,
    longitude: 100.095,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "เดิมบาง",
    zipcode: 72120,
    latitude: 14.893,
    longitude: 100.109,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "นางบวช",
    zipcode: 72120,
    latitude: 14.811,
    longitude: 100.12,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "เขาดิน",
    zipcode: 72120,
    latitude: 14.803,
    longitude: 100.189,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "ปากน้ำ",
    zipcode: 72120,
    latitude: 14.931,
    longitude: 100.087,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "ทุ่งคลี",
    zipcode: 72120,
    latitude: 14.863,
    longitude: 100.189,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "โคกช้าง",
    zipcode: 72120,
    latitude: 14.91,
    longitude: 100.169,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "หัวเขา",
    zipcode: 72120,
    latitude: 14.869,
    longitude: 100.038,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "หัวนา",
    zipcode: 72120,
    latitude: 14.883,
    longitude: 99.97,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "บ่อกรุ",
    zipcode: 72120,
    latitude: 14.893,
    longitude: 99.905,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "วังศรีราช",
    zipcode: 72120,
    latitude: 14.85,
    longitude: 99.988,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "ป่าสะแก",
    zipcode: 72120,
    latitude: 14.8657508,
    longitude: 99.8154654,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "ยางนอน",
    zipcode: 72120,
    latitude: 14.857,
    longitude: 100.132,
  },
  {
    province: "สุพรรณบุรี",
    district: "เดิมบางนางบวช",
    subdistrict: "หนองกระทุ่ม",
    zipcode: 72120,
    latitude: 14.907,
    longitude: 99.804,
  },
  {
    province: "สุพรรณบุรี",
    district: "ด่านช้าง",
    subdistrict: "หนองมะค่าโมง",
    zipcode: 72180,
    latitude: 14.882,
    longitude: 99.737,
  },
  {
    province: "สุพรรณบุรี",
    district: "ด่านช้าง",
    subdistrict: "ด่านช้าง",
    zipcode: 72180,
    latitude: 14.744,
    longitude: 99.527,
  },
  {
    province: "สุพรรณบุรี",
    district: "ด่านช้าง",
    subdistrict: "ห้วยขมิ้น",
    zipcode: 72180,
    latitude: 14.922,
    longitude: 99.534,
  },
  {
    province: "สุพรรณบุรี",
    district: "ด่านช้าง",
    subdistrict: "องค์พระ",
    zipcode: 72180,
    latitude: 14.8,
    longitude: 99.386,
  },
  {
    province: "สุพรรณบุรี",
    district: "ด่านช้าง",
    subdistrict: "วังคัน",
    zipcode: 72180,
    latitude: 14.937,
    longitude: 99.666,
  },
  {
    province: "สุพรรณบุรี",
    district: "ด่านช้าง",
    subdistrict: "นิคมกระเสียว",
    zipcode: 72180,
    latitude: 14.844,
    longitude: 99.548,
  },
  {
    province: "สุพรรณบุรี",
    district: "ด่านช้าง",
    subdistrict: "วังยาว",
    zipcode: 72180,
    latitude: 14.954,
    longitude: 99.381,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "โคกคราม",
    zipcode: 72150,
    latitude: 14.414,
    longitude: 100.151,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "บางปลาม้า",
    zipcode: 72150,
    latitude: 14.402,
    longitude: 100.102,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "ตะค่า",
    zipcode: 72150,
    latitude: 14.348,
    longitude: 100.171,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "บางใหญ่",
    zipcode: 72150,
    latitude: 14.3,
    longitude: 100.102,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "กฤษณา",
    zipcode: 72150,
    latitude: 14.29,
    longitude: 100.178,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "สาลี",
    zipcode: 72150,
    latitude: 14.286,
    longitude: 100.221,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "ไผ่กองดิน",
    zipcode: 72150,
    latitude: 14.34,
    longitude: 100.264,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "องครักษ์",
    zipcode: 72150,
    latitude: 14.374,
    longitude: 100.236,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "จรเข้ใหญ่",
    zipcode: 72150,
    latitude: 14.421,
    longitude: 100.236,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "บ้านแหลม",
    zipcode: 72150,
    latitude: 14.346,
    longitude: 100.14,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "มะขามล้ม",
    zipcode: 72150,
    latitude: 14.37,
    longitude: 100.043,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "วังน้ำเย็น",
    zipcode: 72150,
    latitude: 14.377,
    longitude: 100.015,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "วัดโบสถ์",
    zipcode: 72150,
    latitude: 14.33,
    longitude: 100.037,
  },
  {
    province: "สุพรรณบุรี",
    district: "บางปลาม้า",
    subdistrict: "วัดดาว",
    zipcode: 72150,
    latitude: 14.35,
    longitude: 100.106,
  },
  {
    province: "สุพรรณบุรี",
    district: "ศรีประจันต์",
    subdistrict: "ศรีประจันต์",
    zipcode: 72140,
    latitude: 14.609,
    longitude: 100.179,
  },
  {
    province: "สุพรรณบุรี",
    district: "ศรีประจันต์",
    subdistrict: "บ้านกร่าง",
    zipcode: 72140,
    latitude: 14.638,
    longitude: 100.104,
  },
  {
    province: "สุพรรณบุรี",
    district: "ศรีประจันต์",
    subdistrict: "มดแดง",
    zipcode: 72140,
    latitude: 14.57,
    longitude: 100.119,
  },
  {
    province: "สุพรรณบุรี",
    district: "ศรีประจันต์",
    subdistrict: "บางงาม",
    zipcode: 72140,
    latitude: 14.601,
    longitude: 100.097,
  },
  {
    province: "สุพรรณบุรี",
    district: "ศรีประจันต์",
    subdistrict: "ดอนปรู",
    zipcode: 72140,
    latitude: 14.722,
    longitude: 100.19,
  },
  {
    province: "สุพรรณบุรี",
    district: "ศรีประจันต์",
    subdistrict: "ปลายนา",
    zipcode: 72140,
    latitude: 14.66,
    longitude: 100.2,
  },
  {
    province: "สุพรรณบุรี",
    district: "ศรีประจันต์",
    subdistrict: "วังหว้า",
    zipcode: 72140,
    latitude: 14.661,
    longitude: 100.095,
  },
  {
    province: "สุพรรณบุรี",
    district: "ศรีประจันต์",
    subdistrict: "วังน้ำซับ",
    zipcode: 72140,
    latitude: 14.65,
    longitude: 100.154,
  },
  {
    province: "สุพรรณบุรี",
    district: "ศรีประจันต์",
    subdistrict: "วังยาง",
    zipcode: 72140,
    latitude: 14.559,
    longitude: 100.167,
  },
  {
    province: "สุพรรณบุรี",
    district: "ดอนเจดีย์",
    subdistrict: "ดอนเจดีย์",
    zipcode: 72170,
    latitude: 14.633,
    longitude: 99.959,
  },
  {
    province: "สุพรรณบุรี",
    district: "ดอนเจดีย์",
    subdistrict: "หนองสาหร่าย",
    zipcode: 72170,
    latitude: 14.671,
    longitude: 100.011,
  },
  {
    province: "สุพรรณบุรี",
    district: "ดอนเจดีย์",
    subdistrict: "ไร่รถ",
    zipcode: 72170,
    latitude: 14.603,
    longitude: 99.955,
  },
  {
    province: "สุพรรณบุรี",
    district: "ดอนเจดีย์",
    subdistrict: "สระกระโจม",
    zipcode: 72250,
    latitude: 14.643,
    longitude: 99.865,
  },
  {
    province: "สุพรรณบุรี",
    district: "ดอนเจดีย์",
    subdistrict: "ทะเลบก",
    zipcode: 72170,
    latitude: 14.708,
    longitude: 99.838,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "สองพี่น้อง",
    zipcode: 72110,
    latitude: 14.225,
    longitude: 100.023,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "บางเลน",
    zipcode: 72110,
    latitude: 14.169,
    longitude: 100.085,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "บางตาเถร",
    zipcode: 72110,
    latitude: 14.199,
    longitude: 100.174,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "บางตะเคียน",
    zipcode: 72110,
    latitude: 14.213,
    longitude: 100.103,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "บ้านกุ่ม",
    zipcode: 72110,
    latitude: 14.26,
    longitude: 100.114,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "หัวโพธิ์",
    zipcode: 72110,
    latitude: 14.302,
    longitude: 99.981,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "บางพลับ",
    zipcode: 72110,
    latitude: 14.252,
    longitude: 100.046,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "เนินพระปรางค์",
    zipcode: 72110,
    latitude: 14.194,
    longitude: 100.048,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "บ้านช้าง",
    zipcode: 72110,
    latitude: 14.234,
    longitude: 100.206,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "ต้นตาล",
    zipcode: 72110,
    latitude: 14.22,
    longitude: 100.061,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "ศรีสำราญ",
    zipcode: 72110,
    latitude: 14.217,
    longitude: 99.948,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "ทุ่งคอก",
    zipcode: 72110,
    latitude: 14.166,
    longitude: 99.959,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "หนองบ่อ",
    zipcode: 72110,
    latitude: 14.204,
    longitude: 99.86,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "บ่อสุพรรณ",
    zipcode: 72190,
    latitude: 14.13,
    longitude: 99.849,
  },
  {
    province: "สุพรรณบุรี",
    district: "สองพี่น้อง",
    subdistrict: "ดอนมะนาว",
    zipcode: 72110,
    latitude: 14.166,
    longitude: 100.011,
  },
  {
    province: "สุพรรณบุรี",
    district: "สามชุก",
    subdistrict: "ย่านยาว",
    zipcode: 72130,
    latitude: 14.71,
    longitude: 100.112,
  },
  {
    province: "สุพรรณบุรี",
    district: "สามชุก",
    subdistrict: "วังลึก",
    zipcode: 72130,
    latitude: 14.758,
    longitude: 100.158,
  },
  {
    province: "สุพรรณบุรี",
    district: "สามชุก",
    subdistrict: "สามชุก",
    zipcode: 72130,
    latitude: 14.763,
    longitude: 100.095,
  },
  {
    province: "สุพรรณบุรี",
    district: "สามชุก",
    subdistrict: "หนองผักนาก",
    zipcode: 72130,
    latitude: 14.757,
    longitude: 100.032,
  },
  {
    province: "สุพรรณบุรี",
    district: "สามชุก",
    subdistrict: "บ้านสระ",
    zipcode: 72130,
    latitude: 14.708,
    longitude: 100.041,
  },
  {
    province: "สุพรรณบุรี",
    district: "สามชุก",
    subdistrict: "หนองสะเดา",
    zipcode: 72130,
    latitude: 14.803,
    longitude: 99.991,
  },
  {
    province: "สุพรรณบุรี",
    district: "สามชุก",
    subdistrict: "กระเสียว",
    zipcode: 72130,
    latitude: 14.801,
    longitude: 100.056,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "อู่ทอง",
    zipcode: 72160,
    latitude: 14.377,
    longitude: 99.88,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "สระยายโสม",
    zipcode: 72220,
    latitude: 14.273,
    longitude: 99.874,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "จรเข้สามพัน",
    zipcode: 72160,
    latitude: 14.317,
    longitude: 99.85,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "บ้านดอน",
    zipcode: 72160,
    latitude: 14.303,
    longitude: 99.923,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "ยุ้งทะลาย",
    zipcode: 72160,
    latitude: 14.322,
    longitude: 99.898,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "ดอนมะเกลือ",
    zipcode: 72220,
    latitude: 14.263,
    longitude: 99.95,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "หนองโอ่ง",
    zipcode: 72160,
    latitude: 14.418,
    longitude: 99.886,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "ดอนคา",
    zipcode: 72160,
    latitude: 14.452,
    longitude: 99.854,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "พลับพลาไชย",
    zipcode: 72160,
    latitude: 14.517,
    longitude: 99.887,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "บ้านโข้ง",
    zipcode: 72160,
    latitude: 14.578,
    longitude: 99.864,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "เจดีย์",
    zipcode: 72160,
    latitude: 14.353,
    longitude: 99.966,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "สระพังลาน",
    zipcode: 72220,
    latitude: 14.253,
    longitude: 99.906,
  },
  {
    province: "สุพรรณบุรี",
    district: "อู่ทอง",
    subdistrict: "กระจัน",
    zipcode: 72160,
    latitude: 14.367,
    longitude: 99.928,
  },
  {
    province: "สุพรรณบุรี",
    district: "หนองหญ้าไซ",
    subdistrict: "หนองหญ้าไซ",
    zipcode: 72240,
    latitude: 14.776,
    longitude: 99.885,
  },
  {
    province: "สุพรรณบุรี",
    district: "หนองหญ้าไซ",
    subdistrict: "หนองราชวัตร",
    zipcode: 72240,
    latitude: 14.72,
    longitude: 99.931,
  },
  {
    province: "สุพรรณบุรี",
    district: "หนองหญ้าไซ",
    subdistrict: "หนองโพธิ์",
    zipcode: 72240,
    latitude: 14.831,
    longitude: 99.919,
  },
  {
    province: "สุพรรณบุรี",
    district: "หนองหญ้าไซ",
    subdistrict: "แจงงาม",
    zipcode: 72240,
    latitude: 14.82,
    longitude: 99.804,
  },
  {
    province: "สุพรรณบุรี",
    district: "หนองหญ้าไซ",
    subdistrict: "หนองขาม",
    zipcode: 72240,
    latitude: 14.796,
    longitude: 99.693,
  },
  {
    province: "สุพรรณบุรี",
    district: "หนองหญ้าไซ",
    subdistrict: "ทัพหลวง",
    zipcode: 72240,
    latitude: 14.749,
    longitude: 99.806,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "พระปฐมเจดีย์",
    zipcode: 73000,
    latitude: 13.819,
    longitude: 100.05,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "บางแขม",
    zipcode: 73000,
    latitude: 13.754,
    longitude: 100.034,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "พระประโทน",
    zipcode: 73000,
    latitude: 13.811,
    longitude: 100.088,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "ธรรมศาลา",
    zipcode: 73000,
    latitude: 13.807,
    longitude: 100.105,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "ตาก้อง",
    zipcode: 73000,
    latitude: 13.892,
    longitude: 100.054,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "มาบแค",
    zipcode: 73000,
    latitude: 13.864,
    longitude: 100.073,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "สนามจันทร์",
    zipcode: 73000,
    latitude: 13.804,
    longitude: 100.041,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "ดอนยายหอม",
    zipcode: 73000,
    latitude: 13.725,
    longitude: 100.07,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "ถนนขาด",
    zipcode: 73000,
    latitude: 13.782,
    longitude: 100.077,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "บ่อพลับ",
    zipcode: 73000,
    latitude: 13.837,
    longitude: 100.065,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "นครปฐม",
    zipcode: 73000,
    latitude: 13.848,
    longitude: 100.044,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "วังตะกู",
    zipcode: 73000,
    latitude: 13.857,
    longitude: 100.014,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "หนองปากโลง",
    zipcode: 73000,
    latitude: 13.866,
    longitude: 99.989,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "สามควายเผือก",
    zipcode: 73000,
    latitude: 13.841,
    longitude: 100.113,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "ทุ่งน้อย",
    zipcode: 73000,
    latitude: 13.867,
    longitude: 100.108,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "หนองดินแดง",
    zipcode: 73000,
    latitude: 13.786,
    longitude: 99.991,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "วังเย็น",
    zipcode: 73000,
    latitude: 13.766,
    longitude: 100.008,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "โพรงมะเดื่อ",
    zipcode: 73000,
    latitude: 13.84,
    longitude: 99.978,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "ลำพยา",
    zipcode: 73000,
    latitude: 13.811,
    longitude: 100.019,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "สระกะเทียม",
    zipcode: 73000,
    latitude: 13.7650868,
    longitude: 99.9347935,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "สวนป่าน",
    zipcode: 73000,
    latitude: 13.757,
    longitude: 99.947,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "ห้วยจรเข้",
    zipcode: 73000,
    latitude: 13.804,
    longitude: 100.067,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "ทัพหลวง",
    zipcode: 73000,
    latitude: 13.878,
    longitude: 100.021,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "หนองงูเหลือม",
    zipcode: 73000,
    latitude: 13.914,
    longitude: 99.952,
  },
  {
    province: "นครปฐม",
    district: "เมืองนครปฐม",
    subdistrict: "บ้านยาง",
    zipcode: 73000,
    latitude: 13.868,
    longitude: 99.929,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "ทุ่งกระพังโหม",
    zipcode: 73140,
    latitude: 13.991,
    longitude: 99.983,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "กระตีบ",
    zipcode: 73180,
    latitude: 14.111,
    longitude: 99.964,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "ทุ่งลูกนก",
    zipcode: 73140,
    latitude: 14.055,
    longitude: 99.905,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "ห้วยขวาง",
    zipcode: 73140,
    latitude: 13.923,
    longitude: 100.015,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "ทุ่งขวาง",
    zipcode: 73140,
    latitude: 13.965,
    longitude: 99.963,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "สระสี่มุม",
    zipcode: 73140,
    latitude: 14.065,
    longitude: 100.003,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "ทุ่งบัว",
    zipcode: 73140,
    latitude: 14.041,
    longitude: 99.935,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "ดอนข่อย",
    zipcode: 73140,
    latitude: 14.022,
    longitude: 100.035,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "สระพัฒนา",
    zipcode: 73180,
    latitude: 14.096,
    longitude: 100.043,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "ห้วยหมอนทอง",
    zipcode: 73140,
    latitude: 13.969,
    longitude: 99.921,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "ห้วยม่วง",
    zipcode: 73180,
    latitude: 14.13,
    longitude: 100.025,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "กำแพงแสน",
    zipcode: 73140,
    latitude: 14.024,
    longitude: 99.981,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "รางพิกุล",
    zipcode: 73140,
    latitude: 14.003,
    longitude: 99.917,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "หนองกระทุ่ม",
    zipcode: 73140,
    latitude: 14.004,
    longitude: 99.865,
  },
  {
    province: "นครปฐม",
    district: "กำแพงแสน",
    subdistrict: "วังน้ำเขียว",
    zipcode: 73140,
    latitude: 13.99,
    longitude: 100.027,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "นครชัยศรี",
    zipcode: 73120,
    latitude: 13.787,
    longitude: 100.192,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "บางกระเบา",
    zipcode: 73120,
    latitude: 13.793,
    longitude: 100.183,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "วัดแค",
    zipcode: 73120,
    latitude: 13.814,
    longitude: 100.175,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ท่าตำหนัก",
    zipcode: 73120,
    latitude: 13.766,
    longitude: 100.18,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "บางแก้ว",
    zipcode: 73120,
    latitude: 13.775,
    longitude: 100.168,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ท่ากระชับ",
    zipcode: 73120,
    latitude: 13.76,
    longitude: 100.155,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ขุนแก้ว",
    zipcode: 73120,
    latitude: 13.774,
    longitude: 100.209,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ท่าพระยา",
    zipcode: 73120,
    latitude: 13.785,
    longitude: 100.144,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "พะเนียด",
    zipcode: 73120,
    latitude: 13.795,
    longitude: 100.123,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "บางระกำ",
    zipcode: 73120,
    latitude: 13.756,
    longitude: 100.134,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "โคกพระเจดีย์",
    zipcode: 73120,
    latitude: 13.726,
    longitude: 100.126,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ศรีษะทอง",
    zipcode: 73120,
    latitude: 13.817,
    longitude: 100.143,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "แหลมบัว",
    zipcode: 73120,
    latitude: 13.857,
    longitude: 100.147,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ศรีมหาโพธิ์",
    zipcode: 73120,
    latitude: 13.889,
    longitude: 100.155,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "สัมปทวน",
    zipcode: 73120,
    latitude: 13.83,
    longitude: 100.195,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "วัดสำโรง",
    zipcode: 73120,
    latitude: 13.827,
    longitude: 100.218,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ดอนแฝก",
    zipcode: 73120,
    latitude: 13.859,
    longitude: 100.244,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ห้วยพลู",
    zipcode: 73120,
    latitude: 13.878,
    longitude: 100.233,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "วัดละมุด",
    zipcode: 73120,
    latitude: 13.886,
    longitude: 100.182,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "บางพระ",
    zipcode: 73120,
    latitude: 13.891,
    longitude: 100.206,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "บางแก้วฟ้า",
    zipcode: 73120,
    latitude: 13.896,
    longitude: 100.247,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ลานตากฟ้า",
    zipcode: 73120,
    latitude: 13.828,
    longitude: 100.233,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "งิ้วราย",
    zipcode: 73120,
    latitude: 13.807,
    longitude: 100.212,
  },
  {
    province: "นครปฐม",
    district: "นครชัยศรี",
    subdistrict: "ไทยาวาส",
    zipcode: 73120,
    latitude: 13.793,
    longitude: 100.215,
  },
  {
    province: "นครปฐม",
    district: "ดอนตูม",
    subdistrict: "สามง่าม",
    zipcode: 73150,
    latitude: 13.978,
    longitude: 100.083,
  },
  {
    province: "นครปฐม",
    district: "ดอนตูม",
    subdistrict: "ห้วยพระ",
    zipcode: 73150,
    latitude: 13.93,
    longitude: 100.084,
  },
  {
    province: "นครปฐม",
    district: "ดอนตูม",
    subdistrict: "ลำเหย",
    zipcode: 73150,
    latitude: 13.957,
    longitude: 100.044,
  },
  {
    province: "นครปฐม",
    district: "ดอนตูม",
    subdistrict: "ดอนพุทรา",
    zipcode: 73150,
    latitude: 13.936,
    longitude: 100.124,
  },
  {
    province: "นครปฐม",
    district: "ดอนตูม",
    subdistrict: "บ้านหลวง",
    zipcode: 73150,
    latitude: 13.911,
    longitude: 100.15,
  },
  {
    province: "นครปฐม",
    district: "ดอนตูม",
    subdistrict: "ดอนรวก",
    zipcode: 73150,
    latitude: 13.89,
    longitude: 100.117,
  },
  {
    province: "นครปฐม",
    district: "ดอนตูม",
    subdistrict: "ห้วยด้วน",
    zipcode: 73150,
    latitude: 13.902,
    longitude: 100.1,
  },
  {
    province: "นครปฐม",
    district: "ดอนตูม",
    subdistrict: "ลำลูกบัว",
    zipcode: 73150,
    latitude: 14.024,
    longitude: 100.074,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "บางเลน",
    zipcode: 73130,
    latitude: 14.014,
    longitude: 100.151,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "บางปลา",
    zipcode: 73130,
    latitude: 13.97,
    longitude: 100.158,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "บางหลวง",
    zipcode: 73190,
    latitude: 14.108,
    longitude: 100.093,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "บางภาษี",
    zipcode: 73130,
    latitude: 14.038,
    longitude: 100.243,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "บางระกำ",
    zipcode: 73130,
    latitude: 13.914,
    longitude: 100.254,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "บางไทรป่า",
    zipcode: 73130,
    latitude: 14.047,
    longitude: 100.15,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "หินมูล",
    zipcode: 73190,
    latitude: 14.104,
    longitude: 100.147,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "ไทรงาม",
    zipcode: 73130,
    latitude: 14.093,
    longitude: 100.177,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "ดอนตูม",
    zipcode: 73130,
    latitude: 13.999,
    longitude: 100.123,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "นิลเพชร",
    zipcode: 73130,
    latitude: 14.121,
    longitude: 100.235,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "บัวปากท่า",
    zipcode: 73130,
    latitude: 14.159,
    longitude: 100.228,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "คลองนกกระทุง",
    zipcode: 73130,
    latitude: 13.991,
    longitude: 100.222,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "นราภิรมย์",
    zipcode: 73130,
    latitude: 13.953,
    longitude: 100.262,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "ลำพญา",
    zipcode: 73130,
    latitude: 13.954,
    longitude: 100.199,
  },
  {
    province: "นครปฐม",
    district: "บางเลน",
    subdistrict: "ไผ่หูช้าง",
    zipcode: 73130,
    latitude: 14.064,
    longitude: 100.116,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "ท่าข้าม",
    zipcode: 73110,
    latitude: 13.714,
    longitude: 100.247,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "ทรงคนอง",
    zipcode: 73210,
    latitude: 13.78,
    longitude: 100.262,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "หอมเกร็ด",
    zipcode: 73110,
    latitude: 13.779,
    longitude: 100.24,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "บางกระทึก",
    zipcode: 73210,
    latitude: 13.772,
    longitude: 100.305,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "บางเตย",
    zipcode: 73210,
    latitude: 13.787,
    longitude: 100.289,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "สามพราน",
    zipcode: 73110,
    latitude: 13.716,
    longitude: 100.219,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "บางช้าง",
    zipcode: 73110,
    latitude: 13.702,
    longitude: 100.2,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "ไร่ขิง",
    zipcode: 73210,
    latitude: 13.737,
    longitude: 100.283,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "ท่าตลาด",
    zipcode: 73110,
    latitude: 13.751,
    longitude: 100.234,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "กระทุ่มล้ม",
    zipcode: 73220,
    latitude: 13.737,
    longitude: 100.322,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "คลองใหม่",
    zipcode: 73110,
    latitude: 13.734,
    longitude: 100.174,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "ตลาดจินดา",
    zipcode: 73110,
    latitude: 13.698,
    longitude: 100.15,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "คลองจินดา",
    zipcode: 73110,
    latitude: 13.677,
    longitude: 100.092,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "ยายชา",
    zipcode: 73110,
    latitude: 13.733,
    longitude: 100.235,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "บ้านใหม่",
    zipcode: 73110,
    latitude: 13.69,
    longitude: 100.242,
  },
  {
    province: "นครปฐม",
    district: "สามพราน",
    subdistrict: "อ้อมใหญ่",
    zipcode: 73160,
    latitude: 13.7,
    longitude: 100.268,
  },
  {
    province: "นครปฐม",
    district: "พุทธมณฑล",
    subdistrict: "ศาลายา",
    zipcode: 73170,
    latitude: 13.813,
    longitude: 100.305,
  },
  {
    province: "นครปฐม",
    district: "พุทธมณฑล",
    subdistrict: "คลองโยง",
    zipcode: 73170,
    latitude: 13.862,
    longitude: 100.281,
  },
  {
    province: "นครปฐม",
    district: "พุทธมณฑล",
    subdistrict: "มหาสวัสดิ์",
    zipcode: 73170,
    latitude: 13.815,
    longitude: 100.262,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "มหาชัย",
    zipcode: 74000,
    latitude: 13.552,
    longitude: 100.28,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "ท่าฉลอม",
    zipcode: 74000,
    latitude: 13.538,
    longitude: 100.269,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "โกรกกราก",
    zipcode: 74000,
    latitude: 13.533,
    longitude: 100.276,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "บ้านบ่อ",
    zipcode: 74000,
    latitude: 13.487,
    longitude: 100.192,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "บางโทรัด",
    zipcode: 74000,
    latitude: 13.492,
    longitude: 100.158,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "กาหลง",
    zipcode: 74000,
    latitude: 13.483,
    longitude: 100.13,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "นาโคก",
    zipcode: 74000,
    latitude: 13.464,
    longitude: 100.096,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "ท่าจีน",
    zipcode: 74000,
    latitude: 13.539,
    longitude: 100.252,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "นาดี",
    zipcode: 74000,
    latitude: 13.595,
    longitude: 100.295,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "ท่าทราย",
    zipcode: 74000,
    latitude: 13.571,
    longitude: 100.266,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "คอกกระบือ",
    zipcode: 74000,
    latitude: 13.599,
    longitude: 100.333,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "บางน้ำจืด",
    zipcode: 74000,
    latitude: 13.614,
    longitude: 100.363,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "พันท้ายนรสิงห์",
    zipcode: 74000,
    latitude: 13.539,
    longitude: 100.377,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "โคกขาม",
    zipcode: 74000,
    latitude: 13.531,
    longitude: 100.326,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "บ้านเกาะ",
    zipcode: 74000,
    latitude: 13.591,
    longitude: 100.247,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "บางกระเจ้า",
    zipcode: 74000,
    latitude: 13.512,
    longitude: 100.215,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "บางหญ้าแพรก",
    zipcode: 74000,
    latitude: 13.501,
    longitude: 100.25,
  },
  {
    province: "สมุทรสาคร",
    district: "เมืองสมุทรสาคร",
    subdistrict: "ชัยมงคล",
    zipcode: 74000,
    latitude: 13.54,
    longitude: 100.185,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "ตลาดกระทุ่มแบน",
    zipcode: 74110,
    latitude: 13.654,
    longitude: 100.25,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "อ้อมน้อย",
    zipcode: 74130,
    latitude: 13.702,
    longitude: 100.308,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "ท่าไม้",
    zipcode: 74110,
    latitude: 13.671,
    longitude: 100.261,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "สวนหลวง",
    zipcode: 74110,
    latitude: 13.671,
    longitude: 100.323,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "บางยาง",
    zipcode: 74110,
    latitude: 13.652,
    longitude: 100.226,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "คลองมะเดื่อ",
    zipcode: 74110,
    latitude: 13.637,
    longitude: 100.292,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "หนองนกไข่",
    zipcode: 74110,
    latitude: 13.668,
    longitude: 100.192,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "ดอนไก่ดี",
    zipcode: 74110,
    latitude: 13.633,
    longitude: 100.266,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "แคราย",
    zipcode: 74110,
    latitude: 13.643,
    longitude: 100.324,
  },
  {
    province: "สมุทรสาคร",
    district: "กระทุ่มแบน",
    subdistrict: "ท่าเสา",
    zipcode: 74110,
    latitude: 13.623,
    longitude: 100.24,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "บ้านแพ้ว",
    zipcode: 74120,
    latitude: 13.618,
    longitude: 100.104,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "หลักสาม",
    zipcode: 74120,
    latitude: 13.571,
    longitude: 100.139,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "ยกกระบัตร",
    zipcode: 74120,
    latitude: 13.559,
    longitude: 100.082,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "โรงเข้",
    zipcode: 74120,
    latitude: 13.521,
    longitude: 100.069,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "หนองสองห้อง",
    zipcode: 74120,
    latitude: 13.581,
    longitude: 100.065,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "หนองบัว",
    zipcode: 74120,
    latitude: 13.598,
    longitude: 100.075,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "หลักสอง",
    zipcode: 74120,
    latitude: 13.614,
    longitude: 100.134,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "เจ็ดริ้ว",
    zipcode: 74120,
    latitude: 13.639,
    longitude: 100.141,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "คลองตัน",
    zipcode: 74120,
    latitude: 13.641,
    longitude: 100.171,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "อำแพง",
    zipcode: 74120,
    latitude: 13.6,
    longitude: 100.189,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "สวนส้ม",
    zipcode: 74120,
    latitude: 13.624,
    longitude: 100.186,
  },
  {
    province: "สมุทรสาคร",
    district: "บ้านแพ้ว",
    subdistrict: "เกษตรพัฒนา",
    zipcode: 74120,
    latitude: 13.666,
    longitude: 100.153,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "แม่กลอง",
    zipcode: 75000,
    latitude: 13.4,
    longitude: 99.994,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "บางขันแตก",
    zipcode: 75000,
    latitude: 13.384,
    longitude: 99.955,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "ลาดใหญ่",
    zipcode: 75000,
    latitude: 13.451,
    longitude: 100.037,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "บ้านปรก",
    zipcode: 75000,
    latitude: 13.43,
    longitude: 99.992,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "บางแก้ว",
    zipcode: 75000,
    latitude: 13.431,
    longitude: 100.06,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "ท้ายหาด",
    zipcode: 75000,
    latitude: 13.399,
    longitude: 99.976,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "แหลมใหญ่",
    zipcode: 75000,
    latitude: 13.354,
    longitude: 99.99,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "คลองเขิน",
    zipcode: 75000,
    latitude: 13.445,
    longitude: 99.988,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "คลองโคน",
    zipcode: 75000,
    latitude: 13.329,
    longitude: 99.95,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "นางตะเคียน",
    zipcode: 75000,
    latitude: 13.48,
    longitude: 100.027,
  },
  {
    province: "สมุทรสงคราม",
    district: "เมืองสมุทรสงคราม",
    subdistrict: "บางจะเกร็ง",
    zipcode: 75000,
    latitude: 13.376,
    longitude: 100.01,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "กระดังงา",
    zipcode: 75120,
    latitude: 13.472,
    longitude: 99.951,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "บางสะแก",
    zipcode: 75120,
    latitude: 13.45,
    longitude: 99.928,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "บางยี่รงค์",
    zipcode: 75120,
    latitude: 13.481,
    longitude: 99.912,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "โรงหีบ",
    zipcode: 75120,
    latitude: 13.47,
    longitude: 99.931,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "บางคนที",
    zipcode: 75120,
    latitude: 13.493,
    longitude: 99.945,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "ดอนมะโนรา",
    zipcode: 75120,
    latitude: 13.499,
    longitude: 100.018,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "บางพรม",
    zipcode: 75120,
    latitude: 13.452,
    longitude: 99.955,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "บางกุ้ง",
    zipcode: 75120,
    latitude: 13.459,
    longitude: 99.931,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "จอมปลวก",
    zipcode: 75120,
    latitude: 13.483,
    longitude: 99.976,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "บางนกแขวก",
    zipcode: 75120,
    latitude: 13.504,
    longitude: 99.923,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "ยายแพง",
    zipcode: 75120,
    latitude: 13.487,
    longitude: 99.958,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "บางกระบือ",
    zipcode: 75120,
    latitude: 13.463,
    longitude: 99.968,
  },
  {
    province: "สมุทรสงคราม",
    district: "บางคนที",
    subdistrict: "บ้านปราโมทย์",
    zipcode: 75120,
    latitude: 13.46,
    longitude: 99.898,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "อัมพวา",
    zipcode: 75110,
    latitude: 13.427,
    longitude: 99.954,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "สวนหลวง",
    zipcode: 75110,
    latitude: 13.414,
    longitude: 99.953,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "ท่าคา",
    zipcode: 75110,
    latitude: 13.466,
    longitude: 99.993,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "วัดประดู่",
    zipcode: 75110,
    latitude: 13.38,
    longitude: 99.875,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "เหมืองใหม่",
    zipcode: 75110,
    latitude: 13.433,
    longitude: 99.91,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "บางช้าง",
    zipcode: 75110,
    latitude: 13.437,
    longitude: 99.953,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "แควอ้อม",
    zipcode: 75110,
    latitude: 13.436,
    longitude: 99.937,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "ปลายโพงพาง",
    zipcode: 75110,
    latitude: 13.385,
    longitude: 99.923,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "บางแค",
    zipcode: 75110,
    latitude: 13.407,
    longitude: 99.923,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "แพรกหนามแดง",
    zipcode: 75110,
    latitude: 13.343,
    longitude: 99.882,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "ยี่สาร",
    zipcode: 75110,
    latitude: 13.297,
    longitude: 99.895,
  },
  {
    province: "สมุทรสงคราม",
    district: "อัมพวา",
    subdistrict: "บางนางลี่",
    zipcode: 75110,
    latitude: 13.414,
    longitude: 99.917,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "ท่าราบ",
    zipcode: 76000,
    latitude: 13.097,
    longitude: 99.949,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "คลองกระแชง",
    zipcode: 76000,
    latitude: 13.1033104,
    longitude: 99.9231582,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "บางจาน",
    zipcode: 76000,
    latitude: 13.13,
    longitude: 100.015,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "นาพันสาม",
    zipcode: 76000,
    latitude: 13.054,
    longitude: 100.03,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "ธงชัย",
    zipcode: 76000,
    latitude: 13.151,
    longitude: 99.918,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "บ้านกุ่ม",
    zipcode: 76000,
    latitude: 13.156,
    longitude: 99.941,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "หนองโสน",
    zipcode: 76000,
    latitude: 13.134,
    longitude: 99.973,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "ไร่ส้ม",
    zipcode: 76000,
    latitude: 13.112,
    longitude: 99.916,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "เวียงคอย",
    zipcode: 76000,
    latitude: 13.121,
    longitude: 99.9,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "บางจาก",
    zipcode: 76000,
    latitude: 13.158,
    longitude: 99.891,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "บ้านหม้อ",
    zipcode: 76000,
    latitude: 13.092,
    longitude: 99.932,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "ต้นมะม่วง",
    zipcode: 76000,
    latitude: 13.079,
    longitude: 99.958,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "ช่องสะแก",
    zipcode: 76000,
    latitude: 13.105,
    longitude: 99.997,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "นาวุ้ง",
    zipcode: 76000,
    latitude: 13.088,
    longitude: 99.977,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "สำมะโรง",
    zipcode: 76000,
    latitude: 13.07,
    longitude: 99.992,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "โพพระ",
    zipcode: 76000,
    latitude: 13.082,
    longitude: 100.039,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "หาดเจ้าสำราญ",
    zipcode: 76100,
    latitude: 13.007,
    longitude: 100.044,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "หัวสะพาน",
    zipcode: 76000,
    latitude: 13.117,
    longitude: 99.866,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "ต้นมะพร้าว",
    zipcode: 76000,
    latitude: 13.146,
    longitude: 99.86,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "วังตะโก",
    zipcode: 76000,
    latitude: 13.134,
    longitude: 99.893,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "โพไร่หวาน",
    zipcode: 76000,
    latitude: 13.053,
    longitude: 99.974,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "ดอนยาง",
    zipcode: 76000,
    latitude: 13.015,
    longitude: 99.976,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "หนองขนาน",
    zipcode: 76000,
    latitude: 12.995,
    longitude: 100.013,
  },
  {
    province: "เพชรบุรี",
    district: "เมืองเพชรบุรี",
    subdistrict: "หนองพลับ",
    zipcode: 76000,
    latitude: 13.048,
    longitude: 100,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "เขาย้อย",
    zipcode: 76140,
    latitude: 13.233,
    longitude: 99.819,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "สระพัง",
    zipcode: 76140,
    latitude: 13.257,
    longitude: 99.811,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "บางเค็ม",
    zipcode: 76140,
    latitude: 13.284,
    longitude: 99.838,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "ทับคาง",
    zipcode: 76140,
    latitude: 13.204,
    longitude: 99.833,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "หนองปลาไหล",
    zipcode: 76140,
    latitude: 13.194,
    longitude: 99.877,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "หนองปรง",
    zipcode: 76140,
    latitude: 13.168,
    longitude: 99.801,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "หนองชุมพล",
    zipcode: 76140,
    latitude: 13.295,
    longitude: 99.797,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "ห้วยโรง",
    zipcode: 76140,
    latitude: 13.316,
    longitude: 99.841,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "ห้วยท่าช้าง",
    zipcode: 76140,
    latitude: 13.136,
    longitude: 99.8,
  },
  {
    province: "เพชรบุรี",
    district: "เขาย้อย",
    subdistrict: "หนองชุมพลเหนือ",
    zipcode: 76140,
    latitude: 13.301,
    longitude: 99.724,
  },
  {
    province: "เพชรบุรี",
    district: "หนองหญ้าปล้อง",
    subdistrict: "หนองหญ้าปล้อง",
    zipcode: 76160,
    latitude: 13.201,
    longitude: 99.709,
  },
  {
    province: "เพชรบุรี",
    district: "หนองหญ้าปล้อง",
    subdistrict: "ยางน้ำกลัดเหนือ",
    zipcode: 76160,
    latitude: 13.158,
    longitude: 99.39,
  },
  {
    province: "เพชรบุรี",
    district: "หนองหญ้าปล้อง",
    subdistrict: "ยางน้ำกลัดใต้",
    zipcode: 76160,
    latitude: 13.07,
    longitude: 99.505,
  },
  {
    province: "เพชรบุรี",
    district: "หนองหญ้าปล้อง",
    subdistrict: "ท่าตะคร้อ",
    zipcode: 76160,
    latitude: 13.099,
    longitude: 99.749,
  },
  {
    province: "เพชรบุรี",
    district: "ชะอำ",
    subdistrict: "ชะอำ",
    zipcode: 76120,
    latitude: 12.719,
    longitude: 99.939,
  },
  {
    province: "เพชรบุรี",
    district: "ชะอำ",
    subdistrict: "บางเก่า",
    zipcode: 76120,
    latitude: 12.856,
    longitude: 99.985,
  },
  {
    province: "เพชรบุรี",
    district: "ชะอำ",
    subdistrict: "นายาง",
    zipcode: 76120,
    latitude: 12.887,
    longitude: 99.945,
  },
  {
    province: "เพชรบุรี",
    district: "ชะอำ",
    subdistrict: "เขาใหญ่",
    zipcode: 76120,
    latitude: 12.793,
    longitude: 99.894,
  },
  {
    province: "เพชรบุรี",
    district: "ชะอำ",
    subdistrict: "หนองศาลา",
    zipcode: 76120,
    latitude: 12.891,
    longitude: 99.971,
  },
  {
    province: "เพชรบุรี",
    district: "ชะอำ",
    subdistrict: "ห้วยทรายเหนือ",
    zipcode: 76120,
    latitude: 12.715,
    longitude: 99.832,
  },
  {
    province: "เพชรบุรี",
    district: "ชะอำ",
    subdistrict: "ไร่ใหม่พัฒนา",
    zipcode: 76120,
    latitude: 12.655,
    longitude: 99.806,
  },
  {
    province: "เพชรบุรี",
    district: "ชะอำ",
    subdistrict: "สามพระยา",
    zipcode: 76120,
    latitude: 12.684,
    longitude: 99.882,
  },
  {
    province: "เพชรบุรี",
    district: "ชะอำ",
    subdistrict: "ดอนขุนห้วย",
    zipcode: 76120,
    latitude: 12.864,
    longitude: 99.892,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "ท่ายาง",
    zipcode: 76130,
    latitude: 12.954,
    longitude: 99.904,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "ท่าคอย",
    zipcode: 76130,
    latitude: 12.922,
    longitude: 99.872,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "ยางหย่อง",
    zipcode: 76130,
    latitude: 12.99,
    longitude: 99.883,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "หนองจอก",
    zipcode: 76130,
    latitude: 12.947,
    longitude: 99.989,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "มาบปลาเค้า",
    zipcode: 76130,
    latitude: 12.981,
    longitude: 99.929,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "ท่าไม้รวก",
    zipcode: 76130,
    latitude: 12.847,
    longitude: 99.814,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "วังไคร้",
    zipcode: 76130,
    latitude: 12.919,
    longitude: 99.82,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "กลัดหลวง",
    zipcode: 76130,
    latitude: 12.801,
    longitude: 99.732,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "ปึกเตียน",
    zipcode: 76130,
    latitude: 12.943,
    longitude: 100.014,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "เขากระปุก",
    zipcode: 76130,
    latitude: 12.693,
    longitude: 99.728,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "ท่าแลง",
    zipcode: 76130,
    latitude: 12.961,
    longitude: 99.832,
  },
  {
    province: "เพชรบุรี",
    district: "ท่ายาง",
    subdistrict: "บ้านในดง",
    zipcode: 76130,
    latitude: 12.949,
    longitude: 99.941,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "บ้านลาด",
    zipcode: 76150,
    latitude: 13.063,
    longitude: 99.928,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "บ้านหาด",
    zipcode: 76150,
    latitude: 13.076,
    longitude: 99.918,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "บ้านทาน",
    zipcode: 76150,
    latitude: 13.081,
    longitude: 99.842,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ตำหรุ",
    zipcode: 76150,
    latitude: 13.027,
    longitude: 99.893,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "สมอพลือ",
    zipcode: 76150,
    latitude: 13.06,
    longitude: 99.952,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ไร่มะขาม",
    zipcode: 76150,
    latitude: 13.017,
    longitude: 99.939,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ท่าเสน",
    zipcode: 76150,
    latitude: 13.046,
    longitude: 99.948,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "หนองกระเจ็ด",
    zipcode: 76150,
    latitude: 13.001,
    longitude: 99.953,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "หนองกะปุ",
    zipcode: 76150,
    latitude: 13.065,
    longitude: 99.786,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ลาดโพธิ์",
    zipcode: 76150,
    latitude: 13.102,
    longitude: 99.895,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "สะพานไกร",
    zipcode: 76150,
    latitude: 13.0956697,
    longitude: 99.8488791,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ไร่โคก",
    zipcode: 76150,
    latitude: 13.112,
    longitude: 99.821,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "โรงเข้",
    zipcode: 76150,
    latitude: 13.081,
    longitude: 99.891,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ไร่สะท้อน",
    zipcode: 76150,
    latitude: 13.03,
    longitude: 99.866,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ห้วยข้อง",
    zipcode: 76150,
    latitude: 13.035,
    longitude: 99.829,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ท่าช้าง",
    zipcode: 76150,
    latitude: 13.057,
    longitude: 99.898,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ถ้ำรงค์",
    zipcode: 76150,
    latitude: 13.022,
    longitude: 99.911,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านลาด",
    subdistrict: "ห้วยลึก",
    zipcode: 76150,
    latitude: 13.008,
    longitude: 99.793,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "บ้านแหลม",
    zipcode: 76110,
    latitude: 13.206,
    longitude: 99.98,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "บางขุนไทร",
    zipcode: 76110,
    latitude: 13.161,
    longitude: 100.018,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "ปากทะเล",
    zipcode: 76110,
    latitude: 13.144,
    longitude: 100.04,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "บางแก้ว",
    zipcode: 76110,
    latitude: 13.111,
    longitude: 100.045,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "แหลมผักเบี้ย",
    zipcode: 76100,
    latitude: 13.044,
    longitude: 100.076,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "บางตะบูน",
    zipcode: 76110,
    latitude: 13.259,
    longitude: 99.912,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "บางตะบูนออก",
    zipcode: 76110,
    latitude: 13.247,
    longitude: 99.95,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "บางครก",
    zipcode: 76110,
    latitude: 13.204,
    longitude: 99.933,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "ท่าแร้ง",
    zipcode: 76110,
    latitude: 13.159,
    longitude: 99.96,
  },
  {
    province: "เพชรบุรี",
    district: "บ้านแหลม",
    subdistrict: "ท่าแร้งออก",
    zipcode: 76110,
    latitude: 13.155,
    longitude: 99.976,
  },
  {
    province: "เพชรบุรี",
    district: "แก่งกระจาน",
    subdistrict: "แก่งกระจาน",
    zipcode: 76170,
    latitude: 12.979,
    longitude: 99.609,
  },
  {
    province: "เพชรบุรี",
    district: "แก่งกระจาน",
    subdistrict: "สองพี่น้อง",
    zipcode: 76170,
    latitude: 12.853,
    longitude: 99.614,
  },
  {
    province: "เพชรบุรี",
    district: "แก่งกระจาน",
    subdistrict: "วังจันทร์",
    zipcode: 76170,
    latitude: 12.942,
    longitude: 99.735,
  },
  {
    province: "เพชรบุรี",
    district: "แก่งกระจาน",
    subdistrict: "ป่าเด็ง",
    zipcode: 76170,
    latitude: 12.651,
    longitude: 99.48,
  },
  {
    province: "เพชรบุรี",
    district: "แก่งกระจาน",
    subdistrict: "พุสวรรค์",
    zipcode: 76170,
    latitude: 12.997,
    longitude: 99.743,
  },
  {
    province: "เพชรบุรี",
    district: "แก่งกระจาน",
    subdistrict: "ห้วยแม่เพรียง",
    zipcode: 76170,
    latitude: 12.913,
    longitude: 99.284,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "เมืองประจวบคีรีขันธ์",
    subdistrict: "ประจวบคีรีขันธ์",
    zipcode: 77000,
    latitude: 11.805,
    longitude: 99.784,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "เมืองประจวบคีรีขันธ์",
    subdistrict: "เกาะหลัก",
    zipcode: 77000,
    latitude: 11.808,
    longitude: 99.729,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "เมืองประจวบคีรีขันธ์",
    subdistrict: "คลองวาฬ",
    zipcode: 77000,
    latitude: 11.756,
    longitude: 99.725,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "เมืองประจวบคีรีขันธ์",
    subdistrict: "ห้วยทราย",
    zipcode: 77000,
    latitude: 11.709,
    longitude: 99.672,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "เมืองประจวบคีรีขันธ์",
    subdistrict: "อ่าวน้อย",
    zipcode: 77000,
    latitude: 11.904,
    longitude: 99.701,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "เมืองประจวบคีรีขันธ์",
    subdistrict: "บ่อนอก",
    zipcode: 77210,
    latitude: 11.996,
    longitude: 99.771,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "กุยบุรี",
    subdistrict: "กุยบุรี",
    zipcode: 77150,
    latitude: 12.052,
    longitude: 99.763,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "กุยบุรี",
    subdistrict: "กุยเหนือ",
    zipcode: 77150,
    latitude: 12.086,
    longitude: 99.908,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "กุยบุรี",
    subdistrict: "เขาแดง",
    zipcode: 77150,
    latitude: 12.159,
    longitude: 99.98,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "กุยบุรี",
    subdistrict: "ดอนยายหนู",
    zipcode: 77150,
    latitude: 12.136,
    longitude: 99.929,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "กุยบุรี",
    subdistrict: "สามกระทาย",
    zipcode: 77150,
    latitude: 12.148,
    longitude: 99.843,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "กุยบุรี",
    subdistrict: "หาดขาม",
    zipcode: 77150,
    latitude: 12.108,
    longitude: 99.659,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ทับสะแก",
    subdistrict: "ทับสะแก",
    zipcode: 77130,
    latitude: 11.516,
    longitude: 99.627,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ทับสะแก",
    subdistrict: "อ่างทอง",
    zipcode: 77130,
    latitude: 11.454,
    longitude: 99.508,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ทับสะแก",
    subdistrict: "นาหูกวาง",
    zipcode: 77130,
    latitude: 11.537,
    longitude: 99.499,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ทับสะแก",
    subdistrict: "เขาล้าน",
    zipcode: 77130,
    latitude: 11.606,
    longitude: 99.501,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ทับสะแก",
    subdistrict: "ห้วยยาง",
    zipcode: 77130,
    latitude: 11.637,
    longitude: 99.641,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ทับสะแก",
    subdistrict: "แสงอรุณ",
    zipcode: 77130,
    latitude: 11.573,
    longitude: 99.628,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพาน",
    subdistrict: "กำเนิดนพคุณ",
    zipcode: 77140,
    latitude: 11.248,
    longitude: 99.516,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพาน",
    subdistrict: "พงศ์ประศาสน์",
    zipcode: 77140,
    latitude: 11.188,
    longitude: 99.466,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพาน",
    subdistrict: "ร่อนทอง",
    zipcode: 77230,
    latitude: 11.299,
    longitude: 99.393,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพาน",
    subdistrict: "ธงชัย",
    zipcode: 77190,
    latitude: 11.318,
    longitude: 99.525,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพาน",
    subdistrict: "ชัยเกษม",
    zipcode: 77190,
    latitude: 11.375,
    longitude: 99.473,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพาน",
    subdistrict: "ทองมงคล",
    zipcode: 77230,
    latitude: 11.204,
    longitude: 99.369,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพาน",
    subdistrict: "แม่รำพึง",
    zipcode: 77140,
    latitude: 11.227,
    longitude: 99.544,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพานน้อย",
    subdistrict: "ปากแพรก",
    zipcode: 77170,
    latitude: 11.063,
    longitude: 99.436,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพานน้อย",
    subdistrict: "บางสะพาน",
    zipcode: 77170,
    latitude: 11.107,
    longitude: 99.443,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพานน้อย",
    subdistrict: "ทรายทอง",
    zipcode: 77170,
    latitude: 11.011,
    longitude: 99.424,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพานน้อย",
    subdistrict: "ช้างแรก",
    zipcode: 77170,
    latitude: 11.103,
    longitude: 99.306,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "บางสะพานน้อย",
    subdistrict: "ไชยราช",
    zipcode: 77170,
    latitude: 11.012,
    longitude: 99.256,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ปราณบุรี",
    subdistrict: "ปราณบุรี",
    zipcode: 77120,
    latitude: 12.354,
    longitude: 99.932,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ปราณบุรี",
    subdistrict: "เขาน้อย",
    zipcode: 77120,
    latitude: 12.405,
    longitude: 99.898,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ปราณบุรี",
    subdistrict: "ปากน้ำปราณ",
    zipcode: 77220,
    latitude: 12.381,
    longitude: 99.981,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ปราณบุรี",
    subdistrict: "หนองตาแต้ม",
    zipcode: 77120,
    latitude: 12.38,
    longitude: 99.814,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ปราณบุรี",
    subdistrict: "วังก์พง",
    zipcode: 77120,
    latitude: 12.447,
    longitude: 99.911,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "ปราณบุรี",
    subdistrict: "เขาจ้าว",
    zipcode: 77120,
    latitude: 12.396,
    longitude: 99.52,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "หัวหิน",
    subdistrict: "หัวหิน",
    zipcode: 77110,
    latitude: 12.592,
    longitude: 99.94,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "หัวหิน",
    subdistrict: "หนองแก",
    zipcode: 77110,
    latitude: 12.502,
    longitude: 99.958,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "หัวหิน",
    subdistrict: "หินเหล็กไฟ",
    zipcode: 77110,
    latitude: 12.592,
    longitude: 99.862,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "หัวหิน",
    subdistrict: "หนองพลับ",
    zipcode: 77110,
    latitude: 12.53,
    longitude: 99.704,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "หัวหิน",
    subdistrict: "ทับใต้",
    zipcode: 77110,
    latitude: 12.516,
    longitude: 99.863,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "หัวหิน",
    subdistrict: "ห้วยสัตว์ใหญ่",
    zipcode: 77110,
    latitude: 12.512,
    longitude: 99.526,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "หัวหิน",
    subdistrict: "บึงนคร",
    zipcode: 77110,
    latitude: 12.403,
    longitude: 99.655,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "สามร้อยยอด",
    subdistrict: "สามร้อยยอด",
    zipcode: 77120,
    latitude: 12.266,
    longitude: 99.946,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "สามร้อยยอด",
    subdistrict: "ศิลาลอย",
    zipcode: 77180,
    latitude: 12.309,
    longitude: 99.56,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "สามร้อยยอด",
    subdistrict: "ไร่เก่า",
    zipcode: 77180,
    latitude: 12.252,
    longitude: 99.706,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "สามร้อยยอด",
    subdistrict: "ศาลาลัย",
    zipcode: 77180,
    latitude: 12.271,
    longitude: 99.593,
  },
  {
    province: "ประจวบคีรีขันธ์",
    district: "สามร้อยยอด",
    subdistrict: "ไร่ใหม่",
    zipcode: 77180,
    latitude: 12.214,
    longitude: 99.746,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "ในเมือง",
    zipcode: 80000,
    latitude: 8.408,
    longitude: 99.972,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "ท่าวัง",
    zipcode: 80000,
    latitude: 8.449,
    longitude: 99.961,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "คลัง",
    zipcode: 80000,
    latitude: 8.434,
    longitude: 99.966,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "ท่าไร่",
    zipcode: 80000,
    latitude: 8.43,
    longitude: 100.041,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "ปากนคร",
    zipcode: 80000,
    latitude: 8.421,
    longitude: 99.996,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "นาทราย",
    zipcode: 80280,
    latitude: 8.479,
    longitude: 99.932,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "กำแพงเซา",
    zipcode: 80280,
    latitude: 8.401,
    longitude: 99.868,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "ไชยมนตรี",
    zipcode: 80000,
    latitude: 8.401,
    longitude: 99.914,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "มะม่วงสองต้น",
    zipcode: 80000,
    latitude: 8.397,
    longitude: 99.952,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "นาเคียน",
    zipcode: 80000,
    latitude: 8.452,
    longitude: 99.919,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "ท่างิ้ว",
    zipcode: 80280,
    latitude: 8.452,
    longitude: 99.835,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "โพธิ์เสด็จ",
    zipcode: 80000,
    latitude: 8.428,
    longitude: 99.92,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "บางจาก",
    zipcode: 80330,
    latitude: 8.376,
    longitude: 100.061,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "ปากพูน",
    zipcode: 80000,
    latitude: 8.529,
    longitude: 99.997,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "ท่าซัก",
    zipcode: 80000,
    latitude: 8.51,
    longitude: 100.032,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เมืองนครศรีธรรมราช",
    subdistrict: "ท่าเรือ",
    zipcode: 80000,
    latitude: 8.321,
    longitude: 100.013,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พรหมคีรี",
    subdistrict: "พรหมโลก",
    zipcode: 80320,
    latitude: 8.5,
    longitude: 99.793,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พรหมคีรี",
    subdistrict: "บ้านเกาะ",
    zipcode: 80320,
    latitude: 8.539,
    longitude: 99.814,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พรหมคีรี",
    subdistrict: "อินคีรี",
    zipcode: 80320,
    latitude: 8.54,
    longitude: 99.874,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พรหมคีรี",
    subdistrict: "ทอนหงส์",
    zipcode: 80320,
    latitude: 8.58,
    longitude: 99.776,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พรหมคีรี",
    subdistrict: "นาเรียง",
    zipcode: 80320,
    latitude: 8.504,
    longitude: 99.866,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ลานสกา",
    subdistrict: "เขาแก้ว",
    zipcode: 80230,
    latitude: 8.374,
    longitude: 99.742,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ลานสกา",
    subdistrict: "ลานสกา",
    zipcode: 80230,
    latitude: 8.305,
    longitude: 99.771,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ลานสกา",
    subdistrict: "ท่าดี",
    zipcode: 80230,
    latitude: 8.443,
    longitude: 99.804,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ลานสกา",
    subdistrict: "กำโลน",
    zipcode: 80230,
    latitude: 8.442,
    longitude: 99.757,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ลานสกา",
    subdistrict: "ขุนทะเล",
    zipcode: 80230,
    latitude: 8.344,
    longitude: 99.848,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "ฉวาง",
    zipcode: 80150,
    latitude: 8.397,
    longitude: 99.501,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "ละอาย",
    zipcode: 80250,
    latitude: 8.472,
    longitude: 99.632,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "นาแว",
    zipcode: 80150,
    latitude: 8.483,
    longitude: 99.523,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "ไม้เรียง",
    zipcode: 80150,
    latitude: 8.457,
    longitude: 99.477,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "กะเปียด",
    zipcode: 80260,
    latitude: 8.519,
    longitude: 99.46,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "นากะชะ",
    zipcode: 80150,
    latitude: 8.415,
    longitude: 99.476,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "ห้วยปริก",
    zipcode: 80260,
    latitude: 8.569,
    longitude: 99.44,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "ไสหร้า",
    zipcode: 80150,
    latitude: 8.437,
    longitude: 99.547,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "นาเขลียง",
    zipcode: 80260,
    latitude: 8.533,
    longitude: 99.5,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ฉวาง",
    subdistrict: "จันดี",
    zipcode: 80250,
    latitude: 8.391,
    longitude: 99.554,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พิปูน",
    subdistrict: "พิปูน",
    zipcode: 80270,
    latitude: 8.555,
    longitude: 99.653,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พิปูน",
    subdistrict: "กะทูน",
    zipcode: 80270,
    latitude: 8.638,
    longitude: 99.53,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พิปูน",
    subdistrict: "เขาพระ",
    zipcode: 80270,
    latitude: 8.634,
    longitude: 99.615,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พิปูน",
    subdistrict: "ยางค้อม",
    zipcode: 80270,
    latitude: 8.52,
    longitude: 99.701,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พิปูน",
    subdistrict: "ควนกลาง",
    zipcode: 80270,
    latitude: 8.534,
    longitude: 99.553,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "เชียรใหญ่",
    zipcode: 80190,
    latitude: 8.202,
    longitude: 100.116,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "ท่าขนาน",
    zipcode: 80190,
    latitude: 8.14,
    longitude: 100.165,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "บ้านกลาง",
    zipcode: 80190,
    latitude: 8.156,
    longitude: 100.197,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "บ้านเนิน",
    zipcode: 80190,
    latitude: 8.166,
    longitude: 100.17,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "ไสหมาก",
    zipcode: 80190,
    latitude: 8.203,
    longitude: 100.163,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "ท้องลำเจียก",
    zipcode: 80190,
    latitude: 8.147,
    longitude: 100.117,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "เสือหึง",
    zipcode: 80190,
    latitude: 8.189,
    longitude: 100.242,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "การะเกด",
    zipcode: 80190,
    latitude: 8.073,
    longitude: 100.154,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "เขาพระบาท",
    zipcode: 80190,
    latitude: 8.084,
    longitude: 100.191,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เชียรใหญ่",
    subdistrict: "แม่เจ้าอยู่หัว",
    zipcode: 80190,
    latitude: 8.075,
    longitude: 100.094,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "ชะอวด",
    zipcode: 80180,
    latitude: 7.996,
    longitude: 100.02,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "ท่าเสม็ด",
    zipcode: 80180,
    latitude: 7.949,
    longitude: 100.017,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "ท่าประจะ",
    zipcode: 80180,
    latitude: 7.973,
    longitude: 99.96,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "เคร็ง",
    zipcode: 80180,
    latitude: 7.952,
    longitude: 100.103,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "วังอ่าง",
    zipcode: 80180,
    latitude: 7.927,
    longitude: 99.886,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "บ้านตูล",
    zipcode: 80180,
    latitude: 8.07,
    longitude: 100,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "ขอนหาด",
    zipcode: 80180,
    latitude: 7.872,
    longitude: 100.059,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "เกาะขันธ์",
    zipcode: 80180,
    latitude: 7.91,
    longitude: 99.935,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "ควนหนองหงษ์",
    zipcode: 80180,
    latitude: 8.009,
    longitude: 99.885,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "เขาพระทอง",
    zipcode: 80180,
    latitude: 7.974,
    longitude: 99.905,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ชะอวด",
    subdistrict: "นางหลง",
    zipcode: 80180,
    latitude: 7.901,
    longitude: 99.988,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "ท่าศาลา",
    zipcode: 80160,
    latitude: 8.669,
    longitude: 99.926,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "กลาย",
    zipcode: 80160,
    latitude: 8.812,
    longitude: 99.902,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "ท่าขึ้น",
    zipcode: 80160,
    latitude: 8.714,
    longitude: 99.887,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "หัวตะพาน",
    zipcode: 80160,
    latitude: 8.628,
    longitude: 99.889,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "สระแก้ว",
    zipcode: 80160,
    latitude: 8.752,
    longitude: 99.867,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "โมคลาน",
    zipcode: 80160,
    latitude: 8.589,
    longitude: 99.885,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "ไทยบุรี",
    zipcode: 80160,
    latitude: 8.665,
    longitude: 99.879,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "ดอนตะโก",
    zipcode: 80160,
    latitude: 8.56,
    longitude: 99.903,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "ตลิ่งชัน",
    zipcode: 80160,
    latitude: 8.783,
    longitude: 99.821,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ท่าศาลา",
    subdistrict: "โพธิ์ทอง",
    zipcode: 80160,
    latitude: 8.613,
    longitude: 99.923,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "ปากแพรก",
    zipcode: 80110,
    latitude: 8.1669543,
    longitude: 99.669869,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "ชะมาย",
    zipcode: 80110,
    latitude: 8.148,
    longitude: 99.673,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "หนองหงส์",
    zipcode: 80110,
    latitude: 8.195,
    longitude: 99.614,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "ควนกรด",
    zipcode: 80110,
    latitude: 8.149,
    longitude: 99.632,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "นาไม้ไผ่",
    zipcode: 80110,
    latitude: 8.119,
    longitude: 99.59,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "นาหลวงเสน",
    zipcode: 80110,
    latitude: 8.24,
    longitude: 99.705,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "เขาโร",
    zipcode: 80110,
    latitude: 8.047,
    longitude: 99.594,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "กะปาง",
    zipcode: 80310,
    latitude: 8.029,
    longitude: 99.683,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "ที่วัง",
    zipcode: 80110,
    latitude: 8.099,
    longitude: 99.67,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "น้ำตก",
    zipcode: 80110,
    latitude: 7.971,
    longitude: 99.78,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "ถ้ำใหญ่",
    zipcode: 80110,
    latitude: 8.164,
    longitude: 99.742,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "นาโพธิ์",
    zipcode: 80110,
    latitude: 8.209,
    longitude: 99.574,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งสง",
    subdistrict: "เขาขาว",
    zipcode: 80110,
    latitude: 8.173,
    longitude: 99.5,
  },
  {
    province: "นครศรีธรรมราช",
    district: "นาบอน",
    subdistrict: "นาบอน",
    zipcode: 80220,
    latitude: 8.243,
    longitude: 99.6,
  },
  {
    province: "นครศรีธรรมราช",
    district: "นาบอน",
    subdistrict: "ทุ่งสง",
    zipcode: 80220,
    latitude: 8.255,
    longitude: 99.487,
  },
  {
    province: "นครศรีธรรมราช",
    district: "นาบอน",
    subdistrict: "แก้วแสน",
    zipcode: 80220,
    latitude: 8.282,
    longitude: 99.528,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งใหญ่",
    subdistrict: "ท่ายาง",
    zipcode: 80240,
    latitude: 8.305,
    longitude: 99.346,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งใหญ่",
    subdistrict: "ทุ่งสัง",
    zipcode: 80240,
    latitude: 8.372,
    longitude: 99.342,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งใหญ่",
    subdistrict: "ทุ่งใหญ่",
    zipcode: 80240,
    latitude: 8.366,
    longitude: 99.429,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งใหญ่",
    subdistrict: "กุแหระ",
    zipcode: 80240,
    latitude: 8.194,
    longitude: 99.337,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งใหญ่",
    subdistrict: "ปริก",
    zipcode: 80240,
    latitude: 8.28,
    longitude: 99.432,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งใหญ่",
    subdistrict: "บางรูป",
    zipcode: 80240,
    latitude: 8.4,
    longitude: 99.279,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ทุ่งใหญ่",
    subdistrict: "กรุงหยัน",
    zipcode: 80240,
    latitude: 8.187,
    longitude: 99.403,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "ปากพนัง",
    zipcode: 80140,
    latitude: 8.3464061,
    longitude: 99.9922644,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "คลองน้อย",
    zipcode: 80330,
    latitude: 8.358,
    longitude: 100.108,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "ป่าระกำ",
    zipcode: 80140,
    latitude: 8.244,
    longitude: 100.12,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "ชะเมา",
    zipcode: 80330,
    latitude: 8.275,
    longitude: 100.054,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "คลองกระบือ",
    zipcode: 80140,
    latitude: 8.317,
    longitude: 100.143,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "เกาะทวด",
    zipcode: 80330,
    latitude: 8.283,
    longitude: 100.092,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "บ้านใหม่",
    zipcode: 80140,
    latitude: 8.277,
    longitude: 100.154,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "หูล่อง",
    zipcode: 80140,
    latitude: 8.314,
    longitude: 100.184,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "แหลมตะลุมพุก",
    zipcode: 80140,
    latitude: 8.458,
    longitude: 100.196,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "ปากพนังฝั่งตะวันตก",
    zipcode: 80140,
    latitude: 8.36,
    longitude: 100.165,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "บางศาลา",
    zipcode: 80140,
    latitude: 8.246,
    longitude: 100.185,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "บางพระ",
    zipcode: 80140,
    latitude: 8.326,
    longitude: 100.23,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "บางตะพง",
    zipcode: 80140,
    latitude: 8.232,
    longitude: 100.153,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "ปากพนังฝั่งตะวันออก",
    zipcode: 80140,
    latitude: 8.397,
    longitude: 100.2,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "บ้านเพิง",
    zipcode: 80140,
    latitude: 8.294,
    longitude: 100.227,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "ท่าพยา",
    zipcode: 80140,
    latitude: 8.3464061,
    longitude: 99.9911811,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "ปากแพรก",
    zipcode: 80140,
    latitude: 8.244,
    longitude: 100.212,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ปากพนัง",
    subdistrict: "ขนาบนาก",
    zipcode: 80140,
    latitude: 8.22,
    longitude: 100.247,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ร่อนพิบูลย์",
    subdistrict: "ร่อนพิบูลย์",
    zipcode: 80130,
    latitude: 8.189,
    longitude: 99.832,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ร่อนพิบูลย์",
    subdistrict: "หินตก",
    zipcode: 80350,
    latitude: 8.237,
    longitude: 99.88,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ร่อนพิบูลย์",
    subdistrict: "เสาธง",
    zipcode: 80350,
    latitude: 8.252,
    longitude: 99.924,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ร่อนพิบูลย์",
    subdistrict: "ควนเกย",
    zipcode: 80130,
    latitude: 8.14,
    longitude: 99.9,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ร่อนพิบูลย์",
    subdistrict: "ควนพัง",
    zipcode: 80130,
    latitude: 8.159,
    longitude: 99.977,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ร่อนพิบูลย์",
    subdistrict: "ควนชุม",
    zipcode: 80130,
    latitude: 8.183,
    longitude: 99.919,
  },
  {
    province: "นครศรีธรรมราช",
    district: "สิชล",
    subdistrict: "สิชล",
    zipcode: 80120,
    latitude: 9.008,
    longitude: 99.902,
  },
  {
    province: "นครศรีธรรมราช",
    district: "สิชล",
    subdistrict: "ทุ่งปรัง",
    zipcode: 80120,
    latitude: 8.958,
    longitude: 99.876,
  },
  {
    province: "นครศรีธรรมราช",
    district: "สิชล",
    subdistrict: "ฉลอง",
    zipcode: 80120,
    latitude: 8.89,
    longitude: 99.773,
  },
  {
    province: "นครศรีธรรมราช",
    district: "สิชล",
    subdistrict: "เสาเภา",
    zipcode: 80340,
    latitude: 8.89,
    longitude: 99.904,
  },
  {
    province: "นครศรีธรรมราช",
    district: "สิชล",
    subdistrict: "เปลี่ยน",
    zipcode: 80120,
    latitude: 8.833,
    longitude: 99.87,
  },
  {
    province: "นครศรีธรรมราช",
    district: "สิชล",
    subdistrict: "สี่ขีด",
    zipcode: 80120,
    latitude: 9.023,
    longitude: 99.786,
  },
  {
    province: "นครศรีธรรมราช",
    district: "สิชล",
    subdistrict: "เทพราช",
    zipcode: 80340,
    latitude: 8.841,
    longitude: 99.735,
  },
  {
    province: "นครศรีธรรมราช",
    district: "สิชล",
    subdistrict: "เขาน้อย",
    zipcode: 80120,
    latitude: 8.945,
    longitude: 99.787,
  },
  {
    province: "นครศรีธรรมราช",
    district: "สิชล",
    subdistrict: "ทุ่งใส",
    zipcode: 80120,
    latitude: 9.06,
    longitude: 99.89,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ขนอม",
    subdistrict: "ขนอม",
    zipcode: 80210,
    latitude: 9.166,
    longitude: 99.838,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ขนอม",
    subdistrict: "ควนทอง",
    zipcode: 80210,
    latitude: 9.181,
    longitude: 99.782,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ขนอม",
    subdistrict: "ท้องเนียน",
    zipcode: 80210,
    latitude: 9.324,
    longitude: 99.775,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "หัวไทร",
    zipcode: 80170,
    latitude: 8.037,
    longitude: 100.286,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "หน้าสตน",
    zipcode: 80170,
    latitude: 7.969,
    longitude: 100.322,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "ทรายขาว",
    zipcode: 80170,
    latitude: 8.04,
    longitude: 100.226,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "แหลม",
    zipcode: 80170,
    latitude: 7.97,
    longitude: 100.192,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "เขาพังไกร",
    zipcode: 80170,
    latitude: 7.976,
    longitude: 100.272,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "บ้านราม",
    zipcode: 80170,
    latitude: 8.081,
    longitude: 100.257,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "บางนบ",
    zipcode: 80170,
    latitude: 8.104,
    longitude: 100.261,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "ท่าซอม",
    zipcode: 80170,
    latitude: 8.146,
    longitude: 100.246,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "ควนชะลิก",
    zipcode: 80170,
    latitude: 7.907,
    longitude: 100.206,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "รามแก้ว",
    zipcode: 80170,
    latitude: 7.932,
    longitude: 100.276,
  },
  {
    province: "นครศรีธรรมราช",
    district: "หัวไทร",
    subdistrict: "เกาะเพชร",
    zipcode: 80170,
    latitude: 8.112,
    longitude: 100.291,
  },
  {
    province: "นครศรีธรรมราช",
    district: "บางขัน",
    subdistrict: "บางขัน",
    zipcode: 80360,
    latitude: 8.069,
    longitude: 99.422,
  },
  {
    province: "นครศรีธรรมราช",
    district: "บางขัน",
    subdistrict: "บ้านลำนาว",
    zipcode: 80360,
    latitude: 7.968,
    longitude: 99.417,
  },
  {
    province: "นครศรีธรรมราช",
    district: "บางขัน",
    subdistrict: "วังหิน",
    zipcode: 80360,
    latitude: 7.984,
    longitude: 99.554,
  },
  {
    province: "นครศรีธรรมราช",
    district: "บางขัน",
    subdistrict: "บ้านนิคม",
    zipcode: 80360,
    latitude: 7.933,
    longitude: 99.507,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ถ้ำพรรณรา",
    subdistrict: "ถ้ำพรรณรา",
    zipcode: 80260,
    latitude: 8.414,
    longitude: 99.373,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ถ้ำพรรณรา",
    subdistrict: "คลองเส",
    zipcode: 80260,
    latitude: 8.491,
    longitude: 99.419,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ถ้ำพรรณรา",
    subdistrict: "ดุสิต",
    zipcode: 80260,
    latitude: 8.466,
    longitude: 99.347,
  },
  {
    province: "นครศรีธรรมราช",
    district: "จุฬาภรณ์",
    subdistrict: "บ้านควนมุด",
    zipcode: 80180,
    latitude: 8.028,
    longitude: 99.924,
  },
  {
    province: "นครศรีธรรมราช",
    district: "จุฬาภรณ์",
    subdistrict: "บ้านชะอวด",
    zipcode: 80180,
    latitude: 8.058,
    longitude: 99.933,
  },
  {
    province: "นครศรีธรรมราช",
    district: "จุฬาภรณ์",
    subdistrict: "ควนหนองคว้า",
    zipcode: 80130,
    latitude: 8.103,
    longitude: 99.935,
  },
  {
    province: "นครศรีธรรมราช",
    district: "จุฬาภรณ์",
    subdistrict: "ทุ่งโพธิ์",
    zipcode: 80130,
    latitude: 8.109,
    longitude: 99.829,
  },
  {
    province: "นครศรีธรรมราช",
    district: "จุฬาภรณ์",
    subdistrict: "นาหมอบุญ",
    zipcode: 80130,
    latitude: 8.03,
    longitude: 99.813,
  },
  {
    province: "นครศรีธรรมราช",
    district: "จุฬาภรณ์",
    subdistrict: "สามตำบล",
    zipcode: 80130,
    latitude: 8.0790445,
    longitude: 99.7838115,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พระพรหม",
    subdistrict: "นาพรุ",
    zipcode: 80000,
    latitude: 8.331,
    longitude: 99.907,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พระพรหม",
    subdistrict: "นาสาร",
    zipcode: 80000,
    latitude: 8.37,
    longitude: 99.92,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พระพรหม",
    subdistrict: "ท้ายสำเภา",
    zipcode: 80000,
    latitude: 8.302,
    longitude: 99.909,
  },
  {
    province: "นครศรีธรรมราช",
    district: "พระพรหม",
    subdistrict: "ช้างซ้าย",
    zipcode: 80000,
    latitude: 8.311,
    longitude: 99.966,
  },
  {
    province: "นครศรีธรรมราช",
    district: "นบพิตำ",
    subdistrict: "นบพิตำ",
    zipcode: 80160,
    latitude: 8.744,
    longitude: 99.772,
  },
  {
    province: "นครศรีธรรมราช",
    district: "นบพิตำ",
    subdistrict: "กรุงชิง",
    zipcode: 80160,
    latitude: 8.804,
    longitude: 99.635,
  },
  {
    province: "นครศรีธรรมราช",
    district: "นบพิตำ",
    subdistrict: "กะหรอ",
    zipcode: 80160,
    latitude: 8.666,
    longitude: 99.827,
  },
  {
    province: "นครศรีธรรมราช",
    district: "นบพิตำ",
    subdistrict: "นาเหรง",
    zipcode: 80160,
    latitude: 8.66,
    longitude: 99.745,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ช้างกลาง",
    subdistrict: "ช้างกลาง",
    zipcode: 80250,
    latitude: 8.336,
    longitude: 99.638,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ช้างกลาง",
    subdistrict: "หลักช้าง",
    zipcode: 80250,
    latitude: 8.354,
    longitude: 99.535,
  },
  {
    province: "นครศรีธรรมราช",
    district: "ช้างกลาง",
    subdistrict: "สวนขัน",
    zipcode: 80250,
    latitude: 8.416,
    longitude: 99.643,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "เชียรเขา",
    zipcode: 80190,
    latitude: 8.184,
    longitude: 100.078,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ดอนตรอ",
    zipcode: 80290,
    latitude: 8.213,
    longitude: 100.039,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "สวนหลวง",
    zipcode: 80190,
    latitude: 8.139,
    longitude: 100.037,
  },
  {
    province: "นครศรีธรรมราช",
    district: "เฉลิมพระเกียรติ",
    subdistrict: "ทางพูน",
    zipcode: 80290,
    latitude: 8.239,
    longitude: 99.981,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "ปากน้ำ",
    zipcode: 81000,
    latitude: 8.09,
    longitude: 98.905,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "กระบี่ใหญ่",
    zipcode: 81000,
    latitude: 7.9741656,
    longitude: 98.5093961,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "กระบี่น้อย",
    zipcode: 81000,
    latitude: 8.176,
    longitude: 98.959,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "เขาคราม",
    zipcode: 81000,
    latitude: 8.21,
    longitude: 98.729,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "เขาทอง",
    zipcode: 81000,
    latitude: 8.049,
    longitude: 98.676,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "ทับปริก",
    zipcode: 81000,
    latitude: 8.203,
    longitude: 98.886,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "ไสไทย",
    zipcode: 81000,
    latitude: 8.064,
    longitude: 98.872,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "อ่าวนาง",
    zipcode: 81000,
    latitude: 7.616,
    longitude: 98.863,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "หนองทะเล",
    zipcode: 81000,
    latitude: 8.088,
    longitude: 98.785,
  },
  {
    province: "กระบี่",
    district: "เมืองกระบี่",
    subdistrict: "คลองประสงค์",
    zipcode: 81000,
    latitude: 7.972,
    longitude: 98.809,
  },
  {
    province: "กระบี่",
    district: "เขาพนม",
    subdistrict: "เขาพนม",
    zipcode: 81140,
    latitude: 8.227,
    longitude: 99.045,
  },
  {
    province: "กระบี่",
    district: "เขาพนม",
    subdistrict: "เขาดิน",
    zipcode: 81140,
    latitude: 8.342,
    longitude: 99.167,
  },
  {
    province: "กระบี่",
    district: "เขาพนม",
    subdistrict: "สินปุน",
    zipcode: 80240,
    latitude: 8.277,
    longitude: 99.295,
  },
  {
    province: "กระบี่",
    district: "เขาพนม",
    subdistrict: "พรุเตียว",
    zipcode: 81140,
    latitude: 8.258,
    longitude: 99.128,
  },
  {
    province: "กระบี่",
    district: "เขาพนม",
    subdistrict: "หน้าเขา",
    zipcode: 81140,
    latitude: 8.343,
    longitude: 98.966,
  },
  {
    province: "กระบี่",
    district: "เขาพนม",
    subdistrict: "โคกหาร",
    zipcode: 80240,
    latitude: 8.188,
    longitude: 99.252,
  },
  {
    province: "กระบี่",
    district: "เกาะลันตา",
    subdistrict: "เกาะลันตาใหญ่",
    zipcode: 81150,
    latitude: 7.496,
    longitude: 99.12,
  },
  {
    province: "กระบี่",
    district: "เกาะลันตา",
    subdistrict: "เกาะลันตาน้อย",
    zipcode: 81150,
    latitude: 7.576,
    longitude: 99.142,
  },
  {
    province: "กระบี่",
    district: "เกาะลันตา",
    subdistrict: "เกาะกลาง",
    zipcode: 81120,
    latitude: 7.746,
    longitude: 99.126,
  },
  {
    province: "กระบี่",
    district: "เกาะลันตา",
    subdistrict: "คลองยาง",
    zipcode: 81120,
    latitude: 7.855,
    longitude: 99.053,
  },
  {
    province: "กระบี่",
    district: "เกาะลันตา",
    subdistrict: "ศาลาด่าน",
    zipcode: 81150,
    latitude: 7.603,
    longitude: 99.05,
  },
  {
    province: "กระบี่",
    district: "คลองท่อม",
    subdistrict: "คลองท่อมใต้",
    zipcode: 81120,
    latitude: 7.933,
    longitude: 99.172,
  },
  {
    province: "กระบี่",
    district: "คลองท่อม",
    subdistrict: "คลองท่อมเหนือ",
    zipcode: 81120,
    latitude: 7.93,
    longitude: 99.263,
  },
  {
    province: "กระบี่",
    district: "คลองท่อม",
    subdistrict: "คลองพน",
    zipcode: 81170,
    latitude: 7.758,
    longitude: 99.172,
  },
  {
    province: "กระบี่",
    district: "คลองท่อม",
    subdistrict: "ทรายขาว",
    zipcode: 81170,
    latitude: 7.711,
    longitude: 99.241,
  },
  {
    province: "กระบี่",
    district: "คลองท่อม",
    subdistrict: "ห้วยน้ำขาว",
    zipcode: 81120,
    latitude: 7.866,
    longitude: 99.126,
  },
  {
    province: "กระบี่",
    district: "คลองท่อม",
    subdistrict: "พรุดินนา",
    zipcode: 81120,
    latitude: 8.06,
    longitude: 99.219,
  },
  {
    province: "กระบี่",
    district: "คลองท่อม",
    subdistrict: "เพหลา",
    zipcode: 81120,
    latitude: 8.075,
    longitude: 99.139,
  },
  {
    province: "กระบี่",
    district: "อ่าวลึก",
    subdistrict: "อ่าวลึกใต้",
    zipcode: 81110,
    latitude: 8.386,
    longitude: 98.707,
  },
  {
    province: "กระบี่",
    district: "อ่าวลึก",
    subdistrict: "แหลมสัก",
    zipcode: 81110,
    latitude: 8.292,
    longitude: 98.616,
  },
  {
    province: "กระบี่",
    district: "อ่าวลึก",
    subdistrict: "นาเหนือ",
    zipcode: 81110,
    latitude: 8.529,
    longitude: 98.736,
  },
  {
    province: "กระบี่",
    district: "อ่าวลึก",
    subdistrict: "คลองหิน",
    zipcode: 81110,
    latitude: 8.324,
    longitude: 98.837,
  },
  {
    province: "กระบี่",
    district: "อ่าวลึก",
    subdistrict: "อ่าวลึกน้อย",
    zipcode: 81110,
    latitude: 8.211,
    longitude: 98.687,
  },
  {
    province: "กระบี่",
    district: "อ่าวลึก",
    subdistrict: "อ่าวลึกเหนือ",
    zipcode: 81110,
    latitude: 8.399,
    longitude: 98.825,
  },
  {
    province: "กระบี่",
    district: "อ่าวลึก",
    subdistrict: "เขาใหญ่",
    zipcode: 81110,
    latitude: 8.465,
    longitude: 98.719,
  },
  {
    province: "กระบี่",
    district: "อ่าวลึก",
    subdistrict: "คลองยา",
    zipcode: 81110,
    latitude: 8.379,
    longitude: 98.883,
  },
  {
    province: "กระบี่",
    district: "อ่าวลึก",
    subdistrict: "บ้านกลาง",
    zipcode: 81110,
    latitude: 8.352,
    longitude: 98.792,
  },
  {
    province: "กระบี่",
    district: "ปลายพระยา",
    subdistrict: "ปลายพระยา",
    zipcode: 81160,
    latitude: 8.564,
    longitude: 98.879,
  },
  {
    province: "กระบี่",
    district: "ปลายพระยา",
    subdistrict: "เขาเขน",
    zipcode: 81160,
    latitude: 8.56,
    longitude: 98.798,
  },
  {
    province: "กระบี่",
    district: "ปลายพระยา",
    subdistrict: "เขาต่อ",
    zipcode: 81160,
    latitude: 8.612,
    longitude: 98.739,
  },
  {
    province: "กระบี่",
    district: "ปลายพระยา",
    subdistrict: "คีรีวง",
    zipcode: 81160,
    latitude: 8.445,
    longitude: 98.841,
  },
  {
    province: "กระบี่",
    district: "ลำทับ",
    subdistrict: "ลำทับ",
    zipcode: 81120,
    latitude: 7.96,
    longitude: 99.348,
  },
  {
    province: "กระบี่",
    district: "ลำทับ",
    subdistrict: "ดินอุดม",
    zipcode: 81120,
    latitude: 8.1,
    longitude: 99.358,
  },
  {
    province: "กระบี่",
    district: "ลำทับ",
    subdistrict: "ทุ่งไทรทอง",
    zipcode: 81120,
    latitude: 8.004,
    longitude: 99.311,
  },
  {
    province: "กระบี่",
    district: "ลำทับ",
    subdistrict: "ดินแดง",
    zipcode: 81120,
    latitude: 8.011,
    longitude: 99.373,
  },
  {
    province: "กระบี่",
    district: "เหนือคลอง",
    subdistrict: "เหนือคลอง",
    zipcode: 81130,
    latitude: 8.086,
    longitude: 98.99,
  },
  {
    province: "กระบี่",
    district: "เหนือคลอง",
    subdistrict: "เกาะศรีบอยา",
    zipcode: 81130,
    latitude: 7.801,
    longitude: 98.995,
  },
  {
    province: "กระบี่",
    district: "เหนือคลอง",
    subdistrict: "คลองขนาน",
    zipcode: 81130,
    latitude: 7.941,
    longitude: 99.072,
  },
  {
    province: "กระบี่",
    district: "เหนือคลอง",
    subdistrict: "คลองเขม้า",
    zipcode: 81130,
    latitude: 8.036,
    longitude: 98.976,
  },
  {
    province: "กระบี่",
    district: "เหนือคลอง",
    subdistrict: "โคกยาง",
    zipcode: 81130,
    latitude: 8.09,
    longitude: 99.087,
  },
  {
    province: "กระบี่",
    district: "เหนือคลอง",
    subdistrict: "ตลิ่งชัน",
    zipcode: 81130,
    latitude: 7.963,
    longitude: 98.969,
  },
  {
    province: "กระบี่",
    district: "เหนือคลอง",
    subdistrict: "ปกาสัย",
    zipcode: 81130,
    latitude: 8.015,
    longitude: 99.062,
  },
  {
    province: "กระบี่",
    district: "เหนือคลอง",
    subdistrict: "ห้วยยูง",
    zipcode: 81130,
    latitude: 8.12,
    longitude: 99.026,
  },
  {
    province: "พังงา",
    district: "เมืองพังงา",
    subdistrict: "ท้ายช้าง",
    zipcode: 82000,
    latitude: 8.446,
    longitude: 98.524,
  },
  {
    province: "พังงา",
    district: "เมืองพังงา",
    subdistrict: "นบปริง",
    zipcode: 82000,
    latitude: 8.536,
    longitude: 98.514,
  },
  {
    province: "พังงา",
    district: "เมืองพังงา",
    subdistrict: "ถ้ำน้ำผุด",
    zipcode: 82000,
    latitude: 8.427,
    longitude: 98.527,
  },
  {
    province: "พังงา",
    district: "เมืองพังงา",
    subdistrict: "บางเตย",
    zipcode: 82000,
    latitude: 8.407,
    longitude: 98.575,
  },
  {
    province: "พังงา",
    district: "เมืองพังงา",
    subdistrict: "ตากแดด",
    zipcode: 82000,
    latitude: 8.433,
    longitude: 98.489,
  },
  {
    province: "พังงา",
    district: "เมืองพังงา",
    subdistrict: "สองแพรก",
    zipcode: 82000,
    latitude: 8.603,
    longitude: 98.554,
  },
  {
    province: "พังงา",
    district: "เมืองพังงา",
    subdistrict: "ทุ่งคาโงก",
    zipcode: 82000,
    latitude: 8.554,
    longitude: 98.438,
  },
  {
    province: "พังงา",
    district: "เมืองพังงา",
    subdistrict: "เกาะปันหยี",
    zipcode: 82000,
    latitude: 8.317,
    longitude: 98.539,
  },
  {
    province: "พังงา",
    district: "เมืองพังงา",
    subdistrict: "ป่ากอ",
    zipcode: 82000,
    latitude: 8.5,
    longitude: 98.466,
  },
  {
    province: "พังงา",
    district: "เกาะยาว",
    subdistrict: "เกาะยาวน้อย",
    zipcode: 82160,
    latitude: 8.131,
    longitude: 98.61,
  },
  {
    province: "พังงา",
    district: "เกาะยาว",
    subdistrict: "เกาะยาวใหญ่",
    zipcode: 82160,
    latitude: 8.061,
    longitude: 98.567,
  },
  {
    province: "พังงา",
    district: "เกาะยาว",
    subdistrict: "พรุใน",
    zipcode: 83000,
    latitude: 7.892,
    longitude: 98.581,
  },
  {
    province: "พังงา",
    district: "กะปง",
    subdistrict: "กะปง",
    zipcode: 82170,
    latitude: 8.648,
    longitude: 98.468,
  },
  {
    province: "พังงา",
    district: "กะปง",
    subdistrict: "ท่านา",
    zipcode: 82170,
    latitude: 8.713,
    longitude: 98.419,
  },
  {
    province: "พังงา",
    district: "กะปง",
    subdistrict: "เหมาะ",
    zipcode: 82170,
    latitude: 8.695,
    longitude: 98.362,
  },
  {
    province: "พังงา",
    district: "กะปง",
    subdistrict: "เหล",
    zipcode: 82170,
    latitude: 8.734,
    longitude: 98.507,
  },
  {
    province: "พังงา",
    district: "กะปง",
    subdistrict: "รมณีย์",
    zipcode: 82170,
    latitude: 8.829,
    longitude: 98.501,
  },
  {
    province: "พังงา",
    district: "ตะกั่วทุ่ง",
    subdistrict: "ถ้ำ",
    zipcode: 82130,
    latitude: 8.409,
    longitude: 98.412,
  },
  {
    province: "พังงา",
    district: "ตะกั่วทุ่ง",
    subdistrict: "กระโสม",
    zipcode: 82130,
    latitude: 8.331,
    longitude: 98.483,
  },
  {
    province: "พังงา",
    district: "ตะกั่วทุ่ง",
    subdistrict: "กะไหล",
    zipcode: 82130,
    latitude: 8.285,
    longitude: 98.477,
  },
  {
    province: "พังงา",
    district: "ตะกั่วทุ่ง",
    subdistrict: "ท่าอยู่",
    zipcode: 82130,
    latitude: 8.261,
    longitude: 98.398,
  },
  {
    province: "พังงา",
    district: "ตะกั่วทุ่ง",
    subdistrict: "หล่อยูง",
    zipcode: 82140,
    latitude: 8.198,
    longitude: 98.358,
  },
  {
    province: "พังงา",
    district: "ตะกั่วทุ่ง",
    subdistrict: "โคกกลอย",
    zipcode: 82140,
    latitude: 8.252,
    longitude: 98.306,
  },
  {
    province: "พังงา",
    district: "ตะกั่วทุ่ง",
    subdistrict: "คลองเคียน",
    zipcode: 82130,
    latitude: 8.179,
    longitude: 98.455,
  },
  {
    province: "พังงา",
    district: "ตะกั่วป่า",
    subdistrict: "ตะกั่วป่า",
    zipcode: 82110,
    latitude: 8.829,
    longitude: 98.362,
  },
  {
    province: "พังงา",
    district: "ตะกั่วป่า",
    subdistrict: "บางนายสี",
    zipcode: 82110,
    latitude: 8.914,
    longitude: 98.324,
  },
  {
    province: "พังงา",
    district: "ตะกั่วป่า",
    subdistrict: "บางไทร",
    zipcode: 82110,
    latitude: 8.773,
    longitude: 98.312,
  },
  {
    province: "พังงา",
    district: "ตะกั่วป่า",
    subdistrict: "บางม่วง",
    zipcode: 82190,
    latitude: 8.819,
    longitude: 98.289,
  },
  {
    province: "พังงา",
    district: "ตะกั่วป่า",
    subdistrict: "ตำตัว",
    zipcode: 82110,
    latitude: 8.784,
    longitude: 98.377,
  },
  {
    province: "พังงา",
    district: "ตะกั่วป่า",
    subdistrict: "โคกเคียน",
    zipcode: 82110,
    latitude: 8.851,
    longitude: 98.388,
  },
  {
    province: "พังงา",
    district: "ตะกั่วป่า",
    subdistrict: "คึกคัก",
    zipcode: 82190,
    latitude: 8.698,
    longitude: 98.28,
  },
  {
    province: "พังงา",
    district: "ตะกั่วป่า",
    subdistrict: "เกาะคอเขา",
    zipcode: 82190,
    latitude: 8.962,
    longitude: 98.284,
  },
  {
    province: "พังงา",
    district: "คุระบุรี",
    subdistrict: "คุระ",
    zipcode: 82150,
    latitude: 9.262,
    longitude: 98.414,
  },
  {
    province: "พังงา",
    district: "คุระบุรี",
    subdistrict: "บางวัน",
    zipcode: 82150,
    latitude: 9.046,
    longitude: 98.405,
  },
  {
    province: "พังงา",
    district: "คุระบุรี",
    subdistrict: "เกาะพระทอง",
    zipcode: 82150,
    latitude: 9.406,
    longitude: 97.901,
  },
  {
    province: "พังงา",
    district: "คุระบุรี",
    subdistrict: "แม่นางขาว",
    zipcode: 82150,
    latitude: 9.154,
    longitude: 98.404,
  },
  {
    province: "พังงา",
    district: "ทับปุด",
    subdistrict: "ทับปุด",
    zipcode: 82180,
    latitude: 8.517,
    longitude: 98.632,
  },
  {
    province: "พังงา",
    district: "ทับปุด",
    subdistrict: "มะรุ่ย",
    zipcode: 82180,
    latitude: 8.431,
    longitude: 98.639,
  },
  {
    province: "พังงา",
    district: "ทับปุด",
    subdistrict: "บ่อแสน",
    zipcode: 82180,
    latitude: 8.472,
    longitude: 98.605,
  },
  {
    province: "พังงา",
    district: "ทับปุด",
    subdistrict: "ถ้ำทองหลาง",
    zipcode: 82180,
    latitude: 8.556,
    longitude: 98.598,
  },
  {
    province: "พังงา",
    district: "ทับปุด",
    subdistrict: "โคกเจริญ",
    zipcode: 82180,
    latitude: 8.543,
    longitude: 98.664,
  },
  {
    province: "พังงา",
    district: "ทับปุด",
    subdistrict: "บางเหรียง",
    zipcode: 82180,
    latitude: 8.61,
    longitude: 98.654,
  },
  {
    province: "พังงา",
    district: "ท้ายเหมือง",
    subdistrict: "ท้ายเหมือง",
    zipcode: 82120,
    latitude: 8.442,
    longitude: 98.275,
  },
  {
    province: "พังงา",
    district: "ท้ายเหมือง",
    subdistrict: "นาเตย",
    zipcode: 82120,
    latitude: 8.344,
    longitude: 98.303,
  },
  {
    province: "พังงา",
    district: "ท้ายเหมือง",
    subdistrict: "บางทอง",
    zipcode: 82120,
    latitude: 8.418,
    longitude: 98.354,
  },
  {
    province: "พังงา",
    district: "ท้ายเหมือง",
    subdistrict: "ทุ่งมะพร้าว",
    zipcode: 82120,
    latitude: 8.536,
    longitude: 98.277,
  },
  {
    province: "พังงา",
    district: "ท้ายเหมือง",
    subdistrict: "ลำภี",
    zipcode: 82120,
    latitude: 8.59,
    longitude: 98.36,
  },
  {
    province: "พังงา",
    district: "ท้ายเหมือง",
    subdistrict: "ลำแก่น",
    zipcode: 82120,
    latitude: 8.578,
    longitude: 98.258,
  },
  {
    province: "ภูเก็ต",
    district: "เมืองภูเก็ต",
    subdistrict: "ตลาดใหญ่",
    zipcode: 83000,
    latitude: 7.888,
    longitude: 98.394,
  },
  {
    province: "ภูเก็ต",
    district: "เมืองภูเก็ต",
    subdistrict: "ตลาดเหนือ",
    zipcode: 83000,
    latitude: 7.879,
    longitude: 98.381,
  },
  {
    province: "ภูเก็ต",
    district: "เมืองภูเก็ต",
    subdistrict: "เกาะแก้ว",
    zipcode: 83000,
    latitude: 7.924,
    longitude: 98.455,
  },
  {
    province: "ภูเก็ต",
    district: "เมืองภูเก็ต",
    subdistrict: "รัษฎา",
    zipcode: 83000,
    latitude: 7.834,
    longitude: 98.423,
  },
  {
    province: "ภูเก็ต",
    district: "เมืองภูเก็ต",
    subdistrict: "วิชิต",
    zipcode: 83000,
    latitude: 7.8269017,
    longitude: 98.3250307,
  },
  {
    province: "ภูเก็ต",
    district: "เมืองภูเก็ต",
    subdistrict: "ฉลอง",
    zipcode: 83130,
    latitude: 7.843,
    longitude: 98.341,
  },
  {
    province: "ภูเก็ต",
    district: "เมืองภูเก็ต",
    subdistrict: "ราไวย์",
    zipcode: 83130,
    latitude: 7.767,
    longitude: 98.3,
  },
  {
    province: "ภูเก็ต",
    district: "เมืองภูเก็ต",
    subdistrict: "กะรน",
    zipcode: 83100,
    latitude: 7.821,
    longitude: 98.285,
  },
  {
    province: "ภูเก็ต",
    district: "กะทู้",
    subdistrict: "กะทู้",
    zipcode: 83120,
    latitude: 7.914,
    longitude: 98.338,
  },
  {
    province: "ภูเก็ต",
    district: "กะทู้",
    subdistrict: "ป่าตอง",
    zipcode: 83150,
    latitude: 7.904,
    longitude: 98.308,
  },
  {
    province: "ภูเก็ต",
    district: "กะทู้",
    subdistrict: "กมลา",
    zipcode: 83150,
    latitude: 7.945,
    longitude: 98.289,
  },
  {
    province: "ภูเก็ต",
    district: "ถลาง",
    subdistrict: "เทพกระษัตรี",
    zipcode: 83110,
    latitude: 8.116,
    longitude: 98.425,
  },
  {
    province: "ภูเก็ต",
    district: "ถลาง",
    subdistrict: "ศรีสุนทร",
    zipcode: 83110,
    latitude: 7.984,
    longitude: 98.346,
  },
  {
    province: "ภูเก็ต",
    district: "ถลาง",
    subdistrict: "เชิงทะเล",
    zipcode: 83110,
    latitude: 7.996,
    longitude: 98.309,
  },
  {
    province: "ภูเก็ต",
    district: "ถลาง",
    subdistrict: "ป่าคลอก",
    zipcode: 83110,
    latitude: 8.028,
    longitude: 98.461,
  },
  {
    province: "ภูเก็ต",
    district: "ถลาง",
    subdistrict: "ไม้ขาว",
    zipcode: 83110,
    latitude: 8.147,
    longitude: 98.318,
  },
  {
    province: "ภูเก็ต",
    district: "ถลาง",
    subdistrict: "สาคู",
    zipcode: 83110,
    latitude: 8.093,
    longitude: 98.315,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "ตลาด",
    zipcode: 84000,
    latitude: 9.137,
    longitude: 99.32,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "มะขามเตี้ย",
    zipcode: 84000,
    latitude: 9.112,
    longitude: 99.347,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "วัดประดู่",
    zipcode: 84000,
    latitude: 8.979,
    longitude: 99.328,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "ขุนทะเล",
    zipcode: 84100,
    latitude: 9.023,
    longitude: 99.353,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "บางใบไม้",
    zipcode: 84000,
    latitude: 9.152,
    longitude: 99.295,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "บางชนะ",
    zipcode: 84000,
    latitude: 9.155,
    longitude: 99.311,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "คลองน้อย",
    zipcode: 84000,
    latitude: 9.135,
    longitude: 99.251,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "บางไทร",
    zipcode: 84000,
    latitude: 9.188,
    longitude: 99.3,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "บางโพธิ์",
    zipcode: 84000,
    latitude: 9.185,
    longitude: 99.278,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "บางกุ้ง",
    zipcode: 84000,
    latitude: 9.159,
    longitude: 99.36,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เมืองสุราษฎร์ธานี",
    subdistrict: "คลองฉนาก",
    zipcode: 84000,
    latitude: 9.191,
    longitude: 99.351,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "ท่าทองใหม่",
    zipcode: 84290,
    latitude: 9.174,
    longitude: 99.396,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "ท่าทอง",
    zipcode: 84160,
    latitude: 9.255,
    longitude: 99.539,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "กะแดะ",
    zipcode: 84160,
    latitude: 9.181,
    longitude: 99.465,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "ทุ่งกง",
    zipcode: 84290,
    latitude: 9.122,
    longitude: 99.405,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "กรูด",
    zipcode: 84160,
    latitude: 9.103,
    longitude: 99.549,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "ช้างซ้าย",
    zipcode: 84160,
    latitude: 9.02,
    longitude: 99.478,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "พลายวาส",
    zipcode: 84160,
    latitude: 9.184,
    longitude: 99.518,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "ป่าร่อน",
    zipcode: 84160,
    latitude: 8.97,
    longitude: 99.521,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "ตะเคียนทอง",
    zipcode: 84160,
    latitude: 9.176,
    longitude: 99.43,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "ช้างขวา",
    zipcode: 84160,
    latitude: 9.103,
    longitude: 99.442,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "ท่าอุแท",
    zipcode: 84160,
    latitude: 9.113,
    longitude: 99.639,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "ทุ่งรัง",
    zipcode: 84290,
    latitude: 9.042,
    longitude: 99.403,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "กาญจนดิษฐ์",
    subdistrict: "คลองสระ",
    zipcode: 84160,
    latitude: 8.976,
    longitude: 99.626,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ดอนสัก",
    subdistrict: "ดอนสัก",
    zipcode: 84220,
    latitude: 9.31,
    longitude: 99.66,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ดอนสัก",
    subdistrict: "ชลคราม",
    zipcode: 84160,
    latitude: 9.248,
    longitude: 99.605,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ดอนสัก",
    subdistrict: "ไชยคราม",
    zipcode: 84220,
    latitude: 9.182,
    longitude: 99.653,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ดอนสัก",
    subdistrict: "ปากแพรก",
    zipcode: 84340,
    latitude: 9.134,
    longitude: 99.701,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะสมุย",
    subdistrict: "อ่างทอง",
    zipcode: 84140,
    latitude: 9.708,
    longitude: 99.675,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะสมุย",
    subdistrict: "ลิปะน้อย",
    zipcode: 84140,
    latitude: 9.502,
    longitude: 99.959,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะสมุย",
    subdistrict: "ตลิ่งงาม",
    zipcode: 84140,
    latitude: 9.31,
    longitude: 99.892,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะสมุย",
    subdistrict: "หน้าเมือง",
    zipcode: 84140,
    latitude: 9.461,
    longitude: 99.996,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะสมุย",
    subdistrict: "มะเร็ต",
    zipcode: 84310,
    latitude: 9.468,
    longitude: 100.032,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะสมุย",
    subdistrict: "บ่อผุด",
    zipcode: 84320,
    latitude: 9.539,
    longitude: 100.081,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะสมุย",
    subdistrict: "แม่น้ำ",
    zipcode: 84330,
    latitude: 9.563,
    longitude: 99.967,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะพะงัน",
    subdistrict: "เกาะพะงัน",
    zipcode: 84280,
    latitude: 9.802,
    longitude: 99.979,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะพะงัน",
    subdistrict: "บ้านใต้",
    zipcode: 84280,
    latitude: 9.723,
    longitude: 100.062,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เกาะพะงัน",
    subdistrict: "เกาะเต่า",
    zipcode: 84280,
    latitude: 9.9211649,
    longitude: 99.7272669,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ไชยา",
    subdistrict: "ตลาดไชยา",
    zipcode: 84110,
    latitude: 9.391,
    longitude: 99.198,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ไชยา",
    subdistrict: "พุมเรียง",
    zipcode: 84110,
    latitude: 9.395,
    longitude: 99.261,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ไชยา",
    subdistrict: "เลม็ด",
    zipcode: 84110,
    latitude: 9.365,
    longitude: 99.172,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ไชยา",
    subdistrict: "เวียง",
    zipcode: 84110,
    latitude: 9.388,
    longitude: 99.148,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ไชยา",
    subdistrict: "ทุ่ง",
    zipcode: 84110,
    latitude: 9.424,
    longitude: 99.215,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ไชยา",
    subdistrict: "ป่าเว",
    zipcode: 84110,
    latitude: 9.447,
    longitude: 99.144,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ไชยา",
    subdistrict: "ตะกรบ",
    zipcode: 84110,
    latitude: 9.464,
    longitude: 99.229,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ไชยา",
    subdistrict: "โมถ่าย",
    zipcode: 84110,
    latitude: 9.407,
    longitude: 99.084,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ไชยา",
    subdistrict: "ปากหมาก",
    zipcode: 84110,
    latitude: 9.477,
    longitude: 98.948,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าชนะ",
    subdistrict: "ท่าชนะ",
    zipcode: 84170,
    latitude: 9.593,
    longitude: 99.183,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าชนะ",
    subdistrict: "สมอทอง",
    zipcode: 84170,
    latitude: 9.581,
    longitude: 99.053,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าชนะ",
    subdistrict: "ประสงค์",
    zipcode: 84170,
    latitude: 9.586,
    longitude: 98.851,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าชนะ",
    subdistrict: "คันธุลี",
    zipcode: 84170,
    latitude: 9.661,
    longitude: 99.088,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าชนะ",
    subdistrict: "วัง",
    zipcode: 84170,
    latitude: 9.52,
    longitude: 99.198,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าชนะ",
    subdistrict: "คลองพา",
    zipcode: 84170,
    latitude: 9.685,
    longitude: 98.865,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "คีรีรัฐนิคม",
    subdistrict: "ท่าขนอน",
    zipcode: 84180,
    latitude: 9.075,
    longitude: 98.874,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "คีรีรัฐนิคม",
    subdistrict: "บ้านยาง",
    zipcode: 84180,
    latitude: 9.051,
    longitude: 99.012,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "คีรีรัฐนิคม",
    subdistrict: "น้ำหัก",
    zipcode: 84180,
    latitude: 9.157,
    longitude: 98.824,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "คีรีรัฐนิคม",
    subdistrict: "กะเปา",
    zipcode: 84180,
    latitude: 9.011,
    longitude: 98.89,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "คีรีรัฐนิคม",
    subdistrict: "ท่ากระดาน",
    zipcode: 84180,
    latitude: 9.063,
    longitude: 99.025,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "คีรีรัฐนิคม",
    subdistrict: "ย่านยาว",
    zipcode: 84180,
    latitude: 8.992,
    longitude: 98.977,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "คีรีรัฐนิคม",
    subdistrict: "ถ้ำสิงขร",
    zipcode: 84180,
    latitude: 8.993,
    longitude: 99.038,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "คีรีรัฐนิคม",
    subdistrict: "บ้านทำเนียบ",
    zipcode: 84180,
    latitude: 8.868,
    longitude: 98.968,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านตาขุน",
    subdistrict: "เขาวง",
    zipcode: 84230,
    latitude: 8.923,
    longitude: 98.929,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านตาขุน",
    subdistrict: "พะแสง",
    zipcode: 84230,
    latitude: 8.928,
    longitude: 98.84,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านตาขุน",
    subdistrict: "พรุไทย",
    zipcode: 84230,
    latitude: 8.949,
    longitude: 98.896,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านตาขุน",
    subdistrict: "เขาพัง",
    zipcode: 84230,
    latitude: 9.115,
    longitude: 98.645,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พนม",
    subdistrict: "พนม",
    zipcode: 84250,
    latitude: 8.828,
    longitude: 98.781,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พนม",
    subdistrict: "ต้นยวน",
    zipcode: 84250,
    latitude: 8.776,
    longitude: 98.9,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พนม",
    subdistrict: "คลองศก",
    zipcode: 84250,
    latitude: 8.857,
    longitude: 98.688,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พนม",
    subdistrict: "พลูเถื่อน",
    zipcode: 84250,
    latitude: 8.735,
    longitude: 98.738,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พนม",
    subdistrict: "พังกาญจน์",
    zipcode: 84250,
    latitude: 8.878,
    longitude: 98.806,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พนม",
    subdistrict: "คลองชะอุ่น",
    zipcode: 84250,
    latitude: 8.727,
    longitude: 98.865,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าฉาง",
    subdistrict: "ท่าฉาง",
    zipcode: 84150,
    latitude: 9.258,
    longitude: 99.175,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าฉาง",
    subdistrict: "ท่าเคย",
    zipcode: 84150,
    latitude: 9.228,
    longitude: 99.181,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าฉาง",
    subdistrict: "คลองไทร",
    zipcode: 84150,
    latitude: 9.2,
    longitude: 99.165,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าฉาง",
    subdistrict: "เขาถ่าน",
    zipcode: 84150,
    latitude: 9.31,
    longitude: 99.188,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าฉาง",
    subdistrict: "เสวียด",
    zipcode: 84150,
    latitude: 9.311,
    longitude: 99.101,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ท่าฉาง",
    subdistrict: "ปากฉลุย",
    zipcode: 84150,
    latitude: 9.442,
    longitude: 98.752,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "นาสาร",
    zipcode: 84120,
    latitude: 8.812,
    longitude: 99.359,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "พรุพี",
    zipcode: 84270,
    latitude: 8.707,
    longitude: 99.391,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "ทุ่งเตา",
    zipcode: 84120,
    latitude: 8.917,
    longitude: 99.357,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "ลำพูน",
    zipcode: 84120,
    latitude: 8.845,
    longitude: 99.461,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "ท่าชี",
    zipcode: 84120,
    latitude: 8.756,
    longitude: 99.275,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "ควนศรี",
    zipcode: 84270,
    latitude: 8.713,
    longitude: 99.303,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "ควนสุบรรณ",
    zipcode: 84120,
    latitude: 8.876,
    longitude: 99.367,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "คลองปราบ",
    zipcode: 84120,
    latitude: 8.749,
    longitude: 99.348,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "น้ำพุ",
    zipcode: 84120,
    latitude: 8.781,
    longitude: 99.3,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "ทุ่งเตาใหม่",
    zipcode: 84120,
    latitude: 8.952,
    longitude: 99.408,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาสาร",
    subdistrict: "เพิ่มพูนทรัพย์",
    zipcode: 84120,
    latitude: 8.746,
    longitude: 99.462,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาเดิม",
    subdistrict: "บ้านนา",
    zipcode: 84240,
    latitude: 8.856,
    longitude: 99.293,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาเดิม",
    subdistrict: "ท่าเรือ",
    zipcode: 84240,
    latitude: 8.947,
    longitude: 99.269,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาเดิม",
    subdistrict: "ทรัพย์ทวี",
    zipcode: 84240,
    latitude: 8.914,
    longitude: 99.226,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "บ้านนาเดิม",
    subdistrict: "นาใต้",
    zipcode: 84240,
    latitude: 8.906,
    longitude: 99.324,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เคียนซา",
    subdistrict: "เคียนซา",
    zipcode: 84260,
    latitude: 8.817,
    longitude: 99.18,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เคียนซา",
    subdistrict: "พ่วงพรมคร",
    zipcode: 84210,
    latitude: 8.665,
    longitude: 99.227,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เคียนซา",
    subdistrict: "เขาตอก",
    zipcode: 84260,
    latitude: 8.845,
    longitude: 99.251,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เคียนซา",
    subdistrict: "อรัญคามวารี",
    zipcode: 84260,
    latitude: 8.745,
    longitude: 99.19,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เคียนซา",
    subdistrict: "บ้านเสด็จ",
    zipcode: 84260,
    latitude: 8.736,
    longitude: 99.024,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เวียงสระ",
    subdistrict: "เวียงสระ",
    zipcode: 84190,
    latitude: 8.649,
    longitude: 99.342,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เวียงสระ",
    subdistrict: "บ้านส้อง",
    zipcode: 84190,
    latitude: 8.645,
    longitude: 99.42,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เวียงสระ",
    subdistrict: "คลองฉนวน",
    zipcode: 84190,
    latitude: 8.517,
    longitude: 99.321,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เวียงสระ",
    subdistrict: "ทุ่งหลวง",
    zipcode: 84190,
    latitude: 8.592,
    longitude: 99.298,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "เวียงสระ",
    subdistrict: "เขานิพันธ์",
    zipcode: 84190,
    latitude: 8.584,
    longitude: 99.348,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พระแสง",
    subdistrict: "อิปัน",
    zipcode: 84210,
    latitude: 8.584,
    longitude: 99.224,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พระแสง",
    subdistrict: "สินปุน",
    zipcode: 84210,
    latitude: 8.514,
    longitude: 99.238,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พระแสง",
    subdistrict: "บางสวรรค์",
    zipcode: 84210,
    latitude: 8.616,
    longitude: 98.975,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พระแสง",
    subdistrict: "ไทรขึง",
    zipcode: 84210,
    latitude: 8.538,
    longitude: 99.063,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พระแสง",
    subdistrict: "สินเจริญ",
    zipcode: 84210,
    latitude: 8.339,
    longitude: 99.245,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พระแสง",
    subdistrict: "ไทรโสภา",
    zipcode: 84210,
    latitude: 8.597,
    longitude: 99.066,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พระแสง",
    subdistrict: "สาคู",
    zipcode: 84210,
    latitude: 8.59,
    longitude: 99.166,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "ท่าข้าม",
    zipcode: 84130,
    latitude: 9.062,
    longitude: 99.218,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "ท่าสะท้อน",
    zipcode: 84130,
    latitude: 8.994,
    longitude: 99.227,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "ลีเล็ด",
    zipcode: 84130,
    latitude: 9.203,
    longitude: 99.242,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "บางมะเดื่อ",
    zipcode: 84130,
    latitude: 9.031,
    longitude: 99.123,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "บางเดือน",
    zipcode: 84130,
    latitude: 9.026,
    longitude: 99.087,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "ท่าโรงช้าง",
    zipcode: 84130,
    latitude: 9.031,
    longitude: 99.175,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "กรูด",
    zipcode: 84130,
    latitude: 8.949,
    longitude: 99.126,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "พุนพิน",
    zipcode: 84130,
    latitude: 9.125,
    longitude: 99.199,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "บางงอน",
    zipcode: 84130,
    latitude: 9.116,
    longitude: 99.06,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "ศรีวิชัย",
    zipcode: 84130,
    latitude: 9.162,
    longitude: 99.213,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "น้ำรอบ",
    zipcode: 84130,
    latitude: 9.099,
    longitude: 99.112,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "มะลวน",
    zipcode: 84130,
    latitude: 9.166,
    longitude: 99.106,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "หัวเตย",
    zipcode: 84130,
    latitude: 9.136,
    longitude: 99.163,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "หนองไทร",
    zipcode: 84130,
    latitude: 9.096,
    longitude: 99.159,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "เขาหัวควาย",
    zipcode: 84130,
    latitude: 9.039,
    longitude: 99.267,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "พุนพิน",
    subdistrict: "ตะปาน",
    zipcode: 84130,
    latitude: 8.866,
    longitude: 99.087,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ชัยบุรี",
    subdistrict: "สองแพรก",
    zipcode: 84350,
    latitude: 8.483,
    longitude: 99.125,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ชัยบุรี",
    subdistrict: "ชัยบุรี",
    zipcode: 84350,
    latitude: 8.434,
    longitude: 99.071,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ชัยบุรี",
    subdistrict: "คลองน้อย",
    zipcode: 84350,
    latitude: 8.503,
    longitude: 98.997,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "ชัยบุรี",
    subdistrict: "ไทรทอง",
    zipcode: 84350,
    latitude: 8.389,
    longitude: 99.073,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "วิภาวดี",
    subdistrict: "ตะกุกใต้",
    zipcode: 84180,
    latitude: 9.227,
    longitude: 98.807,
  },
  {
    province: "สุราษฎร์ธานี",
    district: "วิภาวดี",
    subdistrict: "ตะกุกเหนือ",
    zipcode: 84180,
    latitude: 9.252,
    longitude: 98.909,
  },
  {
    province: "ระนอง",
    district: "เมืองระนอง",
    subdistrict: "เขานิเวศน์",
    zipcode: 85000,
    latitude: 9.96,
    longitude: 98.621,
  },
  {
    province: "ระนอง",
    district: "เมืองระนอง",
    subdistrict: "ราชกรูด",
    zipcode: 85000,
    latitude: 9.721,
    longitude: 98.578,
  },
  {
    province: "ระนอง",
    district: "เมืองระนอง",
    subdistrict: "หงาว",
    zipcode: 85000,
    latitude: 9.767,
    longitude: 98.547,
  },
  {
    province: "ระนอง",
    district: "เมืองระนอง",
    subdistrict: "บางริ้น",
    zipcode: 85000,
    latitude: 9.909,
    longitude: 98.644,
  },
  {
    province: "ระนอง",
    district: "เมืองระนอง",
    subdistrict: "ปากน้ำ",
    zipcode: 85000,
    latitude: 9.914,
    longitude: 98.526,
  },
  {
    province: "ระนอง",
    district: "เมืองระนอง",
    subdistrict: "บางนอน",
    zipcode: 85000,
    latitude: 10.005,
    longitude: 98.647,
  },
  {
    province: "ระนอง",
    district: "เมืองระนอง",
    subdistrict: "หาดส้มแป้น",
    zipcode: 85000,
    latitude: 9.916,
    longitude: 98.699,
  },
  {
    province: "ระนอง",
    district: "เมืองระนอง",
    subdistrict: "ทรายแดง",
    zipcode: 85130,
    latitude: 10.13,
    longitude: 98.68,
  },
  {
    province: "ระนอง",
    district: "เมืองระนอง",
    subdistrict: "เกาะพยาม",
    zipcode: 85000,
    latitude: 9.877,
    longitude: 98.442,
  },
  {
    province: "ระนอง",
    district: "ละอุ่น",
    subdistrict: "ละอุ่นใต้",
    zipcode: 85130,
    latitude: 10.124,
    longitude: 98.769,
  },
  {
    province: "ระนอง",
    district: "ละอุ่น",
    subdistrict: "ละอุ่นเหนือ",
    zipcode: 85130,
    latitude: 10.027,
    longitude: 98.806,
  },
  {
    province: "ระนอง",
    district: "ละอุ่น",
    subdistrict: "บางพระใต้",
    zipcode: 85130,
    latitude: 10.126,
    longitude: 98.73,
  },
  {
    province: "ระนอง",
    district: "ละอุ่น",
    subdistrict: "บางพระเหนือ",
    zipcode: 85130,
    latitude: 10.022,
    longitude: 98.731,
  },
  {
    province: "ระนอง",
    district: "ละอุ่น",
    subdistrict: "บางแก้ว",
    zipcode: 85130,
    latitude: 10.179,
    longitude: 98.78,
  },
  {
    province: "ระนอง",
    district: "ละอุ่น",
    subdistrict: "ในวงเหนือ",
    zipcode: 85130,
    latitude: 10.02,
    longitude: 98.842,
  },
  {
    province: "ระนอง",
    district: "ละอุ่น",
    subdistrict: "ในวงใต้",
    zipcode: 85130,
    latitude: 10.029,
    longitude: 98.884,
  },
  {
    province: "ระนอง",
    district: "กะเปอร์",
    subdistrict: "ม่วงกลวง",
    zipcode: 85120,
    latitude: 9.606,
    longitude: 98.515,
  },
  {
    province: "ระนอง",
    district: "กะเปอร์",
    subdistrict: "กะเปอร์",
    zipcode: 85120,
    latitude: 9.623,
    longitude: 98.621,
  },
  {
    province: "ระนอง",
    district: "กะเปอร์",
    subdistrict: "เชี่ยวเหลียง",
    zipcode: 85120,
    latitude: 9.562,
    longitude: 98.664,
  },
  {
    province: "ระนอง",
    district: "กะเปอร์",
    subdistrict: "บ้านนา",
    zipcode: 85120,
    latitude: 9.553,
    longitude: 98.74,
  },
  {
    province: "ระนอง",
    district: "กะเปอร์",
    subdistrict: "บางหิน",
    zipcode: 85120,
    latitude: 9.595,
    longitude: 98.469,
  },
  {
    province: "ระนอง",
    district: "กระบุรี",
    subdistrict: "น้ำจืด",
    zipcode: 85110,
    latitude: 10.394,
    longitude: 98.842,
  },
  {
    province: "ระนอง",
    district: "กระบุรี",
    subdistrict: "น้ำจืดน้อย",
    zipcode: 85110,
    latitude: 10.427,
    longitude: 98.793,
  },
  {
    province: "ระนอง",
    district: "กระบุรี",
    subdistrict: "มะมุ",
    zipcode: 85110,
    latitude: 10.457,
    longitude: 98.846,
  },
  {
    province: "ระนอง",
    district: "กระบุรี",
    subdistrict: "ปากจั่น",
    zipcode: 85110,
    latitude: 10.575,
    longitude: 98.832,
  },
  {
    province: "ระนอง",
    district: "กระบุรี",
    subdistrict: "ลำเลียง",
    zipcode: 85110,
    latitude: 10.287,
    longitude: 98.842,
  },
  {
    province: "ระนอง",
    district: "กระบุรี",
    subdistrict: "จ.ป.ร.",
    zipcode: 85110,
    latitude: 10.648,
    longitude: 98.883,
  },
  {
    province: "ระนอง",
    district: "กระบุรี",
    subdistrict: "บางใหญ่",
    zipcode: 85110,
    latitude: 10.265,
    longitude: 98.757,
  },
  {
    province: "ระนอง",
    district: "สุขสำราญ",
    subdistrict: "นาคา",
    zipcode: 85120,
    latitude: 9.514,
    longitude: 98.36,
  },
  {
    province: "ระนอง",
    district: "สุขสำราญ",
    subdistrict: "กำพวน",
    zipcode: 85120,
    latitude: 9.401,
    longitude: 98.392,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "ท่าตะเภา",
    zipcode: 86000,
    latitude: 10.498,
    longitude: 99.179,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "ปากน้ำ",
    zipcode: 86120,
    latitude: 10.448,
    longitude: 99.253,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "ท่ายาง",
    zipcode: 86000,
    latitude: 10.434,
    longitude: 99.217,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "บางหมาก",
    zipcode: 86000,
    latitude: 10.449,
    longitude: 99.185,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "นาทุ่ง",
    zipcode: 86000,
    latitude: 10.5,
    longitude: 99.196,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "นาชะอัง",
    zipcode: 86000,
    latitude: 10.557,
    longitude: 99.379,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "ตากแดด",
    zipcode: 86000,
    latitude: 10.467,
    longitude: 99.153,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "บางลึก",
    zipcode: 86000,
    latitude: 10.551,
    longitude: 99.185,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "หาดพันไกร",
    zipcode: 86000,
    latitude: 10.561,
    longitude: 99.143,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "วังไผ่",
    zipcode: 86000,
    latitude: 10.525,
    longitude: 99.123,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "วังใหม่",
    zipcode: 86190,
    latitude: 10.523,
    longitude: 99.054,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "บ้านนา",
    zipcode: 86190,
    latitude: 10.437,
    longitude: 98.952,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "ขุนกระทิง",
    zipcode: 86000,
    latitude: 10.457,
    longitude: 99.104,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "ทุ่งคา",
    zipcode: 86100,
    latitude: 10.398,
    longitude: 99.14,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "วิสัยเหนือ",
    zipcode: 86100,
    latitude: 10.367,
    longitude: 99.09,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "หาดทรายรี",
    zipcode: 86120,
    latitude: 10.459,
    longitude: 99.403,
  },
  {
    province: "ชุมพร",
    district: "เมืองชุมพร",
    subdistrict: "ถ้ำสิงห์",
    zipcode: 86100,
    latitude: 10.418,
    longitude: 99.058,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "ท่าแซะ",
    zipcode: 86140,
    latitude: 10.663,
    longitude: 99.166,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "คุริง",
    zipcode: 86140,
    latitude: 10.744,
    longitude: 99.118,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "สลุย",
    zipcode: 86140,
    latitude: 10.835,
    longitude: 99.233,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "นากระตาม",
    zipcode: 86140,
    latitude: 10.606,
    longitude: 99.184,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "รับร่อ",
    zipcode: 86140,
    latitude: 10.768,
    longitude: 99.011,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "ท่าข้าม",
    zipcode: 86140,
    latitude: 10.66,
    longitude: 99.105,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "หงษ์เจริญ",
    zipcode: 86140,
    latitude: 10.808,
    longitude: 99.134,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "หินแก้ว",
    zipcode: 86190,
    latitude: 10.622,
    longitude: 98.992,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "ทรัพย์อนันต์",
    zipcode: 86140,
    latitude: 10.713,
    longitude: 99.202,
  },
  {
    province: "ชุมพร",
    district: "ท่าแซะ",
    subdistrict: "สองพี่น้อง",
    zipcode: 86140,
    latitude: 10.949,
    longitude: 99.189,
  },
  {
    province: "ชุมพร",
    district: "ปะทิว",
    subdistrict: "บางสน",
    zipcode: 86160,
    latitude: 10.658,
    longitude: 99.264,
  },
  {
    province: "ชุมพร",
    district: "ปะทิว",
    subdistrict: "ทะเลทรัพย์",
    zipcode: 86160,
    latitude: 10.727,
    longitude: 99.267,
  },
  {
    province: "ชุมพร",
    district: "ปะทิว",
    subdistrict: "สะพลี",
    zipcode: 86230,
    latitude: 10.608,
    longitude: 99.25,
  },
  {
    province: "ชุมพร",
    district: "ปะทิว",
    subdistrict: "ชุมโค",
    zipcode: 86160,
    latitude: 10.701,
    longitude: 99.41,
  },
  {
    province: "ชุมพร",
    district: "ปะทิว",
    subdistrict: "ดอนยาง",
    zipcode: 86210,
    latitude: 10.861,
    longitude: 99.354,
  },
  {
    province: "ชุมพร",
    district: "ปะทิว",
    subdistrict: "ปากคลอง",
    zipcode: 86210,
    latitude: 10.862,
    longitude: 99.486,
  },
  {
    province: "ชุมพร",
    district: "ปะทิว",
    subdistrict: "เขาไชยราช",
    zipcode: 86210,
    latitude: 10.939,
    longitude: 99.349,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "หลังสวน",
    zipcode: 86110,
    latitude: 9.95,
    longitude: 99.09,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "ขันเงิน",
    zipcode: 86110,
    latitude: 9.934,
    longitude: 99.074,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "ท่ามะพลา",
    zipcode: 86110,
    latitude: 9.929,
    longitude: 99.045,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "นาขา",
    zipcode: 86110,
    latitude: 10.009,
    longitude: 99.081,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "นาพญา",
    zipcode: 86110,
    latitude: 9.872,
    longitude: 99.12,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "บ้านควน",
    zipcode: 86110,
    latitude: 9.85,
    longitude: 99.028,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "บางมะพร้าว",
    zipcode: 86110,
    latitude: 9.918,
    longitude: 99.147,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "บางน้ำจืด",
    zipcode: 86150,
    latitude: 10.051,
    longitude: 99.188,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "ปากน้ำ",
    zipcode: 86150,
    latitude: 9.955,
    longitude: 99.151,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "พ้อแดง",
    zipcode: 86110,
    latitude: 9.926,
    longitude: 99.099,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "แหลมทราย",
    zipcode: 86110,
    latitude: 9.962,
    longitude: 99.118,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "วังตะกอ",
    zipcode: 86110,
    latitude: 9.986,
    longitude: 98.984,
  },
  {
    province: "ชุมพร",
    district: "หลังสวน",
    subdistrict: "หาดยาย",
    zipcode: 86110,
    latitude: 9.96,
    longitude: 98.956,
  },
  {
    province: "ชุมพร",
    district: "ละแม",
    subdistrict: "ละแม",
    zipcode: 86170,
    latitude: 9.752,
    longitude: 98.945,
  },
  {
    province: "ชุมพร",
    district: "ละแม",
    subdistrict: "ทุ่งหลวง",
    zipcode: 86170,
    latitude: 9.8,
    longitude: 99.065,
  },
  {
    province: "ชุมพร",
    district: "ละแม",
    subdistrict: "สวนแตง",
    zipcode: 86170,
    latitude: 9.723,
    longitude: 99.108,
  },
  {
    province: "ชุมพร",
    district: "ละแม",
    subdistrict: "ทุ่งคาวัด",
    zipcode: 86170,
    latitude: 9.748,
    longitude: 99,
  },
  {
    province: "ชุมพร",
    district: "พะโต๊ะ",
    subdistrict: "พะโต๊ะ",
    zipcode: 86180,
    latitude: 9.816,
    longitude: 98.8,
  },
  {
    province: "ชุมพร",
    district: "พะโต๊ะ",
    subdistrict: "ปากทรง",
    zipcode: 86180,
    latitude: 9.741,
    longitude: 98.703,
  },
  {
    province: "ชุมพร",
    district: "พะโต๊ะ",
    subdistrict: "ปังหวาน",
    zipcode: 86180,
    latitude: 9.951,
    longitude: 98.866,
  },
  {
    province: "ชุมพร",
    district: "พะโต๊ะ",
    subdistrict: "พระรักษ์",
    zipcode: 86180,
    latitude: 9.852,
    longitude: 98.923,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "นาโพธิ์",
    zipcode: 86130,
    latitude: 10.198,
    longitude: 99.107,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "สวี",
    zipcode: 86130,
    latitude: 10.247,
    longitude: 99.081,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "ทุ่งระยะ",
    zipcode: 86130,
    latitude: 10.277,
    longitude: 98.945,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "ท่าหิน",
    zipcode: 86130,
    latitude: 10.213,
    longitude: 99.151,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "ปากแพรก",
    zipcode: 86130,
    latitude: 10.291,
    longitude: 99.148,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "ด่านสวี",
    zipcode: 86130,
    latitude: 10.291,
    longitude: 99.241,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "ครน",
    zipcode: 86130,
    latitude: 10.311,
    longitude: 99.063,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "วิสัยใต้",
    zipcode: 86130,
    latitude: 10.36,
    longitude: 99.04,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "นาสัก",
    zipcode: 86130,
    latitude: 10.177,
    longitude: 99,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "เขาทะลุ",
    zipcode: 86130,
    latitude: 10.197,
    longitude: 98.915,
  },
  {
    province: "ชุมพร",
    district: "สวี",
    subdistrict: "เขาค่าย",
    zipcode: 86130,
    latitude: 10.104,
    longitude: 98.891,
  },
  {
    province: "ชุมพร",
    district: "ทุ่งตะโก",
    subdistrict: "ปากตะโก",
    zipcode: 86220,
    latitude: 10.165,
    longitude: 99.176,
  },
  {
    province: "ชุมพร",
    district: "ทุ่งตะโก",
    subdistrict: "ทุ่งตะไคร",
    zipcode: 86220,
    latitude: 10.131,
    longitude: 99.097,
  },
  {
    province: "ชุมพร",
    district: "ทุ่งตะโก",
    subdistrict: "ตะโก",
    zipcode: 86220,
    latitude: 10.056,
    longitude: 98.974,
  },
  {
    province: "ชุมพร",
    district: "ทุ่งตะโก",
    subdistrict: "ช่องไม้แก้ว",
    zipcode: 86220,
    latitude: 10.117,
    longitude: 99.024,
  },
  {
    province: "สงขลา",
    district: "เมืองสงขลา",
    subdistrict: "บ่อยาง",
    zipcode: 90000,
    latitude: 7.198,
    longitude: 100.596,
  },
  {
    province: "สงขลา",
    district: "เมืองสงขลา",
    subdistrict: "เขารูปช้าง",
    zipcode: 90000,
    latitude: 7.154,
    longitude: 100.612,
  },
  {
    province: "สงขลา",
    district: "เมืองสงขลา",
    subdistrict: "เกาะแต้ว",
    zipcode: 90000,
    latitude: 7.112,
    longitude: 100.638,
  },
  {
    province: "สงขลา",
    district: "เมืองสงขลา",
    subdistrict: "พะวง",
    zipcode: 90100,
    latitude: 7.105,
    longitude: 100.588,
  },
  {
    province: "สงขลา",
    district: "เมืองสงขลา",
    subdistrict: "ทุ่งหวัง",
    zipcode: 90000,
    latitude: 7.069,
    longitude: 100.657,
  },
  {
    province: "สงขลา",
    district: "เมืองสงขลา",
    subdistrict: "เกาะยอ",
    zipcode: 90100,
    latitude: 7.163,
    longitude: 100.542,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "จะทิ้งพระ",
    zipcode: 90190,
    latitude: 7.469,
    longitude: 100.437,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "กระดังงา",
    zipcode: 90190,
    latitude: 7.509,
    longitude: 100.427,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "สนามชัย",
    zipcode: 90190,
    latitude: 7.547,
    longitude: 100.417,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "ดีหลวง",
    zipcode: 90190,
    latitude: 7.58,
    longitude: 100.406,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "ชุมพล",
    zipcode: 90190,
    latitude: 7.603,
    longitude: 100.385,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "คลองรี",
    zipcode: 90190,
    latitude: 7.542,
    longitude: 100.388,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "คูขุด",
    zipcode: 90190,
    latitude: 7.458,
    longitude: 100.406,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "ท่าหิน",
    zipcode: 90190,
    latitude: 7.395,
    longitude: 100.419,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "วัดจันทร์",
    zipcode: 90190,
    latitude: 7.382,
    longitude: 100.461,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "บ่อแดง",
    zipcode: 90190,
    latitude: 7.406,
    longitude: 100.454,
  },
  {
    province: "สงขลา",
    district: "สทิงพระ",
    subdistrict: "บ่อดาน",
    zipcode: 90190,
    latitude: 7.435,
    longitude: 100.447,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "บ้านนา",
    zipcode: 90130,
    latitude: 6.901,
    longitude: 100.742,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "ป่าชิง",
    zipcode: 90130,
    latitude: 6.939,
    longitude: 100.686,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "สะพานไม้แก่น",
    zipcode: 90130,
    latitude: 6.826,
    longitude: 100.783,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "สะกอม",
    zipcode: 90130,
    latitude: 6.935,
    longitude: 100.794,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "นาหว้า",
    zipcode: 90130,
    latitude: 6.896,
    longitude: 100.655,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "นาทับ",
    zipcode: 90130,
    latitude: 7.035,
    longitude: 100.7,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "น้ำขาว",
    zipcode: 90130,
    latitude: 6.805,
    longitude: 100.609,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "ขุนตัดหวาย",
    zipcode: 90130,
    latitude: 6.794,
    longitude: 100.7,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "ท่าหมอไทร",
    zipcode: 90130,
    latitude: 6.794,
    longitude: 100.751,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "จะโหนง",
    zipcode: 90130,
    latitude: 7.006,
    longitude: 100.67,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "คู",
    zipcode: 90130,
    latitude: 6.842,
    longitude: 100.695,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "แค",
    zipcode: 90130,
    latitude: 6.853,
    longitude: 100.618,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "คลองเปียะ",
    zipcode: 90130,
    latitude: 6.952,
    longitude: 100.632,
  },
  {
    province: "สงขลา",
    district: "จะนะ",
    subdistrict: "ตลิ่งชัน",
    zipcode: 90130,
    latitude: 6.969,
    longitude: 100.742,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "นาทวี",
    zipcode: 90160,
    latitude: 6.693,
    longitude: 100.777,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "ฉาง",
    zipcode: 90160,
    latitude: 6.762,
    longitude: 100.709,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "นาหมอศรี",
    zipcode: 90160,
    latitude: 6.781,
    longitude: 100.67,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "คลองทราย",
    zipcode: 90160,
    latitude: 6.741,
    longitude: 100.603,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "ปลักหนู",
    zipcode: 90160,
    latitude: 6.676,
    longitude: 100.663,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "ท่าประดู่",
    zipcode: 90160,
    latitude: 6.681,
    longitude: 100.73,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "สะท้อน",
    zipcode: 90160,
    latitude: 6.622,
    longitude: 100.673,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "ทับช้าง",
    zipcode: 90160,
    latitude: 6.586,
    longitude: 100.695,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "ประกอบ",
    zipcode: 90160,
    latitude: 6.483,
    longitude: 100.643,
  },
  {
    province: "สงขลา",
    district: "นาทวี",
    subdistrict: "คลองกวาง",
    zipcode: 90160,
    latitude: 6.636,
    longitude: 100.61,
  },
  {
    province: "สงขลา",
    district: "เทพา",
    subdistrict: "เทพา",
    zipcode: 90150,
    latitude: 6.82,
    longitude: 100.94,
  },
  {
    province: "สงขลา",
    district: "เทพา",
    subdistrict: "ปากบาง",
    zipcode: 90150,
    latitude: 6.826,
    longitude: 101.009,
  },
  {
    province: "สงขลา",
    district: "เทพา",
    subdistrict: "เกาะสะบ้า",
    zipcode: 90150,
    latitude: 6.863,
    longitude: 100.891,
  },
  {
    province: "สงขลา",
    district: "เทพา",
    subdistrict: "ลำไพล",
    zipcode: 90260,
    latitude: 6.721,
    longitude: 100.93,
  },
  {
    province: "สงขลา",
    district: "เทพา",
    subdistrict: "ท่าม่วง",
    zipcode: 90150,
    latitude: 6.742,
    longitude: 101.001,
  },
  {
    province: "สงขลา",
    district: "เทพา",
    subdistrict: "วังใหญ่",
    zipcode: 90260,
    latitude: 6.763,
    longitude: 100.853,
  },
  {
    province: "สงขลา",
    district: "เทพา",
    subdistrict: "สะกอม",
    zipcode: 90150,
    latitude: 6.88,
    longitude: 100.818,
  },
  {
    province: "สงขลา",
    district: "สะบ้าย้อย",
    subdistrict: "สะบ้าย้อย",
    zipcode: 90210,
    latitude: 6.632,
    longitude: 100.929,
  },
  {
    province: "สงขลา",
    district: "สะบ้าย้อย",
    subdistrict: "ทุ่งพอ",
    zipcode: 90210,
    latitude: 6.553,
    longitude: 100.91,
  },
  {
    province: "สงขลา",
    district: "สะบ้าย้อย",
    subdistrict: "เปียน",
    zipcode: 90210,
    latitude: 6.634,
    longitude: 101.003,
  },
  {
    province: "สงขลา",
    district: "สะบ้าย้อย",
    subdistrict: "บ้านโหนด",
    zipcode: 90210,
    latitude: 6.624,
    longitude: 101.057,
  },
  {
    province: "สงขลา",
    district: "สะบ้าย้อย",
    subdistrict: "จะแหน",
    zipcode: 90210,
    latitude: 6.517,
    longitude: 100.962,
  },
  {
    province: "สงขลา",
    district: "สะบ้าย้อย",
    subdistrict: "คูหา",
    zipcode: 90210,
    latitude: 6.61,
    longitude: 100.833,
  },
  {
    province: "สงขลา",
    district: "สะบ้าย้อย",
    subdistrict: "เขาแดง",
    zipcode: 90210,
    latitude: 6.505,
    longitude: 100.802,
  },
  {
    province: "สงขลา",
    district: "สะบ้าย้อย",
    subdistrict: "บาโหย",
    zipcode: 90210,
    latitude: 6.396,
    longitude: 100.884,
  },
  {
    province: "สงขลา",
    district: "สะบ้าย้อย",
    subdistrict: "ธารคีรี",
    zipcode: 90210,
    latitude: 6.559,
    longitude: 101.059,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "ระโนด",
    zipcode: 90140,
    latitude: 7.754,
    longitude: 100.325,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "คลองแดน",
    zipcode: 90140,
    latitude: 7.912,
    longitude: 100.319,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "ตะเครียะ",
    zipcode: 90140,
    latitude: 7.789,
    longitude: 100.269,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "ท่าบอน",
    zipcode: 90140,
    latitude: 7.842,
    longitude: 100.342,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "บ้านใหม่",
    zipcode: 90140,
    latitude: 7.78,
    longitude: 100.288,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "บ่อตรุ",
    zipcode: 90140,
    latitude: 7.631,
    longitude: 100.374,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "ปากแตระ",
    zipcode: 90140,
    latitude: 7.766,
    longitude: 100.358,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "พังยาง",
    zipcode: 90140,
    latitude: 7.721,
    longitude: 100.354,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "ระวะ",
    zipcode: 90140,
    latitude: 7.713,
    longitude: 100.378,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "วัดสน",
    zipcode: 90140,
    latitude: 7.668,
    longitude: 100.372,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "บ้านขาว",
    zipcode: 90140,
    latitude: 7.761,
    longitude: 100.247,
  },
  {
    province: "สงขลา",
    district: "ระโนด",
    subdistrict: "แดนสงวน",
    zipcode: 90140,
    latitude: 7.878,
    longitude: 100.291,
  },
  {
    province: "สงขลา",
    district: "กระแสสินธุ์",
    subdistrict: "เกาะใหญ่",
    zipcode: 90270,
    latitude: 7.552,
    longitude: 100.287,
  },
  {
    province: "สงขลา",
    district: "กระแสสินธุ์",
    subdistrict: "โรง",
    zipcode: 90270,
    latitude: 7.683,
    longitude: 100.338,
  },
  {
    province: "สงขลา",
    district: "กระแสสินธุ์",
    subdistrict: "เชิงแส",
    zipcode: 90270,
    latitude: 7.561,
    longitude: 100.356,
  },
  {
    province: "สงขลา",
    district: "กระแสสินธุ์",
    subdistrict: "กระแสสินธุ์",
    zipcode: 90270,
    latitude: 7.609,
    longitude: 100.307,
  },
  {
    province: "สงขลา",
    district: "รัตภูมิ",
    subdistrict: "กำแพงเพชร",
    zipcode: 90180,
    latitude: 7.087,
    longitude: 100.287,
  },
  {
    province: "สงขลา",
    district: "รัตภูมิ",
    subdistrict: "ท่าชะมวง",
    zipcode: 90180,
    latitude: 7.132,
    longitude: 100.106,
  },
  {
    province: "สงขลา",
    district: "รัตภูมิ",
    subdistrict: "คูหาใต้",
    zipcode: 90180,
    latitude: 7.173,
    longitude: 100.263,
  },
  {
    province: "สงขลา",
    district: "รัตภูมิ",
    subdistrict: "ควนรู",
    zipcode: 90180,
    latitude: 7.183,
    longitude: 100.313,
  },
  {
    province: "สงขลา",
    district: "รัตภูมิ",
    subdistrict: "เขาพระ",
    zipcode: 90180,
    latitude: 7.011,
    longitude: 100.141,
  },
  {
    province: "สงขลา",
    district: "สะเดา",
    subdistrict: "สะเดา",
    zipcode: 90120,
    latitude: 6.636,
    longitude: 100.416,
  },
  {
    province: "สงขลา",
    district: "สะเดา",
    subdistrict: "ปริก",
    zipcode: 90120,
    latitude: 6.694,
    longitude: 100.473,
  },
  {
    province: "สงขลา",
    district: "สะเดา",
    subdistrict: "พังลา",
    zipcode: 90170,
    latitude: 6.773,
    longitude: 100.464,
  },
  {
    province: "สงขลา",
    district: "สะเดา",
    subdistrict: "สำนักแต้ว",
    zipcode: 90120,
    latitude: 6.567,
    longitude: 100.516,
  },
  {
    province: "สงขลา",
    district: "สะเดา",
    subdistrict: "ทุ่งหมอ",
    zipcode: 90240,
    latitude: 6.745,
    longitude: 100.378,
  },
  {
    province: "สงขลา",
    district: "สะเดา",
    subdistrict: "ท่าโพธิ์",
    zipcode: 90170,
    latitude: 6.796,
    longitude: 100.416,
  },
  {
    province: "สงขลา",
    district: "สะเดา",
    subdistrict: "ปาดังเบซาร์",
    zipcode: 90240,
    latitude: 6.74,
    longitude: 100.241,
  },
  {
    province: "สงขลา",
    district: "สะเดา",
    subdistrict: "สำนักขาม",
    zipcode: 90120,
    latitude: 6.584,
    longitude: 100.382,
  },
  {
    province: "สงขลา",
    district: "สะเดา",
    subdistrict: "เขามีเกียรติ",
    zipcode: 90170,
    latitude: 6.774,
    longitude: 100.524,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "หาดใหญ่",
    zipcode: 90110,
    latitude: 7.01,
    longitude: 100.474,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "ควนลัง",
    zipcode: 90110,
    latitude: 6.973,
    longitude: 100.421,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "คูเต่า",
    zipcode: 90110,
    latitude: 7.133,
    longitude: 100.481,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "คอหงส์",
    zipcode: 90110,
    latitude: 7.006,
    longitude: 100.503,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "คลองแห",
    zipcode: 90110,
    latitude: 7.055,
    longitude: 100.48,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "คลองอู่ตะเภา",
    zipcode: 90110,
    latitude: 7.045,
    longitude: 100.446,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "ฉลุง",
    zipcode: 90110,
    latitude: 7.007,
    longitude: 100.296,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "ทุ่งใหญ่",
    zipcode: 90110,
    latitude: 7.03,
    longitude: 100.537,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "ทุ่งตำเสา",
    zipcode: 90110,
    latitude: 6.9,
    longitude: 100.244,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "ท่าข้าม",
    zipcode: 90110,
    latitude: 7.041,
    longitude: 100.574,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "น้ำน้อย",
    zipcode: 90110,
    latitude: 7.108,
    longitude: 100.53,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "บ้านพรุ",
    zipcode: 90250,
    latitude: 6.929,
    longitude: 100.49,
  },
  {
    province: "สงขลา",
    district: "หาดใหญ่",
    subdistrict: "พะตง",
    zipcode: 90230,
    latitude: 6.837,
    longitude: 100.558,
  },
  {
    province: "สงขลา",
    district: "นาหม่อม",
    subdistrict: "นาหม่อม",
    zipcode: 90310,
    latitude: 6.965,
    longitude: 100.529,
  },
  {
    province: "สงขลา",
    district: "นาหม่อม",
    subdistrict: "พิจิตร",
    zipcode: 90310,
    latitude: 6.991,
    longitude: 100.561,
  },
  {
    province: "สงขลา",
    district: "นาหม่อม",
    subdistrict: "ทุ่งขมิ้น",
    zipcode: 90310,
    latitude: 6.916,
    longitude: 100.547,
  },
  {
    province: "สงขลา",
    district: "นาหม่อม",
    subdistrict: "คลองหรัง",
    zipcode: 90310,
    latitude: 6.924,
    longitude: 100.591,
  },
  {
    province: "สงขลา",
    district: "ควนเนียง",
    subdistrict: "รัตภูมิ",
    zipcode: 90220,
    latitude: 7.182,
    longitude: 100.37,
  },
  {
    province: "สงขลา",
    district: "ควนเนียง",
    subdistrict: "ควนโส",
    zipcode: 90220,
    latitude: 7.229,
    longitude: 100.402,
  },
  {
    province: "สงขลา",
    district: "ควนเนียง",
    subdistrict: "ห้วยลึก",
    zipcode: 90220,
    latitude: 7.267,
    longitude: 100.366,
  },
  {
    province: "สงขลา",
    district: "ควนเนียง",
    subdistrict: "บางเหรียง",
    zipcode: 90220,
    latitude: 7.112,
    longitude: 100.364,
  },
  {
    province: "สงขลา",
    district: "บางกล่ำ",
    subdistrict: "บางกล่ำ",
    zipcode: 90110,
    latitude: 7.135,
    longitude: 100.446,
  },
  {
    province: "สงขลา",
    district: "บางกล่ำ",
    subdistrict: "ท่าช้าง",
    zipcode: 90110,
    latitude: 7.048,
    longitude: 100.378,
  },
  {
    province: "สงขลา",
    district: "บางกล่ำ",
    subdistrict: "แม่ทอม",
    zipcode: 90110,
    latitude: 7.1,
    longitude: 100.456,
  },
  {
    province: "สงขลา",
    district: "บางกล่ำ",
    subdistrict: "บ้านหาร",
    zipcode: 90110,
    latitude: 7.075,
    longitude: 100.45,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "ชิงโค",
    zipcode: 90280,
    latitude: 7.266,
    longitude: 100.524,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "สทิงหม้อ",
    zipcode: 90280,
    latitude: 7.21,
    longitude: 100.524,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "ทำนบ",
    zipcode: 90280,
    latitude: 7.246,
    longitude: 100.506,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "รำแดง",
    zipcode: 90330,
    latitude: 7.29,
    longitude: 100.486,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "วัดขนุน",
    zipcode: 90330,
    latitude: 7.304,
    longitude: 100.497,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "ชะแล้",
    zipcode: 90330,
    latitude: 7.308,
    longitude: 100.441,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "ปากรอ",
    zipcode: 90330,
    latitude: 7.277,
    longitude: 100.441,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "ป่าขาด",
    zipcode: 90330,
    latitude: 7.241,
    longitude: 100.469,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "หัวเขา",
    zipcode: 90280,
    latitude: 7.214,
    longitude: 100.567,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "บางเขียด",
    zipcode: 90330,
    latitude: 7.346,
    longitude: 100.437,
  },
  {
    province: "สงขลา",
    district: "สิงหนคร",
    subdistrict: "ม่วงงาม",
    zipcode: 90330,
    latitude: 7.347,
    longitude: 100.476,
  },
  {
    province: "สงขลา",
    district: "คลองหอยโข่ง",
    subdistrict: "คลองหอยโข่ง",
    zipcode: 90230,
    latitude: 6.83,
    longitude: 100.313,
  },
  {
    province: "สงขลา",
    district: "คลองหอยโข่ง",
    subdistrict: "ทุ่งลาน",
    zipcode: 90230,
    latitude: 6.886,
    longitude: 100.442,
  },
  {
    province: "สงขลา",
    district: "คลองหอยโข่ง",
    subdistrict: "โคกม่วง",
    zipcode: 90230,
    latitude: 6.875,
    longitude: 100.406,
  },
  {
    province: "สงขลา",
    district: "คลองหอยโข่ง",
    subdistrict: "คลองหลา",
    zipcode: 90115,
    latitude: 6.882,
    longitude: 100.31,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "พิมาน",
    zipcode: 91000,
    latitude: 6.615,
    longitude: 100.071,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "คลองขุด",
    zipcode: 91000,
    latitude: 6.625,
    longitude: 100.12,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "ควนขัน",
    zipcode: 91000,
    latitude: 6.633,
    longitude: 100.036,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "บ้านควน",
    zipcode: 91140,
    latitude: 6.691,
    longitude: 100.049,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "ฉลุง",
    zipcode: 91140,
    latitude: 6.725,
    longitude: 100.035,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "เกาะสาหร่าย",
    zipcode: 91000,
    latitude: 6.546,
    longitude: 99.706,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "ตันหยงโป",
    zipcode: 91000,
    latitude: 6.589,
    longitude: 99.936,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "เจ๊ะบิลัง",
    zipcode: 91000,
    latitude: 6.687,
    longitude: 99.965,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "ตำมะลัง",
    zipcode: 91000,
    latitude: 6.556,
    longitude: 100.044,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "ปูยู",
    zipcode: 91000,
    latitude: 6.517,
    longitude: 100.124,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "ควนโพธิ์",
    zipcode: 91140,
    latitude: 6.769,
    longitude: 100.018,
  },
  {
    province: "สตูล",
    district: "เมืองสตูล",
    subdistrict: "เกตรี",
    zipcode: 91140,
    latitude: 6.702,
    longitude: 100.095,
  },
  {
    province: "สตูล",
    district: "ควนโดน",
    subdistrict: "ควนโดน",
    zipcode: 91160,
    latitude: 6.814,
    longitude: 100.06,
  },
  {
    province: "สตูล",
    district: "ควนโดน",
    subdistrict: "ควนสตอ",
    zipcode: 91160,
    latitude: 6.764,
    longitude: 100.101,
  },
  {
    province: "สตูล",
    district: "ควนโดน",
    subdistrict: "ย่านซื่อ",
    zipcode: 91160,
    latitude: 6.767,
    longitude: 100.065,
  },
  {
    province: "สตูล",
    district: "ควนโดน",
    subdistrict: "วังประจัน",
    zipcode: 91160,
    latitude: 6.753,
    longitude: 100.16,
  },
  {
    province: "สตูล",
    district: "ควนกาหลง",
    subdistrict: "ทุ่งนุ้ย",
    zipcode: 91130,
    latitude: 6.871,
    longitude: 100.144,
  },
  {
    province: "สตูล",
    district: "ควนกาหลง",
    subdistrict: "ควนกาหลง",
    zipcode: 91130,
    latitude: 6.953,
    longitude: 100.03,
  },
  {
    province: "สตูล",
    district: "ควนกาหลง",
    subdistrict: "อุใดเจริญ",
    zipcode: 91130,
    latitude: 6.903,
    longitude: 99.932,
  },
  {
    province: "สตูล",
    district: "ท่าแพ",
    subdistrict: "ท่าแพ",
    zipcode: 91150,
    latitude: 6.791,
    longitude: 99.964,
  },
  {
    province: "สตูล",
    district: "ท่าแพ",
    subdistrict: "แป-ระ",
    zipcode: 91150,
    latitude: 6.855,
    longitude: 99.927,
  },
  {
    province: "สตูล",
    district: "ท่าแพ",
    subdistrict: "สาคร",
    zipcode: 91150,
    latitude: 6.787,
    longitude: 99.865,
  },
  {
    province: "สตูล",
    district: "ท่าแพ",
    subdistrict: "ท่าเรือ",
    zipcode: 91150,
    latitude: 6.803,
    longitude: 99.917,
  },
  {
    province: "สตูล",
    district: "ละงู",
    subdistrict: "กำแพง",
    zipcode: 91110,
    latitude: 6.933,
    longitude: 99.777,
  },
  {
    province: "สตูล",
    district: "ละงู",
    subdistrict: "ละงู",
    zipcode: 91110,
    latitude: 6.842,
    longitude: 99.826,
  },
  {
    province: "สตูล",
    district: "ละงู",
    subdistrict: "เขาขาว",
    zipcode: 91110,
    latitude: 6.923,
    longitude: 99.848,
  },
  {
    province: "สตูล",
    district: "ละงู",
    subdistrict: "ปากน้ำ",
    zipcode: 91110,
    latitude: 6.834,
    longitude: 99.691,
  },
  {
    province: "สตูล",
    district: "ละงู",
    subdistrict: "น้ำผุด",
    zipcode: 91110,
    latitude: 6.973,
    longitude: 99.85,
  },
  {
    province: "สตูล",
    district: "ละงู",
    subdistrict: "แหลมสน",
    zipcode: 91110,
    latitude: 6.946,
    longitude: 99.713,
  },
  {
    province: "สตูล",
    district: "ทุ่งหว้า",
    subdistrict: "ทุ่งหว้า",
    zipcode: 91120,
    latitude: 7.108,
    longitude: 99.807,
  },
  {
    province: "สตูล",
    district: "ทุ่งหว้า",
    subdistrict: "นาทอน",
    zipcode: 91120,
    latitude: 7.032,
    longitude: 99.743,
  },
  {
    province: "สตูล",
    district: "ทุ่งหว้า",
    subdistrict: "ขอนคลาน",
    zipcode: 91120,
    latitude: 6.998,
    longitude: 99.691,
  },
  {
    province: "สตูล",
    district: "ทุ่งหว้า",
    subdistrict: "ทุ่งบุหลัง",
    zipcode: 91120,
    latitude: 7.047,
    longitude: 99.692,
  },
  {
    province: "สตูล",
    district: "ทุ่งหว้า",
    subdistrict: "ป่าแก่บ่อหิน",
    zipcode: 91120,
    latitude: 7.048,
    longitude: 99.817,
  },
  {
    province: "สตูล",
    district: "มะนัง",
    subdistrict: "ปาล์มพัฒนา",
    zipcode: 91130,
    latitude: 7.038,
    longitude: 99.934,
  },
  {
    province: "สตูล",
    district: "มะนัง",
    subdistrict: "นิคมพัฒนา",
    zipcode: 91130,
    latitude: 6.95,
    longitude: 99.931,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "ทับเที่ยง",
    zipcode: 92000,
    latitude: 7.559,
    longitude: 99.616,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "นาพละ",
    zipcode: 92000,
    latitude: 7.599,
    longitude: 99.664,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "บ้านควน",
    zipcode: 92000,
    latitude: 7.529,
    longitude: 99.636,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "นาบินหลา",
    zipcode: 92000,
    latitude: 7.541,
    longitude: 99.662,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "ควนปริง",
    zipcode: 92000,
    latitude: 7.521,
    longitude: 99.596,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "นาโยงใต้",
    zipcode: 92170,
    latitude: 7.571,
    longitude: 99.669,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "บางรัก",
    zipcode: 92000,
    latitude: 7.558,
    longitude: 99.577,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "โคกหล่อ",
    zipcode: 92000,
    latitude: 7.524,
    longitude: 99.615,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "นาโต๊ะหมิง",
    zipcode: 92000,
    latitude: 7.556,
    longitude: 99.527,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "หนองตรุด",
    zipcode: 92000,
    latitude: 7.594,
    longitude: 99.523,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "น้ำผุด",
    zipcode: 92000,
    latitude: 7.673,
    longitude: 99.726,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "นาตาล่วง",
    zipcode: 92000,
    latitude: 7.584,
    longitude: 99.596,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "บ้านโพธิ์",
    zipcode: 92000,
    latitude: 7.604,
    longitude: 99.627,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "นาท่ามเหนือ",
    zipcode: 92190,
    latitude: 7.652,
    longitude: 99.613,
  },
  {
    province: "ตรัง",
    district: "เมืองตรัง",
    subdistrict: "นาท่ามใต้",
    zipcode: 92190,
    latitude: 7.635,
    longitude: 99.551,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "กันตัง",
    zipcode: 92110,
    latitude: 7.41,
    longitude: 99.519,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "ควนธานี",
    zipcode: 92110,
    latitude: 7.512,
    longitude: 99.563,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "บางหมาก",
    zipcode: 92110,
    latitude: 7.465,
    longitude: 99.554,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "บางเป้า",
    zipcode: 92110,
    latitude: 7.402,
    longitude: 99.549,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "วังวน",
    zipcode: 92110,
    latitude: 7.358,
    longitude: 99.558,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "กันตังใต้",
    zipcode: 92110,
    latitude: 7.338,
    longitude: 99.515,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "โคกยาง",
    zipcode: 92110,
    latitude: 7.509,
    longitude: 99.48,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "คลองลุ",
    zipcode: 92110,
    latitude: 7.482,
    longitude: 99.495,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "ย่านซื่อ",
    zipcode: 92110,
    latitude: 7.473,
    longitude: 99.541,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "บ่อน้ำร้อน",
    zipcode: 92110,
    latitude: 7.425,
    longitude: 99.474,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "บางสัก",
    zipcode: 92110,
    latitude: 7.397,
    longitude: 99.383,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "นาเกลือ",
    zipcode: 92110,
    latitude: 7.326,
    longitude: 99.466,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "เกาะลิบง",
    zipcode: 92110,
    latitude: 7.303,
    longitude: 99.394,
  },
  {
    province: "ตรัง",
    district: "กันตัง",
    subdistrict: "คลองชีล้อม",
    zipcode: 92110,
    latitude: 7.433,
    longitude: 99.587,
  },
  {
    province: "ตรัง",
    district: "ย่านตาขาว",
    subdistrict: "ย่านตาขาว",
    zipcode: 92140,
    latitude: 7.406,
    longitude: 99.674,
  },
  {
    province: "ตรัง",
    district: "ย่านตาขาว",
    subdistrict: "หนองบ่อ",
    zipcode: 92140,
    latitude: 7.416,
    longitude: 99.718,
  },
  {
    province: "ตรัง",
    district: "ย่านตาขาว",
    subdistrict: "นาชุมเห็ด",
    zipcode: 92140,
    latitude: 7.477,
    longitude: 99.822,
  },
  {
    province: "ตรัง",
    district: "ย่านตาขาว",
    subdistrict: "ในควน",
    zipcode: 92140,
    latitude: 7.376,
    longitude: 99.768,
  },
  {
    province: "ตรัง",
    district: "ย่านตาขาว",
    subdistrict: "โพรงจระเข้",
    zipcode: 92140,
    latitude: 7.407,
    longitude: 99.83,
  },
  {
    province: "ตรัง",
    district: "ย่านตาขาว",
    subdistrict: "ทุ่งกระบือ",
    zipcode: 92140,
    latitude: 7.404,
    longitude: 99.626,
  },
  {
    province: "ตรัง",
    district: "ย่านตาขาว",
    subdistrict: "ทุ่งค่าย",
    zipcode: 92140,
    latitude: 7.474,
    longitude: 99.648,
  },
  {
    province: "ตรัง",
    district: "ย่านตาขาว",
    subdistrict: "เกาะเปียะ",
    zipcode: 92140,
    latitude: 7.453,
    longitude: 99.701,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "ท่าข้าม",
    zipcode: 92120,
    latitude: 7.127,
    longitude: 99.699,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "ทุ่งยาว",
    zipcode: 92180,
    latitude: 7.241,
    longitude: 99.753,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "ปะเหลียน",
    zipcode: 92180,
    latitude: 7.288,
    longitude: 99.862,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "บางด้วน",
    zipcode: 92140,
    latitude: 7.35,
    longitude: 99.699,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "บ้านนา",
    zipcode: 92140,
    latitude: 7.302,
    longitude: 99.699,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "สุโสะ",
    zipcode: 92120,
    latitude: 7.236,
    longitude: 99.673,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "ลิพัง",
    zipcode: 92180,
    latitude: 7.184,
    longitude: 99.81,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "เกาะสุกร",
    zipcode: 92120,
    latitude: 7.101,
    longitude: 99.581,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "ท่าพญา",
    zipcode: 92140,
    latitude: 7.354,
    longitude: 99.674,
  },
  {
    province: "ตรัง",
    district: "ปะเหลียน",
    subdistrict: "แหลมสอม",
    zipcode: 92180,
    latitude: 7.287,
    longitude: 99.763,
  },
  {
    province: "ตรัง",
    district: "สิเกา",
    subdistrict: "บ่อหิน",
    zipcode: 92150,
    latitude: 7.549,
    longitude: 99.283,
  },
  {
    province: "ตรัง",
    district: "สิเกา",
    subdistrict: "เขาไม้แก้ว",
    zipcode: 92150,
    latitude: 7.645,
    longitude: 99.306,
  },
  {
    province: "ตรัง",
    district: "สิเกา",
    subdistrict: "กะลาเส",
    zipcode: 92150,
    latitude: 7.744,
    longitude: 99.326,
  },
  {
    province: "ตรัง",
    district: "สิเกา",
    subdistrict: "ไม้ฝาด",
    zipcode: 92150,
    latitude: 7.47,
    longitude: 99.32,
  },
  {
    province: "ตรัง",
    district: "สิเกา",
    subdistrict: "นาเมืองเพชร",
    zipcode: 92000,
    latitude: 7.559,
    longitude: 99.465,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "ห้วยยอด",
    zipcode: 92130,
    latitude: 7.776,
    longitude: 99.632,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "หนองช้างแล่น",
    zipcode: 92130,
    latitude: 7.84,
    longitude: 99.585,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "บางดี",
    zipcode: 92210,
    latitude: 7.841,
    longitude: 99.494,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "บางกุ้ง",
    zipcode: 92210,
    latitude: 7.762,
    longitude: 99.457,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "เขากอบ",
    zipcode: 92130,
    latitude: 7.798,
    longitude: 99.563,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "เขาขาว",
    zipcode: 92130,
    latitude: 7.811,
    longitude: 99.618,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "เขาปูน",
    zipcode: 92130,
    latitude: 7.803,
    longitude: 99.668,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "ปากแจ่ม",
    zipcode: 92190,
    latitude: 7.737,
    longitude: 99.724,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "ปากคม",
    zipcode: 92130,
    latitude: 7.732,
    longitude: 99.607,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "ท่างิ้ว",
    zipcode: 92130,
    latitude: 7.845,
    longitude: 99.651,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "ลำภูรา",
    zipcode: 92190,
    latitude: 7.698,
    longitude: 99.583,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "นาวง",
    zipcode: 92210,
    latitude: 7.74,
    longitude: 99.511,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "ห้วยนาง",
    zipcode: 92130,
    latitude: 7.893,
    longitude: 99.576,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "ในเตา",
    zipcode: 92130,
    latitude: 7.86,
    longitude: 99.727,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "ทุ่งต่อ",
    zipcode: 92130,
    latitude: 7.759,
    longitude: 99.575,
  },
  {
    province: "ตรัง",
    district: "ห้วยยอด",
    subdistrict: "วังคีรี",
    zipcode: 92210,
    latitude: 7.79,
    longitude: 99.472,
  },
  {
    province: "ตรัง",
    district: "วังวิเศษ",
    subdistrict: "เขาวิเศษ",
    zipcode: 92220,
    latitude: 7.651,
    longitude: 99.459,
  },
  {
    province: "ตรัง",
    district: "วังวิเศษ",
    subdistrict: "วังมะปราง",
    zipcode: 92220,
    latitude: 7.656,
    longitude: 99.382,
  },
  {
    province: "ตรัง",
    district: "วังวิเศษ",
    subdistrict: "อ่าวตง",
    zipcode: 92220,
    latitude: 7.862,
    longitude: 99.365,
  },
  {
    province: "ตรัง",
    district: "วังวิเศษ",
    subdistrict: "ท่าสะบ้า",
    zipcode: 92000,
    latitude: 7.69,
    longitude: 99.523,
  },
  {
    province: "ตรัง",
    district: "วังวิเศษ",
    subdistrict: "วังมะปรางเหนือ",
    zipcode: 92220,
    latitude: 7.746,
    longitude: 99.398,
  },
  {
    province: "ตรัง",
    district: "นาโยง",
    subdistrict: "นาโยงเหนือ",
    zipcode: 92170,
    latitude: 7.566,
    longitude: 99.699,
  },
  {
    province: "ตรัง",
    district: "นาโยง",
    subdistrict: "ช่อง",
    zipcode: 92170,
    latitude: 7.546,
    longitude: 99.793,
  },
  {
    province: "ตรัง",
    district: "นาโยง",
    subdistrict: "ละมอ",
    zipcode: 92170,
    latitude: 7.597,
    longitude: 99.753,
  },
  {
    province: "ตรัง",
    district: "นาโยง",
    subdistrict: "โคกสะบ้า",
    zipcode: 92170,
    latitude: 7.49,
    longitude: 99.714,
  },
  {
    province: "ตรัง",
    district: "นาโยง",
    subdistrict: "นาหมื่นศรี",
    zipcode: 92170,
    latitude: 7.609,
    longitude: 99.699,
  },
  {
    province: "ตรัง",
    district: "นาโยง",
    subdistrict: "นาข้าวเสีย",
    zipcode: 92170,
    latitude: 7.532,
    longitude: 99.71,
  },
  {
    province: "ตรัง",
    district: "รัษฎา",
    subdistrict: "ควนเมา",
    zipcode: 92160,
    latitude: 7.938,
    longitude: 99.596,
  },
  {
    province: "ตรัง",
    district: "รัษฎา",
    subdistrict: "คลองปาง",
    zipcode: 92160,
    latitude: 7.987,
    longitude: 99.645,
  },
  {
    province: "ตรัง",
    district: "รัษฎา",
    subdistrict: "หนองบัว",
    zipcode: 92160,
    latitude: 7.9848998,
    longitude: 99.6888052,
  },
  {
    province: "ตรัง",
    district: "รัษฎา",
    subdistrict: "หนองปรือ",
    zipcode: 92130,
    latitude: 7.9,
    longitude: 99.669,
  },
  {
    province: "ตรัง",
    district: "รัษฎา",
    subdistrict: "เขาไพร",
    zipcode: 92160,
    latitude: 7.96,
    longitude: 99.661,
  },
  {
    province: "ตรัง",
    district: "หาดสำราญ",
    subdistrict: "หาดสำราญ",
    zipcode: 92120,
    latitude: 7.256,
    longitude: 99.552,
  },
  {
    province: "ตรัง",
    district: "หาดสำราญ",
    subdistrict: "บ้าหวี",
    zipcode: 92120,
    latitude: 7.283,
    longitude: 99.597,
  },
  {
    province: "ตรัง",
    district: "หาดสำราญ",
    subdistrict: "ตะเสะ",
    zipcode: 92120,
    latitude: 7.033,
    longitude: 99.449,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "คูหาสวรรค์",
    zipcode: 93000,
    latitude: 7.625,
    longitude: 100.085,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "เขาเจียก",
    zipcode: 93000,
    latitude: 7.624,
    longitude: 100.039,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "ท่ามิหรำ",
    zipcode: 93000,
    latitude: 7.589,
    longitude: 100.05,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "โคกชะงาย",
    zipcode: 93000,
    latitude: 7.615,
    longitude: 100.003,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "นาท่อม",
    zipcode: 93000,
    latitude: 7.585,
    longitude: 100.002,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "ปรางหมู่",
    zipcode: 93000,
    latitude: 7.647,
    longitude: 100.068,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "ท่าแค",
    zipcode: 93000,
    latitude: 7.545,
    longitude: 100.05,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "ลำปำ",
    zipcode: 93000,
    latitude: 7.651,
    longitude: 100.141,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "ตำนาน",
    zipcode: 93000,
    latitude: 7.572,
    longitude: 100.083,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "ควนมะพร้าว",
    zipcode: 93000,
    latitude: 7.588,
    longitude: 100.124,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "ร่มเมือง",
    zipcode: 93000,
    latitude: 7.556,
    longitude: 100.008,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "ชัยบุรี",
    zipcode: 93000,
    latitude: 7.697,
    longitude: 100.093,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "นาโหนด",
    zipcode: 93000,
    latitude: 7.507,
    longitude: 100.061,
  },
  {
    province: "พัทลุง",
    district: "เมืองพัทลุง",
    subdistrict: "พญาขัน",
    zipcode: 93000,
    latitude: 7.652,
    longitude: 100.107,
  },
  {
    province: "พัทลุง",
    district: "กงหรา",
    subdistrict: "กงหรา",
    zipcode: 93180,
    latitude: 7.463,
    longitude: 99.877,
  },
  {
    province: "พัทลุง",
    district: "กงหรา",
    subdistrict: "ชะรัด",
    zipcode: 93000,
    latitude: 7.473,
    longitude: 99.989,
  },
  {
    province: "พัทลุง",
    district: "กงหรา",
    subdistrict: "คลองเฉลิม",
    zipcode: 93180,
    latitude: 7.349,
    longitude: 99.958,
  },
  {
    province: "พัทลุง",
    district: "กงหรา",
    subdistrict: "คลองทรายขาว",
    zipcode: 93180,
    latitude: 7.423,
    longitude: 99.921,
  },
  {
    province: "พัทลุง",
    district: "กงหรา",
    subdistrict: "สมหวัง",
    zipcode: 93000,
    latitude: 7.509,
    longitude: 99.99,
  },
  {
    province: "พัทลุง",
    district: "เขาชัยสน",
    subdistrict: "เขาชัยสน",
    zipcode: 93130,
    latitude: 7.433,
    longitude: 100.111,
  },
  {
    province: "พัทลุง",
    district: "เขาชัยสน",
    subdistrict: "ควนขนุน",
    zipcode: 93130,
    latitude: 7.491,
    longitude: 100.095,
  },
  {
    province: "พัทลุง",
    district: "เขาชัยสน",
    subdistrict: "จองถนน",
    zipcode: 93130,
    latitude: 7.489,
    longitude: 100.194,
  },
  {
    province: "พัทลุง",
    district: "เขาชัยสน",
    subdistrict: "หานโพธิ์",
    zipcode: 93130,
    latitude: 7.525,
    longitude: 100.145,
  },
  {
    province: "พัทลุง",
    district: "เขาชัยสน",
    subdistrict: "โคกม่วง",
    zipcode: 93130,
    latitude: 7.389,
    longitude: 100.067,
  },
  {
    province: "พัทลุง",
    district: "ตะโหมด",
    subdistrict: "แม่ขรี",
    zipcode: 93160,
    latitude: 7.338,
    longitude: 100.097,
  },
  {
    province: "พัทลุง",
    district: "ตะโหมด",
    subdistrict: "ตะโหมด",
    zipcode: 93160,
    latitude: 7.278,
    longitude: 100.007,
  },
  {
    province: "พัทลุง",
    district: "ตะโหมด",
    subdistrict: "คลองใหญ่",
    zipcode: 93160,
    latitude: 7.26,
    longitude: 100.074,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "ควนขนุน",
    zipcode: 93110,
    latitude: 7.756,
    longitude: 100.003,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "ทะเลน้อย",
    zipcode: 93150,
    latitude: 7.824,
    longitude: 100.145,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "นาขยาด",
    zipcode: 93110,
    latitude: 7.687,
    longitude: 99.948,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "พนมวังก์",
    zipcode: 93110,
    latitude: 7.684,
    longitude: 100.023,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "แหลมโตนด",
    zipcode: 93110,
    latitude: 7.826,
    longitude: 100.03,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "ปันแต",
    zipcode: 93110,
    latitude: 7.783,
    longitude: 100.051,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "โตนดด้วน",
    zipcode: 93110,
    latitude: 7.718,
    longitude: 100.036,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "ดอนทราย",
    zipcode: 93110,
    latitude: 7.716,
    longitude: 99.975,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "มะกอกเหนือ",
    zipcode: 93150,
    latitude: 7.746,
    longitude: 100.073,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "พนางตุง",
    zipcode: 93150,
    latitude: 7.759,
    longitude: 100.14,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "ชะมวง",
    zipcode: 93110,
    latitude: 7.765,
    longitude: 99.956,
  },
  {
    province: "พัทลุง",
    district: "ควนขนุน",
    subdistrict: "แพรกหา",
    zipcode: 93110,
    latitude: 7.654,
    longitude: 100.01,
  },
  {
    province: "พัทลุง",
    district: "ปากพะยูน",
    subdistrict: "ปากพะยูน",
    zipcode: 93120,
    latitude: 7.33,
    longitude: 100.336,
  },
  {
    province: "พัทลุง",
    district: "ปากพะยูน",
    subdistrict: "ดอนประดู่",
    zipcode: 93120,
    latitude: 7.279,
    longitude: 100.318,
  },
  {
    province: "พัทลุง",
    district: "ปากพะยูน",
    subdistrict: "เกาะนางคำ",
    zipcode: 93120,
    latitude: 7.343,
    longitude: 100.389,
  },
  {
    province: "พัทลุง",
    district: "ปากพะยูน",
    subdistrict: "เกาะหมาก",
    zipcode: 93120,
    latitude: 7.435,
    longitude: 100.329,
  },
  {
    province: "พัทลุง",
    district: "ปากพะยูน",
    subdistrict: "ฝาละมี",
    zipcode: 93120,
    latitude: 7.371,
    longitude: 100.261,
  },
  {
    province: "พัทลุง",
    district: "ปากพะยูน",
    subdistrict: "หารเทา",
    zipcode: 93120,
    latitude: 7.288,
    longitude: 100.267,
  },
  {
    province: "พัทลุง",
    district: "ปากพะยูน",
    subdistrict: "ดอนทราย",
    zipcode: 93120,
    latitude: 7.244,
    longitude: 100.321,
  },
  {
    province: "พัทลุง",
    district: "ศรีบรรพต",
    subdistrict: "เขาย่า",
    zipcode: 93190,
    latitude: 7.746,
    longitude: 99.864,
  },
  {
    province: "พัทลุง",
    district: "ศรีบรรพต",
    subdistrict: "เขาปู่",
    zipcode: 93190,
    latitude: 7.701,
    longitude: 99.833,
  },
  {
    province: "พัทลุง",
    district: "ศรีบรรพต",
    subdistrict: "ตะแพน",
    zipcode: 93190,
    latitude: 7.645,
    longitude: 99.878,
  },
  {
    province: "พัทลุง",
    district: "ป่าบอน",
    subdistrict: "ป่าบอน",
    zipcode: 93170,
    latitude: 7.337,
    longitude: 100.171,
  },
  {
    province: "พัทลุง",
    district: "ป่าบอน",
    subdistrict: "โคกทราย",
    zipcode: 93170,
    latitude: 7.229,
    longitude: 100.229,
  },
  {
    province: "พัทลุง",
    district: "ป่าบอน",
    subdistrict: "หนองธง",
    zipcode: 93170,
    latitude: 7.162,
    longitude: 100.008,
  },
  {
    province: "พัทลุง",
    district: "ป่าบอน",
    subdistrict: "ทุ่งนารี",
    zipcode: 93170,
    latitude: 7.191,
    longitude: 100.13,
  },
  {
    province: "พัทลุง",
    district: "ป่าบอน",
    subdistrict: "วังใหม่",
    zipcode: 93170,
    latitude: 7.318,
    longitude: 100.23,
  },
  {
    province: "พัทลุง",
    district: "บางแก้ว",
    subdistrict: "ท่ามะเดื่อ",
    zipcode: 93140,
    latitude: 7.451,
    longitude: 100.157,
  },
  {
    province: "พัทลุง",
    district: "บางแก้ว",
    subdistrict: "นาปะขอ",
    zipcode: 93140,
    latitude: 7.435,
    longitude: 100.197,
  },
  {
    province: "พัทลุง",
    district: "บางแก้ว",
    subdistrict: "โคกสัก",
    zipcode: 93140,
    latitude: 7.39,
    longitude: 100.145,
  },
  {
    province: "พัทลุง",
    district: "ป่าพะยอม",
    subdistrict: "ป่าพะยอม",
    zipcode: 93110,
    latitude: 7.86,
    longitude: 99.947,
  },
  {
    province: "พัทลุง",
    district: "ป่าพะยอม",
    subdistrict: "ลานข่อย",
    zipcode: 93110,
    latitude: 7.831,
    longitude: 99.78,
  },
  {
    province: "พัทลุง",
    district: "ป่าพะยอม",
    subdistrict: "เกาะเต่า",
    zipcode: 93110,
    latitude: 7.783,
    longitude: 99.799,
  },
  {
    province: "พัทลุง",
    district: "ป่าพะยอม",
    subdistrict: "บ้านพร้าว",
    zipcode: 93110,
    latitude: 7.818,
    longitude: 99.948,
  },
  {
    province: "พัทลุง",
    district: "ศรีนครินทร์",
    subdistrict: "ชุมพล",
    zipcode: 93000,
    latitude: 7.608,
    longitude: 99.948,
  },
  {
    province: "พัทลุง",
    district: "ศรีนครินทร์",
    subdistrict: "บ้านนา",
    zipcode: 93000,
    latitude: 7.559,
    longitude: 99.868,
  },
  {
    province: "พัทลุง",
    district: "ศรีนครินทร์",
    subdistrict: "อ่างทอง",
    zipcode: 93000,
    latitude: 7.554,
    longitude: 99.971,
  },
  {
    province: "พัทลุง",
    district: "ศรีนครินทร์",
    subdistrict: "ลำสินธุ์",
    zipcode: 93000,
    latitude: 7.495,
    longitude: 99.875,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "สะบารัง",
    zipcode: 94000,
    latitude: 6.876,
    longitude: 101.251,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "อาเนาะรู",
    zipcode: 94000,
    latitude: 6.87,
    longitude: 101.256,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "จะบังติกอ",
    zipcode: 94000,
    latitude: 6.857,
    longitude: 101.251,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "บานา",
    zipcode: 94000,
    latitude: 6.866,
    longitude: 101.289,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "ตันหยงลุโละ",
    zipcode: 94000,
    latitude: 6.877,
    longitude: 101.305,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "คลองมานิง",
    zipcode: 94000,
    latitude: 6.845,
    longitude: 101.293,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "กะมิยอ",
    zipcode: 94000,
    latitude: 6.854,
    longitude: 101.318,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "บาราโหม",
    zipcode: 94000,
    latitude: 6.873,
    longitude: 101.317,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "ปะกาฮะรัง",
    zipcode: 94000,
    latitude: 6.833,
    longitude: 101.232,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "รูสะมิแล",
    zipcode: 94000,
    latitude: 6.864,
    longitude: 101.207,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "ตะลุโบะ",
    zipcode: 94000,
    latitude: 6.853,
    longitude: 101.267,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "บาราเฮาะ",
    zipcode: 94000,
    latitude: 6.829,
    longitude: 101.267,
  },
  {
    province: "ปัตตานี",
    district: "เมืองปัตตานี",
    subdistrict: "ปุยุด",
    zipcode: 94000,
    latitude: 6.811,
    longitude: 101.268,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "โคกโพธิ์",
    zipcode: 94120,
    latitude: 6.732,
    longitude: 101.061,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "มะกรูด",
    zipcode: 94120,
    latitude: 6.738,
    longitude: 101.119,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "บางโกระ",
    zipcode: 94120,
    latitude: 6.76,
    longitude: 101.108,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "ป่าบอน",
    zipcode: 94120,
    latitude: 6.701,
    longitude: 101.116,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "ทรายขาว",
    zipcode: 94120,
    latitude: 6.665,
    longitude: 101.096,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "นาประดู่",
    zipcode: 94180,
    latitude: 6.689,
    longitude: 101.142,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "ปากล่อ",
    zipcode: 94180,
    latitude: 6.62,
    longitude: 101.187,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "ทุ่งพลา",
    zipcode: 94180,
    latitude: 6.641,
    longitude: 101.152,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "ท่าเรือ",
    zipcode: 94120,
    latitude: 6.78,
    longitude: 101.053,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "นาเกตุ",
    zipcode: 94120,
    latitude: 6.744,
    longitude: 101.155,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "ควนโนรี",
    zipcode: 94180,
    latitude: 6.719,
    longitude: 101.199,
  },
  {
    province: "ปัตตานี",
    district: "โคกโพธิ์",
    subdistrict: "ช้างให้ตก",
    zipcode: 94120,
    latitude: 6.672,
    longitude: 101.062,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "เกาะเปาะ",
    zipcode: 94170,
    latitude: 6.815,
    longitude: 101.201,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "คอลอตันหยง",
    zipcode: 94170,
    latitude: 6.744,
    longitude: 101.222,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "ดอนรัก",
    zipcode: 94170,
    latitude: 6.848,
    longitude: 101.22,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "ดาโต๊ะ",
    zipcode: 94170,
    latitude: 6.717,
    longitude: 101.244,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "ตุยง",
    zipcode: 94170,
    latitude: 6.832,
    longitude: 101.178,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "ท่ากำชำ",
    zipcode: 94170,
    latitude: 6.826,
    longitude: 101.072,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "บ่อทอง",
    zipcode: 94170,
    latitude: 6.789,
    longitude: 101.135,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "บางเขา",
    zipcode: 94170,
    latitude: 6.834,
    longitude: 101.13,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "บางตาวา",
    zipcode: 94170,
    latitude: 6.858,
    longitude: 101.158,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "ปุโละปุโย",
    zipcode: 94170,
    latitude: 6.774,
    longitude: 101.194,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "ยาบี",
    zipcode: 94170,
    latitude: 6.782,
    longitude: 101.246,
  },
  {
    province: "ปัตตานี",
    district: "หนองจิก",
    subdistrict: "ลิปะสะโง",
    zipcode: 94170,
    latitude: 6.805,
    longitude: 101.231,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "ปะนาเระ",
    zipcode: 94130,
    latitude: 6.86,
    longitude: 101.484,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "ท่าข้าม",
    zipcode: 94130,
    latitude: 6.838,
    longitude: 101.477,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "บ้านนอก",
    zipcode: 94130,
    latitude: 6.812,
    longitude: 101.479,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "ดอน",
    zipcode: 94130,
    latitude: 6.786,
    longitude: 101.482,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "ควน",
    zipcode: 94190,
    latitude: 6.762,
    longitude: 101.493,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "ท่าน้ำ",
    zipcode: 94130,
    latitude: 6.771,
    longitude: 101.515,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "คอกกระบือ",
    zipcode: 94130,
    latitude: 6.8,
    longitude: 101.52,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "พ่อมิ่ง",
    zipcode: 94130,
    latitude: 6.786,
    longitude: 101.554,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "บ้านกลาง",
    zipcode: 94130,
    latitude: 6.839,
    longitude: 101.521,
  },
  {
    province: "ปัตตานี",
    district: "ปะนาเระ",
    subdistrict: "บ้านน้ำบ่อ",
    zipcode: 94130,
    latitude: 6.817,
    longitude: 101.563,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "มายอ",
    zipcode: 94140,
    latitude: 6.71,
    longitude: 101.425,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "ถนน",
    zipcode: 94140,
    latitude: 6.75,
    longitude: 101.419,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "ตรัง",
    zipcode: 94140,
    latitude: 6.693,
    longitude: 101.375,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "กระหวะ",
    zipcode: 94140,
    latitude: 6.759,
    longitude: 101.441,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "ลุโบะยิไร",
    zipcode: 94140,
    latitude: 6.666,
    longitude: 101.417,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "ลางา",
    zipcode: 94190,
    latitude: 6.735,
    longitude: 101.478,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "กระเสาะ",
    zipcode: 94140,
    latitude: 6.749,
    longitude: 101.397,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "เกาะจัน",
    zipcode: 94140,
    latitude: 6.723,
    longitude: 101.382,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "ปะโด",
    zipcode: 94140,
    latitude: 6.666,
    longitude: 101.359,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "สาคอบน",
    zipcode: 94140,
    latitude: 6.73,
    longitude: 101.352,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "สาคอใต้",
    zipcode: 94140,
    latitude: 6.749,
    longitude: 101.357,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "สะกำ",
    zipcode: 94140,
    latitude: 6.72,
    longitude: 101.462,
  },
  {
    province: "ปัตตานี",
    district: "มายอ",
    subdistrict: "ปานัน",
    zipcode: 94140,
    latitude: 6.721,
    longitude: 101.334,
  },
  {
    province: "ปัตตานี",
    district: "ทุ่งยางแดง",
    subdistrict: "ตะโละแมะนา",
    zipcode: 94140,
    latitude: 6.604,
    longitude: 101.401,
  },
  {
    province: "ปัตตานี",
    district: "ทุ่งยางแดง",
    subdistrict: "พิเทน",
    zipcode: 94140,
    latitude: 6.679,
    longitude: 101.467,
  },
  {
    province: "ปัตตานี",
    district: "ทุ่งยางแดง",
    subdistrict: "น้ำดำ",
    zipcode: 94140,
    latitude: 6.605,
    longitude: 101.448,
  },
  {
    province: "ปัตตานี",
    district: "ทุ่งยางแดง",
    subdistrict: "ปากู",
    zipcode: 94140,
    latitude: 6.624,
    longitude: 101.474,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "ตะลุบัน",
    zipcode: 94110,
    latitude: 6.6908289,
    longitude: 101.617146,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "ตะบิ้ง",
    zipcode: 94110,
    latitude: 6.681,
    longitude: 101.589,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "ปะเสยะวอ",
    zipcode: 94110,
    latitude: 6.733,
    longitude: 101.606,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "บางเก่า",
    zipcode: 94110,
    latitude: 6.764,
    longitude: 101.591,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "บือเระ",
    zipcode: 94110,
    latitude: 6.704,
    longitude: 101.584,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "เตราะบอน",
    zipcode: 94110,
    latitude: 6.688,
    longitude: 101.543,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "กะดุนง",
    zipcode: 94110,
    latitude: 6.646,
    longitude: 101.554,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "ละหาร",
    zipcode: 94110,
    latitude: 6.659,
    longitude: 101.627,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "มะนังดาลำ",
    zipcode: 94110,
    latitude: 6.649,
    longitude: 101.599,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "แป้น",
    zipcode: 94110,
    latitude: 6.747,
    longitude: 101.553,
  },
  {
    province: "ปัตตานี",
    district: "สายบุรี",
    subdistrict: "ทุ่งคล้า",
    zipcode: 94190,
    latitude: 6.718,
    longitude: 101.528,
  },
  {
    province: "ปัตตานี",
    district: "ไม้แก่น",
    subdistrict: "ไทรทอง",
    zipcode: 94220,
    latitude: 6.604,
    longitude: 101.673,
  },
  {
    province: "ปัตตานี",
    district: "ไม้แก่น",
    subdistrict: "ไม้แก่น",
    zipcode: 94220,
    latitude: 6.647,
    longitude: 101.669,
  },
  {
    province: "ปัตตานี",
    district: "ไม้แก่น",
    subdistrict: "ตะโละไกรทอง",
    zipcode: 94220,
    latitude: 6.627,
    longitude: 101.64,
  },
  {
    province: "ปัตตานี",
    district: "ไม้แก่น",
    subdistrict: "ดอนทราย",
    zipcode: 94220,
    latitude: 6.6052307,
    longitude: 101.6624479,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ตะโละ",
    zipcode: 94150,
    latitude: 6.782,
    longitude: 101.353,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ตะโละกาโปร์",
    zipcode: 94150,
    latitude: 6.872,
    longitude: 101.43,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ตันหยงดาลอ",
    zipcode: 94150,
    latitude: 6.808,
    longitude: 101.423,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ตันหยงจึงงา",
    zipcode: 94190,
    latitude: 6.787,
    longitude: 101.458,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ตอหลัง",
    zipcode: 94150,
    latitude: 6.802,
    longitude: 101.448,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ตาแกะ",
    zipcode: 94150,
    latitude: 6.844,
    longitude: 101.336,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ตาลีอายร์",
    zipcode: 94150,
    latitude: 6.789,
    longitude: 101.389,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ยามู",
    zipcode: 94150,
    latitude: 6.855,
    longitude: 101.372,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "บางปู",
    zipcode: 94150,
    latitude: 6.868,
    longitude: 101.335,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "หนองแรต",
    zipcode: 94150,
    latitude: 6.849,
    longitude: 101.403,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ปิยามุมัง",
    zipcode: 94150,
    latitude: 6.823,
    longitude: 101.376,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ปุลากง",
    zipcode: 94150,
    latitude: 6.769,
    longitude: 101.391,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "บาโลย",
    zipcode: 94190,
    latitude: 6.781,
    longitude: 101.439,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "สาบัน",
    zipcode: 94150,
    latitude: 6.82,
    longitude: 101.439,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "มะนังยง",
    zipcode: 94150,
    latitude: 6.813,
    longitude: 101.402,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "ราตาปันยัง",
    zipcode: 94150,
    latitude: 6.822,
    longitude: 101.353,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "จะรัง",
    zipcode: 94150,
    latitude: 6.843,
    longitude: 101.45,
  },
  {
    province: "ปัตตานี",
    district: "ยะหริ่ง",
    subdistrict: "แหลมโพธิ์",
    zipcode: 94150,
    latitude: 6.942,
    longitude: 101.296,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "ยะรัง",
    zipcode: 94160,
    latitude: 6.769,
    longitude: 101.299,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "สะดาวา",
    zipcode: 94160,
    latitude: 6.821,
    longitude: 101.311,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "ประจัน",
    zipcode: 94160,
    latitude: 6.797,
    longitude: 101.289,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "สะนอ",
    zipcode: 94160,
    latitude: 6.789,
    longitude: 101.323,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "ระแว้ง",
    zipcode: 94160,
    latitude: 6.755,
    longitude: 101.324,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "ปิตูมุดี",
    zipcode: 94160,
    latitude: 6.743,
    longitude: 101.284,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "วัด",
    zipcode: 94160,
    latitude: 6.735,
    longitude: 101.308,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "กระโด",
    zipcode: 94160,
    latitude: 6.717,
    longitude: 101.299,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "คลองใหม่",
    zipcode: 94160,
    latitude: 6.735,
    longitude: 101.262,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "เมาะมาวี",
    zipcode: 94160,
    latitude: 6.67,
    longitude: 101.303,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "กอลำ",
    zipcode: 94160,
    latitude: 6.673,
    longitude: 101.329,
  },
  {
    province: "ปัตตานี",
    district: "ยะรัง",
    subdistrict: "เขาตูม",
    zipcode: 94160,
    latitude: 6.603,
    longitude: 101.314,
  },
  {
    province: "ปัตตานี",
    district: "กะพ้อ",
    subdistrict: "กะรุบี",
    zipcode: 94230,
    latitude: 6.57,
    longitude: 101.556,
  },
  {
    province: "ปัตตานี",
    district: "กะพ้อ",
    subdistrict: "ตะโละดือรามัน",
    zipcode: 94230,
    latitude: 6.603,
    longitude: 101.554,
  },
  {
    province: "ปัตตานี",
    district: "กะพ้อ",
    subdistrict: "ปล่องหอย",
    zipcode: 94230,
    latitude: 6.638,
    longitude: 101.509,
  },
  {
    province: "ปัตตานี",
    district: "แม่ลาน",
    subdistrict: "แม่ลาน",
    zipcode: 94180,
    latitude: 6.647,
    longitude: 101.231,
  },
  {
    province: "ปัตตานี",
    district: "แม่ลาน",
    subdistrict: "ม่วงเตี้ย",
    zipcode: 94180,
    latitude: 6.677,
    longitude: 101.266,
  },
  {
    province: "ปัตตานี",
    district: "แม่ลาน",
    subdistrict: "ป่าไร่",
    zipcode: 94180,
    latitude: 6.685,
    longitude: 101.214,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "สะเตง",
    zipcode: 95000,
    latitude: 6.547,
    longitude: 101.28,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "บุดี",
    zipcode: 95000,
    latitude: 6.487,
    longitude: 101.305,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "ยุโป",
    zipcode: 95000,
    latitude: 6.597,
    longitude: 101.283,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "ลิดล",
    zipcode: 95160,
    latitude: 6.551,
    longitude: 101.166,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "ยะลา",
    zipcode: 95000,
    latitude: 6.523,
    longitude: 101.181,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "ท่าสาป",
    zipcode: 95000,
    latitude: 6.538,
    longitude: 101.235,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "ลำใหม่",
    zipcode: 95160,
    latitude: 6.583,
    longitude: 101.205,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "หน้าถ้ำ",
    zipcode: 95000,
    latitude: 6.525,
    longitude: 101.225,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "ลำพะยา",
    zipcode: 95160,
    latitude: 6.584,
    longitude: 101.162,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "เปาะเส้ง",
    zipcode: 95000,
    latitude: 6.511,
    longitude: 101.201,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "พร่อน",
    zipcode: 95160,
    latitude: 6.563,
    longitude: 101.229,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "บันนังสาเรง",
    zipcode: 95000,
    latitude: 6.48,
    longitude: 101.258,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "สะเตงนอก",
    zipcode: 95000,
    latitude: 6.552,
    longitude: 101.324,
  },
  {
    province: "ยะลา",
    district: "เมืองยะลา",
    subdistrict: "ตาเซะ",
    zipcode: 95000,
    latitude: 6.62,
    longitude: 101.253,
  },
  {
    province: "ยะลา",
    district: "เบตง",
    subdistrict: "เบตง",
    zipcode: 95110,
    latitude: 5.803,
    longitude: 101.009,
  },
  {
    province: "ยะลา",
    district: "เบตง",
    subdistrict: "ยะรม",
    zipcode: 95110,
    latitude: 5.771,
    longitude: 101.203,
  },
  {
    province: "ยะลา",
    district: "เบตง",
    subdistrict: "ตาเนาะแมเราะ",
    zipcode: 95110,
    latitude: 5.853,
    longitude: 101.099,
  },
  {
    province: "ยะลา",
    district: "เบตง",
    subdistrict: "อัยเยอร์เวง",
    zipcode: 95110,
    latitude: 5.963,
    longitude: 101.398,
  },
  {
    province: "ยะลา",
    district: "เบตง",
    subdistrict: "ธารน้ำทิพย์",
    zipcode: 95110,
    latitude: 5.686,
    longitude: 101.141,
  },
  {
    province: "ยะลา",
    district: "บันนังสตา",
    subdistrict: "บันนังสตา",
    zipcode: 95130,
    latitude: 6.25,
    longitude: 101.233,
  },
  {
    province: "ยะลา",
    district: "บันนังสตา",
    subdistrict: "บาเจาะ",
    zipcode: 95130,
    latitude: 6.212,
    longitude: 101.291,
  },
  {
    province: "ยะลา",
    district: "บันนังสตา",
    subdistrict: "ตาเนาะปูเต๊ะ",
    zipcode: 95130,
    latitude: 6.355,
    longitude: 101.327,
  },
  {
    province: "ยะลา",
    district: "บันนังสตา",
    subdistrict: "ถ้ำทะลุ",
    zipcode: 95130,
    latitude: 6.238,
    longitude: 101.147,
  },
  {
    province: "ยะลา",
    district: "บันนังสตา",
    subdistrict: "ตลิ่งชัน",
    zipcode: 95130,
    latitude: 6.271,
    longitude: 101.352,
  },
  {
    province: "ยะลา",
    district: "บันนังสตา",
    subdistrict: "เขื่อนบางลาง",
    zipcode: 95130,
    latitude: 6.142,
    longitude: 101.309,
  },
  {
    province: "ยะลา",
    district: "ธารโต",
    subdistrict: "ธารโต",
    zipcode: 95150,
    latitude: 6.167,
    longitude: 101.187,
  },
  {
    province: "ยะลา",
    district: "ธารโต",
    subdistrict: "บ้านแหร",
    zipcode: 95150,
    latitude: 6.105,
    longitude: 101.204,
  },
  {
    province: "ยะลา",
    district: "ธารโต",
    subdistrict: "แม่หวาด",
    zipcode: 95170,
    latitude: 6.102,
    longitude: 101.384,
  },
  {
    province: "ยะลา",
    district: "ธารโต",
    subdistrict: "คีรีเขต",
    zipcode: 95150,
    latitude: 6.153,
    longitude: 101.116,
  },
  {
    province: "ยะลา",
    district: "ยะหา",
    subdistrict: "ยะหา",
    zipcode: 95120,
    latitude: 6.504,
    longitude: 101.125,
  },
  {
    province: "ยะลา",
    district: "ยะหา",
    subdistrict: "ละแอ",
    zipcode: 95120,
    latitude: 6.466,
    longitude: 101.186,
  },
  {
    province: "ยะลา",
    district: "ยะหา",
    subdistrict: "ปะแต",
    zipcode: 95120,
    latitude: 6.344,
    longitude: 101.139,
  },
  {
    province: "ยะลา",
    district: "ยะหา",
    subdistrict: "บาโร๊ะ",
    zipcode: 95120,
    latitude: 6.457,
    longitude: 101.133,
  },
  {
    province: "ยะลา",
    district: "ยะหา",
    subdistrict: "ตาชี",
    zipcode: 95120,
    latitude: 6.563,
    longitude: 101.115,
  },
  {
    province: "ยะลา",
    district: "ยะหา",
    subdistrict: "บาโงยซิแน",
    zipcode: 95120,
    latitude: 6.527,
    longitude: 101.153,
  },
  {
    province: "ยะลา",
    district: "ยะหา",
    subdistrict: "กาตอง",
    zipcode: 95120,
    latitude: 6.502,
    longitude: 101.063,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "กายูบอเกาะ",
    zipcode: 95140,
    latitude: 6.476,
    longitude: 101.413,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "กาลูปัง",
    zipcode: 95140,
    latitude: 6.464,
    longitude: 101.374,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "กาลอ",
    zipcode: 95140,
    latitude: 6.378,
    longitude: 101.383,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "กอตอตือร๊ะ",
    zipcode: 95140,
    latitude: 6.5,
    longitude: 101.389,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "โกตาบารู",
    zipcode: 95140,
    latitude: 6.452,
    longitude: 101.345,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "เกะรอ",
    zipcode: 95140,
    latitude: 6.535,
    longitude: 101.576,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "จะกว๊ะ",
    zipcode: 95140,
    latitude: 6.498,
    longitude: 101.536,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "ท่าธง",
    zipcode: 95140,
    latitude: 6.565,
    longitude: 101.458,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "เนินงาม",
    zipcode: 95140,
    latitude: 6.508,
    longitude: 101.347,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "บาลอ",
    zipcode: 95140,
    latitude: 6.448,
    longitude: 101.445,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "บาโงย",
    zipcode: 95140,
    latitude: 6.473,
    longitude: 101.349,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "บือมัง",
    zipcode: 95140,
    latitude: 6.411,
    longitude: 101.336,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "ยะต๊ะ",
    zipcode: 95140,
    latitude: 6.426,
    longitude: 101.407,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "วังพญา",
    zipcode: 95140,
    latitude: 6.544,
    longitude: 101.375,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "อาซ่อง",
    zipcode: 95140,
    latitude: 6.52,
    longitude: 101.451,
  },
  {
    province: "ยะลา",
    district: "รามัน",
    subdistrict: "ตะโล๊ะหะลอ",
    zipcode: 95140,
    latitude: 6.479,
    longitude: 101.485,
  },
  {
    province: "ยะลา",
    district: "กาบัง",
    subdistrict: "กาบัง",
    zipcode: 95120,
    latitude: 6.449,
    longitude: 101,
  },
  {
    province: "ยะลา",
    district: "กาบัง",
    subdistrict: "บาละ",
    zipcode: 95120,
    latitude: 6.33,
    longitude: 101.023,
  },
  {
    province: "ยะลา",
    district: "กรงปินัง",
    subdistrict: "กรงปินัง",
    zipcode: 95000,
    latitude: 6.3921761,
    longitude: 101.1790176,
  },
  {
    province: "ยะลา",
    district: "กรงปินัง",
    subdistrict: "สะเอะ",
    zipcode: 95000,
    latitude: 6.3921761,
    longitude: 101.1688482,
  },
  {
    province: "ยะลา",
    district: "กรงปินัง",
    subdistrict: "ห้วยกระทิง",
    zipcode: 95000,
    latitude: 6.3921558,
    longitude: 101.1789901,
  },
  {
    province: "ยะลา",
    district: "กรงปินัง",
    subdistrict: "ปุโรง",
    zipcode: 95000,
    latitude: 6.3927077,
    longitude: 101.1718152,
  },
  {
    province: "นราธิวาส",
    district: "เมืองนราธิวาส",
    subdistrict: "บางนาค",
    zipcode: 96000,
    latitude: 6.424,
    longitude: 101.82,
  },
  {
    province: "นราธิวาส",
    district: "เมืองนราธิวาส",
    subdistrict: "ลำภู",
    zipcode: 96000,
    latitude: 6.379,
    longitude: 101.819,
  },
  {
    province: "นราธิวาส",
    district: "เมืองนราธิวาส",
    subdistrict: "มะนังตายอ",
    zipcode: 96000,
    latitude: 6.354,
    longitude: 101.76,
  },
  {
    province: "นราธิวาส",
    district: "เมืองนราธิวาส",
    subdistrict: "บางปอ",
    zipcode: 96000,
    latitude: 6.33,
    longitude: 101.804,
  },
  {
    province: "นราธิวาส",
    district: "เมืองนราธิวาส",
    subdistrict: "กะลุวอ",
    zipcode: 96000,
    latitude: 6.342,
    longitude: 101.874,
  },
  {
    province: "นราธิวาส",
    district: "เมืองนราธิวาส",
    subdistrict: "กะลุวอเหนือ",
    zipcode: 96000,
    latitude: 6.404,
    longitude: 101.862,
  },
  {
    province: "นราธิวาส",
    district: "เมืองนราธิวาส",
    subdistrict: "โคกเคียน",
    zipcode: 96000,
    latitude: 6.482,
    longitude: 101.763,
  },
  {
    province: "นราธิวาส",
    district: "ตากใบ",
    subdistrict: "เจ๊ะเห",
    zipcode: 96110,
    latitude: 6.257,
    longitude: 102.051,
  },
  {
    province: "นราธิวาส",
    district: "ตากใบ",
    subdistrict: "ไพรวัน",
    zipcode: 96110,
    latitude: 6.3,
    longitude: 101.958,
  },
  {
    province: "นราธิวาส",
    district: "ตากใบ",
    subdistrict: "พร่อน",
    zipcode: 96110,
    latitude: 6.202,
    longitude: 102.014,
  },
  {
    province: "นราธิวาส",
    district: "ตากใบ",
    subdistrict: "ศาลาใหม่",
    zipcode: 96110,
    latitude: 6.282,
    longitude: 102.008,
  },
  {
    province: "นราธิวาส",
    district: "ตากใบ",
    subdistrict: "บางขุนทอง",
    zipcode: 96110,
    latitude: 6.234,
    longitude: 101.967,
  },
  {
    province: "นราธิวาส",
    district: "ตากใบ",
    subdistrict: "เกาะสะท้อน",
    zipcode: 96110,
    latitude: 6.22,
    longitude: 102.059,
  },
  {
    province: "นราธิวาส",
    district: "ตากใบ",
    subdistrict: "นานาค",
    zipcode: 96110,
    latitude: 6.142,
    longitude: 102.07,
  },
  {
    province: "นราธิวาส",
    district: "ตากใบ",
    subdistrict: "โฆษิต",
    zipcode: 96110,
    latitude: 6.175,
    longitude: 102.052,
  },
  {
    province: "นราธิวาส",
    district: "บาเจาะ",
    subdistrict: "บาเจาะ",
    zipcode: 96170,
    latitude: 6.514,
    longitude: 101.636,
  },
  {
    province: "นราธิวาส",
    district: "บาเจาะ",
    subdistrict: "ลุโบะสาวอ",
    zipcode: 96170,
    latitude: 6.486,
    longitude: 101.667,
  },
  {
    province: "นราธิวาส",
    district: "บาเจาะ",
    subdistrict: "กาเยาะมาตี",
    zipcode: 96170,
    latitude: 6.545,
    longitude: 101.625,
  },
  {
    province: "นราธิวาส",
    district: "บาเจาะ",
    subdistrict: "ปะลุกาสาเมาะ",
    zipcode: 96170,
    latitude: 6.608,
    longitude: 101.609,
  },
  {
    province: "นราธิวาส",
    district: "บาเจาะ",
    subdistrict: "บาเระเหนือ",
    zipcode: 96170,
    latitude: 6.571,
    longitude: 101.613,
  },
  {
    province: "นราธิวาส",
    district: "บาเจาะ",
    subdistrict: "บาเระใต้",
    zipcode: 96170,
    latitude: 6.539,
    longitude: 101.693,
  },
  {
    province: "นราธิวาส",
    district: "ยี่งอ",
    subdistrict: "ยี่งอ",
    zipcode: 96180,
    latitude: 6.382,
    longitude: 101.703,
  },
  {
    province: "นราธิวาส",
    district: "ยี่งอ",
    subdistrict: "ละหาร",
    zipcode: 96180,
    latitude: 6.396,
    longitude: 101.745,
  },
  {
    province: "นราธิวาส",
    district: "ยี่งอ",
    subdistrict: "จอเบาะ",
    zipcode: 96180,
    latitude: 6.382,
    longitude: 101.66,
  },
  {
    province: "นราธิวาส",
    district: "ยี่งอ",
    subdistrict: "ลุโบะบายะ",
    zipcode: 96180,
    latitude: 6.422,
    longitude: 101.663,
  },
  {
    province: "นราธิวาส",
    district: "ยี่งอ",
    subdistrict: "ลุโบะบือซา",
    zipcode: 96180,
    latitude: 6.43,
    longitude: 101.725,
  },
  {
    province: "นราธิวาส",
    district: "ยี่งอ",
    subdistrict: "ตะปอเยาะ",
    zipcode: 96180,
    latitude: 6.457,
    longitude: 101.663,
  },
  {
    province: "นราธิวาส",
    district: "ระแงะ",
    subdistrict: "ตันหยงมัส",
    zipcode: 96130,
    latitude: 6.311,
    longitude: 101.724,
  },
  {
    province: "นราธิวาส",
    district: "ระแงะ",
    subdistrict: "ตันหยงลิมอ",
    zipcode: 96130,
    latitude: 6.283,
    longitude: 101.775,
  },
  {
    province: "นราธิวาส",
    district: "ระแงะ",
    subdistrict: "บองอ",
    zipcode: 96220,
    latitude: 6.172,
    longitude: 101.753,
  },
  {
    province: "นราธิวาส",
    district: "ระแงะ",
    subdistrict: "กาลิซา",
    zipcode: 96130,
    latitude: 6.2257747,
    longitude: 101.6185085,
  },
  {
    province: "นราธิวาส",
    district: "ระแงะ",
    subdistrict: "บาโงสะโต",
    zipcode: 96130,
    latitude: 6.277,
    longitude: 101.691,
  },
  {
    province: "นราธิวาส",
    district: "ระแงะ",
    subdistrict: "เฉลิม",
    zipcode: 96130,
    latitude: 6.291,
    longitude: 101.641,
  },
  {
    province: "นราธิวาส",
    district: "ระแงะ",
    subdistrict: "มะรือโบตก",
    zipcode: 96130,
    latitude: 6.337,
    longitude: 101.66,
  },
  {
    province: "นราธิวาส",
    district: "รือเสาะ",
    subdistrict: "รือเสาะ",
    zipcode: 96150,
    latitude: 6.369,
    longitude: 101.508,
  },
  {
    province: "นราธิวาส",
    district: "รือเสาะ",
    subdistrict: "สาวอ",
    zipcode: 96150,
    latitude: 6.434,
    longitude: 101.507,
  },
  {
    province: "นราธิวาส",
    district: "รือเสาะ",
    subdistrict: "เรียง",
    zipcode: 96150,
    latitude: 6.398,
    longitude: 101.445,
  },
  {
    province: "นราธิวาส",
    district: "รือเสาะ",
    subdistrict: "สามัคคี",
    zipcode: 96150,
    latitude: 6.423,
    longitude: 101.587,
  },
  {
    province: "นราธิวาส",
    district: "รือเสาะ",
    subdistrict: "บาตง",
    zipcode: 96150,
    latitude: 6.336,
    longitude: 101.445,
  },
  {
    province: "นราธิวาส",
    district: "รือเสาะ",
    subdistrict: "ลาโละ",
    zipcode: 96150,
    latitude: 6.343,
    longitude: 101.59,
  },
  {
    province: "นราธิวาส",
    district: "รือเสาะ",
    subdistrict: "รือเสาะออก",
    zipcode: 96150,
    latitude: 6.394,
    longitude: 101.554,
  },
  {
    province: "นราธิวาส",
    district: "รือเสาะ",
    subdistrict: "โคกสะตอ",
    zipcode: 96150,
    latitude: 6.282,
    longitude: 101.449,
  },
  {
    province: "นราธิวาส",
    district: "รือเสาะ",
    subdistrict: "สุวารี",
    zipcode: 96150,
    latitude: 6.458,
    longitude: 101.611,
  },
  {
    province: "นราธิวาส",
    district: "ศรีสาคร",
    subdistrict: "ซากอ",
    zipcode: 96210,
    latitude: 6.23,
    longitude: 101.522,
  },
  {
    province: "นราธิวาส",
    district: "ศรีสาคร",
    subdistrict: "ตะมะยูง",
    zipcode: 96210,
    latitude: 6.282,
    longitude: 101.515,
  },
  {
    province: "นราธิวาส",
    district: "ศรีสาคร",
    subdistrict: "ศรีสาคร",
    zipcode: 96210,
    latitude: 6.152,
    longitude: 101.492,
  },
  {
    province: "นราธิวาส",
    district: "ศรีสาคร",
    subdistrict: "เชิงคีรี",
    zipcode: 96210,
    latitude: 6.27,
    longitude: 101.57,
  },
  {
    province: "นราธิวาส",
    district: "ศรีสาคร",
    subdistrict: "กาหลง",
    zipcode: 96210,
    latitude: 6.213,
    longitude: 101.414,
  },
  {
    province: "นราธิวาส",
    district: "ศรีสาคร",
    subdistrict: "ศรีบรรพต",
    zipcode: 96210,
    latitude: 6.152,
    longitude: 101.574,
  },
  {
    province: "นราธิวาส",
    district: "แว้ง",
    subdistrict: "แว้ง",
    zipcode: 96160,
    latitude: 5.936,
    longitude: 101.835,
  },
  {
    province: "นราธิวาส",
    district: "แว้ง",
    subdistrict: "กายูคละ",
    zipcode: 96160,
    latitude: 5.974,
    longitude: 101.911,
  },
  {
    province: "นราธิวาส",
    district: "แว้ง",
    subdistrict: "ฆอเลาะ",
    zipcode: 96160,
    latitude: 5.906,
    longitude: 101.912,
  },
  {
    province: "นราธิวาส",
    district: "แว้ง",
    subdistrict: "โละจูด",
    zipcode: 96160,
    latitude: 5.831,
    longitude: 101.834,
  },
  {
    province: "นราธิวาส",
    district: "แว้ง",
    subdistrict: "แม่ดง",
    zipcode: 96160,
    latitude: 5.892,
    longitude: 101.82,
  },
  {
    province: "นราธิวาส",
    district: "แว้ง",
    subdistrict: "เอราวัณ",
    zipcode: 96160,
    latitude: 5.973,
    longitude: 101.842,
  },
  {
    province: "นราธิวาส",
    district: "สุคิริน",
    subdistrict: "มาโมง",
    zipcode: 96190,
    latitude: 5.876,
    longitude: 101.764,
  },
  {
    province: "นราธิวาส",
    district: "สุคิริน",
    subdistrict: "สุคิริน",
    zipcode: 96190,
    latitude: 5.951,
    longitude: 101.697,
  },
  {
    province: "นราธิวาส",
    district: "สุคิริน",
    subdistrict: "เกียร์",
    zipcode: 96190,
    latitude: 5.984,
    longitude: 101.775,
  },
  {
    province: "นราธิวาส",
    district: "สุคิริน",
    subdistrict: "ภูเขาทอง",
    zipcode: 96190,
    latitude: 5.796,
    longitude: 101.7,
  },
  {
    province: "นราธิวาส",
    district: "สุคิริน",
    subdistrict: "ร่มไทร",
    zipcode: 96190,
    latitude: 6.048,
    longitude: 101.759,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงโกลก",
    subdistrict: "สุไหงโก-ลก",
    zipcode: 96120,
    latitude: 6.008,
    longitude: 101.949,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงโกลก",
    subdistrict: "ปาเสมัส",
    zipcode: 96120,
    latitude: 6.054,
    longitude: 101.975,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงโกลก",
    subdistrict: "มูโนะ",
    zipcode: 96120,
    latitude: 6.108,
    longitude: 102.052,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงโกลก",
    subdistrict: "ปูโยะ",
    zipcode: 96120,
    latitude: 6.109,
    longitude: 101.993,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงปาดี",
    subdistrict: "ปะลุรู",
    zipcode: 96140,
    latitude: 6.073,
    longitude: 101.905,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงปาดี",
    subdistrict: "สุไหงปาดี",
    zipcode: 96140,
    latitude: 6.161,
    longitude: 101.931,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงปาดี",
    subdistrict: "โต๊ะเด็ง",
    zipcode: 96140,
    latitude: 6.116,
    longitude: 101.849,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงปาดี",
    subdistrict: "สากอ",
    zipcode: 96140,
    latitude: 6.008,
    longitude: 101.849,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงปาดี",
    subdistrict: "ริโก๋",
    zipcode: 96140,
    latitude: 6.057,
    longitude: 101.845,
  },
  {
    province: "นราธิวาส",
    district: "สุไหงปาดี",
    subdistrict: "กาวะ",
    zipcode: 96140,
    latitude: 6.02,
    longitude: 101.889,
  },
  {
    province: "นราธิวาส",
    district: "จะแนะ",
    subdistrict: "จะแนะ",
    zipcode: 96220,
    latitude: 6.077,
    longitude: 101.693,
  },
  {
    province: "นราธิวาส",
    district: "จะแนะ",
    subdistrict: "ดุซงญอ",
    zipcode: 96220,
    latitude: 6.123,
    longitude: 101.643,
  },
  {
    province: "นราธิวาส",
    district: "จะแนะ",
    subdistrict: "ผดุงมาตร",
    zipcode: 96220,
    latitude: 6.132,
    longitude: 101.728,
  },
  {
    province: "นราธิวาส",
    district: "จะแนะ",
    subdistrict: "ช้างเผือก",
    zipcode: 96220,
    latitude: 5.997,
    longitude: 101.573,
  },
  {
    province: "นราธิวาส",
    district: "เจาะไอร้อง",
    subdistrict: "จวบ",
    zipcode: 96130,
    latitude: 6.256,
    longitude: 101.817,
  },
  {
    province: "นราธิวาส",
    district: "เจาะไอร้อง",
    subdistrict: "บูกิต",
    zipcode: 96130,
    latitude: 6.182,
    longitude: 101.828,
  },
  {
    province: "นราธิวาส",
    district: "เจาะไอร้อง",
    subdistrict: "มะรือโบออก",
    zipcode: 96130,
    latitude: 6.249,
    longitude: 101.875,
  },
];
