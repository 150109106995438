import { Dialog, DialogContent, IconButton } from "@mui/material";
import { Dispatch, SetStateAction, memo, useCallback } from "react";
import CrossIcon from "../../../../../admin/assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../../../admin/components/Button/ButtonFillCustom";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../../admin/pages/group-area/views/Dropdown";
import { tw } from "../../../../../utils/tw";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../../redux/store";
import { notifyAssignment } from "../../../../../services/Invoice.service";
import getErrorMessage from "../../../../../utils/getErrorMessage";

interface INotifyInvoiceModal {
  isOpen: boolean;
  onCloseFn: () => void;
  InvoiceOption: TDropdownValue[];
  invoice: TDropdownValue[];
  setInvoice: Dispatch<SetStateAction<TDropdownValue[]>>;
  selectedId: string;
  RefetchDetailsAcceptedAssignment: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiDialog-paperScrollPaper]:rounded-[40px] [&_.MuiButtonBase-root.Mui-selected]:!bg-[#FFFFFF]  [&_.MuiButtonBase-root.Mui-selected]:!text-[#000000] [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[313px]`
  ),
  titleContainer: tw(`flex items-center w-full justify-end`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  space: tw(`h-[12px]`),
};

const NotifyInvoiceModal = (props: INotifyInvoiceModal) => {
  const {
    isOpen,
    onCloseFn,
    InvoiceOption,
    invoice,
    setInvoice,
    selectedId,
    RefetchDetailsAcceptedAssignment,
  } = props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch<AppDispatch>();

  const notifyMutation = useMutation({
    mutationFn: notifyAssignment,
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["details_accepted_update_assignment"],
      });
      RefetchDetailsAcceptedAssignment();
      setTimeout(() => {
        dispatch(loadingActions.hide());
        onClose();
        setTimeout(() => {
          dispatch(
            snakeActions.showMessage({
              message: "ยืนยันติดตามสำเร็จ",
              type: "success",
            })
          );
        }, 500);
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onNotify = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการติดตามรายการ",
        text: "คุณต้องการสร้างการติดตามรายการนี้ใช่หรือไม่?",
        cancelText: "กลับไปแก้ไข",
        confirmText: "ยืนยัน",
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          dispatch(alertAction.hide());
          dispatch(loadingActions.show({ message: "กำลังติดตามรายการ..." }));
          notifyMutation.mutate({
            assignmentId: selectedId,
            invoiceIds: invoice.map((id) => id.value),
          });
        },
      })
    );
  }, [selectedId, invoice]);

  const onClose = useCallback(() => {
    onCloseFn();
    setInvoice([]);
  }, []);

  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      slotProps={slotProps}
      className={classes.container}
      classes={{ paper: "w-full" }}
    >
      <DialogContent className="w-[313px] flex flex-col gap-4">
        <div className={classes.titleContainer}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </div>
        <div className="text-[#3777BC] text-[20px] font-semibold flex w-full justify-center">
          {t("MoneyDebtAndCollection.NotifyToLendingModal")}
        </div>
        <div className="flex flex-col gap-4">
          <Dropdown
            options={InvoiceOption}
            onChange={setInvoice}
            renderValueArray={invoice}
            shrink
            label2={t("MoneyDebtAndCollection.InvoiceNumber")}
            switchStyle
            placeholder="เลข Invoice"
            containerSelect="pb-0"
          />
        </div>
        <ButtonFillCustom
          onClick={onNotify}
          title={t("MoneyDebtAndCollection.Confirm")}
          btnTextColor="#ffffff"
          btnBgColor="#68C184"
          className={classes.button}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(NotifyInvoiceModal);
