import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Box,
  IconButton,
  Tab,
  Tabs as TabsMui,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Tabs from "../../../admin/pages/group-area/views/Tabs";
import BackgrounImage from "../../../assets/images/Login/BackGroungImage.png";
import FarmIcon from "../../../assets/images/OnboardingImages/FarmIcon.svg";
import topIcon from "../../../assets/images/OnboardingImages/top_img-2.svg";
import topIcon3 from "../../../assets/images/OnboardingImages/top_img-3.svg";
import { useFarmCommunityController } from "../controller";

import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import DashboardInfoCard from "../../../components/common/DashboardInfoCard/DashboardInfoCard";
import FooterPartner from "../../../components/common/Footer/FooterPartner";
import { DATE_FORMAT_ENG } from "../../../const/app.constant";
import { loadingActions } from "../../../redux/slices/loadingSlice";
import delay from "../../../utils/delay";
import { useNotificationController } from "../../homepage/notificaction-page/controller";
import PaginationUserManualModal from "../UserManualDetail";
import "./styles.css";

const DocSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.99878 5.49951C4.99878 4.1188 6.11807 2.99951 7.49878 2.99951H13.231C13.8941 2.99951 14.5299 3.2629 14.9988 3.73174L19.2665 7.99951C19.7354 8.46835 19.9988 9.10424 19.9988 9.76728V20.4995C19.9988 21.8802 18.8795 22.9995 17.4988 22.9995H7.49878C6.11807 22.9995 4.99878 21.8802 4.99878 20.4995V5.49951ZM7.49878 12.9995C7.49878 12.3092 8.05842 11.7495 8.74878 11.7495H16.2488C16.9391 11.7495 17.4988 12.3092 17.4988 12.9995C17.4988 13.6899 16.9391 14.2495 16.2488 14.2495H8.74878C8.05842 14.2495 7.49878 13.6899 7.49878 12.9995ZM8.74878 16.7495C8.05842 16.7495 7.49878 17.3092 7.49878 17.9995C7.49878 18.6899 8.05842 19.2495 8.74878 19.2495H16.2488C16.9391 19.2495 17.4988 18.6899 17.4988 17.9995C17.4988 17.3092 16.9391 16.7495 16.2488 16.7495H8.74878Z"
        fill="#68C184"
      />
    </svg>
  );
};
const DashboardSvg = () => {
  return (
    <svg
      width="77"
      height="77"
      viewBox="0 0 77 77"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="77" height="77" rx="20" fill="#68C184" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.25 22.75C24.7647 22.75 22.75 24.7647 22.75 27.25V49.75C22.75 52.2353 24.7647 54.25 27.25 54.25H49.75C52.2353 54.25 54.25 52.2353 54.25 49.75V27.25C54.25 24.7647 52.2353 22.75 49.75 22.75H27.25ZM47.5 31.75C47.5 30.5074 46.4926 29.5 45.25 29.5C44.0074 29.5 43 30.5074 43 31.75V45.25C43 46.4926 44.0074 47.5 45.25 47.5C46.4926 47.5 47.5 46.4926 47.5 45.25V31.75ZM40.75 36.25C40.75 35.0074 39.7426 34 38.5 34C37.2574 34 36.25 35.0074 36.25 36.25V45.25C36.25 46.4926 37.2574 47.5 38.5 47.5C39.7426 47.5 40.75 46.4926 40.75 45.25V36.25ZM34 43C34 41.7574 32.9926 40.75 31.75 40.75C30.5074 40.75 29.5 41.7574 29.5 43V45.25C29.5 46.4926 30.5074 47.5 31.75 47.5C32.9926 47.5 34 46.4926 34 45.25V43Z"
        fill="white"
      />
    </svg>
  );
};

const PartnerFarmCommunity = () => {
  const role = "partner";
  const {
    tabArticleIndex,
    tabIndex,
    onChangeTab,
    onChangeArticleTab,
    data,
    fileUrls,
    dataSwiper,
    fileSwiperUrls,
    dataPigPrice,
    dataPigPriceSequence,
    filePigPriceSwiperUrls,
    filePigPriceUrls,
    tabUserManualIndex,
    onChangeUserManualTab,
    userManual,
    tabUserManualType,
  } = useFarmCommunityController(role);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dataNotification } = useNotificationController();

  const [isNotificationAllRead, setIsNotificationAllRead] =
    useState<boolean>(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleOpenModal = (article: any) => {
    setSelectedArticle(article);
    setIsModalOpen(true);
  };

  const playerRef = useRef<ReactPlayer | null>(null);

  const handlePlay = () => {
    if (playerRef.current) {
      const player = playerRef.current.getInternalPlayer();
      if (player && typeof player.requestFullscreen === "function") {
        player.requestFullscreen();
      } else if (player && typeof player.mozRequestFullScreen === "function") {
        player.mozRequestFullScreen();
      } else if (
        player &&
        typeof player.webkitRequestFullscreen === "function"
      ) {
        player.webkitRequestFullscreen();
      } else if (player && typeof player.msRequestFullscreen === "function") {
        player.msRequestFullscreen();
      }
    }
  };

  useEffect(() => {
    const loadingSetPage = async () => {
      dispatch(loadingActions.show({ message: "กำลังโหลดข้อมูล..." }));
      await delay(2000);
      dispatch(loadingActions.hide());
    };
    loadingSetPage();
  }, [tabArticleIndex]);

  useEffect(() => {
    if (dataNotification.length) {
      const isReadAll = dataNotification.every((item) => item.read === true);
      setIsNotificationAllRead(isReadAll);
    }
  }, [dataNotification]);
  return (
    <div
      style={{
        backgroundImage: `url(${BackgrounImage})`,
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#4882c8",
      }}
      className="!h-auto !min-h-screen"
    >
      <img
        src={topIcon}
        className="w-[65%] top-10 right-4 opacity-25 absolute"
        alt="Top Icon"
      />
      <img
        src={topIcon3}
        className="topIcon1 w-[20%] top-[19%] right-12 opacity-25 absolute"
        alt="Top Icon 3"
      />
      <div className="flex w-11/12 relative justify-between items-center top-6 z-1 mx-auto">
        <img src={FarmIcon} className="w-[60%]" alt="farm-icon" />
        <Link to={"/notifications"}>
          <IconButton className="relative">
            <NotificationsIcon className="notification-icon" />
            {!isNotificationAllRead && (
              <span className="absolute w-[7px] h-[7px] bg-[#F57C00] rounded-full top-[12px] left-[12px]"></span>
            )}
          </IconButton>
        </Link>
      </div>
      <div
        style={{
          height: "auto !important",
          backgroundSize: "cover",
          backgroundColor: "#FFFFFF",
        }}
      >
        <div className="px-4 mt-7 pt-[18px]">
          <Tabs tabIndex={tabIndex} onChange={onChangeTab} isScroll>
            <Tab
              label={t("FarmCommunity.NewsArticleLandingPage")}
              style={{ fontWeight: 600, fontSize: "20px" }}
            />
            <Tab
              label={t("FarmCommunity.PigPriceLandingPage")}
              style={{ fontWeight: 600, fontSize: "20px" }}
            />
            <Tab
              label={t("FarmCommunity.UserManualLandingPage")}
              style={{ fontWeight: 600, fontSize: "20px" }}
            />
          </Tabs>
        </div>
        {tabIndex === 0 && (
          <div className="pt-6 px-4 pb-20">
            <TabsMui
              value={tabArticleIndex}
              onChange={onChangeArticleTab}
              sx={{
                "& .MuiTab-root": {
                  borderRadius: "16px",
                  paddingX: 2,
                  paddingY: 1,
                  minHeight: "32px",
                  minWidth: "72px",
                  transition: "background-color 0.3s",
                  marginX: 1,
                },
                "& .Mui-selected": {
                  backgroundColor: "#68C184 !important",
                  color: "white !important",
                  fontWeight: "500",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab
                label={t("FarmCommunity.News")}
                sx={{
                  borderRadius: 16,
                  paddingX: 2,
                  paddingY: 1,
                }}
              />
              <Tab
                label={t("FarmCommunity.Video")}
                sx={{
                  borderRadius: 16,
                  paddingX: 2,
                  paddingY: 1,
                }}
              />
            </TabsMui>
            {tabArticleIndex === 0 && (
              <div className="mx-4">
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  className="mySwiper"
                  loop
                  style={{ height: "200px", width: "100%" }}
                  modules={[Pagination]}
                >
                  {dataSwiper.length > 0
                    ? dataSwiper.map((slide, index) => (
                        <SwiperSlide key={slide.id}>
                          <div
                            className="swiper-slide-content relative flex flex-col items-center"
                            onClick={() =>
                              navigate(
                                `/farms/farm-community/article/${slide.id}`,
                                {
                                  state: {
                                    file: fileSwiperUrls[index],
                                    data: slide,
                                  },
                                }
                              )
                            }
                          >
                            <img
                              alt="transfer-evidence-file"
                              src={fileSwiperUrls[index]}
                              className="w-24 h-16 rounded-md"
                              style={{
                                minWidth: "110px",
                                height: "200px",
                                objectFit: "cover",
                              }}
                            />
                            <div className="px-3 py-2 rounded-md bg-white shadow-lg text-center mx-2 absolute bottom-10 transform translate-y-1/2">
                              <Typography
                                variant="body1"
                                className="!text-[#3A8349] !font-medium line-clamp-2"
                                style={{ fontSize: "14px", color: "#3A8349" }}
                              >
                                {slide.topicName}
                              </Typography>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))
                    : null}
                </Swiper>

                <p className=" text-[20px] font-semibold text-[#34407B] my-6">
                  {t("FarmCommunity.NewsArticleLandingPage")}
                </p>
                <div className="space-y-4">
                  {data.length > 0 ? (
                    data.map((newsItem, index) => (
                      <Box
                        key={index}
                        className="flex flex-row gap-3 mr-3"
                        onClick={() =>
                          navigate(
                            `/farms/farm-community/article/${newsItem.id}`,
                            { state: { file: fileUrls[index], data: newsItem } }
                          )
                        }
                      >
                        <img
                          alt="article-file"
                          src={fileUrls[index]}
                          className="w-24 h-16 rounded-md"
                          style={{ minWidth: "110px" }}
                        />
                        <Box>
                          <Typography
                            variant="body1"
                            className="text-gray-900 font-semibold line-clamp-2"
                          >
                            {newsItem.topicName}
                          </Typography>
                          <Typography
                            variant="body2"
                            className="line-clamp-1 text-sm text-[#646464]"
                          >
                            {newsItem.url}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Typography
                      variant="body2"
                      className="line-clamp-2 text-sm"
                    >
                      {t("FarmCommunity.NewsArticleLandingPage")}
                    </Typography>
                  )}
                </div>
              </div>
            )}
            {tabArticleIndex === 1 && (
              <div className="mx-4">
                {dataSwiper.length > 0 ? (
                  <>
                    <ReactPlayer
                      ref={playerRef}
                      playing={false}
                      controls={true}
                      width="100%"
                      height="100%"
                      onPlay={handlePlay}
                      url={fileSwiperUrls[0]}
                    />
                    <p className="text-[16px] font-semibold text-[#68C184] line-clamp-2 mt-3">
                      {dataSwiper[0].topicName}
                    </p>
                  </>
                ) : null}

                <p className=" text-[20px] font-semibold text-[#34407B] my-6">
                  {t("FarmCommunity.NewsArticleLandingPage")}
                </p>
                {data.length > 0 ? (
                  <div className="space-y-4">
                    {data.map((newsItem, index) => (
                      <Box key={index} className="flex flex-row gap-3 mr-3">
                        <Box sx={{ width: "50%" }}>
                          <ReactPlayer
                            ref={playerRef}
                            playing={false}
                            controls={true}
                            width="100%"
                            height="100%"
                            onPlay={handlePlay}
                            url={fileUrls[index]}
                          />
                        </Box>
                        <Box sx={{ width: "50%" }}>
                          <Typography
                            variant="body1"
                            className="text-gray-900 font-semibold line-clamp-2"
                          >
                            {newsItem.topicName}
                          </Typography>
                          <Typography
                            variant="body2"
                            className="line-clamp-1 text-sm text-[#646464]"
                          >
                            {newsItem.url}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </div>
                ) : (
                  <Typography variant="body2" className="line-clamp-2 text-sm">
                    {t("FarmCommunity.NewsArticleLandingPage")}
                  </Typography>
                )}
              </div>
            )}
          </div>
        )}
        {tabIndex === 1 && (
          <div className="pt-6 px-4 pb-20">
            {dataPigPriceSequence.length > 0 && (
              <div
                className="mt-6"
                onClick={() =>
                  navigate(
                    `/farms/farm-community/pig-price/${dataPigPriceSequence[0].id}`,
                    {
                      state: {
                        file: filePigPriceSwiperUrls[0],
                        data: dataPigPriceSequence[0],
                      },
                    }
                  )
                }
              >
                <img
                  alt="topic-pig-price-file"
                  src={filePigPriceSwiperUrls[0]}
                />
                <p className="text-[16px] font-semibold text-[#68C184] line-clamp-2 mt-3">
                  {dataPigPriceSequence[0]?.topicName}
                </p>
              </div>
            )}
            <div className="mt-4">
              <DashboardInfoCard
                title={t("FarmCommunity.MarketPriceAndSellingPrice")}
                subTitle={t("FarmCommunity.Graph")}
                icon={<DashboardSvg />}
                removeBgIcon={true}
                bgColor="#F0FDF4"
                onPress={() => {
                  navigate("/farms/farm-community/pig-price/chart", {
                    state: role,
                  });
                }}
              />
            </div>
            <p className=" text-[20px] font-semibold text-[#34407B] my-6">
              {t("FarmCommunity.PigPriceLandingPage")}
            </p>
            <div className="space-y-4">
              {dataPigPrice.length > 0 ? (
                dataPigPrice.map((newsItem, index) => (
                  <Box
                    key={index}
                    className="flex flex-row gap-3 mr-3"
                    onClick={() =>
                      navigate(
                        `/farms/farm-community/pig-price/${newsItem.id}`,
                        {
                          state: {
                            file: filePigPriceUrls[index],
                            data: newsItem,
                          },
                        }
                      )
                    }
                  >
                    <img
                      alt="article-file"
                      src={filePigPriceUrls[index]}
                      className="w-24 h-16 rounded-md"
                      style={{ minWidth: "110px" }}
                    />
                    <Box>
                      <Typography
                        variant="body1"
                        className="text-gray-900 font-semibold line-clamp-2"
                      >
                        {newsItem.topicName}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="line-clamp-2 text-sm text-[#646464]"
                      >
                        {newsItem.announceDate
                          ? dayjs(newsItem.announceDate).format(DATE_FORMAT_ENG)
                          : ""}
                      </Typography>
                    </Box>
                  </Box>
                ))
              ) : (
                <Typography variant="body2" className="line-clamp-2 text-sm">
                  {t("FarmCommunity.PigPriceLandingPage")}
                </Typography>
              )}
            </div>
          </div>
        )}
        {tabIndex === 2 && (
          <div className="pt-6 px-4 pb-20">
            <TabsMui
              value={tabUserManualIndex}
              onChange={onChangeUserManualTab}
              sx={{
                "& .MuiTab-root": {
                  borderRadius: "16px",
                  paddingX: 2,
                  paddingY: 1,
                  minHeight: "32px",
                  minWidth: "72px",
                  transition: "background-color 0.3s",
                  marginX: 1,
                },
                "& .Mui-selected": {
                  backgroundColor: "#68C184 !important",
                  color: "white !important",
                  fontWeight: "500",
                },
                "& .MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tab
                label={t("FarmCommunity.UserManual")}
                sx={{
                  borderRadius: 16,
                  paddingX: 2,
                  paddingY: 1,
                }}
              />
              <Tab
                label={t("FarmCommunity.UserManualVideo")}
                sx={{
                  borderRadius: 16,
                  paddingX: 2,
                  paddingY: 1,
                }}
              />
            </TabsMui>
            {tabUserManualIndex === 0 && (
              <div className="mx-4">
                <div className=" bg-[#FBFBFB] rounded-[20px] p-4 mb-3">
                  {(userManual || []).map((list) => (
                    <>
                      <p className=" text-[20px] font-semibold text-[#34407B] font-sans">
                        {list.name}
                      </p>
                      {list.articles.map((article) => (
                        <div className="bg-[#ffff] rounded-[20px] p-4 mt-3 flex justify-between items-center">
                          <DocSvg />
                          <p className="text-[16px] font-bold font-sans">
                            {article.topicName}
                          </p>
                          <div
                            className="bg-[#68C184] px-4 py-1 text-[#ffff] font-sans rounded-[20px]"
                            onClick={() => handleOpenModal(article)}
                          >
                            {t("FarmCommunity.Read")}
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              </div>
            )}
            {tabUserManualIndex === 1 && (
              <div className="mx-4">
                <div className=" bg-[#FBFBFB] rounded-[20px] p-4 mb-3">
                  {(userManual || []).map((list) => (
                    <>
                      <p className=" text-[20px] font-semibold text-[#34407B] font-sans">
                        {list.name}
                      </p>
                      {list.articles.map((article) => (
                        <div className="bg-[#ffff] rounded-[20px] p-4 mt-3 flex justify-between items-center">
                          <DocSvg />
                          <p className="text-[16px] font-bold font-sans">
                            {article.topicName}
                          </p>
                          <div
                            className="bg-[#68C184] px-4 py-1 text-[#ffff] font-sans rounded-[20px]"
                            onClick={() => handleOpenModal(article)}
                          >
                            {t("FarmCommunity.Read")}
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isModalOpen && (
        <PaginationUserManualModal
          tabUserManualType={tabUserManualType}
          role={role}
          isOpen={isModalOpen}
          article={selectedArticle}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <FooterPartner selectedTab={1} />
    </div>
  );
};

export default PartnerFarmCommunity;
