import { useState } from "react";
import {
  getListSaleTargetManagement,
  getSaleTargetManagerById,
} from "../services/saleTarget.service";
import {
  ISaleTargetManagementByIdRow,
  ISaleTargetManagementRow,
} from "../types/SaleTargetTable.type";
import { TUseSaleTargetSaleLead } from "./useSaleTargetSaleLead.hook";

export const useSaleTargetManagementTableHook = (
  saleTargetHook: TUseSaleTargetSaleLead,
  id?: string
) => {
  const [list, setList] = useState<ISaleTargetManagementRow[]>([]);
  const [listById, setListById] = useState<ISaleTargetManagementByIdRow[]>([]);

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("page", saleTargetHook.page.toString());
    searchParams.append("limit", saleTargetHook.limit.toString());
    searchParams.append("direction", saleTargetHook.direction);
    searchParams.append("orderBy", saleTargetHook.order);
    searchParams.append("searchText", saleTargetHook.searchText);

    setList([]);
    const data = await getListSaleTargetManagement(searchParams);
    if (data) {
      setList(data.rows);
    }
  };

  const handleFetchGetById = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("page", saleTargetHook.page.toString());
    searchParams.append("limit", saleTargetHook.limit.toString());
    searchParams.append("direction", saleTargetHook.direction);
    searchParams.append("orderBy", saleTargetHook.order);
    searchParams.append("searchText", saleTargetHook.searchText);

    setList([]);
    if (id) {
      const data = await getSaleTargetManagerById(id);
      if (data) {
        setListById(data.rows);
      }
    }
  };

  return {
    list,
    listById,
    handleFetch,
    handleFetchGetById,
  };
};
