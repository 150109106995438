import { Checkbox, Link, TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EInvoiceDebtPaymentOptionDisplay } from "../../../../../../types/invoice/log.type";
import { useAdminAuth } from "../../../../../components/AdminAuth/AdminAuthProvider";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../../components/Table/TableCustom";
import ConfirmPaymentModal from "../../components/Modals/ConfirmPaymentModal";
import ViewDocumentModal from "../../components/Modals/ViewDocumentModal";
import { MoneyDebtSearch } from "../../components/MoneyDeptSearch";
import { useMoneyDebtFilter } from "../../hooks/useMoneyDebtFilter.hook";
import { useTrackingDebtTableHook } from "../../hooks/useTrackingDebtTable.hook";
import {
  TTrackingDebtTable,
  TrackingDebtRow,
} from "../../types/trackingDebt.type";

type Props = {};

const TrackingDebt = ({}: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionReadonly =
    action?.moneyDebtAndCollectionLending === "readonly";
  const searchTrackingDebtFilter = useMoneyDebtFilter();
  const {
    list,
    itemSelected,
    setItemSelected,
    isSelected,
    handleClick,
    handleExportExcel,
    handleFetch,
    handleSelectAllClick,
    count,
    dataFollowDebt,
  } = useTrackingDebtTableHook(searchTrackingDebtFilter, "following");

  const [isOpenConfirmPayment, setIsOpenConfirmPayment] = useState(false);
  const [isPayExactAmount, setIsPayExactAmount] = useState(false);
  const [dataConfirmModal, setDataConfirmModal] = useState<
    TrackingDebtRow | undefined
  >();
  const [isOpenDocModal, setIsOpenDocModal] = useState(false);
  const [receiptId, setReceiptId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");

  const headCells: ITableCustomHeadCell<keyof TTrackingDebtTable>[] = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.salesNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.customerNo"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.customerName"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.invoiceNo"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "payment",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.paymentType"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "paidAmount",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.paymentAmount"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "monthsOfInstallment",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.installmentPeriod"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "installmentNumber",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.installmentTime"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "paymentPerMonth",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.paymentAmountPerMonth"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "totalPaid",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.amountPaid"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "dueDate",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.paymentDueDate"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: t("moneyDebtAndCollectionLending.onProcess.lastUpdated"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "120px",
      isSorting: false,
    },
  ];

  const filteredHeadCells = roleActionReadonly
    ? headCells.toSpliced(13, 2)
    : headCells;

  useEffect(() => {
    handleFetch();
  }, [
    searchTrackingDebtFilter.direction,
    searchTrackingDebtFilter.page,
    searchTrackingDebtFilter.tab,
  ]);

  return (
    <>
      <MoneyDebtSearch
        showSelect={false}
        searchMoneyDeptFilter={searchTrackingDebtFilter}
        itemSelected={itemSelected}
        onExport={handleExportExcel}
        onSearch={handleFetch}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchTrackingDebtFilter.direction}
          orderBy={"updateDate"}
          rowsPerPage={10}
          rows={list}
          itemsSelected={itemSelected}
          headerItems={filteredHeadCells}
          page={searchTrackingDebtFilter.page}
          onChangeOrderBy={() => {}}
          onChangeOrder={(val) => {
            searchTrackingDebtFilter.setDirection(val);
          }}
          onChangePage={(val) => searchTrackingDebtFilter.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={list.map((row) => row.id)}
          rowChildren={
            <>
              {list.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {
                        EInvoiceDebtPaymentOptionDisplay[
                          row.payment as keyof typeof EInvoiceDebtPaymentOptionDisplay
                        ]
                      }
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paidAmount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.monthsOfInstallment}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.installmentNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paymentPerMonth}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.totalPaid}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.dueDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.updateDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                        }}
                        onClick={() => {
                          setIsOpenDocModal(true);
                          setReceiptId(row.invoiceReceiptId!);
                          setInvoiceId(row.id);
                        }}
                      >
                        ดูเอกสาร
                      </Link>
                    </TableCell>
                    {!roleActionReadonly && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <ButtonFillCustom
                          title={t(
                            "moneyDebtAndCollectionLending.onProcess.match.title"
                          )}
                          onClick={() => {
                            setIsPayExactAmount(true);
                            setIsOpenConfirmPayment(true);
                            setDataConfirmModal(
                              dataFollowDebt ? dataFollowDebt[index] : undefined
                            );
                          }}
                        />
                      </TableCell>
                    )}
                    {!roleActionReadonly && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <ButtonFillCustom
                          btnBgColor="#D32F2F"
                          title={t(
                            "moneyDebtAndCollectionLending.onProcess.unmatch.title"
                          )}
                          onClick={() => {
                            setIsPayExactAmount(false);
                            setIsOpenConfirmPayment(true);
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </>
          }
        ></TableCustom>
        <ConfirmPaymentModal
          data={dataConfirmModal}
          isOpen={isOpenConfirmPayment}
          isPayExactAmount={isPayExactAmount}
          handleClose={(isSuccess) => {
            if (isSuccess) {
              handleFetch();
            }
            setIsOpenConfirmPayment(false);
          }}
        />
        <ViewDocumentModal
          isOpen={isOpenDocModal}
          handleClose={(isSuccess) => {
            if (isSuccess) {
              handleFetch();
            }
            setIsOpenDocModal(false);
            setReceiptId("");
          }}
          roleName="lending"
          tabLendingName="following"
          invoiceReceiptId={receiptId}
          invoiceId={invoiceId}
        />
      </div>
    </>
  );
};

export default TrackingDebt;
