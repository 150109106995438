import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../../components/app-bar/AppBar";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { putConfirmReceive } from "../../../../services/MyOrder.service";
import {
  putCancelOrder,
  putChangeShippingDate,
} from "../../../../services/OrderFeed.service";
import { IGetMyOrderByID } from "../../../../types/MyOrderFeed.type";
import delay from "../../../../utils/delay";
import ReportModal from "../../../report";
import { OrderFeedBaseSnackBar } from "../../order-feed/OrderFeedBaseSnackbar";
import OrderFeedFarmerDetail from "../my-order-component/OrderFeedFarmerDetail";
import ChangeConfirmDateModal from "../my-order-component/modal/ChangeConfirmDateModal";
import ConfirmModal from "../my-order-component/modal/ConfirmModal";
import ConfirmToCancelModal from "../my-order-component/modal/ConfirmToCancelModal";
import ReceiptProductModal from "../my-order-component/modal/ReceiptProductModal";
import { useTranslation } from "react-i18next";

export default function MyOrderDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderId, myOrderList, farmId } = useLoaderData() as {
    farmId: string;
    orderId: string;
    myOrderList: IGetMyOrderByID;
  };
  //status
  const waitingForSaleApproval =
    myOrderList.result.status === "waiting_for_sale_approval";
  const waitingForSaleSendToLender =
    myOrderList.result.status === "waiting_for_sale_send_to_lender";
  const waitToForDelivery =
    myOrderList.result.status === "waiting_for_delivery";
  const delivery = myOrderList.result.status === "delivery";
  const waitingForLenderApprove =
    myOrderList.result.status === "waiting_for_lender_approval";

  //date
  const shippingDate = dayjs(myOrderList.result.shippingDate);
  const actualShippingDate = dayjs(myOrderList.result.actualShippingDate);
  const today = new Date();
  const dispatch = useDispatch();
  const [confirmDate, setConfirmDate] = useState<Dayjs | null>(null);
  const [isOpenReceiptProductModal, setIsOpenReceiptProductModal] =
    useState<boolean>(false);
  const [isOpenChangeConfirmDateModal, setIsOpenChangeConfirmDateModal] =
    useState<boolean>(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);
  const [isOpenConfirmToCancelModal, setIsOpenConfirmToCancelModal] =
    useState<boolean>(false);
  const [isOpenReportPage, setIsOpenReportPage] = useState<boolean>(false);
  const [openSnackbarEdit, setOpenSnackbarEdit] = useState(false);
  const [openSnackbarCancel, setOpenSnackbarCancel] = useState(false);

  const handleCloseSnackbarEdit = (
    _: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarEdit(false);
  };
  const handleCloseSnackbarCancel = (
    _: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbarCancel(false);
  };
  const [toggleResetForm, setToggleResetForm] = useState(false);

  return (
    <div className="pb-4 h-screen flex flex-col  bg-[#ffff] overflow-scroll">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title={`${t("feedOrderAndDelivery.myOrder.titleDetailBar")}`}
        />
      </div>
      {waitingForSaleApproval ||
      waitToForDelivery ||
      waitingForSaleSendToLender ||
      waitingForLenderApprove ? (
        <OrderFeedFarmerDetail
          primaryButtonText={`${t(
            "feedOrderAndDelivery.myOrder.button.changeDate"
          )}`}
          isPrimaryDisabled={
            dayjs().isBefore(shippingDate)
              ? Math.abs(shippingDate.diff(today, "day")) <= 2
              : true || delivery
          }
          onPrimaryClick={() => setIsOpenChangeConfirmDateModal(true)}
          secondaryButtonText={`${t(
            "feedOrderAndDelivery.myOrder.button.cancelOrder"
          )}`}
          onSecondaryClick={() => setIsOpenConfirmToCancelModal(true)}
          isSecondaryDisabled={
            dayjs().isBefore(shippingDate)
              ? Math.abs(shippingDate.diff(today, "day")) <= 4
              : true || delivery
          }
          isDisplaySecondaryButton={true}
          orderDetail={myOrderList}
          isTotalItemPrice={true}
        />
      ) : (
        <OrderFeedFarmerDetail
          primaryButtonText={`${t(
            "feedOrderAndDelivery.myOrder.button.confirmReceipt"
          )}`}
          isPrimaryDisabled={delivery}
          onPrimaryClick={() => setIsOpenReceiptProductModal(true)}
          secondaryButtonText={`${t(
            "feedOrderAndDelivery.myOrder.button.report"
          )}`}
          isSecondaryDisabled={
            Math.abs(actualShippingDate.diff(today, "day")) >= 6 || delivery
          }
          onSecondaryClick={() => {
            setToggleResetForm((prev) => !prev);
            setIsOpenReportPage(true);
          }}
          isDisplaySecondaryButton={true}
          isTotalItemPrice={true}
          orderDetail={myOrderList}
        />
      )}

      {/* receipt product modal */}
      <ReceiptProductModal
        onOpenModal={isOpenReceiptProductModal}
        onCloseModal={async (isConfirm) => {
          setIsOpenReceiptProductModal(false);
          if (isConfirm) {
            const response = await putConfirmReceive(orderId);
            if (response.status !== 200) {
              dispatch(
                snakeActions.showMessage({
                  message: `${t(
                    "feedOrderAndDelivery.myOrder.snackBar.receiptOrderFail"
                  )}`,
                  type: "error",
                })
              );
            } else {
              dispatch(
                snakeActions.showMessage({
                  message: `${t(
                    "feedOrderAndDelivery.myOrder.snackBar.receiptOrderSuccess"
                  )}`,
                  type: "success",
                })
              );
              await delay(1500);
              navigate(
                "/feed-order-and-delivery/" +
                  farmId +
                  "/" +
                  orderId +
                  "/view-document"
              );
            }
          }
        }}
      />
      {/* Change delivery date modal */}
      <ChangeConfirmDateModal
        defaultDate={shippingDate}
        onOpenModal={isOpenChangeConfirmDateModal}
        onCloseModal={(isConfirm, value) => {
          setIsOpenChangeConfirmDateModal(false);
          if (isConfirm) {
            setConfirmDate(value);
            setIsOpenConfirmModal(true);
          }
        }}
      />
      {/* confirm to change delivery date modal */}
      <ConfirmModal
        onOpenModal={isOpenConfirmModal}
        onCloseModal={async (isConfirm) => {
          setIsOpenConfirmModal(false);
          if (isConfirm) {
            const response = await putChangeShippingDate(
              orderId,
              dayjs(confirmDate)
            );
            if (response.status !== 200) {
              dispatch(
                snakeActions.showMessage({
                  message: `${t(
                    "feedOrderAndDelivery.myOrder.snackBar.changeDateFail"
                  )}`,
                  type: "error",
                })
              );
            } else {
              setOpenSnackbarEdit(true);
              await delay(2000);
              window.location.reload();
            }
          } else {
            setIsOpenChangeConfirmDateModal(true);
          }
        }}
      />
      {/* confirm to cancel order modal */}
      <ConfirmToCancelModal
        onOpenModal={isOpenConfirmToCancelModal}
        onCloseModal={async (isConfirm) => {
          setIsOpenConfirmToCancelModal(false);
          if (isConfirm) {
            const response = await putCancelOrder(orderId);
            if (response.status !== 200) {
              dispatch(
                snakeActions.showMessage({
                  message: `${t(
                    "feedOrderAndDelivery.myOrder.snackBar.cancelOrderFail"
                  )}`,
                  type: "error",
                })
              );
            } else {
              setOpenSnackbarCancel(true);
              await delay(2000);
              navigate("/feed-order-and-delivery/" + farmId);
            }
          }
        }}
      />
      {/* alert message on change delivery date success */}
      <OrderFeedBaseSnackBar
        openSnackbar={openSnackbarEdit}
        handleCloseSnackbar={handleCloseSnackbarEdit}
        snackbarText={`${t(
          "feedOrderAndDelivery.myOrder.snackBar.changeDateSuccess"
        )}`}
        styleCSS={{
          bottom: "2em",
          zIndex: 1000,
          margin: "0 16px 0 16px",
        }}
      />
      {/* alert message on cancel success */}
      <OrderFeedBaseSnackBar
        openSnackbar={openSnackbarCancel}
        handleCloseSnackbar={handleCloseSnackbarCancel}
        snackbarText={`${t(
          "feedOrderAndDelivery.myOrder.snackBar.cancelOrderSuccess"
        )}`}
        styleCSS={{
          bottom: "2em",
          zIndex: 1000,
          margin: "0 16px 0 16px",
        }}
      />
      <ReportModal
        isOpen={isOpenReportPage}
        onClose={() => setIsOpenReportPage(false)}
        myOrderList={myOrderList}
        toggleResetForm={toggleResetForm}
        setToggleResetForm={setToggleResetForm}
      />
    </div>
  );
}
