import { Divider, Link, SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Month } from "../../../const/utils";
import {
  getActiveUsersGraph,
  getApplicationUsageGroupArea,
  getApplicationUsageYear,
  getDailyLogUsersGraph,
} from "../../../services/dashboard/ApplicationUsage";
import { IGroupAreaRow } from "../../../types/Utils.type";
import {
  TActiveUsersData,
  TDailyData,
} from "../../../types/dashboard/ApplicationUsage.type";
import { echartsExportPng } from "../../../utils/echarts";
import { makeXlsx } from "../../../utils/make-xlsx";
import { DownloadSvg } from "../../assets/svg/Download.svg";
import ButtonFillCustom from "../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../components/Button/ButtonOutlinedCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import { Dropdown, TDropdownValue } from "../group-area/views/Dropdown";
import { useTranslation } from "react-i18next";

const ApplicationUsage = () => {
  const { t } = useTranslation();
  const echartRef1 = useRef<any>();
  const echartRef2 = useRef<any>();

  const navigate = useNavigate();

  const [dailyData, setDailyData] = useState<TDailyData>({
    activeFarmer: [],
    inActiveFarmer: [],
    accActiveFarmer: [],
    accInActiveFarmer: [],
  });
  const [activeData, setActiveData] = useState<TActiveUsersData>({
    activeSeven: [],
    activeFourteen: [],
    activeTwentyone: [],
    // activeThirtyone: [],
  });

  const [yearOption, setYearOption] = useState<string[]>([]);
  const [dailyYear, setDailyYear] = useState<string>(dayjs().year().toString());
  const [activeYear, setActiveYear] = useState<string>(
    dayjs().year().toString()
  );

  const [groupAreaOption, setGroupAreaOption] = useState<IGroupAreaRow[]>([]);
  const [dailyGroupArea, setDailyGroupArea] = useState<TDropdownValue[]>([]);
  const [activeGroupArea, setActiveGroupArea] = useState<TDropdownValue[]>([]);

  const segmentOption = ["Potential", "Grower", "Independent"];
  const [dailySegment, setDailySegment] = useState<TDropdownValue[]>([]);
  const [activeSegment, setActiveSegment] = useState<TDropdownValue[]>([]);

  const fetchYearOption = async () => {
    const data = await getApplicationUsageYear();
    setYearOption(data);
    setDailyYear(data[0] ?? dayjs().year().toString());
    setActiveYear(data[0] ?? dayjs().year().toString());
  };

  const fetchGroupAreaOption = async () => {
    const data = await getApplicationUsageGroupArea();
    setGroupAreaOption(data);
  };

  const fetchDailyData = async () => {
    const data = await getDailyLogUsersGraph({
      year: dailyYear ?? dayjs().year().toString(),
      groupAreaIds: dailyGroupArea[0]?.value,
      segments: dailySegment[0]?.value.toLocaleLowerCase(),
    });

    if (data) {
      const activeFarmer = data.barGraphData.map((item) => item.activeFarmer);
      const inActiveFarmer = data.barGraphData.map(
        (item) => item.inActiveFarmer
      );

      const accActiveFarmer = data.accumulatedGraphData.map(
        (item) => item.activeUserAccumulator
      );
      const accInActiveFarmer = data.accumulatedGraphData.map(
        (item) => item.inActiveUserAccumulator
      );
      setDailyData({
        activeFarmer,
        inActiveFarmer,
        accActiveFarmer,
        accInActiveFarmer,
      });
    }
  };

  const fetchActiveData = async () => {
    const data = await getActiveUsersGraph({
      year: activeYear ?? dayjs().year().toString(),
      groupAreaIds: activeGroupArea[0]?.value,
      segments: activeSegment[0]?.value.toLocaleLowerCase(),
    });
    if (data) {
      const activeSeven = data.map((item) => item.sevenToThirteen);
      const activeFourteen = data.map((item) => item.fourteenToTwenty);
      const activeTwentyone = data.map((item) => item.twentyOneToThirtyOne);
      // const activeThirtyone = data.map((item) => item.twentyTwoToThirtyOne);

      setActiveData({
        activeSeven,
        activeFourteen,
        activeTwentyone,
        // activeThirtyone,
      });
    }
  };

  useEffect(() => {
    fetchYearOption();
    fetchGroupAreaOption();
    fetchDailyData();
    fetchActiveData();
  }, []);

  const exportDailyLogUsers = () => {
    let data: {
      Month: string;
      Active: number | undefined;
      Inactive: number | undefined;
      "Accumulate Active": number | undefined;
      "Accumulate Inactive": number | undefined;
    }[] = [];
    Month.map((item, index) => {
      data.push({
        Month: item,
        Active: dailyData?.activeFarmer[index],
        Inactive: dailyData?.inActiveFarmer[index],
        "Accumulate Active": dailyData?.accActiveFarmer[index],
        "Accumulate Inactive": dailyData?.accInActiveFarmer[index],
      });
    });
    const fileName = `All_Users_Graph_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data || [], fileName, sheetName);
  };

  const exportActiveUsers = () => {
    let data: {
      Month: string;
      "7 Days": number | undefined;
      "14 Days": number | undefined;
      "21 Days": number | undefined;
    }[] = [];
    Month.map((item, index) => {
      data.push({
        Month: item,
        "7 Days": activeData?.activeSeven[index],
        "14 Days": activeData?.activeFourteen[index],
        "21 Days": activeData?.activeTwentyone[index],
      });
    });
    const fileName = `Active_Users_Graph_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data || [], fileName, sheetName);
  };

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <span className="text-[#000] font-bold text-3xl">
              {t("dashboard.applicationUsage.title")}
            </span>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6 grid grid-cols-12 gap-6">
              <div className="grid col-span-12 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-[#000] font-bold text-2xl">
                    {t("dashboard.applicationUsage.dailyLogsUser")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() =>
                        echartsExportPng(echartRef1, "Daily Log’s User")
                      }
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => exportDailyLogUsers()}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3 items-end">
                  <Dropdown
                    label="ปี"
                    options={yearOption.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(value) => {
                      setDailyYear(value[0].value);
                    }}
                    onReset={() => {
                      setDailyYear("");
                    }}
                    renderValueArray={
                      [
                        {
                          value: dailyYear,
                          label: dailyYear,
                        },
                      ] as TDropdownValue[]
                    }
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Group Area"
                    options={groupAreaOption.map((row) => ({
                      value: row.id,
                      label: row.name,
                    }))}
                    onChange={(value) => {
                      setDailyGroupArea(value);
                    }}
                    onReset={() => {
                      setDailyGroupArea([]);
                    }}
                    renderValueArray={dailyGroupArea}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Segment"
                    options={segmentOption.map((row) => ({
                      value: row,
                      label: row,
                    }))}
                    onChange={(value) => setDailySegment(value)}
                    onReset={() => {
                      setDailySegment([]);
                    }}
                    renderValueArray={dailySegment}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <ButtonFillCustom
                    title="ค้นหา"
                    onClick={() => fetchDailyData()}
                    className="!mb-3"
                  />
                </div>
                <div className="flex flex-row justify-end">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      navigate("/admin/application-usage/daily-log-user-table");
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </div>
                <div>
                  <ReactECharts
                    ref={echartRef1}
                    option={{
                      grid: {
                        top: "3%",
                        left: "3%",
                        right: "3%",
                        bottom: "15%",
                        containLabel: true,
                      },
                      xAxis: {
                        type: "category",
                        data: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ],
                        axisLine: {
                          show: false,
                        },
                        axisTick: {
                          show: true,
                        },
                        axisLabel: {
                          fontSize: "10px",
                          fontFamily: "DM Sans",
                          color: "#979797",
                          fontWeight: "normal",
                          interval: 0,
                          hideOverlap: false,
                        },
                      },
                      yAxis: {
                        type: "value",
                        axisLabel: {
                          fontSize: "10px",
                          fontFamily: "DM Sans",
                          color: "#979797",
                          fontWeight: "normal",
                          interval: 0,
                          hideOverlap: false,
                        },
                      },

                      legend: {
                        show: true,
                        bottom: "0",
                        textStyle: {
                          color: "#000000",
                          fontSize: "14px",
                          fontFamily: "DM Sans",
                          fontWeight: "normal",
                        },
                        data: [
                          {
                            name: "Active",
                            icon: "circle",
                            itemStyle: {
                              color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                  {
                                    offset: 0,
                                    color: "#68C184", // color at 0%
                                  },
                                  {
                                    offset: 1,
                                    color: "#77F2A1", // color at 100%
                                  },
                                ],
                                global: false, // default is false
                              },
                            },
                          },
                          {
                            name: "Inactive",
                            icon: "circle",
                            itemStyle: {
                              borderRadius: 50,
                              color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                  {
                                    offset: 0,
                                    color: "#3777BC", // color at 0%
                                  },
                                  {
                                    offset: 1,
                                    color: "#659DDA", // color at 100%
                                  },
                                ],
                                global: false, // default is false
                              },
                            },
                          },
                          {
                            name: "Accumulate Active",
                            itemStyle: {
                              borderRadius: 50,
                            },
                          },
                          {
                            name: "Accumulate Inactive",
                            itemStyle: {
                              borderRadius: 50,
                            },
                          },
                        ],
                      },
                      series: [
                        {
                          name: "Active",
                          data: dailyData?.activeFarmer,
                          type: "bar",
                          smooth: false,
                          itemStyle: {
                            color: () => {
                              return new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                  { offset: 0, color: "#68C184" },
                                  { offset: 1, color: "#77F2A1" },
                                ]
                              );
                            },
                          },
                        },
                        {
                          name: "Inactive",
                          data: dailyData?.inActiveFarmer,
                          type: "bar",
                          smooth: false,
                          itemStyle: {
                            color: () => {
                              return new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                  { offset: 0, color: "#3777BC" },
                                  { offset: 1, color: "#659DDA" },
                                ]
                              );
                            },
                          },
                        },
                        {
                          name: "Accumulate Active",
                          data: dailyData?.accActiveFarmer || [],
                          type: "line",
                          smooth: false,
                          lineStyle: {
                            normal: {
                              color: "#62A665",
                              width: 2,
                              type: "dashed",
                            },
                          },
                          itemStyle: {
                            color: "#62A665",
                          },
                          symbol: "circle",
                          symbolSize: 10,
                        },
                        {
                          name: "Accumulate Inactive",
                          data: dailyData?.accInActiveFarmer || [],
                          type: "line",
                          smooth: false,
                          lineStyle: {
                            normal: {
                              color: "#3777BC",
                              width: 2,
                              type: "dashed",
                            },
                          },
                          itemStyle: {
                            color: "#3777BC",
                          },
                          symbol: "circle",
                          symbolSize: 10,
                        },
                      ],
                      tooltip: {
                        trigger: "axis",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="grid col-span-12 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-[#000] font-bold text-2xl">
                    {t("dashboard.applicationUsage.activeUser")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() =>
                        echartsExportPng(echartRef2, "Active User")
                      }
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => exportActiveUsers()}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3 items-end">
                  <Dropdown
                    label="ปี"
                    options={yearOption.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onChange={(value) => {
                      setActiveYear(value[0].value);
                    }}
                    onReset={() => {
                      setActiveYear("");
                    }}
                    renderValueArray={
                      [
                        {
                          value: activeYear,
                          label: activeYear,
                        },
                      ] as TDropdownValue[]
                    }
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Group Area"
                    options={groupAreaOption.map((row) => ({
                      value: row.id,
                      label: row.name,
                    }))}
                    onChange={(value) => {
                      setActiveGroupArea(value);
                    }}
                    onReset={() => {
                      setActiveGroupArea([]);
                    }}
                    renderValueArray={activeGroupArea}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Segment"
                    options={segmentOption.map((row) => ({
                      value: row,
                      label: row,
                    }))}
                    onChange={(value) => setActiveSegment(value)}
                    onReset={() => {
                      setActiveSegment([]);
                    }}
                    renderValueArray={activeSegment}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <ButtonFillCustom
                    title="ค้นหา"
                    onClick={() => fetchActiveData()}
                    className="!mb-3"
                  />
                </div>
                <div className="flex flex-row justify-end">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      navigate("/admin/application-usage/active-user-table");
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </div>
                <div>
                  <ReactECharts
                    ref={echartRef2}
                    option={{
                      grid: {
                        top: "3%",
                        left: "3%",
                        right: "3%",
                        bottom: "15%",
                        containLabel: true,
                      },
                      xAxis: {
                        type: "category",
                        data: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "May",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec",
                        ],
                        axisLine: {
                          show: false,
                        },
                        axisTick: {
                          show: true,
                        },
                        axisLabel: {
                          fontSize: "10px",
                          fontFamily: "DM Sans",
                          color: "#979797",
                          fontWeight: "normal",
                          interval: 0,
                          hideOverlap: false,
                        },
                      },
                      yAxis: {
                        type: "value",
                        axisLabel: {
                          fontSize: "10px",
                          fontFamily: "DM Sans",
                          color: "#979797",
                          fontWeight: "normal",
                          interval: 0,
                          hideOverlap: false,
                        },
                      },

                      legend: {
                        show: true,
                        bottom: "0",
                        textStyle: {
                          color: "#000000",
                          fontSize: "14px",
                          fontFamily: "DM Sans",
                          fontWeight: "normal",
                        },
                        icon: "circle",
                        data: [
                          {
                            name: "7 Days",
                            itemStyle: {
                              color: "#99D6AC",
                              borderRadius: "50",
                            },
                          },
                          {
                            name: "14 Days",
                            itemStyle: {
                              color: "#68C184",
                              borderRadius: 50,
                            },
                          },
                          {
                            name: "21 Days",
                            itemStyle: {
                              color: "#59976D",
                              borderRadius: 50,
                            },
                          },
                        ],
                      },
                      series: [
                        {
                          name: "7 Days",
                          data: activeData?.activeSeven,
                          type: "bar",
                          stack: "Users",
                          itemStyle: {
                            color: "#99D6AC",
                          },
                        },
                        {
                          name: "14 Days",
                          data: activeData?.activeFourteen,
                          type: "bar",
                          stack: "Users",
                          smooth: false,
                          itemStyle: {
                            color: "#68C184",
                          },
                        },
                        {
                          name: "21 Days",
                          data: activeData?.activeTwentyone,
                          type: "bar",
                          stack: "Users",
                          smooth: false,
                          itemStyle: {
                            color: "#59976D",
                          },
                        },
                      ],
                      label: {
                        show: true,
                        position: "inside",
                        color: "white",
                        fontSize: 10,
                      },
                      tooltip: {
                        trigger: "axis",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default ApplicationUsage;
