import api from "../../../services/ApiService";
import { CreateStaff, IGetHouseStaff, IGetListStaff } from "./staff.type";

export const getHouseStaff = async () => {
  const { data } = await api.get<IGetHouseStaff>(
    `/farmers/account-center/staff/farms`
  );
  return data;
};

export const getListStaff = async () => {
  const { data } = await api.get<IGetListStaff>(
    `/farmers/account-center/staff`
  );
  return data;
};

export const getListStaffById = async (staffId: string) => {
  const { data } = await api.get<IGetListStaff>(
    `/farmers/account-center/staff/` + staffId
  );
  return data;
};

export const createStaff = async (body: CreateStaff) => {
  const { data } = await api.post(`/farmers/account-center/staff`, body);
  return data;
};

export const editStaff = async (id: string, body: CreateStaff) => {
  const { data } = await api.put(`/farmers/account-center/staff/` + id, body);
  return data;
};

export const deleteStaff = async (id: string) => {
  const { data } = await api.delete(`farmers/account-center/staff/` + id);
  return data;
};
