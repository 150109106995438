import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import get from "lodash/get";
import { memo, useCallback } from "react";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import AppBarFS from "../../../../components/app-bar/AppBar";
import { tw } from "../../../../utils/tw";
import { useActionController } from "../../actionController";
import { dialogWord } from "../../constants";
import Dialog from "../../views/Dialog";
import { useProveShippingController } from "./controller";
import SummaryModalPage from "./views/SummaryModalPage";

const classes = {
  container: tw(`flex flex-col min-h-screen w-full pb-[24px]`),
  appbarContainer: tw(`py-4`),
  title: tw(
    `text-[18px] leading-[18px] text-tertiary font-semibold mb-[24px] px-[16px]`
  ),
  uploadContainer: tw(`px-[32px] flex flex-col w-full`),
  helperText: tw(`text-[14px] leading-[18px] text-tertiary mt-[8px]`),
  actionButton: tw(
    `self-center font-bold max-w-[450px] bg-secondary transition-all hover:brightness-105 duration-150 rounded-full py-[13.5px] flex justify-center items-center cursor-pointer text-center text-[16px] leading-[20.8px] text-[white]`,
    "w-[calc(100%-32px)]"
  ),
  upload: {
    container: tw(
      `w-[97px] h-[97px] rounded-[17px] flex flex-col justify-center items-center border-[0.76px] border-[#97BFEA] cursor-pointer bg-[#F6FBFF] hover:brightness-105 duration-150 transition-all`
    ),
    listContainer: tw(`flex flex-row flex-wrap gap-3`),
    // image container
    image: tw(
      `w-[98px] h-[98px] rounded-[17px] hover:brightness-110 duration-150 transition-all relative`
    ),
    remove: tw(
      `absolute top-[5.3px] right-[5.3px] w-[19px] h-[19px] rounded-full z-10 bg-[rgba(255,255,255,0.9)] flex justify-center items-center cursor-pointer`
    ),
    img: tw(`w-full h-full object-cover rounded-[17px]`),
  },
};

interface IUploaderProps {
  maxFiles: number;
  onDrop: (images: File[]) => void;
}

const Uploader = memo((props: IUploaderProps) => {
  const { maxFiles, onDrop } = props;

  return (
    <Dropzone
      accept={{ "image/*": [] }}
      maxFiles={maxFiles}
      maxSize={100 * 1024 * 1024} // 100mb
      onDrop={onDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={classes.upload.container}>
            <InsertPhotoIcon className="!text-[#97BFEA] !text-[26px] !mb-[7.6px]" />
            <div className="flex flex-row gap-x-[2px] items-center -ml-[2px] -mb-[2px]">
              <AddIcon className="!text-[#97BFEA] !text-[14px] -mt-[2px]" />
              <div className="text-[14px] leading-[18px] text-[#97BFEA]">
                Photo
              </div>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
});

const ProveShipping = () => {
  const { orderId } = useParams();
  const [searchParams] = useSearchParams();
  const isEdit = searchParams.get("isEdit") === "yes";

  const {
    onBack,
    images1,
    images2,
    setImages1,
    setImages2,
    isShowConfirmModal,
    onShowConfirmShipped,
    onCloseConfirmShipped,
    isShowConfirmEditImage,
    onShowConfirmEditImage,
    onCloseConfirmEditImage,
    isShowSummary,
    onShowSummary,
    onCloseSummary,
    onRemoveImage,
    onAddImage,
    orderDetail,
    onConfirmEdit,
  } = useProveShippingController(orderId!, isEdit);

  const { actionFunctions } = useActionController({
    orderDetail,
    onCloseModal: () => {},
    isNotClose: true,
  });

  const onSave = useCallback(() => {
    actionFunctions.proveShipped([...images1, ...images2]);
    onShowSummary();
  }, [actionFunctions.proveShipped, [...images1, ...images2]]);

  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <div className={classes.appbarContainer}>
        <AppBarFS
          title={t("PartnerOrder.UploadEvidenceDelivery")}
          onIconPress={onBack}
        />
      </div>
      <div className={classes.title}>
        {t("PartnerOrder.UploadImageEvidenceDelivery")} (Optional)
      </div>
      <div className={tw(classes.uploadContainer, "mb-[24px]")}>
        <div className={classes.upload.listContainer}>
          {images1.map((image, index) => {
            const url =
              image instanceof File ? URL.createObjectURL(image) : image.url;
            return (
              <div key={`${index}`} className={classes.upload.image}>
                <div
                  onClick={onRemoveImage("image1", index)}
                  className={classes.upload.remove}
                >
                  <Close className="!text-[#979797] !text-[15px] -mr-[0.6px]" />
                </div>
                <img src={url} className={classes.upload.img} />
              </div>
            );
          })}
          <Uploader onDrop={onAddImage("image1")} maxFiles={2} />
        </div>
        <div className={classes.helperText}>
          {t("reportOrder.picturesOfReceipt")}
        </div>
      </div>
      <div className={classes.title}>{t("PartnerOrder.UploadPickingSlip")}</div>
      <div className={classes.uploadContainer}>
        <div className={classes.upload.listContainer}>
          {images2.map((image, index) => {
            const url =
              image instanceof File ? URL.createObjectURL(image) : image.url;
            return (
              <div key={`${index}`} className={classes.upload.image}>
                <div
                  onClick={onRemoveImage("image2", index)}
                  className={classes.upload.remove}
                >
                  <Close className="!text-[#979797] !text-[15px] -mr-[0.6px]" />
                </div>
                <img src={url} className={classes.upload.img} />
              </div>
            );
          })}
          <Uploader onDrop={onAddImage("image2")} maxFiles={1} />
        </div>
        <div className={classes.helperText}>
          {t("FinancialSupport.picturesOfReceipt")}
        </div>
      </div>
      <div className="flex flex-1" />
      <button
        onClick={!isEdit ? onShowConfirmShipped : onShowConfirmEditImage}
        disabled={images2.length === 0}
        className={tw(
          classes.actionButton,
          images2.length === 0 &&
            "bg-[#E2E2E2] text-[#979797] hover:brightness-100 cursor-default"
        )}
      >
        {t("FinancialSupport.Confirm")}
      </button>
      <Dialog
        isOpen={isShowConfirmModal}
        withoutCloseIcon
        title={get(dialogWord, [orderDetail?.status || "-", "title"], "-")}
        text={get(dialogWord, [orderDetail?.status || "-", "text"], "-")}
        confirmText={t("FinancialSupport.Confirm")}
        cancelText={t("FinancialSupport.Cancel")}
        onClose={onCloseConfirmShipped}
        onConfirm={onSave}
        onCancel={onCloseConfirmShipped}
      />
      <Dialog
        isOpen={isShowConfirmEditImage}
        title={`${t("PartnerOrder.ConfirmTitleOne")}\n${t(
          "PartnerOrder.ConfirmTitleTwo"
        )}`}
        text={`${t("PartnerOrder.ConfirmTextOne")}\n${t(
          "PartnerOrder.ConfirmTextTwo"
        )}\n${t("PartnerOrder.ConfirmTextThree")}`}
        actionContainerClassName="flex flex-col gap-y-3 mt-6 pb-0"
        confirmText={t("FinancialSupport.Confirm")}
        cancelText={t("PartnerOrder.Back")}
        onClose={onCloseConfirmEditImage}
        onConfirm={onConfirmEdit}
        onCancel={onCloseConfirmEditImage}
      />
      <SummaryModalPage
        isOpen={isShowSummary}
        onClose={onCloseSummary}
        images={[...images1, ...images2]}
        orderDetail={orderDetail}
      />
    </div>
  );
};

export default ProveShipping;
