import { apiPaths } from "../../const/apiPaths";
import { IGetGroupArea, IGetYear } from "../../types/Utils.type";
import {
  IFeedCustomerGradeGraphResponse,
  IFeedCustomerGraphResponse,
  IListFeedCustomerGradeResponse,
  IListFeedCustomerResponse,
} from "../../types/dashboard/FeedCustomer.type";
import adminAPI from "../AdminApiService";

export const getFeedCustomerYear = async () => {
  const { data } = await adminAPI.get<IGetYear>(apiPaths.feedCustomer.getYear);
  return data.rows;
};

export const getFeedCustomerGroupArea = async () => {
  const { data } = await adminAPI.get<IGetGroupArea>(
    apiPaths.feedCustomer.getGroupArea
  );
  return data.rows;
};

export const getFeedCustomerGraph = async (params: {
  year: string;
  groupAreaIds?: string;
  segments?: string;
}) => {
  try {
    const { data } = await adminAPI.get<IFeedCustomerGraphResponse>(
      apiPaths.feedCustomer.getFeedCustomerGraph,
      {
        params: {
          year: params.year,
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
        },
      }
    );
    return data.result;
  } catch (error) {
    console.log("getFeedCustomerGraph", error);
    return null;
  }
};

export const getListFeedCustomer = async (params: {
  year: string;
  months?: string;
  contractType?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IListFeedCustomerResponse>(
      apiPaths.feedCustomer.getListFeedCustomer,
      {
        params: {
          year: params.year,
          months: params?.months,
          contractType: params?.contractType,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListFeedCustomer", error);
    return null;
  }
};

export const getListFeedCustomerExport = async (params: {
  year: string;
  month?: string;
  contractType?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IListFeedCustomerResponse>(
      apiPaths.feedCustomer.getListFeedCustomer,
      {
        params: {
          year: params.year,
          month: params?.month,
          contractType: params?.contractType,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListFeedCustomerExport", error);
    return null;
  }
};

export const getFeedCustomerGradeGraph = async (params: {
  year: string;
  groupAreaIds?: string;
  segments?: string;
}) => {
  try {
    const { data } = await adminAPI.get<IFeedCustomerGradeGraphResponse>(
      apiPaths.feedCustomer.getFeedCustomerGradeGraph,
      {
        params: {
          year: params.year,
          groupAreaIds: params?.groupAreaIds,
          segments: params?.segments,
        },
      }
    );
    return data.result;
  } catch (error) {
    console.log("getFeedCustomerGradeGraph", error);
    return null;
  }
};

export const getListFeedCustomerGrade = async (params: {
  year: string;
  month?: string;
  customerGrade?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IListFeedCustomerGradeResponse>(
      apiPaths.feedCustomer.getListFeedCustomerGrade,
      {
        params: {
          year: params.year,
          months: params?.month,
          customerGrade: params?.customerGrade,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListFeedCustomerGrade", error);
    return null;
  }
};

export const getListFeedCustomerGradeExport = async (params: {
  year: string;
  month?: string;
  customerGrade?: string;
  segments?: string;
  groupAreaIds?: string;
  searchText?: string;
  orderBy?: string;
  direction?: string;
  page: number;
  limit: number;
}) => {
  try {
    const { data } = await adminAPI.get<IListFeedCustomerGradeResponse>(
      apiPaths.feedCustomer.getListFeedCustomerGrade,
      {
        params: {
          year: params.year,
          months: params?.month,
          customerGrade: params?.customerGrade,
          segments: params?.segments,
          groupAreaIds: params?.groupAreaIds,
          searchText: params?.searchText,
          orderBy: params?.orderBy,
          direction: params?.direction,
          page: params.page,
          limit: params.limit,
        },
      }
    );
    return data.rows;
  } catch (error) {
    console.log("getListFeedCustomerGradeExport", error);
    return null;
  }
};
