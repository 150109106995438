import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonOutlinedCustom from "../../../../admin/components/Button/ButtonOutlinedCustom";
import PersonSvg from "../../../../assets/svg/PersonSvg";
import AppBarFS from "../../../../components/app-bar/AppBar";
import BasicDetailTable, {
  IDataTable,
} from "../../../../components/common/Table/BasicDetailTable";
import { DATE_FORMAT_ENG } from "../../../../const/app.constant";
import RemoveStaffNoticePopup from "../RemoveStaffNoticePopup";
import { getListStaffById } from "../staff.service";
import { IStaffDetail } from "../staff.type";

type Props = {};

function TrashRedSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8 2.40039C10.3454 2.40039 9.92993 2.65719 9.72666 3.06373L8.85834 4.80039H4.79998C4.13723 4.80039 3.59998 5.33765 3.59998 6.00039C3.59998 6.66313 4.13723 7.20039 4.79998 7.20039L4.79998 19.2004C4.79998 20.5259 5.87449 21.6004 7.19998 21.6004H16.8C18.1255 21.6004 19.2 20.5259 19.2 19.2004V7.20039C19.8627 7.20039 20.4 6.66313 20.4 6.00039C20.4 5.33765 19.8627 4.80039 19.2 4.80039H15.1416L14.2733 3.06373C14.07 2.65719 13.6545 2.40039 13.2 2.40039H10.8ZM8.39998 9.60039C8.39998 8.93765 8.93723 8.40039 9.59998 8.40039C10.2627 8.40039 10.8 8.93765 10.8 9.60039V16.8004C10.8 17.4631 10.2627 18.0004 9.59998 18.0004C8.93723 18.0004 8.39998 17.4631 8.39998 16.8004V9.60039ZM14.4 8.40039C13.7372 8.40039 13.2 8.93765 13.2 9.60039V16.8004C13.2 17.4631 13.7372 18.0004 14.4 18.0004C15.0627 18.0004 15.6 17.4631 15.6 16.8004V9.60039C15.6 8.93765 15.0627 8.40039 14.4 8.40039Z"
        fill="#D32F2F"
      />
    </svg>
  );
}

function HouseSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M21.7649 9.50206L19.2786 5.11258C19.2555 5.06724 19.2185 5.03549 19.1723 5.01282L11.4315 1.25818C11.3621 1.22644 11.2789 1.22644 11.2096 1.25818L3.4688 4.92213C3.42721 4.94027 3.39023 4.97201 3.36713 5.01282L0.501867 9.41137C0.395575 9.57008 0.511109 9.78321 0.705208 9.78774L1.43539 9.81041C1.56941 9.81041 1.6757 9.92378 1.6757 10.0553V19.1879C1.6757 19.9407 2.29497 20.5483 3.06212 20.5483H19.3109C20.0781 20.5483 20.6973 19.9407 20.6973 19.1879V10.1142C20.6973 9.97819 20.8083 9.86936 20.9469 9.86936H21.5431C21.7325 9.86936 21.8527 9.66984 21.7603 9.50206H21.7649ZM11.1172 5.67034C11.8705 5.67034 12.4805 6.2689 12.4805 7.00804C12.4805 7.74718 11.8705 8.34574 11.1172 8.34574C10.3639 8.34574 9.75389 7.74718 9.75389 7.00804C9.75389 6.2689 10.3639 5.67034 11.1172 5.67034ZM16.6998 16.5397C16.6998 17.2653 16.0991 17.8548 15.3596 17.8548H6.87476C6.13534 17.8548 5.53456 17.2653 5.53456 16.5397V13.6059C5.53456 12.8803 6.13534 12.2908 6.87476 12.2908H15.355C16.0944 12.2908 16.6952 12.8803 16.6952 13.6059V16.5397H16.6998Z"
        fill="#68C184"
      />
    </svg>
  );
}

const StaffDetail = (props: Props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { t } = useTranslation();
  const [staff, setStaff] = useState<IDataTable[]>([]);
  const [manage, setManage] = useState<IDataTable[]>([]);
  const [isOpenRemovePopup, setIsOpenRemovePopup] = useState(false);
  const [staffDetail, setStaffDetail] = useState<IStaffDetail[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const data = await getListStaffById(state);
      if (data) {
        setStaffDetail(data.staffs);
      }
    };
    fetch();
  }, []);

  useEffect(() => {
    setStaff([
      {
        detailName: t("RoleAndPermission.Name"),
        detailValue: staffDetail[0]?.name,
        color: "#34407B",
        fontSize: "0.875rem",
      },
      {
        detailName: t("RoleAndPermission.Role"),
        detailValue: staffDetail[0]?.type,
        color: "#34407B",
        fontSize: "0.875rem",
      },
      {
        detailName: t("RoleAndPermission.PIN"),
        detailValue: staffDetail[0]?.initialPin,
        color: "#34407B",
        fontSize: "0.875rem",
      },
      {
        detailName: t("RoleAndPermission.AccountCreationDate"),
        detailValue: dayjs(staffDetail[0]?.createdAt).format(DATE_FORMAT_ENG),
        color: "#34407B",
        fontSize: "0.875rem",
      },
    ]);

    setManage(
      staffDetail?.[0]?.farms.map((farm) => ({
        detailName: farm.name,
        detailValue: farm.houses.map((house) => house.name).join(" ,"),
        color: "#34407B",
        fontSize: "0.875rem",
      })) || []
    );
  }, [staffDetail]);

  return (
    <div className="!h-screen !min-h-screen flex flex-col overflow-scroll">
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          onIconPress={() => navigate("/partner/account-center/staff")}
          title={t("RoleAndPermission.StaffDetail")}
        />
      </div>
      <div className="mx-4">
        <div className="mb-4">
          <div className="relative">
            <div className="bg-[#D9EBFE] flex items-center p-4 rounded-t-[20px] justify-between">
              <span className="text-[#4B4B4B] font-bold flex items-center gap-3 text-[16px]">
                <PersonSvg /> {staffDetail[0]?.userName}
              </span>
              <div
                className="text-[#4B4B4B] font-medium"
                onClick={() => setIsOpenRemovePopup(true)}
              >
                <TrashRedSvg />
              </div>
            </div>
          </div>
          <BasicDetailTable
            tableData={staff}
            tableProps={{
              size: "medium",
            }}
            swapCellColor={true}
            borderCell="0px !important"
            isNewFormat={true}
          />
        </div>
        <div>
          <div className="relative">
            <div className="bg-[#D9EBFE] flex items-center p-4 rounded-t-[20px] justify-between">
              <span className="text-[#4B4B4B] font-bold flex items-center gap-3 text-[16px]">
                <HouseSvg /> {t("RoleAndPermission.Management")}
              </span>
            </div>
          </div>
          <BasicDetailTable
            tableData={manage}
            tableProps={{
              size: "medium",
            }}
            swapCellColor={true}
            borderCell="0px !important"
            isNewFormat={true}
          />
        </div>
      </div>
      <div className="flex mt-auto mx-4 mb-4">
        <ButtonOutlinedCustom
          onClick={() =>
            navigate(
              "/partner/account-center/edit-staff/" + staffDetail[0].id,
              { state: staffDetail[0].id }
            )
          }
          title={t("manageStock.edit")}
          btnTextColor="#68C184"
          btnBorderColor="#68C184"
          className={"!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold"}
        />
      </div>
      <RemoveStaffNoticePopup
        id={staffDetail[0]?.id}
        isOpen={isOpenRemovePopup}
        handleClose={() => setIsOpenRemovePopup(false)}
      />
    </div>
  );
};

export default StaffDetail;
