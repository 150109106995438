import { Card } from "@mui/material";
import React, { useEffect, useRef } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

type MySunEditorProps = {
  initialTempVar?: string[];
  onContentChange?: (content: string) => void;
  initialContent: string;
};

const MySunEditor: React.FC<MySunEditorProps> = ({
  initialTempVar = [],
  onContentChange,
  initialContent,
}) => {
  const editor = useRef<any>(null);

  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };

  const handleEditorChange = (content: string) => {
    const scrollPosition =
      editor.current.core.context.element.wysiwyg.scrollTop;
    if (onContentChange) {
      onContentChange(content);
    }
    setTimeout(() => {
      editor.current.core.context.element.wysiwyg.scrollTop = scrollPosition;
    }, 0);
  };

  useEffect(() => {
    if (editor.current && initialContent !== editor.current.getContents()) {
      editor.current.setContents(initialContent);
    }
  }, [initialContent]);

  return (
    <>
      <Card>
        <div>
          <SunEditor
            setOptions={{
              buttonList: [
                ["font", "fontSize"],
                ["bold", "underline", "italic", "strike"],
                ["fontColor", "hiliteColor"],
                ["undo", "redo"],
                ["removeFormat"],
              ],
            }}
            getSunEditorInstance={getSunEditorInstance}
            onChange={handleEditorChange}
            height="60vh"
            autoFocus={true}
            defaultValue={initialContent}
          />
        </div>
      </Card>
    </>
  );
};

export default MySunEditor;
