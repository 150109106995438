import { Divider, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { thousandComma } from "../../../../utils/common/string";
import PlusCircleIcon from "../../../assets/svg/PlusCircleIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import MenuBar from "../../../components/MenuBar/MenuBar";
import { TabPanelCustom } from "../../../components/TabPanelCustom/TabPanelCustom";
import CreateSaleTarget from "../components/CreateSaleTarget";
import UserTabs from "../components/UserTabs";
import { getCountSaleTarget } from "../services/saleTarget.service";
import { ISaleTargetResult } from "../types/SaleTargetTable.type";

type Props = {};

const tabNames = [
  "user",
  "customer",
  "feed_volume",
  "feed_revenue",
  "basic_partner",
  "core_partner",
];

const SaleTargetSale = (props: Props) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabName, setTabName] = useState<string>("user");
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [saleTargetCount, setSaleTargetCount] = useState<ISaleTargetResult>();

  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
    setTabName(tabNames[value]);
  };

  const fetchSaleTargetCount = async () => {
    const data = await getCountSaleTarget();
    if (data) {
      setSaleTargetCount(data?.result);
    }
  };

  useEffect(() => {
    fetchSaleTargetCount();
  }, []);

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <div className="flex justify-between">
              <span className="text-[#000] font-bold text-3xl">
                Sale Target
              </span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="flex space-x-4 my-6">
              <div className="border-solid border-2 border-[#68C184] py-5 px-6 rounded-2xl w-[33%]">
                <div className="mt-3">
                  <p className="text-[24px] font-extrabold font-sans">
                    <span className="text-[#151D48] text-[24px] font-semibold font-[DM Sans]">
                      {`${thousandComma(saleTargetCount?.userCount || 0)} คน`}
                    </span>
                  </p>
                  <p className="font-sans text-[16px] font-medium text-[#425166]">
                    ผู้ใช้
                  </p>
                </div>
              </div>
              <div className="border-solid border-2 border-[#68C184] py-5 px-6 rounded-2xl w-[33%]">
                <div className="mt-3">
                  <p className="text-[24px] font-extrabold font-sans">
                    <span className="text-[#151D48] text-[24px] font-semibold font-[DM Sans]">
                      {`${thousandComma(
                        saleTargetCount?.customerCount || 0
                      )} คน`}
                    </span>
                  </p>
                  <p className="font-sans text-[16px] font-medium text-[#425166]">
                    ลูกค้า
                  </p>
                </div>
              </div>
              <div className="border-solid border-2 border-[#68C184] py-5 px-6 rounded-2xl w-[33%]">
                <div className="mt-3">
                  <p className="text-[24px] font-extrabold font-sans">
                    <span className="text-[#151D48] text-[24px] font-semibold font-[DM Sans]">
                      {`${thousandComma(
                        saleTargetCount?.feedVolumeCount || 0
                      )} ตัน`}
                    </span>
                  </p>
                  <p className="font-sans text-[16px] font-medium text-[#425166]">
                    Selling feed volume
                  </p>
                </div>
              </div>
            </div>
            <div className="flex space-x-4 my-6">
              <div className="border-solid border-2 border-[#68C184] py-5 px-6 rounded-2xl w-[33%]">
                <div className="mt-3">
                  <p className="text-[24px] font-extrabold font-sans">
                    <span className="text-[#151D48] text-[24px] font-semibold font-[DM Sans]">
                      {`${thousandComma(
                        saleTargetCount?.feedRevenueCount || 0
                      )} บาท`}
                    </span>
                  </p>
                  <p className="font-sans text-[16px] font-medium text-[#425166]">
                    Selling feed revenue
                  </p>
                </div>
              </div>
              <div className="border-solid border-2 border-[#68C184] py-5 px-6 rounded-2xl w-[33%]">
                <div className="mt-3">
                  <p className="text-[24px] font-extrabold font-sans">
                    <span className="text-[#151D48] text-[24px] font-semibold font-[DM Sans]">
                      {`${thousandComma(
                        saleTargetCount?.basicPartnerCount || 0
                      )} คน`}
                    </span>
                  </p>
                  <p className="font-sans text-[16px] font-medium text-[#425166]">
                    Basic Partner
                  </p>
                </div>
              </div>
              <div className="border-solid border-2 border-[#68C184] py-5 px-6 rounded-2xl w-[33%]">
                <div className="mt-3">
                  <p className="text-[24px] font-extrabold font-sans">
                    <span className="text-[#151D48] text-[24px] font-semibold font-[DM Sans]">
                      {`${thousandComma(
                        saleTargetCount?.corePartnerCount || 0
                      )} คน`}
                    </span>
                  </p>
                  <p className="font-sans text-[16px] font-medium text-[#425166]">
                    Core Partner
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <p className="font-sans text-[24px] font-bold text-[#68C184]">
                ประวัติ target
              </p>
              <ButtonFillCustom
                title={"เพิ่ม"}
                startIcon={<PlusCircleIcon />}
                onClick={() => setIsOpenCreateModal(true)}
              />
            </div>
            <div className="pt-6">
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label="sale-order-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
                sx={{
                  height: "36px",
                  minHeight: "unset",
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-flexContainer": {
                      alignItems: "end",
                      height: "100%",
                      display: "flex",
                      gap: "12px",
                      "& .MuiTab-root": {
                        padding: "6px 28px",
                        textTransform: "unset",
                        fontFamily: "IBM Plex Sans Thai",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "unset",
                        color: "#000000",
                        borderBottom: "3px solid #DDF3E4",
                        "& .Mui-selected": {
                          color: "#000000",
                        },
                      },
                    },
                  },
                }}
              >
                <Tab label={`ผู้ใช้`} />
                <Tab label={`ลูกค้า`} />
                <Tab label={`Selling Feed Volume`} />
                <Tab label={`Selling Feed Revenue`} />
                <Tab label={`Basic Partner`} />
                <Tab label={`Core Partner`} />
              </Tabs>
            </div>
            <TabPanelCustom
              value={tabIndex}
              index={0}
              prefix="sale-target-user"
            >
              <UserTabs tabName="user" />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={1}
              prefix="sale-target-customer"
            >
              <UserTabs tabName="customer" />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={2}
              prefix="sale-target-selling-feed-volume"
            >
              <UserTabs tabName="feed_volume" />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={3}
              prefix="sale-target-selling-feed-revenue"
            >
              <UserTabs tabName="feed_revenue" />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={4}
              prefix="sale-target-basic-partner"
            >
              <UserTabs tabName="basic_partner" />
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={5}
              prefix="sale-target-core-partner"
            >
              <UserTabs tabName="core_partner" />
            </TabPanelCustom>
          </div>
        </div>
      </MenuBar>
      <CreateSaleTarget
        tabName={tabName}
        isOpen={isOpenCreateModal}
        handleClose={(isSuccess) => {
          if (isSuccess) {
            //todo : handle fetch data on save success
          }
          setIsOpenCreateModal(false);
        }}
      />
    </>
  );
};

export default SaleTargetSale;
