import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MenuItem, SelectChangeEvent, SvgIcon } from "@mui/material";
import { FormEvent, useEffect, useState } from "react";

import { tw } from "../../../../../utils/tw";
import { DownloadSvg } from "../../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../../components/Button/ButtonOutlinedCustom";
import TextInputSearchCustom from "../../../../components/Input/TextInputSearchCustom";
import SelectAdminCustom from "../../../../components/Select/Select";
import { TUseMoneyDebtFilter } from "../hooks/useMoneyDebtFilter.hook";
import { useTranslation } from "react-i18next";

const classes = {
  flexRow: tw(`flex flex-row`),
  flexCol: tw(`flex flex-col`),
  flex: tw(`flex`),
  fullScreen: tw(`w-full h-full`),
  label: tw(`text-sm font-medium font-sans`),
};

export type TDropdownFarmSize = "0_50" | "51_500" | "501_1000" | "1000UP" | "";

interface IFarmDetailFilter {
  searchMoneyDeptFilter: TUseMoneyDebtFilter;
  itemSelected: string[];
  onExport: () => void;
  onSearch: () => void;
  showSelect?: boolean;
}

export function MoneyDebtSearch({
  searchMoneyDeptFilter,
  itemSelected,
  onExport,
  onSearch,
  showSelect,
}: IFarmDetailFilter) {
  const { t } = useTranslation();
  const [toggleClear, setToggleClear] = useState(false);

  const statusOption = [
    {
      name: "ยังไม่ได้ชำระ",
      key: "unpaid",
    },
    {
      name: "อยู่ระหว่างตรวจสอบ",
      key: "pending_review",
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchMoneyDeptFilter.setPage(1);
    onSearch();
  };

  useEffect(() => {
    onSearch();
  }, [toggleClear]);

  const handleChangeStatus = (e: SelectChangeEvent<unknown>) => {
    searchMoneyDeptFilter.setStatus(e.target.value as string);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className={classes.fullScreen}>
          {showSelect && (
            <div className="flex justify-between">
              <div className="w-[470px]">
                <span className={classes.label}>สถานะ</span>
                <div className="pt-2">
                  <SelectAdminCustom
                    value={searchMoneyDeptFilter.status}
                    placeholder="เลือก"
                    menuItems={(statusOption || []).map((row) => (
                      <MenuItem key={row.key} value={row.key}>
                        {row.name}
                      </MenuItem>
                    ))}
                    renderValueArray={(statusOption || []).map((row) => ({
                      value: row.key,
                      label: row.name,
                    }))}
                    endAdornment={
                      searchMoneyDeptFilter.status !== "" ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "16px !important",
                            marginRight: "20px",
                          }}
                          onClick={(_) => {
                            searchMoneyDeptFilter.setStatus("");
                            setToggleClear((prev) => !prev);
                          }}
                        />
                      ) : null
                    }
                    onChange={(e) => handleChangeStatus(e)}
                  />
                </div>
              </div>

              <div className="h-full self-end">
                <ButtonFillCustom type="submit" title="ค้นหา" />
              </div>
            </div>
          )}
          <div className={tw(classes.flexRow, "w-full justify-between pt-6")}>
            <div className="flex w-full max-w-[656px]">
              <div className="w-full max-w-[470px] mr-4">
                <TextInputSearchCustom
                  value={searchMoneyDeptFilter.searchText}
                  placeholder="ค้นหาโดยเลข invoice"
                  onChange={(event) =>
                    searchMoneyDeptFilter.setSearchText(event.target.value)
                  }
                  InputProps={{
                    endAdornment:
                      searchMoneyDeptFilter.searchText.length > 0 ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(_) => {
                            searchMoneyDeptFilter.setSearchText("");
                            setToggleClear((prev) => !prev);
                          }}
                        />
                      ) : null,
                  }}
                />
              </div>
              {itemSelected.length > 0 && (
                <div className="w-full max-w-[170px] flex items-center font-sans text-[14px] text-[#646464]">
                  {t("common.checked", { number: itemSelected.length })}
                </div>
              )}
            </div>
            <ButtonOutlinedCustom
              disabled={itemSelected.length === 0}
              title={t("common.download")}
              onClick={onExport}
              btnTextColor="#68C184"
              btnBorderColor="#68C184"
              startIcon={
                <SvgIcon
                  sx={{
                    fontSize: "14px !important",
                    color: "currentColor",
                    marginBottom: "3px",
                  }}
                  fontSize="small"
                >
                  <DownloadSvg />
                </SvgIcon>
              }
            />
          </div>
        </div>
      </form>
    </>
  );
}
