import { Box } from "@mui/material";
import { useState } from "react";
import { UserRole } from "../../../../utils/enum/user-role.enum";
import { useAdminAuth } from "../../../components/AdminAuth/AdminAuthProvider";
import "./Tabs.scss";
import CreditHoldOrderTab from "./Tabs/CreditHoldOrderTab";
import SummitToLendingTab from "./Tabs/SummitToLendingTab";
import { ICreditHoldOrderPanelProps } from "./types";
import { useTranslation } from "react-i18next";

export const ChipCount = ({ value }: { value: number }) => {
  return (
    <Box
      component="div"
      sx={{
        color: "#FFFFFF",
        bgcolor: "#5572EB",
        width: "fit-content",
        height: "24px",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        textAlign: "center",
        borderRadius: "99999999px",
        padding: "0 8px",
      }}
    >
      <span className="flex h-full items-center">{value}</span>
    </Box>
  );
};

function CreditHoldOrderPanel({
  setCountCreditHoldOrder,
}: ICreditHoldOrderPanelProps) {
  const { user } = useAdminAuth();
  const isLending = user?.userRole === UserRole.Lending;

  const { t } = useTranslation();

  const [toggleState, setToggleState] = useState(1);
  const [countWaitingToApproveCredit, setCountWaitingToApproveCredit] =
    useState(0);
  const [countWaitingSendToApproveCredit, setCountWaitingSendToApproveCredit] =
    useState(0);
  const toggleTab = (index: number) => {
    setToggleState(index);
  };

  return (
    <div className="w-full h-full pt-8">
      <div className="fs-tab-custom-container">
        <div className="fs-tab-custom-bloc-tabs">
          <button
            className={`fs-tab-custom-button ${
              toggleState === 1
                ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                : "fs-tab-custom-tabs"
            }`}
            onClick={() => toggleTab(1)}
          >
            <div className="flex w-full justify-center gap-3 items-center">
              {t("saleOrder.creditHoldOrdersTabTitle")}
              <ChipCount value={countWaitingSendToApproveCredit} />
            </div>
          </button>
          <button
            className={`fs-tab-custom-button ${
              toggleState === 2
                ? "fs-tab-custom-tabs fs-tab-custom-active-tabs"
                : "fs-tab-custom-tabs"
            }`}
            onClick={() => toggleTab(2)}
          >
            <div className="flex w-full justify-center gap-3 items-center">
              {isLending
                ? t("saleOrder.creditHoldOrdersTab.title")
                : t("saleOrder.submitToLendingTab.title")}
              <ChipCount value={countWaitingToApproveCredit} />
            </div>
          </button>
        </div>
      </div>
      <div className="fs-tab-custom-content-tabs">
        <div
          className={
            toggleState === 1
              ? "fs-tab-custom-content  fs-tab-custom-active-content"
              : "fs-tab-custom-content"
          }
        >
          <CreditHoldOrderTab
            currentTab={toggleState}
            setCount={(valueTab1, valueTab2) => {
              setCountWaitingSendToApproveCredit(valueTab1);
              setCountWaitingToApproveCredit(valueTab2);
              setCountCreditHoldOrder(valueTab1 + valueTab2);
            }}
          />
        </div>

        <div
          className={
            toggleState === 2
              ? "fs-tab-custom-content  fs-tab-custom-active-content"
              : "fs-tab-custom-content"
          }
        >
          <SummitToLendingTab
            currentTab={toggleState}
            setCount={(valueTab1, valueTab2) => {
              setCountWaitingSendToApproveCredit(valueTab1);
              setCountWaitingToApproveCredit(valueTab2);
              setCountCreditHoldOrder(valueTab1 + valueTab2);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CreditHoldOrderPanel;
