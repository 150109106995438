export default function () {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15.5469H10.0083M6.66667 18.0469H13.3333C14.2538 18.0469 15 17.3007 15 16.3802V4.71354C15 3.79307 14.2538 3.04688 13.3333 3.04688H6.66667C5.74619 3.04688 5 3.79307 5 4.71354V16.3802C5 17.3007 5.74619 18.0469 6.66667 18.0469Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
