import dayjs from "dayjs";
import { useState } from "react";
import { DATE_FORMAT_DATE_PICKER2 } from "../../../../../const/app.constant";
import { thousandComma } from "../../../../../utils/common/string";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import {
  exportExcelSaleDebt,
  getAcceptDebt,
} from "../services/acceptDebt.service";
import {
  AcceptDebtRow,
  TDebtInstallmentStatus,
  TMoneyDebtTable,
} from "../types/acceptDebt.type";
import { TUseMoneyDebtFilter } from "./useMoneyDebtFilter.hook";

export const useMoneyDebtTableHook = (
  searchMoneyDebtFilter: TUseMoneyDebtFilter,
  tab: string
) => {
  const [itemSelected, setItemSelected] = useState<string[]>([]);
  const [list, setList] = useState<TMoneyDebtTable[]>([]);
  const [count, setCount] = useState(0);
  const [debtInstallment, setDeptInstallment] = useState<
    AcceptDebtRow[] | undefined
  >();

  const createData = (
    id: string,
    orderId: string,
    customerId: string,
    customerName: string,
    invoiceNumber: string,
    payment: string,
    paidAmount: string,
    monthsOfInstallment: string,
    paymentPerMonth: string,
    totalPaid: string,
    dueDate: string,
    updateDate: string,
    status: TDebtInstallmentStatus
  ): TMoneyDebtTable => {
    return {
      id,
      orderId,
      customerId,
      customerName,
      invoiceNumber,
      payment,
      paidAmount: paidAmount ? thousandComma(+paidAmount) + " บาท" : "-",
      monthsOfInstallment: monthsOfInstallment ? monthsOfInstallment : "-",
      paymentPerMonth: paymentPerMonth
        ? thousandComma(+paymentPerMonth) + " บาท"
        : "-",
      totalPaid: totalPaid ? thousandComma(+totalPaid) + " บาท" : "-",
      dueDate: dueDate ? dayjs(dueDate).format(DATE_FORMAT_DATE_PICKER2) : "-",
      updateDate: updateDate
        ? dayjs(updateDate).format(DATE_FORMAT_DATE_PICKER2)
        : "-",
      status: status as TDebtInstallmentStatus,
    };
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = list.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchMoneyDebtFilter.searchText);
    searchParams.append("status", searchMoneyDebtFilter.status);
    searchParams.append("page", searchMoneyDebtFilter.page.toString());
    searchParams.append("limit", searchMoneyDebtFilter.limit.toString());
    searchParams.append("direction", searchMoneyDebtFilter.direction);
    searchParams.append("tab", tab);

    setList([]);
    const data = await getAcceptDebt(searchParams);

    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.invoiceId,
          row.orderNumber,
          row.customerRegistrationId,
          row.customerName + " " + row.customerSurname,
          row.invoiceNumber,
          row.debtPaymentOption ? row.debtPaymentOption : "",
          row.totalPrice ? row.totalPrice.toString() : "",
          row.debtNumberOfMonth ? row.debtNumberOfMonth.toString() : "",
          row.debtPayPerMonth ? row.debtPayPerMonth.toString() : "",
          row.debtPaid ? row.debtPaid.toString() : "",
          row.debtNextPayment,
          row.updatedAt,
          row.debtPaymentStatus as TDebtInstallmentStatus
        );
      });
      setCount(data.count);
      setList(newList);
      setDeptInstallment(data.rows);
    }
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "หมายเลขสั่งซื้อ"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "เลข Invoice"
      | "รูปเเบบการจ่าย"
      | "ยอดเงินที่ต้องชำระ"
      | "จำนวนเดือนที่ผ่อน"
      | "ยอดเงินที่ต้องชำระ/เดือน"
      | "ยอดเงินที่ชำระเเล้ว"
      | "วันครบกำหนดชำระ"
      | "วันที่อัปเดตล่าสุด"
      | "สถานะ";

    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await exportExcelSaleDebt(
      "accept-debt",
      itemSelected
    );
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            หมายเลขสั่งซื้อ: row.orderNumber,
            รหัสลูกค้า: row.customerRegistrationId,
            ชื่อลูกค้า: row.customerName + " " + row.customerSurname,
            "เลข Invoice": row.invoiceNumber,
            รูปเเบบการจ่าย: row.debtPaymentOption,
            ยอดเงินที่ต้องชำระ: row.totalPrice,
            จำนวนเดือนที่ผ่อน: row.debtNumberOfMonth,
            "ยอดเงินที่ต้องชำระ/เดือน": row.debtPayPerMonth,
            ยอดเงินที่ชำระเเล้ว: row.debtPaid,
            วันครบกำหนดชำระ: dayjs(row.debtNextPayment).format(
              DATE_FORMAT_DATE_PICKER2
            ),
            วันที่อัปเดตล่าสุด: dayjs(row.updatedAt).format(
              DATE_FORMAT_DATE_PICKER2
            ),
            สถานะ: row.debtPaymentStatus,
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `accept_debt_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const MoneyDebtStatusMap: Record<
    TDebtInstallmentStatus,
    { color: string; bgColor: string; label: string }
  > = {
    complete: {
      color: "#409261",
      bgColor: "#E9FFEF",
      label: "สำเร็จ",
    },
    pending_review: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "อยู่ระหว่างตรวจสอบ",
    },
    unpaid: {
      color: "#3F3748",
      bgColor: "#E4E4E4",
      label: "ยังไม่ได้ชำระ",
    },
  };

  return {
    handleSelectAllClick,
    handleClick,
    isSelected,
    count,
    list,
    itemSelected,
    setItemSelected,
    handleFetch,
    handleExportExcel,
    debtInstallment,
    MoneyDebtStatusMap,
  };
};
