import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useQuery } from "@tanstack/react-query";
import { cloneDeep, get, set } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BottomSheet } from "react-spring-bottom-sheet";
import ButtonFillCustom from "../../../admin/components/Button/ButtonFillCustom";
import BackgrounImage from "../../../assets/images/Login/BackGroungImage.png";
import AppBarFS from "../../../components/app-bar/AppBar";
import AuthBox from "../../../components/common/AuthBox";
import { useAuth } from "../../../components/common/ProtectedRoute/ProtectedRoute";
import RadioButton from "../../../components/common/RadioButton";
import { snakeActions } from "../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../redux/store";
import { checkVerifyPin } from "../../../services/AccountCenter.service";
import { checkUserRole } from "../../../utils/check-user-role";
import { tw } from "../../../utils/tw";

interface IState {
  option: number;
  pin: string;
}

const defaultState: IState = {
  option: 0,
  pin: "",
};

const SettingPage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAuth();
  const [userRole, setUserRole] = useState<string>("");
  const [state, setState] = useState<IState>(defaultState);

  const onChangeState = useCallback(
    (key: keyof IState) => (value: any) => {
      setState((prev) => {
        const prevState = cloneDeep(prev);
        return set(prevState, [key], value);
      });
    },
    []
  );

  const currentOption = get(state, ["option"]);

  const [isShowPinConfirm, setIsShowPinConfirm] = useState(false);

  const onShowPinConfirm = useCallback(() => {
    setIsShowPinConfirm(true);
  }, []);

  const onHidePinConfirm = useCallback(() => {
    setIsShowPinConfirm(false);
  }, []);

  const { data: verifyPin } = useQuery({
    queryKey: ["verify_pin", state.pin],
    enabled: state.pin.length === 4,
    queryFn: async () => {
      const result = await checkVerifyPin({ pin: state.pin });
      return result;
    },
  });

  const onSave = useCallback(() => {
    if (currentOption === 0) {
      i18n.changeLanguage("th");
      localStorage.setItem("language", "th");
    } else if (currentOption === 1) {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    }
    navigate(-1);
    setTimeout(() => {
      dispatch(
        snakeActions.showMessage({
          message: "Successful Saved!",
          type: "success",
        })
      );
    }, 500);
  }, [currentOption]);

  useEffect(() => {
    if (i18n.language === "th") {
      setState({
        option: 0,
        pin: "",
      });
    } else if (i18n.language === "en") {
      setState({
        option: 1,
        pin: "",
      });
    }
  }, []);

  useEffect(() => {
    if (verifyPin?.isVerify === true) {
      navigate("/farms/account-center/setting/change-pin", {
        state: state.pin,
      });
    }
  }, [verifyPin, state.pin, navigate]);

  useEffect(() => {
    //* set user role
    const currentUser = checkUserRole(user.userRole, user.staffType);
    setUserRole(currentUser);
  }, [user]);

  return (
    <div
      style={{
        backgroundImage: `url(${BackgrounImage})`,
        height: "auto !important",
        backgroundSize: "cover",
        backgroundColor: "#4882c8",
      }}
      className="!h-screen !min-h-screen"
    >
      <div className="h-[120px] flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          title={t("accountCenter.setting.header")}
          onIconPress={() => navigate(-1)}
        />
      </div>
      <div className="flex flex-col h-[80%] w-full flex-1">
        <div className="flex flex-1 flex-col px-4 pt-4">
          <div className="bg-[#FFFFFF] rounded-[10px] flex flex-col">
            <div
              className="py-2 flex items-center"
              style={{
                boxShadow: "0px -1px 0px 0px #3777BC40 inset",
              }}
            >
              <div className="pl-[20px] pr-[10px] flex flex-col gap-4 justify-between w-full h-auto">
                <div className="text-[16px] font-medium text-[#3777BC] flex flex-row gap-4">
                  Change Languages
                </div>
                <div
                  className="flex flex-row "
                  onClick={() => onChangeState("option")(0)}
                >
                  <RadioButton checked={currentOption === 0} />
                  <div className="text-[16px] text-[#646464] font-normal flex items-center">
                    Thai
                  </div>
                </div>
                <div
                  className="flex flex-row "
                  onClick={() => onChangeState("option")(1)}
                >
                  <RadioButton checked={currentOption === 1} />
                  <div className="text-[16px] text-[#646464] font-normal flex items-center">
                    English
                  </div>
                </div>
              </div>
            </div>
            {userRole === "farmer" && (
              <div
                className="py-2 flex items-center"
                style={{
                  boxShadow: "0px -1px 0px 0px #3777BC40 inset",
                }}
              >
                <div
                  className="pl-[20px] pr-[10px] flex flex-row items-center justify-between w-full h-[40px]"
                  onClick={onShowPinConfirm}
                >
                  <div className="text-[16px] font-medium text-[#3777BC] flex flex-row gap-4">
                    Change PIN Code
                  </div>
                  <KeyboardArrowRightIcon className="text-[#3777BC]" />
                </div>
              </div>
            )}

            {userRole === "farmer" && (
              <div
                className="py-2 flex items-center"
                style={{
                  boxShadow: "0px -1px 0px 0px #3777BC40 inset",
                }}
                onClick={() =>
                  navigate("/farms/account-center/setting/remove-account")
                }
              >
                <div className="pl-[20px] pr-[10px] flex flex-row items-center justify-between w-full h-[40px]">
                  <div className="text-[16px] font-medium text-[#3777BC] flex flex-row gap-4">
                    Remove Account
                  </div>
                </div>
              </div>
            )}
          </div>
          <ButtonFillCustom
            onClick={onSave}
            title="Save"
            btnTextColor="#ffffff"
            btnBgColor="#68C184"
            className={
              "!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold !mt-auto !bg-opacity-25"
            }
          />
        </div>
      </div>
      <BottomSheet
        open={isShowPinConfirm}
        onDismiss={onHidePinConfirm}
        snapPoints={({ minHeight }) => minHeight}
        className="h-full w-full"
      >
        <div className="!min-h-[400px]">
          <div className="pt-7 px-[43px] flex flex-col gap-4 justify-center">
            <div className="text-[#34407B] text-[16px] font-medium text-center">
              Enter 4 Digit PIN
            </div>
            <AuthBox
              boxLength={4}
              inputType="password"
              sucess={verifyPin?.isVerify === true}
              datatestid="verifyPin"
              labelStyle={{ textAlign: "center" }}
              alignError="center"
              containerClassName={tw("flex flex-col gap-y-1 self-center")}
              onChange={onChangeState("pin")}
              error={verifyPin?.isVerify === false}
              errorMessage={"Incorrect PIN please retry"}
            />
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default SettingPage;
