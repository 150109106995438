import { useLoaderData, useNavigate } from "react-router-dom";
import AppBarFS from "../../../../components/app-bar/AppBar";

import axios from "axios";
import get from "lodash/get";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDownloadUrlStockPartner } from "../../../../services/partner/historyStock.service";
import { TBaseGetListPartnerStockList } from "../../../../types/partner/stock.type";
import OrderFeedPartnerDetail from "../../stock-component/OrderFeedPartnerDetail";

type Props = {};

const StockHistoryDetail = (props: Props) => {
  const navigate = useNavigate();
  const { stockList } = useLoaderData() as {
    stockList: TBaseGetListPartnerStockList;
  };
  const [images1, setImages1] = useState<string[]>([]);
  const [images2, setImages2] = useState<string[]>([]);
  const { t } = useTranslation();
  const initImage = useCallback(async () => {
    const imgs = get(stockList, ["stockRefill", "stockRefillFiles"], []);
    const imagesPartnerProofOfDelivery = [];
    const imagesPartnerPickingSlip = [];

    if (stockList && imgs.length > 0) {
      if (imgs.length > 0) {
        for (let i = 0; i < imgs.length; i++) {
          const img = imgs[i];
          const preSignURL = await getDownloadUrlStockPartner(
            stockList?.stockRefill.id,
            img.documentType,
            img.id
          );
          if (preSignURL) {
            const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
              responseType: "blob",
            });
            const objectURL = window.URL.createObjectURL(response.data);
            if (img.documentType === "partnerPickingSlip") {
              imagesPartnerPickingSlip.push(objectURL);
            } else if (img.documentType === "partnerProofOfDelivery") {
              imagesPartnerProofOfDelivery.push(objectURL);
            }
          }
        }
      }
    }

    setImages1(imagesPartnerProofOfDelivery);
    setImages2(imagesPartnerPickingSlip);
  }, [stockList?.stockRefill.id]);

  useEffect(() => {
    initImage();
  }, [stockList?.stockRefill.id]);

  return (
    <div className="pb-4 h-screen flex flex-col  bg-[#ffff] overflow-scroll">
      <div className="py-4 rounded-b-[40px] bg-white">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title={t("OrderStock.LandingPageDetail")}
        />
      </div>
      <OrderFeedPartnerDetail
        stockList={stockList.stockRefill}
        isDisplayPrimaryButton={true}
        isTextDescription={false}
        isShippingProof={true}
        images1={images1}
        images2={images2}
      />
    </div>
  );
};

export default StockHistoryDetail;
