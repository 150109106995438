import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useDispatch } from "react-redux";
import ListOfUploadedFile2 from "../../../../../../components/common/File/ListOfUploadedFile2";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { commonDownloadFile } from "../../../../../../services/CommonApiService";
import AdminModal from "../../../../../components/Modal/AdminModal";
import { IGetDetailUserManualRow } from "../../../types/farmCommunity";
import { TFarmCommunityArticle } from "./ViewArticleModal";
import { useTranslation } from "react-i18next";

type Props = {
  data?: IGetDetailUserManualRow;
  isOpen: boolean;
  handleClose: () => void;
};

const ViewUserManual = ({ isOpen, handleClose, data }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onDownload = async (row: { id: string; fileOriginalName: string }) => {
    dispatch(loadingActions.show({ message: "กำลังดาวน์โหลดภาพ..." }));
    await commonDownloadFile(
      `/farm-community/manual/download/${row.id}`,
      row.fileOriginalName
    );
    dispatch(loadingActions.hide());
  };
  return (
    <>
      <AdminModal
        label={t("farmCommunity.userManualPage.detailsOfTopic.title")}
        isOpen={isOpen}
        size={"sm"}
        handleClose={handleClose}
        children={
          <>
            <div className="flex flex-col gap-6">
              <div className="items-start">
                <p className="text-sm font-bold pb-3 mb-1">
                  {t(
                    "farmCommunity.userManualPage.detailsOfTopic.subcategoryName"
                  )}
                </p>
                <p className="text-sm pb-3 mb-1">{data?.manual.name}</p>
              </div>
              <div className="items-start">
                <p className="text-sm font-bold pb-3 mb-1">
                  {t("farmCommunity.userManualPage.detailsOfTopic.topic")}
                </p>
                <p className="text-sm pb-3 mb-1">{data?.topicName}</p>
              </div>
              <div className="items-start">
                <p className="text-sm font-bold pb-3 mb-1">
                  {t("farmCommunity.userManualPage.detailsOfTopic.type")}
                </p>
                <p className="text-sm">
                  {
                    TFarmCommunityArticle[
                      data?.type as keyof typeof TFarmCommunityArticle
                    ]
                  }
                </p>
              </div>
            </div>
            <div className="flex justify-between pt-5">
              <p className="text-[#3777BC] text-lg font-sans font-bold">
                {t("farmCommunity.userManualPage.detailsOfTopic.pageDetails")}
              </p>
            </div>
            {data?.articlePages.map((category, index) => (
              <div className="mt-4">
                <Accordion
                  sx={{
                    boxShadow: "none",
                    width: "100%",
                    border: "1px solid #E2E2E2",
                    padding: "0 16px !important",
                    borderRadius: "16px",
                    "&:before": {
                      display: "none",
                    },
                    "&.Mui-expanded": {
                      margin: "0 !important",
                    },
                    "&:last-of-type": {
                      borderRadius: "16px",
                    },
                  }}
                >
                  <AccordionSummary
                    style={{ padding: 0, margin: "0px !important" }}
                    expandIcon={<ExpandMoreIcon style={{ color: "#68C184" }} />}
                    sx={{
                      fontSize: "16px !important",
                      fontWeight: "700",
                      color: "#000000",
                      "&.Mui-expanded": {
                        margin: "0 !important",
                      },
                    }}
                  >{`หน้า ${index + 1}`}</AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: "#FFFFFF",
                      padding: "0 !important",
                    }}
                  >
                    <div className="items-start">
                      <p className="text-sm font-bold pb-3 mb-1">
                        {t(
                          "farmCommunity.userManualPage.detailsOfTopic.uploadImage"
                        )}
                      </p>
                      <p className="text-sm">
                        <ListOfUploadedFile2
                          files={category.pageFile || []}
                          fileKey="fileOriginalName"
                          onDownload={(row) => onDownload(row)}
                          hideDeleteBtn
                        />
                      </p>
                    </div>
                    <div className="items-start mt-4">
                      <p className="text-sm font-bold pb-3 mb-1">
                        {t(
                          "farmCommunity.userManualPage.detailsOfTopic.description"
                        )}
                      </p>
                      <p className="text-sm pb-3 mb-1">
                        {category.description}
                      </p>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </>
        }
      />
    </>
  );
};

export default ViewUserManual;
