import {
  IGetBarChartMoneyDebt,
  IGetDonutMoneyDebt,
  IGetGroupAreaOption,
  IGetMoneyDebtChartList,
  IGetYears,
} from "../../types/Utils.type";
import adminAPI from "../AdminApiService";

export const getDonutMoneyDebt = async () => {
  const { data } = await adminAPI.get<IGetDonutMoneyDebt>(
    "/dashboard/performance/debt-collection/pie"
  );
  return data.data;
};
export const getBarChartMoneyDebt = async (searchParams?: URLSearchParams) => {
  const { data } = await adminAPI.get<IGetBarChartMoneyDebt>(
    `/dashboard/performance/debt-collection/bar?${searchParams}`
  );
  return data.data;
};

export const getGroupArea = async () => {
  const { data } = await adminAPI.get<IGetGroupAreaOption>(
    "/dashboard/performance/debt-collection/bar/dropdown/group-area"
  );
  return data.data;
};

export const getYear = async () => {
  const { data } = await adminAPI.get<IGetYears>(
    "/dashboard/performance/debt-collection/bar/dropdown/year"
  );
  return data.data;
};

export const getMoneyDebtChartList = async (searchParams?: URLSearchParams) => {
  const { data } = await adminAPI.get<IGetMoneyDebtChartList>(
    `/dashboard/performance/debt-collection/?${searchParams}`
  );
  return data.rows;
};
