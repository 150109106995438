import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Divider, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuBar from "../../../../../components/MenuBar/MenuBar";
import { TabPanelCustom } from "../../../../../components/TabPanelCustom/TabPanelCustom";
import UserManualTab from "../../../components/farmCommunity/tabs/UserManualTab";
import { getCountManual } from "../../../services/farmCommunity.service";
import { CountManualResult } from "../../../types/farmCommunity";
import { useTranslation } from "react-i18next";

type Props = {};

const UserManualDetail = (props: Props) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [count, setCount] = useState<CountManualResult>();
  const navigate = useNavigate();
  const { state } = useLocation();
  const categoryDetail = state;

  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };
  const fetchCountTab = async () => {
    const data = await getCountManual(categoryDetail.id);
    if (data) {
      setCount(data.result);
    }
  };

  useEffect(() => {
    fetchCountTab();
  }, [tabIndex]);

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <div onClick={() => navigate(-1)}>
                  <ChevronLeftIcon fontSize="large" />
                </div>
                <span className="text-[#000] font-bold text-3xl">
                  {categoryDetail.name}
                </span>
              </div>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6">
              <Tabs
                value={tabIndex}
                onChange={onChangeTabIndex}
                aria-label="sale-order-tab-control"
                TabIndicatorProps={{
                  sx: {
                    "&.MuiTabs-indicator": {
                      transform: "unset",
                      width: "unset",
                      bgcolor: "#68C184",
                      height: "3px",
                    },
                  },
                }}
                sx={{
                  height: "36px",
                  minHeight: "unset",
                  "& .MuiTabs-scroller": {
                    "& .MuiTabs-flexContainer": {
                      alignItems: "end",
                      height: "100%",
                      display: "flex",
                      gap: "12px",
                      "& .MuiTab-root": {
                        padding: "6px 28px",
                        textTransform: "unset",
                        fontFamily: "IBM Plex Sans Thai",
                        fontWeight: 400,
                        fontSize: "1rem",
                        lineHeight: "20px",
                        height: "32px",
                        minHeight: "unset",
                        color: "#000000",
                        borderBottom: "3px solid #DDF3E4",
                        "& .Mui-selected": {
                          color: "#000000",
                        },
                      },
                    },
                  },
                }}
              >
                <Tab label={`บทความ (${count?.article})`} />
                <Tab label={`วิดิโอ (${count?.video})`} />
              </Tabs>
            </div>
            <TabPanelCustom
              value={tabIndex}
              index={0}
              prefix="news-and-article"
            >
              <UserManualTab
                tabIndex={tabIndex}
                id={categoryDetail.id}
                tabName="article"
              />
            </TabPanelCustom>
            <TabPanelCustom value={tabIndex} index={1} prefix="pig-price">
              <UserManualTab
                tabIndex={tabIndex}
                id={categoryDetail.id}
                tabName="video"
              />
            </TabPanelCustom>
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default UserManualDetail;
