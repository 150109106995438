import { Dialog, DialogContent, IconButton } from "@mui/material";
import CrossIcon from "../../../../assets/svg/CrossIcon.svg";
import { LogDetail } from "../types/acceptDebt.type";

type MoneyDebtLogModalProps = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean, values?: any) => void;
  logDetail: LogDetail;
};

const classes = {
  container: `items-start`,
  label: `text-sm text-[#7E7E7E] mb-2`,
  title: `text-sm font-semibold pb-3 mb-1 w-full`,
  flexCenter: `flex justify-between items-center`,
};

const MoneyDebtLogModal = ({
  isOpen,
  handleClose,
  logDetail,
}: MoneyDebtLogModalProps) => {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => handleClose(true)}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent sx={{ width: "600px" }}>
          <div className={classes.flexCenter}>
            <p className="text-lg font-bold pb-3">รายละเอียด</p>
            <IconButton onClick={() => handleClose(true)}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className="mt-4">
            <div className={classes.container}>
              <p className={classes.label}>รายละเอียด</p>
              <p className={classes.title}>{logDetail.detail || "-"}</p>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MoneyDebtLogModal;
