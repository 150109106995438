import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import { Modal } from "@mui/material";
import { memo, useCallback, useState } from "react";
import Dropzone from "react-dropzone";

import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ButtonFillCustom from "../../../../admin/components/Button/ButtonFillCustom";
import AppBarFS from "../../../../components/app-bar/AppBar";
import { alertAction } from "../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../redux/slices/snakeSlice";
import { AppDispatch } from "../../../../redux/store";
import {
  ApproveOrDeniedContract,
  getUploadUrlContract,
} from "../../../../services/Financial.service";
import getErrorMessage from "../../../../utils/getErrorMessage";
import { tw } from "../../../../utils/tw";

interface IApproveContractModal {
  isOpen: boolean;
  onClose: () => void;
  refetchListNewContract: () => void;
  id: string;
}

interface IUploaderProps {
  maxFiles: number;
  onDrop: (images: File[]) => void;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`w-full h-full bg-[white]`),
  headerContainer: tw(`h-[120px] flex items-center`),
  detailContainer: tw(`flex flex-col w-full h-full gap-[16px] px-[16px]`),
  text1: tw(`font-bold text-[16px] text-[#3777BC]`),
  title: tw(
    `text-[18px] leading-[18px] text-tertiary font-semibold mb-[24px] px-[16px]`
  ),
  uploadContainer: tw(`px-[16px] flex flex-col w-full`),
  helperText: tw(`text-[14px] leading-[18px] text-tertiary mt-[8px]`),
  buttonContainer: tw(`pt-1`),
  button: tw(`!h-[59px] w-full !rounded-[50px] !text-[16px] !font-bold`),
  buttonTextContainer: tw(
    `flex flex-col gap-6 pt-8 px-[17.5px] pb-6 fixed bottom-0 bg-[#FFFFFF] w-full z-10`
  ),
  textContainer: tw(`flex flex-row justify-between`),
  upload: {
    container: tw(
      `w-[97px] h-[97px] rounded-[17px] flex flex-col justify-center items-center border-[0.76px] border-[#97BFEA] cursor-pointer bg-[#F6FBFF] hover:brightness-105 duration-150 transition-all`
    ),
    listContainer: tw(`flex flex-row flex-wrap gap-3`),
    // image container
    image: tw(
      `w-[98px] h-[98px] rounded-[17px] hover:brightness-110 duration-150 transition-all relative`
    ),
    remove: tw(
      `absolute top-[5.3px] right-[5.3px] w-[19px] h-[19px] rounded-full z-10 bg-[rgba(255,255,255,0.9)] flex justify-center items-center cursor-pointer`
    ),
    img: tw(`w-full h-full object-cover rounded-[17px]`),
  },
};

const Uploader = memo((props: IUploaderProps) => {
  const { maxFiles, onDrop } = props;

  return (
    <Dropzone
      accept={{ "image/*": [] }}
      maxFiles={maxFiles}
      maxSize={100 * 1024 * 1024} // 100mb
      onDrop={onDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={classes.upload.container}>
            <InsertPhotoIcon className="!text-[#97BFEA] !text-[26px] !mb-[7.6px]" />
            <div className="flex flex-row gap-x-[2px] items-center -ml-[2px] -mb-[2px]">
              <AddIcon className="!text-[#97BFEA] !text-[14px] -mt-[2px]" />
              <div className="text-[14px] leading-[18px] text-[#97BFEA]">
                Photo
              </div>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
});

const ApproveContractModal = (props: IApproveContractModal) => {
  const { isOpen, onClose, refetchListNewContract, id } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const [images1, setImages1] = useState<
    (File | { id: string; url: string })[]
  >([]);

  const [images2, setImages2] = useState<
    (File | { id: string; url: string })[]
  >([]);

  const onAddImage = useCallback(
    (type: "image1" | "image2") => (images: File[]) => {
      if (type === "image1") {
        const maxFile = 1;
        setImages1((prev) => [...images, ...prev].slice(0, maxFile));
      } else if (type === "image2") {
        const maxFile = 1;
        setImages2((prev) => [...images, ...prev].slice(0, maxFile));
      }
    },
    []
  );

  const onRemoveImage = useCallback(
    (type: "image1" | "image2", index: number) => () => {
      if (type === "image1") {
        setImages1((prev) => prev.filter((_, i) => i !== index));
      } else if (type === "image2") {
        setImages2((prev) => prev.filter((_, i) => i !== index));
      }
    },
    []
  );

  const onCloseFn = useCallback(() => {
    onClose();
    setImages1([]);
    setImages2([]);
  }, []);

  const ApproveOrDeniedContractMutation = useMutation({
    mutationFn: ApproveOrDeniedContract,
    onSuccess: async (data) => {
      refetchListNewContract();
      setTimeout(() => {
        onCloseFn();
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
      });
    },
    onError: (error) => {
      setTimeout(() => {
        dispatch(alertAction.hide());
        dispatch(loadingActions.hide());
        dispatch(
          snakeActions.showMessage({
            message: getErrorMessage(error),
            type: "error",
          })
        );
      }, 1500);
    },
  });

  const onConfirmUpload = useCallback(() => {
    dispatch(
      alertAction.showDialog({
        title: t("FinancialSupport.ConfirmToAccept"),
        text: `${t("FinancialSupport.ConfirmContactTitleOne")}\n${t(
          "FinancialSupport.Contact"
        )}\n${t("FinancialSupport.ConfirmContactTitleOne")}\n${t(
          "FinancialSupport.ConfirmEvidenceTitleTwo"
        )}`,
        cancelText: "",
        confirmText: t("FinancialSupport.Confirm"),
        isFullButtonStyle: true,
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          const imgs1 = [...images1];
          const imgs2 = [...images2];
          const totalLength = imgs1.length + imgs2.length;

          const fileArray1: {
            id: string;
            url: string;
            fileName: string;
            fileSize: number;
          }[] = [];
          const fileArray2: {
            id: string;
            url: string;
            fileName: string;
            fileSize: number;
          }[] = [];

          dispatch(
            loadingActions.show({ message: t("reportOrder.recording") })
          );
          try {
            for (let i = 0; i < totalLength; i++) {
              const img1 = imgs1[i];
              const img2 = imgs2[i];

              if (img1 instanceof File) {
                const presign = await getUploadUrlContract(id);
                await axios.put(presign.blob.blobUrl, img1, {
                  responseType: "blob",
                  headers: {
                    "Content-Type": img1.type,
                    "x-ms-blob-type": "BlockBlob",
                  },
                });
                fileArray1.push({
                  id: presign.blob.fileId,
                  url: presign.blob.blobUrl || "",
                  fileName: img1.name,
                  fileSize: img1.size,
                });
              }
              if (img2 instanceof File) {
                const presign = await getUploadUrlContract(id);
                await axios.put(presign.blob.blobUrl, img2, {
                  responseType: "blob",
                  headers: {
                    "Content-Type": img2.type,
                    "x-ms-blob-type": "BlockBlob",
                  },
                });
                fileArray2.push({
                  id: presign.blob.fileId,
                  url: presign.blob.blobUrl || "",
                  fileName: img2.name,
                  fileSize: img2.size,
                });
              }
            }
            ApproveOrDeniedContractMutation.mutate({
              id: id,
              isApprove: true,
              citizenDocument: fileArray1,
              creditBureauDocument: fileArray2,
            });
            setTimeout(() => {
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: t("FinancialSupport.SuccessfullyAcceptedCredit"),
                  type: "success",
                })
              );
            }, 1500);
          } catch (e) {
            setTimeout(() => {
              dispatch(loadingActions.hide());
              dispatch(
                snakeActions.showMessage({
                  message: getErrorMessage(e),
                  type: "error",
                })
              );
            }, 1500);
          }
        },
      })
    );
  }, [images1, images2, id]);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <AppBarFS
            title={t("FinancialSupport.UploadDocumentPage")}
            onIconPress={onCloseFn}
          />
        </div>
        <div className="">
          <div className={classes.title}>
            {t("FinancialSupport.UploadIdCard")} (Optional)
          </div>
          <div className={tw(classes.uploadContainer, "mb-[24px]")}>
            <div className={classes.upload.listContainer}>
              {images1.map((image, index) => {
                const url =
                  image instanceof File
                    ? URL.createObjectURL(image)
                    : image.url;
                return (
                  <div key={`${index}`} className={classes.upload.image}>
                    <div
                      onClick={onRemoveImage("image1", index)}
                      className={classes.upload.remove}
                    >
                      <Close className="!text-[#979797] !text-[15px] -mr-[0.6px]" />
                    </div>
                    <img src={url} className={classes.upload.img} />
                  </div>
                );
              })}
              <Uploader onDrop={onAddImage("image1")} maxFiles={1} />
            </div>
            <div className={classes.helperText}>
              {t("FinancialSupport.picturesOfReceipt")}
            </div>
          </div>
        </div>
        <div className="">
          <div className={classes.title}>
            {t("FinancialSupport.UploadBureau")} (Optional)
          </div>
          <div className={tw(classes.uploadContainer, "mb-[24px]")}>
            <div className={classes.upload.listContainer}>
              {images2.map((image, index) => {
                const url =
                  image instanceof File
                    ? URL.createObjectURL(image)
                    : image.url;
                return (
                  <div key={`${index}`} className={classes.upload.image}>
                    <div
                      onClick={onRemoveImage("image2", index)}
                      className={classes.upload.remove}
                    >
                      <Close className="!text-[#979797] !text-[15px] -mr-[0.6px]" />
                    </div>
                    <img src={url} className={classes.upload.img} />
                  </div>
                );
              })}
              <Uploader onDrop={onAddImage("image2")} maxFiles={1} />
            </div>
            <div className={classes.helperText}>
              {t("FinancialSupport.picturesOfReceipt")}
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <div className={classes.buttonTextContainer}>
              <ButtonFillCustom
                title={t("FinancialSupport.Confirm")}
                className={classes.button}
                onClick={onConfirmUpload}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ApproveContractModal);
