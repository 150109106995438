import axios from "axios";
import { EUserLoginRole, EUserLoginType } from "../utils/enum/logging.enum";
import adminAPI from "./AdminApiService";
import {
  IGetRole,
  IGetUsersRes,
  ILoginReq,
  ILoginRes,
  TAdminLoginLogging,
} from "./AdminAuth.type";

// Define the base URL for your API
const BASE_URL = `${
  process.env.REACT_APP_BASE_API_URL || window.location.origin
}/api/admin/`;

export const login = async (body: ILoginReq) => {
  try {
    const { data } = await axios.post<ILoginRes>("/login", body, {
      baseURL: BASE_URL,
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAllUsers = async () => {
  try {
    const { data } = await axios.get<IGetUsersRes>("/login/users", {
      baseURL: BASE_URL,
    });
    return data.users;
  } catch (error) {
    return [];
  }
};

export const getRole = async () => {
  try {
    const { data } = await adminAPI.get<IGetRole>("/role");
    const loggingPayload: TAdminLoginLogging = {
      isSuccess: data.success,
      userId: data.result.uid,
      type: EUserLoginType.Employee,
      userRole: data.result.userRole as EUserLoginRole,
    };
    await adminLoginLogging(loggingPayload);
    return data;
  } catch (error) {
    const loggingPayload: TAdminLoginLogging = {
      isSuccess: false,
      type: EUserLoginType.Employee,
      errorMessage: error || undefined,
    };
    await adminLoginLogging(loggingPayload);
    return null;
  }
};

export const adminLoginLogging = async (data: TAdminLoginLogging) => {
  try {
    const loggingPayload: TAdminLoginLogging = {
      isSuccess: data.isSuccess,
      userId: data.userId,
      type: EUserLoginType.Employee,
      userRole: data?.userRole ? (data.userRole as EUserLoginRole) : undefined,
      errorMessage: data.errorMessage || undefined,
    };

    await axios.post("/login/logging", loggingPayload, {
      baseURL: BASE_URL,
    });
  } catch (error) {}
};
