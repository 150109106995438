import React, { useState } from "react";

import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";

import { Checkbox, Link, TableCell } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useInvoiceSearchFilter } from "../../../../../hooks/useInvoiceSearchFilter.hook";
import {
  getListExportInvoiceSaleComplete,
  getListInvoiceSuccessSale,
} from "../../../../../services/admin/invoice/invoiceSale.service";
import { IGetInvoiceSuccessRow } from "../../../../../types/invoice/invoiceSale.type";
import { thousandComma } from "../../../../../utils/common/string";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import DocumentModal from "../../../../components/Modal/DocumentModal/DocumentModal";
import ExpandUpdateDocumentInvoice from "../../components/ExpandUpdateDocumentInvoice";
import { FilterInvoice } from "../../components/FilterInvoice";
import ReverseInvoiceModal from "../../components/ReverseInvoiceModal";
import { IInvoiceTable, TInvoiceStatus } from "../../type";

type Props = { handleFetchCount: () => void };

const PastDueInvoicePanel = ({ handleFetchCount }: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.invoiceSale === "edit";
  const [itemSelected, setItemSelected] = React.useState<string[]>([]);
  const [orderInvoice, setOrderInvoice] = React.useState<IInvoiceTable[]>([]);
  const [count, setCount] = React.useState(0);
  const [isOpenReverseModal, setIsOpenReverseModal] = useState<boolean>(false);
  const [invoiceId, setInvoiceId] = useState<string>("");
  const [invoiceLog, setInvoiceLog] = React.useState<IGetInvoiceSuccessRow[]>(
    []
  );
  const [reverseDetail, setReverseDetail] =
    React.useState<IGetInvoiceSuccessRow>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  function createData(
    id: string,
    orderId: string,
    customerId: string,
    customerName: string,
    partnerFollowInvoice: string,
    invoiceNumber: string,
    workSheetNumber: string,
    amountToBePaid: number,
    paidAmount: number,
    receiveSuccessDate: string,
    paymentDueDate: string,
    updateDate: string,
    status: TInvoiceStatus
  ): IInvoiceTable {
    return {
      id,
      orderId: orderId ? orderId : "-",
      customerId: customerId ? customerId : "-",
      customerName: customerName ? customerName : "-",
      partnerFollowInvoice: partnerFollowInvoice ? partnerFollowInvoice : "-",
      invoiceNumber: invoiceNumber ? invoiceNumber : "-",
      workSheetNumber: workSheetNumber ? workSheetNumber : "-",
      amountToBePaid: amountToBePaid
        ? `${thousandComma(amountToBePaid, 2)} บาท`
        : "-",
      paidAmount: paidAmount ? `${thousandComma(paidAmount, 2)} บาท` : "-",
      receiveSuccessDate: receiveSuccessDate
        ? dayjs(receiveSuccessDate).format("DD/MM/YYYY")
        : "-",
      paymentDueDate: paymentDueDate
        ? dayjs(paymentDueDate).format("DD/MM/YYYY")
        : "-",
      updateDate: updateDate ? dayjs(updateDate).format("DD/MM/YYYY") : "-",
      status: status as TInvoiceStatus,
    };
  }
  const [modalData, setModalData] = useState<IInvoiceTable>();

  const headCells: ITableCustomHeadCell<keyof IInvoiceTable>[] = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.orderNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.customerId"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.customerName"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "partnerFollowInvoice",
      numeric: false,
      disablePadding: false,
      label: "พาร์ทเนอร์ที่ติดตาม",
      width: "150px",
      isSorting: false,
    },
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.invoiceNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "workSheetNumber",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.sheetNumber"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "amountToBePaid",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.totalDueAmount"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "paidAmount",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.paidAmount"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "receiveSuccessDate",
      numeric: false,
      disablePadding: false,
      label: t(
        "invoice.successfulInvoiceTab.listOfInvoice.productReceivedDate"
      ),
      width: "150px",
      isSorting: true,
    },
    {
      id: "paymentDueDate",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.paymentDueDate"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.lastUpdatedDate"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: t("invoice.successfulInvoiceTab.listOfInvoice.status"),
      width: "100px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
      isSorting: false,
    },
  ];

  const OrderStatusMap: Record<
    TInvoiceStatus,
    { color: string; bgColor: string; label: string }
  > = {
    verifying_document: {
      color: "#3F3748",
      bgColor: "#E4E4E4",
      label: "รออัปเดทเอกสาร",
    },
    wait_for_update_document: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "อยู่ระหว่างตรวจสอบ",
    },
    overdue: {
      color: "",
      bgColor: "",
      label: "",
    },
    complete: {
      color: "#409261",
      bgColor: "#E9FFEF",
      label: "สำเร็จ",
    },
  };

  const filteredHeadCells = roleActionEdit
    ? headCells
    : headCells.toSpliced(13, 1);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = orderInvoice.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const MapOrderByWithFilter: {
    [K in keyof IInvoiceTable]?: string;
  } = {
    receiveSuccessDate: "receive_date",
    updateDate: "updated_at",
  };

  const searchFilterInvoice = useInvoiceSearchFilter();

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchFilterInvoice.searchText);
    searchParams.append("page", searchFilterInvoice.page.toString());
    searchParams.append("limit", searchFilterInvoice.limit.toString());
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFilterInvoice.orderBy] ?? ""
    );
    searchParams.append("direction", searchFilterInvoice.direction);
    setOrderInvoice([]);
    const data = await getListInvoiceSuccessSale(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row: any) => {
        return createData(
          row.id,
          row.orderNumber,
          row.farmerRegistrationId,
          row.farmerName + row.farmerSurname,
          row.partner?.name + " " + row.partner?.surname,
          row.invoiceNumber,
          row.assignmentNumber,
          +row.totalPaid,
          +row.totalPrice,
          row.receiveDate,
          row.dueDate,
          row.updatedAt,
          row.status as TInvoiceStatus
        );
      });
      setCount(data.count);
      setOrderInvoice(newList);
      setInvoiceLog(data.rows.map((row) => row));
    }
    handleFetchCount();
  };

  React.useEffect(() => {
    handleFetch();
  }, [
    searchFilterInvoice.orderBy,
    searchFilterInvoice.direction,
    searchFilterInvoice.page,
  ]);

  const getExportExcelData = async () => {
    type TColumnName =
      | "หมายเลขสั่งซื้อ"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "เลข Invoice"
      | "เลขที่ใบงาน"
      | "ยอดเงินที่ต้องชำระ"
      | "ยอดเงินที่ชำระเเล้ว"
      | "วันรับสินค้าสำเร็จ"
      | "วันครบกำหนดชำระ"
      | "วันที่อัปเดตล่าสุด"
      | "สถานะ";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getListExportInvoiceSaleComplete(
      itemSelected
    );
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            หมายเลขสั่งซื้อ: row.orderNumber || "-",
            รหัสลูกค้า: row.farmerRegistrationId || "-",
            ชื่อลูกค้า: row.farmerName + " " + row.farmerSurname,
            "เลข Invoice": row.invoiceNumber || "-",
            เลขที่ใบงาน: row.assignmentNumber || "-",
            ยอดเงินที่ต้องชำระ: thousandComma(row.totalPaid, 2) + " บาท" || "-",
            ยอดเงินที่ชำระเเล้ว:
              thousandComma(row.totalPrice, 2) + " บาท" || "-",
            วันรับสินค้าสำเร็จ: row.receiveDate
              ? dayjs(row.receiveDate).format("DD/MM/YYYY")
              : "-",
            วันครบกำหนดชำระ: row.dueDate
              ? dayjs(row.dueDate).format("DD/MM/YYYY")
              : "-",
            วันที่อัปเดตล่าสุด: row.updatedAt
              ? dayjs(row.updatedAt).format("DD/MM/YYYY")
              : "-",
            สถานะ: row.overdueStatus as TInvoiceStatus,
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `success_invoice_sale_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  const handleUpdateInvoice = (rowData: IInvoiceTable) => {
    setIsOpen(true);
    setModalData(rowData);
  };

  const handleModalClose = (isSuccess: boolean) => {
    setIsOpen(false);
    if (isSuccess) {
      handleFetch();
    }
  };

  return (
    <div className="w-full h-full pt-8">
      <FilterInvoice
        searchFilterInvoice={searchFilterInvoice}
        isDisplaySelect={false}
        onSearch={handleFetch}
        placeholder={t("invoice.successfulInvoiceTab.searchForInvoiceNumber")}
        itemSelected={itemSelected}
        onExport={handleExportExcel}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchFilterInvoice.direction}
          orderBy={searchFilterInvoice.orderBy}
          rowsPerPage={searchFilterInvoice.limit}
          rows={orderInvoice}
          itemsSelected={itemSelected}
          headerItems={filteredHeadCells}
          page={searchFilterInvoice.page}
          onChangeOrderBy={(val) =>
            searchFilterInvoice.setOrderBy(val as keyof IInvoiceTable)
          }
          onChangeOrder={(val) => searchFilterInvoice.setDirection(val)}
          onChangePage={(val) => searchFilterInvoice.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={orderInvoice.map((row) => row.id)}
          rowChildren={
            <>
              {orderInvoice.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <ExpandableTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    expandComponent={
                      <ExpandUpdateDocumentInvoice
                        key={row.id}
                        id={row.id}
                        colSpan={16}
                        invoiceLog={invoiceLog[index].invoiceLogs}
                      />
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          console.info("I'm a button.");
                        }}
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.partnerFollowInvoice}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.workSheetNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.amountToBePaid}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paidAmount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.receiveSuccessDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paymentDueDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.updateDate}
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        <ChipOrderStatus
                          bgColor={
                            OrderStatusMap[row.status as TInvoiceStatus].bgColor
                          }
                          fontColor={
                            OrderStatusMap[row.status as TInvoiceStatus].color
                          }
                          label={
                            OrderStatusMap[row.status as TInvoiceStatus].label
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        onClick={() => handleUpdateInvoice(row)}
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {t(
                          "invoice.successfulInvoiceTab.listOfInvoice.viewDocuments"
                        )}
                      </Link>
                    </TableCell>
                    {roleActionEdit && (
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <ButtonFillCustom
                          btnBgColor="#D32F2F"
                          btnTextColor="#FFFFFF"
                          title={t(
                            "invoice.successfulInvoiceTab.resverseButton"
                          )}
                          onClick={() => {
                            setIsOpenReverseModal(true);
                            setInvoiceId(row.id);
                            setReverseDetail(invoiceLog[index]);
                          }}
                        />
                      </TableCell>
                    )}
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <ReverseInvoiceModal
        isOpen={isOpenReverseModal}
        handleClose={() => setIsOpenReverseModal(false)}
        invoiceId={invoiceId}
        reverseDetail={reverseDetail}
      />
      <DocumentModal
        handleModalClose={handleModalClose}
        isOpen={isOpen}
        data={modalData}
      />
    </div>
  );
};

export default PastDueInvoicePanel;
