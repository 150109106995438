import ArrowDropDownCircleSharpIcon from "@mui/icons-material/ArrowDropDownCircleSharp";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Timeline, timelineItemClasses } from "@mui/lab";
import {
  AccordionDetails,
  AccordionProps,
  AccordionSummaryProps,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  SvgIcon,
  styled,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { DATE_FORMAT_TABLE } from "../../../../../../const/app.constant";
import { TCreditLog } from "../../../../../../types/CreditFeed.type";
import { CalendarSvg } from "../../../../../assets/svg/Calendar.svg";
import DateRangePickerPopover from "../../../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import TextInputCustom from "../../../../../components/Input/TextInputCustom";
import TimelineLog from "./TimelineLog";

type Props = {
  id: number | string;
  contractLogModel: TCreditLog[];
};

export const FILTER_DATE_FORMAT = "DD/MM/YYYY";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  padding: "0px",
  border: "0px",
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowDropDownCircleSharpIcon
        sx={{ fontSize: "36px", color: "currentcolor" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  padding: "0px",
  backgroundColor: "transparent",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#D9D9D9",
    transform: "rotate(180deg)",
    "&.Mui-expanded": {
      color: "#68C184",
      transform: "rotate(0deg)",
    },
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const ExpandUpdateLog = ({ contractLogModel }: Props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const refCalendarRangeOrderDate = React.useRef<
    React.ElementRef<typeof DateRangePickerPopover>
  >({
    clearData: () => {},
    onOpen: () => {},
  });

  const [filteredLogs, setFilteredLogs] =
    useState<TCreditLog[]>(contractLogModel);

  const [isOpenCalenderOrderDate, setIsOpenCalenderOrderDate] =
    React.useState<boolean>(false);
  const handleCloseCalenderOrderDate = () => {
    setIsOpenCalenderOrderDate(false);
  };

  const handleSelectOrderDate = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  const handleCalenderSaveOrderDate = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  const [expanded, setExpanded] = useState<number | false>(0);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  useEffect(() => {
    if (startDate && endDate) {
      const start =
        new Date(dayjs(startDate, FILTER_DATE_FORMAT).toString()).getTime() ||
        0;
      const end =
        new Date(dayjs(endDate, FILTER_DATE_FORMAT).toString()).getTime() ||
        9999999999999;

      const filterDateRange = contractLogModel.filter(
        (log) =>
          new Date(log.date).getTime() - start >= 0 &&
          new Date(log.date).getTime() - end <= 0
      );

      setFilteredLogs(filterDateRange);
    } else {
      setFilteredLogs(contractLogModel);
    }
  }, [startDate, endDate, contractLogModel]);

  return (
    <>
      <div className="py-[24px] bg-[#FFFFFF]">
        <p className="text-[#68C184] font-bold text-xl font-sans">
          บันทึกกิจกรรม
        </p>
        <div className="w-[250px] mt-6">
          <span className="text-sm font-medium font-sans">วันที่</span>
          <div className="pt-2">
            <TextInputCustom
              placeholder="เริ่มต้น - สิ้นสุด"
              value={startDate ? startDate + " - " + endDate : ""}
              InputProps={{
                endAdornment:
                  startDate !== "" ? (
                    <CloseRoundedIcon
                      sx={{
                        cursor: "pointer",
                        fontSize: "18px !important",
                      }}
                      onClick={(e) => {
                        setStartDate("");
                        setEndDate("");
                        refCalendarRangeOrderDate.current.clearData();
                        e.stopPropagation();
                      }}
                    />
                  ) : (
                    <SvgIcon
                      sx={{
                        fontSize: "24px !important",
                        color: "#646464",
                      }}
                      fontSize="small"
                    >
                      <CalendarSvg />
                    </SvgIcon>
                  ),
              }}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                e.stopPropagation();
                refCalendarRangeOrderDate.current?.onOpen(e);
              }}
            />
          </div>
          <div className="relative h-full w-full pt-6">
            {filteredLogs.map((log, hIndex) => (
              <Accordion
                key={`history-acc-${hIndex}`}
                expanded={expanded === hIndex}
                onChange={handleChange(hIndex)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <span className="text-[14px] font-normal text-[#1B2128] font-sans">
                    {dayjs().format(DATE_FORMAT_TABLE) ===
                    dayjs(log.date).format(DATE_FORMAT_TABLE)
                      ? "วันนี้"
                      : dayjs(log.date).format(DATE_FORMAT_TABLE)}
                  </span>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: "0px",
                  }}
                >
                  <div className="py-2">
                    <Timeline
                      sx={{
                        padding: "2px 12px",
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                      }}
                    >
                      {log.rows.map((logDetail, index) => (
                        <TimelineLog
                          logDetail={logDetail}
                          index={index}
                          countIndex={log.rows.length}
                          key={logDetail.id}
                        />
                      ))}
                    </Timeline>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
      <DateRangePickerPopover
        ref={refCalendarRangeOrderDate}
        id={"calendar-range-popover-order"}
        handleSelect={handleSelectOrderDate}
        openCalender={isOpenCalenderOrderDate}
        onClose={handleCloseCalenderOrderDate}
        handleCalenderSave={handleCalenderSaveOrderDate}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
};

export default ExpandUpdateLog;
