import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DATE_FORMAT_ENG } from "../../../../../const/app.constant";
import {
  deleteArticle,
  getNewsAndArticles,
  toggleArticles,
} from "../../services/farmCommunity.service";
import {
  IFarmCommunityTable,
  ListNewsAndArticles,
  TTypeFarmCommunity,
} from "../../types/farmCommunity";
import { TUseFarmCommunityFilter } from "./useFarmCommuFilter.hook";
import { useDispatch } from "react-redux";
import { snakeActions } from "../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../utils/getErrorMessage";
import { alertAction } from "../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../redux/slices/loadingSlice";

export const useFarmCommunityTableHook = (
  searchFarmCommunityFilter: TUseFarmCommunityFilter
) => {
  const dispatch = useDispatch();
  const [list, setList] = useState<IFarmCommunityTable[]>([]);
  const [listFarmCommunity, setListFarmCommunity] = useState<
    ListNewsAndArticles[]
  >([]);

  const MapOrderByWithFilter: { [K in keyof IFarmCommunityTable]?: string } = {
    updatedAt: "updated_at",
  };

  const createData = (
    id: string,
    isActive: boolean,
    displayPriority: number,
    header: string,
    type: TTypeFarmCommunity,
    updatedAt: string
  ): IFarmCommunityTable => {
    return {
      id,
      isActive,
      displayPriority,
      header,
      type,
      updatedAt: updatedAt ? dayjs(updatedAt).format(DATE_FORMAT_ENG) : "-",
    };
  };

  const FarmCommunityStatusMap: Record<
    TTypeFarmCommunity,
    { color: string; bgColor: string; label: string }
  > = {
    video: {
      color: "#409261",
      bgColor: "#E9FFEF",
      label: "วิดิโอ",
    },
    article: {
      color: "#3777BC",
      bgColor: "#D9EBFE",
      label: "บทความ",
    },
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("updatedAtStart", searchFarmCommunityFilter.startDate);
    searchParams.append("updatedAtEnd", searchFarmCommunityFilter.endDate);
    searchParams.append("type", searchFarmCommunityFilter.type);
    searchParams.append("page", searchFarmCommunityFilter.page.toString());
    searchParams.append("limit", searchFarmCommunityFilter.limit.toString());
    searchParams.append("direction", searchFarmCommunityFilter.direction);
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFarmCommunityFilter.orderBy] ?? ""
    );
    setList([]);

    const data = await getNewsAndArticles(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.isActive,
          row.displayPriority,
          row.topicName,
          row.type as TTypeFarmCommunity,
          row.updatedAt
        );
      });
      searchFarmCommunityFilter.setCount(data.count);
      setList(newList);
      setListFarmCommunity(data.rows);
    }
  };

  const handleToggleChangeStatus = async (creditId: string) => {
    try {
      await toggleArticles(creditId);
      dispatch(
        snakeActions.showMessage({
          message: "เปลี่ยนสถานะสำเร็จ",
          type: "success",
        })
      );
      handleFetch();
    } catch (error) {
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
    }
  };

  const handleDeleteArticle = async (id: string) => {
    try {
      dispatch(
        alertAction.showDialog({
          title: "ยืนยันการลบ",
          text: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
          cancelText: "ยกเลิก",
          confirmText: "ยืนยัน",
          onCancel: () => {
            dispatch(alertAction.hide());
          },
          onSubmit: () => {
            dispatch(loadingActions.show({ message: "กำลังลบรายการ..." }));
            deleteArticle(id)
              .then(() => {
                dispatch(alertAction.hide());
                setTimeout(() => {
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: "ลบสำเร็จ",
                      type: "success",
                    })
                  );
                }, 1000);
                handleFetch();
              })
              .catch((e) => {
                dispatch(alertAction.hide());
                setTimeout(() => {
                  dispatch(loadingActions.hide());
                  dispatch(
                    snakeActions.showMessage({
                      message: getErrorMessage(e),
                      type: "error",
                    })
                  );
                }, 1000);
              });
          },
        })
      );
    } catch (error) {
      dispatch(
        snakeActions.showMessage({
          message: getErrorMessage(error),
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    handleFetch();
  }, [
    searchFarmCommunityFilter.direction,
    searchFarmCommunityFilter.page,
    searchFarmCommunityFilter.orderBy,
    searchFarmCommunityFilter.toggleFetch,
  ]);

  return {
    list,
    FarmCommunityStatusMap,
    handleFetch,
    handleToggleChangeStatus,
    handleDeleteArticle,
    listFarmCommunity,
  };
};
