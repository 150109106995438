import { Divider, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import PlusCircleIcon from "../../../../assets/svg/PlusCircleIcon.svg";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import MenuBar from "../../../../components/MenuBar/MenuBar";
import { TabPanelCustom } from "../../../../components/TabPanelCustom/TabPanelCustom";
import CreateArticleModal from "../../components/farmCommunity/Modal/CreateArticleModal";
import CreatePigPrice from "../../components/farmCommunity/Modal/CreatePigPrice";
import CreateUserManual from "../../components/farmCommunity/Modal/CreateUserManual";
import { useFarmCommunityFilterHook } from "../../hooks/farm-community/useFarmCommuFilter.hook";
import { useFarmCommunityTableHook } from "../../hooks/farm-community/useFarmCommunityTable.hook";
import { usePigPriceTableHook } from "../../hooks/farm-community/usePigPriceTable.hook";
import {
  getCountFarmCommunity,
  getListUserManual,
} from "../../services/farmCommunity.service";
import {
  CountFarmCommunityResult,
  IUserManualListRow,
} from "../../types/farmCommunity";
import NewsAndArticles from "./NewsAndArticles";
import PigPrice from "./PigPrice";
import UserManuals from "./UserManual/UserManuals";
import { useTranslation } from "react-i18next";

type Props = {};

const FarmCommunity = (props: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.masterDataFarmCommunity === "edit";
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isOpenCreateArticle, setIsOpenCreateArticle] = useState(false);
  const [isOpenCreatePigPrice, setIsOpenCreatePigPrice] = useState(false);
  const [isOpenCreateUserManual, setIsOpenCreateUserManual] = useState(false);
  const [count, setCount] = useState<CountFarmCommunityResult>();
  const [listUserManual, setListUserManual] = useState<IUserManualListRow[]>(
    []
  );
  const searchFarmCommunity = useFarmCommunityFilterHook();
  const {
    list,
    handleFetch,
    FarmCommunityStatusMap,
    handleToggleChangeStatus,
    handleDeleteArticle,
    listFarmCommunity,
  } = useFarmCommunityTableHook(searchFarmCommunity);

  const { handleFetchPigPrice, pigPriceData, dataTable, handleDeletePigPrice } =
    usePigPriceTableHook(searchFarmCommunity);

  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };

  const handleOpenCreate = [
    setIsOpenCreateArticle,
    setIsOpenCreatePigPrice,
    setIsOpenCreateUserManual,
  ];
  const fetchCountFarmCommunity = async () => {
    const data = await getCountFarmCommunity();
    if (data) {
      setCount(data.result);
    }
  };

  useEffect(() => {
    fetchCountFarmCommunity();
  }, [tabIndex]);

  const fetch = async () => {
    const data = await getListUserManual();
    if (data) {
      setListUserManual(data.rows);
    }
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <MenuBar>
      <div className="pt-16 w-full h-full relative">
        <div className="p-10">
          <div className="flex justify-between items-center">
            <span className="text-[#000] font-bold text-3xl">
              {t("farmCommunity.title")}
            </span>
            {roleActionEdit && (
              <ButtonFillCustom
                title="เพิ่ม"
                startIcon={<PlusCircleIcon />}
                onClick={() => {
                  handleOpenCreate[tabIndex](true);
                }}
              />
            )}
          </div>
          <Divider sx={{ paddingTop: "12px" }} />
          <div className="pt-6">
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              aria-label="sale-order-tab-control"
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "#68C184",
                    height: "3px",
                  },
                },
              }}
              sx={{
                height: "36px",
                minHeight: "unset",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#000000",
                      borderBottom: "3px solid #DDF3E4",
                      "& .Mui-selected": {
                        color: "#000000",
                      },
                    },
                  },
                },
              }}
            >
              <Tab
                label={`${t("farmCommunity.newsAndArticlePage.title")} (${
                  count?.article
                })`}
              />
              <Tab
                label={`${t("farmCommunity.pigPricePage.title")} (${
                  count?.pigPrice
                })`}
              />
              <Tab
                label={`${t("farmCommunity.userManualPage.title")} (${
                  count?.manual
                })`}
              />
            </Tabs>
          </div>
          <TabPanelCustom value={tabIndex} index={0} prefix="news-and-article">
            <NewsAndArticles
              list={list}
              FarmCommunityStatusMap={FarmCommunityStatusMap}
              handleToggleChangeStatus={handleToggleChangeStatus}
              handleDeleteArticle={handleDeleteArticle}
              searchFarmCommunity={searchFarmCommunity}
              handleFetch={handleFetch}
              listFarmCommunity={listFarmCommunity}
              tabIndex={tabIndex}
            />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={1} prefix="pig-price">
            <PigPrice
              data={pigPriceData}
              searchFarmCommunity={searchFarmCommunity}
              handleFetch={handleFetchPigPrice}
              dataTable={dataTable}
              tabIndex={tabIndex}
              handleDeletePigPrice={handleDeletePigPrice}
            />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={2} prefix="success-invoice">
            <UserManuals listUserManual={listUserManual} fetch={fetch} />
          </TabPanelCustom>
        </div>
        <CreateArticleModal
          isOpen={isOpenCreateArticle}
          handleClose={(success) => {
            if (success) {
              handleFetch();
            }
            setIsOpenCreateArticle(false);
          }}
        />
        <CreatePigPrice
          isOpen={isOpenCreatePigPrice}
          handleClose={(success) => {
            if (success) {
              handleFetchPigPrice();
            }
            setIsOpenCreatePigPrice(false);
          }}
        />
        <CreateUserManual
          isOpen={isOpenCreateUserManual}
          handleClose={(success) => {
            if (success) {
            }
            setIsOpenCreateUserManual(false);
          }}
        />
      </div>
    </MenuBar>
  );
};

export default FarmCommunity;
