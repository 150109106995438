import { useState } from "react";
import { Order } from "../../../../components/Table/TableCustom";
import { IMedicineTable } from "../../types/medicine.type";
import { IFarmCommunityTable } from "../../types/farmCommunity";

export const useFarmCommunityFilterHook = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("");
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [toggleFetch, setToggleFetch] = useState(false);
  const [orderBy, setOrderBy] =
    useState<keyof IFarmCommunityTable>("updatedAt");
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  return {
    searchText,
    startDate,
    endDate,
    direction,
    page,
    limit,
    toggleFetch,
    orderBy,
    count,
    type,
    setSearchText,
    setType,
    setStartDate,
    setOrderBy,
    setEndDate,
    setDirection,
    setPage,
    setLimit,
    setToggleFetch,
    setCount,
  };
};

export type TUseFarmCommunityFilter = ReturnType<
  typeof useFarmCommunityFilterHook
>;
