import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider, Link, SvgIcon, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { monthOption } from "../../../../const/utils";
import {
  getGroupArea,
  getMoneyDebtChartList,
  getYear,
} from "../../../../services/dashboard/MoneyDebtCollection.service";
import {
  IGetGroupAreaOptionRow,
  IGetMoneyDebtChartListRow,
} from "../../../../types/Utils.type";
import { thousandComma } from "../../../../utils/common/string";
import { makeXlsx } from "../../../../utils/make-xlsx";
import { tw } from "../../../../utils/tw";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import MenuBar from "../../../components/MenuBar/MenuBar";
import TableCustom from "../../../components/Table/TableCustom";
import { Dropdown, TDropdownValue } from "../../group-area/views/Dropdown";

type Props = {};

const DeptCollectionTable = (props: Props) => {
  const navigate = useNavigate();
  const segmentOption = ["potential", "grower", "independent"];
  //* filter
  const [searchText, setSearchText] = useState("");
  const [yearOption, setYearOption] = useState<string[]>([]);
  const [year, setYear] = useState<string>(dayjs().year().toString());
  const [toggleFetch, setToggleFetch] = useState(false);
  const [groupArea, setGroupArea] = useState<TDropdownValue[]>([]);
  const [groupAreaOption, setGroupAreaOption] = useState<
    IGetGroupAreaOptionRow[]
  >([]);
  const [segment, setSegment] = useState<TDropdownValue[]>([]);
  const [month, setMonth] = useState<TDropdownValue[]>([]);

  // table
  const [data, setData] = useState<IGetMoneyDebtChartListRow[] | []>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [orderByType, setOrderByType] = useState<"desc" | "asc">("desc");
  const [currentOrderByKey, setCurrentOrderByKey] = useState<
    "customer_no" | "month"
  >("customer_no");

  const classes = {
    emptyTable: tw(
      `font-sans text-center w-full text-[#979797] text-[14px] py-3`
    ),
  };

  const headerItems = [
    {
      id: "customer_no",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "auto",
      isSorting: true,
      className: tw(`whitespace-pre`),
    },
    {
      id: "customer_name",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "month",
      numeric: false,
      disablePadding: false,
      label: "เดือน",
      width: "auto",
      isSorting: true,
      className: tw(`whitespace-pre`),
    },
    {
      id: "province",
      numeric: false,
      disablePadding: false,
      label: "จังหวัด",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "group_area",
      numeric: false,
      disablePadding: false,
      label: "Group Area",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "segment",
      numeric: false,
      disablePadding: false,
      label: "Segment",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "farm_size",
      numeric: false,
      disablePadding: false,
      label: "ขนาดฟาร์ม",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "farm_amount",
      numeric: false,
      disablePadding: false,
      label: "จำนวนฟาร์ม",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "barn_amount",
      numeric: false,
      disablePadding: false,
      label: "จำนวนโรงเรือน",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "loan_outstanding",
      numeric: false,
      disablePadding: false,
      label: "Loan Outstanding",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "repayment",
      numeric: false,
      disablePadding: false,
      label: "Repayment",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
  ];

  const fetchGroupAreaOption = async () => {
    const data = await getGroupArea();
    setGroupAreaOption(data);
  };

  const fetchYearOption = async () => {
    const data = await getYear();
    setYearOption(data);
  };

  const fetchMoneyDebtList = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("year", year);
    searchParams.append(
      "months",
      month.map((month) => month.value).join(",") || ""
    );
    searchParams.append("groupAreaIds", groupArea[0]?.value || "");
    searchParams.append(
      "segments",
      segment.map((item) => item.value.toLowerCase()).join(",") || ""
    );
    searchParams.append("searchText", searchText);
    searchParams.append("orderBy", currentOrderByKey);
    searchParams.append("direction", orderByType);
    searchParams.append("page", currentPage.toString());
    searchParams.append("limit", limit.toString());
    const data = await getMoneyDebtChartList(searchParams);
    if (data) {
      setData(data);
    }
  };

  const handleExportData = async () => {
    const dataExcel = data?.map((item) => {
      return {
        รหัสลูกค้า: item.registrationId,
        ชื่อลูกค้า: item.farmerFullname,
        เดือน: item.month,
        จังหวัด: item.province,
        "Group Area": item.groupArea,
        Segment: item.segment,
        ขนาดฟาร์ม: item.farmSize,
        จำนวนฟาร์ม: item.farmAmount,
        จำนวนโรงเรือน: item.houseAmount,
        "Loan Outstanding": thousandComma(item.loanOutstanding) + " บาท",
        Repayment: thousandComma(item.repayment) + " บาท",
      };
    });
    const fileName = `Debt_Collection_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";

    makeXlsx(dataExcel || [], fileName, sheetName);
  };

  useEffect(() => {
    fetchMoneyDebtList();
  }, [orderByType, currentOrderByKey, toggleFetch]);

  useEffect(() => {
    fetchYearOption();
    fetchGroupAreaOption();
  }, []);

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      return (
        <TableRow>
          <TableCell>
            <Link
              component="button"
              variant="body2"
              sx={{
                color: "#68C184",
                textDecorationColor: "#68C184",
                ":hover": {
                  fontSize: "16px",
                },
              }}
            >
              {row.registrationId}
            </Link>
          </TableCell>
          <TableCell>{row.farmerFullname}</TableCell>
          <TableCell>{row.month}</TableCell>
          <TableCell>{row.province}</TableCell>
          <TableCell>{row.groupArea}</TableCell>
          <TableCell>{row.segment}</TableCell>
          <TableCell>{row.farmSize}</TableCell>
          <TableCell>{row.farmAmount}</TableCell>
          <TableCell>{row.houseAmount}</TableCell>
          <TableCell>{thousandComma(row.loanOutstanding) + " บาท"}</TableCell>
          <TableCell>{thousandComma(row.repayment) + " บาท"}</TableCell>
        </TableRow>
      );
    });
  }, [data, currentPage]);

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <div className="flex justify-start items-center gap-3">
              <div
                onClick={() => navigate("/admin/performance/dept-collection")}
              >
                <ChevronLeftIcon fontSize="large" />
              </div>
              <span className="text-[#000] font-bold text-3xl">
                Debt Collection
              </span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="flex flex-col py-6 gap-6">
              <div className="flex flex-row gap-3 items-end">
                <Dropdown
                  label="ปี"
                  options={yearOption.map((row) => ({
                    value: row,
                    label: row,
                  }))}
                  onChange={(value) => {
                    setYear(value[0].value);
                  }}
                  onReset={() => {
                    setYear("");
                    setToggleFetch((prev) => !prev);
                  }}
                  renderValueArray={
                    [
                      {
                        value: year,
                        label: year,
                      },
                    ] as TDropdownValue[]
                  }
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                />
                <Dropdown
                  label="เดือน"
                  options={monthOption}
                  onChange={(value) => {
                    setMonth(value);
                  }}
                  onReset={() => {
                    setMonth([]);
                    setToggleFetch((prev) => !prev);
                  }}
                  renderValueArray={month}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                  multiple
                />
                <Dropdown
                  label="Group Area"
                  options={groupAreaOption.map((row) => ({
                    value: row.id,
                    label: row.name,
                  }))}
                  onChange={(value) => {
                    setGroupArea(value);
                  }}
                  onReset={() => {
                    setGroupArea([]);
                    setToggleFetch((prev) => !prev);
                  }}
                  renderValueArray={groupArea}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                />
                <Dropdown
                  label="Segment"
                  options={segmentOption.map((row) => ({
                    value: row,
                    label: row,
                  }))}
                  onChange={(value) => setSegment(value)}
                  onReset={() => {
                    setSegment([]);
                    setToggleFetch((prev) => !prev);
                  }}
                  renderValueArray={segment}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                  multiple
                />
                <ButtonFillCustom
                  title="ค้นหา"
                  onClick={() => {
                    fetchMoneyDebtList();
                  }}
                  className="!mb-3"
                />
              </div>
              <div className="w-full flex justify-between">
                <TextInputSearchCustom
                  value={searchText}
                  className="max-w-[470px]"
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                  placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
                  InputProps={{
                    endAdornment: searchText ? (
                      <CloseRoundedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchText("");
                          setToggleFetch((prev) => !prev);
                        }}
                      />
                    ) : null,
                  }}
                />
                <ButtonOutlinedCustom
                  title="นำออก"
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  onClick={() => {
                    handleExportData();
                  }}
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                />
              </div>
            </div>

            <TableCustom
              order={orderByType}
              onChangeOrder={setOrderByType}
              orderBy={currentOrderByKey}
              onChangeOrderBy={setCurrentOrderByKey as any}
              totalItemSize={0}
              headerItems={headerItems}
              page={currentPage}
              onChangePage={setCurrentPage}
              rowsPerPage={limit}
              rows={[]}
              EmptyComponent={
                data.length === 0 ? (
                  <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
                ) : null
              }
              rowChildren={rowChildren}
              currentPageItems={data!.map(({ id }) => id)}
              hideSelect
              itemsSelected={[]}
              onSelectItem={(_) => {}}
              onSelectAllClick={(_) => {}}
            />
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default DeptCollectionTable;
