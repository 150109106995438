import dayjs from "dayjs";

export const echartsExportPng = (
  echartRef: React.MutableRefObject<undefined>,
  fileName: string
) => {
  //@ts-ignore
  const echartInstance = echartRef.current.getEchartsInstance();
  const base64 = echartInstance.getDataURL({ backgroundColor: "#fff" });
  var link = document.createElement("a");
  link.download = `${fileName} ${dayjs().format("YYYYMMDDHHmmss")}`;
  link.href = base64;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
