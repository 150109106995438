import { useCallback, useEffect, useState } from "react";
import { covertToBlobUrl } from "../../utils/downloadFile";
import {
  getArticleDownloadUrlAPI,
  getPigPrice,
  getPigPriceDownloadUrlAPI,
  getSequencePigPrice,
  getUserManual,
  sequenceArticleFarmer,
  sequenceSwiperArticleFarmer,
} from "./farmCommunity.service";
import {
  ArticleFarmerRow,
  IGetPigPriceRow,
  IGetUserManualFarmerRow,
} from "./type";

export const useFarmCommunityController = (role: string) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [tabArticleIndex, setTabArticleIndex] = useState(0);
  const [tabArticleType, setTabArticleType] = useState("article");
  const [data, setData] = useState<ArticleFarmerRow[]>([]);
  const [dataSwiper, setDataSwiper] = useState<ArticleFarmerRow[]>([]);
  const [fileUrls, setFileUrls] = useState<string[]>([]);
  const [fileSwiperUrls, setFileSwiperUrls] = useState<string[]>([]);
  // pig price
  const [dataPigPriceSequence, setDataPigPriceSequence] = useState<
    IGetPigPriceRow[]
  >([]);
  const [dataPigPrice, setDataPigPrice] = useState<IGetPigPriceRow[]>([]);
  const [filePigPriceUrls, setFilePigPriceUrls] = useState<string[]>([]);
  const [filePigPriceSwiperUrls, setFilePigPriceSwiperUrls] = useState<
    string[]
  >([]);

  //* user manual
  const [tabUserManualIndex, setTabUserManualIndex] = useState(0);
  const [tabUserManualType, setTabUserManualType] = useState("article");
  const [userManual, setUserManual] = useState<IGetUserManualFarmerRow[]>([]);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const onChangeArticleTab = useCallback((e: any, index: number) => {
    setTabArticleIndex(index);
    setTabArticleType(index === 0 ? "article" : "video");
  }, []);

  const onChangeUserManualTab = useCallback((e: any, index: number) => {
    setTabUserManualIndex(index);
    setTabUserManualType(index === 0 ? "article" : "video");
  }, []);

  useEffect(() => {
    const handleFetch = async () => {
      const fetchedData = await sequenceArticleFarmer(tabArticleType, role);
      if (fetchedData) {
        setData(fetchedData.rows);
      }
    };
    handleFetch();
  }, [tabArticleType]);

  useEffect(() => {
    const handleFetch = async () => {
      const fetchedData = await sequenceSwiperArticleFarmer(
        tabArticleType,
        role
      );
      if (fetchedData) {
        setDataSwiper(fetchedData.rows);
      }
    };
    handleFetch();
  }, [tabArticleType]);

  useEffect(() => {
    const fetchFileUrls = async () => {
      const urls = await Promise.all(
        data.map(async (newsItem) => {
          const fileId = newsItem.articleFiles[0].id;
          const response = await getArticleDownloadUrlAPI(fileId, role);
          if (response) {
            try {
              return await covertToBlobUrl(response.blob.blobUrl);
            } catch (error) {
              console.error("Error converting blob URL:", error);
              return "";
            }
          }
          return "";
        })
      );
      setFileUrls(urls);
    };
    if (data.length > 0) {
      fetchFileUrls();
    }
  }, [data]);

  useEffect(() => {
    const fetchFileUrls = async () => {
      const urls = await Promise.all(
        dataSwiper.map(async (newsItem) => {
          const fileId = newsItem.articleFiles[0].id;
          const response = await getArticleDownloadUrlAPI(fileId, role);
          if (response) {
            try {
              return await covertToBlobUrl(response.blob.blobUrl);
            } catch (error) {
              console.error("Error converting blob URL:", error);
              return "";
            }
          }
          return "";
        })
      );
      setFileSwiperUrls(urls);
    };

    if (dataSwiper.length > 0) {
      fetchFileUrls();
    }
  }, [dataSwiper]);

  //pig price

  useEffect(() => {
    const fetchPigPiceSequence = async () => {
      const data = await getSequencePigPrice(role);
      if (data) {
        setDataPigPriceSequence(data?.rows);
      }
    };
    const fetchPigPice = async () => {
      const data = await getPigPrice(role);
      if (data) {
        setDataPigPrice(data?.rows);
      }
    };
    fetchPigPice();
    fetchPigPiceSequence();
  }, []);

  useEffect(() => {
    const fetchFilePigPriceUrls = async () => {
      const urls = await Promise.all(
        dataPigPrice.map(async (newsItem) => {
          const fileId = newsItem.articleFiles[0].id;
          const response = await getPigPriceDownloadUrlAPI(fileId, role);
          if (response) {
            try {
              return await covertToBlobUrl(response.blob.blobUrl);
            } catch (error) {
              console.error("Error converting blob URL:", error);
              return "";
            }
          }
          return "";
        })
      );
      setFilePigPriceUrls(urls);
    };
    if (dataPigPrice.length > 0) {
      fetchFilePigPriceUrls();
    }
  }, [dataPigPrice]);

  useEffect(() => {
    const fetchPigPriceFileUrls = async () => {
      const urls = await Promise.all(
        dataPigPriceSequence.map(async (newsItem) => {
          const fileId = newsItem.articleFiles[0].id;
          const response = await getPigPriceDownloadUrlAPI(fileId, role);
          if (response) {
            try {
              return await covertToBlobUrl(response.blob.blobUrl);
            } catch (error) {
              console.error("Error converting blob URL:", error);
              return "";
            }
          }
          return "";
        })
      );
      setFilePigPriceSwiperUrls(urls);
    };

    if (dataPigPriceSequence.length > 0) {
      fetchPigPriceFileUrls();
    }
  }, [dataPigPriceSequence]);

  useEffect(() => {
    const fetchUserManual = async () => {
      const data = await getUserManual(role, tabUserManualType);
      if (data) {
        setUserManual(data.rows);
      }
    };
    fetchUserManual();
  }, [tabUserManualType, role]);

  return {
    tabArticleIndex,
    tabIndex,
    onChangeTab,
    onChangeArticleTab,
    data,
    fileUrls,
    fileSwiperUrls,
    dataSwiper,
    dataPigPriceSequence,
    dataPigPrice,
    filePigPriceUrls,
    filePigPriceSwiperUrls,
    //* user manual
    tabUserManualIndex,
    onChangeUserManualTab,
    userManual,
    tabUserManualType,
  };
};
