export function StaffIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.8 7.19961C10.8 9.18783 9.18818 10.7996 7.19995 10.7996C5.21173 10.7996 3.59995 9.18783 3.59995 7.19961C3.59995 5.21138 5.21173 3.59961 7.19995 3.59961C9.18818 3.59961 10.8 5.21138 10.8 7.19961Z"
        fill="#3777BC"
      />
      <path
        d="M20.4 7.19961C20.4 9.18783 18.7882 10.7996 16.8 10.7996C14.8117 10.7996 13.2 9.18783 13.2 7.19961C13.2 5.21138 14.8117 3.59961 16.8 3.59961C18.7882 3.59961 20.4 5.21138 20.4 7.19961Z"
        fill="#3777BC"
      />
      <path
        d="M15.5149 20.3996C15.571 20.0077 15.6 19.607 15.6 19.1996C15.6 17.2374 14.9272 15.4323 13.7997 14.0025C14.6823 13.4919 15.707 13.1996 16.8 13.1996C20.1137 13.1996 22.8 15.8859 22.8 19.1996V20.3996H15.5149Z"
        fill="#3777BC"
      />
      <path
        d="M7.19995 13.1996C10.5137 13.1996 13.2 15.8859 13.2 19.1996V20.3996H1.19995V19.1996C1.19995 15.8859 3.88624 13.1996 7.19995 13.1996Z"
        fill="#3777BC"
      />
    </svg>
  );
}
