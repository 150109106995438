import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CircleIcon from "@mui/icons-material/Circle";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { date, object, string } from "yup";
import AppBarFS from "../../../components/app-bar/AppBar";
import DailyLogContext from "../../../provider/DailyLogProvider/DailyLogContext";
import {
  editDailyLog,
  getFeedBrands,
  getHousesFromFarmId,
} from "../../../services/DailyLogService";
import { getAllFarms } from "../../../services/FarmService";
import { Farm } from "../../../types";
import { FeedBrands } from "../../../types/FeedBrand.type";
import { House } from "../../../types/House.type";
import LogFeedDetails from "../../daily-log/feed-details";
import BasicDetailDailyLog from ".././basic-detail-daily-log";
import DailyLogVaccine from ".././daily-log-vaccine";
import DeathDailyLog from ".././death-daily-log";
import Treatment from ".././treatment";

const EditLog = ({ step, houseStartDate, farmId, houseId, logDetail }: any) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  // Loader
  const VACCINES = useLoaderData() as any;

  const { state } = useLocation();

  const [activeStep, setActiveStep] = useState<number>(0);
  const [farms, setFarms] = useState<Farm[]>([]);
  const [preSelectedFarm, setPreSelectedFarm] = useState<string>("");
  const [preSelectedHouse, setPreSelectedHouse] = useState<string>("");
  const [houses, setHouses] = useState<House[]>([]);
  const [feedBrands, setFeedBrands] = useState<FeedBrands[]>([]);

  const [totalPig, setTotalPig] = useState<number>(0);
  const [breederPig, setBreederPig] = useState<number>(0);
  const [totalDeadPig, setTotalDeadPig] = useState<number>(0);
  const [selectedHouse, setSelectedHouse] = useState<any>(undefined);
  const [feedForm, setFeedForm] = useState<any>(null);
  const [deathFormValues, setDeathFormValue] = useState<any>(null);
  const [vaccineFormValues, setVaccineFormValue] = useState<any>(null);
  const {
    setDailyLogData,
    finalDailyLogData,
    isEditMedicine,
    setEditMedicine,
  } = useContext(DailyLogContext);

  const CustomStepIcon = (isComplete: boolean, isPending: boolean) => {
    return isComplete ? (
      <FontAwesomeIcon
        color="secondary"
        className="text-secondary"
        size="lg"
        icon={faCircleCheck}
      />
    ) : (
      <CircleIcon
        fontSize="small"
        className="text-[#EBEBEB]"
        color={isPending ? "secondary" : undefined}
      />
    );
  };

  useEffect(() => {
    if (state?.selectedFarm) {
      formik.setFieldValue("farmId", state?.selectedFarm);
      setPreSelectedFarm(state?.selectedFarm);
    }
    if (state?.houseId) {
      formik.setFieldValue("houseId", state?.houseId);
      setPreSelectedHouse(state?.houseId);
    }
    if (state?.logDate) {
      formik.setFieldValue("date", dayjs(state?.logDate));
    }
    fetchFarms();
    fetchHousesFromFarmId(state.selectedFarm);
    fetchFeedBrand();
  }, [state]);
  useEffect(() => {
    if (state?.houseId) {
      formik.setFieldValue("houseId", state?.houseId);
      setPreSelectedHouse(state?.houseId);
    }
  }, [preSelectedFarm]);
  useEffect(() => {
    if (isEditMedicine) {
      setActiveStep(3);
      if (setEditMedicine) setEditMedicine(false);
    }
  }, [isEditMedicine]);
  useEffect(() => {
    setFinalData();
  }, [logDetail]);
  const setFinalData = () => {
    if (logDetail) {
      const feedForm = {
        feedBrand: logDetail.feedBrandId,
        feedIntake: logDetail.feedIntake,
        SKUOfFeed: logDetail.feedBrandItemId,
        date: state?.logDate,
      };
      const deathForm = {
        numberOfDeadPigs:
          logDetail.deadPigCount > 0 ? logDetail.deadPigCount : undefined,
        numberOfPigsSellingCarcass:
          logDetail.pigCarcassSellingCount > 0
            ? logDetail.pigCarcassSellingCount
            : undefined,
        pictureofDeadPig: [],
        reasonOfDeath:
          logDetail.deathReason !== null ? logDetail.deathReason : undefined,
        sellingPriceCarcass:
          logDetail.pigCarcassSellingPrice > 0
            ? logDetail.pigCarcassSellingPrice
            : undefined,
        totalWeightDeadPigs:
          logDetail.deadPigWeight > 0 ? logDetail.deadPigWeight : undefined,
        weightOfPigsSellingCarcass:
          logDetail.pigCarcassSellingWeight > 0
            ? logDetail.pigCarcassSellingWeight
            : undefined,
      };
      const vaccineForm = {
        numberOfPigsVaccine:
          logDetail.vaccinePigCount === null
            ? undefined
            : logDetail.vaccinePigCount,
        totalBottleOfVaccine:
          logDetail.vaccineBottleCount === null
            ? undefined
            : logDetail.vaccineBottleCount,
        vaccineId:
          logDetail.vaccineId === null ? undefined : logDetail.vaccineId,
        vaccinePerPig:
          logDetail.vaccineQuantity === null
            ? undefined
            : logDetail.vaccineQuantity,
      };
      const id = new Array<string>();
      const cageNo = new Array<string>();
      const TotalofMedicine = new Array<string>();
      const medicineData = new Array<string>();
      const medicineUnit = new Array<string>();
      const numberOfPig = new Array<string>();
      const symptom = new Array<string>();
      const nameOfMedicine = new Array<string>();
      logDetail.dailyLogTreatments.forEach((log: any) => {
        id.push(log.id);
        cageNo.push(log.cageNumber);
        TotalofMedicine.push(log.medicineCount);
        medicineData.push(log.medicineDetailId);
        medicineUnit.push(log.unit);
        numberOfPig.push(log.treatmentPigCount);
        symptom.push(log.symptom);
        nameOfMedicine.push(log.medicineName);
      });
      let treatmentForm = {};
      if (state.data) treatmentForm = state.data;
      else
        treatmentForm = {
          id: id,
          TotalofMedicine: TotalofMedicine,
          cageNo: cageNo,
          medicineData: medicineData,
          medicineUnit: medicineUnit,
          numberOfPig: numberOfPig,
          symptom: symptom,
          nameOfMedicine: nameOfMedicine,
        };
      if (setDailyLogData) {
        setDailyLogData({
          ...finalDailyLogData,
          feedData: { ...feedForm },
          deathData: { ...deathForm },
          vaccineData: { ...vaccineForm },
          treatmentData: { ...treatmentForm },
        });
      }
    }
  };
  const fetchFeedBrand = async () => {
    try {
      const { data } = await getFeedBrands();
      setFeedBrands(data.feedDetails);
    } catch (err) {
      console.error(err);
    }
  };

  const formik = useFormik({
    initialValues: {
      date: finalDailyLogData?.feedData?.date || dayjs(),
      farmId: finalDailyLogData?.feedData?.farmId || "",
      houseId: finalDailyLogData?.feedData?.houseId || "",
    },
    validationSchema: object({
      date: date().required(`${t("dailyLog.dateRequiredErrorMessage")}`),
      farmId: string().required(
        `${t("dailyLog.farmNameRequiredErrorMessage")}`
      ),
      houseId: string().required(
        `${t("dailyLog.houseNameRequiredErrorMessage")}`
      ),
    }),
    onSubmit: async (values) => {
      try {
      } catch (err) {
        console.error(err);
      }
    },
  });

  async function fetchFarms() {
    try {
      const { data } = await getAllFarms();
      if (Array.isArray(data?.farms) && data.farms?.length) {
        setFarms(data.farms);
        if (!preSelectedFarm) {
          setPreSelectedFarm(data?.farms?.[0]?.id);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const fetchHousesFromFarmId = async (farmId: string) => {
    try {
      const { data } = await getHousesFromFarmId(farmId);
      setHouses(data?.houses);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (houses) setSelectedHouse(houses.find((house) => house.id === houseId));
  }, [houses]);
  useEffect(() => {
    if (selectedHouse) {
      setBreederPig(selectedHouse.breederPig ? selectedHouse.breederPig : 0);
      setTotalDeadPig(parseInt(selectedHouse.totalDeadPigs));
      setTotalPig(
        (selectedHouse.breederPigCount ? selectedHouse.breederPigCount : 0) +
          (selectedHouse.fattenerPigCount
            ? selectedHouse.fattenerPigCount
            : 0) +
          (selectedHouse.nurseryPigCount ? selectedHouse.nurseryPigCount : 0)
      );
    }
  }, [selectedHouse]);
  const handleFeedDetailSubmit = async (val: any) => {
    const data: any = {
      feedBrandId: parseInt(val.feedBrand),
      feedBrandItemId: parseInt(val.SKUOfFeed),
      feedIntake: parseFloat(val.feedIntake),
    };
    const resp = await editDailyLog(data, logDetail.id);
    if (resp?.data?.success) setDailyLogData && setDailyLogData({});
    navigate(`/house/${houseId}`);
  };

  function handleBack(index: number) {
    if (index < 0) {
      if (setDailyLogData) setDailyLogData({});
      navigate(`/house/${houseId}`);
    }
    if (index === 0) {
      if (setDailyLogData) setDailyLogData({});
    } else if (index === 1) {
      if (setDailyLogData) setDailyLogData({});
    }
  }
  // Handle Death Submit
  async function handleDeathLog(val: any) {
    const data: any = {
      deadPigCount: val.numberOfDeadPigs ? parseInt(val.numberOfDeadPigs) : 0,
      deadPigWeight: val.totalWeightDeadPigs
        ? parseFloat(val.totalWeightDeadPigs)
        : 0,
      deathReason: val.reasonOfDeath,
      pigCarcassSellingCount: val.numberOfPigsSellingCarcass
        ? parseInt(val.numberOfPigsSellingCarcass)
        : 0,
      pigCarcassSellingWeight: val.weightOfPigsSellingCarcass
        ? parseFloat(val.weightOfPigsSellingCarcass)
        : 0,
      pigCarcassSellingPrice: val.sellingPriceCarcass
        ? parseFloat(val.sellingPriceCarcass)
        : 0,
    };
    const resp = await editDailyLog(data, logDetail.id);
    if (resp?.data?.success) setDailyLogData && setDailyLogData({});
    navigate(`/house/${houseId}`);
  }

  // Handle Vaccine Log
  async function handleVaccineLog(val: any) {
    const data: any = {
      vaccinePigCount: val.numberOfPigsVaccine
        ? parseInt(val.numberOfPigsVaccine)
        : 0,
      vaccineBottleCount: val.totalBottleOfVaccine
        ? parseInt(val.totalBottleOfVaccine)
        : 0,
      vaccineQuantity: val.vaccinePerPig ? parseFloat(val.vaccinePerPig) : 0,
      vaccineId: val.vaccineId,
    };
    const resp = await editDailyLog(data, logDetail.id);
    if (resp?.data?.success) setDailyLogData && setDailyLogData({});
    navigate(`/house/${houseId}`);
  }
  const getTreatmentData = (values: any) => {
    let treatmentData: any = [];
    values?.cageNo?.forEach((item: any, i: number) => {
      let data: any = {};
      if (values?.id?.length > 0 && values?.id[i]) data.id = values?.id[i];
      if (values?.cageNo?.length > 0 && values?.cageNo[i])
        data.cageNumber = Number(values?.cageNo[i]);
      if (values?.numberOfPig?.length > 0 && values?.numberOfPig[i])
        data.treatmentPigCount = Number(values?.numberOfPig[i]);
      if (values?.symptom?.length > 0 && values?.symptom[i])
        data.symptom = values.symptom[i];
      if (values?.medicineData?.length > 0 && values?.medicineData[i])
        data.medicineDetailId = values?.medicineData[i];
      if (values?.TotalofMedicine?.length > 0 && values?.TotalofMedicine[i])
        data.medicineCount = parseFloat(values?.TotalofMedicine[i]);
      if (values?.medicineUnit?.length > 0 && values?.medicineUnit[i])
        data.unit = values?.medicineUnit[i];
      data.unwellPigPicture = "";

      treatmentData.push(data);
    });

    return treatmentData;
  };
  async function handleTreatmentLog(val: any) {
    const treatMentData: any = getTreatmentData(val);
    const data: any = {
      treatment: treatMentData,
    };
    const resp = await editDailyLog(data, logDetail.id);
    if (resp?.data?.success)
      setDailyLogData &&
        setDailyLogData({
          treatmentData: {
            cageNo: [],
            numberOfPig: [],
            symptom: [],
            medicineData: [],
            TotalofMedicine: [],
            medicineUnit: ["CC"],
            pictureofUnwellPig: [[]],
            nameOfMedicine: [],
          },
        });
    navigate(`/house/${houseId}`);
  }
  const handleDateChange = (val: Dayjs | null) => {
    if (val) {
      formik.setFieldTouched("houseStartDate", true);
      formik.setFieldValue("date", val);
    }
  };
  const mapComponentToStep = () => {
    if (step === 0) {
      return (
        <LogFeedDetails
          onSubmit={handleFeedDetailSubmit}
          feedBrands={feedBrands}
          finalDailyLogData={finalDailyLogData}
        />
      );
    } else if (step === 1) {
      return (
        <DeathDailyLog
          breederPigCount={breederPig}
          totalPigs={totalPig}
          form={deathFormValues}
          deathDailyLogSubmit={handleDeathLog}
          finalDailyLogData={finalDailyLogData}
          currentDeadPigsCount={totalDeadPig}
          isEdit={true}
        />
      );
    } else if (step === 2) {
      return (
        <DailyLogVaccine
          totalPigs={totalPig}
          form={vaccineFormValues}
          onDailyLogVaccineSubmit={handleVaccineLog}
          finalDailyLogData={finalDailyLogData}
          vaccines={VACCINES}
        />
      );
    } else if (step === 3) {
      return (
        <Treatment
          onSubmit={handleTreatmentLog}
          finalDailyLogData={finalDailyLogData}
          isEdit={true}
          houseId={houseId}
          farmId={farmId}
          date={dayjs(state.logDate)}
          currentDeadPigsCount={parseInt(selectedHouse?.totalDeadPigs)}
          breederPigCount={selectedHouse?.breederPigCount}
          totalPigs={totalPig}
        />
      );
    }
  };

  const handleFarmChange = (value: any) => {
    formik.setFieldValue("farmId", value);
    formik.setFieldValue("houseId", "");
    // fetchHousesFromFarmId(event?.target?.value);
  };

  return (
    <div className="p-4 h-full  flex flex-col justify-between w-full">
      <AppBarFS
        title={t("dailyLog.logHeader")}
        onIconPress={() => handleBack(-1)}
      />
      <div className="flex flex-col grow mt-4 gap-4">
        <BasicDetailDailyLog
          formik={formik}
          farms={farms}
          houses={houses}
          handleFarmChange={handleFarmChange}
          handleDateChange={handleDateChange}
          formDisabled={true}
          maxDate={dayjs()}
          minDate={dayjs(houseStartDate)}
        />
        {mapComponentToStep()}
      </div>
    </div>
  );
};

export default EditLog;
