import adminAPI from "../../../../services/AdminApiService";
import {
  IProvinceSaleTarget,
  ISaleTarget,
  ISaleTargetCounting,
  ISaleTargetManagement,
  ISaleTargetManagementById,
  ISaleTargetSaleLead,
  ISaleTargetYear,
} from "../types/SaleTargetTable.type";

//* role sale

export const getCountSaleTarget = async () => {
  try {
    const { data } = await adminAPI.get<ISaleTargetCounting>(
      `/sale-target/sale/sale-target-counting`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getSaleTarget = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<ISaleTarget>(
      `/sale-target/sale/get-list-sale-target?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getSaleTargetYear = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<ISaleTargetYear>(
      `/sale-target/sale/get-dropdown-year?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const createSaleTarget = async (body: any) => {
  const { data } = await adminAPI.post("/sale-target/sale/create-target", body);
  return data;
};

export const editSaleTarget = async (id: string, body: any) => {
  const { data } = await adminAPI.put(
    `/sale-target/sale/update-sale-target/${id}`,
    body
  );
  return data;
};

//* role sale lead

export const getSaleLeadTarget = async (searchParams?: URLSearchParams) => {
  try {
    const { data } = await adminAPI.get<ISaleTargetSaleLead>(
      `/sale-target/sale-lead?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getProvinceSaleTarget = async () => {
  try {
    const { data } = await adminAPI.get<IProvinceSaleTarget>(
      `/sale-target/sale-lead/dropdown-province`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getListSaleTargetManagement = async (
  searchParams?: URLSearchParams
) => {
  try {
    const { data } = await adminAPI.get<ISaleTargetManagement>(
      `/sale-target/management?${searchParams?.toString()}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getSaleTargetManagerById = async (id: string) => {
  try {
    const { data } = await adminAPI.get<ISaleTargetManagementById>(
      `/sale-target/management/${id}`
    );
    return data;
  } catch (error) {
    return null;
  }
};
