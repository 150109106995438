import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import RadioButton from "../../../../../../components/common/RadioButton";
import { DATE_FORMAT_ENG } from "../../../../../../const/app.constant";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import ButtonFillCustom from "../../../../../components/Button/ButtonFillCustom";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import AdminModal from "../../../../../components/Modal/AdminModal";
import TableCustom, {
  ITableCustomHeadCell,
  Order,
} from "../../../../../components/Table/TableCustom";
import {
  addSortPigPice,
  getPigPriceTable,
} from "../../../services/farmCommunity.service";
import { IPigPriceTable } from "../../../types/farmCommunity";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  handleClose: (isSuccess: boolean) => void;
};

const headerItems: ITableCustomHeadCell<keyof IPigPriceTable>[] = [
  {
    id: "topicName",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "10px",
    isSorting: false,
  },
  {
    id: "topicName",
    numeric: false,
    disablePadding: false,
    label: "ชื่อหัวข้อ",
    width: "250px",
    isSorting: false,
  },
  {
    id: "updateAt",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัปเดตล่าสุด",
    width: "150px",
    isSorting: true,
  },
];

const createData = (
  id: string,
  displayPriority: number | null,
  topicName: string,
  announceDate: string,
  updateAt: string
): IPigPriceTable => {
  return {
    id,
    displayPriority: displayPriority ? displayPriority : null,
    topicName,
    announceDate: announceDate
      ? dayjs(announceDate).format(DATE_FORMAT_ENG)
      : "-",
    updateAt: dayjs(updateAt).format(DATE_FORMAT_ENG),
  };
};

const SortPigPiceModal = ({ isOpen, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [direction, setDirection] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IPigPriceTable>("updateAt");
  const [list, setList] = useState<IPigPriceTable[]>([]);
  const [count, setCount] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState<string>("");
  const [toggleFetch, setToggleFetch] = useState(false);

  const MapOrderByWithFilter: { [K in keyof IPigPriceTable]?: string } = {
    updateAt: "updated_at",
  };

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchText);
    searchParams.append("page", page.toString());
    searchParams.append("limit", limit.toString());
    searchParams.append("direction", direction);
    searchParams.append("orderBy", MapOrderByWithFilter[orderBy] ?? "");
    setList([]);

    const data = await getPigPriceTable(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.displayPriority,
          row.topicName,
          row.announceDate,
          row.updatedAt
        );
      });
      setCount(data.count);
      setList(newList);
    }
  };

  const handleSubmit = (id: string) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการบันทึก",
        text: "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังบันทึก..." }));
            await addSortPigPice(id);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "บันทึกสำเร็จ",
              })
            );
            handleClose(true);
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  useEffect(() => {
    if (isOpen) {
      handleFetch();
    }
    setSelectedRowId("");
  }, [direction, page, orderBy, isOpen, toggleFetch]);

  const handleSetSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setToggleFetch((prev) => !prev);
  };
  return (
    <div>
      <AdminModal
        label={"เรียงลำดับ"}
        isOpen={isOpen}
        size={"md"}
        handleClose={() => handleClose(false)}
        children={
          <>
            <form onSubmit={handleSetSearch}>
              <TextInputSearchCustom
                value={searchText}
                placeholder="ค้นหาโดยชื่อหัวข้อ"
                onChange={(event) => setSearchText(event.target.value)}
                InputProps={{
                  endAdornment:
                    searchText.length > 0 ? (
                      <CloseRoundedIcon
                        sx={{
                          cursor: "pointer",
                          fontSize: "18px !important",
                        }}
                        onClick={() => {
                          setSearchText("");
                          setToggleFetch((prev) => !prev);
                        }}
                      />
                    ) : null,
                }}
              />
            </form>
            <div className="mt-4">
              <TableCustom
                order={direction}
                orderBy={"updateAt"}
                itemsSelected={[]}
                rowsPerPage={limit}
                page={page}
                headerItems={headerItems}
                rows={list}
                onChangePage={(value) => {
                  setPage(value);
                }}
                onChangeOrder={(val) => setDirection(val)}
                onChangeOrderBy={(val) =>
                  setOrderBy(val as keyof IPigPriceTable)
                }
                onSelectItem={() => {}}
                onSelectAllClick={() => {}}
                totalItemSize={count}
                currentPageItems={list.map((row) => row.id)}
                hideSelect
                rowChildren={list.map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell
                      align="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "16px 0",
                      }}
                    >
                      <div className="ml-3">
                        <RadioButton
                          checked={selectedRowId === row.id}
                          isCheck={selectedRowId === row.id}
                          onClick={() => {
                            setSelectedRowId(row.id);
                          }}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.topicName}
                    </TableCell>
                    <TableCell align="left">{row.updateAt}</TableCell>
                  </TableRow>
                ))}
              />
            </div>
            <div className="flex justify-end mt-6">
              <ButtonFillCustom
                title={t("common.button.confirm")}
                onClick={() => handleSubmit(selectedRowId)}
              />
            </div>
          </>
        }
      />
    </div>
  );
};

export default SortPigPiceModal;
