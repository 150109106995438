import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TextInputSearchCustom from "../../../admin/components/Input/TextInputSearchCustom";
import AppBarFS from "../../../components/app-bar/AppBar";
import { useStockSeeDetailsController } from "./controller";
import AccordionShowList from "./views/AccordionShowList";

const StockDetails = () => {
  const { GetMyStock, searchText, onSearchText, onClearSearchText, id, setId } =
    useStockSeeDetailsController();

  let navigate = useNavigate();

  const handleBack = useCallback(() => {
    navigate("/partner");
  }, []);

  const { t } = useTranslation();

  return (
    <div className="w-full h-full bg-[#D9EBFE]">
      <div className="h-[120px] flex items-center bg-[#FFFFFF]">
        <AppBarFS title={t("OrderStock.Warehouse")} onIconPress={handleBack} />
      </div>
      <div className="flex flex-col gap-4 py-[16px] px-4 justify-between">
        <span className="font-semibold text-[20px] text-[#3777BC] flex flex-col justify-center">
          {t("OrderStock.AllStock")}
        </span>
        <div className="w-full flex justify-between">
          <TextInputSearchCustom
            value={searchText}
            className="max-w-[470px]"
            onChange={onSearchText}
            placeholder={t("OrderStock.SearchBrandSKU")}
            InputProps={{
              endAdornment:
                searchText.length > 0 ? (
                  <CloseRoundedIcon
                    sx={{
                      cursor: "pointer",
                      fontSize: "18px !important",
                    }}
                    onClick={onClearSearchText}
                  />
                ) : null,
              sx: {
                borderRadius: "30px",
                height: "48px",
              },
            }}
          />
        </div>
      </div>
      <AccordionShowList
        data={GetMyStock?.stocks || []}
        id={id}
        setId={setId}
      />
    </div>
  );
};

export default StockDetails;
