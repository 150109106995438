import {
  IGroupAreaAllTeamResponse,
  IGroupAreaAreaResponse,
  IGroupAreaCustomerWithoutCurrentGroup,
  IGroupAreaDistrictResponse,
  IGroupAreaEmployeeResponse,
  IGroupAreaFilterCustomerResponse,
  IGroupAreaFilterCustomerTableResponse,
  IGroupAreaGroupListColumnResponse,
  IGroupAreaPartnerNew,
  IGroupAreaProvinceResponse,
  IGroupAreaTableDetail,
} from "../types/GroupArea.type";
import adminAPI from "./AdminApiService";

export const getArea = async () => {
  try {
    const { data } = await adminAPI.get<IGroupAreaAreaResponse>("/region");
    return data;
  } catch (error) {
    return null;
  }
};

export const getProvince = async () => {
  try {
    const { data } = await adminAPI.get<IGroupAreaProvinceResponse>(
      "/group-area/province"
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getAllProvince = async () => {
  try {
    const { data } = await adminAPI.get<IGroupAreaProvinceResponse>(
      "/province"
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getDistrict = async () => {
  try {
    const { data } = await adminAPI.get<IGroupAreaDistrictResponse>(
      "/group-area/district"
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getAllDistrict = async () => {
  try {
    const { data } = await adminAPI.get<IGroupAreaDistrictResponse>(
      "/district"
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getEmployee = async () => {
  try {
    const { data } = await adminAPI.get<IGroupAreaEmployeeResponse>(
      "/user-management/employee",
      {
        params: {
          page: 1,
          limit: 9999999,
          // orderBy: 'type',
        },
      }
    );
    return data;
  } catch (error) {
    return null;
  }
};

// export const getPartner = async () => {
//   try {
//     const { data } = await adminAPI.get<IGroupAreaPartnerResponse>(
//       "/group-area/partners"
//     );
//     return data;
//   } catch (error) {
//     return null;
//   }
// };
export const getPartner = async () => {
  try {
    const { data } = await adminAPI.get<IGroupAreaPartnerNew>(
      "/group-area/partners"
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getGroupAreaColumn = async (params: {
  regionCode?: string;
  searchText?: string;
  districtCodes?: string;
  provinceCodes?: string;
}) => {
  try {
    const { data } = await adminAPI.get<IGroupAreaGroupListColumnResponse>(
      "/group-area/column",
      { params }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const filterCustomer = async (body: {
  provinceCodes: number[];
  districtCodes: number[];
}) => {
  const { data } = await adminAPI.post<IGroupAreaFilterCustomerResponse>(
    "/group-area/farmers",
    body
  );
  return data;
};

export const createGroup = async (body: {
  name: string;
  regionCode: number;
  provinceCodes: number[];
  districtCodes: number[];
  sellerId: string;
  partnerId: string;
}) => {
  const { data } = await adminAPI.post<{ message: string }>(
    "/group-area",
    body
  );
  return data;
};

export const filterCustomerTable = async (params: {
  regionCode?: number;
  searchText?: string;
  page: number;
  limit: number;
  orderBy: "region" | "name";
  direction: "DESC" | "ASC";
  provinceCodes?: string;
  districtCodes?: string;
}) => {
  const { data } = await adminAPI.get<IGroupAreaFilterCustomerTableResponse>(
    "/group-area/table",
    { params }
  );
  return data;
};

export const getGroupAreaTableDetail = async (params: { id: string }) => {
  const { data } = await adminAPI.get<IGroupAreaTableDetail>(
    `/group-area/table/${params.id}`,
    { params }
  );
  return data;
};

export const getAllGroupArea = async () => {
  const { data } = await adminAPI.get<IGroupAreaAllTeamResponse>(
    "group-area/teams"
  );
  return data;
};

export const addFarmerToGroup = async (body: {
  groupId: string;
  farmerIds: string[];
}) => {
  const { data } = await adminAPI.put(`/group-area/farmers/${body.groupId}`, {
    farmerIds: body.farmerIds,
  });
  return data;
};

export const getCustomerWithoutCurrentGroup = async (id: string) => {
  const { data } = await adminAPI.get<IGroupAreaCustomerWithoutCurrentGroup>(
    `/group-area/farmers/${id}`
  );
  return data;
};

export const editGroupArea = async (body: {
  id: string;
  name: string;
  regionCode: number;
  provinceCodes: number[];
  districtCodes: number[];
  sellerId: string;
  partnerId: string;
}) => {
  const { data } = await adminAPI.put(`/group-area`, body);
  return data;
};
