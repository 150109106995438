export default function () {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.4974 10.5469H12.4974M7.4974 13.8802H12.4974M14.1641 18.0469H5.83073C4.91025 18.0469 4.16406 17.3007 4.16406 16.3802V4.71354C4.16406 3.79307 4.91025 3.04688 5.83073 3.04688H10.4856C10.7066 3.04688 10.9185 3.13467 11.0748 3.29095L15.5867 7.8028C15.7429 7.95908 15.8307 8.17104 15.8307 8.39205V16.3802C15.8307 17.3007 15.0845 18.0469 14.1641 18.0469Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
