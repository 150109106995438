import { Checkbox, TableCell } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { IStockRefillDetail } from "../../../../../types/ManageStock.type";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";
import { FillProductData } from "../type";
import FillProductCellDetail from "./FillProductCellDetail";
import { useTranslation } from "react-i18next";

export type TStockRefill =
  | "stock_created"
  | "delivery"
  | "waiting_for_acceptance"
  | "accepted"
  | "rejected";

interface FillProductPanelProps {
  itemSelected: string[];
  setItemSelected: Dispatch<SetStateAction<string[]>>;
  data: IStockRefillDetail[];
  id: string;
  refetch: () => void;
  showButton: boolean;
}
function FillProductPanel({
  itemSelected,
  setItemSelected,
  data,
  id,
  refetch,
  showButton,
}: FillProductPanelProps) {
  const { t } = useTranslation();

  const ChipStyle: {
    [k in TStockRefill]: {
      label: string;
      fontColor: string;
      bgColor: string;
    };
  } = {
    stock_created: {
      label: "สร้างรายการเติมสินค้า",
      fontColor: "#4B4E5F",
      bgColor: "#E2E2E2",
    },
    delivery: {
      label: "อยู่ระหว่างจัดส่ง",
      fontColor: "#F57C00",
      bgColor: "#F7E6D3",
    },
    waiting_for_acceptance: {
      label: "จัดส่งสำเร็จ",
      fontColor: "#409261",
      bgColor: "#E9FFEF",
    },
    accepted: {
      label: "รับสินค้าสำเร็จ",
      fontColor: "#409261",
      bgColor: "#E9FFEF",
    },
    rejected: {
      label: "จัดส่งไม่สำเร็จ",
      fontColor: "#D32F2F",
      bgColor: "#F9E0E0",
    },
  };

  const headCells: ITableCustomHeadCell<keyof FillProductData>[] = [
    {
      id: "stockRefillNumber",
      numeric: false,
      disablePadding: false,
      label: t("stockManagement.restockList.orderNo"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "deliveryNumber",
      numeric: false,
      disablePadding: false,
      label: t("stockManagement.restockList.deliveryNo"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "totalAmount",
      numeric: false,
      disablePadding: false,
      label: t("stockManagement.restockList.totalAmount"),
      width: "200px",
      isSorting: false,
    },
    {
      id: "actualShippingDate",
      numeric: false,
      disablePadding: false,
      label: t("stockManagement.restockList.deliveryDate"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: t("stockManagement.restockList.status"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "auto",
      isSorting: false,
    },
  ];
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = data.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };
  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;
  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }
    setItemSelected(newSelected);
  };

  return (
    <TableCustom
      order={"asc"}
      orderBy={""}
      totalItemSize={0}
      rowsPerPage={999}
      page={1}
      headerItems={headCells}
      rows={data}
      onChangePage={(_) => {}}
      onChangeOrder={(_) => {}}
      onChangeOrderBy={(_) => {}}
      currentPageItems={data.map((row) => row.id)}
      itemsSelected={itemSelected}
      onSelectItem={(val) => {
        setItemSelected(val as string[]);
      }}
      onSelectAllClick={(val) => handleSelectAllClick(val)}
      hidePaginator
      rowChildren={data.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const { bgColor, fontColor, label } =
          ChipStyle[row.status as TStockRefill];
        return (
          <ExpandableTableRow
            key={row.id}
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            selected={isItemSelected}
            expandComponent={
              <FillProductCellDetail
                showButton={showButton}
                status={row.status as TStockRefill}
                data={row.stockRefillItems}
                partnerId={id}
                stockId={row.id}
                stockFiles={row.stockRefillFiles}
                refetch={refetch}
              />
            }
          >
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                checked={isItemSelected}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
                onClick={(event) => handleClick(event, row.id)}
                sx={{
                  borderWidth: "1px",
                  color: "#C9C9C9",
                  "&.Mui-checked": {
                    color: "#68C184",
                  },
                }}
              />
            </TableCell>
            <TableCell>{row.stockRefillNumber || "-"}</TableCell>
            <TableCell>{row.deliveryNumber || "-"}</TableCell>
            <TableCell>{row.totalAmount}</TableCell>
            <TableCell>
              {row.actualShippingDate
                ? dayjs(row.actualShippingDate).format("DD/MM/YYYY")
                : "-"}
            </TableCell>
            <TableCell>
              <ChipOrderStatus
                label={label}
                fontColor={fontColor}
                bgColor={bgColor}
              />
            </TableCell>
          </ExpandableTableRow>
        );
      })}
    />
  );
}

export default FillProductPanel;
