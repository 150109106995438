import { TableCell, TableRow, styled, tableCellClasses } from "@mui/material";
import dayjs from "dayjs";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Invoice } from "../../../../types/Invoice.type";
import { getCommaNumber } from "../../../../utils/number";
import { tw } from "../../../../utils/tw";
import TableCustomMobile from "../../../financial-support/financial-billPayment/unpaid-bill/views/TableCustomMobile";

const classes = {
  container: tw(`min-h-[277px]`),
  emptyTable: tw(
    `font-sans text-center w-full text-[#979797] text-[14px] py-3`
  ),
};

interface ITable {
  swapCellColor?: boolean;
  data: Invoice[];
}

const Table = (props: ITable) => {
  const { swapCellColor, data } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation();

  const headerItems = [
    {
      id: "invoiceNo",
      numeric: false,
      disablePadding: false,
      label: t("MoneyDebtAndCollection.AssignNo"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "paymentDueDate",
      numeric: false,
      disablePadding: false,
      label: t("MoneyDebtAndCollection.PaymentDueDate"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "totalPay",
      numeric: false,
      disablePadding: false,
      label: t("MoneyDebtAndCollection.AmountDue"),
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
  ];

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#D9EBFE",
      color: "#4B4B4B",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: `${swapCellColor ? "#ffffff" : "#f6faff"}`,
    },
    "&:nth-of-type(even)": {
      backgroundColor: `${swapCellColor ? "#f6faff" : "#ffffff"}`,
    },
  }));

  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      return (
        <StyledTableRow key={row.id + index}>
          <StyledTableCell
            padding="checkbox"
            sx={{
              border: "0px !important",
              paddingX: "4px",
              whiteSpace: "pre",
            }}
          >
            {row.invoiceNumber}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
            }}
          >
            {dayjs(row.dueDate).format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell
            sx={{
              border: "0px !important",
            }}
          >
            {getCommaNumber(row.totalPrice - row.totalPaid, true)}
          </StyledTableCell>
        </StyledTableRow>
      );
    });
  }, [data]);

  return (
    <div className={classes.container}>
      <TableCustomMobile
        orderBy={""}
        order={"desc"}
        onChangeOrder={() => {}}
        onChangeOrderBy={() => {}}
        totalItemSize={data.length}
        headerItems={headerItems}
        page={currentPage}
        onChangePage={setCurrentPage}
        rowsPerPage={4}
        rows={data}
        EmptyComponent={
          data?.length === 0 ? (
            <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
          ) : null
        }
        rowChildren={rowChildren}
        currentPageItems={data.map(({ id }) => id)}
        itemsSelected={[]}
        onSelectAllClick={() => {}}
        onSelectItem={() => {}}
        hideSelect
      />
    </div>
  );
};

export default memo(Table);
