import AddCircleIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Dialog,
  DialogContent,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { FormEvent, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tw } from "../../../../../../utils/tw";
import CrossIcon from "../../../../../assets/svg/CrossIcon.svg";
import { useAdminAuth } from "../../../../../components/AdminAuth/AdminAuthProvider";
import ButtonOutlinedCustom from "../../../../../components/Button/ButtonOutlinedCustom";
import TextInputSearchCustom from "../../../../../components/Input/TextInputSearchCustom";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../../../components/Table/TableCustom";
import { useVaccineFilterHook } from "../../../hooks/vaccine/useVaccineFilter.hook";
import { useVaccineTableHook } from "../../../hooks/vaccine/useVaccineTable.hook";
import { IVaccineTable } from "../../../types/vaccine.type";
import CreateVaccineModal from "./CreateVaccineModal";
import EditVaccineModal from "./EditVaccineModal";

interface IFoodModalProps {
  isOpen: boolean;
  onCloseFn: () => void;
}

const slotProps = {
  backdrop: {
    style: { backgroundColor: "#000000", opacity: "10%" },
    timeout: 500,
  },
};

const classes = {
  container: tw(
    `w-[100vw] m-auto [&_.MuiButtonBase-root.Mui-selected]:!bg-secondary [&_.MuiDialog-paperScrollPaper]:!m-0 max-w-[830px]`
  ),
  titleContainer: tw(`flex justify-between items-center`),
  title: tw(`text-lg font-bold pb-3 mb-1`),
  row: tw(
    `w-full flex flex-col md:flex-row item-center justify-between gap-x-3`
  ),
  space: tw(`h-[12px]`),
};

const VaccineModal = ({ isOpen, onCloseFn }: IFoodModalProps) => {
  const { t } = useTranslation();
  const [toggleClear, setToggleClear] = useState<boolean>(false);
  const [isOpenCreateModal, setIsOpenCreteModal] = useState<boolean>(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const searchVaccine = useVaccineFilterHook();
  const { list, handleFetch } = useVaccineTableHook(searchVaccine);

  const { action } = useAdminAuth();
  const roleActionEdit = action?.masterDataVaccine === "edit";

  const headerItems: ITableCustomHeadCell<keyof IVaccineTable>[] = [
    {
      id: "vaccineNameTH",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.vaccine.vaccineModal.vaccineName")} (TH)`,
      width: "50%",
      isSorting: true,
    },
    {
      id: "vaccineNameEN",
      numeric: false,
      disablePadding: false,
      label: `${t("masterData.vaccine.vaccineModal.vaccineName")} (EN)`,
      width: "50%",
      isSorting: true,
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchVaccine.setPage(1);
    setToggleClear((prev) => !prev);
  };

  useEffect(() => {
    handleFetch();
  }, [
    searchVaccine.count,
    searchVaccine.page,
    searchVaccine.orderBy,
    toggleClear,
  ]);

  return (
    <>
      <Dialog
        open={isOpen}
        maxWidth="lg"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={slotProps}
        className={classes.container}
        classes={{ paper: "w-full" }}
        onClose={onCloseFn}
      >
        <DialogContent>
          <div className={classes.titleContainer}>
            <p className={classes.title}>
              {t("masterData.vaccine.vaccineModal.title")}
            </p>
            <IconButton onClick={onCloseFn}>
              <CrossIcon />
            </IconButton>
          </div>
          <div className={classes.space} />
          <div className={classes.row}>
            <form onSubmit={handleSubmit}>
              <div className="min-w-[470px]">
                <TextInputSearchCustom
                  value={searchVaccine.searchText}
                  placeholder="ค้นหาโดยชื่อวัคซีน"
                  onChange={(event) =>
                    searchVaccine.setSearchText(event.target.value)
                  }
                  InputProps={{
                    endAdornment:
                      searchVaccine.searchText.length > 0 ? (
                        <CloseRoundedIcon
                          sx={{
                            cursor: "pointer",
                            fontSize: "18px !important",
                          }}
                          onClick={(_) => {
                            setToggleClear((prev) => !prev);
                            searchVaccine.setSearchText("");
                          }}
                        />
                      ) : null,
                  }}
                />
              </div>
            </form>
            {roleActionEdit && (
              <div className="flex gap-x-3">
                <ButtonOutlinedCustom
                  title="เพิ่ม"
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  startIcon={<AddCircleIcon />}
                  onClick={() => setIsOpenCreteModal(true)}
                />
                <ButtonOutlinedCustom
                  title="แก้ไข"
                  btnBorderColor="#68C184"
                  btnTextColor="#68C184"
                  onClick={() => setIsOpenEditModal(true)}
                />
              </div>
            )}
          </div>
          <div className="mt-6">
            <TableCustom
              order={searchVaccine.direction}
              orderBy={searchVaccine.orderBy}
              itemsSelected={[]}
              rowsPerPage={10}
              page={searchVaccine.page}
              headerItems={headerItems}
              rows={list}
              onChangeOrderBy={(val) =>
                searchVaccine.setOrderBy(val as keyof IVaccineTable)
              }
              onChangeOrder={(val) => searchVaccine.setDirection(val)}
              onChangePage={(val) => searchVaccine.setPage(val)}
              onSelectItem={() => {}}
              onSelectAllClick={() => {}}
              totalItemSize={searchVaccine.count}
              currentPageItems={[]}
              hideSelect
              maxHeightForScroll="500px"
              rowChildren={list.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.vaccineNameTH}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.vaccineNameEN}
                    </TableCell>
                  </TableRow>
                );
              })}
            />
          </div>
        </DialogContent>
      </Dialog>
      <CreateVaccineModal
        isOpen={isOpenCreateModal}
        onClose={() => setIsOpenCreteModal(false)}
        handleFetch={handleFetch}
      />
      <EditVaccineModal
        isOpen={isOpenEditModal}
        onClose={() => setIsOpenEditModal(false)}
        handleFetch={handleFetch}
      />
    </>
  );
};

export default memo(VaccineModal);
