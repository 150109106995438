import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider, SvgIcon, Tab } from "@mui/material";
import { get, isEmpty } from "lodash";
import React, { useEffect } from "react";
import { tw } from "../../../utils/tw";
import { CalendarSvg } from "../../assets/svg/Calendar.svg";
import { DownloadSvg } from "../../assets/svg/Download.svg";
import PlusCircleIcon from "../../assets/svg/PlusCircleIcon.svg";
import ButtonFillCustom from "../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../components/Button/ButtonOutlinedCustom";
import DateRangePickerPopover from "../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import TextInputCustom from "../../components/Input/TextInputCustom";
import TextInputSearchCustom from "../../components/Input/TextInputSearchCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import { TabPanelCustom } from "../../components/TabPanelCustom/TabPanelCustom";
import { Dropdown } from "../group-area/views/Dropdown";
import Tabs from "../group-area/views/Tabs";
import { useClaimOrderController } from "./controller";
import OrderClaim from "./orderClaim";
import CreateReportModal from "./views/CreateReportModalNew";
import SelectAdminCustom from "../../components/Select/Select";
import { makeXlsx } from "../../../utils/make-xlsx";
import dayjs from "dayjs";
import { getExportClaimOrder } from "../../../services/ClaimOrder.service";
import { useAdminAuth } from "../../components/AdminAuth/AdminAuthProvider";
import { useTranslation } from "react-i18next";

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  title: tw(`text-black font-bold text-3xl`),
  mainContainer: tw(`pt-6 flex flex-col w-full`),
  filterRowContainer: tw(`flex flex-row flex-wrap items-end gap-3`),
  dateContainer: tw(`w-[25%] flex flex-col gap-[6px] mb-[11px]`),
};

const Claim = () => {
  const { action } = useAdminAuth();
  const { t } = useTranslation();
  const roleActionEdit =
    action?.orderClaimAll === "edit" ||
    action?.orderClaimApproved === "edit" ||
    action?.orderClaimHistory === "edit";

  const {
    tabIndex,
    setTabIndex,
    onChangeTab,
    onShowCreateReport,
    onHideCreateReport,
    isShowCreateReport,
    listClaimOrder,
    listClaimOrderProgress,
    listClaimOrderHistory,
    currentPageUpComing,
    setCurrentPageUpComing,
    currentPageProgress,
    setCurrentPageProgress,
    currentPageHistory,
    setCurrentPageHistory,
    refetchListClaimOrderUpcoming,
    refetchListClaimOrderProgress,
    refetchListClaimOrderHistory,
    searchTextUpComing,
    onSearchTextUpcoming,
    onClearSearchTextUpcoming,
    searchTextProgress,
    onSearchTextProgress,
    onClearSearchTextProgress,
    searchTextHistory,
    onSearchTextHistory,
    onClearSearchTextHistory,
    setSearchTextUpComing,
    setSearchTextProgress,
    setSearchTextHistory,
    //////////////////////////// DropdownUp ////////////////////////////
    brandsOptionsUpComing,
    brandUpComing,
    setBrandUpComing,
    feedOptionsUpComing,
    feedUpComing,
    setFeedUpComing,
    //////////////////////////// DropdownIn ////////////////////////////
    brandsOptionsInProgress,
    brandPageInProgress,
    setBrandPageInProgress,
    feedOptionsInProgress,
    feedPageInProgress,
    setFeedPageInProgress,
    //////////////////////////// DropdownHis ////////////////////////////
    brandsOptionsHistory,
    brandPageHistory,
    setBrandPageHistory,
    feedOptionsHistory,
    feedPageHistory,
    setFeedPageHistory,
    /////////////////////////////////// DateUp ///////////////////////////////////
    shippingStartDate,
    setShippingStartDate,
    shippingEndDate,
    setShippingEndDate,
    orderStartDate,
    setOrderStartDate,
    orderEndDate,
    setOrderEndDate,
    itemSelectedUpComing,
    setItemSelectedUpComing,
    currentOrderByKey1,
    setCurrentOrderByKey1,
    orderByType1,
    setOrderByType1,
    onChangeFormUpComing,
    /////////////////////////////////// DateIn ///////////////////////////////////
    shippingStartDateIn,
    setShippingStartDateIn,
    shippingEndDateIn,
    setShippingEndDateIn,
    orderStartDateIn,
    setOrderStartDateIn,
    orderEndDateIn,
    setOrderEndDateIn,
    itemSelectedInProgress,
    setItemSelectedInProgress,
    currentOrderByKey2,
    setCurrentOrderByKey2,
    orderByType2,
    setOrderByType2,
    onChangeFormInProgress,
    /////////////////////////////////// DateHis ///////////////////////////////////
    shippingStartDateHis,
    setShippingStartDateHis,
    shippingEndDateHis,
    setShippingEndDateHis,
    orderStartDateHis,
    setOrderStartDateHis,
    orderEndDateHis,
    setOrderEndDateHis,
    itemSelectedHistory,
    setItemSelectedHistory,
    currentOrderByKey3,
    setCurrentOrderByKey3,
    orderByType3,
    setOrderByType3,
    onChangeFormHistory,
    /////////////////////////////////// CalenderUp ///////////////////////////////////
    isOpenCalenderShippingDate,
    setIsOpenCalenderShippingDate,
    refCalendarRangeShippingDate,
    handleSelectShippingDate,
    handleCloseCalenderShippingDate,
    handleCalenderSaveShippingDate,
    isOpenCalenderOrderDate,
    setIsOpenCalenderOrderDate,
    refCalendarRangeOrderDate,
    handleSelectOrderDate,
    handleCloseCalenderOrderDate,
    handleCalenderSaveOrderDate,
    /////////////////////////////////// CalenderIn ///////////////////////////////////
    isOpenCalenderShippingDateIn,
    setIsOpenCalenderShippingDateIn,
    refCalendarRangeShippingDateIn,
    handleSelectShippingDateIn,
    handleCloseCalenderShippingDateIn,
    handleCalenderSaveShippingDateIn,
    isOpenCalenderOrderDateIn,
    setIsOpenCalenderOrderDateIn,
    refCalendarRangeOrderDateIn,
    handleSelectOrderDateIn,
    handleCloseCalenderOrderDateIn,
    handleCalenderSaveOrderDateIn,
    /////////////////////////////////// CalenderHis ///////////////////////////////////
    isOpenCalenderShippingDateHis,
    setIsOpenCalenderShippingDateHis,
    refCalendarRangeShippingDateHis,
    handleSelectShippingDateHis,
    handleCloseCalenderShippingDateHis,
    handleCalenderSaveShippingDateHis,
    isOpenCalenderOrderDateHis,
    setIsOpenCalenderOrderDateHis,
    refCalendarRangeOrderDateHis,
    handleSelectOrderDateHis,
    handleCloseCalenderOrderDateHis,
    handleCalenderSaveOrderDateHis,
  } = useClaimOrderController();

  const handleExportExcel = async () => {
    if (tabIndex === 0) {
      const data = await getExportClaimOrder(itemSelectedUpComing);
      const fileName = `Report_UpComing_${dayjs().format("YYYYMMDDHHmmss")}`;
      const sheetName = "list";
      makeXlsx(data || [], fileName, sheetName);
    } else if (tabIndex === 1) {
      const data = await getExportClaimOrder(itemSelectedInProgress);
      const fileName = `Report_InProgress_${dayjs().format("YYYYMMDDHHmmss")}`;
      const sheetName = "list";
      makeXlsx(data || [], fileName, sheetName);
    } else if (tabIndex === 2) {
      const data = await getExportClaimOrder(itemSelectedHistory);
      const fileName = `Report_History_${dayjs().format("YYYYMMDDHHmmss")}`;
      const sheetName = "list";
      makeXlsx(data || [], fileName, sheetName);
    }
  };

  const onConfirmUpComing = () => {
    onChangeFormUpComing("orderDateStart")(orderStartDate);
    onChangeFormUpComing("orderDateEnd")(orderEndDate);
    onChangeFormUpComing("deliveryDateStart")(shippingStartDate);
    onChangeFormUpComing("deliveryDateEnd")(shippingEndDate);
    onChangeFormUpComing("feedBrandId")(brandUpComing);
    onChangeFormUpComing("feedBrandItemIds")(feedUpComing);
  };

  const onConfirmInProgress = () => {
    onChangeFormInProgress("orderDateStart")(orderStartDateIn);
    onChangeFormInProgress("orderDateEnd")(orderEndDateIn);
    onChangeFormInProgress("deliveryDateStart")(shippingStartDateIn);
    onChangeFormInProgress("deliveryDateEnd")(shippingEndDateIn);
    onChangeFormInProgress("feedBrandId")(brandPageInProgress);
    onChangeFormInProgress("feedBrandItemIds")(feedPageInProgress);
  };

  const onConfirmHistory = () => {
    onChangeFormHistory("orderDateStart")(orderStartDateHis);
    onChangeFormHistory("orderDateEnd")(orderEndDateHis);
    onChangeFormHistory("deliveryDateStart")(shippingStartDateHis);
    onChangeFormHistory("deliveryDateEnd")(shippingEndDateHis);
    onChangeFormHistory("feedBrandId")(brandPageHistory);
    onChangeFormHistory("feedBrandItemIds")(feedPageHistory);
  };

  useEffect(() => {
    if (brandUpComing) {
      setFeedUpComing([]);
    } else if (brandPageInProgress) {
      setFeedPageInProgress([]);
    } else if (brandPageHistory) {
      setFeedPageHistory([]);
    }
  }, [brandUpComing, brandPageInProgress, brandPageHistory]);

  return (
    <>
      <MenuBar>
        <div className={classes.container}>
          <div className="p-10">
            <div className="flex justify-between">
              <span className={classes.title}>Claim Order</span>
              {roleActionEdit && (
                <ButtonFillCustom
                  startIcon={<PlusCircleIcon />}
                  title={t("claimOrder.createNewClaimButton")}
                  onClick={onShowCreateReport}
                />
              )}
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className={classes.mainContainer}>
              <div className="h-3" />
              <Tabs tabIndex={tabIndex} onChange={onChangeTab}>
                <Tab
                  label={`${t("claimOrder.allClaim")} (${get(
                    listClaimOrder,
                    ["result", "count"],
                    0
                  )})`}
                />
                <Tab
                  label={`รายการรออัปเดต (${get(
                    listClaimOrderProgress,
                    ["result", "count"],
                    0
                  )})`}
                />
                <Tab
                  label={`${t("claimOrder.revisionLogOfClaimOrder")} (${get(
                    listClaimOrderHistory,
                    ["result", "count"],
                    0
                  )})`}
                />
              </Tabs>
              <div className="flex flex-col gap-6">
                <div className={tw(classes.filterRowContainer, "pt-6")}>
                  {tabIndex === 0 && (
                    <>
                      <div className={classes.dateContainer}>
                        <span className="text-sm font-medium font-sans">
                          {t("claimOrder.orderDate")}
                        </span>
                        <TextInputCustom
                          placeholder="เริ่มต้น - สิ้นสุด"
                          value={
                            orderStartDate
                              ? orderStartDate + " - " + orderEndDate
                              : ""
                          }
                          InputProps={{
                            endAdornment:
                              orderStartDate !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={(e) => {
                                    setOrderStartDate("");
                                    setOrderEndDate("");
                                    e.stopPropagation();
                                    refCalendarRangeOrderDate.current.clearData();
                                  }}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarRangeOrderDate.current?.onOpen(e);
                          }}
                        />
                      </div>
                      <div className={classes.dateContainer}>
                        <span className="text-sm font-medium font-sans">
                          {t("claimOrder.deliveryDate")}
                        </span>
                        <TextInputCustom
                          placeholder="เริ่มต้น - สิ้นสุด"
                          value={
                            shippingStartDate
                              ? shippingStartDate + " - " + shippingEndDate
                              : ""
                          }
                          InputProps={{
                            endAdornment:
                              shippingStartDate !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={(e) => {
                                    setShippingStartDate("");
                                    setShippingEndDate("");
                                    e.stopPropagation();
                                    refCalendarRangeShippingDate.current.clearData();
                                  }}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarRangeShippingDate.current?.onOpen(e);
                          }}
                        />
                      </div>
                      <Dropdown
                        label="Brand"
                        options={brandsOptionsUpComing}
                        onChange={(value) => setBrandUpComing(value)}
                        onReset={() => (
                          setBrandUpComing([]), setFeedUpComing([])
                        )}
                        renderValueArray={brandUpComing}
                        containerClassName="min-w-[200px]"
                        placeholder="เลือก"
                      />
                      <Dropdown
                        disabled={isEmpty(brandUpComing)}
                        label="SKU"
                        options={feedOptionsUpComing}
                        onChange={(value) => setFeedUpComing(value)}
                        renderValueArray={feedUpComing}
                        containerClassName="min-w-[200px]"
                        placeholder="เลือก"
                        multiple
                        isCheckbox
                      />
                    </>
                  )}
                  {tabIndex === 1 && (
                    <>
                      <div className={classes.dateContainer}>
                        <span className="text-sm font-medium font-sans">
                          {t("claimOrder.orderDate")}
                        </span>
                        <TextInputCustom
                          placeholder="เริ่มต้น - สิ้นสุด"
                          value={
                            orderStartDateIn
                              ? orderStartDateIn + " - " + orderEndDateIn
                              : ""
                          }
                          InputProps={{
                            endAdornment:
                              orderStartDateIn !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={(e) => {
                                    setOrderStartDateIn("");
                                    setOrderEndDateIn("");
                                    e.stopPropagation();
                                    refCalendarRangeOrderDateIn.current.clearData();
                                  }}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarRangeOrderDateIn.current?.onOpen(e);
                          }}
                        />
                      </div>
                      <div className={classes.dateContainer}>
                        <span className="text-sm font-medium font-sans">
                          {t("claimOrder.deliveryDate")}
                        </span>
                        <TextInputCustom
                          placeholder="เริ่มต้น - สิ้นสุด"
                          value={
                            shippingStartDateIn
                              ? shippingStartDateIn + " - " + shippingEndDateIn
                              : ""
                          }
                          InputProps={{
                            endAdornment:
                              shippingStartDateIn !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={(e) => {
                                    setShippingStartDateIn("");
                                    setShippingEndDateIn("");
                                    e.stopPropagation();
                                    refCalendarRangeShippingDateIn.current.clearData();
                                  }}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarRangeShippingDateIn.current?.onOpen(e);
                          }}
                        />
                      </div>
                      <Dropdown
                        label={t("claimOrder.brand")}
                        options={brandsOptionsInProgress}
                        onChange={(value) => setBrandPageInProgress(value)}
                        onReset={() => (
                          setBrandPageInProgress([]), setFeedPageInProgress([])
                        )}
                        renderValueArray={brandPageInProgress}
                        containerClassName="min-w-[200px]"
                        placeholder="เลือก"
                      />
                      <Dropdown
                        disabled={isEmpty(brandPageInProgress)}
                        label={t("claimOrder.sku")}
                        options={feedOptionsInProgress}
                        onChange={(value) => setFeedPageInProgress(value)}
                        renderValueArray={feedPageInProgress}
                        containerClassName="min-w-[200px]"
                        placeholder="เลือก"
                        multiple
                        isCheckbox
                      />
                    </>
                  )}
                  {tabIndex === 2 && (
                    <>
                      <div className={classes.dateContainer}>
                        <span className="text-sm font-medium font-sans">
                          {t("claimOrder.orderDate")}
                        </span>
                        <TextInputCustom
                          placeholder="เริ่มต้น - สิ้นสุด"
                          value={
                            orderStartDateHis
                              ? orderStartDateHis + " - " + orderEndDateHis
                              : ""
                          }
                          InputProps={{
                            endAdornment:
                              orderStartDateHis !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={(e) => {
                                    setOrderStartDateHis("");
                                    setOrderEndDateHis("");
                                    e.stopPropagation();
                                    refCalendarRangeOrderDateHis.current.clearData();
                                  }}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarRangeOrderDateHis.current?.onOpen(e);
                          }}
                        />
                      </div>
                      <div className={classes.dateContainer}>
                        <span className="text-sm font-medium font-sans">
                          {t("claimOrder.deliveryDate")}
                        </span>
                        <TextInputCustom
                          placeholder="เริ่มต้น - สิ้นสุด"
                          value={
                            shippingStartDateHis
                              ? shippingStartDateHis +
                                " - " +
                                shippingEndDateHis
                              : ""
                          }
                          InputProps={{
                            endAdornment:
                              shippingStartDateHis !== "" ? (
                                <CloseRoundedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontSize: "18px !important",
                                  }}
                                  onClick={(e) => {
                                    setShippingStartDateHis("");
                                    setShippingEndDateHis("");
                                    e.stopPropagation();
                                    refCalendarRangeShippingDateHis.current.clearData();
                                  }}
                                />
                              ) : (
                                <SvgIcon
                                  sx={{
                                    fontSize: "24px !important",
                                    color: "#646464",
                                  }}
                                  fontSize="small"
                                >
                                  <CalendarSvg />
                                </SvgIcon>
                              ),
                          }}
                          onClick={(
                            e: React.MouseEvent<HTMLDivElement, MouseEvent>
                          ) => {
                            e.stopPropagation();
                            refCalendarRangeShippingDateHis.current?.onOpen(e);
                          }}
                        />
                      </div>
                      <Dropdown
                        label={t("claimOrder.brand")}
                        options={brandsOptionsHistory}
                        onChange={(value) => setBrandPageHistory(value)}
                        onReset={() => (
                          setBrandPageHistory([]), setFeedPageHistory([])
                        )}
                        renderValueArray={brandPageHistory}
                        containerClassName="min-w-[200px]"
                        placeholder="เลือก"
                      />
                      <Dropdown
                        disabled={isEmpty(brandPageHistory)}
                        label={t("claimOrder.sku")}
                        options={feedOptionsHistory}
                        onChange={(value) => setFeedPageHistory(value)}
                        renderValueArray={feedPageHistory}
                        containerClassName="min-w-[200px]"
                        placeholder="เลือก"
                        multiple
                        isCheckbox
                      />
                    </>
                  )}
                </div>
                <div className="w-full flex justify-end">
                  <ButtonFillCustom
                    title="ค้นหา"
                    onClick={
                      tabIndex === 0
                        ? onConfirmUpComing
                        : tabIndex === 1
                        ? onConfirmInProgress
                        : onConfirmHistory
                    }
                  />
                </div>
              </div>
              <div className="h-6" />
              <div className="w-full flex justify-between">
                <TextInputSearchCustom
                  value={
                    tabIndex === 0
                      ? searchTextUpComing
                      : tabIndex === 1
                      ? searchTextProgress
                      : searchTextHistory
                  }
                  className="max-w-[470px]"
                  onChange={
                    tabIndex === 0
                      ? onSearchTextUpcoming
                      : tabIndex === 1
                      ? onSearchTextProgress
                      : onSearchTextHistory
                  }
                  placeholder="ค้นหาโดยหมายเลขสั่งซื้อ, รหัสลูกค้า, ชื่อลูกค้า"
                  InputProps={{
                    endAdornment: (
                      tabIndex === 0
                        ? searchTextUpComing
                        : tabIndex === 1
                        ? searchTextProgress
                        : searchTextHistory
                    ) ? (
                      <CloseRoundedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={
                          tabIndex === 0
                            ? onClearSearchTextUpcoming
                            : tabIndex === 1
                            ? onClearSearchTextProgress
                            : onClearSearchTextHistory
                        }
                      />
                    ) : null,
                  }}
                />
                {tabIndex === 0 && (
                  <ButtonOutlinedCustom
                    title="นำออก"
                    btnTextColor="#68C184"
                    btnBorderColor="#68C184"
                    disabled={isEmpty(itemSelectedUpComing)}
                    onClick={handleExportExcel}
                    startIcon={
                      <SvgIcon
                        sx={{
                          fontSize: "14px !important",
                          color: "currentColor",
                          marginBottom: "3px",
                        }}
                        fontSize="small"
                      >
                        <DownloadSvg />
                      </SvgIcon>
                    }
                  />
                )}
                {tabIndex === 1 && (
                  <ButtonOutlinedCustom
                    title="นำออก"
                    btnTextColor="#68C184"
                    btnBorderColor="#68C184"
                    disabled={isEmpty(itemSelectedInProgress)}
                    onClick={handleExportExcel}
                    startIcon={
                      <SvgIcon
                        sx={{
                          fontSize: "14px !important",
                          color: "currentColor",
                          marginBottom: "3px",
                        }}
                        fontSize="small"
                      >
                        <DownloadSvg />
                      </SvgIcon>
                    }
                  />
                )}
                {tabIndex === 2 && (
                  <ButtonOutlinedCustom
                    title="นำออก"
                    btnTextColor="#68C184"
                    btnBorderColor="#68C184"
                    disabled={isEmpty(itemSelectedHistory)}
                    onClick={handleExportExcel}
                    startIcon={
                      <SvgIcon
                        sx={{
                          fontSize: "14px !important",
                          color: "currentColor",
                          marginBottom: "3px",
                        }}
                        fontSize="small"
                      >
                        <DownloadSvg />
                      </SvgIcon>
                    }
                  />
                )}
              </div>
            </div>
            <TabPanelCustom
              value={tabIndex}
              index={0}
              prefix={`list-order-item-${tabIndex}`}
            >
              <div className="pt-6">
                <OrderClaim
                  data={listClaimOrder?.result.rows ?? []}
                  count={listClaimOrder?.result.count ?? 0}
                  currentPageUpComing={currentPageUpComing}
                  onChangeCurrentPageUpComing={setCurrentPageUpComing}
                  refetchListClaimOrderUpcoming={refetchListClaimOrderUpcoming}
                  isFirst
                  itemSelected={itemSelectedUpComing}
                  setItemSelected={setItemSelectedUpComing}
                  currentOrderByKey={currentOrderByKey1}
                  setCurrentOrderByKey={setCurrentOrderByKey1}
                  orderByType={orderByType1}
                  setOrderByType={setOrderByType1}
                />
              </div>
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={1}
              prefix={`list-order-item-${tabIndex}`}
            >
              <div className="pt-6">
                <OrderClaim
                  data={listClaimOrderProgress?.result.rows ?? []}
                  count={listClaimOrderProgress?.result.count ?? 0}
                  currentPageUpComing={currentPageProgress}
                  onChangeCurrentPageUpComing={setCurrentPageProgress}
                  refetchListClaimOrderProgress={refetchListClaimOrderProgress}
                  itemSelected={itemSelectedInProgress}
                  setItemSelected={setItemSelectedInProgress}
                  currentOrderByKey={currentOrderByKey2}
                  setCurrentOrderByKey={setCurrentOrderByKey2}
                  orderByType={orderByType2}
                  setOrderByType={setOrderByType2}
                />
              </div>
            </TabPanelCustom>
            <TabPanelCustom
              value={tabIndex}
              index={2}
              prefix={`list-order-item-${tabIndex}`}
            >
              <div className="pt-6">
                <OrderClaim
                  data={listClaimOrderHistory?.result.rows ?? []}
                  count={listClaimOrderHistory?.result.count ?? 0}
                  currentPageUpComing={currentPageHistory}
                  onChangeCurrentPageUpComing={setCurrentPageHistory}
                  refetchListClaimOrderHistory={refetchListClaimOrderHistory}
                  itemSelected={itemSelectedHistory}
                  setItemSelected={setItemSelectedHistory}
                  currentOrderByKey={currentOrderByKey3}
                  setCurrentOrderByKey={setCurrentOrderByKey3}
                  orderByType={orderByType3}
                  setOrderByType={setOrderByType3}
                />
              </div>
            </TabPanelCustom>
          </div>
          {
            /////////////////////////////////// CalenderUp ///////////////////////////////////
          }
          <DateRangePickerPopover
            ref={refCalendarRangeOrderDate}
            id={"calendar-range-popover-order-up"}
            handleSelect={handleSelectOrderDate}
            openCalender={isOpenCalenderOrderDate}
            onClose={handleCloseCalenderOrderDate}
            handleCalenderSave={handleCalenderSaveOrderDate}
            startDate={orderStartDate}
            endDate={orderEndDate}
          />
          <DateRangePickerPopover
            ref={refCalendarRangeShippingDate}
            id={"calendar-range-popover-shipping-up"}
            handleSelect={handleSelectShippingDate}
            openCalender={isOpenCalenderShippingDate}
            onClose={handleCloseCalenderShippingDate}
            handleCalenderSave={handleCalenderSaveShippingDate}
            startDate={shippingStartDate}
            endDate={shippingEndDate}
          />
          {
            /////////////////////////////////// CalenderIn ///////////////////////////////////
          }
          <DateRangePickerPopover
            ref={refCalendarRangeOrderDateIn}
            id={"calendar-range-popover-order-in"}
            handleSelect={handleSelectOrderDateIn}
            openCalender={isOpenCalenderOrderDateIn}
            onClose={handleCloseCalenderOrderDateIn}
            handleCalenderSave={handleCalenderSaveOrderDateIn}
            startDate={orderStartDateIn}
            endDate={orderEndDateIn}
          />
          <DateRangePickerPopover
            ref={refCalendarRangeShippingDateIn}
            id={"calendar-range-popover-shipping-in"}
            handleSelect={handleSelectShippingDateIn}
            openCalender={isOpenCalenderShippingDateIn}
            onClose={handleCloseCalenderShippingDateIn}
            handleCalenderSave={handleCalenderSaveShippingDateIn}
            startDate={shippingStartDateIn}
            endDate={shippingEndDateIn}
          />
          {
            /////////////////////////////////// CalenderHis ///////////////////////////////////
          }
          <DateRangePickerPopover
            ref={refCalendarRangeOrderDateHis}
            id={"calendar-range-popover-order-his"}
            handleSelect={handleSelectOrderDateHis}
            openCalender={isOpenCalenderOrderDateHis}
            onClose={handleCloseCalenderOrderDateHis}
            handleCalenderSave={handleCalenderSaveOrderDateHis}
            startDate={orderStartDateHis}
            endDate={orderEndDateHis}
          />
          <DateRangePickerPopover
            ref={refCalendarRangeShippingDateHis}
            id={"calendar-range-popover-shipping-his"}
            handleSelect={handleSelectShippingDateHis}
            openCalender={isOpenCalenderShippingDateHis}
            onClose={handleCloseCalenderShippingDateHis}
            handleCalenderSave={handleCalenderSaveShippingDateHis}
            startDate={shippingStartDateHis}
            endDate={shippingEndDateHis}
          />
          <CreateReportModal
            isOpen={isShowCreateReport}
            onCloseFn={onHideCreateReport}
            refetchListClaimOrderProgress={refetchListClaimOrderProgress}
          />
        </div>
      </MenuBar>
    </>
  );
};

export default Claim;
