import { Divider, Link, SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { subDistrictData } from "../../../const/subDistrict";

import { useTranslation } from "react-i18next";
import {
  getBasicPartnerGraph,
  getCorePartnerGraph,
} from "../../../services/dashboard/PerformancePartner.service";
import {
  IBasicPartnerGraphData,
  ICorePartnerGraphData,
  TMapDataItem,
} from "../../../types/dashboard/PerformancePartner.type";
import { echartsExportPng } from "../../../utils/echarts";
import { makeXlsx } from "../../../utils/make-xlsx";
import { DownloadSvg } from "../../assets/svg/Download.svg";
import ButtonOutlinedCustom from "../../components/Button/ButtonOutlinedCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import thailand from "./thailand.json";

type Props = {};

const Partner = (props: Props) => {
  const { t } = useTranslation();
  const echartRef1 = useRef<any>();
  const echartRef2 = useRef<any>();
  const handleExport = () => {};
  const navigate = useNavigate();
  //@ts-ignore
  echarts.registerMap("Thailand", thailand);

  const [coreData, setCoreData] = useState<TMapDataItem[]>([]);
  const [coreMaxValue, setCoreMaxValue] = useState<number>(0);

  const [basicData, setBasicData] = useState<TMapDataItem[]>([]);
  const [basicMaxValue, setBasicMaxValue] = useState<number>(0);

  const convertDataArray = (
    data: ICorePartnerGraphData[] | IBasicPartnerGraphData[]
  ) => {
    var tempData = [];
    for (var i = 0; i < data.length; i++) {
      var geoCoord = subDistrictData.find(
        (item) => item.subdistrict === data[i].subDistrictName
      );
      if (geoCoord) {
        tempData.push({
          name: data[i].subDistrictName,
          value: [geoCoord.longitude, geoCoord.latitude, data[i].population],
          tooltipData: {
            groupArea: data[i].userGroupName,
            partnerName: data[i].partnerName,
            noUser: data[i].numberOfUsers,
            noCustomer: data[i].numberOfCustomers,
            feedVolume: data[i].feedVolume,
            population: data[i].population,
          },
        });
      }
    }
    return tempData;
  };

  const fetchData = async () => {
    const corePartner = await getCorePartnerGraph();
    const basicPartner = await getBasicPartnerGraph();
    if (corePartner) {
      var tempData = convertDataArray(corePartner);
      if (tempData.length > 0) {
        const max = tempData.reduce((max, cur) =>
          cur.value[2] > max.value[2] ? cur : max
        );
        setCoreMaxValue(max.value[2]);
        setCoreData(tempData);
      }
    }

    if (basicPartner) {
      var tempData = convertDataArray(basicPartner);
      if (tempData.length > 0) {
        const max = tempData.reduce((max, cur) =>
          cur.value[2] > max.value[2] ? cur : max
        );
        setBasicMaxValue(max.value[2]);
        setBasicData(tempData);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleMapExport = (data: TMapDataItem[], fileName: string) => {
    let file: {
      Province: string;
      PartnerName: string;
      GroupArea: string;
      "No. User": number;
      "No. Customer": number;
      "Feed Volume": number;
      Population: number;
    }[] = [];
    data.map((item) => {
      file.push({
        Province: item.name,
        PartnerName: item.tooltipData.partnerName,
        GroupArea: item.tooltipData.groupArea,
        "No. User": item.tooltipData.noUser,
        "No. Customer": item.tooltipData.noCustomer,
        "Feed Volume": item.tooltipData.feedVolume,
        Population: item.value[2],
      });
    });
    const fileNameDate = `${fileName}_Graph ${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(file || [], fileNameDate, sheetName);
  };

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <span className="text-[#000] font-bold text-3xl">
              {t("dashboard.performance.partners")}
            </span>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6 grid grid-cols-12 gap-6">
              <div className="grid col-span-6 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-[#000] font-bold text-2xl">
                    {t("dashboard.performance.corePartner")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() =>
                        echartsExportPng(echartRef1, "Core Partner")
                      }
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => handleMapExport(coreData, "Core Partner")}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-row justify-end">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      navigate("/admin/performance/partner/core-partner-table");
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </div>
                <div>
                  <ReactECharts
                    className="min-h-[635px]"
                    ref={echartRef1}
                    option={{
                      geo: {
                        center: [100.5018, 13],
                        zoom: 1.1,
                        map: "Thailand",
                        roam: true,
                        itemStyle: {
                          areaColor: "#e7e8ea",
                        },
                        scaleLimit: {
                          min: 1,
                          max: 15,
                        },
                      },
                      visualMap: {
                        min: 0,
                        max: coreMaxValue,
                        inRange: {
                          color: ["#F0FDF4", "#77F2A1", "#68C184", "#1E482B"],
                        },
                        text: [coreMaxValue.toString(), "0"],
                        calculable: false,
                      },
                      tooltip: {
                        formatter: (params: any) => {
                          return `<div className="grid grid-cols-2 gap-3 min-w-[265px]">
        <div className="grid">Group Area: ${
          params.data.tooltipData.groupArea || "-"
        }</div>
        <div className="grid">ชื่อพาร์ทเนอร์: ${
          params.data.tooltipData.partnerName
        }</div>
        <div className="grid">จำนวน Users: ${
          params.data.tooltipData.noUser
        } คน</div>
        <div className="grid">จำนวน Customers: ${
          params.data.tooltipData.noCustomer
        } คน</div>
        <div className="grid">Feed Volume: ${
          params.data.tooltipData.feedVolume
        } ตัน</div>
        <div className="grid">Population: ${
          params.data.tooltipData.population
        } ตัว</div>
        </div>`;
                        },
                      },
                      legend: { show: false },
                      series: [
                        {
                          name: "core-partner",
                          type: "scatter",
                          coordinateSystem: "geo",
                          data: coreData,
                          encode: {
                            value: 2,
                          },
                          symbolSize: (val: number[]) =>
                            Math.max(val[2] / 250, 15),
                          label: {
                            formatter: "{b}",
                            position: "right",
                          },
                          itemStyle: {
                            color: "#ddb926",
                            borderColor: "#205223",
                          },
                          emphasis: {
                            label: {
                              show: true,
                            },
                          },
                        },
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="grid col-span-6 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-[#000] font-bold text-2xl">
                    {t("dashboard.performance.basicPartner")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() =>
                        echartsExportPng(echartRef2, "Basic Partner")
                      }
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() =>
                        handleMapExport(basicData, "Basic Partner")
                      }
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>

                <div className="flex flex-row justify-end">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      navigate(
                        "/admin/performance/partner/basic-partner-table"
                      );
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </div>
                <div>
                  <ReactECharts
                    className="min-h-[635px]"
                    ref={echartRef2}
                    option={{
                      geo: {
                        center: [100.5018, 13],
                        zoom: 1.1,
                        map: "Thailand",
                        roam: true,
                        itemStyle: {
                          areaColor: "#e7e8ea",
                        },
                        scaleLimit: {
                          min: 1,
                          max: 15,
                        },
                      },
                      visualMap: {
                        min: 0,
                        max: basicMaxValue,
                        inRange: {
                          color: ["#F0FDF4", "#77F2A1", "#68C184", "#1E482B"],
                        },
                        text: [basicMaxValue.toString(), "0"],
                        calculable: false,
                      },
                      tooltip: {
                        formatter: (params: any) => {
                          return `<div className="grid grid-cols-2 gap-3 min-w-[265px]">
        <div className="grid">Group Area: ${
          params.data.tooltipData.groupArea || "-"
        }</div>
        <div className="grid">ชื่อพาร์ทเนอร์: ${
          params.data.tooltipData.partnerName
        }</div>
        <div className="grid">จำนวน Users: ${
          params.data.tooltipData.noUser
        } คน</div>
        <div className="grid">จำนวน Customers: ${
          params.data.tooltipData.noCustomer
        } คน</div>
        <div className="grid">Feed Volume: ${
          params.data.tooltipData.feedVolume
        } ตัน</div>
        <div className="grid">Population: ${
          params.data.tooltipData.population
        } ตัว</div>
        </div>`;
                        },
                      },
                      legend: { show: false },
                      series: [
                        {
                          name: "basic-partner",
                          type: "scatter",
                          coordinateSystem: "geo",
                          data: basicData,
                          encode: {
                            value: 2,
                          },
                          symbolSize: (val: number[]) =>
                            Math.max(val[2] / 250, 15),
                          label: {
                            formatter: "{b}",
                            position: "right",
                          },
                          itemStyle: {
                            color: "#ddb926",
                            borderColor: "#205223",
                          },
                          emphasis: {
                            label: {
                              show: true,
                            },
                          },
                        },
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default Partner;
