import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Dialog,
  DialogContent,
  IconButton,
  Link,
  MenuItem,
  SvgIcon,
  Tab,
} from "@mui/material";
import axios from "axios";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { isNull } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { object, string } from "yup";
import DownloadLinkWithDelete from "../../../../components/common/File/DownloadLinkWithDelete";
import ListOfFile from "../../../../components/common/File/ListOfFile";
import { useSnackBar } from "../../../../provider/SnackBarProvider";
import { getAdminLocation } from "../../../../services/admin/location/location.service";
import {
  createPartner,
  editPartner,
  getDownloadUrlUserPartner,
  getPartnerById,
  getUploadUrlUserPartner,
} from "../../../../services/admin/user-management/partner.service";
import { IAdminProvince } from "../../../../types/admin/location/location";
import { InitialPartnerForm } from "../../../../types/admin/user-management/partner";
import { handleDownloadBlob } from "../../../../utils/downloadFile";
import { formError, formErrorMessage } from "../../../../utils/form-validator";
import { CalendarSvg } from "../../../assets/svg/Calendar.svg";
import CrossIcon from "../../../assets/svg/CrossIcon.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import DatePickerPopover from "../../../components/Input/DatePickerPopover/DatePickerPopover";
import DateRangePickerPopover from "../../../components/Input/DateRangePickerPopover/DateRangePickerPopover";
import DragAndDropUploadImage from "../../../components/Input/DragAndDropUploadImage";
import TextInputCustom from "../../../components/Input/TextInputCustom";
import ConfirmCustomModal from "../../../components/Modal/ConfirmCustomModal";
import SelectAdminCustom from "../../../components/Select/Select";
import Tabs from "../../group-area/views/Tabs";
import { FILTER_DATE_FORMAT } from "../../sale-order/components/SaleOrderSearchFilter";
import RevenueChangeLogModal from "./RevenueChangeLogModal";
import { useTranslation } from "react-i18next";

type CreatePartnerModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  selectedRow?: string;
  currentPage?: number;
  handleFetch: (targetPage?: number) => void;
};

const CreatePartnerModal = ({
  isOpen,
  handleClose,
  selectedRow,
  handleFetch,
  currentPage,
}: CreatePartnerModalProps) => {
  const { t } = useTranslation();
  const RolePartnerSelect = ["basic", "core"];
  const [province, setProvince] = useState<IAdminProvince[]>([]);
  const { showSnackBar, showErrorSnackBar } = useSnackBar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenConfirmCreateModal, setIsOpenConfirmCreateModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [tabIndex, setTabIndex] = useState(0);

  const onChangeTab = useCallback((e: any, index: number) => {
    setTabIndex(index);
  }, []);

  const refCalendarStartDate =
    React.useRef<React.ElementRef<typeof DateRangePickerPopover>>(null);

  const handleSelectStartDate = (value?: Date) => {
    const formattedDate = dayjs(value).format(FILTER_DATE_FORMAT);
    formik.setFieldValue("contractSignDate", formattedDate);
  };

  const fetchLocations = async () => {
    const getLocation = await getAdminLocation();
    const province = getLocation?.provinces || [];
    setProvince(province);
  };

  const [files, setFiles] = useState<(File | { id: string; url: string })[]>(
    []
  );
  const [showFiles, setShowFiles] = useState<{ id: string; name: string }[]>(
    []
  );

  const [viewRevenueType, setViewRevenueType] = useState("");

  const [isOpenRevenueChangeLog, setIsOpenRevenueChangeLog] = useState(false);

  const onOpenRevenueChangeLog = useCallback((type: string) => {
    setIsOpenRevenueChangeLog(true);
    setViewRevenueType(type);
  }, []);

  const onHideRevenueChangeLog = useCallback(
    () => setIsOpenRevenueChangeLog(false),
    []
  );

  let initialValues: InitialPartnerForm = {
    type: "",
    name: "",
    surname: "",
    mobileNumber: "",
    email: "",
    address: "",
    zipCode: "",
    provinceCode: "",
    districtCode: "",
    subDistrictCode: "",
    initialPin: "",
    username: "",
    partnerCode: "",
    onboardRate: "",
    revenueRate: "",
    invoiceFollowRate: "",
    contractSignDate: "",
    contractFileId: "",
    contractFileName: "",
  };

  const handleInitForm = async () => {
    if (selectedRow) {
      setIsLoading(true);
      try {
        const partner = await getPartnerById(selectedRow);
        if (partner) {
          const result = partner.result;
          formik.setValues({
            type: result.partnerType || "",
            name: result.name || "",
            surname: result.surname || "",
            mobileNumber: result.mobileNumber || "",
            email: result.email || "",
            address: result.address || "",
            zipCode: result.subDistrict.zipCode,
            provinceCode: result.province.code.toString() || "",
            districtCode: result.district.code.toString() || "",
            subDistrictCode: result.subDistrict.code.toString() || "",
            initialPin: result.initialPin || "",
            username: result.username || "",
            partnerCode: result.partnerCode || "",
            onboardRate: isNull(result.onboardRate)
              ? ""
              : result.onboardRate.toString() || "",
            revenueRate: isNull(result.revenueRate)
              ? ""
              : result.revenueRate.toString() || "",
            invoiceFollowRate: isNull(result.invoiceFollowRate)
              ? ""
              : result.invoiceFollowRate.toString() || "",
            contractSignDate: dayjs(result.contractSignDate).isValid()
              ? dayjs(result.contractSignDate).format("DD/MM/YYYY") || ""
              : "",
            contractFileId: result.contractFileId || "",
            contractFileName: result.contractFileName || "",
          });

          if (result.contractFileName) {
            setShowFiles([
              {
                id: result.contractFileId,
                name: result.contractFileName,
              },
            ]);
          }
        }
      } catch (error) {
        throw error;
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen && selectedRow) {
      handleInitForm();
      setTabIndex(0);
    }
  }, [isOpen, selectedRow]);

  const reset = useCallback(() => {
    formik.resetForm();
    setTabIndex(0);
    setFiles([]);
    setShowFiles([]);
    handleClose();
  }, []);

  const handleSubmit = async (value: InitialPartnerForm) => {
    setIsSubmitting(true);
    const file = [...files];
    const fileArray: {
      id: string;
      url: string;
      fileName: string;
      fileSize: number;
    }[] = [];
    try {
      for (let i = 0; i < file.length; i++) {
        const _file = file[i];
        if (_file instanceof File) {
          const presign = await getUploadUrlUserPartner();
          await axios.put(presign.blob.blobUrl, _file, {
            responseType: "blob",
            headers: {
              "x-ms-blob-type": "BlockBlob",
            },
          });
          fileArray.push({
            id: presign.blob.fileId,
            url: presign.blob.url || "",
            fileName: _file.name,
            fileSize: _file.size,
          });
        }
      }
      const contractFileId = fileArray.map((file) => file.id).join(",");
      const contractFileName = fileArray.map((file) => file.fileName).join(",");

      const payload = {
        id: selectedRow,
        type: value.type,
        name: value.name,
        surname: value.surname,
        mobileNumber: value.mobileNumber,
        email: value.email,
        address: value.address,
        zipCode: Number(value.zipCode),
        provinceCode: Number(value.provinceCode),
        districtCode: Number(value.districtCode),
        subDistrictCode: Number(value.subDistrictCode),
        onboardRate: Number(value.onboardRate),
        revenueRate: Number(value.revenueRate),
        invoiceFollowRate: Number(value.invoiceFollowRate),
        contractSignDate: dayjs(value.contractSignDate, "DD-MM-YYYY")
          .startOf("day")
          .toDate(),
        contractFileId: contractFileId || showFiles[0]?.id || "",
        contractFileName: contractFileName || showFiles[0]?.name || "",
      };

      await (selectedRow ? editPartner(payload) : createPartner(payload));
      setIsOpenConfirmCreateModal(false);
      reset();
      showSnackBar(
        selectedRow ? "แก้ไขพาร์ทเนอร์สำเร็จ" : "เพิ่มพาร์ทเนอร์สำเร็จ"
      );
      handleFetch(currentPage);
    } catch (error) {
      const axiosError = error as any;
      const status = axiosError?.status || 500;
      const errorMessage = axiosError.data?.message || "Request error";
      showErrorSnackBar(`${status}: ${errorMessage}`);
      throw error;
    } finally {
      setIsOpenConfirmCreateModal(false);
      setIsSubmitting(false);
    }
  };

  const validationSchema = object().shape({
    name: string().ensure().required("กรุณากรอกชื่อ"),
    surname: string().ensure().required("กรุณากรอกนามสกุล"),
    type: string().ensure().required("กรุณาเลือกพาร์ทเนอร์"),
    mobileNumber: string().ensure().required("กรุณากรอกเบอร์โทรศัพท์"),
    email: string().email("กรุณากรอกอีเมลให้ถูกต้อง").optional(),
    address: string().ensure().required("กรุณากรอกที่อยู่"),
    provinceCode: string().required("กรุณาเลือกจังหวัด"),
    districtCode: string().ensure().required("กรุณาเลือกเขต/อำเภอ"),
    subDistrictCode: string().ensure().required("กรุณาเลือกแขวง/ตำบล"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
  });
  const getValueDistrict = formik.getFieldProps("districtCode").value;
  const getValueProvince = formik.getFieldProps("provinceCode").value;

  const districts = (idProvince: number) =>
    province.find((row) => row.code === idProvince)?.districts || [];

  const subDistricts = (idProvince: number, idDistrict: number) =>
    districts(idProvince).find((row) => row.code === idDistrict)
      ?.subDistricts || [];

  const zipCode = (
    idProvince: number,
    idDistrict: number,
    idSubDistrict: number
  ) =>
    subDistricts(idProvince, idDistrict).find(
      (row) => row.code === idSubDistrict
    )?.zipCode ?? "";

  const handleDownload = useCallback(async (file: File) => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${file.name}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, []);

  const handleDownloadByUrl = useCallback(
    (fileId: string, name: string) => async () => {
      const preSignURL = await getDownloadUrlUserPartner(fileId);
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        handleDownloadBlob(objectURL, name);
      }
    },
    [showFiles]
  );

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <>
      <Dialog
        open={!isLoading && isOpen}
        maxWidth="lg"
        onClose={reset}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        slotProps={{
          backdrop: {
            style: { backgroundColor: "#000000", opacity: "10%" },
            timeout: 500,
          },
        }}
        className="!font-sans"
      >
        <DialogContent>
          <div className="flex flex-col gap-6">
            <div className="flex justify-between items-center">
              <p className="text-lg font-bold">
                {selectedRow
                  ? "แก้ไขพาร์ทเนอร์"
                  : t("userManagement.partner.modal.addNewPartner")}
              </p>
              <IconButton onClick={reset}>
                <CrossIcon />
              </IconButton>
            </div>
            <Tabs tabIndex={tabIndex} onChange={onChangeTab}>
              <Tab label="ข้อมูลส่วนตัว" />
              <Tab label="ข้อมูลสัญญา" />
            </Tabs>
            {tabIndex === 0 && (
              <>
                <div className="flex gap-3">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.name")}
                      <span className="text-error-300">*</span>
                    </p>
                    <div className="w-[340px]">
                      <TextInputCustom
                        placeholder="กรอก"
                        error={formError(formik, "name")}
                        helperText={formErrorMessage(formik, "name")}
                        {...formik.getFieldProps("name")}
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.surname")}
                      <span className="text-error-300">*</span>
                    </p>
                    <div className="w-[340px]">
                      <TextInputCustom
                        placeholder="กรอก"
                        error={formError(formik, "surname")}
                        helperText={formErrorMessage(formik, "surname")}
                        {...formik.getFieldProps("surname")}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-3">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.role")}
                      <span className="text-error-300">*</span>
                    </p>
                    <div className="w-[340px]">
                      <SelectAdminCustom
                        placeholder="เลือก"
                        error={formError(formik, "type")}
                        helperText={formErrorMessage(formik, "type")}
                        {...formik.getFieldProps("type")}
                        menuItems={RolePartnerSelect.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                        renderValueArray={RolePartnerSelect.map((row) => ({
                          value: row,
                          label: row,
                        }))}
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.partnerID")}
                    </p>
                    <div className="w-[340px]">
                      <TextInputCustom
                        disabled
                        {...formik.getFieldProps("partnerCode")}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-3">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.phone")}
                      <span className="text-error-300">*</span>
                    </p>
                    <div className="w-[340px]">
                      <TextInputCustom
                        placeholder="กรอก"
                        error={formError(formik, "mobileNumber")}
                        helperText={formErrorMessage(formik, "mobileNumber")}
                        {...formik.getFieldProps("mobileNumber")}
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.email")}
                    </p>
                    <div className="w-full">
                      <TextInputCustom
                        placeholder="กรอก"
                        error={formError(formik, "email")}
                        helperText={formErrorMessage(formik, "email")}
                        {...formik.getFieldProps("email")}
                      />
                    </div>
                  </div>
                </div>
                <p className="text-lg font-bold text-tertiary">
                  {t("userManagement.partner.modal.address")}
                </p>
                <div>
                  <p className="text-[14px] mb-1">
                    {t("userManagement.partner.modal.address")}
                    <span className="text-error-300">*</span>
                  </p>
                  <div className="w-full">
                    <TextInputCustom
                      placeholder="กรอก"
                      error={formError(formik, "address")}
                      helperText={formErrorMessage(formik, "address")}
                      {...formik.getFieldProps("address")}
                    />
                  </div>
                </div>
                <div className="flex gap-x-3">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.province")}
                      <span className="text-error-300">*</span>
                    </p>
                    <div className="w-[340px]">
                      <SelectAdminCustom
                        placeholder="เลือก"
                        renderValueArray={province.map((row) => ({
                          value: row.code.toString(),
                          label: row.nameTh,
                        }))}
                        error={formError(formik, "provinceCode")}
                        helperText={formErrorMessage(formik, "provinceCode")}
                        {...formik.getFieldProps("provinceCode")}
                        menuItems={province.map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.nameTh}
                          </MenuItem>
                        ))}
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.district")}
                      <span className="text-error-300">*</span>
                    </p>
                    <div className="w-[340px]">
                      <SelectAdminCustom
                        placeholder="เลือก"
                        renderValueArray={districts(+getValueProvince).map(
                          (row) => ({
                            value: row.code.toString(),
                            label: row.nameTh,
                          })
                        )}
                        error={formError(formik, "districtCode")}
                        helperText={formErrorMessage(formik, "districtCode")}
                        {...formik.getFieldProps("districtCode")}
                        menuItems={districts(+getValueProvince).map(
                          (option) => (
                            <MenuItem key={option.code} value={option.code}>
                              {option.nameTh}
                            </MenuItem>
                          )
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-x-3">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.subDistrict")}
                      <span className="text-error-300">*</span>
                    </p>
                    <div className="w-[340px]">
                      <SelectAdminCustom
                        placeholder="เลือก"
                        error={formError(formik, "subDistrictCode")}
                        helperText={formErrorMessage(formik, "subDistrictCode")}
                        {...formik.getFieldProps("subDistrictCode")}
                        menuItems={subDistricts(
                          +getValueProvince,
                          +getValueDistrict
                        ).map((option) => (
                          <MenuItem key={option.code} value={option.code}>
                            {option.nameTh}
                          </MenuItem>
                        ))}
                        renderValueArray={subDistricts(
                          +getValueProvince,
                          +getValueDistrict
                        ).map((row) => ({
                          value: row.code.toString(),
                          label: row.nameTh,
                        }))}
                        onChange={(e) => {
                          const { onChange } =
                            formik.getFieldProps("subDistrictCode");
                          onChange(e);
                          const selectSubDistrictCode = +(e.target
                            .value as string);
                          formik.setFieldValue(
                            "zipCode",
                            zipCode(
                              +getValueProvince,
                              +getValueDistrict,
                              selectSubDistrictCode
                            )
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.postal")}
                    </p>
                    <div className="w-[340px]">
                      <TextInputCustom
                        disabled
                        {...formik.getFieldProps("zipCode")}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-x-3">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.username")}
                    </p>
                    <div className="w-[340px]">
                      <TextInputCustom
                        disabled
                        {...formik.getFieldProps("username")}
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">
                      {t("userManagement.partner.modal.pin")}
                    </p>
                    <div className="w-[340px]">
                      <TextInputCustom
                        disabled
                        {...formik.getFieldProps("initialPin")}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <ButtonFillCustom
                    title={t("common.button.confirm")}
                    onClick={async () => {
                      await formik.validateForm();
                      if (formik.isValid) {
                        setIsOpenConfirmCreateModal(true);
                      } else {
                        formik.setTouched({
                          name: true,
                          surname: true,
                          type: true,
                          mobileNumber: true,
                          email: true,
                          address: true,
                          provinceCode: true,
                          districtCode: true,
                          subDistrictCode: true,
                        });
                      }
                    }}
                  />
                </div>
              </>
            )}
            {tabIndex === 1 && (
              <>
                <div className="flex gap-3">
                  <div className="w-[50%]">
                    <p className="text-[14px] mb-1">วันที่เซ็นสัญญา</p>
                    <div className="w-[340px]">
                      <TextInputCustom
                        placeholder="วันที่เซ็นสัญญา"
                        {...formik.getFieldProps("contractSignDate")}
                        InputProps={{
                          endAdornment:
                            formik.values.contractSignDate !== "" ? (
                              <CloseRoundedIcon
                                sx={{
                                  cursor: "pointer",
                                  fontSize: "18px !important",
                                }}
                                onClick={() =>
                                  formik.setFieldValue("contractSignDate", "")
                                }
                              />
                            ) : (
                              <SvgIcon
                                sx={{
                                  fontSize: "24px !important",
                                  color: "#646464",
                                }}
                                fontSize="small"
                              >
                                <CalendarSvg />
                              </SvgIcon>
                            ),
                        }}
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>
                        ) => {
                          e.stopPropagation();
                          refCalendarStartDate.current?.onOpen(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <div className="flex flex-row justify-between">
                      <p className="text-[14px] mb-1">รายได้ลูกค้าใหม่</p>
                      {selectedRow && (
                        <Link
                          underline="hover"
                          component="button"
                          color="#68C184"
                          className="!text-[14px] !mb-1 !font-medium"
                          onClick={() => onOpenRevenueChangeLog("onboard")}
                        >
                          ประวัติรายได้
                        </Link>
                      )}
                    </div>
                    <div className="w-[340px]">
                      <TextInputCustom
                        placeholder="กรอก"
                        {...formik.getFieldProps("onboardRate")}
                        suffix="บาท/คน"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-3">
                  <div className="w-[50%]">
                    <div className="flex flex-row justify-between">
                      <p className="text-[14px] mb-1">
                        เปอร์เซ็นต์รายได้ยอดขาย
                      </p>
                      {selectedRow && (
                        <Link
                          underline="hover"
                          component="button"
                          color="#68C184"
                          className="!text-[14px] !mb-1 !font-medium"
                          onClick={() => onOpenRevenueChangeLog("order_feed")}
                        >
                          ประวัติรายได้
                        </Link>
                      )}
                    </div>
                    <div className="w-[340px]">
                      <TextInputCustom
                        placeholder="กรอก"
                        {...formik.getFieldProps("revenueRate")}
                        suffix="%"
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="w-[50%]">
                    <div className="flex flex-row justify-between">
                      <p className="text-[14px] mb-1">
                        เปอร์เซ็นต์รายได้เก็บหนี้
                      </p>
                      {selectedRow && (
                        <Link
                          underline="hover"
                          component="button"
                          color="#68C184"
                          className="!text-[14px] !mb-1 !font-medium"
                          onClick={() => onOpenRevenueChangeLog("invoice")}
                        >
                          ประวัติรายได้
                        </Link>
                      )}
                    </div>
                    <div className="w-[340px]">
                      <TextInputCustom
                        placeholder="กรอก"
                        {...formik.getFieldProps("invoiceFollowRate")}
                        suffix="%"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <p className="font-bold text-[#000000] text-[14px] font-sans">
                    ใบสัญญา
                  </p>
                  {showFiles.length > 0 ? (
                    <DownloadLinkWithDelete
                      onDownload={handleDownloadByUrl(
                        showFiles[0].id,
                        showFiles[0].name
                      )}
                      onDelete={async () => {
                        setFiles([]);
                        setShowFiles([]);
                      }}
                      name={showFiles[0].name}
                    />
                  ) : (
                    <ListOfFile
                      files={files as File[]}
                      onDownload={handleDownload}
                      onDelete={async (idx) => {
                        setFiles([]);
                      }}
                    />
                  )}
                  <DragAndDropUploadImage
                    onUpload={(newFiles) => {
                      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
                    }}
                    type={{ "application/pdf": [".pdf"] }}
                  />
                </div>
                <div className="flex justify-end">
                  <ButtonFillCustom
                    title={t("common.button.confirm")}
                    onClick={async () => {
                      await formik.validateForm();
                      if (formik.isValid) {
                        setIsOpenConfirmCreateModal(true);
                      } else {
                        formik.setTouched({
                          name: true,
                          surname: true,
                          type: true,
                          mobileNumber: true,
                          email: true,
                          address: true,
                          provinceCode: true,
                          districtCode: true,
                          subDistrictCode: true,
                        });
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
      <ConfirmCustomModal
        title={selectedRow ? "ยืนยันการบันทึก" : "ยืนยันการเพิ่มพาร์ทเนอร์"}
        subtitleFirstRow={
          selectedRow
            ? "คุณต้องการบันทึกรายการนี้ใช่หรือไม่?"
            : "คุณต้องการเพิ่มพาร์ทเนอร์ใช่หรือไม่?"
        }
        subtitleSecondRow="การดำเนินการนี้ไม่สามารถยกเลิกได้"
        buttonPrimaryText={t("common.button.confirm")}
        buttonSecondaryText="กลับไปแก้ไข"
        disablePrimaryBtn={isSubmitting}
        disableSecondaryBtn={isSubmitting}
        open={isOpenConfirmCreateModal}
        onClose={() => setIsOpenConfirmCreateModal(false)}
        onSubmit={() => {
          formik.handleSubmit();
        }}
      />
      <DatePickerPopover
        ref={refCalendarStartDate}
        id={"date-picker-start-date"}
        date={formik.values.contractSignDate}
        handleCalenderSave={handleSelectStartDate}
      />
      <RevenueChangeLogModal
        isOpen={isOpenRevenueChangeLog}
        onClose={onHideRevenueChangeLog}
        type={viewRevenueType as "onboard" | "order_feed" | "invoice"}
        selectedRow={selectedRow || ""}
      />
    </>
  );
};

export default CreatePartnerModal;
