import CloseIcon from "@mui/icons-material/Close";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, IconButton, Modal } from "@mui/material";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import DateRangePickerPopover from "../../../../../admin/components/Input/DateRangePickerPopover/DateRangePickerPopover";
import {
  Dropdown,
  TDropdownValue,
} from "../../../../../admin/pages/group-area/views/Dropdown";
import IconDownloadSvg from "../../../../../assets/svg/IconDownloadSvg";
import {
  TFormFilterModal,
  TFormSelectBillType,
  defaultFormFilterModal,
  defaultFormSelectBillType,
} from "../defaultFormSeeDatails";

const FILTER_DATE_FORMAT = "YYYY-MM-DD";

interface IFilterModalOrImportProps {
  isOpen: boolean;
  onClose: () => void;
  onReset?: () => void;
  title: string;
  subtitle: string;
  isImport?: boolean;
  onImport?: (startDate: string, endDate: string) => void;
  form?: TFormFilterModal;
  setForm?: (form: TFormFilterModal) => void;
  onChangeForm?: (key: keyof TFormFilterModal) => (value: any) => void;
  maxDate?: Date;
  minDate?: Date;
  isDropdownMultiple?: boolean;
  option?: TDropdownValue[];
  placeholder?: string;
  formDropdown?: TFormSelectBillType;
  setFormDropdown?: (form: TFormSelectBillType) => void;
  onChangeFormDropdown?: (
    key: keyof TFormSelectBillType
  ) => (value: any) => void;
}

const FilterModalOrImport = (props: IFilterModalOrImportProps) => {
  const {
    isOpen,
    onClose,
    onReset,
    title,
    subtitle,
    isImport,
    onImport,
    form,
    setForm,
    onChangeForm,
    maxDate,
    minDate,
    isDropdownMultiple,
    option,
    placeholder,
    formDropdown,
    setFormDropdown,
    onChangeFormDropdown,
  } = props;
  const [startCalendarDate, setStartCalendarDate] = useState("");
  const [endCalendarDate, setEndCalendarDate] = useState("");
  const [valueOption, setValueOption] = useState<TDropdownValue[]>([]);
  const { t } = useTranslation();
  const refCalendarDateRange = React.useRef<
    React.ElementRef<typeof DateRangePickerPopover>
  >({
    clearData: () => {},
    onOpen: () => {},
  });

  const [isOpenCalender, setIsOpenCalender] = useState<boolean>(false);

  const handleSelect = (dateRange: any[]) => {
    const formattedStartDate = dayjs(
      dateRange[0].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    const formattedEndDate = dayjs(
      dateRange[1].format(FILTER_DATE_FORMAT)
    ).format(FILTER_DATE_FORMAT);
    setStartCalendarDate(formattedStartDate);
    setEndCalendarDate(formattedEndDate);
  };

  const handleCloseCalender = () => {
    setIsOpenCalender(false);
  };
  const handleCalenderSave = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: any[]
  ) => {
    event?.stopPropagation();
    const formattedStartDate = dayjs(value[0].startDate).format(
      FILTER_DATE_FORMAT
    );
    const formattedEndDate = dayjs(value[0].endDate).format(FILTER_DATE_FORMAT);
    setStartCalendarDate(formattedStartDate);
    setEndCalendarDate(formattedEndDate);
  };

  const onResetFn = useCallback(() => {
    onReset && onReset();
    onClose();
    setStartCalendarDate("");
    setEndCalendarDate("");
    setValueOption([]);
    setForm && setForm(defaultFormFilterModal);
    setFormDropdown && setFormDropdown(defaultFormSelectBillType);
    refCalendarDateRange.current.clearData();
  }, []);

  const onDefaultFn = useCallback(() => {
    setStartCalendarDate("");
    setEndCalendarDate("");
    setValueOption([]);
    setForm && setForm(defaultFormFilterModal);
    setFormDropdown && setFormDropdown(defaultFormSelectBillType);
    refCalendarDateRange.current.clearData();
  }, []);

  const onConfirm = () => {
    onChangeForm && onChangeForm("startDate")(startCalendarDate);
    onChangeForm && onChangeForm("endDate")(endCalendarDate);
    onChangeFormDropdown && onChangeFormDropdown("billType")(valueOption);
  };

  return (
    <Modal
      open={isOpen}
      //   onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      disableEnforceFocus={false}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "313px",
          minWidth: "313px",
          bgcolor: "#000",
          borderRadius: "40px",
          boxShadow: 24,
        }}
      >
        <div className="flex flex-col relative px-6 pt-4 pb-6 bg-white w-full h-full rounded-[40px]">
          <div className="flex flex-row gap-2 items-center">
            {isImport ? (
              <IconDownloadSvg />
            ) : (
              <FilterListIcon
                sx={{
                  color: "#68C184",
                  fontWeight: 500,
                  alignSelf: "center",
                  height: "100%",
                }}
              />
            )}
            <span className="text-secondary font-medium text-2xl h-full self-center">
              {isImport ? t("BasicAccounting.export") : title}
            </span>
          </div>
          <div className="absolute right-6 top-4">
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon sx={{ color: "#000" }} />
            </IconButton>
          </div>
          <div className="flex flex-col h-full justify-start pt-6 gap-6">
            <div className="flex flex-col w-full">
              <span className="text-tertiary font-semibold text-xl pb-3">
                {subtitle}
              </span>
              {!isDropdownMultiple ? (
                <Button
                  variant="outlined"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    e.stopPropagation();
                    refCalendarDateRange.current?.onOpen(e);
                  }}
                  endIcon={<EventOutlinedIcon />}
                  sx={{
                    height: "2rem",
                    borderRadius: "10px !important",
                    minWidth: "132px",
                    padding: "10px",
                    border: "1px solid #68C184",
                    color: "#68C184",
                    bgcolor: "#F0FDF4",
                    justifyContent: "space-between",
                    width: "fit-content",
                    textAlign: "center",
                    fontWeight: "normal",
                    fontSize: "1rem",
                  }}
                  fullWidth={false}
                >
                  {placeholder || t("FinancialSupport.startEnd")}
                </Button>
              ) : (
                <Dropdown
                  options={option || []}
                  placeholder={placeholder}
                  onChange={(value) => setValueOption(value)}
                  renderValueArray={valueOption}
                  choseStyle2
                  isCheckbox
                  multiple
                  containerDropdown="pb-0 text-center"
                  styleMenuItem={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root": {
                          backgroundColor: "#FFFFFF",
                          boxShadow: "0px -1px 0px 0px #00000040 inset",
                          "&.Mui-selected": {
                            backgroundColor: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#D9EBFE !important",
                            },
                          },
                          "&:hover": {
                            backgroundColor: "#D9EBFE",
                          },
                        },
                      },
                    },
                    MenuListProps: {
                      sx: {
                        padding: 0,
                      },
                    },
                  }}
                />
              )}

              {startCalendarDate || endCalendarDate ? (
                <span className="text-sm font-normal text-secondary pt-2">
                  {/* {dayjs(startCalendarDate).format("DD/MM/BBBB")} -{" "}
                  {dayjs(endCalendarDate).format("DD/MM/BBBB")} */}
                  {dayjs(startCalendarDate).format("DD/MM/YYYY")} -{" "}
                  {dayjs(endCalendarDate).format("DD/MM/YYYY")}
                </span>
              ) : null}
            </div>
          </div>
          <div className="flex w-full gap-2 pt-6">
            {isImport ? (
              <Button
                sx={{
                  bgcolor: "#68C184",
                  color: "#FFFFFF",
                  ":active": {
                    border: "1px solid #68C184",
                    bgcolor: "#FFFFFF",
                    color: "#68C184",
                  },
                  ":hover": {
                    border: "1px solid #68C184",
                    bgcolor: "#FFFFFF",
                    color: "#68C184",
                  },
                  height: "37px",
                }}
                fullWidth
                onClick={() => {
                  onConfirm();
                  onImport && onImport(startCalendarDate, endCalendarDate);
                  onResetFn();
                }}
              >
                {t("BasicAccounting.export")}
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  sx={{
                    border: "1px solid #68C184",
                    color: "#68C184",
                    ":active": {
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                      color: "#FFFFFF",
                    },
                    ":hover": {
                      border: "1px solid #68C184",
                      bgcolor: "#68C184",
                      color: "#FFFFFF",
                    },
                    height: "37px",
                  }}
                  fullWidth
                  onClick={onDefaultFn}
                >
                  {t("FinancialSupport.reset")}
                </Button>
                <Button
                  sx={{
                    bgcolor: "#68C184",
                    color: "#FFFFFF",
                    ":active": {
                      border: "1px solid #68C184",
                      bgcolor: "#FFFFFF",
                      color: "#68C184",
                    },
                    ":hover": {
                      border: "1px solid #68C184",
                      bgcolor: "#FFFFFF",
                      color: "#68C184",
                    },
                    height: "37px",
                  }}
                  fullWidth
                  onClick={() => {
                    onConfirm();
                    onClose();
                  }}
                >
                  {t("FinancialSupport.apply")}
                </Button>
              </>
            )}
          </div>
        </div>
        <DateRangePickerPopover
          ref={refCalendarDateRange}
          id={"calendar-range-popover"}
          handleSelect={handleSelect}
          openCalender={isOpenCalender}
          onClose={handleCloseCalender}
          handleCalenderSave={handleCalenderSave}
          startDate={startCalendarDate}
          endDate={endCalendarDate}
          maxDate={maxDate}
          minDate={minDate}
        />
      </Box>
    </Modal>
  );
};

export default FilterModalOrImport;
