import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Divider, Link, SvgIcon, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getExportListCorePartner,
  getListCorePartner,
  getPartnerGroupArea,
} from "../../../../services/dashboard/PerformancePartner.service";
import { IGroupAreaRow } from "../../../../types/Utils.type";
import { IListCorePartner } from "../../../../types/dashboard/PerformancePartner.type";
import { makeXlsx } from "../../../../utils/make-xlsx";
import { tw } from "../../../../utils/tw";
import { DownloadSvg } from "../../../assets/svg/Download.svg";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../../components/Button/ButtonOutlinedCustom";
import TextInputSearchCustom from "../../../components/Input/TextInputSearchCustom";
import MenuBar from "../../../components/MenuBar/MenuBar";
import TableCustom from "../../../components/Table/TableCustom";
import { Dropdown, TDropdownValue } from "../../group-area/views/Dropdown";

const CorePartnerTable = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");

  // table
  const [data, setData] = useState<IListCorePartner[] | []>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderByType, setOrderByType] = useState<"desc" | "asc">("desc");
  const [currentOrderByKey, setCurrentOrderByKey] = useState<
    "customer_no" | "month"
  >("customer_no");

  const [groupAreaOption, setGroupAreaOption] = useState<IGroupAreaRow[]>([]);
  const [groupArea, setGroupArea] = useState<TDropdownValue[]>([]);

  const segmentOption = ["Potential", "Grower", "Independent"];
  const [segment, setSegment] = useState<TDropdownValue[]>([]);

  const classes = {
    emptyTable: tw(
      `font-sans text-center w-full text-[#979797] text-[14px] py-3`
    ),
  };

  const headerItems = [
    {
      id: "customer_no",
      numeric: false,
      disablePadding: false,
      label: "รหัสลูกค้า",
      width: "auto",
      isSorting: true,
      className: tw(`whitespace-pre`),
    },
    {
      id: "customer_name",
      numeric: false,
      disablePadding: false,
      label: "ชื่อลูกค้า",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "province",
      numeric: false,
      disablePadding: false,
      label: "จังหวัด",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "group_area",
      numeric: false,
      disablePadding: false,
      label: "Group Area",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "farm_size",
      numeric: false,
      disablePadding: false,
      label: "ขนาดฟาร์ม",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "farm_amount",
      numeric: false,
      disablePadding: false,
      label: "จำนวนฟาร์ม",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "barn_amount",
      numeric: false,
      disablePadding: false,
      label: "จำนวนโรงเรือน",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "partnerNo",
      numeric: false,
      disablePadding: false,
      label: "รหัสพาร์ทเนอร์",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "partnerName",
      numeric: false,
      disablePadding: false,
      label: "ชื่อพาร์ทเนอร์",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "feedVolume",
      numeric: false,
      disablePadding: false,
      label: "Feed volume",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
  ];
  const rowChildren = useMemo(() => {
    return data.map((row, index) => {
      return (
        <TableRow>
          <TableCell>
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                console.info("I'm a button.");
              }}
              sx={{
                color: "#68C184",
                textDecorationColor: "#68C184",
                ":hover": {
                  fontSize: "16px",
                },
              }}
            >
              {row.farmerRegistrationId}
            </Link>
          </TableCell>
          <TableCell>{row.farmerFullname}</TableCell>
          <TableCell>{row.province}</TableCell>
          <TableCell>{row.groupArea}</TableCell>
          <TableCell>{row.farmSize}</TableCell>
          <TableCell>{row.farmAmount}</TableCell>
          <TableCell>{row.houseAmount}</TableCell>
          <TableCell>{row.partnerCode}</TableCell>
          <TableCell>{row.partnerName}</TableCell>
          <TableCell>{row.feedVolume}</TableCell>
        </TableRow>
      );
    });
  }, [data, currentPage]);

  const fetchGroupArea = async () => {
    const data = await getPartnerGroupArea();
    if (data) {
      setGroupAreaOption(data);
    }
  };

  const fetchData = async () => {
    const data = await getListCorePartner({
      groupAreaIds: groupArea[0]?.value,
      segments: segment.map((item) => item.value.toLowerCase()).join(",") || "",
      searchText: searchText,
      orderBy: currentOrderByKey,
      direction: orderByType,
      page: currentPage,
      limit: 10,
    });
    if (data) {
      setData(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [orderByType, currentOrderByKey]);

  useEffect(() => {
    fetchGroupArea();
    fetchData();
  }, []);

  const handleExport = async () => {
    const data = await getExportListCorePartner({
      groupAreaIds: groupArea[0]?.value,
      segments: segment.map((item) => item.value.toLowerCase()).join(",") || "",
      searchText: searchText,
      orderBy: currentOrderByKey,
      direction: orderByType,
      page: currentPage,
      limit: 10,
    });

    const dataExcel = data?.map((item) => {
      return {
        รหัสลูกค้า: item.farmerRegistrationId,
        ชื่อลูกค้า: item.farmerFullname,
        จังหวัด: item.province,
        "Group Area": item.groupArea,
        ขนาดฟาร์ม: item.farmSize || "-",
        จำนวนฟาร์ม: item.farmAmount || "-",
        จำนวนโรงเรือน: item.houseAmount || "-",
        รหัสพาร์ทเนอร์: item.partnerCode,
        ชื่อพาร์ทเนอร์: item.partnerName,
        "Feed volume": item.feedVolume || 0,
      };
    });
    const fileName = `Core_Partner_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(dataExcel || [], fileName, sheetName);
  };

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <div className="flex justify-start items-center gap-3">
              <div onClick={() => navigate("/admin/performance/partner")}>
                <ChevronLeftIcon fontSize="large" />
              </div>
              <span className="text-[#000] font-bold text-3xl">
                Core Partners
              </span>
            </div>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="flex flex-col py-6 gap-6">
              <div className="flex flex-row gap-3 items-end">
                <Dropdown
                  label="Group Area"
                  options={groupAreaOption.map((row) => ({
                    value: row.id,
                    label: row.name,
                  }))}
                  onChange={(value) => {
                    setGroupArea(value);
                  }}
                  onReset={() => {
                    setGroupArea([]);
                  }}
                  renderValueArray={groupArea}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                />
                <Dropdown
                  label="Segment"
                  options={segmentOption.map((row) => ({
                    value: row,
                    label: row,
                  }))}
                  onChange={(value) => setSegment(value)}
                  onReset={() => {
                    setSegment([]);
                  }}
                  renderValueArray={segment}
                  containerClassName="min-w-[150px]"
                  placeholder="เลือก"
                  multiple
                />
                <ButtonFillCustom
                  title="ค้นหา"
                  onClick={() => fetchData()}
                  className="!mb-3"
                />
              </div>
              <div className="w-full flex justify-between">
                <TextInputSearchCustom
                  value={searchText}
                  className="max-w-[470px]"
                  onChange={(event) => setSearchText(event.target.value)}
                  placeholder="ค้นหาโดยรหัสลูกค้า, ชื่อลูกค้า"
                  InputProps={{
                    endAdornment: searchText ? (
                      <CloseRoundedIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setSearchText("");
                        }}
                      />
                    ) : null,
                  }}
                />
                <ButtonOutlinedCustom
                  title="นำออก"
                  btnTextColor="#68C184"
                  btnBorderColor="#68C184"
                  onClick={() => handleExport()}
                  startIcon={
                    <SvgIcon
                      sx={{
                        fontSize: "14px !important",
                        color: "currentColor",
                        marginBottom: "3px",
                      }}
                      fontSize="small"
                    >
                      <DownloadSvg />
                    </SvgIcon>
                  }
                />
              </div>
            </div>

            <TableCustom
              order={orderByType}
              onChangeOrder={setOrderByType}
              orderBy={currentOrderByKey}
              onChangeOrderBy={setCurrentOrderByKey as any}
              totalItemSize={0}
              headerItems={headerItems}
              page={currentPage}
              onChangePage={setCurrentPage}
              rowsPerPage={10}
              rows={[]}
              EmptyComponent={
                data.length === 0 ? (
                  <div className={classes.emptyTable}>ไม่มีข้อมูล</div>
                ) : null
              }
              rowChildren={rowChildren}
              currentPageItems={data!.map(({ id }) => id)}
              hideSelect
              itemsSelected={[]}
              onSelectItem={(_) => {}}
              onSelectAllClick={(_) => {}}
            />
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default CorePartnerTable;
