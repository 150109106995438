import React from "react";

import TableCustom, {
  ExpandableTableRow,
  ITableCustomHeadCell,
} from "../../../../components/Table/TableCustom";

import { Checkbox, Link, TableCell } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useInvoiceSearchFilter } from "../../../../../hooks/useInvoiceSearchFilter.hook";
import {
  getListExportInvoiceSaleOverdue,
  getListInvoiceOverDueSale,
} from "../../../../../services/admin/invoice/invoiceSale.service";
import { IRowOverInvoiceDueSale } from "../../../../../types/invoice/invoiceSale.type";
import { thousandComma } from "../../../../../utils/common/string";
import { makeXlsx } from "../../../../../utils/make-xlsx";
import { tw } from "../../../../../utils/tw";
import { useAdminAuth } from "../../../../components/AdminAuth/AdminAuthProvider";
import ButtonFillCustom from "../../../../components/Button/ButtonFillCustom";
import ChipOrderStatus from "../../../../components/Chip/ChipOrderStatus";
import DocumentModal from "../../../../components/Modal/DocumentModal/DocumentModal";
import ExpandUpdateDocumentInvoice from "../../components/ExpandUpdateDocumentInvoice";
import { FilterInvoice } from "../../components/FilterInvoice";
import { IInvoiceTable, TInvoiceOverdueStatus } from "../../type";
import { usePastDueInvoiceController } from "./controller";
import CreateAssignmentModal from "./views/CreateAssignmentModal";
import FollowInvoiceModal from "./views/FollowInvoiceModal";

type Props = { handleFetchCount: () => void };

const PastDueInvoicePanel = ({ handleFetchCount }: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.invoiceSale === "edit";
  const {
    isShowCreateAssignment,
    onShowCreateAssignment,
    onHideCreateAssignment,
    overdueFarmer,
    setOverdueFarmer,
    OverdueFarmerOptions,
    listPartner,
    setListPartner,
    PartnerOptions,
    OverdueFarmerInvoice,
    searchText,
    onSearchText,
    onClearSearchText,
    currentPage,
    setCurrentPage,
  } = usePastDueInvoiceController();

  const [itemSelected, setItemSelected] = React.useState<string[]>([]);

  const [disabledBtnManageDept, setDisabledBtnManageDept] =
    React.useState<boolean>(true);

  const [orderInvoice, setOrderInvoice] = React.useState<IInvoiceTable[]>([]);
  const [selectedInvoice, setSelectedInvoice] = React.useState<IInvoiceTable[]>(
    []
  );
  const [count, setCount] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [modalData, setModalData] = React.useState<IInvoiceTable>();
  const [isOpenFollowInvoice, setIsOpenFollowInvoice] = React.useState(false);
  const [invoiceLog, setInvoiceLog] = React.useState<IRowOverInvoiceDueSale[]>(
    []
  );
  const navigate = useNavigate();

  function createData(
    id: string,
    orderId: string,
    customerId: string,
    customerName: string,
    partnerFollowInvoice: string,
    invoiceNumber: string,
    workSheetNumber: string,
    amountToBePaid: string,
    totalPending: string,
    paidAmount: string,
    receiveSuccessDate: string,
    paymentDueDate: string,
    updateDate: string,
    status: TInvoiceOverdueStatus
  ): IInvoiceTable {
    return {
      id,
      orderId: orderId ? orderId : "-",
      customerId: customerId ? customerId : "-",
      customerName: customerName ? customerName : "-",
      partnerFollowInvoice: partnerFollowInvoice ? partnerFollowInvoice : "-",
      invoiceNumber: invoiceNumber ? invoiceNumber : "-",
      workSheetNumber: workSheetNumber ? workSheetNumber : "-",
      amountToBePaid,
      totalPending,
      paidAmount,
      receiveSuccessDate: receiveSuccessDate
        ? dayjs(receiveSuccessDate).format("DD/MM/YYYY")
        : "-",
      paymentDueDate: paymentDueDate
        ? dayjs(paymentDueDate).format("DD/MM/YYYY")
        : "-",
      updateDate: updateDate ? dayjs(updateDate).format("DD/MM/YYYY") : "-",
      status: status as TInvoiceOverdueStatus,
    };
  }

  const headCells: ITableCustomHeadCell<keyof IInvoiceTable>[] = [
    {
      id: "orderId",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.orderNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerId",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.customerId"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "customerName",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.customerName"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "partnerFollowInvoice",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.partnerName"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "invoiceNumber",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.invoiceNumber"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "workSheetNumber",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.sheetNumber"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "amountToBePaid",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.totalDueAmount"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "totalPending",
      numeric: false,
      disablePadding: false,
      label: "ยอดเงินที่รอตวจสอบ",
      width: "auto",
      isSorting: false,
      className: tw(`whitespace-pre`),
    },
    {
      id: "paidAmount",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.paidAmount"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "receiveSuccessDate",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.productReceivedDate"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "paymentDueDate",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.paymentDueDate"),
      width: "150px",
      isSorting: false,
    },
    {
      id: "updateDate",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.lastUpdatedDate"),
      width: "150px",
      isSorting: true,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: t("invoice.overdueInvoiceTab.listOfInvoice.status"),
      width: "200px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "100px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "80px",
      isSorting: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "",
      width: "20px",
      isSorting: false,
    },
  ];

  const OrderStatusMap: Record<
    TInvoiceOverdueStatus,
    { color: string; bgColor: string; label: string }
  > = {
    not_follow: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "ยังไม่ได้ติดตาม",
    },
    followed: {
      color: "#409261",
      bgColor: "#E9FFEF",
      label: "กำลังติดตาม",
    },
    followed_three_days: {
      color: "#014F79",
      bgColor: "#F2F9FD",
      label: "กำลังติดตาม 3 วัน",
    },
    not_followed_seven_days: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "ยังไม่ได้ติดตาม 7-30 วัน",
    },
    followed_seven_days: {
      color: "#3777BC",
      bgColor: "#D9EBFE",
      label: "กำลังติดตาม 7-30 วัน",
    },
    not_followed_thirty_one_days: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "ยังไม่ได้ติดตาม 31-60 วัน",
    },
    followed_thirty_one_days: {
      color: "#5572EB",
      bgColor: "#DDE4FF",
      label: "กำลังติดตาม 31-60 วัน",
    },
    not_followed_sixty_days: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "ยังไม่ได้ติดตาม 60 วัน",
    },
    followed_sixty_days: {
      color: "#D32F2F",
      bgColor: "#F3DADA",
      label: "กำลังติดตาม 60 วัน",
    },
    waiting_for_approval: {
      color: "#F57C00",
      bgColor: "#FDECD9",
      label: "รออนุมัติ",
    },
  };

  const statusPastDueInvoice = [
    {
      statusEN: "not_follow",
      statusTH: "ยังไม่ได้ติดตาม",
    },
    {
      statusEN: "followed",
      statusTH: "กำลังติดตาม",
    },
    {
      statusEN: "followed_three_days",
      statusTH: "กำลังติดตาม",
    },
    {
      statusEN: "not_followed_seven_days",
      statusTH: "ยังไม่ได้ติดตาม 7-30 วัน",
    },
    {
      statusEN: "followed_seven_days",
      statusTH: "กำลังติดตาม 7-30 วัน",
    },
    {
      statusEN: "not_followed_thirty_one_days",
      statusTH: "ยังไม่ได้ติดตาม 31-60 วัน",
    },
    {
      statusEN: "followed_thirty_one_days",
      statusTH: "กำลังติดตาม 31-60 วัน",
    },
    {
      statusEN: "not_followed_sixty_days",
      statusTH: "ยังไม่ได้ติดตาม 60 วัน",
    },
    {
      statusEN: "followed_sixty_days",
      statusTH: "กำลังติดตาม 60 วัน",
    },
    {
      statusEN: "waiting_for_approval",
      statusTH: "รออนุมัติ",
    },
  ];

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = orderInvoice.map((n) => n?.id);
    if (event.target.checked) {
      setItemSelected(Array.from(new Set([...itemSelected, ...newSelected])));
      return;
    }
    const newItemDeselected = itemSelected.filter(
      (row) => !newSelected.includes(row)
    );
    setItemSelected(newItemDeselected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: string) => {
    const selectedIndex = itemSelected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(itemSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(itemSelected.slice(1));
    } else if (selectedIndex === itemSelected.length - 1) {
      newSelected = newSelected.concat(itemSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        itemSelected.slice(0, selectedIndex),
        itemSelected.slice(selectedIndex + 1)
      );
    }

    setItemSelected(newSelected);
  };

  const isSelected = (id: string) => itemSelected.indexOf(id) !== -1;

  const MapOrderByWithFilter: {
    [K in keyof IInvoiceTable]?: string;
  } = {
    receiveSuccessDate: "receive_date",
    updateDate: "updated_at",
  };

  const searchFilterInvoice = useInvoiceSearchFilter();

  const handleFetch = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("searchText", searchFilterInvoice.searchText);
    searchParams.append("page", searchFilterInvoice.page.toString());
    searchParams.append("limit", searchFilterInvoice.limit.toString());
    searchParams.append(
      "overdueStatus",
      searchFilterInvoice.searchOverdueStatus
    );
    searchParams.append(
      "orderBy",
      MapOrderByWithFilter[searchFilterInvoice.orderBy] ?? ""
    );
    searchParams.append("direction", searchFilterInvoice.direction);
    setOrderInvoice([]);

    const data = await getListInvoiceOverDueSale(searchParams);
    if (data?.rows) {
      const newList = data.rows.map((row) => {
        return createData(
          row.id,
          row.orderNumber,
          row.farmerRegistrationId,
          row.farmerName + " " + row.farmerSurname,
          row.partner ? row.partner.name + " " + row.partner.surname : "",
          row.invoiceNumber,
          row.assignmentNumber,
          row.totalPrice.toString(),
          row.totalPending.toString(),
          row.totalPaid.toString(),
          row.receiveDate,
          row.dueDate,
          row.updatedAt,
          row.overdueStatus as TInvoiceOverdueStatus
        );
      });
      setCount(data.count);
      setOrderInvoice(newList);
      setInvoiceLog(data.rows.map((row) => row));
    }
    handleFetchCount();
  };

  React.useEffect(() => {
    handleFetch();
  }, [
    searchFilterInvoice.orderBy,
    searchFilterInvoice.direction,
    searchFilterInvoice.page,
  ]);

  React.useEffect(() => {
    const itemDetailSelected = orderInvoice.filter((item) =>
      itemSelected.includes(item.id)
    );
    if (
      itemDetailSelected.length &&
      itemDetailSelected.every(
        (item) => item.status === "not_followed_sixty_days"
      )
    ) {
      setDisabledBtnManageDept(false);
      setSelectedInvoice(itemDetailSelected);
    } else {
      setDisabledBtnManageDept(true);
      setSelectedInvoice([]);
    }
  }, [itemSelected]);

  const handleOpenModalDocument = (row: IInvoiceTable) => {
    setModalData(row);
    setIsOpen(true);
  };

  const handleOpenModalFollow = (row: IInvoiceTable) => {
    setModalData(row);
    setIsOpenFollowInvoice(true);
  };

  const handleCloseModalDocument = (isSuccess: boolean) => {
    setIsOpen(false);
    if (isSuccess) {
      handleFetch();
    }
  };

  const getExportExcelData = async () => {
    type TColumnName =
      | "หมายเลขสั่งซื้อ"
      | "รหัสลูกค้า"
      | "ชื่อลูกค้า"
      | "พาร์ทเนอร์ที่ติดตาม"
      | "เลข Invoice"
      | "เลขที่ใบงาน"
      | "ยอดเงินที่ต้องชำระ"
      | "ยอดเงินที่รอตรวจสอบ"
      | "ยอดเงินที่ชำระเเล้ว"
      | "วันรับสินค้าสำเร็จ"
      | "วันครบกำหนดชำระ"
      | "วันที่อัปเดตล่าสุด"
      | "สถานะ";
    type TExcelData = {
      [k in TColumnName]: string | number;
    };
    const allSelectItemData = await getListExportInvoiceSaleOverdue(
      itemSelected
    );
    if (allSelectItemData?.rows) {
      return allSelectItemData.rows
        .map<TExcelData>((row) => {
          return {
            หมายเลขสั่งซื้อ: row.orderNumber || "-",
            รหัสลูกค้า: row.farmerRegistrationId || "-",
            ชื่อลูกค้า: row.farmerName + " " + row.farmerSurname,
            พาร์ทเนอร์ที่ติดตาม: row.partner?.name + " " + row.partner?.surname,
            "เลข Invoice": row.invoiceNumber || "-",
            เลขที่ใบงาน: row.assignmentNumber || "-",
            ยอดเงินที่ต้องชำระ:
              thousandComma(row.totalPaid, 2) + t("common.bath") || "-",
            ยอดเงินที่รอตรวจสอบ:
              thousandComma(row.totalPending, 2) + t("common.bath") || "-",
            ยอดเงินที่ชำระเเล้ว:
              thousandComma(row.totalPrice, 2) + t("common.bath") || "-",
            วันรับสินค้าสำเร็จ: dayjs(row.receiveDate).format("DD/MM/YYYY"),
            วันครบกำหนดชำระ: dayjs(row.dueDate).format("DD/MM/YYYY"),
            วันที่อัปเดตล่าสุด: dayjs(row.updatedAt).format("DD/MM/YYYY"),
            สถานะ: row.overdueStatus as TInvoiceOverdueStatus,
          };
        })
        .flat();
    }
    return [];
  };

  const handleExportExcel = async () => {
    const data = await getExportExcelData();
    const fileName = `overdue_invoice_sale_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data, fileName, sheetName);
  };

  return (
    <div className="w-full h-full pt-8">
      <FilterInvoice
        searchFilterInvoice={searchFilterInvoice}
        onSearch={handleFetch}
        placeholder={"ค้นหาโดยเลข invoice"}
        isDisplayOverDueSelect={true}
        itemSelected={itemSelected}
        select={statusPastDueInvoice}
        displayButtonManageBelt={true}
        displayButtonCreateAssignment={true}
        onOpenCreate={onShowCreateAssignment}
        disabledBtnManageDept={disabledBtnManageDept}
        selectedInvoice={selectedInvoice}
        onExport={handleExportExcel}
      />
      <div className="flex w-full pt-6">
        <TableCustom
          order={searchFilterInvoice.direction}
          orderBy={searchFilterInvoice.orderBy}
          rowsPerPage={searchFilterInvoice.limit}
          rows={orderInvoice}
          itemsSelected={itemSelected}
          headerItems={headCells}
          page={searchFilterInvoice.page}
          onChangeOrderBy={(val) =>
            searchFilterInvoice.setOrderBy(val as keyof IInvoiceTable)
          }
          onChangeOrder={(val) => searchFilterInvoice.setDirection(val)}
          onChangePage={(val) => searchFilterInvoice.setPage(val)}
          onSelectAllClick={(val) => handleSelectAllClick(val)}
          onSelectItem={(val) => setItemSelected(val as string[])}
          totalItemSize={count}
          currentPageItems={orderInvoice.map((row) => row.id)}
          rowChildren={
            <>
              {orderInvoice.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <ExpandableTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.orderId}
                    selected={isItemSelected}
                    expandComponent={
                      <ExpandUpdateDocumentInvoice
                        key={row.id}
                        id={row.id}
                        colSpan={18}
                        invoiceLog={invoiceLog[index].invoiceLogs}
                      />
                    }
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.id)}
                        sx={{
                          borderWidth: "1px",
                          color: "#C9C9C9",
                          "&.Mui-checked": {
                            color: "#68C184",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{
                        padding: "0px 16px",
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.orderId}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => {
                          console.info("I'm a button.");
                        }}
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {row.customerId}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.customerName}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.partnerFollowInvoice}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.invoiceNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.workSheetNumber}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.amountToBePaid &&
                        thousandComma(+row.amountToBePaid, 2) +
                          t("common.bath")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.totalPending &&
                        thousandComma(+row.totalPending, 2) + t("common.bath")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paidAmount &&
                        thousandComma(+row.paidAmount, 2) + t("common.bath")}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.receiveSuccessDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.paymentDueDate}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {row.updateDate}
                    </TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row w-full">
                        {row.status ? (
                          <div className="flex flex-row w-full">
                            <ChipOrderStatus
                              bgColor={
                                OrderStatusMap[
                                  row.status as TInvoiceOverdueStatus
                                ].bgColor
                              }
                              fontColor={
                                OrderStatusMap[
                                  row.status as TInvoiceOverdueStatus
                                ].color
                              }
                              label={
                                OrderStatusMap[
                                  row.status as TInvoiceOverdueStatus
                                ].label
                              }
                            />
                          </div>
                        ) : null}
                      </div>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() =>
                          navigate(`/admin/sale-invoice/${row.id}`)
                        }
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                      >
                        {t(
                          "invoice.overdueInvoiceTab.listOfInvoice.seeMoreDetails"
                        )}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      <Link
                        component="button"
                        variant="body2"
                        sx={{
                          color: "#68C184",
                          textDecorationColor: "#68C184",
                          ":hover": {
                            fontSize: "16px",
                          },
                        }}
                        onClick={() => handleOpenModalDocument(row)}
                      >
                        {t(
                          "invoice.overdueInvoiceTab.listOfInvoice.seeMoreDocuments"
                        )}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontFamily: "IBM Plex Sans Thai",
                        fontSize: "14px",
                        fontWeight: 400,
                        lineHeight: "19px",
                      }}
                    >
                      {roleActionEdit &&
                        row.status !== "waiting_for_approval" &&
                        row.status !== "not_followed_sixty_days" &&
                        row.status !== "followed_sixty_days" && (
                          <ButtonFillCustom
                            title={t(
                              "invoice.overdueInvoiceTab.listOfInvoice.follow"
                            )}
                            onClick={() => handleOpenModalFollow(row)}
                            disabled={row.partnerFollowInvoice !== "-"}
                          />
                        )}
                    </TableCell>
                  </ExpandableTableRow>
                );
              })}
            </>
          }
        ></TableCustom>
      </div>
      <CreateAssignmentModal
        isOpen={isShowCreateAssignment}
        onCloseFn={onHideCreateAssignment}
        overdueFarmer={overdueFarmer}
        setOverdueFarmer={setOverdueFarmer}
        OverdueFarmerOptions={OverdueFarmerOptions}
        listPartner={listPartner}
        setListPartner={setListPartner}
        PartnerOptions={PartnerOptions}
        OverdueFarmerInvoice={OverdueFarmerInvoice || []}
        searchText={searchText}
        onSearchText={onSearchText}
        onClearSearchText={onClearSearchText}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <DocumentModal
        handleModalClose={handleCloseModalDocument}
        isOpen={isOpen}
        data={modalData}
      />
      <FollowInvoiceModal
        isOpen={isOpenFollowInvoice}
        data={modalData}
        handleClose={(success) => {
          if (success) {
            handleFetch();
          }
          setIsOpenFollowInvoice(false);
        }}
      />
    </div>
  );
};

export default PastDueInvoicePanel;
