import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import AppBarFS from "../../components/app-bar/AppBar";
import BasicDetailTable, {
  IDataTable,
} from "../../components/common/Table/BasicDetailTable";

type Props = {};

const PigPiceDetail = (props: Props) => {
  const navigate = useNavigate();
  const [pigPrice, setPigPrice] = useState<IDataTable[]>([]);
  const { state } = useLocation();
  const { t } = useTranslation();

  const formatPriceRange = (lowestPrice: number, highestPrice: number) =>
    lowestPrice === highestPrice
      ? `${lowestPrice}`
      : `${lowestPrice} - ${highestPrice}`;

  const priceNorth = formatPriceRange(
    state.data.northLowestPrice,
    state.data.northHighestPrice
  );
  const priceWest = formatPriceRange(
    state.data.westLowestPrice,
    state.data.westHighestPrice
  );
  const priceEast = formatPriceRange(
    state.data.eastLowestPrice,
    state.data.eastHighestPrice
  );
  const priceNorthEast = formatPriceRange(
    state.data.northEastLowestPrice,
    state.data.northEastHighestPrice
  );
  const priceSouth = formatPriceRange(
    state.data.southLowestPrice,
    state.data.southHighestPrice
  );

  const priceCentral = formatPriceRange(
    state.data.centralLowestPrice,
    state.data.centralHighestPrice
  );

  useEffect(() => {
    setPigPrice([
      {
        detailName: t("FarmCommunity.North"),
        detailValue: priceNorth,
        color: "#4B4B4B",
        fontSize: "0.875rem",
      },
      {
        detailName: t("FarmCommunity.East"),
        detailValue: priceEast,
        color: "#4B4B4B",
        fontSize: "0.875rem",
      },
      {
        detailName: t("FarmCommunity.West"),
        detailValue: priceWest,
        color: "#4B4B4B",
        fontSize: "0.875rem",
      },
      {
        detailName: t("FarmCommunity.NorthEast"),
        detailValue: priceNorthEast,
        color: "#4B4B4B",
        fontSize: "0.875rem",
      },
      {
        detailName: t("FarmCommunity.Central"),
        detailValue: priceCentral,
        color: "#4B4B4B",
        fontSize: "0.875rem",
      },
      {
        detailName: t("FarmCommunity.South"),
        detailValue: priceSouth,
        color: "#4B4B4B",
        fontSize: "0.875rem",
      },
    ]);
  }, []);

  return (
    <div className="!h-screen !min-h-screen flex flex-col overflow-scroll">
      <div className="my-4 flex items-center bg-[#FFFF] rounded-b-[40px]">
        <AppBarFS
          onIconPress={() => navigate(-1)}
          title={t("FarmCommunity.DetailPage")}
        />
      </div>
      <div className="mx-4">
        <p className="text-[18px] font-semibold text-[#68C184] line-clamp-2">
          {state.data.topicName}
        </p>
        <div className=" flex justify-center my-3 mb-6">
          <img alt="article-file" src={state.file} />
        </div>
        <div>
          <div className="relative">
            <div className="bg-[#D9EBFE] flex items-center p-4 rounded-t-[20px]">
              <div className="w-[50%]">
                <span className="text-[#4B4B4B] font-bold flex items-center justify-center gap-3 text-[16px]">
                  {t("FarmCommunity.Region")}
                </span>
              </div>
              <div className="w-[50%]">
                <span className="text-[#4B4B4B] font-bold flex items-center justify-center gap-3 text-[16px]">
                  {t("FarmCommunity.Price")} ({t("FarmCommunity.BahtPerKilo")})
                </span>
              </div>
            </div>
          </div>
          <BasicDetailTable
            tableData={pigPrice}
            tableProps={{
              size: "medium",
              sx: {
                "& .MuiTableCell-root": {
                  textAlign: "center",
                  padding: "16px 48px",
                },
              },
            }}
            swapCellColor={true}
            isNewFormat={true}
          />
        </div>
        <p className="mt-4">{state.data.description}</p>
      </div>
    </div>
  );
};

export default PigPiceDetail;
