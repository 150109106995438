export const checkUserRole = (userRole: string, staffType?: string) => {
  if (typeof userRole !== "undefined" && typeof userRole === "string") {
    return "partner";
  } else if (
    typeof staffType !== "undefined" &&
    typeof staffType === "string"
  ) {
    if (staffType === "manager") {
      return "manager";
    } else if (staffType === "staff") {
      return "staff";
    }
  }
  return "farmer";
};
