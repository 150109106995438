import { Modal } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";

import axios from "axios";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import AppBarFS from "../../../components/app-bar/AppBar";
import PDFRenderer from "../../../components/common/PDFRenderer";
import { getDownloadUrlStockPartner } from "../../../services/partner/historyStock.service";
import { TGetListPartnerStockRefillRep } from "../../../types/partner/stock.type";
import { handleDownloadBlob } from "../../../utils/downloadFile";
import { tw } from "../../../utils/tw";

interface IPartnerViewDocumentProps {
  isOpen: boolean;
  onClose: () => void;
  stockList: TGetListPartnerStockRefillRep;
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4`),
  headerContainer: tw(
    `mb-[16px] px-[16.5px] text-[18px] leading-[21.6px] font-semibold flex flex-row items-center justify-between`
  ),
  label: tw(
    `border-[1px] border-[#2C702F] rounded-full font-normal flex justify-center items-center text-[14px] leading-[19.6px] text-[#2C702F] py-[2px] px-[8px]`
  ),
  danger: tw(
    `text-status-bad font-semibold text-[14px] leading-[21px] whitespace-pre-line text-center`
  ),
  actionContainer: tw(
    `flex flex-col gap-[16px] mt-[16px] max-w-[450px] px-[16px] w-full mx-auto`
  ),
  button: tw(
    `bg-secondary border-[1px] border-secondary flex justify-center items-center text-center text-[white] text-[16px] leading-[25.6px] font-bold w-full py-[16.5px] rounded-full hover:brightness-105 duration-150 transition-all`
  ),
};

const PartnerViewDocument = ({
  isOpen,
  onClose,
  stockList,
}: IPartnerViewDocumentProps) => {
  const [pdfURL, setPDFURL] = useState<string>("");
  const [pdfName, setPDFName] = useState("salePickingSlip");
  const { t } = useTranslation();

  const initImage = useCallback(async () => {
    const files = get(stockList, ["stockRefillFiles"], []);
    const imageSalePickingSlip = files.find(
      (row) => row.documentType === "salePickingSlip"
    );
    if (imageSalePickingSlip) {
      const preSignURL = await getDownloadUrlStockPartner(
        stockList?.id,
        "salePickingSlip",
        imageSalePickingSlip.id
      );
      if (preSignURL) {
        const response = await axios.get<Blob>(preSignURL.blob.blobUrl, {
          responseType: "blob",
        });
        const objectURL = window.URL.createObjectURL(response.data);
        setPDFURL(objectURL);
        setPDFName(imageSalePickingSlip.fileName);
      }
    }
  }, [stockList?.id]);

  const onSaveImage = useCallback(() => {
    if (pdfURL) {
      handleDownloadBlob(pdfURL, pdfName);
    }
  }, [pdfURL, pdfName]);

  useEffect(() => {
    initImage();
  }, [stockList?.id]);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.appbarContainer}>
          <AppBarFS
            title={t("OrderStock.DeliveryDocument")}
            onIconPress={onClose}
          />
        </div>
        <div className={classes.headerContainer}>
          {t("OrderStock.OrderStockNo")} {stockList?.stockRefillNumber}
          <div className={classes.label}>
            {t("feedOrderAndDelivery.myOrder.card.status.accepted")}
          </div>
        </div>
        {pdfURL && <PDFRenderer url={pdfURL} />}
        <div className="flex flex-1" />
        <div className={classes.danger}>
          {`${t("PartnerOrder.ConfirmSaveDocumentOne")}\n${t(
            "PartnerOrder.ConfirmSaveDocumentTwo"
          )}`}
        </div>
        <div className={classes.actionContainer}>
          {/*Todo : save image */}
          <button onClick={onSaveImage} className={tw(classes.button)}>
            {t("FinancialSupport.SaveImage")}
          </button>
          <button
            onClick={onClose}
            className={tw(classes.button, "bg-[white] text-secondary")}
          >
            {t("FinancialSupport.Close")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(PartnerViewDocument);
