import { Link, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { DATE_FORMAT_ENG } from "../../../../const/app.constant";
import { thousandComma } from "../../../../utils/common/string";
import ButtonFillCustom from "../../../components/Button/ButtonFillCustom";
import TableCustom, {
  ITableCustomHeadCell,
} from "../../../components/Table/TableCustom";
import { useSaleTargetHook } from "../hooks/useSaleTarget.hook";
import { useSaleTargetTableHook } from "../hooks/useSaleTargetTable.hook";
import { ISaleTargetTable } from "../types/SaleTargetTable.type";
import CreateSaleTarget from "./CreateSaleTarget";
import DetailSaleTarget from "./DetailSaleTarget";

type Props = {
  tabName: string;
};

export const unitSaleOrderEnum: Record<string, string> = {
  people: "คน",
  currency_baht: "บาท",
  ton: "ตัน",
};

const headCells: ITableCustomHeadCell<keyof ISaleTargetTable>[] = [
  {
    id: "year",
    numeric: false,
    disablePadding: false,
    label: "ปี",
    width: "200px",
    isSorting: false,
  },
  {
    id: "amount",
    numeric: false,
    disablePadding: false,
    label: "จำนวน",
    width: "200px",
    isSorting: false,
  },
  {
    id: "unit",
    numeric: false,
    disablePadding: false,
    label: "หน่วย",
    width: "200px",
    isSorting: false,
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "วันที่อัปเดตล่าสุด",
    width: "200px",
    isSorting: false,
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "100px",
    isSorting: false,
  },
  {
    id: "updated_at",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "100px",
    isSorting: false,
  },
];

const UserTabs = ({ tabName }: Props) => {
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedIndex, setSelectIndex] = useState(0);
  const saleTargetHook = useSaleTargetHook();
  const { list, handleChangeOrder, handleFetch } = useSaleTargetTableHook(
    saleTargetHook,
    tabName
  );

  useEffect(() => {
    handleFetch();
  }, [tabName]);

  return (
    <div>
      <div className="flex w-full pt-6">
        <div className="flex w-full">
          <TableCustom
            order={"desc"}
            orderBy={"updated_at"}
            rowsPerPage={10}
            rows={list}
            itemsSelected={[]}
            headerItems={headCells}
            page={saleTargetHook.page}
            onChangeOrderBy={(val) =>
              handleChangeOrder(val as keyof ISaleTargetTable)
            }
            onChangeOrder={(val) => {
              saleTargetHook.setDirection(val);
            }}
            onChangePage={() => {}}
            onSelectAllClick={() => {}}
            onSelectItem={() => {}}
            totalItemSize={999}
            currentPageItems={list.map((row) => row.id)}
            hidePaginator
            hideSelect
            rowChildren={
              <>
                {list.map((row, index) => {
                  return (
                    <TableRow hover key={row.id}>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {row.year}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {thousandComma(row.totalAmount)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {unitSaleOrderEnum[row.unit]}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {dayjs(row.updatedAt).format(DATE_FORMAT_ENG)}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        <Link
                          component="button"
                          variant="body2"
                          sx={{
                            width: "80px",
                            color: "#68C184",
                            textDecorationColor: "#68C184",
                          }}
                          onClick={() => {
                            setSelectIndex(index);
                            setIsOpenDetailModal(true);
                          }}
                        >
                          ดูรายละเอียด
                        </Link>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          fontFamily: "IBM Plex Sans Thai",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                        }}
                      >
                        {index === 0 && (
                          <ButtonFillCustom
                            btnBgColor="#68C184"
                            btnTextColor="#FFFFFF"
                            title={"แก้ไข"}
                            onClick={() => {
                              setSelectIndex(index);
                              setIsOpenEditModal(true);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            }
          ></TableCustom>
        </div>
      </div>
      <DetailSaleTarget
        data={list[selectedIndex]}
        isOpen={isOpenDetailModal}
        handleClose={() => setIsOpenDetailModal(false)}
      />
      <CreateSaleTarget
        data={list[selectedIndex]}
        isOpen={isOpenEditModal}
        handleClose={() => setIsOpenEditModal(false)}
        tabName={tabName}
      />
    </div>
  );
};

export default UserTabs;
