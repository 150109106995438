import { Modal } from "@mui/material";
import { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import PdfRenderer from "../../../../../../../admin/components/Modal/DocumentModal/ModalPanel/PdfRenderer";
import AppBarFS from "../../../../../../../components/app-bar/AppBar";
import { TFarmerGetListBillItem } from "../../../../../../../types/Invoice.type";
import { tw } from "../../../../../../../utils/tw";
import { useReceiptController } from "./controller";

type TImage = { id: string; url: string };

interface IReceiptModalProps {
  isOpen: boolean;
  onClose: () => void;
  images?: (TImage | File)[];
  ids: string;
  isMoney?: boolean;
  data?: TFarmerGetListBillItem[];
}

const classes = {
  wrapper: tw(`overflow-scroll`),
  container: tw(`min-h-screen w-full flex flex-col bg-[white] pb-[24px]`),
  appbarContainer: tw(`py-4`),
  headerContainer: tw(
    `mb-[16px] px-[16.5px] text-[18px] leading-[21.6px] font-semibold flex flex-row items-center justify-between`
  ),
  label: tw(
    `border-[1px] border-[#2C702F] rounded-full font-normal flex justify-center items-center text-[14px] leading-[19.6px] text-[#2C702F] py-[2px] px-[8px]`
  ),
  swiperWrapper: tw(`[&_.swiper-wrapper]:items-center`),
  swiperContainer: tw(`px-[45px] relative pb-[24px]`),
  swiperImage: tw(`w-full object-contain max-w-[450px] m-auto`),
  danger: tw(
    `text-status-bad font-semibold text-[14px] leading-[21px] whitespace-pre-line text-center`
  ),
  actionContainer: tw(
    `flex flex-col gap-[16px] mt-[16px] max-w-[450px] px-[16px] w-full mx-auto`
  ),
  button: tw(
    `bg-secondary border-[1px] border-secondary flex justify-center items-center text-center text-[white] text-[16px] leading-[25.6px] font-bold w-full py-[16.5px] rounded-full hover:brightness-105 duration-150 transition-all`
  ),
};

const ReceiptModal = (props: IReceiptModalProps) => {
  const { isOpen, onClose, ids, isMoney = false, data } = props;
  const { imgList, handleDownloadByUrl } = useReceiptController(
    ids,
    isMoney,
    data
  );

  const { t } = useTranslation();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  return (
    <Modal open={isOpen} className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.appbarContainer}>
          <AppBarFS
            title={
              isMoney
                ? t("FinancialSupport.DebtAcknowledgement")
                : t("FinancialSupport.Receipt")
            }
            onIconPress={onClose}
          />
        </div>
        <Swiper
          pagination
          modules={[Pagination]}
          loop
          className={classes.swiperWrapper}
        >
          {imgList.length > 0 ? (
            imgList.map((image, index) => {
              const isPdf = image.name.indexOf(".pdf") !== -1;
              return (
                <SwiperSlide
                  key={`${index}`}
                  className={classes.swiperContainer}
                  onClick={() => setIsOpenModal(true)}
                >
                  {!isPdf ? (
                    <>
                      <img
                        src={image.url}
                        className={tw(
                          classes.swiperImage,
                          "min-h-[420px] border-[1px] border-[#555]"
                        )}
                      />
                    </>
                  ) : (
                    <PdfRenderer
                      url={image.url}
                      className={tw(
                        classes.swiperImage,
                        "min-h-[420px] border-[1px] border-[#555]"
                      )}
                    />
                  )}
                </SwiperSlide>
              );
            })
          ) : (
            <div className="text-center font-normal text-[14px]">
              {isMoney
                ? t("FinancialSupport.NoDebtReceipt")
                : t("FinancialSupport.NoReceipt")}
            </div>
          )}
        </Swiper>
        <div className="flex flex-1" />
        <div className={classes.actionContainer}>
          <button
            onClick={handleDownloadByUrl}
            className={tw(classes.button)}
            disabled={imgList.length === 0}
          >
            {t("FinancialSupport.SaveImage")}
          </button>
          <button
            onClick={onClose}
            className={tw(classes.button, "bg-[white] text-secondary")}
          >
            {t("FinancialSupport.Close")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(ReceiptModal);
