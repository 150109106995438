import { useState } from "react";
import { Order } from "../../../components/Table/TableCustom";

export const useSaleTargetHook = () => {
  const [direction, setDirection] = useState<Order>("desc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState();
  const [orderBy, setOrderBy] = useState("updated_at");

  return {
    orderBy,
    setOrderBy,
    direction,
    page,
    limit,
    setDirection,
    setPage,
    setLimit,
  };
};

export type TUseSaleTargetHook = ReturnType<typeof useSaleTargetHook>;
