import { Divider, Link, SvgIcon } from "@mui/material";
import dayjs from "dayjs";
import * as echarts from "echarts";
import ReactECharts from "echarts-for-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Month } from "../../../const/utils";
import {
  getBarChartMoneyDebt,
  getDonutMoneyDebt,
  getGroupArea,
  getYear,
} from "../../../services/dashboard/MoneyDebtCollection.service";
import { BarChart, IGetGroupAreaOptionRow } from "../../../types/Utils.type";
import { thousandComma } from "../../../utils/common/string";
import { echartsExportPng } from "../../../utils/echarts";
import { makeXlsx } from "../../../utils/make-xlsx";
import { DownloadSvg } from "../../assets/svg/Download.svg";
import ButtonFillCustom from "../../components/Button/ButtonFillCustom";
import ButtonOutlinedCustom from "../../components/Button/ButtonOutlinedCustom";
import MenuBar from "../../components/MenuBar/MenuBar";
import { Dropdown, TDropdownValue } from "../group-area/views/Dropdown";
import { useTranslation } from "react-i18next";

type Props = {};

const DeptCollection = (props: Props) => {
  const { t } = useTranslation();
  const [dataPie, setDataPie] = useState<{ [key: string]: number }>({});
  const segmentOption = ["Potential", "Grower", "Independent"];
  const [segment, setSegment] = useState<TDropdownValue[]>([]);
  const [groupAreaOption, setGroupAreaOption] = useState<
    IGetGroupAreaOptionRow[]
  >([]);
  const [yearOption, setYearOption] = useState<string[]>([]);
  const [year, setYear] = useState<string>(dayjs().year().toString());
  const [groupArea, setGroupArea] = useState<TDropdownValue[]>([]);
  const [dataBar, setDataBarChart] = useState<BarChart[]>([]);
  const [toggleFetch, setToggleFetch] = useState(false);
  const navigate = useNavigate();

  const eChartRef1 = useRef<any>();
  const eChartRef2 = useRef<any>();

  const fetchDonutChart = async () => {
    const data: { [key: string]: number } | undefined =
      await getDonutMoneyDebt();
    if (data) {
      setDataPie(data);
    }
  };

  const fetchGroupAreaOption = async () => {
    const data = await getGroupArea();
    setGroupAreaOption(data);
  };
  const fetchYearOption = async () => {
    const data = await getYear();
    setYearOption(data);
    setYear(data[0] ?? dayjs().year().toString());
  };

  const fetchBarChart = async () => {
    const searchParams = new URLSearchParams();
    searchParams.append("year", year);
    searchParams.append("groupAreaIds", groupArea[0]?.value || "");
    searchParams.append(
      "segments",
      segment.map((segment) => segment.value).join(",") || ""
    );
    const data = await getBarChartMoneyDebt(searchParams);
    if (data) {
      setDataBarChart(data);
    }
  };

  const exportMoneyDebt = () => {
    let data: {
      Month: string;
      "Loan Outstanding": number;
      repayment: number;
      "Repayment Expectation": number;
      "Accumulate LoanOutstanding": number;
      "Accumulate Repayment": number;
    }[] = [];
    Month.map((item, index) => {
      data.push({
        Month: item,
        "Loan Outstanding": dataBar[index].loanOutstanding,
        repayment: dataBar[index].repayment,
        "Repayment Expectation": dataBar[index].repaymentExpectation,
        "Accumulate LoanOutstanding": dataBar[index].accumulateLoanOutstanding,
        "Accumulate Repayment": dataBar[index].accumulateRepayment,
      });
    });
    const fileName = `Debt_Collection_Graph_${dayjs().format(
      "YYYYMMDDHHmmss"
    )}`;
    const sheetName = "list";
    makeXlsx(data || [], fileName, sheetName);
  };

  const exportDebtVolume = () => {
    let data: {
      "On Time": number;
      "Overdue 1-2 days": number;
      "Overdue 3-6 days": number;
      "Overdue 7-30 days": number;
      "Overdue 31-60 days": number;
      "Overdue more than 60 days": number;
    }[] = [];

    data.push({
      "On Time": dataPie["on_time"],
      "Overdue 1-2 days": dataPie["1_to_2"],
      "Overdue 3-6 days": dataPie["3_to_6"],
      "Overdue 7-30 days": dataPie["7_to_30"],
      "Overdue 31-60 days": dataPie["31_to_60"],
      "Overdue more than 60 days": dataPie["61_up"],
    });
    const fileName = `Debt_Volume_Chart_${dayjs().format("YYYYMMDDHHmmss")}`;
    const sheetName = "list";
    makeXlsx(data || [], fileName, sheetName);
  };

  useEffect(() => {
    fetchDonutChart();
    fetchGroupAreaOption();
    fetchYearOption();
  }, []);

  useEffect(() => {
    fetchBarChart();
  }, [toggleFetch]);

  const optionsSale = {
    grid: {
      top: "3%",
      left: "3%",
      right: "3%",
      bottom: "15%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      axisLine: {
        show: false,
      },
      axisTick: {
        show: true,
      },
      axisLabel: {
        fontSize: "10px",
        fontFamily: "DM Sans",
        color: "#979797",
        fontWeight: "normal",
        interval: 0,
        hideOverlap: false,
      },
    },
    yAxis: [
      {
        type: "value",
        axisLabel: {
          fontSize: "10px",
          fontFamily: "DM Sans",
          color: "#979797",
          fontWeight: "normal",
          interval: 0,
          hideOverlap: false,
        },
      },
      {
        type: "category",
        data: ["0%", "20%", "40%", "60%", "80%", "100%"],
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          fontSize: "10px",
          fontFamily: "DM Sans",
          color: "#979797",
          fontWeight: "normal",
          interval: 0,
          hideOverlap: false,
        },
      },
    ],

    legend: {
      show: true,
      bottom: "0",
      itemGap: 20,
      textStyle: {
        color: "#000000",
        fontSize: "14px",
        fontFamily: "DM Sans",
        fontWeight: "normal",
      },
      data: [
        {
          name: "Loan Outstanding",
          icon: "circle",
          itemStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#68C184", // color at 0%
                },
                {
                  offset: 1,
                  color: "#77F2A1", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        {
          name: "Repayment",
          icon: "circle",
          itemStyle: {
            borderRadius: 50,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#3777BC", // color at 0%
                },
                {
                  offset: 1,
                  color: "#659DDA", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        {
          name: "Repayment Expectation",
          icon: "circle",
          itemStyle: {
            borderRadius: 50,
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#3777BC99", // color at 0%
                },
                {
                  offset: 1,
                  color: "#659DDA99", // color at 100%
                },
              ],
              global: false, // default is false
            },
          },
        },
        {
          name: "Accumulate Loan Outstanding",
          itemStyle: {
            borderRadius: 50,
          },
        },
        {
          name: "Accumulate Repayment",
          itemStyle: {
            borderRadius: 50,
          },
        },
      ],
    },
    series: [
      {
        name: "Loan Outstanding",
        data: dataBar.map((data) => data.loanOutstanding),
        type: "bar",
        smooth: false,
        itemStyle: {
          color: () => {
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#68C184" },
              { offset: 1, color: "#77F2A1" },
            ]);
          },
        },
      },
      {
        name: "Repayment",
        data: dataBar.map((data) => data.repayment),
        type: "bar",
        smooth: false,
        itemStyle: {
          color: () => {
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#3777BC" },
              { offset: 1, color: "#659DDA" },
            ]);
          },
        },
      },
      {
        name: "Repayment Expectation",
        data: dataBar.map((data) => data.repaymentExpectation),
        type: "bar",
        smooth: false,
        itemStyle: {
          color: () => {
            return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: "#3777BC66" },
              { offset: 1, color: "#659DDA66" },
            ]);
          },
        },
      },
      {
        name: "Accumulate Loan Outstanding",
        data: dataBar.map((data) => data.accumulateLoanOutstanding),
        type: "line",
        smooth: false,
        lineStyle: {
          normal: {
            color: "#62A665",
            width: 2,
            type: "dashed",
          },
        },
        itemStyle: {
          color: "#62A665",
        },
        symbol: "circle",
        symbolSize: 10,
      },

      {
        name: "Accumulate Repayment",
        data: dataBar.map((data) => data.accumulateRepayment),
        type: "line",
        smooth: false,
        lineStyle: {
          normal: {
            color: "#3777BC",
            width: 2,
            type: "dashed",
          },
        },
        itemStyle: {
          color: "#3777BC",
        },
        symbol: "circle",
        symbolSize: 10,
      },
    ],
    tooltip: {
      trigger: "axis",
    },
  };

  const optionDonut = {
    legend: {
      bottom: "0%",
      left: "center",
      data: [
        "On Time",
        "Overdue 1-2 days",
        "Overdue 3-6 days",
        "Overdue 7-30 days",
        "Overdue 31-60 days",
        "Overdue more than 60 days",
      ],
      textStyle: {
        fontSize: 14,
        color: "#000000",
      },
      itemGap: 20,
      icon: "circle",
      show: true,
    },
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        radius: ["50%", "70%"],
        center: ["50%", "50%"],
        padAngle: 5,
        itemStyle: {
          color: undefined,
          borderRadius: 5,
          borderWidth: 2,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 30,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          label: {
            show: false,
          },
        },
        labelLine: { show: false },
        tooltip: {
          formatter: (params: any) => {
            return `${params.name}: ${thousandComma(params.value)} บาท`;
          },
        },
        data: [
          {
            value: dataPie["on_time"],
            name: "On Time",
            itemStyle: {
              color: "#68C184",
              shadowColor: "#68C184B3",
            },
          },
          {
            value: dataPie["1_to_2"],
            name: "Overdue 1-2 days",
            itemStyle: {
              color: "#3777BC",
              shadowColor: "#3777BCB3",
            },
          },
          {
            value: dataPie["3_to_6"],
            name: "Overdue 3-6 days",
            itemStyle: {
              color: "#5572EB",
              shadowColor: "#5572EBB3",
            },
          },
          {
            value: dataPie["7_to_30"],
            name: "Overdue 7-30 days",
            itemStyle: {
              color: "#F79836",
              shadowColor: "#F79836B3",
            },
          },
          {
            value: dataPie["31_to_60"],
            name: "Overdue 31-60 days",
            itemStyle: {
              color: "#F57C00",
              shadowColor: "#F57C00B3",
            },
          },
          {
            value: dataPie["61_up"],
            name: "Overdue more than 60 days",
            itemStyle: {
              color: "#DC5B5B",
              shadowColor: "#DC5B5BB3",
            },
          },
        ].filter((row) => row.value),
        label: {
          show: false,
        },
      },
    ],
  };

  return (
    <>
      <MenuBar>
        <div className="pt-16 w-full h-full relative">
          <div className="p-10">
            <span className="text-[#000] font-bold text-3xl">
              {t("dashboard.performance.debtCollectionTitle")}
            </span>
            <Divider sx={{ paddingTop: "12px" }} />
            <div className="pt-6 grid grid-cols-12 gap-6">
              <div className="grid col-span-12 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-[#000] font-bold text-2xl">
                    {t("dashboard.performance.debtCollection")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => {
                        echartsExportPng(eChartRef1, "Debt Collection");
                      }}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => {
                        exportMoneyDebt();
                      }}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3 items-end">
                  <Dropdown
                    label="ปี"
                    options={yearOption.map((row) => ({
                      value: row,
                      label: row,
                    }))}
                    onChange={(value) => {
                      setYear(value[0].value);
                    }}
                    onReset={() => {
                      setYear("");
                      setToggleFetch((prev) => !prev);
                    }}
                    renderValueArray={
                      [
                        {
                          value: year,
                          label: year,
                        },
                      ] as TDropdownValue[]
                    }
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Group Area"
                    options={groupAreaOption.map((row) => ({
                      value: row.id,
                      label: row.name,
                    }))}
                    onChange={(value) => {
                      setGroupArea(value);
                    }}
                    onReset={() => {
                      setGroupArea([]);
                      setToggleFetch((prev) => !prev);
                    }}
                    renderValueArray={groupArea}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                  />
                  <Dropdown
                    label="Segment"
                    options={segmentOption.map((row) => ({
                      value: row,
                      label: row,
                    }))}
                    onChange={(value) => setSegment(value)}
                    onReset={() => {
                      setSegment([]);
                      setToggleFetch((prev) => !prev);
                    }}
                    renderValueArray={segment}
                    containerClassName="min-w-[150px]"
                    placeholder="เลือก"
                    multiple
                  />
                  <ButtonFillCustom
                    title="ค้นหา"
                    onClick={() => {
                      fetchBarChart();
                    }}
                    className="!mb-3"
                  />
                </div>
                <div className="flex flex-row justify-end">
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      width: "80px",
                      color: "#68C184",
                      textDecorationColor: "#68C184",
                    }}
                    onClick={() => {
                      navigate(
                        "/admin/performance/dept-collection/dept-collection-table"
                      );
                    }}
                  >
                    ดูรายละเอียด
                  </Link>
                </div>
                <div>
                  <ReactECharts option={optionsSale} ref={eChartRef1} />
                </div>
              </div>

              <div className="grid col-span-12 p-6 rounded-[20px] shadow-[1px_1px_10px_0px_#0000001A] gap-6">
                <div className="flex flex-row justify-between items-center">
                  <span className="text-[#000] font-bold text-2xl">
                    {t("dashboard.performance.debtVolumn")}
                  </span>
                  <div className="flex flex-row justify-between items-center gap-3">
                    <ButtonOutlinedCustom
                      title="Download"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => {
                        echartsExportPng(eChartRef2, "Debt Volume");
                      }}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                    <ButtonOutlinedCustom
                      title="นำออก"
                      btnTextColor="#68C184"
                      btnBorderColor="#68C184"
                      onClick={() => {
                        exportDebtVolume();
                      }}
                      startIcon={
                        <SvgIcon
                          sx={{
                            fontSize: "14px !important",
                            color: "currentColor",
                            marginBottom: "3px",
                          }}
                          fontSize="small"
                        >
                          <DownloadSvg />
                        </SvgIcon>
                      }
                    />
                  </div>
                </div>
                <div>
                  <ReactECharts option={optionDonut} ref={eChartRef2} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </MenuBar>
    </>
  );
};

export default DeptCollection;
