import { Divider, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tw } from "../../../../../utils/tw";
import MenuBar from "../../../../components/MenuBar/MenuBar";
import { TabPanelCustom } from "../../../../components/TabPanelCustom/TabPanelCustom";
import { getCountDebtLending } from "../services/trackingDebt.service";
import { CountTabLendingResult } from "../types/trackingDebt.type";
import FinishDebt from "./LendingTabs/FinishDebt";
import TrackingDebt from "./LendingTabs/TrackingDebt";

type Props = {};

const classes = {
  container: tw(`pt-16 w-full h-full relative`),
  header: tw(`text-[#000] font-bold text-3xl font-sans`),
  paddingTop: tw(`p-10`),
  flexItemCenter: tw(`flex items-center`),
};

const MoneyDebtLending = (props: Props) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [countTab, setCountTab] = useState<CountTabLendingResult>();

  const onChangeTabIndex = (
    event: React.SyntheticEvent<Element, Event>,
    value: number
  ) => {
    setTabIndex(value);
  };

  const getCountMoneyDebt = async () => {
    const data = await getCountDebtLending();
    setCountTab(data?.result);
  };

  useEffect(() => {
    getCountMoneyDebt();
  }, [tabIndex]);

  return (
    <MenuBar>
      <div className={classes.container}>
        <div className={classes.paddingTop}>
          <div className={classes.flexItemCenter}>
            <span className={classes.header}>
              {t("moneyDebtAndCollectionLending.title")}
            </span>
          </div>
          <Divider sx={{ paddingTop: "12px" }} />
          <div className="mt-6">
            <Tabs
              value={tabIndex}
              onChange={onChangeTabIndex}
              aria-label="farm-information-tab-control"
              TabIndicatorProps={{
                sx: {
                  "&.MuiTabs-indicator": {
                    transform: "unset",
                    width: "unset",
                    bgcolor: "#68C184",
                    height: "3px",
                  },
                },
              }}
              sx={{
                height: "36px",
                minHeight: "unset",
                "& .MuiTabs-scroller": {
                  "& .MuiTabs-flexContainer": {
                    alignItems: "end",
                    height: "100%",
                    display: "flex",
                    gap: "12px",
                    "& .MuiTab-root": {
                      padding: "6px 28px",
                      textTransform: "unset",
                      fontFamily: "IBM Plex Sans Thai",
                      fontWeight: 400,
                      fontSize: "1rem",
                      lineHeight: "20px",
                      height: "32px",
                      minHeight: "unset",
                      color: "#000000",
                      borderBottom: "3px solid #DDF3E4",
                      "& .Mui-selected": {
                        color: "#000000",
                      },
                    },
                  },
                },
              }}
            >
              <Tab
                label={`${t(
                  "moneyDebtAndCollectionLending.onProcess.title"
                )} (${countTab?.followingDebtAmount})`}
              />
              <Tab
                label={`${t("moneyDebtAndCollectionLending.success.title")} (${
                  countTab?.finishedDebtAmount
                })`}
              />
            </Tabs>
          </div>
          <TabPanelCustom
            value={tabIndex}
            index={0}
            prefix="personal-information"
          >
            <TrackingDebt />
          </TabPanelCustom>
          <TabPanelCustom value={tabIndex} index={1} prefix="farm-information">
            <FinishDebt />
          </TabPanelCustom>
        </div>
      </div>
    </MenuBar>
  );
};

export default MoneyDebtLending;
