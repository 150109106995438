import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteOutlinedIcon } from "../../../../../../assets/svg/DeleteOutlined";
import { alertAction } from "../../../../../../redux/slices/alertSlice";
import { loadingActions } from "../../../../../../redux/slices/loadingSlice";
import { snakeActions } from "../../../../../../redux/slices/snakeSlice";
import getErrorMessage from "../../../../../../utils/getErrorMessage";
import { useAdminAuth } from "../../../../../components/AdminAuth/AdminAuthProvider";
import { deleteManual } from "../../../services/farmCommunity.service";
import { IUserManualListRow } from "../../../types/farmCommunity";
import { useTranslation } from "react-i18next";

type Props = {
  listUserManual: IUserManualListRow[];
  fetch: () => void;
};

const UserManuals = ({ listUserManual }: Props) => {
  const { t } = useTranslation();
  const { action } = useAdminAuth();
  const roleActionEdit = action?.masterDataFarmCommunity === "edit";
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleDelete = async (manualId: string) => {
    dispatch(
      alertAction.showDialog({
        title: "ยืนยันการลบ",
        text: "คุณต้องการลบรายการนี้ใช่หรือไม่?",
        cancelText: t("common.button.back"),
        confirmText: t("common.button.confirm"),
        onCancel: () => {
          dispatch(alertAction.hide());
        },
        onSubmit: async () => {
          try {
            dispatch(alertAction.hide());
            dispatch(loadingActions.show({ message: "กำลังยืนยัน..." }));
            await deleteManual(manualId);
            dispatch(
              snakeActions.showMessage({
                type: "success",
                message: "ลบสำเร็จ",
              })
            );
          } catch (error) {
            dispatch(
              snakeActions.showMessage({
                type: "error",
                message: getErrorMessage(error),
              })
            );
          } finally {
            dispatch(loadingActions.hide());
          }
        },
      })
    );
  };

  return (
    <div className="pt-6 flex flex-col w-full">
      <div className="flex flex-row w-full items-center justify-between py-[16px] px-[20px] bg-[#FBFBFB] font-sans rounded-t-[6px] text-[#534D59] font-bold text-[14px] leading-[18.23px] border-[#E4E4E4] border-[0.5px]">
        {t("farmCommunity.userManualPage.addUserManual.topic")}
      </div>
      {listUserManual?.map((userManual) => (
        <div className="flex flex-row w-full items-center justify-between border-b-[0.5px] rounded-b-[6px] py-[16px] px-[20px] bg-[#ffff] font-sans text-[#534D59] text-[14px] leading-[18.23px] border-[#E4E4E4] border-[0.5px] border-y-0">
          {userManual.name}
          <div className="flex flex-row justify-end gap-x-[36px]">
            <button
              className="text-secondary text-[14px] leading-[14px] tracking-[-5%] font-medium underline hover:brightness-110 cursor-pointer"
              onClick={() =>
                navigate(
                  "/admin/master-data/farm-community/user-manual/" +
                    userManual.id,
                  { state: userManual }
                )
              }
            >
              ดูรายละเอียด
            </button>
            {roleActionEdit && (
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => handleDelete(userManual.id)}
              >
                <DeleteOutlinedIcon />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserManuals;
